import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@rossum/ui/material';
import React, { RefObject, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import { displayDAGIntroBannerSelector } from '../../../redux/modules/user/selectors';
import EmptyExtensionsList from '../components/EmptyExtensionsList';
import IntroToDAGBanner from '../components/IntroToDAGBanner';
import DAG from './components/DAG';
import UpdateDependenciesDrawer from './components/UpdateDependenciesDrawer';
import { ExtendedHook } from './helpers';
import { GraphDataGroupedPerEvents } from './hooks';

type Props = {
  isLoading: boolean;
  isFetching: boolean;
  graphDataGroupedPerEvents: GraphDataGroupedPerEvents;
  selectedQueueId?: number;
};

const DependenciesGraph = ({
  graphDataGroupedPerEvents,
  selectedQueueId,
  isLoading,
  isFetching,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayDAGIntroBanner = useSelector(displayDAGIntroBannerSelector);
  const hideIntroToDAGBanner = () =>
    dispatch(
      updateUiSettings({
        hideDagIntroBanner: true,
      })
    );

  const [drawerState, setDrawerState] = useState<null | {
    selectedExtension: ExtendedHook | null;
    selectedEvents: ExtendedHook['events'] | null;
    selectedElement: RefObject<HTMLDivElement> | undefined;
  }>(null);

  const onUpdateDependenciesClick = useCallback(
    (
      node: ExtendedHook | null,
      nodeEvents: ExtendedHook['events'] | null,
      nodeElement: RefObject<HTMLDivElement> | undefined
    ) => {
      setDrawerState({
        selectedExtension: node,
        selectedEvents: nodeEvents,
        selectedElement: nodeElement,
      });
    },
    []
  );

  return isLoading ? (
    <Stack alignItems="center" sx={{ pt: 8 }}>
      <CircularProgress size={64} />
    </Stack>
  ) : graphDataGroupedPerEvents.some(({ graphData }) => graphData[0].length) ? (
    <Stack>
      {displayDAGIntroBanner && (
        <IntroToDAGBanner
          onClose={hideIntroToDAGBanner}
          dataCy="intro-to-dag"
        />
      )}
      {graphDataGroupedPerEvents.map(
        ({ graphData, extensionGraphNodesMap, eventNames }) => {
          if (graphData[0].length === 0) {
            return null;
          }

          return (
            <React.Fragment key={eventNames.join('-')}>
              <Typography
                variant="h6"
                sx={{ mt: 3 }}
                data-cy={`extension-name-${eventNames
                  .toString()
                  .replaceAll('.', '_')}`}
              >
                {eventNames
                  .map(event =>
                    intl.formatMessage({
                      id: `containers.settings.queues.queue.extensions.${event}`,
                    })
                  )
                  .join(', ')}
              </Typography>
              <DAG
                graphData={graphData}
                extensionGraphNodesMap={extensionGraphNodesMap}
                key={`${selectedQueueId}${graphData}-${isFetching}`}
                selectedExtension={drawerState?.selectedExtension}
                onUpdateDependenciesClick={onUpdateDependenciesClick}
                eventNames={eventNames}
              />

              <Divider sx={{ my: 3 }} />
            </React.Fragment>
          );
        }
      )}
      <UpdateDependenciesDrawer
        graphDataGroupedPerEvents={graphDataGroupedPerEvents}
        drawerState={drawerState}
        onClose={() => setDrawerState(null)}
        selectedQueueId={selectedQueueId}
      />
    </Stack>
  ) : (
    <EmptyExtensionsList />
  );
};

export default DependenciesGraph;
