import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngineSchemaObjectPatchModel } from '../models/dedicatedEngineSchemaObject';
import { DedicatedEngineSchemaValidationResult } from '../models/integrityValidationResults';
import { dedicatedEngineSchemaValidationResultSchema } from '../models/integrityValidationResults.schema';
import { dedicatedEngineSchemaValidatePayloadSchema } from './validate.schema';

// TODO: Patch model is inaccurate here even if it is the same
// using it now to avoid duplication, but open to discussion:
// How to address this until our TS strict mode is turned on and domain models refactored
// to Zod?
// in general these io-ts models are spreading complexity that Zod will help with
// by using compose/omit/partial utilities?
export type DedicatedEngineSchemaValidatePayload = {
  content: {
    trainingQueues: Url[];
    fields: DedicatedEngineSchemaObjectPatchModel[];
  };
};

export const validate = (payload: DedicatedEngineSchemaValidatePayload) => {
  return {
    endpoint: `/dedicated_engine_schemas/validate`,
    method: 'POST',
    responseSchema: dedicatedEngineSchemaValidationResultSchema,
    payload,
    payloadSchema: dedicatedEngineSchemaValidatePayloadSchema,
  } as const;
};
