import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';
import { userSelector } from '../../redux/modules/user/selectors';

export const useResetPasswordByAdmin = () => {
  const dispatch = useDispatch();

  const { email: adminEmail } = useSelector(userSelector);

  return useMutation({
    mutationFn: (payload: { email: string; emailType?: 'welcome' }) =>
      api.request(
        endpoints.authentication.password.reset({
          ...payload,
          adminEmail: adminEmail || undefined,
        })
      ),

    onError: () => {
      dispatch(throwError('passwordChangeFailed'));
    },

    retry: false,
  });
};
