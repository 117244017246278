import {
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSetupEngineSchema } from '../../legacy-engines/hooks/useSetupEngineSchema';
import { useInfiniteDedicatedEngines } from '../hooks/useInfiniteDedicatedEngines';
import EngineTile from './EngineTile';
import ShowMoreButton from './ShowMoreButton';
import TilesList from './TilesList';
import TilesListEmptyState from './TilesListEmptyState';

const DedicatedEnginesList = () => {
  const intl = useIntl();

  const {
    data: engines,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status,
  } = useInfiniteDedicatedEngines({
    pageSize: 10,
    ordering: ['-id'],
  });

  const { handleOnClick, isLoading } = useSetupEngineSchema();

  const allEngines = engines?.pages.flatMap(page => page.results);

  return (
    <TilesList
      title={intl.formatMessage({
        id: 'components.dedicatedEnginesList.title',
      })}
      items={allEngines}
      renderTile={engine => (
        <EngineTile
          key={engine.id}
          engine={engine}
          configureEngineButton={
            engine.status !== 'draft' ? null : (
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={() => handleOnClick(engine.id, !!engine.schema)}
                endIcon={
                  isLoading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : null
                }
                disabled={isLoading}
                data-cy="engines-configure-engine"
              >
                {intl.formatMessage({
                  id: 'components.engineCard.actionButton',
                })}
              </Button>
            )
          }
        />
      )}
      status={status}
      emptyState={
        <TilesListEmptyState
          title={intl.formatMessage({
            id: 'components.dedicatedEnginesList.noEngines',
          })}
        />
      }
      errorState={
        <Stack justifyContent="center" direction="row">
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.dedicatedEnginesList.errorFetchingEngines',
            })}
          </Typography>
        </Stack>
      }
      buttons={
        hasNextPage
          ? [
              <ShowMoreButton
                key="show-more-results"
                onClick={fetchNextPage}
                isFetching={isFetching}
              />,
            ]
          : []
      }
    />
  );
};

export default DedicatedEnginesList;
