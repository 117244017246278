import { ExpandLess, ExpandMore, FolderOpen } from '@rossum/ui/icons';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { Queue as QueueT } from '../../../types/queue';
import { WorkspaceWithQueues } from '../../../types/workspace';
import Queue from './Queue';

type Props = {
  defaultExpand: boolean;
  forceExpand: boolean;
  workspace: WorkspaceWithQueues;
  setSelectedQueue: (_queue: QueueT) => void;
  selectedQueue: QueueT | undefined;
};

const Workspace = ({
  forceExpand,
  defaultExpand,
  workspace,
  selectedQueue,
  setSelectedQueue,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpand);
  const toggleWorkspace = () => setExpanded(!expanded);

  useEffect(() => {
    if (!expanded && forceExpand) setExpanded(true);
  }, [expanded, forceExpand]);

  return (
    <>
      <ListItemButton
        onClick={toggleWorkspace}
        data-cy={`workspace-menu-item-${workspace.name.replaceAll(' ', '-')}`}
      >
        <ListItemIcon sx={{ minWidth: theme => theme.spacing(4.5) }}>
          <FolderOpen color="primary" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography noWrap variant="body2">
              {workspace.name}
            </Typography>
          }
        />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expanded} mountOnEnter timeout="auto">
        <List component="div" disablePadding>
          {workspace.queues.map(queue => (
            <Queue
              key={queue.id}
              onClick={() => setSelectedQueue(queue)}
              queue={queue}
              selected={selectedQueue ? selectedQueue.url === queue.url : false}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Workspace;
