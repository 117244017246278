import { useCallback, useEffect, useRef } from 'react';
import {
  Point2D,
  Rectangle2D,
  rectangleToCoordinates,
} from '../document-canvas/utils/geometry';
import {
  useCanvasGeometryActions,
  useDocumentStore,
} from '../document-store/DocumentStore';

const MOUSE_SCROLL_SPEED = 15;
const SCROLL_BOUNDARY = 40;

const getScrollDirection = (
  mousePosition: Point2D,
  viewportBoundary: Rectangle2D
): Point2D | undefined => {
  const [x1, y1, x2, y2] = rectangleToCoordinates(viewportBoundary);
  const { x: mx, y: my } = mousePosition;

  const top = Math.abs(my - y1) < SCROLL_BOUNDARY;
  const left = Math.abs(mx - x1) < SCROLL_BOUNDARY;
  const bottom = Math.abs(my - y2) < SCROLL_BOUNDARY;
  const right = Math.abs(mx - x2) < SCROLL_BOUNDARY;

  const point = {
    // Convert true/false to 1/0
    y: (-top + +bottom) * MOUSE_SCROLL_SPEED,
    x: (-left + +right) * MOUSE_SCROLL_SPEED,
  };
  return point.x === 0 && point.y === 0 ? undefined : point;
};

export const useCanvasScrollOnEdges = () => {
  const { translateBy } = useCanvasGeometryActions();
  const mousePosition = useRef<Point2D | undefined>(undefined);
  const viewport = useDocumentStore(state => state.viewportRef);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    // currently we scroll any time button is pressed on a mouse
    // we could make this better by using actual state (UI action in progress)
    const buttonPressed = event.buttons === 1;
    const selector = document.querySelector('[data-dragged]');

    mousePosition.current =
      buttonPressed && selector !== null
        ? { x: event.clientX, y: event.clientY }
        : undefined;
  }, []);

  const handleMouseUp = useCallback(() => {
    mousePosition.current = undefined;
  }, []);

  const handleStep = useCallback(() => {
    if (mousePosition.current && viewport.current) {
      const viewportBoundary = viewport.current.getBoundingClientRect();
      const point = getScrollDirection(mousePosition.current, viewportBoundary);
      if (point) {
        translateBy(point);
      }
    }
  }, [translateBy, viewport]);

  useEffect(() => {
    const interval = window.setInterval(handleStep, 30);
    return () => clearInterval(interval);
  }, [handleStep]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseUp]);
};
