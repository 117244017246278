import { Url } from '@rossum/api-client';
import { useUnpaginatedQueuesPerOrg } from './useUnpaginatedQueuesPerOrg';
import { useUnpaginatedWorkspacesPerOrg } from './useUnpaginatedWorkspacesPerOrg';

export const useQueuesForOrganization = (organizationUrl: Url | undefined) => {
  const queuesResult = useUnpaginatedQueuesPerOrg(organizationUrl, {
    pageSize: 100,
  });
  const workspacesResult = useUnpaginatedWorkspacesPerOrg(organizationUrl, {
    pageSize: 100,
  });

  const isLoading = queuesResult.isLoading || workspacesResult.isLoading;
  const isSuccess = queuesResult.isSuccess && workspacesResult.isSuccess;
  const data = isSuccess
    ? workspacesResult.data.map(workspace => ({
        ...workspace,
        queues: queuesResult.data.filter(
          queue => queue.workspace === workspace.url
        ),
      }))
    : undefined;
  const queues = queuesResult.isSuccess
    ? queuesResult.data.filter(queue => queue.workspace !== null)
    : undefined;

  return { data, isLoading, isSuccess, queues };
};
