import { AutomationResponse } from '@rossum/api-client/queues';
import { RuiSectionTitle } from '@rossum/rossum-ui/RuiSectionTitle';
import { KeyboardArrowDown, KeyboardArrowUp } from '@rossum/ui/icons';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';
import { getTypeName } from '../../../../components/UI/AutomationBlockers';
import { boldText, white } from '../../../../lib/formaterValues';
import { AnyDatapointSchema } from '../../../../types/schema';
import { roundToFixedPercents } from './utils';

const DISPLAYED_BLOCKERS_COUNT = 5;

const shouldDisplayShowMoreLessButton = (
  automationBlockers: AutomationResponse['automationBlockers']
) => automationBlockers.length > DISPLAYED_BLOCKERS_COUNT;

type Props = {
  data?: AutomationResponse;
  schemaMap: ReadonlyMap<string, AnyDatapointSchema>;
  dateRangeLabel: string;
};

const AutomationBlockers = ({ data, schemaMap, dateRangeLabel }: Props) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const blockers = useMemo(
    () => sortBy(data?.automationBlockers, 'documentOccurrences').reverse(),
    [data?.automationBlockers]
  );
  const blockedAutomationFraction = (data: AutomationResponse) =>
    1 - data.totals.automation;

  return (
    <Stack spacing={1}>
      <RuiSectionTitle>
        {intl.formatMessage({
          id: 'containers.settings.automation.automationBlockers.title',
        })}
      </RuiSectionTitle>

      {data && !!data.automationBlockers.length ? (
        <Stack spacing={2}>
          <Typography variant="body2" color="text.secondary" paragraph>
            <Tooltip
              placement="top"
              title={roundToFixedPercents(blockedAutomationFraction(data), 0)}
            >
              <Chip
                component="span"
                size="tiny"
                color="default"
                label={(
                  blockedAutomationFraction(data) * data.totals.documentCount
                ).toFixed(0)}
              />
            </Tooltip>
            {` `}
            {intl.formatMessage(
              {
                id: 'containers.settings.automation.automationBlockers.description',
              },
              {
                boldText,
                dateRange: dateRangeLabel.toLowerCase(),
                total: data.totals.documentCount,
              }
            )}
          </Typography>

          <TransitionGroup>
            {blockers
              .slice(0, expanded ? undefined : DISPLAYED_BLOCKERS_COUNT)
              .map(blocker => {
                return (
                  <Collapse
                    key={`${blocker.level}-${blocker.type}-${blocker.id}`}
                  >
                    <Grid container spacing={3} sx={{ marginBottom: 2 }}>
                      <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <Typography variant="body2" fontWeight="bold">
                          {`${roundToFixedPercents(
                            blocker.documentOccurrencesRel,
                            0
                          )} (${blocker.documentOccurrences})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" component="span">
                          {getTypeName(blocker.type)}{' '}
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {blocker.id
                              ? intl.formatMessage(
                                  {
                                    id: 'containers.settings.automation.automationBlockers.datapointLevel',
                                  },
                                  {
                                    datapointLabel:
                                      schemaMap.get(blocker.id)?.label ??
                                      blocker.id,
                                    white,
                                  }
                                )
                              : intl.formatMessage({
                                  id: 'containers.settings.automation.automationBlockers.annotationLevel',
                                })}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Collapse>
                );
              })}
          </TransitionGroup>

          {shouldDisplayShowMoreLessButton(data.automationBlockers) && (
            <Grid>
              <Grid item xs={3} />
              <Grid item xs={8}>
                <Button
                  color="secondary"
                  onClick={() => setExpanded(!expanded)}
                  startIcon={
                    expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                  }
                  size="small"
                  sx={{ left: -8 }}
                >
                  {intl.formatMessage({
                    id: !expanded
                      ? 'containers.settings.automation.automationBlockers.showAll'
                      : 'containers.settings.automation.automationBlockers.showLess',
                  })}
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>
      ) : (
        <Box textAlign="center" sx={{ paddingTop: 2 }}>
          <Typography variant="body2" paragraph>
            {intl.formatMessage({
              id: 'containers.settings.automation.automationData.table.noData',
            })}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default AutomationBlockers;
