import { Box, Slide } from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import {
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
} from '../../redux/modules/datapoints/selector';
import { getFooterSchema } from '../../redux/modules/schema/selectors';
import { readOnlySelector } from '../../redux/modules/ui/selectors';
import { State } from '../../types/state';
import { Aggregation } from './AggregationStack';

// Distance between bottom of aggregation chips and the top of the footer
const MINIMAL_SCROLL_DISTANCE = 40;

export const AggregationForCurrentColumn = ({
  scrollContainer,
}: {
  scrollContainer?: HTMLElement;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { columns } = useSelector(getFooterSchema);

  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    if (!scrollContainer) return undefined;

    const listener = (ev: Event) => {
      if (ev.target instanceof HTMLElement) {
        setScrolledDown(ev.target.scrollTop > MINIMAL_SCROLL_DISTANCE);
      }
    };

    scrollContainer.addEventListener('scroll', listener);
    return () => {
      scrollContainer.removeEventListener('scroll', listener);
    };
  }, [scrollContainer]);

  const currentDatapoint = useSelector(currentDatapointSelector);

  const aggregationMessage = useSelector((state: State) => {
    const multivalue = currentMultivalueDatapointSelector(state);
    if (!multivalue) return null;
    const aggregations = state.datapoints.aggregations[multivalue.id] ?? [];

    if (currentDatapoint?.category !== 'datapoint') return null;

    return aggregations.find(
      agg =>
        agg.aggregationType === 'sum' &&
        agg.schemaId === currentDatapoint.schemaId
    );
  });

  const schema = columns.find(
    schema => schema.id === currentDatapoint?.schemaId
  );

  const isReadOnly = useSelector(readOnlySelector);

  const visible = schema?.aggregations?.sum && scrolledDown && !isReadOnly;

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '100%',
        right: 0,
        zIndex: -2,
        pr: 3,
        pb: 1,
      }}
      ref={containerRef}
    >
      <SwitchTransition>
        <Slide
          direction="up"
          key={String(visible)}
          mountOnEnter
          unmountOnExit
          container={containerRef.current}
        >
          {visible ? (
            <Aggregation
              content={aggregationMessage?.content}
              schema={schema}
            />
          ) : (
            <span />
          )}
        </Slide>
      </SwitchTransition>
    </Box>
  );
};
