import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { contentSelectPayloadSchema } from './contentSelect.schema';

export type ContentSelectPayload = {
  rectangle: [number, number, number, number];
  page: string;
};

export const contentSelect = (
  annotationId: number,
  datapointId: number,
  payload: ContentSelectPayload
) => {
  return {
    endpoint: `/annotations/${annotationId}/content/${datapointId}/select`,
    method: 'POST',
    payload,
    payloadSchema: contentSelectPayloadSchema,
    responseSchema: z.unknown(),
  } as const;
};
