// Generated by ts-to-zod
import { z } from 'zod';

export const annotationStatusSchema = z.union([
  z.literal('created'),
  z.literal('importing'),
  z.literal('failed_import'),
  z.literal('split'),
  z.literal('to_review'),
  z.literal('reviewing'),
  z.literal('confirmed'),
  z.literal('rejected'),
  z.literal('exporting'),
  z.literal('exported'),
  z.literal('failed_export'),
  z.literal('postponed'),
  z.literal('deleted'),
  z.literal('purged'),
  z.literal('in_workflow'),
]);
