import { ElisRequestConfig } from '../../../utils/requestConfig';
import {
  scorePasswordPayloadSchema,
  scorePasswordResponseSchema,
} from './score.schema';

export type ScorePasswordPayload = {
  password: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export type ScorePasswordResponse = {
  score: 0 | 1 | 2 | 3 | 4;
  messages: string[];
};

export const scorePassword = (payload: ScorePasswordPayload) => {
  return {
    authorize: false,
    endpoint: '/auth/password/score',
    method: 'POST',
    payload,
    payloadSchema: scorePasswordPayloadSchema,
    responseSchema: scorePasswordResponseSchema,
  } as const;
};
