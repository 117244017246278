import {
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';

type ExcludeNullFromKey<T, Key extends keyof T> = {
  [K in keyof T]: K extends Key ? Exclude<T[K], null> : T[K];
};
export type SchemaFieldWithPathNonNullableField = ExcludeNullFromKey<
  SchemaFieldWithPath,
  'field'
>;

type FieldPathItem = {
  id: string;
  label: string;
  canBeParent: boolean;
  index: number;
};

export type SchemaFieldWithPath = {
  field: SchemaSection | SchemaItem | null;
  path: FieldPathItem[] | null;
};

export const getFlattenSchemaFields = (
  items: SchemaSection[] | SchemaItem[],
  parentPath: FieldPathItem[] = []
) => {
  return items.reduce<{
    [key: string]: SchemaFieldWithPathNonNullableField;
  }>((acc, item, index) => {
    const canBeParent =
      item.category === 'section' || isSchemaTableMultiValue(item);

    const path = [
      ...parentPath,
      {
        // for simple multivalues, we are using the child's id to identify it
        id: isSchemaSimpleMultiValue(item) ? item.children.id : item.id,
        label: item.label,
        canBeParent,
        index,
      },
    ];
    const schemaItem = {
      field: item,
      path,
    };

    const children: {
      [key: string]: SchemaFieldWithPathNonNullableField;
    } = canBeParent
      ? getFlattenSchemaFields(
          Array.isArray(item.children) ? item.children : item.children.children,
          path
        )
      : {};

    return {
      ...acc,
      // for simple multivalues, we are using the child's id to identify it
      ...(isSchemaSimpleMultiValue(item)
        ? { [item.children.id]: schemaItem }
        : { [item.id]: schemaItem }),
      ...children,
    };
  }, {});
};
