import { Button } from '@rossum/ui/material';
import StatisticsIcon from 'mdi-react/GoogleAnalyticsIcon';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';

type Props = {
  link: string;
};

const DisabledOverlay = ({ link }: Props) => (
  <div className={styles.DisabledOverlay}>
    <div className={styles.StatsIcon}>
      <StatisticsIcon />
    </div>
    <div className={styles.DisabledTitle}>
      <FormattedMessage id="containers.statistics.disabled.title" />
    </div>
    <div className={styles.DisabledText}>
      <FormattedMessage id="containers.statistics.disabled.text" />
    </div>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button variant="contained" size="small">
        <FormattedMessage id="containers.statistics.disabled.button" />
      </Button>
    </a>
  </div>
);

export default DisabledOverlay;
