import { CheckCircleOutline } from '@rossum/ui/icons';
import { Button, Grow, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

const SuccessfullySentMessage = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl();

  return (
    <Grow in appear unmountOnExit>
      <Stack spacing={2}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'containers.requestDemo.dialog.success.headline',
          })}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <CheckCircleOutline sx={{ color: 'success.main' }} />
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'containers.requestDemo.dialog.success.point',
            })}
          </Typography>
        </Stack>
        <Typography color="text.secondary">
          {intl.formatMessage({
            id: 'containers.requestDemo.dialog.success.text',
          })}
        </Typography>
        <Stack alignItems="center">
          <Button
            variant="contained"
            data-cy="request-demo-form-success-close"
            onClick={onClose}
            sx={{ mt: 4, alignSelf: 'center' }}
          >
            {intl.formatMessage({
              id: 'containers.requestDemo.dialog.closeButton',
            })}
          </Button>
        </Stack>
      </Stack>
    </Grow>
  );
};

export default SuccessfullySentMessage;
