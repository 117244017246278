import { Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const PADDING_TOP = 120;

type ContainerProps = {
  children: ReactNode;
  customTopOffset?: number;
  dataPageTitle?: string;
};

const Container = ({
  children,
  dataPageTitle,
  customTopOffset,
}: ContainerProps) => (
  <Stack
    data-page-title={dataPageTitle}
    sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme =>
        `${customTopOffset || PADDING_TOP}px ${theme.spacing(4)} 0`,
      backgroundColor: theme => theme.palette.background.default,
      '> *': {
        maxWidth: 330,
      },
    }}
  >
    {children}
  </Stack>
);

export default Container;
