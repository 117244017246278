import { Status } from '../../../types/annotationStatus';

const unResetableStatuses = [
  'exporting',
  'exported',
  'deleted',
  'exportFailed',
];

export const canResetOnMove = <T extends Array<{ status: Status }>>(
  annotations: T
) => !annotations.find(({ status }) => unResetableStatuses.includes(status));
