import { pipe } from 'fp-ts/function';
import * as EI from 'fp-ts/lib/Either';
import * as D from 'io-ts/lib/Decoder';
import * as E from 'io-ts/lib/Encoder';

// Just for clarity and potential changes in the future
interface IDBrand {
  readonly ID: unique symbol;
}

export type ID = number; // & IDBrand;

export const ID = pipe(
  D.number,
  D.refine((n): n is ID => !Number.isNaN(n) && Number.isSafeInteger(n), 'ID')
);

// Url helpers
// We had to disable matching by this regex because some internal "urls" are not actually
// valid URLs - thus loosening this to just 'string' for now
// Borrowed from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
// const urlRegex = new RegExp(
//   /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
// );

interface UrlBrand {
  readonly Url: unique symbol;
}

export type Url = string; // & UrlBrand;

export const Url = pipe(
  D.string,
  D.refine((s): s is Url => typeof s === 'string', 'Url')
);

// TODO: How do we go about encoding number to URL string?
// With this approach we are throwing part of the context (entity identifier) away
// so reconstructing URL back from ID is impossible without passing in more information
// e.g. UrlFromIDEncoder('schemas')
// Alternatively we could keep both and transform the URL into a tuple [URL, ID] which adds
// bloat but could save us some work with extracting ID when necessary
// Example at https://elis.rossum.ai/api/docs/#filters-and-ordering shows
// that to filter by workspace on a queue you pass just the ID but in create models
// you need the entire URL - this is an API inconsistency issue
export const IDFromUrl = pipe(
  Url,
  D.map(url => Number(url.split('/').pop())),
  D.refine((n): n is ID => !Number.isNaN(n) && Number.isSafeInteger(n), 'ID')
);

export const getIDFromUrl = (url: Url): ID => {
  const decoded = IDFromUrl.decode(url);

  if (EI.isRight(decoded)) {
    return decoded.right;
  }

  throw new Error(`Cannot decode ID from URL: ${url}.`);
};
