import { ActionType, createAction } from 'typesafe-actions';
import { Rectangle, ResizingEdge } from '../../../components/MagicGridV2/utils';
import { ID } from '../../../types/basic';
import {
  AnyDatapointData,
  ColumnPosition,
  Grid,
  MultivalueDatapointDataST,
} from '../../../types/datapoints';

export type UpdateColumnGridMeta = { datapointIndex: number; page: number };

type UpdateColumnGridAction = UpdateColumnGridMeta & {
  grid: Grid;
};

type BatchActionType = 'UPDATE_GRID_AFTER_HORIZONTAL_SEPARATOR_CREATED';

export type UpdateGridActionMeta = {
  gridIndex: number;
  datapointIndex: number;
  actionType?: BatchActionType;
};

export type UpdateGridRows = {
  createdIndexes: number[];
  deletedIndexes: number[];
  updatedIndexes: number[];
};

type UpdateGridAction = UpdateGridActionMeta & {
  grid: Grid;
  columns?: {
    clearedIds?: string[];
    updatedIds?: string[];
  };
  datapoints: {
    clearedIds?: number[];
    deletedTupleIds?: (number | null)[];
    updatedIds?: number[];
    updatedTupleIds?: (number | null)[];
  };
  rows?: Partial<UpdateGridRows>;
};

export const upgradeGridAfterVerticalSeparatorCreated = createAction(
  'UPDATE_GRID_AFTER_VERTICAL_SEPARATOR_CREATED',
  ({ grid }: UpdateColumnGridAction & { newPosition: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    newPosition,
  }: UpdateColumnGridAction & { newPosition: number }) => ({
    datapointIndex,
    page,
    newPosition,
  })
)();

export const updateGridAfterVerticalSeparatorMove = createAction(
  'UPDATE_GRID_AFTER_VERTICAL_SEPARATOR_MOVE',
  ({ grid }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    separatorIndex,
  }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    datapointIndex,
    page,
    separatorIndex,
  })
)();

export const updateGridAfterVerticalSeparatorDeleted = createAction(
  'UPDATE_GRID_AFTER_VERTICAL_SEPARATOR_DELETED',
  ({ grid }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    separatorIndex,
  }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    datapointIndex,
    page,
    separatorIndex,
  })
)();

export const updateGridAfterHorizontalSeparatorDeleted = createAction(
  'UPDATE_GRID_AFTER_HORIZONTAL_SEPARATOR_DELETED',
  ({ grid }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    separatorIndex,
  }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    datapointIndex,
    page,
    separatorIndex,
  })
)();

export const updateGridAfterHorizontalSeparatorCreated = createAction(
  'UPDATE_GRID_AFTER_HORIZONTAL_SEPARATOR_CREATED',
  ({ grid }: UpdateColumnGridAction & { newPosition: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    newPosition,
  }: UpdateColumnGridAction & { newPosition: number }) => ({
    datapointIndex,
    page,
    newPosition,
  })
)();

export const updateGridAfterHorizontalSeparatorMove = createAction(
  'UPDATE_GRID_AFTER_HORIZONTAL_SEPARATOR_MOVE',
  ({ grid }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    separatorIndex,
  }: UpdateColumnGridAction & { separatorIndex: number }) => ({
    datapointIndex,
    page,
    separatorIndex,
  })
)();

export const updateGridAfterSchemaIdAssigned = createAction(
  'UPDATE_GRID_AFTER_SCHEMA_ID_ASSIGNED',
  ({
    grid,
  }: UpdateColumnGridAction & {
    changes: {
      separatorIndex: number;
      prev: string | null;
      current: string | null;
    }[];
  }) => ({
    grid,
  }),
  ({
    datapointIndex,
    page,
    changes,
  }: UpdateColumnGridAction & {
    changes: {
      separatorIndex: number;
      prev: string | null;
      current: string | null;
    }[];
  }) => ({
    datapointIndex,
    page,
    changes,
  })
)();

export const updateGridAfterRowTypeChanged = createAction(
  'UPDATE_GRID_AFTER_ROW_TYPE_CHANGED',
  ({
    grid,
  }: UpdateColumnGridAction & {
    separatorIndex: number;
    rowType: string | null;
  }) => ({ grid }),
  ({
    datapointIndex,
    page,
    separatorIndex,
    rowType,
  }: UpdateColumnGridAction & {
    separatorIndex: number;
    rowType: string | null;
  }) => ({
    datapointIndex,
    page,
    separatorIndex,
    rowType,
  })
)();

export const updateGridAfterExtractAllRows = createAction(
  'UPDATE_GRID_AFTER_EXTRACT_ALL_ROWS',
  (currentDatapoint: MultivalueDatapointDataST) => ({ currentDatapoint })
)();

export const updateGridAfterResized = createAction(
  'UPDATE_GRID_AFTER_RESIZED',
  ({
    grid,
  }: UpdateColumnGridAction & {
    removedRowsIndexes: number[];
    removedColumnsIndexes: number[];
    resizingEdge: ResizingEdge;
  }) => ({ grid }),
  ({
    datapointIndex,
    page,
    removedRowsIndexes,
    removedColumnsIndexes,
    resizingEdge,
  }: UpdateColumnGridAction & {
    removedRowsIndexes: number[];
    removedColumnsIndexes: number[];
    resizingEdge: ResizingEdge;
  }) => ({
    datapointIndex,
    page,
    removedRowsIndexes,
    removedColumnsIndexes,
    resizingEdge,
  })
)();

export const updateGridAfterMoved = createAction(
  'UPDATE_GRID_AFTER_MOVED',
  ({ grid }: UpdateColumnGridAction) => ({ grid }),
  ({ datapointIndex, page }: UpdateColumnGridAction) => ({
    datapointIndex,
    page,
  })
)();

export const updateGridAfterMovedFulfilled = createAction(
  'UPDATE_GRID_AFTER_MOVED_FULFILLED',
  ({
    grid,
  }: {
    datapointIndex: number;
    gridIndex: number;
    grid: Grid;
    updatedDatapointIds: ID[];
  }) => ({
    grid,
  }),
  ({
    datapointIndex,
    gridIndex,
    updatedDatapointIds,
  }: {
    datapointIndex: number;
    gridIndex: number;
    grid: Grid;
    updatedDatapointIds: ID[];
  }) => ({
    datapointIndex,
    gridIndex,
    updatedDatapointIds,
  })
)();

export const createGrid = createAction(
  'CREATE_GRID',
  ({
    rectangle,
    rowType,
  }: {
    datapointIndex: number;
    page: number;
    rectangle: Rectangle;
    rowType: string | null;
  }) => ({ rectangle, rowType }),
  ({
    datapointIndex,
    page,
  }: {
    datapointIndex: number;
    page: number;
    rectangle: Rectangle;
    rowType: string | null;
  }) => ({ datapointIndex, page })
)();

export const createGridFulfilled = createAction(
  'CREATE_GRID_FULFILLED',
  ({ grid }: { datapointIndex: number; gridIndex: number; grid: Grid }) => ({
    grid,
  }),
  ({
    datapointIndex,
    gridIndex,
  }: {
    datapointIndex: number;
    gridIndex: number;
    grid: Grid;
  }) => ({ datapointIndex, gridIndex })
)();

export const deleteGrid = createAction(
  'DELETE_GRID',
  undefined,
  ({ datapointIndex, page }: { datapointIndex: number; page: number }) => ({
    datapointIndex,
    page,
  })
)();

export const deleteGridFulfilled = createAction(
  'DELETE_GRID_FULFILLED',
  undefined,
  ({
    datapointIndex,
    page,
    gridIndex,
  }: {
    datapointIndex: number;
    page: number;
    gridIndex: number;
  }) => ({
    datapointIndex,
    page,
    gridIndex,
  })
)();

export const deleteAllGrids = createAction(
  'DELETE_ALL_GRIDS',
  undefined,
  ({
    datapointIndex,
    gridCount,
  }: {
    datapointIndex: number;
    gridCount: number;
  }) => ({ datapointIndex, gridCount })
)();

export const updateGridAction = createAction(
  'UPDATE_GRID',
  ({ columns, rows, datapoints, grid }: UpdateGridAction) => ({
    grid,
    columns: { clearedIds: [], updatedIds: [], ...columns },
    rows: {
      createdIndexes: [],
      deletedIndexes: [],
      updatedIndexes: [],
      ...rows,
    },
    datapoints: {
      clearedIds: [],
      deletedTupleIds: [],
      updatedIds: [],
      updatedTupleIds: [],
      ...datapoints,
    },
  }),
  ({ gridIndex, datapointIndex, actionType }: UpdateGridAction) => ({
    gridIndex,
    datapointIndex,
    actionType,
  })
)();

export const updateGridFulfilled = createAction(
  'UPDATE_GRID_FULFILLED',
  ({
    datapoints,
  }: {
    datapoints: AnyDatapointData[];
    updatedDatapointIds: ID[];
    createdTupleIds?: ID[];
    gridIndex?: number;
    fullResponse?: boolean;
  }) => datapoints,
  ({
    updatedDatapointIds,
    createdTupleIds,
    gridIndex,
    fullResponse,
  }: {
    datapoints: AnyDatapointData[];
    // ids for validate action
    updatedDatapointIds: ID[];
    createdTupleIds?: ID[];
    gridIndex?: number;
    fullResponse?: boolean;
  }) => ({ updatedDatapointIds, createdTupleIds, gridIndex, fullResponse })
)();

export const updateGridAfterColumnsCleared = createAction(
  'UPDATE_GRID_AFTER_COLUMNS_CLEARED',
  ({ grid }: { grid: Grid; datapointIndex: number; page: number }) => ({
    grid,
  }),
  ({ datapointIndex, page }: { datapointIndex: number; page: number }) => ({
    datapointIndex,
    page,
  })
)();

export const updateGridAfterRowsCleared = createAction(
  'UPDATE_GRID_AFTER_ROWS_CLEARED',
  ({ grid }: { grid: Grid; datapointIndex: number; page: number }) => ({
    grid,
  }),
  ({ datapointIndex, page }: { datapointIndex: number; page: number }) => ({
    datapointIndex,
    page,
  })
)();

export const copyGrid = createAction('COPY_GRID', (payload: Grid) => payload)();

export const pasteGridToPage = createAction(
  'PASTE_GRID_TO_PAGE',
  undefined,
  ({ datapointIndex, page }: { datapointIndex: number; page: number }) => ({
    datapointIndex,
    page,
  })
)();

export const applyGridToNextPages = createAction(
  'APPLY_GRID_TO_NEXT_PAGES',
  undefined,
  ({
    datapointIndex,
    page,
    grids,
  }: {
    grids: Grid[];
    datapointIndex: number;
    page: number;
  }) => ({
    datapointIndex,
    page,
    grids,
  })
)();

export const pasteGrid = createAction(
  'PASTE_GRID',
  undefined,
  ({
    datapointIndex,
    page,
    existingGridIndex,
  }: {
    datapointIndex: number;
    page: number;
    existingGridIndex: number;
  }) => ({
    datapointIndex,
    page,
    existingGridIndex,
  })
)();

export const applyColumnsToAllGrids = createAction(
  'APPLY_COLUMNS_TO_ALL_GRIDS',
  ({
    columns,
    width,
  }: {
    columns: ColumnPosition[];
    width: number;
    datapointIndex: number;
    page: number;
  }) => ({ columns, width }),
  ({
    datapointIndex,
    page,
  }: {
    columns: ColumnPosition[];
    width: number;
    datapointIndex: number;
    page: number;
  }) => ({ datapointIndex, page })
)();

export type GridActions = ActionType<
  | typeof applyColumnsToAllGrids
  | typeof applyGridToNextPages
  | typeof copyGrid
  | typeof createGrid
  | typeof createGridFulfilled
  | typeof deleteAllGrids
  | typeof deleteGrid
  | typeof deleteGridFulfilled
  | typeof pasteGrid
  | typeof pasteGridToPage
  | typeof updateGridAction
  | typeof updateGridAfterColumnsCleared
  | typeof updateGridAfterExtractAllRows
  | typeof updateGridAfterHorizontalSeparatorCreated
  | typeof updateGridAfterHorizontalSeparatorDeleted
  | typeof updateGridAfterMoved
  | typeof updateGridAfterMovedFulfilled
  | typeof updateGridAfterResized
  | typeof updateGridAfterRowsCleared
  | typeof updateGridAfterSchemaIdAssigned
  | typeof updateGridAfterVerticalSeparatorDeleted
  | typeof updateGridAfterVerticalSeparatorMove
  | typeof updateGridFulfilled
  | typeof upgradeGridAfterVerticalSeparatorCreated
>;
