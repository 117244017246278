import { Stack, Typography } from '@rossum/ui/material';
import { Control, FieldValues, Path, useFormState } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SingleCheckboxControl from '../../ReactHookForm/controls/SingleCheckboxControl';

type Props<T extends FieldValues> = {
  control?: Control<T>;
  checkboxSchema: {
    label: string;
    name: Path<T>;
  }[];
  errorMessage: string;
};

const ActionDialogCheckboxes = <T extends FieldValues>({
  control,
  checkboxSchema,
  errorMessage,
}: Props<T>) => {
  const { errors } = useFormState({
    control,
  });

  const errorKeys = checkboxSchema.map(({ name }) => name);

  const hasErrors = errorKeys.some(key => errors[key] !== undefined);

  const intl = useIntl();

  return (
    <Stack spacing={1} sx={{ pt: 2.5, mb: 0 }}>
      {errorMessage && (
        <Typography
          variant="body2"
          color={hasErrors ? 'error' : 'text.secondary'}
        >
          {intl.formatMessage({
            id: 'components.actionDialogCheckboxes.text',
          })}
        </Typography>
      )}
      <Stack spacing={0} sx={{ pl: 2.5 }}>
        {checkboxSchema.map(({ label, name }) => (
          <SingleCheckboxControl
            ControllerProps={{ control, name }}
            FieldLabelProps={{ layout: 'none' }}
            label={label}
            key={name}
            data-cy={name}
            required
          />
        ))}
      </Stack>
      {hasErrors && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default ActionDialogCheckboxes;
