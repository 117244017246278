import { createSelector } from 'reselect';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';

// In a separate file to resolve circular dependency.
export const schemaMapSelector = createSelector(
  (state: State) => state.schema.content,
  (schemas): ReadonlyMap<string, AnyDatapointSchema> =>
    new Map(schemas?.map(schema => [schema.id, schema]) ?? [])
);
