// TODO: This is a re-implementation of ResizeHandle from MagicGridV2.
// It will use some of its utils/types but should be more general

import { forwardRef } from 'react';
import { useDraggableInteraction } from '../hooks/useDraggableInteraction';
import { Point2D } from '../utils/geometry';
import { ResizingDirection } from '../utils/resizing';
import { CornerHandle } from './CornerHandle';
import { EdgeHandle } from './EdgeHandle';

// The goal is for it to be usable for both grid (once it is SVG-compatible) and bounding boxes
type ResizeHandleProps = {
  direction: ResizingDirection;
  color?: string;
  strokeWidth: number;
  cornerHandleRadius: number;
  onResizeStart: (edge: ResizingDirection) => void;
  onResizeMove: (edge: ResizingDirection, diff: Point2D) => void;
  onResizeEnd: (edge: ResizingDirection) => void;
  onResizeCancel: (edge: ResizingDirection) => void;
};

export const ResizeHandle = forwardRef<SVGCircleElement, ResizeHandleProps>(
  (
    {
      direction,
      color,
      strokeWidth,
      cornerHandleRadius,
      onResizeStart,
      onResizeMove,
      onResizeEnd,
      onResizeCancel,
    }: ResizeHandleProps,
    ref
  ) => {
    const { handleMouseDown, isDragging } = useDraggableInteraction({
      onDragStart: () => onResizeStart(direction),
      onDragMove: diff => onResizeMove(direction, diff),
      onDragEnd: () => onResizeEnd(direction),
      onDragCancel: () => onResizeCancel(direction),
    });

    if (
      direction === 'left' ||
      direction === 'top' ||
      direction === 'bottom' ||
      direction === 'right'
    ) {
      return (
        <EdgeHandle
          strokeWidth={strokeWidth}
          isDragging={false}
          direction={direction}
          color={color}
          onMouseDownCapture={handleMouseDown}
        />
      );
    }

    return (
      <CornerHandle
        strokeWidth={strokeWidth}
        radius={cornerHandleRadius}
        ref={ref}
        isDragging={isDragging}
        direction={direction}
        color={color}
        onMouseDownCapture={handleMouseDown}
      />
    );
  }
);
