import { Close, Remove } from '@rossum/ui/icons';
import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ElementType, isValidElement, ReactNode } from 'react';
import DialogTitleIcon, { DialogIconColor } from './DialogTitleIcon';

type DialogTitleProps = {
  title: ReactNode | string;
  iconBackgroundColor?: DialogIconColor;
  Icon?: ElementType;
  onClose?: () => void;
  onToggleMinimize?: () => void;
  closeDataCy?: string;
};

const DialogTitle = ({
  onClose,
  onToggleMinimize,
  title,
  iconBackgroundColor,
  Icon,
  closeDataCy,
}: DialogTitleProps) => (
  <MuiDialogTitle
    component={Paper}
    elevation={8}
    borderRadius={0}
    boxShadow={0}
    sx={{
      py: 2,
      px: 3,
    }}
  >
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <DialogTitleIcon
          Icon={Icon}
          iconBackgroundColor={iconBackgroundColor}
        />
        <Typography
          component={isValidElement(title) ? 'div' : 'h5'}
          variant="h5"
        >
          {title}
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row">
        {onToggleMinimize && (
          <IconButton size="small" color="inherit" onClick={onToggleMinimize}>
            <Remove />
          </IconButton>
        )}
        {onClose && (
          <IconButton
            size="small"
            color="inherit"
            onClick={onClose}
            sx={{ justifySelf: 'flex-end' }}
            data-cy={closeDataCy}
          >
            <Close />
          </IconButton>
        )}
      </Stack>
    </Stack>
  </MuiDialogTitle>
);

export default DialogTitle;
