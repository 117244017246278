import { ActionType, createAction } from 'typesafe-actions';

export const setCurrentFooterColumn = createAction(
  'SET_CURRENT_FOOTER_COLUMN',
  (currentColumn: string, columnRightClientX = 0) => ({
    currentColumn,
    columnRightClientX,
  })
)<{ currentColumn: string; columnRightClientX: number }>();

type SetCurrentFooterColumn = typeof setCurrentFooterColumn;

export type SearchAndReplaceActions = ActionType<SetCurrentFooterColumn>;
