// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import {
  customEmailTemplateTypeSchema,
  emailTemplatePayloadBaseSchema,
} from '../models/emailTemplate.schema';

export const createEmailTemplatePayloadSchema = emailTemplatePayloadBaseSchema.and(
  z.object({
    type: customEmailTemplateTypeSchema,
    triggers: z.array(urlSchema).optional(),
  })
);
