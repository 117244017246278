import { endpoints } from '@rossum/api-client';
import { RequestDemoPayload } from '@rossum/api-client/internal';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';

export const useRequestDemo = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: RequestDemoPayload) =>
      api.request(endpoints.internal.requestDemo(payload)),

    onError: () => {
      dispatch(throwError('requestDemoFailed'));
    },
  });
};
