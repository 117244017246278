import { IntlProvider, ReactIntlErrorCode } from 'react-intl';

window.__missingLocalizationKeys = [];

/**
 * This function is intended to be called only from Chrome Developer Tools during development
 */
window.__printMissingLocalizations = () => {
  window.__missingLocalizationKeys.sort();

  const json = JSON.stringify(
    Object.fromEntries(window.__missingLocalizationKeys.map(k => [k, ''])),
    null,
    2
  );

  // Add trailing comma so that you can paste it in the beginning of the JSON file.
  // It wouldn't be added automatically.
  const jsonWithoutBraces = `${json.split('\n').slice(1, -1).join('\n')},`;

  // @ts-expect-error copy() function is available only in Dev Tools
  // eslint-disable-next-line no-undef
  copy(jsonWithoutBraces);

  // eslint-disable-next-line no-console
  console.log(
    `--------------------------------
${jsonWithoutBraces}
-----------------------------
%cMissing localizations has been copied into the clipboard.
%cPaste them into localization files:
  'src/i18n/en/en.json'
  'src/i18n/cs/cs.json'
-----------------------------`,
    'font-weight: bold; font-size: ',
    'font-weight: normal;'
  );
};

export const handleIntlProviderError: IntlProvider['props']['onError'] =
  err => {
    if (process.env.NODE_ENV === 'development') {
      if (
        err.code === ReactIntlErrorCode.MISSING_TRANSLATION &&
        typeof err.descriptor?.id === 'string'
      ) {
        const key = err.descriptor.id;

        if (!window.__missingLocalizationKeys.includes(key)) {
          window.__missingLocalizationKeys.push(key);
        }
      }
    }

    if (
      err.code === ReactIntlErrorCode.MISSING_TRANSLATION &&
      err.descriptor?.defaultMessage
    ) {
      // Do not show an error if default message is present.
    } else {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
