import clsx from 'clsx';
import DownArrowIcon from 'mdi-react/ChevronDownIcon';
import UpArrowIcon from 'mdi-react/ChevronUpIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'remeda';
import {
  displaySearchPanel,
  getSearchResults,
  nextSearchResult,
  previousSearchResult,
} from '../../redux/modules/search/actions';
import { State } from '../../types/state';
import styles from './styles.module.sass';

const SearchPanel = () => {
  const searchInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const amountOfResults = useSelector(
    (state: State) => state.search.results.length
  );
  const currrentResult = useSelector((state: State) =>
    state.search.results.length ? state.search.currentResultIndex + 1 : 0
  );

  const debouncer = R.debounce(
    phrase => {
      dispatch(getSearchResults(phrase ?? ''));
    },
    { waitMs: 300 }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncer.call(e.target.value);
  };

  return (
    <div className={clsx(styles.SearchPanel, 'search-panel')}>
      <input
        autoFocus
        ref={searchInput}
        data-cy="search-input"
        onChange={handleInputChange}
        className={styles.SearchInput}
      />
      <div className={styles.Results}>
        {`${currrentResult}/${amountOfResults}`}
      </div>
      <div onClick={() => dispatch(previousSearchResult())}>
        <UpArrowIcon className={styles.FindButton} />
      </div>
      <div onClick={() => dispatch(nextSearchResult())}>
        <DownArrowIcon className={styles.FindButton} />
      </div>
      <div onClick={() => dispatch(displaySearchPanel(false))}>
        <CloseIcon className={styles.FindButton} />
      </div>
    </div>
  );
};

export default SearchPanel;
