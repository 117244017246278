import { Delete, FolderZip, SubdirectoryArrowRight } from '@rossum/ui/icons';
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@rossum/ui/material';
import TranslatedTooltip from '../../../../components/UI/Tooltip/TranslatedTooltip';
import { Document } from '../../../../types/document';
import DocumentFileName from '../../../../ui/filename/DocumentFileName';
import StatusChip from '../../../../ui/status/StatusChip';
import styles from './styles.module.sass';

type Props = {
  attachment: Document;
  isChild: boolean;
};

const DocumentAttachment = ({ attachment, isChild }: Props) => {
  return (
    <ListItem
      sx={{
        pl: 2,
        py: 0.5,
        borderRadius: 1,
      }}
    >
      {isChild && (
        <SubdirectoryArrowRight
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      {attachment.attachmentStatus === 'extracted_archive' ? (
        <ListItemIcon sx={{ minWidth: 24 }} data-cy="document-zip">
          <FolderZip
            sx={{
              width: 20,
              height: 20,
              color: theme => theme.palette.text.secondary,
            }}
          />
        </ListItemIcon>
      ) : (
        <>
          <ListItemIcon sx={{ minWidth: 25, mr: 1 }}>
            <StatusChip
              maxWidth={200}
              status="automaticallyDeleted"
              isOwned={false}
            />
          </ListItemIcon>
          {attachment.attachmentStatus !== 'filtered_by_insecure_mime_type' && (
            <ListItemIcon sx={{ minWidth: 25 }} data-cy="automatically-deleted">
              <TranslatedTooltip
                translationId="components.documentAttachment.automaticallyDeletedTooltip"
                innerPopperClassName={styles.AlwaysOnTopTooltip}
              >
                <Avatar sx={{ width: 18, height: 18 }}>
                  <Delete sx={{ width: 14, height: 14 }} />
                </Avatar>
              </TranslatedTooltip>
            </ListItemIcon>
          )}
        </>
      )}
      <ListItemText primaryTypographyProps={{ color: 'text.secondary' }}>
        <DocumentFileName originalFileName={attachment.originalFileName} />
      </ListItemText>
    </ListItem>
  );
};

export default DocumentAttachment;
