import { ThemeOptions } from '@mui/material';

// https://www.figma.com/file/AaBwZVqcfaoe476bCiLqmJ/Rossum-Design-System-%E2%80%94-Dark?node-id=6785%3A42814
export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#336EC9',
    },
    secondary: {
      main: '#9ECEF7',
    },
    success: {
      main: '#66BB6A',
    },
    info: {
      main: '#29B6F6',
    },
    warning: {
      main: '#FFA726',
    },
    error: {
      main: '#F44336',
    },
    divider: `rgba(255, 255, 255, 0.12)`,
    // TODO: Finally define surfaces behavior
    // there should be consistency between these two colors!
    background: {
      default: '#151519',
      // trial and error to roughly match elevations to our current shades
      // would be nice to also customise shadows in theme 'shadows' property
      paper: '#24252B',
    },
    // TODO: We should probably get rid of this and instead enforce `Menu` to be systematic
    // is connected to the above
    // action: {
    //   hover: 'rgba(255, 255, 255, 0.2)',
    //   hoverOpacity: 0.2,
    // },
  },
};
