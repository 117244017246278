import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { AutomationBlocker } from '../models/automationBlocker';
import { automationBlockerSchema } from '../models/automationBlocker.schema';

export const get = (automationBlockerId: ID) => {
  return {
    endpoint: `/automation_blockers/${automationBlockerId}`,
    method: 'GET',
    responseSchema: automationBlockerSchema,
  } as const;
};
