import { ElisRequestConfig } from '../../utils/requestConfig';
import { AnnotationStart, annotationStartSchema } from '../models';
import { startAnnotationPayloadSchema } from './start.schema';

export type StartAnnotationPayload = {
  statuses?: Array<string>;
};

export const start = (
  annotationId: number,
  payload: StartAnnotationPayload = {}
) => {
  return {
    endpoint: `/annotations/${annotationId}/start`,
    method: 'POST',
    payloadSchema: startAnnotationPayloadSchema,
    payload,
    responseSchema: annotationStartSchema,
  } as const;
};
