// Generated by ts-to-zod
import { z } from 'zod';
import { datapointFieldPatchModelSchema } from './datapointField.schema';
import { datapointFieldSchema } from './transformedDatapointField.schema';

export const multivalueFieldTypeSchema = z.union([
  z.literal('grid'),
  z.literal('freeform'),
]);

export const tupleFieldSchema = z.object({
  category: z.literal('tuple'),
  children: z.array(datapointFieldSchema),
});

export const tupleFieldPatchModelSchema = z.object({
  category: z.literal('tuple'),
  children: z.array(datapointFieldPatchModelSchema),
});

export const baseTableMultivalueFieldSchema = z.object({
  category: z.literal('multivalue'),
  engineOutputId: z.string(),
  type: multivalueFieldTypeSchema,
  label: z.string(),
  trained: z.boolean(),
  description: z.string(),
  children: tupleFieldSchema,
});

export const tableMultivalueFieldPatchModelSchema = z
  .object({
    category: z.literal('multivalue'),
  })
  .and(
    z
      .object({
        engineOutputId: z.string(),
        type: multivalueFieldTypeSchema,
        label: z.string(),
        description: z.string(),
        children: tupleFieldPatchModelSchema,
      })
      .partial()
  );
