import { ReactNode } from 'react';
import { map } from 'rxjs/operators';
import {
  PageSpaceContext,
  PageSpaceContextValue,
} from '../../../../components/DocumentPage/PageSpaceContext';
import { useObservableContext } from '../../../../components/ObservableProvider';
import { Point2D } from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { safeInverse } from '../../document-store/helpers';
import { PageRectangle } from '../useCanvasDimensions';

type LineItemsPageSpaceProviderProps = {
  children: ReactNode;
  visiblePage: PageRectangle;
};

export const LineItemsPageSpaceProvider = ({
  children,
  visiblePage,
}: LineItemsPageSpaceProviderProps) => {
  const scaleFactor = useDocumentStore(state => state.canvasState.zoomLevel);
  const { onDocumentMouseMoveObserver } = useObservableContext();

  // These callbacks are not reactive, their ref is always the same.
  const getViewportStateMatrix = useDocumentStore(
    state => state.getViewportStateMatrix
  );

  const getCanvasStateMatrix = useDocumentStore(
    state => state.getCanvasStateMatrix
  );

  // In line items coordinates (inside viewport, not scaled by zoom)
  const pointViewportToSvg = (point: Point2D): Point2D => {
    const viewportState = getViewportStateMatrix();
    const canvasState = getCanvasStateMatrix();
    const newPoint = DOMPoint.fromPoint(point).matrixTransform(
      safeInverse(viewportState)
    );
    return {
      x: (newPoint.x - canvasState.e) / canvasState.a,
      y: (newPoint.y - canvasState.f) / canvasState.a,
    };
  };

  // In canvas coordinates (inside viewport, scaled by zoom)
  const mousePositionInViewportObservable = onDocumentMouseMoveObserver.pipe(
    map((e: MouseEvent) => {
      const point = pointViewportToSvg(e);
      return [
        (point.x - visiblePage.dimensions.x) * scaleFactor,
        (point.y - visiblePage.dimensions.y) * scaleFactor,
      ];
    })
  );

  return (
    <PageSpaceContext.Provider
      key={visiblePage.pageNumber}
      value={
        {
          pointViewportToSvg,
          pageWidth: visiblePage.dimensions.width,
          pageHeight: visiblePage.dimensions.height,
          mousePositionOnPageObservable: mousePositionInViewportObservable,
          // TODO: SVG-mission: this is not type-safe, MagicGrid only uses
          // subset of the PageSpaceContext values in the SVG canvas (those above)
          // but this type cast allows you to import any of the context values
          // in the future
        } as unknown as PageSpaceContextValue
      }
    >
      {children}
    </PageSpaceContext.Provider>
  );
};
