import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { BillingStatsPayload } from './getStats';
import { billingStatsPayloadSchema } from './getStats.schema';

export const getStatsExport =
  (endpoint: 'organizations' | 'organization_groups') =>
  (id: ID, payload: BillingStatsPayload) => {
    return {
      endpoint: `/${endpoint}/${id}/billing_stats/export`,
      method: 'POST',
      responseType: 'blob',
      payloadSchema: billingStatsPayloadSchema,
      payload,
    } as const;
  };
