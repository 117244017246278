import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
} from '../../features/annotation-view/document-canvas/utils/geometry';
import { safeInverse } from '../../features/annotation-view/document-store/helpers';
import { Vector } from '../../lib/spaceConvertor';
import { useObservableContext } from '../ObservableProvider';

export type PageSpaceContextValue = {
  pageWidth: number;
  pageHeight: number;
  mousePositionOnPageObservable: Observable<Vector>;
  overlayRef: React.MutableRefObject<SVGSVGElement | null>;
  pointViewportToSvg: (point: Point2D) => Point2D;
  pointSvgToViewport: (point: Point2D) => Point2D;
};

export const PageSpaceContext = createContext<
  PageSpaceContextValue | undefined
>(undefined);

export const PageSpaceProviderSVG = ({
  canvasDimensions,
  children,
  pageRef,
}: {
  canvasDimensions: Rectangle2D;
  children: ReactNode;
  pageRef: React.MutableRefObject<SVGSVGElement | null>;
}) => {
  const { onDocumentMouseMoveObserver } = useObservableContext();
  const overlayRef = useRef<SVGSVGElement | null>(null);

  // TODO: This is not optimal from perf perspective. We can use zustand store.
  const getScreenCTMOverlay = (page: SVGSVGElement | null): DOMMatrix =>
    page?.getScreenCTM() ?? IDENTITY_MATRIX_2D;

  const pointViewportToSvg = useCallback(
    (point: Point2D): Point2D =>
      DOMPoint.fromPoint(point).matrixTransform(
        safeInverse(getScreenCTMOverlay(pageRef.current))
      ),
    [pageRef]
  );

  const pointSvgToViewport = useCallback(
    (point: Point2D) =>
      DOMPoint.fromPoint(point).matrixTransform(
        getScreenCTMOverlay(pageRef.current)
      ),
    [pageRef]
  );

  const mousePositionOnPageObservable = useMemo(
    () =>
      onDocumentMouseMoveObserver.pipe(
        map((e: MouseEvent) =>
          overlayRef.current
            ? ([
                e.clientX - overlayRef.current.getBoundingClientRect().left,
                e.clientY - overlayRef.current.getBoundingClientRect().top,
              ] as Vector)
            : ([0, 0] as Vector)
        )
      ),
    [onDocumentMouseMoveObserver]
  );

  const contextValue: PageSpaceContextValue = useMemo(
    () => ({
      pageWidth: canvasDimensions.width,
      pageHeight: canvasDimensions.height,
      mousePositionOnPageObservable,
      overlayRef,
      pointViewportToSvg,
      pointSvgToViewport,
    }),
    [
      canvasDimensions.width,
      canvasDimensions.height,
      mousePositionOnPageObservable,
      pointViewportToSvg,
      pointSvgToViewport,
    ]
  );

  return (
    <PageSpaceContext.Provider value={contextValue}>
      {children}
    </PageSpaceContext.Provider>
  );
};

export const usePageSpaceContext = () => {
  const ctx = useContext(PageSpaceContext);

  if (ctx === undefined) {
    throw new Error(
      '`usePageSpaceContext` must be used within a PageSpaceContext provider'
    );
  }

  return ctx;
};
