import { ErrorObject } from 'ajv';
import jsonMap from 'json-source-map';

// helpers for detecting error position taken from jsoneditor textmode
// https://github.com/josdejong/jsoneditor/blob/develop/src/js/textmode.js
const improveSchemaError = (
  error: ErrorObject<string, Record<string, unknown>, unknown> & {
    type: string;
  }
) => {
  if (error.keyword === 'enum' && Array.isArray(error.schema)) {
    let enums = error.schema;
    if (enums) {
      enums = enums.map(value => JSON.stringify(value));

      if (enums.length > 5) {
        const more = [`(${enums.length - 5} more...)`];
        enums = enums.slice(0, 5);
        enums.push(more);
      }
      // eslint-disable-next-line no-param-reassign
      error.message = `should be equal to one of: ${enums.join(', ')}`;
    }
  }

  if (error.keyword === 'additionalProperties') {
    // eslint-disable-next-line no-param-reassign
    error.message = `should NOT have additional property: ${error.params.additionalProperty}`;
  }

  return error;
};

type PathWithLocation = {
  path: string;
  line: number;
  column: number;
};

type JSONMap = {
  pointers: {
    [key: string]: {
      [key: string]: { value: string; line: number; column: number };
    };
  };
};

const getLocationsForPaths = (
  text: string,
  paths: Array<string>
): Array<PathWithLocation> => {
  try {
    if (!paths || !paths.length) {
      return [];
    }
    const jsmap: JSONMap = jsonMap.parse(text);

    return paths.reduce<Array<PathWithLocation>>((acc, path) => {
      const pointer = jsmap.pointers[path];

      return pointer
        ? [
            ...acc,
            {
              path,
              line: pointer.key
                ? pointer.key.line
                : pointer.value
                  ? pointer.value.line
                  : 0,
              column: pointer.key
                ? pointer.key.column
                : pointer.value
                  ? pointer.value.column
                  : 0,
            },
          ]
        : acc;
    }, []);
  } catch {
    return [];
  }
};

export default {
  getLocationsForPaths,
  improveSchemaError,
};
