import { endpoints, ID } from '@rossum/api-client';
import { EnginePatchPayload } from '@rossum/api-client/engines';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const patchEngineKey = (id: ID) =>
  mutationKey(['patch_engine', id] as const);

export const usePatchEngine = (id: ID) => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: patchEngineKey(id),
    mutationFn: (payload: EnginePatchPayload) =>
      api.request(endpoints.engines.patch(id, payload)),
    onSuccess: result => {
      showToastMessage('engineUpdated');
      queryClient.setQueryData(engineKeys.detail(id), result);
    },
  });
};
