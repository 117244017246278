import { LocationDescriptor, LocationState, Path } from 'history';
import { mapValues, omit, pick } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { parse, stringify } from '../../lib/url';
import { StorageKey } from '../../redux/modules/localStorage/actions';

const getArrayFromValue = (
  value: null | undefined | string | string[]
): string[] | undefined =>
  value ? (Array.isArray(value) ? value : [value]) : undefined;

export const getStringFromValue = (
  value: null | undefined | string | string[]
): string | undefined =>
  value ? (Array.isArray(value) ? value[0] : value) : undefined;

type UseStoredStateProps<
  T extends Partial<Record<K1, string> & Record<K2, string[]>>,
  K1 extends string,
  K2 extends string,
> = {
  name: StorageKey;
  defaultValues: T;
  stringKeys?: K1[];
  arrayKeys?: K2[];
  historyFn?: (
    location: Path | LocationDescriptor<LocationState>,
    state?: LocationState
  ) => void;
  parseFn?: typeof parse;
  omitFromLocalStorage?: K1[];
};

export const useStoredState = <
  T extends Partial<Record<K1, string> & Record<K2, string[]>>,
  K1 extends string = never,
  K2 extends string = never,
>({
  name,
  defaultValues,
  arrayKeys = [],
  stringKeys = [],
  historyFn,
  parseFn = parse,
  omitFromLocalStorage,
}: UseStoredStateProps<T, K1, K2>) => {
  const searchQueryData = useLocation().search;

  const [internalState, setInternalState] = useState<
    Partial<Record<K1, string> & Record<K2, string[]>>
  >(() => {
    const localStorageData = localStorage.getItem(name);
    const savedState = parseFn(searchQueryData || localStorageData || '');
    const strings = mapValues(pick(savedState, stringKeys), getStringFromValue);
    const arrays = mapValues(pick(savedState, arrayKeys), getArrayFromValue);
    const savedValues = { ...arrays, ...strings };

    return {
      ...defaultValues,
      ...savedValues,
    };
  });

  const persistState = useCallback(
    (state: Record<string, string | string[] | undefined>) => {
      const locationQuery = stringify(state);
      const localStorageQuery = stringify(
        omit(state, omitFromLocalStorage ?? [])
      );
      localStorage.setItem(name, localStorageQuery);
      if (historyFn) historyFn({ search: locationQuery });
    },
    [omitFromLocalStorage, name, historyFn]
  );

  useEffect(() => persistState(internalState), [internalState, persistState]);

  return [internalState, setInternalState] as const;
};
