import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Organization } from '../models/organization';
import { organizationSchema } from '../models/organization.schema';

export const get = (organizationId: ID) => {
  return {
    endpoint: `/organizations/${organizationId}`,
    method: 'GET',
    responseSchema: organizationSchema,
  } as const;
};
