import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@rossum/ui/material';
import { Fragment } from 'react';
import { FlatContent } from './hooks/flattenContent';
import { FlatSchema } from './hooks/flattenSchema';

type HeaderFieldsProps = {
  datapoints: FlatContent[];
  schemaMap: Record<string, FlatSchema>;
  activeDatapointId: number | undefined;
  setActiveDatapointId: (datapoint: number | undefined) => void;
};

export const HeaderFields = ({
  datapoints,
  schemaMap,
  activeDatapointId,
  setActiveDatapointId,
}: HeaderFieldsProps) => (
  <List dense sx={{ py: 0 }}>
    {datapoints
      .filter(dp => dp.type === 'section')
      .filter(section => schemaMap[section.schemaId]?.hidden !== true)
      .map(section => (
        <Fragment key={section.id}>
          <ListSubheader disableSticky sx={{ textTransform: 'uppercase' }}>
            {schemaMap[section.schemaId]?.label}
          </ListSubheader>
          {datapoints
            .filter(dp => dp.parentId === section.id)
            .filter(
              dp =>
                schemaMap[dp.schemaId] &&
                schemaMap[dp.schemaId]?.hidden !== true
            )
            .map(dp => (
              <ListItem
                key={dp.id}
                id={
                  activeDatapointId === dp.id ? 'magic-line-source' : undefined
                }
                sx={
                  activeDatapointId === dp.id
                    ? {
                        background: theme => theme.palette.grey[800],
                        borderLeft: 3,
                        borderLeftColor: theme => theme.palette.primary.main,
                        position: 'sticky',
                        bottom: 0,
                        top: 0,
                        zIndex: 2,
                        '& ::selection': {
                          background: theme =>
                            `${theme.palette.primary.dark} !important`,
                        },
                      }
                    : undefined
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="caption"
                      color={
                        activeDatapointId === dp.id
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {schemaMap[dp.schemaId]?.label}
                    </Typography>
                  }
                  secondary={
                    dp.type === 'datapoint' ? (
                      <Input
                        onFocus={() => setActiveDatapointId(dp.id)}
                        sx={{ width: '100%' }}
                        disableUnderline
                        value={dp.content?.value ?? ''}
                      />
                    ) : (
                      <Button onFocus={() => setActiveDatapointId(dp.id)}>
                        Open line items
                      </Button>
                    )
                  }
                />
              </ListItem>
            ))}
        </Fragment>
      ))}
  </List>
);
