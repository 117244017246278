// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const listSchema = z.object({
  pagination: z.object({
    total: z.number(),
    totalPages: z.number(),
    next: z.number().nullable(),
    previous: z.number().nullable(),
  }),
  results: z.tuple([
    z.object({
      id: z.number(),
      name: z.string(),
      url: urlSchema,
      queues: z.array(urlSchema),
      metadata: z.unknown(),
    }),
  ]),
});
