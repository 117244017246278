import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Document } from '../models/document';
import { documentSchema } from '../models/document.schema';

export const get = (documentId: ID) => {
  return {
    endpoint: `/documents/${documentId}`,
    method: 'GET',
    responseSchema: documentSchema,
  } as const;
};
