import update from 'immutability-helper';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { Search } from '../../../types/search';
import { RootActionType } from '../../rootActions';
import {
  displaySearchPanel,
  getSearchResultsFulfilled,
  nextSearchResult,
  previousSearchResult,
} from './actions';

const initialState: Search = {
  shouldShow: false,
  results: [],
  currentResultIndex: 0,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(nextSearchResult):
      return update(state, {
        currentResultIndex: index =>
          state.results[index + 1] ? index + 1 : state.results[0] ? 0 : -1,
      });

    case getType(previousSearchResult):
      return update(state, {
        currentResultIndex: index =>
          state.results[index - 1]
            ? index - 1
            : state.results[state.results.length - 1]
              ? state.results.length - 1
              : -1,
      });

    case getType(getSearchResultsFulfilled):
      return update(state, {
        currentResultIndex: { $set: 0 },
        results: { $set: action.payload },
      });

    case getType(displaySearchPanel):
      return update(initialState, {
        shouldShow: { $set: action.payload },
      });

    default:
      return state;
  }
};

export default reducer;
