import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../../../../../lib/apiClient';
import { DocumentUpload } from '../../../../../types/documents';

type UploadDocumentsPayload = Array<DocumentUpload<'pending'> & { file: File }>;

type OnSuccess = (signature: string) => void;
type OnError = (signature: string, error: unknown) => void;

type UseUploadDocumentProps = {
  queueId: number;
  payload: UploadDocumentsPayload;
  onEverySuccess?: OnSuccess;
  onEveryError?: OnError;
};

type RequestParams = {
  onSuccess?: OnSuccess;
  onError?: OnError;
  queueId: number | null;
};

// Logical flow implemented in this hook is as follows:
// 1. When the uploadQueue and queueId are defined the useEffect runs mutations
// 2. The useEffect runs in a synchronous loop until pendingCount reaches its limit. In most cases max 20 (MAX_CONCURRENT_UPLOADS) requests are made almost simultaneously.
// 3. onSuccess (or onError) of each request pendingCount is decreased
// 4. cancelUpload will only work if requests are more than MAX_CONCURRENT_UPLOADS

const MAX_CONCURRENT_UPLOADS = 20;

const resolveUploadTaskStatus = (url: string, signal: AbortSignal) => {
  let timeoutId: NodeJS.Timeout;

  return new Promise((resolve, reject) => {
    const abortHandler = () => {
      clearTimeout(timeoutId);
      reject(new Error('Upload task status resolution aborted'));
      signal.removeEventListener('abort', abortHandler);
    };

    signal.addEventListener('abort', abortHandler);

    const pollTask = async () => {
      try {
        const task = await api.request(
          endpoints.tasks.get(
            getIDFromUrl(url),
            {
              noRedirect: true,
            },
            signal
          )
        );

        if (task.status === 'succeeded') {
          signal.removeEventListener('abort', abortHandler);
          resolve(task);
          return;
        }

        if (task.status === 'failed') {
          signal.removeEventListener('abort', abortHandler);
          reject(task);
          return;
        }

        timeoutId = setTimeout(pollTask, 1000);
      } catch (error) {
        signal.removeEventListener('abort', abortHandler);
        reject(error);
      }
    };

    timeoutId = setTimeout(pollTask, 300);
  });
};

export const useUploadDocumentsOld = () => {
  const [uploadQueue, setUploadQueue] = useState<UploadDocumentsPayload>([]);
  const [pendingCount, setPendingCount] = useState<number>(0);

  const [requestParams, setRequestParams] = useState<RequestParams>({
    queueId: null,
  });

  const abortControllers = useRef<Map<string, AbortController>>(new Map());

  const { mutate } = useMutation({
    mutationFn: ({
      queueId,
      payload,
    }: {
      payload: UploadDocumentsPayload[number];
      queueId: number;
    }) => {
      const { file, name, signature } = payload;
      const data = new FormData();
      data.append('content', file);

      const controller = new AbortController();

      abortControllers.current.set(signature, controller);

      return api
        .request(endpoints.uploads.post(queueId, data, name, controller.signal))
        .then(response =>
          resolveUploadTaskStatus(response.url, controller.signal)
        )
        .then(() => requestParams.onSuccess?.(signature))
        .catch(error => {
          return requestParams.onError?.(
            signature,
            error?.response?.content?.[0]
          );
        })
        .finally(() => {
          abortControllers.current.delete(signature);
        });
    },
  });

  const decreasePendingCount = useCallback(() => {
    setPendingCount(prev => Math.max(0, prev - 1));
  }, []);

  // this useEffect should trigger MAX_CONCURRENT_UPLOADS * mutations simultaneously.
  // It should stop triggering requests if we have more than MAX_CONCURRENT_UPLOADS, and enable the user to cancel
  useEffect(() => {
    const nextUpload = uploadQueue[0];
    if (
      pendingCount < MAX_CONCURRENT_UPLOADS &&
      nextUpload &&
      requestParams.queueId
    ) {
      setPendingCount(prev => Math.min(uploadQueue.length, prev + 1));

      setUploadQueue(uploadQueue.slice(1));

      mutate(
        { queueId: requestParams.queueId, payload: nextUpload },
        {
          onSuccess: decreasePendingCount,
          onError: decreasePendingCount,
        }
      );
    }
  }, [
    uploadQueue,
    pendingCount,
    requestParams.queueId,
    mutate,
    decreasePendingCount,
  ]);

  const uploadDocuments = useCallback(
    ({
      queueId,
      payload,
      onEverySuccess,
      onEveryError,
    }: UseUploadDocumentProps) => {
      setRequestParams({
        queueId,
        onError: onEveryError,
        onSuccess: onEverySuccess,
      });
      setUploadQueue(payload);
    },
    []
  );

  const cancelUpload = useCallback(() => {
    setUploadQueue([]);
    setRequestParams({ queueId: null });
    setPendingCount(0);
    abortControllers.current.forEach(controller => controller.abort());
    abortControllers.current.clear();
  }, []);

  return {
    uploadDocuments,
    isLoading: !!pendingCount,
    cancelUpload,
  };
};
