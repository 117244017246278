import { Components, Theme } from '../../material';

// source of the colors: https://www.figma.com/design/TueobED3R985BWFoT6mwiP/Helene-%7C-Styles?m=auto&node-id=57-4480&t=ELJnyUTSIGNAcSol-1
const backgroundImageColors = {
  dark: {
    0: 'none',
    2: 'rgba(255, 255, 255, 0.02)',
    4: 'rgba(255, 255, 255, 0.04)',
    6: 'rgba(255, 255, 255, 0.06)',
    8: 'rgba(255, 255, 255, 0.10)',
    12: 'rgba(255, 255, 255, 0.14)',
    24: 'rgba(255, 255, 255, 0.16)',
  },
  light: {
    0: 'none',
    2: 'rgba(0, 0, 0, 0.01)',
    4: 'rgba(0, 0, 0, 0.02)',
    6: 'rgba(0, 0, 0, 0.03)',
    8: 'rgba(0, 0, 0, 0.04)',
    12: 'rgba(0, 0, 0, 0.05)',
    24: 'rgba(0, 0, 0, 0.06)',
  },
};
// source of the elevations: https://www.figma.com/design/TueobED3R985BWFoT6mwiP/Helene-%7C-Styles?m=auto&node-id=1-231&t=ZcCvByVsqD91L7my-1
const elevations = {
  dark: {
    0: ['none'],
    2: [
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
      '0px 2px 2px 0px rgba(0, 0, 0, 0.14)',
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    ],
    4: [
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
      '0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
      '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    ],
    6: [
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
      '0px 6px 10px 0px rgba(0, 0, 0, 0.14)',
      '0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    ],
    8: [
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
      '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    ],
    12: [
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
      '0px 12px 17px 2px rgba(0, 0, 0, 0.14)',
      '0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    ],
    24: [
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
      '0px 24px 38px 3px rgba(0, 0, 0, 0.14)',
      '0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    ],
  },
  light: {
    0: ['none'],
    2: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 10px 0px rgba(0, 0, 0, 0.04)',
    ],
    4: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 10px 0px rgba(0, 0, 0, 0.04)',
    ],
    6: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 10px 0px rgba(0, 0, 0, 0.04)',
    ],
    8: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 24px -8px rgba(0, 0, 0, 0.04)',
    ],
    12: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 24px -8px rgba(0, 0, 0, 0.04)',
    ],
    24: [
      '0px 0px 0px 1px rgba(238, 238, 238, 1) inset',
      '0px 0px 24px -8px rgba(0, 0, 0, 0.04)',
    ],
  },
};

const getElevationStyles = (
  elevation: 0 | 2 | 4 | 6 | 8 | 12 | 24,
  mode: Theme['palette']['mode']
) => {
  const color = backgroundImageColors[mode][elevation];

  return {
    boxShadow: elevations[mode][elevation].join(', '),
    backgroundImage:
      color === 'none' ? color : `linear-gradient(${color}, ${color})`,
  };
};

export const muiPaper = (theme: Theme): Components['MuiPaper'] => ({
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(!ownerState?.square && {
        borderRadius: 2 * (theme.shape.borderRadius as number),
      }),
    }),
    elevation0: getElevationStyles(0, theme.palette.mode),
    elevation1: getElevationStyles(0, theme.palette.mode),
    elevation2: getElevationStyles(2, theme.palette.mode),
    elevation3: getElevationStyles(2, theme.palette.mode),
    elevation4: getElevationStyles(4, theme.palette.mode),
    elevation5: getElevationStyles(4, theme.palette.mode),
    elevation6: getElevationStyles(6, theme.palette.mode),
    elevation7: getElevationStyles(6, theme.palette.mode),
    elevation8: getElevationStyles(8, theme.palette.mode),
    elevation9: getElevationStyles(8, theme.palette.mode),
    elevation10: getElevationStyles(8, theme.palette.mode),
    elevation11: getElevationStyles(8, theme.palette.mode),
    elevation12: getElevationStyles(12, theme.palette.mode),
    elevation13: getElevationStyles(12, theme.palette.mode),
    elevation14: getElevationStyles(12, theme.palette.mode),
    elevation15: getElevationStyles(12, theme.palette.mode),
    elevation16: getElevationStyles(12, theme.palette.mode),
    elevation17: getElevationStyles(12, theme.palette.mode),
    elevation18: getElevationStyles(12, theme.palette.mode),
    elevation19: getElevationStyles(12, theme.palette.mode),
    elevation20: getElevationStyles(12, theme.palette.mode),
    elevation21: getElevationStyles(12, theme.palette.mode),
    elevation22: getElevationStyles(12, theme.palette.mode),
    elevation23: getElevationStyles(12, theme.palette.mode),
    elevation24: getElevationStyles(24, theme.palette.mode),
  },
});
