import CodeTagsIcon from 'mdi-react/CodeTagsIcon';
import WebhookIcon from 'mdi-react/WebhookIcon';
import { LocalizationKeys } from '../../../../i18n';
import { ExtensionType } from '../../../../types/extensions';

type ConfigOption = {
  icon: JSX.Element;
  translation: LocalizationKeys;
};

type ExtensionTypeConfig = Record<ExtensionType, ConfigOption>;

export const extensionTypeConfig: ExtensionTypeConfig = {
  webhook: {
    icon: <WebhookIcon />,
    translation:
      'containers.settings.extensions.createExtension.extensionType.webhook.label',
  },
  function: {
    icon: <CodeTagsIcon />,
    translation:
      'containers.settings.extensions.createExtension.extensionType.function.label',
  },
};
