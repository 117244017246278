import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DeleteRecommendation } from '../models';
import { deleteRecommendationSchema } from '../models/deleteRecommendation.schema';
import { patchDeleteRecommendationPayloadSchema } from './patch.schema';

export type PatchDeleteRecommendationPayload = Partial<{
  queue: Url;
  enabled: boolean;
  triggers: Url[];
}>;

export const patch = (id: ID, payload: PatchDeleteRecommendationPayload) => {
  return {
    endpoint: `/delete_recommendations/${id}`,
    method: 'PATCH',
    responseSchema: deleteRecommendationSchema,
    payloadSchema: patchDeleteRecommendationPayloadSchema,
    payload,
  } as const;
};
