import { Stack, styled } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useLeavingModalDispatch } from '../../containers/Queue/containers/QueueSchema/lib/leavingModalHooks';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { currentTabSelector } from '../../redux/modules/router/selectors';
import { State } from '../../types/state';
import { defaultTab, tabToLink } from './navigationStructure';
import RossumLogo from './RossumLogo';
import styles from './style.module.sass';

const BrandingStack = styled(Stack)({
  '& img': {
    maxHeight: 36,
  },
});

const brandingSelector = (state: State) =>
  organizationSelector(state).uiSettings?.branding;

const organizationLoadedSelector = (state: State) =>
  !!organizationSelector(state).id;

export const hasCustomLogoSelector = (state: State) => {
  const branding = brandingSelector(state);

  return !!(
    // there is an option to set top_left value to an empty string which should result in displaying PoweredBy
    (branding?.topLeft === '' || branding?.topLeft || branding?.brandName)
  );
};

const Logo = () => {
  const organizationLoaded = useSelector(organizationLoadedSelector);
  const branding = useSelector(brandingSelector);
  const customLogo = branding?.topLeft;
  const brandName = branding?.brandName;
  const currentTab = useSelector(currentTabSelector);
  const leaveSafely = useLeavingModalDispatch();

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (currentTab?.name === defaultTab.name) return null;

    if (e.metaKey || e.ctrlKey) {
      return window.open(tabToLink(defaultTab), '_blank');
    }

    e.preventDefault();
    return leaveSafely(push(tabToLink(defaultTab)));
  };

  const isCustom = useSelector(hasCustomLogoSelector);

  // there is an option to hide logo completely by setting top_left value to an empty string
  if (!organizationLoaded || customLogo === '') {
    return null;
  }

  return (
    <div onClick={onClick} className={styles.Logo}>
      {isCustom ? (
        <BrandingStack direction="row" spacing={6} alignItems="center">
          {customLogo && <img src={customLogo} alt="logo" />}
          {brandName && (
            <span style={{ textAlign: 'center', lineHeight: 1.1 }}>
              {brandName}
            </span>
          )}
        </BrandingStack>
      ) : (
        <RossumLogo />
      )}
    </div>
  );
};

export default Logo;
