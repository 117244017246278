import { Report as ReportIcon } from '@rossum/ui/icons';
import { Button, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { boldText } from '../../../lib/formaterValues';
import styles from '../style.module.sass';

type ChangedApiAlertProps = {
  changedApiUrl: string;
};

const ChangedApiAlert = ({ changedApiUrl }: ChangedApiAlertProps) => {
  const intl = useIntl();

  const handleRemoveClearUrl = () => {
    localStorage.removeItem('customApiUrlValue');

    window.location.replace(`/`);
  };

  return (
    <div className={clsx(styles.Announcement, styles.AlertAnnouncement)}>
      <ReportIcon />
      {intl.formatMessage(
        {
          id: 'components.announcements.changeAPIAlert.text',
        },
        {
          changedApiUrl,
          boldText,
        }
      )}

      <Stack direction="row" alignItems="center" sx={{ paddingLeft: 1 }}>
        <Button
          variant="text"
          onClick={() => handleRemoveClearUrl()}
          color="inherit"
        >
          {intl.formatMessage({
            id: `components.announcements.changeAPIAlert.clearButton`,
          })}
        </Button>
      </Stack>
    </div>
  );
};

export default ChangedApiAlert;
