import { Divider, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { PAPPER_BOX_PADDING } from './PaperBox';

type PaperBoxFooterProps = {
  children: ReactNode;
};

const PaperBoxFooter = ({ children }: PaperBoxFooterProps) => (
  <>
    <Divider
      sx={{
        my: PAPPER_BOX_PADDING,
        mx: -PAPPER_BOX_PADDING,
      }}
    />
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{ py: 0.75, '& a': { textDecoration: 'none' } }}
    >
      {children}
    </Stack>
  </>
);

export default PaperBoxFooter;
