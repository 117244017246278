import { useDraggable } from '@dnd-kit/core';
import { useCallback, useEffect, useRef } from 'react';
import { clamp } from 'remeda';
import { v4 as uuidv4 } from 'uuid';

type UseDragPositionProps = {
  initial?: number;
  min?: number;
  max?: number;
  storageKey?: string;
};

type DragPositionValues = {
  position: number;
} & ReturnType<typeof useDraggable>;

export const useDragPosition = ({
  initial = 0,
  min = 0,
  max = Infinity,
  storageKey,
}: UseDragPositionProps): DragPositionValues => {
  const savedPosition = storageKey ? localStorage.getItem(storageKey) : null;

  const defaultInitialPosition = clamp(initial, { min, max });

  const initialPosition = savedPosition
    ? parseInt(savedPosition, 10) || defaultInitialPosition
    : defaultInitialPosition;

  const positionRef = useRef(initialPosition);

  const dragPositionId = useRef(`drag-position-${uuidv4()}`);

  const draggableProps = useDraggable({
    id: dragPositionId.current,
    data: { position: positionRef.current },
  });

  const onPointerMove = useCallback(
    (e: MouseEvent) => {
      if (draggableProps.isDragging) {
        positionRef.current = clamp(e.clientX, { min, max });
      }
    },
    [draggableProps.isDragging, max, min]
  );

  useEffect(() => {
    document.addEventListener('pointermove', onPointerMove);
    return () => {
      document.removeEventListener('pointermove', onPointerMove);
    };
  }, [onPointerMove]);

  return {
    position: positionRef.current,
    ...draggableProps,
  };
};
