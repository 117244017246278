import { FormattedMessage } from 'react-intl';
import LoadingIndicator from '../../../../../components/UI/LoadingIndicator';
import styles from '../style.module.sass';

const InputContentLoader = () => (
  <div className={styles.InputContentLoaderWrapper}>
    <div className={styles.InputLoader}>
      <LoadingIndicator />
    </div>
    <div className={styles.LoadingMessage}>
      <FormattedMessage id="components.editor.inputBox.loading" />
    </div>
  </div>
);

export default InputContentLoader;
