import { Skeleton } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';

const CellValueSkeleton = () => {
  const skeletonWidth = useRef(random(50, 150));

  return <Skeleton width={skeletonWidth.current} />;
};

export default CellValueSkeleton;
