import update, { CustomCommands } from 'immutability-helper';
import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { FlatSchemaWithQueues } from '../../types/schema';
import { aggregationRow } from '../constants';
import { defaultValues } from './columns/defaultValues';
import { GridRowModel } from './rows/rowTypes';

type UnknownObject = Record<string, unknown>;

const updateInSchema = <
  R extends {
    meta: { path: Array<string> };
  },
  S extends UnknownObject,
>(
  schema: S,
  newRow: R
) => {
  const { meta, ...field } = newRow;

  const setOnPath = ['content', ...meta.path].reduceRight<UnknownObject>(
    (acc, current) => ({ [current]: acc }),
    { $set: field }
  );

  return update<S, CustomCommands<UnknownObject>>(schema, setOnPath);
};

export const convertKeysWithDotsToNestedObject = <T extends UnknownObject>(
  newRow: T
): T => {
  const keysWithDot = Object.keys(newRow).filter(key => key.includes('.'));

  return keysWithDot.reduce((result, keyWithDot) => {
    const propertyPath = keyWithDot.split('.');

    return update<T, CustomCommands<UnknownObject>>(result, {
      $unset: [keyWithDot],
      ...propertyPath.reduceRight<UnknownObject>(
        (setOnPath, property) => ({
          [property]: (propertyValue: T) =>
            update<T, CustomCommands<UnknownObject>>(
              propertyValue || {},
              setOnPath
            ),
        }),
        { $set: newRow[keyWithDot] }
      ),
    });
  }, newRow);
};

type NestedObject<T> = {
  [key: string]: T | NestedObject<T>;
} & Record<string, unknown>;

export const isNonEmptyObject = (
  value: unknown
): value is Record<string, unknown> =>
  typeof value === 'object' &&
  value !== null &&
  !Array.isArray(value) &&
  Object.keys(value).length > 0;

export const checkDefaults = <
  I extends Record<string, unknown>,
  O extends Record<string, unknown>,
  D,
>(
  input: I,
  original: undefined | O,
  defaults: NestedObject<D>
): I =>
  Object.keys(defaults).reduce<I>((acc, key): I => {
    if (isNonEmptyObject(acc[key])) {
      const { [key]: currentKeyInput, ...withoutKey } = acc;

      const newValueForCurrentKey = checkDefaults(
        // @ts-expect-error TODO: fix this typing
        currentKeyInput,
        original?.[key],
        defaults[key]
      );

      if (isEmpty(newValueForCurrentKey)) {
        // @ts-expect-error TODO: fix this typing
        return withoutKey;
      }

      return {
        ...acc,
        [key]: newValueForCurrentKey,
      };
    }

    if (original?.[key] === undefined && acc[key] === defaults[key]) {
      const { [key]: _, ...withoutDefault } = acc;

      // @ts-expect-error TODO: fix this typing
      return withoutDefault;
    }

    return acc;
  }, input);

export type UpdatedSchemasMap = Record<number, FlatSchemaWithQueues>;

type GetProcessRowUpdate = {
  setRows: React.Dispatch<React.SetStateAction<GridRowModel[]>>;
  setUpdatedSchemas: React.Dispatch<React.SetStateAction<UpdatedSchemasMap>>;
  schemas: FlatSchemaWithQueues[];
};

export const getProcessRowUpdate =
  ({ setRows, setUpdatedSchemas, schemas }: GetProcessRowUpdate) =>
  (newRowWithDotKeys: GridRowModel, prevRow?: GridRowModel) => {
    const newRowWithoutDotKeys =
      convertKeysWithDotsToNestedObject(newRowWithDotKeys);

    const newRow = checkDefaults(
      newRowWithoutDotKeys,
      newRowWithoutDotKeys.meta.original,
      defaultValues
    );

    const {
      meta: { schema_id },
    } = newRow;

    if (schema_id !== aggregationRow && !isEqual(newRow, prevRow)) {
      setUpdatedSchemas(prevState => {
        const alreadyInUpdated = prevState[schema_id];
        const originalSchema = schemas.find(schema => schema.id === schema_id);
        const schemaToUpdate = alreadyInUpdated ?? originalSchema;
        if (!schemaToUpdate) {
          return prevState;
        }

        const updatedSchema = updateInSchema(schemaToUpdate, newRow);

        // if updated schema is equal to the original schema, remove it from updatedSchemas
        if (alreadyInUpdated && isEqual(updatedSchema, originalSchema)) {
          const { [schema_id]: excluded, ...rest } = prevState;
          return rest;
        }

        return {
          ...prevState,
          [schema_id]: updatedSchema,
        };
      });
    }

    setRows(rows =>
      rows.map(row =>
        row.meta.schema_id === newRow.meta.schema_id ? newRow : row
      )
    );

    // processRowUpdate must return updated row
    return newRow;
  };
