import { get } from 'lodash';
import styles from '../styles.module.sass';
import { GraphTooltipProps } from './types';

type Props = GraphTooltipProps & {
  formatTime: (time: number) => string;
};

const TooltipContent = ({ label, payload, formatTime }: Props) => {
  const time = get(payload, '0.value');
  return (
    <div className={styles.TooltipContent}>
      <div className={styles.TooltipLabel}>{label}</div>
      {time && <div>{formatTime(time)}</div>}
    </div>
  );
};

export default TooltipContent;
