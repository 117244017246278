import { Page } from '@rossum/api-client/pages';
import { ReactNode } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  useDocumentStore,
  useDocumentStoreContext,
} from '../../document-store/DocumentStore';
import { visiblePagesZustandSelector } from '../../document-store/documentStoreSelectors';
import { Rectangle2D } from '../../document-store/helpers/geometry';

type DocumentPagesProps = {
  renderPage: (page: Page, pageDimensions: Rectangle2D) => ReactNode;
};

export const DocumentPages = ({ renderPage }: DocumentPagesProps) => {
  const { dimensions, pages } = useDocumentStoreContext();
  const visiblePages = useDocumentStore(
    useShallow(visiblePagesZustandSelector(dimensions))
  );

  return (
    <>
      {pages.map(page => {
        const pageDimensions = dimensions.pages.find(
          p => p.pageNumber === page.number
        )?.dimensions;

        return pageDimensions && visiblePages[page.number]
          ? renderPage(page, pageDimensions)
          : null;
      })}
    </>
  );
};
