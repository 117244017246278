import { Annotation } from '@rossum/api-client/annotations';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { camelToSnake } from '../../../lib/keyConvertor';
import { snakeCaseStatuses, Status } from '../../../types/annotationStatus';

export type NormalizedAnnotations = Array<
  Pick<Annotation, 'id' | 'url'> & { status: Status }
>;

export const normalizeAnnotations = (annotations: NormalizedAnnotations) =>
  annotations.reduce<{
    ids: number[];
    statuses: AnnotationStatus[];
  }>(
    (acc, { id, status }) => ({
      ids: [...acc.ids, id],
      statuses: [...acc.statuses, camelToSnake(status)],
    }),
    { ids: [], statuses: [] }
  );

export const getExistingStatusFilter = (
  existingFilter: GridFilterModel | null
) => {
  const existingValue = existingFilter?.items.filter(
    item => item.field === 'status' && item.operator === 'isAnyOf'
  )[0]?.value;

  if (!Array.isArray(existingValue)) return undefined;

  return existingValue.filter((status): status is AnnotationStatus =>
    snakeCaseStatuses.includes(status)
  );
};

export const reviewableStatuses = [
  'to_review',
  'reviewing',
  'postponed',
  'confirmed',
] as const satisfies Array<AnnotationStatus>;
