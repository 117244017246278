import clsx from 'clsx';
import AlertIcon from 'mdi-react/AlertIcon';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';

const NetworkAlert = () => (
  <div className={clsx(styles.Announcement, styles.AlertAnnouncement)}>
    <AlertIcon />
    <FormattedMessage id="components.announcements.networkAlert.text" />
  </div>
);

export default NetworkAlert;
