import { UniqueIdentifier } from '@dnd-kit/core';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useCallback } from 'react';
import { getDnDItems } from '../helpers';
import { FieldsList, FieldsListProps } from './FieldsList';
import { SchemaSectionTileContent } from './SchemaSectionTileContent';

export type SectionsReorderingProps = {
  sections: SchemaSection[];
  onSectionsReorder: (
    from: readonly [UniqueIdentifier, number],
    to: readonly [UniqueIdentifier, number]
  ) => void;
};

export const SECTIONS_DROPPABLE_ID = 'sections-droppable';

export const SectionsReordering = ({
  sections,
  onSectionsReorder,
}: SectionsReorderingProps) => {
  const sectionsFieldsMap = sections.reduce<{
    [key: string]: SchemaSection;
  }>((acc, field) => {
    return {
      ...acc,
      [field.id]: field,
    };
  }, {});

  const items = getDnDItems([
    { id: SECTIONS_DROPPABLE_ID, children: sections },
  ]);

  const renderFieldItem: FieldsListProps['renderFieldItem'] = useCallback(
    fieldId => {
      const section = sectionsFieldsMap[fieldId];

      return section ? <SchemaSectionTileContent field={section} /> : null;
    },
    [sectionsFieldsMap]
  );

  return (
    <FieldsList
      items={items}
      renderFieldItem={renderFieldItem}
      onItemsReorder={onSectionsReorder}
      draggableRowStyles={{
        paperSx: {
          backgroundColor: 'transparent',
        },
        indicatorSx: {
          opacity: 1,
          mb: 3.5,
          padding: 1,
          mr: 0.5,
        },
      }}
    />
  );
};
