// Generated by ts-to-zod
import { z } from 'zod';

export const schemaGetQuerySchema = z
  .object({
    fields: z.array(
      z.union([
        z.literal('id'),
        z.literal('name'),
        z.literal('url'),
        z.literal('queues'),
        z.literal('metadata'),
        z.literal('content'),
      ])
    ),
  })
  .partial();
