// Generated by ts-to-zod
import { z } from 'zod';

export const emailNotificationsSettingsEmailSchema = z
  .object({
    email: z.string(),
    name: z.string(),
  })
  .partial();

export const emailNotificationsSettingsSchema = z.object({
  recipient: emailNotificationsSettingsEmailSchema.optional().nullable(),
  unprocessableAttachments: z.boolean().optional().nullable(),
  postponedAnnotations: z.boolean().optional().nullable(),
  deletedAnnotations: z.boolean().optional().nullable(),
  emailWithNoAttachments: z.boolean().optional().nullable(),
});

export const emailNotificationConfigSchema = z.object({
  enabled: z.boolean(),
  templateValues: z.object({
    subject: z.string(),
    message: z.string(),
  }),
});
