// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointBaseSchema } from './schemaDatapointBase.schema';

export const schemaDatapointDateSchema = z
  .object({
    type: z.literal('date'),
    format: z.string().optional().nullable(),
  })
  .and(schemaDatapointBaseSchema);
