import { ArrowDropDownRounded } from '@rossum/ui/icons';
import { Button, Menu, MenuItem, Stack, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { defaultWorkspaceSorting } from '../../../../constants/values';
import { sortingValues } from '../../../../types/user';
import { ExpandableSearch } from '../../../../ui/expandable-search/ExpandableSearch';
import { useWorkspaceSorting } from '../hooks/useWorkspaceSorting';

type Props = {
  searchValue: string;
  onSearchChange: (value: string) => void;
};

const Toolbar = ({ searchValue, onSearchChange }: Props) => {
  const intl = useIntl();
  const { width: toolbarWidth, ref } = useResizeDetector();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { sorting, setSorting } = useWorkspaceSorting();

  return (
    <Stack
      ref={ref}
      direction="row"
      justifyContent="space-between"
      sx={{ py: 1, px: 2, zIndex: 1 }}
      alignItems="center"
    >
      <Stack direction="row" justifyContent="space-between" flex={1}>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {sortingValues.map(value => (
            <MenuItem
              key={value}
              onClick={() => {
                setSorting(value);
                setAnchorEl(null);
              }}
            >
              {intl.formatMessage({
                id: `components.queueWorkspaceControl.sortingSelect.labels.${value}`,
              })}
            </MenuItem>
          ))}
        </Menu>
        <Button
          color="secondary"
          data-cy="sort-workspace-control"
          onClick={e => setAnchorEl(e.currentTarget)}
          sx={{ zIndex: 1 }}
          endIcon={<ArrowDropDownRounded />}
        >
          <Typography variant="button">
            {intl.formatMessage({
              id: `components.queueWorkspaceControl.sortingSelect.labels.${
                sorting ?? defaultWorkspaceSorting
              }`,
            })}
          </Typography>
        </Button>
        <Stack position="relative">
          <ExpandableSearch
            value={searchValue}
            onChange={onSearchChange}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 9,
              backgroundColor: t => t.palette.background.paper,
            }}
            targetWidth={toolbarWidth}
            shouldCollapseOnBlur
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Toolbar;
