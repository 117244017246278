const ExtensionsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="88"
    viewBox="0 0 96 88"
  >
    <path
      fill="currentColor"
      d="M48,0 C56.836556,0 64,7.163444 64,16 C64,24.500604 57.3708878,31.4528958 49.0002994,31.9692396 L49,50.46 L67.1972199,62.4021415 C70.1161813,58.5146941 74.7644215,56 80,56 C88.836556,56 96,63.163444 96,72 C96,80.836556 88.836556,88 80,88 C71.163444,88 64,80.836556 64,72 C64,69.1159494 64.763065,66.4101223 66.0983161,64.0733975 L47.999,52.196 L29.9016839,64.0733975 C31.236935,66.4101223 32,69.1159494 32,72 C32,80.836556 24.836556,88 16,88 C7.163444,88 0,80.836556 0,72 C0,63.163444 7.163444,56 16,56 C21.2355785,56 25.8838187,58.5146941 28.8027801,62.4021415 L47,50.459 L47.0007031,31.9693014 C38.6296415,31.4534509 32,24.5009433 32,16 C32,7.163444 39.163444,0 48,0 Z M16,58 C8.2680135,58 2,64.2680135 2,72 C2,79.7319865 8.2680135,86 16,86 C23.7319865,86 30,79.7319865 30,72 C30,64.2680135 23.7319865,58 16,58 Z M80,58 C72.2680135,58 66,64.2680135 66,72 C66,79.7319865 72.2680135,86 80,86 C87.7319865,86 94,79.7319865 94,72 C94,64.2680135 87.7319865,58 80,58 Z M48,2 C40.2680135,2 34,8.2680135 34,16 C34,23.7319865 40.2680135,30 48,30 C55.7319865,30 62,23.7319865 62,16 C62,8.2680135 55.7319865,2 48,2 Z"
    />
  </svg>
);
export default ExtensionsIcon;
