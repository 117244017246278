import { snakeToCamel } from '@rossum/api-client/utils';
import { Button, Menu, MenuItem, SxProps, Theme } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { StatusTab, StatusTabArray } from './tabs';

type Props = {
  hiddenTabs: StatusTabArray;
  onClick: (tab: StatusTab) => void;
  setMoreButtonSize: (node: HTMLButtonElement | null) => void;
  sx: SxProps<Theme>;
};

export const HiddenTabs = ({
  hiddenTabs,
  onClick,
  setMoreButtonSize,
  sx,
}: Props) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        ref={setMoreButtonSize}
        onClick={e => setAnchorEl(e.currentTarget)}
        sx={{
          flexShrink: 0,
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          ...sx,
        }}
      >
        {intl.formatMessage({ id: 'containers.documents.tabs.more' })}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {hiddenTabs.map(tab => (
          <MenuItem
            key={tab.key}
            onClick={() => {
              onClick(tab);
              setAnchorEl(null);
            }}
          >
            {intl.formatMessage({
              id: `containers.allDocuments.statusTabs.${snakeToCamel(tab.key)}`,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
