// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { schemaSectionSchema } from './schemaSection.schema';

export const schemaSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: urlSchema,
  queues: z.array(urlSchema),
  metadata: z.unknown().optional(),
  content: z.array(schemaSectionSchema).optional(),
});
