import {
  Button,
  buttonBaseClasses,
  ButtonProps,
  Stack,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { NonAdminRestrictor } from '../../../components/Restrictors';
import { labelsPath } from './constants';

type Props = {
  shouldShowApplyButton: boolean;
  applyButtonProps?: ButtonProps;
  onCreateLabel: () => void;
};

export const LabelMenuActions = ({
  shouldShowApplyButton,
  applyButtonProps,
  onCreateLabel,
}: Props) => {
  const intl = useIntl();
  const { push } = useHistory();

  return (
    <Stack
      width={1}
      borderTop="1px solid"
      borderColor="divider"
      mt="auto"
      sx={{
        [`& .${buttonBaseClasses.root}`]: {
          pl: 4,
          justifyContent: 'flex-start',
        },
      }}
    >
      {shouldShowApplyButton ? (
        <Button
          data-cy="batch-label-apply"
          color="inherit"
          {...applyButtonProps}
        >
          {intl.formatMessage({
            id: 'containers.annotationList.selectionPanel.buttons.label.apply',
          })}
        </Button>
      ) : (
        <NonAdminRestrictor>
          <Button color="inherit" onClick={onCreateLabel}>
            {intl.formatMessage({
              id: 'containers.annotationList.selectionPanel.buttons.label.create',
            })}
          </Button>
          <Button color="inherit" onClick={() => push(labelsPath())}>
            {intl.formatMessage({
              id: 'containers.annotationList.selectionPanel.buttons.label.manage',
            })}
          </Button>
        </NonAdminRestrictor>
      )}
    </Stack>
  );
};
