import { alpha, Components, tableRowClasses, Theme } from '../../material';

// TODO: Maybe add this into theme when we resolve the greys
const alternatingRowOpacity = 0.1;

export const muiTableRow = (theme: Theme): Components['MuiTableRow'] => ({
  styleOverrides: {
    root: {
      [`:not(.${tableRowClasses.head})`]: {
        [`&.${tableRowClasses.hover}`]: {
          transition: 'background 0.15s',
        },
        [`&:nth-of-type(odd)`]: {
          backgroundColor: alpha(
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black,
            alternatingRowOpacity
          ),
          '&:hover': {
            backgroundColor: alpha(
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
              alternatingRowOpacity + theme.palette.action.hoverOpacity
            ),
          },
        },
      },
    },
  },
});
