import { useDispatch } from 'react-redux';
import { useHook } from '../../../../../business/hooks/useHook';
import { fetchExtensionDetailFulfilled } from '../../../../../redux/modules/extensions/actions';

export const useRefetchHook = (id: number) => {
  const dispatch = useDispatch();

  const { refetch, isFetching } = useHook(id, {
    enabled: false,
    onSuccess: data => {
      // @ts-expect-error mismatch between api-client and redux types
      dispatch(fetchExtensionDetailFulfilled(data));
    },
  });

  return { isFetching, refetch };
};
