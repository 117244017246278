import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { WorkflowRun, WorkflowRunStatus } from '../models/workflowRun';
import { workflowRunSchema } from '../models/workflowRun.schema';
import { workflowRunsListQuerySchema } from './list.schema';

export type WorkflowRunsListQuery = Partial<{
  annotation: ID | ID[];
  workflowStatus: WorkflowRunStatus;
}> &
  PaginationQuery;

export const list = (query: WorkflowRunsListQuery = {}) => {
  return {
    endpoint: `/workflow_runs`,
    method: 'GET',
    responseSchema: listResponse(workflowRunSchema),
    query,
    querySchema: workflowRunsListQuerySchema,
  } as const;
};
