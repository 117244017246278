import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Trigger, TriggerEventType } from '../models/trigger';
import { triggerSchema } from '../models/trigger.schema';
import { patchTriggerPayloadSchema } from './patch.schema';

export type PatchTriggerPayload = Partial<{
  queue: Url;
  event: TriggerEventType;
  // TODO what to use here?
  condition: unknown;
  emailTemplates: Url[];
  deleteRecommendations: Url[];
}>;

export const patch = (triggerId: ID, payload: PatchTriggerPayload) => {
  return {
    endpoint: `/triggers/${triggerId}`,
    method: 'PATCH',
    responseSchema: triggerSchema,
    payloadSchema: patchTriggerPayloadSchema,
    payload,
  } as const;
};
