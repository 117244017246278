import { Message } from '@rossum/api-client/shared';
import { Message as MessageIcon } from '@rossum/ui/icons';
import { Warning as WarningIcon } from '@rossum/ui/icons';
import { Stack } from '@rossum/ui/material';
import { get } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { annotationSideloadsSelector } from '../../redux/modules/annotation/selectors';
import { Annotation, ModifierType } from '../../types/annotation';
import {
  MatchedTriggerRuleFilename,
  MatchedTriggerRulesPerLevel,
} from '../../types/datapoints';
import { State } from '../../types/state';
import { Workspace } from '../../types/workspace';
import FileAndQueueNameWrapper from './components/FileAndQueueNameWrapper';
import Messages from './components/Messages';

type Props = {
  annotation: Annotation | undefined;
  backLink: string;
  messages?: Array<Message>;
  modifier: ModifierType;
  username?: string;
  showModifier: boolean;
  matchedTriggerRules: MatchedTriggerRulesPerLevel;
  workspacesList: Workspace[];
  currentDatapointRef: HTMLElement | null;
};

const AnnotationInformation = (props: Props) => {
  const { annotation, messages, matchedTriggerRules, modifier } = props;
  const { queue, workspace } = useSelector(annotationSideloadsSelector);

  const userId = useSelector((state: State) => state.user.id);
  const name = useSelector(annotationSideloadsSelector).document
    ?.originalFileName;
  const isOwned = modifier?.user ? modifier.user.id === userId : false;

  const [showMessage, setShowMessage] = useState(false);

  const triageRulesFileName = matchedTriggerRules?.annotationLevel.find(
    (rule): rule is MatchedTriggerRuleFilename => rule.type === 'filename'
  );

  const intl = useIntl();

  const messagesExist = Boolean(get(messages, 'length'));

  const toggleMessage = () => setShowMessage(!showMessage);

  return (
    <Stack
      sx={{
        mr: 1,
        position: 'relative',
      }}
    >
      <Stack data-cy="status-file-name">
        <FileAndQueueNameWrapper
          {...props}
          queue={queue}
          workspace={workspace}
          status={annotation?.status}
          name={name ?? undefined}
          isOwned={isOwned}
          restrictedAccess={!!annotation?.restrictedAccess}
          icons={{
            messagesExist,
            triageRulesFileName: !!triageRulesFileName,
          }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        position="absolute"
        right={42}
        top={15}
      >
        {name && (
          <Stack
            direction="row"
            alignItems="center"
            fontSize={11}
            justifyContent="space-between"
            sx={{
              cursor: 'text',
              color: theme =>
                triageRulesFileName
                  ? theme.palette.warning.main
                  : theme.palette.text.secondary,
            }}
          >
            {triageRulesFileName && (
              <Stack
                component="span"
                title={intl.formatMessage({
                  id: 'components.annotationOverview.triageRulesFileName.message',
                })}
              >
                <WarningIcon
                  fontSize="small"
                  sx={{
                    color: theme => theme.palette.warning.main,
                    mr: -0.25,
                  }}
                />
              </Stack>
            )}
          </Stack>
        )}
        {messagesExist && (
          <MessageIcon
            onClick={toggleMessage}
            sx={{
              ml: 1,
              cursor: 'pointer',
              transition: 'all 0.3s ease-in',
              color: theme =>
                showMessage
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
              '&:hover': {
                color: theme => theme.palette.text.primary,
              },
            }}
          />
        )}
      </Stack>
      {showMessage && <Messages messages={messages} />}
    </Stack>
  );
};

export default AnnotationInformation;
