import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ExtensionsIcon from './ExtensionsIcon';

const EmptyExtensionsList = () => {
  const intl = useIntl();
  return (
    <Stack
      sx={{ pt: 15 }}
      spacing={3}
      alignItems="center"
      data-cy="extensions-empty-results"
    >
      <Typography color="other.muted">
        <ExtensionsIcon />
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontWeight: theme => theme.typography.fontWeightBold }}
      >
        {intl.formatMessage({
          id: 'containers.settings.extensions.noResults',
        })}
      </Typography>
    </Stack>
  );
};

export default EmptyExtensionsList;
