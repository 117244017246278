import { WarningAmberRounded } from '@rossum/ui/icons';
import {
  alpha,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { typeSafeObjectEntries } from '../../../lib/fromEntries';
import { getRateLimitFeatureMap } from '../../pricing/utils';

type Props = {
  rateLimitFeatures: ReturnType<typeof getRateLimitFeatureMap>;
};

export const SubscriptionExceededBanner = ({ rateLimitFeatures }: Props) => {
  const intl = useIntl();

  const exceededFeatures = typeSafeObjectEntries(rateLimitFeatures).filter(
    ([_, feature]) => feature.hasExceededLimit
  );
  return (
    <Stack
      component={Paper}
      sx={{ p: 4 }}
      data-cy="subscription-exceeded-banner"
    >
      <Stack
        sx={{
          border: t => `1px solid ${alpha(t.palette.warning.main, 0.5)}`,
          backgroundColor: t => alpha(t.palette.warning.main, 0.08),
          borderRadius: 1,
          p: 2,
        }}
        spacing={1.5}
      >
        <WarningAmberRounded color="warning" />

        <Stack spacing={0.5}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'features.pricing.subscriptionExceeded.title',
            })}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {intl.formatMessage({
              id: 'features.pricing.subscriptionExceeded.description',
            })}
          </Typography>
          <List
            disablePadding
            dense
            sx={{
              listStyleType: 'disc',
              listStyle: 'inside',
              color: 'text.secondary',
            }}
          >
            {exceededFeatures.map(([featureKey, featureValues]) => (
              <ListItem key={featureKey} sx={{ display: 'list-item' }}>
                {intl.formatMessage(
                  {
                    id: `features.pricing.subscriptionExceeded.${featureKey}`,
                  },
                  { count: featureValues.used }
                )}
              </ListItem>
            ))}
          </List>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {intl.formatMessage({
              id: 'features.pricing.subscriptionExceeded.contact',
            })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
