import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { HookTemplate } from '../models/hookTemplate';
import { hookTemplateSchema } from '../models/hookTemplate.schema';
import { hookTemplateQuerySchema } from './list.schema';

export type HookTemplateQuery = {
  ordering?: string;
} & PaginationQuery;

export const list = (query: HookTemplateQuery = {}) => {
  return {
    endpoint: '/hook_templates',
    method: 'GET',
    query,
    querySchema: hookTemplateQuerySchema,
    responseSchema: listResponse(hookTemplateSchema),
  } as const;
};
