import { Email } from '@rossum/api-client/email';
import { useIntl } from 'react-intl';
import CountBadge from '../../../ui/count-badge/CountBadge';
import { AnnotationPart, useRelatedEmail } from './useRelatedEmail';

type Props = {
  annotation: AnnotationPart;
  handleOnClick: (email: Email) => void;
};

const AnnotationEmailBadge = ({ annotation, handleOnClick }: Props) => {
  const { email } = useRelatedEmail({ annotation });
  const intl = useIntl();

  if (!email) return null;

  return (
    <CountBadge
      label={intl.formatMessage({ id: 'components.emailCard.label' })}
      count={annotation.relatedEmails.length}
      onClick={() => handleOnClick(email)}
    />
  );
};

export default AnnotationEmailBadge;
