import { Chip, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { GridRowId } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';

type AttachmentModalFooterProps = {
  selectedAnnotations: { id: number; name: string }[];
  onRemove: (id: GridRowId) => void;
};

const MAX_CHIP_NUMBER = 3;

export const AttachmentModalFooter = ({
  selectedAnnotations,
  onRemove,
}: AttachmentModalFooterProps) => {
  const intl = useIntl();
  const hasSelections = selectedAnnotations.length > 0;

  const visibleAnnotations = selectedAnnotations.slice(0, MAX_CHIP_NUMBER);
  const hiddenAnnotations = selectedAnnotations.slice(MAX_CHIP_NUMBER - 1, -1);

  return (
    <Stack
      mt="auto"
      direction="row"
      alignItems="center"
      spacing={1}
      minHeight={25}
    >
      {hasSelections ? (
        <>
          <Typography variant="body2">
            {intl.formatMessage({
              id: 'components.modal.addAttachments.selections.label',
            })}
          </Typography>
          {visibleAnnotations.map(({ id, name }) => (
            <Chip
              key={id}
              label={name}
              size="small"
              variant="outlined"
              color="secondary"
              onDelete={() => onRemove(id)}
              sx={{ maxWidth: 120, cursor: 'default' }}
            />
          ))}
          {hiddenAnnotations.length ? (
            <Tooltip
              placement="top"
              title={
                <Stack maxHeight={150} overflow="auto">
                  {hiddenAnnotations.map(({ id, name }) => (
                    <Typography key={id} variant="body2">
                      {name}
                    </Typography>
                  ))}
                </Stack>
              }
            >
              <Chip
                label={`+${hiddenAnnotations.length}`}
                size="small"
                variant="outlined"
                color="secondary"
              />
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </Stack>
  );
};
