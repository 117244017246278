import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { patch } from './patch';
export { type CreateTriggerPayload } from './create';
export { type PatchTriggerPayload } from './patch';

export const triggers = {
  create,
  get,
  patch,
  delete: deleteEndpoint,
};
