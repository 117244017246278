import SearchIcon from '../../icons/SearchIcon';
import styles from './style.module.sass';

type Props = {
  title: string;
  subtitle: string;
};

const NoItemsFound = ({ title, subtitle }: Props) => (
  <div className={styles.NoItemFound}>
    <div>
      <SearchIcon />
    </div>
    <div className={styles.NoItemFoundTitle}>{title}</div>
    <div className={styles.BrownText}>{subtitle}</div>
  </div>
);

export default NoItemsFound;
