import { Message } from '@rossum/api-client/shared';
import { uniqBy } from 'lodash';
import { findDatapointById } from '../../../../redux/modules/datapoints/navigation/findDatapointIndex';
import { AnyDatapointDataST } from '../../../../types/datapoints';

export const getErrors = (
  messagesObject: Record<number, Message> & { all?: Message | undefined }
) =>
  Object.values(messagesObject).flatMap(message =>
    message?.type === 'error' && typeof message.id === 'string'
      ? [
          {
            id: parseInt(message.id, 10),
            content: message.content,
          },
        ]
      : []
  );

export const getUniqErrorMessages = (
  messages: ReturnType<typeof getErrors>,
  datapoints: AnyDatapointDataST[]
) => {
  // Uniqe by datapoint label is being performed because of the multivalues
  // (taking message for one column instead of all column rows)
  return uniqBy(
    messages.flatMap(m => {
      const label = findDatapointById(datapoints, m.id)?.schema?.label;
      return label ? [{ ...m, label }] : [];
    }),
    'label'
  );
};
