import { Grid, Grow, ListItem, MenuItem, Stack } from '@rossum/ui/material';
import { FieldValues, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { SwitchTransition } from 'react-transition-group';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../components/ReactHookForm/FormTypeahead';
import { CompositeControlProps } from '../../../../components/ReactHookForm/utils';
import VerboseFieldLabel from '../../../../containers/Queue/containers/QueueEmails/VerboseFieldLabel';
import {
  TriggerClientEvent,
  triggerConditionOperators,
} from '../mappers/templateMapper';

type EmailTemplateTriggerShape = {
  event: string;
  fields: string;
  condition: {
    field: string;
    operator: string;
    value: string;
  };
};

type EmailTemplateTriggerControlProps<
  TFieldValues extends FieldValues = FieldValues,
> = CompositeControlProps<TFieldValues, EmailTemplateTriggerShape> & {
  fieldOptions: { id: string; label: string }[];
  triggerEvents: readonly TriggerClientEvent[];
};

const EmailTemplateTriggerControl = <
  TFieldValues extends FieldValues = FieldValues,
>({
  ControllerProps: { control, nameMap },
  disabled,
  fieldOptions,
  triggerEvents,
}: EmailTemplateTriggerControlProps<TFieldValues>) => {
  const intl = useIntl();

  const triggerEvent = useWatch({ control, name: nameMap.event });

  const conditionDisabled = triggerEvent === 'document_received';

  return (
    <>
      <Stack spacing={0.5}>
        <VerboseFieldLabel
          label={intl.formatMessage({
            id: 'components.emailTemplateForm.triggerEvent.label',
          })}
          description={intl.formatMessage({
            id: 'components.emailTemplateForm.triggerEvent.description',
          })}
          disabled={disabled}
        />
        <TextFieldControl
          ControllerProps={{ control, name: nameMap.event }}
          placeholder={intl.formatMessage({
            id: 'components.emailTemplateForm.triggerEvent.placeholder',
          })}
          select
          size="small"
          sx={{ maxWidth: 320 }}
          disabled={disabled}
        >
          {triggerEvents.map(event => (
            <MenuItem key={event} value={event}>
              {intl.formatMessage({
                id: `components.emailTemplateForm.triggerEvent.enum.${event}`,
              })}
            </MenuItem>
          ))}
        </TextFieldControl>
      </Stack>
      <SwitchTransition>
        <Grow
          key={triggerEvent === 'at_least_one_missing' ? 'multiselect' : 'none'}
        >
          {triggerEvent === 'at_least_one_missing' ? (
            <Stack spacing={0.5}>
              <VerboseFieldLabel
                label={intl.formatMessage({
                  id: 'components.emailTemplateForm.triggerFields.label',
                })}
                description={intl.formatMessage({
                  id: 'components.emailTemplateForm.triggerFields.description',
                })}
                disabled={disabled}
              />
              <FormTypeahead
                autoSelect={false}
                control={control}
                name={nameMap.fields}
                options={fieldOptions}
                disabled={disabled}
                disablePortal
                multiple
                loading={!fieldOptions}
                getOptionValue={option => option.id}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.label
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                renderOption={(props, option) => (
                  <ListItem {...props} key={option.id}>
                    {option.label}
                  </ListItem>
                )}
                placeholder={intl.formatMessage({
                  id: 'components.emailTemplateForm.triggerFields.placeholder',
                })}
                sx={{ maxWidth: 450 }}
                size="small"
              />
            </Stack>
          ) : (
            <span style={{ display: 'none' }} />
          )}
        </Grow>
      </SwitchTransition>
      <Stack spacing={1.5}>
        <VerboseFieldLabel
          label={intl.formatMessage({
            id: 'components.emailTemplateForm.triggerCondition.label',
          })}
          description={intl.formatMessage({
            id: 'components.emailTemplateForm.triggerCondition.description',
          })}
          disabled={disabled || conditionDisabled}
        />
        {/* ml centers the Grid */}
        <Grid container columnSpacing={1} sx={{ ml: '-8px !important' }}>
          <Grid item xs={6}>
            <FormTypeahead
              autoSelect={false}
              control={control}
              label={intl.formatMessage({
                id: 'components.emailTemplateForm.triggerCondition.field.label',
              })}
              name={nameMap.condition.field}
              disabled={disabled || conditionDisabled}
              options={fieldOptions ?? []}
              loading={!fieldOptions}
              size="small"
              disablePortal
              getOptionValue={option => option.id}
              getOptionLabel={option =>
                typeof option === 'string' ? option : option.label
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                  {option.label}
                </ListItem>
              )}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldControl
              select
              ControllerProps={{
                control,
                name: nameMap.condition.operator,
              }}
              FieldLabelProps={{
                layout: 'floating',
                label: intl.formatMessage({
                  id: 'components.emailTemplateForm.triggerCondition.operator.label',
                }),
              }}
              sx={{ width: '100%' }}
              size="small"
              disabled={disabled || conditionDisabled}
            >
              {triggerConditionOperators.map(operator => (
                <MenuItem key={operator} value={operator}>
                  {intl.formatMessage({
                    id: `components.emailTemplateForm.triggerCondition.operator.enum.${operator}`,
                  })}
                </MenuItem>
              ))}
            </TextFieldControl>
          </Grid>
          <Grid item xs={3}>
            <TextFieldControl
              ControllerProps={{ control, name: nameMap.condition.value }}
              FieldLabelProps={{
                layout: 'floating',
                label: intl.formatMessage({
                  id: 'components.emailTemplateForm.triggerCondition.value.label',
                }),
              }}
              sx={{ width: '100%' }}
              size="small"
              disabled={disabled || conditionDisabled}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default EmailTemplateTriggerControl;
