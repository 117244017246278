import { endpoints } from '@rossum/api-client';
import { AnnotationPayload } from '@rossum/api-client/annotations';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

type PatchAnnotations = {
  id: number;
  payload: AnnotationPayload;
};

export const usePatchAnnotations = () => {
  return useMutation({
    mutationFn: (annotations: Array<PatchAnnotations>) => {
      const patchAnnotations = annotations.map(({ id, payload }) =>
        api.request(
          endpoints.annotations.patch(id, {
            ...payload,
          })
        )
      );

      return Promise.all(patchAnnotations);
    },
  });
};
