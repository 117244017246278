import { get } from 'lodash';
import { Action, AnyAction, MiddlewareAPI } from 'redux';
import { push, replace } from 'redux-first-history';
import { getType } from 'typesafe-actions';
import { CHANGES_SAVED_PATH } from '../../containers/ChangesSaved/constants';
import { Next } from '../../types/redux';
import { nextAnnotation } from '../modules/annotation/actions';
import {
  callHistoryMethod,
  isActionOf,
  locationChange,
} from '../modules/utils';

const isRedirectingCallHistoryAction = (
  action: AnyAction
): action is ReturnType<typeof replace> | ReturnType<typeof push> => {
  return (
    isActionOf(callHistoryMethod)(action) &&
    (action.payload.method === 'replace' || action.payload.method === 'push')
  );
};

const getPathName = (action: AnyAction) => {
  if (isRedirectingCallHistoryAction(action)) {
    const locationDescriptor = action.payload.args?.[0];

    if (typeof locationDescriptor === 'string') {
      // The string variant represents the whole URL, but
      // we consider only the path name, query string and hash should be ignored.
      return locationDescriptor.split(/\?|#/)[0];
    }
    return locationDescriptor?.pathname;
  }

  if (isActionOf(locationChange)(action)) {
    return action.payload.location.pathname;
  }

  return undefined;
};

export default (store: MiddlewareAPI) => (next: Next) => (action: Action) => {
  const nextPathname = getPathName(action);
  const currentPathname = get(store.getState(), [
    'router',
    'location',
    'pathname',
  ]);

  if (
    (nextPathname &&
      currentPathname &&
      // Navigation to edit mode is allowed in embedded mode
      nextPathname.replace('/edit', '') !==
        currentPathname.replace('/edit', '') &&
      nextPathname !== '/timeExpired' &&
      nextPathname !== CHANGES_SAVED_PATH) ||
    action.type === getType(nextAnnotation)
  ) {
    // eslint-disable-next-line
    console.log('Action is disabled for embedded version ', action);
  } else next(action);
};
