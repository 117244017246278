import { endpoints, ID } from '@rossum/api-client';
import { EngineDuplicatePayload } from '@rossum/api-client/engines';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const useDuplicateEngine = (id: ID) => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: mutationKey(['duplicate_engine', id] as const),
    mutationFn: (payload: EngineDuplicatePayload) =>
      api.request(endpoints.engines.duplicate(id, payload)),
    onSuccess: engine => {
      showToastMessage('engineDuplicated');
      queryClient.setQueryData(engineKeys.detail(id), engine);
    },
  });
};
