import { endpoints } from '@rossum/api-client';
import { WorkspaceListQuery } from '@rossum/api-client/workspaces';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_LAZY_WORKSPACES = 'workspaces';

// lazily fetches workspaces by `pageSize`
// to fetch next page, call fetchNextPage()
// falsy value from `getNextPageParams` means `hasNextPage` will be false - do not fetch in that case
// because it will execute the query with wrong parameters
// TODO: Ask about something like `useInfiniteQueries` for a dynamic number of infinite
// queries
// For now let's stick with non-overengineered eager load
export const useLazyWorkspaces = (
  query: WorkspaceListQuery = { pageSize: 10 }
) => {
  return useInfiniteQuery(
    [QUERY_KEY_LAZY_WORKSPACES, query] as const,
    ({ queryKey, pageParam }) => {
      return api.request(
        endpoints.workspaces.list({ ...queryKey[1], page: pageParam })
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.pagination.totalPages
          ? pages.length + 1
          : false;
      },
    }
  );
};
