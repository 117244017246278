import { Action, Location } from 'history';
import { isEmpty } from 'lodash';
import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'redux-first-history';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs/internal/Observable';
import Immutable, {
  asMutable,
  Immutable as ImmutableType,
  ImmutableArray,
  ImmutableObject,
  isImmutable,
} from 'seamless-immutable';
import {
  ActionCreator,
  createAction,
  isActionOf as originalIsActionOf,
} from 'typesafe-actions';
import { EpicDependencies } from '../../lib/apiTypes';
import { AnyDatapointDataST } from '../../types/datapoints';
import { Pagination } from '../../types/pagination';
import { State } from '../../types/state';
import { RootActionType } from '../rootActions';

export const ensureArray = <T>(maybeArray: Array<T> | T): Array<number> =>
  ([] as T[]).concat(maybeArray).map(Number);

export const updateInArray = <T>(
  array: Array<T>,
  index: number,
  updateFn: (_item: T) => T
) => [
  ...array.slice(0, index),
  updateFn(array[index]),
  ...array.slice(index + 1),
];

export const insertInArray = <T>(array: Array<T>, index: number, data: T) => [
  ...array.slice(0, index),
  data,
  ...array.slice(index),
];

export const insertArrayInArray =
  <T>(index: number, data: Array<T>) =>
  (array: T[]) => [...array.slice(0, index), ...data, ...array.slice(index)];

export const swapItemsInArray = <T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number
) => {
  const newArray = Array.from(array);
  const [item] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, item);
  return newArray;
};

export const removeIndexInArray = <T>(array: Array<T>, index: number) =>
  array.filter((_, i) => i !== index);

export const findLastIndex = (
  datapoints: Array<AnyDatapointDataST>,
  parentIndex: number
): number => {
  const datapoint = datapoints[parentIndex];
  const currentChildren = 'children' in datapoint ? datapoint.children : [];

  return isEmpty(currentChildren)
    ? parentIndex
    : findLastIndex(
        datapoints,
        currentChildren[currentChildren.length - 1].index
      );
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

/**
 * Marking all types properly as Immutable is very tedious, and it's cluttering the source code.
 * Since we don't use mutations anywhere, we safely can just cast Immutable<T> to type T,
 * and use it like that.
 *
 * Note that this is not the same as ImmutableObject/Array.asMutable() method, since
 * that method creates a copy of the given object/array (so that it can be safely mutated).
 *
 * seamless-immutable throws in case of any mutation on ImmutableObject/ImmutableArray,
 * so a mistake should be caught early.
 */
export function withoutImmutable<T>(obj: ImmutableObject<T>): T;
export function withoutImmutable<T>(obj: ImmutableArray<T>): T[];
export function withoutImmutable(obj: unknown): unknown {
  return obj;
}

/**
 * Using array types everywhere instead of ImmutableArray has one downside - Array.sort.
 * If it's called on an immutable array, it will throw.
 *
 * Anytime you call Array.sort() you should use this function to ensure that the array can be sorted.
 *
 * @param obj
 * @returns
 */
export function ensureMutable<T>(obj: Array<T>): Array<T> {
  return isImmutable(obj) ? (asMutable(obj) as Array<T>) : obj;
}

/**
 * A convenience function to define a new epic.
 * This way you don't need to specify types of the parameters (and you don't need to import them).
 */
export const makeEpic = <T>(
  epic: (
    _action$: ActionsObservable<RootActionType>,
    _state$: StateObservable<State>,
    _dependencies: EpicDependencies
  ) => Observable<T>
) => epic;

/**
 * Return type of original isActionOf from typesafe-actions library
 * is determined as union of all possible actions.
 * For example, when the return type is { type: 'A', meta: M } | { type: 'B' },
 * you can't access 'action.meta' without type narrowing. However, in the codebase there are a lot of places
 * which try to access 'meta' property in this situation.
 *
 * This isActionOf determines the return type a bit differently - in the same situation
 * it will be { type: 'A', meta: M } | { type: 'B', meta?: undefined }
 * so the rest of the code can safely access the 'meta' property.
 *
 * The same applies to 'payload' property.
 */
export function isActionOf<T extends string, AC extends ActionCreator<T>>(
  actionCreators: AC[] | AC
): (action: { type: string }) => action is ReturnType<AC> {
  const result = (_action: { type: string }) =>
    (actionCreators instanceof Array ? actionCreators : [actionCreators]).some(
      ac => {
        return originalIsActionOf(ac, _action);
      }
    );

  return result as (action: { type: string }) => action is ReturnType<AC>;
}

/**
 * LOCATION_CHANGE and CALL_HISTORY_METHOD actions come from redux-first-history.
 * For consistency, we want to use then in filter(isActionOf(...))
 * in the same way as our own action creators,
 * that's why we create an action creators for them.
 */

export const locationChange = createAction(
  LOCATION_CHANGE,
  (
    _
  ): {
    location: Location;
    action: Action;
  } => {
    throw new Error(
      "locationChange action creator shouldn't be called directly"
    );
  }
)();

export const callHistoryMethod = createAction(
  CALL_HISTORY_METHOD,
  (
    _
  ): {
    method: string;
    args?: unknown[];
  } => {
    throw new Error(
      "callHistoryMethod action creator shouldn't be called directly"
    );
  }
)();

export const initialPagination: ImmutableType<Pagination> = Immutable({
  next: undefined,
  previous: undefined,
  total: 0,
  totalPages: 1,
});
