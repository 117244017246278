// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { schemaItemBaseSchema } from './schemaItemBase.schema';

export const schemaDatapointButtonSchema = schemaItemBaseSchema
  .and(
    z.object({
      category: z.literal('datapoint'),
      type: z.literal('button'),
    })
  )
  .and(
    z
      .object({
        canExport: z.boolean(),
        canCollapse: z.boolean(),
        popupUrl: urlSchema,
        canObtainToken: z.boolean(),
      })
      .partial()
  );
