// Generated by ts-to-zod
import { z } from 'zod';

export const messageSchema = z.object({
  type: z.union([z.literal('error'), z.literal('warning'), z.literal('info')]),
  content: z.string(),
  id: z
    .union([z.literal('all'), z.string(), z.number()])
    .optional()
    .nullable(),
  detail: z
    .object({
      hookId: z.number().nullable(),
      hookName: z.string(),
      requestId: z.string(),
      isException: z.boolean().optional(),
      tracebackLineNumber: z.number().optional(),
      timestamp: z.string().optional(),
    })
    .optional()
    .nullable(),
});
