import { Slider } from '@rossum/ui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Input from '../Input';
import styles from './style.module.sass';
import { useThresholdInput } from './useThresholdInput';

type PredefinedValueT = { value: number; label: string };

type Props = {
  defaultValue: number;
  onChange: (value: number | null) => void;
  predefinedValues: PredefinedValueT[];
  value: number;
};

const Threshold = ({
  defaultValue,
  predefinedValues,
  value,
  onChange,
}: Props) => {
  const [isCustom, setIsCustom] = useState(
    !predefinedValues.some(option => option.value === value)
  );

  const { thresholdValue, handleInputBlur, handleInputChange } =
    useThresholdInput({ value, defaultValue, onChange });

  return (
    <div>
      <div className={styles.ThresholdSelect}>
        {predefinedValues.map(option => (
          <div
            data-cy={`threshold-value-${option.value}`}
            key={option.label}
            onClick={() => {
              setIsCustom(false);
              onChange(option.value);
            }}
            className={clsx(
              styles.Option,
              value === option.value && !isCustom && styles.OptionActive
            )}
          >
            {option.label}
          </div>
        ))}
        <div
          className={clsx(styles.Option, isCustom && styles.OptionActive)}
          onClick={() => setIsCustom(true)}
          data-cy="threshold-value-custom"
        >
          <FormattedMessage id="components.threshold.custom" />
        </div>
      </div>
      {isCustom && (
        <div className={styles.ValueSlider}>
          <span className={styles.MinValue}>0</span>
          <Slider
            onChange={(_, val) => onChange(Array.isArray(val) ? val[0] : val)}
            step={0.01}
            value={value}
            max={1}
            classes={{
              root: styles.SliderRoot,
              thumb: styles.SliderThumb,
              rail: styles.SliderRail,
              track: styles.SliderTrack,
            }}
          />
          <span className={styles.MaxValue}>100 %</span>
          <div className={styles.InputWrapper}>
            <Input
              className={styles.SliderInput}
              // Since percentages are limited to one decimal place, user shouldn't be able to enter more than 4 characters
              maxLength={4}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              value={`${thresholdValue}`}
            />
            %
          </div>
        </div>
      )}
    </div>
  );
};

export default Threshold;
