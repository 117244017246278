type Chunk = {
  highlight: boolean;
  start: number;
  end: number;
};

export const findAll = ({
  autoEscape,
  caseSensitive = false,
  findChunks = defaultFindChunks,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  findChunks?: typeof defaultFindChunks;
  searchWords: Array<string>;
  textToHighlight: string;
}): Array<Chunk> =>
  fillInChunks({
    chunksToHighlight: findChunks({
      autoEscape,
      caseSensitive,
      searchWords,
      textToHighlight,
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  });

const defaultFindChunks = ({
  autoEscape,
  caseSensitive,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  searchWords: Array<string>;
  textToHighlight: string;
}): Array<Chunk> =>
  searchWords
    .filter(searchWord => searchWord)
    .reduce((chunks: Chunk[], searchWord) => {
      const regex = new RegExp(
        autoEscape ? escapeRegExpFn(searchWord) : searchWord,
        caseSensitive ? 'g' : 'gi'
      );

      let match;
      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          // eslint-disable-next-line no-plusplus
          regex.lastIndex++;
        }
      }
      return chunks;
    }, []);
// Allow the findChunks to be overridden in findAll,
// but for backwards compatibility we export as the old name
export { defaultFindChunks as findChunks };

const fillInChunks = ({
  chunksToHighlight,
  totalLength,
}: {
  chunksToHighlight: Array<Chunk>;
  totalLength: number;
}): Array<Chunk> => {
  const allChunks: Array<Chunk> = [];
  const append = (start: number, end: number, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach(chunk => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};

function escapeRegExpFn(string: string): string {
  return string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}
