import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { useSchema } from '../../../business/schema/useSchema';
import Queue from '../../../containers/Queue';
import AutomationDetail from '../../../containers/Queue/containers/AutomationDetail';
import QueueAccess from '../../../containers/Queue/containers/QueueAccess';
import QueueEmailsContainer from '../../../containers/Queue/containers/QueueEmails';
import QueueSchema from '../../../containers/Queue/containers/QueueSchema';
import QueueSettings from '../../../containers/Queue/containers/QueueSettings';
import { queueByIdSelector } from '../../../redux/modules/queues/selector';
import { enterQueue, exitQueue } from '../../../redux/modules/ui/actions';
import { HelmetComponent } from '../../../routes/HelmetComponent';
import { State } from '../../../types/state';
import ResearchCallSurvey from '../../surveys/ResearchCallSurvey/ResearchCallSurvey';
import { FieldsRoute } from './FieldsRoute';

export const QueueSettingsRoute = (
  props: RouteChildrenProps<{ queueId: string }>
) => {
  const { match, location } = props;

  const queueId = Number(match?.params.queueId);

  const dispatch = useDispatch();

  // TODO: fetch queue for whole route and store it in reactQuery

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    dispatch(enterQueue());

    return () => {
      dispatch(exitQueue());
    };
  }, [dispatch]);

  const currentQueue = useSelector((state: State) =>
    queueByIdSelector(state, queueId)
  );

  // TODO: Can we move this down to `FieldsRoute`?
  const { data: schema, isLoading: schemaIsLoading } = useSchema(
    currentQueue?.schema
  );

  if (!match) {
    return null;
  }

  return (
    <>
      {currentQueue ? (
        <HelmetComponent
          dynamicName={currentQueue.name}
          translationKey="features.routes.pageTitles.queueSettings"
        />
      ) : null}
      <Switch>
        <Route path={`${match.path}/schema`}>
          <QueueSchema {...props} match={match} />
        </Route>
        <Route>
          <Switch location={location}>
            <Route path={`${match.path}/basic`}>
              {currentQueue ? (
                // TODO: remove <Queue /> wrapper and rewrite inner components
                <Queue {...props} selectedQueue={currentQueue}>
                  <QueueSettings selectedQueue={currentQueue} />
                </Queue>
              ) : null}
            </Route>
            <Route path={`${match.path}/fields`}>
              {currentQueue ? (
                <FieldsRoute
                  {...props}
                  schema={schema}
                  queue={currentQueue}
                  schemaIsLoading={schemaIsLoading}
                />
              ) : null}
            </Route>
            <Route path={`${match.path}/emails`}>
              {currentQueue ? (
                // TODO: remove <Queue /> wrapper and rewrite inner components
                <Queue {...props} selectedQueue={currentQueue}>
                  <QueueEmailsContainer selectedQueue={currentQueue} />
                </Queue>
              ) : null}
            </Route>
            <Route path={`${match.path}/automation`}>
              {currentQueue ? (
                <AutomationDetail
                  selectedQueue={currentQueue}
                  parentPath={match.url}
                />
              ) : null}
            </Route>
            <Route path={`${match.path}/access`}>
              {routeProps =>
                currentQueue ? (
                  <QueueAccess
                    selectedQueue={currentQueue}
                    parentPath={match.url}
                    {...routeProps}
                  />
                ) : null
              }
            </Route>

            <Route
              path={`${match.path}/`}
              render={() => (
                <Redirect
                  to={{
                    pathname: `${match.url}/basic`,
                    state: location.state,
                  }}
                />
              )}
            />
          </Switch>
        </Route>
      </Switch>
      <ResearchCallSurvey surveyPlacement="queueSettings" />
    </>
  );
};
