import { Url } from '@rossum/api-client';
import { TriggerUrl } from '@rossum/api-client/triggers';
import {
  DetailDrawer,
  DetailDrawerProps,
} from '@rossum/rossum-ui/DetailDrawer';
import { Settings } from '@rossum/ui/icons';
import { CircularProgress, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useValidationTrigger } from '../../business/triggers/useValidationTrigger';
import { AnyDatapointSchema, EnumOption } from '../../types/schema';
import { State } from '../../types/state';
import TriageConditionsForm from './TriageConditionsForm';

type TriageConditionsDrawerProps = {
  DrawerProps: DetailDrawerProps;
  queueUrl: Url;
  triggerUrl?: TriggerUrl;
};

const TriageConditionsDrawer = ({
  queueUrl,
  triggerUrl,
  DrawerProps: { open, onClose, sx, ...restDrawerProps },
}: TriageConditionsDrawerProps) => {
  const intl = useIntl();

  const { data: trigger, isInitialLoading: isTriggerLoading } =
    useValidationTrigger(triggerUrl);

  type DatapointOptions = { options: EnumOption[] };
  const enumDatapoints = useSelector(({ schema: { content } }: State) => {
    return content?.filter(
      (datapoint): datapoint is AnyDatapointSchema & DatapointOptions =>
        datapoint.category === 'datapoint' && datapoint.type === 'enum'
    );
  });

  return (
    <DetailDrawer
      {...restDrawerProps}
      PaperProps={{
        elevation: 2,
      }}
      title={
        <Stack direction="row" spacing={2} alignItems="center">
          <Settings />
          <Typography variant="h5">
            {intl.formatMessage({
              id: `components.triageConditionsDrawer.title`,
            })}
          </Typography>
        </Stack>
      }
      open={open}
      keepMounted={false}
      onClose={onClose}
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography color="text.secondary">
          {intl.formatMessage({ id: 'components.triageConditionsDrawer.text' })}
        </Typography>
        {!isTriggerLoading && enumDatapoints ? (
          <TriageConditionsForm
            queueUrl={queueUrl}
            enumDatapoints={enumDatapoints}
            trigger={trigger}
            onClose={() => onClose?.({}, 'backdropClick')}
          />
        ) : (
          <Stack alignItems="center" justifyContent="center" sx={{ py: 5 }}>
            <CircularProgress size={48} />
          </Stack>
        )}
      </Stack>
    </DetailDrawer>
  );
};

export default TriageConditionsDrawer;
