import {
  CODE_EDITOR_OVERVIEW_LINK,
  DEVELOPER_ARTICLE_LINK,
  docsLinks,
  HOWTO_ACCESS_PUBLIC_API_FROM_FUNCTION,
  HOWTO_CREATE_CUSTOM_FIELD_LINK,
  HOWTO_CREATE_CUSTOMIZE_TABLE_LINK,
  HOWTO_CREATE_MANAGE_ENUM_FIELD_LINK,
  HOWTO_TEST_FUNCTION,
} from '../../../../../constants/values';
import { DocumentationLinksT } from './types';

const codeEditor = [
  {
    translation: 'overviewArticle',
    link: CODE_EDITOR_OVERVIEW_LINK,
    icon: 'textSubject',
  },
  {
    translation: 'developerArticle',
    link: DEVELOPER_ARTICLE_LINK,
    icon: 'textSubject',
  },
  {
    translation: 'howToAccessInternet',
    link: HOWTO_ACCESS_PUBLIC_API_FROM_FUNCTION,
    icon: 'textSubject',
  },
  {
    translation: 'howToTestFunction',
    link: HOWTO_TEST_FUNCTION,
    icon: 'textSubject',
  },
  {
    translation: 'apiDocumentation',
    link: docsLinks.codeEditorApiDocs,
    icon: 'codeTags',
  },
] as const satisfies ReadonlyArray<DocumentationLinksT>;

const schemaEditor = [
  {
    translation: 'howToCustomField',
    link: HOWTO_CREATE_CUSTOM_FIELD_LINK,
    icon: 'textSubject',
  },
  {
    translation: 'howToCustomTable',
    link: HOWTO_CREATE_CUSTOMIZE_TABLE_LINK,
    icon: 'textSubject',
  },
  {
    translation: 'howToEnumField',
    link: HOWTO_CREATE_MANAGE_ENUM_FIELD_LINK,
    icon: 'textSubject',
  },
  {
    translation: 'schemaDocumentation',
    link: docsLinks.schema,
    icon: 'codeTags',
  },
] as const satisfies ReadonlyArray<DocumentationLinksT>;

export const documentationLinksConfig = {
  codeEditor,
  schemaEditor,
};
