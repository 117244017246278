import { endpoints } from '@rossum/api-client';
import { ApplyLabelPayload } from '@rossum/api-client/labels';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const MUTATION_KEY_LABELS_APPLY = 'labelsApply';

export const useRequestLabelsApply = () =>
  useMutation({
    mutationFn: (payload: ApplyLabelPayload) =>
      api.request(endpoints.labels.apply(payload)),
    mutationKey: [MUTATION_KEY_LABELS_APPLY],
  });
