import { isEmpty } from 'lodash';

export const isNotNullOrUndefined = <T>(
  input: null | undefined | T
): input is T => input !== null && input !== undefined;

const isNonEmpty = <T extends unknown[] | string>(
  input: null | undefined | T
): input is T => isNotNullOrUndefined(input) && input.length > 0;

export const isNonEmptyString = (s: null | undefined | string): s is string =>
  isNonEmpty(s);

export const isNonEmptyArray = <T>(t: null | undefined | T[]): t is T[] =>
  isNonEmpty(t);

export const isNonEmptyObject = <T extends object>(
  t: null | undefined | T
): t is T => isNotNullOrUndefined(t) && !isEmpty(t);
