import { Help as HelpIcon } from '@rossum/ui/icons';
import { alpha, Stack, Typography } from '@rossum/ui/material';
import { Link } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmbedded } from '../../../constants/config';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  icon: ReactNode;
  localeKey: LocalizationKeys;
  children?: ReactNode;
  helpLink?: string;
};

const TooltipItem = ({ helpLink, icon, localeKey, children }: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} key={localeKey}>
      {icon}
      <Typography
        variant="body2"
        color={theme => alpha(theme.palette.common.white, 0.6)}
      >
        <FormattedMessage id={localeKey} />
      </Typography>
      {children}
      {helpLink && !isEmbedded() && (
        <Link
          href={helpLink}
          target="_blank"
          rel="noreferrer noopener"
          sx={{ display: 'flex' }}
        >
          <HelpIcon
            sx={{
              width: 16,
              height: 16,
              color: theme => alpha(theme.palette.common.white, 0.6),
              '&:hover': { color: 'white' },
            }}
          />
        </Link>
      )}
    </Stack>
  );
};

export default TooltipItem;
