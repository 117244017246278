import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { getSecretsKeys } from './getSecretsKeys';
import { invoke } from './invoke';
import { list } from './list';
import { logs } from './logs';
import { patch } from './patch';
import { test } from './test';
import { generatePayload } from './generatePayload';
export { type GeneratePayloadPayload } from './generatePayload';
export { type HooksListQuery } from './list';
export { type HookPatchPayload } from './patch';
export { type TestPayload, type TestResponse } from './test';
export { type LogsQuery } from './logs';
export { type CreateFunctionPayload, type CreateHookPayload } from './create';

export const hooks = {
  test,
  create,
  delete: deleteEndpoint,
  get,
  logs,
  invoke,
  list,
  patch,
  getSecretsKeys,
  generatePayload,
};
