import { Skeleton, Stack, TextField } from '@rossum/ui/material';
import { random } from 'lodash';
import { Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';

export const FormSkeleton = () => {
  const intl = useIntl();

  const descriptionWidths = useRef(
    Array.from({ length: 6 }, () => `${random(80, 100)}%`)
  ).current;

  return (
    <Stack px={4} py={8} direction="row" spacing={4}>
      <ContentGroup
        title={<Skeleton width={200} height={40} />}
        description={
          <Stack>
            {descriptionWidths.map((width, index) => (
              <Fragment
                // eslint-disable-next-line react/no-array-index-key
                key={`${width}-${index}`}
              >
                <Skeleton width={width} />
                {index % 3 === 0 && <br />}
              </Fragment>
            ))}
          </Stack>
        }
      >
        <Stack width={1} spacing={4}>
          <TextField
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.label.label',
            })}
            value=" "
            variant="outlined"
            disabled
          />
          <TextField
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.id.label',
            })}
            value=" "
            variant="outlined"
            disabled
          />
        </Stack>
      </ContentGroup>
    </Stack>
  );
};
