import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { Stack, Typography } from '@rossum/ui/material';

const TILE_HEIGHT = 36;

type SchemaSectionTileContentProps = {
  field: SchemaItem | SchemaSection;
};

export const SchemaSectionTileContent = ({
  field,
}: SchemaSectionTileContentProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        height: TILE_HEIGHT,
      }}
      data-cy="section-tile"
    >
      <Stack
        direction="row"
        spacing={2}
        tabIndex={-1}
        sx={{
          color: t => t.palette.text.primary,
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Stack flex={1} data-cy="section-tile-description">
          <Typography variant="h5">{field.label}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
