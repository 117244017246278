import { ActionType, createAction } from 'typesafe-actions';
import { ProductTourName } from '../../../types/productTour';

export const showProductTourStep = createAction(
  'SHOW_PRODUCT_TOUR_STEP',
  (payload: { tourName: ProductTourName; stepIndex: number }) => payload
)<{ tourName: ProductTourName; stepIndex: number }>();

export const finishProductTourAction = createAction(
  'FINISH_PRODUCT_TOUR',
  (payload: { tourName: ProductTourName; currentStepIndex: number }) => payload
)();

export const skipProductTourAction = createAction(
  'SKIP_PRODUCT_TOUR',
  (payload: { tourName: ProductTourName; currentStepIndex: number }) => payload
)();

export type ProductTourActions = ActionType<
  | typeof showProductTourStep
  | typeof skipProductTourAction
  | typeof finishProductTourAction
>;
