import { InfoOutlined } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { boldText, linebreak } from '../../../lib/formaterValues';
import styles from '../styles.module.sass';
import { GraphName } from '../types';

export type GraphsContainerCustomWidth = 'Double' | 'Full';

type GraphsContainerProps = {
  children: ReactNode;
  graphName?: GraphName;
  className?: string;
  customWidth?: GraphsContainerCustomWidth;
};

const GraphsContainer = ({
  children,
  graphName,
  className,
  customWidth,
}: GraphsContainerProps) => {
  const intl = useIntl();

  return (
    // TODO: Rewrite GraphContainer to the reusable styled component
    <div
      className={clsx(
        styles.GraphContainer,
        customWidth && styles[`GraphContainer${customWidth}`],
        className
      )}
    >
      {graphName && (
        <div className={styles.Title}>
          <div>
            <FormattedMessage id={`components.graphs.${graphName}.title`} />
          </div>
          <Tooltip
            title={
              intl.formatMessage(
                {
                  id: `components.graphs.${graphName}.tooltip`,
                },
                {
                  linebreak,
                  boldText,
                }
              ) ?? ''
            }
            placement="top"
            componentsProps={{
              tooltip: { sx: { textAlign: 'left' } },
            }}
          >
            <InfoOutlined
              fontSize="small"
              color="disabled"
              sx={{ ml: 0.5, cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      )}
      <div className={styles.GraphContent}>{children}</div>
    </div>
  );
};

export default GraphsContainer;
