import { endpoints } from '@rossum/api-client';
import { LogsQuery } from '@rossum/api-client/hooks';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

export const useRequestLogs = (query: LogsQuery = {}) => {
  return useQuery({
    queryKey: ['hooks', 'logs', query] as const,
    queryFn: () => api.request(endpoints.hooks.logs(query)),
    cacheTime: 0,
  });
};
