// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const uploadSchema = z.object({
  id: z.number(),
  url: urlSchema,
  organization: urlSchema,
  creator: urlSchema.nullable(),
  createdAt: z.string(),
  queue: urlSchema,
  email: urlSchema.nullable(),
  documents: z.array(urlSchema),
  additionalDocuments: z.array(urlSchema),
  annotations: z.array(urlSchema),
});
