// Generated by ts-to-zod
import { z } from 'zod';

export const gridSchema = z
  .object({
    parts: z.array(
      z.object({
        page: z.number(),
        columns: z.array(
          z.object({
            leftPosition: z.number(),
            schemaId: z.string().nullable(),
            headerTexts: z.array(z.string()),
          })
        ),
        rows: z.array(
          z.object({
            topPosition: z.number(),
            type: z.string().nullable(),
          })
        ),
        width: z.number(),
        height: z.number(),
      })
    ),
  })
  .nullable();
