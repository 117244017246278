// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const engineSchema = z.object({
  id: idSchema,
  url: urlSchema,
  name: z.string(),
  description: z.string().optional(),
  type: z.union([z.literal('extractor'), z.literal('splitter')]),
  learningEnabled: z.boolean(),
  agendaId: z.string(),
});
