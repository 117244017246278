import { DecodingError } from './decodingError';
import { HttpError } from './httpError';
import { RequestError } from './requestError';

export * from './httpError';
export * from './decodingError';
export * from './requestError';

/**
 * @noSchema
 */
export type ElisClientError = RequestError | HttpError | DecodingError;

export const isElisClientError = (e: unknown): e is ElisClientError =>
  e instanceof RequestError ||
  e instanceof HttpError ||
  e instanceof DecodingError;
