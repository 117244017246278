import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Schema } from '../models/schema';
import { schemaSchema } from '../models/schema.schema';
import { schemaGetQuerySchema } from './get.schema';

export type SchemaGetQuery = Partial<{
  fields: ('id' | 'name' | 'url' | 'queues' | 'metadata' | 'content')[];
}>;

export const get = (schemaId: ID, query: SchemaGetQuery = {}) => {
  return {
    endpoint: `/schemas/${schemaId}`,
    method: 'GET',
    responseSchema: schemaSchema,
    query,
    querySchema: schemaGetQuerySchema,
  } as const;
};
