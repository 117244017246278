import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import {
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@rossum/ui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  StepWorkflowInitialState,
  useStepWorkflow,
} from '../../../../../../components/UI/StepWorkflow/useStepWorkflow';
import FieldsMappingStep from './components/FieldsMappingStep';
import IntegrityValidationStep from './components/IntegrityValidationStep';
import QueueSelectionStep from './components/QueueSelectionStep';

const stepKeys = [
  'queuesSelection',
  'fieldsMapping',
  'integrityValidation',
] as const;

// helpers for resolving initial StepWorkflow state
const isCompleted = (stepKey: string, engineSchema?: DedicatedEngineSchema) => {
  switch (stepKey) {
    case 'queuesSelection': {
      return (engineSchema?.content?.trainingQueues?.length ?? 0) > 0;
    }
    case 'fieldsMapping': {
      return (engineSchema?.content?.fields?.length ?? 0) > 0;
    }
    case 'integrityValidation': {
      return false;
    }
    default: {
      return false;
    }
  }
};

const getActiveStep = (engineSchema?: DedicatedEngineSchema) => {
  if (!engineSchema?.content?.trainingQueues?.length) {
    return 0;
  }
  if (!engineSchema?.content?.fields?.length) {
    return 1;
  }
  return 2;
};

type DedicatedEngineSchemaSetupProps = {
  engine: DedicatedEngine;
  engineSchema: DedicatedEngineSchema;
};

const DedicatedEngineSchemaSetup = ({
  engine,
  engineSchema,
}: DedicatedEngineSchemaSetupProps) => {
  const intl = useIntl();

  const initialStepsState: StepWorkflowInitialState = useMemo(() => {
    return {
      activeStep: getActiveStep(engineSchema),
      steps: stepKeys.map(key => ({
        key,
        label: intl.formatMessage({
          id: `containers.settings.engineDetail.engineSchemaSetup.stepLabels.${key}`,
        }),
        completed: isCompleted(key, engineSchema),
      })),
    };
  }, [engineSchema, intl]);

  const { state, goToStep, complete, unComplete, setError } =
    useStepWorkflow(initialStepsState);

  const completeIntegrityValidation = useCallback(() => {
    setError(2, false);
    complete(2);
  }, [complete, setError]);

  const failIntegrityValidation = useCallback(() => {
    unComplete(2);
    setError(2, true);
  }, [setError, unComplete]);

  const { activeStep, steps } = state;

  // scrolling the main content area into view when switching steps
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeStep]);

  return (
    <Stack spacing={3} sx={{ pt: 3 }} ref={elementRef}>
      <Stack spacing={3} justifyContent="center" sx={{ p: 3, pb: 1 }}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step, i) => (
            <Step key={step.key} completed={step.completed}>
              <StepButton onClick={() => goToStep(i)}>
                <StepLabel error={step.error}>{step.label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack spacing={2}>
        {activeStep === 0 ? (
          <QueueSelectionStep
            engine={engine}
            engineSchema={engineSchema}
            onNext={() => complete(0)}
            onError={() => setError(0, true)}
          />
        ) : activeStep === 1 ? (
          <FieldsMappingStep
            engine={engine}
            engineSchema={engineSchema}
            onPrevious={() => goToStep(0)}
            onNext={() => complete(1)}
          />
        ) : activeStep === 2 ? (
          <IntegrityValidationStep
            key={2}
            engine={engine}
            engineSchema={engineSchema}
            onComplete={completeIntegrityValidation}
            onError={failIntegrityValidation}
            onPrevious={() => goToStep(1)}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default DedicatedEngineSchemaSetup;
