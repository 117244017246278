import { PersonAddOutlined } from '@rossum/ui/icons';
import { Button, Dialog } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../../ui/dialog-title/DialogTitle';
import InviteColleagueContent from './InviteColleagueContent';

const DIALOG_MAX_WIDTH = 448;

const InviteColleague = () => {
  const [open, setOpen] = useState(false);

  const intl = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<PersonAddOutlined />}
        onClick={() => setOpen(true)}
        variant="outlined"
        color="secondary"
        data-cy="invite-colleague-btn"
      >
        {intl.formatMessage({ id: 'components.inviteColleague.button' })}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: DIALOG_MAX_WIDTH, position: 'fixed' },
          elevation: 2,
        }}
      >
        {open && (
          <>
            <DialogTitle
              title={intl.formatMessage({
                id: 'components.inviteColleague.title',
              })}
              onClose={handleClose}
              closeDataCy="invite-colleague-close-icon"
            />
            {open && <InviteColleagueContent />}
          </>
        )}
      </Dialog>
    </>
  );
};

export default InviteColleague;
