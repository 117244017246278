import { alpha, Theme } from '@rossum/ui/material';

export const LIST_ITEM_PADDINGS = {
  px: 2,
  py: 1.5,
};

export const getSidebarListItemStyles = (active: boolean) => ({
  backgroundColor: (t: Theme) =>
    active
      ? alpha(
          t.palette.common[t.palette.mode === 'dark' ? 'white' : 'black'],
          0.16
        )
      : 'transparent',
  borderLeft: (t: Theme) =>
    `4px solid ${active ? t.palette.primary.main : 'transparent'}`,
  transition: (t: Theme) =>
    t.transitions.create('all', {
      duration: t.transitions.duration.shortest,
      easing: t.transitions.easing.easeInOut,
    }),
});
