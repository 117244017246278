// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointSchema } from './schemaDatapoint.schema';
import {
  schemaSimpleMultivalueSchema,
  schemaTableMultivalueSchema,
} from './schemaMultivalue.schema';
import { schemaTupleSchema } from './schemaTuple.schema';

export const schemaItemSchema = z.union([
  schemaDatapointSchema,
  schemaTupleSchema,
  schemaSimpleMultivalueSchema,
  schemaTableMultivalueSchema,
]);
