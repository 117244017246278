import { styled } from '@rossum/ui/material';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.light,
      textDecoration: !disabled ? 'underline' : 'none',
    },
  })
);

export default StyledLink;
