import { combineEpics } from 'redux-observable';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { apiUrl } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import { logoutUser } from '../auth/actions';
import { fetchUserFulfilled } from '../user/actions';
import { isActionOf, makeEpic } from '../utils';
import { fetchGroupsFulfilled } from './actions';
import { FetchGroupsFulfilledPayload } from './types';

const fetchGroupsEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchUserFulfilled)),
    mergeMap(() =>
      authGetJSON$<FetchGroupsFulfilledPayload>(`${apiUrl}/groups`).pipe(
        takeUntil(action$.pipe(filter(isActionOf(logoutUser)))),
        map(fetchGroupsFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

export default combineEpics(fetchGroupsEpic);
