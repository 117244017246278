import { endpoints, ID } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const QUERY_KEY_EMAIL_THREADS_COUNTS = 'email-threads-counts';

export const useFetchEmailThreadsCounts = ({
  queueId,
}: {
  queueId: ID | undefined;
}) => {
  const api = useApiClient();

  return useQuery({
    queryKey: [QUERY_KEY_EMAIL_THREADS_COUNTS, queueId] as const,

    queryFn: () => {
      if (!queueId) {
        throw new Error('QueueId is required for counts fetching.');
      }

      return api.request(endpoints.emailThreads.counts({ queue: queueId }));
    },
    enabled: !!queueId,
  });
};
