import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineFieldKeys } from '../keys';

export const useEngineField = (id: number) => {
  return useQuery({
    queryKey: engineFieldKeys.detail(id),
    queryFn: () => api.request(endpoints.engineFields.get(id)),
    enabled: id > 0,
  });
};
