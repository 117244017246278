// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const purgePayloadSchema = z
  .object({
    annotations: z.array(urlSchema),
    queue: z.string(),
  })
  .partial();
