import { z } from 'zod';
import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  uploadsPostResponseSchema,
  uploadsPostQuerySchema,
} from './post.schema';

export type UploadsPostQuery = {
  queue: number;
};

export type UploadsPostResponse = {
  url: Url;
};

export const post = (
  queueId: number,
  data: unknown,
  name: string = '',
  signal?: AbortSignal
) => {
  const encodedFilename = encodeURIComponent(name);
  // replace strips trailing slash from the endpoint URL in case of missing filename
  const endpoint = `/uploads/${encodedFilename}`.replace(/\/$/, '');
  return {
    endpoint,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    query: { queue: queueId },
    querySchema: uploadsPostQuerySchema,
    responseSchema: uploadsPostResponseSchema,
    payloadSchema: z.unknown(),
    payload: data,
    signal,
  } as const;
};
