import { create } from './create';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';

export const deleteRecommendations = {
  create,
  patch,
  get,
  list,
};
