import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import IconEditDocument from '../../../components/icons/IconEditDocument';
import { EditDocumentsRestrictor } from '../../../components/Restrictors';
import { constructDocumentUrl } from '../../../lib/url';
import styles from './style.module.sass';

export const EditModeButton = ({ annotationId }: { annotationId: number }) => {
  const history = useHistory();
  return (
    <EditDocumentsRestrictor>
      <button
        type="button"
        onClick={() => {
          history.push(
            constructDocumentUrl({ id: annotationId, nestedPath: '/edit' })
          );
        }}
        className={clsx(styles.Button, styles.StartEditMode)}
        data-cy="start-edit-mode-button"
      >
        <IconEditDocument
          className={styles.EditIconButton}
          viewBoxHeight={20}
          viewBoxWidth={20}
        />
      </button>
    </EditDocumentsRestrictor>
  );
};
