import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Group } from '../models';
import { groupSchema } from '../models/group.schema';

export const list = () => {
  return {
    endpoint: `/groups`,
    method: 'GET',
    responseSchema: listResponse(groupSchema),
  } as const;
};
