import { InfoOutlined } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledToggle from '../../../../../components/ReactHookForm/Toggle';
import TranslatedTooltip from '../../../../../components/UI/Tooltip/TranslatedTooltip';
import { CONFIRMED_STATE_LINK } from '../../../../../constants/values';
import { linebreak, link } from '../../../../../lib/formaterValues';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';
import QueueSettingsLabel from './QueueSettingsLabel';

type ConfirmStateProps = {
  control: Control<QueueSettingsData>;
  useConfirmedState: boolean;
};

const ConfirmState = ({ control, useConfirmedState }: ConfirmStateProps) => {
  const intl = useIntl();

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.confirmedState',
      })}
      tooltip={
        <Tooltip
          placement="top"
          title={
            intl.formatMessage(
              {
                id: 'containers.settings.queues.confirmedState.headerTooltip',
              },
              {
                link: link(`${CONFIRMED_STATE_LINK}`),
                linebreak,
              }
            ) ?? ''
          }
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      }
    >
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.confirmedState.label"
            tooltipId="containers.settings.queues.confirmedState.tooltip"
          />
        </div>
        <div className={styles.ToggleField}>
          <ControlledToggle<QueueSettingsData>
            control={control}
            name="useConfirmedState"
          />
        </div>
      </div>
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.confirmedStateHideButton.label"
            tooltipId="containers.settings.queues.confirmedStateHideButton.tooltip"
          />
        </div>
        <div className={styles.ToggleField}>
          <TranslatedTooltip
            disabled={useConfirmedState}
            translationId="containers.settings.queues.confirmedStateHideButton.disabled.tooltip"
          >
            <ControlledToggle<QueueSettingsData>
              control={control}
              disabled={!useConfirmedState}
              name="hideExportButton"
            />
          </TranslatedTooltip>
        </div>
      </div>
    </PaperSection>
  );
};

export default ConfirmState;
