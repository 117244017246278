import {
  annotationsSearchPayloadSchema,
  annotationsSearchQuerySchema,
} from './search.schema';
import { annotationSchema } from '../models';
import { listResponse } from '../../utils/listResponse';
import { annotationListSideloadSchema } from '../models/annotationListSideload.schema';

export type AnnotationsSearchQuery = Partial<{
  pageSize: number;
  searchAfter: string;
  ordering: string;
  'content.schema_id': string[];
}>;

// $ signs get stripped out during camelToSnake conversion from operators
// using simplified AnnotationsSearchPayload type and schema in request for now
// query is validated in the component level and is not used in the request in case of invalid shape
type QueryConditionModel = Record<string, unknown>;

export type AnnotationsSearchPayload = {
  query: QueryConditionModel;
  queryString?: {
    string: string;
  };
};

export const search = (
  query: AnnotationsSearchQuery = {},
  payload: AnnotationsSearchPayload
) => {
  return {
    endpoint: `/annotations/search`,
    method: 'POST',
    responseSchema: listResponse(annotationSchema),
    query,
    querySchema: annotationsSearchQuerySchema,
    payload,
    payloadSchema: annotationsSearchPayloadSchema,
    sideloadSchema: annotationListSideloadSchema,
  } as const;
};
