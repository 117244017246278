import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import * as R from 'remeda';
import { flattenContent } from './flattenContent';
import { flattenSchema } from './flattenSchema';
import { useApiClient } from './useApiClient';

type UseValidationScreenHooksParams = {
  annotationId: number;
};

export const useValidationScreenHooks = ({
  annotationId,
}: UseValidationScreenHooksParams) => {
  const apiClient = useApiClient();
  const { data } = useQuery({
    queryKey: ['mini-validation-screen', 'annotation', annotationId] as const,
    queryFn: async () => {
      const annotation = await apiClient.request(
        endpoints.annotations.get(annotationId)
      );

      const pages = await apiClient.request(
        endpoints.pages.list({ annotation: annotation.id, id: [], number: [] })
      );

      const schema = await apiClient.request(
        endpoints.schemas.get(getIDFromUrl(annotation.schema))
      );

      return { annotation, pages, schema };
    },
  });

  const queryClient = useQueryClient();

  const { data: content } = useQuery({
    queryKey: ['mini-validation-screen', 'content', annotationId] as const,
    queryFn: () => {
      return apiClient.request(endpoints.annotations.getContent(annotationId));
    },
  });

  const datapoints = content ? flattenContent(content.results, null) : [];

  const schemaMap =
    data && data.schema.content
      ? R.indexBy(flattenSchema(data.schema.content, null), s => s.id)
      : {};

  const { mutate: start } = useMutation({
    mutationFn: (payload: { annotationId: number }) => {
      return apiClient.request(
        endpoints.annotations.start(payload.annotationId, {
          statuses: ['to_review', 'reviewing'],
        })
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'annotation',
        annotationId,
      ]);
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'content',
        annotationId,
      ]);
    },
  });

  const { mutate: cancel } = useMutation({
    mutationFn: (payload: { annotationId: number }) => {
      return apiClient.request(
        endpoints.annotations.cancel(payload.annotationId)
      );
    },
  });

  useEffect(() => {
    start({ annotationId });

    return () => cancel({ annotationId });
  }, [annotationId, cancel, start]);

  return {
    schemaMap,
    datapoints,
    annotation: data?.annotation,
    pages: data?.pages,
  };
};
