import { InfoOutlined } from '@rossum/ui/icons';
import { Stack, Tooltip, Typography } from '@rossum/ui/material';
import { IntlShape, useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../../../i18n';
import FormLabel from '../../../../User/components/FormLabel';

type Props = {
  titleId: LocalizationKeys;
  tooltipId?: LocalizationKeys;
  tooltipValues?: Parameters<IntlShape['formatMessage']>['1'];
};

const QueueSettingsLabel = ({ titleId, tooltipId, tooltipValues }: Props) => {
  const intl = useIntl();
  return (
    <FormLabel>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography>{intl.formatMessage({ id: titleId })}</Typography>
        {tooltipId && (
          <Tooltip
            placement="top"
            title={intl.formatMessage({ id: tooltipId }, tooltipValues) ?? ''}
          >
            <InfoOutlined />
          </Tooltip>
        )}
      </Stack>
    </FormLabel>
  );
};

export default QueueSettingsLabel;
