import { endpoints, Url } from '@rossum/api-client';
import { EngineFieldCreatePayload } from '@rossum/api-client/engineFields';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineFieldKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const createEngineFieldKey = () =>
  mutationKey(['create_engine_field'] as const);

export const useCreateEngineField = (engine: Url) => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: createEngineFieldKey(),
    mutationFn: (payload: Omit<EngineFieldCreatePayload, 'engine'>) =>
      api.request(endpoints.engineFields.create({ ...payload, engine })),
    onSuccess: field => {
      showToastMessage('engineFieldCreated');
      // In theory, here we can invalidate just fields for that one engine. But is that worth it?
      queryClient.invalidateQueries(engineFieldKeys.lists());
      queryClient.setQueryData(engineFieldKeys.detail(field.id), field);
    },
  });
};
