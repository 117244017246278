// Generated by ts-to-zod
import { z } from 'zod';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const emailThreadsListQuerySchema = z
  .object({
    queue: z.number(),
    hasRootEmail: z.boolean(),
    hasReplies: z.boolean(),
    hasNewReplies: z.boolean(),
    createdAtBefore: z.string(),
    createdAtAfter: z.string(),
    lastEmailCreatedAtBefore: z.string(),
    lastEmailCreatedAtAfter: z.string(),
    recentWithNoDocumentsNotReplied: z.boolean(),
    search: z.string(),
    ordering: z.string(),
  })
  .partial()
  .and(paginationQuerySchema);
