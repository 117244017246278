// Generated by ts-to-zod
import { z } from 'zod';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const engineListQuerySchema = z
  .object({
    type: z.union([z.literal('extractor'), z.literal('splitter')]),
    ordering: z.array(
      z.union([
        z.literal('id'),
        z.literal('-id'),
        z.literal('type'),
        z.literal('-type'),
      ])
    ),
  })
  .partial()
  .and(paginationQuerySchema);
