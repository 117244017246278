export const extensionFunctionType = 'function';
export const extensionWebhookType = 'webhook';

export const rossumStore = 'rossum_store';
export const customExtension = 'custom';

export const runtimes = [
  'nodejs18.x',
  'python3.12',
  'python3.8',
  'nodejs12.x',
] as const;
