import { HourglassTop, LockOutlined } from '@rossum/ui/icons';
import { Box, CircularProgress } from '@rossum/ui/material';
import { DisplayedStatus, StatusStyles } from './types';

const statusStyles: StatusStyles = {
  created: {},
  automaticallyDeleted: { backgroundColor: 'other.muted' },
  exported: { backgroundColor: 'success.main' },
  deleted: { backgroundColor: 'other.muted' },
  confirmed: { border: theme => `1px solid ${theme.palette.text.disabled}` },
  rejected: {
    border: theme => `1px solid ${theme.palette.other.muted}`,
  },
  failedExport: { backgroundColor: 'error.main' },
  failedImport: { backgroundColor: 'error.main' },
  postponed: { backgroundColor: theme => theme.palette.text.disabled },
  reviewing: { border: theme => `2px solid ${theme.palette.primary.main}` },
  toReview: { backgroundColor: 'primary.main' },
  split: { backgroundColor: 'info.main' },
  inWorkflow: {},
  exporting: {},
  importing: {},
};

type StatusIndicatorProps = {
  status: DisplayedStatus;
  isOwned: boolean;
};

const StatusIndicator = ({ status, isOwned }: StatusIndicatorProps) => {
  const otherStatuses = (
    <Box p="5px">
      <Box sx={statusStyles[status]} borderRadius="50%" width={9} height={9} />
    </Box>
  );

  switch (status) {
    case 'importing':
    case 'exporting':
      return <CircularProgress color="secondary" size={18} />;
    case 'reviewing':
      if (!isOwned) {
        return <LockOutlined fontSize="small" color="primary" />;
      }
      return otherStatuses;
    case 'inWorkflow':
      return <HourglassTop fontSize="small" color="warning" />;
    default:
      return otherStatuses;
  }
};

export default StatusIndicator;
