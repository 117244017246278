import { MoreVert } from '@rossum/ui/icons';
import {
  Button,
  ButtonProps,
  DialogActions,
  Menu,
  MenuItem,
} from '@rossum/ui/material';
import { useContext, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';
import { ActionDialogContext } from '.';

type MoreActionsItem = {
  localizationKey: LocalizationKeys;
  onClick: () => void;
};

type Props = {
  confirmButtonProps: ButtonProps;
  confirmButtonText?: string;
  secondaryButtonProps?: ButtonProps;
  secondaryButtonText?: string;
  cancelButtonText?: string;
  dataCyConfirm?: string;
  dataCySecondary?: string;
  dataCyCancel?: string;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  moreActions?: Array<MoreActionsItem>;
};

const ActionDialogActions = ({
  confirmButtonProps,
  confirmButtonText,
  secondaryButtonProps,
  secondaryButtonText,
  dataCyConfirm,
  dataCyCancel,
  dataCySecondary,
  cancelButtonText,
  hideConfirmButton,
  hideCancelButton,
  moreActions,
}: Props) => {
  const context = useContext(ActionDialogContext);
  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const intl = useIntl();

  return (
    <DialogActions sx={{ pb: 4, pt: 2.5, mr: 4, pr: 0 }}>
      {moreActions && (
        <>
          <Button
            onClick={() => setMenuOpen(true)}
            size="medium"
            variant="outlined"
            color="secondary"
            ref={moreButtonRef}
            // 36px to make it square
            sx={{ minWidth: '36px', px: 0.5 }}
          >
            <MoreVert />
          </Button>
          <Menu
            open={menuOpen}
            anchorEl={moreButtonRef.current}
            onClick={() => {
              setMenuOpen(false);
            }}
            onClose={() => {
              setMenuOpen(false);
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {moreActions.map(({ onClick, localizationKey }) => (
              <MenuItem key={localizationKey} onClick={onClick}>
                {intl.formatMessage({
                  id: localizationKey,
                })}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      {hideCancelButton ? null : (
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={context.onCancel}
          data-cy={dataCyCancel}
        >
          {cancelButtonText ??
            intl.formatMessage({
              id: 'components.actionDialog.actionDialogActions.cancel',
            })}
        </Button>
      )}

      {secondaryButtonProps && secondaryButtonText ? (
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          {...secondaryButtonProps}
          data-cy={dataCySecondary}
        >
          {secondaryButtonText}
        </Button>
      ) : null}
      {hideConfirmButton ? null : (
        <Button
          variant="contained"
          size="medium"
          color={context.dialogColor ?? 'primary'}
          {...confirmButtonProps}
          data-cy={dataCyConfirm}
        >
          {confirmButtonText ??
            intl.formatMessage({
              id: 'components.actionDialog.actionDialogActions.confirm',
            })}
        </Button>
      )}
    </DialogActions>
  );
};

export default ActionDialogActions;
