import { Warning as WarningIcon } from '@rossum/ui/icons';
import { IconButton, Stack, Typography } from '@rossum/ui/material';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { LocalizationKeys } from '../../i18n';
import { State } from '../../types/state';
import styles from './style.module.sass';

type Props = {
  label: LocalizationKeys;
  nPages: number;
  page: number;
  navigateToNext: () => void;
  navigateToPrevious?: () => void;
  navigateToCurrent?: () => void;
  displayNext?: boolean;
  displayPrevious?: boolean;
  nextIcon?: ComponentType<{ onClick?: () => void }>;
  previousIcon?: ComponentType<{ onClick?: () => void }>;
};

const NavigationIndicator = ({
  navigateToPrevious,
  navigateToCurrent,
  label,
  nPages,
  page,
  navigateToNext,
  displayNext,
  displayPrevious,
  nextIcon: NextIcon,
  previousIcon: PreviousIcon,
}: Props) => {
  const triageRulesPageCount = useSelector((state: State) =>
    state.datapoints.matchedTriggerRules.annotationLevel.find(
      rule => rule.type === 'page_count'
    )
  );
  return (
    <Stack
      direction="row"
      alignItems="center"
      color={theme => theme.palette.text.secondary}
      fontSize={13}
    >
      <Stack
        width={25}
        alignItems="center"
        justifyContent="center"
        mr={0.5}
        ml={1.5}
        sx={{
          overflow: 'hidden',
          cursor: 'pointer',
          '&:hover': { fill: theme => theme.palette.text.secondary },
          '&:svg': { transition: 'fill 0.2' },
        }}
      >
        {displayPrevious && !!PreviousIcon && (
          <IconButton
            size="small"
            onClick={navigateToPrevious}
            data-cy="navigate-to-start"
          >
            <PreviousIcon />
          </IconButton>
        )}
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        onClick={navigateToCurrent}
        sx={{
          color: theme =>
            triageRulesPageCount
              ? theme.palette.warning.main
              : theme.palette.text.secondary,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          color={theme =>
            triageRulesPageCount
              ? theme.palette.warning.main
              : theme.palette.text.primary
          }
        >
          {triageRulesPageCount && (
            <WarningIcon
              fontSize="small"
              sx={{ color: theme => theme.palette.warning.main, mr: 1 }}
            />
          )}
          <Stack spacing={0.5} direction="row">
            <Typography variant="body2">
              <FormattedMessage id={label} />
            </Typography>

            <Stack spacing={0.5} direction="row">
              <Typography
                variant="body2"
                fontWeight="bold"
                data-cy="page-number"
              >
                {page}
              </Typography>
              <Typography variant="body2">/</Typography>
              <Typography variant="body2" data-cy="number-of-pages">
                {nPages}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width={25}
        alignItems="center"
        justifyContent="center"
        direction="row"
        mx={1}
        sx={{
          overflow: 'hidden',
          cursor: 'pointer',
          '&:hover': { fill: theme => theme.palette.text.secondary },
          '&:svg': { transition: 'fill 0.2' },
        }}
      >
        {displayNext && !!NextIcon && (
          <IconButton
            size="small"
            onClick={navigateToNext}
            data-cy="navigate-to-end"
          >
            <NextIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

NavigationIndicator.defaultProps = {
  displayPrevious: true,
  displayNext: true,
  navigateToCurrent: () => {},
  navigateToPrevious: () => {},
  nextIcon: (props: Record<string, unknown>) => (
    <MenuDownIcon {...props} className={styles.Icon} />
  ),
  previousIcon: (props: Record<string, unknown>) => (
    <MenuUpIcon {...props} className={styles.Icon} />
  ),
};

export default NavigationIndicator;
