import { Box, Typography } from '@rossum/ui/material';
import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

type Props = { onRetry: (event: MouseEvent) => void };

const Retry = ({ onRetry }: Props) => {
  const intl = useIntl();
  return (
    <Typography color="text.secondary" variant="caption" textAlign="center">
      {intl.formatMessage({ id: 'components.user.recovery.retry.text' })}
      &nbsp;
      <Box
        component="span"
        onClick={event => onRetry(event)}
        sx={{
          color: 'text.primary',
          textDecoration: 'underline',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'none',
          },
        }}
      >
        {intl.formatMessage({ id: 'components.user.recovery.retry.button' })}
      </Box>
    </Typography>
  );
};

export default Retry;
