import {
  Input,
  Stack,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { FlatContent } from './hooks/flattenContent';
import { FlatSchema } from './hooks/flattenSchema';

type FooterProps = {
  datapoints: FlatContent[];
  schemaMap: Record<string, FlatSchema>;
  activeDatapointId: number | undefined;
  activeTableDatapointId: number | undefined;
  setActiveTableDatapointId: (datapoint: number | undefined) => void;
};

export const Footer = ({
  datapoints,
  schemaMap,
  activeDatapointId,
  activeTableDatapointId,
  setActiveTableDatapointId,
}: FooterProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const activeDatapoint = datapoints.find(dp => dp.id === activeDatapointId);

  return (
    <Stack sx={{ background: theme => theme.palette.grey[900] }}>
      <Stack direction="column" height="100%">
        <Tabs
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={currentTab}
        >
          <Tab
            label={
              <Typography variant="body2" color="text.primary">
                Line items
              </Typography>
            }
            value={0}
            onClick={() => setCurrentTab(0)}
          />
        </Tabs>

        <Stack
          role="tabpanel"
          sx={{
            overflow: 'auto',
            display: currentTab === 0 ? 'flex' : 'none',
            flex: 1,
          }}
        >
          {activeDatapoint?.type === 'multivalue' ? (
            <Table size="small">
              {datapoints
                .filter(dp => dp.parentId === activeDatapoint.id)
                .map(tuple => (
                  <TableRow key={tuple.id}>
                    {datapoints
                      .filter(dp => dp.parentId === tuple.id)
                      .filter(dp => schemaMap[dp.schemaId]?.hidden !== true)
                      .map(dp => (
                        <TableCell
                          key={dp.id}
                          sx={
                            activeTableDatapointId === dp.id
                              ? {
                                  background: theme =>
                                    theme.palette.primary.main,
                                }
                              : undefined
                          }
                        >
                          <Input
                            onFocus={() => setActiveTableDatapointId(dp.id)}
                            sx={{ width: '100%' }}
                            disableUnderline
                            value={dp.content?.value ?? ''}
                          />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </Table>
          ) : (
            <Stack p={2}>
              Select a multivalue in the sidebar to see line items.
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
