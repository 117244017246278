import { SVGProps } from 'react';

const Image = (props: SVGProps<SVGSVGElement>) => (
  <svg width={388} height={340} {...props}>
    <defs>
      <path id="prefix__a" d="M0 .65h387.677V26H0z" />
      <path id="prefix__c" d="M0 338.5h388V.5H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 313.5)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill="#2C2B33"
          d="M387.677 13.325c0 7-86.784 12.675-193.839 12.675C86.784 26 0 20.325 0 13.325 0 6.325 86.784.65 193.838.65c107.055 0 193.84 5.675 193.84 12.675"
          mask="url(#prefix__b)"
        />
      </g>
      <path
        fill="#CECECE"
        d="M248.535 321.127c-2.094.888-4.35 1.674-6.742 2.375-.315.095-.63.188-.954.274-16.531 4.613-39.251 5.212-59.52 4.443l-1.72-.077c-15.009-.632-28.527-1.982-36.963-2.974-3.158-.367-4.732-4.075-2.775-6.596 2.145-2.768 4.733-6.93 7.55-13.184 8.011-17.79-1.106-33.34-1.106-33.34s13.093-56.845 13.945-58.264c.85-1.427 85.893-14.26 80.71 1.47-5.176 15.73-4.947 59.477-3.526 74.284.962 10.083 8.113 19.9 12.574 25.146 1.796 2.102 1.064 5.375-1.473 6.443"
      />
      <path
        fill="#2B2B2B"
        d="M230 234.5s-51.17 60.172-80.08 56.869c0 0-.317-13.793-4.92-18.79 0 0 12.107 2.258 25.628-2.584C184.148 265.152 230 234.5 230 234.5"
      />
      <path
        fill="#EDEDED"
        d="M87.301 150.257c.997.839 1.718 17.356 1.718 17.356L107 158.999l-3.211 6.552L83.254 179.5 52 146.5l35.301 3.757z"
      />
      <path
        fill="#DBAE7D"
        d="M105.541 153.5s-3.85 3.494-4.442 8.5c-.55 4.658 1.38 8.5 1.38 8.5s3.521-2.046 3.521-2.681c0-.635-.459-14.319-.459-14.319"
      />
      <path
        fill="#CECECE"
        d="M184.478 48.411l24.47-2.911L215 53.773 182 60.5zM135.772 48.878c-6.248 4.076-16.328 30.4-15.748 47.223.079 2.283 7.003-5.971 7.003-5.971L153 69.024s-11.659-23.78-17.228-20.146"
      />
      <path
        fill="#FCD6B1"
        d="M160.455 32.42s3.24 9.785 6.545 10.08l-15.318-.272s.374-8.7-2.682-14.728l11.455 4.92z"
      />
      <path
        fill="#EFCAAC"
        d="M165.769 23.486s2.494-4.581.382-4.975c-2.112-.392-7.333 9.501-5.908 10.826 1.426 1.324 5.526-5.85 5.526-5.85"
      />
      <path
        fill="#C1966B"
        d="M159.984 31.466s-9.894-2.364-7.654.305c2.788 3.322 8.67 2.679 8.67 2.679l-1.016-2.984z"
      />
      <path
        fill="#191919"
        d="M165.052 19.185c-2.312 5.31-8.51 7.745-13.837 5.452-3.157-1.359-5.745-3.393-6.5-6.47-.209-.85-.929-2.468-1.415-4.51a8.841 8.841 0 01-.176-.918 7.425 7.425 0 01-.1-.867c0-.033 0-.075-.008-.108-.009-.259-.026-.509-.009-.767.017-1.493.36-2.943 1.08-4.227.076-.15.16-.292.252-.434.008-.017.017-.025.025-.042.084-.133.176-.267.276-.392.294-.417.637-.8 1.022-1.159a13.42 13.42 0 012.37-1.759c.151-.092.294-.175.444-.258.009 0 .009-.009.017-.009.143-.075.293-.15.444-.225 1.19-.575 2.42-.934 3.568-.975.235-.017.46-.017.695-.017.176 0 .36 0 .536.008h.11c.175.009.36.017.535.034 1.918.125 3.635.558 5.067 1.225.16.075.31.15.461.225.05.034.109.059.16.092.158.084.31.175.452.267 1.097.659 1.968 1.476 2.57 2.393 1.056 1.6 2.287 3.985 2.731 6.712.034.192.059.383.084.584.235 1.942.059 4.043-.854 6.145"
      />
      <path
        fill="#0A0A0A"
        d="M155 15.48a4.635 4.635 0 01-1.721-.173c-3.678-1.058-7.38-6.249-9.279-9.287.073-.164.154-.32.244-.474.008-.019.016-.028.024-.046 2.102 3.376 6.697 9.89 10.683 9.443l.049.538zm1-2.453l-.09.473a3.335 3.335 0 01-.309-.058c-4.214-.979-7.15-6.96-8.601-10.685.163-.091.317-.174.48-.257 1.414 3.625 4.287 9.548 8.248 10.477l.272.05m4-3.02l-.093.493-.14-.035C156.535 9.55 154.274 4.407 153 .5c.163 0 .334 0 .497.009 1.274 3.846 3.473 8.772 6.503 9.498m2-.805l-.482.298c-1.649-2-2.784-4.678-3.518-7 .191.074.372.149.553.223.06.033.13.058.19.091.714 2.174 1.78 4.587 3.257 6.388m-9 11.274c-.615.064-1.247 0-1.87-.175-3.376-.883-6.76-4.69-9.014-7.725a8.481 8.481 0 01-.107-.956c0-.037 0-.083-.009-.12 2.547 3.577 7.028 8.838 10.955 8.442l.045.534z"
      />
      <path
        fill="#FCD6B1"
        d="M159.569 33.269c-1.364.53-4.62.174-7.054-1.33-3.435-2.123-6.473-6.963-5.232-14.398 1.241-7.437 6.16-12.783 10.997-11.957 3.453.603 5.832 4.196 6.518 8.923.047.34.092.685.127 1.035.005.127.018.257.027.393.024.322.04.64.045.97.025 1.182-.121 2.393-.223 3.645-.918 11.157-2.185 11.544-5.205 12.719"
      />
      <path
        fill="#C1966B"
        d="M158.66 26.495c.047.014.1-.002.134-.048l1.172-1.529a.174.174 0 00-.01-.22c-.021-.021-2.085-2.219-1.614-3.99.022-.083-.018-.171-.09-.197-.082-.036-.147.021-.17.104-.464 1.747 1.166 3.727 1.594 4.209l-1.085 1.415a.175.175 0 00.012.221.122.122 0 00.056.035"
      />
      <path
        fill="#2D2D2D"
        d="M150 16.452c.006-.24 2.94-1.14 4.504-.917 1.566.224 1.68.936 1.337.844-.344-.092-1.967-.264-2.814-.154-.847.11-3.032.385-3.027.227m15-.952c-.466-.05-.904-.127-1.197-.166-.855-.116-2.237.037-2.579.147-.345.114-.49-.649 1.08-.934.703-.127 1.74.021 2.624.238.046.236.072.473.072.715"
      />
      <path
        fill="#FFF"
        d="M160.739 28.526c-.528.302-1.856.803-4.357.273-.243-.051-.459.136-.356.31.283.477.948 1.195 2.437 1.365 2.075.237 2.465-1.195 2.536-1.839.012-.108-.147-.174-.26-.11"
      />
      <path
        fill="#2D2D2D"
        d="M152.129 18.956l1.823-.456.048.761-2 .239zM153.99 20.431c.053.551-.123 1.03-.394 1.067-.271.038-.534-.379-.587-.93-.052-.55.125-1.028.395-1.066.271-.037.534.379.587.93M160.388 18.5l3.612.248-.107.752-3.893-.524zM161.99 20.57c-.054.552-.317.966-.588.928-.27-.04-.446-.518-.392-1.069.054-.55.318-.965.588-.927.271.04.446.518.392 1.069"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M174.807 98.974s4.595-32.616 7.07-45.223c.564-2.877-.845-5.774-3.422-7.05l-11.07-5.201-17.646.783-8.739 8.33 3.625 50.887 30.182-2.526z"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M151 43.5l6.738-.136 7.278-.15 3.984-.078s-1.352-.913-2.646-2.033l-.057-.05-3.153-2.553-5.733 3.165-4.4-1.834L151 43.5z"
      />
      <path
        fill="#1297C6"
        d="M155.417 98.5l.452-.1c-1.012-9.48.091-55.246.122-55.524l-.222-.054.231-.037-.142-.285-.23.044c-.018.021-.037.125-.055.307l-.028.007.028-.005c-.425 4.14-.962 48.097-.156 55.647m5.146 0l.437-.1c-.981-9.51-.33-55.255-.305-55.531l-.215-.047.223-.037-.137-.285-.223.044c-.016.02-.032.123-.048.306l-.032.008.032-.006c-.355 4.127-.518 48.034.268 55.648"
      />
      <path
        fill="#CECECE"
        d="M182.9 54.092l-4.807 33.55.195 4.32.009.067.025.595v.068l.086 1.905.008.068.026.595v.06l.263 5.875.034.722v.077l.026.603.757 16.903-14.522-.204.578-20.355 1.574-55.86v-.161l.043-1.292V41.5s.008 0 .017.009c.332.076 6.687 1.58 12.659 3.18.289.076.57.152.859.237C184.814 46.023 202 46.65 202 46.65s-18.905 5.844-19.1 7.443M139.543 92.394l.297-3.906-2.895-25.456-.06-.513-.034-.256L135 49.774l.391-.205.781-.402 9.434-4.889h.008l3.04-1.778 3.346 77-14.453-.231 1.206-16.155.043-.667.509-6.753.051-.667.144-1.974zM149 42.335l3.809 5.165 4.407-3.755.328-.309.316.268 4.61 3.61 3.53-6.247-.058-.05-1.018-.815-.521-.415-1.617-1.287s-2.795 1.055-4.56 1.126c-.147.007-.289.007-.43.014-.22.006-.432.006-.645.006-3.394.008-5.464-.734-5.464-.734L149 42.335z"
      />
      <path
        fill="#1297C6"
        d="M158.071 39.507l.078 3.993.398-.302.382.26-.078-3.958c-.266.007-.523.007-.78.007"
      />
      <path
        fill="#2C2B33"
        d="M164.57 21.807c0 .217-.252.393-.562.393h-3.306c-.31 0-.563-.176-.563-.393v-.613c0-.217.253-.394.563-.394h3.306c.31 0 .563.177.563.394v.613zm-7.709 0c0 .217-.252.393-.562.393h-3.307c-.31 0-.563-.176-.563-.393v-.613c0-.217.253-.394.563-.394h3.307c.31 0 .562.177.562.394v.613zm7.147-1.307h-3.306c-.547 0-.992.311-.992.694v.113c-.949-.43-1.93-.22-2.341-.094-.034.01-.05.017-.078.027v-.046c0-.383-.445-.694-.992-.694h-3.307c-.547 0-.992.311-.992.694v.613c0 .382.445.693.992.693h3.307c.547 0 .992-.31.992-.693v-.229c.281-.115 1.411-.5 2.42.109v.12c0 .382.444.693.99.693h3.307c.547 0 .992-.31.992-.693v-.613c0-.383-.445-.694-.992-.694z"
      />
      <path
        fill="#B5B5B5"
        d="M148.969 41.5L141 45.656l5.39 11.694-2.583 2.413 2.553 7.737 3.64-3.228zM166.864 41.5l5.732 1.488-1.798 11.242L173 57.926l-3.523 7.574L166 62.943z"
      />
      <path
        fill="#2C2B33"
        d="M150.778 21.5a.242.242 0 01-.058-.007l-6.556-1.598c-.118-.028-.188-.14-.156-.246.032-.108.155-.17.272-.142l6.556 1.597c.118.028.188.14.156.247a.22.22 0 01-.214.149m13.43-1a.249.249 0 01-.124-.032c-.092-.053-.111-.155-.043-.227l1.585-1.676c.07-.071.2-.086.29-.034.092.054.112.156.043.228l-1.585 1.675a.231.231 0 01-.167.066"
      />
      <path
        fill="#FCD6B1"
        d="M147.936 20.123s-3.549-4.01-4.793-2.113c-1.244 1.899 5.972 9.4 8.38 9.49 2.135.079-3.587-7.377-3.587-7.377"
      />
      <path
        fill="#C1966B"
        d="M144.16 20.496c.02.006.04.005.06-.001a.116.116 0 00.07-.145c-.09-.301-.096-.515-.016-.585.114-.1.43.027.565.104.053.03.12.01.148-.046a.118.118 0 00-.044-.156c-.055-.031-.55-.304-.808-.079-.161.14-.178.413-.052.832a.11.11 0 00.077.076"
      />
      <path fill="#1297C6" d="M158 50a.5.5 0 101 0 .5.5 0 00-1 0" />
      <path
        fill="#2B2B2B"
        d="M268.938 184.974L233.755 207.5 171 55.65s1.982-1.13 6.6-2.337c2.299-.595 5.25-1.216 8.932-1.709a93.735 93.735 0 016.093-.654c2.747-.23 5.784-.383 9.14-.425 1.862-.034 3.835-.034 5.911.008 1.485.026 3.03.068 4.626.136a67.89 67.89 0 015.192.417c39.97 4.598 59.596 41.235 59.596 41.235 24.028 47.176-8.152 92.653-8.152 92.653"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M211 65.786l4.884-8.286 10.839 3.505-2.458 7.969 7.006 3.021 5.632-6.377 8.709 6.168-4.527 6.827 4.283 4.322 4.332-4.041 7.3 8.626-10.238 9.98-24.669-7.461z"
      />
      <path
        fill="var(--color-primary)"
        d="M217 51.06l-4.991 19.44-20.03-1.05-13.354-11.077L177 53.28c2.305-.594 5.265-1.212 8.956-1.704l2.32 8.39 3.872-1.836-.082-7.206c2.753-.23 5.799-.382 9.163-.424l-.333 10.666 2.796 3.814 3.465-14.472c1.488.026 3.037.068 4.637.136a68.45 68.45 0 015.206.415"
      />
      <path
        fill="#FCD6B1"
        d="M210.765 44.519c5.353.327 10.111 6.65 5.143 5.923-4.866-.712-13.908-.869-13.908-.869s2.565-5.433 8.765-5.054"
      />
      <path
        fill="#EDEDED"
        d="M287.623 128.216c-1.903 1.848-5.623 6.198-5.623 6.198l16.754 22.563c6.082 4.695 14.902 2.603 18.273-4.334L328 130.049l-16.348-3.549-4.578 16.63-19.45-14.914z"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M257.905 98.132l6.864-7.163 6.863 7.898 7.346-2.303.393-10.064 10.768 1.078-1.149 10.268 5.405 3.1 7.714-6.226 6.104 7.371-6.837 7.337.955 5.064 8.669 3.21-4.175 10.047-7.359-4.18-2.216 3.68c.715 1.287 4.504 7.507 4.504 7.507l-7.998 4.951-3.911-8.473-3.777 1.086v8.18l-9.53-.26.124-8.32-4.744-1.024-4.911 6.189-7.378-6.427 5.997-6.777-1.196-2.89-7.542 1.381L254 108.733l10.606-1.609-1.124-1.823-5.577-7.17z"
      />
      <path fill="#2B2B2B" d="M288 114.5a6 6 0 11-12 0 6 6 0 1112 0" />
      <path
        fill="#FFF"
        d="M305 243.341l5.28-83.168s-.218-17.038.964-29.397a20.027 20.027 0 0111.553-16.276l.047 1.592 2.809 56.454L329 243.5l-24-.159z"
      />
      <path
        fill="#565656"
        d="M158 61a.5.5 0 101 0 .5.5 0 00-1 0m0 14a.5.5 0 101 0 .5.5 0 00-1 0"
      />
      <path
        fill="#EAEAEA"
        d="M55.136 214.5L82 217.361l-.435 45.139L51 262.104l.615-33.661z"
      />
      <path
        fill="#2C2B33"
        d="M242.447 323.433c-19.179-17.798-22.355-41.177-21.642-57.656.757-17.291 5.784-30.763 6.364-32.266l.146-.393-1.59-.618-.156.403c-.582 1.502-5.702 15.184-6.474 32.777-.736 16.697 2.45 40.426 21.812 58.658l.173.162.229-.061c.33-.088.65-.183.97-.278l.721-.216-.553-.512zm-61.832 5.067l-1.846-.077c11.146-51.8-8.917-58.182-9.118-58.241-.484-.137-.758-.62-.612-1.065.146-.456.649-.713 1.133-.584.896.248 21.835 6.646 10.443 59.967"
      />
      <path
        fill="var(--color-primary)"
        d="M259.036 110.185l4.61 10.802-2.951.638-.846.187-12.377 2.688L234 99.663l7.698-6.09-2.249-3.496-.548-.842-2.086-1.735h15.438l-.556 8.166 3.635 2.245 7.972-2.892L268 106.281z"
      />
      <path fill="#2B2B2B" d="M254 105a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0" />
      <path
        fill="#0C0C0C"
        d="M48.677 326.492s6.477 1.711 11.354 2.598c1.14.208 1.969 1.156 1.969 2.269v.01l-12 .131c-2.363 0-5.81-.141-7.988-1.174l.063 1.043H38l.076-3.42c.03-1.361 1.188-2.449 2.606-2.449l7.995.992z"
      />
      <path
        fill="#5D5D66"
        d="M42.445 207.5l1.302 59.477s-7.358 52.21-7.747 59.48l17.353 1.043 7.59-59.128L62 213.603 42.445 207.5z"
      />
      <path
        fill="#0C0C0C"
        d="M85.651 327.627s3.33.817 7.361 1.481c1.148.189 1.988 1.143 1.988 2.26l-12 .132c-2.363 0-5.81-.142-7.988-1.174l.063 1.043H71l.076-3.42c.03-1.361 1.188-2.449 2.605-2.449l11.97 2.127z"
      />
      <path
        fill="#3B3B42"
        d="M82.055 202.5L87 265.424s-.976 54.46-.702 62.505l-17.092.571 1.067-59.63L64 214.154 82.055 202.5z"
      />
      <path
        fill="#5D5D66"
        d="M82.993 205.243c-.29 7.72-9.03 13.647-19.52 13.237-10.488-.41-18.757-7.002-18.466-14.724.292-7.72 9.03-13.646 19.52-13.236 10.488.41 18.757 7.002 18.466 14.723"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M49.622 199.87s-2.445-22.479-5.249-37.783c-1.511-8.25 1.579-16.426 9.059-19.919l1.432-.668 19.04.165L85 149.527 81.205 202.5l-31.583-2.63z"
      />
      <path fill="#333033" d="M59 198.5h18l-.015 9H59z" />
      <path
        fill="#FCD6B1"
        d="M62.006 122.766s2.264 15.562-6.006 19.44l18 .294s-4.745-7.512-.992-14.816c0 0-8.398-6.569-11.002-4.918"
      />
      <path
        fill="#C1966B"
        d="M72 130.254s-6.24-4.469-4.778-2.021c1.82 3.045 4.399 4.267 4.399 4.267l.379-2.246z"
      />
      <path
        fill="#EDEDED"
        d="M86.997 150.217c.084.25-.086 1.056-.086 1.056l-3.072 38.16.085 1.484.04.682.38 6.676.039.742L88 262.5H77.372S77.166 157.549 75 141.565c-.103-.76 10.887 5.343 11.996 8.652"
      />
      <path
        fill="#191919"
        d="M74.996 114.409c-.224 5.793-5.081 10.307-10.855 10.083-3.414-.13-6.551-1.095-8.36-3.705-.495-.714-1.748-1.966-2.941-3.7-1.89-2.746-2.48-6.166-1.03-9.172.027-.06.061-.122.089-.183.142-.286.283-.57.447-.856.576-1.061 1.26-2.081 2.046-2.918l.027-.027c.018-.018.049-.048.089-.084a8.24 8.24 0 01.541-.514c.224-.19.454-.367.69-.523.028-.02.055-.034.082-.048a.148.148 0 00.034-.027c.265-.163.536-.3.82-.415.86-.346 1.653-.625 2.385-.856.203-.069.393-.13.582-.184 1.877-.558 3.3-.755 4.364-.776.196-.005.372-.005.542 0 1.836.076 2.406.715 2.406.715s.447.4 1.13 1.088c.177.176.36.367.563.577 1.05 1.095 2.433 2.66 3.632 4.406 1.545 2.244 2.805 4.808 2.717 7.119"
      />
      <path
        fill="#0A0A0A"
        d="M60 123.195l-.386.305c-.302-.42-5.843-8.234-6.614-15.147.135-.284.27-.568.424-.853.412 7.007 6.5 15.587 6.576 15.695"
      />
      <path
        fill="#FCD6B1"
        d="M76.72 128.403c-.992 1.265-3.187 2.584-6.275 1.918-4.137-.898-10.44-4.17-12.013-11.544-.148-.7-.262-1.388-.325-2.075-.05-.415-.078-.83-.092-1.238-.242-5.993 2.443-10.993 6.742-11.844 3.626-.707 7.316 1.763 9.767 5.912.17.3.346.606.51.919.056.115.12.23.176.353.142.293.285.578.412.885.468 1.088.85 2.25 1.147 3.476 2.139 8.959 1.07 11.802-.05 13.238"
      />
      <path
        fill="#FCD6B1"
        d="M60.948 120.887s-4.395-2.635-4.923-.641c-.528 1.995 7.325 7.344 8.734 6.056 1.409-1.287-3.81-5.415-3.81-5.415"
      />
      <path
        fill="#191919"
        d="M73.605 106.33l-.18-.02a27.284 27.284 0 00-3.163-.085c-.188 0-.39.013-.586.02a22.697 22.697 0 00-4.048.542c-.181.04-.362.085-.544.137-1.346.366-2.652.908-3.734 1.712-.16.111-.314.236-.46.373a6.33 6.33 0 00-1.69 2.215c-.607 1.326-2.17-2.411-3.21-5.248a75.872 75.872 0 01-.593-1.679c-.244-.719-.397-1.19-.397-1.19l.027-.025c.07-.066.3-.262.643-.536.195-.151.439-.334.719-.53l.083-.059a.151.151 0 00.035-.026c.726-.496 1.654-1.065 2.694-1.503.265-.11.523-.215.781-.313.217-.085.434-.17.643-.242 1.654-.601 3.147-.967 4.599-1.163.202-.033.404-.06.607-.079a23.84 23.84 0 013.685-.104c.188.006.384.013.572.025.58.033 1.173.079 1.772.131 1.11.099 2.01.249 2.339.451 2.1 1.34-.594 7.195-.594 7.195"
      />
      <path
        fill="#C1966B"
        d="M74.031 121.5a.182.182 0 01-.17-.244l.726-1.955c-.715-.304-3.442-1.592-3.587-3.606a.182.182 0 01.169-.195c.099-.005.186.068.194.168.146 2.044 3.487 3.35 3.52 3.363a.18.18 0 01.106.232l-.787 2.119a.182.182 0 01-.17.118"
      />
      <path
        fill="#2D2D2D"
        d="M61.006 117.46c-.145-.472 2.255-3.617 3.981-3.916 1.726-.3 2.294 1.04 1.888 1.02-.407-.018-2.16.412-2.947 1.025-.788.615-2.826 2.181-2.922 1.87M76 110.27c-.477.09-.94.158-1.243.207-.89.14-2.147.75-2.42.982-.276.238-.81-.574.563-1.39.614-.367 1.64-.531 2.609-.568.166.25.332.506.491.768"
      />
      <path
        fill="#FFF"
        d="M75.272 121.706c-.422.76-1.274 1.71-2.954 1.997-.3.052-.424.52-.21.8.47.618 1.2 1.249 2.415.895 1.502-.438 1.598-2.247 1.401-3.53-.063-.407-.467-.499-.652-.162"
      />
      <path fill="#2D2D2D" d="M65 119.016l1.714-1.516.286.527-1.961 1.473z" />
      <path
        fill="#2D2D2D"
        d="M66.874 119.202c.183.527.164 1.087-.042 1.252-.206.165-.522-.129-.706-.655-.183-.528-.164-1.088.042-1.253.207-.165.522.129.706.656M73 115.016l1.714-1.516.286.528-1.961 1.472z"
      />
      <path
        fill="#2D2D2D"
        d="M75.874 114.201c.183.527.164 1.088-.042 1.253-.206.165-.522-.128-.705-.655-.184-.527-.165-1.089.04-1.253.207-.165.523.128.707.655"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M60.312 137.5l7.675 1.185 4.133-.593 2.88 3.893-21 .515s5.625-3.152 6.312-5"
      />
      <path
        fill="#FFF"
        d="M74 141.93l-1.608 4.402-5.702-3.864-5.227 4.032L57 140.103l.72-.627.176-.185.185-.194.175-.185 1.148-1.412s4.168.962 6.917 1.041c2.162.053 5.156-.72 5.156-.72l1.627 2.547.351.564.545.997z"
      />
      <path
        fill="#FFF"
        d="M39 261.59l5.94-75.565-1.975-28.369c-1.09-8.918 6.708-13.854 11.574-15.95.939-.405 3.686-1.206 3.686-1.206l-.395 1.651 2.486 39.052a339.04 339.04 0 010 43.044L57.884 262.5 39 261.59z"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M53.629 197.782a4.17 4.17 0 01-3.015 1.046 4.164 4.164 0 01-2.879-1.371 4.083 4.083 0 01-1.056-2.986l.147-2.636 8.336.434-.149 2.661a4.095 4.095 0 01-1.384 2.852zm1.68-8.14l-.11 1.963-8.335-.435.11-1.987 8.336.459zm.374-.646l-9.006-.496a.351.351 0 00-.243.084.336.336 0 00-.112.23l-.314 5.62a4.738 4.738 0 001.227 3.467 4.83 4.83 0 003.618 1.599 4.84 4.84 0 003.224-1.222 4.753 4.753 0 001.607-3.312l.315-5.62a.333.333 0 00-.316-.35zM84 199.077a3.863 3.863 0 01-1.76.423h-.064c-2.302 0-4.176-2.022-4.176-4.506v-6.153c0-.192.146-.341.316-.341h5.236l-.05.68h-4.87v1.805h4.926l.038.68H78.63v3.329c0 2.11 1.59 3.825 3.545 3.825h.064c.624 0 1.21-.177 1.722-.483l.038.741z"
      />
      <path
        fill="#C1966B"
        d="M57.398 122.5a.13.13 0 01-.095-.04c-.284-.316-.367-.555-.255-.733.177-.284.777-.224.845-.217.067.008.114.063.106.123-.01.06-.069.1-.137.095-.162-.018-.524-.017-.6.106-.055.086.03.264.232.488a.102.102 0 01-.019.154.13.13 0 01-.077.024"
      />
      <path
        fill="#0A0A0A"
        d="M60 117.5c-2.055-3.387-4.234-8.09-3.98-12.475.037-.656.13-1.295.284-1.928 0-.014.007-.028.007-.043.24-.201.486-.388.741-.554v.014c-.45 1.388-.553 2.862-.414 4.358.298 3.265 1.75 6.608 3.267 9.32.015.43.044.869.095 1.308m1-8.408a3.47 3.47 0 00-.254.408c-.613-2.933-.963-6.138-.597-9.084.027-.222.057-.437.096-.65.12-.094.24-.187.355-.266-.047.236-.085.479-.12.723-.393 2.832-.07 5.972.52 8.869"
      />
      <mask id="prefix__d" fill="#fff">
        <use xlinkHref="#prefix__c" />
      </mask>
      <path fill="#CECCCD" mask="url(#prefix__d)" d="M66 207.5h5v-9h-5z" />
      <path
        fill="#FFF"
        d="M253.142 170.148a30.61 30.61 0 01-.077 1.084c-1.035 11.306-10.885 47.932-18.736 59.066-8.124 11.519-59.861 39.247-68.84 41.807-3.498.998-9.04 2.525-15.144 3.131-9.57.956-20.534-.35-27.187-9.53-.77-1.058-1.701-2.68-2.736-4.735-.94-1.886-1.95-4.138-2.984-6.647-.24-.58-.48-1.185-.719-1.8-3.233-8.233-6.44-18.83-7.67-28.624-1.104-8.788-1.642-27.123-2.506-56.465-.017-.478-.025-.955-.042-1.433-.027-.973-.061-1.954-.087-2.952-.025-1.1-.06-2.218-.093-3.345-.086-2.696-.172-5.469-.266-8.327a101.087 101.087 0 013.156-28.633c.154-.572.308-1.143.453-1.706.051-.162.095-.333.137-.495 5.84-20.784 16.35-33.155 16.35-33.155 24.8-35.408 57.296-32.848 57.296-32.848 20.524 1.706 33.351 7.252 54.302 32.421 20.952 25.17 15.82 73.375 15.393 83.186"
        mask="url(#prefix__d)"
      />
      <path
        fill="#CECECE"
        d="M122.824 194.305c-5.816 8.83-1.855 11.93 1.482 14.617 1.266 1.017 6.13 1.48 4.252 1.64-15.832 1.34-8.207 17.125 2.446 35.597.38.653.821 2.131.812 3.072-.008.534 1.01.56.889 1.144-9.144-.187-15.315 2.975-15.42 3.025a.88.88 0 01-1.005-.136c-.086-.212-.173-.432-.26-.651-3.337-8.228-5.654-13.233-7.93-28.803-1.274-8.708-1.665-26.938-2.54-56.079-.017-.473-.026-.948-.043-1.423-.026-.966-.061-1.94-.087-2.932-.026-1.093-.06-2.203-.095-3.32-.087-2.68-.174-5.433-.27-8.272a98.46 98.46 0 013.2-28.438c.155-.567.311-1.135.459-1.694 1.776-.263 3.128-.22 3.128-.22s9.344-9.398 24.954 5.456c8.13 7.737 5.2 29.532 1.517 37.657-3.459 7.636-9.326 20.405-15.489 29.76"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M184 119a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M182 118.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M213 296.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M212 296.5a2 2 0 11-4.001 0 2 2 0 014.001 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M192 318.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M191 318.5a2 2 0 11-3.999 0 2 2 0 013.999 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M237 293.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M236 293.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M215 128a2.5 2.5 0 11-4.999.001A2.5 2.5 0 01215 128"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M213 127.5a1 1 0 11-2 0 1 1 0 012 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M201 121.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M200 121.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M122 113.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M121 113.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M140 114a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M139 115.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3F3F3F"
        d="M241.79 93.5s-14.804 7.026-19.034 26.438c-4.23 19.41 9.789 48.034 29.532 50.562 0 0 4.053-22.483 3.689-28.08-2.256-34.7-14.188-48.92-14.188-48.92"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary)"
        d="M237.831 148.943l9.448 7.47-9.316 8.087s-5.53-4.01-10.646-13.687c-.142-.246-.273-.5-.405-.762a65.86 65.86 0 01-1.594-3.367 13.528 13.528 0 01-.3-.727c-.492-1.278-1.074-3.054-1.487-4.17-1.718-4.653-2.457-8.824-2.528-12.597v-.152a32.842 32.842 0 01.141-3.528c.035-.287.062-.575.097-.863.863-6.92 3.875-12.401 6.63-17.147l9.573 5.65-4.572 9.202 4.804 5.48 11.509-1.775L252 140.5l-11.871 1.252-2.298 7.191zM284 145.5c-.093.661-.306 1.718-.605 3.032a24.81 24.81 0 01-.204.92c-1.646 7.146-5.217 19.979-6.65 20.649-.401.18-11.722 3.84-17.007 5.54-.29.095-.562.18-.81.266-1.151.37-1.858.593-1.858.593L251 160.403l13.786-6.047 3.213 7.997 4.544-3.737 1.168-11.02L284 145.5z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FFF"
        d="M248.982 173.09s20.997-9.539 29.854-1.114c8.855 8.423 5.142 20.777-2.286 29.481-7.428 8.704-28.282 30.043-38.567 30.043-10.284 0-9.84-2.547-7.841-9.005 2-6.459 18.84-49.405 18.84-49.405"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M272.022 197.5a.822.822 0 01-.354-.08.851.851 0 01-.406-1.127c3.444-7.475 4-12.704 1.65-15.541-3.55-4.287-12.764-1.88-12.856-1.856a.841.841 0 01-1.028-.604.85.85 0 01.597-1.038c.42-.113 10.348-2.698 14.576 2.408 2.805 3.386 2.328 9.223-1.417 17.347a.839.839 0 01-.762.491m-22.17 21a.857.857 0 01-.753-.43c-.22-.393-.06-.88.355-1.089 7.392-3.7 12.98-10.12 13.036-10.185a.883.883 0 011.197-.114c.365.281.418.79.12 1.134-.235.273-5.864 6.74-13.558 10.59a.883.883 0 01-.397.094m-16.864-4.826a.866.866 0 01-.215.613c-1.873 2.107-40.66 45.213-68.242 45.213-4.064 0-7.88-.935-11.3-3.067-7.535-4.701-14.65-6.23-20.536-6.35-9.065-.187-15.183 2.984-15.286 3.034-.094.052-.36.299-.687.63-.24-.578-.481-1.181-.722-1.794.31-.17.533-.289.593-.323.103-.06 6.642-3.467 16.291-3.23 6.118.137 13.49 1.743 21.267 6.596 24.566 15.34 73.33-37.43 77.101-41.577-.12-3.51-.189-31.59 19.497-42.919.61.144 1.83.331 2.251.476-20.355 11.363-20.038 42.375-20.012 42.698"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M172.768 225.25c2.676-1.217-18.617-2.727-23.628-5.422-9.69-5.213-10.678 1.976-14.336 1.976-2.748 0-2.33-4.006-5.84-5.068-3.483-1.055-7.417 1.683-9.576 3.074-.658.424-.438 1.378.347 1.517 2.598.46 6.997 1.33 8.633 2.2.533.283 5.425 6.891 13.775 2.432 0 0 27.846.554 30.625-.71m-34.12 12.19c-3.284-.328-5.437-1.904-6.982-3.033l-.287-.21c-.391-.284-.497-.859-.236-1.284.26-.425.791-.54 1.183-.255l.292.213c2.504 1.83 6.289 4.6 14.208 1.089.436-.192.932.034 1.11.508.179.473-.031 1.014-.467 1.208-3.624 1.605-6.504 1.995-8.822 1.764"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3F3F3F"
        d="M232 136.169c0 3.95-2.609 7.284-6.186 8.331-.47-1.275-1.021-3.047-1.414-4.161-1.63-4.643-2.333-8.804-2.4-12.569v-.152c.46-.076.936-.118 1.413-.118 4.75 0 8.587 3.883 8.587 8.669"
        mask="url(#prefix__d)"
      />
      <path
        fill="#CECECE"
        d="M138 209.5c.19-.03 4.45-2.683 8.65-3 5.23-.395 11.35 1.339 11.35 1.339s-8.788-3.472-10.176-4.14c-1.388-.67-4.08.505-5.424 1.328-1.344.823-4.4 4.473-4.4 4.473"
        mask="url(#prefix__d)"
      />
      <path
        fill="#CECECE"
        d="M160.996 208.377c-.05.472-.45.831-.927.77-6.38-.615-7.571-1.351-9.366-2.463-.63-.393-1.345-.848-2.45-1.352-3.25-1.505-5.65.718-7.819 3.122-.663.736-1.106 1.232-1.573 1.446-2.357 1.062-4.884 1.6-7.308 1.6-2.297 0-4.5-.48-6.372-1.446-2.518-1.3-4.169-3.354-4.662-5.783-1.174-5.852 2.059-10.54 5.793-15.955 2.57-3.722 5.47-7.932 7.461-13.244l.757-1.976c4.1-10.738 12.642-33.076 2.706-44.334-5.105-5.775-10.873-8.675-17.16-8.547-3.93.068-7.699 1.3-11.076 3.011.196-.744.391-1.48.587-2.207 3.216-1.454 6.746-2.447 10.455-2.515 6.79-.128 13.017 2.951 18.461 9.128 10.634 12.038 1.838 35.027-2.382 46.08-.272.71-.527 1.368-.748 1.967-2.068 5.501-5.045 9.813-7.666 13.611-3.658 5.306-6.55 9.497-5.52 14.64.39 1.906 1.726 3.54 3.768 4.602 3.403 1.745 8.073 1.677 12.2-.197.17-.085.646-.608 1.02-1.027 1.795-1.993 5.13-5.689 9.792-3.533a22.33 22.33 0 012.637 1.454c1.642 1.019 2.638 1.634 8.627 2.216a.854.854 0 01.765.932m-86.9-66.86s1.64.121 2.412.891c1.826 1.818 6.492 8.867 6.492 8.867l-5.954 7.143 4.903 1.919-3.839 14.819-2.2 10.344L74 141.552c-.002-.048.063-.07.095-.034M58.415 153.5l-4.617 8.31 5.053 7.239L47 162.112l12.98 24.385c.05.07-.007-1.096-.037-1.562L58.416 153.5z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FCD6B1"
        d="M289.046 131.516c1.025 7.088 9.43 10.683 11.34 9.871 1.912-.811 4.614-4.512 4.614-4.512s-5.643-5.171-6.469-5.672c-.824-.498.337 2.992 1.237 3.655.9.662-11.622-9.563-10.722-3.342"
        mask="url(#prefix__d)"
      />
      <path
        fill="#1297C6"
        mask="url(#prefix__d)"
        d="M67.085 137.508L67 142.5l.511-.631.489.531-.152-4.9z"
      />
      <path
        fill="#CECECE"
        mask="url(#prefix__d)"
        d="M338 331.766l-5.889.734L273 189.607l5.321-2.107z"
      />
      <path
        fill="#CECECE"
        mask="url(#prefix__d)"
        d="M291.112 221.5L289 216.537l20.887-8.037 2.113 4.962zM300.113 243.5L298 238.537l20.887-8.037 2.113 4.962zM308.112 269.5L306 264.537l20.886-8.037 2.114 4.963zM321.112 293.5L319 288.538l20.887-8.038 2.113 4.963z"
      />
      <path
        fill="#CECECE"
        mask="url(#prefix__d)"
        d="M360 325.855l-5.923.645L295 183.608l5.318-2.108z"
      />
      <path
        fill="#CECECE"
        mask="url(#prefix__d)"
        d="M329.112 315.5L327 310.537l20.886-8.037 2.114 4.963z"
      />
      <path
        fill="#CCC"
        mask="url(#prefix__d)"
        d="M343 190.408V243.5l-23.427-.032L319 199.86l8.525-18.36z"
      />
      <path
        fill="#0C0C0C"
        d="M346.323 299.492s-6.477 1.711-11.355 2.598c-1.138.208-1.968 1.156-1.968 2.269v.01l12 .131c2.364 0 5.81-.141 7.989-1.174l-.064 1.043H357l-.075-3.42c-.03-1.361-1.189-2.449-2.606-2.449l-7.996.992z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#5D5D66"
        d="M352.526 181.5l-1.307 59.476s7.39 52.21 7.781 59.48l-17.43 1.044-7.623-59.129L332 187.325l20.526-5.825z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3B3B42"
        d="M311.068 259.323s-3.266.998-7.247 1.897c-1.132.256-1.899 1.219-1.815 2.28l12.007-.674c2.363-.157 5.798-.52 7.897-1.647l.016.995 4.074-.27-.336-3.245c-.134-1.291-1.374-2.248-2.79-2.152l-11.806 2.816z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3B3B42"
        d="M318.978 174.5L304 199.128s6.971 51.398 6.695 59.372l16.078-.108-5.193-55.46L332 189.777v-3.033L318.978 174.5z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#5D5D66"
        d="M318.018 178.17c.29 7.388 7.849 9.698 13.31 9.284 10.477-.795 19.957-2.884 19.665-10.271-.29-7.386-9.04-13.057-19.542-12.664-10.502.393-13.725 6.265-13.433 13.652"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M346.124 172.928s-.078-32.766 2.808-46.161c.709-3.294-4.275-9.907-7.38-11.308l-.845-.86-19.557-.099-.468.38c-4.572 4.095-3.685 14.063-3.47 22.549l.841 38.071 28.071-2.572z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FCD6B1"
        d="M332.994 95.766s-2.264 15.562 6.006 19.44l-18 .294s4.745-7.512.992-14.816c0 0 8.398-6.569 11.002-4.918"
        mask="url(#prefix__d)"
      />
      <path
        fill="#C1966B"
        d="M323 103.254s6.24-4.468 4.778-2.022c-1.82 3.047-4.398 4.268-4.398 4.268l-.38-2.246z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#191919"
        d="M321.025 89.212c.226 5.792 3.923 9.503 9.738 9.278 3.44-.128 6.6-1.093 8.422-3.704.498-.714 1.76-1.965 2.962-3.698 1.904-2.747 2.498-6.167 1.037-9.172-.028-.062-.062-.123-.089-.184-.143-.285-.286-.57-.45-.857-.58-1.06-1.27-2.08-2.06-2.916l-.029-.027a3.142 3.142 0 00-.09-.085 8.291 8.291 0 00-.544-.514 7.489 7.489 0 00-.696-.523c-.028-.02-.055-.034-.082-.048-.015-.006-.027-.02-.034-.027a5.54 5.54 0 00-.826-.415 33.416 33.416 0 00-2.402-.856c-.206-.068-.397-.13-.588-.184-1.89-.557-3.324-.754-4.395-.775a7.28 7.28 0 00-.546 0c-1.85.075-2.423.714-2.423.714s-.45.401-1.14 1.088c-.177.177.204 1.267-.002 1.479-1.057 1.094-3.014 1.757-4.222 3.504-1.557 2.244-1.63 5.61-1.541 7.922"
        mask="url(#prefix__d)"
      />
      <path
        fill="#0A0A0A"
        d="M336 96.195l.386.305c.302-.42 5.843-8.234 6.614-15.146-.135-.285-.27-.57-.425-.854-.412 7.007-6.498 15.587-6.575 15.695"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FCD6B1"
        d="M336.985 89.476c-.009.401-.045.819-.09 1.237a16.893 16.893 0 01-.333 2.072c-1.59 7.367-7.973 10.64-12.153 11.536-3.129.665-5.35-.648-6.357-1.918-.422-.537-.782-1.177-.943-2.243h-.009a9.176 9.176 0 01-.099-1.5c0-.197.01-.41.018-.631.018-.418.054-.887.117-1.399.18-1.688.583-3.88 1.285-6.787.305-1.22 1.196-3.794 2.194-6.208 1.896-4.57 5.88-6.71 9.547-6.002 4.35.852 7.066 5.849 6.823 11.843"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FCD6B1"
        d="M333.051 94.888s4.396-2.637 4.924-.642c.528 1.995-7.324 7.344-8.734 6.056-1.409-1.287 3.81-5.414 3.81-5.414"
        mask="url(#prefix__d)"
      />
      <path
        fill="#191919"
        d="M322.394 81.33l.182-.02c.447-.04 1.633-.131 3.16-.085.19 0 .393.013.587.02 1.243.058 2.653.215 4.048.542.182.039.363.085.544.137 1.347.366 2.652.908 3.734 1.712.161.112.313.236.46.373a6.34 6.34 0 011.69 2.215c.606 1.326 2.169-2.411 3.21-5.248.23-.627.432-1.209.593-1.679.244-.719.398-1.19.398-1.19l-.028-.025c-.07-.066-.3-.262-.642-.536a21.33 21.33 0 00-.719-.53l-.084-.059c-.014-.006-.028-.02-.035-.026-.725-.497-1.654-1.065-2.694-1.503-.265-.11-.524-.216-.782-.313-.216-.085-.432-.17-.641-.243-1.654-.6-3.148-.967-4.6-1.163a9.617 9.617 0 00-.607-.078 23.94 23.94 0 00-3.684-.104c-.188.006-.384.013-.572.026a55.95 55.95 0 00-1.773.13c-1.11.099-2.01.249-2.338.451-2.1 1.34.593 7.195.593 7.195"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2D2D2D"
        d="M326.994 89.46c.145-.472-2.255-3.617-3.98-3.916-1.727-.3-2.294 1.04-1.889 1.02.406-.018 2.16.412 2.947 1.025.789.615 2.826 2.181 2.922 1.87"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FFF"
        d="M320.95 97.206c-.363.55-1.022 1.181-2.534 1.285-.59.037-1.052-.044-1.416-.223 0-.17.01-.356.02-.55.02-.364.06-.772.128-1.218.747.26 1.827.43 3.33.23.364-.052.639.23.472.476"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2D2D2D"
        mask="url(#prefix__d)"
        d="M322.987 89.216l-1.772-.716-.215.334 2 .666zM322.077 91.278c-.15.544-.075 1.08.167 1.199.24.118.557-.227.707-.77.149-.544-.065-1.064-.306-1.183-.242-.118-.419.21-.568.754"
      />
      <path
        fill="#A09D9F"
        d="M335.6 110.5l-7.476 1.523-4.32-1.111-2.804 4.202 21 .386s-5.719-3.38-6.4-5"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FFF"
        d="M320 115.665l2.093 2.49 5.653-2.876 5.562 3.221 3.692-5.64-.639-.745-2.001-2.615s-3.622 1.543-6.259 1.625c-2.071.06-4.882-.618-4.882-.618l-1.627 2.963-.304.55-1.288 1.645zm39 127.875l-9.055-84.153s.213-17.116-.944-29.534c-.664-7.124-4.973-13.347-11.323-16.353l-.046 1.6L336 174.388l1.431 70.112 21.569-.96z"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M339.987 168.93l-.15-2.66 8.336-.435.148 2.636a4.086 4.086 0 01-1.057 2.986 4.16 4.16 0 01-2.878 1.37 4.166 4.166 0 01-3.014-1.045 4.09 4.09 0 01-1.385-2.852zm8.037-5.747l.112 1.987-8.336.435-.11-1.963 8.334-.459zm-9.023.164l.315 5.62a4.751 4.751 0 001.607 3.31 4.84 4.84 0 003.5 1.215 4.832 4.832 0 003.342-1.591 4.742 4.742 0 001.227-3.466l-.315-5.62a.333.333 0 00-.354-.315l-9.005.496a.333.333 0 00-.317.35z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#C1966B"
        d="M336.602 96.5a.125.125 0 00.095-.041c.283-.314.367-.554.255-.732-.177-.284-.777-.224-.845-.217-.067.008-.114.063-.106.123.01.06.07.101.137.095.163-.019.524-.017.601.106.054.086-.031.264-.233.487a.103.103 0 00.019.155c.022.016.05.024.077.024"
        mask="url(#prefix__d)"
      />
      <path
        fill="#0A0A0A"
        d="M337 91.5c2.055-3.387 4.234-8.09 3.98-12.475a10.785 10.785 0 00-.283-1.928c0-.014-.007-.028-.007-.043a7.857 7.857 0 00-.741-.554v.014c.45 1.388.552 2.862.413 4.358-.298 3.265-1.75 6.608-3.267 9.32-.015.43-.043.87-.095 1.308m-3-7.408c.089.122.173.257.254.408.612-2.933.963-6.137.597-9.084a11.508 11.508 0 00-.097-.65c-.119-.094-.238-.187-.354-.266.047.236.085.48.12.723.393 2.832.07 5.973-.52 8.869"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FCD6B1"
        d="M319 88.5s-1.706 2.834-3.664 3.378c-1.601.446 3.035 2.622 3.035 2.622l.629-6z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#CECECE"
        d="M317.515 118.911L314 130.018l2.183 3.781-2.074 3.674 3.015 20.712c.066.456.748.4.738-.06l-.347-15.48L321 115.5l-3.485 3.411zM337.525 113.553l1.651 3.947-2 6.244-1.508 2.909 7.332 8.063-6.967 27.784L334 117.012l2.298-4.512z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#1297C6"
        mask="url(#prefix__d)"
        d="M327.194 111.506L327 115.5l.5-.292.5.244-.116-3.952z"
      />
      <path
        fill="#333033"
        mask="url(#prefix__d)"
        d="M317 168.194l18-1.694-.195 7.317-17.653 1.683z"
      />
      <path
        fill="#BCBCBC"
        mask="url(#prefix__d)"
        d="M322 168.908l.641 6.592 5.359-.445-.61-6.555z"
      />
      <path
        fill="#FCD6B1"
        d="M137 75.198c-1.431-1.801-4.996-.017-8.574 4.216-1.395 1.652-6.405 13.943-.907 8.864.744-.687 3.102-3.936 4.23-5.152 1.654-1.785 5.251-7.928 5.251-7.928"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M131.125 154.526c.61-.244 2.023 1.223-.945 4.557-3.276 3.677-11.597 9.673-24.65 12.417-.37-1.827-.497-6.53-.53-7.647 7.302-1.193 19.479-6.678 26.125-9.327"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M125.883 151.74c3.533 1.59 7.462 2.623 7.093 5.535 0 0-3.947 1.891-3.856.974.319-3.25-.37-4.194-4.316-5.602-1.649-.588-.497-1.615 1.079-.907m43.634 3.879c-1.849-.81-2.013 2.723-.463 4.493 15.014 17.14 46.727 9.847 46.727 9.847 1.473-.304 1.666-2.27.279-2.838a23.886 23.886 0 00-8.323-1.774c-6.282-.197-19.87-1.692-38.22-9.728"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M175.07 151.76c-3.425 1.588-6.483 3.141-7.07 5.443 0 0 3.997 1.97 3.932 1.054-.227-3.228.499-4.317 4.326-5.723 1.598-.587.34-1.48-1.187-.773M279 176.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M278 176.5a2 2 0 11-4 0 2 2 0 014 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#2C2B33"
        d="M244 224.5a3 3 0 11-6 0 3 3 0 016 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="var(--color-primary-bright)"
        d="M243 224.5a2 2 0 11-4.001 0 2 2 0 014.001 0"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FFF"
        d="M78.622 159.499l-6.468-.298a1.2 1.2 0 01-1.153-1.247l.11-2.317a1.205 1.205 0 011.267-1.136l6.468.298a1.2 1.2 0 011.153 1.247l-.11 2.317a1.205 1.205 0 01-1.267 1.136"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3A3A3A"
        mask="url(#prefix__d)"
        d="M77.923 158.5L72 158.126l.077-1.626 5.923.375z"
      />
      <path
        fill="#FFF"
        d="M320.928 131.499l-5.03-.238a.951.951 0 01-.897-.998l.086-1.854a.948.948 0 01.985-.908l5.03.238a.95.95 0 01.897.998l-.086 1.854a.948.948 0 01-.985.908"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3A3A3A"
        mask="url(#prefix__d)"
        d="M320.923 130.5l-5.923-.187.077-.813 5.923.187z"
      />
      <path
        fill="#FFF"
        d="M170.622 54.499l-6.467-.298A1.2 1.2 0 01163 52.954l.11-2.317a1.205 1.205 0 011.268-1.136l6.466.298A1.2 1.2 0 01172 51.046l-.11 2.317a1.203 1.203 0 01-1.267 1.136"
        mask="url(#prefix__d)"
      />
      <path
        fill="#3A3A3A"
        mask="url(#prefix__d)"
        d="M170.923 54.5L165 54.126l.077-1.626 5.923.375z"
      />
      <path
        fill="#FCD6B1"
        d="M105.62 159.5s-4.075 2.848-4.564 7.855c-.455 4.658 2.033 9.145 2.033 9.145s2.911-2.047 2.911-2.682c0-.634-.38-14.318-.38-14.318"
        mask="url(#prefix__d)"
      />
      <path
        fill="#FFF"
        d="M58.374 140.5c.385.327.336 7.206 3.646 13.808 5.27 10.514 14.662 22.483 14.662 22.483l25.22-9.651 2.098 9.651s-18.226 9.21-23.585 12.436c-6.98 4.2-11.37 2.38-16.584-3.811L45 161.02 58.374 140.5z"
        mask="url(#prefix__d)"
      />
      <path
        fill="#020202"
        mask="url(#prefix__d)"
        d="M310.023 153.5l6.875 6.51.102 4.49-7-7.674z"
      />
      <path
        fill="#FFF"
        d="M302.92 133.933c-1.942 1.87-4.92 6.867-4.92 6.867l18.16 19.148c6.205 4.75 15.206 2.633 18.644-4.384L346 132.71l-7.046-18.211-14.305 31.443-21.729-12.01z"
        mask="url(#prefix__d)"
      />
    </g>
  </svg>
);

export default Image;
