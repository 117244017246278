import { SxProps, Theme, Typography } from '@rossum/ui/material';
import { memo } from 'react';

type StringWithOccurrencesProps = {
  primaryValue: string;
  secondaryValue: string | number;
  PrimaryValueSx?: SxProps<Theme>;
};

export const StringWithOccurrences = memo(
  ({
    primaryValue,
    secondaryValue,
    PrimaryValueSx,
  }: StringWithOccurrencesProps) => {
    return (
      <span>
        <Typography component="span" variant="body2" sx={PrimaryValueSx}>
          {primaryValue}
        </Typography>
        <Typography color="text.secondary" component="span" variant="caption">
          {` (${secondaryValue})`}
        </Typography>
      </span>
    );
  }
);
