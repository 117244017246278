import { CircularProgress, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import { compact } from 'lodash';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { useEffect, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Dropdown, { ItemPropsT } from '../../../../components/UI/Dropdown';
import NoItemsFound from '../../../../components/UI/NoItemsFound';
import SearchFilter from '../../../../components/UI/SearchFilter/SearchFilter';
import { filterWorkspaces } from '../../../../redux/modules/workspaces/helpers';
import { Url } from '../../../../types/basic';
import { Queue } from '../../../../types/queue';
import { State } from '../../../../types/state';
import { WorkspaceWithQueues } from '../../../../types/workspace';
import ExpandableWorkspace from './components/ExpandableWorkspace';
import ExpandableWorkspaceBadges from './components/ExpandableWorkspaceBadges';
import styles from './style.module.sass';

type Props<FormT extends FieldValues> = {
  hasError: boolean;
  workspaces: Array<WorkspaceWithQueues>;
  control: Control<FormT>;
  queues?: Url[];
  setQueuesLoaded?: (_loaded: boolean) => void;
};

const ExpandableWorkspacesQueuesDropdown = <FormT extends FieldValues>({
  hasError,
  workspaces,
  control,
  queues: attachedQueuesUrls,
  setQueuesLoaded,
}: Props<FormT>) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspaces);
  const queuesLoading = useSelector((state: State) => !state.queues.loaded);

  const {
    field: { value, onChange },
  } = useController({ name: 'queues' as Path<FormT>, control });

  const intl = useIntl();

  useEffect(() => {
    if (searchValue === '') {
      setFilteredWorkspaces(workspaces);
    }
  }, [workspaces, searchValue]);

  useEffect(() => {
    if (!attachedQueuesUrls) return;

    const allFetchedQueues: Array<Queue> = workspaces.flatMap(
      ({ queues }) => queues
    );

    const selectedQueues = compact(
      attachedQueuesUrls.map(
        _url => allFetchedQueues.find(({ url }) => url === _url)?.url
      )
    );

    setQueuesLoaded?.(
      !!workspaces.length && attachedQueuesUrls.length === selectedQueues.length
    );

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, attachedQueuesUrls]);

  return (
    <Dropdown
      listClassName={styles.QueuesDropdownList}
      align="left"
      withIcons={false}
      items={[
        (_props: ItemPropsT) => (
          <div
            key="queues-search"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <SearchFilter
              className={styles.Search}
              placeholderID="components.selectQueueModal.placeholder"
              setFilteredEntities={setFilteredWorkspaces}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              entities={workspaces}
              filterFunction={filterWorkspaces}
            />
          </div>
        ),
        ...(filteredWorkspaces?.length
          ? filteredWorkspaces.map(
              (workspace: WorkspaceWithQueues, index: number) =>
                ({ open = false }: { open?: boolean }) => (
                  <ExpandableWorkspace
                    key={`expandable-workspace-${workspace.id}-${open}`}
                    selected={value}
                    shouldExpand={searchValue !== '' || index === 0}
                    setSelected={onChange}
                    workspace={workspace}
                    workspaceQueues={workspace.queues}
                  />
                )
            )
          : [
              (_props: ItemPropsT) => (
                <div key="no-queues-placeholder">
                  <NoItemsFound
                    title={intl.formatMessage({
                      id: 'components.selectQueueModal.noqueues.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'components.selectQueueModal.noqueues.text',
                    })}
                  />
                </div>
              ),
            ]),
      ]}
      onBlur={() => setSearchValue('')}
      keepOpen
    >
      {({ open }: Record<'open', boolean>) => (
        <div
          className={clsx(
            styles.Value,
            open && styles.IsOpen,
            hasError && styles.HasError
          )}
          data-cy="extensions-queues-select"
        >
          <ExpandableWorkspaceBadges
            id="expandable-dropdown-badges"
            queueUrls={value}
            workspaces={workspaces}
            displayPlaceholder
          />
          <Stack alignItems="center" spacing={1} direction="row">
            {queuesLoading && <CircularProgress color="inherit" size={16} />}
            <ChevronDownIcon className={styles.Chevron} />
          </Stack>
        </div>
      )}
    </Dropdown>
  );
};

export default ExpandableWorkspacesQueuesDropdown;
