// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const userSchema = z.object({
  id: idSchema,
  url: urlSchema,
  firstName: z.string().optional(),
  lastName: z.string(),
  email: z.string().optional(),
  emailVerified: z.boolean(),
  dateJoined: z.string(),
  username: z.string(),
  groups: z.array(urlSchema),
  organization: urlSchema,
  queues: z.array(urlSchema),
  isActive: z.boolean(),
  lastLogin: z.string().nullable(),
  uiSettings: z.unknown().optional(),
  metadata: z.unknown().optional(),
  oidcId: z.string().optional().nullable(),
  authType: z.union([z.literal('password'), z.literal('sso')]),
  deleted: z.boolean(),
  phoneNumber: z.string().nullable(),
});
