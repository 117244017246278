import Cookies from 'js-cookie';
import { getCookieValidityDomain } from './helpers';

// cookie used in root page of elis-registration for skipping e-mail login form
const ROSSUM_APP_DOMAIN = 'rossum-app-domain';

const cookieDomainValidity = getCookieValidityDomain(
  new URL(window.location.origin)
);

export const setRossumAppDomainCookie = (domain: string) => {
  if (cookieDomainValidity) {
    Cookies.set(ROSSUM_APP_DOMAIN, domain, {
      domain: cookieDomainValidity,
      expires: 365,
      path: '/',
    });
  }
};

export const removeRossumAppDomainCookie = () => {
  Cookies.remove(ROSSUM_APP_DOMAIN, { domain: cookieDomainValidity });
};
