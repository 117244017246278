import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  cheatSheetAnnotationVariables,
  VariablesConfig,
} from './TemplateValueCheatsheetItem/cheatSheetConfig';

export type EmailTemplateVariablesButtonProps = {
  cheatSheetConfig: VariablesConfig;
  annotationFields: { id: string; label: string }[];
  onValueSelect: (option: string) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const makeAnnotationVariable = (id: string) => {
  return `{{annotation.content.value.${id}}}`;
};

const EmailTemplateVariableButton = ({
  children,
  onClick,
  value,
}: {
  children: ReactNode;
  onClick: (variable: string) => void;
  value: string;
}) => (
  <MenuItem onClick={() => onClick(value)} dense>
    <Stack direction="row" spacing={1}>
      <Typography variant="body2">{children}</Typography>
      <Typography variant="body2" color="text.secondary">
        {value}
      </Typography>
    </Stack>
  </MenuItem>
);

export const EmailTemplateVariablesButton = ({
  cheatSheetConfig,
  annotationFields,
  onValueSelect,
  disabled,
  sx,
}: EmailTemplateVariablesButtonProps) => {
  const [anchorElTo, setAnchorElTo] = useState<HTMLButtonElement | null>(null);

  const openMenu = Boolean(anchorElTo);
  const onClick = (option: string) => {
    onValueSelect(option);
    setAnchorElTo(null);
  };

  const intl = useIntl();
  return (
    <>
      <Tooltip
        title={
          disabled
            ? ''
            : intl.formatMessage({
                id: 'containers.settings.queues.email.editTemplate.cheatsheet.subtitle',
              })
        }
        placement="top"
      >
        <IconButton
          disabled={disabled}
          onClick={e => {
            setAnchorElTo(e.currentTarget);
            // .blur() solves problem of selecting a variable to focused editor
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
          }}
          sx={{ ...sx }}
        >
          <Typography variant="body2">{`{var}`}</Typography>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElTo}
        open={openMenu}
        onClose={() => setAnchorElTo(null)}
      >
        {cheatSheetConfig.map(option => (
          <EmailTemplateVariableButton
            key={option.value}
            onClick={onClick}
            value={option.value}
          >
            {intl.formatMessage({
              id: option.descriptionId,
            })}
          </EmailTemplateVariableButton>
        ))}
        <Divider />
        {cheatSheetAnnotationVariables.map(option => (
          <EmailTemplateVariableButton
            key={option.value}
            onClick={onClick}
            value={option.value}
          >
            {intl.formatMessage({
              id: option.descriptionId,
            })}
          </EmailTemplateVariableButton>
        ))}
        <Divider />
        {annotationFields.map(field => (
          <EmailTemplateVariableButton
            key={field.id}
            onClick={onClick}
            value={makeAnnotationVariable(field.id)}
          >
            {field.label}
          </EmailTemplateVariableButton>
        ))}
      </Menu>
    </>
  );
};
