import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { Query, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useApiClient } from '../../../lib/apiClient';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { isUserAdmin } from '../../../redux/modules/user/selectors';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';

const QUERY_KEY_BILLING_ENABLED = 'billing-enabled';

export const useBillingEnabled = () => {
  const api = useApiClient();
  const featureFlagEnabled = useFeatureFlag('ac-494-billing');

  const isAdmin = useSelector(isUserAdmin);

  const organization = useSelector(safeOrganizationSelector);

  const testBillingQuery = useQuery({
    queryKey: [QUERY_KEY_BILLING_ENABLED] as const,

    queryFn: () => {
      if (!organization) {
        throw new Error('useBillingEnabled fetched without organization.');
      }

      const date = format(new Date(), 'yyyy-MM-dd');
      return api.request(
        endpoints.organization.billingStats.get(
          organization.id,
          {
            filters: {
              beginDate: date,
              endDate: date,
            },
          },
          { pageSize: 0 }
        )
      );
    },
    meta: {
      disableReporting: (query: Query<unknown, HttpError>) =>
        query.state.error?.code === 403,
    },
    enabled: featureFlagEnabled && isAdmin && !!organization,
    staleTime: 1000 * 3600,
    retry: false,
    select: result => result.updatedAt,
  });

  return {
    isEnabled: testBillingQuery.isSuccess,
    isInitialLoading: testBillingQuery.isInitialLoading,
  };
};
