import { IntlShape } from 'react-intl';

export const getErrorLabel = (
  intl: IntlShape,
  field: string | number | undefined
) =>
  field
    ? intl.formatMessage({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        id: `features.queueSettings.fields.form.${field}.label` as any,
      })
    : field;

export const invalidOptionMessage =
  (intl: IntlShape) => (field: string | number | undefined) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.invalidOption',
      },
      {
        field,
      }
    );

export const wrongTypeMessage =
  (intl: IntlShape) =>
  (field: string | number | undefined, expectedType: string) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.wrongType',
      },
      {
        field,
        expectedType,
      }
    );

export const noExactAndConstrainedLengthMessage =
  (intl: IntlShape) =>
  (exactLength: string, minLength: string, maxLength: string) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.noExactAndConstrainedLength',
      },
      {
        exactLength,
        minLength,
        maxLength,
      }
    );

export const minLessThanMaxMessage =
  (intl: IntlShape) => (minLength: string, maxLength: string) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.minLessThanMax',
      },
      {
        minLength,
        maxLength,
      }
    );

export const atLeastOneOptionMessage =
  (intl: IntlShape) => (field: string | number | undefined) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.atLeastOneOption',
      },
      {
        field,
      }
    );

export const requiredMessage =
  (intl: IntlShape) => (field: string | number | undefined) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.required',
      },
      {
        field,
      }
    );

export const maxLengthMessage =
  (intl: IntlShape) =>
  (field: string | number | undefined, maxLength: number) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.maxLength',
      },
      {
        field,
        maxLength,
      }
    );

export const slugMessage =
  (intl: IntlShape) => (field: string | number | undefined) =>
    intl.formatMessage(
      {
        id: 'features.queueSettings.fields.form.errors.slug',
      },
      {
        field,
      }
    );
