import {
  ActiveDatapoint,
  DatapointMagicLine,
  DocumentPages,
  DocumentScrollable,
  DocumentScrollbars,
  DocumentStore,
  DocumentSvg,
} from '@rossum/document-canvas';
import { Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { ConnectedPageSvg } from './ConnectedPageSvg';
import { Footer } from './Footer';
import { useValidationScreenHooks } from './hooks/useValidationScreenHooks';
import { Sidebar } from './Sidebar';

type MiniValidationScreenProps = {
  annotationId: number;
};

export const MiniValidationScreen = ({
  annotationId,
}: MiniValidationScreenProps) => {
  const [activeSidebarDatapointId, setActiveSidebarDatapointId] = useState<
    number | undefined
  >(undefined);

  const [activeTableDatapointId, setActiveTableDatapointId] = useState<
    number | undefined
  >(undefined);

  const { datapoints, schemaMap, annotation, pages } = useValidationScreenHooks(
    { annotationId }
  );

  const activeSidebarDatapoint = datapoints.find(
    dp => dp.id === activeSidebarDatapointId
  );

  const activeDatapointId =
    activeSidebarDatapoint?.type === 'multivalue'
      ? activeTableDatapointId
      : activeSidebarDatapointId;

  const setActiveDatapointId = (id: number | undefined) => {
    const datapoint = datapoints.find(dp => dp.id === id);
    const parent = datapoints.find(dp => dp.id === datapoint?.parentId);
    const grandparent = datapoints.find(dp => dp.id === parent?.parentId);

    if (grandparent && grandparent.type === 'multivalue') {
      setActiveSidebarDatapointId(grandparent.id);
      setActiveTableDatapointId(id);
    } else {
      setActiveSidebarDatapointId(id);
      setActiveTableDatapointId(undefined);
    }
  };

  return annotation && pages ? (
    <Stack
      direction="row"
      sx={{
        height: '100%',

        backgroundColor: '#2d2d2d',
        backgroundImage:
          'linear-gradient(135deg, #191919 25%, transparent 25%), linear-gradient(225deg, #191919 25%, transparent 25%), linear-gradient(45deg, #191919 25%, transparent 25%), linear-gradient(315deg, #191919 25%, #2d2d2d 25%)',
        backgroundPosition: '10px 0, 10px 0, 0 0, 0 0',
        backgroundSize: '20px 20px',
        backgroundRepeat: 'repeat',
        p: 1,
      }}
      spacing={1}
    >
      <Sidebar
        datapoints={datapoints}
        schemaMap={schemaMap}
        activeDatapointId={activeSidebarDatapointId}
        setActiveDatapointId={setActiveSidebarDatapointId}
        annotation={annotation}
      />
      <Stack direction="column" flex={1} spacing={1}>
        <Stack
          sx={{
            position: 'relative',
          }}
          flex={1}
        >
          <DocumentStore key={annotation.id} pages={pages.results}>
            <DocumentSvg>
              <DocumentScrollable>
                <DocumentPages
                  renderPage={(page, pageDimensions) => (
                    <ConnectedPageSvg
                      key={page.id}
                      pageDimensions={pageDimensions}
                      page={page}
                      datapoints={datapoints}
                      schemaMap={schemaMap}
                      activeDatapointId={activeDatapointId}
                      setActiveDatapointId={setActiveDatapointId}
                    />
                  )}
                />
              </DocumentScrollable>
              {/* <DocumentSelection />
          <ValidationDialog /> */}
              {activeSidebarDatapoint &&
                activeSidebarDatapoint.content &&
                activeSidebarDatapoint.content.page &&
                activeSidebarDatapoint.content.position && (
                  <ActiveDatapoint
                    pageNumber={activeSidebarDatapoint.content.page}
                    position={activeSidebarDatapoint.content.position}
                  />
                )}

              {activeSidebarDatapoint &&
                activeSidebarDatapoint.content &&
                activeSidebarDatapoint.content.page &&
                activeSidebarDatapoint.content.position && (
                  <DatapointMagicLine
                    pageNumber={activeSidebarDatapoint.content.page}
                    position={activeSidebarDatapoint.content.position}
                    sourceElementId="magic-line-source"
                  />
                )}
            </DocumentSvg>
            <DocumentScrollbars />
          </DocumentStore>
        </Stack>
        <Footer
          datapoints={datapoints}
          schemaMap={schemaMap}
          activeDatapointId={activeSidebarDatapointId}
          activeTableDatapointId={activeTableDatapointId}
          setActiveTableDatapointId={setActiveTableDatapointId}
        />
      </Stack>
    </Stack>
  ) : null;
};
