import { Stack, Typography } from '@rossum/ui/material';

// TODO: Systematize this component (colors etc.)
type KeyboardShortcutProps = { keyName?: string; description: string };

// TODO: Platform dependent mappings etc.
const keyNameLabelMap: { [k: string]: string } = {
  tab: 'Tab',
  enter: 'Enter',
  delete: 'Delete',
  escape: 'Esc',
  altOption: 'Alt/Option',
  altOptionSpace: 'Alt/Option + Space',
  shiftTab: 'Shift + Tab',
  ctrlShiftA: 'Ctrl + Shift + A',
  ctrlZ: 'Ctrl/Cmd + Z',
  ctrlShiftZ: 'Ctrl/Cmd + Shift + Z',
};

export const KeyboardShortcut = ({
  keyName,
  description,
}: KeyboardShortcutProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      component="span"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="inherit">{description}</Typography>
      {keyName && (
        <Typography
          variant="inherit"
          sx={{
            px: 0.5,
            borderRadius: '2px',
            // TODO: Colors from theme?
            color: theme => theme.palette.getContrastText('#24252B'),
            backgroundColor: '#24252B',
            border: '1px #ffffff50 solid',
          }}
        >
          {keyNameLabelMap[keyName]}
        </Typography>
      )}
    </Stack>
  );
};
