import { ActionType, createAction } from 'typesafe-actions';
import { InputMessages } from './types';

const SET_VALIDATION_MESSAGES = 'SET_VALIDATION_MESSAGE';
const CLEAR_VALIDATION_MESSAGES = 'CLEAR_VALIDATION_MESSAGES';

export const setValidationMessagesAction = createAction(
  SET_VALIDATION_MESSAGES,
  (messages: InputMessages, _formId: string) => messages,
  (_messages, formId) => ({ formId })
)();

export const clearValidationMessagesAction = createAction(
  CLEAR_VALIDATION_MESSAGES,
  undefined,
  (formId: string, inputId: string) => ({ formId, inputId })
)();

export type ValidationMessagesActions = ActionType<
  typeof clearValidationMessagesAction | typeof setValidationMessagesAction
>;
