import { Dialog } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import { ContactDialogContent } from './ContactDialogContent';

const DIALOG_MAX_WIDTH = 448;

type ContactDialogProps = {
  type: 'requestAccess' | 'contactUs' | undefined;
  open: boolean;
  onClose: () => void;
  extensionName: string;
};

export const ContactDialog = ({
  open,
  onClose,
  type = 'contactUs',
  extensionName,
}: ContactDialogProps) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: DIALOG_MAX_WIDTH, position: 'fixed' },
        elevation: 2,
      }}
    >
      {open && (
        <>
          <DialogTitle
            title={intl.formatMessage({
              id: `components.contactDialog.title.${type}`,
            })}
            onClose={onClose}
          />
          <ContactDialogContent
            type={type}
            onClose={onClose}
            extensionName={extensionName}
          />
        </>
      )}
    </Dialog>
  );
};
