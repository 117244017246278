import { Theme } from '../material';

export const getScrollbarColors = (theme: Theme) => {
  const trackDarkColor = '#32333b';
  const thumbDarkColor = '#696969';
  const trackLightColor = '#f0f2f3';
  const thumbLightColor = '#696969';

  const trackColor =
    theme.palette.mode === 'dark' ? trackDarkColor : trackLightColor;

  const thumbColor =
    theme.palette.mode === 'dark' ? thumbDarkColor : thumbLightColor;

  return { trackColor, thumbColor };
};
