import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Survey } from '../models';
import { Answer } from '../models/answer';
import { surveySchema } from '../models/survey.schema';
import { surveyPatchPayloadSchema } from './patch.schema';

export type SurveyPatchPayload = {
  additionalData?: Record<string, unknown>;
  answers?: Array<Partial<Omit<Answer, 'question'>>>;
};

export const patch = (surveyId: ID, payload: SurveyPatchPayload = {}) => {
  return {
    endpoint: `/surveys/${surveyId}`,
    method: 'PATCH',
    responseSchema: surveySchema,
    payload,
    payloadSchema: surveyPatchPayloadSchema,
  } as const;
};
