import { get } from './get';
import { list } from './list';
import { reset } from './reset';
export { type WorkflowRunResetPayload } from './reset';

export const workflowRuns = {
  get,
  list,
  reset,
};
