import { Url } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { LinearProgress, Stack } from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridNoRowsOverlay,
} from '@rossum/ui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { mergeDeep } from 'remeda';
import {
  commonDataGridStyles,
  virtualScrollerOnResizeStyles,
} from '../../../ui/data-grid/styles';
import CustomNoRowsOverlay from './components/CustomNoRowsOverlay';
import { useCalculatePreview } from './hooks/useCalculatePreview';
import { useColumns } from './hooks/useColumns';
import { useUpdateColumWidth } from './hooks/useColumnWidths';
import { useGetAnnotationsForPreview } from './hooks/useGetAnnotationsForPreview';
import { documentsColumn, useRows } from './hooks/useRows';

const PAGE_SIZE = 5;

const gridStyles = mergeDeep(
  { ...commonDataGridStyles, ...virtualScrollerOnResizeStyles },
  {
    // fix cropped empty NoRowsOverlay
    [`.${gridClasses.virtualScroller}`]: {
      minHeight: 105,
    },
  }
);

export type FormulaPreviewGridProps = {
  queueUrls: Url[];
  currentFormulaId: string;
  schemaForPreview: SchemaSection[];
  type: 'formula';
  disabled?: boolean;
};

export const FormulaPreviewGrid = ({
  queueUrls,
  currentFormulaId,
  schemaForPreview,
  type,
  disabled,
}: FormulaPreviewGridProps) => {
  const intl = useIntl();
  const [page, setPage] = useState(0);

  const annotationsQuery = useGetAnnotationsForPreview(queueUrls);

  const annotationUrls = useMemo(() => {
    return (
      annotationsQuery.data?.pages.flatMap(page =>
        page.results.map(({ url }) => url)
      ) ?? []
    );
  }, [annotationsQuery.data]);

  const evaluationQueries = useCalculatePreview(
    annotationUrls,
    schemaForPreview,
    disabled
  );

  const columns = useColumns({ currentFormulaId, schemaForPreview, type });

  const pageData = annotationsQuery.data?.pages[page];
  const rows = useRows({
    columns,
    annotations: pageData?.results,
    documents: pageData?.documents,
    evaluationQueries,
  });

  const isIdle = evaluationQueries.some(
    query => query.isLoading && !query.isFetching
  );

  const updateColumnWidth = useUpdateColumWidth();

  return (
    <Stack sx={{ background: theme => theme.palette.background.paper, mt: 2 }}>
      <DataGridPro
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: isIdle ? CustomNoRowsOverlay : GridNoRowsOverlay,
        }}
        slotProps={{
          noRowsOverlay: {
            sx: { backgroundColor: 'inherit' },
          },
          loadingOverlay: {
            color: 'secondary',
          },
          pagination: {
            sx: {
              // hide pagination when there are no rows
              display: rows.length === 0 ? 'none' : 'inherit',
              [`> * p`]: {
                margin: 0,
              },
            },
          },
        }}
        localeText={
          rows.length === 0
            ? {
                noRowsLabel: intl.formatMessage({
                  id: 'features.queueSettings.fields.formulaPreview.noAnnotations',
                }),
              }
            : undefined
        }
        autoHeight
        loading={
          annotationsQuery.isFetching ||
          evaluationQueries.some(query => query.isFetching)
        }
        columns={columns}
        rows={isIdle ? [] : rows}
        onColumnWidthChange={updateColumnWidth}
        density="compact"
        disableColumnMenu
        disableColumnReorder
        pagination
        paginationModel={{ pageSize: PAGE_SIZE, page }}
        paginationMode="server"
        onPaginationModelChange={newModel => {
          setPage(newModel.page);

          if (
            annotationsQuery.data &&
            annotationsQuery.data.pages.length <= newModel.page
          ) {
            annotationsQuery.fetchNextPage();
          }
        }}
        rowCount={annotationsQuery.data?.pages[0]?.pagination.total}
        pageSizeOptions={[PAGE_SIZE]}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: [documentsColumn, 'columns.condition', 'actions'],
          },
        }}
        sx={gridStyles}
      />
    </Stack>
  );
};
