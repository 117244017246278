import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

export const useGetWorkflows = () => {
  const workflows = useQuery({
    queryKey: ['workflows'] as const,

    queryFn: () => {
      // We should use normal token here, not workflows token.
      return api.unpaginatedRequest(endpoints.workflows.list)();
    },
  });
  return workflows;
};
