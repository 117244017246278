import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import DialogTitle from '../dialog-title/DialogTitle';

export type SelectDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;

  title: string;
  submitText: string;
  cancelText: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  tertiaryAction?: JSX.Element;
  children: ReactNode;
};

export const ChoiceDialog = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  isDisabled,
  title,
  submitText,
  cancelText,
  tertiaryAction,
  children,
}: SelectDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 2,
        sx: { width: 450, height: '80vh' },
      }}
    >
      <DialogTitle title={title} onClose={onClose} />
      {children}

      <DialogActions sx={{ flexGrow: 1, justifySelf: 'right' }}>
        {tertiaryAction}
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          data-cy="choice-dialog-cancel-btn"
        >
          {cancelText}
        </Button>
        <Button
          variant="contained"
          disabled={isDisabled || isLoading}
          startIcon={
            isLoading ? <CircularProgress color="inherit" size={16} /> : null
          }
          onClick={() => {
            onConfirm();
          }}
          data-cy="choice-dialog-submit-btn"
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
