// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

const automationBlockerSampleSchema = z.object({
  datapointId: z.number(),
  details: z
    .object({
      content: z.array(z.string()).optional(),
      messageContent: z.array(z.string()).optional(),
      score: z.number().optional(),
    })
    .optional(),
});

const annotationAutomationBlockerSchema = z.object({
  level: z.literal('annotation'),
  type: z.union([
    z.literal('automation_disabled'),
    z.literal('is_duplicate'),
    z.literal('suggested_edit_present'),
    z.literal('error_message'),
    z.literal('human_confirmation_required'),
    z.literal('extension'),
  ]),
  details: z
    .object({
      content: z.array(z.string()).optional(),
      messageContent: z.array(z.string()).optional(),
    })
    .optional(),
});

const datapointAutomationBlockerSchema = z.object({
  level: z.literal('datapoint'),
  type: z.union([
    z.literal('low_score'),
    z.literal('failed_checks'),
    z.literal('no_validation_sources'),
    z.literal('error_message'),
    z.literal('extension'),
  ]),
  schemaId: z.string(),
  samples: z.array(automationBlockerSampleSchema),
  samplesTruncated: z.boolean(),
});

export const automationBlockerSchema = z.object({
  id: idSchema,
  url: urlSchema,
  annotation: urlSchema,
  content: z.array(
    z.union([
      annotationAutomationBlockerSchema,
      datapointAutomationBlockerSchema,
    ])
  ),
});
