import { Tab, Tabs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { tabs } from '../../components/NavBar/navigationStructure';

export const AutomationTabs = ({
  value,
}: {
  value: 'aiEngines' | 'automationSettings';
}) => {
  const intl = useIntl();
  const headerTabs = tabs.find(tab => tab.name === 'automation')?.menu ?? [];
  const { push } = useHistory();

  return (
    <Tabs
      value={value}
      onChange={(_, value) => {
        const link = headerTabs.find(tab => tab.name === value)?.link;
        if (link) {
          push(link);
        }
      }}
    >
      {headerTabs.map(tab => (
        <Tab
          key={tab.name}
          label={intl.formatMessage({
            id: `components.appBar.menu.${tab.translationKey}`,
          })}
          data-cy={`tab-${tab.translationKey}`}
          value={tab.name}
        />
      ))}
    </Tabs>
  );
};
