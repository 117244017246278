import { endpoints } from '@rossum/api-client';
import { User } from '@rossum/api-client/users';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useApiClient } from '../../lib/apiClient';
import { getUnsafe } from '../../lib/helpers';
import {
  isSSOEnable,
  safeOrganizationSelector,
} from '../../redux/modules/organization/selectors';
import { PASSWORD, SSO } from '../../redux/modules/users/values';
import { UiSettings } from '../../types/user';
import { useResetPasswordByAdmin } from './useResetPasswordByAdmin';

const getErrorMessageFromErrorData = (errorData: unknown) => {
  if (errorData && typeof errorData === 'object') {
    const firstKeyOfData = Object.keys(errorData)[0];

    const firstApiErrorValue =
      firstKeyOfData && getUnsafe(errorData, firstKeyOfData);

    return (
      (Array.isArray(firstApiErrorValue) &&
        typeof firstApiErrorValue[0] === 'string' &&
        firstApiErrorValue[0]) ||
      undefined
    );
  }

  return undefined;
};

type CreateUserPayload = {
  email: string;
  role: string[];
  firstName?: string;
  lastName?: string;
  addQueues?: boolean;
};

export const useCreateUser = (
  handleOnSuccess?: (user: User) => void,
  emailType?: 'welcome'
) => {
  const api = useApiClient();
  const { mutate } = useResetPasswordByAdmin();

  const organization = useSelector(safeOrganizationSelector)?.url;

  const authType = useSelector(isSSOEnable) ? SSO : PASSWORD;

  const mutation = useMutation({
    mutationFn: async ({
      email,
      role,
      firstName,
      lastName,
      addQueues,
    }: CreateUserPayload) => {
      const uiSettings: Partial<UiSettings> = {};

      // Get the most up-to-date queues here, don't take them from state.
      const queues = addQueues
        ? await api
            .unpaginatedRequest(endpoints.queues.list)()
            .then(queues => queues.map(queue => queue.url))
        : [];

      return api.request(
        endpoints.users.create({
          authType,
          email,
          firstName,
          groups: role,
          lastName,
          organization,
          queues,
          username: email,
          uiSettings,
        })
      );
    },

    onSuccess: response => {
      const { email, username } = response;
      mutate({ email: email ?? username, emailType });

      if (handleOnSuccess) {
        handleOnSuccess(response);
      }
    },

    retry: false,
  });

  const { error } = mutation;
  const errorData = error && getUnsafe(error, 'data');

  return {
    ...mutation,
    apiErrorMessage: getErrorMessageFromErrorData(errorData),
  };
};
