import { useSelector } from 'react-redux';
import { MagicGridV2Container } from '../../../../components/MagicGridV2/MagicGridV2Container';
import { currentMultivalueDatapointSelector } from '../../../../redux/modules/datapoints/selector';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { UseCanvasDimensions } from '../useCanvasDimensions';
import { LineItemsPageSpaceProvider } from './LineItemsPageSpaceProvider';
import { LineItemsTransformWrapper } from './LineItemsTransformWrapper';

type MagicGridSVGContainerProps = {
  dimensions: UseCanvasDimensions;
  visiblePages: Record<number, boolean>;
};

export const LineItemsSVGContainer = ({
  dimensions,
  visiblePages,
}: MagicGridSVGContainerProps) => {
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const currentMultivalue = useSelector(currentMultivalueDatapointSelector);

  const currentMultivalueIndex = currentMultivalue?.meta.index;

  return currentMultivalueIndex &&
    !currentMultivalue.meta.isSimpleMultivalue ? (
    <>
      {dimensions.pages
        .filter(page => visiblePages[page.pageNumber])
        .map(visiblePage => {
          return visiblePage ? (
            <LineItemsPageSpaceProvider
              visiblePage={visiblePage}
              key={visiblePage.pageNumber}
            >
              <LineItemsTransformWrapper visiblePage={visiblePage}>
                {complexLineItemsEnabled ? null : (
                  <MagicGridV2Container
                    page={visiblePage.pageNumber}
                    datapointIndex={currentMultivalueIndex}
                  />
                )}
              </LineItemsTransformWrapper>
            </LineItemsPageSpaceProvider>
          ) : null;
        })}
    </>
  ) : null;
};
