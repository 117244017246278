/* eslint-disable react/hook-use-state */
import { TimeAndCorrectionsPerField } from '@rossum/api-client/annotations';
import { MenuItem, Select, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { get, map, sortBy } from 'lodash';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EmptySeries from '../../../containers/Statistics/components/EmptySeries';
import styles from '../styles.module.sass';
import GraphsContainer from './GraphContainer';

type ItemT = {
  uniqueId: string;
  dimmed?: boolean;
  label: string;
};

type DataT = {
  date: string;
  key: string;
  timeAndCorrectionsPerField: TimeAndCorrectionsPerField[];
} & ItemT;

type Props = {
  name: 'timeAndCorrectionsTable';
  data: Array<DataT>;
};

const Table = ({ data, name }: Props) => {
  const [activeSeriesIndex, setActive] = useState(0);
  const [scrollBarWidth, setScrollbarWidth] = useState(0);
  const activeSeries = data[activeSeriesIndex];
  const activeData = get(activeSeries, 'timeAndCorrectionsPerField', []);

  const [sortAscending, setSortOptions] = useState<boolean>(false);

  const activeDataSortedDescending = useMemo(
    () =>
      sortBy(activeData, [
        (timesAndCorrections: TimeAndCorrectionsPerField) =>
          timesAndCorrections.correctedRatio,
      ]).reverse(),
    [activeData]
  );

  const activeDataSortedAscending = useMemo(
    () =>
      sortBy(activeData, [
        (timesAndCorrections: TimeAndCorrectionsPerField) =>
          timesAndCorrections.correctedRatio,
      ]),
    [activeData]
  );

  const scrollDiv = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setScrollbarWidth(
        get(node, 'offsetWidth', 0) - get(node, 'clientWidth', 0)
      );
    }
  }, []);

  const handleSortClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setSortOptions(!sortAscending);
  };

  return (
    <GraphsContainer graphName={name} className={styles.TableContainer}>
      <Select
        size="small"
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
        }}
        disableUnderline
        variant="standard"
        value={activeSeriesIndex}
        renderValue={index => (
          <Typography variant="caption">{data[index].label}</Typography>
        )}
        onChange={e => {
          const index = e.target.value;
          if (typeof index === 'number') {
            setActive(index);
          }
        }}
      >
        {data.map((option, index) => (
          <MenuItem value={index} key={option.label}>
            <Typography
              variant="caption"
              color={option.dimmed ? 'text.disabled' : 'text.primary'}
            >
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <div
        className={styles.TableHeader}
        style={{ paddingRight: scrollBarWidth }}
      >
        <table className={styles.Table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="components.graphs.timeAndCorrectionsTable.fields" />
              </th>
              <th>
                <div className={styles.SortButton} onClick={handleSortClick}>
                  <FormattedMessage id="components.graphs.timeAndCorrectionsTable.pctCorrected" />
                  <div className={styles.SortArrow}>
                    <div
                      className={clsx(sortAscending && styles.SortArrowDown)}
                    >
                      &#9660;
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <FormattedMessage id="components.graphs.timeAndCorrectionsTable.avgTime" />
              </th>
            </tr>
          </thead>
        </table>
      </div>
      {activeData.length ? (
        <div className={styles.TableScrollable} ref={scrollDiv}>
          <table className={styles.Table}>
            <tbody>
              {map(
                sortAscending
                  ? activeDataSortedAscending
                  : activeDataSortedDescending,
                ({
                  schemaId,
                  label,
                  correctedRatio,
                  timeSpentAvgS,
                }: TimeAndCorrectionsPerField) => (
                  <tr key={schemaId}>
                    <td title={schemaId}>{label}</td>
                    <td>
                      {(Math.round(correctedRatio * 100 * 100) / 100).toFixed(
                        2
                      )}
                    </td>
                    <td>
                      {(Math.round(timeSpentAvgS * 100) / 100).toFixed(2)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptySeries inGraph graphName={name} />
      )}
    </GraphsContainer>
  );
};

export default Table;
