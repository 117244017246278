// Generated by ts-to-zod
import { z } from 'zod';

const dateFieldSchema = z.union([
  z.literal('created_at'),
  z.literal('modified_at'),
  z.literal('exported_at'),
  z.literal('confirmed_at'),
  z.literal('rejected_at'),
  z.literal('deleted_at'),
  z.literal('assigned_at'),
]);

const userFieldSchema = z.union([
  z.literal('exported_by'),
  z.literal('confirmed_by'),
  z.literal('rejected_by'),
  z.literal('deleted_by'),
  z.literal('modifier'),
  z.literal('assignees'),
]);

export const metaFieldSchema = z.union([
  z.literal('original_file_name'),
  z.literal('queue'),
  z.literal('status'),
  z.literal('labels'),
  z.literal('details'),
  dateFieldSchema,
  userFieldSchema,
]);

export const schemaColumnSchema = z.object({
  schemaId: z.string(),
  dataType: z.union([
    z.literal('number'),
    z.literal('string'),
    z.literal('date'),
    z.literal('boolean'),
  ]),
  columnType: z.literal('schema'),
  visible: z.boolean(),
  width: z.number(),
});

export const metaColumnSchema = z.object({
  metaName: metaFieldSchema,
  columnType: z.literal('meta'),
  visible: z.boolean(),
  width: z.number(),
});

export const columnsWidthSchema = z.record(z.number());

export const userTableConfigSchema = z.object({
  organization: z
    .object({
      width: columnsWidthSchema,
    })
    .optional(),
  queues: z
    .object({
      width: z.record(columnsWidthSchema),
    })
    .optional(),
});

export const annotationListColumnSchema = z.union([
  metaColumnSchema,
  schemaColumnSchema,
]);

export const annotationListColumnsSchema = z.array(annotationListColumnSchema);

export const annotationListTableSchema = z.object({
  columns: annotationListColumnsSchema,
});
