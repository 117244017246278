// This function serves as a helper for Tag manager (Google analytics) to track
// app usage and user usecases via data-cy attributes

window.getDataCy = (element: HTMLElement | null) => {
  if (!element) {
    return undefined;
  }
  const dataCy = element.getAttribute('data-cy');
  return dataCy ?? window.getDataCy(element.parentElement);
};

export {};
