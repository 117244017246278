import { endpoints, ID } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

const QUERY_KEY_RELATED_OBJECTS_COUNTS = 'queue-related-objects-counts';

export const useRelatedObjectsCounts = (queueId: ID) => {
  return useQuery({
    queryKey: [QUERY_KEY_RELATED_OBJECTS_COUNTS, queueId] as const,

    queryFn: ({ queryKey }) =>
      api.request(endpoints.queues.getRelatedObjectsCounts(queryKey[1])),
  });
};
