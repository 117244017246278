import { get, isString } from 'lodash';
import { absoluteApiUrl } from '../../../../../constants/config';
import { Token } from '../../../../../redux/modules/popup/classes/Token';
import { ApiRequest } from './classes/ApiRequest';
import { ConfigApp } from './classes/ConfigApp';
import { Hook } from './classes/Hook';

export const rossumRpcMessageType = 'ROSSUM:RPC';

export type RPCMessage = {
  type: typeof rossumRpcMessageType;
  callId: string;
  methodCall: {
    methodName: string;
    params: unknown[];
  };
};

export type RPCMessageEvent = MessageEvent<RPCMessage>;

// `forbiddenEndpoints` array contains prefixes of endpoints that are explicitly prohibited
const forbiddenEndpoints = ['/auth', '/oauth'];

// `allowedEndpoints` array contains endpoints that are exceptions to the forbidden endpoints
const allowedEndpoints = ['/auth/user'];

export const validateEndpoint = (
  endpoint: string,
  config: { absoluteApiUrl: string } = { absoluteApiUrl }
) => {
  // only endpoints that are relative or starts with current absolute API url are passed through
  // so that we do not expose authorization header to other domains
  const isRelativeOrAbsoluteApiUrl =
    endpoint.startsWith('/') || endpoint.startsWith(config.absoluteApiUrl);

  // TODO: check with BE if we could use token with specific scope and remove this check
  const isForbidden =
    forbiddenEndpoints.some(prefix => endpoint.includes(prefix)) &&
    !allowedEndpoints.some(prefix => endpoint.includes(prefix));

  return isRelativeOrAbsoluteApiUrl && !isForbidden;
};

export const resolveResponse = async (
  postMessageClasses: PostMessageClasses,
  data: RPCMessage,
  createMessage: (response: unknown) => void,
  reportError: (response: unknown) => void
) => {
  const methodName = data?.methodCall?.methodName;
  const method = get(postMessageClasses, methodName);

  if (!method) {
    return reportError(`${methodName} is not supported.`);
  }

  const endpoint = get(data, ['methodCall', 'params', 0, 'endpoint']);

  if (
    isString(endpoint) &&
    postMessageClasses.ApiRequest &&
    methodName?.includes(postMessageClasses.ApiRequest.constructor.name) &&
    !validateEndpoint(endpoint)
  ) {
    return reportError(
      `Making a request to '${methodName} ${endpoint}' is not allowed.`
    );
  }

  try {
    const result = await method(...(data?.methodCall?.params || []));

    return createMessage(result);
  } catch (error) {
    return reportError(error);
  }
};

export type PostMessageClasses = {
  ConfigApp?: ConfigApp;
  Hook?: Hook;
  ApiRequest?: ApiRequest;
  Token?: Token;
};
