import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngine } from '../models/dedicatedEngine';
import { dedicatedEngineSchema } from '../models/dedicatedEngine.schema';
import { createDedicatedEnginePayloadSchema } from './create.schema';

export type CreateDedicatedEnginePayload = {
  name: string;
};

export const create = (payload: CreateDedicatedEnginePayload) => {
  return {
    endpoint: `/dedicated_engines`,
    method: 'POST',
    responseSchema: dedicatedEngineSchema,
    payloadSchema: createDedicatedEnginePayloadSchema,
    payload,
  } as const;
};
