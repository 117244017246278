import { Typography } from '@rossum/ui/material';

export const AttachmentsModalSubtitle = ({
  value,
  isVisible,
}: {
  value: string;
  isVisible: boolean;
}) => {
  return (
    <Typography variant="body1" sx={{ my: 0.5, whiteSpace: 'pre-wrap' }}>
      {isVisible ? value : ' '}
    </Typography>
  );
};
