import { useTheme } from '@rossum/ui/material';
import { useRef } from 'react';
import { getPageNumberByPoint } from '../../document-store/documentGeometry';
import {
  useDocumentStore,
  useDocumentStoreContext,
} from '../../document-store/DocumentStore';
import { CanvasState } from '../../document-store/helpers';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
} from '../../document-store/helpers/geometry';
import { CanvasDimensions } from '../../document-store/helpers/getCanvasDimensions';
import { useRectangleCreator } from '../common/useRectangleCreator';
import { CornerHandle } from './CornerHandle';
import { EdgeHandle } from './EdgeHandle';

export const getRectangleInfoFromPoints = (
  original: Rectangle2D,
  p1: Point2D,
  p2: Point2D,
  startMatrix: DOMMatrix,
  endMatrix: DOMMatrix,
  dimensions: CanvasDimensions,
  direction:
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | 'move'
) => {
  const startPoint = DOMPoint.fromPoint(p1).matrixTransform(startMatrix);
  const endPoint = DOMPoint.fromPoint(p2).matrixTransform(endMatrix);
  const startPage = getPageNumberByPoint(startPoint, dimensions.pages);

  const h =
    direction === 'move'
      ? {
          x: original.x - startPoint.x + endPoint.x,
          width: original.width,
        }
      : direction === 'top' || direction === 'bottom'
        ? {
            x: original.x,
            width: original.width,
          }
        : direction === 'right' ||
            direction === 'top-right' ||
            direction === 'bottom-right'
          ? {
              x: original.x,
              width: original.width - startPoint.x + endPoint.x,
            }
          : {
              x: original.x - startPoint.x + endPoint.x,
              width: original.width + startPoint.x - endPoint.x,
            };

  const v =
    direction === 'move'
      ? {
          y: original.y - startPoint.y + endPoint.y,
          height: original.height,
        }
      : direction === 'left' || direction === 'right'
        ? {
            y: original.y,
            height: original.height,
          }
        : direction === 'bottom' ||
            direction === 'bottom-left' ||
            direction === 'bottom-right'
          ? {
              y: original.y,
              height: original.height - startPoint.y + endPoint.y,
            }
          : {
              y: original.y - startPoint.y + endPoint.y,
              height: original.height + startPoint.y - endPoint.y,
            };

  const rectangle = { ...h, ...v };

  return {
    startPoint,
    endPoint,
    startPage,
    rectangle,
  };
};

const bboxToGlobalCoordinates = (
  page: Rectangle2D,
  boxRectangle: Rectangle2D,
  canvasState: CanvasState
) => ({
  x:
    page.x * canvasState.zoomLevel +
    canvasState.translateX +
    boxRectangle.x * canvasState.zoomLevel,
  y:
    page.y * canvasState.zoomLevel +
    canvasState.translateY +
    boxRectangle.y * canvasState.zoomLevel,
  height: boxRectangle.height * canvasState.zoomLevel,
  width: boxRectangle.width * canvasState.zoomLevel,
});

type ActiveDatapointProps = {
  pageNumber: number;
  position: Rectangle2DCoordinates;
};

export const ActiveDatapoint = ({
  pageNumber,
  position,
}: ActiveDatapointProps) => {
  const theme = useTheme();
  const { dimensions } = useDocumentStoreContext();
  const {
    dragPoint,
    handleMouseDown,
    startPoint: _,
  } = useRectangleCreator({
    onRectangleCreated: () => {},
  });

  const canvasState = useDocumentStore(state => state.canvasState);

  const page = dimensions.pages.find(page => pageNumber === page.pageNumber);
  const boxRectangle = rectangleFromCoordinates(position);
  const getCanvasCTM = useDocumentStore(state => state.getCanvasCTM);

  const box = page
    ? bboxToGlobalCoordinates(page.dimensions, boxRectangle, canvasState)
    : undefined;

  const initialInverseMatrix = useRef<DOMMatrix>(IDENTITY_MATRIX_2D);
  const directionRef = useRef<
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'top-right'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-left'
    | 'move'
    | undefined
  >(undefined);

  // const draft =
  //   startPoint && dragPoint && box && directionRef.current
  //     ? getRectangleInfoFromPoints(
  //         box,
  //         startPoint,
  //         dragPoint,
  //         initialInverseMatrix.current,
  //         getCanvasCTM().inverse(),
  //         dimensions,
  //         directionRef.current
  //       )
  //     : undefined;

  return box ? (
    <>
      <rect
        vectorEffect="non-scaling-stroke"
        stroke={theme.palette.primary.main}
        fill="transparent"
        strokeWidth={2}
        pointerEvents="all"
        x={box.x}
        y={box.y}
        height={box.height}
        width={box.width}
        cursor="grab"
        onMouseDownCapture={e => {
          e.stopPropagation();
          directionRef.current = 'move';
          initialInverseMatrix.current = getCanvasCTM().inverse();
          handleMouseDown(e);
        }}
      />
      {/* {draft && draft.rectangle && (
        <rect
          vectorEffect="non-scaling-stroke"
          stroke={theme.palette.primary.main}
          fill="transparent"
          strokeWidth={2}
          pointerEvents="none"
          x={draft.rectangle.x}
          y={draft.rectangle.y}
          height={draft.rectangle.height}
          width={draft.rectangle.width}
        />
      )} */}
      <svg
        style={{ overflow: 'visible' }}
        viewBox={`0 0 ${box.width} ${box.height}`}
        {...box}
      >
        {(['left', 'right', 'bottom', 'top'] as const).map(direction => (
          <EdgeHandle
            key={direction}
            direction={direction}
            strokeWidth={2}
            isDragging={false}
            color={theme.palette.primary.main}
            onMouseDownCapture={e => {
              e.stopPropagation();
              directionRef.current = direction;
              initialInverseMatrix.current = getCanvasCTM().inverse();
              handleMouseDown(e);
            }}
          />
        ))}

        {(
          ['top-right', 'top-left', 'bottom-right', 'bottom-left'] as const
        ).map(direction => (
          <CornerHandle
            key={direction}
            strokeWidth={2}
            radius={2}
            isDragging={dragPoint !== undefined}
            direction={direction}
            color={theme.palette.primary.main}
            onMouseDownCapture={e => {
              e.stopPropagation();
              directionRef.current = direction;
              initialInverseMatrix.current = getCanvasCTM().inverse();
              handleMouseDown(e);
            }}
          />
        ))}
      </svg>
    </>
  ) : null;
};
