// Generated by ts-to-zod
import { z } from 'zod';
import { annotationListTableSchema } from '../../shared/models/annotationListTable.schema';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const organizationSchema = z.object({
  id: idSchema,
  url: urlSchema,
  name: z.string(),
  workspaces: z.array(urlSchema),
  users: z.array(urlSchema),
  uiSettings: z.unknown().optional(),
  metadata: z.unknown().optional(),
  expiresAt: z.string().nullable(),
  trialExpiresAt: z.string().nullable(),
  isTrial: z.boolean(),
  oidcProvider: z.string().nullable(),
  organizationGroup: urlSchema,
  createdAt: z.string(),
  settings: z.object({
    annotationListTable: annotationListTableSchema.optional(),
  }),
});
