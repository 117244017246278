import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  display: boolean;
  beforeAnimationClassName: string;
  animationClassName: string;
  children: ReactNode;
  className?: string;
};

const AnimateIn = ({
  display,
  className,
  children,
  beforeAnimationClassName,
  animationClassName,
  ...props
}: Props) => (
  <div
    className={clsx(
      beforeAnimationClassName,
      display && animationClassName,
      className
    )}
    {...props}
  >
    {display ? children : null}
  </div>
);

AnimateIn.defaultProps = {
  className: '',
};

export default AnimateIn;
