import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailTemplateValues } from '../models/emailTemplate';
import { emailTemplateValuesSchema } from '../models/emailTemplate.schema';
import { renderEmailTemplatePayloadSchema } from './render.schema';

export type RenderEmailTemplatePayload = {
  parentEmail?: string;
  documentList?: string[];
  annotationList?: string[];
};

export const render = (
  emailTemplateId: ID,
  payload: RenderEmailTemplatePayload
) => {
  return {
    endpoint: `/email_templates/${emailTemplateId}/render`,
    method: 'POST',
    responseSchema: emailTemplateValuesSchema,
    payload,
    payloadSchema: renderEmailTemplatePayloadSchema,
  } as const;
};
