import * as R from 'remeda';
import { z } from 'zod';
import { collectStringArrays } from '../../../../field-manager/field-manager/field-detail/errors/collectStringArrays';
import { AllFieldKeys, FieldsFormModel } from '../formModels';

const fieldErrorSchema = z.object({
  content: z.array(z.record(z.unknown())),
});

export const FORM_ROOT_KEY = 'root';

export type ErrorEntry = readonly [
  `field.${AllFieldKeys}` | typeof FORM_ROOT_KEY,
  string,
];

const isValidField = (
  value: string,
  formModel: FieldsFormModel
): value is AllFieldKeys => value in formModel.field;

export const handleFieldFormErrors = ({
  response,
  formModel,
}: {
  response: unknown;
  formModel: FieldsFormModel;
}): ErrorEntry[] | null => {
  if (R.isDeepEqual(response, {})) {
    return null;
  }
  const parsed = fieldErrorSchema.safeParse(response);

  const fallbackErrorEntry = [
    FORM_ROOT_KEY,
    JSON.stringify(response, undefined, 2),
  ] as const;

  return parsed.success
    ? // flatten incoming BE response and convert it into entries based on the field key
      R.uniqueBy(
        collectStringArrays(parsed.data).flatMap(errorObj =>
          Object.entries(errorObj).map(([key, errorMessages]) => {
            const message = errorMessages.join('\r\n');
            // field key always comes in at the end
            const snakeCaseKey = key.split('.').pop();
            const fieldKey = snakeCaseKey ? R.toCamelCase(snakeCaseKey) : null;

            return fieldKey && isValidField(fieldKey, formModel)
              ? ([`field.${fieldKey}`, message] as const)
              : fallbackErrorEntry;
          })
        ),
        ([key]) => key
      )
    : [fallbackErrorEntry];
};
