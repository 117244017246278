import { sortBy } from 'lodash';
import React, { forwardRef, useCallback, useState } from 'react';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { useDoubleClickPrevention } from '../hooks/useDoubleClickPrevent';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox, BoundingBoxProps } from './BoundingBox';
import { DatapointTooltip } from './DatapointTooltip';

type DatapointBoundingBoxProps = Omit<
  React.SVGAttributes<SVGRectElement>,
  'onClick'
> & {
  datapointId: number;
  position: Rectangle2DCoordinates;
  label: string;
  active: boolean;
  interactive: boolean;
  disabled: boolean;
  onClick?: (
    e: React.MouseEvent<SVGRectElement, MouseEvent>,
    datapointId: number
  ) => void;
  // onMove and onMoveCancel should be unnecessary with new MagicLine
  // it is good API tho
  onMoveStart?: () => void;
  onMoveEnd?: (
    datapointId: number,
    newPosition: Rectangle2DCoordinates
  ) => void;
  onMoveCancel?: () => void;
  onResizeStart?: () => void;
  onResizeEnd?: (
    datapointId: number,
    newPosition: Rectangle2DCoordinates
  ) => void;
  onResizeCancel?: () => void;
  onPositionChanged?: () => void;
  boundingBoxProps?: Partial<BoundingBoxProps>;
};

export const DatapointBoundingBox = forwardRef<
  SVGRectElement,
  DatapointBoundingBoxProps
>(
  (
    {
      datapointId,
      label,
      active,
      interactive,
      disabled = false,
      position,
      onClick,
      onMoveStart,
      onMoveEnd,
      onMoveCancel,
      onResizeStart,
      onResizeEnd,
      onResizeCancel,
      onPositionChanged,
      boundingBoxProps,
    },
    ref
  ) => {
    // Layering
    const [layerCount, setLayerCount] = useState(0);

    const handleMouseMove: React.MouseEventHandler<SVGRectElement> =
      useCallback(e => {
        const elAtPosition = document.elementsFromPoint(e.clientX, e.clientY);

        setLayerCount(
          elAtPosition.filter(el => el.classList.contains('bounding-box'))
            .length
        );
      }, []);

    // For simplicity we go through boxes by datapoint id
    // Might not be ideal but we don't want to spend too much time on this feature yet
    const handleDoubleClick: React.MouseEventHandler<SVGRectElement> =
      useCallback(
        e => {
          // We need bboxes in stable order
          const boxesAtPosition = sortBy(
            document
              .elementsFromPoint(e.clientX, e.clientY)
              .filter(el => el.classList.contains('bounding-box')),
            [box => box.getAttribute('data-datapoint-id')]
          );

          // find where the current sits
          const currentBoxIndex = boxesAtPosition.findIndex(
            box => box.getAttribute('data-datapoint-id') === String(datapointId)
          );

          // find the one below this one (cycle)
          const nextBox =
            boxesAtPosition[(currentBoxIndex + 1) % boxesAtPosition.length];

          if (!nextBox) {
            return;
          }

          nextBox.dispatchEvent(
            new MouseEvent('click', {
              shiftKey: e.shiftKey,
              bubbles: true,
              clientX: e.clientX,
              clientY: e.clientY,
            })
          );
        },
        [datapointId]
      );

    const [wrappedOnClick, wrappedOnDblClick] = useDoubleClickPrevention({
      onClick: (e: React.MouseEvent<SVGRectElement, MouseEvent>) =>
        onClick?.(e, datapointId),
      onDoubleClick: handleDoubleClick,
    });

    const setHoveredBbox = useDocumentStore(state => state.setHoveredBbox);

    return (
      <DatapointTooltip active={active} label={label} layerCount={layerCount}>
        <BoundingBox
          ref={ref}
          position={position}
          cursor={disabled ? 'default' : active ? 'grab' : undefined}
          canResize={!disabled && active}
          interactive={interactive}
          onMoveStart={onMoveStart}
          onMoveEnd={position => onMoveEnd?.(datapointId, position)}
          onMoveCancel={onMoveCancel}
          onResizeStart={onResizeStart}
          onResizeEnd={position => onResizeEnd?.(datapointId, position)}
          onResizeCancel={onResizeCancel}
          onClick={active ? undefined : wrappedOnClick}
          onPositionChanged={onPositionChanged}
          className="bounding-box"
          data-datapoint-id={datapointId}
          onMouseMove={handleMouseMove}
          onMouseOver={() => setHoveredBbox(datapointId)}
          onMouseOut={() => setHoveredBbox(undefined)}
          onDoubleClick={wrappedOnDblClick}
          {...boundingBoxProps}
        />
      </DatapointTooltip>
    );
  }
);
