import {
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
  FunctionRuntime,
} from '@rossum/api-client/hooks';
import { Tooltip, TooltipProps } from '@rossum/ui/material';
import { find, get, uniq } from 'lodash';
import CodeTagsIcon from 'mdi-react/CodeTagsIcon';
import LanguagePythonIcon from 'mdi-react/LanguagePythonIcon';
import NodejsIcon from 'mdi-react/NodejsIcon';
import WebhookIcon from 'mdi-react/WebhookIcon';
import { FormattedMessage, IntlShape } from 'react-intl';
import { DEV_FEATURES_ENABLED } from '../../../constants/config';
import { Split } from '../../../lib/keyConvertor';
import { ExtensionsView, ExtensionType } from '../../../types/extensions';
import { runtimesConfig } from '../../Extension/config';

export const icons = {
  webhook: WebhookIcon,
  'function.python': LanguagePythonIcon,
  'function.nodejs': NodejsIcon,
  function: CodeTagsIcon,
};

const splitEvent = <T extends string = ExtensionEvent, R = Split<T, '.'>>(
  event: T
): R => event.split('.') as R;

export const getEventTypesFromEvents = (events: Array<ExtensionEvent>) =>
  events.map(event => splitEvent(event)[0]);

// TODO: could be simplified after https://rossumai.atlassian.net/browse/EB-421
export const convertEvents = (events: Array<ExtensionEvent>) =>
  events.reduce<
    Partial<Record<EventType, { name: EventType; actions: Array<EventAction> }>>
  >((acc, event) => {
    const [name, action] = splitEvent(event);

    return {
      ...acc,
      [name]: {
        name,
        actions: action
          ? uniq([...(acc[name]?.actions || []), action])
          : get(find(webhookEvents, { name }), 'actions', []),
      },
    };
  }, {});

export const validateCronString = (value: string | undefined) => {
  if (!value) return false;
  const items = value.replace(/\u00a0/g, ' ').split(' ');
  const itemRegexDigit = /(\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*/;
  const itemRegexDayAbbreviation = /^(MON|TUE|WED|THU|FRI)$/i;
  return (
    items.length === 5 &&
    items.every((item, index) => {
      return index === 4
        ? item.match(itemRegexDigit) || item.match(itemRegexDayAbbreviation)
        : item.match(itemRegexDigit);
    })
  );
};

export const getIcon = (
  type: ExtensionType,
  runtime: FunctionRuntime | undefined,
  props: { size?: number; color?: string },
  tooltipProps?: Partial<TooltipProps>
) => {
  const tooltipId =
    type === extensionWebhookType
      ? extensionWebhookType
      : runtime
        ? (`function.${runtimesConfig[runtime].type}` as const)
        : extensionFunctionType;

  const Icon = icons[tooltipId];

  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id={`containers.settings.extensions.types.${tooltipId}`}
        />
      }
      {...tooltipProps}
    >
      <span>
        <Icon {...props} />
      </span>
    </Tooltip>
  );
};

export const assureCorrectView = (
  view: string | string[] | null
): ExtensionsView => (view === 'list' || view === 'graph' ? view : 'list');

export const webhookEvents = [
  {
    name: 'email',
    actions: ['received'],
  },
  {
    name: 'upload',
    actions: ['created'],
  },
  {
    name: 'annotation_status',
    actions: ['changed'],
  },
  {
    name: 'annotation_content',
    actions: [
      'initialize',
      'started',
      'updated',
      'confirm',
      'export',
      'user_update', // deprecated, only to be shown when the GET /hooks/{id} API response has it set in `hook.events` (set up before the change)
    ],
  },
  {
    name: 'invocation',
    actions: ['scheduled', 'manual'],
  },
] as const;

export type EventShape = (typeof webhookEvents)[number];

// Keep this so we can quickly hide events in the future?
const hiddenEvents: Readonly<Array<EventShape['name']>> = [] as const;
export const visibleWebhookEvents = webhookEvents.filter(e =>
  DEV_FEATURES_ENABLED ? true : !hiddenEvents.includes(e.name)
);

type SplitEvent = Split<ExtensionEvent, '.'>;
export type EventType = SplitEvent[0];
export type EventAction = Exclude<SplitEvent[1], undefined>;

export type ExtensionEventAction = Exclude<ExtensionEvent, EventType>;

export const constructActionId =
  (eventName: EventType) => (action: EventAction) =>
    `${eventName}.${action}` as ExtensionEventAction;

export const getEventActionsIds = (
  event: EventShape
): Array<ExtensionEvent> => {
  const eventName = event.name;
  return [eventName, ...event.actions.map(constructActionId(eventName))];
};

export const webhookNormalizedEvents = visibleWebhookEvents.flatMap(
  ({ name, actions }) => actions.map(constructActionId(name))
);

export const normalizedEventsWithoutInvocation = webhookNormalizedEvents.filter(
  event => !event.includes('invocation.')
);

const isExtensionEventAction = (
  event: ExtensionEvent
): event is ExtensionEventAction => {
  return event.includes('.');
};

export const normalizeExtensionEvents = (events: Array<ExtensionEvent>) => {
  return events.flatMap(event => {
    if (isExtensionEventAction(event)) {
      return event;
    }
    const eventGroup = webhookEvents.find(({ name }) => name === event);

    return eventGroup
      ? eventGroup.actions.map(constructActionId(eventGroup.name))
      : [];
  });
};

export const getExtensionChipText = ({
  event,
  actions,
  intl,
}: {
  event: EventType;
  actions: EventAction[];
  intl: IntlShape;
}) => {
  const actionsText = actions
    .map(action =>
      intl.formatMessage({
        id: `containers.settings.extensions.events.${action}`,
      })
    )
    .join(', ');

  return {
    eventsText: intl.formatMessage({
      id: `containers.settings.extensions.events.${event}`,
    }),
    actionsText,
  };
};
