import { ActionType, createAction } from 'typesafe-actions';
import { SearchResult } from '../../../types/search';

export const displaySearchPanel = createAction(
  'DISPLAY_SEARCH_PANEL',
  (switchState: boolean) => switchState
)<boolean>();

export const nextSearchResult = createAction('NEXT_SEARCH_RESULT')<void>();

export const previousSearchResult = createAction(
  'PREVIOUS_SEARCH_RESULT'
)<void>();

export const getSearchResults = createAction(
  'GET_SEARCH_RESULTS',
  (phrase: string) => ({ phrase })
)<{
  phrase: string;
}>();

export const getSearchResultsFulfilled = createAction(
  'GET_SEARCH_RESULTS_FULFILLED',
  (payload: SearchResult[]) => payload
)<SearchResult[]>();

export type DisplaySearchPanel = typeof displaySearchPanel;
export type NextSearchResult = typeof nextSearchResult;
export type PreviousSearchResult = typeof previousSearchResult;

export type SearchActions = ActionType<
  | DisplaySearchPanel
  | NextSearchResult
  | PreviousSearchResult
  | typeof getSearchResults
  | typeof getSearchResultsFulfilled
>;
