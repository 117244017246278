// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const membershipTokenPaylodSchema = z.object({
  organization: urlSchema,
  origin: z.string().optional(),
});

export const membershipTokenResponseSchema = z.object({
  key: z.string(),
  organization: urlSchema,
});
