export type TaskInfo = {
  type: 'documents_download' | 'upload_created' | 'import';
  status: 'succeeded' | 'failed' | 'running';
};

export const getTaskDialogTitle = (tasks: TaskInfo[]) => {
  if (tasks.every(task => task.status === 'succeeded')) {
    return 'components.asyncTasksDialog.tasks.done.title' as const;
  }

  if (tasks.every(task => task.status === 'failed')) {
    return 'components.asyncTasksDialog.tasks.failure.title' as const;
  }

  if (tasks.every(task => task.type === 'documents_download')) {
    return 'components.asyncTasksDialog.download.title' as const;
  }

  if (tasks.every(task => task.type === 'import')) {
    return 'components.asyncTasksDialog.import.title' as const;
  }

  if (
    tasks.every(
      task => task.type === 'import' || task.type === 'upload_created'
    )
  ) {
    return 'components.asyncTasksDialog.upload.title' as const;
  }

  return 'components.asyncTasksDialog.mixed.title' as const;
};

export const getTaskDialogCounter = (tasks: TaskInfo[]) => {
  const taskSuccessCount = tasks.filter(
    task => task.status === 'succeeded'
  ).length;

  const taskFailureCount = tasks.filter(
    task => task.status === 'failed'
  ).length;

  return taskFailureCount + taskSuccessCount;
};
