import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Email } from '@rossum/api-client/email';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';

export const QUERY_KEY_ANNOTATION_EMAIL = 'annotation-email';

export type AnnotationPart = {
  relatedEmails: string[];
  email: string | null;
  status: AnnotationStatus | null;
};

type UseRelatedEmailProps = {
  annotation: AnnotationPart;
};

type RelevantEmails = Partial<{
  sourceEmail?: Email;
  rejectionEmail?: Email;
  forwardEmail?: Email;
  postponeEmail?: Email;
}>;

const LABEL_REJECTION = 'rejection';
const LABEL_FORWARD = 'forwarded';
const LABEL_POSTPONED = 'postponed';

const pickCorrectEmail = (annotation: AnnotationPart, emails: Email[]) => {
  const relevantEmails = emails.reduce<RelevantEmails>((acc, email) => {
    if (email.url === annotation.email) {
      return { ...acc, sourceEmail: email };
    }
    if (email.labels.includes(LABEL_POSTPONED)) {
      return { ...acc, postponeEmail: email };
    }
    if (email.labels.includes(LABEL_FORWARD)) {
      return { ...acc, forwardEmail: email };
    }
    if (
      email.labels.includes(LABEL_REJECTION) &&
      annotation.status === 'rejected'
    ) {
      return { ...acc, rejectionEmail: email };
    }
    return acc;
  }, {});

  return (
    relevantEmails.rejectionEmail ??
    relevantEmails.postponeEmail ??
    relevantEmails.sourceEmail ??
    relevantEmails.forwardEmail
  );
};

export const useRelatedEmail = ({ annotation }: UseRelatedEmailProps) => {
  const api = useApiClient();
  const emailUrls = [
    ...(annotation.email ? [annotation.email] : []),
    ...annotation.relatedEmails,
  ];

  const query = useQuery({
    queryKey: [QUERY_KEY_ANNOTATION_EMAIL, emailUrls],

    queryFn: () => {
      if (!emailUrls.length) {
        return Promise.reject(new Error('No related emails.'));
      }

      return api.request(
        endpoints.email.list({
          id: emailUrls.map(email => getIDFromUrl(email)),
        })
      );
    },

    enabled: emailUrls.length > 0,
  });

  const email = query.isSuccess
    ? pickCorrectEmail(annotation, query.data.results)
    : undefined;

  return { ...query, email };
};
