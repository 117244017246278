import { Location } from 'history';
import { getAuthToken } from './token';
import { asScalar, AUTH_CODE, parse } from './url';

export type ReferrerState = { referrer?: Location };
export type LocationWithReferrerState = Location<ReferrerState | undefined>;

export const getAuthCodeFromLocation = (
  location: LocationWithReferrerState
) => {
  const lookup = [location.hash, location.state?.referrer?.hash ?? ''];

  return lookup
    .map(hash => asScalar(parse(hash)[AUTH_CODE]))
    .find(code => !!code);
};

export const isUserLoggedIn = (location: LocationWithReferrerState) => {
  // authCode in URL should take precedence over token in storage
  // ignore stored token and pretend user is not logged in
  return getAuthToken() !== null && !getAuthCodeFromLocation(location);
};
