import { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Extension } from '../../../../../types/extensions';
import ConfigApp from '../ConfigApp';
import { fullscreenConfigAppPath, getExtensionConfigAppUrl } from '../helpers';
import { configAppContext } from './configAppContext';

export const ConfigAppProvider = ({
  children,
  selectedExtension,
}: {
  children: (timestamp: number) => ReactNode;
  selectedExtension: Extension;
}) => {
  const [timestamp, setTimestamp] = useState(0);
  const [configAppOpen, setConfigAppOpen] = useState(false);
  const configAppUrl = getExtensionConfigAppUrl(selectedExtension);
  const { pathname, state } = useLocation<{ backLink?: string } | undefined>();
  const backLink = state?.backLink;
  const { push, replace } = useHistory();

  const isFullScreen =
    selectedExtension.config.app?.displayMode === 'fullscreen';

  useEffect(() => {
    if (pathname.includes(fullscreenConfigAppPath) && !isFullScreen) {
      // user can navigate to URL directly but we want to sanitize it
      // in case of displayMode !== 'fullscreen' or config app is not attached at all
      replace({
        pathname: pathname.replace(fullscreenConfigAppPath, ''),
      });
    }
  }, [isFullScreen, pathname, replace]);

  const { openConfigApp, onClose } = isFullScreen
    ? {
        openConfigApp: () => push({ pathname: `${pathname}/app` }),
        onClose: () =>
          push({
            pathname: backLink || pathname.replace(fullscreenConfigAppPath, ''),
          }),
      }
    : {
        openConfigApp: () => setConfigAppOpen(true),
        onClose: () => setConfigAppOpen(false),
      };

  return (
    <configAppContext.Provider
      value={{
        setTimestamp,
        openConfigApp,
        configAppOpen,
        isFullScreen,
        name: selectedExtension.name,
      }}
    >
      {children(timestamp)}
      {configAppUrl && (
        <ConfigApp
          DrawerProps={{
            onClose,
            open: configAppOpen,
          }}
          selectedExtension={selectedExtension}
          configAppUrl={configAppUrl}
        />
      )}
    </configAppContext.Provider>
  );
};
