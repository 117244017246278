import { getIDFromUrl } from '@rossum/api-client';
import { Inbox } from '@rossum/api-client/inboxes';
import { Queue } from '@rossum/api-client/queues';
import { Box } from '@rossum/ui/material';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { UPLOAD_HASH } from '../../../../constants/values';
import { throwError } from '../../../../redux/modules/messages/actions';
import { Documents } from '../../../../types/documents';
import { useGetInbox } from '../../hooks/useGetInbox';
import { useUploadEnabled } from '../../hooks/useUploadEnabled';
import { useUploadEvents } from '../../hooks/useUploadEvents';
import { DragOverlay } from '../DragOverlay';
import { absoluteMaxSizeStyles, findQueueInWorkspace } from '../helpers';
import { UploadProps } from '../UploadV2';
import { Footer } from './Footer';
import { handleFilesUpload, UploadDocumentsPayload } from './handleFilesUpload';
import { useUploadDocumentsOld } from './hooks/useUploadDocumentOld';
import { useUploadWarning } from './hooks/useUploadWarning';
import { OldUploadDialog } from './OldUploadDialog';
import { SelectQueueDialog } from './SelectQueueDialog';
import { TrialUploadWarning } from './TrialUploadWarning';

export const Upload = ({
  children,
  activeQueueUrl,
  workspacesWithQueues,
  pageTitle,
  onUploadDialogClose,
  areColumnsInMotion,
}: UploadProps) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  // to be able to validate & transform the selected files we need to know the queue. On all docs level we don't know the queue
  // That is why we have two states of files (transformed & raw); to have access to the raw files at the moment of selecting the queue
  const [transformedFiles, setTransformedFiles] = useState<Documents['files']>(
    []
  );

  const uploadQueue =
    workspacesWithQueues && activeQueueUrl
      ? findQueueInWorkspace(workspacesWithQueues, activeQueueUrl)
      : undefined;

  const isOnQueueLevel = !!uploadQueue;
  // relying on the Queue type we have in api client was causing type inconsistencies
  // for now we're using the Queue type we have in /types/queue
  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>(
    uploadQueue
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // narrowing the type because so that we can consume Queue objects from redux state and react-query
  const inbox = selectedQueue?.inbox as Inbox | string | undefined;
  const inboxId = inbox
    ? getIDFromUrl(typeof inbox === 'string' ? inbox : inbox.url)
    : 0;
  const { data: queueInbox, isInitialLoading: isInboxLoading } =
    useGetInbox(inboxId);

  const shouldShowWarningBeforeUpload = useUploadWarning({
    isOnQueueLevel,
  });

  const {
    uploadDocuments,
    isLoading: isUploading,
    cancelUpload,
  } = useUploadDocumentsOld();

  useEffect(() => {
    if (
      onUploadDialogClose &&
      transformedFiles.length > 0 &&
      !transformedFiles.some(({ status }) => status !== 'pending')
    ) {
      onUploadDialogClose();
    }
  }, [transformedFiles, onUploadDialogClose]);

  const uploadEnabled = useUploadEnabled(true);

  const acceptedMimeTypes = selectedQueue?.settings.acceptedMimeTypes || [];
  const commonInputProps = {
    type: 'file',
    accept: acceptedMimeTypes.join(),
    multiple: true,
  };
  const isUploadDialogOpen = history.location.hash === UPLOAD_HASH;

  const closeUploadDialog = useCallback(() => {
    history.replace({ ...history.location, hash: '' });

    if (!isOnQueueLevel) setSelectedQueue(undefined);

    cancelUpload();
    setTransformedFiles([]);
    setSelectedFiles(null);
  }, [history, isOnQueueLevel, cancelUpload]);

  const showUploadDialog = () => {
    history.replace({ ...history.location, hash: UPLOAD_HASH });
  };

  const startUpload = ({
    payload,
    queueId,
  }: {
    payload: UploadDocumentsPayload;
    queueId: number;
  }) => {
    uploadDocuments({
      payload,
      queueId,
      onEverySuccess: signature => {
        setTransformedFiles(prev =>
          prev.map(transformedFile =>
            transformedFile.signature === signature
              ? { ...transformedFile, status: 'uploaded' }
              : transformedFile
          )
        );
      },
      onEveryError: (signature, error) =>
        setTransformedFiles(prev =>
          prev.map(transformedFile =>
            transformedFile.signature === signature
              ? {
                  ...transformedFile,
                  status: 'failed',
                  reason: JSON.stringify(error),
                }
              : transformedFile
          )
        ),
    });
  };

  const successfulUploadExists = transformedFiles.some(
    file => file.status === 'uploaded'
  );

  // This function handles the cases when;
  // 1. The user drags & drops onto the dashboard.
  // 2. The user clicks on upload button, clicks on choose files and selects files.
  // 3. The user clicks on upload button, and drags & drops files into the upload area.
  // 4. The trial user tries to upload, we pause the upload and we show a warning.
  const handleOnChange = ({
    queue,
    files,
    openModalOnUpload,
  }: {
    queue: Queue;
    files: FileList | null | undefined;
    openModalOnUpload?: boolean;
  }) => {
    const isUploadValid = handleFilesUpload({
      files,
      acceptedMimeTypes: queue.settings.acceptedMimeTypes,
      queueUrl: queue.url,
      alreadyHandledFiles: transformedFiles,
      onError: id => dispatch(throwError(id)),
      onUpload: validatedFiles => {
        if (!validatedFiles.length) return;

        setTransformedFiles(prev => prev.concat(validatedFiles));

        if (successfulUploadExists || !shouldShowWarningBeforeUpload)
          startUpload({ payload: validatedFiles, queueId: queue.id });
      },
    });

    if (isUploadValid && openModalOnUpload) {
      showUploadDialog();
    }
  };

  const onEscape = useCallback(() => closeUploadDialog(), [closeUploadDialog]);

  useUploadEvents({
    onEscape,
  });

  return (
    <Box
      onDragStart={e => {
        // this prevents from dragging elements on the page (e.g. links etc.)
        e.preventDefault();
      }}
      // onDragEnter does not work because of a verified issue of Data Grid Pro: https://github.com/mui/mui-x/issues/10164
      onDragOver={e => {
        e.preventDefault();
        if (uploadEnabled && !isDragging && !areColumnsInMotion) {
          setIsDragging(true);
        }
      }}
      data-page-title={pageTitle}
      position="relative"
      flexGrow={1}
      height={1}
      sx={{ backgroundColor: 'inherit' }}
    >
      <Box
        component="input"
        disabled={!uploadEnabled}
        onDragLeave={() => setIsDragging(false)}
        onDrop={() => setIsDragging(false)}
        onClick={event => event.preventDefault()}
        width={isDragging ? 1 : 0}
        sx={{
          cursor: 'copy',
          opacity: 0,
          zIndex: theme => theme.zIndex.tooltip,
          ...absoluteMaxSizeStyles,
        }}
        // changing key clears the file input
        key={`${isUploadDialogOpen}-1`}
        onChange={e => {
          // if file drop happens on all docs level, selectedQueue is undefined
          if (selectedQueue) {
            handleOnChange({
              queue: selectedQueue,
              files: e.target.files,
              openModalOnUpload: true,
            });
          } else {
            setSelectedFiles(e.target.files);
            showUploadDialog();
          }
        }}
        {...commonInputProps}
      />
      {isDragging && <DragOverlay />}
      {selectedQueue && (
        <OldUploadDialog
          shouldShowWarningBeforeUpload={shouldShowWarningBeforeUpload}
          open={isUploadDialogOpen}
          onClose={closeUploadDialog}
          fileList={transformedFiles}
          currentQueue={selectedQueue}
          inboxEmail={queueInbox?.email}
          isInboxLoading={isInboxLoading}
          uploadInputProps={{
            ...commonInputProps,
            onChange: e => {
              handleOnChange({
                queue: selectedQueue,
                files: e.target.files,
                openModalOnUpload: false,
              });
            },
          }}
        >
          {transformedFiles.length ? (
            <Footer
              isUploading={isUploading}
              queueName={selectedQueue.name}
              closing={false}
              onClose={closeUploadDialog}
              cancelUpload={cancelUpload}
              transformedFiles={transformedFiles}
            >
              {shouldShowWarningBeforeUpload && !successfulUploadExists ? (
                <TrialUploadWarning
                  onConfirm={() => {
                    const payload = transformedFiles.filter(
                      (file): file is UploadDocumentsPayload[number] =>
                        file.status === 'pending'
                    );
                    startUpload({
                      payload,
                      queueId: selectedQueue.id,
                    });
                  }}
                >
                  {({ showModal, setShowModal }) =>
                    workspacesWithQueues && (
                      <SelectQueueDialog
                        title={intl.formatMessage({
                          id: 'containers.annotationList.upload.selectQueueTitle',
                        })}
                        workspaces={workspacesWithQueues}
                        onClose={() => setShowModal(false)}
                        open={showModal}
                        onSubmit={selectedQueue => {
                          setSelectedQueue(selectedQueue);
                          setShowModal(false);
                        }}
                        submitText={intl.formatMessage({
                          id: 'components.selectQueueDialog.submit',
                        })}
                      />
                    )
                  }
                </TrialUploadWarning>
              ) : null}
            </Footer>
          ) : null}
        </OldUploadDialog>
      )}
      {workspacesWithQueues && (
        <SelectQueueDialog
          title={intl.formatMessage({
            id: 'containers.annotationList.upload.selectQueueTitle',
          })}
          workspaces={workspacesWithQueues}
          onClose={closeUploadDialog}
          open={isUploadDialogOpen && !selectedQueue}
          onSubmit={selectedQueue => {
            setSelectedQueue(selectedQueue);
            handleOnChange({
              queue: selectedQueue,
              files: selectedFiles,
              openModalOnUpload: false,
            });
          }}
          submitText={intl.formatMessage({
            id: 'components.selectQueueDialog.submit',
          })}
        />
      )}
      {typeof children === 'function'
        ? children({
            onUpload: files => {
              if (selectedQueue) {
                handleOnChange({
                  queue: selectedQueue,
                  files,
                  openModalOnUpload: true,
                });
              }
            },
          })
        : children}
    </Box>
  );
};
