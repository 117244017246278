import { VirtualElement } from '@popperjs/core';
import { Close } from '@rossum/ui/icons';
import { ArrowDropDown as ArrowDropDownIcon } from '@rossum/ui/icons';
import { ArrowDropUp as ArrowDropUpIcon } from '@rossum/ui/icons';
import {
  CircularProgress,
  ClickAwayListener,
  FormControl,
  Grow,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';
import { Queue } from '../../../types/queue';
import { WorkspaceWithQueues } from '../../../types/workspace';
import SingleQueueSelectList from '../SingleQueueSelectList';
import {
  activeLabelStyle,
  activeOutlineOpenStyle,
  activeOutlineStyle,
} from './styles';

const QUEUES_SELECT_WIDTH = 300;

type SingleQueueSelectProps = {
  workspaces: Array<WorkspaceWithQueues>;
  selectedQueue: Queue | undefined;
  setSelectedQueue: (_queue: Queue | undefined) => void;
  allQueuesLoaded: boolean;
  onCloseClick: (e: MouseEvent) => void;
};

const SingleQueueSelect = ({
  workspaces,
  selectedQueue,
  setSelectedQueue,
  allQueuesLoaded,
  onCloseClick,
}: SingleQueueSelectProps) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<VirtualElement | null>(null);

  const handleClick: MouseEventHandler = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FormControl variant="outlined" sx={{ width: QUEUES_SELECT_WIDTH }}>
        <TextField
          label={intl.formatMessage({
            id: 'containers.settings.extensions.queues.select.label',
          })}
          InputLabelProps={{
            sx: selectedQueue || open ? activeLabelStyle : {},
          }}
          value={
            selectedQueue
              ? selectedQueue.name
              : intl.formatMessage({
                  id: 'containers.settings.extensions.queues.select.placeholder',
                })
          }
          sx={{
            width: QUEUES_SELECT_WIDTH,
            ...(open
              ? activeOutlineOpenStyle
              : selectedQueue
                ? activeOutlineStyle
                : {}),
          }}
          size="small"
          onMouseDown={e => allQueuesLoaded && handleClick(e)}
          disabled={!allQueuesLoaded}
          InputProps={{
            endAdornment: (
              <>
                {selectedQueue && (
                  <IconButton
                    size="small"
                    onMouseDown={e => {
                      onCloseClick(e);
                      setAnchorEl(null);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
                {allQueuesLoaded ? (
                  open ? (
                    <ArrowDropUpIcon sx={{ cursor: 'pointer' }} />
                  ) : (
                    <ArrowDropDownIcon sx={{ cursor: 'pointer' }} />
                  )
                ) : (
                  <CircularProgress sx={{ flex: '0 0 auto' }} size={18} />
                )}
              </>
            ),
            inputProps: {
              readOnly: true,
              sx: { cursor: allQueuesLoaded && 'pointer', pl: 3 },
            },
          }}
          data-cy="extensions-queue-select"
        />
      </FormControl>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={{ zIndex: 1 }}
        onKeyUp={event => event.key === 'Escape' && setAnchorEl(null)}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => setAnchorEl(null)}
            mouseEvent="onMouseDown"
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center top',
              }}
            >
              <Paper
                sx={{
                  width: QUEUES_SELECT_WIDTH,
                }}
              >
                <Stack maxWidth={QUEUES_SELECT_WIDTH} maxHeight={600}>
                  <SingleQueueSelectList
                    onChange={selected => {
                      if (selected === selectedQueue) {
                        // deselect the option but do not close the menu
                        return setSelectedQueue(undefined);
                      }
                      setSelectedQueue(selected);
                      return setAnchorEl(null);
                    }}
                    selectedQueue={selectedQueue}
                    workspaces={workspaces}
                  />
                </Stack>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default SingleQueueSelect;
