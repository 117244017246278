import { useCallback, useState } from 'react';
import { DOCUMENTS_SIDEBAR_IS_OPEN } from '../../../redux/modules/localStorage/actions';

const useSidebarState = () => {
  const storedSidebarState = localStorage.getItem(DOCUMENTS_SIDEBAR_IS_OPEN);

  const [isSidebarOpen, setIsSidebarOpen] = useState(
    storedSidebarState ? storedSidebarState === 'true' : true
  );

  const toggleSidebar = useCallback(() => {
    const newState = !isSidebarOpen;

    setIsSidebarOpen(newState);
    localStorage.setItem(
      DOCUMENTS_SIDEBAR_IS_OPEN,
      newState ? 'true' : 'false'
    );
  }, [isSidebarOpen]);

  return { isSidebarOpen, toggleSidebar };
};

export default useSidebarState;
