import { differenceBy } from 'lodash';
import * as mime from 'mime-types';
import { MessageId } from '../../../../redux/modules/messages/actions';
import { DocumentUpload } from '../../../../types/documents';

export type UploadDocumentsPayload = Array<
  DocumentUpload<'pending'> & { file: File }
>;

const createFileSignature = ({ name, size }: File) => `${name}${size}`;

const getValidFiles = (
  files: File[],
  acceptedMimeTypes: HandleFilesUploadPayload['acceptedMimeTypes']
) => {
  if (!acceptedMimeTypes) return files;

  return files.filter(
    ({ type }) =>
      !!mime.contentType(type) &&
      acceptedMimeTypes.some(mimeType => {
        if (mimeType === type) return true;

        if (mimeType.includes('/*')) {
          return mimeType.split('/')?.[0] === type.split('/')?.[0];
        }

        return false;
      })
  );
};

type HandleFilesUploadPayload = {
  files: FileList | null | undefined;
  acceptedMimeTypes: string[] | undefined;
  queueUrl: string;
  alreadyHandledFiles: Array<DocumentUpload>;
  onUpload: (payload: UploadDocumentsPayload) => void;
  onError: (id: MessageId) => void;
};

export const handleFilesUpload = ({
  files,
  acceptedMimeTypes,
  queueUrl,
  alreadyHandledFiles,
  onUpload,
  onError,
}: HandleFilesUploadPayload) => {
  if (!files) return false;

  const filesArray = Array.from(files);

  if (!filesArray.length) return false;

  const validFiles = getValidFiles(filesArray, acceptedMimeTypes);

  if (validFiles.length) {
    const payload = validFiles.map(
      file =>
        ({
          file,
          status: 'pending',
          signature: createFileSignature(file),
          name: file.name,
          queueUrl,
        }) as const
    );

    const deduplicated = differenceBy(
      payload,
      alreadyHandledFiles,
      'signature'
    );

    onUpload(deduplicated);
    return true;
  }

  onError('invalidMimeType');
  return false;
};
