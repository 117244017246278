import { alpha, useTheme } from '@rossum/ui/material';
import { partition } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Rectangle2DCoordinates } from '../../features/annotation-view/document-canvas/utils/geometry';
import { CliLabelConfig } from '../../features/annotation-view/document-store/documentBboxStoreSlice';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import {
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
  getBoundedDatapointsPerPage,
  messagesSelector,
} from '../../redux/modules/datapoints/selector';
import {
  replaceSuggestedOperationsWithPositionPerPage,
  suggestedOperationsOptionsSelector,
} from '../../redux/modules/datapoints/suggestedOperations/selector';
import { getBorderColor } from '../../redux/modules/datapoints/suggestedOperations/utils';
import { isVirtualDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import { BoundedDatapoint } from '../../redux/modules/datapoints/types';
import {
  AnyDatapointDataST,
  DatapointsST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
  SuggestedOperationWithMeta,
} from '../../types/datapoints';

export const bboxColorMap = {
  success: '#8CFF32',
  warning: '#FAFF00',
  error: '#E4002B',
} as const;

type BoundingBox = {
  id: number;
  variant?: 'success' | 'warning' | 'error';
  type: 'datapoint' | 'tableSuggestion' | 'extensionSuggestion';
  position: Rectangle2DCoordinates;
  active?: boolean;
  activeRow?: boolean;
  highlighted: boolean;
  schemaId: string;
};

type BoundingBoxStyle = Partial<{
  outlineColor: string;
  outlineStyle: 'dashed' | 'solid' | 'none';
  color: string;
}>;

type FindBoundingBoxesParams = {
  suggestedOperations: SuggestedOperationWithMeta[];
  boundedDatapoints: BoundedDatapoint[];
  showingSuggestions: boolean;
  currentDatapoint: AnyDatapointDataST | undefined;
  messages: DatapointsST['messages'];
  isDatapointHighlighted: (dp: SimpleDatapointDataST) => boolean;
};

const isDatapointInHoveredLabel =
  (
    currentMultivalue: MultivalueDatapointDataST | null,
    hoveredCliLabel: CliLabelConfig | undefined
  ) =>
  (datapoint: SimpleDatapointDataST) =>
    hoveredCliLabel && currentMultivalue
      ? ((hoveredCliLabel.type === 'page' &&
          datapoint.content?.page === hoveredCliLabel.pageNumber) ||
          (hoveredCliLabel.type === 'column' &&
            datapoint.schemaId === hoveredCliLabel.schemaId) ||
          (hoveredCliLabel.type === 'row' &&
            datapoint.meta.parentId === hoveredCliLabel.tupleId)) &&
        currentMultivalue.children.some(
          child => child.id === datapoint.meta.parentId
        )
      : false;

const findBoundingBoxes = ({
  suggestedOperations,
  boundedDatapoints,
  showingSuggestions,
  currentDatapoint,
  messages,
  isDatapointHighlighted,
}: FindBoundingBoxesParams) => {
  const [[activeDatapoint], passiveDatapoints] = partition(
    boundedDatapoints,
    dp => dp.id === currentDatapoint?.id
  );

  const existsTableOperationForDatapoint = (dp: BoundedDatapoint) =>
    suggestedOperations.some(op => op.id === dp.id && op.source === 'table');

  const isActiveRow = (dp: SimpleDatapointDataST) =>
    currentDatapoint &&
    currentDatapoint.meta.parentId &&
    !dp.meta.sidebarDatapoint
      ? currentDatapoint.meta.parentId === dp.meta.parentId
      : false;

  const passiveDatapointBoundingBoxes: BoundingBox[] = passiveDatapoints
    .filter(dp => !existsTableOperationForDatapoint(dp))
    .map((dp): BoundingBox => {
      const isSuccessBbox = dp.validationSources.includes('human');

      const variant =
        messages[dp.id]?.type === 'error'
          ? 'error'
          : isSuccessBbox
            ? 'success'
            : 'warning';

      return {
        id: dp.id,
        variant,
        activeRow: isActiveRow(dp),
        type: 'datapoint',
        schemaId: dp.schemaId,
        position: dp.content.position,
        highlighted: isDatapointHighlighted(dp),
      };
    });

  const tableOperationBoundingBoxes = suggestedOperations
    .filter(op => op.source === 'table')
    .flatMap<BoundingBox>(op =>
      op.value.content.position
        ? [
            {
              id: op.id,
              type: 'tableSuggestion' as const,
              position: op.value.content.position,
              schemaId: op.meta.datapoint.schemaId,
              activeRow: isActiveRow(op.meta.datapoint),
              highlighted: isDatapointHighlighted(op.meta.datapoint),
              active: currentDatapoint && currentDatapoint.id === op.id,
            },
          ]
        : []
    );

  const extensionOperationBoundingBoxes = suggestedOperations
    .filter(op => op.source === 'extension')
    .flatMap<BoundingBox>(op =>
      op.value.content.position
        ? [
            {
              id: op.id,
              type: 'extensionSuggestion' as const,
              position: op.value.content.position,
              schemaId: op.meta.datapoint.schemaId,
              highlighted: false,
            },
          ]
        : []
    );

  const activeDatapointBoundingBoxes: BoundingBox[] = (
    activeDatapoint && !existsTableOperationForDatapoint(activeDatapoint)
      ? [activeDatapoint]
      : []
  ).map(
    (dp: BoundedDatapoint): BoundingBox => ({
      id: dp.id,
      type: 'datapoint' as const,
      position: dp.content.position,
      schemaId: dp.schemaId,
      active: true,
      highlighted: false,
    })
  );

  const boundingBoxes = showingSuggestions
    ? [...activeDatapointBoundingBoxes]
    : [
        ...passiveDatapointBoundingBoxes,
        ...tableOperationBoundingBoxes,
        ...extensionOperationBoundingBoxes,
        ...activeDatapointBoundingBoxes,
      ];

  return boundingBoxes;
};

export const useBoundingBoxes = (
  showingSuggestions: boolean,
  pageNumber: number
) => {
  const theme = useTheme();

  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);

  const messages = useSelector(messagesSelector);
  const currentDatapoint = useSelector(currentDatapointSelector);
  const suggestedOperations = useSelector(
    replaceSuggestedOperationsWithPositionPerPage
  )[pageNumber];
  const boundedDatapoints = useSelector(getBoundedDatapointsPerPage)[
    pageNumber
  ];

  const currentMultivalue = useSelector(currentMultivalueDatapointSelector);

  const hoveredCliLabel = useDocumentStore(state => state.hoveredCliLabel);
  const isDatapointHighlighted = isDatapointInHoveredLabel(
    currentMultivalue,
    hoveredCliLabel
  );

  const suggestedOperationsOptions = useSelector(
    suggestedOperationsOptionsSelector
  );

  const getSuggestedBoxColor = useCallback(
    (schemaId: string) => {
      return getBorderColor(schemaId, suggestedOperationsOptions.schemaIds);
    },
    [suggestedOperationsOptions.schemaIds]
  );

  const boundingBoxes = useMemo(
    () =>
      findBoundingBoxes({
        messages,
        suggestedOperations: suggestedOperations ?? [],
        boundedDatapoints: boundedDatapoints ?? [],
        currentDatapoint,
        showingSuggestions,
        isDatapointHighlighted,
      }),
    [
      boundedDatapoints,
      currentDatapoint,
      messages,
      showingSuggestions,
      suggestedOperations,
      isDatapointHighlighted,
    ]
  );

  const styledBoundingBoxes = useMemo(() => {
    const getBoundingBoxStyle = (
      boundingBox: BoundingBox
    ): BoundingBoxStyle => {
      if (boundingBox.active) {
        return {
          outlineStyle: 'solid',
          outlineColor: theme.palette.primary.main,
        };
      }

      if (selectedDatapointIds.includes(boundingBox.id)) {
        return {
          outlineStyle: 'solid',
          outlineColor: theme.palette.primary.main,
        };
      }
      if (boundingBox.highlighted) {
        return {
          outlineStyle: 'solid',
          outlineColor: theme.palette.primary.dark,
        };
      }

      const color = boundingBox.variant
        ? bboxColorMap[boundingBox.variant]
        : undefined;

      const isVirtual =
        boundingBox.type === 'tableSuggestion' ||
        (boundingBox.type === 'datapoint' &&
          isVirtualDatapoint(boundingBox.id));

      if (boundingBox.activeRow) {
        return {
          outlineStyle: isVirtual ? 'dashed' : 'solid',
          outlineColor: alpha(theme.palette.primary.main, 0.5),
          color: isVirtual ? undefined : color,
        };
      }

      if (boundingBox.type === 'extensionSuggestion') {
        return {
          outlineStyle: 'dashed',
          outlineColor: getSuggestedBoxColor(boundingBox.schemaId),
        };
      }

      if (isVirtual) {
        return {
          outlineStyle: 'dashed',
          outlineColor: theme.palette.aurora.main,
        };
      }

      return { outlineStyle: 'none', color };
    };

    return boundingBoxes.map(boundingBox => ({
      ...boundingBox,
      style: getBoundingBoxStyle(boundingBox),
    }));
  }, [boundingBoxes, selectedDatapointIds, theme, getSuggestedBoxColor]);

  return {
    boundingBoxes: styledBoundingBoxes,
  };
};
