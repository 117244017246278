import { ID } from '../../utils/codecUtils';
import { Engine } from '../models/engine';
import { engineSchema } from '../models/engine.schema';
import { enginePatchPayloadSchema } from './patch.schema';

export type EnginePatchPayload = Pick<
  Partial<Engine>,
  'name' | 'learningEnabled' | 'description'
>;

export const patch = (engineId: ID, payload: EnginePatchPayload) => {
  return {
    endpoint: `/engines/${engineId}`,
    method: 'PATCH',
    responseSchema: engineSchema,
    payloadSchema: enginePatchPayloadSchema,
    payload,
  } as const;
};
