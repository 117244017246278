import { zodResolver } from '@hookform/resolvers/zod';
import { Schema, SchemaSection } from '@rossum/api-client/schemas';
import { Alert, Button, Collapse, Stack, useTheme } from '@rossum/ui/material';
import { useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Prompt, useHistory } from 'react-router';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { linebreak, link } from '../../../../lib/formaterValues';
import { ContentGroup } from '../../../../ui/content-group/ContentGroup';
import {
  DELETE_FIELD_FORM_ID,
  FIELD_FORM_ID,
  getLinkStyles,
  IDENTIFICATION_SECTION_LINK,
} from '../constants';
import { FORM_ROOT_KEY } from '../form-model/errors/errorParsers';
import { fieldsFormErrorMap } from '../form-model/errors/formErrorMap';
import { useValidateFormErrors } from '../form-model/errors/useValidateFormErrors';
import {
  FieldsFormModel,
  fieldsFormSchema,
  FieldsFormValues,
} from '../form-model/formModels';
import { toFormValues } from '../form-model/transformations';
import { useConfirmationDialog } from '../hooks/useConfirmationDialog';

// Redefining API as this is supposed to be `SectionDetailForm` or something like that
// so it can submit, delete or error out
export type SectionContentProps = {
  data: SchemaSection | null;
  schema: Schema;
  onSubmit: (formModel: FieldsFormModel) => void;
  onDelete: (sectionId: string) => void;
  // TODO: Add `onError`
};

export const SectionContent = ({
  schema,
  data,
  onSubmit,
  onDelete,
}: SectionContentProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const [rootError, setRootError] = useState<string | null>(null);

  const { dialog, setDialogState, dialogState } = useConfirmationDialog();

  const onValidateFail = useCallback(() => {
    skipPrompt.current = false;
  }, []);

  const { validateForm } = useValidateFormErrors({
    schema,
    parentId: null,
    data,
    onValidateFail,
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FieldsFormValues, undefined, FieldsFormModel>({
    defaultValues: toFormValues(
      // TODO: This should be prettier
      data || ({ category: 'section' } as SchemaSection)
    ),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(
      fieldsFormSchema(intl).superRefine(async (formValues, ctx) => {
        const errorEntries = await validateForm(formValues);

        setRootError(null);

        if (errorEntries) {
          errorEntries.forEach(([key, message]) => {
            if (key === FORM_ROOT_KEY) setRootError(message);

            ctx.addIssue({ code: 'custom', path: [key], message });
          });
        }
      }),
      {
        errorMap: fieldsFormErrorMap(intl),
      }
    ),
  });

  const { handleSubmit: handleDeleteFormSubmit } = useForm({
    defaultValues: {
      id: data?.id ?? null,
    },
  });

  // resets on remounts and this component is being re-mounted every time `data` changes
  // not just rerendered (`key` from parent)
  const skipPrompt = useRef(false);

  const deleteHandler: SubmitHandler<{ id: string | null }> = useCallback(
    ({ id }) => {
      if (id) {
        skipPrompt.current = true;
        onDelete(id);
      }
    },
    [onDelete]
  );

  const submitHandler: SubmitHandler<FieldsFormModel> = useCallback(
    formModel => {
      skipPrompt.current = true;
      onSubmit(formModel);
    },
    [onSubmit]
  );

  const [errorExpanded, setErrorExpanded] = useState(false);

  return (
    <>
      <Stack
        p={4}
        spacing={4}
        id={FIELD_FORM_ID}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
      >
        {rootError ? (
          <Alert
            variant="filled"
            severity="error"
            action={
              <Button
                color="inherit"
                variant="text"
                onClick={() => setErrorExpanded(expanded => !expanded)}
                sx={{ flexShrink: 0 }}
              >
                {intl.formatMessage({
                  id: 'features.queueSettings.fields.form.errors.encountered.more',
                })}
              </Button>
            }
          >
            {intl.formatMessage({
              id: 'features.queueSettings.fields.form.errors.encountered',
            })}
            <Collapse in={errorExpanded}>
              <pre>{rootError}</pre>
            </Collapse>
          </Alert>
        ) : null}
        <ContentGroup
          title={intl.formatMessage({
            id: 'features.queueSettings.fields.form.identification.title',
          })}
          description={intl.formatMessage(
            {
              id: 'features.queueSettings.fields.form.identification.description',
            },
            {
              linebreak,
              link: link(IDENTIFICATION_SECTION_LINK, getLinkStyles(theme)),
            }
          )}
        >
          <TextFieldControl
            autoFocus
            ControllerProps={{ control, name: 'field.label' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.label.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.id' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.id.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
          <TextFieldControl
            ControllerProps={{ control, name: 'field.description' }}
            label={intl.formatMessage({
              id: 'features.queueSettings.fields.form.description.label',
            })}
            FieldLabelProps={{
              layout: 'floating',
            }}
          />
        </ContentGroup>
      </Stack>
      <Stack
        display="none"
        component="form"
        id={DELETE_FIELD_FORM_ID}
        onSubmit={e => {
          e.preventDefault();
          setDialogState({
            key: 'deleteSchemaSection',
            onConfirm: handleDeleteFormSubmit(deleteHandler),
          });
        }}
      />
      <Prompt
        message={location => {
          if (dialogState) {
            setDialogState(null);
            return true;
          }

          setDialogState({
            key: 'notSavedChanges',
            onConfirm: () => history.replace(location),
          });

          return false;
        }}
        when={!skipPrompt.current && isDirty}
      />
      {dialog}
    </>
  );
};
