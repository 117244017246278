import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getAuthToken } from '../../../lib/token';

export const usePagePreview = (pageUrl: string | undefined) =>
  useQuery({
    queryKey: ['emails-page-preview', pageUrl] as const,

    queryFn: async () => {
      const response = await axios.get<Blob>(`${pageUrl}/preview`, {
        headers: { Authorization: `Token ${getAuthToken()}` },
        responseType: 'blob',
        method: 'GET',
      });
      return URL.createObjectURL(response.data);
    },
    enabled: pageUrl !== undefined,
    staleTime: 3600 * 1000,
  });
