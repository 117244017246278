import { ElisRequestConfig } from '../../utils/requestConfig';
import { WorkflowStep, WorkflowStepUrl } from '../models/workflowStep';
import { workflowStepSchema } from '../models/workflowStep.schema';

export const get = (workflowStepUrl: WorkflowStepUrl) => {
  return {
    endpoint: workflowStepUrl,
    method: 'GET',
    responseSchema: workflowStepSchema,
  } as const;
};
