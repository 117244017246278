import { endpoints } from '@rossum/api-client';
import { EmailTemplateListQuery } from '@rossum/api-client/emailTemplates';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED =
  'email-templates-unpaginated';

export const useEmailTemplatesPerQueueUnpaginated = (
  queue: number | null,
  query: EmailTemplateListQuery = {}
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED, query] as const,
    queryFn: ({ queryKey }) => {
      if (!queue) return Promise.reject();

      return api.unpaginatedRequest(endpoints.emailTemplates.list)({
        ...queryKey[1],
        queue,
      });
    },
    enabled: queue !== null,
  });
};
