import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { Chip, Tooltip } from '@rossum/ui/material';
import { isNumber } from 'lodash';
import { useIntl } from 'react-intl';
import { isDefaultRejectionEmailTemplateType } from './helpers';

const USAGES_COUNT_PERIOD_LENGTH = 90;

type EmailTemplatesChipsProps = {
  emailTemplate: EmailTemplate | Pick<EmailTemplate, 'type'>;
  numberOfUsages?: number;
};

const EmailTemplateChips = ({
  emailTemplate,
  numberOfUsages: _numberOfUsages,
}: EmailTemplatesChipsProps) => {
  const intl = useIntl();
  const numberOfUsages = isNumber(_numberOfUsages) ? _numberOfUsages : 0;

  return (
    <>
      {!isDefaultRejectionEmailTemplateType(emailTemplate.type) && (
        <Chip
          size="tiny"
          label={intl.formatMessage({
            id: `containers.settings.queues.email.emailTemplates.${
              'automate' in emailTemplate &&
              emailTemplate.automate &&
              emailTemplate.triggers.length
                ? 'automationEnabled'
                : 'automationDisabled'
            }`,
          })}
        />
      )}
      {numberOfUsages !== 0 && (
        <Tooltip
          placement="top"
          title={intl.formatMessage(
            {
              id: 'containers.settings.queues.email.emailTemplates.numberOfUsages.tooltip',
            },
            {
              count: numberOfUsages,
              usagesCountPeriodLength: USAGES_COUNT_PERIOD_LENGTH,
            }
          )}
        >
          <Chip size="tiny" label={numberOfUsages} />
        </Tooltip>
      )}
    </>
  );
};

export default EmailTemplateChips;
