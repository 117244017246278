import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { GenericEngine } from '../models/genericEngine';
import { genericEngineSchema } from '../models/genericEngine.schema';
import { genericEngineListQuerySchema } from './list.schema';

export type GenericEngineListQuery = {
  pageSize?: number;
  page?: number;
  fields?: (
    | 'id'
    | 'name'
    | 'url'
    | 'schema'
    | 'description'
    | 'documentationUrl'
  )[];
  ordering?: (
    | 'id'
    | 'name'
    | 'url'
    | 'schema'
    | 'description'
    | 'documentationUrl'
    | '-id'
    | '-name'
    | '-url'
    | '-schema'
    | '-description'
    | '-documentationUrl'
  )[];
  id?: number | number[];
  name?: number | number[];
  url?: number | number[];
  schema?: number | number[];
  description?: number | number[];
  documentationUrl?: number | number[];
};

export const list = (query: GenericEngineListQuery = {}) => {
  return {
    endpoint: `/generic_engines`,
    method: 'GET',
    responseSchema: listResponse(genericEngineSchema),
    query,
    querySchema: genericEngineListQuerySchema,
  } as const;
};
