// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const documentSchema = z.object({
  id: idSchema,
  url: urlSchema,
  s3Name: z.string().nullable(),
  parent: z.string().nullable(),
  email: z.string().nullable(),
  mimeType: z.string(),
  creator: urlSchema.nullable(),
  createdAt: z.string(),
  arrivedAt: z.string(),
  originalFileName: z.string().nullable(),
  content: urlSchema.nullable(),
  attachmentStatus: z.string().nullable(),
  metadata: z.unknown().optional(),
  annotations: z.array(urlSchema),
});
