import { initial, last, mean } from 'lodash';
import { Rectangle } from '../../../components/MagicGridV2/utils';
import { BboxType } from '../../../types/bbox';
import { ColumnPosition, Grid } from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import { ensureMutable } from '../utils';

const initialColumn: ColumnPosition = {
  leftPosition: 0,
  schemaId: null,
  headerTexts: [],
};

// create rulers

const MIN_ROW_WIDTH = 15;

const compareHeight = (
  { rectangle: [, bTop1, , bBottom1] }: BboxType,
  { rectangle: [, bTop2, , bBottom2] }: BboxType
) => bBottom1 - bTop1 - (bBottom2 - bTop2);

const exclusiveJoinCallback = (acc: Array<BboxType>, bbox: BboxType) => {
  if (
    acc.some(
      ({ rectangle: [, bTop, , bBottom] }) =>
        (bbox.rectangle[1] > bTop && bbox.rectangle[1] < bBottom) ||
        (bbox.rectangle[3] > bTop && bbox.rectangle[3] < bBottom)
    )
  ) {
    return acc;
  }

  return [...acc, bbox];
};

const countTopPositionsCallback = (
  acc: Array<BboxType & { topPosition: number }>,
  bbox: BboxType,
  index: number,
  sortedBboxes: Array<BboxType>
) => {
  if (!index) return acc; // skip first row

  const previousElement = last(acc) || sortedBboxes[0];

  if (bbox.rectangle[1] - previousElement.rectangle[1] < MIN_ROW_WIDTH)
    return acc;

  return [
    ...acc,
    {
      ...bbox,
      topPosition: Math.round(
        (bbox.rectangle[1] + previousElement.rectangle[3]) / 2
      ),
    },
  ];
};

const createRows = (bboxes: Array<BboxType>, type: string | null | undefined) =>
  ensureMutable(bboxes)
    .sort(compareHeight)
    .reduce(exclusiveJoinCallback, [])
    .sort(({ rectangle: [, bTop1] }, { rectangle: [, bTop2] }) => bTop1 - bTop2)
    .reduce(countTopPositionsCallback, [])
    .map(({ topPosition }) => ({ topPosition, type, tupleId: null }));

const createColumnsPositions = (
  bboxes: Array<BboxType>,
  leftGridEdge: number,
  width: number,
  height: number
) =>
  ensureMutable(bboxes)
    .filter(
      ({ rectangle: [leftBboxEdge, , bRight] }) =>
        leftBboxEdge > leftGridEdge || bRight < leftGridEdge + width
    )
    .reduce<Array<number>>(
      (acc, { rectangle: [leftBboxEdge, , bRight] }) => [
        ...acc,
        leftBboxEdge,
        bRight,
      ],
      []
    )
    .filter(edge => edge > leftGridEdge && edge < leftGridEdge + width)
    .sort((a, b) => a - b)
    .reduce<number[][]>(
      (acc, edge) => {
        const previousEdge = last(last(acc));
        const maxEdgeDifference = width / 70;

        if (previousEdge && edge - previousEdge > maxEdgeDifference)
          return [...acc, [edge]];

        return [...initial(acc), [...(last(acc) || []), edge]];
      },
      [[]]
    )
    .reduce<number[][]>((acc, edgeCluster, index, edgeClusters) => {
      const minClusterLength = height / 70;

      if (!index || index === edgeClusters.length - 1) return acc;

      if (edgeCluster.length > minClusterLength) return [...acc, edgeCluster];

      return acc;
    }, [])
    .map((edgeCluster, index, edgeClusters) => {
      if (!index) return Math.max(...edgeCluster);
      if (index === edgeClusters.length - 1) return Math.min(...edgeCluster);
      return mean(edgeCluster);
    });

const createColumns = (
  bboxes: Array<BboxType>,
  left: number,
  width: number,
  height: number
) => {
  const columnsPositions = createColumnsPositions(bboxes, left, width, height);

  return columnsPositions.length
    ? columnsPositions.map(leftPosition => ({
        ...initialColumn,
        leftPosition,
      }))
    : [{ ...initialColumn, leftPosition: left + width / 2 }];
};

export const createGridModel = (
  page: number,
  rectangle: Rectangle,
  bboxes: Array<BboxType>,
  defaultRowType: string | null
): Grid => {
  return {
    page,
    width: rectangle[2],
    height: rectangle[3],
    rows: [
      { type: defaultRowType, topPosition: rectangle[1], tupleId: null },
      ...createRows(bboxes, defaultRowType),
    ],
    columns: [
      { ...initialColumn, leftPosition: rectangle[0] },
      ...createColumns(bboxes, rectangle[0], rectangle[2], rectangle[3]),
    ],
  };
};

// TODO: Not sure where to put this properly but since we're still using old types, I left it here
export const isValidColumnForAnnotation = (
  columnSchema: AnyDatapointSchema
) => {
  return (
    columnSchema.category === 'datapoint' &&
    (!columnSchema?.uiConfiguration?.type ||
      columnSchema.uiConfiguration.type === 'captured')
  );
};
