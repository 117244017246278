import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { OrganizationGroupOrganization } from '../models/organizationGroupOrganization';
import { organizationGroupOrganizationSchema } from '../models/organizationGroupOrganization.schema';
import { organizationGroupOrganizationListQuerySchema } from './organizations.schema';

export type OrganizationGroupOrganizationListQuery = PaginationQuery;

export const organizations = (
  baseQuery: OrganizationGroupOrganizationListQuery & {
    // TODO: organizationGroupId needs to be mandatory
    organizationGroupId?: ID;
  }
) => {
  const { organizationGroupId, ...query } = baseQuery ?? {};
  // Hack for unpaginated query, where all props have to be optional.
  // eslint-disable-next-line no-console
  if (!organizationGroupId) console.warn('No organization group id');

  return {
    endpoint: `/organization_groups/${organizationGroupId}/organizations`,
    method: 'GET',
    responseSchema: listResponse(organizationGroupOrganizationSchema),
    query,
    querySchema: organizationGroupOrganizationListQuerySchema,
  } as const;
};
