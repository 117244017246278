const startsWithOperator = {
  value: 'startsWith',
  type: 'string',
  component: 'filterStartsWith',
} as const;

export const emailOptionsOperator = {
  value: 'isAnyOf',
  component: 'filterMultiSelect',
  type: 'multiSelect',
} as const;

export const operators = [
  {
    value: 'contains',
    type: 'string',
    component: 'filterContains',
  },
  startsWithOperator,
  {
    value: 'equals',
    type: 'string',
    component: 'filterTextField',
  },
  {
    value: 'isAnyOf',
    component: 'filterMultiString',
    type: 'string',
  },
  {
    value: 'notIn',
    component: 'filterMultiString',
    type: 'string',
  },
  {
    value: 'isEmpty',
    type: 'string',
    component: 'filterTextField',
  },
  {
    value: 'isNotEmpty',
    type: 'string',
    component: 'filterTextField',
  },
  {
    value: 'isAnyOf',
    component: 'filterMultiSelect',
    type: 'multiSelect',
  },
  {
    value: 'notIn',
    component: 'filterMultiSelect',
    type: 'multiSelect',
  },
  {
    value: 'isEmpty',
    component: 'filterMultiSelect',
    type: 'multiSelect',
  },
  {
    value: 'isNotEmpty',
    component: 'filterMultiSelect',
    type: 'multiSelect',
  },
  {
    value: '>',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: '<',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: '=',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: '!=',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: '>=',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: '<=',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: 'isEmpty',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: 'isNotEmpty',
    type: 'number',
    component: 'filterTextField',
  },
  {
    value: 'is',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'after',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'onOrAfter',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'before',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'onOrBefore',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'isEmpty',
    type: 'date',
    component: 'dateTimePicker',
  },
  {
    value: 'isNotEmpty',
    type: 'date',
    component: 'dateTimePicker',
  },
] as const;

export const multiSelectOperators = operators.flatMap(op =>
  op.type === 'multiSelect' ? [op] : []
);

export const stringFieldOperators = operators.flatMap(op =>
  op.type === 'string' ? [op] : []
);

export const numericOperators = operators.flatMap(op =>
  op.type === 'number' ? [op] : []
);

export const dateOperators = operators.flatMap(op =>
  op.type === 'date' ? [op] : []
);

export const emailsDateOperators = dateOperators.filter(
  op => op.value !== 'isEmpty' && op.value !== 'isNotEmpty'
);

export const userSelectOperators = [
  ...multiSelectOperators.map(op => ({ ...op, component: 'filterUserSelect' })),
  startsWithOperator,
];

export const detailsOperators = operators.flatMap(op =>
  op.type === 'multiSelect' && (op.value === 'isAnyOf' || op.value === 'notIn')
    ? [op]
    : []
);

const isOperatorEmpty = (op: (typeof operators)[number]) =>
  op.value === 'isEmpty' || op.value === 'isNotEmpty';

export const queueSelectOperators = multiSelectOperators.flatMap(op => {
  return isOperatorEmpty(op)
    ? []
    : [
        {
          ...op,
          component: 'filterQueueSelect' as const,
        },
      ];
});

export const statusOperators = multiSelectOperators.filter(
  op => !isOperatorEmpty(op)
);
export type FilterOperator =
  | (typeof operators)[number]
  | (typeof userSelectOperators)[number]
  | (typeof queueSelectOperators)[number];
