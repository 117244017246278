// Generated by ts-to-zod
import { z } from 'zod';

export const dedicatedEngineRequestPayloadSchema = z
  .object({
    documentType: z.string(),
    documentLanguage: z.string(),
    volume: z.number(),
  })
  .partial();
