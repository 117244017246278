import { QueryKey } from '@tanstack/react-query';
import { AnyAction } from 'redux';
import { createAction, isActionOf } from 'typesafe-actions';
import { queryClient } from '../../lib/queryClient';
import { Next } from '../../types/redux';

export default () => (next: Next) => (action: AnyAction) => {
  if (isActionOf(invalidateQueryClientCache)(action)) {
    queryClient.invalidateQueries({ queryKey: action.payload.queryKey });
  }

  return next(action);
};

export const invalidateQueryClientCache = createAction(
  'INVALIDATE_QUERY_CLIENT_CACHE',
  (queryKey: QueryKey) => ({ queryKey })
)<{ queryKey: QueryKey }>();
