import { ActionType, createAction } from 'typesafe-actions';
import { Annotation } from '../../../types/annotation';
import { DeleteAnnotationsMeta, PostponeAnnotationsMeta } from './types';

export const deleteAnnotations = createAction(
  'DELETE_ANNOTATIONS',
  undefined,
  (payload: string[], skipRedirect?: boolean, emailId?: number) => ({
    urls: payload,
    skipRedirect: !!skipRedirect,
    emailId,
  })
)<undefined, DeleteAnnotationsMeta>();

export const postponeAnnotations = createAction(
  'POSTPONE_ANNOTATIONS',
  undefined,
  (payload: string[], skipRedirect?: boolean) => ({
    urls: payload,
    skipRedirect: !!skipRedirect,
  })
)<undefined, PostponeAnnotationsMeta>();

export const popAnnotationFromStack = createAction(
  'POP_ANNOTATION_FROM_STACK',
  (payload: Annotation) => payload
)<Annotation>();

export type AnnotationsActions = ActionType<
  | typeof deleteAnnotations
  | typeof postponeAnnotations
  | typeof popAnnotationFromStack
>;
