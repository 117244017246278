import { endpoints, ID, Url } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import { api } from '../../lib/apiClient';
import { useTriageSetup } from '../deleteRecommendation/useTriageSetup';
import {
  toTriggerApiModel,
  TriggerClientModel,
} from './mappers/validationMapper';
import { QUERY_KEY_TRIGGER } from './useValidationTrigger';

type SaveValidationTriggerArgs = {
  id?: ID | undefined;
  clientModel: TriggerClientModel;
};

export const useSaveValidationTrigger = (
  queueUrl: Url,
  onSuccess: () => void
) => {
  const queryClient = useQueryClient();
  const { connectToTrigger, isLoading, isError } = useTriageSetup(queueUrl);

  const triggerMutation = useMutation({
    mutationFn: ({ id, clientModel }: SaveValidationTriggerArgs) =>
      id
        ? api.request(
            endpoints.triggers.patch(id, {
              // Connect trigger to the queue
              // Fixes broken data in https://rossumai.atlassian.net/browse/US-1660
              queue: queueUrl,
              condition: toTriggerApiModel(clientModel),
            })
          )
        : api.request(
            endpoints.triggers.create({
              queue: queueUrl,
              emailTemplates: [],
              deleteRecommendations: [],
              event: 'validation',
              condition: toTriggerApiModel(clientModel),
            })
          ),

    onSuccess: trigger => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_TRIGGER, trigger.url],
      });
      connectToTrigger(trigger.url, { onSuccess });
    },
  });

  const regexError = triggerMutation.isError
    ? get(triggerMutation.error, 'data.condition.filename.$regex[0]')
    : undefined;

  return {
    ...triggerMutation,
    isLoading: triggerMutation.isLoading || isLoading,
    isError: triggerMutation.isError || isError,
    regexError,
  };
};
