import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import {
  activeLabelStyle,
  activeOutlineStyle,
} from '../../../components/UI/SingleQueueSelect/styles';
import { SelectedEventT } from '../DependenciesGraph/types';

type Props = {
  options: Readonly<Array<SelectedEventT>>;
  onChange: SelectProps<SelectedEventT>['onChange'];
  value: SelectProps<SelectedEventT>['value'];
  closeIcon: ReactNode;
};

const EVENTS_SELECT_WIDTH = 280;

const EventsSelect = ({ options, value, onChange, closeIcon }: Props) => {
  const intl = useIntl();
  const hasValue = value !== 'all';

  return (
    <FormControl variant="outlined" sx={{ width: EVENTS_SELECT_WIDTH }}>
      <InputLabel
        sx={hasValue ? activeLabelStyle : {}}
        id="queue-extensions-event-label"
      >
        {intl.formatMessage({
          id: 'containers.settings.queues.queue.extensions.selectEvent.label',
        })}
      </InputLabel>
      <Select
        labelId="queue-extensions-event-label"
        label={intl.formatMessage({
          id: 'containers.settings.queues.queue.extensions.selectEvent.label',
        })}
        value={value}
        onChange={onChange}
        sx={hasValue ? activeOutlineStyle : {}}
        endAdornment={closeIcon}
        data-cy="extensions-event-select"
        size="small"
      >
        {options.map(event => (
          <MenuItem
            key={event}
            value={event}
            data-cy={`event-menu-item-${event.replace('.', '_')}`}
          >
            {intl.formatMessage({
              id: `containers.settings.queues.queue.extensions.${event}`,
            })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EventsSelect;
