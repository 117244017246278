/**
 * @noSchema
 */
export type DecodingErrorContext = {
  endpoint: string;
  decodingOutput: string;
};
export class DecodingError extends Error {
  endpoint: string;

  constructor({ endpoint, decodingOutput }: DecodingErrorContext) {
    super(`'${endpoint}': When decoding: \n ${decodingOutput}`);
    this.name = 'DecodingError';
    this.endpoint = endpoint;
    Object.setPrototypeOf(this, DecodingError.prototype);
  }
}
