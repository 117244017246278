import { Stack } from '@rossum/ui/material';
import CopyToClipboardButton from '../copy-to-clipboard/CopyToClipboardButton';
import DocumentFileName from './DocumentFileName';

const minimizeFilenameString = (
  string: string,
  max: number,
  separator: Array<string> = ['.', '.', '.']
) => {
  const array = string.split('');
  const halfMax = Math.floor(max / 2);
  return string.length > max
    ? [
        ...array.slice(0, halfMax),
        ...separator,
        ...array.slice(array.length - halfMax, array.length),
      ].join('')
    : string;
};

type Props = {
  disableClipboard?: boolean;
  disabled?: boolean;
  limit?: number;
  originalFilename: string | null | undefined;
  status?: string;
};

const Filename = ({
  originalFilename,
  limit = 50,
  disabled = false,
  status,
  disableClipboard = false,
}: Props) => {
  const name = minimizeFilenameString(originalFilename || '', limit || 50);
  const [prefix, postfix] = name.split(/([^/]+)$/g);

  const fileName = (
    <>
      {prefix}
      <span>{postfix}</span>
    </>
  );

  return (
    <Stack
      data-cy="file-name"
      position="relative"
      whiteSpace="nowrap"
      sx={
        disabled && (status === 'failedImport' || status === 'importing')
          ? {
              color: 'text.disabled',
              cursor: status === 'importing' ? 'wait' : 'not-allowed',
              textDecoration: 'none',
            }
          : {}
      }
    >
      {originalFilename ? (
        disableClipboard ? (
          fileName
        ) : (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            sx={{
              '& button': { opacity: 0, transition: 'opacity 0.1s ease-in' },
              '&:hover button': { opacity: 1 },
            }}
          >
            {fileName}
            <CopyToClipboardButton
              content={originalFilename || ''}
              iconSize="16px"
              iconColor="text.primary"
            />
          </Stack>
        )
      ) : (
        <DocumentFileName originalFileName={originalFilename} />
      )}
    </Stack>
  );
};

export default Filename;
