import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EditDataSource } from '../models/editPages';
import { ProcessingDuration } from '../models/processingDuration';
import {
  editPagesInPlacePayloadSchema,
  editPagesInPlaceResponseSchema,
} from './editPagesInPlace.schema';

export type EditPagesInPlacePayload = {
  parentPages: { rotationDeg: number; page: Url }[];
  targetQueue?: Url;
  processingDuration?: ProcessingDuration;
  editDataSource?: EditDataSource;
};

export type EditPagesInPlaceResponse = {
  results: { document: Url; annotation: Url }[];
};

export const editPagesInPlace = (
  parentAnnotationId: number,
  payload: EditPagesInPlacePayload
) => {
  return {
    endpoint: `/annotations/${parentAnnotationId}/edit_pages/in_place`,
    method: 'POST',
    responseSchema: editPagesInPlaceResponseSchema,
    payload,
    payloadSchema: editPagesInPlacePayloadSchema,
  } as const;
};
