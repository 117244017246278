import * as apiClient from '@rossum/api-client';
import { createContext, useContext } from 'react';
import { apiUrl } from '../constants/config';
import { getAuthToken } from './token';

export const api = apiClient.createElisClient({
  baseUrl: apiUrl,
  getAuthToken,
});

type ApiClientContextParams = {
  api: typeof api;
};

export const ApiClientContext = createContext<
  ApiClientContextParams | undefined
>(undefined);

export const useApiClient = () => {
  const apiClientContext = useContext(ApiClientContext);
  return apiClientContext ? apiClientContext.api : api;
};
