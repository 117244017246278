import { Button } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { NonAdminRestrictor } from '../../components/Restrictors';
import CallToActionDialog from './CallToActionDialog';

type CallToActionButtonProps = {
  ctaLocation: string;
};

const CallToActionButton = ({ ctaLocation }: CallToActionButtonProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  return (
    <NonAdminRestrictor>
      <Button
        variant="contained"
        size="small"
        color="warning"
        onClick={() => setOpen(true)}
        data-cy="cta-walk-me-through-btn"
        sx={{ alignSelf: 'center' }}
      >
        {intl.formatMessage({
          id: 'containers.callToAction.button',
        })}
      </Button>
      <CallToActionDialog
        open={open}
        onClose={() => setOpen(false)}
        key={`call-to-action-dialog-${open}`}
        ctaLocation={ctaLocation}
        extraInfoPayload={`Request location: ${ctaLocation}`}
      />
    </NonAdminRestrictor>
  );
};

export default CallToActionButton;
