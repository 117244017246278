// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const uploadsPostQuerySchema = z.object({
  queue: z.number(),
});

export const uploadsPostResponseSchema = z.object({
  url: urlSchema,
});
