import { Hook } from '@rossum/api-client/hooks';
import { Close } from '@rossum/ui/icons';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  paperClasses,
  Select,
  SelectProps,
  Skeleton,
  Stack,
} from '@rossum/ui/material';
import { sortBy } from 'lodash';
import { useIntl } from 'react-intl';
import {
  activeLabelStyle,
  activeOutlineStyle,
} from '../../../../components/UI/SingleQueueSelect/styles';

type Props = {
  setHookFilter: (hooks: Array<string>) => void;
  value: SelectProps<string>['value'];
  extensions: Array<Hook> | undefined;
  isFetchingExtensions: boolean;
  isLoadingExtensions: boolean;
  width: number;
};

const MENU_LIST_MIN_HEIGHT = 450;
const MENU_CONTENT_MAX_WIDTH = 600;

const HooksSelect = ({
  extensions = [],
  isFetchingExtensions,
  isLoadingExtensions,
  value = '',
  setHookFilter,
  width,
}: Props) => {
  const intl = useIntl();
  const hasValue = !!value;

  const existingSelected = extensions.find(e => `${e.id}` === value);

  const existingNotFound = Boolean(
    extensions.length > 0 && value && !existingSelected
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormControl variant="outlined" sx={{ width }} error={existingNotFound}>
        <InputLabel
          sx={hasValue && !isLoadingExtensions ? activeLabelStyle : {}}
          id="extensions-logs-hook-label"
          size="small"
        >
          {intl.formatMessage({
            id: 'containers.settings.extensions.logs.filters.extension.label',
          })}
        </InputLabel>
        <Select
          labelId="extensions-logs-hook-label"
          label={intl.formatMessage({
            id: 'containers.settings.extensions.logs.filters.extension.label',
          })}
          size="small"
          fullWidth
          value={value}
          onChange={({ target: { value } }) => {
            setHookFilter([value]);
          }}
          sx={hasValue && !isLoadingExtensions ? activeOutlineStyle : {}}
          startAdornment={
            isLoadingExtensions &&
            hasValue && <Skeleton variant="text" width="200%" />
          }
          endAdornment={
            hasValue ? (
              <Stack
                sx={{ mr: 2 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <IconButton size="small" onClick={() => setHookFilter([])}>
                  <Close fontSize="small" />
                </IconButton>
              </Stack>
            ) : isFetchingExtensions ? (
              <Stack sx={{ mr: 2 }}>
                <CircularProgress size={20} />
              </Stack>
            ) : null
          }
          disabled={isLoadingExtensions}
          data-cy="extensions-logs-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              maxHeight: MENU_LIST_MIN_HEIGHT,
              [`& .${paperClasses.root}`]: {
                maxWidth: MENU_CONTENT_MAX_WIDTH,
                width: 'fit-content',
              },
            },
          }}
        >
          {sortBy(extensions, e => e.name.toLocaleLowerCase()).map(
            extension => (
              <MenuItem
                key={extension.id}
                value={`${extension.id}`}
                data-cy={`logs-hook-menu-item-${extension.id}`}
                selected={`${extension.id}` === value}
              >
                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Box
                    component="span"
                    sx={{
                      '&::before': {
                        content: `"${extension.id}: "`,
                      },
                    }}
                  >
                    {extension.name}
                  </Box>
                </Box>
              </MenuItem>
            )
          )}
        </Select>
        {existingNotFound ? (
          <FormHelperText>
            {intl.formatMessage({
              id: 'containers.settings.extensions.logs.filters.extension.notFoundError',
            })}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Stack>
  );
};

export default HooksSelect;
