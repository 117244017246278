// Generated by ts-to-zod
import { z } from 'zod';

export const emailLimitsSchema = z.object({
  countToday: z.number(),
  countTodayNotification: z.number(),
  countTotal: z.number(),
  emailPerDayLimit: z.number(),
  emailPerDayLimitNotification: z.number(),
  emailTotalLimit: z.number().nullable(),
  lastSentAt: z.string(),
  lastSentAtNotification: z.string(),
});
