import { SchemaItem } from './schemaItem';
import { SchemaItemBase } from './schemaItemBase';

export type SchemaSection = SchemaItemBase & {
  category: 'section';
  children: SchemaItem[];
  icon?: string | null;
};

export const isSchemaSection = (item: unknown): item is SchemaSection =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  item.category === 'section';
