import { Replay as ReplayIcon } from '@rossum/ui/icons';
import {
  Box,
  ClickAwayListener,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  limitPercentageDecimalPlaces,
  useThresholdInput,
} from '../../../../components/UI/Threshold/useThresholdInput';
import { UiFieldType } from '../../../../types/schema';

// magic numbers
export const inputOffsetX = 30;
export const resetIconSize = 14;
export const editingStackSpacing = 1;
export const rightPadding = 12;
const inputOffsetY = 18;

export const getLeftPosition = (themeSpacing: (s: number) => string) =>
  inputOffsetX +
  resetIconSize +
  Number(themeSpacing(editingStackSpacing).replace('px', ''));

type SharedProps = {
  editingValue: number | '';
  onChange: (value: number | null) => void;
  queueThreshold: number;
  value: number;
};

const EditingInput = ({
  editingValue,
  queueThreshold,
  onChange,
  value,
  setEditInProgress,
}: SharedProps & { setEditInProgress: (b: boolean) => void }) => {
  const intl = useIntl();

  const { thresholdValue, handleInputChange, handleInputBlur } =
    useThresholdInput({
      value: editingValue,
      onChange,
      defaultValue: value,
    });

  const queueThresholdPercentage = `${limitPercentageDecimalPlaces(
    queueThreshold * 100
  )}`;

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleInputBlur({
          target: { value: `${thresholdValue}` },
        } as React.FocusEvent<HTMLInputElement>);
        setEditInProgress(false);
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={editingStackSpacing}
        sx={{
          position: 'absolute',
          transform: `translateY(-${inputOffsetY}px) translateX(${inputOffsetX}px)`,
          height: inputOffsetY * 2,
        }}
      >
        <Tooltip
          title={intl.formatMessage({
            id: 'containers.settings.automation.automationData.table.resetScore',
          })}
          placement="top"
        >
          <ReplayIcon
            sx={{
              fontSize: resetIconSize,
              cursor: 'pointer',
              visibility: value !== queueThreshold ? 'visible' : 'hidden',
            }}
            onClick={() => {
              handleInputChange(queueThresholdPercentage);
            }}
          />
        </Tooltip>

        <TextField
          value={thresholdValue ?? ''}
          placeholder={`${queueThresholdPercentage}`}
          autoFocus
          InputProps={{
            inputProps: {
              // Since percentages are limited to one decimal place, user shouldn't be able to enter more than 4 characters
              maxLength: 4,
              sx: {
                paddingLeft: 0,
                paddingRight: 2,
                textAlign: 'right',
                fontSize: 14,
              },
            },
          }}
          onChange={({ target: { value: inputValue } }) =>
            handleInputChange(inputValue)
          }
        />
        <span>%</span>
      </Stack>
    </ClickAwayListener>
  );
};

const ThresholdInput = ({
  editingValue,
  onChange,
  queueThreshold,
  value,
  className,
  uiFieldType,
}: SharedProps & {
  className: string;
  uiFieldType?: UiFieldType;
}) => {
  const [editInProgress, setEditInProgress] = useState(false);

  const theme = useTheme();
  const intl = useIntl();

  const isEditable =
    uiFieldType === 'captured' ||
    uiFieldType === undefined ||
    uiFieldType === null;

  const leftOffset = (getLeftPosition(theme.spacing) + rightPadding) / 2;

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {editInProgress ? (
        <EditingInput
          editingValue={editingValue}
          queueThreshold={queueThreshold}
          onChange={onChange}
          value={value}
          setEditInProgress={setEditInProgress}
        />
      ) : (
        <Tooltip
          title={
            isEditable
              ? ''
              : intl.formatMessage(
                  {
                    id: `containers.settings.automation.automationData.table.disabledEditing`,
                  },
                  { uiFieldType }
                )
          }
          placement="top"
          componentsProps={{
            arrow: {
              style: { marginLeft: `${leftOffset}px` },
            },
          }}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [leftOffset, 0],
                },
              },
            ],
          }}
        >
          <Box
            className={className}
            onClick={() => setEditInProgress(isEditable)}
            component="div"
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ marginLeft: '30px' }}
            >
              {`${limitPercentageDecimalPlaces(value * 100)} %`}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default ThresholdInput;
