import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { createSelector } from 'reselect';
import { BboxParams } from '../../../../lib/spaceConvertor';
import {
  currentDatapointSelector,
  currentMultivalueDatapointSelector,
  getPasiveBboxesSelector,
} from '../../../../redux/modules/datapoints/selector';
import {
  isBoundedDatapoint,
  isTableDatapoint,
} from '../../../../redux/modules/datapoints/typedHelpers';
import {
  AnyDatapointDataST,
  Grid,
  PassiveBbox,
} from '../../../../types/datapoints';
import { rectangleFromCoordinates } from '../../document-canvas/utils/geometry';
import {
  getBoxCoordinates,
  getPointCoordinates,
} from '../../document-store/documentGeometry';
import { useCanvasGeometryActions } from '../../document-store/DocumentStore';
import { UseCanvasDimensions } from '../useCanvasDimensions';

type GridPosition = { page: number; x: number; y: number };
type GridBboxPosition = {
  page: number;
  position: BboxParams;
};

const findDatapointWithPositionInCurrentTuple = (pbb: PassiveBbox[][]) => {
  const tupleBboxes = pbb.flat().filter(bbox => bbox && bbox.isInCurrentTuple);

  const firstBboxPage = tupleBboxes[0]?.page;

  // there is still a situation when we scroll to the first bbox in the tuple when user has only one bbox in the tuple
  return firstBboxPage && tupleBboxes.every(bbox => bbox.page === firstBboxPage)
    ? tupleBboxes[0]
    : undefined;
};

const getGridPosition = (grid: {
  parts: Array<Grid>;
}): GridBboxPosition | undefined => {
  const gridPosition = grid.parts.reduce<GridPosition | undefined>(
    (acc, curr) => {
      const currentGridPosition = {
        page: curr.page,
        x:
          R.firstBy(curr.columns, column => column.leftPosition)
            ?.leftPosition ?? 0,
        y: R.firstBy(curr.rows, row => row.topPosition)?.topPosition ?? 0,
      };

      if (acc === undefined) {
        return currentGridPosition;
      }

      if (currentGridPosition.page < acc.page) {
        return currentGridPosition;
      }

      if (
        currentGridPosition.page === acc.page &&
        currentGridPosition.y < acc.y
      ) {
        return currentGridPosition;
      }

      if (
        currentGridPosition.page === acc.page &&
        currentGridPosition.y === acc.y &&
        currentGridPosition.x < acc.x
      ) {
        return currentGridPosition;
      }

      return acc;
    },
    undefined
  );
  return gridPosition
    ? {
        page: gridPosition.page,
        position: [
          gridPosition.x,
          gridPosition.y,
          gridPosition.x + 0,
          gridPosition.y + 0,
        ],
      }
    : undefined;
};

const activeDatapointWithPositionSelector = createSelector(
  currentMultivalueDatapointSelector,
  currentDatapointSelector,
  getPasiveBboxesSelector,
  (multivalueDatapoint, datapoint, passiveBboxes) => {
    const getPositionToScroll = (datapoint: AnyDatapointDataST) => {
      // Multivalue in sidebar
      if (multivalueDatapoint && datapoint.id === multivalueDatapoint.id) {
        const { grid } = multivalueDatapoint;
        return grid ? getGridPosition(grid) : undefined;
      }

      // Any bounded datapoint
      if (isBoundedDatapoint(datapoint)) {
        return {
          page: datapoint.content.page,
          position: datapoint.content.position,
        };
      }

      // Unbounded datapoint in footer
      if (multivalueDatapoint) {
        return findDatapointWithPositionInCurrentTuple(passiveBboxes);
      }

      return undefined;
    };

    return datapoint
      ? {
          multivalueDatapoint,
          datapoint,
          bboxPosition: getPositionToScroll(datapoint),
          isGrid: multivalueDatapoint
            ? datapoint.id === multivalueDatapoint.id
            : false,
        }
      : undefined;
  }
);

export const useScrollToActiveDatapoint = ({
  dimensions,
}: {
  dimensions: UseCanvasDimensions;
}) => {
  const matrixActions = useCanvasGeometryActions();
  const previousDatapointId = useRef<number | undefined>(undefined);
  const previousMultivalueDatapointId = useRef<number | undefined>(undefined);

  const activeDatapointWithPosition = useSelector(
    activeDatapointWithPositionSelector
  );

  const scrollToBoundingBox = useCallback(
    (isGrid: boolean, content: GridBboxPosition) => {
      if (isGrid) {
        matrixActions.translateGridIntoViewport(
          getPointCoordinates(
            content.page,
            { x: content.position[0], y: content.position[1] },
            dimensions.pages
          )
        );
      } else {
        matrixActions.translateIntoViewport(
          getBoxCoordinates(
            content.page,
            rectangleFromCoordinates(content.position),
            dimensions.pages
          )
        );
      }
    },
    [dimensions.pages, matrixActions]
  );

  useEffect(() => {
    if (activeDatapointWithPosition) {
      const { bboxPosition, datapoint, isGrid } = activeDatapointWithPosition;

      const goingToDifferentDatapoint =
        datapoint.id !== previousDatapointId.current;

      const goingToParentMultivalue =
        isTableDatapoint(datapoint) &&
        datapoint.id === previousMultivalueDatapointId.current;

      if (goingToDifferentDatapoint && !goingToParentMultivalue) {
        if (bboxPosition) {
          // Looks like we need to wait for footer for two frames before scrolling
          // Mostly likely because of the makeResizable implementation
          // Viewport is resized few frames later.
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              scrollToBoundingBox(isGrid, bboxPosition);
            });
          });
        }
      }
    }

    previousDatapointId.current = activeDatapointWithPosition?.datapoint.id;
    previousMultivalueDatapointId.current =
      activeDatapointWithPosition?.multivalueDatapoint?.id;
  }, [activeDatapointWithPosition, scrollToBoundingBox]);
};
