import { z } from 'zod';
import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { applyLabelPayloadSchema } from './apply.schema';

export type ApplyLabelPayload = {
  operations: {
    add?: Array<Url>;
    remove?: Array<Url>;
  };
  objects: {
    annotations: Array<Url>;
  };
};

export const apply = (payload: ApplyLabelPayload) => {
  return {
    endpoint: `/labels/apply`,
    method: 'POST',
    payload,
    responseSchema: z.unknown(),
    payloadSchema: applyLabelPayloadSchema,
  } as const;
};
