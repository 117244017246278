import { ReactNode } from 'react';
import {
  useCanvasGeometryState,
  useDocumentStore,
} from '../document-store/DocumentStore';
import { stateToMatrix } from '../document-store/helpers';

type DocumentCanvasGroupProps = {
  children: ReactNode;
};

export const DocumentCanvasGroup = ({ children }: DocumentCanvasGroupProps) => {
  const matrixState = useCanvasGeometryState();

  const canvasRef = useDocumentStore(state => state.canvasRef);
  return (
    <g ref={canvasRef} transform={`${stateToMatrix(matrixState).toString()}`}>
      {children}
    </g>
  );
};
