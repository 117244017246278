import { QueueCounts } from '@rossum/api-client/queues';
import { SortingValue } from '../../../types/user';
import { WorkspaceWithQueues } from '../../queues/select-queue/types';

type CompareFn<T> = (a: T, b: T) => number;

export type WorkspaceWithToReviewCount = WorkspaceWithQueues & {
  toReviewCount: number;
};
type SortFns<T> = Record<SortingValue, CompareFn<T>>;
const sortFns: Omit<SortFns<{ id: number; name: string }>, 'toReview'> = {
  oldest: (a, b) => a.id - b.id,
  newest: (a, b) => b.id - a.id,
  alphabeticalDesc: (a, b) => b.name.localeCompare(a.name),
  alphabeticalAsc: (a, b) => a.name.localeCompare(b.name),
};

export const queueSortFns: SortFns<{
  counts: QueueCounts;
  id: number;
  name: string;
}> = {
  ...sortFns,
  toReview: (valueA, valueB) => {
    const valueAToReview = valueA.counts.to_review ?? 0;
    const valueAReviewing = valueA.counts.reviewing ?? 0;

    const valueBToReview = valueB.counts.to_review ?? 0;
    const valueBReviewing = valueB.counts.reviewing ?? 0;
    return (
      valueBToReview + valueBReviewing - (valueAToReview + valueAReviewing)
    );
  },
};

export const workspaceSortFns: SortFns<{
  id: number;
  name: string;
  toReviewCount: number | undefined;
}> = {
  ...sortFns,
  toReview: (valueA, valueB) => {
    return (valueB.toReviewCount ?? 0) - (valueA.toReviewCount ?? 0);
  },
};
