// Generated by ts-to-zod
import { z } from 'zod';

export const domainSettingsQuerySchema = z.object({
  domain: z.string(),
});

export const domainSettingsSsoProviderSchema = z.object({
  id: z.number(),
  name: z.string(),
  sendRedirectUrl: z.boolean(),
  apiUrl: z.string(),
});

export const domainSettingsResponseSchema = z.object({
  ssoProviders: z.array(domainSettingsSsoProviderSchema),
});
