import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  DatapointData,
  Options,
  SuggestedOperations,
} from '../models/annotationData';
import {
  validateAnnotationPayloadSchema,
  validateAnnotationResponseSchema,
} from './validate.schema';

export type ValidateAnnotationPayload = {
  updatedDatapointIds?: Array<ID>;
  actions?: Array<string>;
};

type Messages = Array<
  | {
      type: 'error' | 'warning' | 'info';
      content: string;
      id: string;
    }
  | {
      type: 'aggregation';
      content: string;
      id: string;
      aggregationType: 'sum';
      schemaId: string;
    }
>;

type MatchedTriggerRule_Datapoint = {
  type: 'datapoint';
  value: string;
  id: ID;
};

type MatchedTriggerRule_PageCount = {
  type: 'page_count';
  value: number;
  threshold: number;
};

type MatchedTriggerRule_Filename = {
  type: 'filename';
  value: string;
  regex: string;
};

type MatchedTriggerRule =
  | MatchedTriggerRule_Datapoint
  | MatchedTriggerRule_PageCount
  | MatchedTriggerRule_Filename;

export type ValidateAnnotationResponse = {
  messages: Messages;
  updatedDatapoints: Array<
    DatapointData & {
      options?: Array<Options>;
    }
  >;
  suggestedOperations: Array<SuggestedOperations>;
  matchedTriggerRules: Array<MatchedTriggerRule>;
};

export const validate = (
  annotationId: number,
  payload: ValidateAnnotationPayload = {}
) => {
  return {
    endpoint: `/annotations/${annotationId}/content/validate`,
    method: 'POST',
    payloadSchema: validateAnnotationPayloadSchema,
    payload,
    responseSchema: validateAnnotationResponseSchema,
  } as const;
};
