// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const billingStatsPayloadSchema = z
  .object({
    filters: z
      .object({
        beginDate: z.string(),
        endDate: z.string(),
        queues: z.array(urlSchema.nullable()),
        organizations: z.array(urlSchema),
      })
      .partial(),
    groupBy: z.array(
      z.union([
        z.literal('organization'),
        z.literal('queue'),
        z.literal('month'),
        z.literal('week'),
      ])
    ),
    orderBy: z.array(
      z.union([z.literal('-billable_pages'), z.literal('-billable_documents')])
    ),
  })
  .partial();

export const billingStatsQuerySchema = paginationQuerySchema;
