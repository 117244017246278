import clsx from 'clsx';
import { includes, map } from 'lodash';
import AlertIcon from 'mdi-react/AlertIcon';
import MenuDown from 'mdi-react/ChevronDownIcon';
import { FormattedMessage } from 'react-intl';
import Dropdown, { ItemPropsT } from '../../../../../components/UI/Dropdown';
import TranslatedTooltip from '../../../../../components/UI/Tooltip/TranslatedTooltip';
import { linebreak } from '../../../../../lib/formaterValues/index';
import styles from '../style.module.sass';
import { EventNameOptions } from '../types';

type Props = {
  assignedFunctionEventNames: Array<EventNameOptions>;
  events: Array<EventNameOptions>;
  currentEvent: EventNameOptions;
  disabled: boolean;
  savedInput: string;
  setCurrentEvent: (_event: EventNameOptions) => void;
  setShowInputLoading: (loading: boolean) => void;
};

const FunctionEventsDropdown = ({
  assignedFunctionEventNames,
  events,
  currentEvent,
  disabled,
  savedInput,
  setCurrentEvent,
  setShowInputLoading,
}: Props) => (
  <Dropdown
    className={clsx(styles.DropDownPanel)}
    align="left"
    listClassName={styles.EventsList}
    items={map(events, event => (props: ItemPropsT) => {
      const shouldDisplayWarning =
        !includes(assignedFunctionEventNames, event) &&
        event !== 'mySavedInput';

      return (
        <div key={`eventDropdown-${event}`}>
          {event === 'mySavedInput' && !savedInput ? (
            <TranslatedTooltip
              dark
              placement="bottom"
              translationId="components.editor.inputBox.dropdown.noSavedInput"
              translationValues={{ linebreak }}
              className={styles.NoInputTooltip}
            >
              <div
                {...props}
                className={clsx(
                  styles.EventsListItem,
                  styles.NoInput,
                  props.className
                )}
              >
                <FormattedMessage
                  id={`components.editor.inputBox.dropdown.${event}`}
                />
              </div>
            </TranslatedTooltip>
          ) : shouldDisplayWarning ? (
            <TranslatedTooltip
              dark
              placement="left"
              translationId="components.editor.inputBox.dropdown.eventNotAssigned"
              translationValues={{ linebreak }}
              className={styles.NoInputTooltip}
            >
              <div
                {...props}
                onClick={() => {
                  if (!disabled && currentEvent !== event) {
                    setShowInputLoading(true);
                    setCurrentEvent(event);
                  }
                }}
                className={clsx(
                  disabled && styles.NoInput,
                  styles.EventsListItem,
                  currentEvent === event && styles.EventsListItemActive,
                  props.className
                )}
              >
                <FormattedMessage
                  id={`components.editor.inputBox.dropdown.${event}`}
                />
                <AlertIcon size={18} />
              </div>
            </TranslatedTooltip>
          ) : (
            <div
              {...props}
              onClick={() => {
                if (!disabled && currentEvent !== event) {
                  setShowInputLoading(true);
                  setCurrentEvent(event);
                }
              }}
              className={clsx(
                disabled && styles.NoInput,
                styles.EventsListItem,
                currentEvent === event && styles.EventsListItemActive,
                props.className
              )}
            >
              <FormattedMessage
                id={`components.editor.inputBox.dropdown.${event}`}
              />
            </div>
          )}
        </div>
      );
    })}
  >
    {({ open }: Record<'open', boolean>) => (
      <div
        className={clsx(
          styles.DropdownValue,
          open && styles.DropdownValueActive
        )}
      >
        <div className={clsx(disabled && styles.NoInput)}>
          <FormattedMessage
            id={`components.editor.inputBox.dropdown.${currentEvent}`}
          />
          <MenuDown
            size={18}
            className={clsx(
              open && styles.ChevronUp,
              disabled && styles.NoInput
            )}
          />
        </div>
      </div>
    )}
  </Dropdown>
);

export default FunctionEventsDropdown;
