import { ActionType, createAction, PayloadMetaAction } from 'typesafe-actions';
import {
  FeedbackFormMeta,
  FeedbackFormPayload,
  FeedbackFormType,
} from './types';

export const submitFeedbackForm = createAction(
  'SUBMIT_FEEDBACK_FORM',
  (payload: FeedbackFormPayload, _meta: FeedbackFormMeta) => payload,
  (_payload, meta) => meta
)<FeedbackFormPayload, FeedbackFormMeta>();

export const submitFeedbackFormFulfilled = createAction(
  'SUBMIT_FEEDBACK_FORM_FULFILLED',
  (payload: FeedbackFormType) => payload
)<FeedbackFormType>();

export type SubmitFeedbackActionT = PayloadMetaAction<
  'SUBMIT_FEEDBACK_FORM',
  FeedbackFormPayload,
  FeedbackFormMeta
>;

export type FeedbackActions = ActionType<
  typeof submitFeedbackForm | typeof submitFeedbackFormFulfilled
>;
