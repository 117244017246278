import { Button } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import Disclaimer from './components/Disclaimer';
import Header from './components/Header';
import styles from './style.module.sass';

const IntroScreen = () => {
  const { state = {} } = useLocation<{ redirectUrl?: string }>();

  const redirectUrl = state.redirectUrl || '/';

  return (
    <div className={styles.Container}>
      <Header />
      <Disclaimer />
      <Link
        to={redirectUrl}
        className={styles.LinkToWeb}
        style={{ width: '100%' }}
      >
        <Button
          variant="contained"
          className={styles.GoToWebButton}
          data-cy="mobile-intro-button"
          sx={{ width: '100%' }}
        >
          <FormattedMessage id="containers.introScreen.gotoapp" />
        </Button>
      </Link>
    </div>
  );
};

export default IntroScreen;
