// Generated by ts-to-zod
import { z } from 'zod';
import { schemaFieldSchema } from '../models/schemaField.schema';

export const schemaFieldListQuerySchema = z.object({
  schemaId: z.array(z.string()).optional(),
  search: z.string().optional(),
  queue: z.array(z.number()).optional(),
  headerFieldsOnly: z.boolean().optional(),
});

export const schemaFieldListResponseSchema = z.object({
  results: z.array(schemaFieldSchema),
});
