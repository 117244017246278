import { Typography } from '@rossum/ui/material';
import { ValueIcon } from '../../../ui/ValueIcon';

type StringWithIconProps = {
  value: string;
};

export const StringWithIcon = ({ value }: StringWithIconProps) => {
  return (
    <Typography component="span" alignItems="center">
      <ValueIcon value={value} />
      &nbsp;
      <Typography component="span" variant="body2">
        {value}
      </Typography>
    </Typography>
  );
};
