import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { suggestTable } from '../../../../redux/modules/datapoints/actions';
import { MultivalueDatapointDataST } from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { AuroraIcon } from '../../../../ui/aurora/Icons';
import { useCurrentPage } from './useCurrentPage';

type SuggestButtonProps = {
  disabled: boolean;
  currentDatapoint: MultivalueDatapointDataST;
};

const SuggestButton = ({ disabled, currentDatapoint }: SuggestButtonProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const waitingForSuggestions = useSelector(
    (state: State) => state.datapoints.waitingForSuggestions
  );

  const { pageNumber: currentPage } = useCurrentPage();

  return (
    <Button
      startIcon={
        waitingForSuggestions ? (
          <CircularProgress sx={{ width: '20px' }} size="20" color="inherit" />
        ) : (
          <AuroraIcon />
        )
      }
      color="secondary"
      variant="outlined"
      disabled={disabled || waitingForSuggestions}
      data-cy="line-items-suggest"
      onClick={() => {
        if (!disabled && currentPage) {
          dispatch(
            suggestTable({
              multivalueId: currentDatapoint.id,
              pagesChunks: [[currentPage]],
            })
          );
        }
      }}
    >
      {intl.formatMessage({
        id: 'components.documentValidation.sidebar.lineItems.splitButton.primary',
      })}
    </Button>
  );
};

export default SuggestButton;
