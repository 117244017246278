import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  takeUntil,
} from 'rxjs/operators';
import { clickApiErrorHandler } from '../../../lib/api';
import { isNonEmptyString } from '../../../lib/typeGuards';
import { SearchResult } from '../../../types/search';
import { exitAnnotation } from '../annotation/actions';
import { leaveValidation } from '../ui/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  displaySearchPanel,
  getSearchResults,
  getSearchResultsFulfilled,
} from './actions';

const getEmptySearchEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(getSearchResults)),
    pluck('payload', 'phrase'),
    filter(phrase => phrase.length === 0),
    mapTo(getSearchResultsFulfilled([]))
  )
);

const getSearchResultsEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(getSearchResults)),
    pluck('payload', 'phrase'),
    filter(isNonEmptyString),
    map(phrase => ({
      phrase,
      url: state$.value.annotation.url,
    })),
    mergeMap(({ url, phrase }) =>
      authGetJSON$<{ results: SearchResult[] }>(`${url}/search`, {
        query: { phrase },
      }).pipe(
        takeUntil(action$.pipe(filter(isActionOf([leaveValidation])))),
        pluck('results'),
        map(getSearchResultsFulfilled),
        catchError(clickApiErrorHandler)
      )
    )
  )
);

const autocloseSearchEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(exitAnnotation)),
    mapTo(displaySearchPanel(false))
  )
);

export default combineEpics(
  getSearchResultsEpic,
  getEmptySearchEpic,
  autocloseSearchEpic
);
