import { compact, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import {
  currentDatapointIdSelector,
  datapointsSelector,
} from '../../../../../redux/modules/datapoints/selector';
import { useDocumentStore } from '../../../document-store/DocumentStore';

export const useHighlightedDatapoints = () => {
  const hoveredBbox = useDocumentStore(state => state.hoveredBbox);
  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);
  const currentDatapointId = useSelector(currentDatapointIdSelector);

  const highlightedDatapointIds = uniq(
    compact([...selectedDatapointIds, currentDatapointId, hoveredBbox])
  );

  const datapoints = useSelector(datapointsSelector);
  const highlightedDatapoints = datapoints.flatMap(dp =>
    highlightedDatapointIds.includes(dp.id) ? [dp] : []
  );

  return { highlightedDatapoints };
};
