import { Button, Stack, Typography } from '@rossum/ui/material';
import { Location } from 'history';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { SsoProvider } from '../../containers/Login/useSsoProviders';

type AuthProvidersProps = {
  authProviders: SsoProvider[];
  domain: string;
};

const AuthProviders = ({ authProviders, domain }: AuthProvidersProps) => {
  const intl = useIntl();
  const { state = {} } = useLocation<{ referrer?: Location }>();
  const { referrer } = state;

  const redirectUrl = referrer
    ? `${document.location.origin}${referrer.pathname}${referrer.search}${referrer.hash}`
    : undefined;

  const getRedirectUrl = (sendRedirectUrl: boolean) =>
    redirectUrl ?? (sendRedirectUrl ? document.location.origin : '');

  const createProviderUrl = (authProvider: SsoProvider) => {
    const redirectUrl = getRedirectUrl(authProvider.sendRedirectUrl);
    const redirectUrlParam = redirectUrl
      ? { redirect_url: redirectUrl }
      : undefined;

    const search = new URLSearchParams({
      domain,
      sso_provider: `${authProvider.id}`,
      ...redirectUrlParam,
    });

    return `${authProvider.apiUrl}/oauth/authorize?${search.toString()}`;
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: -2 }}
      >
        {intl.formatMessage({ id: 'containers.login.separator' })}
      </Typography>
      {authProviders.map(authProvider => {
        return (
          <Button
            key={authProvider.name}
            component="a"
            href={createProviderUrl(authProvider)}
            color="secondary"
            variant="outlined"
            sx={{
              ':hover': { color: 'secondary.main' },
            }}
          >
            {intl.formatMessage(
              {
                id: 'components.authProviders.unknownProvider',
              },
              { authProvider: authProvider.name }
            )}
          </Button>
        );
      })}
    </Stack>
  );
};

export default AuthProviders;
