import { SVGProps, useCallback, useEffect } from 'react';
import { browserVersion, isChrome } from 'react-device-detect';
import {
  useDocumentStore,
  useDocumentStoreContext,
} from '../../document-store/DocumentStore';
import { matrixToState } from '../../document-store/helpers';
import { IDENTITY_MATRIX_2D } from '../../document-store/helpers/geometry';
import { useCanvasGeometry } from './useCanvasGeometry';
import { useSyncViewportSize } from './useSyncViewportSize';

type DocumentSvgProps = SVGProps<SVGSVGElement>;

const VERY_LARGE_VIEWPORT_HEIGHT = 10000000000;

export const DocumentSvg = ({ children, ...svgProps }: DocumentSvgProps) => {
  /* This SVG (and its viewbox) define a coordinate system within the entire document canvas */
  const { dimensions } = useDocumentStoreContext();
  const viewportRef = useDocumentStore(state => state.viewportRef);
  const setViewportState = useDocumentStore(state => state.setViewportState);

  // Update viewport state in zustand on window resize
  const handleWindowResize = useCallback(() => {
    requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        if (viewportRef.current) {
          const newState = matrixToState(
            viewportRef.current.getScreenCTM() ?? IDENTITY_MATRIX_2D
          );
          setViewportState(newState);
        }
      })
    );
  }, [setViewportState, viewportRef]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  useCanvasGeometry();
  useSyncViewportSize();

  // Logic below is here just to ensure compatibility with older broswers
  // There is a bug in chrome v69 with svg having a viewbox and a relative sizes.
  // By making the height as large as possible (larger then the largest screen),
  // We ensure that the viewport has its absolute height, which is being snapped to
  // the actual height of the screen viewport
  // empirically observed that the issue is in the Chrome version < 75
  // https://issues.chromium.org/issues/41007188
  const isOldChromeVersion = isChrome && Number(browserVersion) < 75;

  return (
    <svg
      {...svgProps}
      id="document-page"
      xmlns="http://www.w3.org/2000/svg"
      ref={viewportRef}
      style={{
        width: '100%',
        height: isOldChromeVersion ? VERY_LARGE_VIEWPORT_HEIGHT : '100%',
        ...svgProps.style,
      }}
      // TODO: If we want to center the first page, we need to change first value of `viewBox` to center the first page
      viewBox={`0 0 ${dimensions.canvas.width} 1`}
      preserveAspectRatio="xMidYMin meet"
    >
      {children}
    </svg>
  );
};
