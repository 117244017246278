import { Box } from '@rossum/ui/material';
import { ElementType } from 'react';

export type DialogIconColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type DialogTitleIconProps = {
  Icon?: ElementType;
  iconBackgroundColor?: DialogIconColor;
};

const DialogTitleIcon = ({
  Icon,
  iconBackgroundColor,
}: DialogTitleIconProps) => {
  if (!Icon) return null;
  if (!iconBackgroundColor) return <Icon fontSize="small" />;

  return (
    <Box
      sx={{
        minWidth: 32,
        minHeight: 32,
        my: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme => theme.palette[iconBackgroundColor].main,
      }}
    >
      <Icon fontSize="small" />
    </Box>
  );
};

export default DialogTitleIcon;
