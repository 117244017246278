import { ElisRequestConfig } from '../../utils/requestConfig';
import { UsageReport, usageReportSchema } from '../models';
import { usageReportPayloadSchema } from './usageReportExport.schema';

type UsageReportFilterPayload = {
  users: string[];
  queues: string[];
  beginDate: string;
  endDate: string;
};

export type UsageReportPayload = {
  filter: UsageReportFilterPayload;
  groupBy: string[];
};

export const usageReport = (payload: UsageReportPayload) => {
  return {
    endpoint: `/annotations/usage-report`,
    method: 'POST',
    responseSchema: usageReportSchema,
    payloadSchema: usageReportPayloadSchema,
    payload,
  } as const;
};

export const usageReportExport = (payload: UsageReportPayload) => {
  return {
    endpoint: `/annotations/usage-report?format=csv`,
    payloadSchema: usageReportPayloadSchema,
    payload,
    method: 'POST',
    responseType: 'blob',
  } as const;
};
