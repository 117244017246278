import { Components, Theme } from '../../material';
import { getScrollbarColors } from '../utils';

export const muiCssBaseline = (theme: Theme): Components['MuiCssBaseline'] => {
  const { trackColor, thumbColor } = getScrollbarColors(theme);

  return {
    styleOverrides: {
      body: {
        scrollbarColor: `${thumbColor} ${trackColor}`,
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          backgroundColor: trackColor,
          width: 8,
        },
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          borderRadius: 8,
          backgroundColor: thumbColor,
          minHeight: 24,
        },
        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
          {
            backgroundColor: thumbColor,
          },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
          {
            backgroundColor: thumbColor,
          },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
          {
            backgroundColor: thumbColor,
          },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          backgroundColor: trackColor,
        },
      },
    },
  };
};
