import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const QUERY_KEY_ORGANIZATION = 'organization';

type Props = {
  enabled?: boolean;
  orgId: number | undefined;
};

export const useFetchOrganization = ({ enabled = true, orgId }: Props) => {
  return useQuery({
    queryKey: [QUERY_KEY_ORGANIZATION, orgId],

    queryFn: () => {
      if (!orgId) throw new Error('ID is required to fetch organization');

      return api.request(endpoints.organization.get(orgId));
    },

    enabled: enabled && !!orgId,
    staleTime: 60 * 60 * 1000,
  });
};
