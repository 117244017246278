import { Document } from '@rossum/api-client/documents';
import {
  alpha,
  ListItem,
  ListItemProps,
  listItemSecondaryActionClasses,
} from '@rossum/ui/material';
import { WithDepth } from '../getAttachmentRows';
import { DocumentInfo } from './DocumentInfo';

type Props = ListItemProps & {
  document: WithDepth<Document>;
};

export const EmailAttachmentRow = ({
  document: documentWithDepth,
  sx,
  ...restListItemProps
}: Props) => {
  const { item: document, depth } = documentWithDepth;

  return (
    <ListItem
      disablePadding
      sx={{
        ...sx,
        ml: depth * 2,
        width: 'auto',
        [`& > .${listItemSecondaryActionClasses.root}`]: {
          display: 'none',
        },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: {
          display: 'flex',
        },
        '&:nth-of-type(odd)': {
          borderRadius: 0.5,
          backgroundColor: theme =>
            alpha(
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.04
            ),
        },
      }}
      {...restListItemProps}
    >
      <DocumentInfo isChild={depth > 0} document={document} />
    </ListItem>
  );
};
