import { useSelector } from 'react-redux';
import {
  documentValidationIsLoadedSelector,
  shouldRunDataCaptureTourSelector,
} from '../../../redux/modules/productTour/selectors';
import { useDataCaptureTour } from '../../../redux/modules/productTour/useDataCaptureTour';
import TourDialog from '../TourDialog';

export const DataCaptureTourDialog = () => {
  const { steps } = useDataCaptureTour();

  /* Conditions for running this specific tour */
  const shouldRun = useSelector(shouldRunDataCaptureTourSelector);

  const documentValidationLoaded = useSelector(
    documentValidationIsLoadedSelector
  );

  return shouldRun && documentValidationLoaded ? (
    <TourDialog tourName="dataCaptureTour" steps={steps} />
  ) : null;
};
