import { Lock } from '@rossum/ui/icons';
import { Alert } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const EngineSchemaLockedMessage = () => {
  const intl = useIntl();

  return (
    <Alert
      variant="filled"
      severity="info"
      icon={<Lock fontSize="small" />}
      sx={{
        width: '100%',
        fontWeight: 'normal',
      }}
      elevation={6}
    >
      {intl.formatMessage({
        id: `components.engineSchemaLockedMessage.locked`,
      })}
    </Alert>
  );
};
