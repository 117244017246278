// Generated by ts-to-zod
import { z } from 'zod';

const usageReportFilterPayloadSchema = z.object({
  users: z.array(z.string()),
  queues: z.array(z.string()),
  beginDate: z.string(),
  endDate: z.string(),
});

export const usageReportPayloadSchema = z.object({
  filter: usageReportFilterPayloadSchema,
  groupBy: z.array(z.string()),
});
