import { endpoints, ID, Url } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { QUERY_KEY_WORKSPACES_UNPAGINATED } from '../workspaces';
import { QUERY_KEY_LAZY_QUEUES } from './useLazyQueues';
import { QUERY_KEY_QUEUES_UNPAGINATED } from './useUnpaginatedQueues';

export const useMoveQueue = (onSuccess: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { queueId: ID; workspace: Url }) =>
      api.request(
        endpoints.queues.patch(payload.queueId, {
          workspace: payload.workspace,
        })
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_WORKSPACES_UNPAGINATED],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_QUEUES],
      });
      dispatch(throwInfo('queueMoved', undefined));
      onSuccess();
    },

    onError: () => {
      dispatch(throwError('queueMovedError'));
    },
  });
};
