import {
  Box,
  Card,
  CardContent,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ComponentType, ReactNode } from 'react';

type Props = {
  Icon: ComponentType<Record<string, unknown>>;
  title: string;
  titleDataCy: string;
  description?: ReactNode;
};
export const StatCard = ({ title, description, Icon, titleDataCy }: Props) => {
  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              borderRadius: 40,
              svg: { color: 'primary.main' },
              flexShrink: 0,
            }}
            elevation={8}
          >
            <Icon />
          </Paper>
          <Box>
            <Typography variant="h6" fontSize={14} data-cy={titleDataCy}>
              {title}
            </Typography>
            {description}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
