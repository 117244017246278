import { useEffect, useState } from 'react';

export const useTimeoutSearchQuery = (searchQuery: string | undefined) => {
  const [search, setSearch] = useState<string | undefined>(searchQuery);

  useEffect(() => {
    if (!search) {
      setSearch(searchQuery);
    }

    setTimeout(() => {
      setSearch(searchQuery);
    }, 100);
  }, [search, searchQuery]);

  return search;
};
