// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const engineFieldListQuerySchema = z
  .object({
    id: z.union([idSchema, z.array(idSchema)]).optional(),
    engine: z.union([idSchema, z.array(idSchema)]).optional(),
    name: z.string().optional(),
    used: z.boolean().optional(),
  })
  .and(paginationQuerySchema);
