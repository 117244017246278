import { Stack, Tooltip, Typography } from '@rossum/ui/material';
import { useRef } from 'react';

type Props = {
  name: string;
};

const TruncatedLabelMenuText = ({ name }: Props) => {
  const wrapperEl = useRef<HTMLDivElement>(null);
  const textEl = useRef<HTMLDivElement>(null);

  const shouldRenderTooltip =
    wrapperEl.current && textEl.current
      ? wrapperEl.current.clientWidth < textEl.current.scrollWidth
      : false;

  return (
    <Tooltip
      placement="top"
      title={shouldRenderTooltip ? name : ''}
      enterDelay={600}
      disableInteractive
    >
      <Stack ref={wrapperEl} sx={{ maxWidth: '100%', pr: 3 }}>
        <Typography
          component="div"
          variant="body2"
          ref={textEl}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default TruncatedLabelMenuText;
