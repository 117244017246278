import { useEffect } from 'react';
import { EMPTY, interval } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { useObservableContext } from '../../components/ObservableProvider';

const SCROLL_VELOCITY_BASE = 10;

// empirical - speed up based on distance from edge
const getDirectionForDistance = (distance: number) =>
  Math.floor((1 / (1 + (distance / 20) ** 2)) * 7);

const performScroll = (dirVelocity: number, originalScrollTop: number) => {
  return originalScrollTop + dirVelocity * SCROLL_VELOCITY_BASE;
};
/**
 * Scrolls the footer when the mouse gets close enough to top/bottom edge.
 * It implements behavior from ScrollTrigger used in the old footer.
 */
export const useScrollTriggerInBatchUpdate = ({
  scrolledElement,
  inBatchUpdateState,
}: {
  scrolledElement?: HTMLElement;
  inBatchUpdateState: boolean;
}) => {
  const { mousePositionOnDocumentObserver } = useObservableContext()!;

  useEffect(() => {
    if (!scrolledElement) return undefined;

    const footerRect = scrolledElement.getBoundingClientRect();

    const scrollTriggerSubscription = mousePositionOnDocumentObserver
      .pipe(
        filter(() => inBatchUpdateState),
        map(({ y }) => {
          const distanceFromTop = getDirectionForDistance(y - footerRect.top);

          const distanceFromBottom = getDirectionForDistance(
            footerRect.bottom - y
          );

          if (distanceFromTop >= 1) {
            return -distanceFromTop;
          }

          if (distanceFromBottom >= 1) {
            return distanceFromBottom;
          }

          return null;
        }),
        distinctUntilChanged(),
        switchMap(dirVelocity => {
          if (dirVelocity == null) return EMPTY;

          return interval(16).pipe(
            map(() => {
              const { scrollTop } = scrolledElement;
              const maxScroll =
                scrolledElement.scrollHeight - scrolledElement.offsetHeight;

              const resultScroll = performScroll(dirVelocity, scrollTop);

              const roundedScroll = Math.min(
                Math.max(resultScroll, 0),
                maxScroll
              );

              return roundedScroll;
            })
          );
        })
      )
      .subscribe(roundedScroll => {
        scrolledElement.scrollTo({
          top: roundedScroll,
        });
      });

    return () => {
      scrollTriggerSubscription.unsubscribe();
    };
  }, [scrolledElement, inBatchUpdateState, mousePositionOnDocumentObserver]);
};
