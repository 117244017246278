// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const connectorSchema = z.object({
  id: idSchema,
  name: z.string(),
  queues: z.array(urlSchema),
  url: urlSchema,
  serviceUrl: urlSchema,
  params: z.string().nullable(),
  authorizationToken: z.string(),
  authorizationType: z.string(),
  clientSslCertificate: z.string().nullable(),
  clientSslKey: z.string().optional(),
  asynchronous: z.boolean(),
  metadata: z.unknown().optional(),
});
