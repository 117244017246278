import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { boldText, linebreak } from '../../../lib/formaterValues';
import { auroraTourCompletedSelector } from '../../../redux/modules/ui/selectors';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import tour1 from './img/tour1.png';
import tour2 from './img/tour2.png';
import tour3 from './img/tour3.png';

const steps = [
  {
    title: 'containers.complexLineItemsTour.auroraTour.step1.title',
    description: 'containers.complexLineItemsTour.auroraTour.step1.description',
    image: tour1,
  },
  {
    title: 'containers.complexLineItemsTour.auroraTour.step2.title',
    description: 'containers.complexLineItemsTour.auroraTour.step2.description',
    image: tour2,
  },
  {
    title: 'containers.complexLineItemsTour.auroraTour.step3.title',
    description: 'containers.complexLineItemsTour.auroraTour.step3.description',
    image: tour3,
  },
] as const;

export const AuroraTour = () => {
  const intl = useIntl();
  const auroraTourCompleted = useSelector(auroraTourCompletedSelector);
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(!auroraTourCompleted);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const onClose = useCallback(() => {
    setOpen(false);
    dispatch(
      updateUiSettings({
        auroraTourCompleted: true,
      })
    );
  }, [dispatch]);

  const step = steps[stepIndex];
  const lastStepIndex = steps.length - 1;

  return (
    <Dialog open={open && !!step} PaperProps={{ elevation: 2 }}>
      <DialogTitle>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="h5" variant="h5">
            {step ? intl.formatMessage({ id: step.title }) : null}
          </Typography>
          <Typography variant="body1">
            {stepIndex + 1}/{lastStepIndex + 1}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {step && (
          <Stack direction="column" spacing={3} px={2} py={4}>
            <Stack px={4}>
              <Stack
                sx={{
                  border: t => `${t.spacing(0.5)} solid ${t.palette.divider}`,
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <img src={step.image} alt={step.title} />
              </Stack>
            </Stack>
            <Typography>
              {intl.formatMessage(
                { id: step.description },
                { boldText, linebreak }
              )}
            </Typography>
          </Stack>
        )}

        {stepIndex !== lastStepIndex ? (
          <DialogActions>
            <Button onClick={onClose} variant="outlined" color="secondary">
              {intl.formatMessage({
                id: 'containers.complexLineItemsTour.auroraTour.skip',
              })}
            </Button>
            <Button
              onClick={() => setStepIndex(i => i + 1)}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage({
                id: 'containers.complexLineItemsTour.auroraTour.next',
              })}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              {intl.formatMessage({
                id: 'containers.complexLineItemsTour.auroraTour.close',
              })}
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};
