import { Stack, Typography } from '@rossum/ui/material';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';

const IntegrationTest = () => {
  const isEnabled = useFeatureFlag('integration-test-flag');

  return (
    <Stack sx={{ p: 5 }}>
      <Typography>{isEnabled ? 'Yes, enabled' : 'No, disabled'}</Typography>
    </Stack>
  );
};

export default IntegrationTest;
