import { LinearProgress, Stack, Typography } from '@rossum/ui/material';

type LoadingProgressBarProps = {
  progressMessage?: string;
};

export const LoadingProgressBar = ({
  progressMessage,
}: LoadingProgressBarProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack
        sx={{
          width: 170,
          height: 4,
        }}
      >
        <LinearProgress color="primary" />
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {progressMessage}
      </Typography>
    </Stack>
  );
};
