import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DatapointData } from '../models/annotationData';
import { datapointDataSchema } from '../models/annotationData.schema';
import { updateAnnotationContentPayloadSchema } from './updateContent.schema';

export type UpdateAnnotationContentPayload = {
  content: { value: string | number };
  validationSources: Array<'checks' | 'score' | 'human'>;
};

export const updateContent = (
  annotationId: ID,
  datapointId: ID,
  payload: UpdateAnnotationContentPayload
) => {
  return {
    endpoint: `/annotations/${annotationId}/content/${datapointId}`,
    method: 'PATCH',
    responseSchema: datapointDataSchema,
    payload,
    payloadSchema: updateAnnotationContentPayloadSchema,
  } as const;
};
