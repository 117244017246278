import { ExtensionEvent } from '@rossum/api-client/hooks';
import { Chip } from '@rossum/ui/material';
import clsx from 'clsx';
import { includes } from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Triangle from '../../../../../components/UI/TriangleIcon';
import {
  constructActionId,
  EventShape,
  getEventActionsIds,
} from '../../../lib/helpers';
import styles from '../style.module.sass';
import Action from './Action';

type Props = {
  event: EventShape;
  setSelected: (_selected: Array<ExtensionEvent>) => void;
  selected: Array<ExtensionEvent>;
};

const ExpandableEvent = ({ event, selected, setSelected }: Props) => {
  const [expanded, setExpanded] = useState(true);
  const allSelected = includes(selected, event.name);
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventActions = useMemo(() => getEventActionsIds(event), [event.name]);
  const selectedFromEvent = eventActions.filter(_event =>
    includes(selected, _event)
  );
  const shouldDeselect =
    allSelected || selectedFromEvent.length === event.actions.length;
  const handleAllEventActionsSelection = () => {
    const newSelect = selected.filter(
      _event => !includes(eventActions, _event)
    );

    return setSelected(
      shouldDeselect ? newSelect : newSelect.concat(event.name)
    );
  };

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className={styles.Wrapper} onClick={e => e.stopPropagation()}>
      <div
        className={clsx(styles.EventWrapper, styles.Item)}
        data-cy="extension-event"
        onClick={toggleExpanded}
      >
        <Triangle expanded={expanded} />
        <div className={styles.EventName}>
          <FormattedMessage
            id={`containers.settings.extensions.events.${event.name}`}
          />
          <div
            className={styles.ItemsSelector}
            onClick={e => e.stopPropagation()}
          >
            <span
              className={styles.ItemsSelectorHandler}
              onClick={handleAllEventActionsSelection}
            >
              <FormattedMessage
                id={`containers.settings.extensions.events.${
                  shouldDeselect ? 'deselect' : 'selectAll'
                }`}
              />
            </span>
            <Chip
              size="tiny"
              label={`${
                allSelected ? eventActions.length - 1 : selectedFromEvent.length
              }/${event.actions.length}`}
            />
          </div>
        </div>
      </div>
      {expanded &&
        event.actions.map(action => {
          const actionId = constructActionId(event.name)(action);
          const isActionSelected = includes(selected, actionId);

          // TODO: could be simplified after https://rossumai.atlassian.net/browse/EB-421
          const handleAllSelectedClick = () =>
            setSelected(
              selected
                .filter(_event => !includes(eventActions, _event))
                .concat(eventActions)
                .filter(_event => _event !== actionId && _event !== event.name)
            );

          const handleClick = () =>
            setSelected(
              isActionSelected
                ? selected.filter(e => e !== actionId)
                : [...selected, actionId]
            );

          return (
            <Action
              key={actionId}
              type={action}
              onClick={allSelected ? handleAllSelectedClick : handleClick}
              selected={allSelected || isActionSelected}
            />
          );
        })}
    </div>
  );
};

export default ExpandableEvent;
