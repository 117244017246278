// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const dedicatedEnginePatchPayloadSchema = z
  .object({
    name: z.string(),
    schema: urlSchema,
  })
  .partial();
