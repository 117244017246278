import { endpoints, ID } from '@rossum/api-client';
import { Workspace } from '@rossum/api-client/workspaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { QUERY_KEY_LAZY_WORKSPACES } from './useLazyWorkspaces';
import { QUERY_KEY_WORKSPACES_UNPAGINATED } from './useUnpaginatedWorkspaces';

export const useRenameWorkspace = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { workspaceId: ID; newName: string }) =>
      api.request(
        endpoints.workspaces.patch(payload.workspaceId, {
          name: payload.newName,
        })
      ),

    onSuccess: (_, payload) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_WORKSPACES],
      });
      dispatch(throwInfo('workspaceUpdated', undefined));
      queryClient.setQueriesData<Workspace[] | undefined>(
        [QUERY_KEY_WORKSPACES_UNPAGINATED],
        prev => {
          if (prev) {
            return prev.map(ws =>
              ws.id === payload.workspaceId
                ? { ...ws, name: payload.newName }
                : ws
            );
          }

          return prev;
        }
      );
    },

    onError: () => {
      dispatch(throwError('workspaceError'));
    },
  });
};
