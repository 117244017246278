import { Queue } from '../../queues/models/queue';
import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { engineQueueStatsItemSchema } from '../models/engineQueueStatsItem.schema';
import {
  engineQueueStatsQuerySchema,
  engineQueueStatsSideloadSchema,
} from './queueStats.schema';

export type EngineQueueStatsQuery = PaginationQuery;

export type EngineQueueStatsSideload = {
  queues: Queue[];
};

export const queueStats = (id: ID, query: EngineQueueStatsQuery) => {
  return {
    endpoint: `/engines/${id}/queue_stats`,
    method: 'GET',
    responseSchema: listResponse(engineQueueStatsItemSchema),
    query,
    querySchema: engineQueueStatsQuerySchema,
    sideloadSchema: engineQueueStatsSideloadSchema,
  } as const;
};
