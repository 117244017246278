import { InfoOutlined } from '@rossum/ui/icons';
import { Stack, Tooltip, Typography } from '@rossum/ui/material';

// This should only be a temporary component until form elements are properly
// formalized within the design system - UX is aware of this issue and we are on it now

// Problem is generally the very open minded usage of "labels" and "sections" and
// as a consequence us not having proper framework built around it

// For now we agreed these will just be typographical sections above form elements without proper labels

type VerboseFieldLabelProps = {
  label: string;
  tooltip?: string;
  description?: string;
  disabled?: boolean;
};

const VerboseFieldLabel = ({
  label,
  tooltip,
  description,
  disabled,
}: VerboseFieldLabelProps) => (
  <Stack spacing={0.25}>
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography
        variant="subtitle2"
        color={disabled ? 'text.disabled' : 'text.primary'}
      >
        {label}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoOutlined fontSize="small" color="disabled" />
        </Tooltip>
      )}
    </Stack>
    <Typography
      variant="caption"
      color={disabled ? 'text.disabled' : 'text.secondary'}
    >
      {description}
    </Typography>
  </Stack>
);

export default VerboseFieldLabel;
