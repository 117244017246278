import { styled } from '@rossum/ui/material';

const direction = ['top', 'right', 'bottom', 'left'] as const;
type Direction = (typeof direction)[number];

/**
 * This is a presentational component that only renders an edge handle
 * It needs to be used within a PageSpaceContext that provides transformational functions
 */

const OuterLine = styled('line', {
  shouldForwardProp: propName => propName !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  stroke: 'transparent',
  pointerEvents: 'all',
  [`&:hover ~ line`]: {
    stroke: color ?? theme.palette.primary.dark,
  },
}));

const edgeHandleProps: Record<
  Direction,
  React.SVGAttributes<SVGLineElement>
> = {
  top: {
    x1: 0,
    x2: '100%',
    y1: 0,
    y2: 0,
    style: {
      cursor: 'ns-resize',
    },
  },
  right: {
    x1: '100%',
    x2: '100%',
    y1: 0,
    y2: '100%',
    style: {
      cursor: 'ew-resize',
    },
  },
  bottom: {
    x1: 0,
    x2: '100%',
    y1: '100%',
    y2: '100%',
    style: {
      cursor: 'ns-resize',
    },
  },
  left: {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: '100%',
    style: {
      cursor: 'ew-resize',
    },
  },
};

type EdgeHandleProps = React.SVGAttributes<SVGLineElement> & {
  direction: Direction;
  isDragging: boolean;
  color?: string;
  strokeWidth: number;
};

export const EdgeHandle = ({
  direction,
  isDragging,
  color,
  strokeWidth,
  ...lineProps
}: EdgeHandleProps) => (
  <g>
    <OuterLine
      {...edgeHandleProps[direction]}
      vectorEffect="non-scaling-stroke"
      color={color}
      strokeWidth={strokeWidth * 4}
      stroke="transparent"
      {...lineProps}
    />
    <line
      {...edgeHandleProps[direction]}
      vectorEffect="non-scaling-stroke"
      strokeWidth={strokeWidth}
      pointerEvents="none"
    />
  </g>
);
