import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DevToolsActions } from '../../redux/devToolsActions';

type DevToolsSyncProps = {
  action: DevToolsActions;
};

export const DevToolsSync = ({ action }: DevToolsSyncProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(action);
  }, [action, dispatch]);

  return null;
};
