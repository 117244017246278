import { apply } from './apply';
import { create } from './create';
import { deleteEndpoint } from './delete';
import { list } from './list';
import { patch } from './patch';
export { type ApplyLabelPayload } from './apply';
export { type CreateLabelPayload } from './create';
export { type PatchLabelPayload } from './patch';

export const labels = {
  apply,
  list,
  patch,
  create,
  delete: deleteEndpoint,
};
