import { Close } from '@rossum/ui/icons';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@rossum/ui/material';
import ActIcon from 'mdi-react/AccountClockIcon';
import ExportIcon from 'mdi-react/ExportVariantIcon';
import ReceiveIcon from 'mdi-react/FileMultipleIcon';
import ExtensionsIcon from 'mdi-react/GoogleCirclesExtendedIcon';
import UnderstandIcon from 'mdi-react/MessageReplyTextIcon';
import React from 'react';
import { useIntl } from 'react-intl';
import { boldText, linebreak } from '../../../lib/formaterValues';

type Props = {
  onClose: () => void;
  dataCy?: string;
};

const tooltips = [
  {
    id: 'receive' as const,
    icon: ReceiveIcon,
  },
  {
    id: 'understand' as const,
    icon: UnderstandIcon,
  },
  {
    id: 'act' as const,
    icon: ActIcon,
  },
  {
    id: 'export' as const,
    icon: ExportIcon,
  },
];

const IntroToExtensions = ({ onClose, dataCy }: Props) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Card elevation={1} data-cy={dataCy}>
      <CardContent sx={{ padding: 2.5 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Box
                sx={{
                  borderRadius: '100%',
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme =>
                    theme.palette.action.disabledBackground,
                }}
              >
                <ExtensionsIcon
                  size={18}
                  style={{ color: theme.palette.primary.main }}
                />
              </Box>
              <Typography variant="subtitle2">
                {intl.formatMessage({
                  id: 'containers.settings.extensions.intro.title',
                })}
              </Typography>
            </Stack>
            <IconButton onClick={onClose} size="small">
              <Close
                fontSize="small"
                sx={{ color: theme => theme.palette.text.secondary }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary" sx={{ pr: 4 }}>
              {intl.formatMessage({
                id: 'containers.settings.extensions.intro.description',
              })}
            </Typography>
            <div>
              <Grid container spacing={1}>
                {tooltips.map(({ id, icon }) => (
                  <Grid item xs={6} key={id}>
                    <Stack direction="row" spacing={1}>
                      <Tooltip
                        placement="top"
                        title={
                          intl.formatMessage(
                            {
                              id: `containers.settings.extensions.intro.${id}.tooltip`,
                            },
                            { bold: boldText, linebreak }
                          ) ?? ''
                        }
                      >
                        <div>
                          {React.createElement(icon, {
                            size: 20,
                            style: { color: theme.palette.text.secondary },
                          })}
                        </div>
                      </Tooltip>
                      <Typography variant="body2">
                        {intl.formatMessage({
                          id: `containers.settings.extensions.intro.${id}`,
                        })}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default IntroToExtensions;
