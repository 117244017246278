import { Paper, Stack, StackProps } from '@rossum/ui/material';
import { ReactNode } from 'react';

const SIDEBAR_WIDTH = 300;

type Props = {
  children: ReactNode;
  sx?: StackProps['sx'];
};

const Sidebar = ({ children, sx }: Props) => (
  <Stack
    height={1}
    width={SIDEBAR_WIDTH}
    position="absolute"
    flexDirection="column"
    component={Paper}
    sx={{
      top: 0,
      bottom: 0,
      zIndex: 99,
      overflow: 'hidden',
      ...sx,
    }}
    data-cy="sidebar"
  >
    {children}
  </Stack>
);

export default Sidebar;
