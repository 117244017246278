import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Workspace } from '../models/workspace';
import { workspaceSchema } from '../models/workspace.schema';
import { workspaceListQuerySchema } from './list.schema';

export type WorkspaceListQuery = Partial<{
  fields: string[];
  id: number[];
  ordering: string[];
}> &
  PaginationQuery;

export const list = (query: WorkspaceListQuery = {}) => {
  return {
    endpoint: `/workspaces`,
    method: 'GET',
    responseSchema: listResponse(workspaceSchema),
    query,
    querySchema: workspaceListQuerySchema,
  } as const;
};
