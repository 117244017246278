import { clock } from './clock';

export const detectUserActivity = (
  onActivityDetected: (timestamp: number) => void
) => {
  const handler = () => {
    onActivityDetected(clock.now());
  };

  document.addEventListener('mousemove', handler, { capture: true });
  document.addEventListener('click', handler, { capture: true });
  document.addEventListener('keydown', handler, { capture: true });
  document.addEventListener('wheel', handler, { capture: true });

  return () => {
    document.removeEventListener('mousemove', handler);
    document.removeEventListener('click', handler);
    document.removeEventListener('keydown', handler);
    document.removeEventListener('wheel', handler);
  };
};
