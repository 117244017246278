import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { LevelOptions, validateLevel } from './useDashboardQuery';

type Props = {
  existingFilter: GridFilterModel | null;
  levelQuery: string | undefined;
  onFallback: (level: LevelOptions) => void;
  isUserRestrictedToAllDocsLevel: boolean;
};

export const useValidateDashboardLevel = ({
  existingFilter,
  levelQuery,
  onFallback,
  isUserRestrictedToAllDocsLevel,
}: Props) => {
  const expectedLevel = useMemo(() => {
    if (isUserRestrictedToAllDocsLevel) return 'all';

    const queueFilter = existingFilter?.items.find(
      item => item.field === 'queue'
    );

    if (!queueFilter) return 'all';

    const isLevelAllDocs =
      Array.isArray(queueFilter.value) && queueFilter.value.length > 1;

    return isLevelAllDocs ? 'all' : 'queue';
  }, [existingFilter, isUserRestrictedToAllDocsLevel]);

  const validatedLevel = validateLevel(levelQuery);

  if (!validatedLevel) {
    onFallback(expectedLevel);
  }
};
