import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { QueueListQuery } from '@rossum/api-client/queues';
import { Queue } from '@rossum/api-client/queues';
import { ListResponse } from '@rossum/api-client/utils';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

// TODO: Key factories: https://tkdodo.eu/blog/leveraging-the-query-function-context
// TODO: Would be nice to be able to use ?fields filtering with API client to optimise
// requests for large entities like this
export const QUERY_KEY_LAZY_QUEUES = 'queues';

export const useLazyQueues = (
  query: QueueListQuery = { pageSize: 10 },
  queryOptions: Omit<
    UseInfiniteQueryOptions<
      ListResponse<Queue>,
      ElisClientError,
      ListResponse<Queue>,
      ListResponse<Queue>,
      [typeof QUERY_KEY_LAZY_QUEUES, QueueListQuery]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useInfiniteQuery(
    [QUERY_KEY_LAZY_QUEUES, query] as [
      typeof QUERY_KEY_LAZY_QUEUES,
      QueueListQuery,
    ],
    ({ queryKey, pageParam }) => {
      return api.request(
        endpoints.queues.list({ ...queryKey[1], page: pageParam })
      );
    },
    {
      ...queryOptions,
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.pagination.totalPages
          ? pages.length + 1
          : false;
      },
      // TODO: getFirstPageParam
    }
  );
};
