import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from '@rossum/ui/icons';
import { alpha, CheckboxProps, styled } from '@rossum/ui/material';
import React, { useCallback } from 'react';

export type FakeCheckboxProps = Omit<
  CheckboxProps,
  'inputProps' | 'onChange'
> & {
  onChange: (
    e: Omit<React.MouseEvent<HTMLDivElement>, 'target'> & {
      target: EventTarget & {
        checked: boolean;
        indeterminate: boolean;
      };
    },
    checked: boolean
  ) => void;
};

const defaultCheckedIcon = <CheckBox />;
const defaultIcon = <CheckBoxOutlineBlank />;
const defaultIndeterminateIcon = <IndeterminateCheckBox />;

const StyledFakeCheckbox = styled<
  (
    props: Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> & {
      ref?: React.ForwardedRef<HTMLDivElement>;
      color: CheckboxProps['color'];
      checked: CheckboxProps['checked'];
      indeterminate: CheckboxProps['indeterminate'];
      disabled: CheckboxProps['disabled'];
    }
  ) => JSX.Element | null
>(
  React.forwardRef((props, ref) => <div {...props} ref={ref} />),
  {
    shouldForwardProp: propName =>
      propName !== 'checked' &&
      propName !== 'indeterminate' &&
      propName !== 'color' &&
      propName !== 'disabled',
  }
)(
  ({
    checked = 'primary',
    indeterminate,
    color = 'primary',
    disabled,
    theme,
  }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color:
      (checked || indeterminate) && color !== 'default'
        ? theme.palette[color].main
        : alpha(theme.palette.text.primary, 0.7),
    cursor: disabled ? 'default' : 'pointer',
  })
);

export const FakeCheckbox = React.forwardRef<HTMLDivElement, FakeCheckboxProps>(
  (
    {
      checked,
      indeterminate,
      disabled,
      onChange,
      size,
      color = 'primary',
      style,
    },
    ref
  ) => {
    const icon = (() => {
      if (indeterminate) {
        return defaultIndeterminateIcon;
      }

      if (checked) {
        return defaultCheckedIcon;
      }

      return defaultIcon;
    })();

    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
      e => {
        const event = {
          ...e,
          target: {
            ...e.target,
            checked: !checked,
            indeterminate: !indeterminate,
          },
        };
        onChange(event, !(checked || indeterminate));
      },
      [checked, indeterminate, onChange]
    );

    return (
      <StyledFakeCheckbox
        ref={ref}
        color={color}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        onClick={!disabled ? handleClick : undefined}
        style={style}
        data-cy="styled-fake-checkbox"
      >
        {React.cloneElement(icon, { fontSize: size })}
      </StyledFakeCheckbox>
    );
  }
);
