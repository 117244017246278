import { ID } from '@rossum/api-client';
import { Workspace } from '@rossum/api-client/workspaces';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY_WORKSPACES_UNPAGINATED } from './useUnpaginatedWorkspaces';

export const removeWorkspaceMutation = (
  queryClient: QueryClient,
  workspaceId: ID
) => {
  queryClient.setQueriesData<Workspace[] | undefined>(
    [QUERY_KEY_WORKSPACES_UNPAGINATED],
    prev => prev?.filter(ws => ws.id !== workspaceId)
  );
};
