import { ThemeOptions } from '@mui/material';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0066F3',
    },
    secondary: {
      main: '#586178',
    },
    success: {
      main: '#66BB6A',
    },
    info: {
      main: '#29B6F6',
    },
    warning: {
      main: '#FFA726',
    },
    error: {
      main: '#F44336',
    },
    divider: `rgba(0, 0, 0, 0.12)`,
    background: {
      default: '#fff',
      paper: '#F8F8F8',
    },
    // TODO: We should probably get rid of this and instead enforce `Menu` to be systematic
    // is connected to the above
    // action: {
    //   hover: 'rgba(255, 255, 255, 0.2)',
    //   hoverOpacity: 0.2,
    // },
  },
};
