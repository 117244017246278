import { CloudOff } from '@rossum/ui/icons';
import { Stack, Typography } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  mail: string | null | undefined;
};

const IEView = ({ mail }: Props) => (
  <Stack alignItems="center" justifyContent="center" spacing={3}>
    <CloudOff fontSize="large" />
    <Typography variant="h5">
      <FormattedMessage id="containers.annotationList.upload.incompatible" />
    </Typography>
    {mail && (
      <FormattedMessage
        id="containers.annotationList.upload.email"
        values={{
          mail: (
            <a href={`mailto:${mail || ''}`}>
              <strong>{mail}</strong>
            </a>
          ),
        }}
      />
    )}
  </Stack>
);

export default IEView;
