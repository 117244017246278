// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { processingDurationSchema } from '../models/processingDuration.schema';

export const cancelEditPagesPayloadSchema = z.object({
  annotations: z.array(urlSchema),
  cancelParent: z.boolean(),
  processingDuration: processingDurationSchema.optional(),
});
