import { alpha, useTheme } from '@rossum/ui/material';
import React, {
  CSSProperties,
  SVGProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as R from 'remeda';

export type Orientation = 'horizontal' | 'vertical';

export type InitEventPayload = {
  orientation: Orientation;
  totalSize: number;
  size: number;
  propSize: number;
  offset: number;
};

type ScrollbarProps = {
  orientation: Orientation;

  /**
   * Between 0 and 1. 0 = hidden, 1 = full page.
   */
  size: number;

  /**
   * Between 0 and 1. 0 = minimum, 1 = maximum.
   */
  offset: number;

  onMove: (percentage: number) => void;
  wrapperStyleOverrides?: CSSProperties;
};

type SVGMouseEvent = React.MouseEvent<SVGRectElement, MouseEvent>;

const SCROLLBAR_THICKNESS = 12;
const SCROLLBAR_PADDING = 1;

// When clicking the scrollbar area, you want to scroll but slightly less to keep context.
// So, instead of scrolling by 100% of the viewport, we scroll only by 90%.
const OVERLAP_FACTOR = 0.9;

type DragState = {
  event: SVGMouseEvent;
  payload: InitEventPayload;
};

const useScrollbarInteraction = (
  moveCallback: (percentage: number) => void
) => {
  const [dragState, setDragState] = useState<DragState | undefined>(undefined);

  const handleMouseDown = useCallback(
    ({
      event,
      payload,
    }: {
      event: SVGMouseEvent;
      payload: InitEventPayload;
    }) => {
      event.stopPropagation();
      setDragState({
        event,
        payload,
      });
    },
    []
  );

  const getPosition = (e: MouseEvent | SVGMouseEvent, d: Orientation) =>
    d === 'vertical' ? e.clientY : e.clientX;

  // When clicking, use offset instead of position (otherwise top/left padding is counted)
  const getOffset = (e: MouseEvent, d: Orientation) =>
    d === 'vertical' ? e.offsetY : e.offsetX;

  const handleClick = useCallback(
    ({
      event,
      payload,
    }: {
      event: SVGMouseEvent;
      payload: InitEventPayload;
    }) => {
      const { orientation, offset, size, totalSize, propSize } = payload;

      // Converts real pixels to scroll percentages.
      const normalizationFactor = totalSize * (1 - size);
      const position = getOffset(event.nativeEvent, orientation);
      const clickPercentage = position / normalizationFactor;

      // check if the click happened below or above handle.
      const direction =
        clickPercentage < offset ? -1 : clickPercentage > offset + size ? 1 : 0;

      // Move by propSize (which is actual viewport size)
      const newPercentage = offset + direction * propSize * OVERLAP_FACTOR;

      moveCallback(R.clamp(newPercentage, { min: 0, max: 1 }));
    },
    [moveCallback]
  );

  const handleMouseMove = useCallback(
    (moveEvent: MouseEvent) => {
      if (dragState) {
        const {
          event: initEvent,
          payload: { orientation, offset, size, totalSize },
        } = dragState;

        // Converts real pixels to scroll percentages.
        const normalizationFactor = totalSize * (1 - size);

        const difference =
          getPosition(moveEvent, orientation) -
          getPosition(initEvent, orientation);

        const percentage = offset + difference / normalizationFactor;

        moveCallback(R.clamp(percentage, { min: 0, max: 1 }));
      }
    },
    [moveCallback, dragState]
  );

  const handleMouseUp = useCallback(() => {
    setDragState(undefined);
  }, []);

  useEffect(() => {
    if (dragState) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, dragState]);

  return { handleMouseDown, handleClick };
};

export const Scrollbar = ({
  orientation,
  size: propSize,
  offset,
  onMove,
  wrapperStyleOverrides,
}: ScrollbarProps) => {
  const size = R.clamp(propSize, { min: 0.1, max: 1 });
  const theme = useTheme();
  const wrapperRef = useRef<SVGRectElement>(null);
  const { handleMouseDown, handleClick } = useScrollbarInteraction(onMove);

  // I have attempted to type this safely and to be readable at the same time
  // but failed to do so.
  const wrapperStyle: CSSProperties = {
    position: 'absolute',
    [orientation === 'vertical' ? 'width' : 'height']: SCROLLBAR_THICKNESS,
    [orientation === 'vertical' ? 'bottom' : 'right']: SCROLLBAR_THICKNESS,
    [orientation === 'vertical' ? 'right' : 'bottom']: 0,
    display: propSize !== 0 ? 'visible' : 'hidden',
    top: orientation === 'vertical' ? 0 : undefined,
    left: orientation === 'vertical' ? undefined : 0,
    ...wrapperStyleOverrides,
  };

  const handleProps: SVGProps<SVGRectElement> = {
    [orientation === 'vertical' ? 'y' : 'x']: `${offset * (1 - size) * 100}%`,
    [orientation === 'vertical' ? 'height' : 'width']: `${size * 100}%`,
    [orientation === 'vertical' ? 'width' : 'height']:
      SCROLLBAR_THICKNESS - SCROLLBAR_PADDING * 2,
    [orientation === 'vertical' ? 'x' : 'y']: SCROLLBAR_PADDING,
    rx: SCROLLBAR_THICKNESS / 2,
    ry: SCROLLBAR_THICKNESS / 2,
    style: {
      fill: alpha(theme.palette.grey[600], 0.5),
    },
  };

  const wrapperProps: SVGProps<SVGRectElement> = {
    height: '100%',
    width: '100%',
    fill: 'transparent',
  };

  const getPayload = () => {
    if (!wrapperRef.current) {
      return undefined;
    }

    const wrapperBox = wrapperRef.current.getBBox();
    return {
      orientation,
      size,
      offset,
      propSize,
      totalSize:
        orientation === 'vertical' ? wrapperBox.height : wrapperBox.width,
    };
  };

  return (
    <div style={wrapperStyle}>
      <svg style={{ width: '100%', height: '100%' }}>
        <rect
          onClick={event => {
            const payload = getPayload();
            if (payload) {
              handleClick({ event, payload });
            }
          }}
          {...wrapperProps}
          ref={wrapperRef}
        />
        <rect
          onMouseDown={event => {
            const payload = getPayload();
            if (payload) {
              handleMouseDown({ event, payload });
            }
          }}
          {...handleProps}
        />
      </svg>
    </div>
  );
};
