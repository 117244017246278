import { combineEpics } from 'redux-observable';
import { catchError, filter, map, pluck, switchMap } from 'rxjs/operators';
import { errorHandler } from '../../../lib/api';
import { OrganizationGroup } from '../../../types/organizationGroup';
import { fetchOrganizationFulfilled } from '../organization/actions';
import { isActionOf, makeEpic } from '../utils';
import { fetchOrgGroupFulfilled } from './actions';

const fetchOrgGroupEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchOrganizationFulfilled)),
    pluck('payload', 'organizationGroup'),
    switchMap(url =>
      authGetJSON$<OrganizationGroup>(url).pipe(
        map(fetchOrgGroupFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

export default combineEpics(fetchOrgGroupEpic);
