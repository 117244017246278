import { validate as validateEmail } from 'email-validator';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';

const validateEmailsIfDefined = (value: Array<string | undefined>) =>
  value.every(email => email && validateEmail(email));

export const sendEmailValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    to: yup
      .array(yup.string())
      .test(
        'testEmailAddresses',
        intl.formatMessage({
          id: 'components.emailResponseForm.fields.edit.to.invalidEmail',
        }),
        value => {
          return !!value && value.every(email => email && validateEmail(email));
        }
      )
      .min(
        1,
        intl.formatMessage({
          id: 'components.emailResponseForm.fields.edit.to.required',
        })
      ),
    cc: yup.array(yup.string()).test(
      'testEmailAddresses',
      intl.formatMessage({
        id: 'components.emailResponseForm.fields.edit.cc.invalidEmail',
      }),
      value => !value || validateEmailsIfDefined(value)
    ),
    bcc: yup.array(yup.string()).test(
      'testEmailAddresses',
      intl.formatMessage({
        id: 'components.emailResponseForm.fields.edit.bcc.invalidEmail',
      }),
      value => !value || validateEmailsIfDefined(value)
    ),
    message: yup.string().nullable(),
    subject: yup.string().required(
      intl.formatMessage({
        id: 'components.emailResponseForm.fields.subject.requiredSubject',
      })
    ),
    attachments: yup.array(yup.string()),
    copyFieldVisible: yup.boolean(),
    blindCopyFieldVisible: yup.boolean(),
  });
