import { assertNever } from '../../../../lib/typeUtils';
import { Point2D, Rectangle2DCoordinates } from './geometry';

// all 8 possible resizing directions
const resizingDirections = [
  'top',
  'top-right',
  'right',
  'bottom-right',
  'bottom',
  'bottom-left',
  'left',
  'top-left',
] as const;

export type ResizingDirection = (typeof resizingDirections)[number];

export type CornerResizingDirection = Extract<
  ResizingDirection,
  'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
>;

export type EdgeResizingDirection = Extract<
  ResizingDirection,
  'top' | 'right' | 'bottom' | 'left'
>;

const ensureRectangle = (
  rect: Rectangle2DCoordinates
): Rectangle2DCoordinates => {
  return [
    Math.min(rect[0], rect[2]),
    Math.min(rect[1], rect[3]),
    Math.max(rect[0], rect[2]),
    Math.max(rect[1], rect[3]),
  ];
};

/** Resizes `rect` by `diff` (already clamped) in `direction`
 * */
export const resizeRectangle = (
  rect: Rectangle2DCoordinates,
  direction: ResizingDirection,
  diff: Point2D
): Rectangle2DCoordinates => {
  switch (direction) {
    case 'top': {
      return ensureRectangle([rect[0], rect[1] + diff.y, rect[2], rect[3]]);
    }
    case 'top-right': {
      return ensureRectangle([
        rect[0],
        rect[1] + diff.y,
        rect[2] + diff.x,
        rect[3],
      ]);
    }
    case 'right': {
      return ensureRectangle([rect[0], rect[1], rect[2] + diff.x, rect[3]]);
    }
    case 'bottom-right': {
      return ensureRectangle([
        rect[0],
        rect[1],
        rect[2] + diff.x,
        rect[3] + diff.y,
      ]);
    }
    case 'bottom': {
      return ensureRectangle([rect[0], rect[1], rect[2], rect[3] + diff.y]);
    }
    case 'bottom-left': {
      return ensureRectangle([
        rect[0] + diff.x,
        rect[1],
        rect[2],
        rect[3] + diff.y,
      ]);
    }
    case 'left': {
      return ensureRectangle([rect[0] + diff.x, rect[1], rect[2], rect[3]]);
    }
    case 'top-left': {
      return ensureRectangle([
        rect[0] + diff.x,
        rect[1] + diff.y,
        rect[2],
        rect[3],
      ]);
    }
    default: {
      assertNever(direction);
      return rect;
    }
  }
};
