import { IAnnotation } from 'react-ace';
import { TestResponse } from '@rossum/api-client/hooks';
import { FunctionRuntime } from '@rossum/api-client/hooks';
import clsx from 'clsx';
import AlertCircle from 'mdi-react/AlertCircleIcon';
import {
  ChangeEvent,
  CSSProperties,
  LegacyRef,
  useEffect,
  useState,
} from 'react';
import ReactAce from 'react-ace/lib/ace';
import { FormattedMessage } from 'react-intl';
import { MIN_FUNCTION_BOX_SECTION_HEIGHT } from '../../../../../decorators/makeResizable/config';
import makeResizable from '../../../../../decorators/makeResizable/index';
import styles from '../style.module.sass';
import { EventNameOptions, TabNames } from '../types';
import ExtensionsInput from './ExtensionsInput';
import ExtensionsOutput from './ExtensionsOutput';
import InputBoxMenu from './InputBoxMenu';
import OutputBoxMenu from './OutputBoxMenu';

type OwnProps = {
  assignedFunctionEventNames: Array<EventNameOptions>;
  canRun: boolean;
  canSave: boolean;
  currentEvent: EventNameOptions;
  currentFunctionOutput: TestResponse | undefined;
  currentObject: string;
  editorFooter: boolean;
  functionEvents: Array<EventNameOptions>;
  testFunctionError?: boolean;
  inputErrors: Array<IAnnotation>;
  inputRef: LegacyRef<ReactAce>;
  inputTmpValue: string;
  isInputValid: boolean;
  isRunning: boolean;
  onChange: (_value: string, _event?: ChangeEvent<Element>) => void;
  onLoad: () => void;
  onPrettify: (_value?: string) => void;
  onSave: () => void;
  runtime: FunctionRuntime;
  savedInput: string;
  setCurrentEvent: (_event: EventNameOptions) => void;
  setCurrentObject: (_objectRef: string) => void;
  setFooterResizing: (_resizing: boolean) => void;
  setInputErrors: (inputErrors: IAnnotation[]) => void;
  setIsInputValid: (_value: boolean) => void;
  setShowInputLoading: (loading: boolean) => void;
  showInputLoading: boolean;
  testFunction: () => void;
  isSaving?: boolean;
};

type InjectedProps = {
  resizing: boolean;
  style: CSSProperties;
  height: number;
};

type EditorFooterProps = OwnProps & InjectedProps;

const EditorFooter = ({
  assignedFunctionEventNames,
  canRun,
  canSave,
  currentEvent,
  currentFunctionOutput,
  currentObject,
  functionEvents,
  testFunctionError,
  height,
  inputErrors,
  inputRef,
  inputTmpValue,
  isInputValid,
  isRunning,
  onChange,
  onLoad,
  onPrettify,
  onSave,
  resizing,
  runtime,
  savedInput,
  setCurrentEvent,
  setCurrentObject,
  setFooterResizing,
  setInputErrors,
  setIsInputValid,
  setShowInputLoading,
  showInputLoading,
  style,
  testFunction,
  isSaving,
}: EditorFooterProps) => {
  const [activeResponseTab, setActiveResponseTab] =
    useState<TabNames>('response');

  useEffect(() => {
    setFooterResizing(resizing);
  }, [resizing, setFooterResizing]);

  return (
    <div style={style} className={styles.FooterWrapper}>
      <ExtensionsInput
        onChange={onChange}
        value={inputTmpValue}
        forwardRef={inputRef}
        onValidate={annotations => {
          setInputErrors(
            annotations.filter(({ type }) => type === 'error') as IAnnotation[]
          );
          setIsInputValid(!annotations.some(({ type }) => type === 'error'));
        }}
        height={height}
        showInputLoading={showInputLoading}
      >
        <InputBoxMenu
          assignedFunctionEventNames={assignedFunctionEventNames}
          canRun={canRun}
          canSave={canSave}
          onSave={onSave}
          onLoad={onLoad}
          currentObject={currentObject}
          setCurrentObject={setCurrentObject}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          functionEvents={functionEvents}
          hasError={!isInputValid && !!inputErrors.length}
          isRunning={isRunning}
          onPrettify={onPrettify}
          onRun={testFunction}
          savedInput={savedInput}
          setShowInputLoading={setShowInputLoading}
          isSaving={isSaving}
        />
      </ExtensionsInput>
      <ExtensionsOutput
        value={currentFunctionOutput}
        activeTab={activeResponseTab}
        runtime={runtime}
      >
        <OutputBoxMenu
          activeTab={activeResponseTab}
          setActiveTab={setActiveResponseTab}
          hasError={testFunctionError}
          isRunning={isRunning}
        />
      </ExtensionsOutput>
      {inputErrors && !!inputErrors.length && (
        <div className={styles.Errors}>
          {inputErrors.map(({ row, text }) => (
            <div
              key={`${row}-${text}`}
              className={clsx(styles.Error, styles.ErrorMessage)}
            >
              {`Error: ${text} at row ${row}`}
            </div>
          ))}
          <div className={clsx(styles.Error, styles.ErrorCount)}>
            <AlertCircle />
            <FormattedMessage
              id="components.editor.inputBox.errorMessage"
              values={{ count: inputErrors.length }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default (props: OwnProps) =>
  makeResizable(EditorFooter)({
    ...props,
    minHeight: MIN_FUNCTION_BOX_SECTION_HEIGHT,
  });
