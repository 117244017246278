import { size } from 'lodash';
import React from 'react';
import { clearValidationMessagesAction } from '../redux/modules/validationMessages/action';
import { InputMessages } from '../redux/modules/validationMessages/types';

const hasValidationMessage = (
  validationMessages: InputMessages,
  id: string
) => {
  const message = validationMessages[id];
  return !!size(message);
};

export const clearValidationsOnChangeCreator =
  (
    clearValidationMessages: typeof clearValidationMessagesAction,
    formId: string,
    validationMessages: InputMessages | undefined
  ) =>
  (id: string) =>
  (handleChange: (_e: React.ChangeEvent<unknown>) => void) =>
  (event: React.ChangeEvent<unknown>) => {
    if (validationMessages && hasValidationMessage(validationMessages, id))
      clearValidationMessages(formId, id);
    handleChange(event);
  };
