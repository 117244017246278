import { Queue } from '@rossum/api-client/queues';
import { Done, RemoveCircleOutline, WarningAmber } from '@rossum/ui/icons';
import {
  alpha,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createFileSignature, isValidFile } from './helpers';

type Props = {
  fileList: File[];
  removeFileFromList: (signature: string) => void;
  selectedQueue: Queue | undefined;
};

export const FileList = ({
  fileList,
  removeFileFromList,
  selectedQueue,
}: Props) => {
  const intl = useIntl();
  const acceptedMimeTypes = selectedQueue?.settings.acceptedMimeTypes;

  const filesWithValidity = useMemo(() => {
    const fileListWithValidity = fileList.map(file => ({
      file,
      valid: isValidFile(file, acceptedMimeTypes),
    }));
    // When validity changes, we want the invalid files to be at the top of the list, so that user is aware of these
    return sortBy(fileListWithValidity, file => file.valid);
  }, [acceptedMimeTypes, fileList]);

  return (
    <Stack flex={0}>
      <Typography color="text.secondary" mb={1}>
        {intl.formatMessage({
          id: 'containers.annotationList.upload.fileList.title',
        })}
      </Typography>
      <List
        sx={{
          maxHeight: '25vh',
          overflow: 'auto',
          alignSelf: 'stretch',
        }}
        disablePadding
      >
        {filesWithValidity.map(({ file, valid }) => (
          <ListItem
            key={createFileSignature(file)}
            sx={{
              py: 0.5,
              my: 1,
              backgroundColor: theme =>
                alpha(theme.palette.action.selected, 0.12),
              borderRadius: 1,
            }}
          >
            <ListItemText
              primary={file.name}
              primaryTypographyProps={{ variant: 'subtitle2', fontWeight: 700 }}
            />
            <ListItemText
              primary={
                valid
                  ? intl.formatMessage({
                      id: 'containers.annotationList.upload.fileList.selected.success',
                    })
                  : intl.formatMessage({
                      id: 'containers.annotationList.upload.fileList.selected.error',
                    })
              }
              primaryTypographyProps={{
                variant: 'subtitle2',
                fontWeight: 400,
                color: theme =>
                  valid
                    ? theme.palette.success.main
                    : theme.palette.warning.main,
              }}
              sx={{ textAlign: 'end' }}
            />
            <Stack
              sx={{ ml: 1 }}
              spacing={2}
              direction="row"
              alignItems="center"
            >
              {valid ? (
                <Done color="success" />
              ) : (
                <Tooltip
                  title={intl.formatMessage(
                    {
                      id: 'containers.annotationList.upload.fileList.select.error.tooltip',
                    },
                    {
                      fileType: `${file.type}`,
                    }
                  )}
                >
                  <WarningAmber color="warning" />
                </Tooltip>
              )}
              <IconButton
                onClick={() => removeFileFromList(createFileSignature(file))}
              >
                <RemoveCircleOutline
                  sx={{ color: t => t.palette.text.disabled }}
                />
              </IconButton>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
