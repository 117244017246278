import clsx from 'clsx';
import CheckIcon from 'mdi-react/CheckIcon';
import { Queue } from '../../../../../../types/queue';
import styles from './styles.module.sass';

type Props = {
  onClick: () => void;
  queue: Queue;
  selected: boolean;
  className?: string;
};

const QueueComponent = ({ queue, selected, onClick, className }: Props) => (
  <div
    className={clsx(styles.QueueWrapper, styles.Item, className)}
    onClick={onClick}
  >
    <div
      className={clsx(
        styles.IconWrapper,
        selected && styles.SelectedIconWrapper
      )}
    >
      <CheckIcon size={18} />
    </div>
    {queue.name}
  </div>
);

export default QueueComponent;
