import { endpoints } from '@rossum/api-client';
import { DomainSettingsSsoProvider } from '@rossum/api-client/internal';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';

const QUERY_KEY_SSO_PROVIDERS = 'sso-providers';

export type SsoProvider = DomainSettingsSsoProvider;

type UseSsoProvidersParams = {
  domain: string;
};

export const useSsoProviders = ({ domain }: UseSsoProvidersParams) => {
  const api = useApiClient();

  const authProvidersQuery = useQuery({
    queryKey: [QUERY_KEY_SSO_PROVIDERS, domain] as const,
    queryFn: () => api.request(endpoints.internal.domainSettings({ domain })),
    select: data => data.ssoProviders,
  });

  return authProvidersQuery;
};
