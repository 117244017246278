import { SvgIconComponent } from '@rossum/ui/icons';
import { Button, Stack, Typography } from '@rossum/ui/material';

type Props = {
  buttonText: string;
  icon: SvgIconComponent;
  onButtonClick: () => void;
  subtitle: string;
  title: string;
  withBackground?: boolean;
  dataCy?: string;
};

const AdBanner = ({
  buttonText,
  icon: Icon,
  onButtonClick,
  subtitle,
  title,
  withBackground = true,
  dataCy,
}: Props) => (
  <Stack
    py={6}
    px={4}
    alignItems="center"
    sx={withBackground ? { backgroundColor: 'background.paper' } : {}}
    data-cy={dataCy}
  >
    <Icon sx={{ fontSize: 40 }} color="secondary" />

    <Typography variant="h6" mt={1}>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary" mt={1}>
      {subtitle}
    </Typography>
    <Button
      variant="outlined"
      color="secondary"
      onClick={onButtonClick}
      sx={{ mt: 2.5 }}
    >
      {buttonText}
    </Button>
  </Stack>
);

export default AdBanner;
