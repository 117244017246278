import { useTheme } from '@rossum/ui/material';
import chroma from 'chroma-js';
import { useMemo } from 'react';

const lerp = (from: number, to: number, t: number) => from + t * (to - from);

export const useHeatmapColor = (
  confidence: number,
  validationSources: string[]
) => {
  const theme = useTheme();

  const lowest = useMemo(
    () => chroma(theme.palette.error.main).hcl(),
    [theme.palette.error.main]
  );

  const highest = useMemo(
    () => chroma(theme.palette.success.main).hcl(),
    [theme.palette.success.main]
  );

  if (confidence <= 0 || validationSources.includes('human')) {
    return null;
  }

  return chroma
    .hcl(
      lerp(lowest[0], highest[0], confidence),
      lerp(lowest[1], highest[1], confidence),
      lerp(lowest[2], highest[2], confidence)
    )
    .css();
};
