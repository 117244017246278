import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from '@rossum/ui/material';
import { GridFilterInputValueProps } from '@rossum/ui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useGetQueues } from '../../hooks/useGetQueues';
import { QueueSelectList } from './QueueSelectList';

const selectLabelKey = 'filtering-value-select-label';

export const MultiSelectInput = (props: GridFilterInputValueProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // we only expect the value to be an array for this component and we restrict the type here to please MUI's Select component
  const controlledValue = Array.isArray(props.item?.value)
    ? props.item.value
    : [];

  const [selectedValues, setSelectedValues] =
    useState<string[]>(controlledValue);

  // when we make a selection and apply those selections the global state is updated, therefore controlledValue is updated.
  // To display the latest selected values we make sure selectedValues is up to date.
  if (controlledValue.join('') !== selectedValues.join('')) {
    setSelectedValues(controlledValue);
  }

  const selectLabel = 'Value';

  const queuesQuery = useGetQueues();
  const queues = useMemo(
    () => queuesQuery.data?.pages.flatMap(page => page.results) || [],
    [queuesQuery.data]
  );

  return (
    <FormControl sx={{ width: 190 }}>
      <InputLabel id={selectLabelKey}>{selectLabel}</InputLabel>
      <Select
        value={selectedValues}
        labelId={selectLabelKey}
        open={isOpen}
        multiple
        input={<OutlinedInput label={selectLabel} />}
        renderValue={values => {
          const selectedValues = values
            .map(id => queues.filter(q => String(q.id) === id)[0]?.name)
            .join(', ');
          return selectedValues;
        }}
        label={selectLabel}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        size="small"
      >
        {isOpen && QueueSelectList ? (
          <QueueSelectList
            gridFilterInputValueProps={props}
            handleClose={setIsOpen}
          />
        ) : null}
      </Select>
    </FormControl>
  );
};
