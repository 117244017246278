import { endpoints } from '@rossum/api-client';
import { UsageReportPayload } from '@rossum/api-client/annotations';
import { useMutation } from '@tanstack/react-query';
import download from 'downloadjs';
import { useDispatch } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { throwError } from '../../../redux/modules/messages/actions';

const QUERY_KEY_USAGE_REPORT_EXPORT = 'usage-report-export';

export const useUsageReportExport = () => {
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: UsageReportPayload) =>
      api.request(endpoints.annotations.usageReportExport(payload)),

    retry: 1,

    onError: () => {
      dispatch(throwError('downloadFailed'));
    },

    onSuccess: data => {
      download(
        data,
        `${QUERY_KEY_USAGE_REPORT_EXPORT}-${Date.now()}.csv`,
        'text/csv'
      );
    },
  });

  return { triggerExport: mutate, isExporting: isLoading };
};
