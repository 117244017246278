import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { AsyncTask } from '../models/asyncTask';
import { asyncTaskSchema } from '../models/asyncTask.schema';
import { getAsyncTaskQuerySchema } from './get.schema';

export type GetAsyncTaskQuery = {
  noRedirect: boolean;
};

export const get = (
  taskId: ID,
  query: GetAsyncTaskQuery,
  signal?: AbortSignal
) => {
  return {
    endpoint: `/tasks/${taskId}`,
    method: 'GET',
    responseSchema: asyncTaskSchema,
    query,
    querySchema: getAsyncTaskQuerySchema,
    signal,
  } as const;
};
