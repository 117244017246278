import clsx from 'clsx';
import AlertCircle from 'mdi-react/AlertCircleIcon';
import { FormattedMessage } from 'react-intl';
import { SchemaError } from '../../../../../types/schema';
import styles from '../styles.module.sass';

type Props = {
  errors: SchemaError[];
};

const SchemaErrors = ({ errors }: Props) => (
  <div className={styles.Errors}>
    {errors.map(({ key, message }, i) => (
      <div // eslint-disable-next-line react/no-array-index-key
        key={`${key}-${message}-${i}`}
        className={clsx(styles.Error, styles.ErrorMessage)}
      >
        <span className={styles.MessageKey}>{key}</span>
        {message}
      </div>
    ))}
    <div className={clsx(styles.Error, styles.ErrorCount)}>
      <AlertCircle />
      <FormattedMessage
        id="containers.queueSchema.components.editor.errors"
        values={{ count: errors.length }}
      />
    </div>
  </div>
);

export default SchemaErrors;
