import { useCallback, useEffect } from 'react';
import {
  useCanvasGeometryActions,
  useDocumentStore,
} from '../../document-store/DocumentStore';

export const useSyncViewportSize = () => {
  const setViewportDimensions = useDocumentStore(
    state => state.setViewportDimensions
  );

  const { resetZoom } = useCanvasGeometryActions();

  const viewportRef = useDocumentStore(state => state.viewportRef);

  // Update viewport dimensions in zustand on window resize
  const handleWindowResize = useCallback(() => {
    if (viewportRef.current) {
      const newSize = viewportRef.current.getBoundingClientRect();
      setViewportDimensions(newSize);
    }
  }, [setViewportDimensions, viewportRef]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  // Update viewport size in zustand on footer resize
  useEffect(() => {
    handleWindowResize();
    resetZoom();
  }, [handleWindowResize, resetZoom]);

  return null;
};
