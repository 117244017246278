import { Components, tableCellClasses, Theme } from '../../material';

export const muiTableCell = (_theme: Theme): Components['MuiTableCell'] => ({
  styleOverrides: {
    root: {
      border: 0,
      [`&.${tableCellClasses.head}`]: {
        lineHeight: 1.43,
      },
    },
  },
});
