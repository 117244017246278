import { Chip, ChipProps } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { AuroraIcon } from './Icons';

const AURORA_KEYWORD = 'aurora';

export const shouldDisplayAuroraChipByName = (
  name: string,
  auroraEngineEnabled: boolean
) => {
  const isAuroraInName = name.toLowerCase().includes(AURORA_KEYWORD);
  return isAuroraInName && auroraEngineEnabled;
};

export type AuroraChipProps = Omit<ChipProps, 'label'> & {
  label?: string;
};

// Consider using ConditionalAuroraChip instead of AuroraChip
const AuroraChip = ({ size, label }: AuroraChipProps) => {
  const intl = useIntl();

  const resolvedLabel =
    label ??
    intl.formatMessage({
      id: 'component.auroraChip.label',
    });

  return (
    <Chip
      icon={
        <AuroraIcon
          color="inherit"
          sx={{ color: t => t.palette.common.white }}
        />
      }
      label={resolvedLabel}
      size={size}
      sx={{
        backgroundColor: t => t.palette.aurora.main,
        color: t => t.palette.common.white,
      }}
    />
  );
};

export default AuroraChip;
