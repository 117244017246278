import { ID } from '@rossum/api-client';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailThreadCounts } from '../models/emailThreads';
import { emailThreadCountsSchema } from '../models/emailThreads.schema';
import { emailThreadsCountsQuerySchema } from './counts.schema';

export type EmailThreadsCountsQuery = {
  queue: ID;
};

export const counts = (query: EmailThreadsCountsQuery) => {
  return {
    endpoint: `/email_threads/counts`,
    method: 'GET',
    responseSchema: emailThreadCountsSchema,
    query,
    querySchema: emailThreadsCountsQuerySchema,
  } as const;
};
