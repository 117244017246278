import { Box, Stack } from '@rossum/ui/material';
import { Dictionary, groupBy, range, sortBy } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { getCurrentTuple } from '../../redux/modules/datapoints/selector';
import { shouldShowGhostRow } from '../../redux/modules/datapoints/typedHelpers';
import { findCurrentGrids } from '../../redux/modules/magicGrid/selector';
import { columnsForMultivaluesSelector } from '../../redux/modules/schema/selectors';
import {
  complexLineItemsEnabledSelector,
  complexTablesEnabledOnOrganization,
} from '../../redux/modules/ui/selectors';
import { Children, MultivalueDatapointDataST } from '../../types/datapoints';
import { FooterLayout, SortFooterColumns } from '../../types/user';
import EmptyFooter from './components/EmptyFooter';
import ExtractAllButton from './ExtractAllButton';
import { FooterAddLineButton } from './FooterAddLineButton';
import { FooterMenu as FooterMenuButton } from './FooterMenu';
import { FooterSelectionPanel } from './FooterSelectionPanel';
import { FooterTable } from './FooterTable';
import { getOrderedIndexesForGrid } from './getOrderedIndexesForGrid';
import { useBatchUpdate } from './useBatchUpdate';
import { useDetectTupleUnderCursor } from './useDetectTupleUnderCursor';
import { useFooterVirtualObserver } from './useFooterVirtualObserver';
import { useScrollTriggerInBatchUpdate } from './useScrollTriggerInBatchUpdate';
import { useTupleActions } from './useTupleActions';
import {
  DEFAULT_STICKY_HEADER_Z_INDEX,
  hasVisibleStretchedColumn,
} from './utils';
import { VirtualTableContainer } from './VirtualTableContainer';

const getTableIdentifier = (el: HTMLElement) => el.dataset.part ?? '';

const ROW_SIZE = 32;
const HEADER_SIZE = 54 + 52;
const OFFSET = -6;

const simpleLayoutTopPositions = (tuple: Children, tupleIndex: number) => {
  return [tuple.id, OFFSET + HEADER_SIZE + tupleIndex * ROW_SIZE] as const;
};

const complexLayoutTopPositions = (tupleIdsByPart: Dictionary<number[]>) => {
  const allSortedTuples = sortBy(Object.entries(tupleIdsByPart), x =>
    Number(x[0])
  ).flatMap(([part, ids]) => ids.map(id => ({ id, part })));

  type T = { tuple: { id: number; part: string }; numberOfHeaders: number };
  return Object.fromEntries(
    allSortedTuples
      .reduce<T[]>((acc, tuple, index) => {
        const prev = acc[index - 1];
        const numberOfHeaders = prev
          ? tuple.part === prev.tuple.part
            ? prev.numberOfHeaders
            : prev.numberOfHeaders + 1
          : 1;

        return [...acc, { tuple, numberOfHeaders }];
      }, [])
      .map(
        ({ tuple, numberOfHeaders }, index) =>
          [tuple.id, HEADER_SIZE * numberOfHeaders + index * ROW_SIZE] as const
      )
  );
};

export const FooterTableList = React.memo(
  ({
    scrolledElement,
    layout,
    onFooterLayoutChanged,
    sortFooterColumns,
    onSortFooterColumnsChanged,
    onComplexLineItemsChanged,
    readOnly,
    currentMultivalue,
  }: {
    scrolledElement?: HTMLElement;
    layout: FooterLayout;
    onFooterLayoutChanged: (value: FooterLayout) => void;
    sortFooterColumns: SortFooterColumns;
    onSortFooterColumnsChanged: (value: SortFooterColumns) => void;
    onComplexLineItemsChanged: (cli: boolean) => void;
    readOnly: boolean;
    currentMultivalue: MultivalueDatapointDataST;
  }) => {
    const tupleIds = useMemo(
      () => currentMultivalue.children.map(c => c.id),
      [currentMultivalue.children]
    );

    const columnsMap = useSelector(columnsForMultivaluesSelector);

    const allColumns = columnsMap[currentMultivalue.schemaId];

    const allGrids = useSelector(findCurrentGrids);

    const cliFlagEnabled = useSelector(complexTablesEnabledOnOrganization);

    const complexLineItemsEnabled = useSelector(
      complexLineItemsEnabledSelector
    );

    const tupleIdToGrid = useMemo(
      () =>
        Object.fromEntries(
          allGrids.flatMap(g =>
            g.rows
              .map(r => r.tupleId)
              .filter(isNotNullOrUndefined)
              .map(tupleId => [tupleId, g])
          )
        ),
      [allGrids]
    );

    const indexesOrderedBySchema = useMemo(
      () => range(0, allColumns.length),
      [allColumns.length]
    );

    const tupleIdsByPart = useMemo(
      () =>
        layout === 'one-table'
          ? { all: tupleIds }
          : groupBy(
              tupleIds,
              id => tupleIdToGrid[id]?.page ?? 'manually-added'
            ),
      [layout, tupleIdToGrid, tupleIds]
    );

    const indexesOrderedByGrid = Object.fromEntries(
      allGrids.map(g => [g.page, getOrderedIndexesForGrid(g, allColumns)])
    );

    const { selection, onSelect, onDelete, onAddTuple } = useTupleActions(
      tupleIds,
      currentMultivalue,
      tupleIdsByPart
    );

    const { onBatchUpdateEvent, batchUpdateState } = useBatchUpdate(tupleIds);

    const inBatchUpdateState = !!batchUpdateState;

    useScrollTriggerInBatchUpdate({ scrolledElement, inBatchUpdateState });

    const { setTableBodyRef } = useDetectTupleUnderCursor({
      scrolledElement,
      tupleIdsByPart,
      onBatchUpdateEvent,
      inBatchUpdateState,
    });

    const showGhostRow = shouldShowGhostRow(tupleIds, complexLineItemsEnabled);

    const currentTuple = useSelector(getCurrentTuple);
    const currentTupleId = currentTuple?.id;

    const tuplePositions = useMemo(() => {
      const result =
        layout === 'one-table'
          ? Object.fromEntries(
              currentMultivalue.children.map((tuple, tupleIndex) =>
                simpleLayoutTopPositions(tuple, tupleIndex)
              )
            )
          : complexLayoutTopPositions(tupleIdsByPart);

      return result;
    }, [currentMultivalue.children, layout, tupleIdsByPart]);

    useEffect(() => {
      if (currentTupleId && scrolledElement) {
        const shouldScroll =
          scrolledElement.scrollTop >= tuplePositions[currentTupleId] ||
          scrolledElement.scrollTop + scrolledElement.clientHeight <=
            tuplePositions[currentTupleId];

        if (shouldScroll) {
          scrolledElement?.scroll({
            top: tuplePositions[currentTupleId] + 54,
          });
        }
      }
    }, [currentTupleId, scrolledElement, tuplePositions]);

    const { visibleItems, observer } = useFooterVirtualObserver(
      scrolledElement,
      getTableIdentifier
    );

    // AFI: Iteration order doesn't have to be guaranteed, it would be good to sort it
    return (
      <Stack
        direction="row"
        sx={{
          userSelect: batchUpdateState?.startAt ? 'none' : 'auto',
        }}
        style={{
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 24,
            height: 'calc(100% - 8px)', // 8px is the height of the horizontal scrollbar
            backgroundColor: theme => theme.palette.background.paper,
            zIndex: DEFAULT_STICKY_HEADER_Z_INDEX + 2,
          }}
        />
        <Stack
          sx={{
            cursor: batchUpdateState ? 'crosshair' : 'auto',
            flex: `${hasVisibleStretchedColumn(allColumns) ? 1 : 0} 0`,
            px: 3,
          }}
          style={{ margin: '0 auto' }}
        >
          <Stack
            direction="row"
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 500,
            }}
          >
            {!readOnly && (
              <FooterSelectionPanel
                itemsCount={selection.count}
                onClearSelected={() => onSelect({ type: 'deselect-all' })}
                onDeleteSelected={() => onDelete('selection')}
                onAddTuple={onAddTuple}
                footerLayout={layout}
              />
            )}
          </Stack>
          {tupleIds.length === 0 && !complexLineItemsEnabled ? (
            <Box width="400px" style={{ margin: '0 auto' }}>
              {!readOnly ? <EmptyFooter /> : null}
            </Box>
          ) : (
            <>
              {layout === 'one-table' && (
                <FooterTable
                  part="all"
                  layout={layout}
                  tupleIds={tupleIds}
                  tupleIdToGrid={tupleIdToGrid}
                  tableIndex={0}
                  selection={selection}
                  onSelect={onSelect}
                  onDelete={onDelete}
                  allColumns={allColumns}
                  orderedIndexes={
                    sortFooterColumns === 'queue-settings'
                      ? indexesOrderedBySchema
                      : indexesOrderedByGrid[
                          Object.keys(indexesOrderedByGrid).sort(
                            (a: string, b: string) => {
                              return a.localeCompare(b, undefined, {
                                numeric: true,
                              });
                            }
                          )[0]
                        ] ?? indexesOrderedBySchema
                  }
                  onBatchUpdateEvent={onBatchUpdateEvent}
                  batchUpdateState={batchUpdateState}
                  setTableBodyRef={setTableBodyRef}
                  readOnly={readOnly}
                  showGhostRow={showGhostRow}
                  scrolledElement={scrolledElement}
                />
              )}
              {layout === 'table-per-page' &&
                Object.entries(tupleIdsByPart).map(
                  ([part, tupleIds], tableIndex) => {
                    // When user switches between CLI and Grid, the grid might not exist
                    const gridForCurrentPartDoesntExists =
                      !indexesOrderedByGrid[part];

                    return (
                      <VirtualTableContainer
                        observer={observer}
                        tupleIdToGrid={tupleIdToGrid}
                        layout={layout}
                        isVisible={!!visibleItems[part]}
                        key={part}
                        part={
                          part === 'manually-added'
                            ? part
                            : String(tupleIdToGrid[tupleIds[0]].page)
                        }
                        tupleIds={tupleIds}
                        tableIndex={tableIndex}
                        selection={selection}
                        onSelect={onSelect}
                        onDelete={onDelete}
                        allColumns={allColumns}
                        orderedIndexes={
                          part === 'manually-added' ||
                          gridForCurrentPartDoesntExists ||
                          sortFooterColumns === 'queue-settings'
                            ? indexesOrderedBySchema
                            : indexesOrderedByGrid[part]
                        }
                        onBatchUpdateEvent={onBatchUpdateEvent}
                        batchUpdateState={batchUpdateState}
                        setTableBodyRef={setTableBodyRef}
                        readOnly={readOnly}
                        showGhostRow={showGhostRow}
                        scrolledElement={scrolledElement}
                      />
                    );
                  }
                )}
            </>
          )}
          {!(tupleIds.length === 0 && readOnly) && (
            <Stack direction="row" justifyContent="flex-end">
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  py: 3,
                  pl: 3,
                  position: 'sticky',
                  right: '24px',
                }}
              >
                {!complexLineItemsEnabled && !readOnly && <ExtractAllButton />}
                {!readOnly && !showGhostRow && <FooterAddLineButton />}
                {/* TODO: CLI-UX: remove completely once CLI feature is released */}
                {!cliFlagEnabled && (
                  <FooterMenuButton
                    layout={layout}
                    onFooterLayoutChanged={onFooterLayoutChanged}
                    sortFooterColumns={sortFooterColumns}
                    onSortFooterColumnsChanged={onSortFooterColumnsChanged}
                    onComplexLineItemsChanged={onComplexLineItemsChanged}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 8, // 8px is the width of the vertical scrollbar
            width: 24,
            height: 'calc(100% - 8px)', // 8px is the height of the horizontal scrollbar
            backgroundColor: theme => theme.palette.background.paper,
            zIndex: DEFAULT_STICKY_HEADER_Z_INDEX + 2,
          }}
        />
      </Stack>
    );
  }
);

FooterTableList.displayName = 'FooterTableList';
