import { Delete, RotateLeft, RotateRight } from '@rossum/ui/icons';
import { Stack, SvgIcon } from '@rossum/ui/material';
import { ContainedIconButton } from './ContainedIconButton';
import { DispatchEdit, Page } from './editState';
import { ReactComponent as DeleteCancel } from './icons/delete-cancel.svg';

export const EditedPageActions = ({
  page,
  dispatchEdit,
}: {
  page: Page;
  dispatchEdit: DispatchEdit;
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      data-cy="page-actions"
      sx={{
        opacity: 0,
        position: 'absolute',
        '&:hover': {
          opacity: 1,
        },
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ContainedIconButton
        onClick={e => {
          e.stopPropagation();
          dispatchEdit({
            type: 'ROTATE_PAGE',
            pageNumber: page.pageNumber,
            rotationDeg: page.rotationDeg - 90,
          });
        }}
        disabled={page.deleted}
        data-no-dnd="true"
        data-cy="document-edit-rotate-page-counter-clockwise-btn"
      >
        <RotateLeft fontSize="small" />
      </ContainedIconButton>
      <ContainedIconButton
        onClick={e => {
          e.stopPropagation();
          dispatchEdit({
            type: 'ROTATE_PAGE',
            pageNumber: page.pageNumber,
            rotationDeg: page.rotationDeg + 90,
          });
        }}
        disabled={page.deleted}
        data-no-dnd="true"
        data-cy="document-edit-rotate-page-clockwise-btn"
      >
        <RotateRight fontSize="small" />
      </ContainedIconButton>
      <ContainedIconButton
        onClick={e => {
          e.stopPropagation();
          dispatchEdit({
            type: 'DELETE_PAGE',
            pageNumber: page.pageNumber,
            deleted: !page.deleted,
          });
        }}
        data-no-dnd="true"
        data-cy="document-edit-delete-page-btn"
      >
        {page.deleted ? (
          <SvgIcon component={DeleteCancel} fontSize="small" />
        ) : (
          <Delete fontSize="small" />
        )}
      </ContainedIconButton>
    </Stack>
  );
};
