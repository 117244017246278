import { Queue } from '@rossum/api-client/queues';
import { SchemaField } from '@rossum/api-client/schemaFields';
import { AnnotationListTable, SchemaColumn } from '@rossum/api-client/shared';
import { useSetFetchedSchemaFields } from '../../../hooks/useFetchSchemaFields';
import {
  DEFAULT_COLUMN_WIDTH,
  getColumnName,
  uniqColumns,
} from '../../helpers';
import { usePatchTableConfig } from '../../hooks/usePatchTableConfig';

type Props = AnnotationListTable & { activeQueue: Queue | null };

export const useCustomizeColumns = ({ columns, activeQueue }: Props) => {
  const { updateColumns } = usePatchTableConfig({ activeQueue });

  const setFetchedSchemaFields = useSetFetchedSchemaFields();

  const handleAddColumn = (selectedSchemaField: SchemaField) => {
    const asSchemaColumn: SchemaColumn = {
      columnType: 'schema',
      dataType: selectedSchemaField.type ?? 'string',
      schemaId: selectedSchemaField.schemaId ?? '',
      width: DEFAULT_COLUMN_WIDTH,
      visible: true,
    };

    const uniqueColumns = uniqColumns([asSchemaColumn, ...columns]);

    // this runs before the update because otherwise for a brief second we see the 'schema_id' on the table header instead of 'label'
    // e.g. total_amount instead of Total Amount
    setFetchedSchemaFields(response => {
      if (!response) return response;

      return {
        ...response,
        results: [...response.results, selectedSchemaField],
      };
    });

    updateColumns(uniqueColumns);
  };

  const handleRemoveColumn = (selectedColumn: SchemaColumn) => {
    const filteredColumns = columns.filter(
      c => getColumnName(c) !== selectedColumn.schemaId
    );
    updateColumns(filteredColumns);
  };

  return { handleAddColumn, handleRemoveColumn };
};
