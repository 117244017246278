import { endpoints } from '@rossum/api-client';
import { GenericEngineListQuery } from '@rossum/api-client/genericEngines';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { getNextPageFromUrl } from '../helpers/getNextPageFromUrl';
import { genericEngineKeys } from '../keys';

export const useInfiniteGenericEngines = (query: GenericEngineListQuery) =>
  useInfiniteQuery({
    queryKey: genericEngineKeys.list(query),
    queryFn: ({ pageParam = 1 }) =>
      api.request(
        endpoints.genericEngines.list({
          ...query,
          page: pageParam,
        })
      ),
    getNextPageParam: data => getNextPageFromUrl(data.pagination.next),
  });
