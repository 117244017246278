import { clamp } from 'lodash';
import {
  Point2D,
  Rectangle2D,
  Vector2D,
} from '../document-canvas/utils/geometry';
import { CanvasState, matrixToState, stateToMatrix } from './helpers';

export type Dimensions = {
  width: number;
  height: number;
};

/**
 * @param value Coordinate of the translation matrix.
 * @param availableSpace How much space do I have around.
 */
export const clampInAvailableSpace = (
  value: number,
  availableSpace: number
) => {
  return availableSpace > 0
    ? clamp(value, -availableSpace, 0) // Zoomed into the page - restrict in negative values.
    : -availableSpace / 2; // Zoomed out - center the page.
};

/** Fits `position` (used in `translateX/Y`) within canvas boundaries (clamping) */
export const restrictToCanvasBounds = (
  position: number,
  canvasSize: number,
  viewportSize: number,
  scaleFactor: number,
  zoomLevel: number
) => {
  const scaledViewportSize = viewportSize * scaleFactor;

  const availableSpace = (canvasSize - scaledViewportSize) * zoomLevel;

  return clampInAvailableSpace(position, availableSpace);
};

export const findRectangleCenter = (rectangle: Rectangle2D) => ({
  x: rectangle.x + rectangle.width / 2,
  y: rectangle.y + rectangle.height / 2,
});

type FindViewportCoordinatesParams = {
  originalScaleFactor: number;
  viewportDimensions: Dimensions;
};

export const findViewportCoordinates =
  ({
    originalScaleFactor,
    viewportDimensions,
  }: FindViewportCoordinatesParams) =>
  (canvasState: CanvasState): Rectangle2D => ({
    x: -(canvasState.translateX / canvasState.zoomLevel),
    y: -(canvasState.translateY / canvasState.zoomLevel),
    width: viewportDimensions.width * originalScaleFactor,
    height: viewportDimensions.height * originalScaleFactor,
  });

export const isRectangleInside = (box: Rectangle2D) => (wrapper: Rectangle2D) =>
  box.x >= wrapper.x &&
  box.y >= wrapper.y &&
  box.x + box.width <= wrapper.x + wrapper.width &&
  box.y + box.height <= wrapper.y + wrapper.height;

export const isRectangleOverlapping =
  (box: Rectangle2D) => (wrapper: Rectangle2D) =>
    box.x + box.width >= wrapper.x &&
    box.y + box.height >= wrapper.y &&
    box.x <= wrapper.x + wrapper.width &&
    box.y <= wrapper.y + wrapper.height;

export const translateBy =
  (delta: Vector2D) =>
  (previousState: CanvasState): CanvasState =>
    matrixToState(stateToMatrix(previousState).translate(-delta.x, -delta.y));

export const zoomBy =
  (delta: number, origin: Point2D) => (previousState: CanvasState) => {
    const clampedZoomFactor =
      clamp(previousState.zoomLevel * (1 + delta), 0.5, 5) /
      previousState.zoomLevel;

    return matrixToState(
      stateToMatrix(previousState).scale(
        clampedZoomFactor,
        clampedZoomFactor,
        1,
        origin.x,
        origin.y,
        0
      )
    );
  };

type CanvasContext = {
  originalState: CanvasState;
  originalScaleFactor: number;
  canvasDimensions: Dimensions;
  viewportDimensions: Dimensions;
};

/** Given current `CanvasContext`, ensure `CanvasState` doesn't overflow canvas bounds */
export const fitWithinCanvas =
  ({
    originalState,
    originalScaleFactor,
    canvasDimensions,
    viewportDimensions,
  }: CanvasContext) =>
  ({ translateX, translateY, zoomLevel }: CanvasState): CanvasState => {
    const scaleFactor =
      originalScaleFactor * (originalState.zoomLevel / zoomLevel);

    return {
      translateX: restrictToCanvasBounds(
        translateX,
        canvasDimensions.width,
        viewportDimensions.width,
        scaleFactor,
        zoomLevel
      ),
      translateY: restrictToCanvasBounds(
        translateY,
        canvasDimensions.height,
        viewportDimensions.height,
        scaleFactor,
        zoomLevel
      ),
      // We are not clamping zoom level - zoom functions have to make sure not to zoom outside of boundaries.
      zoomLevel,
    };
  };

export const getPointCoordinates = (
  pageNumber: number,
  point: Point2D,
  pageDimensions: {
    pageNumber: number;
    dimensions: Rectangle2D;
  }[]
): Point2D | undefined => {
  const targetPageDimensions = pageDimensions.find(
    p => p.pageNumber === pageNumber
  );

  return targetPageDimensions
    ? {
        x: point.x + targetPageDimensions.dimensions.x,
        y: point.y + targetPageDimensions.dimensions.y,
      }
    : undefined;
};

export const getBoxCoordinates = (
  pageNumber: number,
  rectangle: Rectangle2D,
  pageDimensions: {
    pageNumber: number;
    dimensions: Rectangle2D;
  }[]
): Rectangle2D | undefined => {
  const point = getPointCoordinates(
    pageNumber,
    { x: rectangle.x, y: rectangle.y },
    pageDimensions
  );

  return point
    ? {
        height: rectangle.height,
        width: rectangle.width,
        x: point.x,
        y: point.y,
      }
    : undefined;
};

type PageRectangle = {
  pageNumber: number;
  dimensions: Rectangle2D;
};

export const getPageNumberByPoint = (
  point: Point2D,
  pageDimensions: PageRectangle[]
): PageRectangle | undefined => {
  return pageDimensions.find(p =>
    // TODO: This could be written better.
    isRectangleInside({ x: point.x, y: point.y, width: 0, height: 0 })(
      p.dimensions
    )
  );
};
