import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { datapointsWithOperationsSelector } from '../../../redux/modules/datapoints/suggestedOperations/selector';
import { rectangleFromCoordinates } from '../document-canvas/utils/geometry';
import { UseCanvasDimensions } from './useCanvasDimensions';

type UseSelectBoundingBoxesParams = {
  dimensions: UseCanvasDimensions;
};

export type CanvasBoundingBoxes = ReturnType<typeof useCanvasBoundingBoxes>;

export const useCanvasBoundingBoxes = ({
  dimensions,
}: UseSelectBoundingBoxesParams) => {
  const datapointsWithOperations = useSelector(
    datapointsWithOperationsSelector
  );

  return useMemo(
    () =>
      datapointsWithOperations
        .map(box => {
          const page = dimensions.pages.find(
            page => box.page === page.pageNumber
          );
          const boxRectangle = rectangleFromCoordinates(box.position);
          return page
            ? {
                box,
                boxRectangle: {
                  x: page.dimensions.x + boxRectangle.x,
                  y: page.dimensions.y + boxRectangle.y,
                  height: boxRectangle.height,
                  width: boxRectangle.width,
                },
              }
            : undefined;
        })
        .filter(R.isDefined),
    [datapointsWithOperations, dimensions.pages]
  );
};
