import { Line as LineGraph } from 'recharts';
import styles from '../styles.module.sass';

type LineProps = {
  key: string;
  color: string;
  dotClassName: string;
};

const Line = ({ key, color, dotClassName }: LineProps) => (
  <LineGraph
    type="linear"
    dataKey={key}
    key={key}
    stroke={color}
    isAnimationActive={false}
    dot={{ className: dotClassName, r: 4 }}
    activeDot={{ r: 6, className: styles.ActiveDot }}
  />
);

export default Line;
