import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { WorkflowStep } from '../models';
import { workflowStepSchema } from '../models/workflowStep.schema';
import { workflowStepsListQuerySchema } from './list.schema';

export type WorkflowStepsListQuery = Partial<{
  workflow: ID;
  id: ID | ID[];
}> &
  PaginationQuery;

export const list = (query: WorkflowStepsListQuery = {}) => {
  return {
    endpoint: `/workflow_steps`,
    method: 'GET',
    responseSchema: listResponse(workflowStepSchema),
    query,
    querySchema: workflowStepsListQuerySchema,
  } as const;
};
