import {
  ExtensionOutlined,
  FitScreenRounded,
  FormatListBulletedRounded,
  GridOnRounded,
  HelpOutline,
  LooksOneOutlined,
  ShortTextOutlined,
} from '@rossum/ui/icons';
import { SvgIconProps } from '@rossum/ui/material';
import { ButtonIcon } from './ButtonIcon';
import { Formula } from './Formula';

const fieldsIconsMap = {
  captured: FitScreenRounded,
  data: ExtensionOutlined,
  manual: ShortTextOutlined,
  unset: HelpOutline,
  formula: Formula,
  button: ButtonIcon,
  lineItemSimpleValue: LooksOneOutlined,
  lineItemButton: ButtonIcon,
  simpleValue: LooksOneOutlined,
  multivalue: FormatListBulletedRounded,
  lineItems: GridOnRounded,
} as const;

export type SchemaFieldIconType = keyof typeof fieldsIconsMap;

export const SchemaFieldIcon = ({
  type,
  ...rest
}: { type: keyof typeof fieldsIconsMap } & SvgIconProps) => {
  const Icon = fieldsIconsMap[type];
  return <Icon {...rest} />;
};
