import { endpoints } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';

export const useSelectDatapointRectangle = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { mutate: validate } = useMutation({
    mutationFn: (payload: {
      annotationId: number;
      actions: ('user_update' | 'updated')[];
      updatedDatapointIds: number[];
    }) => {
      const { annotationId, ...restPayload } = payload;
      return apiClient.request(
        endpoints.annotations.validate(annotationId, restPayload)
      );
    },
    onSettled: (_1, _2, { annotationId }) => {
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'content',
        annotationId,
      ]);
    },
  });

  return useMutation({
    mutationFn: (payload: {
      page: string;
      rectangle: [number, number, number, number];
      activeDatapoint: number;
      annotationId: number;
    }) => {
      const { annotationId, activeDatapoint, ...restPayload } = payload;
      return apiClient.request(
        endpoints.annotations.contentSelect(
          annotationId,
          activeDatapoint,
          restPayload
        )
      );
    },
    onSettled: (_1, _2, { activeDatapoint, annotationId }) => {
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'content',
        annotationId,
      ]);
      validate({
        annotationId,
        actions: ['user_update', 'updated'],
        updatedDatapointIds: [activeDatapoint],
      });
    },
  });
};
