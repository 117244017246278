import { useState } from 'react';

export type TemporaryMessageT = { key: 'saved' | 'reverted' } | null;

export const useTemporaryMessage = () => {
  const [temporaryMessage, setTemporaryMessage] =
    useState<TemporaryMessageT>(null);

  return {
    temporaryMessage,
    setTemporarySaved: () => setTemporaryMessage({ key: 'saved' }),
    setTemporaryReverted: () => setTemporaryMessage({ key: 'reverted' }),
  };
};
