import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useOrganizationLimits } from '../../../../business/organization/useOrganizationLimits';
import { organizationSelector } from '../../../../redux/modules/organization/selectors';
import { State } from '../../../../types/state';
import { TrialLimitedBox } from '../../../../ui/trial-limited-box/TrialLimitedBox';

export const TrialLimited = () => {
  const intl = useIntl();
  const organizationId = useSelector(
    (state: State) => organizationSelector(state).id
  );
  const query = useOrganizationLimits(organizationId);
  const { data } = query;
  const totalLimit = data?.emailLimits?.emailTotalLimit ?? Infinity;
  const totalCount = data?.emailLimits?.countTotal ?? 0;
  const countLimitDiff = totalLimit - totalCount;

  return (
    <TrialLimitedBox elevation={14}>
      {totalCount >= totalLimit &&
        intl.formatMessage(
          {
            id: 'containers.settings.queues.email.trialLimited.limitReached.text',
          },
          {
            count: countLimitDiff,
          }
        )}
      {countLimitDiff === 1 &&
        intl.formatMessage(
          {
            id: 'containers.settings.queues.email.trialLimited.limitReachedSoon.text',
          },
          {
            count: countLimitDiff,
          }
        )}
      {totalCount < totalLimit &&
        countLimitDiff !== 1 &&
        totalCount !== 0 &&
        intl.formatMessage(
          {
            id: 'containers.settings.queues.email.trialLimited.limitOk.text',
          },
          {
            count: countLimitDiff,
          }
        )}
      {totalCount === 0 &&
        countLimitDiff !== 0 &&
        intl.formatMessage(
          {
            id: 'containers.settings.queues.email.trialLimited.noEmailsSendYet.text',
          },
          {
            count: countLimitDiff,
          }
        )}
    </TrialLimitedBox>
  );
};
