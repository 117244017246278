import { getHistory } from '../../billing/endpoints/getHistory';
import { getHistoryExport } from '../../billing/endpoints/getHistoryExport';
import { getStats } from '../../billing/endpoints/getStats';
import { getStatsExport } from '../../billing/endpoints/getStatsExport';
import { create } from './create';
import { get } from './get';
import { limits } from './limits';
import { patch } from './patch';

export const organization = {
  limits,
  create,
  patch,
  get,
  billingStats: {
    get: getStats('organizations'),
    export: getStatsExport('organizations'),
  },
  billingHistory: {
    get: getHistory('organizations'),
    export: getHistoryExport('organizations'),
  },
};
