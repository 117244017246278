import { formLabelClasses, Stack, textFieldClasses } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const FORM_FIELD_WIDTH = 300;

type Props = {
  children: ReactNode;
};

const FormRow = ({ children }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{
        [`.${textFieldClasses.root}`]: {
          width: FORM_FIELD_WIDTH,
        },
        [`.${formLabelClasses.root}`]: {
          mb: 0,
          color: 'text.primary',
          fontSize: '15px',
          // tried to use alignSelf: 'center' but since
          // the height changes with displayed error message
          // it doesn't look good
          mt: 1.25,
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default FormRow;
