import { SvgIcon, SvgIconProps } from '@rossum/ui/material';

type AddRowBelowProps = SvgIconProps;

export const AddRowBelow = (props: AddRowBelowProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V9.16667C17.5 10.0871 16.7538 10.8333 15.8333 10.8333H4.16667C3.24619 10.8333 2.5 10.0871 2.5 9.16667V4.16667ZM4.16667 4.16667H15.8333V5.83333H4.16667V4.16667ZM15.8333 7.5H4.16667L4.16667 9.16667H15.8333V7.5Z"
      />
      <path d="M10 12.5C10.4602 12.5 10.8333 12.8731 10.8333 13.3333V15H12.5C12.9602 15 13.3333 15.3731 13.3333 15.8333C13.3333 16.2936 12.9602 16.6667 12.5 16.6667H10.8333V18.3333C10.8333 18.7936 10.4602 19.1667 10 19.1667C9.53976 19.1667 9.16667 18.7936 9.16667 18.3333V16.6667H7.5C7.03976 16.6667 6.66667 16.2936 6.66667 15.8333C6.66667 15.3731 7.03976 15 7.5 15H9.16667V13.3333C9.16667 12.8731 9.53976 12.5 10 12.5Z" />
    </SvgIcon>
  );
};

AddRowBelow.muiName = 'MuiAddRowBelow';
