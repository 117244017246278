import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DeleteRecommendation } from '../models';
import { deleteRecommendationSchema } from '../models/deleteRecommendation.schema';
import { createDeleteRecommendationPayloadSchema } from './create.schema';

export type CreateDeleteRecommendationPayload = {
  queue: Url;
  enabled: boolean;
  triggers: Url[];
};

export const create = (payload: CreateDeleteRecommendationPayload) => {
  return {
    endpoint: `/delete_recommendations`,
    method: 'POST',
    responseSchema: deleteRecommendationSchema,
    payloadSchema: createDeleteRecommendationPayloadSchema,
    payload,
  } as const;
};
