// Generated by ts-to-zod
import { z } from 'zod';

export const dedicatedEngineGetQuerySchema = z
  .object({
    fields: z.array(
      z.union([
        z.literal('id'),
        z.literal('name'),
        z.literal('url'),
        z.literal('status'),
        z.literal('schema'),
        z.literal('description'),
      ])
    ),
  })
  .partial();
