import * as R from 'remeda';
import { isSimpleDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import {
  AnyDatapointDataST,
  SimpleDatapointDataST,
} from '../../../../../types/datapoints';

export const createDeletePayload = (
  allDatapoints: AnyDatapointDataST[],
  selectedDatapoints: SimpleDatapointDataST[]
) => {
  const selectedIds = new Set(selectedDatapoints.map(dp => dp.id));
  const parentIds = new Set(selectedDatapoints.map(dp => dp.meta.parentId));
  const parentTuples = allDatapoints.filter(
    dp => dp.category === 'tuple' && parentIds.has(dp.id)
  );

  const idToIndexMap = new Map(allDatapoints.map(dp => [dp.id, dp.meta.index]));

  const parentTuplesIds = new Set(parentTuples.map(dp => dp.id));
  const relevantChildrenByTuple = R.groupBy(
    allDatapoints.filter(
      dp =>
        // Simple datapoints
        isSimpleDatapoint(dp) &&
        // With value - we ignore empty datapoints
        dp.content?.value &&
        // formulas and extensions can add value without position & page - we ignore such datapoints too
        dp.content?.position &&
        dp.content?.page &&
        // Children of some of the selected tuples
        dp.meta.parentId &&
        parentTuplesIds.has(dp.meta.parentId) &&
        dp.schema?.hidden !== true
    ),
    dp => dp.meta.parentId
  );

  const tuplesToDelete = parentTuples.reduce<AnyDatapointDataST[]>(
    (acc, tuple) => {
      const children = relevantChildrenByTuple[tuple.id] ?? [];
      const isFullySelected = children.length
        ? children.every(child => selectedIds.has(child.id))
        : false;

      return isFullySelected ? [...acc, tuple] : acc;
    },
    []
  );
  const deletedTupleIds = new Set(tuplesToDelete.map(tuple => tuple.id));

  return {
    tuplesToDelete: tuplesToDelete.map(dp => {
      const parentIndex = dp.meta.parentId
        ? idToIndexMap.get(dp.meta.parentId)
        : null;

      return {
        id: dp.id,
        index: dp.meta.index,
        parentIndex: R.isNonNullish(parentIndex) ? parentIndex : null,
      };
    }),
    datapointsToReset: selectedDatapoints
      .filter(dp => !dp.meta.parentId || !deletedTupleIds.has(dp.meta.parentId))
      .map(dp => ({
        id: dp.id,
        index: dp.meta.index,
      })),
  };
};
