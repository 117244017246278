import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { GenericEngineListQuery } from '@rossum/api-client/genericEngines';
import { GenericEngine } from '@rossum/api-client/genericEngines';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_GENERIC_ENGINES_UNPAGINATED = 'generic-engines-unpaginated';

export const useUnpaginatedGenericEngines = <TReturnedData = GenericEngine[]>(
  query: GenericEngineListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      GenericEngine[],
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_GENERIC_ENGINES_UNPAGINATED, GenericEngineListQuery]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_GENERIC_ENGINES_UNPAGINATED, query],

    queryFn: ({ queryKey }) =>
      api.unpaginatedRequest(endpoints.genericEngines.list)(queryKey[1]),

    ...queryOptions,
  });
