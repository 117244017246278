import { Label } from '@rossum/api-client/labels';
import { Chip, Stack, Tooltip, Typography } from '@rossum/ui/material';
import TruncatedLabelChip from './TruncatedLabelChip';

type Props = { labels: Array<Label>; maxVisibleLabelCount?: number };

const NUMBER_OF_VISIBLE_LABELS = 3;

const LabelsColumn = ({ labels, maxVisibleLabelCount }: Props) => {
  const numberOfVisibleLabels =
    maxVisibleLabelCount ?? NUMBER_OF_VISIBLE_LABELS;

  const head = labels.slice(0, numberOfVisibleLabels);
  const tail = labels.slice(numberOfVisibleLabels);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {head.map(({ name, url }) => (
        <TruncatedLabelChip name={name} key={url} />
      ))}
      {tail.length > 0 && (
        <Tooltip
          placement="top"
          title={tail.map(({ name, url }) => (
            <Typography variant="body2" key={url}>
              {name}
            </Typography>
          ))}
          enterDelay={600}
        >
          <Chip size="small" variant="outlined" label={`+${tail.length}`} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default LabelsColumn;
