import { axios } from '@rossum/api-client';
import { getBase64Image } from '@rossum/document-canvas';
import { useQuery } from '@tanstack/react-query';
import { getAuthToken } from './useApiClient';

export const usePagePreview = ({ contentUrl }: { contentUrl: string }) =>
  useQuery({
    queryKey: ['mini-validation-screen', 'page-preview', contentUrl],

    queryFn: async () => {
      const response = await axios.get<Blob>(`${contentUrl}`, {
        headers: { Authorization: `Token ${getAuthToken()}` },
        responseType: 'blob',
        method: 'GET',
      });

      const imageUrl = URL.createObjectURL(response.data);

      // We use base64 so that we see the image in smartlook if enabled.
      return getBase64Image(imageUrl).catch(() => imageUrl);
    },
    staleTime: 3600 * 1000,
  });
