import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { StatisticsFilterFormState } from '../components/StatisticsFilter';
import { BillingEntity, getBillingEndpoint } from './useBillingEntity';
import { QUERY_KEY_USAGE_VALIDITY } from './useUsageValidity';

const QUERY_KEY_BILLING_STATS_TIMELINE = 'billing-stats-timeline';

export type PageStatisticsGranularity = 'week' | 'month';

export const usePageStatisticsTimeline = (
  billingEntity: BillingEntity,
  filters: Omit<StatisticsFilterFormState, 'unit'>,
  granularity: PageStatisticsGranularity
) => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [
      QUERY_KEY_BILLING_STATS_TIMELINE,
      billingEntity,
      filters,
      granularity,
    ] as const,

    queryFn: ({ queryKey: [, billingEntity, filters, granularity] }) => {
      const organizations = filters.organizationUrl
        ? [filters.organizationUrl]
        : undefined;

      const { id, endpoint } = getBillingEndpoint(billingEntity);
      return api.request(
        endpoint.billingStats.get(
          id,
          {
            filters: {
              beginDate: filters.dateRange.from,
              endDate: filters.dateRange.to,
              queues: filters.queues
                ? [
                    ...filters.queues.urls,
                    ...(filters.queues.deleted ? [null] : []),
                  ]
                : undefined,
              organizations,
            },
            groupBy: [granularity],
          },
          {
            page: 1,
            pageSize: 100,
          }
        )
      );
    },
    onSuccess: ({ updatedAt }) =>
      queryClient.setQueryData([QUERY_KEY_USAGE_VALIDITY], updatedAt),
  });
};
