// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const workflowUrlSchema = urlSchema;

export const workflowSchema = z.object({
  id: idSchema,
  url: workflowUrlSchema,
  organization: urlSchema,
  name: z.string(),
  relevantSchemaIds: z.array(z.string()),
  condition: z.unknown().optional(),
});
