export const truncateEmail = (email: string) => {
  const atIndex = email.indexOf('@');

  if (atIndex > 20) {
    const truncatedUsername = `${email.slice(0, 10)}...${email.slice(
      atIndex - 10,
      atIndex
    )}`;
    const truncatedEmail = truncatedUsername + email.slice(atIndex);
    return { truncatedEmail, showTooltip: true };
  }
  const truncatedEmail = email;
  return { truncatedEmail, showTooltip: false };
};

export const isImageOrPdf = (mimeType: string) => {
  // checks if string is 'image/*' or 'application/pdf'
  const regex = /^(image\/.+|application\/pdf)$/;
  return regex.test(mimeType);
};
