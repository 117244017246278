import { ActionType, createAction } from 'typesafe-actions';
import { Organization } from '../../../types/organization';
import {
  FetchOrganizationAdminsPayload,
  FetchOrganizationGroupOrganizationsMeta,
  FetchOrganizationGroupOrganizationsPayload,
  SetOrganizationColor,
  SetOrganizationFont,
} from './types';

export const fetchOrganizationFulfilled = createAction(
  'FETCH_ORGANIZATION_FULFILLED',
  (payload: Organization) => payload
)<Organization>();

export const fetchOrganizationAdminsFulfilled = createAction(
  'FETCH_ORGANIZATION_ADMINS_FULFILLED',
  (payload: FetchOrganizationAdminsPayload) => payload
)<FetchOrganizationAdminsPayload>();

export const fetchOrganizationGroupOrganizations = createAction(
  'FETCH_ORGANIZATION_GROUP_ORGANIZATIONS',
  undefined,
  ({
    url,
  }: Partial<FetchOrganizationGroupOrganizationsMeta> | undefined = {}) => ({
    url,
  })
)<undefined, FetchOrganizationGroupOrganizationsMeta>();

export const fetchOrganizationGroupOrganizationsFulfilled = createAction(
  'FETCH_ORGANIZATION_GROUP_ORGANIZATIONS_FULFILLED',
  (payload: FetchOrganizationGroupOrganizationsPayload) => payload
)<FetchOrganizationGroupOrganizationsPayload>();

export const updateOrganization = createAction(
  'UPDATE_ORGANIZATION',
  (payload: Partial<Organization>) => payload
)<Organization>();

export const updateOrganizationFulfilled = createAction(
  'UPDATE_ORGANIZATION_FULFILLED',
  (payload: Organization) => payload
)<Organization>();

export const setOrganizationFont = createAction(
  'SET_ORGANIZATION_FONT',
  (font: string) => ({
    font,
  })
)<SetOrganizationFont>();

export const setOrganizationColor = createAction(
  'SET_ORGANIZATION_COLOR',
  (color: string) => ({
    color,
  })
)<SetOrganizationColor>();

export const setOrganizationRadiuses = createAction(
  'SET_ORGANIZATION_RADIUSES'
)<void>();

export type OrganizationActions = ActionType<
  | typeof fetchOrganizationFulfilled
  | typeof fetchOrganizationAdminsFulfilled
  | typeof fetchOrganizationGroupOrganizations
  | typeof fetchOrganizationGroupOrganizationsFulfilled
  | typeof updateOrganization
  | typeof updateOrganizationFulfilled
  | typeof setOrganizationFont
>;
