import { FormattedMessage } from 'react-intl';
import HelpPanel from '../HelpPanel';
import styles from './styles.module.sass';

const MissingToken = () => (
  <div className={styles.MissingToken}>
    <h1>
      <FormattedMessage id="components.missingtoken.title" />
    </h1>
    <p>
      <FormattedMessage id="components.missingtoken.text" />
    </p>
    <HelpPanel />
  </div>
);

export default MissingToken;
