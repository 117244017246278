import {
  FlagProvider,
  LocalStorageProvider,
  UnleashClient,
} from '@unleash/proxy-client-react';
import React from 'react';
import {
  apiUrl,
  appUrl,
  isPathBasedEmbeddedMode,
  unleashClientKey,
} from '../constants/config';
import { exposeInterfaceForDevTools } from './devTools';
import featureFlags from './featureFlags.json';
import { getUnleashUrl } from './getUnleashUrl';
import SessionStorageProvider from './sessionStorageProvider';

export type UnleashFlagName = keyof typeof featureFlags;

const unleashConfig = {
  url: getUnleashUrl(appUrl, apiUrl),
  clientKey: unleashClientKey || 'no-client-key',
  appName: 'elis-frontend',
  disableRefresh: false,
  disableMetrics: true,
  refreshInterval: 60 * 60 * 24,
  storageProvider: isPathBasedEmbeddedMode()
    ? new SessionStorageProvider()
    : new LocalStorageProvider(),
};

export const unleashClient = new UnleashClient(unleashConfig);
exposeInterfaceForDevTools(unleashClient);

type Props = {
  children: React.ReactNode;
};

const UnleashProvider = ({ children }: Props) => {
  return (
    <FlagProvider
      unleashClient={unleashClient}
      startClient={unleashConfig.clientKey !== 'no-client-key'}
    >
      {children}
    </FlagProvider>
  );
};

export default UnleashProvider;
