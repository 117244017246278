import { Url } from '@rossum/api-client';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Control, Controller, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';
import { BillingUnit } from '../helpers';
import { QueueFilter } from './BillingQueuePicker';
import ControlledDatepicker from './ControlledDatepicker';
import ControlledQueuePicker from './ControlledQueuePicker';

type UnitOptions = Array<{ value: BillingUnit; labelId: LocalizationKeys }>;

const unitOptions: UnitOptions = [
  {
    value: 'pages',
    labelId: 'containers.billing.usage.units.pages',
  },
  {
    value: 'documents',
    labelId: 'containers.billing.usage.units.documents',
  },
];

export type DatepickerDateRange = {
  from: string;
  to: string;
};

export type StatisticsFilterFormState = {
  dateRange: DatepickerDateRange;
  organizationUrl: Url | undefined;
  queues: QueueFilter | undefined;
  unit: BillingUnit;
};

type StatisticsFilterProps = {
  control: Control<StatisticsFilterFormState>;
};

const StatisticsFilter = ({ control }: StatisticsFilterProps) => {
  const intl = useIntl();

  const {
    field: { value: organizationUrl },
  } = useController({ control, name: 'organizationUrl' });

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap={1.5} alignItems="end">
        <ControlledDatepicker
          ControllerProps={{ control, name: 'dateRange' }}
        />
        <ControlledQueuePicker
          ControllerProps={{ control, name: 'queues' }}
          organizationUrl={organizationUrl}
        />
      </Stack>
      <Controller
        control={control}
        name="unit"
        render={({ field }) => (
          <RadioGroup row {...field}>
            {unitOptions.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio size="small" />}
                sx={{ ml: 2, mr: 0, mb: 0 }}
                label={
                  <Typography variant="body2">
                    {intl.formatMessage({ id: option.labelId })}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        )}
      />
    </Stack>
  );
};

export default StatisticsFilter;
