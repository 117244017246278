import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { typeSafeObjectEntries } from '../../../lib/fromEntries';
import { resolvedFeaturesSelector } from '../selectors';
import { getRateLimitFeatureMap } from '../utils';

export const usePricingBannerWarning = () => {
  const intl = useIntl();

  const resolvedFeatures = useSelector(resolvedFeaturesSelector);

  if (!resolvedFeatures) return null;

  const pricingRateLimitMap = getRateLimitFeatureMap(resolvedFeatures);

  const usageEntries = typeSafeObjectEntries(pricingRateLimitMap);

  const exceededUsages = usageEntries.filter(
    ([_, { hasExceededLimit }]) => hasExceededLimit
  );
  const translationKey =
    exceededUsages.length > 1 ? 'generic' : exceededUsages[0]?.[0] ?? null;

  return translationKey
    ? intl.formatMessage({
        id: `features.pricing.banner.text.${translationKey}`,
      })
    : null;
};
