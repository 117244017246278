import { ListItem, ListItemText, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

const ITEM_MAX_WIDTH = 300;

type TaskProgressProps = {
  primaryLabel: ReactNode;
  progressLabel: string;
  progressIcon?: ReactNode;
  secondaryLabel?: ReactNode;
};

export const TaskProgress = ({
  primaryLabel,
  secondaryLabel,
  progressLabel,
  progressIcon,
}: TaskProgressProps) => {
  return (
    <ListItem>
      <ListItemText
        primary={primaryLabel}
        primaryTypographyProps={{
          variant: 'subtitle2',
          fontWeight: 700,
          maxWidth: ITEM_MAX_WIDTH,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        secondary={secondaryLabel}
        secondaryTypographyProps={{
          variant: 'caption',
          maxWidth: ITEM_MAX_WIDTH,
        }}
      />
      <ListItemText
        primary={progressLabel}
        primaryTypographyProps={{
          variant: 'body2',
        }}
        sx={{ textAlign: 'end', ml: 2 }}
      />
      <Stack sx={{ ml: 1 }}>{progressIcon}</Stack>
    </ListItem>
  );
};
