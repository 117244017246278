import { endpoints } from '@rossum/api-client';
import { get, includes, invoke } from 'lodash';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import {
  resolveResponse,
  rossumRpcMessageType,
  RPCMessage,
} from '../../../containers/Extensions/containers/ConfigApp/hooks/postMessagesHelpers';
import { api } from '../../../lib/apiClient';
import { EpicDependencies } from '../../../lib/apiTypes';
import { State } from '../../../types/state';
import { isFeatureFlagEnabled } from '../../../unleash/useFeatureFlag';
import { DatapointsFulfilledPayload } from '../datapoints/types';
import { isActionOf } from '../utils';
import { closePopup, openPopup, PopupActions } from './actions';
import { Token } from './classes/Token';

type HandlerProps = {
  session: Window | null;
  popupUrl: string;
  trusted: boolean;
  annotation: { url: string };
  resolve: () => void;
} & EpicDependencies;

type FINISH_MESSAGE = { type: 'FINISH' };
type GET_DATAPOINTS_MESSAGE = { type: 'GET_DATAPOINTS' };
type GET_ANNOTATION_MESSAGE = { type: 'GET_ANNOTATION' };
type UPDATE_DATAPOINT_MESSAGE = {
  type: 'UPDATE_DATAPOINT';
  id: number | string;
  value: string;
};
type DELETE_DATAPOINT_CHILDREN_MESSAGE = {
  type: 'DELETE_DATAPOINT_CHILDREN';
  parentId: number | string;
};
type CREATE_DATAPOINT_CHILD_MESSAGE = {
  type: 'CREATE_DATAPOINT_CHILD';
  parentId: number | string;
};
type IncomingMessages =
  | RPCMessage
  | GET_DATAPOINTS_MESSAGE
  | UPDATE_DATAPOINT_MESSAGE
  | GET_ANNOTATION_MESSAGE
  | CREATE_DATAPOINT_CHILD_MESSAGE
  | DELETE_DATAPOINT_CHILDREN_MESSAGE
  | FINISH_MESSAGE;

const createHandler =
  ({
    session,
    popupUrl,
    trusted,
    annotation,
    authGetJSON$,
    authPatch$,
    authPost$,
    authDelete$,
    resolve,
  }: HandlerProps) =>
  ({ data, origin, source }: MessageEvent<IncomingMessages>) => {
    if (!source || !source.postMessage) return;

    if (
      // This ensures that we will respond only to the popup that sent the message
      // Otherwise, with multiple popups open, we would send a message to all of them
      session === source &&
      (includes(popupUrl, origin) || origin === window.origin)
    ) {
      switch (data.type) {
        case rossumRpcMessageType: {
          const createMessage = (
            response: unknown,
            config = { type: 'info' }
          ) =>
            source.postMessage(
              {
                type: rossumRpcMessageType,
                callId: data?.callId,
                methodResponse: {
                  [config.type === 'error' ? 'error' : 'value']: response,
                },
              },
              origin
            );

          const reportError = (e: unknown) =>
            createMessage(
              {
                reason: `${e}`,
                ...(get(e, ['data']) && { response: get(e, ['data']) }),
                ...(get(e, ['code']) && { code: get(e, ['code']) }),
                meta: data,
              },
              { type: 'error' }
            );

          if (!isFeatureFlagEnabled('ac-4438-popup-for-trusted-buttons')) {
            reportError('Rossum RPC methods are not enabled.');
          } else if (trusted)
            resolveResponse(
              {
                Token: new Token({
                  getToken: () =>
                    api.request(
                      endpoints.authentication.token({
                        maxTokenLifetimeS: 3600,
                        origin: 'rossum_ui',
                      })
                    ),
                }),
              },
              data,
              createMessage,
              reportError
            );
          else
            reportError(
              'Token method is not allowed for this button. You have to set `can_obtain_token` flag on the button schema.'
            );
          break;
        }
        case 'GET_DATAPOINTS': {
          authGetJSON$(`${annotation.url}/content`)
            .pipe(pluck('results'))
            .subscribe(result =>
              source.postMessage({ type: 'GET_DATAPOINTS', result }, origin)
            );
          break;
        }
        case 'GET_ANNOTATION': {
          authGetJSON$(annotation.url).subscribe(result =>
            source.postMessage({ type: 'GET_ANNOTATION', result }, origin)
          );
          break;
        }
        case 'UPDATE_DATAPOINT': {
          authPatch$(`${annotation.url}/content/${data.id}?fields!=children`, {
            content: { value: data.value },
          }).subscribe(() =>
            source.postMessage(
              { type: 'UPDATE_DATAPOINT', result: true },
              origin
            )
          );
          break;
        }
        case 'CREATE_DATAPOINT_CHILD': {
          authPost$<DatapointsFulfilledPayload>(
            `${annotation.url}/content/${data.parentId}/add_empty`,
            {},
            { query: { deprecatedFields: false } }
          ).subscribe(({ content }) =>
            source.postMessage(
              { type: 'CREATE_DATAPOINT_CHILD', result: content },
              origin
            )
          );
          break;
        }
        case 'DELETE_DATAPOINT_CHILDREN': {
          authDelete$(
            `${annotation.url}/content/${data.parentId}/children`
          ).subscribe(() =>
            source.postMessage(
              { type: 'DELETE_DATAPOINT_CHILDREN', result: true },
              origin
            )
          );
          break;
        }
        case 'FINISH': {
          resolve();
          break;
        }
        default:
          break;
      }
    }
  };

export const openPopupEpic = (
  action$: ActionsObservable<PopupActions>,
  state$: StateObservable<State>,
  {
    authGetJSON$,
    authGetBlob$,
    authPatch$,
    authPost$,
    authPostBlob$,
    authDelete$,
    authPut$,
  }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf(openPopup)),
    map(
      ({ payload: { url, trusted } }) =>
        [window.open(url), url, trusted] as const
    ),
    filter(([window]) => window !== null), // No reason to continue if window did not open successfully?
    switchMap(([session, popupUrl, trusted]) =>
      from(
        new Promise<(e: MessageEvent<IncomingMessages>) => void>(resolve => {
          const annotationUrl = state$.value.annotation?.url;
          const handler = createHandler({
            session,
            popupUrl,
            trusted,
            // The empty string is technically not correct but should never happen
            annotation: { url: annotationUrl ?? '' },
            authGetJSON$,
            authGetBlob$,
            authPatch$,
            authPost$,
            authPostBlob$,
            authDelete$,
            authPut$,
            resolve: () => resolve(handler),
          });
          setInterval(() => get(session, 'closed') && resolve(handler), 1000);
          window.addEventListener('message', handler);
        }).then(handler => {
          window.removeEventListener('message', handler);
          invoke(session, 'close');
        })
      )
    ),
    map(closePopup)
  );

export default combineEpics(openPopupEpic);
