import { AxiosResponse } from 'axios';

/**
 * @noSchema
 */
export type ElisApiResponseStatus =
  | 400
  | 401
  | 403
  | 404
  | 405
  | 409
  | 413
  | 429
  | 500
  | 503
  | number;

const statusMessage: Record<ElisApiResponseStatus, string> = {
  400: 'Bad request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method not Allowed',
  409: 'Conflict',
  413: 'Payload Too Large',
  429: 'Too many requests',
  500: 'Internal Server Error',
  503: 'Service Unavailable',
};

/**
 * @noSchema
 */
export type HttpErrorContext = {
  endpoint: string;
  data?: unknown;
  statusCode: ElisApiResponseStatus;
  headers?: AxiosResponse['headers'];
};

export class HttpError extends Error {
  code: number;

  data?: unknown;

  endpoint: string;

  headers?: AxiosResponse['headers'];

  constructor({ endpoint, statusCode, data, headers }: HttpErrorContext) {
    super(
      `'${endpoint}': Request failed with code '${statusCode}': ${
        statusMessage[statusCode] ?? 'Undocumented API error.'
      }`
    );
    this.name = 'HttpError';
    this.code = statusCode;
    this.data = data;
    this.endpoint = endpoint;
    this.headers = headers;
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}
