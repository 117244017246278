import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';

import {
  generateRejectionPayloadSchema,
  generateResponseSchema,
} from './generateRejectionEmail.schema';

export type GenerateResponse = {
  emailBody: string;
};

type ContextMessage = {
  label: string;
  content: string;
};

type ContextOptions = {
  short: boolean;
  friendly: boolean;
  regenerate: boolean;
};

export type GenerateRejectionPayload = {
  annotation: Url;
  messages: ContextMessage[];
  options: ContextOptions;
};

export const generateRejectionEmail = (payload: GenerateRejectionPayload) => {
  return {
    endpoint: `/internal/generate_rejection_email`,
    method: 'POST',
    responseSchema: generateResponseSchema,
    payloadSchema: generateRejectionPayloadSchema,
    payload,
  } as const;
};
