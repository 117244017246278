import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export const NoAttachmentsFound = ({
  translationKey,
}: {
  translationKey:
    | 'emptyAttachmentsList'
    | 'suggestionsError'
    | 'genericError'
    | 'noAttachments';
}) => {
  const intl = useIntl();
  return (
    <Stack justifyContent="center" alignItems="center" height={1}>
      <Typography variant="body2">
        {intl.formatMessage({
          id: `components.modal.docAttachments.noAttachmentsFound.${translationKey}`,
        })}
      </Typography>
    </Stack>
  );
};
