import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { workflowRunResetPayloadSchema } from './reset.schema';

export type WorkflowRunResetPayload = {
  noteContent?: string;
};

export const reset = (
  workflowRunId: number,
  payload: WorkflowRunResetPayload
) => {
  return {
    endpoint: `/workflow_runs/${workflowRunId}/reset`,
    method: 'POST',
    payload,
    responseSchema: z.unknown(),
    payloadSchema: workflowRunResetPayloadSchema,
  } as const;
};
