import { Engine } from '@rossum/api-client/engines';
import { Box, Chip, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useQueuesForEngine } from '../hooks/useQueuesForEngine';
import { engineDetailPath } from '../paths';
import EngineQueues from './EngineQueues';
import TileLink from './TileLink';

type EngineCardProps = {
  engine: Engine;
};

const AuroraEngineTile = ({ engine }: EngineCardProps) => {
  const intl = useIntl();

  const { data, isFetching } = useQueuesForEngine({
    engine: engine.id,
  });

  const queuesInfo = {
    info: data?.info ?? [],
    totalCount: data?.pagination?.total ?? 0,
  };

  return (
    <TileLink
      to={engineDetailPath(engine.id)}
      data-cy="engine-tile"
      data-id={engine.id}
    >
      <Stack flex={1} spacing={2}>
        <Stack flex={1} spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">{engine.name}</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: 'fit-content', mb: 1.5 }}>
                <Chip
                  size="small"
                  variant="outlined"
                  color={engine.learningEnabled ? 'success' : 'default'}
                  label={intl.formatMessage({
                    id: `components.engineCard.aurora.${engine.learningEnabled ? 'enabled' : 'disabled'}`,
                  })}
                />
              </Box>
            </Stack>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {engine.description}
          </Typography>
        </Stack>
        <EngineQueues isLoading={isFetching} queuesInfo={queuesInfo} />
      </Stack>
    </TileLink>
  );
};

export default AuroraEngineTile;
