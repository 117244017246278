import { endpoints } from '@rossum/api-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { api } from '../../../lib/apiClient';
import { STALE_TIME } from './config';

export const useGetWorkspaces = () => {
  const workspacesPaginatedQuery = useInfiniteQuery({
    queryKey: ['workspaces-paginated'],
    queryFn: ({ pageParam = 1 }) => {
      const endpoint = endpoints.workspaces.list({
        pageSize: 100,
        page: pageParam,
      });

      // We need full workspaces in order to construct queue select
      return api.request(endpoint);
    },

    getNextPageParam: lastPage =>
      lastPage.pagination.next
        ? Number(lastPage.pagination.next.match(/page=(\d*)/)?.[1])
        : null,
    staleTime: STALE_TIME,
  });

  const { isFetching, hasNextPage, fetchNextPage } = workspacesPaginatedQuery;

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  return workspacesPaginatedQuery;
};
