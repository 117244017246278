import {
  FOOTER_CONTROL_MARGIN,
  getInitialMaxHeight,
  getMaxHeight,
  INITIAL_FOOTER_HEIGHT,
  INITIAL_FOOTER_HEIGHT_AGGREGATIONS,
  ROW_HEIGHT,
} from './config';

export const getFittingHeight = (
  tuples: Array<unknown>,
  hasAggregations: boolean
) => {
  if (tuples && tuples.length) {
    const fittingHeight = tuples.length * ROW_HEIGHT + FOOTER_CONTROL_MARGIN;
    return fittingHeight <= getInitialMaxHeight()
      ? fittingHeight
      : getInitialMaxHeight();
  }
  return hasAggregations
    ? INITIAL_FOOTER_HEIGHT_AGGREGATIONS
    : INITIAL_FOOTER_HEIGHT;
};

export const getAdjustedHeight = (height: number, minHeight: number) => {
  if (height > getMaxHeight()) return getMaxHeight();
  if (height < minHeight) return minHeight;
  return height;
};
