import { useState } from 'react';
import SearchInput from '../../../components/UI/SearchInput';
import { LocalizationKeys } from '../../../i18n';
import { filterWorkspaces } from '../../../redux/modules/workspaces/helpers';
import { WorkspaceWithQueues } from '../../../types/workspace';

type Props = {
  className?: string;
  setFilteredWorkspaces: (workspaces: WorkspaceWithQueues[]) => void;
  workspaces: Array<WorkspaceWithQueues> | undefined;
  placeholderID?: LocalizationKeys;
};

const SearchFilter = ({
  workspaces,
  setFilteredWorkspaces,
  className,
  placeholderID,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');

  const onValueChange = (value: string) => {
    setFilteredWorkspaces(filterWorkspaces(value, workspaces || []));
    return setSearchValue(value);
  };

  return (
    <SearchInput
      className={className}
      onChange={onValueChange}
      placeholder={
        placeholderID || 'containers.users.components.searchfilter.placeholder'
      }
      value={searchValue}
    />
  );
};

SearchFilter.defaultProps = { className: '' };

export default SearchFilter;
