import { Schema } from '@rossum/api-client/schemas';
import { flattenDeep } from 'lodash';
import { useMemo } from 'react';
import { Queue } from '../types/queue';
import {
  AnySchemaItem,
  FlatSchemaItem,
  FlatSchemaWithQueues,
} from '../types/schema';

const flattenSchemaTree = (
  tree: AnySchemaItem,
  path: string[]
): FlatSchemaItem[] | FlatSchemaItem => {
  if ('children' in tree) {
    const childrenArray: AnySchemaItem[] = Array.isArray(tree.children)
      ? tree.children
      : [tree.children];
    return [
      {
        ...tree,
        path,
      },
      ...childrenArray
        .map((child: AnySchemaItem, index) => {
          const indexSuffix = Array.isArray(tree.children) ? [`${index}`] : [];
          const newPath = [...path, 'children', ...indexSuffix];
          return flattenSchemaTree(child, newPath);
        })
        .flat(),
    ];
  }

  return { ...tree, path };
};

export const flattenSchemaAndAddQueues = (
  schema: Schema,
  allQueues: Queue[]
): FlatSchemaWithQueues => {
  return {
    ...schema,
    queues: allQueues.filter(queue => schema.queues.includes(queue.url)),
    content: schema.content,
    flattenedContent: flattenDeep(
      schema.content?.map((tree, index) =>
        flattenSchemaTree(tree, [`${index}`])
      )
    ),
  };
};

export const useMemoFlatSchemasWithQueues = (
  queues: Queue[],
  schemas: Schema[]
): FlatSchemaWithQueues[] =>
  useMemo(
    () => schemas.map(schema => flattenSchemaAndAddQueues(schema, queues)),
    [queues, schemas]
  );
