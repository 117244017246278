import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Document } from '../models/document';
import { documentSchema } from '../models/document.schema';
import { documentsListQuerySchema } from './list.schema';

export type DocumentsListQuery = {
  id?: ID | ID[];
  email?: ID | ID[];
  creator?: string;
  arrivedAt?: string;
  createdAt?: string;
  originalFileName?: string;
  attachmentStatus?: string;
  ordering?: '-attachment_status' | undefined;
} & PaginationQuery;

export const list = (query: DocumentsListQuery = {}) => {
  return {
    endpoint: `/documents`,
    method: 'GET',
    responseSchema: listResponse(documentSchema),
    query,
    querySchema: documentsListQuerySchema,
  } as const;
};
