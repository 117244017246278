import { getIDFromUrl } from '@rossum/api-client';
import { ApplyLabelPayload } from '@rossum/api-client/labels';
import { sortBy } from 'lodash';
import {
  asAnnotationStatuses,
  getAvailableFilters,
} from '../../../containers/AnnotationList/helpers';
import { FilterableAnnotationOptions } from '../../../containers/AnnotationList/types';
import { ApplyLabelsToAnnotationsMeta, QueryT } from './types';

export const isRangeFilter = (param: string) =>
  param.match(/([a-zA-Z_]*)(_before|_after)$/);

type ParsedQueryT = Omit<QueryT, 'page' | 'pageSize' | 'status'> & {
  page?: number;
  pageSize?: number;
  status?: string | string[];
};

export const removeNonActiveRanges = (query: ParsedQueryT) => {
  const { status, ..._query } = query;

  if (!status || !status.length) return query;

  const availableDateFilters = getAvailableFilters(
    asAnnotationStatuses(status)
  );

  const availableQuery = (
    Object.keys(_query) as (keyof Omit<QueryT, 'status'>)[]
  ).reduce((acc, param) => {
    const rangeFilter = isRangeFilter(param);

    if (rangeFilter && rangeFilter[1]) {
      if (
        availableDateFilters.includes(
          rangeFilter[1] as FilterableAnnotationOptions
        )
      ) {
        return { ...acc, [param]: _query[param] };
      }

      return acc;
    }
    return { ...acc, [param]: _query[param] };
  }, {});

  return { ...availableQuery, status };
};

export const handleLabelsUpdate = <T extends { url: string; labels: string[] }>(
  list: Array<T>,
  payload: ApplyLabelPayload,
  meta: ApplyLabelsToAnnotationsMeta
) => {
  const updatedList = list.map(annotation =>
    payload.objects.annotations.includes(annotation.url)
      ? {
          ...annotation,
          labels: sortBy(
            annotation.labels
              .filter(
                label => !(payload.operations.remove ?? []).includes(label)
              )
              .concat(payload.operations.add ?? [])
          ),
        }
      : annotation
  );

  return meta.labelsInQuery.length
    ? updatedList.filter(annotation =>
        annotation.labels.some(label =>
          meta.labelsInQuery.includes(`${getIDFromUrl(label)}`)
        )
      )
    : updatedList;
};
