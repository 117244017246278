import { useSelector } from 'react-redux';
import {
  isUserAnnotatorBetaSelector,
  isUserViewer,
} from '../../../redux/modules/user/selectors';

export const useCanRemoveDuplicates = () => {
  const isLimitedAnnotator = useSelector(isUserAnnotatorBetaSelector);
  const isViewer = useSelector(isUserViewer);

  return !(isLimitedAnnotator || isViewer);
};
