import { create } from './create';
import { deleteRelation } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
export { type CreateRelationPayload } from './create';
export { type PatchRelationPayload } from './patch';

export const relations = {
  list,
  create,
  get,
  patch,
  delete: deleteRelation,
};
