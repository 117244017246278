import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

export const useDeleteAnnotations = () => {
  return useMutation({
    mutationFn: (ids: Array<number>) => {
      const deleteRequests = ids.map(id =>
        api.request(endpoints.annotations.deleteEndpoint(id))
      );

      return Promise.all(deleteRequests);
    },
  });
};
