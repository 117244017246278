// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { dedicatedEngineSchemaObjectSchema } from './dedicatedEngineSchemaObject.schema';

export const dedicatedEngineSchemaContentSchema = z.object({
  fields: z.array(dedicatedEngineSchemaObjectSchema),
  trainingQueues: z.array(urlSchema),
});

export const dedicatedEngineSchemaSchema = z.object({
  id: idSchema,
  url: urlSchema,
  content: dedicatedEngineSchemaContentSchema,
});
