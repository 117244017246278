import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Workspace } from '../models';
import { workspaceSchema } from '../models/workspace.schema';
import { createWorkspacePayloadSchema } from './create.schema';

export type CreateWorkspacePayload = {
  name: string;
  organization: Url;
};

export const create = (payload: CreateWorkspacePayload) => {
  return {
    endpoint: `/workspaces`,
    method: 'POST',
    responseSchema: workspaceSchema,
    payloadSchema: createWorkspacePayloadSchema,
    payload,
  } as const;
};
