import { BillingHistoryValues } from '@rossum/api-client/billing';
import { BillingStatsValues } from '@rossum/api-client/billing';
import { range } from 'lodash';

type PurchasedStatusColor = 'info' | 'error' | 'warning';
export const getColor = (value: number): PurchasedStatusColor => {
  if (value > 100) return 'error';
  if (value > 90) return 'warning';
  return 'info';
};

export const getPercentage = (value: number) => `${value.toFixed(1)}%`;

export type BillingUnit = 'pages' | 'documents';

export const getValuesByUnit = (
  totals: Partial<BillingStatsValues>,
  unit: BillingUnit
) => {
  return unit === 'pages'
    ? {
        billable: totals.billablePages ?? 0,
        nonBillable: totals.nonBillablePages ?? 0,
      }
    : {
        billable: totals.billableDocuments ?? 0,
        nonBillable: totals.nonBillableDocuments ?? 0,
      };
};

export type BillingVolume = {
  purchased: number;
  billable: number;
  unit: BillingUnit;
};

export const getVolumeFromTotals = (
  totals: BillingHistoryValues
): BillingVolume | null => {
  return totals.billableDocuments > 0
    ? {
        purchased: totals.purchasedDocuments,
        billable: totals.billableDocuments,
        unit: 'documents',
      }
    : totals.billablePages > 0
      ? {
          purchased: totals.purchasedPages,
          billable: totals.billablePages,
          unit: 'pages',
        }
      : null;
};

export const getUsedPercentage = (volume: BillingVolume) =>
  (volume.billable / volume.purchased) * 100;

const getNumberFromRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTemporaryDataForChart = () => {
  return {
    // TODO: rewrite undifiend to the empty strings after clean types in Aggregate component
    totals: {
      billableDocuments: undefined,
      billablePages: undefined,
      nonBillableDocuments: undefined,
      nonBillablePages: undefined,
    },
    results: range(0, 10).map(i => {
      const year = 2022 - i;
      return {
        beginDate: `${year}-01-01`,
        endDate: `${year}-09-06`,
        organization: '',
        queue: null,
        values: {
          billableDocuments: getNumberFromRange(50, 350),
          billablePages: getNumberFromRange(50, 350),
          nonBillableDocuments: getNumberFromRange(30, 250),
          nonBillablePages: getNumberFromRange(30, 250),
        },
      };
    }),
  };
};
