import { Chip, Radio, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import VSpace from '../../../../components/UI/VSpace';
import { Queue } from '../../../../types/queue';
import styles from './style.module.sass';

type Props = {
  control: Control<Pick<Queue, 'defaultScoreThreshold' | 'automationLevel'>>;
};

const AUTOMATION_LEVELS = ['confident', 'always', 'never'] as const;

// TODO this is kind of similar to ControlledRadioGroup which is used currently only for User authentication type,
// so it should be possible to unify it
const AutomationLevels = ({ control }: Props) => {
  const intl = useIntl();
  const {
    field: { value, onChange },
  } = useController({
    name: 'automationLevel',
    control,
  });

  return (
    <>
      <VSpace size="24px" />
      <div>
        {AUTOMATION_LEVELS.map(level => (
          <Typography
            variant="body1"
            component="label"
            htmlFor={`automationLevel-${level}`}
            className={clsx(
              styles.AutomationLevel,
              level === value && styles.AutomationLevelChecked
            )}
            key={level}
            data-cy={`automation-level-${level}`}
          >
            <div className={styles.AutomationLevelHeader}>
              <div className={styles.RadioWrapper}>
                <Radio
                  value={level}
                  checked={level === value}
                  onChange={() => onChange(level)}
                  id={`automationLevel-${level}`}
                />
              </div>
              <Typography variant="body2" fontWeight="bold">
                <FormattedMessage
                  id={`containers.settings.automation.documentAutomation.${level}.title`}
                />
                {level === 'confident' ? (
                  <Chip
                    sx={{ ml: 1 }}
                    size="tiny"
                    color="primary"
                    label={intl.formatMessage({
                      id: 'containers.settings.automation.documentAutomation.recommended',
                    })}
                  />
                ) : null}
              </Typography>
            </div>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage
                id={`containers.settings.automation.documentAutomation.${level}.description`}
              />
            </Typography>
          </Typography>
        ))}
      </div>
      <VSpace size="24px" />
    </>
  );
};

export default AutomationLevels;
