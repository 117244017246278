import clsx from 'clsx';
import CalendarIcon from 'mdi-react/CalendarRangeIcon';
import MenuDown from 'mdi-react/MenuDownIcon';
import { IntlShape } from 'react-intl';
import { PanelProps } from '../../../components/Datepicker';
import { formatDatepickerValue } from '../../../components/Datepicker/helpers';
import styles from '../styles.module.sass';

type Props = {
  beginDate: Date;
  endDate: Date;
  intl: IntlShape;
};

const StatsDatePanel =
  ({ beginDate, endDate, intl }: Props) =>
  ({ visible }: PanelProps) => (
    <div
      className={clsx(styles.DatePanel, visible && styles.DatePanelActive)}
      data-cy="stats-date-panel"
    >
      <span className={styles.Inner}>
        <CalendarIcon size={20} />
        {formatDatepickerValue(beginDate, endDate, intl.locale)}
      </span>
      <MenuDown
        size={18}
        className={clsx(styles.Caret, visible && styles.CaretUp)}
      />
    </div>
  );

export default StatsDatePanel;
