import { GridContainer } from './GridContainer';
import { useGridState } from './useGridState';

export type MagicGridV2ContainerProps = {
  page: number;
  datapointIndex: number;
};

export const MagicGridV2Container = ({
  page,
  datapointIndex,
}: MagicGridV2ContainerProps) => {
  // make magic grid render as a function of state only
  // keep any "interactive" states local
  const {
    serverGridState,
    schemaIdOptions,
    gridSchema,
    rowTypeOptions,
    documentReadOnly,
    lineItemIsFocused,
    actionInProgress,
    handleGridMoved,
    handleGridResized,
    handleVerticalSeparatorMoved,
    handleVerticalSeparatorDeleted,
    handleVerticalSeparatorCreated,
    handleSchemaIdsAssigned,
    handleHorizontalSeparatorCreated,
    handleHorizontalSeparatorMoved,
    handleHorizontalSeparatorDeleted,
    handleRowTypeChanged,
    handleGridAction,
  } = useGridState({
    pageNumber: page,
    datapointIndex,
  });

  if (!serverGridState) return null;

  return (
    <GridContainer
      gridState={serverGridState}
      schemaIdOptions={schemaIdOptions}
      gridSchema={gridSchema}
      rowTypeOptions={rowTypeOptions}
      actionInProgress={actionInProgress}
      disabled={documentReadOnly}
      focused={lineItemIsFocused}
      onGridMoved={handleGridMoved}
      onGridResized={handleGridResized}
      onVerticalSeparatorMoved={handleVerticalSeparatorMoved}
      onVerticalSeparatorDeleted={handleVerticalSeparatorDeleted}
      onVerticalSeparatorCreated={handleVerticalSeparatorCreated}
      onHorizontalSeparatorMoved={handleHorizontalSeparatorMoved}
      onHorizontalSeparatorDeleted={handleHorizontalSeparatorDeleted}
      onHorizontalSeparatorCreated={handleHorizontalSeparatorCreated}
      onSchemaIdsAssigned={handleSchemaIdsAssigned}
      onRowTypeChanged={handleRowTypeChanged}
      onGridActionFired={handleGridAction}
    />
  );
};
