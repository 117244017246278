import { ZodArray, ZodObject, ZodRawShape, ZodType, ZodTypeAny, z } from 'zod';
import { camelToSnake } from './keyConvertor';

export const withFields = <
  ResultsShape extends ZodRawShape,
  PayloadShape extends ZodRawShape,
  PaginationShape extends ZodRawShape,
  Payload extends Record<string, unknown>,
  Mask extends {
    [k in keyof ResultsShape]?: true;
  }
>(
  obj: {
    responseSchema: ZodObject<{
      results: ZodArray<ZodObject<ResultsShape>>;
      pagination: ZodObject<PaginationShape>;
    }>;
    querySchema: ZodType<unknown>;
    query: Record<string, unknown>;

    // All relevant props have to be included here to get forwarded properly.
    // IDK how else to fix it.
    endpoint: string;
    payload: Payload;
    payloadSchema: ZodObject<PayloadShape>;
  },
  mask: Mask
) => {
  const fields = Object.entries(mask)
    .filter(([k, v]) => v === true)
    .map(([k]) => camelToSnake(k));

  const resultObject: ZodObject<ResultsShape> =
    obj.responseSchema.shape.results.element;

  return {
    ...obj,
    responseSchema: obj.responseSchema.omit({ results: true }).merge(
      z.object({
        results: z.array(resultObject.pick(mask)),
      })
    ),
    querySchema: obj.querySchema.and(
      z.object({
        fields: z.array(z.string()),
      })
    ),
    query: {
      ...obj.query,
      fields,
    },
  } as const;
};
