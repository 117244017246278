import * as R from 'remeda';
import { DocumentGeometryState } from './documentGeometryStoreSlice';
import { CanvasDimensions } from './helpers/getCanvasDimensions';

// In normalized canvas coordinates (can be drawn inside of the transform group)
export const centerOfViewportSelector = (state: DocumentGeometryState) =>
  (-state.canvasState.translateY +
    state.viewportDimensions.height / state.viewportState.zoomLevel / 2) /
  state.canvasState.zoomLevel;

export const visiblePageZustandSelector =
  (dimensions: CanvasDimensions) => (state: DocumentGeometryState) => {
    const centerOfViewport = centerOfViewportSelector(state);

    const pagesProximityToTopBoundary = dimensions.pages.map(page => ({
      pageNumber: page.pageNumber,
      pageIndex: page.pageIndex,
      // All dimensions are normalized here
      proximity: Math.abs(
        page.dimensions.y + page.dimensions.height / 2 - centerOfViewport
      ),
    }));

    const visiblePage = R.firstBy(
      pagesProximityToTopBoundary,
      p => p.proximity
    );

    return {
      pageIndex: visiblePage?.pageIndex ?? 0,
      pageNumber: visiblePage?.pageNumber ?? 1,
    };
  };

export const visiblePagesZustandSelector =
  (dimensions: CanvasDimensions) => (state: DocumentGeometryState) => {
    const { viewportState, canvasState, viewportDimensions } = state;

    const top = -canvasState.translateY / canvasState.zoomLevel;
    const bottom =
      -canvasState.translateY / canvasState.zoomLevel +
      viewportDimensions.height / viewportState.zoomLevel;

    const firstVisiblePageIndex = dimensions.pages.findLastIndex(
      page => page.dimensions.y <= top
    );
    const lastVisiblePageIndex = dimensions.pages.findIndex(
      page => page.dimensions.y + page.dimensions.height > bottom
    );

    const lastPageIndex = dimensions.pages.length;

    // Number of pages around your page to be mounted
    const pagePadding = 1;

    const offsetA = Math.max(0, firstVisiblePageIndex - pagePadding);
    const offsetB =
      lastVisiblePageIndex === -1
        ? lastPageIndex
        : Math.min(lastPageIndex, lastVisiblePageIndex + pagePadding + 1);

    const pageNumbers = dimensions.pages
      .slice(offsetA, offsetB)
      .map(page => [page.pageNumber, true] as const);
    return Object.fromEntries(pageNumbers);
  };

export const scaleFactorSelector = (state: DocumentGeometryState) =>
  state.canvasState.zoomLevel * state.viewportState.zoomLevel;

// For some reasons, grid is placed on a different place in DOM and is scaled differently.
export const gridScaleFactorSelector = (state: DocumentGeometryState) =>
  state.canvasState.zoomLevel;
