import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Annotation } from '../models/annotation';
import { annotationSchema } from '../models/annotation.schema';
import { annotationPayloadSchema } from './patch.schema';

export type AnnotationPayload = Partial<Annotation>;

export const patch = (annotationId: ID, payload: AnnotationPayload = {}) => {
  return {
    endpoint: `/annotations/${annotationId}`,
    method: 'PATCH',
    responseSchema: annotationSchema,
    payload,
    payloadSchema: annotationPayloadSchema,
  } as const;
};
