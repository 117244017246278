import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../../types/state';
import { rectangleFromCoordinates } from '../../document-canvas/utils/geometry';
import { getBoxCoordinates } from '../../document-store/documentGeometry';
import { useCanvasGeometryActions } from '../../document-store/DocumentStore';
import { UseCanvasDimensions } from '../useCanvasDimensions';

export const useScrollToActiveSearchResultBox = ({
  dimensions,
}: {
  dimensions: UseCanvasDimensions;
}) => {
  const searchResults = useSelector((state: State) => state.search.results);
  const currentSearchResultIndex = useSelector(
    (state: State) => state.search.currentResultIndex
  );
  const previousSearchResultIndex = useRef<number | undefined>(undefined);

  const searchActive = useSelector((state: State) => state.search.shouldShow);

  const matrixActions = useCanvasGeometryActions();

  const activeSearchResultIndex =
    searchActive && !!searchResults.length
      ? currentSearchResultIndex
      : undefined;

  useEffect(() => {
    if (
      activeSearchResultIndex !== undefined &&
      activeSearchResultIndex !== previousSearchResultIndex.current
    ) {
      const activeSearchResultBox = searchResults[activeSearchResultIndex];

      if (activeSearchResultBox) {
        const { page, rectangle } = activeSearchResultBox;

        matrixActions.translateIntoViewport(
          getBoxCoordinates(
            page,
            rectangleFromCoordinates(rectangle),
            dimensions.pages
          )
        );
      }
    }
    previousSearchResultIndex.current = activeSearchResultIndex;
  }, [activeSearchResultIndex, dimensions.pages, matrixActions, searchResults]);
};
