import { endpoints, ID } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { QUERY_KEY_LAZY_QUEUES } from './useLazyQueues';
import { QUERY_KEY_QUEUES_UNPAGINATED } from './useUnpaginatedQueues';

type RenameQueuePayload = { queueId: ID; newName: string };

export const useRenameQueue = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: RenameQueuePayload) =>
      api.request(
        endpoints.queues.patch(payload.queueId, {
          name: payload.newName,
        })
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_QUEUES],
      });
      dispatch(throwInfo('queueUpdated', undefined));
    },

    onError: () => {
      dispatch(throwError('queueError'));
    },
  });
};

export const useSetQueueName = () => {
  const queryClient = useQueryClient();

  const setQueueName = useCallback(
    (payload: RenameQueuePayload) =>
      queryClient.setQueriesData<Queue[] | undefined>(
        [QUERY_KEY_QUEUES_UNPAGINATED],
        prev => {
          if (prev) {
            return prev.map(q =>
              q.id === payload.queueId ? { ...q, name: payload.newName } : q
            );
          }

          return prev;
        }
      ),
    [queryClient]
  );

  return { setQueueName };
};
