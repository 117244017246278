import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailTemplate } from '../models/emailTemplate';
import { emailTemplateSchema } from '../models/emailTemplate.schema';

export const get = (emailTemplateId: ID) => {
  return {
    endpoint: `/email_templates/${emailTemplateId}`,
    method: 'GET',
    responseSchema: emailTemplateSchema,
  } as const;
};
