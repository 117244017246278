// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const datapointFieldSourceSchema = z.object({
  queue: urlSchema,
  schemaId: z.string(),
});

export const datapointFieldSourcePatchModelSchema = z.object({
  queue: urlSchema,
  schemaId: z.string(),
});
