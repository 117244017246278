import { Schema, SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { FieldsFormModel, FieldsFormValues } from '../form-model/formModels';
import { toSchemaPatchPayload } from '../form-model/transformations/toSchema';
import { SchemaAction } from '../form-model/transformations/toSchema.utils';

export const getUnsafeSchemaConcept = ({
  schema,
  parentId,
  apiModel,
  formValues,
}: {
  schema: Schema;
  parentId: string | null;
  apiModel: SchemaSection | SchemaItem | null;
  formValues: FieldsFormValues;
}) => {
  const schemaAction = apiModel
    ? { op: 'edit', id: apiModel.id, formModel: formValues }
    : { op: 'add', parentId, formModel: formValues };

  return toSchemaPatchPayload(schema, schemaAction as SchemaAction, true);
};
/**
 * Return a WIP version of `Schema` that would be created if the form were to be submitted with `formValues`
 */
export const getSchemaConcept = ({
  schema,
  parentId,
  apiModel,
  formValues,
}: {
  schema: Schema;
  parentId: string | null;
  apiModel: SchemaSection | SchemaItem | null;
  formValues: FieldsFormModel;
}) => {
  const schemaAction: SchemaAction = apiModel
    ? { op: 'edit', id: apiModel.id, formModel: formValues }
    : { op: 'add', parentId, formModel: formValues };

  const parsedSchema = toSchemaPatchPayload(schema, schemaAction);

  if (!parsedSchema) {
    return schema;
  }

  return parsedSchema;
};
