import * as apiClient from '@rossum/api-client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowAny: any = window;
const reactAppApiUrl =
  windowAny && windowAny.env && windowAny.env.REACT_APP_API_URL;

export const getAuthToken = () => localStorage.getItem('secureToken');
export const apiUrl =
  reactAppApiUrl || process.env.REACT_APP_API_URL || 'http://localhost:5000';

const api = apiClient.createElisClient({
  baseUrl: apiUrl,
  getAuthToken,
});

export const useApiClient = () => {
  return api;
};
