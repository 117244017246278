import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  generatePayloadPayloadSchema,
  generatePayloadResponseSchema,
} from './generatePayload.schema';

export type GeneratePayloadPayload = {
  action: string;
  event: string;
  annotation?: string;
  previousStatus?: string;
  status?: string;
  email?: string;
  upload?: string;
};

export type GeneratePayloadResponse = unknown;

export const generatePayload = (
  hookId: number,
  payload: GeneratePayloadPayload
) => {
  return {
    endpoint: `/hooks/${hookId}/generate_payload`,
    method: 'POST',
    payloadSchema: generatePayloadPayloadSchema,
    payload,
    responseSchema: generatePayloadResponseSchema,
  } as const;
};
