import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngineSchema } from '../models/dedicatedEngineSchema';
import { dedicatedEngineSchemaSchema } from '../models/dedicatedEngineSchema.schema';
import { DedicatedEngineSchemaObjectPatchModel } from '../models/dedicatedEngineSchemaObject';
import { dedicatedEngineSchemaPatchPayloadSchema } from './patch.schema';

export type DedicatedEngineSchemaPatchPayload = {
  content: {
    trainingQueues: Url[];
    fields: DedicatedEngineSchemaObjectPatchModel[];
  };
};

export const patch = (
  engineSchemaId: ID,
  payload: DedicatedEngineSchemaPatchPayload
) => {
  return {
    endpoint: `/dedicated_engine_schemas/${engineSchemaId}`,
    method: 'PATCH',
    responseSchema: dedicatedEngineSchemaSchema,
    payloadSchema: dedicatedEngineSchemaPatchPayloadSchema,
    payload,
  } as const;
};
