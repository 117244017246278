import { endpoints, Url } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const EMAIL_QUERY_KEY = 'email';

export const useSenderEmail = (emailUrl: Url) => {
  const { data } = useQuery({
    queryKey: [EMAIL_QUERY_KEY, emailUrl],
    queryFn: () => api.request(endpoints.email.get(emailUrl)),
    enabled: !!emailUrl,
  });

  const { email } = data?.from || {};

  return email;
};
