import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys } from '../keys';

export const useEngine = (id: number) => {
  return useQuery({
    queryKey: engineKeys.detail(id),
    queryFn: () => api.request(endpoints.engines.get(id)),
    enabled: id > 0,
  });
};
