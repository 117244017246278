import { Link, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type LinkWithNameAndIdProps = {
  link: string;
  name: string;
  id: string;
};

export const LinkWithNameAndId = ({
  link,
  name,
  id,
}: LinkWithNameAndIdProps) => {
  const intl = useIntl();
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        variant="body1"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Link
          color="secondary"
          target="_blank"
          rel="noreferrer noopener"
          href={link}
        >
          {name}
        </Link>
      </Typography>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'nowrap',
          color: theme => theme.palette.text.secondary,
        }}
      >
        {intl.formatMessage(
          {
            id: 'features.fieldManager.overview.ui.linkWithNameAndId.textSecondary',
          },
          { id }
        )}
      </Typography>
    </Stack>
  );
};
