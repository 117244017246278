import React, { createContext, ReactNode, useContext } from 'react';
import { Grid } from '../../types/datapoints';
import { GridSchema } from '../../types/schema';
import { RowTypeOption } from './useGridState';
import { GridUiState, SelectOption } from './utils';

type GridContextValue = {
  gridState: Grid;
  gridDraftState: [
    Grid | null,
    React.Dispatch<React.SetStateAction<Grid | null>>,
  ];
  gridUiState: [GridUiState, React.Dispatch<React.SetStateAction<GridUiState>>];
  interactionInProgress: boolean;
  schemaIdOptions: SelectOption[];
  gridSchema: GridSchema;
  rowTypeOptions: RowTypeOption[];
  gridLoading: boolean;
  gridDisabled: boolean;
  gridFocused: boolean;
};

export const GridContext = createContext<GridContextValue | undefined>(
  undefined
);

type GridProviderProps = {
  gridState: Grid;
  gridDraftState: [
    Grid | null,
    React.Dispatch<React.SetStateAction<Grid | null>>,
  ];
  gridUiState: [GridUiState, React.Dispatch<React.SetStateAction<GridUiState>>];
  schemaIdOptions: SelectOption[];
  gridSchema: GridSchema;
  rowTypeOptions: RowTypeOption[];
  children: ReactNode;
  gridLoading: boolean;
  gridDisabled: boolean;
  gridFocused: boolean;
};

export const GridProvider = ({
  gridState,
  gridDraftState,
  gridUiState,
  schemaIdOptions,
  gridSchema,
  rowTypeOptions,
  gridLoading,
  gridDisabled,
  gridFocused,
  children,
}: GridProviderProps) => {
  return (
    <GridContext.Provider
      value={{
        gridState,
        gridDraftState,
        gridUiState,
        interactionInProgress: !!gridDraftState[0],
        schemaIdOptions,
        gridSchema,
        rowTypeOptions,
        gridLoading,
        gridDisabled,
        gridFocused,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

export const useGridContext = () => {
  const ctx = useContext(GridContext);

  if (ctx === undefined) {
    throw new Error(
      '`useGridContext` must be used within a GridContextProvider'
    );
  }

  return ctx;
};
