// Generated by ts-to-zod
import { z } from 'zod';

export const scorePasswordPayloadSchema = z.object({
  password: z.string(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});

export const scorePasswordResponseSchema = z.object({
  score: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
  ]),
  messages: z.array(z.string()),
});
