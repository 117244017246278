import clsx from 'clsx';
import { every } from 'lodash';
import styles from './style.module.sass';

type Props = { path: Array<string>; className?: string };

const Breadcrumbs = ({ path, className }: Props) =>
  every(path) ? (
    <div className={clsx(styles.Breadcrumbs, className)}>
      {path.join(' / ')}
    </div>
  ) : null;

Breadcrumbs.defaultProps = { className: '' };

export default Breadcrumbs;
