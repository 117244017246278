// Generated by ts-to-zod
import { z } from 'zod';
import {
  functionConfigSchema,
  webhookConfigSchema,
} from '../../hooks/models/hookConfig.schema';
import {
  extensionEventSchema,
  extensionSourceSchema,
} from '../../hooks/models/hookUtils.schema';
import { urlSchema } from '../../utils/codecUtils.schema';

const settingsDescriptionSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    tooltip: z.string(),
  })
  .partial();

const hookTemplateBaseSchema = z
  .object({
    name: z.string(),
    url: urlSchema,
    events: z.array(extensionEventSchema),
    sideload: z.array(z.string()),
    metadata: z.unknown().optional(),
    test: z.unknown().optional(),
    description: z.string(),
    extensionSource: extensionSourceSchema,
    settings: z.record(z.unknown()).optional(),
    guide: z.string(),
    readMoreUrl: urlSchema.nullable(),
    extensionImageUrl: urlSchema.nullable(),
    settingsDescription: z.array(settingsDescriptionSchema),
    storeDescription: z.string(),
    externalUrl: urlSchema,
    useTokenOwner: z.boolean(),
    order: z.number(),
  })
  .and(
    z
      .object({
        id: z.number(),
        installAction: z.string(),
      })
      .partial()
  );

const webhookTemplateSchema = hookTemplateBaseSchema.and(
  z.object({
    type: z.literal('webhook'),
    config: webhookConfigSchema,
  })
);

const functionTemplateSchema = hookTemplateBaseSchema.and(
  z.object({
    type: z.literal('function'),
    config: functionConfigSchema,
  })
);

export const hookTemplateSchema = z.union([
  webhookTemplateSchema,
  functionTemplateSchema,
]);
