import { AnnotationStatus } from '@rossum/api-client/shared';
import { isDefined } from 'remeda';
import { AsyncTasks, ImportTask, UploadTasks } from '../TaskContext';
import { TaskInfo } from './getTaskDialogTitle';

type ClassifyTasksParams = {
  asyncTasks: AsyncTasks;
  uploadsTasks: UploadTasks;
  importTasks: ImportTask[];
  uploadedAnnotations?: {
    annotationUrl: string;
    annotationStatus: AnnotationStatus;
  }[];
  childAnnotations?: {
    annotationStatus: AnnotationStatus;
  }[];
};

export const classifyTasks = ({
  asyncTasks,
  uploadsTasks,
  importTasks,
  uploadedAnnotations,
  childAnnotations,
}: ClassifyTasksParams): TaskInfo[] => {
  return [
    ...asyncTasks
      .map<TaskInfo | undefined>(({ data }) => data)
      .filter(isDefined),
    ...uploadsTasks.map<TaskInfo>(() => ({
      type: 'upload_created',
      status: 'failed',
    })),
    ...importTasks.map<TaskInfo>(({ annotationUrl }) => {
      const status = uploadedAnnotations?.find(
        annotation => annotation.annotationUrl === annotationUrl
      )?.annotationStatus;

      return {
        type: 'import',
        status:
          status && status !== 'importing'
            ? status === 'failed_import'
              ? 'failed'
              : 'succeeded'
            : 'running',
      };
    }),
    ...(childAnnotations ?? []).map<TaskInfo>(
      ({ annotationStatus: status }) => {
        return {
          type: 'import',
          status:
            status && status !== 'importing'
              ? status === 'failed_import'
                ? 'failed'
                : 'succeeded'
              : 'running',
        };
      }
    ),
  ];
};
