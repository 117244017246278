// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const pageSchema = z.object({
  id: idSchema,
  annotation: urlSchema,
  number: z.number(),
  rotationDeg: z.number(),
  mimeType: z.string(),
  s3Name: z.string(),
  url: urlSchema,
  content: urlSchema,
  width: z.number(),
  height: z.number(),
  metadata: z.unknown().optional(),
});
