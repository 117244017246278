import { Typography } from '@rossum/ui/material';
import { useTheme } from '@rossum/ui/material';

type FormulaPreviewProps = {
  value: string;
};

export const FormulaPreview = ({ value }: FormulaPreviewProps) => {
  const theme = useTheme();

  return (
    <Typography
      textAlign="left"
      component="pre"
      color="inherit"
      fontFamily='Menlo, Monaco, Consolas, "Courier New", monospace'
      pl={0}
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: 'none',
      }}
    >
      {value}
    </Typography>
  );
};
