type CalculateScrollbarPropsParams = {
  viewportSize: number;
  canvasSize: number;
  translate: number;
  zoomLevel: number;
  setTranslate: (p: number) => void;
};

export const calculateScrollbarProps = ({
  viewportSize,
  canvasSize,
  translate,
  setTranslate,
  zoomLevel,
}: CalculateScrollbarPropsParams) => {
  const scrollableDistance = Math.max(0, canvasSize - viewportSize);

  // Normalized translate = positive, for zoom level 1
  const normalizedTranslate = -translate / zoomLevel;

  // Scrollbar props are undefined if no scrollbar should be displayed.
  return scrollableDistance
    ? {
        // What is the percentage of canvas size you see in the viewport.
        size: viewportSize / canvasSize,

        // How large part of the available scroll distance you scrolled through
        offset: normalizedTranslate / scrollableDistance,

        // Inverse calculation to offset.
        onMove: (newOffset: number) => {
          const newNormalizedTranslate = newOffset * scrollableDistance;
          const newTranslate = -(newNormalizedTranslate * zoomLevel);
          return setTranslate(newTranslate);
        },
      }
    : undefined;
};
