import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { WorkspaceListQuery } from '@rossum/api-client/workspaces';
import { Workspace } from '@rossum/api-client/workspaces';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_WORKSPACES_UNPAGINATED = 'workspaces-unpaginated';

export const useUnpaginatedWorkspaces = <TReturnedData = Workspace[]>(
  query: WorkspaceListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      Workspace[],
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_WORKSPACES_UNPAGINATED]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_WORKSPACES_UNPAGINATED],
    queryFn: () => api.unpaginatedRequest(endpoints.workspaces.list)(query),
    ...queryOptions,
  });
