// Generated by ts-to-zod
import { z } from 'zod';

export const pageDataItemSchema = z.object({
  position: z.tuple([z.number(), z.number(), z.number(), z.number()]),
  text: z.string(),
});

export const pageDataSchema = z.object({
  pageNumber: z.number(),
  granularity: z.union([
    z.literal('words'),
    z.literal('lines'),
    z.literal('chars'),
    z.literal('texts'),
  ]),
  items: z.array(pageDataItemSchema),
});
