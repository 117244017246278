import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import { Box, Grow, Stack } from '@rossum/ui/material';
import { Control, FieldArrayWithId } from 'react-hook-form';
import { SwitchTransition } from 'react-transition-group';
import { DragHandleProps } from '../../../../../../../../../components/Dnd/SortableWrapper';
import { HeaderFieldFormModel, LineItemFormModel } from '../utils';
import LineItemColumnCard from './LineItemColumnCard';
import LineItemColumnForm from './LineItemColumnForm';

export type EditModeState = {
  index: number;
  originalValue?: HeaderFieldFormModel;
} | null;

type TableColumnItemProps = {
  field: FieldArrayWithId<LineItemFormModel, 'columns', 'id'>;
  engineSchema: DedicatedEngineSchema;
  index: number;
  editing: EditModeState;
  watchColumns: HeaderFieldFormModel[];
  dragHandleProps: DragHandleProps;
  handleColumnConfirm: (index: number) => void;
  handleColumnCancel: () => void;
  handleColumnDelete: (index: number) => void;
  handleEnterEditMode: (index: number) => void;
  disabled?: boolean;
  control: Control<LineItemFormModel>;
};

const TableColumnItem = ({
  field,
  engineSchema,
  index,
  editing,
  watchColumns,
  dragHandleProps,
  handleColumnConfirm,
  handleColumnCancel,
  handleColumnDelete,
  handleEnterEditMode,
  disabled,
  control,
}: TableColumnItemProps) => {
  const getItemContent = (): JSX.Element => {
    if (editing?.index === index) {
      return (
        <LineItemColumnForm
          control={control}
          index={index}
          engineSchema={engineSchema}
          disabled={disabled}
          onConfirm={handleColumnConfirm}
          onCancel={handleColumnCancel}
          onDelete={handleColumnDelete}
          mode={editing?.originalValue ? 'edit' : 'add'}
        />
      );
    }

    if (dragHandleProps.isDragging) {
      return (
        <Stack
          sx={{
            minHeight: 52,
          }}
        />
      );
    }

    return (
      <LineItemColumnCard
        columnModel={watchColumns[index] ?? field}
        onClick={() => handleEnterEditMode(index)}
        onDelete={() => handleColumnDelete(index)}
        disabled={editing !== null}
        dragHandleProps={dragHandleProps}
      />
    );
  };

  return (
    <Stack>
      <SwitchTransition>
        <Grow key={editing?.index === index ? 'form' : 'card'} unmountOnExit>
          <Box>{getItemContent()}</Box>
        </Grow>
      </SwitchTransition>
    </Stack>
  );
};

export default TableColumnItem;
