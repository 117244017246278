import { endpoints, ID } from '@rossum/api-client';
import { GenericEngineGetQuery } from '@rossum/api-client/genericEngines';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_GENERIC_ENGINE = 'generic_engine';

export const useGenericEngine = (
  engineId: ID | null,
  query: GenericEngineGetQuery = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_GENERIC_ENGINE, engineId, query] as const,

    queryFn: () => {
      if (!engineId) throw new Error('No engine ID provided');
      return api.request(endpoints.genericEngines.get(engineId, query));
    },
    enabled: !!engineId,
  });
};
