import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Relation, RelationType } from '../models/relations';
import { relationSchema } from '../models/relations.schema';
import { relationListQuerySchema } from './list.schema';

export type RelationListQuery = Partial<{
  parent: ID[];
  annotation: ID[];
  type: RelationType;
}> &
  PaginationQuery;

export const list = (query: RelationListQuery = {}) => {
  return {
    endpoint: `/relations`,
    method: 'GET',
    responseSchema: listResponse(relationSchema),
    query,
    querySchema: relationListQuerySchema,
  } as const;
};
