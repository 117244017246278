import { MiddlewareAPI } from 'redux';
import { getType } from 'typesafe-actions';
import { timeSpent } from '../../timeSpent/timeSpent';
import { Next } from '../../types/redux';
import {
  cancelEditMode,
  hideBlockers,
  showBlockers,
  startEditMode,
} from '../modules/ui/actions';
import { RootActionType } from '../rootActions';

export default (store: MiddlewareAPI) =>
  (next: Next) =>
  (action: RootActionType) => {
    switch (action.type) {
      case getType(startEditMode): {
        timeSpent.openEditMode();
        break;
      }
      case getType(cancelEditMode): {
        if (!action.payload) break;

        timeSpent.closeEditMode(action.payload);
        break;
      }
      case getType(showBlockers): {
        timeSpent.openBlockers();
        break;
      }
      case getType(hideBlockers): {
        timeSpent.closeBlockers(store.getState().annotation.id);
        break;
      }
      default:
        break;
    }
    return next(action);
  };
