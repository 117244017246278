import { endpoints } from '@rossum/api-client';
import { EngineFieldListQuery } from '@rossum/api-client/engineFields';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineFieldKeys } from '../keys';

export const useEngineFields = (
  filters: EngineFieldListQuery,
  { enabled }: { enabled?: boolean } = {}
) => {
  return useQuery({
    queryKey: engineFieldKeys.list({ filters }),
    queryFn: () => api.request(endpoints.engineFields.list(filters)),
    enabled,
  });
};
