import { ElisRequestConfig } from '../../utils/requestConfig';
import { AnnotationData } from '../models/annotationData';
import { annotationDataSchema } from '../models/annotationData.schema';

export const getContent = (annotationId: number) => {
  return {
    endpoint: `/annotations/${annotationId}/content`,
    method: 'GET',
    responseSchema: annotationDataSchema,
  } as const;
};
