import { useTheme } from '@rossum/ui/material';
import { Rectangle2D } from '../../document-canvas/utils/geometry';

type SelectionSpanProps = {
  rectangle: Rectangle2D;
};

export const SelectionSpan = ({ rectangle }: SelectionSpanProps) => {
  const theme = useTheme();

  return (
    <rect
      x={rectangle.x}
      y={rectangle.y}
      width={rectangle.width}
      height={rectangle.height}
      fill="none"
      stroke={theme.palette.primary.main}
      pointerEvents="none"
      vectorEffect="non-scaling-stroke"
      strokeWidth={2}
      rx={4}
      ry={4}
    />
  );
};
