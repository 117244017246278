import { ComponentProps, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

export type ChildrenOrFormattedMessage = {
  children?: ReactNode;
  id?: LocalizationKeys;
  values?: ComponentProps<typeof FormattedMessage>['values'];
};

export const childrenOrFormattedMessage = (props: ChildrenOrFormattedMessage) =>
  props.children || <FormattedMessage id={props.id} values={props.values} />;
