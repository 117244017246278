import { ID } from '../../utils/codecUtils';
import { listResponse } from '../../utils/listResponse';
import { pageSchema } from '../models/page.schema';
import { pagesQuerySchema } from './list.schema';

export type PagesQuery = {
  id: ID | ID[];
  annotation: ID | ID[];
  number: number | number[];
};

export const list = (query: PagesQuery) => {
  return {
    endpoint: `/pages`,
    method: 'GET',
    responseSchema: listResponse(pageSchema),
    querySchema: pagesQuerySchema,
    query,
  } as const;
};
