import { create } from './create';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { requestEndpoint } from './request';
export { type DedicatedEngineListQuery } from './list';
export { type DedicatedEngineGetQuery } from './get';
export { type DedicatedEnginePatchPayload } from './patch';

export const dedicatedEngines = {
  list,
  create,
  get,
  patch,
  request: requestEndpoint,
};
