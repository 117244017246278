import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const postpone = (annotationId: number) => {
  return {
    endpoint: `/annotations/${annotationId}/postpone`,
    method: 'POST',
    responseSchema: z.unknown(),
  } as const;
};
