const ruleNames = ['missingPassword', 'missingUsername'] as const;

type LoginPayload = {
  password: string;
  username: string;
};

const rules: Record<
  (typeof ruleNames)[number],
  (_param: LoginPayload) => boolean
> = {
  missingPassword: ({ password }: LoginPayload) =>
    !!password && password.length > 0,
  missingUsername: ({ username }: LoginPayload) =>
    !!username && username.length > 0,
};

export const validateLoginPayload = (body: LoginPayload) => ({
  body,
  _reason: ruleNames.reduce<(typeof ruleNames)[number] | null>(
    (acc, ruleName) => (rules[ruleName](body) ? acc : ruleName),
    null
  ),
});
