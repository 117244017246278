import { escapeRegExp, map } from 'lodash';
import { OptionT } from '../../../components/Picker';
import {
  queueSortFns,
  workspaceSortFns,
} from '../../../features/queue-sidebar/sidebar/sorting';
import { SortingValue } from '../../../types/user';
import { Workspace } from '../../../types/workspace';

export const filterWorkspaces = <
  Q extends { name: string; id: number },
  W extends { name: string; queues: Q[] },
>(
  search: string,
  workspaces: W[]
): W[] => {
  const value = escapeRegExp(search.toUpperCase());

  return workspaces.reduce<W[]>((acc, workspace) => {
    if (workspace.name.toUpperCase().search(value) !== -1)
      return [...acc, workspace];

    const queues = workspace.queues.reduce<Q[]>(
      (queuesAcc, queue) =>
        queue.name.toUpperCase().search(value) !== -1 ||
        queue.id.toString() === value
          ? [...queuesAcc, queue]
          : queuesAcc,
      []
    );

    return queues.length
      ? [
          ...acc,
          {
            ...workspace,
            queues,
            originalQueuesCount: workspace.queues.length,
          },
        ]
      : acc;
  }, []);
};

export const workspacesToOptions = <
  T extends { name: string; queues: { name: string; id: number }[] },
>(
  workspaces: T[]
): OptionT[] =>
  workspaces
    .filter(({ queues }) => queues.length)
    .map(({ name, queues }) => ({
      label: name,
      value: map(queues, ({ name: _name, id }) => ({
        label: _name,
        value: id,
      })),
    }));

export type WorkspaceWithToReviewCount = Workspace & { toReviewCount: number };

export const getQueueSortFn = (sort: SortingValue) => {
  return queueSortFns[sort] ?? queueSortFns.alphabeticalAsc;
};

export const getWorkspaceSortFn = (sort: SortingValue) => {
  return workspaceSortFns[sort] ?? workspaceSortFns.alphabeticalAsc;
};
