import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Document } from '../models/document';
import { documentSchema } from '../models/document.schema';
import { documentPayloadSchema } from './patch.schema';

export type DocumentPayload = Partial<Document>;

export const patch = (documentID: ID, payload: DocumentPayload = {}) => {
  return {
    endpoint: `/documents/${documentID}`,
    method: 'PATCH',
    responseSchema: documentSchema,
    payload,
    payloadSchema: documentPayloadSchema,
  } as const;
};
