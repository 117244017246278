import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  startEmbeddedAnnotationPayloadSchema,
  startEmbeddedAnnotationResponseSchema,
} from './startEmbedded.schema';

export type StartEmbeddedAnnotationPayload = {
  returnUrl: Url;
  cancelUrl: Url;
};

export type StartEmbeddedAnnotationResponse = {
  url: Url;
};

export const startEmbedded = (
  annotationId: number,
  payload: StartEmbeddedAnnotationPayload
) => {
  return {
    endpoint: `/annotations/${annotationId}/start_embedded`,
    method: 'POST',
    payloadSchema: startEmbeddedAnnotationPayloadSchema,
    payload,
    responseSchema: startEmbeddedAnnotationResponseSchema,
  } as const;
};
