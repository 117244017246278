import { ReactNode } from 'react';
import Tooltip from '../../UI/Tooltip/TranslatedTooltip';

type Props = {
  children: ReactNode;
  tooMany: boolean;
  tooFew: boolean;
  isSimpleMultivalue: boolean;
};

const getText = (tooFew: boolean) =>
  tooFew
    ? 'components.documentValidation.multivalue.occurences.min'
    : 'components.documentValidation.multivalue.occurences.max';

const OccurenceTooltip = ({
  children,
  tooFew,
  tooMany,
  isSimpleMultivalue,
}: Props) => (
  <Tooltip
    placement="right"
    disabled={(!tooFew && !tooMany) || !isSimpleMultivalue}
    enableOverflow={false}
    translationId={getText(tooFew)}
  >
    {children}
  </Tooltip>
);

export default OccurenceTooltip;
