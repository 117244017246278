import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Email } from '../models/email';
import { emailSchema } from '../models/email.schema';

export const get = (emailUrl: Url) => {
  return {
    endpoint: emailUrl,
    method: 'GET',
    responseSchema: emailSchema,
  } as const;
};
