import { DecodingError, HttpError } from '@rossum/api-client/errors';
import * as Sentry from '@sentry/react';
import { AjaxError } from 'rxjs/ajax';
import { currentCodeVersion } from '../constants/config';

const getApiErrorStatusCode = (error: unknown) => {
  if (error instanceof AjaxError) {
    return error.status;
  }

  if (error instanceof HttpError) {
    return error.code;
  }

  return null;
};

// TODO: Slowly expand these? For now only log 400 and 500 errors
const loggedApiErrorCodes = [400, 500];

export const isIgnoredApiError = (error: unknown) => {
  // log all decoding errors
  if (error instanceof DecodingError) {
    return false;
  }

  // for API errors, only whitelisted errors are reported
  if (error instanceof AjaxError || error instanceof HttpError) {
    const code = getApiErrorStatusCode(error);

    // just in case, see what happens
    if (code === null) {
      return false;
    }

    return !loggedApiErrorCodes.includes(code);
  }

  // other errors with no special handling get reported
  return false;
};

const ignoredEndpointPatterns = [/\/auth\//, /\/oauth\//];

export const isIgnoredApiEndpoint = (requestUrl: string | undefined) => {
  if (!requestUrl) {
    return false;
  }

  return ignoredEndpointPatterns.some(pattern => pattern.test(requestUrl));
};

export const initSentry = () => {
  Sentry.init({
    enabled: window.env.REACT_APP_SENTRY_ENABLED,
    dsn: window.env.REACT_APP_SENTRY_DSN ?? undefined,
    environment: window.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'unknown',
    release: currentCodeVersion,
    integrations: [],
    // Breadcrumbs are events captured in an event trail, like UI clicks, XHR etc.
    beforeBreadcrumb: (breadcrumb, hint) => {
      // For logged API errors, try logging payloads and responses
      // There are limits to how much Sentry can ingest so with large payloads this will still be useless, can we think of a better way?
      if (
        breadcrumb.category === 'xhr' &&
        breadcrumb.level === 'error' &&
        loggedApiErrorCodes.includes(breadcrumb.data?.status_code) &&
        !isIgnoredApiEndpoint(breadcrumb.data?.url)
      ) {
        const data = {
          // To make sure response is logged first in case payload is too large
          _response: {
            response: hint?.xhr?.response,
            responseText: hint?.xhr?.responseText,
          },
          payload: hint?.input,
        };

        return { ...breadcrumb, data };
      }

      return breadcrumb;
    },
    // TODO: Can be used to clean data, ignore errors etc
    beforeSend: (event, hint) => {
      const { originalException } = hint;

      if (isIgnoredApiError(originalException)) {
        return null;
      }

      if (window.smartlook) {
        Sentry.setContext('smartlook', {
          // @ts-expect-error
          playUrl: window.smartlook?.playUrl,
        });
      }

      return event;
    },
  });

  window.Sentry = Sentry;
};
