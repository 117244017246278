import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models';
import { queueSchema } from '../models/queue.schema';
import { createQueuePayloadSchema } from './create.schema';

export type CreateQueuePayload = {
  name: string;
  workspace: Url;
  schema: Url;
  useConfirmedState?: boolean;
};

export const create = (payload: CreateQueuePayload) => {
  return {
    endpoint: `/queues`,
    method: 'POST',
    responseSchema: queueSchema,
    payloadSchema: createQueuePayloadSchema,
    payload,
  } as const;
};
