import './style.sass';
import UndoIcon from './editorImages/arrow-u-left-top.svg';
import RedoIcon from './editorImages/arrow-u-right-bottom.svg';
import FormatAlignCenter from './editorImages/format-align-center.svg';
import FormatAlignLeft from './editorImages/format-align-left.svg';
import FormatAlignRight from './editorImages/format-align-right.svg';
import FormatBold from './editorImages/format-bold.svg';
import FormatItalic from './editorImages/format-italic.svg';
import FormatListBulleted from './editorImages/format-list-bulleted.svg';
import FormatListNumbered from './editorImages/format-list-numbered.svg';
import FormatUnderline from './editorImages/format-underline.svg';
import LinkIcon from './editorImages/link-variant.svg';
import styles from './styles.module.sass';

const editorToolbarConfig = {
  options: ['inline', 'list', 'textAlign', 'link', 'history'],
  inline: {
    inDropdown: false,
    className: styles.EditorToolbarItem,
    options: ['bold', 'italic', 'underline'],
    bold: {
      icon: FormatBold,
      className: styles.EditorToolbarIcon,
    },
    italic: { icon: FormatItalic, className: styles.EditorToolbarIcon },
    underline: {
      icon: FormatUnderline,
      className: styles.EditorToolbarIcon,
    },
  },
  list: {
    inDropdown: false,
    className: styles.EditorToolbarItem,
    options: ['unordered', 'ordered'],
    unordered: {
      icon: FormatListBulleted,
      className: styles.EditorToolbarIcon,
    },
    ordered: { icon: FormatListNumbered, className: styles.EditorToolbarIcon },
  },
  textAlign: {
    inDropdown: false,
    className: styles.EditorToolbarItem,
    options: ['left', 'center', 'right'],
    left: { icon: FormatAlignLeft, className: styles.EditorToolbarIcon },
    center: { icon: FormatAlignCenter, className: styles.EditorToolbarIcon },
    right: { icon: FormatAlignRight, className: styles.EditorToolbarIcon },
  },
  link: {
    inDropdown: false,
    className: styles.EditorToolbarItem,
    popupClassName: styles.EditorLinkPopup,
    showOpenOptionOnHover: false,
    defaultTargetOption: '_self',
    options: ['link'],
    link: { icon: LinkIcon, className: styles.EditorToolbarIcon },
  },
  history: {
    inDropdown: false,
    className: styles.EditorToolbarItem,
    options: ['undo', 'redo'],
    undo: { icon: UndoIcon, className: styles.EditorToolbarIcon },
    redo: { icon: RedoIcon, className: styles.EditorToolbarIcon },
  },
};

export default editorToolbarConfig;
