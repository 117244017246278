import { z } from 'zod';
import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { uploadResponseSchema } from './upload.schema';

export type UploadPayload = {
  filePath: string;
};

export type UploadResponse = {
  document: Url;
  annotation: Url;
  results: Array<{
    document: Url;
    annotation: Url;
  }>;
};

/**
 * @deprecated
 */
export const upload = (
  queueId: number,
  fileName: string,
  payload: unknown,
  signal?: AbortSignal
) => {
  return {
    endpoint: `/queues/${queueId}/upload/${fileName}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseSchema: uploadResponseSchema,
    payloadSchema: z.unknown(),
    payload,
    signal,
  } as const;
};
