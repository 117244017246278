// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const createQueuePayloadSchema = z.object({
  name: z.string(),
  workspace: urlSchema,
  schema: urlSchema,
  useConfirmedState: z.boolean().optional(),
});
