import { ElisRequestConfig } from '../../utils/requestConfig';
import { Annotation } from '../models/annotation';
import { annotationSchema } from '../models/annotation.schema';

export const get = (annotationId: number) => {
  return {
    endpoint: `/annotations/${annotationId}`,
    method: 'GET',
    responseSchema: annotationSchema,
  } as const;
};
