// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const createConnectorPayloadSchema = z.object({
  name: z.string(),
  queues: z.array(z.string()),
  serviceUrl: urlSchema,
  authorizationToken: z.string(),
});
