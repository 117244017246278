import { CONTROL_BAR_HEIGHT, SIDEBAR_WIDTH } from '../../constants/values';

export const CONTROL_PANEL_DEFAULT_RIGHT = 20;
export const CONTROL_PANEL_WIDTH = 50;
export const CONTROL_PANEL_HEIGHT = 233;
export const DRAG_HANDLE_HALF_SIZE = 10;
const OFFSET_LEFT_RIGHT = 10;

const clamp = (value: number, min: number, max: number) =>
  Math.min(max, Math.max(min, value));

export const limitToolbarToDocumentView = (
  x: number,
  y: number,
  footerHeight?: number
) => {
  const minX = CONTROL_PANEL_WIDTH / 2 + OFFSET_LEFT_RIGHT;
  const maxX =
    window.innerWidth -
    SIDEBAR_WIDTH -
    CONTROL_PANEL_WIDTH / 2 -
    OFFSET_LEFT_RIGHT;
  const minY = CONTROL_BAR_HEIGHT + CONTROL_PANEL_HEIGHT / 2;
  const maxY =
    window.innerHeight - (footerHeight ?? 0) - CONTROL_PANEL_HEIGHT / 2;

  return {
    constrainedX: clamp(x, minX, maxX),
    constrainedY: clamp(y, minY, maxY),
  };
};
