export const Grab = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9C7 9.55 6.55 10 6 10C5.45 10 5 9.55 5 9C5 8.45 5.45 8 6 8C6.55 8 7 8.45 7 9ZM6 5C5.45 5 5 5.45 5 6C5 6.55 5.45 7 6 7C6.55 7 7 6.55 7 6C7 5.45 6.55 5 6 5ZM6 2C5.45 2 5 2.45 5 3C5 3.55 5.45 4 6 4C6.55 4 7 3.55 7 3C7 2.45 6.55 2 6 2ZM9 4C9.55 4 10 3.55 10 3C10 2.45 9.55 2 9 2C8.45 2 8 2.45 8 3C8 3.55 8.45 4 9 4ZM9 5C8.45 5 8 5.45 8 6C8 6.55 8.45 7 9 7C9.55 7 10 6.55 10 6C10 5.45 9.55 5 9 5ZM9 8C8.45 8 8 8.45 8 9C8 9.55 8.45 10 9 10C9.55 10 10 9.55 10 9C10 8.45 9.55 8 9 8Z"
      fill="white"
    />
    <path
      d="M4 9C4 9.55 3.55 10 3 10C2.45 10 2 9.55 2 9C2 8.45 2.45 8 3 8C3.55 8 4 8.45 4 9ZM3 5C2.45 5 2 5.45 2 6C2 6.55 2.45 7 3 7C3.55 7 4 6.55 4 6C4 5.45 3.55 5 3 5ZM3 2C2.45 2 2 2.45 2 3C2 3.55 2.45 4 3 4C3.55 4 4 3.55 4 3C4 2.45 3.55 2 3 2ZM6 4C6.55 4 7 3.55 7 3C7 2.45 6.55 2 6 2C5.45 2 5 2.45 5 3C5 3.55 5.45 4 6 4ZM6 5C5.45 5 5 5.45 5 6C5 6.55 5.45 7 6 7C6.55 7 7 6.55 7 6C7 5.45 6.55 5 6 5ZM6 8C5.45 8 5 8.45 5 9C5 9.55 5.45 10 6 10C6.55 10 7 9.55 7 9C7 8.45 6.55 8 6 8Z"
      fill="white"
    />
  </svg>
);
