import clsx from 'clsx';
import { map } from 'lodash';
import MenuDown from 'mdi-react/MenuDownIcon';
import { FormattedMessage } from 'react-intl';
import Dropdown, { ItemPropsT } from '../../../components/UI/Dropdown';
import styles from '../styles.module.sass';

export type GranularityT = 'day' | 'week' | 'month';

type Props = {
  activeGranularity: GranularityT;
  setActiveGranularity: (granularity: GranularityT) => void;
};

export const granularities: GranularityT[] = ['day', 'week', 'month'];

const Granularity = ({ setActiveGranularity, activeGranularity }: Props) => (
  <Dropdown
    className={styles.DropDownPanel}
    align="left"
    listClassName={styles.GranularityList}
    items={map(granularities, (g: GranularityT) => (props: ItemPropsT) => (
      <div
        {...props}
        key={`granularity-${g}`}
        onClick={() => setActiveGranularity(g)}
        className={clsx(
          styles.GranularityItem,
          activeGranularity === g && styles.GranularityItemActive,
          props.className
        )}
      >
        <FormattedMessage id={`containers.statistics.granularities.${g}`} />
      </div>
    ))}
  >
    {({ open }: Record<'open', boolean>) => (
      <div
        data-cy="stats-granularity-dropdown"
        className={clsx(
          styles.DropdownValue,
          open && styles.DropdownValueActive
        )}
      >
        <FormattedMessage
          id={`containers.statistics.granularities.${activeGranularity}`}
        />
        <MenuDown size={18} className={clsx(open && styles.CaretUp)} />
      </div>
    )}
  </Dropdown>
);

export default Granularity;
