import { schemaSchema } from '../models/schema.schema';
import { unknown, z } from 'zod';

/**
 * @noSchema
 */
const validateSchemaPayloadSchema = schemaSchema.partial();

/**
 * @noSchema
 */
type ValidateSchemaPayload = z.infer<typeof validateSchemaPayloadSchema>;

export const validate = (payload: ValidateSchemaPayload) => {
  return {
    endpoint: `schemas/validate`,
    method: 'POST',
    responseSchema: z.any(), // TODO: create proper response schema for validation
    payload,
    payloadSchema: validateSchemaPayloadSchema,
  } as const;
};
