import { DeleteForever } from '@rossum/ui/icons';
import { Button, CircularProgress } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { throwInfo } from '../../../redux/modules/messages/actions';
import { useSetDashboardData } from '../hooks/useFetchDashboardData';
import { usePurgeModal } from '../hooks/usePurgeModal';
import { usePurgeAnnotations } from '../selection-panel/hooks/usePurgeAnnotations';

type Props = {
  annotationIds: number[];
  queueUrl: string;
};

export const PurgeCTA = ({ annotationIds, queueUrl }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const setDashboardData = useSetDashboardData();

  const { mutate: purgeAnnotations, isLoading } = usePurgeAnnotations();
  const { node: purgeModal, openPurgeModal } = usePurgeModal();

  return (
    <>
      <Button
        color="secondary"
        disabled={isLoading || annotationIds.length === 0}
        variant="outlined"
        onClick={() =>
          openPurgeModal(
            {
              payload: { queue: queueUrl },
              annotationCount: annotationIds.length,
            },
            {
              onSubmit: ({ payload, annotationCount }) =>
                purgeAnnotations(payload, {
                  onSuccess: () => {
                    dispatch(
                      throwInfo('annotationPurged', undefined, {
                        count: annotationCount,
                      })
                    );
                    setDashboardData(response => {
                      if (!response) return response;

                      return {
                        ...response,
                        results: response.results.filter(
                          ({ id }) => !annotationIds.includes(id)
                        ),
                      };
                    });
                  },
                }),
            }
          )
        }
        startIcon={
          isLoading ? <CircularProgress size={16} /> : <DeleteForever />
        }
        data-cy="purgeAll-btn"
      >
        {intl.formatMessage({
          id: `containers.documents.buttons.purgeAll`,
        })}
      </Button>
      {purgeModal}
    </>
  );
};
