import { endpoints } from '@rossum/api-client';
import { EngineCreatePayload } from '@rossum/api-client/engines';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const createEngineKey = () => mutationKey(['create_engine'] as const);

export const useCreateEngine = () => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: createEngineKey(),
    mutationFn: (payload: EngineCreatePayload) =>
      api.request(endpoints.engines.create(payload)),
    onSuccess: engine => {
      showToastMessage('engineCreated');
      queryClient.setQueryData(engineKeys.detail(engine.id), engine);
    },
  });
};
