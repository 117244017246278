// Generated by ts-to-zod
import { z } from 'zod';
import { hookBaseSchema } from '../models/hook.schema';
import {
  functionConfigSchema,
  webhookConfigSchema,
} from '../models/hookConfig.schema';
import { extensionStatusSchema } from '../models/hookUtils.schema';

export const hookPatchPayloadSchema = z.union([
  z
    .object({
      type: z.literal('function'),
      config: functionConfigSchema.optional(),
      status: extensionStatusSchema.optional(),
      test: z
        .object({
          savedInput: z.string().optional(),
        })
        .optional(),
    })
    .and(hookBaseSchema.partial()),
  z
    .object({
      type: z.literal('webhook'),
      config: webhookConfigSchema.optional(),
    })
    .and(hookBaseSchema.partial()),
]);
