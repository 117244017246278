import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';
import { linebreak } from '../../../lib/formaterValues';
import styles from './styles.module.sass';

type Props = {
  icon: ReactNode;
  title: LocalizationKeys;
  text?: LocalizationKeys;
  inGraph?: boolean;
  inTable?: boolean;
};

const InfoPlaceholder = ({ title, text, icon, inGraph, inTable }: Props) => (
  <div
    className={clsx(
      styles.EmptyWrapper,
      inGraph && styles.EmptyInGraphWrapper,
      inTable && styles.EmptyInTableWrapper
    )}
  >
    <div className={styles.Icon}>{icon}</div>
    <div className={styles.PlaceholderTitle}>
      <FormattedMessage id={title} />
    </div>
    {text && (
      <div className={styles.PlaceholderText}>
        <FormattedMessage id={text} values={{ linebreak }} />
      </div>
    )}
  </div>
);

export default InfoPlaceholder;
