import { Typography } from '@rossum/ui/material';
import { Control, UseFormSetFocus } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MultiInput from '../../../../../components/ReactHookForm/controls/MultiInput';
import TextFieldControl from '../../../../../components/ReactHookForm/controls/TextFieldControl';
import FormTypeahead from '../../../../../components/ReactHookForm/FormTypeahead';
import { regions } from '../../../../../constants/regions';
import { docsLinks } from '../../../../../constants/values';
import { linebreak, link } from '../../../../../lib/formaterValues';
import { auroraEngineEnabledSelector } from '../../../../../redux/modules/organizationGroup/selectors';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';
import QueueSettingsLabel from './QueueSettingsLabel';

const sessionTimeoutInputs = [
  { name: 'sessionTimeoutHours', letter: 'h', placeholder: '00' },
  { name: 'sessionTimeoutMinutes', letter: 'm', placeholder: '00' },
  { name: 'sessionTimeoutSeconds', letter: 's', placeholder: '00' },
] as const;

type GeneralProps = {
  id: number | undefined;
  inbox?: string;
  control: Control<QueueSettingsData>;
  setFocus: UseFormSetFocus<QueueSettingsData>;
};

const General = ({ id, control, inbox, setFocus }: GeneralProps) => {
  const intl = useIntl();

  const auroraEngineEnabled = useSelector(auroraEngineEnabledSelector);

  const regionOptions = auroraEngineEnabled
    ? [
        {
          value: 'auto',
          label: intl.formatMessage({
            id: 'containers.settings.queues.documentRegion.auto',
          }),
        },
        ...regions,
      ]
    : regions;

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.general',
      })}
    >
      <div className={styles.Row}>
        <div>
          <Typography>
            {intl.formatMessage({
              id: 'containers.settings.queues.name.label',
            })}
          </Typography>
        </div>
        <div>
          <TextFieldControl
            ControllerProps={{ control, name: 'name' }}
            inputProps={{
              'data-cy': 'basic-setting-name-input',
            }}
            fullWidth
            FieldLabelProps={{ layout: 'none' }}
            placeholder={intl.formatMessage({
              id: 'containers.settings.queues.name.placeholder',
            })}
          />
        </div>
      </div>
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.documentRegion.label"
            tooltipId="containers.settings.queues.documentRegion.tooltip"
          />
        </div>
        <div data-cy="basic-setting-document-region-select">
          <FormTypeahead
            placeholder={intl.formatMessage({
              id: `containers.settings.queues.locale.placeholder`,
            })}
            control={control}
            options={regionOptions}
            getOptionValue={option => option.value}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.label
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            disableClearable
            name="locale"
            size="small"
          />
        </div>
      </div>

      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel
            titleId="containers.settings.queues.sessionTimeout.label"
            tooltipId="containers.settings.queues.sessionTimeout.tooltip"
          />
        </div>
        <div data-cy="basic-setting-session-timeout-select">
          <MultiInput
            control={control}
            inputs={sessionTimeoutInputs}
            setFocus={setFocus}
            maxLength={2}
            sanitizeValueOnBlur={e => {
              const trimmedValue = e.target.value.trim();
              return Number.isFinite(Number(trimmedValue))
                ? trimmedValue.padStart(2, '0')
                : '00';
            }}
          />
        </div>
      </div>
      {inbox && (
        <div className={styles.Row}>
          <div>
            <QueueSettingsLabel
              titleId="containers.settings.queues.inbox.label"
              tooltipId="containers.settings.queues.inbox.tooltip"
              tooltipValues={{
                link: link(docsLinks.inbox),
                linebreak,
              }}
            />
          </div>
          <div>
            <div className={styles.ReadOnlyValue}>{inbox}</div>
          </div>
        </div>
      )}
      {!!id && (
        <div className={styles.Row}>
          <div>
            <QueueSettingsLabel titleId="containers.settings.queues.id.label" />
          </div>
          <div>
            <div className={styles.ReadOnlyValue}>{id}</div>
          </div>
        </div>
      )}
    </PaperSection>
  );
};

export default General;
