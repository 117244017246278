import { useCallback } from 'react';
import { useResizeClamps } from '../hooks/useResizeClamps';
import { Point2D, Rectangle2DCoordinates } from '../utils/geometry';
import { resizeRectangle, ResizingDirection } from '../utils/resizing';
import { ResizeHandle } from './ResizeHandle';

type ResizeHandlesProps = {
  onResizeStart?: () => void;
  onResize?: (position: Rectangle2DCoordinates) => void;
  onResizeEnd?: (position: Rectangle2DCoordinates) => void;
  onResizeCancel?: () => void;
  position: Rectangle2DCoordinates;
  setDraftPosition: (coords: Rectangle2DCoordinates | null) => void;
  draftPosition: Rectangle2DCoordinates | null;
  outlineColor?: string;
  cornerHandleRadius: number;
  strokeWidth: number;
};

export const ResizeHandles = ({
  onResizeStart,
  onResize,
  onResizeEnd,
  onResizeCancel,
  position,
  draftPosition,
  setDraftPosition,
  outlineColor,
  cornerHandleRadius,
  strokeWidth,
}: ResizeHandlesProps) => {
  const resizeClamp = useResizeClamps(position, { minSize: 5 });

  const handleResizeStart = useCallback(() => {
    onResizeStart?.();
    setDraftPosition(position);
  }, [onResizeStart, position, setDraftPosition]);

  const handleResizeMove = useCallback(
    (direction: ResizingDirection, diff: Point2D) => {
      const newPosition: Rectangle2DCoordinates = resizeRectangle(
        position,
        direction,
        resizeClamp(direction)(diff)
      );

      setDraftPosition(newPosition);

      onResize?.(newPosition);
    },
    [onResize, position, resizeClamp, setDraftPosition]
  );

  const handleResizeEnd = useCallback(
    (_direction: ResizingDirection) => {
      if (draftPosition) {
        onResizeEnd?.(draftPosition);
      }
      setDraftPosition(null);
    },
    [draftPosition, onResizeEnd, setDraftPosition]
  );

  const handleResizeCancel = useCallback(() => {
    onResizeCancel?.();
    setDraftPosition(null);
  }, [onResizeCancel, setDraftPosition]);

  return (
    <>
      {(
        [
          'top',
          'right',
          'bottom',
          'left',
          'top-left',
          'top-right',
          'bottom-right',
          'bottom-left',
        ] as const
      ).map(direction => (
        <ResizeHandle
          cornerHandleRadius={cornerHandleRadius}
          strokeWidth={strokeWidth}
          key={direction}
          direction={direction}
          color={outlineColor}
          data-cy={`active-bbox-resize-handle-${direction}`}
          onResizeStart={handleResizeStart}
          onResizeMove={handleResizeMove}
          onResizeEnd={handleResizeEnd}
          onResizeCancel={handleResizeCancel}
        />
      ))}
    </>
  );
};
