import { Warning } from '@rossum/ui/icons';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { restrictedEngineCreationEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { useEnginesForQueueTemplate } from '../../engines/hooks/useEnginesForQueueTemplate';

type EngineSelectionFormProps = {
  onClose: () => void;
  onSubmit: (formModel: { engine: string }) => void;
  templateName: string;
  mutationLoading: boolean;
};

export const EngineSelectionForm = ({
  onClose,
  onSubmit,
  templateName,
  mutationLoading,
}: EngineSelectionFormProps) => {
  const intl = useIntl();

  const isEngineCreationRestricted = useSelector(
    restrictedEngineCreationEnabledSelector
  );

  const { data: engines, status: enginesStatus } =
    useEnginesForQueueTemplate(templateName);

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      engine: isEngineCreationRestricted
        ? ('legacyEngine' as const)
        : ('newEngine' as const),
    },
  });

  const fromScratchOptions = [
    {
      value: 'newEngine',
      label: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.newEngine',
      }),
      description: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.newEngine.description',
      }),
      disabled: isEngineCreationRestricted,
      disabledTooltip: isEngineCreationRestricted
        ? intl.formatMessage({
            id: 'features.engines.creation.disabled',
          })
        : '',
    },
  ];

  const legacyOptions = [
    {
      value: 'legacyEngine',
      label: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.legacyEngine',
      }),
      description: intl.formatMessage({
        id: 'components.addQueueDialog.step.engineDetail.legacyEngine.description',
      }),
    },
  ];

  return (
    <form id="queue-detail-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="body1" sx={{ pt: 1 }}>
            {intl.formatMessage({
              id: 'components.addQueueDialog.step.engineDetail',
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.addQueueDialog.step.engineDetail.description',
            })}
          </Typography>
        </Stack>
        <Stack spacing={3}>
          <Controller
            control={control}
            name="engine"
            render={({ field }) => {
              return (
                <FormControl component="fieldset" hiddenLabel>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Typography variant="overline">
                        {intl.formatMessage({
                          id: 'components.addQueueDialog.step.engineDetail.section.fromScratch',
                        })}
                      </Typography>
                      <RadioGroup
                        value={field.value}
                        onChange={(_, val) => field.onChange(val)}
                        sx={{ gap: 2 }}
                      >
                        {fromScratchOptions.map(option => (
                          <Stack
                            key={option.label}
                            spacing={1}
                            component={Paper}
                            sx={{
                              label: {
                                m: 0,
                                p: 2,
                                width: '100%',
                                justifyContent: 'space-between',
                              },
                            }}
                          >
                            <Tooltip title={option.disabledTooltip ?? ''}>
                              <FormControlLabel
                                value={option.value}
                                disabled={option.disabled}
                                control={<Radio size="small" />}
                                componentsProps={{
                                  typography: {
                                    variant: 'body2',
                                  },
                                }}
                                labelPlacement="start"
                                label={
                                  <Stack spacing={0.5} mr={1}>
                                    <Typography variant="h6">
                                      {option.label}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {option.description}
                                    </Typography>
                                  </Stack>
                                }
                              />
                            </Tooltip>
                          </Stack>
                        ))}
                      </RadioGroup>
                    </Stack>
                    <Stack spacing={1}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="overline">
                          {intl.formatMessage({
                            id: 'components.addQueueDialog.step.engineDetail.section.existing',
                          })}
                        </Typography>
                        {enginesStatus === 'loading' ? (
                          <CircularProgress size={16} />
                        ) : enginesStatus === 'error' ? (
                          <Tooltip
                            title={intl.formatMessage({
                              id: 'components.addQueueDialog.step.engineDetail.engines.loadingError',
                            })}
                          >
                            <Warning fontSize="small" color="error" />
                          </Tooltip>
                        ) : null}
                      </Stack>
                      <RadioGroup
                        value={field.value}
                        onChange={(_, val) => field.onChange(val)}
                        sx={{ gap: 2 }}
                      >
                        {engines.map(engine => (
                          <Stack
                            key={engine.id}
                            spacing={1}
                            component={Paper}
                            sx={{
                              label: {
                                m: 0,
                                p: 2,
                                width: '100%',
                                justifyContent: 'space-between',
                              },
                            }}
                          >
                            <FormControlLabel
                              value={engine.url}
                              control={<Radio size="small" />}
                              componentsProps={{
                                typography: {
                                  variant: 'body2',
                                },
                              }}
                              labelPlacement="start"
                              label={
                                <Stack spacing={0.5} mr={1}>
                                  <Typography variant="h6">
                                    {engine.name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {engine.description}
                                  </Typography>
                                </Stack>
                              }
                            />
                          </Stack>
                        ))}
                      </RadioGroup>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography variant="overline">
                        {intl.formatMessage({
                          id: 'components.addQueueDialog.step.engineDetail.section.legacy',
                        })}
                      </Typography>
                      <RadioGroup
                        value={field.value}
                        onChange={(_, val) => field.onChange(val)}
                        sx={{ gap: 2 }}
                      >
                        {legacyOptions.map(option => (
                          <Stack
                            key={option.label}
                            spacing={1}
                            component={Paper}
                            sx={{
                              label: {
                                m: 0,
                                p: 2,
                                width: '100%',
                                justifyContent: 'space-between',
                              },
                            }}
                          >
                            <FormControlLabel
                              value={option.value}
                              control={<Radio size="small" />}
                              componentsProps={{
                                typography: {
                                  variant: 'body2',
                                },
                              }}
                              labelPlacement="start"
                              label={
                                <Stack spacing={0.5} mr={1}>
                                  <Typography variant="h6">
                                    {option.label}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {option.description}
                                  </Typography>
                                </Stack>
                              }
                            />
                          </Stack>
                        ))}
                      </RadioGroup>
                    </Stack>
                  </Stack>
                </FormControl>
              );
            }}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              data-cy="queue-form-button-cancel"
              disabled={mutationLoading}
            >
              {intl.formatMessage({
                id: 'components.queueDetailForm.cancelButton',
              })}
            </Button>
            <Button
              variant="contained"
              startIcon={
                mutationLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null
              }
              type="submit"
              disabled={mutationLoading}
              data-cy="queue-form-button-submit"
            >
              {intl.formatMessage({
                id: 'components.queueDetailForm.finishButton',
              })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
