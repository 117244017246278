import { AnyAction } from 'redux';
import Immutable, { Immutable as ImmutableType } from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { State } from '../../../types/statistics';
import { fetchStatisticsFulfilled, showTmpStatistics } from './actions';

const initialState = Immutable({
  series: [],
  totals: {
    importedCount: 0,
    deletedCount: 0,
    exportedCount: 0,
    turnaroundAvgS: 0,
    correctionsPerDocumentAvg: 0,
    exportedOnTimeCount: 0,
    exportedLateCount: 0,
    timePerDocumentAvgS: 0,
    rejectedAutomaticallyCount: 0,
    rejectedManuallyCount: 0,
  },
  isTmpState: false,
});

export default (
  state: ImmutableType<State> = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case getType(showTmpStatistics):
      return state.merge(action.payload).set('isTmpState', true);
    case getType(fetchStatisticsFulfilled):
      return state.merge(action.payload).set('isTmpState', false);
    default:
      return state;
  }
};
