import { CheckCircleOutline, Error } from '@rossum/ui/icons';
import { CircularProgress, Skeleton, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { TaskProgress } from './components/TaskProgress';
import { AsyncTasks } from './TaskContext';

type AsyncTaskProgressProps = {
  task: AsyncTasks[number];
};

export const AsyncTaskProgress = ({ task }: AsyncTaskProgressProps) => {
  const intl = useIntl();

  return task.status === 'error' ? null : task.status === 'loading' ? (
    <Skeleton variant="rectangular" width="100%" height={25} />
  ) : (
    <TaskProgress
      primaryLabel={
        task.data.type === 'documents_download'
          ? task.data.content?.fileName ??
            intl.formatMessage({
              id: 'components.asyncTaskProgress.progress',
            })
          : task.data.metadata?.fileName ??
            intl.formatMessage({
              id: 'components.asyncTasksDialog.item.title.placeholder',
            })
      }
      secondaryLabel={
        task.data.type === 'upload_created'
          ? task.data.metadata?.queueName &&
            intl.formatMessage(
              {
                id: `components.asyncTasksDialog.item.subtitle.upload.${task.data.status}`,
              },
              {
                queueName: <span>{task.data.metadata.queueName}</span>,
              }
            )
          : task.data.type === 'documents_download'
            ? intl.formatMessage({
                id: 'components.asyncTasksDialog.item.subtitle.download',
              })
            : ''
      }
      progressLabel={intl.formatMessage({
        id: `components.asyncTasksDialog.item.progress.${task.data.status}`,
      })}
      progressIcon={
        task.data.status === 'failed' ? (
          <Tooltip title={task.data.detail}>
            <Error color="error" fontSize="small" />
          </Tooltip>
        ) : task.data.status === 'succeeded' ? (
          <CheckCircleOutline color="success" fontSize="small" />
        ) : (
          <CircularProgress size={16} color="inherit" />
        )
      }
    />
  );
};
