import { Message } from '@rossum/api-client/shared';
import { Stack } from '@rossum/ui/material';
import InfoIcon from 'mdi-react/InformationOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { UnpaidFeatureOverlay } from '../../../features/pricing/components/UnpaidFeatureOverlay';
import styles from '../style.module.sass';

type Props = {
  status: 'deleted' | 'importing' | 'failedImport';
  annotationMessages: Array<Message> | undefined;
  restrictedAccess: boolean;
};

const UnableToAnnotate = ({
  restrictedAccess,
  status,
  annotationMessages,
}: Props) => {
  const intl = useIntl();
  const isImporting = status === 'importing';

  if (restrictedAccess) {
    return (
      <Stack textAlign="center" height={1}>
        <UnpaidFeatureOverlay
          title={intl.formatMessage({
            id: 'features.pricing.unpaidFeatureOverlay.title.documentRestricted',
          })}
          dataCy="unpaid-overlay-document-restricted-sidebar"
          sx={{ p: 4 }}
        />
      </Stack>
    );
  }

  return (
    <div className={styles.UnableToAnnotate}>
      {isImporting ? (
        <LoadingIcon
          data-cy={`no-data-icon-${status}`}
          className={styles.ProgressIcon}
        />
      ) : (
        <div
          className={styles.AlertIconWrapper}
          data-cy={`no-data-icon-${status}`}
        >
          <InfoIcon size={25} className={styles.AlertIcon} />
        </div>
      )}
      <div className={styles.UnableTitle} data-cy={`no-data-title-${status}`}>
        <FormattedMessage
          id={`components.documentValidation.sidebar.unable.${status}`}
        />
      </div>
      {(isImporting || status === 'deleted') && (
        <div className={styles.UnableText}>
          <FormattedMessage
            id={`components.documentValidation.sidebar.unable.${status}.text`}
          />
        </div>
      )}
      {annotationMessages && (
        <div className={styles.AlertMessages}>
          {annotationMessages.map(({ content }) => (
            <div key={content} data-cy="no-data-message">
              {content}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UnableToAnnotate;
