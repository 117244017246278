type ErrorMessages = Array<Record<string, Array<string>>>;
type UnknownObject = Partial<Record<string, unknown>>;

export const collectStringArrays = (
  obj: UnknownObject,
  prevKey?: string
): ErrorMessages =>
  Object.keys(obj).reduce<ErrorMessages>((leaves, key) => {
    const value = obj[key];
    const currentKey = prevKey === undefined ? key : [prevKey, key].join('.');

    if (Array.isArray(value)) {
      // assuming all elements of the array are of the same type
      if (typeof value[0] === 'string') {
        return leaves.concat([{ [currentKey]: value }]);
      }

      if (typeof value[0] === 'object') {
        const flattenedLeaves = value.flatMap((o: UnknownObject, index) => {
          const currentKeyWithIndex = [currentKey, index].join('.');
          return collectStringArrays(o, currentKeyWithIndex);
        });
        return leaves.concat(flattenedLeaves);
      }

      return leaves;
    }

    if (typeof value === 'object' && value !== null) {
      return leaves.concat(collectStringArrays(value, currentKey));
    }

    return leaves;
  }, []);
