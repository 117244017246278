import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Trigger, TriggerEventType } from '../models/trigger';
import { triggerSchema } from '../models/trigger.schema';
import { createTriggerPayloadSchema } from './create.schema';

export type CreateTriggerPayload = {
  queue: Url;
  event: TriggerEventType;
  // TODO what to use here?
  condition?: unknown;
  emailTemplates?: Url[];
  deleteRecommendations?: Url[];
};

export const create = (payload: CreateTriggerPayload) => {
  return {
    endpoint: `/triggers`,
    method: 'POST',
    responseSchema: triggerSchema,
    payloadSchema: createTriggerPayloadSchema,
    payload,
  } as const;
};
