import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Modal } from '../../../types/modal';
import { RootActionType } from '../../rootActions';
import { logoutUser } from '../auth/actions';
import { closeModal, openModal } from './actions';

const initialState = Immutable<Modal>({
  confirmAction: undefined,
  confirmType: 'Primary',
  isOpen: false,
  onConfirm: undefined,
  textId: undefined,
  values: undefined,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(openModal): {
      const { textId, confirmAction, confirmType, values, onConfirm } =
        action.payload;
      return state
        .set('confirmAction', confirmAction)
        .set('confirmType', confirmType)
        .set('isOpen', true)
        .set('onConfirm', onConfirm)
        .set('textId', textId)
        .set('values', values);
    }

    case getType(closeModal):
      return (
        initialState
          // Translations is not cleared because of closing animation
          .set('textId', state.getIn(['textId']))
          .set('values', state.getIn(['values']))
          .set('confirmType', state.getIn(['confirmType']))
      );

    case getType(logoutUser):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
