export const getPartId = (
  optionId: string | undefined,
  part: 'schemaId' | 'sectionId' | 'multivalueId'
) => {
  if (!optionId) return undefined;

  const partIdToken = optionId
    .split('.')
    .find(partSegment => partSegment.includes(`${part}:`));

  const partId = partIdToken ? partIdToken.split(':')[1] : undefined;

  return partId;
};
