// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';
import { workflowRunStatusSchema } from '../models/workflowRun.schema';

export const workflowRunsListQuerySchema = z
  .object({
    annotation: z.union([idSchema, z.array(idSchema)]),
    workflowStatus: workflowRunStatusSchema,
  })
  .partial()
  .and(paginationQuerySchema);
