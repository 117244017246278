import { SchemaDatapointEnumOption } from '@rossum/api-client/schemas';
import { Typography } from '@rossum/ui/material';
import {
  GridBooleanCell,
  GridRenderCellParams,
} from '@rossum/ui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { text } from '../../../constants';
import { GridRowModel } from '../../rows/rowTypes';
import { EnumAndFormulaCellValue } from '../types';

export const renderBooleanCell = (
  params: GridRenderCellParams<GridRowModel>
) =>
  params.isEditable ? <GridBooleanCell {...params} /> : text.cellNotEditable;

// our custom valueGetter is sending value that isn't in the options
// params.formattedValue resolves to empty string and params.value to undefined
// this allows the content for empty cell from dataGridStyles.ts to be used
export const renderSingleSelectCell = (
  params: GridRenderCellParams<GridRowModel>
) => (
  <div className="singleSelectCellContent">
    {(params.formattedValue || params.value) ??
      (params.isEditable ? undefined : text.cellNotEditable)}
  </div>
);

export const renderCellWithCount =
  (
    setOptionsForEnumDialog?: Dispatch<
      SetStateAction<{
        options: SchemaDatapointEnumOption[];
        value: EnumAndFormulaCellValue;
      } | null>
    >
  ) =>
  (params: GridRenderCellParams<GridRowModel>) => {
    const intl = useIntl();
    return (
      <Typography
        component="span"
        sx={{
          cursor: 'pointer',
        }}
        alignItems="center"
        color="secondary"
        onClick={e => {
          e.stopPropagation();

          if ('options' in params.row && setOptionsForEnumDialog) {
            setOptionsForEnumDialog({
              options: params.row.options,
              value: params.value,
            });
          }
        }}
      >
        {params.value?.label ? (
          <>
            <Typography component="span" variant="body2">
              {params.value.label}
            </Typography>
            <Typography color="text.secondary" component="span" variant="body2">
              {intl.formatMessage(
                {
                  id: 'features.fieldManager.fieldDetail.columns.renderCell.textSecondary',
                },
                { count: params.value.count }
              )}
            </Typography>
          </>
        ) : params.isEditable ? undefined : (
          <Typography sx={{ color: 'text.disabled', cursor: 'not-allowed' }}>
            {text.cellNotEditable}
          </Typography>
        )}
      </Typography>
    );
  };

export const renderFormulaCellWithCount =
  (
    setFormulaForFormulaDrawer?: Dispatch<
      SetStateAction<{
        formula: string;
        value: EnumAndFormulaCellValue;
      } | null>
    >
  ) =>
  (params: GridRenderCellParams<GridRowModel>) => (
    <Typography
      component="span"
      alignItems="center"
      color="secondary"
      sx={{
        cursor: 'pointer',
      }}
      onClick={e => {
        e.stopPropagation();

        if (
          'formula' in params.row &&
          params.row.formula &&
          setFormulaForFormulaDrawer
        ) {
          setFormulaForFormulaDrawer({
            formula: params.row.formula,
            value: {
              label: params.value.label,
              count: params.value.count,
            },
          });
        }
      }}
    >
      {params.value?.label ? (
        <>
          <Typography component="span" variant="body2">
            {params.value.label}
          </Typography>
          <Typography color="text.secondary" component="span" variant="body2">
            {` (${params.value.count} characters)`}
          </Typography>
        </>
      ) : params.isEditable ? undefined : (
        <Typography sx={{ color: 'text.disabled', cursor: 'not-allowed' }}>
          {text.cellNotEditable}
        </Typography>
      )}
    </Typography>
  );
