// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const dedicatedEngineSchemaCreatePayloadSchema = z.object({
  content: z.object({
    trainingQueues: z.array(urlSchema),
    fields: z.array(z.unknown()),
  }),
});
