import { Label } from '@rossum/api-client/labels';
import {
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import { boldText } from '../../../lib/formaterValues';
import { useCreateLabel } from '../hooks/useCreateLabel';
import { useDeleteLabel } from '../hooks/useDeleteLabel';
import { useEditLabel } from '../hooks/useEditLabel';

type LabelDialogProps = {
  id: number;
  name: string;
  onCancel: () => void;
};

const DIALOG_WIDTH = 448;

const handleDialogSubmit = (onSubmit: () => void) => (e: FormEvent) => {
  e.preventDefault();
  onSubmit();
};

export const EditLabelDialog = ({ name, id, onCancel }: LabelDialogProps) => {
  const [labelName, setLabelName] = useState<string>(name);

  const intl = useIntl();
  const { mutate: editLabel, isLoading } = useEditLabel();

  return (
    <form
      onSubmit={handleDialogSubmit(() =>
        editLabel({ labelId: id, name: labelName }, { onSuccess: onCancel })
      )}
    >
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'containers.settings.labels.dialogs.editTitle',
        })}
        dialogColor="primary"
        onCancel={onCancel}
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
              startIcon: isLoading ? <CircularProgress size={14} /> : null,
              disabled: isLoading || !labelName,
            }}
            confirmButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.editButton',
            })}
            cancelButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.cancelButton',
            })}
            dataCyConfirm="edit-label-dialog-confirm-button"
            dataCyCancel="edit-label-dialog-cancel-button"
          />
        }
        dataCy="edit-label-dialog"
      >
        <Stack spacing={2} minWidth={DIALOG_WIDTH}>
          <Typography>
            {intl.formatMessage({
              id: 'containers.settings.labels.dialogs.fields.labelName.description',
            })}
          </Typography>
          <TextField
            label={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.fields.labelName.placeholder',
            })}
            value={labelName}
            onChange={e => setLabelName(e.target.value)}
            size="small"
            autoFocus
          />
        </Stack>
      </ActionDialog>
    </form>
  );
};

export const CreateLabelDialog = ({
  onCancel,
  onSuccess,
}: Pick<LabelDialogProps, 'onCancel'> & {
  onSuccess?: (label: Label) => void;
}) => {
  const [labelName, setLabelName] = useState<string>('');

  const intl = useIntl();

  const { mutate: createLabel, isLoading } = useCreateLabel();
  return (
    <form
      onSubmit={handleDialogSubmit(() =>
        createLabel(
          { name: labelName },
          {
            onSuccess: data => {
              onSuccess?.(data);
              onCancel();
            },
          }
        )
      )}
    >
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'containers.settings.labels.dialogs.createTitle',
        })}
        dialogColor="primary"
        onCancel={onCancel}
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
              startIcon: isLoading ? <CircularProgress size={14} /> : null,
              disabled: isLoading || !labelName,
            }}
            confirmButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.createButton',
            })}
            cancelButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.cancelButton',
            })}
            dataCyConfirm="create-label-dialog-confirm-button"
            dataCyCancel="create-label-dialog-cancel-button"
          />
        }
        dataCy="create-label-dialog"
      >
        <Stack spacing={1} minWidth={DIALOG_WIDTH}>
          <Typography>
            {intl.formatMessage({
              id: 'containers.settings.labels.dialogs.fields.labelName.description',
            })}
          </Typography>
          <TextField
            label={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.fields.labelName.placeholder',
            })}
            value={labelName}
            onChange={e => setLabelName(e.target.value)}
            autoFocus
            size="small"
          />
        </Stack>
      </ActionDialog>
    </form>
  );
};

export const DeleteLabelDialog = ({ name, onCancel, id }: LabelDialogProps) => {
  const { mutate: deleteLabel, isLoading } = useDeleteLabel();
  const intl = useIntl();

  return (
    <form
      onSubmit={handleDialogSubmit(() =>
        deleteLabel(id, { onSuccess: onCancel })
      )}
    >
      <ActionDialog
        dialogTitle={intl.formatMessage(
          {
            id: 'containers.settings.labels.dialogs.deleteTitle',
          },
          { labelName: name }
        )}
        dialogColor="error"
        onCancel={onCancel}
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
              startIcon: isLoading ? <CircularProgress size={14} /> : null,
              disabled: isLoading,
              autoFocus: true,
            }}
            confirmButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.deleteButton',
            })}
            cancelButtonText={intl.formatMessage({
              id: 'containers.settings.labels.dialogs.cancelButton',
            })}
            dataCyConfirm="delete-label-dialog-confirm-button"
            dataCyCancel="delete-label-dialog-cancel-button"
          />
        }
        dataCy="delete-label-dialog"
      >
        <Stack minWidth={DIALOG_WIDTH}>
          <Typography>
            {intl.formatMessage(
              {
                id: 'containers.settings.labels.dialogs.deleteDescription',
              },
              {
                labelName: name,
                boldText,
              }
            )}
          </Typography>
        </Stack>
      </ActionDialog>
    </form>
  );
};
