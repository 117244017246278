// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { relationTypeSchema } from '../models/relations.schema';

export const createRelationPayloadSchema = z.object({
  type: relationTypeSchema,
  parent: urlSchema.optional(),
  annotations: z.array(urlSchema),
});
