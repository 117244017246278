import { Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';

export const TileLinkSkeleton = () => {
  const tilesWidths = useRef(
    Array.from({ length: 3 }, () => ({
      title: random(100, 250),
      description: random(350, 750),
    }))
  ).current;

  return (
    <Stack spacing={1} width={1}>
      {tilesWidths.map(({ title, description }, index) => (
        <Stack
          // eslint-disable-next-line react/no-array-index-key
          key={`${title}-${index}`}
          spacing={1}
          sx={{
            px: 4,
            py: 2,
            borderRadius: 1,
            backgroundColor: t => t.palette.background.paper,
          }}
        >
          <Skeleton width={title} height={25} />
          <Skeleton width={description} />
        </Stack>
      ))}
    </Stack>
  );
};
