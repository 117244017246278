import { ID, Url } from './basic';

export const LABEL_REJECTION = 'rejection';
const LABEL_AUTOMATIC_REJECTION = 'automatic_rejection';
export const LABEL_REJECTED = 'rejected';
const LABEL_AUTOMATIC_STATUS_CHANGED = 'automatic_status_changed_info';
// TODO: check whether these labels actually exists once they added at BE side
export const LABEL_FORWARD = 'forwarded';
const LABEL_REPLY = 'reply';
export const LABEL_POSTPONED = 'postponed';

type KnownEmailLabels =
  | typeof LABEL_REJECTION
  | typeof LABEL_AUTOMATIC_REJECTION
  | typeof LABEL_REJECTED
  | typeof LABEL_AUTOMATIC_STATUS_CHANGED
  | typeof LABEL_FORWARD
  | typeof LABEL_POSTPONED
  | typeof LABEL_REPLY;

// This way we can preserve auto complete for EmailLabel values, otherwise
// EmailLabel | string would be treated as a string.
// https://github.com/microsoft/TypeScript/issues/29729#issuecomment-567871939
export type EmailLabels = Array<
  KnownEmailLabels | (string & Record<never, never>)
>;

type AnnotationCount =
  | 'annotations'
  | 'annotationsProcessed'
  | 'annotationsRejected'
  | 'annotationsUnprocessed';

type EmailType = 'incoming' | 'outgoing';

type AnnotationCounts = Record<AnnotationCount, number | null> & {
  annotationsPurged?: number | null;
};

export type EmailAddress = { email: string; name?: string | null };

// https://api.elis.rossum.ai/docs/#email
export type EmailData = {
  annotationCounts: AnnotationCounts;
  id: ID;
  url: Url;
  inbox: Url | null;
  documents: Array<Url>;
  children: Array<Url>;
  createdAt: string;
  creator: Url | null;
  labels: EmailLabels;
  lastThreadEmailCreatedAt?: string;
  subject: string;
  from: EmailAddress;
  to: Array<Partial<EmailAddress>>;
  cc: Array<Partial<EmailAddress>>;
  bcc: Array<Partial<EmailAddress>>;
  bodyTextPlain: string | null;
  bodyTextHtml: string | null;
  metadata?: unknown;
  type: EmailType;
  queue: Url;
  relatedAnnotations: Array<Url>;
  relatedDocuments: Array<Url>;
  annotations: Array<Url>;
  emailThread: Url | null;
  parent: Url | null;
};
