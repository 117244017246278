import { Url } from '@rossum/api-client';
import { EditRelation, Relation } from '@rossum/api-client/relations';

export const resolveAnnotationEdits = (
  annotationUrl: Url,
  relations: Array<Relation>
) =>
  Object.values(relations).find(
    (relationData): relationData is EditRelation =>
      relationData.annotations.includes(annotationUrl) &&
      relationData.type === 'edit'
  );
