// Generated by ts-to-zod
import { z } from 'zod';

export const timeAndCorrectionsPerFieldSchema = z.object({
  schemaId: z.string(),
  label: z.string(),
  totalCount: z.number(),
  correctedRatio: z.number(),
  timeSpentAvgS: z.number(),
});

export const valuesBaseSchema = z
  .object({
    importedCount: z.number().nullable(),
    exportedCount: z.number().nullable(),
    deletedCount: z.number().nullable(),
    confirmedCount: z.number().nullable(),
    rejectedAutomaticallyCount: z.number().nullable(),
    rejectedCount: z.number().nullable(),
    rejectedManuallyCount: z.number().nullable(),
    turnaroundAvgS: z.number().nullable(),
    correctionsPerDocumentAvg: z.number().nullable(),
    exportedOnTimeCount: z.number().nullable(),
    exportedLateCount: z.number().nullable(),
    exportedAutomatedCount: z.number().nullable(),
    timePerDocumentAvgS: z.number().nullable(),
    usage: z.number().nullable(),
  })
  .partial();

const valuesSchema = z
  .object({
    timeAndCorrectionsPerField: z.array(timeAndCorrectionsPerFieldSchema),
  })
  .and(valuesBaseSchema);

export const serieSchema = z.object({
  beginDate: z.string(),
  endDate: z.string(),
  values: valuesSchema,
});

export const usageReportSchema = z.object({
  series: z.array(serieSchema),
  totals: valuesBaseSchema,
});
