import { useSelector } from 'react-redux';
import { isTrialSelector } from '../../../../../redux/modules/organization/selectors';
import {
  displayFirstUploadWarningSelector,
  firstTimeUserSelector,
} from '../../../../../redux/modules/user/selectors';

export const useUploadWarning = ({
  isOnQueueLevel,
}: {
  isOnQueueLevel: boolean;
}) => {
  const isTrial = useSelector(isTrialSelector);
  const isUploadWarningEnabled = useSelector(displayFirstUploadWarningSelector);
  const isUserOnboarding = useSelector(firstTimeUserSelector);

  return (
    isOnQueueLevel && !isUserOnboarding && !!isTrial && isUploadWarningEnabled
  );
};
