import { HttpError } from '@rossum/api-client/errors';
import { Stack, Typography } from '@rossum/ui/material';
import { fromPairs } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { apiUrl } from '../../../../../constants/config';
import { ResultsPerStatus } from '../../../hooks/usePatchSchemas';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { resolveQueuesDisplayedValue } from '../columns/filterOperators';
import { collectStringArrays } from './collectStringArrays';
import { ErrorKey } from './ErrorKey';

type Props = {
  rejectedRequests: ResultsPerStatus['rejected'];
  schemas: FlatSchemaWithQueues[];
};

export const ErrorDialogContent = ({ rejectedRequests, schemas }: Props) => {
  const intl = useIntl();
  const rejectedSchemaErrors = rejectedRequests.filter(
    (rejected): rejected is HttpError => {
      return (
        'data' in rejected &&
        'endpoint' in rejected &&
        'name' in rejected &&
        rejected.name === 'HttpError'
      );
    }
  );

  const schemasUrlMap = useMemo(
    () => fromPairs(schemas.map(schema => [schema.url, schema])),
    [schemas]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.errors.errorDialogContent.description',
        })}
      </Typography>
      <Stack spacing={2}>
        {rejectedSchemaErrors.map(({ endpoint, data }) => {
          const schema = schemasUrlMap[`${apiUrl}${endpoint}`];
          if (!schema) {
            return null;
          }

          if (
            !data ||
            typeof data !== 'object' ||
            !('constructor' in data) ||
            data.constructor !== Object
          ) {
            return null;
          }

          const errors = collectStringArrays(data);

          return (
            <Stack key={endpoint}>
              <Typography sx={{ fontWeight: 'bold' }} key={endpoint}>
                {resolveQueuesDisplayedValue(schema.queues, schema.id)}
              </Typography>
              {errors.map(error =>
                Object.entries(error).map(([key, value]) => (
                  <div key={key}>
                    <ErrorKey pathKey={key} />{' '}
                    <Typography component="span" variant="body2">
                      {value.join(', ')}
                    </Typography>
                  </div>
                ))
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
