// Generated by ts-to-zod
import { z } from 'zod';
import { queueSchema } from '../../queues/models/queue.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const engineQueueStatsQuerySchema = paginationQuerySchema;

export const engineQueueStatsSideloadSchema = z.object({
  queues: z.array(queueSchema),
});
