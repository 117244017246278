import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  EmailTemplate,
  EmailTemplatePayloadBase,
} from '../models/emailTemplate';
import { emailTemplateSchema } from '../models/emailTemplate.schema';
import { emailTemplatePatchPayloadSchema } from './patch.schema';

export type EmailTemplatePatchPayload = Partial<EmailTemplatePayloadBase>;

export const patch = (
  emailTemplateId: ID,
  payload: EmailTemplatePatchPayload
) => {
  return {
    endpoint: `/email_templates/${emailTemplateId}`,
    method: 'PATCH',
    responseSchema: emailTemplateSchema,
    payloadSchema: emailTemplatePatchPayloadSchema,
    payload,
  } as const;
};
