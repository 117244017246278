// Generated by ts-to-zod
import { z } from 'zod';

export const dedicatedEngineSchemaGetQuerySchema = z
  .object({
    fields: z.array(
      z.union([z.literal('id'), z.literal('url'), z.literal('content')])
    ),
  })
  .partial();
