import { isFinite } from 'lodash';

const getNonNumberParts = (
  [first, ...restOfPath]: Array<string>,
  acc?: string
): string | undefined =>
  isFinite(Number(first))
    ? acc
    : getNonNumberParts(
        restOfPath,
        acc === undefined ? first : `${first}.${acc}`
      );

export const getFirstNonNumericKeysFromRight = (pathKey: string) => {
  const nonNumberParts = getNonNumberParts(pathKey.split('.').reverse());

  if (nonNumberParts === undefined) {
    return pathKey;
  }

  return nonNumberParts;
};
