import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { makeEpic } from '../utils';
import { clearActions, resumeValidation } from './actions';

const resumeValidationEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(resumeValidation)),
    map(() => state$.value.beforeLeave.current),
    filter(isNotNullOrUndefined),
    switchMap(({ nextAction }) => from([clearActions(), nextAction]))
  )
);

export default combineEpics(resumeValidationEpic);
