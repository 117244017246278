import { combineEpics } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { updateUiSettings } from '../user/actions';
import { isActionOf, makeEpic } from '../utils';
import { finishProductTourAction, skipProductTourAction } from './actions';

const finishProductTourEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(finishProductTourAction)),

    map(({ payload: { tourName, currentStepIndex } }) =>
      updateUiSettings({
        productTours: {
          [tourName]: {
            result: 'FINISHED',
            lastStep: currentStepIndex,
            annotationId: state$.value.annotation.id,
          },
        },
      })
    )
  )
);

const skipProductTourEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(skipProductTourAction)),
    map(({ payload: { tourName, currentStepIndex } }) =>
      updateUiSettings({
        productTours: {
          [tourName]: {
            result: 'SKIPPED',
            lastStep: currentStepIndex,
            annotationId: state$.value.annotation.id,
          },
        },
      })
    )
  )
);

export default combineEpics(finishProductTourEpic, skipProductTourEpic);
