import {
  CustomEmailTemplateType,
  DefaultEmailTemplateType,
  EmailTemplate,
  EmailTemplateType,
} from '@rossum/api-client/emailTemplates';
import { IntlShape } from 'react-intl';
import { LocalizationKeys } from '../../../../i18n';
import {
  TriggerClientEvent,
  TriggerClientModel,
  triggerEvents,
} from '../mappers/templateMapper';

export const isRejectionEmailTemplateType = (type: EmailTemplateType) =>
  type === 'rejection' || type === 'rejection_default';

export const isDefaultRejectionEmailTemplateType = (type: EmailTemplateType) =>
  type === 'rejection_default';

export const isDefaultEmailTemplateType = (
  type: EmailTemplateType
): type is DefaultEmailTemplateType =>
  type === 'email_with_no_processable_attachments' ||
  type === 'rejection_default';

export const defaultEmailTemplateNames: Record<
  EmailTemplateType,
  LocalizationKeys | null
> = {
  email_with_no_processable_attachments:
    'containers.settings.queues.email.automaticProcessing.noDocuments.label',
  rejection_default:
    'containers.settings.queues.email.documentRejection.toggle',
  rejection: null,
  custom: null,
};

export type EmailTemplateDrawerAction =
  | { type: 'update'; emailTemplate: EmailTemplate }
  | { type: 'create'; emailTemplateType: CustomEmailTemplateType };

export type EmailTemplateFormModel = {
  to: string[];
  cc: string[];
  bcc: string[];
  message: string;
  name: string;
  subject: string;
  automate: boolean;
  trigger: TriggerClientModel | null;
};

const rejectionTriggerEvents: TriggerClientEvent[] = [
  'document_extracted',
  'any_field_missing',
  'at_least_one_missing',
];

type AutomationPerEmailType = {
  canAutomate: boolean;
  allowedTriggers: readonly TriggerClientEvent[];
};

export const automationPerEmailTemplateType: Record<
  EmailTemplateType,
  AutomationPerEmailType
> = {
  rejection_default: { canAutomate: false, allowedTriggers: [] },
  email_with_no_processable_attachments: {
    canAutomate: true,
    allowedTriggers: [],
  },
  rejection: { canAutomate: true, allowedTriggers: rejectionTriggerEvents },
  custom: { canAutomate: true, allowedTriggers: triggerEvents },
};

export const resolveTemplateTypeFromDrawerAction = (
  action: EmailTemplateDrawerAction
) => {
  return action.type === 'update'
    ? action.emailTemplate.type
    : action.emailTemplateType;
};

const resolveEmailTemplateDefaultValues = (
  emailTemplate: EmailTemplate | null,
  defaultName: string
) => {
  return {
    to: emailTemplate?.to?.map(({ email }) => email) ?? [],
    cc: emailTemplate?.cc?.map(({ email }) => email) ?? [],
    bcc: emailTemplate?.bcc?.map(({ email }) => email) ?? [],
    message: emailTemplate?.message || '<p></p>',
    subject: emailTemplate?.subject || '',
    name: emailTemplate?.name || defaultName || '',
    automate: emailTemplate?.automate || false,
  };
};

const resolveTriggerDefaultValues = (
  trigger: TriggerClientModel | null,
  defaultEvent: TriggerClientEvent
) => {
  return {
    id: trigger?.id,
    event: trigger?.event || defaultEvent,
    fields: trigger?.fields ?? [],
    condition: {
      field: trigger?.condition?.field ?? '',
      operator: trigger?.condition?.operator ?? 'contains',
      value: trigger?.condition?.value ?? '',
    },
  };
};

export const resolveDefaultValues = (
  action: EmailTemplateDrawerAction,
  trigger: TriggerClientModel | null,
  intl: IntlShape
): EmailTemplateFormModel => {
  const templateType = resolveTemplateTypeFromDrawerAction(action);
  const emailTemplate = action.type === 'update' ? action.emailTemplate : null;
  const defaultEvent =
    automationPerEmailTemplateType[templateType].allowedTriggers[0];

  const nameTranslationId = defaultEmailTemplateNames[templateType];
  const defaultName = nameTranslationId
    ? intl.formatMessage({ id: nameTranslationId })
    : '';

  return {
    ...resolveEmailTemplateDefaultValues(emailTemplate, defaultName),
    trigger: defaultEvent
      ? resolveTriggerDefaultValues(trigger, defaultEvent)
      : null,
  };
};
