import { Url } from '@rossum/api-client';
import {
  AppConfig,
  customExtension,
  ExtensionEvent,
  extensionFunctionType,
  extensionWebhookType,
  FunctionRuntime,
  rossumStore,
} from '@rossum/api-client/hooks';
import { Pagination } from './pagination';

// This also defines their order in UI.
export const sideloadOptions = [
  'schemas',
  'modifiers',
  'queues',
  'emails',
  'related_emails',
  'relations',
  'child_relations',
  'suggested_edits',
  'assignees',
  'pages',
  'notes',
  'labels',
  'automation_blockers',
] as const;

export type ExtensionType =
  | typeof extensionWebhookType
  | typeof extensionFunctionType;

export type SideloadValues = (typeof sideloadOptions)[number];
export type ExtensionSourceT = typeof rossumStore | typeof customExtension;

type ExtensionCommon = {
  active: boolean;
  description: string;
  events: ExtensionEvent[];
  id: number;
  metadata: Record<string, unknown>;
  name: string;
  queues: string[];
  runAfter: Array<Url>;
  settings: Record<string, unknown>;
  settingsSchema?: Record<string, unknown>;
  secrets?: Record<string, unknown>;
  secretsSchema?: Record<string, unknown>;
  sideload: Array<SideloadValues>;
  tokenOwner: Url;
  type: ExtensionType;
  url: string;
  extensionSource: ExtensionSourceT;
  guide: string | null;
  readMoreUrl: string | null;
};

type ExtensionWebhookConfig = {
  type: typeof extensionWebhookType;
  status: undefined;
  config: {
    private?: false;
    url?: string;
    insecure_ssl?: boolean;
    secret?: string;
    schedule?: { cron: string };
    timeout_s?: number;
    app?: AppConfig | null;
    payloadLoggingEnabled: boolean;
  };
  test: undefined;
};

export type ExtensionStatusT = 'pending' | 'ready' | 'failed';

type ExtensionFunctionConfig = {
  type: typeof extensionFunctionType;
  status: ExtensionStatusT;
  config: {
    schedule?: { cron: string };
    private?: false;
    code: string;
    runtime: FunctionRuntime;
    app?: AppConfig | null;
    payloadLoggingEnabled: boolean;
  };
  test: {
    savedInput: string;
  };
};

export type ExtensionWebhook = ExtensionCommon & ExtensionWebhookConfig;

export type ExtensionFunction = ExtensionCommon & ExtensionFunctionConfig;

export type Extension = ExtensionWebhook | ExtensionFunction;

export type State = {
  activeExtensionsCount: number | null;
  list: Array<Extension>;
  loaded: boolean;
  pagination: Pagination | Record<string, never>;
};

export type ExtensionsView = 'list' | 'graph';
