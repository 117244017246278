import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import { FormattedMessage } from 'react-intl';
import { EMAIL_HELP_LINK } from '../../../../constants/values';
import { linebreak, link } from '../../../../lib/formaterValues';
import styles from './styles.module.sass';

const IntroBanner = () => (
  <div className={styles.IntroBannerWrapper}>
    <InfoIcon size={35} className={styles.IntroBannerIcon} />
    <span className={styles.IntroBannerDescription}>
      <FormattedMessage
        id="containers.settings.queues.email.introBanner"
        values={{ linebreak, link: link(EMAIL_HELP_LINK) }}
      />
    </span>
  </div>
);

export default IntroBanner;
