import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  formulasInfoPayloadSchema,
  formulasInfoResponseSchema,
} from './formulasInfo.schema';

export type FormulasInfoPayload = {
  schemaContent: SchemaSection[];
};

export type FormulasInfoResponse = {
  results: Array<{ formula: string; dependencies: Array<string>; id: string }>;
};

export const formulasInfo = (payload: FormulasInfoPayload) => {
  return {
    endpoint: `internal/schemas/formulas_info`,
    method: 'POST',
    responseSchema: formulasInfoResponseSchema,
    payloadSchema: formulasInfoPayloadSchema,
    payload,
  } as const;
};
