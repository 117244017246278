import { useTheme } from '@rossum/ui/material';
import { Rectangle2D } from '../../document-canvas/utils/geometry';

type IntermediateSelectionProps = {
  box: Rectangle2D;
};

export const IntermediateSelection = ({ box }: IntermediateSelectionProps) => {
  const theme = useTheme();

  return (
    <rect
      vectorEffect="non-scaling-stroke"
      stroke={theme.palette.primary.main}
      fill="transparent"
      strokeWidth={2}
      pointerEvents="none"
      x={box.x}
      y={box.y}
      height={box.height}
      width={box.width}
    />
  );
};
