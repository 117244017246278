import { Warning as WarningIcon } from '@rossum/ui/icons';
import { Paper, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

const isChildrenInProps = (
  props: Props
): props is {
  children: ReactNode;
} => 'children' in props;

type Props = {
  icon?: () => ReactNode;
} & (
  | {
      text: LocalizationKeys;
      title: LocalizationKeys;
    }
  | {
      children: ReactNode;
    }
);

const InfoBox = ({
  icon = () => (
    <WarningIcon
      sx={{
        color: theme => theme.palette.warning.main,
      }}
    />
  ),
  ...props
}: Props) => {
  const intl = useIntl();
  return (
    <Paper
      elevation={5}
      sx={{
        borderRadius: 0.5,
        p: 2,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        {icon()}
        {isChildrenInProps(props) ? (
          props.children
        ) : (
          <>
            <Typography variant="subtitle2">
              {intl.formatMessage({ id: props.title })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({ id: props.text })}
            </Typography>
          </>
        )}
      </Stack>
    </Paper>
  );
};

export default InfoBox;
