import { Components, Theme } from '../../material';

export const muiAlert = (theme: Theme): Components['MuiAlert'] => ({
  styleOverrides: {
    // TODO: Remove the `ownerState` conditions when this is resolved
    // https://github.com/mui/material-ui/issues/32799
    root: ({ ownerState }) =>
      ownerState && {
        ...(ownerState.variant === 'filled' && {
          backgroundColor:
            theme.palette[ownerState.color || ownerState.severity || 'success']
              .main,
          color: theme.palette.getContrastText(
            theme.palette[ownerState.color || ownerState.severity || 'success']
              .main
          ),
        }),
      },
  },
});
