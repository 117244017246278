import { AnnotationStatus } from '../../shared/models/annotationStatus';
import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { annotationListQuerySchema } from './list.schema';
import { listResponse } from '../../utils/listResponse';
import { annotationSchema } from '../models';
import { annotationListSideloadSchema } from '../models/annotationListSideload.schema';

export type AnnotationListQuery = Partial<{
  id: number[];
  queue: ID;
  search: string;
  status: AnnotationStatus[];
  labels: string[];
  ordering: string;
  assignees: number[];
  'content.schema_id': string[];
}> &
  PaginationQuery;

export const list = (query: AnnotationListQuery = {}) => {
  return {
    endpoint: `/annotations`,
    method: 'GET',
    responseSchema: listResponse(annotationSchema),
    query,
    querySchema: annotationListQuerySchema,
    sideloadSchema: annotationListSideloadSchema,
  } as const;
};
