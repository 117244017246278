import { fromTemplate } from './fromTemplate';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
export { type SurveyPatchPayload } from './patch';

export const surveys = {
  fromTemplate,
  patch,
  get,
  list,
};
