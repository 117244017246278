import { config } from 'ace-builds';
import AceEditor, { IAnnotation } from 'react-ace';
import './style.sass';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import { useTheme } from '@rossum/ui/material';
import workerJavascriptUrl from 'ace-builds/src-noconflict/worker-javascript?url';
import workerJsonUrl from 'ace-builds/src-noconflict/worker-json?url';
import { ChangeEvent, ComponentProps, LegacyRef, ReactNode } from 'react';
import ReactAce from 'react-ace/lib/ace';
import styles from '../styles.module.sass';

config.setModuleUrl('ace/mode/json_worker', workerJsonUrl);
config.setModuleUrl('ace/mode/javascript_worker', workerJavascriptUrl);

type Props = {
  children?: ReactNode;
  name: string;
  mode: 'javascript' | 'python' | 'json';
  onChange: (_value: string, _event?: ChangeEvent) => void;
  onFocus?: ComponentProps<typeof AceEditor>['onFocus'];
  onBlur?: ComponentProps<typeof AceEditor>['onBlur'];
  value?: string;
  forwardRef?: LegacyRef<ReactAce>;
  debounceChangePeriod?: number;
  onValidate?: (_annotations: IAnnotation[]) => void;
  readOnly?: boolean;
};

const Editor = ({
  forwardRef,
  children,
  name,
  mode,
  onBlur,
  onChange,
  onFocus,
  value,
  debounceChangePeriod,
  onValidate,
  readOnly,
}: Props) => {
  const theme = useTheme();

  return (
    <div className={styles.Editor}>
      {children}

      <AceEditor
        className={styles.AceEditor}
        name={name}
        mode={mode}
        theme={theme.palette.mode === 'light' ? 'tomorrow' : 'tomorrow_night'}
        width="100%"
        height="100%"
        ref={forwardRef}
        readOnly={readOnly}
        onChange={onChange}
        enableLiveAutocompletion
        enableSnippets
        enableBasicAutocompletion
        fontSize={14}
        tabSize={mode === 'javascript' ? 2 : 4}
        debounceChangePeriod={debounceChangePeriod}
        showGutter
        highlightActiveLine
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        editorProps={{
          $blockScrolling: Infinity,
        }}
        scrollMargin={[7, 7, 0, 0]}
        onValidate={annotations =>
          onValidate &&
          onValidate(
            annotations.map(a => ({
              ...a,
              row: a.row ?? 0,
              column: a.column ?? 0,
              type:
                a.type === 'error' || a.type === 'warning' || a.type === 'info'
                  ? a.type
                  : 'warning',
            }))
          )
        }
      />
    </div>
  );
};

export default Editor;
