import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { SuggestedEdit } from '../models/suggestedEdit';
import { suggestedEditSchema } from '../models/suggestedEdit.schema';

export const get = (suggestedEditId: ID) => {
  return {
    endpoint: `/suggested_edits/${suggestedEditId}`,
    method: 'GET',
    responseSchema: suggestedEditSchema,
  } as const;
};
