import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { UsersListQuery } from '@rossum/api-client/users';
import { User } from '@rossum/api-client/users';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

const QUERY_KEY_USERS_UNPAGINATED = 'users-unpaginated';

export const useUnpaginatedUsers = <TReturnedData = Array<User>>(
  query: UsersListQuery = {},
  options: Omit<
    UseQueryOptions<
      Array<User>,
      ElisClientError,
      TReturnedData,
      Readonly<[typeof QUERY_KEY_USERS_UNPAGINATED, UsersListQuery]>
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_USERS_UNPAGINATED, query],

    queryFn: () => {
      return api.unpaginatedRequest(endpoints.users.list)(query);
    },

    ...options,
  });
