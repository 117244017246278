// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { gridSchema } from './grid.schema';

const valueContentSchema = z
  .object({
    value: z.string().nullable(),
    normalizedValue: z.string().nullable(),
    page: z.number().nullable(),
    position: z
      .tuple([z.number(), z.number(), z.number(), z.number()])
      .nullable(),
    rirText: z.string().nullable(),
    rirRawText: z.string().nullable(),
    ocrText: z.string().nullable(),
    ocrRawText: z.string().nullable(),
    rirPage: z.number().nullable(),
    rirPosition: z.array(z.number()).nullable(),
    ocrPosition: z.array(z.number()).nullable(),
    rirConfidence: z.number().nullable(),
    connectorPosition: z.array(z.number()).nullable(),
    connectorText: z.string().nullable(),
  })
  .partial();

export const datapointDataSchema = z.object({
  id: idSchema.nullable(),
  url: urlSchema.nullable(),
  content: valueContentSchema.optional().nullable(),
  category: z.literal('datapoint').nullable(),
  schemaId: z.string().nullable(),
  validationSources: z.array(z.string()).optional().nullable(),
  timeSpent: z.number().optional().nullable(),
  timeSpentOverall: z.number().optional().nullable(),
});

export const tupleDataSchema = z.object({
  id: idSchema.nullable(),
  url: urlSchema.nullable(),
  category: z.literal('tuple').nullable(),
  children: z.array(datapointDataSchema.nullable()),
  schemaId: z.string(),
});

export const multivalueDataSchema = z.object({
  id: idSchema.nullable(),
  url: urlSchema.nullable(),
  content: valueContentSchema.optional().nullable(),
  children: z
    .union([z.array(datapointDataSchema), z.array(tupleDataSchema)])
    .nullable(),
  category: z.literal('multivalue').nullable(),
  schemaId: z.string().nullable(),
  validationSources: z.array(z.string()).optional().nullable(),
  timeSpent: z.number().optional().nullable(),
  timeSpentOverall: z.number().optional().nullable(),
  grid: gridSchema.optional().nullable(),
  timeSpentGrid: z.number().optional().nullable(),
  timeSpentGridOverall: z.number().optional().nullable(),
});

export const sectionDataSchema = z.object({
  id: idSchema.nullable(),
  url: urlSchema.nullable(),
  children: z
    .array(z.union([multivalueDataSchema, datapointDataSchema]))
    .nullable(),
  category: z.literal('section').nullable(),
  schemaId: z.string().nullable(),
});

export const annotationDataSchema = z.object({
  content: z.array(sectionDataSchema),
  results: z.array(sectionDataSchema),
});

export const optionsSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export const suggestedOperationsSchema = z.object({
  op: z.string(),
  id: z.string(),
  value: z.object({
    content: valueContentSchema,
    hidden: z.boolean().optional(),
    options: z.array(optionsSchema).optional().nullable(),
    validation_sources: z.array(z.string()),
  }),
});

export const updatedDatapointsSchema = datapointDataSchema.and(
  z.object({
    options: z.array(optionsSchema),
  })
);
