import { alpha, SxProps, Theme } from '@rossum/ui/material';

export const applyTextButtonStyle = (isActive = true) => ({
  color: (theme: Theme) =>
    isActive ? theme.palette.text.primary : theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: (theme: Theme) =>
      alpha(theme.palette.text.secondary, 0.08),
  },
});

export const shortcutStyles: SxProps = {
  textAlign: 'left',
  display: 'block',
  py: 0.7,
};

export const SHORTCUTS_MIN_WIDTH = 150;
