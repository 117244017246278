import { Close } from '@rossum/ui/icons';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import DAG from '../../DependenciesGraph/components/DAG';
import { ExtensionsGraphData } from '../../DependenciesGraph/helpers';

type Props = {
  onClose: () => void;
  dataCy?: string;
};

const graphDataGroupedPerEvents = [
  {
    eventNames: ['email.received'],
    graphData: [
      [
        {
          id: 979829,
          name: 'Extension 1',
          url: 'https://elis.develop.r8.lol/api/v1/hooks/979829',
          queues: ['https://elis.develop.r8.lol/api/v1/queues/2702249'],
          runAfter: [],
          active: true,
          events: ['email.received'],
          config: {
            runtime: 'nodejs18.x',
          },
          runBefore: ['https://elis.develop.r8.lol/api/v1/hooks/979831'],
        },
        {
          id: 979830,
          name: 'Extension 2',
          url: 'https://elis.develop.r8.lol/api/v1/hooks/979830',
          queues: ['https://elis.develop.r8.lol/api/v1/queues/2702249'],
          runAfter: [],
          active: true,
          events: ['email.received'],
          config: {
            runtime: 'nodejs18.x',
          },
          status: 'ready',
          runBefore: ['https://elis.develop.r8.lol/api/v1/hooks/979831'],
        },
      ],
      [
        {
          id: 979831,
          name: 'Extension 3',
          url: 'https://elis.develop.r8.lol/api/v1/hooks/979831',
          queues: ['https://elis.develop.r8.lol/api/v1/queues/2702249'],
          runAfter: [
            'https://elis.develop.r8.lol/api/v1/hooks/979829',
            'https://elis.develop.r8.lol/api/v1/hooks/979830',
          ],
          active: true,
          events: ['email.received'],
          config: {
            runtime: 'nodejs18.x',
          },
          status: 'ready',
          runBefore: ['https://elis.develop.r8.lol/api/v1/hooks/979832'],
        },
      ],
      [
        {
          id: 979832,
          name: 'Extension 4',
          url: 'https://elis.develop.r8.lol/api/v1/hooks/979832',
          queues: ['https://elis.develop.r8.lol/api/v1/queues/2702249'],
          runAfter: ['https://elis.develop.r8.lol/api/v1/hooks/979831'],
          active: true,
          events: ['email.received'],
          config: {
            runtime: 'nodejs18.x',
          },
          status: 'ready',
          runBefore: [],
        },
      ],
    ],
    extensionGraphNodesMap: {
      'https://elis.develop.r8.lol/api/v1/hooks/979829': {
        parents: [],
        children: [],
        mergedRelations: [],
      },
      'https://elis.develop.r8.lol/api/v1/hooks/979830': {
        parents: [],
        children: [],
        mergedRelations: [],
      },
      'https://elis.develop.r8.lol/api/v1/hooks/979831': {
        parents: [],
        children: [],
        mergedRelations: [],
      },
      'https://elis.develop.r8.lol/api/v1/hooks/979832': {
        parents: [],
        children: [],
        mergedRelations: [],
      },
    },
  },
];

const mockGraphData = graphDataGroupedPerEvents[0]
  .graphData as unknown as ExtensionsGraphData;

const { extensionGraphNodesMap } = graphDataGroupedPerEvents[0];

const IntroToDAGBanner = ({ onClose, dataCy }: Props) => {
  return (
    <Card elevation={1} data-cy={dataCy}>
      <CardContent sx={{ padding: 2.5 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Typography variant="h6">
                <FormattedMessage id="containers.settings.extensions.graph.intro.title" />
              </Typography>
            </Stack>
            <IconButton onClick={onClose} size="small">
              <Close
                fontSize="small"
                sx={{ color: theme => theme.palette.text.secondary }}
              />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={8} alignItems="flex-start">
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="containers.settings.extensions.graph.intro.description" />
            </Typography>
          </Stack>
          <Stack
            sx={{
              height: 200,
              width: '100%',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              justifyContent: 'center',
            }}
          >
            <DAG
              graphData={mockGraphData}
              extensionGraphNodesMap={extensionGraphNodesMap}
              key={`${mockGraphData}`}
              gridSx={{ justifyContent: 'center', pointerEvents: 'none' }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default IntroToDAGBanner;
