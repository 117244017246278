import { LocationDescriptor } from 'history';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { push, replace as replacePath } from 'redux-first-history';
import { useLeavingModalDispatch } from '../../containers/Queue/containers/QueueSchema/lib/leavingModalHooks';
import { LocalizationKeys } from '../../i18n';
import styles from './styles.module.sass';

type Props = {
  text: LocalizationKeys;
  to: LocationDescriptor;
  icon?: ReactNode;
  replace?: boolean;
  dataCy?: string;
  isActive?: NavLinkProps['isActive'];
};

const SidebarNavLink = ({
  isActive,
  replace,
  icon,
  text,
  to,
  dataCy,
}: Props) => {
  const leaveSafely = useLeavingModalDispatch();

  const handleNavigation = (e: React.MouseEvent) => {
    if (e.metaKey || e.ctrlKey) return;

    e.preventDefault();
    leaveSafely(replace ? replacePath(to) : push(to));
  };
  return (
    <NavLink
      data-cy={dataCy}
      className={styles.SidebarNavLink}
      activeClassName={styles.ActiveSidebarNavLink}
      to={to}
      onClick={handleNavigation}
      isActive={isActive}
      exact
    >
      {icon}
      <FormattedMessage id={text} />
    </NavLink>
  );
};

export default SidebarNavLink;
