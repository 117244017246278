import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngine } from '../models/dedicatedEngine';
import { dedicatedEngineSchema } from '../models/dedicatedEngine.schema';
import { dedicatedEngineListQuerySchema } from './list.schema';

export type DedicatedEngineListQuery = {
  pageSize?: number;
  page?: number;
  fields?: ('id' | 'name' | 'url' | 'status' | 'schema' | 'description')[];
  ordering?: (
    | 'id'
    | 'name'
    | 'url'
    | 'status'
    | 'schema'
    | 'description'
    | '-id'
    | '-name'
    | '-url'
    | '-status'
    | '-schema'
    | '-description'
  )[];
  id?: number | number[];
  name?: number | number[];
  url?: number | number[];
  status?: number | number[];
  schema?: number | number[];
  description?: number | number[];
};

export const list = (query: DedicatedEngineListQuery = {}) => {
  return {
    endpoint: `/dedicated_engines`,
    method: 'GET',
    responseSchema: listResponse(dedicatedEngineSchema),
    query,
    querySchema: dedicatedEngineListQuerySchema,
  } as const;
};
