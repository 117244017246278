import { PaginationQuery } from '@rossum/api-client/utils';
import { PaginationResponse } from '@rossum/api-client/utils';
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow as MuiTableRow,
  Theme,
} from '@rossum/ui/material';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';
import { useIntl } from 'react-intl';
import { pageSizes } from '../../../../constants/values';
import { LocalizationKeys } from '../../../../i18n';

const getBorderColor = (theme: Theme) =>
  theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.08)'
    : 'rgba(0, 0, 0, 0.08)';

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  height: 54,
  transition: 'background-color 0.2s',
  '&.TableRowOverride, &.TableRowPlaceholder': {
    borderTop: `1px solid ${getBorderColor(theme)}`,
  },
  '&.TableRowOverride:nth-of-type(odd):hover, &.TableRowOverride:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '&.TableRowPlaceholder:nth-of-type(odd):hover, &.TableRowPlaceholder:hover': {
    backgroundColor: 'transparent',
  },
  '&.TableRowOverride:nth-of-type(odd)': {
    backgroundColor: 'inherit',
  },
}));

type HeadColumnsConfig = Array<{
  id: LocalizationKeys;
  width?: string;
  align?: TableCellProps['align'];
}>;

type BillingTableProps = {
  headColumns: HeadColumnsConfig;
  children: ReactNode;
  paginationState?: Required<PaginationQuery>;
  setPaginationState?: Dispatch<SetStateAction<Required<PaginationQuery>>>;
  paginationResponse?: PaginationResponse;
};

const BillingTable = ({
  paginationState,
  setPaginationState,
  paginationResponse,
  headColumns,
  children,
}: BillingTableProps) => {
  const intl = useIntl();

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (setPaginationState) {
      setPaginationState(prevState => ({
        page: newPage,
        pageSize: prevState.pageSize,
      }));
    }
  };

  const handleChangePageSize: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newPageSize = +event.target.value;
    if (setPaginationState) {
      setPaginationState({
        page: 0,
        pageSize: newPageSize,
      });
    }
  };

  const rowsPerPageOptions = pageSizes.map(size => +size);
  const minimumItemsForPagination = rowsPerPageOptions[0] ?? 0;
  const showPagination =
    paginationState &&
    paginationResponse &&
    paginationResponse.total > minimumItemsForPagination;

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              {headColumns.map(column => (
                <TableCell
                  key={column.id}
                  width={column.width}
                  align={column.align}
                >
                  {intl.formatMessage({
                    id: column.id,
                  })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          component="div"
          sx={{
            '& p': { m: 0 },
            borderTop: theme => `1px solid ${getBorderColor(theme)}`,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          count={paginationResponse.total}
          labelRowsPerPage={intl.formatMessage({
            id: 'components.tablePagination.rowsPerPage',
          })}
          rowsPerPage={paginationState.pageSize}
          page={paginationState.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
      )}
    </>
  );
};

export default BillingTable;
