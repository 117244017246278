import { CloudUploadOutlined } from '@rossum/ui/icons';
import { alpha, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { HEADER_COLUMN_HEIGHT } from '../../../ui/data-grid/styles';
import { absoluteMaxSizeStyles } from './helpers';

export const DragOverlay = () => {
  const intl = useIntl();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        background: theme =>
          alpha(
            theme.palette.mode === 'dark'
              ? theme.palette.common.black
              : theme.palette.common.white,
            0.75
          ),
        border: theme => `2px dashed ${theme.palette.primary.main}`,
        borderRadius: 2,
        zIndex: theme => theme.zIndex.drawer,
        ...absoluteMaxSizeStyles,
        top: `${HEADER_COLUMN_HEIGHT}px`,
      }}
    >
      <CloudUploadOutlined
        sx={{
          fontSize: theme => theme.typography.pxToRem(36),
          color: 'text.secondary',
          marginBottom: 1,
        }}
      />
      <Typography variant="subtitle2" color="text.secondary">
        {intl.formatMessage({
          id: 'containers.annotationList.dropFiles', // TODO: mission-simplified-dashboard: rewrite translation key
        })}
      </Typography>
    </Stack>
  );
};
