import { Queue } from '../../../types/queue';

export const isGenericEngine = (queue: Queue) => {
  // if Engine is set using the "modern way"
  if (queue.dedicatedEngine || queue.genericEngine) {
    return queue.dedicatedEngine === null && queue.genericEngine !== null;
  }

  // fallback to legacy way
  return !!queue.rirUrl && queue.rirUrl.startsWith('https://all.rir.');
};
