import { Typography } from '@rossum/ui/material';
import { memo, ReactNode } from 'react';

type StringWithOccurrencesProps = {
  value: string;
  occurrences: string | number;
  icon?: ReactNode;
};

export const ValueWithOccurrence = memo(
  ({ value, occurrences, icon }: StringWithOccurrencesProps) => {
    return (
      <Typography component="span" alignItems="center">
        {icon || null}
        &nbsp;
        <Typography component="span" variant="body2">
          {value}
        </Typography>
        <Typography color="text.secondary" component="span" variant="caption">
          {` (${occurrences})`}
        </Typography>
      </Typography>
    );
  }
);
