import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteEndpoint = (userId: number) => {
  return {
    endpoint: `/users/${userId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
