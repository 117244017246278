import { GTM_ID, rollbarEnvironment } from '../constants/config';
import { getRole } from '../redux/modules/groups/helpers';
import { getName } from '../redux/modules/user/helpers';
import { Groups } from '../types/group';
import { User } from '../types/user';
import { initGTM } from './gtm';
import { getIDFromString } from './url';

export const initializeTracking = (
  isTrial: boolean,
  user: User,
  groups: Groups,
  isProduction: boolean | null,
  marketCategory: string | null
) => {
  const organization_id =
    getIDFromString(user.organization) ?? 'unknown organization ID';

  initGTM(GTM_ID, {
    event: 'elis-user identification',
    eventData: {
      user_id: user.id,
      user_role: getRole(user.groups, groups)?.id,
      organization_id,
      is_trial: isTrial,
      is_production: isProduction,
      environment: rollbarEnvironment,
      market_category: marketCategory,
    },
  });

  if (window.initSmartlook) {
    const { id, email } = user;

    window.initSmartlook();
    window.smartlook('record', { ips: true, forms: true, numbers: true });

    window.smartlook('identify', id, {
      name: getName(user),
      email,
      is_trial: isTrial,
      organization_id,
    });
  }
};
