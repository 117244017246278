import { Observable } from 'rxjs';
import { RootActionType } from './rootActions';

type AssumedInternalShapeOfFilterSubscriber = {
  destination?: {
    constructor?: { name: string };
    predicate?: unknown;
  };
};

export const tryToFilterBeforeScheduling =
  () => (source: Observable<RootActionType>) =>
    new Observable(subscriber =>
      source.subscribe({
        next(value) {
          try {
            const { destination } =
              subscriber as unknown as AssumedInternalShapeOfFilterSubscriber;

            if (
              destination &&
              destination.constructor?.name === 'FilterSubscriber' &&
              typeof destination.predicate === 'function' &&
              !destination.predicate(value)
            ) {
              // We know that filter() operator for particular epic would filter out this action
              // so we can ignore it right here, and we don't need to schedule it.
              return;
            }
          } catch {
            // Process the action normally
          }

          subscriber.next(value);
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      })
    );
