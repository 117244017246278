import { useEffect, useRef } from 'react';
import { FooterTable, FooterTableProps } from './FooterTable';

export type VirtualTableContainerProps = {
  isVisible: boolean;
  observer: IntersectionObserver | null;
} & FooterTableProps;

// Container that controls whether to render the table based on what is visible in footer
export const VirtualTableContainer = ({
  isVisible,
  observer,
  ...tableProps
}: VirtualTableContainerProps) => {
  const rootRef = useRef(null);

  useEffect(() => {
    const el = rootRef.current;

    if (observer && el) {
      observer.observe(el);

      return () => observer.unobserve(el);
    }

    return () => {};
  }, [observer]);

  // These are taken manually as height of table title + table header, careful if changing them!
  const height = 54 + 52 + tableProps.tupleIds.length * 32;

  return (
    <div
      ref={rootRef}
      style={{ width: '100%', height }}
      data-part={tableProps.part}
    >
      {isVisible ? <FooterTable {...tableProps} /> : null}
    </div>
  );
};
