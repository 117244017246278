import { useEffect, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';

export const useCopyPastePermissions = () => {
  const [pasteIsAllowed, setPasteIsAllowed] = useState<boolean>(false);
  const [pasteIsSupported, setPasteIsSupported] = useState<boolean>(false);
  useEffect(() => {
    if (isSafari) {
      setPasteIsAllowed(false);
      return;
    }

    if (isFirefox) {
      setPasteIsAllowed(false);
      return;
    }

    setPasteIsSupported(true);

    navigator.permissions
      // @ts-expect-error
      .query({ name: 'clipboard-read' })
      .then(permissionStatus => {
        permissionStatus.onchange /* eslint-disable-line */ = ({
          // @ts-expect-error
          target: { state },
        }) => {
          if (state === 'denied') setPasteIsAllowed(false);
        };

        if (permissionStatus.state === 'denied') {
          return setPasteIsAllowed(false);
        }

        return setPasteIsAllowed(true);
      });
  }, []);

  return { pasteIsAllowed, pasteIsSupported };
};
