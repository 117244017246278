import clsx from 'clsx';
import { find } from 'lodash';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dropdown, { ItemPropsT } from '../../../../components/UI/Dropdown';
import NoItemsFound from '../../../../components/UI/NoItemsFound';
import SearchFilter from '../../../../components/UI/SearchFilter/SearchFilter';
import { filterUsers } from '../../../../redux/modules/users/helpers';
import { Url } from '../../../../types/basic';
import { User, UserList } from '../../../../types/user';
import styles from './style.module.sass';

type Props = {
  allOrgUsers: UserList;
  filteredUsers: UserList;
  selectedUser: Url;
  setSelectedUser: (_user: Url | null) => void;
  setFilteredUsers: (_users: UserList) => void;
  searchValue: string;
  setSearchValue: (_searchValue: string) => void;
  onBlur: {
    (_e?: React.FocusEvent<unknown>): void;
    <T = unknown>(
      _fieldOrEvent: T
    ): T extends string ? (_e: unknown) => void : void;
  };
  align?: 'right' | 'left';
};

export const getUserName = (user: User): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.email) {
    return user.email;
  }

  return user.username;
};

const UsersDropdown = ({
  allOrgUsers,
  setSearchValue,
  onBlur,
  selectedUser,
  setSelectedUser,
  filteredUsers,
  setFilteredUsers,
  searchValue,
  align = 'left',
}: Props) => {
  const intl = useIntl();
  const selectedUserObject = find(
    allOrgUsers,
    ({ url }) => url === selectedUser
  );

  return (
    <Dropdown
      listClassName={styles.UsersDropdownList}
      align={align}
      withIcons={false}
      items={[
        (_props: ItemPropsT) => (
          <div
            key="users-search"
            onClick={e => {
              e.nativeEvent.stopImmediatePropagation();
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <SearchFilter
              className={styles.Search}
              placeholderID="containers.settings.extensions.createExtension.tokenOwner.search"
              setFilteredEntities={setFilteredUsers}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              entities={filteredUsers}
              filterFunction={filterUsers}
            />
          </div>
        ),
        ...(filteredUsers?.length
          ? filteredUsers.map((user: User) => () => {
              const isUserSelected = selectedUser === user.url;

              return (
                <div
                  key={user.id}
                  onClick={() =>
                    setSelectedUser(isUserSelected ? null : user.url)
                  }
                  className={clsx(
                    styles.UserOption,
                    isUserSelected && styles.UserOptionSelected
                  )}
                >
                  {getUserName(user)}
                </div>
              );
            })
          : [
              (_props: ItemPropsT) => (
                <div key="no-users-placeholder">
                  <NoItemsFound
                    title={intl.formatMessage(
                      {
                        id: 'containers.settings.extensions.createExtension.tokenOwner.noUsers.title',
                      },
                      { search: searchValue }
                    )}
                    subtitle={intl.formatMessage({
                      id: 'containers.settings.extensions.createExtension.tokenOwner.noUsers.subtitle',
                    })}
                  />
                </div>
              ),
            ]),
      ]}
      onBlur={onBlur}
    >
      {({ open }: Record<'open', boolean>) => (
        <div
          className={clsx(styles.Value, open && styles.IsOpen)}
          data-cy="extensions-users-select"
        >
          <span className={styles.SelectedUser}>
            {selectedUser ? (
              selectedUserObject ? (
                getUserName(selectedUserObject)
              ) : (
                // fallback to original URL in case user cannot access selectedUser object
                selectedUser
              )
            ) : (
              <FormattedMessage id="containers.settings.extensions.createExtension.tokenOwner.placeholder" />
            )}
          </span>
          <ChevronDownIcon className={styles.Chevron} />
        </div>
      )}
    </Dropdown>
  );
};

export default UsersDropdown;
