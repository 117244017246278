import { AxiosResponseHeaders } from 'axios';

export type WithEtag<T> = T & { __etag__: string | undefined };

export const getEtag = (object: WithEtag<unknown>) =>
  object.__etag__?.substring(2);

export const loadEtag = <T extends Record<string, unknown>>(response: {
  data: T;
  headers: AxiosResponseHeaders;
}) => ({ ...response.data, __etag__: response.headers.etag });
