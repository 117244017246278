import { useDocumentPageContext } from '../page-svg/useDocumentPageContext';

type PageImageProps = {
  href: string | undefined;
};

export const PageImage = ({ href }: PageImageProps) => {
  const pageContext = useDocumentPageContext();
  return href && pageContext ? (
    <image
      href={href}
      width={pageContext.pageDimensions.width}
      height={pageContext.pageDimensions.height}
    />
  ) : null;
};
