// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import {
  datapointDataSchema,
  optionsSchema,
  suggestedOperationsSchema,
} from '../models/annotationData.schema';

export const validateAnnotationPayloadSchema = z.object({
  updatedDatapointIds: z.array(idSchema).optional(),
  actions: z.array(z.string()).optional(),
});

const messagesSchema = z.array(
  z.union([
    z.object({
      type: z.union([
        z.literal('error'),
        z.literal('warning'),
        z.literal('info'),
      ]),
      content: z.string(),
      id: z.string(),
    }),
    z.object({
      type: z.literal('aggregation'),
      content: z.string(),
      id: z.string(),
      aggregationType: z.literal('sum'),
      schemaId: z.string(),
    }),
  ])
);

const matchedTriggerRuleDatapointSchema = z.object({
  type: z.literal('datapoint'),
  value: z.string(),
  id: idSchema,
});

const matchedTriggerRulePageCountSchema = z.object({
  type: z.literal('page_count'),
  value: z.number(),
  threshold: z.number(),
});

const matchedTriggerRuleFilenameSchema = z.object({
  type: z.literal('filename'),
  value: z.string(),
  regex: z.string(),
});

const matchedTriggerRuleSchema = z.union([
  matchedTriggerRuleDatapointSchema,
  matchedTriggerRulePageCountSchema,
  matchedTriggerRuleFilenameSchema,
]);

export const validateAnnotationResponseSchema = z.object({
  messages: messagesSchema,
  updatedDatapoints: z.array(
    datapointDataSchema.and(
      z.object({
        options: z.array(optionsSchema).optional(),
      })
    )
  ),
  suggestedOperations: z.array(suggestedOperationsSchema),
  matchedTriggerRules: z.array(matchedTriggerRuleSchema),
});
