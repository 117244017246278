import { useCallback, useEffect, useState } from 'react';

type UseCopyToClipboardOptions = {
  timeout: number;
};

export const useCopyToClipboard = (
  textToCopy: string,
  { timeout }: UseCopyToClipboardOptions = { timeout: 900 }
) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => setCopied(true))
      .catch(() => {});
  }, [textToCopy]);

  useEffect(() => {
    if (copied === true) {
      const timeoutId = setTimeout(() => setCopied(false), timeout);
      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [copied, setCopied, timeout]);

  return {
    copied,
    copyToClipboard,
  };
};
