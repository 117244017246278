// Generated by ts-to-zod
import { z } from 'zod';

export const pageDataQuerySchema = z.object({
  granularity: z.union([
    z.literal('lines'),
    z.literal('words'),
    z.literal('chars'),
  ]),
  pageNumbers: z.array(z.number()),
});
