import { ElisRequestConfig } from '../../utils/requestConfig';
import { OrganizationLimits } from '../models/limits';
import { organizationLimitsSchema } from '../models/limits.schema';

export const limits = (organizationId: number) => {
  return {
    endpoint: `organizations/${organizationId}/limits`,
    method: 'GET',
    responseSchema: organizationLimitsSchema,
  } as const;
};
