// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const emailListQuerySchema = z
  .object({
    id: z.union([idSchema, z.array(idSchema)]),
    createdAt: z.string(),
    subject: z.string(),
    queue: z.number(),
    inbox: z.string(),
    documents: z.array(z.string()),
    fromEmail: z.string(),
    fromName: z.string(),
    to: z.array(z.string()),
    annotationsCount: z.number(),
    annotationsCountGte: z.number(),
    annotationsRejectedCountGte: z.number(),
    lastThreadEmailCreatedAtBefore: z.string(),
    lastThreadEmailCreatedAtAfter: z.string(),
    filteredOutDocumentCount: z.number(),
    filteredOutDocumentCountGte: z.number(),
    type: z.string(),
    emailThread: z.union([z.number(), z.array(z.number())]),
  })
  .partial()
  .and(paginationQuerySchema);
