import { styled, TableRow } from '@rossum/ui/material';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { datapointPathSelector } from '../../redux/modules/datapoints/selector';
import { State } from '../../types/state';
import { FooterTupleRow, FooterTupleRowProps } from './FooterTupleRow';
import { highlightOverlay } from './utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd):not(.MuiTableRow-head):not(.Mui-selected)': {
    backgroundColor: 'unset',
  },
  '&:nth-of-type(even):not(.Mui-selected)': {
    backgroundColor: 'unset',
  },
  '&:nth-of-type(odd):not(.MuiTableRow-head):hover, &:nth-of-type(even):hover, &.Mui-selected':
    {
      backgroundColor: highlightOverlay(theme),
    },
  // The checkbox cell is sticky, and it uses background color
  // to cover the row below, so we need to apply hover/selected state differently
  '&:hover > td:first-of-type, &.Mui-selected > td:first-of-type': {
    backgroundImage: `linear-gradient(${highlightOverlay(
      theme
    )}, ${highlightOverlay(theme)})`,
  },
  // Last cell is made sticky only for the hovered row (which shows the delete icon)
  '&:hover > td[data-last-cell]': {
    position: 'sticky',
    right: 0,
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `linear-gradient(${highlightOverlay(
      theme
    )}, ${highlightOverlay(theme)})`,
  },
}));

type VirtualTupleRowContainerProps = {
  isVisible: boolean;
  observer: IntersectionObserver | null;
} & FooterTupleRowProps;

export const VirtualTupleRowContainer = React.memo(
  ({ isVisible, observer, ...rowProps }: VirtualTupleRowContainerProps) => {
    const rootRef = useRef(null);

    useEffect(() => {
      const el = rootRef.current;

      if (observer && el) {
        observer.observe(el);

        return () => observer.unobserve(el);
      }

      return () => {};
    }, [observer]);

    const containsCurrentDatapoint = useSelector(
      (state: State) => datapointPathSelector(state)[2] === rowProps.tupleId
    );

    return (
      <StyledTableRow
        ref={rootRef}
        selected={containsCurrentDatapoint || rowProps.checked}
        style={{ minWidth: '1000px', width: '100%', height: 32 }}
        sx={{
          '&:hover [data-cy=remove-line]': {
            visibility: 'visible',
            opacity: 1,
          },
        }}
        data-cy="footer-table-row"
        data-tupleid={rowProps.tupleId}
      >
        {isVisible ? <FooterTupleRow {...rowProps} /> : null}
      </StyledTableRow>
    );
  }
);
