import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import AlertIcon from 'mdi-react/AlertIcon';
import { FormattedMessage } from 'react-intl';
import { RepeatedStatus } from '../../../redux/modules/ui/types';
import LoadingIndicator from '../../UI/LoadingIndicator';
import styles from '../style.module.sass';

const Loading = () => (
  <div className={clsx(styles.Announcement, styles.PendingAnouncement)}>
    <LoadingIndicator />
    <FormattedMessage id="components.announcements.updating.text" />
  </div>
);
const Refresh = () => (
  <div className={clsx(styles.Announcement, styles.AlertAnnouncement)}>
    <AlertIcon />
    <span className={styles.Bold}>
      <FormattedMessage id="components.announcements.refresh.title" />
    </span>
    &nbsp;
    <FormattedMessage id="components.announcements.refresh.text" />
    &nbsp;
    <Button
      color="inherit"
      size="small"
      onClick={() => window.location.reload()}
    >
      <FormattedMessage id="components.announcements.refresh.confirm" />
    </Button>
  </div>
);

const RepeatedStatusAlert = ({ status }: { status: RepeatedStatus }) =>
  status === 'pending' ? <Loading /> : <Refresh />;

export default RepeatedStatusAlert;
