import { FolderOpen } from '@rossum/ui/icons';
import { Box, List, Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SearchFilter from '../../../containers/User/components/SearchFilter';
import { Queue } from '../../../types/queue';
import { WorkspaceWithQueues } from '../../../types/workspace';
import Scrollable from '../../Scrollable';
import NoItemsFound from '../NoItemsFound';
import Workspace from './Workspace';

type SelectSingleQueueProps = {
  workspaces: Array<WorkspaceWithQueues> | undefined;
  selectedQueue: Queue | undefined;
  onChange: (_queue: Queue) => void;
};

const SingleQueueSelectList = ({
  onChange,
  selectedQueue,
  workspaces,
}: SelectSingleQueueProps) => {
  const intl = useIntl();

  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspaces);

  useEffect(() => {
    setFilteredWorkspaces(workspaces);
  }, [workspaces]);

  const getContent = () => {
    if (!filteredWorkspaces) {
      return (
        <Stack sx={{ mt: 1 }}>
          {[1, 2, 3, 4, 5, 6, 7].map(n => (
            <Stack key={n} direction="row" gap={1} sx={{ px: 2, py: 1.5 }}>
              <FolderOpen color="primary" />
              <Skeleton width={random(200, 250)} />
            </Stack>
          ))}
        </Stack>
      );
    }

    if (!filteredWorkspaces.length) {
      return (
        <NoItemsFound
          title={intl.formatMessage({
            id: 'components.selectQueueModal.noqueues.title',
          })}
          subtitle={intl.formatMessage({
            id: 'components.selectQueueModal.noqueues.text',
          })}
        />
      );
    }

    return (
      <List component="nav">
        {filteredWorkspaces.map((workspace, index) => (
          <Workspace
            key={workspace.id}
            defaultExpand={index === 0}
            forceExpand={!!workspace.originalQueuesCount}
            workspace={workspace}
            selectedQueue={selectedQueue}
            setSelectedQueue={onChange}
          />
        ))}
      </List>
    );
  };

  return (
    <>
      <Box sx={{ mt: 3, mb: 1, mx: 2 }}>
        <SearchFilter
          placeholderID="components.selectQueueModal.placeholder"
          setFilteredWorkspaces={setFilteredWorkspaces}
          workspaces={workspaces}
        />
      </Box>
      <Scrollable>{getContent()}</Scrollable>
    </>
  );
};

export default SingleQueueSelectList;
