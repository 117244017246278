import { InfoOutlined, KeyboardArrowDown } from '@rossum/ui/icons';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  cardHeaderClasses,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export const ReviewIsNeeded = () => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(true);

  return (
    <Box position="relative">
      <Card
        square
        sx={{
          pb: 4,
        }}
      >
        <CardHeader
          title={intl.formatMessage({
            id: 'components.sidebar.reviewIsNeeded.title',
          })}
          titleTypographyProps={{
            variant: 'h6',
            color: 'info.light',
          }}
          avatar={
            <InfoOutlined fontSize="small" sx={{ color: 'info.light' }} />
          }
          onClick={() => setExpanded(!expanded)}
          action={
            <IconButton size="small">
              <KeyboardArrowDown
                sx={{
                  transform: expanded ? 'rotate(180deg)' : 'none',
                  transition: theme => theme.transitions.create('transform'),
                }}
              />
            </IconButton>
          }
          sx={{
            pt: 4,
            px: 2,
            pb: 1,
            [`.${cardHeaderClasses.avatar}`]: {
              mr: 1,
            },
          }}
        />
        <Collapse in={expanded}>
          <CardContent
            sx={{ pt: 0, pl: 5.5, '&:last-child': { pb: 0 }, pr: 2 }}
          >
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'components.sidebar.reviewIsNeeded.description',
              })}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <Divider />
    </Box>
  );
};
