import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { engineSchema } from '../models/engine.schema';
import { engineListQuerySchema } from './list.schema';

export type EngineListQuery = Partial<{
  type: 'extractor' | 'splitter';
  ordering: ('id' | '-id' | 'type' | '-type')[];
}> &
  PaginationQuery;

export const list = (query: EngineListQuery) => {
  return {
    endpoint: `/engines`,
    method: 'GET',
    responseSchema: listResponse(engineSchema),
    query,
    querySchema: engineListQuerySchema,
  } as const;
};
