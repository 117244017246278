import { Message } from '@rossum/api-client/shared';
import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import { Box } from '@rossum/ui/material';
import { get } from 'lodash';
import { connect } from 'react-redux';
import messageStyles from '../../../components/Datapoint/style.module.sass';
import HTMLMessage from '../../../components/DatapointMessage/HTMLMessage';
import Icon from '../../../components/DatapointMessage/Icon';
import {
  firstDatapointMessageSelector,
  getDatapointLabel,
} from '../../../redux/modules/datapoints/selector';
import { State } from '../../../types/state';

type OwnProps = {
  selectDatapoint: () => void;
};

type StateProps = {
  label: string | undefined;
  message?: Message;
};

type Props = OwnProps & StateProps;

const MessageButton = ({ label, message, selectDatapoint }: Props) =>
  message ? (
    <Tooltip
      placement="right"
      popperClassName={messageStyles.MessageWithLink}
      content={
        <HTMLMessage
          content={`${label ? `<u>${label}</u>: ` : ''}${get(
            message,
            'content'
          )}`}
        />
      }
    >
      <Box
        sx={{ pl: 0.5 }}
        onClick={() => selectDatapoint()}
        data-cy="annotation-sidebar-global-message"
      >
        <Icon type={get(message, 'type')} />
      </Box>
    </Tooltip>
  ) : null;

const mapStateToProps = (state: State): StateProps => ({
  message: firstDatapointMessageSelector(state),
  label: getDatapointLabel(state),
});

export default connect<StateProps, null, OwnProps, State>(mapStateToProps)(
  MessageButton
);
