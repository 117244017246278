import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import styles from './styles.module.sass';

type Props = {
  totalUploads: number;
  finishedUploads: number;
};

const Loading = ({ totalUploads, finishedUploads }: Props) => {
  const intl = useIntl();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <div className={styles.Loading}>
        <div className={styles.LoadingBar}>
          <div
            style={{ width: `${(165 / totalUploads) * finishedUploads}px` }}
          />
        </div>
      </div>
      <Typography color="text.secondary" variant="body2">
        {intl.formatMessage(
          { id: 'containers.annotationList.upload.loadingText' },
          {
            total: <strong>{totalUploads}</strong>,
            finished: <strong>{finishedUploads}</strong>,
          }
        )}
      </Typography>
    </Stack>
  );
};

export default Loading;
