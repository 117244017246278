import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { render } from './render';
export { type RenderEmailTemplatePayload } from './render';
export { type CreateEmailTemplatePayload } from './create';
export { type EmailTemplateListQuery } from './list';
export { type EmailTemplatePatchPayload } from './patch';

export const emailTemplates = {
  render,
  list,
  get,
  create,
  patch,
  delete: deleteEndpoint,
};
