import { PageDataItem } from '@rossum/api-client/annotations';
import { styled } from '@rossum/ui/material';
import { isRectangleOverlapping } from '../../document-store/documentGeometry';
import {
  Rectangle2D,
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
} from '../../document-store/helpers/geometry';
import { getSpanningRectangleCoordinates } from '../../document-store/helpers/getSpanningRectangle';
import { useDocumentPageContext } from '../page-svg/useDocumentPageContext';
import { useDrawTextSelection } from './useDrawTextSelection';

const SelectionRect = styled('rect')(() => ({
  opacity: 0.25,
  fill: 'blue',
}));

const SuggestionRect = styled('rect')(() => ({
  fill: 'transparent',
  // stroke: theme.palette.primary.main,
  // strokeDasharray: '4 4',
  // strokeWidth: 2,
  cursor: 'text',
}));

type PageTextProps = {
  pageText: {
    chars: PageDataItem[];
    lines: PageDataItem[];
    words: PageDataItem[];
  };
  onSelect?: (rectangle: Rectangle2DCoordinates) => void;
};

export const PageText = ({ pageText, onSelect }: PageTextProps) => {
  const pageContext = useDocumentPageContext();
  const { boundingBox, handleMouseDown } = useDrawTextSelection({
    onFinish: rectangle => {
      const spanningRectangle = getSpanningRectangleCoordinates(
        getSelectedCharItems(pageText.chars, rectangle).map(
          item => item.position
        )
      );

      if (onSelect && spanningRectangle) {
        onSelect?.(spanningRectangle);
      }
    },
  });

  const getSelectedCharItems = (pageText: PageDataItem[], rect: Rectangle2D) =>
    pageText.filter(textItem =>
      isRectangleOverlapping(rectangleFromCoordinates(textItem.position))(rect)
    );

  const selectedCharItems = boundingBox
    ? getSelectedCharItems(pageText.chars, boundingBox)
    : [];

  return pageContext ? (
    <g
      width={pageContext.pageDimensions.width}
      height={pageContext.pageDimensions.height}
      onMouseDown={handleMouseDown}
    >
      <rect
        width={pageContext.pageDimensions.width}
        height={pageContext.pageDimensions.height}
        pointerEvents="all"
        fill="transparent"
      />

      {pageText.lines.map(lineItem => (
        <SuggestionRect
          key={lineItem.position.join(',')}
          vectorEffect="non-scaling-stroke"
          rx={2}
          ry={2}
          x={lineItem.position[0]}
          y={lineItem.position[1]}
          width={lineItem.position[2] - lineItem.position[0]}
          height={lineItem.position[3] - lineItem.position[1]}
          pointerEvents="all"
        >
          <title>{lineItem.text}</title>
        </SuggestionRect>
      ))}

      {selectedCharItems.map(item => (
        <SelectionRect
          key={item.position.join(',')}
          x={item.position[0]}
          y={item.position[1]}
          width={item.position[2] - item.position[0]}
          height={item.position[3] - item.position[1]}
          pointerEvents="none"
        />
      ))}
    </g>
  ) : null;
};
