import { createContext, useContext } from 'react';

export const configAppContext = createContext<{
  isFullScreen: boolean;
  name: string;
  setTimestamp: (timestamp: number) => void;
  openConfigApp: () => void;
  configAppOpen: boolean;
} | null>(null);

export const useExtensionConfigAppContext = () => {
  const ctx = useContext(configAppContext);

  if (ctx === undefined || ctx === null) {
    throw new Error(
      '`useExtensionConfigAppContext` must be used within a ExtensionConfigAppContext provider'
    );
  }

  return ctx;
};
