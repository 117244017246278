import { LocationDescriptor } from 'history';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { Link } from 'react-router-dom';
import styles from './styles.module.sass';

type Props = {
  to?: LocationDescriptor | (() => void);
};

const ArrowLeft = ({ to }: Props) => {
  const commonProps = {
    className: styles.HeaderIcon,
    'data-cy': 'exit-route-button',
  };

  return typeof to === 'function' ? (
    <div onClick={to} {...commonProps}>
      <ArrowLeftIcon />
    </div>
  ) : (
    <Link to={to ?? '/'} {...commonProps}>
      <ArrowLeftIcon />
    </Link>
  );
};

export default ArrowLeft;
