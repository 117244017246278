import { Typography } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';

export type ModifierMessageProps = {
  message: 'deletedModifier' | 'unknownModifier' | 'unmodified';
};

const ModifierMessage = ({ message }: ModifierMessageProps) => {
  return message === 'unmodified' ? (
    <Typography variant="body2" color="text.disabled">
      -
    </Typography>
  ) : (
    <Typography variant="body2" color="text.secondary" whiteSpace="nowrap">
      <FormattedMessage id={`containers.documents.${message}`} />
    </Typography>
  );
};

export default ModifierMessage;
