import { useSelector } from 'react-redux';
import { useUsers } from '../../../../business/users/useUsers';
import { getRoleId } from '../../../../redux/modules/groups/helpers';
import {
  isTrialSelector,
  organizationSelector,
} from '../../../../redux/modules/organization/selectors';
import {
  groupSelector,
  userSelector,
} from '../../../../redux/modules/user/selectors';
import { OrganizationUser, User } from '../../../../types/user';

type DeleteModeWithCheckboxes = 'delete_my_account' | 'delete_organization';
type DeleteModeWithoutCheckboxes =
  | 'cannot_delete_last_admin'
  | 'cannot_delete_org_group_admin'
  | 'delete_user';

type DeleteMode = DeleteModeWithCheckboxes | DeleteModeWithoutCheckboxes;

export const canRemoveUser = (mode: DeleteModeWithoutCheckboxes) => {
  if (mode === 'cannot_delete_last_admin') {
    return { canRemoveUser: false, reason: 'lastAdmin' } as const;
  }
  if (mode === 'cannot_delete_org_group_admin') {
    return { canRemoveUser: false, reason: 'orgGroupAdmin' } as const;
  }
  return { canRemoveUser: true } as const;
};

export const isModeWithCheckboxes = (
  mode: DeleteMode
): mode is DeleteModeWithCheckboxes =>
  ['delete_my_account', 'delete_organization'].includes(mode);

export const useDeleteUserMode = (
  user: OrganizationUser | User
): DeleteMode | 'loading' => {
  const isTrial = useSelector(isTrialSelector);
  const { username } = useSelector(userSelector);
  const { admins } = useSelector(organizationSelector);

  const isMyAccount = username === user.username;

  const isLastAdminInOrganization =
    'role' in user && user.role === 'admin' && admins && admins.length === 1;

  const isOrgGroupAdmin =
    'role' in user && user.role === 'organization_group_admin';

  const groups = useSelector(groupSelector);
  const adminRole = getRoleId('organization_group_admin', groups);

  const { data, isLoading } = useUsers({
    pageSize: 1,
    groups: adminRole ? [adminRole] : undefined,
  });

  const hasNoOrgGroupAdmin = data?.pagination.total === 0;

  if (isLoading) {
    return 'loading';
  }
  if (isOrgGroupAdmin) {
    return 'cannot_delete_org_group_admin';
  }

  if (isLastAdminInOrganization && hasNoOrgGroupAdmin && !isTrial) {
    return 'cannot_delete_last_admin';
  }

  if (isLastAdminInOrganization && hasNoOrgGroupAdmin && isTrial) {
    return 'delete_organization';
  }

  if (isMyAccount) {
    return 'delete_my_account';
  }

  return 'delete_user';
};
