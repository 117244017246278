import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models/queue';
import { queueSchema } from '../models/queue.schema';
import { queueGetQuerySchema } from './get.schema';

export type QueueGetQuery = Partial<{
  fields: string[];
}>;

export const get = (queueId: ID, query: QueueGetQuery = {}) => {
  return {
    endpoint: `/queues/${queueId}`,
    method: 'GET',
    responseSchema: queueSchema,
    query,
    querySchema: queueGetQuerySchema,
  } as const;
};
