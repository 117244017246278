import { BillingStats } from '@rossum/api-client/billing';
import { PaginationQuery } from '@rossum/api-client/utils';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import {
  getBillingEndpoint,
  OrganizationGroupBillingEntity,
} from './useBillingEntity';

const QUERY_KEY_BILLING_STATS_PER_ORGANIZATION =
  'billing-stats-per-organization';

export const getBillingStatsPerOrganizationPayload = (
  filters: Pick<BillingStats, 'endDate' | 'beginDate'>
) => ({
  filters: {
    beginDate: filters.beginDate,
    endDate: filters.endDate,
  },
  groupBy: ['organization' as const],
  orderBy: ['-billable_pages' as const],
});

export const usePageStatisticsPerOrganization = (
  billingEntity: OrganizationGroupBillingEntity,
  filters: Pick<BillingStats, 'endDate' | 'beginDate'>,
  pagination: Required<PaginationQuery>
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [
      QUERY_KEY_BILLING_STATS_PER_ORGANIZATION,
      billingEntity,
      filters,
      pagination,
    ] as const,

    queryFn: ({ queryKey: [, billingEntity, filters, pagination] }) => {
      const { id, endpoint } = getBillingEndpoint(billingEntity);

      return api.request(
        endpoint.billingStats.get(
          id,
          getBillingStatsPerOrganizationPayload(filters),
          {
            pageSize: pagination.pageSize,
            page: pagination.page + 1,
          }
        )
      );
    },
  });
};
