import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  suggestedRecipientsPayloadSchema,
  suggestedRecipientsResponseSchema,
} from './suggestedRecipients.schema';

export type SuggestedRecipientsPayload = {
  emailThreads?: Url[];
  annotations?: Url[];
};

export type SuggestedRecipient = {
  email: string;
  name: string | null;
  source: string;
};

export type SuggestedRecipientsResponse = {
  results: SuggestedRecipient[];
};

export const suggestedRecipients = (
  queueId: number,
  payload: SuggestedRecipientsPayload = {}
) => {
  return {
    endpoint: `/queues/${queueId}/suggested_recipients`,
    method: 'POST',
    responseSchema: suggestedRecipientsResponseSchema,
    payload,
    payloadSchema: suggestedRecipientsPayloadSchema,
  } as const;
};
