import { ElisRequestConfig } from '../../utils/requestConfig';
import { SchemaField } from '../models/schemaField';
import {
  schemaFieldListResponseSchema,
  schemaFieldListQuerySchema,
} from './list.schema';

export type SchemaFieldListQuery = {
  schemaId?: string[];
  search?: string;
  queue?: number[];
  headerFieldsOnly?: boolean;
};

export type SchemaFieldListResponse = { results: SchemaField[] };

export const list = (query: SchemaFieldListQuery = {}) => {
  return {
    endpoint: `/schemas/fields`,
    method: 'GET',
    responseSchema: schemaFieldListResponseSchema,
    query,
    querySchema: schemaFieldListQuerySchema,
  } as const;
};
