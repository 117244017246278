import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Workflow } from '../models/workflow';
import { workflowSchema } from '../models/workflow.schema';

export const get = (workflowUrl: Url) => {
  return {
    endpoint: workflowUrl,
    method: 'GET',
    responseSchema: workflowSchema,
  } as const;
};
