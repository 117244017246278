import { useEffect } from 'react';

// TODO: I guess this hook can live on some more general place, but since it is now used only in DocumentValidation (canvas and footer)
// I'll leave it here

export const useHandleDefaultSwipe = (
  element: HTMLElement | null | undefined
) => {
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const container = element;
      if (container) {
        if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) {
          // Scrolling more vertically than horizontally. Let it be!
          return;
        }
        const scrollLeftMax = container.scrollWidth - container.offsetWidth;
        if (
          container.scrollLeft + event.deltaX < 0 ||
          container.scrollLeft + event.deltaX > scrollLeftMax
        ) {
          event.preventDefault();
        }
      }
    };

    if (element) {
      element.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleWheel);
      }
    };
  }, [element]);
};
