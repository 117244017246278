import { BackupOutlined } from '@rossum/ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Input,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@rossum/ui/material';
import { HtmlHTMLAttributes, useState } from 'react';
import { useIntl } from 'react-intl';
import { Documents } from '../../../../types/documents';
import { LoadingProgressBar } from './LoadingProgressBar';

type OldUploadAreaProps = {
  ButtonProps: ButtonProps<'label'> & { 'data-cy': string };
  dropAreaMessage?: string;
  files: Documents['files'];
  uploadInputProps: HtmlHTMLAttributes<HTMLInputElement>;
  inProgress?: boolean;
  progressMessage?: string;
  sx?: SxProps<Theme>;
};

const OldUploadArea = ({
  ButtonProps,
  dropAreaMessage,
  files, // TODO: verify if this is needed
  inProgress,
  progressMessage,
  uploadInputProps,
  sx = { py: 2, mx: 4 },
}: OldUploadAreaProps) => {
  const intl = useIntl();

  const [dragging, setDragging] = useState(false);

  return (
    <Box
      component="span"
      onDragEnter={() => setDragging(true)}
      sx={{
        ...sx,
        border: '1px dashed',
        borderRadius: 2,
        display: 'flex',
        alignSelf: 'stretch',
        alignContent: 'center',
        justifyContent: 'center',
        borderColor: dragging ? 'primary.main' : 'text.disabled',
        position: 'relative',
      }}
    >
      <Stack spacing={1} alignItems="center" my={1} justifyContent="center">
        {inProgress ? (
          <LoadingProgressBar progressMessage={progressMessage} />
        ) : (
          <>
            <BackupOutlined fontSize="large" sx={{ color: 'text.secondary' }} />
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ fontWeight: 700 }}
            >
              {dropAreaMessage ||
                intl.formatMessage({
                  id: 'containers.annotationList.upload.dragndrop',
                })}
            </Typography>
            <Button {...ButtonProps} component="label">
              <Typography fontSize="small">
                {intl.formatMessage({
                  id: 'containers.annotationList.upload.clickButton',
                })}
              </Typography>
              <Input
                key={`input-button-${files.length}`}
                inputProps={uploadInputProps}
                sx={{
                  display: 'none',
                }}
              />
            </Button>
          </>
        )}
      </Stack>
      <input
        {...uploadInputProps}
        key={`input-drag-and-drop-${files.length}`}
        onDragLeave={() => setDragging(false)}
        onDrop={() => setDragging(false)}
        style={{
          display: 'none',
          top: 0,
          ...(dragging && {
            position: 'absolute',
            display: 'block',
            opacity: 0,
            height: '100%',
            width: '100%',
            background: 'white',
            zIndex: 1,
          }),
        }}
      />
    </Box>
  );
};

export default OldUploadArea;
