import { Button, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import ControlledToggle from '../../../components/ReactHookForm/Toggle';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  onClick?: () => void;
  buttonIcon?: ReactNode;
  buttonTitle?: ReactNode;
  children?: ReactNode;
  dataCy: string;
};

const DefaultAlignedToggle = <T extends FieldValues>({
  control,
  name,
  onClick,
  buttonIcon,
  buttonTitle,
  children,
  dataCy,
}: Props<T>) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <ControlledToggle
          control={control}
          name={name}
          dataCy={`${dataCy}-switch`}
        />
        <span>{children}</span>
      </Stack>

      {buttonTitle && buttonIcon && (
        <Button
          color="secondary"
          size="medium"
          startIcon={buttonIcon}
          onClick={onClick}
          data-cy={`${dataCy}-btn`}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {buttonTitle}
        </Button>
      )}
    </Stack>
  );
};

export default DefaultAlignedToggle;
