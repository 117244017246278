import { Button, Stack, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../ui/Dialog';
import { GridRowModel } from '../rows/rowTypes';

type UseRemoveFromQueues = {
  removeFromSchema: (row: GridRowModel) => void;
};

type RemoveDialogParams = {
  selectedRows: GridRowModel[];
};

export type RemoveDialogParamsSetter = (params: RemoveDialogParams) => void;

export const useRemoveFromQueues = ({
  removeFromSchema,
}: UseRemoveFromQueues) => {
  const [removeDialogParams, setRemoveDialogParams] =
    useState<RemoveDialogParams | null>(null);
  const open = removeDialogParams !== null;
  const intl = useIntl();

  const removeDialog = (
    <Dialog
      onClose={() => setRemoveDialogParams(null)}
      open={open}
      title="Confirm delete"
      actions={
        open && (
          <Stack direction="row" spacing={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setRemoveDialogParams(null)}
              data-cy="fm-remove-dialog-cancel-button"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useRemoveFromQueues.button.cancel',
              })}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                removeDialogParams?.selectedRows.forEach(removeFromSchema);
                setRemoveDialogParams(null);
              }}
              data-cy="fm-remove-dialog-delete-button"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useRemoveFromQueues.button.delete',
              })}
            </Button>
          </Stack>
        )
      }
    >
      {open && (
        <Typography variant="body2">
          {intl.formatMessage({
            id: 'features.fieldManager.fieldDetail.hooks.useRemoveFromQueues.info',
          })}{' '}
          {removeDialogParams.selectedRows.length === 1 &&
          removeDialogParams.selectedRows[0]?.meta.schema_queues.length === 1
            ? intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useRemoveFromQueues.subtitle.singular',
              })
            : intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.hooks.useRemoveFromQueues.subtitle.plural',
              })}
          ?
        </Typography>
      )}
    </Dialog>
  );

  return {
    removeDialog,
    setRemoveDialogParams,
  };
};
