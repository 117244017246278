import { Chip, Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';

export const QueueChipsSkeleton = () => {
  const skeletons = useRef(
    Array.from({ length: 2 }, () => random(100, 250))
  ).current;

  return (
    <Stack justifyContent="flex-start" direction="row" gap={1}>
      {skeletons.map((width, index) => (
        <Chip
          // eslint-disable-next-line react/no-array-index-key
          key={`${width}-${index}`}
          size="small"
          color="default"
          variant="outlined"
          label={<Skeleton width={width} />}
        />
      ))}
    </Stack>
  );
};
