import { Switch, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { linebreak } from '../../../lib/formaterValues';

type Props = {
  disabled: boolean;
  active: boolean;
  onToggle: (active: boolean) => void;
  isUserOrgGroupAdmin: boolean;
};

const Toggle = ({ disabled, active, onToggle, isUserOrgGroupAdmin }: Props) => {
  const intl = useIntl();
  const tooltipTitle = disabled
    ? isUserOrgGroupAdmin
      ? 'containers.settings.users.activityToggle.disabled.orgGroupAdmin'
      : 'containers.settings.users.activityToggle.disabled'
    : 'containers.settings.users.activityToggle.description';

  return (
    <Tooltip title={intl.formatMessage({ id: tooltipTitle }, { linebreak })}>
      <span>
        <Switch
          disabled={disabled}
          checked={active}
          onChange={(_, value) => onToggle(value)}
          size="small"
        />
      </span>
    </Tooltip>
  );
};

export default Toggle;
