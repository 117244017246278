import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';

type Props = {
  handleDeleteSelected: () => void;
};

// FIXME: handle properly colors, maybe during MUI refactoring (?)

const DeleteSelectedButton = ({ handleDeleteSelected }: Props) => (
  <div
    className={clsx(styles.ValueButton)}
    style={{ backgroundColor: '#FFA726', color: '#000' }}
    onClick={() => handleDeleteSelected()}
  >
    <div>
      {'- '}
      <FormattedMessage id="components.documentValidation.footer.inputs.deleteSelected" />
    </div>
  </div>
);

export default DeleteSelectedButton;
