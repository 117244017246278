// Width and height of drag handle
export const DRAG_HANDLE_SIZE = 16;

// Size of separator handles
export const SEPARATOR_HANDLE_SIZE = 16;

// Width and height of resize handles
export const CORNER_RESIZE_HANDLE_SIZE = 12;

// Width of grid lines in px
export const GRID_LINE_WIDTH = 2;

// Width of invisible interactive area for elements (on each side)
export const INTERACTIVE_AREA_SIZE = 5;

// Width of min distance from another separator protected area (i.e. half)
// minimum col size === minimum grid size === 2 * min separator distance
export const MIN_SEPARATOR_DISTANCE = INTERACTIVE_AREA_SIZE / 4;

// Minimal dimensions of grid in PDF pixels
export const MIN_GRID_SIZE = 10;

// Maximum width of a label button in web pixels
// we can actually set it based on button content with some JS but this is faster
// aware this causes a scrollbar to apear when label close to document edge - solve later
export const MAX_LABEL_WIDTH = 200;

// Had to adjust this to 100px over the grid to account for vertical labels
// TODO: could be done better to fit _actual_ label?
export const LABEL_VERTICAL_PADDING = 100;

export const MAX_LABEL_HEIGHT = 22;

export const LABEL_HORIZONTAL_PADDING = 8;

// RowTypes colors
export const ROW_TYPE_COLORS = [
  '#0288D1',
  '#FF8000',
  '#FFFF00',
  '#9DD100',
  '#00FFFF',
  '#FF01BF',
];

export const NOT_EXTRACTED_COLOR = '#24252B';

export const CONTROL_BAR_DISTANCE = 14;
