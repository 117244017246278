// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const applyLabelPayloadSchema = z.object({
  operations: z.object({
    add: z.array(urlSchema).optional(),
    remove: z.array(urlSchema).optional(),
  }),
  objects: z.object({
    annotations: z.array(urlSchema),
  }),
});
