import { GridColumnResizeParams } from '@rossum/ui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUiSettings } from '../../../../redux/modules/user/actions';
import { userSelector } from '../../../../redux/modules/user/selectors';
import { formulaPreviewSchema } from '../../../../types/user';

export const useColumnWidths = () => {
  const user = useSelector(userSelector);

  const columnWidths = useMemo(() => {
    return formulaPreviewSchema
      .catch({ widths: {} })
      .parse(user.uiSettings.formulaPreview).widths;
  }, [user]);

  return columnWidths;
};

export const useUpdateColumWidth = () => {
  const widths = useColumnWidths();
  const dispatch = useDispatch();

  return useCallback(
    (params: GridColumnResizeParams) =>
      dispatch(
        updateUiSettings({
          formulaPreview: {
            widths: {
              ...widths,
              [params.colDef.field]: params.width,
            },
          },
        })
      ),
    [dispatch, widths]
  );
};
