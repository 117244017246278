import { UnleashClient } from 'unleash-proxy-client';

type UnleashPrivateInterface = {
  toggles: { name: string; enabled: boolean }[];
};
export const exposeInterfaceForDevTools = (unleashClient: UnleashClient) => {
  window.__applyLocalUnleashOverrides = () => {
    const overrides = JSON.parse(
      localStorage.getItem('localUnleashOverrides') ?? '{}'
    ) as Record<string, boolean>;

    Object.entries(overrides).forEach(([name, enabled]) => {
      // HACK: We are accessing private property
      const { toggles } = unleashClient as unknown as UnleashPrivateInterface;

      const existingToggle = toggles.find(t => t.name === name);

      if (existingToggle) {
        existingToggle.enabled = enabled;
      } else {
        toggles.push({ name, enabled });
      }
    });

    // This will cause re-rendering of all components using useFlag hook,
    // if the enabled state has changed
    unleashClient.emit('update');
  };

  window.__getAllUnleashToggles = () => unleashClient.getAllToggles();
};
