import { ReactNode } from 'react';
import styles from './style.module.sass';

type Props = { children: ReactNode };

const ContentWrapper = ({ children }: Props) => (
  <div className={styles.ContentWrapper}>{children}</div>
);

export default ContentWrapper;
