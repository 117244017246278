import { create } from 'zustand';
import {
  documentBboxStoreSlice,
  DocumentBboxStoreType,
} from './documentBboxStoreSlice';
import {
  documentGeometryStoreSlice,
  DocumentGeometryStoreType,
} from './documentGeometryStoreSlice';
import {
  documentInfoStoreSlice,
  DocumentInfoStoreType,
} from './documentInfoStoreSlice';

export type DocumentStoreType = DocumentGeometryStoreType &
  DocumentBboxStoreType &
  DocumentInfoStoreType;

export const createDocumentStore = () =>
  create<DocumentStoreType>()((...args) => ({
    ...documentBboxStoreSlice(...args),
    ...documentGeometryStoreSlice(...args),
    ...documentInfoStoreSlice(...args),
  }));
