import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const htmlToContentState = (html: string) => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
    contentBlock.entityMap
  );
  return contentState;
};

export const editorStateToHtml = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  return draftToHtml(convertToRaw(currentContent));
};
