import {
  annotationsCrossQueuePayloadSchema,
  annotationsCrossQueueQuerySchema
} from './exportAnnotationCrossQueue.schema';

export type AnnotationsCrossQueueQuery = Partial<{
  format: string;
  appendColumns: string;
  prependColumns: string;
  columns: string;
  pageSize: number;
  toStatus?: 'exported' | 'exporting';
}>;

export type AnnotationsCrossQueuePayload = Partial<{
  annotations: string[];
}>;

export const exportAnnotationCrossQueue = (
  query: AnnotationsCrossQueueQuery = {},
  payload: AnnotationsCrossQueuePayload = {},
) => {
  return {
    endpoint: `annotations/export`,
    method: 'POST',
    query,
    querySchema: annotationsCrossQueueQuerySchema,
    payload,
    payloadSchema: annotationsCrossQueuePayloadSchema,
    responseType: 'blob',
  } as const;
};
