// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const startEmbeddedAnnotationPayloadSchema = z.object({
  returnUrl: urlSchema,
  cancelUrl: urlSchema,
});

export const startEmbeddedAnnotationResponseSchema = z.object({
  url: urlSchema,
});
