import { useSelector } from 'react-redux';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { userRoleNameSelector } from '../../../redux/modules/user/selectors';

export const useIsUserRestrictedToAllDocsLevel = () => {
  const userRole = useSelector(userRoleNameSelector);
  const org = useSelector(safeOrganizationSelector);

  return {
    isUserRestrictedToAllDocsLevel:
      userRole === 'viewer' && !!org?.uiSettings.restrictViewersToAllDocsLevel,
  };
};
