// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const organizationGroupQueueSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: urlSchema,
  workspace: urlSchema.nullable(),
  organization: urlSchema.nullable(),
});
