import {
  Accordion,
  accordionSummaryClasses,
  cardHeaderClasses,
  styled,
} from '@rossum/ui/material';

export const ActivityAccordion = styled(Accordion)(({ theme }) => ({
  scrollMargin: '16px',
  '&&': {
    marginBottom: '0px',
    marginTop: '8px',
  },
  [`.${accordionSummaryClasses.root}`]: {
    paddingLeft: 0,
  },
  [`&& .${accordionSummaryClasses.content}`]: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  [`.${cardHeaderClasses.title}`]: theme.typography.body1,
}));
