import { endpoints } from '@rossum/api-client';
import { TriggerUrl } from '@rossum/api-client/triggers';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';
import {
  toTriggerClientModel,
  TriggerConditionApiModel,
} from './mappers/validationMapper';

export const QUERY_KEY_TRIGGER = 'trigger';

export const useValidationTrigger = (triggerUrl: TriggerUrl | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEY_TRIGGER, triggerUrl] as const,

    // We know ID is not null because of the enabled condition.
    queryFn: ({ queryKey }) =>
      api.request(endpoints.triggers.get(queryKey[1]!)),
    enabled: !!triggerUrl,
    select: trigger => ({
      ...toTriggerClientModel(trigger.condition as TriggerConditionApiModel),
      id: trigger.id,
    }),
  });
};
