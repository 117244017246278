import { Serie } from '@rossum/api-client/annotations';
import { get, map } from 'lodash';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import Table from '../../../../components/Graphs/components/Table';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { parse } from '../../../../lib/url';
import { State } from '../../../../types/state';
import { GranularityT } from '../Granularity';

type OwnProps = { intl: IntlShape; series: Array<Serie> };

const mapStateToProps = (
  {
    router: {
      location: { search },
    },
  }: State,
  { intl, series }: OwnProps
) => ({
  name: 'timeAndCorrectionsTable' as const,
  data: map(
    series,
    ({ values: { timeAndCorrectionsPerField }, beginDate, endDate }) => {
      const key = `${beginDate}-${endDate}`;
      const date = dateFormatByGranularity(
        intl.locale,
        get(parse(search), 'groupBy') as GranularityT,
        beginDate,
        endDate
      );

      return {
        key,
        uniqueId: `${date}-${key}`,
        dimmed: timeAndCorrectionsPerField.length === 0,
        timeAndCorrectionsPerField,
        date,
        label: date,
      };
    }
  ),
});

export default injectIntl(connect(mapStateToProps)(Table));
