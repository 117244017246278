import { automation } from './automation';
import { create } from './create';
import { createFromTemplate } from './createFromTemplate';
import { deleteEndpoint } from './delete';
import { duplicate } from './duplicate';
import { exportAnnotation } from './exportAnnotation';
import { get } from './get';
import { getRelatedObjectsCounts } from './getRelatedObjectsCounts';
import { list } from './list';
import { nextAnnotation } from './nextAnnotation';
import { patch } from './patch';
import { suggestedRecipients } from './suggestedRecipients';
import { upload } from './upload';
export {
  type CreateQueueFromTemplatePayload,
  type CreateQueueFromTemplateQuery,
} from './createFromTemplate';
export { type AutomationPayload, type AutomationResponse } from './automation';
export { type QueueDuplicatePayload } from './duplicate';
export { type QueueListQuery, type QueueListQueryWithFields } from './list';

export const queues = {
  list,
  automation,
  delete: deleteEndpoint,
  get,
  patch,
  getRelatedObjectsCounts,
  createFromTemplate,
  create,
  exportAnnotation,
  upload,
  suggestedRecipients,
  duplicate,
  nextAnnotation,
};
