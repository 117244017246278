import { get } from 'lodash';
import { connect } from 'react-redux';
import { State } from '../../types/state';
import ListFooter, {
  ListFooterProps,
} from '../AnnotationList/components/ListFooter';

const mapStateToProps = (state: State): ListFooterProps => ({
  totalPages: get(state, 'extensions.pagination.totalPages'),
  listLength: get(state, 'extensions.pagination.total'),
});

export default connect<ListFooterProps, null, Record<string, never>, State>(
  mapStateToProps
)(ListFooter);
