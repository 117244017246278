import { Reducer } from 'redux';
import Immutable, { Immutable as ImmutableType } from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { setCreatingInboxAction, updateInboxFulfilled } from '../inbox/actions';
import { callHistoryMethod, locationChange } from '../utils';
import {
  clearValidationMessagesAction,
  setValidationMessagesAction,
} from './action';
import { ValidationMessagesState } from './types';

const initialState = Immutable<ValidationMessagesState>({});

const validationMessagesReducer: Reducer<
  ImmutableType<ValidationMessagesState>,
  RootActionType
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(setValidationMessagesAction): {
      return state.set(action.meta.formId, action.payload);
    }

    case getType(clearValidationMessagesAction): {
      const { formId, inputId } = action.meta;

      return state.update(
        formId,
        ({ [inputId]: deletedValidation, ...validations }) => ({
          ...validations,
        })
      );
    }

    case getType(locationChange):
    case getType(callHistoryMethod):
    case getType(setCreatingInboxAction):
    case getType(updateInboxFulfilled):
      return initialState;
    default:
      return state;
  }
};

export default validationMessagesReducer;
