// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { dedicatedEngineSchemaObjectPatchModelSchema } from '../models/dedicatedEngineSchemaObject.schema';

export const dedicatedEngineSchemaPatchPayloadSchema = z.object({
  content: z.object({
    trainingQueues: z.array(urlSchema),
    fields: z.array(dedicatedEngineSchemaObjectPatchModelSchema),
  }),
});
