import { useSelector } from 'react-redux';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { isUserOfRoleSelector } from '../../../redux/modules/user/selectors';
import { State } from '../../../types/state';

// Returns org group url for org group admin, undefined otherwise
export const useOrganizationGroupUrl = () => {
  return useSelector((state: State) =>
    isUserOfRoleSelector(state)('organization_group_admin')
      ? safeOrganizationSelector(state)?.organizationGroup
      : undefined
  );
};
