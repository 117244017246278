import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { annotationWorkflowPayloadSchema } from './approve.schema';

export type AnnotationWorkflowPayload = {
  noteContent?: string;
};

export const approve = (
  annotationId: number,
  payload: AnnotationWorkflowPayload
) => {
  return {
    endpoint: `/annotations/${annotationId}/approve`,
    method: 'POST',
    payload,
    responseSchema: z.unknown(),
    payloadSchema: annotationWorkflowPayloadSchema,
  } as const;
};
