import { Stack } from '@rossum/ui/material';
import { Rectangle2D } from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { useDocumentContext } from '../../DocumentContext';
import { ValidationDialog } from '../validation-dialog/ValidationDialog';
import { ContentInBboxCoordinates } from './ContentInBboxCoordinates';

type ValidationDialogSvgProps = {
  position: Rectangle2D | undefined;
};

export const ValidationDialogSvg = ({ position }: ValidationDialogSvgProps) => {
  const { canvasActionInProgress } = useDocumentContext();
  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);

  const totalZoom = useDocumentStore(
    state => state.canvasState.zoomLevel * state.viewportState.zoomLevel
  );

  if (!position) {
    return null;
  }

  const canChangeValue = selectedDatapointIds.length === 0;
  const width = totalZoom * position.width;
  const widthWithPadding = canChangeValue ? width + 40 : width;

  return position && !canvasActionInProgress ? (
    <ContentInBboxCoordinates
      id="editbox-wrapper"
      pos={position}
      style={{ width: widthWithPadding }}
      sx={{
        minWidth: canChangeValue ? 150 : undefined,
        paddingTop: 1,
        mx: canChangeValue ? -2.5 : undefined,
        zIndex: 10002,
      }}
    >
      <Stack
        direction={canChangeValue ? 'column' : 'row'}
        justifyContent="center"
      >
        <ValidationDialog parentWidth={width} />
      </Stack>
    </ContentInBboxCoordinates>
  ) : null;
};
