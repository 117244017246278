import { ElisRequestConfig } from '../../utils/requestConfig';
import { userSchema } from '../models';
import { User } from '../models/user';

export const get = (userId: string) => {
  return {
    endpoint: `/users/${userId}`,
    method: 'GET',
    responseSchema: userSchema,
  } as const;
};
