import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

const QUERY_KEY_ORGANIZATION_LIMITS = 'organization-limits';

export const useOrganizationLimits = (organizationId: number | undefined) =>
  useQuery({
    queryKey: [QUERY_KEY_ORGANIZATION_LIMITS, organizationId] as const,

    queryFn: () => {
      if (!organizationId) {
        return Promise.reject();
      }
      return api.request(endpoints.organization.limits(organizationId));
    },
    refetchInterval: (_, query) =>
      query.state.status === 'error' ? false : 10 * 1000,
  });
