import { DoNotDisturbAlt, Forward, MailOutline, Reply } from '@rossum/ui/icons';

export const emailSummaryIcon: Record<
  | ('rejection' | 'forwarded' | 'reply' | 'default')
  | (string & Record<never, never>),
  JSX.Element
> = {
  rejection: <DoNotDisturbAlt fontSize="small" color="error" />,
  automatic_rejection: <DoNotDisturbAlt fontSize="small" color="error" />,
  forwarded: <Forward fontSize="small" />,
  automatic_status_changed_info: <Reply fontSize="small" />,
  reply: <Reply fontSize="small" />,
  default: <MailOutline fontSize="small" />,
};
