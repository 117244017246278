import { Serie } from '@rossum/api-client/annotations';
import { get, map } from 'lodash';
import { ReactNode } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  colorPrimary,
  colorSuccess,
  gray,
  grayLight,
} from '../../../../components/Graphs/constants';
import StackedBarGraph, {
  StackedBarGraphProps,
} from '../../../../components/Graphs/StackedBar';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { parse } from '../../../../lib/url';
import { State } from '../../../../types/state';
import { GranularityT } from '../Granularity';

type OwnProps = {
  intl: IntlShape;
  isTmpState: boolean;
  children?: ReactNode;
  series: Array<Serie>;
};

const mapStateToProps = (
  {
    router: {
      location: { search },
    },
  }: State,
  { intl, isTmpState, series }: OwnProps
): StackedBarGraphProps<'exportedNonAutomated' | 'exportedAutomatedCount'> => ({
  name: 'automated' as const,
  bars: [
    {
      name: 'exportedNonAutomated' as const,
      color: isTmpState ? gray : colorPrimary,
    },
    {
      name: 'exportedAutomatedCount' as const,
      color: isTmpState ? grayLight : colorSuccess,
    },
  ],
  data: map(
    series,
    ({
      values: { exportedAutomatedCount, exportedCount },
      beginDate,
      endDate,
    }) => ({
      exportedNonAutomated: exportedCount ?? undefined,
      exportedAutomatedCount: exportedAutomatedCount ?? undefined,
      date: dateFormatByGranularity(
        intl.locale,
        get(parse(search), 'groupBy') as GranularityT,
        beginDate,
        endDate
      ),
    })
  ),
  formatLabel: !isTmpState
    ? s => intl.formatMessage({ id: `components.graphs.automated.series.${s}` })
    : undefined,
});

export default injectIntl(
  connect(mapStateToProps)(
    StackedBarGraph<'exportedNonAutomated' | 'exportedAutomatedCount'>
  )
);
