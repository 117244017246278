import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Organization } from '../../../types/organization';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import {
  fetchOrganizationAdminsFulfilled,
  fetchOrganizationFulfilled,
  updateOrganization,
} from './actions';

const initialState = Immutable<Partial<Organization>>({
  id: undefined,
  isTrial: undefined,
  oidcProvider: undefined,
  organizationGroup: undefined,
  metadata: {},
  name: '',
  uiSettings: {},
  url: '',
  users: [],
  workspaces: [],
  admins: [],
  sandbox: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchOrganizationFulfilled):
      return state.merge(action.payload, { deep: true });

    case getType(fetchOrganizationAdminsFulfilled):
      return state.set('admins', action.payload.results);

    case getType(updateOrganization):
      return state.merge(action.payload, { deep: true });

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
