import { Check, ContentCopy } from '@rossum/ui/icons';
import { Button, ButtonProps, Grow, Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { SwitchTransition } from 'react-transition-group';
import { useCopyToClipboard } from './useCopyToClipboard';

type CopyToClipboardButtonProps = {
  content: string;
  iconSize?: '20px' | '16px'; // we should use "small" | "medium" etc., but currently we don't have smaller icon then "small" which is 20px
  iconColor?:
    | 'inherit'
    | 'text.disabled'
    | 'text.secondary'
    | 'text.primary'
    | 'secondary.main';
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
};

const CopyToClipboardButton = ({
  content,
  iconSize = '20px',
  iconColor = 'inherit',
  children,
  buttonProps,
}: CopyToClipboardButtonProps) => {
  const intl = useIntl();
  const { copied, copyToClipboard } = useCopyToClipboard(content);

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'components.clipboardButton.copy',
      })}
      placement="top"
    >
      <Button
        sx={{
          minWidth: 0,
          p: children ? 0.5 : 1,
          animation: ({ transitions: { duration } }) =>
            `fadeIn ${duration.shorter}ms`,
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          copyToClipboard();
        }}
        {...(buttonProps ?? {})}
      >
        <SwitchTransition>
          <Grow
            key={copied ? 'copied' : 'copy'}
            appear
            unmountOnExit
            timeout={100}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={0.5}
              color={iconColor}
              sx={{ fontSize: iconSize }}
            >
              {children}
              {copied ? (
                <Check fontSize="inherit" />
              ) : (
                <ContentCopy fontSize="inherit" />
              )}
            </Stack>
          </Grow>
        </SwitchTransition>
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
