import { useSelector } from 'react-redux';
import { uploadAdditionalValuesSelector } from '../../../redux/modules/organizationGroup/selectors';
import { Upload } from './old-upload/Upload';
import { UploadProps, UploadV2 } from './UploadV2';

export const UploadWrapper = (props: UploadProps) => {
  const newUploadEnabled = useSelector(uploadAdditionalValuesSelector);

  return newUploadEnabled ? <UploadV2 {...props} /> : <Upload {...props} />;
};
