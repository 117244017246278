import { Tooltip } from '@rossum/ui/material';
import {
  GridEditInputCell,
  GridRenderEditCellParams,
} from '@rossum/ui/x-data-grid-pro';

const EditInputCellWithValidation = ({
  errorMessage,
  ...props
}: GridRenderEditCellParams) => {
  const { error } = props;
  const hasError = !!error;

  return (
    <Tooltip
      open={hasError}
      title={errorMessage}
      arrow={false}
      sx={{
        height: '50%',
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'error.main',
            color: 'error.contrastText',
          },
        },
      }}
    >
      <span>
        <GridEditInputCell {...props} />
      </span>
    </Tooltip>
  );
};

export const EditCellWithValidation = (params: GridRenderEditCellParams) => (
  <EditInputCellWithValidation {...params} />
);
