// Generated by ts-to-zod
import { z } from 'zod';
import { datapointFieldPatchModelSchema } from './datapointField.schema';
import { datapointFieldSchema } from './transformedDatapointField.schema';

export const baseSimpleMultivalueFieldSchema = z.object({
  category: z.literal('multivalue'),
  children: datapointFieldSchema,
});

export const simpleMultivalueFieldPatchModelSchema = z.object({
  category: z.literal('multivalue'),
  children: datapointFieldPatchModelSchema.optional(),
});
