// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const dedicatedEngineStatusSchema = z.union([
  z.literal('sample_review'),
  z.literal('draft'),
  z.literal('schema_review'),
  z.literal('annotating_initial'),
  z.literal('annotating_review'),
  z.literal('annotating_training'),
  z.literal('training_started'),
  z.literal('training_finished'),
  z.literal('retraining'),
]);

export const dedicatedEngineSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: urlSchema,
  status: dedicatedEngineStatusSchema,
  schema: urlSchema.nullable(),
  description: z.string(),
});
