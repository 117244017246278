import clsx from 'clsx';
import ArrowExpandIcon from 'mdi-react/ArrowExpandIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { MouseEventHandler } from 'react';
import Tooltip from '../../../components/UI/Tooltip/LazyTooltip';
import styles from '../style.module.sass';

type Props = {
  onZoomIn: MouseEventHandler;
  onZoomOut: MouseEventHandler;
  onZoomReset: MouseEventHandler;
};

const Zoom = ({ onZoomIn, onZoomOut, onZoomReset }: Props) => (
  <>
    <Tooltip
      data-cy="zoom-reset"
      id="components.documentValidation.controlPanel.tooltip.resetZoom"
    >
      <ArrowExpandIcon
        className={clsx(styles.IconButton, styles.ResetZoomIcon)}
        onClick={onZoomReset}
      />
    </Tooltip>
    <Tooltip
      data-cy="zoom-in"
      id="components.documentValidation.controlPanel.tooltip.zoomIn"
    >
      <PlusIcon
        className={clsx(styles.IconButton, styles.LargeIcon)}
        onClick={onZoomIn}
      />
    </Tooltip>
    <Tooltip
      data-cy="zoom-out"
      id="components.documentValidation.controlPanel.tooltip.zoomOut"
    >
      <MinusIcon
        className={clsx(styles.IconButton, styles.LargeIcon)}
        onClick={onZoomOut}
      />
    </Tooltip>
  </>
);

export default Zoom;
