import {
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchExtensionDetailFulfilled } from '../../../../../redux/modules/extensions/actions';
import { Extension } from '../../../../../types/extensions';
import { getExtensionConfigAppUrl } from '../helpers';

type DebugConfigAppInputProps = {
  extension: Extension;
};

const DebugConfigAppInput = ({ extension }: DebugConfigAppInputProps) => {
  const urlInStore = getExtensionConfigAppUrl(extension);
  const [url, setUrl] = useState<string>(urlInStore ?? '');

  const displayModeInStore = extension.config.app?.displayMode ?? 'drawer';
  const [displayMode, setDisplayMode] = useState<'drawer' | 'fullscreen'>(
    displayModeInStore
  );

  const dispatch = useDispatch();

  const setFakeUrlToStore = () => {
    dispatch(
      // @ts-expect-error
      fetchExtensionDetailFulfilled({
        ...extension,
        config: {
          ...extension.config,
          app: { ...extension.config.app, url, displayMode },
        },
      })
    );
  };

  const toggleDisplayModeHandler = () =>
    setDisplayMode(prev => (prev === 'drawer' ? 'fullscreen' : 'drawer'));

  return (
    <Paper elevation={10} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h6" gutterBottom>
          Debug
        </Typography>
        <Stack spacing={2} flex={1} alignItems="flex-start">
          <TextField
            label="Config app url"
            fullWidth
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="body2">
              <strong>Display mode: </strong>
              {displayMode}
            </Typography>
            <Button
              variant="outlined"
              onClick={toggleDisplayModeHandler}
              sx={{ whiteSpace: 'nowrap' }}
              size="small"
            >
              Toggle display mode
            </Button>
          </Stack>
          <Button
            variant="contained"
            disabled={
              (displayModeInStore === displayMode && urlInStore === url) || !url
            }
            onClick={setFakeUrlToStore}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Change temporarily
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default DebugConfigAppInput;
