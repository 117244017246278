import createDOMPurify from 'dompurify';
import { sanitizeLinks } from '../../lib/htmlSanitization';
import messageStyles from '../Datapoint/style.module.sass';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

const HTMLMessage = ({ content }: { content: string }) => (
  <div
    className={messageStyles.HTMLMessage}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(content, {
        ALLOWED_TAGS: ['b', 'br', 'u', 'strong', 'a', 'ul', 'ol', 'li', 'i'],
        FORBID_ATTR: ['style', 'draggable'],
      }),
    }}
  />
);

export default HTMLMessage;
