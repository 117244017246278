import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailThread } from '../models/emailThreads';
import { emailThreadSchema } from '../models/emailThreads.schema';
import { emailThreadsListQuerySchema } from './list.schema';

export type EmailThreadsListQuery = Partial<{
  queue: number;
  hasRootEmail: boolean;
  hasReplies: boolean;
  hasNewReplies: boolean;
  createdAtBefore: string;
  createdAtAfter: string;
  lastEmailCreatedAtBefore: string;
  lastEmailCreatedAtAfter: string;
  recentWithNoDocumentsNotReplied: boolean;
  search: string;
  ordering: string;
}> &
  PaginationQuery;

export const list = (query: EmailThreadsListQuery = {}) => {
  return {
    endpoint: `/email_threads`,
    method: 'GET',
    responseSchema: listResponse(emailThreadSchema),
    query,
    querySchema: emailThreadsListQuerySchema,
  } as const;
};
