import { ZodObject, ZodRawShape, ZodType, z } from 'zod';
import { camelToSnake } from './keyConvertor';

export const withSideload = <
  SideloadShape extends ZodRawShape,
  ResponseShape extends ZodRawShape,
  Mask extends {
    [k in keyof SideloadShape]?: true;
  }
>(
  endpoint: {
    sideloadSchema: ZodObject<SideloadShape>;
    responseSchema: ZodObject<ResponseShape>;
    querySchema: ZodType<unknown>;
    endpoint: string;
    query: Record<string, unknown>;
  },
  mask: Mask
) => {
  const sideload = Object.entries(mask)
    .filter(([k, v]) => v === true)
    .map(([k]) => camelToSnake(k));

  return {
    ...endpoint,
    responseSchema: endpoint.responseSchema.and(
      endpoint.sideloadSchema.pick(mask)
    ),
    querySchema: endpoint.querySchema.and(
      z.object({
        sideload: z.array(z.string()),
      })
    ),
    query: {
      ...endpoint.query,
      sideload,
    },
  } as const;
};
