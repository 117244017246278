import { useSelector } from 'react-redux';
import { canUploadRoles } from '../../../components/Restrictors';
import { UPLOAD_HASH } from '../../../constants/values';
import { userRoleNameSelector } from '../../../redux/modules/user/selectors';
import { State } from '../../../types/state';

export const useUploadEnabled = (currentQueueExist: boolean): boolean => {
  const userRole = useSelector(userRoleNameSelector);
  const showUpload = useSelector(
    (state: State) => state.router.location.hash === UPLOAD_HASH
  );

  return currentQueueExist && canUploadRoles.includes(userRole) && !showUpload;
};
