import { getHistory } from '../../billing/endpoints/getHistory';
import { getHistoryExport } from '../../billing/endpoints/getHistoryExport';
import { getStats } from '../../billing/endpoints/getStats';
import { getStatsExport } from '../../billing/endpoints/getStatsExport';
import { organizations } from './organizations';
import { queues } from './queues';
import { workspaces } from './workspaces';
export { type OrganizationGroupOrganizationListQuery } from './organizations';
export { type OrganizationGroupQueueListQuery } from './queues';
export { type OrganizationGroupWorkspaceListQuery } from './workspaces';

export const organizationGroups = {
  billingStats: {
    get: getStats('organization_groups'),
    export: getStatsExport('organization_groups'),
  },
  billingHistory: {
    get: getHistory('organization_groups'),
    export: getHistoryExport('organization_groups'),
  },
  workspaces,
  queues,
  organizations,
};
