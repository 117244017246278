import DragIcon from 'mdi-react/DragHorizontalIcon';
import {
  ComponentType,
  CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EMPTY, fromEvent } from 'rxjs';
import {
  filter,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getMaxHeight, MIN_FOOTER_HEIGHT } from './config';
import { getAdjustedHeight } from './helpers';
import styles from './style.module.sass';

type Props = {
  minHeight: number;
  height?: number;
  setHeight?: (_height: number) => void;
  style?: CSSProperties;
};

type WrappedProps = {
  resizing: boolean;
  style: CSSProperties;
  height: number;
};

const makeResizable =
  <T,>(Wrapped: ComponentType<T & WrappedProps>) =>
  (props: Props & T) => {
    const { setHeight, height = props.minHeight, minHeight } = props;

    const ref = useRef<HTMLDivElement>(null);

    const [ownHeight, setOwnHeight] = useState(
      getAdjustedHeight(height, minHeight)
    );

    const [resizing, setResizing] = useState(false);

    useEffect(() => {
      const mousedown = ref.current
        ? fromEvent(ref.current, 'mousedown')
        : EMPTY;
      const mouseup = fromEvent(document, 'mouseup');
      const mousemove = fromEvent(document, 'mousemove');

      const resizeSubscription = mousedown
        .pipe(
          switchMap(() =>
            mousemove.pipe(
              tap(event => {
                event.preventDefault();
                event.stopPropagation();
              }),
              map(event => event as MouseEvent),
              pluck('clientY'),
              map((y: number) => (window.innerHeight as number) - y),
              filter(_height => _height > MIN_FOOTER_HEIGHT),
              filter(_height => _height < getMaxHeight()),
              takeUntil(mouseup)
            )
          )
        )
        .subscribe(_height => {
          setOwnHeight(_height);
          setResizing(true);
        });

      const mouseUpSubscription = mousedown
        .pipe(
          switchMap(() =>
            mousemove.pipe(
              take(1),
              switchMap(() => mouseup)
            )
          )
        )
        .subscribe(() => {
          setResizing(false);
        });

      return () => {
        resizeSubscription.unsubscribe();
        mouseUpSubscription.unsubscribe();
        if (setHeight) setHeight(0);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const adjustedHeight = getAdjustedHeight(height, minHeight);

      if (setHeight) setHeight(adjustedHeight);
      setOwnHeight(adjustedHeight);
    }, [height, minHeight, setHeight]);

    useEffect(() => {
      if (setHeight) setHeight(ownHeight);
    }, [ownHeight, setHeight]);

    return (
      <>
        <div
          className={styles.ResizeButton}
          ref={ref}
          style={{
            bottom: ownHeight,
          }}
        >
          <DragIcon />
        </div>
        <Wrapped
          {...props}
          resizing={resizing}
          height={ownHeight}
          style={{ ...props.style, height: ownHeight }}
        />
      </>
    );
  };

export default makeResizable;
