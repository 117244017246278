import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UPLOAD_HASH } from '../../../constants/values';
import { ESC_CODE, filterKeys } from '../../../lib/keyboard';

type Props = {
  onEscape: () => void;
};
export const useUploadEvents = ({ onEscape }: Props) => {
  const history = useHistory();
  const showUpload = history.location.hash === UPLOAD_HASH;

  useEffect(() => {
    const onEscapeObserver = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(filterKeys([ESC_CODE])),
        filter(() => showUpload)
      )
      .subscribe(() => {
        onEscape();
      });

    return () => onEscapeObserver.unsubscribe();
  }, [onEscape, showUpload]);
};
