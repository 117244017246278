import {
  Box,
  Button,
  Link as MuiLink,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { removeRossumAppDomainCookie } from '../../../appDomain';
import AuthProviders from '../../../components/AuthProviders';
import {
  appAuthInfoUrl,
  customerDomainEnabled,
} from '../../../constants/config';
import { useSsoProviders } from '../useSsoProviders';

const Options = () => {
  const intl = useIntl();

  const { hostname } = new URL(window.location.origin);
  const { data: authProviders } = useSsoProviders({ domain: hostname });

  const handleChangeDomain = () => {
    removeRossumAppDomainCookie();
    window.location.href = appAuthInfoUrl;
  };

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      {authProviders && authProviders.length ? (
        <AuthProviders authProviders={authProviders} domain={hostname} />
      ) : null}
      <Stack spacing={1} alignItems="center">
        {customerDomainEnabled && appAuthInfoUrl && (
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage({ id: 'containers.login.domain' })}
            {hostname}
          </Typography>
        )}
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap={1}
          spacing={1}
          divider={
            <Box
              sx={{
                width: '6px',
                height: '6px',
                backgroundColor: theme => theme.palette.primary.main,
                borderRadius: '50%',
              }}
            />
          }
        >
          {customerDomainEnabled && appAuthInfoUrl && (
            <MuiLink
              color="text.primary"
              onClick={handleChangeDomain}
              variant="caption"
              sx={{
                textDecorationColor: 'inherit',
                cursor: 'pointer',
                '&:hover': {
                  color: 'text.primary',
                  textDecoration: 'none',
                },
              }}
            >
              {intl.formatMessage({ id: 'containers.login.wrongDomain' })}
            </MuiLink>
          )}
          <MuiLink
            component={Link}
            color="text.primary"
            variant="caption"
            to="/recovery"
            sx={{
              textDecorationColor: 'inherit',
              '&:hover': {
                color: 'text.primary',
                textDecoration: 'none',
              },
            }}
          >
            {intl.formatMessage({ id: 'containers.login.recovery' })}
          </MuiLink>
        </Stack>
      </Stack>
      {appAuthInfoUrl && (
        <Button
          component="a"
          href={`${appAuthInfoUrl}/registration`}
          color="secondary"
          variant="outlined"
          sx={{
            display: 'inline',
            alignSelf: 'center',
            ':hover': { color: 'secondary.main' },
          }}
        >
          {intl.formatMessage({ id: 'containers.login.signup' })}
        </Button>
      )}
    </Stack>
  );
};

export default Options;
