import {
  MenuItem,
  MenuItemProps,
  Stack,
  Typography,
} from '@rossum/ui/material';

type AutocompleteItemProps = {
  MenuItemProps: MenuItemProps;
  label: string;
  description: string;
};

export const AutocompleteItem = ({
  MenuItemProps,
  label,
  description,
}: AutocompleteItemProps) => {
  return (
    <MenuItem {...MenuItemProps} sx={{ whiteSpace: 'normal' }}>
      <Stack direction="column">
        <Typography variant="body2">{label}</Typography>
        <Typography variant="caption" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </MenuItem>
  );
};
