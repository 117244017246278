import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngine } from '../models/dedicatedEngine';
import { dedicatedEngineSchema } from '../models/dedicatedEngine.schema';
import { dedicatedEnginePatchPayloadSchema } from './patch.schema';

export type DedicatedEnginePatchPayload = Partial<{
  name: string;
  schema: Url;
}>;

export const patch = (engineId: ID, payload: DedicatedEnginePatchPayload) => {
  return {
    endpoint: `/dedicated_engines/${engineId}`,
    method: 'PATCH',
    responseSchema: dedicatedEngineSchema,
    payloadSchema: dedicatedEnginePatchPayloadSchema,
    payload,
  } as const;
};
