// Generated by ts-to-zod
import { z } from 'zod';

export const suggestedRecipientSourcesSettingsSchema = z.array(
  z
    .object({
      source: z.union([
        z.literal('email_header'),
        z.literal('extracted_value'),
        z.literal('vendor_database'),
        z.literal('queue_mailing_history'),
        z.literal('organization_users'),
      ]),
      schemaId: z.string(),
    })
    .partial()
);
