import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { requestExtensionPayloadSchema } from './requestExtension.schema';

export type RequestExtensionPayload = {
  email: string;
  phone?: string;
  // Extension name
  extension: string;
  message: string;
};

export const requestExtension = (payload: RequestExtensionPayload) => {
  return {
    endpoint: `/internal/request_extension`,
    method: 'POST',
    responseSchema: z.unknown(),
    payload,
    payloadSchema: requestExtensionPayloadSchema,
  } as const;
};
