import { createContext, RefObject, useContext } from 'react';
import { Rectangle2D } from '../../document-store/helpers/geometry';

type DocumentPageContext = {
  pageDimensions: Rectangle2D;
  pageRef: RefObject<SVGSVGElement>;
};

export const DocumentPageContext = createContext<
  DocumentPageContext | undefined
>(undefined);

export const useDocumentPageContext = () => {
  return useContext(DocumentPageContext);
};
