import { ID } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY_QUEUES_UNPAGINATED } from './useUnpaginatedQueues';

export const removeQueueMutation = (queryClient: QueryClient, queueId: ID) => {
  queryClient.setQueriesData<Queue[] | undefined>(
    [QUERY_KEY_QUEUES_UNPAGINATED],
    prev => prev?.filter(q => q.id !== queueId)
  );
};
