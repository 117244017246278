// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { editDataSourceSchema } from '../models/editPages.schema';
import { processingDurationSchema } from '../models/processingDuration.schema';

export const editPagesInPlacePayloadSchema = z.object({
  parentPages: z.array(
    z.object({
      rotationDeg: z.number(),
      page: urlSchema,
    })
  ),
  targetQueue: urlSchema.optional(),
  processingDuration: processingDurationSchema.optional(),
  editDataSource: editDataSourceSchema.optional(),
});

export const editPagesInPlaceResponseSchema = z.object({
  results: z.array(
    z.object({
      document: urlSchema,
      annotation: urlSchema,
    })
  ),
});
