import { endpoints } from '@rossum/api-client';
import { CreateLabelPayload } from '@rossum/api-client/labels';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { UNPAGINATED_LABELS_QUERY_KEY } from './useRequestLabels';

const MUTATION_KEY_CREATE_LABEL = 'createLabel';

export const useCreateLabel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATION_KEY_CREATE_LABEL] as const,
    mutationFn: (params: CreateLabelPayload) =>
      api.request(endpoints.labels.create(params)),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UNPAGINATED_LABELS_QUERY_KEY],
      }),
  });
};
