import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Workflow } from '../models';
import { workflowSchema } from '../models/workflow.schema';
import { workflowsListQuerySchema } from './list.schema';

export type WorkflowsListQuery = Partial<{
  id: ID | ID[];
}> &
  PaginationQuery;

export const list = (query: WorkflowsListQuery = {}) => {
  return {
    endpoint: `/workflows`,
    method: 'GET',
    responseSchema: listResponse(workflowSchema),
    query,
    querySchema: workflowsListQuerySchema,
  } as const;
};
