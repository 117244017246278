import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { removeQueueFromState } from '../../redux/modules/queues/actions';
import { removeQueueMutation } from './removeQueueMutation';
import { QUERY_KEY_LAZY_QUEUES } from './useLazyQueues';

export const useDeleteQueue = (onSuccess: (queueId: number) => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (queueId: ID) => api.request(endpoints.queues.delete(queueId)),

    onSuccess: (_, queueId) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_QUEUES],
      });

      // remove queue from the list of queues
      removeQueueMutation(queryClient, queueId);

      dispatch(removeQueueFromState(queueId));
      onSuccess(queueId);
    },
  });
};
