import {
  isSchemaSection,
  isSchemaSimpleMultiValue,
  isSchemaTableMultiValue,
  SchemaDatapoint,
  SchemaDatapointButton,
  SchemaItem,
  SchemaSection,
  SchemaSimpleMultivalue,
  SchemaTableMultivalue,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { assertNever } from '../../../../../lib/typeUtils';
import { FieldsFormModel } from '../formModels';
import { AllKeys, AnySchemaItem } from './toApiModel.utils';

export type AllSchemaDatapointKeys = Exclude<
  AllKeys<Exclude<SchemaDatapoint, SchemaDatapointButton>>,
  'suggest'
>;

const emptySchemaDatapoint = {
  category: 'datapoint',
  id: '',
  label: '',
  description: '',
  hidden: null,
  canExport: null,
  canCollapse: false,
  rirFieldNames: [],
  defaultValue: null,
  constraints: null,
  uiConfiguration: null,
  width: null,
  stretch: null,
  widthChars: null,
  scoreThreshold: null,
  formula: null,
  disablePrediction: null,
  type: 'string',
  format: null,
  aggregations: null,
  options: [],
  enumValueType: 'string',
} satisfies Pick<AnySchemaItem, AllSchemaDatapointKeys>;

type AllSchemaDatapointButtonKeys = AllKeys<SchemaDatapointButton>;

const emptySchemaDatapointButton = {
  category: 'datapoint',
  type: 'button',
  id: '',
  label: '',
  description: '',
  hidden: null,
  popupUrl: '',
  canObtainToken: false,
  canExport: null,
  canCollapse: false,
  disablePrediction: null,
} satisfies Pick<AnySchemaItem, AllSchemaDatapointButtonKeys>;

type SchemaSectionKeys = AllKeys<SchemaSection>;

const emptySchemaSection = {
  category: 'section',
  id: '',
  label: '',
  description: '',
  hidden: null,
  disablePrediction: null,
  icon: null,
  children: [],
} satisfies Pick<AnySchemaItem, SchemaSectionKeys>;

type SchemaTableMultivalueKeys = AllKeys<SchemaTableMultivalue>;

const emptySchemaTableMultivalue = {
  category: 'multivalue',
  id: '',
  label: '',
  description: '',
  hidden: null,
  rirFieldNames: [],
  disablePrediction: null,
  minOccurrences: null,
  maxOccurrences: null,
  grid: null,
  showGridByDefault: null,
  children: {
    category: 'tuple',
    id: '',
    label: '',
    description: '',
    children: [],
    rirFieldNames: [],
  },
} satisfies Pick<AnySchemaItem, SchemaTableMultivalueKeys>;

type SchemaSimpleMultivalueKeys = AllKeys<SchemaSimpleMultivalue>;

const emptySchemaSimpleMultivalue = {
  category: 'multivalue',
  id: '',
  label: '',
  description: '',
  hidden: null,
  disablePrediction: null,
  minOccurrences: null,
  maxOccurrences: null,
  rirFieldNames: null,
  grid: null,
  showGridByDefault: null,
  children: {
    ...emptySchemaDatapoint,
    rirFieldNames: [],
  },
} satisfies Pick<AnySchemaItem, SchemaSimpleMultivalueKeys>;

const getEmptyModelForType = (modelType: FieldsFormModel['fieldType']) => {
  switch (modelType) {
    case 'section':
      return emptySchemaSection;
    case 'simpleValue':
      return R.omit(emptySchemaDatapoint, ['width', 'widthChars', 'stretch']);
    case 'lineItemSimpleValue':
      return emptySchemaDatapoint;
    case 'multivalue':
      return emptySchemaSimpleMultivalue;
    case 'button':
      return emptySchemaDatapointButton;
    case 'lineItemButton':
      return emptySchemaDatapointButton;
    case 'lineItems':
      return emptySchemaTableMultivalue;
    default:
      return assertNever(modelType);
  }
};

// TODO: Rewrite more concisely
export const backfillValues =
  (apiModel: SchemaSection | SchemaItem | null) =>
  (targetModelType: FieldsFormModel['fieldType']) => {
    if (apiModel === null) {
      return getEmptyModelForType(targetModelType);
    }

    if (targetModelType === 'section') {
      return R.merge(
        emptySchemaSection,
        isSchemaSection(apiModel)
          ? apiModel
          : // if source wasn't a section, we don't care about children
            R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    if (targetModelType === 'simpleValue') {
      return R.merge(
        getEmptyModelForType('simpleValue'),
        R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    if (targetModelType === 'lineItemSimpleValue') {
      return R.merge(
        getEmptyModelForType('lineItemSimpleValue'),
        R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    if (targetModelType === 'button') {
      return R.merge(
        emptySchemaDatapointButton,
        R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    if (targetModelType === 'lineItemButton') {
      return R.merge(
        getEmptyModelForType('lineItemButton'),
        R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    if (targetModelType === 'multivalue') {
      return R.mergeAll([
        emptySchemaSimpleMultivalue,
        // first, omit
        R.omit(apiModel as AnySchemaItem, ['children']),
        // only add it if source was already a multivalue
        isSchemaSimpleMultiValue(apiModel)
          ? {
              // and in that case backfill children
              // this is instead of a deep merge
              children: R.merge(
                emptySchemaSimpleMultivalue.children,
                apiModel.children
              ),
            }
          : {},
      ]);
    }

    if (targetModelType === 'lineItems') {
      return R.merge(
        emptySchemaTableMultivalue,
        isSchemaTableMultiValue(apiModel)
          ? apiModel
          : // if it wasn't already a line item, we don't care about children
            R.omit(apiModel as AnySchemaItem, ['children'])
      );
    }

    return emptySchemaDatapoint;
  };
