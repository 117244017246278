import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import createDeepSelector from '../../../../lib/createDeepEqualSelector';
import {
  ReplaceSuggestedOperation,
  SuggestedOperationState,
  SuggestedOperationWithMeta,
} from '../../../../types/datapoints';
import { State } from '../../../../types/state';
import { schemaMapSelector } from '../../schema/schemaMapSelector';
import { datapointsSelector, suggestedTuplesSelector } from '../selector';
import { isBoundedDatapoint } from '../typedHelpers';

const _suggestedOperationsSelector = (state: State) =>
  state.datapoints.suggestedOperations;

export const suggestedOperationsSelector = createDeepSelector(
  _suggestedOperationsSelector,
  // The ?? {} is there only to fix tests with incomplete mock states.
  operations => operations ?? {}
);

export const replaceSuggestedOperationsSelector = createSelector(
  suggestedOperationsSelector,
  operations =>
    Object.values(operations).reduce<
      Record<number, SuggestedOperationState<ReplaceSuggestedOperation>>
    >(
      (acc, operation) =>
        operation.op === 'replace'
          ? { ...acc, [operation.id]: operation }
          : acc,
      {}
    )
);

export const replaceSuggestedOperationsWithDatapointSelector = createSelector(
  replaceSuggestedOperationsSelector,
  datapointsSelector,
  (operations, datapoints) => {
    if (isEmpty(operations)) return {};

    return datapoints.reduce<{ [id: number]: SuggestedOperationWithMeta }>(
      (acc, datapoint) => {
        const operation = operations[datapoint.id];

        if (!operation || datapoint.category !== 'datapoint') {
          return acc;
        }

        return {
          ...acc,
          [datapoint.id]: { ...operation, meta: { datapoint } },
        };
      },
      {}
    );
  }
);

export const replaceSuggestedOperationsWithPositionPerPage = createSelector(
  replaceSuggestedOperationsWithDatapointSelector,
  suggestedOperations =>
    Object.values(suggestedOperations).reduce<{
      [pageNumber: string]: SuggestedOperationWithMeta[];
    }>((acc, operation) => {
      if (
        !operation.value?.content?.position ||
        !operation.value?.content?.page
      )
        return acc;

      const { page } = operation.value.content;

      const currentPage = get(acc, page, []);

      return { ...acc, [page]: [...currentPage, operation] };
    }, {})
);

export const suggestedOperationsOptionsSelector = createSelector(
  replaceSuggestedOperationsWithDatapointSelector,
  suggestedOperations => {
    const counts = Object.values(suggestedOperations)
      .filter(op => op.source === 'extension')
      .reduce<Record<string, number>>((acc, operation) => {
        const {
          meta: {
            datapoint: { schemaId },
          },
        } = operation;

        const count = acc[schemaId] || 0;

        return { ...acc, [schemaId]: count + 1 };
      }, {});
    const schemaIds = Object.keys(counts);

    const mostCommonSchemaId = schemaIds.sort(
      (schemaId1, schemaId2) => get(counts, schemaId2) - get(counts, schemaId1)
    )[0];

    return { rowsCount: get(counts, mostCommonSchemaId), schemaIds };
  }
);

export const datapointsWithOperationsSelector = createSelector(
  datapointsSelector,
  suggestedOperationsSelector,
  schemaMapSelector,
  (allDatapoints, suggestedOperations, schemaMap) => {
    const datapoints = allDatapoints
      .filter(isBoundedDatapoint)
      .filter(dp => !schemaMap.get(dp.schemaId)?.hidden)
      .map(dp => ({
        id: dp.id,
        type: 'datapoint' as const,
        position: dp.content.position,
        page: dp.content.page,
      }));

    const operations = Object.values(suggestedOperations)
      .filter(op => op.source === 'table')
      .flatMap(op => {
        return op.op === 'replace' && op.value.content.position
          ? [
              {
                id: op.id,
                type: 'operation' as const,
                position: op.value.content.position,
                page: op.value.content.page,
              },
            ]
          : [];
      });

    // Order is important, because operations are displayed before datapoints.
    // This is because we are using `.find()` in DocumentCanvasSVG
    // TODO: Would be better to use useBoundingBoxes instead of selector.
    return [...operations, ...datapoints];
  }
);

export const hasSuggestionsSelector = createSelector(
  suggestedOperationsSelector,
  suggestedTuplesSelector,
  (suggestedOperations, virtualTuples) => {
    const replaceOperations = Object.values(suggestedOperations).filter(
      op => op.source === 'table' && op.op === 'replace'
    );
    return replaceOperations.length || virtualTuples.length;
  }
);
