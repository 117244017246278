import {
  isAnyButtonFormModel,
  isCapturedFieldFormModel,
  isDataFieldFormModel,
  isDateFieldFormModel,
  isEnumFieldFormModel,
  isFormulaFieldFormModel,
  isLineItemButtonFormModel,
  isLineItemsFormModel,
  isManualFieldFormModel,
  isMultivalueFormModel,
  isNumberFieldFormModel,
  isSimpleOrMultivalueFormModel,
  isStringFieldFormModel,
  isUnsetFieldFormModel,
} from '../formModels.guards';
import { PropertyExtractor } from './toApiModel.utils';

export const extractId: PropertyExtractor<'id'> = fallback => blueprint =>
  blueprint.field.id ?? fallback;

export const extractLabel: PropertyExtractor<'label'> = fallback => blueprint =>
  blueprint.field.label ?? fallback;

export const extractDescription: PropertyExtractor<'description'> =
  fallback => blueprint =>
    blueprint.field.description ?? fallback;

export const extractHidden: PropertyExtractor<'hidden'> =
  fallback => blueprint =>
    blueprint.field.hidden ?? fallback;

export const extractCanExport: PropertyExtractor<'canExport'> =
  fallback => blueprint =>
    blueprint.field.exported ?? fallback;

export const extractType: PropertyExtractor<'type'> = fallback => blueprint =>
  isSimpleOrMultivalueFormModel(blueprint)
    ? blueprint.field.dataType
    : fallback;

export const extractMinLength: PropertyExtractor<'constraints.length.min'> =
  fallback => blueprint =>
    isStringFieldFormModel(blueprint) ? blueprint.field.minLength : fallback;

export const extractMaxLength: PropertyExtractor<'constraints.length.max'> =
  fallback => blueprint =>
    isStringFieldFormModel(blueprint) ? blueprint.field.maxLength : fallback;

export const extractExactLength: PropertyExtractor<
  'constraints.length.exact'
> = fallback => blueprint =>
  isStringFieldFormModel(blueprint) ? blueprint.field.exactLength : fallback;

export const extractRegexpPattern: PropertyExtractor<
  'constraints.regexp.pattern'
> = fallback => blueprint =>
  isStringFieldFormModel(blueprint) ? blueprint.field.regex : fallback;

export const extractRequired: PropertyExtractor<'constraints.required'> =
  fallback => blueprint =>
    blueprint.field.required ?? fallback;

export const extractOptions: PropertyExtractor<'options'> =
  fallback => blueprint =>
    isEnumFieldFormModel(blueprint) ? blueprint.field.options : fallback;

export const extractFormat: PropertyExtractor<'format'> =
  fallback => blueprint =>
    isNumberFieldFormModel(blueprint) || isDateFieldFormModel(blueprint)
      ? blueprint.field.format
      : fallback;

export const extractUiType: PropertyExtractor<'uiConfiguration.type'> =
  fallback => blueprint => {
    if (!isSimpleOrMultivalueFormModel(blueprint)) {
      return fallback;
    }

    if (blueprint.field.valueSource === 'unset') {
      return null;
    }

    if (isMultivalueFormModel(blueprint)) {
      if (blueprint.field.valueSource === 'formula') {
        return 'captured';
      }
    }

    return blueprint.field.valueSource;
  };

export const extractUiEdit: PropertyExtractor<'uiConfiguration.edit'> =
  fallback => blueprint => {
    if (!isSimpleOrMultivalueFormModel(blueprint)) {
      return fallback;
    }

    if (
      isCapturedFieldFormModel(blueprint) ||
      isDataFieldFormModel(blueprint) ||
      isFormulaFieldFormModel(blueprint)
    ) {
      return blueprint.field.editing;
    }

    if (blueprint.field.valueSource === 'unset') {
      return undefined;
    }

    return isManualFieldFormModel(blueprint) ? 'enabled' : fallback;
  };

export const extractRirFieldNames: PropertyExtractor<'rirFieldNames'> =
  fallback => blueprint =>
    isCapturedFieldFormModel(blueprint) ||
    isDataFieldFormModel(blueprint) ||
    isUnsetFieldFormModel(blueprint) ||
    isLineItemsFormModel(blueprint)
      ? blueprint.field.aiEngineFields
      : fallback;

export const extractScoreThreshold: PropertyExtractor<'scoreThreshold'> =
  fallback => blueprint =>
    isUnsetFieldFormModel(blueprint) || isCapturedFieldFormModel(blueprint)
      ? blueprint.field.scoreThreshold
      : fallback;

export const extractFormula: PropertyExtractor<'formula'> =
  fallback => blueprint =>
    isFormulaFieldFormModel(blueprint) ? blueprint.field.formula : fallback;

export const extractPopupUrl: PropertyExtractor<'popupUrl'> =
  fallback => blueprint =>
    isAnyButtonFormModel(blueprint) || isLineItemButtonFormModel(blueprint)
      ? blueprint.field.url
      : fallback;

export const extractCanObtainToken: PropertyExtractor<'canObtainToken'> =
  fallback => blueprint =>
    isAnyButtonFormModel(blueprint) ? blueprint.field.canObtainToken : fallback;
