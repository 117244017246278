import { getIDFromUrl } from '@rossum/api-client';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  mapTo,
  mergeMap,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { EpicDependencies } from '../../../lib/apiTypes';
import { ProcessingDuration, timeSpent } from '../../../timeSpent/timeSpent';
import { RootActionType } from '../../rootActions';
import { lastDatapointsForTimeSpentUpdate } from '../datapoints/actions';
import { isActionOf } from '../utils';

type WithProcessingDuration<T> = [
  T,
  { processingDuration: ProcessingDuration } | undefined,
];

/**
 * Input:  A validation ending action
 * Output: The same action + processing duration for the annotation
 *    (or undefined, if it has been already handled elsewhere).
 *
 * This operator also handles updating of time spent for last selected datapoints.
 */
export const withProcessingDuration =
  <TAction>(
    getAnnotationUrlOrId: (inputAction: TAction) => string | number,
    action$: Observable<RootActionType>,
    authPatch$: EpicDependencies['authPatch$']
  ) =>
  (source: Observable<TAction>): Observable<WithProcessingDuration<TAction>> =>
    source
      .pipe(
        withLatestFrom(
          action$.pipe(
            filter(isActionOf(lastDatapointsForTimeSpentUpdate)),
            // Ensure we are not blocking, if there was no such action received yet
            startWith(
              lastDatapointsForTimeSpentUpdate({
                annotationUrl: '',
                datapoints: [],
              })
            )
          )
        )
      )
      .pipe(
        mergeMap(
          ([
            inputAction,
            {
              payload: { datapoints, annotationUrl },
            },
          ]) => {
            const idOrUrl = getAnnotationUrlOrId(inputAction);

            const id =
              typeof idOrUrl === 'string' ? getIDFromUrl(idOrUrl) : idOrUrl;

            // There can be many actions calling stopAnnotation,
            // the first one to stop the timer, is responsible for
            // updating last datapoints

            const processingDuration = timeSpent.stopAnnotation(id);

            const outputAction = [
              inputAction,
              processingDuration,
            ] as WithProcessingDuration<TAction>;

            if (!processingDuration) {
              return of(outputAction);
            }

            if (datapoints.length === 0) {
              return of(outputAction);
            }

            return forkJoin(
              datapoints.map(d => {
                const timeSpentUpdate = timeSpent.stopAnyDatapoint(d.id);

                // We need to return some value, so that
                // forkJoin will emit a value at the end.
                if (!timeSpentUpdate) return of(undefined);

                return authPatch$(
                  `${annotationUrl}/content/${d.id}?fields!=children`,
                  timeSpentUpdate
                ).pipe(
                  catchError(_err => {
                    // Ignore errors so that we can call validation ending action

                    // TODO there is a bug when we try to patch deleted datapoints
                    // and we are getting 404 here. Until it's fixed in magic grid mission,
                    // it's commented out, to prevent cluttering Rollbar.
                    // report(err);
                    return of(undefined);
                  })
                );
              })
            ).pipe(mapTo(outputAction));
          }
        )
      );
