import { Components, Theme } from '../../material';

export const muiButton = (theme: Theme): Components['MuiButton'] => ({
  variants: [
    {
      props: { variant: 'contained' },
      style: { boxShadow: 'none', ':hover': { boxShadow: 'none' } },
    },
    {
      props: {
        size: 'small',
      },
      style: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.38,
        letterSpacing: 0.46,
        padding: '2px 8px',
      },
    },
    {
      props: {
        size: 'medium',
      },
      style: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.71,
        letterSpacing: 0.4,
        padding: '6px 16px',
      },
    },
    {
      props: {
        size: 'large',
      },
      style: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.73,
        letterSpacing: 0.3,
        padding: '11px 24px',
      },
    },
    {
      props: {
        size: 'small',
        variant: 'outlined',
      },
      style: {
        padding: '1px 8px',
      },
    },
    {
      props: {
        size: 'medium',
        variant: 'outlined',
      },
      style: {
        padding: '5px 16px',
      },
    },
    {
      props: {
        size: 'large',
        variant: 'outlined',
      },
      style: {
        padding: '10px 24px',
      },
    },
  ],
});
