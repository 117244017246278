import { BoxProps, useTheme } from '@rossum/ui/material';
import { Rectangle2D } from '../../document-canvas/utils/geometry';

type RectangleCreatorProps = BoxProps & {
  rectangle: Rectangle2D | undefined;
};

export const CreatedRectangle = ({ rectangle }: RectangleCreatorProps) => {
  const theme = useTheme();

  return rectangle ? (
    <rect
      vectorEffect="non-scaling-stroke"
      data-dragged
      stroke={theme.palette.primary.main}
      strokeDasharray={4}
      fill="transparent"
      strokeWidth={2}
      pointerEvents="none"
      x={rectangle.x}
      y={rectangle.y}
      height={rectangle.height}
      width={rectangle.width}
    />
  ) : null;
};
