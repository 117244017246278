// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointButtonSchema } from './schemaDatapointButton.schema';
import { schemaDatapointDateSchema } from './schemaDatapointDate.schema';
import { schemaDatapointEnumSchema } from './schemaDatapointEnum.schema';
import { schemaDatapointNumberSchema } from './schemaDatapointNumber.schema';
import { schemaDatapointStringSchema } from './schemaDatapointString.schema';

export const schemaDatapointSchema = z.union([
  schemaDatapointStringSchema,
  schemaDatapointDateSchema,
  schemaDatapointNumberSchema,
  schemaDatapointEnumSchema,
  schemaDatapointButtonSchema,
]);
