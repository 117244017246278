import { ID } from '@rossum/api-client';
import { StateCreator } from 'zustand';

type SupportedDrawer = 'duplicates' | 'emails' | 'attachments' | 'activities';

export type DocumentInfoState = {
  currentDrawer: SupportedDrawer | undefined;
  annotationId: ID | undefined;
};

export type DocumentInfoActions = {
  closeDrawer: () => void;
  openDrawer: (params: { drawer: SupportedDrawer; annotationId: ID }) => void;
};

export type DocumentInfoStoreType = DocumentInfoState & DocumentInfoActions;

export const documentInfoStoreSlice: StateCreator<
  DocumentInfoStoreType,
  [],
  [],
  DocumentInfoStoreType
> = set => ({
  currentDrawer: undefined,
  annotationId: undefined,
  closeDrawer: () => {
    set({ currentDrawer: undefined, annotationId: undefined });
  },
  openDrawer: ({ drawer, annotationId }) => {
    set({ currentDrawer: drawer, annotationId });
  },
});
