// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const documentsDownloadTaskResultSchema = z.object({
  id: idSchema,
  user: urlSchema.optional(),
  content: urlSchema,
  expiresAt: z.string(),
  fileName: z.string(),
});
