import { PaginationQuery } from './listQuery';
import { ListResponse } from './listResponse';

// wraps a list function so that it always fetches all available items
// not advisable to use this tho
export const unpaginated = <Args, TResponseItem>(
  listFn: (
    query: Partial<Args & PaginationQuery>
  ) => Promise<ListResponse<TResponseItem>>
) => {
  const recurse = ({
    page = 1,
    results = [],
    query,
  }: {
    page: number;
    results?: TResponseItem[];
    query: Partial<Args & PaginationQuery>;
  }): Promise<TResponseItem[]> => {
    return listFn({ ...query, page }).then(response => {
      const allResults = [...results, ...response.results];

      if (response?.pagination?.next) {
        return recurse({
          page: page + 1,
          query,
          results: allResults,
        });
      }
      return allResults;
    });
  };

  // start by fetching first page
  return (query: Partial<Args & PaginationQuery> = {}) => {
    return recurse({ page: 1, query });
  };
};
