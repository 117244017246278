import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
  MenuRounded,
} from '@rossum/ui/icons';
import { IconButton } from '@rossum/ui/material';
import { useState } from 'react';

type Props = {
  onClick: () => void;
  isSidebarOpen: boolean;
};

export const ToggleSidebarButton = ({ isSidebarOpen, onClick }: Props) => {
  const [hover, setHover] = useState(false);

  const SidebarIcon = isSidebarOpen
    ? KeyboardDoubleArrowLeftRounded
    : KeyboardDoubleArrowRightRounded;

  return (
    <IconButton
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        position: 'relative',
        width: 40,
        height: 40,
      }}
    >
      <MenuRounded
        color="secondary"
        sx={{
          position: 'absolute',
          opacity: hover ? 0 : 1,
          transition: ({ transitions: { create, duration, easing } }) =>
            create(['opacity'], {
              duration: duration.shorter,
              easing: easing.easeInOut,
            }),
        }}
      />

      <SidebarIcon
        color="secondary"
        sx={{
          position: 'absolute',
          opacity: !hover ? 0 : 1,
          transition: ({ transitions: { create, duration, easing } }) =>
            create(['opacity'], {
              duration: duration.shorter,
              easing: easing.easeInOut,
            }),
        }}
      />
    </IconButton>
  );
};
