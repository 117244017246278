import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailTemplate } from '../models/emailTemplate';
import { emailTemplateSchema } from '../models/emailTemplate.schema';
import { emailTemplateListQuerySchema } from './list.schema';

export type EmailTemplateListQuery = {
  pageSize?: number;
  page?: number;
  fields?: string[];
  ordering?: string[];
  queue?: number;
};

export const list = (query: EmailTemplateListQuery = {}) => {
  return {
    endpoint: `/email_templates`,
    method: 'GET',
    responseSchema: listResponse(emailTemplateSchema),
    query,
    querySchema: emailTemplateListQuerySchema,
  } as const;
};
