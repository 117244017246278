import { Popover } from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatapoint } from '../../../../../redux/modules/datapoints/actions';
import { datapointPathSelector } from '../../../../../redux/modules/datapoints/selector';
import { isVirtualDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import { findMagicGridByPage } from '../../../../../redux/modules/magicGrid/selector';
import { MultivalueDatapointDataST } from '../../../../../types/datapoints';
import { State } from '../../../../../types/state';
import {
  useCanvasSelectionActions,
  useDocumentStore,
} from '../../../document-store/DocumentStore';
import { scaleFactorSelector } from '../../../document-store/documentStoreSelectors';
import { calculateGaps } from './calculateGaps';
import { CliButton } from './CliButton';
import { Config, LabelDialogContent } from './LabelDialogContent';
import { ScaleToViewport } from './ScaleToViewport';
import { useHighlightedDatapoints } from './useHighlightedDatapoints';

const LABEL_MAX_SIZE = 24;
const LABEL_MIN_SIZE = 12;

/**
 * There is a bug i wasn't able to figure out - you aren't able
 * to interact with elements that are scaled down too much.
 *
 * This makes sure that the final scale is not less than 0.33.
 * In chrome, the interactivity stops working at scale 0.25.
 */
const MAGIC_MAX_ZOOM = 3;

/**
 * CLI labels aren't scaling with zoom completely.
 * They are scaling with zoom only with (1/3) effectiveness.
 * (2x zoom increases the size of CLI labels by 1/3)
 */
const W = 0.5;
export const getWeightedFactor = (scaleFactor: number) =>
  Math.min((scaleFactor + W) / (1 + W), MAGIC_MAX_ZOOM);

type ComplexLineItemsLabelsProps = {
  page: number;
  multivalue: MultivalueDatapointDataST;
};

type PopoverState = (Config & { anchorEl: HTMLButtonElement }) | undefined;

export const ComplexLineItemsLabels = ({
  page,
  multivalue,
}: ComplexLineItemsLabelsProps) => {
  const [popoverState, setPopoverState] = useState<PopoverState>(undefined);
  const scaleFactor = useDocumentStore(scaleFactorSelector);

  // TODO: SVG-mission: the getGridsSelector already calculates the
  // tuplesOnPage and transposedTuplesOnPage for each page, would be
  // great to:
  // 1. return these already calculated values from the selector
  // I didn't find a way how to do it nicely without changing the
  // return type of the selector (which is Grid -
  //  aka "parts" and it is handy that it is the same type as on API level)
  const gridForCurrentPage = useSelector((state: State) =>
    findMagicGridByPage(state)(page)
  );

  const weightedFactor = getWeightedFactor(scaleFactor);
  const rows = gridForCurrentPage?.rows;
  const scaledRowPositions = useMemo(() => {
    const rowPositions = rows?.map(row => row.topPosition) ?? [];
    return calculateGaps(rowPositions, {
      minSize: LABEL_MIN_SIZE,
      maxSize: LABEL_MAX_SIZE,
      scaleFactor: weightedFactor,
    });
  }, [rows, weightedFactor]);

  const columns = gridForCurrentPage?.columns;
  const scaledColumnPositions = useMemo(() => {
    const columnPositions = columns?.map(column => column.leftPosition) ?? [];
    return calculateGaps(columnPositions, {
      // We are not squishing widths.
      minSize: LABEL_MAX_SIZE,
      maxSize: LABEL_MAX_SIZE,
      scaleFactor: weightedFactor,
    });
  }, [columns, weightedFactor]);

  const rowsLeft = columns?.[0]?.leftPosition ?? 0;
  const columnsTop = rows?.[0]?.topPosition ?? 0;

  const setHoveredCliLabel = useDocumentStore(
    state => state.setHoveredCliLabel
  );

  const { highlightedDatapoints } = useHighlightedDatapoints();

  const { clearSelectedBboxes } = useCanvasSelectionActions();
  const dispatch = useDispatch();
  const datapointPath = useSelector(datapointPathSelector);
  const onLabelClick = (popoverState: PopoverState) => {
    setPopoverState(popoverState);
    clearSelectedBboxes();

    if (datapointPath.length > 2) {
      const datapointPathToSelect = datapointPath.slice(0, 2);
      dispatch(selectDatapoint(datapointPathToSelect, { noTail: true }));
    }
  };

  const getCallbacks = (config: Config) => ({
    onMouseOver: () => setHoveredCliLabel(config),
    onMouseOut: () => setHoveredCliLabel(undefined),
    onClick: (e: { currentTarget: HTMLButtonElement }) =>
      onLabelClick({
        ...config,
        anchorEl: e.currentTarget,
      }),
  });

  return (
    <>
      {rows?.length && columns?.length && (
        <foreignObject
          overflow="visible"
          y={columnsTop}
          x={rowsLeft}
          // The width/height does not matter, it will be overflowing anyway.
          // However, it cannot be 0, otherwise firefox hides the element completely.
          width="1"
          height="1"
        >
          <ScaleToViewport
            tx={-LABEL_MAX_SIZE}
            ty={-1.5 * LABEL_MAX_SIZE}
            factor={weightedFactor}
          >
            <CliButton
              key={page}
              isActive={
                popoverState?.type === 'page' &&
                popoverState.pageNumber === page
              }
              data-cy="cli-page-label"
              {...getCallbacks({
                pageNumber: page,
                type: 'page',
              })}
            >
              {/* TODO: @CLI2-mission: add translations */}
              {`Page ${page}`}
            </CliButton>
          </ScaleToViewport>
        </foreignObject>
      )}
      {rows?.map(({ tupleId, topPosition }, index) => (
        <foreignObject
          overflow="visible"
          key={tupleId}
          x={rowsLeft}
          y={topPosition}
          width="1"
          height="1"
        >
          <ScaleToViewport
            tx={-LABEL_MAX_SIZE}
            ty={scaledRowPositions[index]?.offset ?? 0}
            factor={weightedFactor}
          >
            <CliButton
              // Rows and scaledRowPositions should match but it is not typesafe
              height={Math.floor((scaledRowPositions[index]?.size ?? 0) * 0.95)}
              id={`${tupleId}`}
              data-cy="cli-row-label"
              isActive={
                highlightedDatapoints.some(
                  dp => dp.meta.parentId === tupleId
                ) ||
                (popoverState?.type === 'row' &&
                  popoverState.tupleId === tupleId)
              }
              isVirtual={tupleId ? isVirtualDatapoint(tupleId) : false}
              {...getCallbacks({
                type: 'row',
                // @ts-expect-error How can this be null?
                tupleId,
              })}
            >
              {index + 1}
            </CliButton>
          </ScaleToViewport>
        </foreignObject>
      ))}
      {columns?.map(({ schemaId, headerTexts, leftPosition }, index) => (
        <foreignObject
          overflow="visible"
          key={schemaId}
          y={columnsTop}
          x={leftPosition}
          width="1"
          height="1"
        >
          <ScaleToViewport
            tx={scaledColumnPositions[index]?.offset ?? 0}
            ty={-LABEL_MAX_SIZE}
            factor={weightedFactor}
          >
            <CliButton
              key={schemaId}
              data-cy="cli-column-label"
              isVertical
              isActive={
                highlightedDatapoints.some(dp => dp.schemaId === schemaId) ||
                (popoverState?.type === 'column' &&
                  popoverState.schemaId === schemaId)
              }
              {...getCallbacks({
                type: 'column',
                // @ts-expect-error How can this be null?
                schemaId,
              })}
            >
              {headerTexts[0] ?? ''}
            </CliButton>
          </ScaleToViewport>
        </foreignObject>
      ))}
      {popoverState && (
        <Popover
          open
          sx={
            popoverState.type !== 'row' ? { marginTop: 1 } : { marginLeft: 1 }
          }
          anchorEl={popoverState.anchorEl}
          transformOrigin={
            popoverState.type !== 'row'
              ? {
                  vertical: 'top',
                  horizontal: 'center',
                }
              : {
                  vertical: 'center',
                  horizontal: 'left',
                }
          }
          anchorOrigin={
            popoverState.type !== 'row'
              ? {
                  vertical: 'bottom',
                  horizontal: 'center',
                }
              : {
                  vertical: 'center',
                  horizontal: 'right',
                }
          }
          onClose={() => setPopoverState(undefined)}
        >
          <LabelDialogContent
            config={popoverState}
            multivalue={multivalue}
            onClose={() => setPopoverState(undefined)}
          />
        </Popover>
      )}
    </>
  );
};
