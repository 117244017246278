import { ActionType, createAction } from 'typesafe-actions';
import { Url } from '../../../types/basic';
import { SignInPayload, SignOutMeta } from './types';

export const authSignIn = createAction('AUTH_SIGN_IN', (token: string) => ({
  token,
}))<SignInPayload>();

export const authSignInFulfilled = createAction(
  'AUTH_SIGN_IN_FULFILLED'
)<void>();

export const signOut = createAction(
  'AUTH_SIGN_OUT',
  undefined,
  ({ handled }: { handled?: boolean } = {}) => ({ handled })
)<undefined, SignOutMeta>();

export const logoutUserFulfilled = createAction(
  'LOGOUT_USER_FULFILLED'
)<void>();

export const logoutUser = createAction('LOGOUT_USER')<void>();

export const switchOrganization = createAction(
  'SWITCH_ORGANIZATION',
  (organizationUrl: Url, _organizationName: string) => organizationUrl,
  (_organizationUrl: Url, organizationName: string) => organizationName
)<Url, string>();

export const switchOrganizationError = createAction(
  'SWITCH_ORGANIZATION_ERROR'
)<void>();

export const fetchMembershipToken = createAction(
  'FETCH_MEMBERSHIP_TOKEN',
  (organization: Url, _orgName: string) => ({ organization }),
  (_organization: Url, orgName: string) => orgName
)<{ organization: Url }, string>();

export type FetchMembershipTokenFulfilledPayload = {
  key: string;
  organization: Url;
};

export const fetchMembershipTokenFulfilled = createAction(
  'FETCH_MEMBERSHIP_TOKEN_FULFILLED',
  ({ key, organization }: FetchMembershipTokenFulfilledPayload) => ({
    key,
    organization,
  })
)<FetchMembershipTokenFulfilledPayload>();

export type AuthActions = ActionType<
  | typeof authSignIn
  | typeof authSignInFulfilled
  | typeof signOut
  | typeof logoutUserFulfilled
  | typeof logoutUser
  | typeof switchOrganization
  | typeof switchOrganizationError
  | typeof fetchMembershipToken
  | typeof fetchMembershipTokenFulfilled
>;
