import { Stack, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import CopyToClipboardButton from '../../../ui/copy-to-clipboard/CopyToClipboardButton';
import DocumentFileName from '../../../ui/filename/DocumentFileName';

type FilenameProps = {
  originalFileName: string | null | undefined;
  disabled: boolean;
  status: string;
  estimatedFilenameLength: number;
  disableClipboard?: boolean;
};

const Filename = ({
  originalFileName,
  disabled,
  status,
  estimatedFilenameLength,
  disableClipboard,
}: FilenameProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return originalFileName ? (
    <Stack
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      data-cy="file-name"
      sx={{
        ...(disabled && (status === 'failedImport' || status === 'importing')
          ? {
              color: 'text.disabled',
              cursor: status === 'importing' ? 'wait' : 'not-allowed',
              textDecoration: 'none',
            }
          : {}),
      }}
    >
      <Typography
        component="div"
        style={{
          maxWidth: `${estimatedFilenameLength}px`,
        }}
        sx={{
          fontSize: 'inherit',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {originalFileName}
      </Typography>
      {!disableClipboard && isHovered ? (
        <div>
          <CopyToClipboardButton
            content={originalFileName || ''}
            iconSize="16px"
            iconColor="text.primary"
          />
        </div>
      ) : null}
    </Stack>
  ) : (
    <DocumentFileName originalFileName={originalFileName} />
  );
};

export default Filename;
