import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EditDataSource } from '../models/editPages';
import { ProcessingDuration } from '../models/processingDuration';
import {
  editPagesPayloadSchema,
  editPagesResponseSchema,
} from './editPages.schema';

export type EditPagesPayload = {
  delete: Url[];
  edit: {
    annotation?: Url;
    parentPages: { rotationDeg: number; page: Url }[];
    targetQueue: Url;
    documentName?: string;
  }[];
  move: {
    annotation: Url;
    targetQueue: Url;
  }[];
  stopReviewing: Url[];
  stopParent: boolean;
  processingDuration?: ProcessingDuration;
  editDataSource?: EditDataSource;
};

export type EditPagesResponse = {
  results: { document: Url; annotation: Url }[];
};

export const editPages = (
  parentAnnotationId: number,
  payload: EditPagesPayload
) => {
  return {
    endpoint: `/annotations/${parentAnnotationId}/edit_pages`,
    method: 'POST',
    responseSchema: editPagesResponseSchema,
    payload,
    payloadSchema: editPagesPayloadSchema,
  } as const;
};
