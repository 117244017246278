import { Chip } from '@rossum/ui/material';
import clsx from 'clsx';
import { isNumber } from 'lodash';
import TriangleIcon from 'mdi-react/ChevronUpIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import { ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './style.module.sass';

type Props = {
  activeChildrenLength?: number;
  children: ReactNode;
  childrenLength: number;
  controlElements?: ReactNode;
  defaultExpand?: boolean;
  disabled?: boolean;
  forceExpand?: boolean;
  name: string;
  nameComponent?: (params: {
    name: string;
    className: string;
    badgeComponent: ReactNode;
  }) => ReactNode;
  forceControlElements?: boolean;
  smallArrow?: boolean;
  headerClassName?: string;
};

type NameComponentsProps = {
  name: string;
  className: string;
  badgeComponent: ReactNode;
};

const Collapse = ({
  activeChildrenLength,
  children,
  childrenLength,
  controlElements,
  defaultExpand,
  disabled,
  forceExpand,
  name,
  nameComponent,
  forceControlElements,
  smallArrow,
  headerClassName,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpand);

  useEffect(() => {
    if (!expanded && forceExpand) setExpanded(true);
  }, [expanded, forceExpand]);

  const toggleExpanded = () => setExpanded(!expanded);

  const ArrowIcon = smallArrow ? MenuUpIcon : TriangleIcon;

  return (
    <div className={styles.Wrapper}>
      <div className={clsx(styles.Header, headerClassName)}>
        <div
          className={clsx(
            styles.Label,
            disabled && styles.NoAccess,
            !childrenLength && styles.Empty
          )}
          onClick={childrenLength ? toggleExpanded : undefined}
        >
          <div className={styles.IconWrapper}>
            <ArrowIcon
              size={26}
              className={clsx(
                styles.TriangleIcon,
                expanded ? styles.ExpandedIcon : styles.CollapsedIcon
              )}
            />
          </div>
          {nameComponent &&
            nameComponent({
              className: styles.Name,
              name,
              badgeComponent: (
                <Chip
                  size="tiny"
                  color={
                    activeChildrenLength === null
                      ? childrenLength === 0
                        ? 'default'
                        : 'primary'
                      : activeChildrenLength === 0
                        ? 'default'
                        : 'primary'
                  }
                  label={
                    isNumber(activeChildrenLength)
                      ? `${activeChildrenLength} / ${childrenLength}`
                      : `${childrenLength}`
                  }
                />
              ),
            })}
        </div>
        {!!childrenLength && (
          <div
            className={clsx(
              styles.ActionButtons,
              (expanded || forceControlElements) && styles.ActionButtonsExpanded
            )}
          >
            {controlElements}
          </div>
        )}
      </div>
      <CSSTransition
        in={expanded}
        timeout={400}
        appear
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: styles.Enter,
          enterActive: styles.EnterActive,
          exitActive: styles.ExitActive,
        }}
      >
        {children}
      </CSSTransition>
    </div>
  );
};

Collapse.defaultProps = {
  defaultExpand: false,
  controlElements: null,
  activeChildrenLength: null,
  smallArrow: false,
  disabled: false,
  forceControlElements: false,
  forceExpand: false,
  nameComponent: ({ className, name, badgeComponent }: NameComponentsProps) => (
    <>
      <div className={className}>{name}</div>
      {badgeComponent}
    </>
  ),
};

export default Collapse;
