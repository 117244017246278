import { LinearProgress } from '@rossum/ui/material';
import { useMutation } from '@tanstack/react-query';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { loginIntoWorkflows, workflowsApi } from '../../features/workflows';
import { ApiClientContext } from '../../lib/apiClient';

// This file lets you configure the workflows.
// You can put data from redux/state into workflow pages this way.

const LazyRequestDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows_request_dashboard" */
      './RequestDashboardRoute'
    )
);

const LazyRequestDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "workflows_request_detail" */
      './RequestDetailRoute'
    )
);

export const WorkflowRoutes = () => {
  const { mutate, isSuccess } = useMutation({
    mutationFn: () => loginIntoWorkflows(),
  });

  useEffect(() => mutate(), [mutate]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <ApiClientContext.Provider value={{ api: workflowsApi }}>
        {isSuccess ? (
          <Switch>
            <Route path="/requests/:requestId" component={LazyRequestDetail} />
            <Route path="/requests" component={LazyRequestDashboard} />
          </Switch>
        ) : (
          <LinearProgress />
        )}
      </ApiClientContext.Provider>
    </Suspense>
  );
};
