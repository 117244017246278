import { ID, Url } from '@rossum/api-client';
import { CreateQueueFromTemplatePayload } from '@rossum/api-client/queues';
import { assertNever } from '../../../lib/typeUtils';
import { OutOfTheBoxDocumentType } from './data';

export const regions = ['us', 'eu', 'uk', 'other'] as const;
type Region = (typeof regions)[number];

export type QueueDetailFormModel = {
  documentType: OutOfTheBoxDocumentType['id'];
  queueName: string;
  addingNewWorkspace: boolean;
  workspaceId: ID;
  workspaceUrl: Url;
  newWorkspaceName: string;
  region: Region | '';
  includeDocuments: boolean;
};

const defaultUSTemplate = {
  templateName: 'Tax Invoice US Demo Template',
  firstQueueName: 'Tax invoices (US)',
};

export const getTemplateConfig = (
  documentType: OutOfTheBoxDocumentType['id'],
  region: Region | ''
) => {
  switch (documentType) {
    case 'tax_invoice': {
      switch (region) {
        case 'eu': {
          return {
            templateName: 'Tax Invoice EU Demo Template',
            firstQueueName: 'Tax invoices (EU)',
          };
        }
        case 'us': {
          return {
            templateName: 'Tax Invoice US Demo Template',
            firstQueueName: 'Tax invoices (US)',
          };
        }
        case 'uk': {
          return {
            templateName: 'Tax Invoice UK Demo Template',
            firstQueueName: 'Tax invoices (UK)',
          };
        }
        case 'other': {
          return defaultUSTemplate;
        }
        // TODO: Controversial
        case '': {
          return defaultUSTemplate;
        }
        default: {
          return assertNever(region);
        }
      }
    }
    case 'purchase_order': {
      return {
        templateName: 'Purchase Order Demo Template',
        firstQueueName: 'Purchase orders',
      };
    }
    case 'credit_note': {
      return {
        templateName: 'Credit Note Demo Template',
        firstQueueName: 'Credit notes',
      };
    }

    case 'debit_note': {
      return {
        templateName: 'Debit Note Demo Template',
        firstQueueName: 'Debit notes',
      };
    }

    case 'pro_forma': {
      return {
        templateName: 'Proforma Invoice Demo Template',
        firstQueueName: 'Pro forma invoices',
      };
    }

    case 'delivery_note': {
      return {
        templateName: 'Delivery Note Demo Template',
        firstQueueName: 'Delivery notes',
      };
    }

    case 'custom_document_type': {
      return {
        templateName: 'Custom Document Type Template',
        firstQueueName: 'Custom Document Type',
      };
    }

    // This never gets called
    default: {
      return assertNever(documentType);
    }
  }
};

export const formToApiModel = (
  formModel: QueueDetailFormModel
): CreateQueueFromTemplatePayload => {
  return {
    name: formModel.queueName,
    workspace: formModel.workspaceUrl,
    templateName: getTemplateConfig(formModel.documentType, formModel.region)
      .templateName,
    includeDocuments: formModel.includeDocuments,
  };
};

export const dialogWidthDefault = 745;
