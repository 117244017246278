import { Ace } from 'ace-builds';
import Ajv from 'ajv';
import { truncate, uniq } from 'lodash';
import helpers from './schemaValidationHelpers';

export const JSON_FIELD_ANNOTATION_SOURCE = 'jsonFieldEditor';

export const getSchemaValidationAnnotations = ({
  schema,
  valueAsObject,
  valueAsString,
}: {
  schema?: object;
  valueAsObject: object;
  valueAsString: string;
}) => {
  if (!schema) {
    return [];
  }

  const ajv = new Ajv({
    strict: false, // can be enabled in https://rossumai.atlassian.net/browse/AC-1031
    allErrors: true,
    verbose: true,
    $data: true,
  });

  const validateBySchema = ajv.compile(schema);

  const isValid = validateBySchema(valueAsObject);

  if (isValid) {
    return [];
  }

  const improvedSchemaErrors =
    validateBySchema.errors?.map(error =>
      helpers.improveSchemaError({
        ...error,
        type: 'validation',
      })
    ) ?? [];

  const errorLocations = helpers.getLocationsForPaths(
    valueAsString,
    uniq(improvedSchemaErrors.map(err => err.instancePath))
  );

  return errorLocations
    .map(errorLocation => {
      const validationErrors = improvedSchemaErrors.filter(
        ({ instancePath }) => instancePath === errorLocation.path
      );
      const message = validationErrors
        .map(
          ({ data, message }) => `${truncate(JSON.stringify(data))} ${message}`
        )
        .join('\n');
      if (message) {
        return {
          row: errorLocation.line,
          column: errorLocation.column,
          // I would go with hardcoded English only for now
          // but there is https://github.com/ajv-validator/ajv-i18n
          text: `Schema validation error${
            validationErrors.length !== 1 ? 's' : ''
          }: \n${message}`,
          type: 'error',
          source: JSON_FIELD_ANNOTATION_SOURCE,
        };
      }

      return null;
    })
    .filter(
      (m): m is Required<Ace.Annotation> & { source: string } => m !== null
    );
};
