import { ID } from '../../utils/codecUtils';
import { engineSchema } from '../models/engine.schema';

export const get = (engineId: ID) => {
  return {
    endpoint: `/engines/${engineId}`,
    method: 'GET',
    responseSchema: engineSchema,
  } as const;
};
