import { closestCenter, CollisionDetection, MouseSensor } from '@dnd-kit/core';
import { minBy } from 'lodash';
import { MouseEvent } from 'react';

/**
 * Collision detection algorithm which:
 * 1. first finds the closest row
 * 2. finds the closest item on that row
 *
 * This feels a bit more intuitive for the user, since otherwise
 * a page dragged over an empty part of a row, could be placed
 * on the row above (since other pages from that row are closer).
 *
 * It should also fix an issue, when moving pages between documents.
 * In that case, after a page is moved to a different document,
 * the existing layout will shift, and suddenly a different page
 * is closer to the dragged page. The resulting animation looks bad.
 */
export const closestCenterOnClosestRow: CollisionDetection = args => {
  if (!args.active.rect.current.translated) {
    return closestCenter(args);
  }

  const draggedItemTop = args.active.rect.current.translated.top;

  const closestRowTop = minBy(args.droppableContainers, dc =>
    Math.abs(draggedItemTop - dc.rect.current!.top)
  );

  return closestRowTop
    ? closestCenter({
        ...args,
        droppableContainers: args.droppableContainers.filter(
          dc => dc.rect.current!.top === closestRowTop.rect.current!.top
        ),
      })
    : closestCenter(args);
};

// Ignore mouse down on elements marked with data-no-dnd attribute
// https://github.com/clauderic/dnd-kit/issues/477#issuecomment-985194908
export class MouseSensorIgnoringNoDndElements extends MouseSensor {
  static shouldHandleEvent(element: HTMLElement | null) {
    let cur = element;

    while (cur) {
      if (cur.dataset && cur.dataset.noDnd) {
        return false;
      }
      cur = cur.parentElement;
    }

    return true;
  }

  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return event.target instanceof HTMLElement
          ? this.shouldHandleEvent(event.target)
          : false;
      },
    },
  ];
}
