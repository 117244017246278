import { CheckCircle, CheckCircleOutlined } from '@rossum/ui/icons';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@rossum/ui/material';
import { Queue } from '../../../types/queue';

type Props = {
  onClick: () => void;
  queue: Queue;
  selected: boolean;
};

const QueueComponent = ({ queue, selected, onClick }: Props) => (
  <ListItemButton
    onClick={onClick}
    sx={{ pl: 4, py: 0.5 }}
    data-cy={`queue-menu-item-${queue.name.replaceAll(' ', '-')}`}
  >
    <ListItemIcon sx={{ minWidth: theme => theme.spacing(4.5) }}>
      {selected ? (
        <CheckCircle color="primary" />
      ) : (
        <CheckCircleOutlined color="disabled" />
      )}
    </ListItemIcon>
    <ListItemText
      primary={
        <Typography
          variant="body2"
          title={queue.name}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {queue.name}
        </Typography>
      }
    />
  </ListItemButton>
);

export default QueueComponent;
