import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  copyAnnotationPayloadSchema,
  copyAnnotationResponseSchema,
} from './copy.schema';

export type CopyAnnotationPayload = {
  targetQueue: Url;
  targetStatus: string;
};

export type CopyAnnotationResponse = {
  annotation: Url;
};

export const copy = (annotationId: number, payload: CopyAnnotationPayload) => {
  return {
    endpoint: `/annotations/${annotationId}/copy`,
    method: 'POST',
    payloadSchema: copyAnnotationPayloadSchema,
    payload,
    responseSchema: copyAnnotationResponseSchema,
  } as const;
};
