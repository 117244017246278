import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import * as R from 'remeda';

export const isValidFieldId = (id: string) =>
  !/^\d/.test(id) && !id.includes('-');

export const getSchemaFields = (
  obj: SchemaSection | SchemaItem | Array<SchemaSection> | Array<SchemaItem>
): Array<SchemaSection | SchemaItem> =>
  'children' in obj && obj.children
    ? [[obj], ...getSchemaFields(obj.children)].flat()
    : Array.isArray(obj)
      ? obj.flatMap(getSchemaFields)
      : [obj];

export const getNonTupleSchemaFields = (content: SchemaSection[]) => {
  return R.pipe(
    content,
    getSchemaFields,
    R.flatMap(item => (item.category !== 'tuple' ? [item] : []))
  );
};

export const getValidSchemaFields = (
  schema: SchemaSection | SchemaItem | Array<SchemaSection> | Array<SchemaItem>
) => {
  return getSchemaFields(schema).filter(({ id }) =>
    id ? isValidFieldId(id) : false
  );
};
