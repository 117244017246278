import { colors } from '../../redux/modules/theme/colors';

// TODO: rewrite to the MUI color pallette
export const {
  colorPrimary,
  colorError,
  colorSuccess,
  colorWarning,
  colorErrorDark,
  colorErrorLight,
} = colors;
export const grayDark = '#2c2b33';
export const grayLight = '#38363d';
export const gray = '#757575';
export const graySecondary = '#979797';
export const grayTertiary = '#f6f6f6';

export const tickCount = 5;
