import { endpoints } from '@rossum/api-client';
import { PatchTriggerPayload } from '@rossum/api-client/triggers';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const usePatchTrigger = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: ({
      triggerId,
      triggerPatchModel,
    }: {
      triggerId: number;
      triggerPatchModel: PatchTriggerPayload;
    }) => api.request(endpoints.triggers.patch(triggerId, triggerPatchModel)),
  });
};
