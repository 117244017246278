import { alpha, CircularProgress, Stack } from '@rossum/ui/material';
import { ReactElement } from 'react';

type Props = {
  message: ReactElement;
};
export const IFrameLoading = ({ message }: Props) => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: theme =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.common.black, 0.5)
          : alpha(theme.palette.common.white, 0.5),
    }}
    alignItems="center"
    justifyContent="center"
    spacing={2}
  >
    <CircularProgress />
    {message}
  </Stack>
);
