import { ArrowDropDown, UnfoldLess, UnfoldMore } from '@rossum/ui/icons';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { isEqual, range } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import DatapointMessage from '../../components/Datapoint/components/DatapointMessage';
import { FakeCheckbox } from '../../components/UI/FakeCheckbox';
import { toggleFooter } from '../../redux/modules/ui/actions';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { ID } from '../../types/basic';
import { Grid } from '../../types/datapoints';
import { AnyDatapointSchema } from '../../types/schema';
import { State } from '../../types/state';
import { FooterLayout } from '../../types/user';
import { AggregationStack } from './AggregationStack';
import { FooterHeaderTableCell } from './FooterHeaderTableCell';
import { FooterTableHeader } from './FooterTableHeader';
import { BatchUpdateEvent, BatchUpdateState } from './useBatchUpdate';
import { useFooterVirtualObserver } from './useFooterVirtualObserver';
import { SelectionAction, TupleSelection } from './useTupleActions';
import {
  commonCellStyle,
  commonCheckboxCellStyle,
  composeSx,
  DEFAULT_STICKY_HEADER_Z_INDEX,
  getTableRowIdentifier,
  hasVisibleCollapsibleColumn,
  hasVisibleStretchedColumn,
  isColumnHidden,
  LINE_NUMBER_HEADER_TEXT,
} from './utils';
import { VirtualTupleRowContainer } from './VirtualTupleRowContainer';

const StyledHeaderRow = styled(TableRow)(() => ({
  verticalAlign: 'bottom',
}));

const StyledTableContainer = styled(TableContainer)(() => ({
  backgroundImage: 'none',
  overflowX: 'unset',
  width: '100%',
}));

type TupleIdToGrid = {
  [k: string]: Grid;
};

export type FooterTableProps = {
  part: string;
  layout: FooterLayout;
  tupleIds: ID[];
  tupleIdToGrid: TupleIdToGrid;
  tableIndex: number;
  selection: TupleSelection;
  onSelect: (selection: SelectionAction) => void;
  onDelete: (tupleId: number) => void;
  allColumns: AnyDatapointSchema[];
  orderedIndexes: number[];
  onBatchUpdateEvent: (event: BatchUpdateEvent) => void;
  batchUpdateState: BatchUpdateState;
  setTableBodyRef: (
    el: HTMLElement | null,
    part: FooterTableProps['part']
  ) => void;
  readOnly: boolean;
  showGhostRow: boolean;
  scrolledElement: HTMLElement | undefined;
};

export const FooterTable = React.memo(
  ({
    part,
    layout,
    tupleIdToGrid,
    tupleIds,
    tableIndex,
    selection,
    onSelect,
    onDelete,
    allColumns,
    orderedIndexes,
    onBatchUpdateEvent,
    batchUpdateState,
    setTableBodyRef,
    readOnly,
    showGhostRow,
    scrolledElement,
  }: FooterTableProps) => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const footerExpanded = useSelector(
      (state: State) => state.ui.footerExpanded
    );

    const complexLineItemsEnabled = useSelector(
      complexLineItemsEnabledSelector
    );

    const { visibleItems, observer } = useFooterVirtualObserver(
      scrolledElement,
      getTableRowIdentifier
    );

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    // should be stable for all time per part
    const selectionActions = useMemo(
      () => [
        {
          type: 'select-all',
          label: intl.formatMessage({
            id: 'components.documentValidation.footer.selectionActions.selectAll',
          }),
          onClick: () => {
            onSelect({ type: 'select-all' });
            setMenuAnchor(null);
          },
        },
        {
          type: 'deselect-all',
          label: intl.formatMessage({
            id: 'components.documentValidation.footer.selectionActions.deselectAll',
          }),
          onClick: () => {
            onSelect({ type: 'deselect-all' });
            setMenuAnchor(null);
          },
        },
        {
          type: 'select-from-part',
          label: intl.formatMessage({
            id: 'components.documentValidation.footer.selectionActions.selectBelow',
          }),
          onClick: () => {
            onSelect({ type: 'select-from-part', partId: part });
            setMenuAnchor(null);
          },
        },
      ],
      [intl, onSelect, part]
    );

    const tableCellSx = composeSx(commonCheckboxCellStyle, commonCellStyle, {
      zIndex: DEFAULT_STICKY_HEADER_Z_INDEX + 2,
    });

    const isOneTable = layout === 'one-table';

    const manuallyAdded = useMemo(() => {
      const tupleIdToGridKeys = new Set(Object.keys(tupleIdToGrid));

      return tupleIds.flatMap(id =>
        !tupleIdToGridKeys.has(`${id}`) ? { tupleId: id } : []
      );
    }, [tupleIdToGrid, tupleIds]);

    return (
      <StyledTableContainer data-part={part}>
        <Stack direction="row" justifyContent="space-between">
          <FooterTableHeader showGhostRow={showGhostRow} part={part} />
          {tableIndex === 0 && <AggregationStack />}
        </Stack>
        <Stack direction="row">
          <Table size="small" stickyHeader>
            <TableHead>
              <StyledHeaderRow>
                <TableCell component="th" sx={tableCellSx}>
                  <Stack direction="row">
                    <DatapointMessage renderEmptyPlaceholder>
                      {({ messageIcon }) => <>{messageIcon}</>}
                    </DatapointMessage>
                    <FakeCheckbox
                      size="small"
                      disabled={showGhostRow}
                      indeterminate={
                        complexLineItemsEnabled
                          ? selection.selectionMetaPerPart[part]
                              .atLeastOneSelected &&
                            !selection.selectionMetaPerPart[part].allSelected
                          : selection.atLeastOneSelected &&
                            !selection.allSelected
                      }
                      checked={
                        complexLineItemsEnabled
                          ? selection.selectionMetaPerPart[part].allSelected
                          : selection.allSelected
                      }
                      onChange={() =>
                        complexLineItemsEnabled
                          ? onSelect(
                              selection.selectionMetaPerPart[part]
                                .atLeastOneSelected
                                ? { type: 'deselect-part', partId: part }
                                : { type: 'select-part', partId: part }
                            )
                          : onSelect(
                              selection.atLeastOneSelected
                                ? { type: 'deselect-all' }
                                : { type: 'select-all' }
                            )
                      }
                      style={{
                        visibility: readOnly ? 'hidden' : 'visible',
                        cursor: showGhostRow ? 'not-allowed' : undefined,
                      }}
                    />
                    {complexLineItemsEnabled && layout === 'table-per-page' && (
                      <>
                        <IconButton
                          size="small"
                          onClick={e => setMenuAnchor(e.currentTarget)}
                          disabled={showGhostRow}
                        >
                          <ArrowDropDown fontSize="small" />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchor}
                          open={Boolean(menuAnchor)}
                          onClose={() => setMenuAnchor(null)}
                          MenuListProps={{
                            dense: true,
                          }}
                        >
                          {selectionActions.map(action => (
                            <MenuItem
                              key={action.type}
                              onClick={action.onClick}
                            >
                              <Typography variant="body2">
                                {action.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    )}
                  </Stack>
                </TableCell>
                {complexLineItemsEnabled && (
                  <TableCell
                    component="th"
                    sx={{
                      ...commonCellStyle,
                      pr: 0,
                      textAlign: 'left',
                    }}
                  >
                    {LINE_NUMBER_HEADER_TEXT}
                  </TableCell>
                )}
                {range(0, allColumns.length)
                  .map(i => allColumns[orderedIndexes[i]])
                  .map(column =>
                    isColumnHidden(column, footerExpanded) ? null : (
                      <FooterHeaderTableCell
                        key={column.id}
                        column={column}
                        readOnly={readOnly}
                      />
                    )
                  )}
                {hasVisibleStretchedColumn(allColumns) ? null : (
                  <TableCell
                    component="th"
                    sx={{ width: '100%', ...commonCellStyle }}
                  />
                )}

                <TableCell
                  sx={{
                    minWidth: '30px',
                    maxWidth: '30px',
                    padding: '0 0 6px 6px',
                    ...commonCellStyle,
                  }}
                >
                  {hasVisibleCollapsibleColumn(allColumns) && (
                    <Tooltip
                      title={intl.formatMessage({
                        id: footerExpanded
                          ? 'components.documentValidation.footer.columns.collapse'
                          : 'components.documentValidation.footer.columns.expand',
                      })}
                      enterDelay={600}
                    >
                      <IconButton
                        color="secondary"
                        size="small"
                        sx={{
                          minWidth: 'unset',
                          transform: `rotate(90deg)`,
                          p: 0.25,
                        }}
                        onClick={() => dispatch(toggleFooter())}
                      >
                        {footerExpanded ? (
                          <UnfoldLess fontSize="inherit" />
                        ) : (
                          <UnfoldMore fontSize="inherit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </StyledHeaderRow>
            </TableHead>
            <TableBody ref={el => setTableBodyRef(el, part)}>
              {tupleIds.map((id, i) => {
                const page = showGhostRow ? 1 : tupleIdToGrid[id]?.page ?? `M`;
                const rows = tupleIdToGrid[id]?.rows ?? manuallyAdded;

                const rowIndex = showGhostRow
                  ? 0
                  : rows.findIndex(e => e.tupleId === id);

                const rowIndicator = isOneTable
                  ? `${page}-${rowIndex + 1}`
                  : `${i + 1}`;

                return (
                  <VirtualTupleRowContainer
                    rowIndicator={rowIndicator}
                    isVisible={i === 1 ? true : !!visibleItems[id]}
                    observer={observer}
                    key={id}
                    tupleId={id}
                    allColumns={allColumns}
                    orderedIndexes={orderedIndexes}
                    onSelect={onSelect}
                    onDelete={onDelete}
                    checked={id in selection.tupleIds}
                    onBatchUpdateEvent={onBatchUpdateEvent}
                    batchUpdatedColumnSchemaId={
                      batchUpdateState &&
                      id in batchUpdateState.includedTupleIds
                        ? batchUpdateState.schemaId
                        : null
                    }
                    footerExpanded={footerExpanded}
                    readOnly={readOnly}
                    isGhostRow={showGhostRow}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Stack>
      </StyledTableContainer>
    );
  },
  isEqual
);

FooterTable.displayName = 'FooterTable';
