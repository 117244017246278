import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { Delete, Edit } from '@rossum/ui/icons';
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { boldText, white } from '../../../../lib/formaterValues';
import { isTrialSelector } from '../../../../redux/modules/organization/selectors';
import { useOpenModal } from '../../../../utils/hooks/useOpenModal';
import { useDeleteEmailTemplate } from '../hooks/useDeleteEmailTemplate';
import EmailTemplateChips from './EmailTemplateChips';
import { defaultEmailTemplateNames } from './helpers';

type Props = {
  emailTemplate: EmailTemplate;
  onEdit: (emailTemplate: EmailTemplate) => void;
  numberOfUsages?: number;
};

const CustomEmailTemplateRow = ({
  emailTemplate,
  onEdit,
  numberOfUsages,
}: Props) => {
  const intl = useIntl();
  const [ModalDialog, openModal] = useOpenModal();
  const { mutate: deleteEmailTemplate, isLoading } = useDeleteEmailTemplate();

  const isTrial = useSelector(isTrialSelector);
  const translationId = defaultEmailTemplateNames[emailTemplate.type];

  const templateName =
    emailTemplate.name ||
    (translationId ? intl.formatMessage({ id: translationId }) : '');

  const handleOnDeleteButtonClicked = useCallback(
    (emailTemplate: EmailTemplate) => {
      openModal({
        textId: 'deleteTemplate',
        onConfirm: () => {
          deleteEmailTemplate(emailTemplate.id);
        },
        values: {
          label: templateName,
          white,
          boldText,
        },
      });
    },
    [deleteEmailTemplate, openModal, templateName]
  );

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        data-cy="custom-email-template-row"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">
            {templateName ||
              intl.formatMessage({
                id: 'components.emailTemplateForm.unnamedTemplate',
              })}
          </Typography>

          <EmailTemplateChips
            emailTemplate={emailTemplate}
            numberOfUsages={numberOfUsages}
          />
        </Stack>

        <Stack spacing={2} direction="row" flexShrink="0">
          <Button
            color="secondary"
            sx={{ flexShrink: 0 }}
            disabled={isLoading}
            startIcon={<Edit />}
            onClick={() => onEdit(emailTemplate)}
            data-cy="queue-settings-emails-edit-template-btn"
          >
            {intl.formatMessage({
              id: 'containers.settings.queues.email.editTemplate.button',
            })}
          </Button>
          <Tooltip
            title={
              isTrial
                ? intl.formatMessage({
                    id: 'containers.settings.queues.email.editTemplate.trialBanner.text',
                  })
                : ''
            }
            placement="top"
          >
            <span>
              <IconButton
                color="secondary"
                size="small"
                disabled={isLoading || isTrial}
                onClick={() => handleOnDeleteButtonClicked(emailTemplate)}
              >
                <Delete data-cy="queue-settings-emails-delete-template-btn" />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      {ModalDialog}
    </>
  );
};

export default CustomEmailTemplateRow;
