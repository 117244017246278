import {
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { FUNCTIONS_LINK, WEBHOOKS_LINK } from '../../../../../constants/values';
import { link } from '../../../../../lib/formaterValues';
import { ExtensionType } from '../../../../../types/extensions';
import { extensionTypeConfig } from '../helperConfig';
import styles from '../style.module.sass';

type Props = {
  extensionType: ExtensionType;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
  dataCy?: string;
};

const ExtensionTypeSelect = ({
  extensionType,
  onClick,
  selected,
  disabled,
  dataCy,
}: Props) => {
  return (
    <div
      className={clsx(
        styles.ExtensionType,
        selected && styles.ExtensionTypeSelected,
        disabled && styles.ExtensionTypeDisabled
      )}
      onClick={onClick}
      data-cy={dataCy}
    >
      <div className={styles.ExtensionTypeLabel}>
        {extensionTypeConfig[extensionType].icon}
        <FormattedMessage id={extensionTypeConfig[extensionType].translation} />
      </div>
      <span>
        {extensionType === extensionFunctionType && (
          <FormattedMessage
            id="containers.settings.extensions.createExtension.extensionType.function.description"
            values={{ link: link(FUNCTIONS_LINK) }}
          />
        )}
        {extensionType === extensionWebhookType && (
          <FormattedMessage
            id="containers.settings.extensions.createExtension.extensionType.webhook.description"
            values={{ link: link(WEBHOOKS_LINK) }}
          />
        )}
      </span>
    </div>
  );
};

export default ExtensionTypeSelect;
