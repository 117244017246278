import { User } from '@rossum/api-client/users';
import { Typography } from '@rossum/ui/material';
import { ModifierType } from '../../../types/annotation';
import ModifierMessage, { ModifierMessageProps } from './ModifierMessage';

/**
 * @param modifier
  There could be three types of modifier:
  1. **undefined** - there is no modifier (annotation has not been modified yet)
  2. **{ user: undefined }** - there is a modifier but the current user hasn't got access to the modifier's data
  3. **{ user: User }**
 */
const getModifierInfo = (
  modifier: ModifierType
): { user: User } | ModifierMessageProps =>
  modifier
    ? modifier.user
      ? modifier.user.deleted
        ? { message: 'deletedModifier' }
        : { user: modifier.user }
      : { message: 'unknownModifier' }
    : { message: 'unmodified' };

type ModifierProps = { modifier: ModifierType };

export const Modifier = ({ modifier }: ModifierProps) => {
  const info = getModifierInfo(modifier);

  return 'user' in info ? (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {info.user.username}
    </Typography>
  ) : (
    <ModifierMessage {...info} />
  );
};
