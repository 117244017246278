import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { emailSendPayloadSchema, emailSendResponseSchema } from './send.schema';

export type EmailSendPayloadAddress = {
  email: string;
  name?: string;
};

export type EmailSendPayloadContent = {
  subject: string;
  message: string;
  rejectionNote?: string;
};

export type EmailSendPayload = {
  to: EmailSendPayloadAddress[];
  cc?: EmailSendPayloadAddress[];
  bcc?: EmailSendPayloadAddress[];
  parentEmail?: Url;
  templateValues: EmailSendPayloadContent;
  relatedAnnotations?: Url[];
  relatedDocuments?: Url[];
  attachments?: {
    documents: Url[];
  };
  queue: Url;
  labels?: string[];
  emailTemplate?: Url;
};

export type EmailSendResponse = {
  email: Url;
};

export const send = (payload: EmailSendPayload) => {
  return {
    endpoint: `/emails/send`,
    method: 'POST',
    responseSchema: emailSendResponseSchema,
    payload,
    payloadSchema: emailSendPayloadSchema,
  } as const;
};
