import { Button, CircularProgress } from '@rossum/ui/material';

const ExportingButton = () => (
  <Button
    variant="contained"
    size="large"
    startIcon={<CircularProgress size={26} color="inherit" />}
    disabled
    sx={{ width: '100%' }}
  />
);

export default ExportingButton;
