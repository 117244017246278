import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { Queue } from '@rossum/api-client/queues';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_QUEUES_UNPAGINATED = 'queues-unpaginated';

export const useUnpaginatedQueues = <TReturnedData = Queue[]>(
  queryOptions: Omit<
    UseQueryOptions<
      Queue[],
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_QUEUES_UNPAGINATED]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
    queryFn: () =>
      api.unpaginatedRequest(endpoints.queues.list)({ pageSize: 100 }),
    ...queryOptions,
  });
