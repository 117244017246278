import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { UNPAGINATED_LABELS_QUERY_KEY } from './useRequestLabels';

const MUTATION_KEY_DELETE_LABEL = 'deleteLabel';

export const useDeleteLabel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEY_DELETE_LABEL] as const,
    mutationFn: (id: ID) =>
      api.request(endpoints.labels.delete(id)).then(() => id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UNPAGINATED_LABELS_QUERY_KEY],
      });
    },
  });
};
