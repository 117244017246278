/**
 * @noSchema
 */
export type RequestErrorContext = {
  endpoint: string;
  message: string;
};

export class RequestError extends Error {
  endpoint: string;

  constructor({ endpoint, message }: RequestErrorContext) {
    super(`'${endpoint}': ${message}`);
    this.name = 'RequestError';
    this.endpoint = endpoint;
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}
