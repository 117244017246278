import { endpoints, Url } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const usePredictDedicatedEngineSchema = () => {
  return useMutation({
    mutationFn: ({ trainingQueues }: { trainingQueues: Url[] }) =>
      api.request(endpoints.engineSchemas.predict({ trainingQueues })),
  });
};
