import { useCallback, useEffect } from 'react';
import { isMacOs } from 'react-device-detect';
import { ZOOM_SPEED } from '../../document-store/documentGeometryStoreSlice';
import {
  useCanvasGeometryActions,
  useDocumentStore,
} from '../../document-store/DocumentStore';
import { safeInverse } from '../../document-store/helpers';
import useDeferredKeypress from './useDeferredKeypress';

/** For deltas non-parallel with axes, return the larger coordinate only */
export const directionalizeDelta = (
  dx: number,
  dy: number
): [number, number] => {
  return [
    Math.abs(dx) > Math.abs(dy) ? dx : 0,
    Math.abs(dy) > Math.abs(dx) ? dy : 0,
  ];
};

export const useCanvasGeometry = () => {
  const { zoomBy, translateBy, resetZoom } = useCanvasGeometryActions();

  const viewportRef = useDocumentStore(state => state.viewportRef);

  const getTransformGroupCTM = useDocumentStore(state => state.getCanvasCTM);

  const { zoomEnabled } = useDeferredKeypress({
    keysToDefer: ['Meta', 'Control'],
    canvasRef: viewportRef,
  });

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (zoomEnabled) {
        const inverseMatrix = safeInverse(getTransformGroupCTM());

        const origin = DOMPoint.fromPoint({
          x: e.clientX,
          y: e.clientY,
        }).matrixTransform(inverseMatrix);

        zoomBy(-Math.sign(e.deltaY) * ZOOM_SPEED, origin);
      } else {
        // On Mac, shift+scroll is horizontal scroll by default
        const isWheelInverted = !isMacOs && e.shiftKey;

        const [dx, dy] = directionalizeDelta(
          isWheelInverted ? e.deltaY : e.deltaX,
          isWheelInverted ? e.deltaX : e.deltaY
        );
        translateBy({ x: dx, y: dy });
      }
    },
    [getTransformGroupCTM, translateBy, zoomBy, zoomEnabled]
  );

  useEffect(() => {
    resetZoom();
  }, [resetZoom]);

  useEffect(() => {
    const canvas = viewportRef.current;
    if (canvas) {
      canvas.addEventListener('wheel', handleWheel);
      return () => canvas.removeEventListener('wheel', handleWheel);
    }

    return () => {};
  }, [viewportRef, handleWheel]);
};
