import { MenuItem, Stack, TextField } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { datapointsSelector } from '../../../../../redux/modules/datapoints/selector';
import { isSimpleDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import { isValidColumnForAnnotation } from '../../../../../redux/modules/magicGrid/helpers';
import { getColumnSchemas } from '../../../../../redux/modules/magicGrid/selector';
import {
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
} from '../../../../../types/datapoints';
import { State } from '../../../../../types/state';
import { useValidationDialogCallbacks } from '../../../document-canvas-svg/validation-dialog/callbacks/useValidationDialogCallbacks';
import { ConfirmButton } from '../../../document-canvas-svg/validation-dialog/ui/ConfirmButton';
import { DeleteButton } from '../../../document-canvas-svg/validation-dialog/ui/DeleteButton';
import { ValidationDialogWrapper } from '../../../document-canvas-svg/validation-dialog/ui/ValidationDialogWrapper';
import { CliLabelConfig } from '../../../document-store/documentBboxStoreSlice';
import { InsertLine } from './InsertLine';
import { useLabelDialogCallbacks } from './useLabelDialogCallbacks';

export type Config = CliLabelConfig;

type LabelDialogContentProps = {
  config: Config;
  multivalue: MultivalueDatapointDataST;
  onClose: () => void;
};

const isRelevantDatapoint =
  (config: Config) => (datapoint: SimpleDatapointDataST) => {
    const page =
      config.type === 'page' && config.pageNumber === datapoint.content?.page;
    const column =
      config.type === 'column' && config.schemaId === datapoint.schemaId;
    const row =
      config.type === 'row' && config.tupleId === datapoint.meta.parentId;
    return page || column || row;
  };

export const LabelDialogContent = ({
  config,
  multivalue,
  onClose,
}: LabelDialogContentProps) => {
  const datapoints = useSelector(datapointsSelector);
  const readOnly = useSelector((state: State) => state.ui.readOnly);

  const selectedDatapoints = datapoints
    .filter(isSimpleDatapoint)
    .filter(dp =>
      multivalue.children.some(tuple => tuple.id === dp.meta.parentId)
    )
    .filter(isRelevantDatapoint(config));

  const { handleSchemaIdChange } = useLabelDialogCallbacks();
  const { handleDatapointConfirm, handleDatapointDelete } =
    useValidationDialogCallbacks();

  const columnSchemas = useSelector(getColumnSchemas);
  const schemaIdOptions = columnSchemas
    .filter(isValidColumnForAnnotation)
    .map(({ id, label }) => ({ id, label }));

  const onDelete = () => {
    handleDatapointDelete(selectedDatapoints);
    onClose();
  };

  const onConfirm = () => {
    handleDatapointConfirm(selectedDatapoints);
    onClose();
  };

  return (
    <ValidationDialogWrapper>
      {config.type === 'column' && (
        <TextField
          select
          value={config.schemaId}
          onChange={e => {
            handleSchemaIdChange(multivalue, config.schemaId, e.target.value);
            onClose();
          }}
          // HELENE: Get rid of this after unifying TextFields
          SelectProps={{
            SelectDisplayProps: {
              style: {
                paddingLeft: '12px',
              },
            },
          }}
          size="small"
        >
          {schemaIdOptions.map(schema => (
            <MenuItem
              key={schema.id}
              value={schema.id}
              data-cy="validation-dialog-schema-option"
            >
              {schema.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Stack spacing={1.5} direction="row-reverse">
        <ConfirmButton onClick={onConfirm} disabled={readOnly} />
        <DeleteButton onClick={onDelete} disabled={readOnly} />
        {config.type === 'row' ? (
          <InsertLine
            tupleId={config.tupleId}
            disabled={readOnly}
            onLineInserted={onClose}
          />
        ) : undefined}
      </Stack>
    </ValidationDialogWrapper>
  );
};
