const OTHER_KEY = 'other';

export const onboardingData = {
  surveySteps: {
    'what-is-your-role': {
      key: 'what-is-your-role',
      titleId: 'containers.trialOnboarding.surveyStep.0.title',
      subtitleId: 'containers.trialOnboarding.surveyStep.0.subTitle',
      noteId: 'containers.trialOnboarding.surveyStep.0.note',
      options: [
        {
          key: 'consultant',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.0',
        },
        {
          key: 'manager',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.1',
        },
        {
          key: 'engineer-or-tech-lead',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.2',
        },
        {
          key: 'in-c-level-position',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.3',
        },
        {
          key: 'in-operations-or-transformation-department',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.4',
        },
        {
          key: 'in-finance-or-logistics-department',
          translationId: 'containers.trialOnboarding.surveyStep.0.options.5',
        },
        {
          key: OTHER_KEY,
          translationId: 'containers.trialOnboarding.surveyStep.0.options.6',
        },
      ],
    },
  },
  onboardingSteps: {
    complete: {
      key: 'complete',
      titleId: 'containers.trialOnboarding.onboardingStep.complete.title',
      subtitleId: 'containers.trialOnboarding.onboardingStep.complete.subtitle',
    },
  },
} as const;

// these values are deprected as of the date of this commit, but can still exist in some of our users' ui settings.
export type DeprecatedSurveySteps =
  | 'expected-document-quantity'
  | 'what-you-are-looking-for';

export type SurveyQuestionKeys = keyof typeof onboardingData.surveySteps;

export const { surveySteps } = onboardingData;

export type SurveySteps = Record<
  SurveyQuestionKeys,
  { selectedOptions: Array<string>; extraInfo?: string } | undefined
>;
