import { ElisRequestConfig } from '../../utils/requestConfig';
import { AuthInfo } from '../models/authInfo';
import { authInfoSchema } from '../models/authInfo.schema';
import { loginPayloadSchema } from './login.schema';

export type LoginPayload = {
  username: string;
  password: string;
  scope?: string;
  origin?: string;
};

export const login = (payload: LoginPayload) => {
  return {
    authorize: false,
    endpoint: `/auth/login`,
    method: 'POST',
    responseSchema: authInfoSchema,
    payloadSchema: loginPayloadSchema,
    payload,
  } as const;
};
