import {
  SchemaDatapoint,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import { isSchemaItemChildPath } from './toSchema.utils';

export type Evolver<T> = T extends object
  ? T extends Array<unknown>
    ? never
    : {
        readonly [K in keyof T]?:
          | Evolver<T[K]>
          | ((data: Required<T>[K]) => unknown);
      }
  : never;

export type SchemaPath = Array<string | number>;

export const isLineItemChild =
  (schemaPath: SchemaPath | undefined | null) =>
  (apiModel: SchemaItem | SchemaSection | null): apiModel is SchemaDatapoint =>
    !!schemaPath && isSchemaItemChildPath(schemaPath);
