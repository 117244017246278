import clsx from 'clsx';
import { connect } from 'react-redux';
import { ReactComponent as LogoAnimate } from '../../assets/logoAnimate.svg';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { State } from '../../types/state';
import styles from './style.module.sass';

type StateProps = {
  loader?: string;
};

type OwnProps = {
  absolute?: boolean;
  size?: number;
  className?: string;
};

type Props = StateProps & OwnProps;

const shouldAnimate = (loader: string) => {
  try {
    const base64 = loader.replace('data:image/svg+xml;base64,', '');
    return !window.atob(base64).includes('<animate');
  } catch {
    return false;
  }
};

const Loader = ({ loader, absolute, size, className }: Props) => (
  <div
    id="loader"
    className={clsx(styles.Loader, absolute && styles.absolute, className)}
  >
    {loader ? (
      <img src={loader} alt="loader" width={size} height={size} />
    ) : (
      <LogoAnimate width={size} height={size} />
    )}
    {loader &&
      shouldAnimate(loader) &&
      (['StaticCircle', 'InnerCircle', 'OuterCircle'] as const).map(circle => (
        <div
          key={circle}
          style={{ borderRadius: size, width: size, height: size }}
          className={styles[circle]}
        />
      ))}
  </div>
);

Loader.defaultProps = {
  absolute: false,
  size: 150,
};

const mapStateToProps = (state: State): StateProps => ({
  loader: organizationSelector(state)?.uiSettings?.branding?.loader,
});

export default connect(mapStateToProps)(Loader);
