import { Functions } from '@rossum/ui/icons';
import { Avatar, Chip, Skeleton, Stack, Typography } from '@rossum/ui/material';
import { round } from 'lodash';
import { ForwardedRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { currentMultivalueDatapointSelector } from '../../redux/modules/datapoints/selector';
import { getFooterSchema } from '../../redux/modules/schema/selectors';
import { readOnlySelector } from '../../redux/modules/ui/selectors';
import { AnyDatapointSchema } from '../../types/schema';
import { State } from '../../types/state';
import { highlightOverlay } from './utils';

export const Aggregation = forwardRef(
  (
    {
      content,
      schema,
    }: {
      content: string | undefined;
      schema: AnyDatapointSchema;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const contentAsNumber = Number(content);
    const displayedValue = Number.isNaN(contentAsNumber)
      ? content
      : round(contentAsNumber, 2);

    return (
      <Chip
        ref={ref}
        avatar={
          <Avatar
            sx={{ backgroundColor: theme => theme.palette.background.paper }}
          >
            {/* Users doesn't seem to use any specific aggregation labels, so we just show an icon to indicate sum  */}
            <Functions fontSize="small" />
          </Avatar>
        }
        sx={{
          // This chip is displayed also above the document area,
          // so we can't assume there is a suitable background below.
          backgroundColor: theme => theme.palette.background.paper,
          backgroundImage: theme =>
            `linear-gradient(${highlightOverlay(theme)}, ${highlightOverlay(
              theme
            )})`,
        }}
        label={
          <Typography
            variant="body2"
            color="text.secondary"
            component={Stack}
            direction="row"
            spacing={0.5}
            alignItems="center"
          >
            <Typography variant="body2" data-cy="aggregations-label">
              {schema.label}
            </Typography>
            <Typography
              variant="body2"
              color="text.primary"
              data-cy="aggregations-value"
            >
              {displayedValue ?? <Skeleton width={30} />}
            </Typography>
          </Typography>
        }
      />
    );
  }
);

export const AggregationStack = () => {
  const { columns } = useSelector(getFooterSchema);
  const aggregationMessages = useSelector((state: State) => {
    const multivalue = currentMultivalueDatapointSelector(state);
    if (!multivalue) return [];
    return state.datapoints.aggregations[multivalue.id] ?? [];
  });

  const isReadOnly = useSelector(readOnlySelector);

  const hasColumnWithAggregation = columns.some(c => c.aggregations?.sum);

  if (!hasColumnWithAggregation || isReadOnly) return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ position: 'sticky', right: '24px', py: 1 }}
      data-cy="aggregations-row"
    >
      {columns.map(schema => {
        if (!schema.aggregations?.sum) return null;

        const sum = aggregationMessages.find(
          m => m.aggregationType === 'sum' && m.schemaId === schema.id
        );

        return (
          <Aggregation key={schema.id} content={sum?.content} schema={schema} />
        );
      })}
    </Stack>
  );
};
