import { FontDownloadOutlined, Help } from '@rossum/ui/icons';
import { IconButton, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVELOPER_HUB_LINK } from '../../../constants/values';
import { snakeToSpaces, upperFirst } from '../../../lib/keyConvertor';
import { AutomationBlocker } from '../../../redux/modules/annotation/types';
import HTMLMessage from '../../DatapointMessage/HTMLMessage';

const getArticleLink = (type: string) => {
  switch (type) {
    case 'suggested_edit_present':
    case 'is_duplicate':
      return `${DEVELOPER_HUB_LINK}docs/automation-rules-overview`;

    default:
      return '';
  }
};

export const getTypeName = (type: AutomationBlocker['type']) =>
  upperFirst(snakeToSpaces(type));

type AutomationBlockersTooltipProps = {
  blockers: AutomationBlocker[];
};

const AutomationBlockersTooltip = ({
  blockers,
}: AutomationBlockersTooltipProps) => {
  const documentBlockers = useMemo(
    () =>
      blockers.flatMap(b => {
        if (b.level !== 'annotation') return [];
        // `content` is in extension blocker
        // `messageContent` is in error_message blocker
        const contentProp = b.details?.content ? 'content' : 'messageContent';
        // Create a separate blocker for each content item
        const content = b.details?.[contentProp] ?? [];
        if (!content.length) return [b];
        return content.map(val => ({
          ...b,
          details: {
            ...b.details,
            content: [val],
          },
        }));
      }),
    [blockers]
  );

  const fieldBlockers = useMemo(
    () =>
      uniq(
        blockers.flatMap(b => (b.level === 'datapoint' ? [b.schemaId] : []))
      ),
    [blockers]
  );

  return (
    <Stack spacing={2} p={1}>
      <Stack>
        <Typography variant="body1" fontWeight="bold">
          <FormattedMessage id="components.automationBlockers.title" />
        </Typography>
      </Stack>
      {!!documentBlockers.length && (
        <Stack spacing={1}>
          <Typography variant="body2">
            <FormattedMessage id="components.automationBlockers.section.document" />
          </Typography>
          <Stack>
            {documentBlockers.map(blocker => {
              const content = blocker.details?.content?.[0];

              return (
                <Stack
                  key={blocker.type}
                  direction="row"
                  spacing={1}
                  alignContent="flex-start"
                  alignItems="center"
                >
                  <FontDownloadOutlined
                    fontSize="small"
                    sx={{
                      fill: theme => theme.palette.error.main,
                    }}
                  />
                  <Typography variant="caption">
                    {getTypeName(blocker.type)}
                  </Typography>
                  {content && (
                    <Typography variant="caption" sx={{ overflowX: 'auto' }}>
                      <HTMLMessage content={content} />
                    </Typography>
                  )}
                  {getArticleLink(blocker.type) && (
                    <a
                      href={getArticleLink(blocker.type)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Help fontSize="small" color="info" />
                    </a>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      {!!fieldBlockers.length && (
        <Stack spacing={0.5}>
          <Typography variant="body2">
            <FormattedMessage id="components.automationBlockers.section.fields" />
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            alignContent="flex-start"
            alignItems="center"
          >
            <FontDownloadOutlined
              fontSize="small"
              sx={{
                fill: theme => theme.palette.error.main,
              }}
            />
            <Typography variant="caption">
              <FormattedMessage
                id="components.automationBlockers.section.fields.count"
                values={{ count: fieldBlockers.length }}
              />
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

type AutomationBlockersTopBarProps = {
  blockers: AutomationBlocker[];
  showBlockers: () => void;
  hideBlockers: () => void;
  isBlockersVisible: boolean;
};

const AutomationBlockersTopBar = ({
  blockers,
  showBlockers,
  hideBlockers,
  isBlockersVisible,
}: AutomationBlockersTopBarProps) => {
  const relevantBlockers = useMemo(
    () =>
      blockers.filter(
        b =>
          b.type !== 'automation_disabled' &&
          b.type !== 'human_confirmation_required'
      ),
    [blockers]
  );
  return relevantBlockers.length ? (
    <Tooltip title={<AutomationBlockersTooltip blockers={relevantBlockers} />}>
      <IconButton
        onClick={() => (isBlockersVisible ? hideBlockers() : showBlockers())}
        sx={{ p: 0 }}
      >
        <FontDownloadOutlined
          fontSize="small"
          sx={{ fill: theme => theme.palette.error.main }}
        />
      </IconButton>
    </Tooltip>
  ) : null;
};

export default AutomationBlockersTopBar;
