import { CardMedia } from '@rossum/ui/material';
import { useCallback } from 'react';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SidebarGif from '../../../assets/gifs/tour-1.gif';
import BboxGif from '../../../assets/gifs/tour-2.gif';
import DrawBboxGif from '../../../assets/gifs/tour-3.gif';
import { boldText } from '../../../lib/formaterValues';
import { ProductTourName, ProductTourStep } from '../../../types/productTour';
import { tourResultSelector } from '../user/selectors';
import {
  finishProductTourAction,
  showProductTourStep,
  skipProductTourAction,
} from './actions';
import {
  anyOtherTourRunningSelector,
  tourCurrentStepIndexSelector,
} from './selectors';

type AnnotationProductTourMedia = {
  image: string;
  alt: string;
};

const AnnotationProductTourMedia = ({
  image,
  alt,
}: AnnotationProductTourMedia) => {
  return (
    <CardMedia
      component="img"
      sx={{ borderRadius: 2, mb: 1 }}
      image={image}
      alt={alt}
    />
  );
};

const translationValues = {
  boldText,
};

export const annotationProductTourSteps = (
  intl: IntlShape,
  currentDatapointHasBbox: boolean
): ProductTourStep[] => [
  {
    target: '[data-tourstep="dataCaptureProductTour-sidebar"]',
    title: intl.formatMessage({
      id: `components.tourDialog.dataCaptureProductTour.sidebarStep.title`,
    }),
    content: intl.formatMessage(
      {
        id: `components.tourDialog.dataCaptureProductTour.sidebarStep.content`,
      },
      translationValues
    ),
    placement: 'right-start',
    media: <AnnotationProductTourMedia image={SidebarGif} alt="Sidebar" />,
    showSkipButton: true,
    nextStep: 1,
    disableBeacon: true,
  },
  {
    target: `[data-tourstep='${
      currentDatapointHasBbox
        ? 'dataCaptureProductTour-bbox'
        : 'dataCaptureProductTour-missing-bbox'
    }']`,
    title: intl.formatMessage({
      id: `components.tourDialog.dataCaptureProductTour.documentStep.title`,
    }),
    content: intl.formatMessage(
      {
        id: `components.tourDialog.dataCaptureProductTour.documentStep.content`,
      },
      translationValues
    ),
    media: <AnnotationProductTourMedia image={BboxGif} alt="Bbox" />,
    styles: {
      spotlight: {
        borderRadius: '10px',
      },
    },
    showSkipButton: true,
    nextStep: 2,
    placement: currentDatapointHasBbox ? 'auto' : 'center',
  },
  {
    target: '[data-tourstep="dataCaptureProductTour-document"]',
    title: intl.formatMessage({
      id: `components.tourDialog.dataCaptureProductTour.finalStep.title`,
    }),
    content: intl.formatMessage(
      {
        id: `components.tourDialog.dataCaptureProductTour.finalStep.content`,
      },
      translationValues
    ),
    media: <AnnotationProductTourMedia image={DrawBboxGif} alt="Document" />,
    styles: {
      spotlight: {
        backgroundColor: 'transparent',
      },
    },
    placement: 'center',
    showSkipButton: false,
  },
];

export const useProductTour = (
  tourName: ProductTourName,
  steps: ProductTourStep[]
) => {
  /**
   * Redux connection
   */
  const dispatch = useDispatch();

  const tourResult = useSelector(tourResultSelector(tourName));
  const currentStepIndex = useSelector(tourCurrentStepIndexSelector(tourName));
  const anyOtherTourRunning = useSelector(
    anyOtherTourRunningSelector(tourName)
  );

  const isRunning = currentStepIndex !== null;
  const shouldRun = tourResult === null && !anyOtherTourRunning;

  const setNextStepOfProductTour = useCallback(() => {
    if (isRunning) {
      const currentStep = steps[currentStepIndex];

      if (currentStep?.nextStep) {
        dispatch(
          showProductTourStep({
            tourName,
            stepIndex: currentStep.nextStep,
          })
        );
      }
    }
  }, [isRunning, steps, currentStepIndex, dispatch, tourName]);

  const startProductTour = useCallback(() => {
    if (shouldRun) dispatch(showProductTourStep({ tourName, stepIndex: 0 }));
  }, [dispatch, tourName, shouldRun]);

  const skipProductTour = useCallback(() => {
    if (isRunning)
      dispatch(skipProductTourAction({ tourName, currentStepIndex }));
  }, [isRunning, dispatch, tourName, currentStepIndex]);

  const finishProductTour = useCallback(() => {
    if (isRunning)
      dispatch(finishProductTourAction({ tourName, currentStepIndex }));
  }, [isRunning, dispatch, tourName, currentStepIndex]);

  return {
    isRunning,
    currentStepIndex,
    setNextStepOfProductTour,
    startProductTour,
    skipProductTour,
    finishProductTour,
  };
};
