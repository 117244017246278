import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useReorderingSectionsState = () => {
  const location = useLocation();
  const reorderingSections = location.search.includes('reorder-sections');

  const [exitingState, setExitingState] = useState<
    'initial' | 'exiting' | 'exited'
  >('initial');

  // handles two cases:
  // 1) edge-case when reordering is toggled on when all sections are already collapsed
  //       - onExiting and onExited callbacks are not called in this case
  // 2) resets to `initial` state when reordering is toggled off
  useEffect(() => {
    setExitingState(reorderingSections ? 'exited' : 'initial');
  }, [reorderingSections]);

  return {
    shouldCollapseSections: reorderingSections,
    reorderingVisible: exitingState === 'exited' && reorderingSections,
    setExitingState,
  };
};
