import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { RelatedObjectsCounts } from '../models/relatedObjectsCounts';
import { relatedObjectsCountsSchema } from '../models/relatedObjectsCounts.schema';

export const getRelatedObjectsCounts = (queueId: ID) => {
  return {
    endpoint: `/queues/${queueId}/related_objects_counts`,
    method: 'GET',
    responseSchema: relatedObjectsCountsSchema,
  } as const;
};
