import { Queue } from '@rossum/api-client/queues';
import { MetaField } from '@rossum/api-client/shared';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { GridFilterItem } from '@rossum/ui/x-data-grid-pro';
import { sum } from 'lodash';

export type StatusTabKeys =
  | Extract<
      AnnotationStatus,
      'confirmed' | 'postponed' | 'rejected' | 'deleted' | 'in_workflow'
    >
  | 'reviews'
  | 'exports'
  | 'all_statuses';

type GridFilterTab = Omit<GridFilterItem, 'value'> & {
  value: AnnotationStatus[];
};
export type StatusTab = {
  key: StatusTabKeys;
  filter: GridFilterTab;
  getValidCount: (context: Queue['counts']) => number;
  getInvalidCount: (context: Queue['counts']) => number;
};
export type StatusTabs = Partial<Record<StatusTabKeys, StatusTab>>;
export type StatusTabArray = StatusTab[];

export const statusTabSortOrder: StatusTabKeys[] = [
  'all_statuses',
  'reviews',
  'postponed',
  'in_workflow',
  'rejected',
  'confirmed',
  'exports',
  'deleted',
];

export const createStatusFilter = (values: AnnotationStatus[]) => ({
  field: 'status',
  value: values,
  operator: 'isAnyOf',
});

export const defaultStatusTabs = {
  all_statuses: {
    key: 'all_statuses',
    filter: createStatusFilter([]),
    getValidCount: ({
      reviewing,
      to_review,
      postponed,
      confirmed,
      exported,
      exporting,
      in_workflow,
    }) =>
      // using lodash.sum to handle undefined
      sum([
        reviewing,
        to_review,
        postponed,
        confirmed,
        exported,
        exporting,
        in_workflow,
      ]),
    getInvalidCount: ({ failed_export, failed_import }) =>
      sum([failed_export, failed_import]),
  },
  reviews: {
    key: 'reviews',
    filter: createStatusFilter([
      'to_review',
      'reviewing',
      'importing',
      'failed_import',
    ]),
    getValidCount: ({ to_review, reviewing, importing }) =>
      sum([to_review, reviewing, importing]),
    getInvalidCount: ({ failed_import }) => failed_import ?? 0,
  },
  exports: {
    key: 'exports',
    filter: createStatusFilter(['exported', 'exporting', 'failed_export']),
    getValidCount: () => 0,
    getInvalidCount: ({ failed_export }) => failed_export ?? 0,
  },
  postponed: {
    key: 'postponed',
    filter: createStatusFilter(['postponed']),
    getValidCount: ({ postponed }) => postponed ?? 0,
    getInvalidCount: () => 0,
  },
  deleted: {
    key: 'deleted',
    filter: createStatusFilter(['deleted']),
    getValidCount: () => 0,
    getInvalidCount: () => 0,
  },
} as const satisfies StatusTabs;

export const workflowsTab: StatusTab = {
  key: 'in_workflow',
  filter: createStatusFilter(['in_workflow']),
  getValidCount: ({ in_workflow }) => in_workflow ?? 0,
  getInvalidCount: () => 0,
};

export const confirmedTab: StatusTab = {
  key: 'confirmed',
  filter: createStatusFilter(['confirmed']),
  getValidCount: ({ confirmed }) => confirmed ?? 0,
  getInvalidCount: () => 0,
};

export const rejectedTab: StatusTab = {
  key: 'rejected',
  filter: createStatusFilter(['rejected']),
  getValidCount: ({ rejected }) => rejected ?? 0,
  getInvalidCount: () => 0,
};

export const statusTabsColumnMap = {
  modified_at: ['reviews', 'postponed'],
  modifier: ['reviews', 'postponed'],
  rejected_at: ['rejected'],
  rejected_by: ['rejected'],
  exported_at: ['exports'],
  exported_by: ['exports'],
  confirmed_at: ['confirmed'],
  confirmed_by: ['confirmed'],
  deleted_at: ['deleted'],
  deleted_by: ['deleted'],
  assigned_at: ['in_workflow'],
  assignees: ['in_workflow'],
} as const satisfies Partial<Record<MetaField, ReadonlyArray<StatusTabKeys>>>;
