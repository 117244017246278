import clsx from 'clsx';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import Dropdown, { ItemPropsT } from '../../../../components/UI/Dropdown';
import { visibleWebhookEvents } from '../../lib/helpers';
import EventsBadges from './components/EventsBadges';
import ExpandableEvent from './components/ExpandableEvent';
import styles from './style.module.sass';

type Props<T extends FieldValues> = {
  align?: 'right' | 'left';
  control: Control<T>;
  name: Path<T>;
};

const EventsDropdown = <T extends FieldValues>({
  align,
  control,
  name,
}: Props<T>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <Dropdown
      listClassName={styles.EventsDropdownList}
      align={align || 'left'}
      withIcons={false}
      items={visibleWebhookEvents.map(event => ({ open }: ItemPropsT) => (
        <ExpandableEvent
          key={`expandble-event-${event.name}-${open}`}
          selected={value}
          setSelected={onChange}
          event={event}
        />
      ))}
      onBlur={onBlur}
      keepOpen
    >
      {({ open }: Record<'open', boolean>) => (
        <div
          className={clsx(
            styles.Value,
            open && styles.IsOpen,
            invalid && styles.HasError
          )}
          data-cy="extensions-events-select"
        >
          <EventsBadges
            id="events-dropdown"
            events={value}
            displayPlaceholder
          />
          <ChevronDownIcon className={styles.Chevron} />
        </div>
      )}
    </Dropdown>
  );
};

export default EventsDropdown;
