import { GridApiPro } from '@rossum/ui/x-data-grid-pro';
import { useEffect, useRef } from 'react';

type LazyLoadDatapointsProps = {
  apiRef: React.MutableRefObject<GridApiPro>;
  fetchMoreDatapoints: (indexes: [number, number]) => void;
};

export const LazyLoadDatapoints = ({
  apiRef,
  fetchMoreDatapoints,
}: LazyLoadDatapointsProps) => {
  // Keep info about rendered rows updated.

  const indexes = useRef<[number, number]>([0, 0]);

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'scrollPositionChange',
      ({ renderContext }) => {
        if (!renderContext) {
          return;
        }

        indexes.current = [
          renderContext.firstRowIndex,
          renderContext.lastRowIndex,
        ];
      }
    );
  }, [apiRef]);

  useEffect(() => {
    // Attempt to fetch outdated / missing datapoints every 500 miliseconds.
    const THROTTLE_TIME = 500;
    const intervalId = setInterval(
      () => fetchMoreDatapoints(indexes.current),
      THROTTLE_TIME
    );
    return () => clearInterval(intervalId);
  }, [fetchMoreDatapoints]);

  return null;
};
