import React from 'react';
import { AnyDatapointDataST } from '../../types/datapoints';

// TODO: Do we still need this??
export type ConnectedProps = {
  active: boolean;
  data: AnyDatapointDataST;
};

export interface RefTrackerProps {
  setCurrentDatapointRef?: (ref: HTMLElement) => void;
}

const datapointRefTracker = <P extends object>(
  WrapperComponent: React.ComponentType<P>
): React.ComponentType<P & ConnectedProps & RefTrackerProps> => {
  type Props = P & ConnectedProps & RefTrackerProps;

  return class RefTracker extends React.Component<Props> {
    ref: HTMLElement | null = null;

    componentDidMount() {
      if (this.ref) {
        this.liftActiveReference(this.ref, this.props);
      }
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
      const { data: newData, active: newActive } = newProps;
      const { data, active } = this.props;

      const receivedData = newData && !data;
      const currentDatapointChanged = newData && active !== newActive;

      if (this.ref && (receivedData || currentDatapointChanged)) {
        this.liftActiveReference(this.ref, newProps);
      }
    }

    setRef = (ref: HTMLElement) => {
      this.ref = ref;
      this.liftActiveReference(ref, this.props);
    };

    liftActiveReference = (
      ref?: HTMLElement,
      currentProps: Props = this.props
    ) => {
      const { active } = currentProps;
      const { setCurrentDatapointRef } = this.props;
      if (active && ref) {
        if (setCurrentDatapointRef) setCurrentDatapointRef(ref);
      }
    };

    render() {
      return <WrapperComponent {...this.props} setRef={this.setRef} />;
    }
  };
};

export default datapointRefTracker;
