import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { automationDedicatedEngineDetailPath } from '../../../containers/Automation/helpers';
import { throwError } from '../../../redux/modules/messages/actions';
import { useCreateDedicatedEngineSchema } from './useCreateDedicatedEngineSchema';
import { usePatchDedicatedEngine } from './usePatchDedicatedEngine';

export const useSetupEngineSchema = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { mutate: createEngineSchema, isLoading: createEngineSchemaLoading } =
    useCreateDedicatedEngineSchema();

  const { mutate: patchEngine, isLoading: patchEngineLoading } =
    usePatchDedicatedEngine();

  const handleOnClick = useCallback(
    (id: number, hasSchema: boolean) => {
      // if engine already has a schema, just redirect
      // this needs to be done better
      if (hasSchema) {
        history.push(automationDedicatedEngineDetailPath(id));
      } else {
        // create an engine schema and then patch this engine to attach it. Finally, redirect
        // this should be fixed in the UX - dependent mutations are eh unless absolutely necessary
        createEngineSchema(
          {
            content: {
              trainingQueues: [],
              fields: [],
            },
          },
          {
            onSuccess: schemaResponse => {
              patchEngine(
                {
                  engineId: id,
                  enginePatchModel: { schema: schemaResponse.url },
                },
                {
                  onSuccess: engineResponse => {
                    history.push(
                      automationDedicatedEngineDetailPath(engineResponse.id)
                    );
                  },
                  onError: () =>
                    dispatch(throwError('linkSchemaToEngineError')),
                }
              );
            },
            onError: () => dispatch(throwError('createEngineSchemaError')),
          }
        );
      }
    },
    [createEngineSchema, dispatch, history, patchEngine]
  );

  const isLoading = createEngineSchemaLoading || patchEngineLoading;

  return { handleOnClick, isLoading };
};
