import { SchemaDatapoint } from './schemaDatapoint';
import { SchemaDatapointButton } from './schemaDatapointButton';
import { SchemaDatapointDate } from './schemaDatapointDate';
import { SchemaDatapointEnum } from './schemaDatapointEnum';
import { SchemaDatapointNumber } from './schemaDatapointNumber';
import { SchemaDatapointString } from './schemaDatapointString';
import {
  SchemaSimpleMultivalue,
  SchemaTableMultivalue,
} from './schemaMultivalue';
import { SchemaTuple } from './schemaTuple';

export type SchemaItem =
  | SchemaDatapoint
  | SchemaTuple
  | SchemaSimpleMultivalue
  | SchemaTableMultivalue;

// these names come from business usage and should be settled on
// doladit, mozno postavit bottom up
// These are kinda annoying
export const isSchemaDatapoint = (item: unknown): item is SchemaDatapoint =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  item.category === 'datapoint';

export const isSchemaDatapointString = (
  item: unknown
): item is SchemaDatapointString =>
  isSchemaDatapoint(item) && item.type === 'string';

export const isSchemaDatapointDate = (
  item: unknown
): item is SchemaDatapointDate =>
  isSchemaDatapoint(item) && item.type === 'date';

export const isSchemaDatapointNumber = (
  item: unknown
): item is SchemaDatapointNumber =>
  isSchemaDatapoint(item) && item.type === 'number';

export const isSchemaDatapointEnum = (
  item: unknown
): item is SchemaDatapointEnum =>
  isSchemaDatapoint(item) && item.type === 'enum';

export const isSchemaDatapointButton = (
  item: unknown
): item is SchemaDatapointButton =>
  isSchemaDatapoint(item) && item.type === 'button';

export const isSchemaMultiValue = (
  item: unknown
): item is SchemaSimpleMultivalue | SchemaTableMultivalue =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  item.category === 'multivalue';

export const isSchemaSimpleMultiValue = (
  item: unknown
): item is SchemaSimpleMultivalue =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  'children' in item &&
  typeof item.children === 'object' &&
  item.children !== null &&
  'category' in item.children &&
  item.category === 'multivalue' &&
  item.children.category === 'datapoint';

export const isSchemaTableMultiValue = (
  item: unknown
): item is SchemaTableMultivalue =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  'children' in item &&
  typeof item.children === 'object' &&
  item.children !== null &&
  'category' in item.children &&
  item.category === 'multivalue' &&
  item.children.category === 'tuple';

export const isSchemaTuple = (item: unknown): item is SchemaTuple =>
  typeof item === 'object' &&
  item !== null &&
  'category' in item &&
  'children' in item &&
  typeof item.children === 'object' &&
  item.children !== null &&
  'category' in item.children &&
  item.category === 'tuple';

export const isSchemaItem = (item: unknown): item is SchemaItem =>
  isSchemaDatapoint(item) ||
  isSchemaTuple(item) ||
  isSchemaSimpleMultiValue(item) ||
  isSchemaTableMultiValue(item);
