import { styled } from '@rossum/ui/material';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { scaleFactorSelector } from '../../document-store/documentStoreSelectors';
import {
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
} from '../../document-store/helpers/geometry';

const PageDatapointRect = styled('rect')(({ theme }) => ({
  fillOpacity: 0.25,
  cursor: 'pointer',
  [`&:hover`]: {
    stroke: theme.palette.primary.dark,
    strokeDasharray: 'none',
  },
}));

type PageDatapoint = {
  id: number;
  position: Rectangle2DCoordinates;
  fill: string;
};

type PageDatapointsProps = {
  datapoints: PageDatapoint[];
  onMouseDown: (datapoint: number) => void;
};

export const PageDatapoints = ({
  datapoints,
  onMouseDown,
}: PageDatapointsProps) => {
  const scaleFactor = useDocumentStore(scaleFactorSelector);
  const cornerHandleRadius = 3 / scaleFactor;

  return (
    <g>
      {datapoints.map(datapoint => {
        const rectangle = rectangleFromCoordinates(datapoint.position);
        return (
          <PageDatapointRect
            key={datapoint.id}
            {...rectangle}
            vectorEffect="non-scaling-stroke"
            pointerEvents="all"
            fill={datapoint.fill}
            rx={cornerHandleRadius}
            ry={cornerHandleRadius}
            onMouseDownCapture={() => onMouseDown(datapoint.id)}
          />
        );
      })}
    </g>
  );
};
