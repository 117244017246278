import { endpoints } from '@rossum/api-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { api } from '../../../lib/apiClient';
import { STALE_TIME } from './config';

export const useGetQueues = () => {
  const queuesPaginatedQuery = useInfiniteQuery({
    queryKey: ['queues-paginated'],
    queryFn: ({ pageParam = 1 }) => {
      const endpoint = endpoints.queues.list({
        pageSize: 100,
        page: pageParam,
        fields: ['id', 'name', 'schema', 'url', 'workspace'],
      });

      return api.request(endpoint);
    },

    getNextPageParam: lastPage =>
      lastPage.pagination.next
        ? Number(lastPage.pagination.next.match(/page=(\d*)/)?.[1])
        : null,

    staleTime: STALE_TIME,
  });

  const { isFetching, hasNextPage, fetchNextPage } = queuesPaginatedQuery;

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  // TO-DO: Check this one to use better type
  return queuesPaginatedQuery;
};
