import { Alert, Box, Fade } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

// It is not possible to detect an error inside iframe or iframe being blocked due to CSP policy.
// This error message is rendered with delay and is not visible when iframe is loaded correctly.
const IFrameError = () => {
  const intl = useIntl();
  return (
    <Fade in style={{ transitionDelay: '1000ms' }} timeout={0}>
      <Box sx={{ p: 2, position: 'absolute', left: 0, right: 0, top: 0 }}>
        <Alert severity="error">
          {intl.formatMessage({
            id: 'containers.settings.extensions.configApp.iframeError',
          })}
        </Alert>
      </Box>
    </Fade>
  );
};

export default IFrameError;
