import { Chip } from '@rossum/ui/material';
import clsx from 'clsx';
import { includes, uniq } from 'lodash';
import FolderIcon from 'mdi-react/FolderOutlineIcon';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Triangle from '../../../../../components/UI/TriangleIcon';
import { Url } from '../../../../../types/basic';
import { Queue } from '../../../../../types/queue';
import { WorkspaceWithQueues } from '../../../../../types/workspace';
import styles from '../style.module.sass';
import QueueComponent from './QueueComponent/Queue';

type Props = {
  selected: Array<Url>;
  setSelected: (_selected: Array<Url>) => void;
  shouldExpand: boolean;
  workspace: WorkspaceWithQueues;
  workspaceQueues: Array<Queue>;
};

const ExpandableWorkspace = ({
  selected,
  setSelected,
  shouldExpand,
  workspace,
  workspaceQueues,
}: Props) => {
  const [expanded, setExpanded] = useState(shouldExpand);

  useEffect(() => {
    setExpanded(shouldExpand);
  }, [shouldExpand]);

  const selectedWorkspaceQueues = selected.filter(
    queueUrl => workspaceQueues.find(({ url }) => url === queueUrl)?.workspace
  );

  const shouldDeselect =
    selectedWorkspaceQueues.length === workspaceQueues.length;

  const workspaceUrls = workspaceQueues.map(({ url }) => url);

  const handleAllQueuesSelection = () =>
    setSelected(
      shouldDeselect
        ? selected.filter(queue => !includes(selectedWorkspaceQueues, queue))
        : uniq(selected.concat(workspaceUrls))
    );

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className={styles.Wrapper} onClick={e => e.stopPropagation()}>
      <div
        className={clsx(styles.WorkspaceWrapper, styles.WorkspaceItem)}
        data-cy="expandable-workspace"
        onClick={toggleExpanded}
      >
        <Triangle expanded={expanded} />
        <FolderIcon className={styles.FolderIcon} />
        <div className={styles.WorkspaceName}>
          {workspace.name}
          <div
            className={styles.ItemsSelector}
            onClick={e => e.stopPropagation()}
          >
            <span
              className={styles.ItemsSelectorHandler}
              onClick={handleAllQueuesSelection}
            >
              <FormattedMessage
                id={`containers.settings.extensions.create.queues.${
                  shouldDeselect ? 'deselect' : 'selectAll'
                }`}
              />
            </span>
            <Chip
              size="tiny"
              label={`${selectedWorkspaceQueues.length}/${workspace.queues.length}`}
            />
          </div>
        </div>
      </div>
      {expanded &&
        workspaceQueues.map(queue => {
          const isSelected = includes(selected, queue.url);

          const handleClick = () =>
            setSelected(
              isSelected
                ? selected.filter(queueUrl => queueUrl !== queue.url)
                : [...selected, queue.url]
            );

          return (
            <QueueComponent
              key={`${queue.name}-${queue.id}-queue-${workspace.id}`}
              queue={queue}
              onClick={handleClick}
              selected={shouldDeselect || isSelected}
              className={styles.QueueComponent}
            />
          );
        })}
    </div>
  );
};

export default ExpandableWorkspace;
