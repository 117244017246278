import { SchemaDatapointEnumOption } from '@rossum/api-client/schemas';
import { alpha, darken, Grid, lighten, Typography } from '@rossum/ui/material';
import { useTheme } from '@rossum/ui/material';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';

type EnumPreviewProps = {
  options: SchemaDatapointEnumOption[];
};

export const EnumPreview = ({ options }: EnumPreviewProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const optionsLength = options.length;

  const color =
    theme.palette.mode === 'light'
      ? lighten(alpha(theme.palette.divider, 1), 0.88)
      : darken(alpha(theme.palette.divider, 1), 0.68);

  return (
    <Grid container justifyContent="center" p={3}>
      <Grid item xs={6}>
        <Typography
          p={2}
          fontWeight={700}
          sx={{
            borderBottom: `1px solid ${color}`,
            borderRight: `1px solid ${color}`,
          }}
        >
          {intl.formatMessage({
            id: 'features.fieldManager.fieldDetail.enumFormulaEditing.enumPreview.id',
          })}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography
          p={2}
          fontWeight={700}
          sx={{
            borderBottom: `1px solid ${color}`,
          }}
        >
          {intl.formatMessage({
            id: 'features.fieldManager.fieldDetail.enumFormulaEditing.enumPreview.label',
          })}
        </Typography>
      </Grid>
      {options.map((option, index) => {
        return (
          <Fragment key={`${option.value}${option.label}`}>
            <Grid item xs={6}>
              <Typography
                p={2}
                sx={{
                  borderBottom:
                    index !== optionsLength - 1 ? `1px solid ${color}` : 'none',
                  borderRight: `1px solid ${color}`,
                }}
              >
                {option.value}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                p={2}
                sx={{
                  borderBottom:
                    index !== options.length - 1
                      ? `1px solid ${color}`
                      : 'none',
                }}
              >
                {option.label}
              </Typography>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};
