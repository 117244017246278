import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { materializeVirtualDatapoints } from '../../redux/modules/datapoints/actions';
import {
  currentMultivalueDatapointSelector,
  suggestedTuplesSelector,
} from '../../redux/modules/datapoints/selector';
import {
  hasSuggestionsSelector,
  suggestedOperationsSelector,
} from '../../redux/modules/datapoints/suggestedOperations/selector';
import { State } from '../../types/state';

export const useAcceptSuggestions = () => {
  const dispatch = useDispatch();

  const suggestedOperations = useSelector(suggestedOperationsSelector);
  const replaceOperations = Object.values(suggestedOperations).filter(
    op => op.source === 'table' && op.op === 'replace'
  );

  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );

  const suggestedTuples = useSelector(suggestedTuplesSelector);
  const hasSuggestions = useSelector(hasSuggestionsSelector);
  const loading = useSelector(
    (state: State) => state.datapoints.pendingMaterialization
  );
  const canAcceptSuggestions = hasSuggestions && !loading;

  const acceptSuggestions = useCallback(() => {
    if (!currentMultivalueDatapoint) {
      throw new Error('No multivalue datapoint selected.');
    }

    const payload = suggestedTuples.map(t => ({
      tupleId: t.id,
      validatedDatapointIds: [],
      createWithContent: true,
    }));

    dispatch(
      materializeVirtualDatapoints({
        tuplesToCreate: payload,
        datapointsToReplace: (replaceOperations || []).map(op => ({
          datapointId: op.id,
          isValidated: false,
        })),
        multivalueId: currentMultivalueDatapoint.id,
      })
    );
  }, [
    currentMultivalueDatapoint,
    dispatch,
    replaceOperations,
    suggestedTuples,
  ]);

  return { acceptSuggestions, canAcceptSuggestions, loading };
};
