// MVP version, it is conceivable this could get more complex - but solve that on API client layer
export const payloadToFormData = <TPayload extends Record<string, any>>(
  payload: TPayload
) => {
  const fd = new FormData();

  // this does not cover all possible use cases of a request payload
  // but should do for us long-term
  // however revisit this next time you are refactoring API client

  // we only use object payloads, so we don't cover other cases
  if (typeof payload === 'object' && payload !== null) {
    Object.keys(payload)
      .filter(key => typeof payload[key] !== 'undefined')
      .forEach(key => {
        // if we have a file list, append all files under the same key name one by one
        // as per https://datatracker.ietf.org/doc/html/rfc7578#page-5 formData spec
        if (payload[key] instanceof FileList) {
          Object.keys(payload[key]).forEach(fileKey => {
            fd.append(key, payload[key][fileKey]);
          });
          // if we got object or array, JSON stringify it. FormData automatically calls `.toString()`
          // on whatever `append` receives so in this cases we would end up with '[object Object]'
        } else if (
          Array.isArray(payload[key]) ||
          typeof payload[key] === 'object'
        ) {
          fd.append(key, JSON.stringify(payload[key]));
          // for primitives, do nothing (calls `toString` automatically)
        } else {
          fd.append(key, payload[key]);
        }
      });
  }

  return fd;
};
