import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Email } from '../models/email';
import { emailSchema } from '../models/email.schema';
import { emailListQuerySchema } from './list.schema';

export type EmailListQuery = Partial<{
  id: ID | ID[];
  createdAt: string;
  subject: string;
  queue: number;
  inbox: string;
  documents: string[];
  fromEmail: string;
  fromName: string;
  to: string[];
  annotationsCount: number;
  annotationsCountGte: number;
  annotationsRejectedCountGte: number;
  lastThreadEmailCreatedAtBefore: string;
  lastThreadEmailCreatedAtAfter: string;
  filteredOutDocumentCount: number;
  filteredOutDocumentCountGte: number;
  type: string;
  emailThread: number | number[];
}> &
  PaginationQuery;

export const list = (query: EmailListQuery = {}) => {
  return {
    endpoint: `/emails`,
    method: 'GET',
    responseSchema: listResponse(emailSchema),
    query,
    querySchema: emailListQuerySchema,
  } as const;
};
