import { User } from '@rossum/api-client/users';
import {
  WorkflowActivity,
  WorkflowActivityAction,
} from '@rossum/api-client/workflowActivities';
import { Cancel, CheckCircleOutline, InfoOutlined } from '@rossum/ui/icons';
import {
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ActivityAccordion } from '../../../ui/activity-accordion/ActivityAccordion';
import { CardInfo } from '../../../ui/card-info/CardInfo';
import UserAvatarGroup from '../../../ui/user-avatar/UserAvatarGroup';

const displayedActions = [
  'step_started',
  'step_completed',
  'approved',
  'rejected',
  'workflow_started',
  'workflow_completed',
  'reassigned',
  'pushed_back',
] as const satisfies readonly WorkflowActivityAction[];

type DisplayedAction = (typeof displayedActions)[number];

const activityAvatars: Partial<Record<WorkflowActivityAction, ReactNode>> = {
  approved: <CheckCircleOutline fontSize="small" color="success" />,
  rejected: <Cancel fontSize="small" color="error" />,
};

const activityMessages: Record<
  DisplayedAction,
  (
    workflowStepName: string | undefined,
    workflowName: string | undefined,
    intl: IntlShape
  ) => string
> = {
  // Technically, step can be undefined on API, but should happen only using django admin
  // Not a real use-case.
  approved: (workflowStepName, workflowName, intl) =>
    `${intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.approved',
      },
      { stepName: workflowStepName ?? '' }
    )} (${workflowName})`,
  rejected: (workflowStepName, workflowName, intl) =>
    `${intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.rejected',
      },
      { stepName: workflowStepName ?? '' }
    )} (${workflowName})`,
  reassigned: (workflowStepName, workflowName, intl) =>
    `${intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.reassigned',
      },
      { stepName: workflowStepName ?? '' }
    )} (${workflowName})`,
  pushed_back: (workflowStepName, workflowName, intl) =>
    `${intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.pushedBack',
      },
      { stepName: workflowStepName ?? '' }
    )} (${workflowName})`,
  workflow_started: (_step, workflowName, intl) =>
    intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.workflowStarted',
      },
      { workflowName }
    ),
  workflow_completed: (_step, workflowName, intl) =>
    intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.workflowCompleted',
      },
      { workflowName }
    ),
  step_completed: (workflowStepName, _workflowName, intl) =>
    intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.stepCompleted',
      },
      {
        stepName: workflowStepName ?? '',
      }
    ),
  step_started: (workflowStepName, _workflowName, intl) =>
    intl.formatMessage(
      {
        id: 'components.requestsDetail.activityCard.stepStarted',
      },
      {
        stepName: workflowStepName ?? '',
      }
    ),
};

type ActivityCardProps = {
  activity: WorkflowActivity;
  workflowStepName: string | undefined;
  user: User | undefined;
  assignees: User[] | undefined;
  workflowName: string | undefined;
};

// TODO: This appears on multiple places, we might want a general component.
const getName = (user: User) =>
  user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.email;

export const ActivityCard = ({
  activity,
  user,
  workflowStepName,
  workflowName,
  assignees,
}: ActivityCardProps) => {
  const intl = useIntl();
  const activityAction = displayedActions.find(
    action => action === activity.action
  );

  if (!activityAction) {
    return null;
  }

  if (
    activityAction === 'approved' ||
    activityAction === 'rejected' ||
    activityAction === 'reassigned' ||
    activityAction === 'pushed_back'
  ) {
    return (
      <ActivityAccordion elevation={5} expanded>
        <AccordionSummary sx={{ cursor: 'default !important' }}>
          <CardHeader
            title={activityMessages[activityAction](
              workflowStepName,
              workflowName,
              intl
            )}
            subheader={
              <CardInfo
                date={activity.createdAt}
                fromText={
                  user
                    ? intl.formatMessage(
                        {
                          id: 'components.requestsDetail.activityCard.byAuthor',
                        },
                        {
                          author: getName(user),
                        }
                      )
                    : undefined
                }
                fromTooltip={user?.email}
              />
            }
            avatar={
              activityAvatars[activityAction] ?? (
                <InfoOutlined
                  fontSize="small"
                  sx={{ color: theme => theme.palette.text.primary }}
                />
              )
            }
          />
        </AccordionSummary>
        {activity.note && (
          <AccordionDetails>
            {activityAction === 'reassigned' && assignees ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                ml={5}
                mb={1}
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'components.requestsDetail.activityCard.changedTo',
                  })}
                </Typography>

                <UserAvatarGroup users={assignees} />
              </Stack>
            ) : null}

            <Typography variant="body2" color="text.primary" ml={5}>
              <Typography
                component="span"
                variant="inherit"
                color="text.secondary"
                fontWeight="bold"
              >
                {intl.formatMessage({
                  id: 'components.requestsDetail.activityCard.comment',
                })}
              </Typography>
              : {activity.note}
            </Typography>
          </AccordionDetails>
        )}
      </ActivityAccordion>
    );
  }
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ pl: 2, py: 0.5 }}
      alignItems="center"
    >
      {activityAvatars[activityAction] ?? (
        <InfoOutlined
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2" color="text.secondary">
          {activityMessages[activityAction](
            workflowStepName,
            workflowName,
            intl
          )}
        </Typography>
        <CardInfo
          date={activity.createdAt}
          fromText={undefined}
          fromTooltip=""
        />
      </Stack>
    </Stack>
  );
};
