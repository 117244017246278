import { Button, CircularProgress } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';

const ValidationInProgressButton = () => (
  <Button
    variant="contained"
    data-cy="annotation-detail-validating-btn"
    size="large"
    startIcon={<CircularProgress size={14} color="inherit" />}
    disabled
    sx={{ width: '100%' }}
  >
    <FormattedMessage id="components.documentValidation.sidebar.validating" />
  </Button>
);

export default ValidationInProgressButton;
