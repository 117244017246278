import { styled, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useConfirmPassword } from '../../business/auth/useConfirmPassword';
import InfoScreenHeader from '../../components/UI/InfoScreenHeader';
import { parse } from '../../lib/url';
import ConfirmForm from './components/ConfirmForm';
import PasswordContainer from './components/PasswordContainer';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

const Confirm = () => {
  const intl = useIntl();
  const { search } = useLocation();

  const { mutate, isSuccess, errorKey, error } = useConfirmPassword();

  const usernameFromSearch = parse(search).username;
  const username = Array.isArray(usernameFromSearch)
    ? usernameFromSearch[0]
    : usernameFromSearch;

  const title = isSuccess
    ? 'components.user.resetPassword.submitted.title'
    : errorKey
      ? (`components.user.resetPassword.${errorKey}.title` as const)
      : 'components.user.resetPassword.unsubmitted.title';

  const text = isSuccess
    ? 'components.user.resetPassword.submitted.text'
    : errorKey
      ? (`components.user.resetPassword.${errorKey}.text` as const)
      : 'components.user.resetPassword.unsubmitted.text';

  const displayForm = !(isSuccess || error) && username;

  return (
    <PasswordContainer dataPageTitle="password-reset">
      <InfoScreenHeader
        title={intl.formatMessage({ id: title })}
        subTitle={intl.formatMessage(
          { id: text },
          {
            username,
            white: (msg: ReactNode) => (
              <Typography component="span" variant="body2" color="text.primary">
                {msg}
              </Typography>
            ),
            recovery: (msg: ReactNode) => (
              <StyledLink to="/recovery">{msg}</StyledLink>
            ),
          }
        )}
      />
      {displayForm && (
        <ConfirmForm username={username} confirmPassword={mutate} />
      )}
    </PasswordContainer>
  );
};

export default Confirm;
