import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDatapointsSchemaIds } from '../../../../../redux/modules/datapoints/actions';
import { datapointsSelector } from '../../../../../redux/modules/datapoints/selector';
import { isSimpleDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import { schemaMapSelector } from '../../../../../redux/modules/schema/schemaMapSelector';
import { MultivalueDatapointDataST } from '../../../../../types/datapoints';

export const useLabelDialogCallbacks = () => {
  const dispatch = useDispatch();
  const schemaMap = useSelector(schemaMapSelector);
  const allDatapoints = useSelector(datapointsSelector);

  const handleSchemaIdChange = useCallback(
    (
      multivalue: MultivalueDatapointDataST,
      sourceSchemaId: string,
      targetSchemaId: string
    ) => {
      const tupleIds = new Set(multivalue.children.map(child => child.id));
      const tuples = allDatapoints.filter(dp => tupleIds.has(dp.id));

      // Probably insignificant perf optimisation - pre-filter relevant dps.
      const relevantSimpleDatapoints = allDatapoints
        .filter(isSimpleDatapoint)
        .filter(dp => dp.meta.parentId && tupleIds.has(dp.meta.parentId))
        .filter(dp => [sourceSchemaId, targetSchemaId].includes(dp.schemaId));

      const operations = tuples.flatMap(tuple => {
        const source = relevantSimpleDatapoints.find(
          dp => tuple.id === dp.meta.parentId && dp.schemaId === sourceSchemaId
        );
        const target = relevantSimpleDatapoints.find(
          dp => tuple.id === dp.meta.parentId && dp.schemaId === targetSchemaId
        );
        return source && target ? [{ source, target }] : [];
      });

      if (schemaMap) {
        dispatch(changeDatapointsSchemaIds(operations, schemaMap));
      }
    },
    [allDatapoints, dispatch, schemaMap]
  );

  return { handleSchemaIdChange };
};
