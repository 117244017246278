import { Stack } from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type BackButtonProps = {
  children: ReactNode;
  to?: LocationDescriptor;
  handleOnClick?: (e: MouseEvent) => void;
  dataCy?: string;
};

const BackButton = ({
  children,
  to,
  handleOnClick,
  dataCy,
}: BackButtonProps) => {
  if (handleOnClick) {
    return (
      <Stack
        onClick={handleOnClick}
        data-cy={dataCy}
        sx={{ cursor: 'pointer' }}
      >
        {children}
      </Stack>
    );
  }

  if (to) {
    return (
      <Link to={to} data-cy={dataCy}>
        {children}
      </Link>
    );
  }

  return null;
};

export default BackButton;
