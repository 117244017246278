import { Box, SxProps, Theme } from '@rossum/ui/material';
import { ReactNode } from 'react';
import SplitPane from 'react-split-pane';

// Example styling from https://github.com/tomkp/react-split-pane#example-styling converted to MUI sx props
const defaultSplitPaneStyle: SxProps<Theme> = {
  '& .Resizer': {
    background: '#000',
    opacity: 0.2,
    zIndex: 1,
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
    '&:hover': {
      transition: 'all 2s ease',
    },
    '&.horizontal': {
      height: '11px',
      margin: '-5px 0',
      borderTop: '5px solid rgba(255, 255, 255, 0)',
      borderBottom: '5px solid rgba(255, 255, 255, 0)',
      cursor: 'row-resize',
      width: '100%',
      '&:hover': {
        borderTop: '5px solid rgba(0, 0, 0, 0.5)',
        borderBottom: '5px solid rgba(0, 0, 0, 0.5)',
      },
    },
    '&.vertical': {
      width: '13px',
      margin: '0 -5px',
      borderLeft: '5px solid rgba(255, 255, 255, 0)',
      borderRight: '5px solid rgba(255, 255, 255, 0)',
      cursor: 'col-resize',
      '&:hover': {
        transition: 'none',
        backgroundColor: theme => theme.palette.primary.main,
        opacity: 1,
      },
    },
    '&.disabled': {
      cursor: 'not-allowed',
      '&:hover': {
        borderColor: 'transparent',
      },
    },
  },
};

// The default width should adapt to the screen size, with an upper limit.
const calculateDefaultOriginalDocumentWidth = () => {
  const PADDING_X = 32;
  const MAX_WIDTH = 1040;
  const RATIO = 2 / 3;

  return Math.min(MAX_WIDTH, (window.innerWidth - PADDING_X * 2) * RATIO);
};

export const EditDocumentSplitPane = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Box
    sx={{
      flex: '1 0',
      position: 'relative',
      ...defaultSplitPaneStyle,
    }}
  >
    <SplitPane
      allowResize
      split="vertical"
      /* All sizes apply to primary pane (left side)
            - minSize should make original pages equal size as pages on the right side
            - negative maxSize sets the minimal size for the right side (two pages in a row) */
      minSize={190}
      defaultSize={calculateDefaultOriginalDocumentWidth()}
      maxSize={-400}
    >
      {children}
    </SplitPane>
  </Box>
);
