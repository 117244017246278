import { Dialog, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ActionDialog from '../UI/ActionDialog';
import ActionDialogActions from '../UI/ActionDialog/ActionDialogActions';

export type AnnotationsActionModalProps = {
  action:
    | 'annotationSkip'
    | 'annotationDelete'
    | 'reject'
    | 'reExtract'
    | 'reprocessToConfirmed'
    | 'reprocessToToReview'
    | undefined;
  annotationsLength: number;
  onClose: () => void;
  onPrimaryActionClick: () => void;
  onSecondaryActionClick?: () => void;
  secondaryButtonText?: string;
  dataCyCancel?: string;
  dataCyConfirm?: string;
  dataCySecondary?: string;
  /** use in cases when onClosed is handled by parent component or async callback  */
  preventOnClose?: boolean;
};

const AnnotationsActionModal = ({
  action,
  annotationsLength,
  onClose,
  onPrimaryActionClick,
  onSecondaryActionClick,
  secondaryButtonText,
  dataCyCancel,
  dataCyConfirm,
  dataCySecondary,
  preventOnClose,
}: AnnotationsActionModalProps) => {
  const intl = useIntl();

  return (
    <Dialog
      open={annotationsLength > 0}
      PaperProps={{
        sx: { width: 480, minHeight: 220, position: 'fixed' },
        elevation: 2,
      }}
      onClose={onClose}
      sx={{ transition: 'smooth' }}
    >
      {action && (
        <ActionDialog
          dialogTitle={intl.formatMessage({
            id: `components.modal.${action}.title`,
          })}
          onCancel={onClose}
          dialogColor="primary"
          dialogActions={
            <ActionDialogActions
              confirmButtonProps={{
                onClick: () => {
                  if (!preventOnClose) {
                    onClose();
                  }
                  onPrimaryActionClick();
                },
                color: 'primary',
              }}
              secondaryButtonProps={
                onSecondaryActionClick
                  ? {
                      onClick: () => {
                        if (!preventOnClose) {
                          onClose();
                        }
                        onSecondaryActionClick();
                      },
                    }
                  : undefined
              }
              confirmButtonText={intl.formatMessage({
                id: `components.modal.${action}.confirm`,
              })}
              secondaryButtonText={secondaryButtonText}
              cancelButtonText={intl.formatMessage({
                id: `components.modal.${action}.cancel`,
              })}
              dataCyConfirm={dataCyConfirm}
              dataCyCancel={dataCyCancel}
              dataCySecondary={dataCySecondary}
            />
          }
          dataCy="close-dialog-btn"
        >
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: `components.modal.${action}.text`,
              },
              { count: annotationsLength }
            )}
          </Typography>
        </ActionDialog>
      )}
    </Dialog>
  );
};

export default AnnotationsActionModal;
