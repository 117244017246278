// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { workflowUrlSchema } from '../../workflows/models/workflow.schema';
import { workflowStepUrlSchema } from '../../workflowSteps/models/workflowStep.schema';

export const workflowActivityUrlSchema = urlSchema;

export const workflowActivityActionSchema = z.union([
  z.literal('step_started'),
  z.literal('step_completed'),
  z.literal('approved'),
  z.literal('rejected'),
  z.literal('workflow_started'),
  z.literal('reassigned'),
  z.literal('workflow_completed'),
  z.literal('pushed_back'),
]);

export const workflowActivitySchema = z.object({
  id: idSchema,
  url: workflowActivityUrlSchema,
  organization: urlSchema,
  annotation: urlSchema,
  createdAt: z.string(),
  createdBy: urlSchema.nullable(),
  workflowRun: urlSchema.nullable(),
  workflowStep: workflowStepUrlSchema.nullable(),
  workflow: workflowUrlSchema.nullable(),
  action: z.union([workflowActivityActionSchema, z.string()]),
  note: z.string().nullable(),
  assignees: z.array(urlSchema).nullable(),
});
