import { TransitionProps } from '@rossum/ui/material';
import { useRef, useState } from 'react';

type F = (...args: unknown[]) => void;

export const useMountTransition = (): readonly [
  TransitionProps,
  (onExited: F) => void,
] => {
  const [isIn, setIsIn] = useState(true);
  const handleOnExited = useRef<F | undefined>(undefined);

  return [
    { in: isIn, onExited: handleOnExited.current },
    (onExited: F) => {
      handleOnExited.current = onExited;
      setIsIn(false);
    },
  ] as const;
};
