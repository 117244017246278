import { Document } from '@rossum/api-client/documents';
import { List } from '@rossum/ui/material';
import { orderFlatTree } from '../getAttachmentRows';
import { CollapsibleList } from './CollapsibleList';
import { EmailAttachmentRow } from './EmailAttachmentRow';

type Props = {
  documents: Document[] | undefined;
};

const DISPLAYED_ATTACHMENTS_COUNT = 3;

export const EmailDocumentsPreview = ({ documents }: Props) => {
  const documentRows = documents
    ? orderFlatTree(
        documents,
        document => document.url,
        document => document.parent
      )
    : undefined;

  return (
    <CollapsibleList
      items={documentRows}
      displayCount={DISPLAYED_ATTACHMENTS_COUNT}
    >
      {items => (
        <List dense>
          {items.map(documentRow => (
            <EmailAttachmentRow
              document={documentRow}
              key={documentRow.item.url}
            />
          ))}
        </List>
      )}
    </CollapsibleList>
  );
};
