import { compact, find } from 'lodash';
import { createSelector } from 'reselect';
import { AnyDatapointSchema } from '../../../types/schema';
import {
  currentMultivalueDatapointSelector,
  getGridsSelector,
  isFooterOpenSelector,
  schemaSelector,
} from '../datapoints/selector';

export const findCurrentGrids = createSelector(
  currentMultivalueDatapointSelector,
  getGridsSelector,
  (multivalue, getGrids) => {
    return multivalue ? getGrids(multivalue) : [];
  }
);

export const findMagicGridByPage = createSelector(
  findCurrentGrids,
  grids => (page: number) => find(grids, { page })
);

export const getColumnSchemas = createSelector(
  currentMultivalueDatapointSelector,
  schemaSelector,
  isFooterOpenSelector,
  (currentMultivalue, schema, isFooterOpen): AnyDatapointSchema[] => {
    if (!isFooterOpen || !currentMultivalue || !currentMultivalue.schema)
      return [];

    // For Pavel: I don't think this can happen but this is to avoid the stupid types atm :D
    const tupleSchemaId = currentMultivalue.schema.children?.[0];

    if (!tupleSchemaId) {
      return [];
    }

    const { children = [] } = find(schema, { id: tupleSchemaId }) ?? {
      children: [],
    };

    return compact(children.map(id => find(schema, { id }))).filter(
      ({ hidden }) => !hidden
    );
  }
);

const defaultGridSchema = {
  rowTypes: ['data'],
  defaultRowType: 'data',
  rowTypesToExtract: ['data'],
};

export const getGridSchema = createSelector(
  currentMultivalueDatapointSelector,
  currentMultivalue => ({
    ...defaultGridSchema,
    ...currentMultivalue?.schema?.grid,
  })
);
