import { MoreVert } from '@rossum/ui/icons';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
} from '@rossum/ui/material';
import { ReactElement, useState } from 'react';

export type MenuAction = {
  id: string;
  label: string;
  icon: ReactElement;
  buttonProps: MenuItemProps;
  disabled?: boolean;
  dataCy?: string;
};

type OtherActionsProps = {
  actions: MenuAction[];
  disabled: boolean;
};

const OtherActions = ({ actions, disabled }: OtherActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <IconButton
        disabled={disabled}
        color="secondary"
        size="small"
        aria-controls="other-actions-menu"
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={e => setAnchorEl(e.currentTarget)}
        data-cy="annotation-detail-footer-more-actions"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>
      {/* TODO: These styles are also hacky, raise with UX */}
      <Menu
        id="other-actions-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {actions.map(action => (
          <MenuItem
            dense
            data-cy={action.dataCy}
            key={action.id}
            disabled={action.disabled}
            {...action.buttonProps}
            onClick={e => {
              setAnchorEl(null);
              if (action.buttonProps.onClick) action.buttonProps.onClick(e);
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: 20,
                '& svg': { fontSize: 'inherit !important' },
              }}
            >
              {action.icon}
            </ListItemIcon>
            <ListItemText>{action.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OtherActions;
