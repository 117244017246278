import { endpoints, ID } from '@rossum/api-client';
import { QueueDuplicatePayload } from '@rossum/api-client/queues';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { addQueueToWorkspaceMutation } from '../workspaces/addQueueToWorkspaceMutation';
import { addQueueMutation } from './addQueueMutation';
import { QUERY_KEY_LAZY_QUEUES } from './useLazyQueues';

type UseDuplicateQueueProps = {
  onSuccess: (queueId: number) => void;
};

export const useDuplicateQueue = ({ onSuccess }: UseDuplicateQueueProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (queue: { queueId: ID; payload: QueueDuplicatePayload }) =>
      api.request(endpoints.queues.duplicate(queue.queueId, queue.payload)),

    onSuccess: queue => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_QUEUES],
      });
      // update queues for specific workspace

      // update queues for specific workspace
      addQueueToWorkspaceMutation(queryClient, queue);
      // update queue list
      addQueueMutation(queryClient, queue);

      dispatch(throwInfo('queueDuplicated', undefined, { queue: queue.name }));
      onSuccess(queue.id);
    },

    onError: () => {
      dispatch(throwError('queueDuplicatedError'));
    },
  });
};
