// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';
import { relationTypeSchema } from '../models/relations.schema';

export const relationListQuerySchema = z
  .object({
    parent: z.array(idSchema),
    annotation: z.array(idSchema),
    type: relationTypeSchema,
  })
  .partial()
  .and(paginationQuerySchema);
