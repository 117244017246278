import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'redux-first-history';
import { constructDocumentUrl, getCurrentAnnotationId } from '../../lib/url';

export const useLeaveEditMode = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  return () => {
    const fromParam = new URLSearchParams(location.search).get('from');
    const fromAnnotationId =
      fromParam !== null ? parseInt(fromParam, 10) : null;

    const currentAnnotationId = getCurrentAnnotationId(location.pathname);

    // If we are coming to a parent annotation from a child annotation, redirect there
    if (fromAnnotationId) {
      dispatch(
        replace(
          constructDocumentUrl({
            id: fromAnnotationId,
          })
        )
      );
      return;
    }

    // Otherwise stay at the current annotation
    dispatch(
      replace(
        constructDocumentUrl({
          id: currentAnnotationId,
        })
      )
    );
  };
};
