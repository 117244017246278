import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DeleteRecommendation } from '../models';
import { deleteRecommendationSchema } from '../models/deleteRecommendation.schema';

export const get = (deleteRecommendationId: ID) => {
  return {
    endpoint: `/delete_recommendations/${deleteRecommendationId}`,
    method: 'GET',
    responseSchema: deleteRecommendationSchema,
  } as const;
};
