import { LoginPayload } from '@rossum/api-client/authentication';
import { Visibility, VisibilityOff } from '@rossum/ui/icons';
import {
  Button,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { isArray } from 'lodash';
import { parse } from 'query-string';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { termsAndConditionsURL } from '../../../constants/values';
import { validateLoginPayload } from '../../../redux/modules/auth/helpers';
import { throwError } from '../../../redux/modules/messages/actions';

type FormProps = {
  login: (credentials: LoginPayload) => void;
};

const Form = ({ login }: FormProps) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const { username: usernameFromUrl } = parse(location.search);
  const username = isArray(usernameFromUrl)
    ? usernameFromUrl[0]
    : usernameFromUrl;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: username || '',
      password: '',
    },
  });

  const onSubmit = handleSubmit(credentials => {
    const { body, _reason } = validateLoginPayload(credentials);

    return _reason ? dispatch(throwError(_reason)) : login(body);
  });

  return (
    <Stack sx={{ width: '100%' }}>
      <form onSubmit={event => onSubmit(event)}>
        <Stack spacing={3} alignItems="stretch">
          <Stack spacing={1}>
            <Stack spacing={2}>
              <TextFieldControl
                ControllerProps={{ control, name: 'username' }}
                FieldLabelProps={{ layout: 'floating' }}
                label={intl.formatMessage({
                  id: 'components.user.login.email',
                })}
                placeholder={intl.formatMessage({
                  id: 'components.user.login.email',
                })}
                inputProps={{
                  'data-cy': 'username-input',
                }}
              />
              <TextFieldControl
                ControllerProps={{ control, name: 'password' }}
                FieldLabelProps={{ layout: 'floating' }}
                label={intl.formatMessage({
                  id: 'components.user.login.password',
                })}
                placeholder={intl.formatMessage({
                  id: 'components.user.login.password',
                })}
                autoFocus={!!username}
                InputProps={{
                  type: showPassword ? 'text' : 'password',
                  inputProps: {
                    'data-cy': 'password-input',
                    sx: { paddingRight: 5 },
                  },
                  sx: { paddingRight: 0 },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ position: 'absolute', right: 18 }}
                    >
                      <IconButton
                        onClick={() => setShowPassword(visible => !visible)}
                        edge="end"
                        data-cy="eye-icon"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          fontSize: 18,
                          svg: {
                            color: 'text.secondary',
                            fontSize: 'inherit',
                            '&:hover': {
                              color: 'text.primary',
                              transition: 'smooth',
                            },
                          },
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Typography
              color="text.disabled"
              variant="caption"
              textAlign="center"
            >
              {intl.formatMessage({ id: 'containers.login.terms.text' })}
              &nbsp;
              <MuiLink
                color="inherit"
                href={termsAndConditionsURL}
                data-cy="terms-link"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  '&:hover': {
                    color: 'text.secondary',
                    textDecoration: 'none',
                  },
                }}
              >
                {intl.formatMessage({ id: 'containers.login.terms.link' })}
              </MuiLink>
            </Typography>
          </Stack>
          <Button variant="contained" type="submit" data-cy="login-button">
            {intl.formatMessage({ id: 'containers.login.signin' })}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default Form;
