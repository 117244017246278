import { useIntl } from 'react-intl';
import NoItemsFound from '../../../../components/UI/NoItemsFound';

export const EmptyQueueList = () => {
  const intl = useIntl();
  return (
    <NoItemsFound
      title={intl.formatMessage({
        id: 'components.selectQueueModal.noqueues.title',
      })}
      subtitle={intl.formatMessage({
        id: 'components.selectQueueModal.noqueues.text',
      })}
    />
  );
};
