// Generated by ts-to-zod
import { z } from 'zod';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';

export const formulasInfoPayloadSchema = z.object({
  schemaContent: z.array(schemaSectionSchema),
});

export const formulasInfoResponseSchema = z.object({
  results: z.array(
    z.object({
      formula: z.string(),
      dependencies: z.array(z.string()),
      id: z.string(),
    })
  ),
});
