import { get } from './get';
import { list } from './list';
import { send } from './send';
export { type EmailSendPayload } from './send';

export const email = {
  list,
  send,
  get,
};
