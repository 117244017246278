import { Url } from '@rossum/api-client';
import { CircularProgress, Stack } from '@rossum/ui/material';
import { RequestDetailBodyActivity } from '../request-detail-activity/RequestDetailBodyActivity';
import { useDrawerAnnotation } from './hooks/useDrawerAnnotation';

type WorkflowActivityDrawerContentProps = {
  annotationUrl: Url;
};

export const WorkflowActivityDrawerContent = ({
  annotationUrl,
}: WorkflowActivityDrawerContentProps) => {
  const { data: annotation, isLoading } = useDrawerAnnotation({
    annotationUrl,
  });

  return isLoading ? (
    <Stack p={2} pt={4} width="100%">
      <CircularProgress sx={{ alignSelf: 'center' }} />
    </Stack>
  ) : annotation ? (
    <Stack p={2}>
      <RequestDetailBodyActivity annotation={annotation} />
    </Stack>
  ) : null;
};
