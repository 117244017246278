import { endpoints } from '@rossum/api-client';
import { SchemaFieldListResponse } from '@rossum/api-client/schemaFields';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const SCHEMA_FIELDS_QUERY_KEY = 'organization-schema-fields-schema-id';

export const useFetchSchemaFields = ({
  schemaIds,
  queueIds,
}: {
  schemaIds: string[];
  queueIds: number[];
}) => {
  return useQuery({
    // do NOT change schemaIds part of the key
    queryKey:
      // isInitialLoading in useColumns hook counts on it being an array of ids
      [SCHEMA_FIELDS_QUERY_KEY, schemaIds] as const,

    queryFn: () =>
      api.request(
        endpoints.schemaFields.list({ schemaId: schemaIds, queue: queueIds })
      ),
    enabled: !!schemaIds.length,
    keepPreviousData: true,
  });
};

export const useSetFetchedSchemaFields = () => {
  const queryClient = useQueryClient();

  return (
    callback: (
      response: SchemaFieldListResponse | undefined
    ) => SchemaFieldListResponse | undefined
  ) =>
    queryClient.setQueriesData<SchemaFieldListResponse | undefined>(
      [SCHEMA_FIELDS_QUERY_KEY],
      response => callback(response)
    );
};
