import { DateRange } from '@rossum/ui/icons';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Datepicker from '../../../components/Datepicker';
import { formatDatepickerValue } from '../../../components/Datepicker/helpers';
import {
  formatDateQuery,
  parseDateQuery,
} from '../../../redux/modules/statistics/helpers';
import StatisticsFilterDropdown from './StatisticsFilterDropdown';

type ControlledDatepickerProps<T extends FieldValues> = {
  ControllerProps: Omit<ControllerProps<T>, 'render'>;
};

const ControlledDatepicker = <T extends FieldValues>({
  ControllerProps,
}: ControlledDatepickerProps<T>) => {
  const intl = useIntl();

  const { control, name } = ControllerProps;

  return (
    <Controller<T>
      control={control}
      name={name}
      render={({ field }) => {
        const value = field.value as { from: string; to: string };
        return (
          <Datepicker
            beginDate={new Date(value.from)}
            endDate={new Date(value.to)}
            onConfirm={({ from, to }: { from: Date; to: Date }) =>
              field.onChange({
                from: formatDateQuery(from),
                to: formatDateQuery(to),
              })
            }
            DatePanel={({ visible }) => (
              <StatisticsFilterDropdown
                open={visible}
                startIcon={<DateRange />}
              >
                {formatDatepickerValue(
                  parseDateQuery(value.from),
                  parseDateQuery(value.to),
                  intl.locale
                )}
              </StatisticsFilterDropdown>
            )}
            applyButtonTitle="ok"
          />
        );
      }}
    />
  );
};

export default ControlledDatepicker;
