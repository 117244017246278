import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { cancelEditMode, startEditMode } from '../../redux/modules/ui/actions';
import { useCancelEditMode } from './useCancelEditMode';
import { useConfirmEditMode } from './useConfirmEditMode';
import { useStartEditMode } from './useStartEditMode';

export const useEditMode = () => {
  const dispatch = useDispatch();

  const { data: config } = useStartEditMode();
  const { mutate: cancelStartedAnnotations } = useCancelEditMode();

  const confirmHasSucceededRef = useRef<boolean>(false);

  const {
    mutate: confirm,
    isLoading: confirmInProgress,
    isSuccess: isSuccessConfirm,
  } = useConfirmEditMode({
    onSuccess: () => {
      confirmHasSucceededRef.current = true;
    },
  });

  useEffect(() => {
    return () => {
      // If confirm was successful, and we are leaving the edit page,
      // we shouldn't cancel any annotation (they were cancelled by the confirm request)
      if (config && !confirmHasSucceededRef.current) {
        cancelStartedAnnotations(config);
      }
    };
  }, [cancelStartedAnnotations, config]);

  // Integration with legacy Redux state
  useEffect(() => {
    dispatch(startEditMode());

    return () => {
      dispatch(cancelEditMode());
    };
  }, [dispatch]);

  return {
    // Ensure the UI is not usable after confirm is successful (user is always redirected elsewhere)
    config: isSuccessConfirm ? undefined : config,
    confirm,
    confirmInProgress,
  };
};
