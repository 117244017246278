import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { Hook } from '@rossum/api-client/hooks';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

const QUERY_KEY_EXTENSION = 'extensions';

export const useHook = <TReturnedData = Hook>(
  id: number,
  queryOptions: Omit<
    UseQueryOptions<
      Hook,
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_EXTENSION, number]
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({
    queryKey: [QUERY_KEY_EXTENSION, id],
    queryFn: () => api.request(endpoints.hooks.get(id)),
    ...queryOptions,
  });
