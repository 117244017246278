import { Queue } from '@rossum/api-client/queues';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { approvalWorkflowsFeatureSelector } from '../../../pricing/selectors';
import {
  confirmedTab,
  defaultStatusTabs,
  rejectedTab,
  StatusTabArray,
  workflowsTab,
} from '../tabs';
import { sortStatusTabs } from '../utils';

const defaultTabs: StatusTabArray = Object.values(defaultStatusTabs);

export const useStatusTabs = (queue: Queue | null) => {
  const isApprovalWorkflowEnabled = useSelector(
    approvalWorkflowsFeatureSelector
  );
  const isConfirmedTabEnabled = queue?.useConfirmedState;
  const isRejectedTabEnabled = queue?.settings.rejectionConfig?.enabled;
  const isInWorkflowTabEnabled = queue?.settings.workflows?.enabled;

  const statusTabs = useMemo<StatusTabArray>(() => {
    const dynamicTabs = compact([
      isApprovalWorkflowEnabled && isInWorkflowTabEnabled && workflowsTab,
      isRejectedTabEnabled && rejectedTab,
      isConfirmedTabEnabled && confirmedTab,
    ]);
    return sortStatusTabs(defaultTabs.concat(dynamicTabs));
  }, [
    isApprovalWorkflowEnabled,
    isInWorkflowTabEnabled,
    isConfirmedTabEnabled,
    isRejectedTabEnabled,
  ]);

  return statusTabs;
};
