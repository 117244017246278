import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Trigger } from '../models/trigger';
import { triggerSchema } from '../models/trigger.schema';

export const get = (triggerUrl: Url) => {
  return {
    endpoint: triggerUrl,
    method: 'GET',
    responseSchema: triggerSchema,
  } as const;
};
