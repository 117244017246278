import { get } from 'lodash';
import createSelector from '../../../lib/createDeepEqualSelector';
import {
  AnyDatapointDataST,
  SimpleDatapointDataST,
} from '../../../types/datapoints';
import { State } from '../../../types/state';
import { getVisibleDatapoints } from '../datapoints/selector';

const activeColumnSelector = (state: State) =>
  state.searchAndReplace.currentColumn;

export const getDatapointsBySchemaId = createSelector(
  getVisibleDatapoints,
  activeColumnSelector,
  (datapoints: Array<AnyDatapointDataST>, currentColumn: string) =>
    datapoints.filter(
      datapoint => get(datapoint, 'schemaId') === currentColumn
    ) as Array<SimpleDatapointDataST>
);
