import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { apiUrlWithoutVersion } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import { EpicDependencies } from '../../../lib/apiTypes';
import { State } from '../../../types/state';
import { RootActionType } from '../../rootActions';
import {
  SubmitFeedbackActionT,
  submitFeedbackForm,
  submitFeedbackFormFulfilled,
} from './actions';

const sendFeedBackEpic = (
  action$: ActionsObservable<RootActionType>,
  _: StateObservable<State>,
  { authPost$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf([submitFeedbackForm])),
    mergeMap(
      ({
        meta: { form },
        payload: { rating = 666, message },
      }: SubmitFeedbackActionT) =>
        authPost$(`${apiUrlWithoutVersion}/feedback/email_report`, {
          form,
          message,
          rating,
        }).pipe(
          map(() => submitFeedbackFormFulfilled(form)),
          catchError(errorHandler)
        )
    )
  );

export default combineEpics(sendFeedBackEpic);
