/**
 * Types and utils for working with geometry
 * Everything is assumed to be parallel with axes
 */

export type Point2DCoordinates = [number, number];

export type Point2D = {
  x: number;
  y: number;
};

export type Vector2D = Point2D;

export const pointFromTuple = (tuple: Point2DCoordinates): Point2D => ({
  x: tuple[0],
  y: tuple[1],
});

/**
 * Backend representation of rectangle, `[x1, y1, x2, y2]`
 */
export type Rectangle2DCoordinates = [number, number, number, number];

/**
 * More practical and idiomatic representation of rectangle
 */
export type Rectangle2D = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const IDENTITY_MATRIX_2D = DOMMatrix.fromMatrix({
  is2D: true,
  m11: 1,
  m12: 0,
  m13: 0,
  m21: 0,
  m22: 1,
  m23: 0,
  m31: 0,
  m32: 0,
  m33: 1,
});

/**
 *
 * @param coords Coordinates of a rectangle corners `[x1, y1, x2, y2]`
 * @returns Rectangle object representation
 */
export const rectangleFromCoordinates = (
  coords: Rectangle2DCoordinates
): Rectangle2D => {
  return {
    x: coords[0],
    y: coords[1],
    width: coords[2] - coords[0],
    height: coords[3] - coords[1],
  };
};

export const applyPadding = (
  { x, y, width, height }: Rectangle2D,
  padding: number
): Rectangle2D => {
  return {
    x: x - padding,
    y: y - padding,
    width: width + 2 * padding,
    height: height + 2 * padding,
  };
};

/**
 *
 * @param rect Rectangle object representation
 * @returns Coordinates of a rectangle corners `[x1, y1, x2, y2]`
 */
export const rectangleToCoordinates = (
  rect: Rectangle2D
): Rectangle2DCoordinates => {
  return [rect.x, rect.y, rect.x + rect.width, rect.y + rect.height];
};
