import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ConditionalAuroraChip from '../../../components/conditional-aurora-chip/ConditionalAuroraChip';
import { boldText, linebreak } from '../../../lib/formaterValues';
import { auroraPromoCompletedSelector } from '../../../redux/modules/ui/selectors';
import { updateUiSettings } from '../../../redux/modules/user/actions';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import image from './img/aurora.gif';

export const AuroraPromo = () => {
  const intl = useIntl();

  const auroraPromoCompleted = useSelector(auroraPromoCompletedSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(!auroraPromoCompleted);

  const onClose = useCallback(() => {
    setOpen(false);
    dispatch(
      updateUiSettings({
        auroraPromoCompleted: true,
      })
    );
  }, [dispatch]);

  const onSwitchToAurora = useCallback(() => {
    setOpen(false);
    dispatch(
      updateUiSettings({
        auroraPromoCompleted: true,
        complexLineItems: true,
      })
    );
  }, [dispatch]);

  return (
    <Dialog open={open} PaperProps={{ elevation: 2 }}>
      <DialogTitle
        title={
          <Stack flexDirection="row" gap={2}>
            {intl.formatMessage({
              id: 'containers.complexLineItemsTour.auroraPromo.title',
            })}
            <ConditionalAuroraChip size="small" />
          </Stack>
        }
        onClose={onClose}
      />
      <DialogContent>
        <Stack direction="column" spacing={3} p={4}>
          <img
            src={image}
            alt={intl.formatMessage({
              id: 'containers.complexLineItemsTour.auroraPromo.title',
            })}
          />
          <Typography>
            {intl.formatMessage(
              { id: 'containers.complexLineItemsTour.auroraPromo.description' },
              { boldText, linebreak }
            )}
          </Typography>
        </Stack>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            {intl.formatMessage({
              id: 'containers.complexLineItemsTour.auroraPromo.keepOld',
            })}
          </Button>
          <Button
            onClick={onSwitchToAurora}
            variant="contained"
            color="primary"
          >
            {intl.formatMessage({
              id: 'containers.complexLineItemsTour.auroraPromo.switch',
            })}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
