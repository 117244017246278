import { assertNever } from '../../../../lib/typeUtils';
import { NavigationStepFunc } from './datapointNavigation';
import { defaultOrderBackward, defaultOrderForward } from './defaultOrder';
import { queryDatapointInMultivalue } from './queryDatapointInMultivalue';

export const gridColumnOrderForward: NavigationStepFunc = (current, ctx) => {
  if (
    current.kind !== 'table-multivalue' &&
    current.kind !== 'table-multivalue-child'
  ) {
    return defaultOrderForward(current, ctx);
  }

  const multivalue = current.path[1];

  if (multivalue.children.length === 0)
    return defaultOrderForward(current, ctx);

  const multivalueSchema = ctx.schema.find(s => s.id === multivalue.schemaId);

  if (!multivalueSchema) {
    return { kind: 'none' };
  }

  const rowSchema = ctx.schema.find(
    s => s.id === multivalueSchema.children?.[0]
  );

  if (!rowSchema) {
    return { kind: 'none' };
  }

  const schemaColumns = ctx.schema.filter(s =>
    (rowSchema.children ?? []).includes(s.id)
  );

  if (current.kind === 'table-multivalue') {
    const datapointInTuple = queryDatapointInMultivalue(
      ctx,
      multivalue,
      'first-in-multivalue',
      'first-in-tuple',
      schemaColumns
    );

    if (!datapointInTuple) {
      return {
        kind: 'none',
      };
    }

    return {
      kind: 'table-multivalue-child',
      path: [current.path[0], current.path[1], ...datapointInTuple],
    };
  }

  if (current.kind === 'table-multivalue-child') {
    const [, , tuple, datapoint] = current.path;

    const datapointInTuple = queryDatapointInMultivalue(
      ctx,
      multivalue,
      tuple,
      { type: 'next', datapoint, canMoveToDifferentTuple: true },
      schemaColumns
    );

    if (!datapointInTuple) {
      return {
        kind: 'table-multivalue-add-value',
        path: [current.path[0], multivalue],
      };
    }

    return {
      kind: 'table-multivalue-child',
      path: [current.path[0], current.path[1], ...datapointInTuple],
    };
  }

  /* istanbul ignore next */
  return assertNever(current);
};

export const gridColumnOrderBackward: NavigationStepFunc = (current, ctx) => {
  if (
    current.kind !== 'table-multivalue-add-value' &&
    current.kind !== 'table-multivalue-child'
  ) {
    return defaultOrderBackward(current, ctx);
  }

  const multivalue = current.path[1];
  if (multivalue.children.length === 0)
    return defaultOrderBackward(current, ctx);

  const multivalueSchema = ctx.schema.find(s => s.id === multivalue.schemaId);

  if (!multivalueSchema) {
    return { kind: 'none' };
  }

  const rowSchema = ctx.schema.find(
    s => s.id === multivalueSchema.children?.[0]
  );

  if (!rowSchema) {
    return { kind: 'none' };
  }

  const schemaColumns = ctx.schema.filter(s =>
    (rowSchema.children ?? []).includes(s.id)
  );

  if (current.kind === 'table-multivalue-add-value') {
    const datapointInTuple = queryDatapointInMultivalue(
      ctx,
      multivalue,
      'last-in-multivalue',
      'last-in-tuple',
      schemaColumns
    );

    if (!datapointInTuple) {
      return {
        kind: 'none',
      };
    }

    return {
      kind: 'table-multivalue-child',
      path: [current.path[0], current.path[1], ...datapointInTuple],
    };
  }

  const tuple = current.path[2];
  const datapoint = current.path[3];

  if (current.kind === 'table-multivalue-child') {
    const datapointInTuple = queryDatapointInMultivalue(
      ctx,
      multivalue,
      tuple,
      { type: 'prev', datapoint, canMoveToDifferentTuple: true },
      schemaColumns
    );

    if (!datapointInTuple) {
      return {
        kind: 'table-multivalue',
        path: [current.path[0], multivalue],
      };
    }

    return {
      kind: 'table-multivalue-child',
      path: [current.path[0], current.path[1], ...datapointInTuple],
    };
  }

  /* istanbul ignore next */
  return assertNever(current);
};
