import { ReactNode } from 'react';
import styles from './style.module.sass';

type Props = { children: ReactNode };

const PageLayout = ({ children }: Props) => (
  <div className={styles.PageLayout}>{children}</div>
);

export default PageLayout;
