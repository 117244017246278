import { create } from './create';
import { deleteEndpoint } from './delete';
import { list } from './list';
import { patch } from './patch';
export { type CreateWorkspacePayload } from './create';
export { type WorkspaceListQuery } from './list';

export const workspaces = {
  list,
  create,
  delete: deleteEndpoint,
  patch,
};
