import { FolderOpen } from '@rossum/ui/icons';
import { Skeleton, Stack } from '@rossum/ui/material';
import { random } from 'lodash';
import { useRef } from 'react';

export const QueueListSkeletons = () => {
  const widths = useRef(
    [1, 2, 3, 4, 5, 6, 7].map(n => [n, random(200, 250)] as const)
  ).current;

  return (
    <Stack sx={{ mt: 1 }}>
      {widths.map(([n, width]) => (
        <Stack key={n} direction="row" gap={1} sx={{ px: 2, py: 1.5 }}>
          <FolderOpen color="primary" />
          <Skeleton width={width} />
        </Stack>
      ))}
    </Stack>
  );
};
