// Generated by ts-to-zod
import { z } from 'zod';

export const createUserPayloadSchema = z.object({
  authType: z.string(),
  email: z.string().optional(),
  groups: z.array(z.string()),
  queues: z.array(z.string()),
  username: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  organization: z.string().optional(),
  password: z.string().optional(),
  url: z.string().optional(),
  uiSettings: z.unknown().optional(),
});
