import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { primaryColor } from '@rossum/rossum-ui/theme';
import { Stack, Typography } from '@rossum/ui/material';
import { get, kebabCase } from 'lodash';
import { matchSorter } from 'match-sorter';
import EmailIcon from 'mdi-react/EmailIcon';
import HelpCircleIcon from 'mdi-react/HelpCircleIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import WidgetsIcon from 'mdi-react/WidgetsIcon';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHookTemplatesQuery } from '../../../../business/hooks';
import InfoBox from '../../../../components/UI/InfoBox';
import LoadingIndicator from '../../../../components/UI/LoadingIndicator';
import Tile from '../../../../components/UI/Tile';
import { FIRST_EXTENSION_LINK } from '../../../../constants/values';
import { useExtensionsFeatureSubscription } from '../../../../features/pricing/hooks/useExtensionsFeatureSubscription';
import {
  duplicateHandlingFeatureSelector,
  mailboxFeatureSelector,
} from '../../../../features/pricing/selectors';
import { boldText, gray } from '../../../../lib/formaterValues/index';
import SearchInput from '../../../../ui/search-input/SearchInput';
import useDebounce from '../../../../utils/hooks/useDebounce';
import Loader from '../../../Loader';
import ExtensionDrawer, {
  ExtendedHookTemplate,
} from '../../components/ExtensionDrawer';
import sharedStyles from '../../style.module.sass';
import styles from './styles.module.sass';

export const searchInExtensions = <T extends { name: string; tags: string }>(
  searchValue: string,
  results: T[] | undefined
) => {
  if (!results) return [];

  const normalizedSearchValue = searchValue.trim();

  if (!normalizedSearchValue.length) {
    return results;
  }

  return matchSorter(results, normalizedSearchValue, {
    keys: [
      { threshold: matchSorter.rankings.CONTAINS, key: 'name' },
      { threshold: matchSorter.rankings.CONTAINS, key: 'tags' },
    ],
  });
};

const DUPLICATE_HANDLING_NAME = 'Duplicate Handling';
const mailboxExtensions = ['Advanced Email Filtering', 'Email Notifications'];

const ExtensionsStore = () => {
  const intl = useIntl();
  const [selectedExtension, setSelectedExtension] =
    useState<ExtendedHookTemplate | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const isDuplicateFeaturePurchased = useSelector(
    duplicateHandlingFeatureSelector
  );
  const isMailboxFeaturePurchased = useSelector(mailboxFeatureSelector);

  const {
    data: results,
    status: extensionTemplatesStatus,
    isRefetching,
  } = useHookTemplatesQuery();

  const extensionsSubscription = useExtensionsFeatureSubscription();

  const extendedResults = useMemo<ExtendedHookTemplate[] | undefined>(
    () =>
      results?.map(r => {
        const restrictDuplicateHandling =
          !isDuplicateFeaturePurchased && r.name === DUPLICATE_HANDLING_NAME;
        const restrictMailboxExtensions =
          !isMailboxFeaturePurchased && mailboxExtensions.includes(r.name);

        return {
          ...r,
          tags:
            r.storeDescription.match(/<p class="tags">(.+)<\/p>/)?.[1] ?? '',
          restricted:
            !extensionsSubscription.purchased ||
            restrictDuplicateHandling ||
            restrictMailboxExtensions,
        };
      }),
    [
      isDuplicateFeaturePurchased,
      isMailboxFeaturePurchased,
      results,
      extensionsSubscription.purchased,
    ]
  );

  const extensionTemplateList = useMemo(() => {
    return searchInExtensions(searchValue, extendedResults);
  }, [searchValue, extendedResults]);

  useEffect(() => {
    if (debouncedSearchValue.length >= 3 && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: 'rossumStoreSearch',
        searchValue: debouncedSearchValue,
      });
    }
  }, [debouncedSearchValue]);

  return (
    <PageContainer maxWidth={false} data-page-title="extension-store">
      <Stack spacing={3}>
        <Stack direction="row" alignItems="center" minHeight={35} spacing={1}>
          <Typography variant="h6">
            {intl.formatMessage({ id: 'containers.settings.extensions.store' })}
          </Typography>
          {isRefetching && <LoadingIndicator />}
        </Stack>
        <InfoBox icon={() => <WidgetsIcon color={primaryColor} />}>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.instorePrebuilt',
              })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage({
                id: 'containers.settings.extensions.store.instoreImprove',
              })}
            </Typography>
          </Stack>
        </InfoBox>
        {extensionTemplatesStatus === 'success' ? (
          <div className={sharedStyles.StoreTiles}>
            <SearchInput
              value={searchValue}
              onChange={value => setSearchValue(value)}
              sx={{ gridColumn: '1/-1' }}
              placeholder={intl.formatMessage({
                id: 'containers.settings.extensions.store.search.placeholder',
              })}
            />

            {extensionTemplateList.map(extension => {
              const { name, extensionImageUrl, description, type, config } =
                extension;
              return (
                <Tile
                  key={name}
                  name={name}
                  description={description}
                  imageUrl={extensionImageUrl}
                  handleClick={() => setSelectedExtension(extension)}
                  type={type}
                  runtime={get(config, ['runtime'])}
                  dataCy={`extension-store-${kebabCase(name)}`}
                />
              );
            })}
          </div>
        ) : (
          <div className={sharedStyles.LoaderContainer}>
            <Loader size={90} />
          </div>
        )}
        <div className={styles.StoreHints}>
          <div className={styles.StoreHintsInner}>
            <div className={styles.StoreHint}>
              <div className={styles.HintIcon}>
                <PlusIcon />
              </div>
              <div>
                <FormattedMessage
                  id="containers.settings.extensions.store.hints.createYourOwn.text"
                  values={{ gray, boldText }}
                />
              </div>
              <Link
                data-cy="extension-store-create"
                to={{
                  pathname: '/settings/extensions/create',
                  state: { backLink: '/settings/store' },
                }}
              >
                <FormattedMessage id="containers.settings.extensions.store.hints.createYourOwn.link" />
              </Link>
            </div>
            <div className={styles.StoreHint}>
              <div className={styles.HintIcon}>
                <HelpCircleIcon />
              </div>
              <div>
                <FormattedMessage
                  id="containers.settings.extensions.store.hints.howTo.text"
                  values={{ gray, boldText }}
                />
              </div>
              <a
                data-cy="extension-store-read-more"
                href={FIRST_EXTENSION_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="containers.settings.extensions.store.hints.howTo.link" />
              </a>
            </div>
            <div className={styles.StoreHint}>
              <div className={styles.HintIcon}>
                <EmailIcon />
              </div>
              <div>
                <FormattedMessage
                  id="containers.settings.extensions.store.hints.wantSubmit.text"
                  values={{ gray, boldText }}
                />
              </div>
              <a
                href="mailto:product@rossum.ai?subject=Interested in placing my extension to Rossum Store"
                data-cy="extension-store-contact-us"
              >
                <FormattedMessage id="containers.settings.extensions.store.hints.wantSubmit.link" />
              </a>
            </div>
          </div>
        </div>

        <ExtensionDrawer
          extension={selectedExtension}
          onClose={() => setSelectedExtension(null)}
        />
      </Stack>
    </PageContainer>
  );
};

export default ExtensionsStore;
