import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grow,
  Stack,
} from '@rossum/ui/material';
import { isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import SingleCheckboxControl from '../../../../components/ReactHookForm/controls/SingleCheckboxControl';
import {
  datapointsSelector,
  messagesSelector,
} from '../../../../redux/modules/datapoints/selector';
import { AuroraIcon } from '../../../../ui/aurora/Icons';
import { useGenerateRejectionEmail } from '../EmailResponseForm/hooks/useGenerateRejectionEmail';
import { EDITOR_STATE_EMPTY_VALUE } from '../EmailResponseForm/hooks/useGetFormValues';
import { getErrors, getUniqErrorMessages } from './helpers';

type GenerateEmailOptions = {
  formalLanguage: boolean;
  short: boolean;
  includeErrors: boolean;
};

const generateMessageOptionsSchema = yup.object().shape({
  formalLanguage: yup.boolean(),
  short: yup.boolean(),
  includeErrors: yup.boolean(),
});

type Props = {
  handleGeneratedMessage: (message: string) => void;
  senderName?: string;
  annotationUrl: string;
  editorStateHtmlValue: string;
};

const GenerateRejectionEmail = ({
  handleGeneratedMessage,
  annotationUrl,
  editorStateHtmlValue,
}: Props) => {
  const intl = useIntl();
  const isInitialState = editorStateHtmlValue === EDITOR_STATE_EMPTY_VALUE;

  // Get messages
  const allDatapoints = useSelector(datapointsSelector);
  const allMessages = useSelector(messagesSelector);

  const errorMessages = getUniqErrorMessages(
    getErrors(allMessages),
    allDatapoints
  );

  // Form submission and request
  const defaultValues = {
    formalLanguage: true,
    short: true,
    includeErrors: true,
  };

  const { control, handleSubmit, watch } = useForm<GenerateEmailOptions>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(generateMessageOptionsSchema),
  });

  // Render generated email message
  const [isRenderingMessage, setIsRenderingMessage] = useState(false);

  const generatingShortEmail = watch('short');

  const {
    mutate: generate,
    isLoading,
    error: generationError,
  } = useGenerateRejectionEmail(message => {
    // The code below imitates how ChatGPT is rendering the generated messages
    // Incrementaly adding parts of the message
    setIsRenderingMessage(true);
    const splittedString = message.split('');

    let index = 0;

    const intervalId = setInterval(
      () => {
        handleGeneratedMessage(splittedString.slice(0, index + 1).join(''));

        index += 1;

        if (index === splittedString.length) {
          setIsRenderingMessage(false);
          clearInterval(intervalId);
        }
      },
      generatingShortEmail ? 10 : 2
    );
  });

  const lastSubmittedValues = useRef(defaultValues);

  const onSubmit = (values: GenerateEmailOptions) => {
    const shouldRegenerate =
      isEqual(lastSubmittedValues.current, values) && !isInitialState;

    lastSubmittedValues.current = values;

    return generate({
      annotation: annotationUrl,
      messages: values.includeErrors ? errorMessages : [],
      options: {
        short: values.short,
        friendly: !values.formalLanguage,
        regenerate: shouldRegenerate,
      },
    });
  };

  const isGenerating = isLoading || isRenderingMessage;

  return (
    <Stack spacing={1}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{ label: { marginBottom: 0 } }}
          >
            <SingleCheckboxControl
              ControllerProps={{ control, name: 'short' }}
              FieldLabelProps={{ layout: 'none' }}
              label={intl.formatMessage({
                id: 'components.emailResponseForm.autoCompose.short',
              })}
              key="short"
              data-cy="generate-email-short-option"
            />
            <SingleCheckboxControl
              ControllerProps={{ control, name: 'includeErrors' }}
              FieldLabelProps={{ layout: 'none' }}
              label={intl.formatMessage({
                id: 'components.emailResponseForm.autoCompose.includeErrors',
              })}
              key="includeErrors"
              data-cy="generate-email-includeErrors-option"
            />
            <SingleCheckboxControl
              ControllerProps={{ control, name: 'formalLanguage' }}
              FieldLabelProps={{ layout: 'none' }}
              label={intl.formatMessage({
                id: 'components.emailResponseForm.autoCompose.friendly',
              })}
              key="formalLanguage"
              data-cy="generate-email-formalLanguage-option"
            />
          </Box>
          <Button
            variant={isInitialState ? 'contained' : 'outlined'}
            color={isInitialState ? 'primary' : 'secondary'}
            data-cy={
              isInitialState
                ? 'btn-generate-rejection-email-write'
                : 'btn-generate-rejection-email-re-write'
            }
            startIcon={
              isGenerating ? (
                <CircularProgress size={16} color="secondary" />
              ) : (
                <AuroraIcon />
              )
            }
            disabled={isGenerating}
            size="medium"
            onClick={handleSubmit(onSubmit)}
            sx={{
              alignSelf: 'center',
            }}
          >
            {isGenerating
              ? intl.formatMessage({
                  id: 'components.emailResponseForm.autoCompose.button.inProgress',
                })
              : isInitialState
                ? intl.formatMessage({
                    id: 'components.emailResponseForm.autoCompose.button.label',
                  })
                : intl.formatMessage({
                    id: 'components.emailResponseForm.autoCompose.button.label.rewrite',
                  })}
          </Button>
        </Stack>
        <Grow in={generationError?.code === 429} unmountOnExit>
          <Alert variant="standard" severity="error">
            {intl.formatMessage({
              id: 'components.emailResponseForm.autoCompose.throttleError',
            })}
          </Alert>
        </Grow>
      </Stack>
    </Stack>
  );
};

export default GenerateRejectionEmail;
