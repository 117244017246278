import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngine } from '../models/dedicatedEngine';
import { dedicatedEngineSchema } from '../models/dedicatedEngine.schema';
import { dedicatedEngineGetQuerySchema } from './get.schema';

export type DedicatedEngineGetQuery = Partial<{
  fields: ('id' | 'name' | 'url' | 'status' | 'schema' | 'description')[];
}>;

export const get = (engineId: ID, query: DedicatedEngineGetQuery = {}) => {
  return {
    endpoint: `/dedicated_engines/${engineId}`,
    method: 'GET',
    responseSchema: dedicatedEngineSchema,
    query,
    querySchema: dedicatedEngineGetQuerySchema,
  } as const;
};
