import { Action, ActionType, createAction } from 'typesafe-actions';
import { APIOptions } from '../../../lib/apiTypes';
import { Url } from '../../../types/basic';

type RepeateRequestPayload = { url: Url; query: APIOptions };
type RepeateRequestMeta = {
  fulfilledAction: (payload: unknown) => Action;
  timestamp: number;
  takeUntilActions: Array<string> | undefined;
};

export const repeateRequest = createAction(
  'REPEATE_REQUEST',
  (
    _timestamp: number,
    url: Url,
    query: APIOptions,
    _fullfilledAction: (payload: unknown) => Action,
    _takeUntilActions: Array<string> | undefined
  ) => ({ url, query }),
  (
    timestamp: number,
    _url: Url,
    _query: APIOptions,
    fulfilledAction,
    takeUntilActions
  ) => ({
    fulfilledAction,
    timestamp,
    takeUntilActions,
  })
)<RepeateRequestPayload, RepeateRequestMeta>();

export const repeateRequestSucceeded = createAction(
  'REPEATE_REQUEST_SUCCEEDED',
  undefined,
  timestamp => ({ timestamp })
)<undefined, { timestamp: number }>();

export type RepeatedRequestsAction = ActionType<
  typeof repeateRequest | typeof repeateRequestSucceeded
>;
