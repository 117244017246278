import { alpha, Typography } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { whiteDarkBackground } from '../../../../lib/formaterValues';
import styles from '../../style.module.sass';

const KeyboardShortcutsInfo = () => (
  <>
    <Typography variant="body2">
      <FormattedMessage
        id="components.documentValidation.validationSources.tooltip.shortcuts.title"
        values={{ white: whiteDarkBackground }}
      />
    </Typography>
    <div className={styles.KeyboardShortcutsWrapper}>
      <div className={styles.KeyboardShortcuts}>
        <div>
          <span className={styles.KeyboardKey}>TAB</span>
        </div>
        <div>
          <span className={styles.KeyboardKey}>ENTER</span>
        </div>
      </div>
      <div className={styles.KeyboardShortcuts}>
        <Typography
          variant="body2"
          sx={{
            color: theme => alpha(theme.palette.common.white, 0.6),
          }}
        >
          <FormattedMessage id="components.documentValidation.validationSources.tooltip.tab" />
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme => alpha(theme.palette.common.white, 0.6),
          }}
        >
          <FormattedMessage id="components.documentValidation.validationSources.tooltip.enter" />
        </Typography>
      </div>
    </div>
  </>
);

export default KeyboardShortcutsInfo;
