import { Logo } from '@rossum/rossum-ui/Logo';
import { Box, Stack, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { hasCustomLogoSelector } from '../../NavBar/Logo';

type InfoScreenHeaderProps = {
  title: string;
  subTitle?: ReactNode;
};

const InfoScreenHeader = ({ title, subTitle }: InfoScreenHeaderProps) => {
  const hasCustomLogo = useSelector(hasCustomLogoSelector);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        {!hasCustomLogo && (
          <Box
            component="span"
            sx={{ position: 'relative', mr: 1, top: '6px' }}
          >
            <Logo />
          </Box>
        )}
        {title}
      </Typography>
      {subTitle && (
        <Typography variant="body2" color="text.secondary" align="center">
          {subTitle}
        </Typography>
      )}
    </Stack>
  );
};

export default InfoScreenHeader;
