import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { QueueListQuery } from '@rossum/api-client/queues';
import { Queue } from '@rossum/api-client/queues';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';
import { QUERY_KEY_QUEUES_UNPAGINATED } from './useUnpaginatedQueues';

export const useUnpaginatedQueuesByIds = <TReturnedData = Queue[]>(
  query: QueueListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      Queue[],
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_QUEUES_UNPAGINATED, QueueListQuery]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_QUEUES_UNPAGINATED, query],

    queryFn: ({ queryKey }) =>
      api.unpaginatedRequest(endpoints.queues.list)(queryKey[1]),

    ...queryOptions,
  });
