import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

export type DragHandleProps = Omit<
  ReturnType<typeof useSortable>,
  'setNodeRef' | 'transform' | 'transition'
>;

const SortableWrapper = ({
  id,
  render,
}: {
  id: string;
  render: (renderProps: DragHandleProps) => ReactNode;
}) => {
  const { setNodeRef, transform, transition, ...rest } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div style={style} ref={setNodeRef}>
      {render(rest)}
    </div>
  );
};

export default SortableWrapper;
