// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const uploadPayloadSchema = z.object({
  filePath: z.string(),
});

export const uploadResponseSchema = z.object({
  document: urlSchema,
  annotation: urlSchema,
  results: z.array(
    z.object({
      document: urlSchema,
      annotation: urlSchema,
    })
  ),
});
