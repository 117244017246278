import { isBoolean } from 'lodash';
import React from 'react';
import { toastr } from 'react-redux-toastr';
import { getType } from 'typesafe-actions';
import Message from '../../components/Messages/Message';
import { throwError, throwInfo } from '../modules/messages/actions';

export default () => next => action => {
  if (
    action.type === getType(throwInfo) ||
    action.type === getType(throwError)
  ) {
    const { id, options, values } = action.payload;
    const { type } = action.meta;
    const {
      timeOut = 6000,
      buttonAction,
      buttonFunction,
      buttonType,
      dismissible,
      removeOnHover,
      removeOnHoverTimeOut,
    } = options;
    toastr[type](id, {
      component: props => (
        <Message
          timeout={timeOut}
          type={type}
          id={id}
          dismissible={isBoolean(dismissible) ? dismissible : true}
          removeOnHover={removeOnHover}
          buttonAction={buttonAction}
          buttonFunction={buttonFunction}
          buttonType={buttonType}
          removeOnHoverTimeOut={removeOnHoverTimeOut}
          values={values}
          {...props}
        />
      ),
      showCloseButton: false,
      timeOut,
      ...options,
    });
  }

  return next(action);
};
