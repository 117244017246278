import { GridPreProcessEditCellProps } from '@rossum/ui/x-data-grid-pro';
import { IntlShape } from 'react-intl';

export const validatePositiveInteger =
  (fieldName: string, intl: IntlShape) =>
  (params: GridPreProcessEditCellProps) => {
    const hasError =
      params.props.value < 0 || !Number.isInteger(Number(params.props.value));

    return {
      ...params.props,
      error: hasError,
      // sending a special prop with error message to avoid displaying an empty tooltip on transition out
      errorMessage: intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.columns.validations.validatePositiveInteger.error',
        },
        { fieldName }
      ),
    };
  };

export const validateNonEmptyString =
  (fieldName: string, intl: IntlShape) =>
  (params: GridPreProcessEditCellProps) => {
    const hasError = params.props.value === '';

    return {
      ...params.props,
      error: hasError,
      // sending a special prop with error message to avoid displaying an empty tooltip on transition out

      errorMessage: intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.columns.validations.validateNonEmptyString.error',
        },
        { fieldName }
      ),
    };
  };
