// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const organizationGroupWorkspaceSchema = z.object({
  id: idSchema,
  name: z.string(),
  organization: urlSchema.nullable(),
  url: urlSchema,
});
