import { useTheme } from '@rossum/ui/material';
import { ComponentProps } from 'react';
import Xarrow from 'react-xarrows';

type Props = {
  start: ComponentProps<typeof Xarrow>['start'];
  end: ComponentProps<typeof Xarrow>['end'];
  isActive: boolean;
  dataCy: string;
};

const Line = ({ start, end, isActive, dataCy }: Props) => {
  const theme = useTheme();
  const color = isActive ? theme.palette.primary.main : 'gray';

  return start && end ? (
    <span data-cy={dataCy}>
      <Xarrow
        start={start}
        end={end}
        startAnchor="right"
        endAnchor="left"
        curveness={0.5}
        lineColor={color}
        showHead
        headSize={4}
        headColor={color}
        zIndex={isActive ? 2 : 0}
        strokeWidth={isActive ? 2 : 1}
      />
    </span>
  ) : null;
};

export default Line;
