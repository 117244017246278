import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { Query, useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import { useLocation } from 'react-router';
import { apiUrlWithoutVersion } from '../../constants/config';
import { api } from '../../lib/apiClient';
import { asScalar, parse } from '../../lib/url';

type ErrorKey = 'invalidToken' | 'error';

export const useConfirmPassword = () => {
  const getErrorKey = (
    error: HttpError,
    isError: boolean
  ): ErrorKey | undefined => {
    if (!isError) return undefined;

    const tokenError = get(error.data, 'token') ?? undefined;

    return error.code === 400 && tokenError ? 'invalidToken' : 'error';
  };

  const { search } = useLocation();

  const {
    confirm: url,
    token: tokenFromUrl,
    uid: uidFromUrl,
    username: usernameFromUrl,
  } = parse(search);

  const token = asScalar(tokenFromUrl) || '';
  const uid = asScalar(uidFromUrl) || '';
  const username = asScalar(usernameFromUrl) || '';

  const mutation = useMutation({
    mutationFn: ({
      newPassword,
      confirmPassword,
    }: {
      newPassword: string;
      confirmPassword: string;
    }) =>
      api.request(
        endpoints.authentication.password.confirm(
          `${apiUrlWithoutVersion}${asScalar(url)}`,
          {
            token,
            uid,
            username,
            new_password1: newPassword,
            new_password2: confirmPassword,
          }
        ),
        { convertKeys: 'none' }
      ),

    meta: {
      disableReporting: (query: Query<unknown, HttpError>) =>
        // We do not report errors because they might be just token expiring
        query.state.error?.code === 400,
    },
  });

  const { error, isError } = mutation;

  return {
    ...mutation,
    errorKey: getErrorKey(error as HttpError, isError),
  };
};
