// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { suggestedDocumentSchema } from './suggestedDocument.schema';

export const suggestedEditSchema = z.object({
  id: idSchema,
  url: urlSchema,
  annotation: urlSchema,
  documents: z.array(suggestedDocumentSchema),
});
