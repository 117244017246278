// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const groupUrlSchema = urlSchema;

export const groupSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: groupUrlSchema,
});
