import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { QueueListQuery } from '@rossum/api-client/queues';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { queueInfoKeys } from '../keys';

export const useQueuesForEngine = (query: QueueListQuery = {}) => {
  return useQuery({
    queryKey: queueInfoKeys.list(query),
    queryFn: async () => {
      const queues = await api.request(
        endpoints.queues.list({
          ...query,
        })
      );

      const workspacesIds = queues.results.flatMap(queue =>
        queue.workspace ? [getIDFromUrl(queue.workspace)] : []
      );

      const workspaces = workspacesIds.length
        ? await api.request(endpoints.workspaces.list({ id: workspacesIds }))
        : { results: [] };

      const queuesInfo = queues.results.map(queue => {
        const workspace =
          workspaces.results.find(
            workspace => workspace.url === queue.workspace
          )?.name ?? '';

        return {
          queue: queue.name,
          id: queue.id,
          trainingEnabled: queue.trainingEnabled,
          workspace,
        };
      });

      return {
        info: queuesInfo,
        pagination: queues.pagination,
      };
    },
  });
};
