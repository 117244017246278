import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { RefCallBack } from 'react-hook-form';

type LineItemSettingsControlProps = {
  name: string;
  id: string;
  options: {
    title: string;
    description: string;
    value: string;
    chip?: ReactNode;
  }[];
  field: {
    onChange: (...event: unknown[]) => void;
    value: string;
    name: string;
    ref: RefCallBack;
  };
  disabled?: boolean;
};

export const LineItemSettingsControl = ({
  name,
  id,
  options,
  disabled,
  field,
}: LineItemSettingsControlProps) => (
  <FormControl>
    <FormLabel id={`settings-${id}`}>
      <Typography
        variant="h6"
        color={disabled ? 'text.disabled' : 'text.primary'}
      >
        {name}
      </Typography>
    </FormLabel>
    <RadioGroup aria-labelledby={`settings-${id}`} {...field}>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          disabled={disabled}
          value={option.value}
          control={<Radio size="small" />}
          sx={{ alignItems: 'start' }}
          label={
            <Stack pr={2}>
              <Stack direction="row" gap={1}>
                <Typography variant="body2" pt={1}>
                  {option.title}
                </Typography>
                {option.chip && (
                  <Box sx={{ mt: 0.75, width: 'fit-content' }}>
                    {option.chip}
                  </Box>
                )}
              </Stack>
              <Typography variant="caption" color="text.secondary">
                {option.description}
              </Typography>
            </Stack>
          }
        />
      ))}
    </RadioGroup>
  </FormControl>
);
