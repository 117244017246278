import { ActionType, createAction } from 'typesafe-actions';

export const openPopup = createAction(
  'OPEN_POPUP',
  (url: string, trusted: boolean) => ({
    url,
    trusted,
  })
)<{
  url: string;
  trusted: boolean;
}>();

export const closePopup = createAction('CLOSE_POPUP')<void>();

export type PopupActions = ActionType<typeof openPopup | typeof closePopup>;
