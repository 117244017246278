import { Paper, Stack, Typography } from '@rossum/ui/material';
import { invoke } from 'lodash';
import { ReactNode, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  activeRoute: { section: string };
  children: ReactNode;
  id: string;
  title: LocalizationKeys;
};

const Section = ({ id, title, children, activeRoute }: Props) => {
  const intl = useIntl();
  const section = useRef(null);

  useEffect(() => {
    if (id === activeRoute.section) {
      invoke(section, ['current', 'scrollIntoView'], {
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [activeRoute, id]);

  return (
    <Stack
      ref={section}
      id={id}
      component={Paper}
      spacing={2}
      p={4}
      alignItems="center"
      data-cy="personal-settings-section"
    >
      <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
        {intl.formatMessage({ id: title })}
      </Typography>
      {children}
    </Stack>
  );
};

export default Section;
