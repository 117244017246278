import { ElisRequestConfig } from '../../utils/requestConfig';
import { Schema } from '../models';
import { schemaSchema } from '../models/schema.schema';
import { createSchemaPayloadSchema } from './create.schema';

export type CreateSchemaPayload = {
  name: string;
  // Use more precise types
  content?: any;
};

export const create = (payload: CreateSchemaPayload) => {
  return {
    endpoint: `/schemas`,
    method: 'POST',
    responseSchema: schemaSchema,
    payloadSchema: createSchemaPayloadSchema,
    payload,
  } as const;
};
