import { ID } from '@rossum/api-client';
import { Alert, Collapse, LinearProgress, Stack } from '@rossum/ui/material';
import {
  DataGridPro,
  GridFilterItem,
  GridFilterModel,
  GridRowClassNameParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from '@rossum/ui/x-data-grid-pro';
import { LicenseInfo } from '@rossum/ui/x-license-pro';
import { useIsFetching } from '@tanstack/react-query';
import { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FakeCheckbox } from '../../components/UI/FakeCheckbox';
import { muiDataGridLicenseKey } from '../../constants/config';
import { UPLOAD_HASH } from '../../constants/values';
import { asScalar } from '../../lib/url';
import usePrometheusMetrics from '../../metrics/index';
import { safeOrganizationSelector } from '../../redux/modules/organization/selectors';
import { isUserAdmin } from '../../redux/modules/user/selectors';
import {
  commonDataGridStyles,
  HEADER_COLUMN_HEIGHT,
  restrictedRowStyles,
  ROW_HEIGHT,
} from '../../ui/data-grid/styles';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { CustomRow } from '../document-list-base/components/CustomRow';
import {
  allowedPageSizes,
  DEFAULT_PAGE_SIZE,
  useNextCursorStore,
  UsePaginationResponse,
  useRowCount,
} from '../document-list-base/hooks/usePagination';
import { SupportedAnnotationView } from '../document-list-base/supportedAnnotationViews';
import { absoluteMaxSizeStyles } from '../document-upload/components/helpers';
import { UploadWrapper } from '../document-upload/components/UploadWrapper';
import { useWorkspacesWithQueues } from '../queues/hooks/useWorkspacesWithQueues';
import { getColumnName } from './columns/helpers';
import { useTableConfig } from './columns/hooks/useTableConfig';
import { useColumns } from './columns/useColumns';
import { useStatusColumns } from './columns/useStatusColumns';
import BreadCrumbs from './components/BreadCrumbs';
import { EmptyStates } from './components/EmptyStates';
import { ErrorPage } from './components/ErrorPage';
import { getFilterChipsCount } from './components/HideFiltersButton';
import PagePreview from './components/PagePreview';
import { QueueDetails } from './components/QueueDetails';
import SearchResults from './components/SearchResults';
import { ToggleSidebarButton } from './components/ToggleSidebarButton';
import Toolbar from './components/Toolbar';
import ViewSwitch from './components/ViewSwitch';
import {
  contentWrapperStyles,
  navigationPanelInnerStyles,
  navigationPanelOuterStyles,
} from './constants';
import { CTAButtons } from './cta-actions/CTAButtons';
import { UploadButton } from './cta-actions/UploadButton';
import { getDocumentListErrorState } from './errorHandlers';
import { FilterPanel } from './filtering/FilterPanel';
import {
  appendSchemaColumns,
  getActiveQueue,
  getPageSizeFromQuery,
  TransformedData,
} from './helpers';
import { useCustomizeTable } from './hooks/useCustomizeTable';
import {
  DashboardQuery,
  LevelOptions,
  ViewOptions,
} from './hooks/useDashboardQuery';
import { useDataGridLocaleText } from './hooks/useDataGridLocaleText';
import { useFetchDashboardData } from './hooks/useFetchDashboardData';
import { useFetchDashboardDatapoints } from './hooks/useFetchDashboardDatapoints';
import { SCHEMA_FIELDS_QUERY_KEY } from './hooks/useFetchSchemaFields';
import { useSafelyParsedFiltering } from './hooks/useSafelyParsedFiltering';
import { useTimeoutSearchQuery } from './hooks/useTimeoutSearchQuery';
import { LazyLoadDatapoints } from './LazyLoadDatapoints';
import SelectionPanel from './selection-panel/SelectionPanel';
import { StatusTabs } from './statuses/StatusTabs';
import { AllDocsAnnotation } from './types';

LicenseInfo.setLicenseKey(muiDataGridLicenseKey);

type Props = {
  paginationProps: UsePaginationResponse;
  handleQueryChange: (newQuery: DashboardQuery) => void;
  handleFilterModelChange: (newFilterItems: GridFilterItem[]) => void;
  handleClearAllFilters: () => void;
  existingFilter: GridFilterModel | null;
  query: DashboardQuery;
  handleSelectAnnotation: (params: {
    annotationUrl: string;
    view: SupportedAnnotationView;
  }) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  view: ViewOptions;
  setView: (view: ViewOptions) => void;
  level: LevelOptions | null;
  newEmailsExist: boolean;
  sortingProps: {
    onSortModelChange: (sortModel: GridSortModel) => void;
    sortModel: GridSortModel;
  };
  onSearchSubmit: (searchValue: string | undefined) => void;
  openQueueModal: () => void;
  activeQueueId: ID | undefined;
  isSidebarEnabled: boolean;
};

const restrictedRowClass = 'restricted-row';

const DocumentsContent = memo(
  ({
    paginationProps,
    handleQueryChange,
    handleFilterModelChange,
    handleClearAllFilters,
    existingFilter,
    query,
    isSidebarOpen,
    toggleSidebar,
    view,
    setView,
    level,
    newEmailsExist,
    sortingProps: { onSortModelChange, sortModel },
    onSearchSubmit,
    openQueueModal,
    handleSelectAnnotation,
    activeQueueId,
    isSidebarEnabled,
  }: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const localeText = useDataGridLocaleText();
    const isAdmin = useSelector(isUserAdmin);
    const organization = useSelector(safeOrganizationSelector);

    const [rowForPreview, setRowForPreview] = useState<
      TransformedData | undefined
    >(undefined);

    const {
      workspacesWithQueues,
      queues,
      isLoading: isWorkspacesLoading,
    } = useWorkspacesWithQueues({
      enableQueries: true,
    });

    const {
      searchAfter,
      paginationModel,
      handlePaginationModelChange,
      setNextToMap,
      shouldForceSetNextCursor,
    } = paginationProps;

    const safelyParsedFiltering = useSafelyParsedFiltering(
      query.filtering ?? ''
    );

    const [areColumnsInMotion, setAreColumnsInMotion] =
      useState<boolean>(false);

    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(true);

    const activeQueue = getActiveQueue({
      level: query.level,
      queues,
      queueId: activeQueueId,
    });

    const { tableConfig, isLoading: isTableConfigLoading } = useTableConfig({
      // for a second before the request to fetch queues is finished tableConfig points to org level even if the user is on queue level. To prevent that we condition orgId to query level
      orgId: query.level === 'all' ? organization?.id ?? 0 : 0,
      queueId: activeQueue?.id ?? 0,
    });

    const statusColumns = useStatusColumns({
      queues,
      existingFilter,
      activeQueue,
    });

    const visibleStatusColumns = statusColumns.map(col => {
      const savedStatusColumn = tableConfig.columns.find(
        c => getColumnName(c) === col.metaName
      );
      return savedStatusColumn
        ? { ...col, visible: savedStatusColumn.visible }
        : col;
    });

    const { data, isInitialLoading, error } = useFetchDashboardData({
      statusColumns: visibleStatusColumns,
      isTableConfigLoading,
      query: {
        ordering: query.ordering,
        pageSize: getPageSizeFromQuery({
          query,
          fallbackValue: DEFAULT_PAGE_SIZE,
        }),
        searchAfter: asScalar(searchAfter),
      },
      searchQuery: query.search,
      dataGridFiltering: safelyParsedFiltering,
      isEnabled: !areColumnsInMotion,
      level: query.level,
    });

    const { schemaColumns, fetchMoreDatapoints, datapointData } =
      useFetchDashboardDatapoints({
        annotations:
          data?.results.map(({ id, restricted_access }) => ({
            id,
            restricted_access,
          })) ?? [],
        activeQueueId,
      });

    const rows = useMemo(() => {
      if (!data?.results) return [];

      // we are filtering annotations which are not in the filtered statuses or belongs to different queue

      return data.results;
    }, [data?.results]);

    const errorState = useMemo(() => getDocumentListErrorState(error), [error]);

    const doesUserRequireAccess = queues?.length === 0 && !isAdmin;

    const schemaFieldsAreLoading = !!useIsFetching({
      queryKey: [SCHEMA_FIELDS_QUERY_KEY],
    });

    const [rowSelectionModel, setRowSelectionModel] =
      useState<GridRowSelectionModel>([]);
    const selectedAnnotations = useMemo(() => {
      return (data?.results ?? []).filter(({ id }) =>
        rowSelectionModel.includes(id)
      );
    }, [rowSelectionModel, data?.results]);

    const selectionActive = selectedAnnotations.length > 0;

    const { columnsAreReady, columns, columnVisibilityModel } = useColumns({
      statusColumns,
      tableConfig,
      isTableConfigLoading,
      commonFieldProps: {
        sortable: !selectionActive,
      },
      annotations: data?.results,
      sideloadedLabels: data?.labels,
      dataIsLoading: isInitialLoading,
      level: query.level,
      activeQueue,
      handleSelectAnnotation,
      setRowForPreview,
    });

    const shouldShowParsingError =
      !safelyParsedFiltering.items.length && query.filtering?.length;

    const gridIsLoading =
      isInitialLoading || schemaFieldsAreLoading || isTableConfigLoading;

    useNextCursorStore({
      next: data?.pagination.next,
      gridIsLoading,
      setNextToMap,
      shouldForceSetNextCursor,
    });

    const rowCount = useRowCount(data?.pagination.total);

    const apiRef = useGridApiRef();

    const rowsWithSchemaColumns = useMemo(() => {
      return rows.map(row =>
        appendSchemaColumns(
          row,
          schemaColumns,
          datapointData.content,
          datapointData.results.map(r => r.id)
        )
      );
    }, [datapointData, rows, schemaColumns]);

    const filteredColumns = columns.flatMap(col =>
      'columnType' in col ? [col] : []
    );
    const {
      isDisabled,
      handleColumnOrderChange,
      handleWidthChange,
      pinnedColumns,
    } = useCustomizeTable({
      apiRef,
      setAreColumnsInMotion,
      columnsAreReady,
      activeQueue,
      columns: filteredColumns,
    });

    const filterableColumns = useMemo(
      () =>
        columns.flatMap(column =>
          column.filterable && column.field !== query.level ? [column] : []
        ),
      [columns, query.level]
    );

    const filterChipsCount = getFilterChipsCount({
      existingFilter,
      filterableColumns,
      dashboardLevel: query.level,
    });

    const search = useTimeoutSearchQuery(query.search);
    const viewSwitchIsVisible = !!activeQueue?.inbox;

    const dashboardIsReadyToUse = !!(
      !gridIsLoading &&
      columnsAreReady &&
      workspacesWithQueues &&
      queues
    );

    const sendMetricsToPrometheusEnabled = useFeatureFlag(
      'send-metrics-to-prometheus'
    );

    const prometheusMetricsEnabled =
      Boolean(organization?.uiSettings.sendMetricsToPrometheus) ||
      sendMetricsToPrometheusEnabled;

    usePrometheusMetrics(
      dashboardIsReadyToUse,
      {
        metricName: 'frontend_document_list_load_time_seconds',
        description: 'Time to load documet list in seconds',
      },
      prometheusMetricsEnabled
    );

    // This use effect is to remove filters that do not exist in available columns in the url
    useEffect(() => {
      if (existingFilter && columnsAreReady) {
        const columnNames = columns.map(c => c.field);
        const { invalidFilters, validFilters } = existingFilter.items.reduce<{
          invalidFilters: GridFilterItem[];
          validFilters: GridFilterItem[];
        }>(
          (acc, item) => {
            if (!columnNames.includes(item.field))
              return {
                ...acc,
                invalidFilters: [...acc.invalidFilters, item],
              };

            return { ...acc, validFilters: [...acc.validFilters, item] };
          },
          {
            invalidFilters: [],
            validFilters: [],
          }
        );

        if (invalidFilters.length > 0) {
          handleFilterModelChange(validFilters);
        }
      }
    }, [existingFilter, columns, handleFilterModelChange, columnsAreReady]);

    const onErrorReset = () =>
      handleQueryChange({
        filtering: undefined,
        level: 'all',
        ordering: undefined,
        view: 'documents',
        page: '1',
      });

    const renderEmptyState = () => (
      <EmptyStates
        searchQuery={search}
        isEmailView={false}
        shouldCreateQueue={
          !isWorkspacesLoading && workspacesWithQueues?.length === 0
        }
        openQueueModal={openQueueModal}
        queue={activeQueue}
        existingFilter={existingFilter}
        onClearFilters={handleClearAllFilters}
        onErrorReset={errorState !== null ? onErrorReset : undefined}
      />
    );

    const MemoisedToolbarSection = useMemo(
      () => (
        <>
          <Toolbar
            columns={columns.flatMap(c => ('columnType' in c ? [c] : []))}
            selectionActive={selectionActive}
            onSearchSubmit={onSearchSubmit}
            search={query.search}
            filterChipsCount={filterChipsCount}
            toggleFilterPanel={() => setShowFilterPanel(!showFilterPanel)}
            showFilterPanel={showFilterPanel}
            activeQueue={activeQueue}
          />
          <UploadButton
            disabled={!(dashboardIsReadyToUse && queues.length > 0)}
            onClick={() =>
              history.replace({ ...history.location, hash: UPLOAD_HASH })
            }
          />
        </>
      ),
      [
        activeQueue,
        columns,
        dashboardIsReadyToUse,
        filterChipsCount,
        history,
        onSearchSubmit,
        query.search,
        queues?.length,
        selectionActive,
        showFilterPanel,
      ]
    );

    return doesUserRequireAccess || errorState === 'no-access' ? (
      <>
        <ToggleSidebarButton
          isSidebarOpen={isSidebarOpen}
          onClick={toggleSidebar}
        />
        <ErrorPage
          onReset={() =>
            handleQueryChange({
              filtering: undefined,
              level: 'all',
              ordering: undefined,
              view: 'documents',
            })
          }
        />
      </>
    ) : (
      <Stack data-page-title="document-list" {...contentWrapperStyles}>
        {shouldShowParsingError ? (
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            onClose={() => handleQueryChange({ filtering: undefined })}
          >
            {intl.formatMessage({
              id: 'containers.allDocuments.alert.parsingUrlError',
            })}
          </Alert>
        ) : null}

        <Stack {...navigationPanelOuterStyles}>
          <Stack {...navigationPanelInnerStyles}>
            {isSidebarEnabled && (
              <ToggleSidebarButton
                isSidebarOpen={isSidebarOpen}
                onClick={toggleSidebar}
              />
            )}
            <BreadCrumbs
              activeQueueId={activeQueueId}
              allDocumentsLevelIsActive={level === 'all'}
            />
            {viewSwitchIsVisible && (
              <ViewSwitch
                isVisible={level === 'queue'}
                view={view}
                setView={setView}
                newEmailsExist={newEmailsExist}
              />
            )}
            {!activeQueue ? MemoisedToolbarSection : null}
          </Stack>

          {activeQueue ? (
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <QueueDetails activeQueue={activeQueue} />
              <Stack direction="row" spacing={2}>
                {MemoisedToolbarSection}
                <CTAButtons
                  documentListQuery={{
                    query: {
                      ordering: query.ordering,
                      pageSize: DEFAULT_PAGE_SIZE,
                    },
                    searchQuery: query.search,
                    dataGridFiltering: safelyParsedFiltering,
                  }}
                  activeQueue={activeQueue}
                  existingFilter={existingFilter}
                  annotations={
                    data?.results.flatMap(
                      ({ restricted_access, id, status, url }) =>
                        restricted_access
                          ? []
                          : {
                              id,
                              status,
                              url,
                            }
                    ) ?? []
                  }
                />
              </Stack>
            </Stack>
          ) : null}
        </Stack>

        <SelectionPanel
          selectedAnnotations={selectedAnnotations}
          setSelectedAnnotations={setRowSelectionModel}
          queues={queues}
        />

        <Stack gap={2}>
          {activeQueue && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
              }}
            >
              <StatusTabs
                existingFilter={existingFilter}
                handleFilterModelChange={handleFilterModelChange}
                activeQueue={activeQueue}
              />
            </Stack>
          )}

          <Collapse in={showFilterPanel}>
            <FilterPanel
              columns={filterableColumns}
              existingFilter={existingFilter}
              handleFilterModelChange={handleFilterModelChange}
              handleClearAllFilters={handleClearAllFilters}
              // we must wait till we have complete columns definition, filterChips for nonexisting columns are removed otherwise
              isLoading={!columnsAreReady}
            />
          </Collapse>

          <SearchResults querySearch={query.search} />
        </Stack>

        <UploadWrapper
          // we use key to reset the selectedQueue state while navigating
          key={activeQueue?.url}
          workspacesWithQueues={workspacesWithQueues}
          areColumnsInMotion={areColumnsInMotion}
          activeQueueUrl={activeQueue?.url}
        >
          <LazyLoadDatapoints
            apiRef={apiRef}
            fetchMoreDatapoints={fetchMoreDatapoints}
          />
          <DataGridPro
            apiRef={apiRef}
            loading={gridIsLoading}
            // data
            columns={columns}
            rows={rowsWithSchemaColumns}
            // localization
            localeText={localeText}
            // selection
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={newRowSelectionModel => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            disableColumnMenu
            rowSelectionModel={rowSelectionModel}
            // columns visibility
            columnVisibilityModel={columnVisibilityModel}
            pinnedColumns={!isDisabled ? pinnedColumns : undefined}
            // columns width
            onColumnWidthChange={handleWidthChange}
            // columns reordering
            onColumnOrderChange={handleColumnOrderChange}
            disableColumnReorder={isDisabled}
            // pagination
            pageSizeOptions={allowedPageSizes}
            rowCount={rowCount}
            pagination
            paginationMode="server"
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            // sorting
            sortingMode="server"
            onSortModelChange={sortModel => {
              // workaround for the issue with sorting when we are trying to apply sorting by column which is not loaded yet
              // otherwise onSortModelChange resets the sorting to the empty array
              if (sortModel.length === 0 && !columnsAreReady) return;
              onSortModelChange(sortModel);
            }}
            sortModel={sortModel}
            // slots and styles customization
            slots={{
              // TODO: investigate when noRowsOverlay is shown:
              // docs:https://mui.com/x/api/data-grid/data-grid-pro/
              noResultsOverlay: renderEmptyState,
              noRowsOverlay: renderEmptyState,
              baseCheckbox: FakeCheckbox,
              loadingOverlay: LinearProgress,
              row: CustomRow,
            }}
            slotProps={{
              row: { 'data-cy': 'document-row' },
              pagination: {
                'data-cy': 'all-documents-pagination',
                // vertically center pagination
                backIconButtonProps: {
                  // @ts-expect-error
                  'data-cy': 'all-documents-pagination-prev-btn',
                },
                nextIconButtonProps: {
                  // @ts-expect-error
                  'data-cy': 'all-documents-pagination-next-btn',
                },
                labelRowsPerPage: intl.formatMessage({
                  id: 'containers.allDocuments.pagination.label',
                }),
                sx: {
                  // hide pagination when there are no rows
                  display: rowCount === 0 ? 'none' : 'inherit',
                  [`> * p`]: {
                    margin: 0,
                  },
                },
              },
              baseSwitch: {
                color: 'secondary',
              },
              baseSelect: { native: false },
              baseSelectOption: {
                // @ts-expect-error sx is not in the type definition but it works,
                sx: {
                  // workaround for making first empty option having the same height as other options
                  '&:after': { content: "' '" },
                },
              },
            }}
            rowHeight={ROW_HEIGHT}
            columnHeaderHeight={HEADER_COLUMN_HEIGHT}
            getRowClassName={(
              params: GridRowClassNameParams<AllDocsAnnotation>
            ) => (params.row.restricted_access ? restrictedRowClass : '')}
            sx={{
              ...commonDataGridStyles,
              ...absoluteMaxSizeStyles,
              ...restrictedRowStyles,
            }}
          />
          {rowForPreview && (
            <PagePreview
              pages={rowForPreview.pages}
              originalFileName={rowForPreview.original_file_name ?? undefined}
            />
          )}
        </UploadWrapper>
      </Stack>
    );
  }
);

export default DocumentsContent;
