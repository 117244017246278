export type CanvasState = {
  zoomLevel: number;
  translateX: number;
  translateY: number;
};

export const stateToInverseMatrix = (state: CanvasState) =>
  new DOMMatrix([
    1 / state.zoomLevel,
    0,
    0,
    1 / state.zoomLevel,
    -state.translateX / state.zoomLevel,
    -state.translateY / state.zoomLevel,
  ]);

export const stateToMatrix = (state: CanvasState) =>
  new DOMMatrix([
    state.zoomLevel,
    0,
    0,
    state.zoomLevel,
    state.translateX,
    state.translateY,
  ]);

export const matrixToState = (matrix: DOMMatrix): CanvasState => ({
  translateX: matrix.e,
  translateY: matrix.f,
  zoomLevel: matrix.a,
});

// Attempt to solve a weird issue where we get a 3D matrix when inverting.
// Related? https://www.reddit.com/r/learnjavascript/comments/yn0tv9/dommatrixinverse_returns_3d_matrix/
// Here we can abuse the fact that the matricies we are working with have a very specific form.
export const safeInverse = (matrix: DOMMatrix): DOMMatrix => {
  return stateToInverseMatrix(matrixToState(matrix));
};
