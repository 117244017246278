import { endpoints } from '@rossum/api-client';
import {
  CreateQueueFromTemplatePayload,
  CreateQueueFromTemplateQuery,
} from '@rossum/api-client/queues';
import { api } from '../../lib/apiClient';

export const createConfiguredQueue = ({
  payload,
  query,
}: {
  payload: CreateQueueFromTemplatePayload;
  query?: CreateQueueFromTemplateQuery;
}) => api.request(endpoints.queues.createFromTemplate(payload, query));
