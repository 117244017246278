import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../types/basic';
import { User, UserList } from '../../../types/user';
import { QueryT } from '../annotations/types';
import { PartialUser } from '../user/actions';
import {
  DeleteUserFulfilledMeta,
  FetchUsersFulfilledPayload,
  UpdateUserDetailMeta,
} from './types';

export const fetchUsers = createAction(
  'FETCH_USERS',
  undefined,
  (query: QueryT) => ({ query })
)<undefined, { query: QueryT }>();

export const clearUsers = createAction('CLEAR_USERS')<void>();

export const fetchUsersFulfilled = createAction(
  'FETCH_USERS_FULFILLED',
  (payload: FetchUsersFulfilledPayload) => payload
)<FetchUsersFulfilledPayload>();

export const fetchDeletedUsersFulfilled = createAction(
  'FETCH_DELETED_USERS_FULFILLED',
  (payload: Array<number>) => payload
)<Array<number>>();

export const fetchActiveUsersCount = createAction(
  'FETCH_ACTIVE_USERS_COUNT'
)<void>();

export const fetchActiveUsersCountFulfilled = createAction(
  'FETCH_ACTIVE_USERS_COUNT_FULFILLED',
  (payload: number) => payload
)<number>();

export const fetchUserDetail = createAction(
  'FETCH_USER_DETAIL',
  (userId: ID) => userId
)<ID>();

export const fetchUserDetailFulfilled = createAction(
  'FETCH_USER_DETAIL_FULFILLED',
  (payload: User) => payload
)<User>();

export const updateUserDetail = createAction(
  'UPDATE_USER_DETAIL',
  (_id: ID, payload: PartialUser, _meta = {}) => payload,
  (id, _payload, meta: UpdateUserDetailMeta = {}) => ({ id, ...meta })
)<PartialUser, { id: ID } & UpdateUserDetailMeta>();

export const updateUserDetailFulfilled = createAction(
  'UPDATE_USER_DETAIL_FULFILLED',
  (payload: User, _meta: UpdateUserDetailMeta = {}) => payload,
  (_payload, meta = {}) => meta
)<User, UpdateUserDetailMeta>();

export const fetchAdminUsers = createAction('FETCH_ADMIN_USERS')<void>();

export const fetchAdminsCountFulfilled = createAction(
  'FETCH_ADMINS_COUNT_FULFILLED',
  (payload: number) => payload
)<number>();

export const fetchAllUsers = createAction(
  'FETCH_ALL_USERS',
  (query?: QueryT) => ({
    query,
  })
)<{
  query: QueryT | undefined;
}>();

export const fetchAllUsersFulfilled = createAction(
  'FETCH_ALL_USERS_FULFILLED',
  (payload: UserList, _isFirstBatch: boolean, _loaded: boolean) => payload,
  (_payload, isFirstBatch, loaded) => ({ isFirstBatch, loaded })
)<UserList, { isFirstBatch: boolean; loaded: boolean }>();

export const deleteUserFulfilled = createAction(
  'DELETE_USER_FULFILLED',
  (id: ID, _meta?: DeleteUserFulfilledMeta) => ({
    id,
  }),
  (_: ID, meta?: DeleteUserFulfilledMeta) => meta
)<{ id: ID }, DeleteUserFulfilledMeta | undefined>();

export type UpdateUserDetail = typeof updateUserDetail;

export type UsersActions = ActionType<
  | typeof fetchUsers
  | typeof clearUsers
  | typeof fetchUsersFulfilled
  | typeof fetchActiveUsersCountFulfilled
  | typeof fetchUserDetail
  | typeof fetchUserDetailFulfilled
  | UpdateUserDetail
  | typeof updateUserDetailFulfilled
  | typeof fetchAdminUsers
  | typeof fetchAdminsCountFulfilled
  | typeof fetchAllUsers
  | typeof fetchAllUsersFulfilled
  | typeof deleteUserFulfilled
>;
