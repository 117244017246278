// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import {
  functionConfigSchema,
  webhookConfigSchema,
} from '../models/hookConfig.schema';
import { extensionSourceSchema } from '../models/hookUtils.schema';

export const createWebHookPayloadSchema = z.object({
  name: z.string(),
  type: z.literal('webhook'),
  queues: z.array(urlSchema),
  events: z.array(urlSchema),
  config: webhookConfigSchema,
  secretsSchema: z.record(z.unknown()).optional(),
  settingsSchema: z.record(z.unknown()).optional(),
  settings: z.record(z.unknown()).optional(),
  extensionSource: extensionSourceSchema.optional(),
});

export const createFunctionPayloadSchema = z.object({
  name: z.string(),
  type: z.literal('function'),
  queues: z.array(urlSchema),
  events: z.array(z.string()),
  config: functionConfigSchema,
  runAfter: z.union([z.array(urlSchema), z.tuple([])]).optional(),
  tokenOwner: urlSchema.optional(),
  secretsSchema: z.record(z.unknown()).optional(),
  settingsSchema: z.record(z.unknown()).optional(),
  settings: z.record(z.unknown()).optional(),
  extensionSource: extensionSourceSchema.optional(),
});

export const createHookPayloadSchema = z.union([
  createWebHookPayloadSchema,
  createFunctionPayloadSchema,
]);
