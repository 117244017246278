import { DatapointFieldPatchModel } from './datapointField';
import { SimpleMultivalueFieldPatchModel } from './simpleMultivalueField';
import { TableMultivalueFieldPatchModel } from './tableMultivalueField';
import { DatapointField } from './transformedDatapointField';
import {
  SimpleMultivalueField,
  TableMultivalueField,
} from './transformedFields';

export type DedicatedEngineSchemaObject =
  | DatapointField
  | SimpleMultivalueField
  | TableMultivalueField;

export type DedicatedEngineSchemaObjectPatchModel =
  | DatapointFieldPatchModel
  | SimpleMultivalueFieldPatchModel
  | TableMultivalueFieldPatchModel;

/** @noSchema */
export enum DedicatedEngineSchemaObjectKind {
  Datapoint = 'Datapoint',
  SimpleMultivalue = 'SimpleMultivalue',
  TableMultivalue = 'TableMultivalue',
}

export const isDatapointField = (
  field: DedicatedEngineSchemaObject
): field is DatapointField =>
  field.__kind === DedicatedEngineSchemaObjectKind.Datapoint;

export const isSimpleMultivalueField = (
  field: DedicatedEngineSchemaObject
): field is SimpleMultivalueField =>
  field.__kind === DedicatedEngineSchemaObjectKind.SimpleMultivalue;

export const isTableMultivalueField = (
  field: DedicatedEngineSchemaObject
): field is TableMultivalueField =>
  field.__kind === DedicatedEngineSchemaObjectKind.TableMultivalue;
