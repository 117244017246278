import { Message } from '@rossum/api-client/shared';
import { Alert, Typography } from '@rossum/ui/material';
import { ForwardedRef, forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  isMessageDetailVisible,
  isMessageExceptionalError,
} from '../../../components/DatapointMessage/helpers';
import HTMLMessage from '../../../components/DatapointMessage/HTMLMessage';
import { boldText } from '../../../lib/formaterValues';
import MessageDetailDialog from './MessageDetailDialog';

type FooterMessageProps = {
  message: Message | undefined;
};

const FooterMessage = forwardRef(
  ({ message }: FooterMessageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [showMessageDetail, setShowMessageDetail] = useState(false);

    const intl = useIntl();
    if (!message) {
      return <div ref={ref} style={{ display: 'none' }} />;
    }

    const { detail, type } = message;

    const isExceptionalError = isMessageExceptionalError(detail, type);

    const messageDetailVisible = isMessageDetailVisible(detail, type);

    const onClose = () => setShowMessageDetail(false);

    return (
      <>
        <Alert
          ref={ref}
          severity={message.type}
          variant="filled"
          sx={{
            // so it doesn't take too much space in the sidebar
            maxHeight: 150,
            overflow: 'auto',
            wordBreak: 'break-word',
          }}
        >
          <>
            {isExceptionalError ? (
              intl.formatMessage(
                {
                  id: 'components.documentValidation.sidebar.exceptionalError.errorMessage',
                },
                {
                  extension: detail.hookName,
                  boldText,
                }
              )
            ) : (
              <HTMLMessage content={message.content} />
            )}
            {messageDetailVisible && (
              <Typography
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: 'inherit',
                }}
                onClick={() => setShowMessageDetail(true)}
              >
                {intl.formatMessage({
                  id: 'components.documentValidation.sidebar.exceptionalError.moreDetails',
                })}
              </Typography>
            )}
          </>
        </Alert>
        {messageDetailVisible && (
          <MessageDetailDialog
            open={showMessageDetail}
            messageDetail={detail}
            onClose={onClose}
            content={message.content}
            datapointId={message.id}
          />
        )}
      </>
    );
  }
);

export default FooterMessage;
