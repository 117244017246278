import { z } from 'zod';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { requestDemoPayloadSchema } from './requestDemo.schema';

export type RequestDemoPayload = {
  email: string;
  phone: string;
  extraInfo?: string;
};

export const requestDemo = (payload: RequestDemoPayload) => {
  return {
    endpoint: `/internal/request-demo`,
    method: 'POST',
    responseSchema: z.unknown(),
    payload,
    payloadSchema: requestDemoPayloadSchema,
  } as const;
};
