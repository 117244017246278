export const lengthComparator = (
  value1: Array<unknown> | undefined = [],
  value2: Array<unknown> | undefined = []
) => value1.length - value2.length;

export const arrayObjectComparator = (
  value1: { [key: string]: number } | undefined,
  value2: { [key: string]: number } | undefined
) => {
  if (value1 && value2) {
    return Object.entries(value1).length - Object.entries(value2).length;
  }
  return 0;
};
