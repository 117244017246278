// Generated by ts-to-zod
import { z } from 'zod';
import { organizationSchema } from '../models/organization.schema';

export const createOrganizationResponseSchema = z.object({
  organization: organizationSchema,
  key: z.string(),
  domain: z.string(),
});

export const createOrganizationPayloadSchema = z.object({
  templateName: z.string(),
  organizationName: z.string(),
  userFullname: z.string(),
  userEmail: z.string(),
  userPassword: z.string(),
  userUiSettings: z.unknown().optional(),
  createKey: z.string(),
  domain: z.string().optional(),
  phoneNumber: z.string().optional(),
});
