import { endpoints } from '@rossum/api-client';
import { DocumentsDownloadTaskResult } from '@rossum/api-client/tasks';
import download from 'downloadjs';
import { api } from '../../lib/apiClient';

export type AsyncTaskResultHandlerOptions<TResult> = {
  onSuccess?: (result: TResult) => void;
};

export const handleDownloadOriginalFilesResult = (
  downloadResult: DocumentsDownloadTaskResult,
  options: AsyncTaskResultHandlerOptions<DocumentsDownloadTaskResult>
) => {
  return api
    .request(endpoints.documents.getBlob(downloadResult.content))
    .then(data => download(data, downloadResult.fileName))
    .then(() => options?.onSuccess?.(downloadResult));
};
