import { Add } from '@rossum/ui/icons';
import { Button, Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import emailIcon from '../../../../assets/svg/email.svg';
import { docsUrl } from '../../../../constants/config';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import { Queue } from '../../../../types/queue';
import CreateInboxModal from './CreateInboxModal';
import styles from './styles.module.sass';
import { useCreateInbox } from './useCreateInbox';

type Props = {
  clearValidationMessages: typeof clearValidationMessagesAction;
  validationMessages: InputMessages;
  selectedQueue: Queue;
};

const NoInboxPlaceholder = ({
  clearValidationMessages,
  validationMessages,
  selectedQueue,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate } = useCreateInbox(selectedQueue);

  const onCreate = ({ emailPrefix }: { emailPrefix: string }) =>
    mutate(emailPrefix, { onSuccess: () => setOpen(false) });

  return (
    <>
      <Stack
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 24 }}
      >
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <div>
            <img src={emailIcon} alt="email icon" width="96px" height="64px" />
          </div>
          <div>
            <FormattedMessage id="containers.settings.queues.email.noInbox" />
          </div>
          <div className={styles.Description}>
            <FormattedMessage id="containers.settings.queues.email.noInbox.description" />
          </div>
          <a
            href={`${docsUrl}/#import-by-email`}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.FindMoreLink}
          >
            <div>
              <FormattedMessage id="containers.settings.queues.email.noInbox.moreInfo" />
            </div>
          </a>
        </Stack>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setOpen(true)}
        >
          <FormattedMessage id="containers.settings.queues.email.noInbox.create" />
        </Button>
      </Stack>
      <CreateInboxModal
        clearValidationMessages={clearValidationMessages}
        isOpen={open}
        onCancel={() => setOpen(false)}
        onSubmit={onCreate}
        validationMessages={validationMessages}
      />
    </>
  );
};

export default NoInboxPlaceholder;
