import { ElisRequestConfig } from '../../utils/requestConfig';
import { Survey } from '../models/survey';
import { surveySchema } from '../models/survey.schema';
import { fromTemplatePayloadSchema } from './fromTemplate.schema';

export type FromTemplatePayload = {
  uuid: string;
};

export const fromTemplate = (surveyTemplateUuid: string) => {
  return {
    endpoint: 'surveys/from_template',
    method: 'POST',
    responseSchema: surveySchema,
    payload: { uuid: surveyTemplateUuid },
    payloadSchema: fromTemplatePayloadSchema,
  } as const;
};
