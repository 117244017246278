import { userTableConfigSchema } from '@rossum/api-client/shared';
import { createSelector } from 'reselect';
import { allDocumentsDashboardCustomizationSelector } from '../../redux/modules/user/selectors';

export const userDashboardCustomizationSelector = createSelector(
  allDocumentsDashboardCustomizationSelector,
  dashboardUserCustomization => {
    return userTableConfigSchema
      .refine(
        obj => {
          if (obj.queues)
            return Object.keys(obj.queues.width).every(
              key => !Number.isNaN(+key)
            );

          return true;
        },
        { message: 'All keys must be numbers' }
      )
      .catch({})
      .parse(dashboardUserCustomization);
  }
);
