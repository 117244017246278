import { BillingStats } from '@rossum/api-client/billing';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { useApiClient } from '../../../lib/apiClient';
import {
  getBillingEndpoint,
  OrganizationGroupBillingEntity,
} from './useBillingEntity';
import { getBillingStatsPerOrganizationPayload } from './usePageStatisticsPerOrganization';

const QUERY_KEY_BILLING_STATS_PER_ORGANIZATION_EXPORT =
  'billing-stats-per-organization-export';

export const usePageStatisticsPerOrganizationExport = (
  billingEntity: OrganizationGroupBillingEntity,
  filters: Pick<BillingStats, 'endDate' | 'beginDate'>
) => {
  const api = useApiClient();
  const { refetch, isFetching } = useQuery({
    queryKey: [
      QUERY_KEY_BILLING_STATS_PER_ORGANIZATION_EXPORT,
      billingEntity,
      filters,
    ] as const,

    queryFn: ({ queryKey: [, billingEntity, filters] }) => {
      const { id, endpoint } = getBillingEndpoint(billingEntity);
      return api.request(
        endpoint.billingStats.export(
          id,
          getBillingStatsPerOrganizationPayload(filters)
        )
      );
    },
    enabled: false,
    onSuccess: data => {
      download(
        data,
        `${QUERY_KEY_BILLING_STATS_PER_ORGANIZATION_EXPORT}-${Date.now()}.csv`,
        'text/csv'
      );
    },
  });

  return { triggerExport: refetch, isExporting: isFetching };
};
