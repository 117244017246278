import { useTheme } from '@rossum/ui/material';
import { Point2D } from '../../document-store/helpers/geometry';

// Unit = multiples of stroke.
const CIRCLE_RADIUS = 3;

type MagicLineProps = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

const point = (p: Point2D) => {
  return `${p.x} ${p.y}`;
};

const roundCorner = (p1: Point2D, end: Point2D, radius: number) => {
  const angle = Math.atan2(end.x - p1.x, end.y - p1.y);
  const deg = angle * (180 / Math.PI) - 90;
  const sweep = end.y > p1.y ? 1 : 0;
  const point1 = { x: p1.x - radius, y: p1.y };
  const point2 = {
    x: p1.x + radius * Math.sin(angle),
    y: p1.y + radius * Math.cos(angle),
  };
  const rad = radius * (90 / deg);

  // We have to collapse to a line to avoid division by zero.
  // In that case, `rad` is equal to Infinity but SVG cannot render that.
  const arc =
    deg === 0
      ? `L ${point2}`
      : `A ${rad} ${rad} ${deg} 0 ${sweep} ${point(point2)}`;

  return `L ${point(point1)} ${arc} L ${point(end)}`;
};

export const MagicLine = ({ startX, startY, endX, endY }: MagicLineProps) => {
  const theme = useTheme();
  const start = { x: startX, y: startY };
  const mid = {
    x: start.x + (endX - start.x) / 2,
    y: start.y,
  };
  const end = { x: endX - 1, y: endY - 1 };

  const d = `M ${point(start)} ${roundCorner(mid, end, 10)}`;

  return (
    <g>
      <defs>
        <marker
          id="circle"
          viewBox="-5 -5 10 10"
          markerUnits="strokeWidth"
          markerWidth={10}
          markerHeight={10}
          refX={CIRCLE_RADIUS + 0.5}
        >
          <circle
            r={CIRCLE_RADIUS}
            stroke={theme.palette.primary.main}
            fill="transparent"
          />
        </marker>
      </defs>
      <path
        d={d}
        stroke={theme.palette.primary.main}
        strokeDasharray="0 0"
        strokeWidth="2"
        fill="transparent"
        pointerEvents="none"
        vectorEffect="non-scaling-stroke"
        markerStart="url(#circle)"
      />
    </g>
  );
};
