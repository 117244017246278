import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';

const getInitialEditorStateAndMessage = (msgString: string) => {
  const contentBlock = htmlToDraft(msgString);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const initialEditorState = EditorState.createWithContent(contentState);

  return {
    editorState: initialEditorState,
    initialMessage: draftToHtml(
      convertToRaw(initialEditorState.getCurrentContent())
    ),
  };
};

export const getSanitizedEditorMessage = (msgString: string) =>
  getInitialEditorStateAndMessage(msgString).initialMessage;

export const useWysiwygHtmlDecorator = ({
  msgString,
}: {
  msgString: string;
}) => {
  const initialEditorStateAndMessage =
    getInitialEditorStateAndMessage(msgString);

  const [editorState, setEditorState] = useState(
    initialEditorStateAndMessage.editorState
  );

  return [
    initialEditorStateAndMessage.initialMessage,
    editorState,
    setEditorState,
  ] as const;
};
