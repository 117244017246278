import { ofType } from 'redux-observable';
import { NEVER, of } from 'rxjs';
import { catchError, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { errorHandler, repeatedRequestHandler } from '../../../lib/api';
import { isActionOf, makeEpic } from '../utils';
import { repeateRequest, repeateRequestSucceeded } from './actions';

const repeateRequestEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(repeateRequest)),
    mergeMap(
      ({
        payload: { url, query },
        meta: { fulfilledAction, timestamp, takeUntilActions },
      }) =>
        authGetJSON$(url, query).pipe(
          takeUntil(
            takeUntilActions ? action$.pipe(ofType(...takeUntilActions)) : NEVER
          ),
          mergeMap(response =>
            of(fulfilledAction(response), repeateRequestSucceeded(timestamp))
          ),
          catchError(repeatedRequestHandler(timestamp)),
          catchError(errorHandler)
        )
    )
  )
);

export default repeateRequestEpic;
