import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { OrganizationGroupWorkspace } from '../models/organizationGroupWorkspace';
import { organizationGroupWorkspaceSchema } from '../models/organizationGroupWorkspace.schema';
import { organizationGroupWorkspaceListQuerySchema } from './workspaces.schema';

export type OrganizationGroupWorkspaceListQuery = {
  organization?: ID;
} & PaginationQuery;

export const workspaces = (
  // TODO: organizationGroupId needs to be mandatory here
  baseQuery: OrganizationGroupWorkspaceListQuery & { organizationGroupId?: ID }
) => {
  const { organizationGroupId, ...query } = baseQuery ?? {};
  // Hack for unpaginated query, where all props have to be optional.
  // eslint-disable-next-line no-console
  if (!organizationGroupId) console.warn('No organization group id');

  return {
    endpoint: `/organization_groups/${organizationGroupId}/workspaces`,
    method: 'GET',
    responseSchema: listResponse(organizationGroupWorkspaceSchema),
    query,
    querySchema: organizationGroupWorkspaceListQuerySchema,
  } as const;
};
