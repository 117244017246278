import { StateCreator } from 'zustand';

type PageConfig = {
  pageNumber: number;
  type: 'page';
};

type ColumnConfig = {
  schemaId: string;
  type: 'column';
};

type RowConfig = {
  tupleId: number;
  type: 'row';
};

export type CliLabelConfig = PageConfig | ColumnConfig | RowConfig;

export type DocumentBboxState = {
  selectedBboxes: number[];
  hoveredBbox: number | undefined;
  hoveredCliLabel: CliLabelConfig | undefined;
};

export type DocumentBboxActions = {
  addToSelectedBboxes: (bboxes: number[]) => void;
  setSelectedBboxes: (bboxes: number[]) => void;
  removeFromSelectedBboxes: (bboxes: number[]) => void;
  clearSelectedBboxes: () => void;
  setHoveredBbox: (bbox: number | undefined) => void;
  setHoveredCliLabel: (cliLabel: CliLabelConfig | undefined) => void;
};

export type DocumentBboxStoreType = DocumentBboxState & DocumentBboxActions;

export const documentBboxStoreSlice: StateCreator<
  DocumentBboxStoreType,
  [],
  [],
  DocumentBboxStoreType
> = set => ({
  selectedBboxes: [],
  hoveredBbox: undefined,
  hoveredCliLabel: undefined,

  clearSelectedBboxes: () => {
    set({ selectedBboxes: [] });
  },

  setSelectedBboxes: (newBoxes: number[]) => {
    set({ selectedBboxes: newBoxes });
  },

  addToSelectedBboxes: (newBoxes: number[]) => {
    set(({ selectedBboxes }) => ({
      selectedBboxes: [
        ...selectedBboxes,
        ...newBoxes.filter(bbox => !selectedBboxes.includes(bbox)),
      ],
    }));
  },

  removeFromSelectedBboxes: (removedBboxes: number[]) => {
    set(({ selectedBboxes }) => ({
      selectedBboxes: selectedBboxes.filter(
        bbox => !removedBboxes.includes(bbox)
      ),
    }));
  },

  setHoveredBbox: hoveredBbox => {
    set({ hoveredBbox });
  },

  setHoveredCliLabel: hoveredCliLabel => {
    set({ hoveredCliLabel });
  },
});
