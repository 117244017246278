import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { clearActions, pauseValidation } from './actions';
import { BeforeLeaveState } from './types';

const initialState: BeforeLeaveState = {
  current: undefined,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(pauseValidation): {
      return { ...state, current: action.payload };
    }
    case getType(clearActions): {
      return { ...state, current: undefined };
    }
    default:
      return state;
  }
};

export default reducer;
