import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  id: number;
};

const Title = ({ name, id }: Props) => {
  const intl = useIntl();

  return (
    <span>
      {name}{' '}
      <Typography component="span" color="text.disabled">
        {intl.formatMessage(
          {
            id: 'containers.settings.extensions.configApp.drawer.extensionId',
          },
          { id }
        )}
      </Typography>
    </span>
  );
};

export default Title;
