import { ContentPaste, DeleteOutline } from '@rossum/ui/icons';
import { Close as CloseIcon } from '@rossum/ui/icons';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@rossum/ui/material';
import {
  GridApi,
  gridExpandedSortedRowIdsSelector,
  GridRowId,
  gridVisibleColumnDefinitionsSelector,
} from '@rossum/ui/x-data-grid-pro';
import React from 'react';
import { useIntl } from 'react-intl';
import { GridColumns } from '../columns/types';
import { RemoveDialogParamsSetter } from '../hooks/useRemoveFromQueues';
import { GridRowModel, GridRowModelKeys } from '../rows/rowTypes';
import { EditValue } from './EditValue';

type SelectionPanelProps = {
  visible: boolean;
  editableColumns: Array<GridColumns[number] & { isBatchEditable: boolean }>;
  // CHECK GridApiPro => GridApi
  gridApiRef: React.MutableRefObject<GridApi>;
  selectedRows: GridRowModel[];
  startBatchEditMode: (params: { id: GridRowId; field: string }) => void;
  setRowSelectionModel: (selection: string[]) => void;
  openDeleteModal: RemoveDialogParamsSetter;
  shouldDisplayPasteOption: boolean;
  pasteFieldConfigurationToRows: (rows: GridRowModel[]) => void;
  setDataForBulkEditDrawer: React.Dispatch<
    React.SetStateAction<{
      field: Extract<GridRowModelKeys, 'options' | 'formula'>;
      aggregations?: Set<string>;
      rows?: GridRowModel[] | undefined;
    } | null>
  >;
};

export const SelectionPanel = ({
  visible,
  editableColumns,
  gridApiRef,
  selectedRows,
  startBatchEditMode,
  setRowSelectionModel,
  openDeleteModal,
  shouldDisplayPasteOption,
  pasteFieldConfigurationToRows,
  setDataForBulkEditDrawer,
}: SelectionPanelProps) => {
  const intl = useIntl();
  return (
    <Slide direction="right" in={visible} mountOnEnter unmountOnExit>
      <Box sx={{ position: 'relative' }}>
        <Paper
          elevation={4}
          component={Stack}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          height="100%"
          sx={{
            position: 'absolute',
            px: 2,
            mt: 2,
            minHeight: theme => theme.spacing(6.75),
            backgroundColor: theme => theme.palette.background.paper,
            borderRadius: 2,
            zIndex: 2,
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                minWidth: 110,
              }}
            >
              {intl.formatMessage(
                {
                  id: 'features.fieldManager.fieldDetail.selection.selectionPanel.selected.row',
                },
                { count: selectedRows.length }
              )}{' '}
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.selection.selectionPanel.selected',
              })}
            </Typography>
            {shouldDisplayPasteOption && (
              <Button
                color="secondary"
                onClick={() => {
                  pasteFieldConfigurationToRows(selectedRows);
                  setRowSelectionModel([]);
                }}
                startIcon={<ContentPaste />}
                data-cy="fm-batch-paste-field-config-from-clipboard"
              >
                {intl.formatMessage({
                  id: 'features.fieldManager.fieldDetail.selection.selectionPanel.button.paste',
                })}
              </Button>
            )}
            <EditValue
              onEditColumnValueClick={(field: GridRowModelKeys) => {
                const firstRowId = gridApiRef.current
                  .getSortedRowIds()
                  .find(id => gridApiRef.current.getSelectedRows().has(id));

                if (!firstRowId) {
                  throw new Error('No row selected');
                }

                if (field === 'options' || field === 'formula') {
                  setDataForBulkEditDrawer({
                    field,
                    aggregations: new Set([]),
                    rows: selectedRows,
                  });
                } else {
                  // handle scroll to the cell in case it is not visible
                  // due to virtualization the cell does not even need to be rendered and
                  // scrolling to focused input handled by browser automatically will not work
                  const rowIndex = gridExpandedSortedRowIdsSelector(
                    gridApiRef
                  ).findIndex(id => id === firstRowId);
                  const colIndex = gridVisibleColumnDefinitionsSelector(
                    gridApiRef
                  ).findIndex(column => column.field === field);
                  gridApiRef.current.scrollToIndexes({ rowIndex, colIndex });

                  startBatchEditMode({ id: firstRowId, field });
                }
              }}
              editableColumns={editableColumns}
            />
            <Button
              color="secondary"
              onClick={() => openDeleteModal({ selectedRows })}
              startIcon={<DeleteOutline />}
              data-cy="fm-batch-remove-field"
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.selection.selectionPanel.button.delete',
              })}
            </Button>

            <IconButton
              onClick={() => setRowSelectionModel([])}
              data-cy="fm-batch-close-panel"
            >
              <CloseIcon
                sx={{
                  opacity: 0.6,
                  cursor: 'pointer',
                  transition: 'opacity 0.2s',
                  ':hover': { opacity: 1 },
                }}
              />
            </IconButton>
          </Stack>
        </Paper>
      </Box>
    </Slide>
  );
};
