import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const USE_UNPAGINATED_INBOXES_QUERY_KEY = 'unpaginated-inboxes';

export const useGetInbox = (inboxId: number) => {
  return useQuery({
    queryKey: [USE_UNPAGINATED_INBOXES_QUERY_KEY, inboxId],
    queryFn: () => api.request(endpoints.inboxes.get(inboxId)),
    enabled: !!inboxId,
  });
};
