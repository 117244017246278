import date from 'dateformat';
import { nth } from 'lodash';
import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import { of } from 'rxjs';
import { filter, map, mergeAll, pluck, switchMap } from 'rxjs/operators';
import { RootActionType } from '../../rootActions';
import {
  clearAnnotationData,
  deleteAnnotation,
  nextAnnotableAnnotation,
  postponeAnnotation,
  startAnnotation,
} from '../annotation/actions';
import { throwInfo } from '../messages/actions';
import { fetchAnnotationStackFulfilled } from '../stack/actions';
import { isActionOf, makeEpic } from '../utils';
import {
  deleteAnnotations,
  popAnnotationFromStack,
  postponeAnnotations,
} from './actions';

export const getFilenameForExport = (
  contentDisposition: string | null,
  queueName?: string | null,
  format?: string
) => {
  const matches =
    contentDisposition &&
    contentDisposition.match(/filename="((['"]).*?\2|[^";\n]*)/);

  return (
    nth(matches, 1) ||
    R.filter(['export', queueName, date('isoDate')], R.isTruthy)
      .join('-')
      // https://stackoverflow.com/a/37511463
      .normalize('NFKD')
      .replace(/\p{Diacritic}/gu, '')
      .concat(format ? `.${format}` : '')
  );
};

const deleteAnnotationsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(deleteAnnotations)),
    pluck('meta'),
    map(({ urls, skipRedirect, emailId }) =>
      urls.map(url => deleteAnnotation(url, skipRedirect, emailId))
    ),
    mergeAll()
  )
);

const postponeAnnotationsEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(postponeAnnotations)),
    map(({ meta: { urls, skipRedirect } }) =>
      urls.map(url => postponeAnnotation(url, skipRedirect))
    ),
    mergeAll()
  )
);

const popAnnotationFromStackEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(isActionOf(popAnnotationFromStack)),
    switchMap(({ payload }) => {
      return of<RootActionType>(
        fetchAnnotationStackFulfilled(
          state$.value.stack.filter(id => id !== payload.id)
        ),
        clearAnnotationData(),
        ...(state$.value.ui.navigateToNext
          ? [nextAnnotableAnnotation()]
          : [startAnnotation(payload.id), throwInfo('annotationMoved')])
      );
    })
  )
);

export default combineEpics(
  popAnnotationFromStackEpic,
  deleteAnnotationsEpic,
  postponeAnnotationsEpic
);
