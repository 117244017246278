import { ID } from '../../utils/codecUtils';
import { z } from 'zod';

export const deleteEndpoint = (engineId: ID) => {
  return {
    endpoint: `/engines/${engineId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
