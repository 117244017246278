import { docsUrl } from '../../../constants/config';

export const defaultCodeJS = `
/*
This example function does 2 things:
1. Displays a warning message to the user for all the "item_amount_total" fields which exceed the predefined threshold
2. Removes all dashes from the "document_id" field

More about custom functions - https://developers.rossum.ai/docs/how-to-use-serverless-functions
*/

/**
 * Obligatory main function
 * @param {Object} payload - differs for each event, see ${docsUrl}/#webhook-events
 * @returns {Object} - messages to the user and operations that update the annotation content
 */
exports.rossum_hook_request_handler = ({
  annotation: {
    content // Annotation content tree (see ${docsUrl}/#annotation-data)
  }
}) => {
  // Values over the threshold trigger a warning message
  const tooBigThreshold = 1000000;

  // List of all datapoints with item_amount_total schema id
  const amountTotalColumnDatapoints = findBySchemaId(
    content,
    'item_amount_total',
  );

  // Display warning message for all the "item_amount_total" fields exceeding the threshold
  messages = [];
  for (var i = 0; i < amountTotalColumnDatapoints.length; i++) {

    // Use normalized_value for comparing values of Date and Number fields
    if (amountTotalColumnDatapoints[i].content.normalized_value >= tooBigThreshold) {
      messages.push(
        createMessage(
          'warning',
          'Value is too big',
          amountTotalColumnDatapoints[i].id,
        ),
      );
    }
  }

  // There should be only one "document_id" datapoint
  const [documentIdDatapoint] = findBySchemaId(content, 'document_id');

  // Remove dashes from the "document_id" field
  const operations = [
    createReplaceOperation(
      documentIdDatapoint.id,
      documentIdDatapoint.content.value.replace(/-/g, ''),
    ),
  ];

  return {
    messages,
    operations,
  };
};

// --- HELPER FUNCTIONS ---

/**
 * Return all datapoints matching a schema id.
 * @param {Object} content - annotation content tree (see ${docsUrl}/#annotation-data)
 * @param {string} schemaId - field's ID as defined in the extraction schema (see ${docsUrl}/#document-schema)
 * @returns - list of datapoints matching the schema ID
 */
const findBySchemaId = (content, schemaId) =>
  content.reduce(
    (results, dp) =>
    dp.schema_id === schemaId ?
    [...results, dp] :
    dp.children ?
    [...results, ...findBySchemaId(dp.children, schemaId)] :
    results,
    [],
  );


/**
 * Create a message which will be shown to the user
 * @param {string} type - type of the message, any of {info|warning|error}. Errors prevent confirmation in the UI.
 * @param {string} content - message shown to the user
 * @param {number} datapointId - id of the datapoint where the message will appear (null for "global" messages).
 * @returns {Object} - JSON message definition (see ${docsUrl}/#annotation-content-event-response-format)
 */
const createMessage = (type, content, datapointId = null) => ({
  content: content,
  type: type,
  id: datapointId,
});


/**
 * Replace the value of the datapoint with a new value.
 * @param {Object} datapointId - id of the datapoint to be changed
 * @param {string} newValue - new value of the datapoint
 * @return {Object} - JSON replace operation definition (see ${docsUrl}/#annotation-content-event-response-format)
 */
const createReplaceOperation = (datapointId, newValue) => ({
  op: 'replace',
  id: datapointId,
  value: {
    content: {
      value: newValue,
    },
  },
});

`;

export const defaultCodePython = `
"""
This custom serverless function example demonstrates showing messages to the
user on the validation screen, updating values of specific fields, and
executing actions on the annotation.

See https://elis.rossum.ai/api/docs/#rossum-transaction-scripts for more examples.
"""

from txscript import TxScript, default_to, substitute

def rossum_hook_request_handler(payload: dict) -> dict:
    t = TxScript.from_payload(payload)

    for row in t.field.line_items:
        if default_to(row.item_amount_base, 0) >= 1000000:
            t.show_warning('Value is too big', row.item_amount_base)

    # Remove dashes from document_id
    # Note: This type of operation is strongly discouraged in serverless
    # functions, since the modification is non-transparent to the user and
    # it is hard to trace down which hook modified the field.
    # Always prefer making a formula field!
    t.field.document_id = substitute(r'-', '', t.field.document_id)

    if default_to(t.field.amount_total, 0) > 1000000:
        print("postponing")
        t.annotation.action("postpone")
        return t.hook_response()

    return t.hook_response()
`;
