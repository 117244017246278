import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { snakeToCamel } from '../../lib/keyConvertor';
import { State } from '../../types/state';

export const ViewportSizeUpdater = ({ height }: { height: number }) => {
  // Loading states
  const annotationStatus = useSelector((state: State) =>
    snakeToCamel(state.annotation.status!)
  );

  const isImporting = annotationStatus === 'importing';

  const pages = useSelector((state: State) => state.pages.pages);

  const setViewportDimensions = useDocumentStore(
    state => state.setViewportDimensions
  );

  const viewportRef = useDocumentStore(state => state.viewportRef);

  // Update viewport dimensions in zustand on window resize
  const handleWindowResize = useCallback(() => {
    requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        if (viewportRef.current) {
          const newSize = viewportRef.current.getBoundingClientRect();
          setViewportDimensions(newSize);
        }
      })
    );
  }, [setViewportDimensions, viewportRef]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize, isImporting, pages.length]);

  // Update viewport size in zustand on footer resize
  useEffect(
    () => handleWindowResize(),
    [setViewportDimensions, viewportRef, height, handleWindowResize]
  );

  return null;
};
