import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';

export const EMAIL_THREAD_EMAILS = 'emailThreadEmails';

export const useEmailThreadEmails = (emailThreadUrl: string | undefined) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [EMAIL_THREAD_EMAILS, emailThreadUrl] as const,

    queryFn: () => {
      if (!emailThreadUrl) {
        return Promise.reject(new Error('Email thread is undefined.'));
      }

      return api.unpaginatedRequest(endpoints.email.list)({
        emailThread: getIDFromUrl(emailThreadUrl),
      });
    },
    enabled: !!emailThreadUrl,
  });
};
