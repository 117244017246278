// Generated by ts-to-zod
import { z } from 'zod';

export const schemaItemBaseSchema = z.object({
  id: z.string(),
  label: z.string(),
  description: z.string().optional(),
  hidden: z.boolean().optional().nullable(),
  disablePrediction: z.boolean().optional().nullable(),
});
