import {
  extensionFunctionType,
  extensionWebhookType,
} from '@rossum/api-client/hooks';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import {
  CreateExtension as CreateExtensionType,
  createExtension,
} from '../../../../redux/modules/extensions/actions';
import { workspacesWithNonEmptyQueuesSelector } from '../../../../redux/modules/workspaces/selector';
import { ExtensionType } from '../../../../types/extensions';
import { State } from '../../../../types/state';
import { Users } from '../../../../types/users';
import { WorkspaceWithQueues } from '../../../../types/workspace';
import CreateExtensionForm from './components/CreateExtensionForm';
import styles from './style.module.sass';

const isExtensionType = (type: string): type is ExtensionType =>
  [extensionWebhookType, extensionFunctionType].includes(type);

type StateProps = {
  workspacesWithNonEmptyQueues: Array<WorkspaceWithQueues>;
  defaultExtensionType: ExtensionType | undefined;
  organizationUsers: Users;
};

type DispatchProps = {
  onSubmit: CreateExtensionType;
};

type Props = StateProps & DispatchProps;

const CreateExtension = ({
  onSubmit,
  workspacesWithNonEmptyQueues,
  defaultExtensionType,
  organizationUsers,
}: Props) => {
  const location = useLocation<{ backLink?: string } | undefined>();
  return (
    <div className={styles.Page} data-page-title="create-extension">
      <CreateExtensionForm
        workspacesWithNonEmptyQueues={workspacesWithNonEmptyQueues}
        defaultExtensionType={defaultExtensionType}
        onSubmit={onSubmit}
        organizationUsers={organizationUsers}
        backLink={location?.state?.backLink}
      />
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const extensionTypeHash = state.router.location.hash;

  const maybeDefaultExtensionType =
    extensionTypeHash && extensionTypeHash.split('#')[1];

  const defaultExtensionType = isExtensionType(maybeDefaultExtensionType)
    ? maybeDefaultExtensionType
    : undefined;

  return {
    workspacesWithNonEmptyQueues: workspacesWithNonEmptyQueuesSelector(state),
    organizationUsers: state.users,
    defaultExtensionType,
  };
};

const mapDispatchToProps = {
  onSubmit: createExtension,
};

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(CreateExtension);
