import { combineEpics } from 'redux-observable';
import { catchError, filter, map, mergeMap, pluck } from 'rxjs/operators';
import { clickApiErrorHandler } from '../../../lib/api';
import { BboxType } from '../../../types/bbox';
import { isActionOf, makeEpic } from '../utils';
import { fetchBboxes, fetchBboxesFulfilled } from './actions';

const fetchBboxesEpic = makeEpic((action$, state$, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchBboxes)),
    pluck('meta', 'annotationUrl'),
    filter(() => !state$.value.ui.readOnly),
    mergeMap(url =>
      authGetJSON$<{ results: BboxType[] }>(`${url}/bboxes`).pipe(
        pluck('results'),
        map(fetchBboxesFulfilled),
        catchError(clickApiErrorHandler)
      )
    )
  )
);

export default combineEpics(fetchBboxesEpic);
