import { Dialog, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';

type DialogState = {
  key: 'deleteSchemaSection' | 'deleteSchemaField' | 'notSavedChanges';
  onConfirm: () => void;
};

export const useConfirmationDialog = () => {
  const [dialogState, setDialogState] = useState<DialogState | null>(null);

  const intl = useIntl();

  const dialog = (
    <Dialog
      open={!!dialogState}
      onClose={() => setDialogState(null)}
      sx={{ transition: 'smooth' }}
      PaperProps={{
        sx: { width: 480, minHeight: 213, position: 'fixed' },
        elevation: 2,
      }}
    >
      {dialogState && (
        <ActionDialog
          dialogTitle={intl.formatMessage({
            id: `features.queueSettings.dialog.${dialogState.key}.title`,
          })}
          onCancel={() => setDialogState(null)}
          dialogColor="secondary"
          dialogActions={
            <ActionDialogActions
              confirmButtonText={intl.formatMessage({
                id: `features.queueSettings.dialog.${dialogState.key}.confirm`,
              })}
              confirmButtonProps={{
                onClick: dialogState.onConfirm,
                color: 'secondary',
              }}
            />
          }
          dataCy={`${dialogState.key}-cancel-btn`}
        >
          <Typography variant="body1">
            {intl.formatMessage({
              id: `features.queueSettings.dialog.${dialogState.key}.text`,
            })}
          </Typography>
        </ActionDialog>
      )}
    </Dialog>
  );

  return { dialog, dialogState, setDialogState };
};
