// Generated by ts-to-zod
import { z } from 'zod';
import { annotationStatusSchema } from '../../shared/models/annotationStatus.schema';
import { urlSchema } from '../../utils/codecUtils.schema';

export const nextAnnotationResponseSchema = z.object({
  annotation: urlSchema.nullable(),
  sessionTimeout: z.string().optional(),
});

export const nextAnnotationPayloadSchema = z.object({
  annotationIds: z.array(z.number()),
  statuses: z.array(annotationStatusSchema),
});
