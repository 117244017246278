import { CheckCircleOutlined } from '@rossum/ui/icons';
import { IconButton, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { KeyboardShortcut } from '../../../../../components/UI/KeyboardShortcut';

type ConfirmButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  keyName?: 'tab';
};

export const ConfirmButton = ({
  disabled,
  onClick,
  keyName,
}: ConfirmButtonProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      key="confirm-button"
      title={
        disabled ? (
          ''
        ) : (
          <KeyboardShortcut
            keyName={keyName}
            description={intl.formatMessage({
              id: 'components.documentValidation.validationDialog.button.confirm.tooltip',
            })}
          />
        )
      }
    >
      <IconButton
        color="success"
        size="medium"
        data-cy="validation-dialog-confirm"
        onClick={onClick}
        disabled={disabled}
        sx={{ p: 0.5, minWidth: 0 }}
      >
        <CheckCircleOutlined />
      </IconButton>
    </Tooltip>
  );
};
