// Generated by ts-to-zod
import { z } from 'zod';

const errorsSchema = z.array(z.string());

const sourceValidateResultSchema = z
  .object({
    schemaId: errorsSchema,
    queue: errorsSchema,
  })
  .partial();

const datapointFieldValidateResultSchema = z
  .object({
    engineOutputId: errorsSchema,
    type: errorsSchema,
    label: errorsSchema,
    description: errorsSchema,
    sources: z.record(sourceValidateResultSchema),
    nonFieldErrors: errorsSchema,
  })
  .partial();

const allFieldsValidateResultSchema = z
  .object({
    engineOutputId: errorsSchema,
    type: errorsSchema,
    label: errorsSchema,
    description: errorsSchema,
    sources: z.record(sourceValidateResultSchema),
    nonFieldErrors: errorsSchema,
    children: z.union([
      z.object({
        children: z.record(datapointFieldValidateResultSchema),
      }),
      datapointFieldValidateResultSchema,
    ]),
  })
  .partial();

export const dedicatedEngineSchemaValidationResultSchema = z
  .object({
    content: z
      .object({
        fields: z.record(allFieldsValidateResultSchema),
        trainingQueues: z.record(errorsSchema),
      })
      .partial(),
  })
  .partial();
