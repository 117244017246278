// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../types.schema';

export const generateResponseSchema = z.object({
  emailBody: z.string(),
});

const contextMessageSchema = z.object({
  label: z.string(),
  content: z.string(),
});

const contextOptionsSchema = z.object({
  short: z.boolean(),
  friendly: z.boolean(),
  regenerate: z.boolean(),
});

export const generateRejectionPayloadSchema = z.object({
  annotation: urlSchema,
  messages: z.array(contextMessageSchema),
  options: contextOptionsSchema,
});
