import { Theme } from '@rossum/ui/material';
import { CSSProperties } from 'react';

export const FIELD_FORM_ID = 'field-form';
export const DELETE_FIELD_FORM_ID = 'delete-field-form';

export const IDENTIFICATION_SECTION_LINK =
  'https://rossum.ai/help/article/how-to-set-up-extraction-schema-in-rossum/#:~:text=First%20steps%20in%20the%20extraction%20schema%20setup&text=To%20customize%20the%20schema%20associated,included%20in%20your%20extraction%20schema';
export const RELEASE_DATE_OF_NEW_FIELDS_TYPE = '2023-08-31';

export const uiValidFieldTypeOptions = [
  'simpleValue',
  'multivalue',
  'lineItems',
  'button',
] as const;

export const uiValidLineItemFieldTypeOptions = [
  'lineItemSimpleValue',
  'lineItemButton',
] as const;

export type FieldTypeOptionExclSection = (
  | typeof uiValidFieldTypeOptions
  | typeof uiValidLineItemFieldTypeOptions
)[number];

export const dataTypeOptions = ['string', 'number', 'date', 'enum'] as const;
export type DataTypeOption = (typeof dataTypeOptions)[number];

export const multivalueDataTypeOptions = dataTypeOptions.filter(
  o => o !== 'enum'
);

export const legacyValueSourceOptions = ['unset'] as const;
export const properValueSourceOptions = [
  'captured',
  'formula',
  'data',
  'manual',
] as const;

export type ValueSourceOption =
  | (typeof legacyValueSourceOptions)[number]
  | (typeof properValueSourceOptions)[number];

export const editingOptions = [
  'enabled',
  'enabled_without_warning',
  'disabled',
] as const;

export type EditingOption = (typeof editingOptions)[number];

export const formulaEditingOptions = ['enabled', 'disabled'] as const;

export const numberFieldFormatOptions = [
  { label: '# ##0,#', helpText: '1 230,0', value: '# ##0,#' },
  { label: '# ##0.#', helpText: '1 230.0', value: '# ##0.#' },
  { label: '#,##0.#', helpText: '1,230.0', value: '#,##0.#' },
  { label: `#'##0.#`, helpText: `1'230.0`, value: `#'##0.#` },
  { label: `#.##0,#`, helpText: `1.230,0`, value: `#.##0,#` },
  { label: '# ##0', helpText: '1 230', value: '# ##0' },
  { label: '#,##0', helpText: '1,230', value: '#,##0' },
  { label: `#'##0`, helpText: `1'230`, value: `#'##0` },
  { label: `#.##0`, helpText: `1.230`, value: `#.##0` },
];

export const dateFieldFormatOptions = [
  { label: 'D/M/YYYY', helpText: '23/1/2019', value: 'D/M/YYYY' },
  { label: 'MM/DD/YYYY', helpText: '01/23/2019', value: 'MM/DD/YYYY' },
  { label: 'YYYY-MM-DD', helpText: '2019-01-23', value: 'YYYY-MM-DD' },
  { label: 'DD.MM.YY', helpText: '23.01.19', value: 'DD.MM.YY' },
];

export const queueSettingsPages = [
  'basic',
  'fields',
  'emails',
  'automation',
  'access',
] as const;

export const getLinkStyles = (theme: Theme): CSSProperties => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  textDecoration: 'none',
});

export const preDefinedDescriptions = {
  account_num: 'Bank account number.',
  bank_num: 'Sort code. Numerical code of the bank.',
  bic: 'Bank BIC or SWIFT code.',
  const_sym: 'Statistical code on payment order.',
  customer_id:
    'The number by which the customer is registered in the system of the supplier.',
  date_due: 'The due date of the invoice.',
  date_issue: 'Date of issue of the document.',
  date_uzp: 'The date of taxable event.',
  iban: 'Bank account number in IBAN format.',
  invoice_id: 'Invoice number.',
  document_id: 'The ID of the document.',
  order_id: 'Purchase order identification.',
  sender_order_id: '[BETA] Internal order ID in the suppliers system.',
  delivery_note_id: '[BETA] Delivery note ID defined on the invoice.',
  supply_place:
    '[BETA] Place of supply (the name of the city or state where the goods will be supplied).',
  recipient_address: 'Address of the customer.',
  recipient_dic: 'Tax identification number of the customer.',
  recipient_ic: 'Company identification number of the customer.',
  recipient_name: 'Name of the customer.',
  recipient_vat_id: 'Customer VAT Number.',
  recipient_delivery_name:
    '[BETA] Name of the recipient to whom the goods will be delivered.',
  recipient_delivery_address:
    '[BETA] Address of the reciepient where the goods will be delivered.',
  sender_address: 'Address of the supplier.',
  sender_dic: 'Tax identification number of the supplier.',
  sender_ic: 'Business/organization identification number of the supplier.',
  sender_name: 'Name of the supplier.',
  sender_vat_id: 'VAT identification number of the supplier.',
  sender_email: '[BETA] Email of the sender.',
  spec_sym: 'Payee id on the payment order, or similar.',
  terms:
    'Payment terms as written on the document (eg. "45 days", "upon receipt").',
  var_sym:
    'In some countries used by the supplier to match the payment received against the invoice.',
  currency:
    'The currency which the invoice is to be paid in. Possible values: czk, dkk, eur, gbp, nok, sek, usd or other.',
  document_type:
    'Possible values: credit_note, debit_note, tax_invoice (most typical), proforma, receipt, delivery_note or other.',
  invoice_type:
    'Possible values: credit_note, debit_note, tax_invoice (most typical), proforma, receipt, delivery_note or other.',
  language:
    'The language which the document was written in. Possible values: ces, deu, eng, fra, slk or other.',
  payment_state:
    'Particularly for consumer invoices with standing payment orders. Possible values: automatic, manual (most typical) or pending.',
  payment_method:
    '[BETA] Payment method text description defined on a document (e.g. "Cheque", "Pay order", "Before delivery")',
  amount_due:
    'Final amount including tax to be paid after deducting all discounts and advances.',
  amount_rounding: 'Remainder after rounding amount_total.',
  amount_total: 'Subtotal over all items, including tax.',
  amount_paid: 'Amount paid already.',
  amount_total_base: 'Base amount for tax calculation.',
  amount_total_tax: 'Total tax amount.',
  tax_detail_base: 'Sum of tax bases for items with the same tax rate.',
  tax_detail_rate: 'One of the tax rates in the tax breakdown.',
  tax_detail_tax: 'Sum of taxes for items with the same tax rate.',
  tax_detail_total:
    'Total amount including tax for all items with the same tax rate.',
  tax_detail_code:
    '[BETA] Text on document describing tax code of the tax rate (e.g. "GST", "CGST", "DPH", "TVA"). If multiple tax rates belong to one tax code on the document, the tax code will be assigned only to the first tax rate. (in future such tax code will be distributed to all matching tax rates.)',
  item_code:
    'Can be the SKU, EAN, a custom code (string of letters/numbers) or even just the line number.',
  item_description: 'Line item description. Can be multi-line with details.',
  item_quantity: 'Quantity of the item.',
  item_uom: 'Unit of measure of the item (kg, container, piece, gallon, ...).',
  item_rate: 'Tax rate for the line item.',
  item_tax: 'Tax amount for the line. Rule of thumb: tax = rate * amount_base.',
  item_amount:
    'Unit price with tax. Rule of thumb: amount = amount_base + tax.',
  item_amount_base: 'Unit price without tax.',
  item_amount_total:
    'The total amount to be paid for all the items including the tax. Rule of thumb: amount_total = amount * quantity.',
  item_amount_total_base:
    'The total amount to be paid for all the items excluding the tax. Rule of thumb: amount_total_base = amount_base * quantity.',
  item_other: 'Unrecognized data type.',
  notes: "Reviewer's notes.",
};
