import { isEmbedded } from '../constants/config';

// Everything is initialized on the start.
// Switching embedded / normal mode is not allowed.

const ELIS_TOKEN = 'secureToken';
const EMBEDDED_TOKEN = 'secureToken';

export const getAuthToken = isEmbedded()
  ? () => sessionStorage.getItem(EMBEDDED_TOKEN)
  : () => localStorage.getItem(ELIS_TOKEN);

export const setAuthToken = isEmbedded()
  ? (token: string) => sessionStorage.setItem(EMBEDDED_TOKEN, token)
  : (token: string) => localStorage.setItem(ELIS_TOKEN, token);

export const clearAuthToken = isEmbedded()
  ? () => sessionStorage.removeItem(EMBEDDED_TOKEN)
  : () => localStorage.removeItem(ELIS_TOKEN);
