import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../types/basic';
import { Workspace } from '../../../types/workspace';
import {
  CreateWorkspaceFulfilledMeta,
  FetchWorkspaceFulfilledPayload,
} from './types';

export const fetchWorkspaces = createAction(
  'FETCH_WORKSPACES',
  undefined,
  (url?: string) => ({
    url,
  })
)<undefined, { url: string | undefined }>();

export const deleteWorkspaceFulfilled = createAction(
  'DELETE_WORKSPACE_FULFILLED',
  (id: ID) => ({
    id,
  })
)<{ id: ID }>();

export const fetchWorkspacesFulfilled = createAction(
  'FETCH_WORKSPACES_FULFILLED',
  (payload: FetchWorkspaceFulfilledPayload) => payload
)<FetchWorkspaceFulfilledPayload>();

export const fetchWorkspace = createAction(
  'FETCH_WORKSPACE',
  undefined,
  (url: string) => ({ url })
)<undefined, { url: string }>();

export const fetchWorkspaceFulfilled = createAction(
  'FETCH_WORKSPACE_FULFILLED',
  (payload: Workspace) => payload
)<Workspace>();

export const updateWorkspace = createAction(
  'UPDATE_WORKSPACE',
  (_id: ID, payload: Partial<Workspace>) => payload,
  (id, _payload) => ({ id })
)<Partial<Workspace>, { id: ID }>();

export const updateWorkspaceFulfilled = createAction(
  'UPDATE_WORKSPACE_FULFILLED'
)<void>();

export const createWorkspaceFulfilled = createAction(
  'CREATE_WORKSPACE_FULFILLED',
  (payload: Workspace, _?: CreateWorkspaceFulfilledMeta) => payload,
  (_: Workspace, meta: CreateWorkspaceFulfilledMeta = { withMessage: true }) =>
    meta
)<Workspace, CreateWorkspaceFulfilledMeta>();

export type WorkspacesActions = ActionType<
  | typeof fetchWorkspaces
  | typeof fetchWorkspacesFulfilled
  | typeof fetchWorkspace
  | typeof fetchWorkspaceFulfilled
  | typeof updateWorkspace
  | typeof updateWorkspaceFulfilled
  | typeof createWorkspaceFulfilled
  | typeof deleteWorkspaceFulfilled
>;
