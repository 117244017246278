import {
  Input as UIInput,
  Props as ProvidedProps,
} from '@rossum/rossum-ui/Input';
import { injectIntl, IntlShape } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type IntlProps = { intl: IntlShape };

type Props = IntlProps & ProvidedProps & { placeholder?: LocalizationKeys };

const Input = ({ intl, placeholder, ...props }: Props) => (
  <UIInput
    placeholder={placeholder && intl.formatMessage({ id: placeholder })}
    {...props}
  />
);

export default injectIntl<'intl', Props>(Input);
