// Generated by ts-to-zod
import { z } from 'zod';

export const queueDuplicatePayloadSchema = z.object({
  name: z.string(),
  copyExtensionsSettings: z.boolean(),
  copyEmailSettings: z.boolean(),
  copyAutomationSettings: z.boolean(),
  copyPermissions: z.boolean(),
  copyDeleteRecommendations: z.boolean(),
});
