import {
  RuiAutocomplete,
  RuiAutocompleteProps,
} from '@rossum/rossum-ui/RuiAutocomplete';
import {
  AutocompleteRenderInputParams,
  FormHelperTextProps,
  TextField,
} from '@rossum/ui/material';
import React, { FocusEvent, ReactNode, useCallback } from 'react';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import { FormControlProps } from './utils';

type FormTypeaheadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = string,
  TOption = string,
> = Pick<FormControlProps<TFieldValues, TName>, 'control' | 'name'> &
  Omit<RuiAutocompleteProps<TOption>, 'renderInput'> & {
    getOptionValue?: (_option: TOption) => TValue;
    // TODO: GET RID OF THIS!
    getErrorMessage?: (_id: string, _error: FieldError) => string | undefined;
    renderInput?: (
      controllerRenderProps: Parameters<
        ControllerProps<TFieldValues, TName>['render']
      >[number]
    ) => (params: AutocompleteRenderInputParams) => ReactNode;
    label?: React.ReactNode;
    helperText?: string;
    FormHelperTextProps?: FormHelperTextProps;
  };

const FormTypeahead = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends string = string,
  TOption = string,
>({
  control,
  name,
  getErrorMessage,
  options,
  multiple,
  renderInput,
  // accessors for value and label if objects are provided to option, default to just returning option
  getOptionValue = (option: TOption | string) => option as unknown as TValue,
  getOptionLabel = (option: TOption | string) => option as unknown as string,
  helperText = '',
  FormHelperTextProps = undefined,
  ...rest
}: FormTypeaheadProps<TFieldValues, TName, TValue, TOption>) => {
  // These are necessary for 'freeSolo' variant where both an option from a list
  // can be picked (can be object) or the text field value can be selected (which is a string)
  const handleOptionValue = useCallback(
    (option: TOption | string | null) =>
      // option === null means the field was cleared
      // these things need conventions
      option === null || typeof option === 'string'
        ? option
        : getOptionValue(option),
    [getOptionValue]
  );

  const handleOptionLabel = useCallback(
    (option: TOption | string) =>
      typeof option === 'string' ? option : getOptionLabel(option),
    [getOptionLabel]
  );

  // handling onChange inside this component instead of in the form itself is a bit tricky
  // and it also means this component will be a lot less flexible
  const handleOnChange = useCallback(
    (onChange: (..._val: unknown[]) => void) => {
      return (
        _e: React.SyntheticEvent<Element, Event>,
        value: string | TOption | (string | TOption)[] | null
      ) => {
        if (Array.isArray(value)) {
          onChange(value.map(handleOptionValue));
        } else {
          onChange(handleOptionValue(value));
        }
      };
    },
    [handleOptionValue]
  );

  // autoSelect causes inconsistent value changes onBlur (Github issue at the bottom of the page)
  // removing autoSelect forces us to manually handle the onBlur here (we update the autocomplete value onBlur)
  const handleOnBlur = ({
    onChange,
    fieldValue,
    e,
  }: {
    onChange: (..._val: unknown[]) => void;
    fieldValue: string | TOption | (string | TOption)[] | null;
    e: FocusEvent<HTMLInputElement>;
  }) => {
    const { value } = e.target;
    if (!value) return;

    const isMultiSelect = Array.isArray(fieldValue);

    if (!isMultiSelect) {
      onChange(handleOptionValue(fieldValue));
    } else if (!fieldValue.includes(value)) {
      onChange([...fieldValue, value].map(handleOptionValue));
    }
  };

  const resolveAutocompleteValue = useCallback(
    (fieldValue: TValue) => {
      if (rest.freeSolo) {
        return fieldValue;
      }

      if (multiple) {
        return options.filter(option =>
          fieldValue.includes(getOptionValue(option))
        );
      }

      return (
        options.find(option => getOptionValue(option) === fieldValue) ?? null
      );
    },
    [getOptionValue, multiple, options, rest.freeSolo]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={controllerRenderProps => (
        <RuiAutocomplete
          {...controllerRenderProps.field}
          value={resolveAutocompleteValue(controllerRenderProps.field.value)}
          onChange={handleOnChange(controllerRenderProps.field.onChange)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleOnBlur({
              onChange: controllerRenderProps.field.onChange,
              fieldValue: controllerRenderProps.field.value,
              e,
            });
            controllerRenderProps.field.onBlur();
          }}
          options={options}
          multiple={multiple}
          clearOnBlur
          renderInput={
            renderInput
              ? renderInput(controllerRenderProps)
              : params => {
                  return (
                    <TextField
                      {...params}
                      error={Boolean(controllerRenderProps.fieldState.error)}
                      helperText={
                        controllerRenderProps.fieldState.error
                          ? getErrorMessage
                            ? getErrorMessage(
                                controllerRenderProps.field.name,
                                controllerRenderProps.fieldState.error
                              )
                            : controllerRenderProps.fieldState.error.message
                          : helperText
                      }
                      FormHelperTextProps={FormHelperTextProps}
                      placeholder={rest.placeholder}
                      label={rest.label}
                    />
                  );
                }
          }
          getOptionLabel={handleOptionLabel}
          {...rest}
        />
      )}
    />
  );
};

export default FormTypeahead;

// autoSelect issue https://github.com/mui/material-ui/issues/20602
