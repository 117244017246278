import { Chip, Tab, Typography } from '@rossum/ui/material';

type Props = {
  count: number;
  label: string;
  onClick: () => void;
  dataCy?: string;
};

const CountBadge = ({ count, label, onClick, dataCy }: Props) => (
  <Tab
    onClick={onClick}
    data-cy={dataCy}
    iconPosition="start"
    icon={
      <Chip
        size="tiny"
        label={count}
        sx={{
          backgroundColor: t => t.palette.primary.main,
        }}
      />
    }
    label={
      <Typography variant="caption" sx={{ textDecoration: 'underline' }}>
        {label}
      </Typography>
    }
    sx={{
      fontWeight: 'normal',
      fontSize: t => t.spacing(1.5),
      opacity: 1,
    }}
  />
);

export default CountBadge;
