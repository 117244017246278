import { Point2D, Rectangle2D } from '../../document-canvas/utils/geometry';

export const getRectangleFromPoints = (
  startPoint: Point2D,
  endPoint: Point2D
): Rectangle2D => {
  const minX = Math.min(startPoint.x, endPoint.x);
  const minY = Math.min(startPoint.y, endPoint.y);
  const maxX = Math.max(startPoint.x, endPoint.x);
  const maxY = Math.max(startPoint.y, endPoint.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
};
