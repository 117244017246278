import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { onDatapointSelection } from '../datapoints/actions';
import { leaveValidation, startEditMode } from '../ui/actions';
import { setCurrentFooterColumn } from './actions';

const initialState = Immutable({
  currentColumn: '',
  columnRightClientX: 0,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(setCurrentFooterColumn):
      return state
        .set('currentColumn', action.payload.currentColumn)
        .set('columnRightClientX', action.payload.columnRightClientX);
    case getType(leaveValidation):
    case getType(onDatapointSelection):
    case getType(startEditMode):
    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
