import { Skeleton, Stack, styled, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

const MainWrapper = styled(Stack)(({ theme }) => ({
  fontWeight: 700,
  marginRight: theme.spacing(4),
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: 40,
  backgroundColor: theme.palette.background.paper,
  '& svg': {
    width: 20,
    height: 20,
  },
}));

const LabelsWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
}));

type AggregateStatProps = {
  icon: ReactNode;
  value: ReactNode | undefined;
  description: ReactNode;
};

const LABEL_SIZE = 20;

export const AggregateStat = ({
  icon,
  value,
  description,
}: AggregateStatProps) => (
  <MainWrapper flexDirection="row" alignItems="center">
    <IconWrapper justifyContent="center" alignItems="center">
      {icon}
    </IconWrapper>
    <LabelsWrapper>
      {
        // TODO rewrite condition after clean types in Aggtegates component
      }
      {!value && value !== 0 ? (
        <Skeleton variant="text" style={{ fontSize: LABEL_SIZE }} />
      ) : (
        <Typography
          fontSize={LABEL_SIZE}
          color="text.primary"
          fontWeight={500}
          lineHeight={1.3}
        >
          {value}
        </Typography>
      )}
      <Typography fontSize={14} color="text.disabled">
        {description}
      </Typography>
    </LabelsWrapper>
  </MainWrapper>
);

export default AggregateStat;
