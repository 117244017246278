import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';

const Disclaimer = () => (
  <div className={styles.Disclaimer}>
    <div className={styles.DisclaimerText}>
      <FormattedMessage id="containers.introScreen.disclaimer" />
    </div>
  </div>
);

export default Disclaimer;
