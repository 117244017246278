enum VerticalOrigin {
  top = 'top',
  bottom = 'bottom',
  center = 'center',
}
enum HorizontalOrigin {
  left = 'left',
  right = 'right',
  center = 'center',
}

// TODO: this hook is temporary solution for resolving nested menu positions
// can be removed after finishing:
// - https://rossumai.atlassian.net/browse/AC-3435
// - https://rossumai.atlassian.net/browse/AC-3436

export const useMenuPosition = (
  nestedMenu: boolean
): {
  anchorOrigin: { vertical: VerticalOrigin; horizontal: HorizontalOrigin };
  transformOrigin: { vertical: VerticalOrigin; horizontal: HorizontalOrigin };
} => {
  const anchorOrigin = {
    vertical: nestedMenu ? VerticalOrigin.top : VerticalOrigin.bottom,
    horizontal: nestedMenu ? HorizontalOrigin.left : HorizontalOrigin.center,
  };

  const transformOrigin = {
    vertical: VerticalOrigin.top,
    horizontal: nestedMenu ? HorizontalOrigin.right : HorizontalOrigin.center,
  };

  return { anchorOrigin, transformOrigin };
};
