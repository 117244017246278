import { Avatar, Stack, Theme, Tooltip, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

export type User = {
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
};

type UserAvatarProps = {
  user: User | undefined;
};

const getInitials = ({ firstName, lastName, username, email }: User) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
  if (username) {
    return username.slice(0, 2);
  }
  if (email) {
    return email.slice(0, 2);
  }
  return '';
};

const UserAvatarTooltipTitle = ({
  firstName,
  lastName,
  username,
  email,
}: User) => {
  const title = firstName && lastName ? `${firstName} ${lastName}` : username;
  return (
    <Stack>
      {title && <strong>{title}</strong>}
      {email ?? null}
    </Stack>
  );
};

const UserAvatar = ({ user }: UserAvatarProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        user ? (
          <UserAvatarTooltipTitle {...user} />
        ) : (
          intl.formatMessage({ id: 'components.userAvatar.unknown' })
        )
      }
    >
      <Avatar
        sx={{
          width: 32,
          height: 32,
          backgroundColor: (theme: Theme) => theme.palette.text.primary,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme =>
              theme.palette.getContrastText(theme.palette.text.primary),
          }}
        >
          {user ? getInitials(user) : '?'}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
