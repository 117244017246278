import { ActionType, createAction } from 'typesafe-actions';

type FetchAnnotationStackPayload = {
  queue: number;
  search: string;
  currentId: number;
  previousId: number;
  nextId: number;
};

export const fetchAnnotationStack = createAction(
  'FETCH_ANNOTATION_STACK',
  (
    queue: number,
    search: string,
    currentId?: number,
    previousId?: number,
    nextId?: number
  ) => ({
    search,
    queue,
    currentId,
    previousId,
    nextId,
  })
)<FetchAnnotationStackPayload>();

export const fetchAnnotationStackFulfilled = createAction(
  'FETCH_ANNOTATION_STACK_FULFILLED',
  (payload: number[]) => payload
)<number[]>();

export const resetAnnotationStack = createAction(
  'RESET_ANNOTATION_STACK'
)<void>();

export type StackActions = ActionType<
  | typeof fetchAnnotationStack
  | typeof fetchAnnotationStackFulfilled
  | typeof resetAnnotationStack
>;
