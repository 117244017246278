import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import { QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED } from './useEmailTemplatesPerQueueUnpaginated';

export const useDeleteEmailTemplate = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();
  return useMutation({
    mutationFn: (emailTemplateId: number) =>
      api
        .request(endpoints.emailTemplates.get(emailTemplateId))
        .then(emailTemplate =>
          api
            .request(endpoints.emailTemplates.delete(emailTemplateId))
            .then(() => emailTemplate)
        ),

    onSuccess: async (deletedTemplate: EmailTemplate) => {
      deletedTemplate.triggers.forEach(triggerUrl =>
        api
          .request(endpoints.triggers.get(triggerUrl))
          .then(({ emailTemplates }) =>
            emailTemplates.length
              ? Promise.resolve()
              : api.request(endpoints.triggers.delete(getIDFromUrl(triggerUrl)))
          )
      );

      return queryClient.setQueryData<EmailTemplate[]>(
        [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED],
        old =>
          (old || []).filter(
            emailTemplate => emailTemplate.id !== deletedTemplate.id
          )
      );
    },

    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED],
      });
    },
  });
};
