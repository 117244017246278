import { minBy, zip } from 'lodash';
import { groupBy, isNumber } from 'remeda';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
  TupleDatapointDataST,
} from '../../../../types/datapoints';
import { AnyDatapointSchema } from '../../../../types/schema';
import {
  getColumnLabelsWithPosition,
  getTupleCoordsForTuple,
} from '../typedHelpers';
import { BoundedDatapoint } from '../types';

export const getRowLabelsWithPosition = (
  tuplesOnPage: (readonly [number, SimpleDatapointDataST[]])[],
  page: number
) =>
  tuplesOnPage.map(([tupleId, children]) => {
    const childrenOnThisPage = children.filter(c => c.content?.page === page);
    const top =
      minBy(childrenOnThisPage, c => c.content?.position?.[1] ?? Infinity)
        ?.content?.position?.[1] ??
      childrenOnThisPage[0].content?.ocrPosition?.[1] ??
      0;

    const left =
      minBy(childrenOnThisPage, c => c.content?.position?.[0] ?? Infinity)
        ?.content?.position?.[0] ?? 0;

    return [
      tupleId,
      {
        top,
        left,
      },
    ] as const;
  });

// transpose tuple children into columns
// Return type for zip includes undefined to cover situations when the arrays have different lengths.
// In this case, it's guaranteed that all tuples have the same number of children,
// so we can cast it
export const getTransposedTuplesOnPage = (
  tuplesOnPage: (readonly [number, SimpleDatapointDataST[]])[]
) =>
  tuplesOnPage.length
    ? (zip(...tuplesOnPage.map(t => t[1])) as SimpleDatapointDataST[][])
    : [];

export const getVirtualGrids = (
  allDatapoints: AnyDatapointDataST[],
  schemaMap: ReadonlyMap<string, AnyDatapointSchema>,
  pageDatapointsMap: Record<string, BoundedDatapoint[]>,
  multivalue: MultivalueDatapointDataST
) => {
  const pages = Object.keys(pageDatapointsMap);

  const tuples = multivalue.children
    .map(child => allDatapoints[child.index] as TupleDatapointDataST)
    .map(tuple =>
      // Tuple should belong on one page only (even if its datapoints are spread across multiple pages)
      {
        const tupleChildren = tuple.children.map(
          c => allDatapoints[c.index] as SimpleDatapointDataST
        );
        return {
          tuple,
          tupleChildren,
          tupleCoords: getTupleCoordsForTuple(tupleChildren),
        };
      }
    );

  const tuplesByPage = groupBy(
    tuples,
    tuple => tuple.tupleCoords?.page ?? undefined
  );

  const parts = pages.flatMap(_page => {
    const page = Number(_page);
    const preTuplesOnPage = tuplesByPage[page];

    if (isNumber(page)) {
      if (!preTuplesOnPage || !preTuplesOnPage.length) return [];

      const tuplesOnPage = preTuplesOnPage.map(
        tuple => [tuple.tuple.id, tuple.tupleChildren] as const
      );

      const rowsOnPageWithPosition = getRowLabelsWithPosition(
        tuplesOnPage,
        page
      );

      const fakeGridRows = rowsOnPageWithPosition.map(row => ({
        tupleId: row[0],
        topPosition: row[1].top,
        type: 'data',
      }));

      const sortedColumnLabelsWithPosition = getColumnLabelsWithPosition(
        tuplesOnPage,
        getTransposedTuplesOnPage(tuplesOnPage),
        schemaMap,
        page
      );

      return [
        {
          page,
          rows: fakeGridRows,
          columns: sortedColumnLabelsWithPosition.map(col => ({
            leftPosition: col[1].left,
            schemaId: col[0],
            headerTexts: [col[1].label ?? ''],
          })),
          // CLI: This is a fake grid, we are not using the width and height of it anywhere
          // but if we will try to use it, the value obviously doesn't make any sense
          width: -1,
          height: -1,
        },
      ];
    }
    return [];
  });

  return parts;
};
