import { Check } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import CheckboxBlankOutlineIcon from 'mdi-react/CheckboxBlankOutlineIcon';
import CheckBoxOutlineIcon from 'mdi-react/CheckBoxOutlineIcon';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isEnter } from '../../../lib/keyboard';
import { queueHasWorkflowsSelector } from '../../../redux/modules/annotation/selectors';
import styles from '../style.module.sass';

type Props = {
  displayAnnotationNavigation: boolean;
  documentIsValid: boolean;
  focused: boolean;
  navigateToNext: boolean;
  onDocumentConfirm: () => void;
  onDocumentConfirmByEnter: () => void;
  setNavigationToNext: () => void;
};

const ExportButton = ({
  displayAnnotationNavigation,
  documentIsValid,
  navigateToNext,
  onDocumentConfirm,
  setNavigationToNext,
  focused,
  onDocumentConfirmByEnter,
}: Props) => {
  useEffect(() => {
    const onEnterPressSubscription = fromEvent<KeyboardEvent>(
      document,
      'keydown'
    )
      .pipe(
        filter(() => focused),
        filter(isEnter)
      )
      .subscribe(() => onDocumentConfirmByEnter());

    return () => onEnterPressSubscription.unsubscribe();
  }, [focused, onDocumentConfirmByEnter]);

  const queueHasWorkflows = useSelector(queueHasWorkflowsSelector);

  const intl = useIntl();

  return (
    <div className={styles.ExportHoverable}>
      <Button
        variant="contained"
        fullWidth
        size="large"
        className={clsx(focused && styles.ExportButtonFocused)}
        startIcon={<Check />}
        onClick={() => onDocumentConfirm()}
        id="export-button"
        disabled={!documentIsValid}
        sx={{
          zIndex: 2,
          backgroundColor: !documentIsValid
            ? 'var(--graybox) !important'
            : undefined,
        }}
        disableElevation
        data-cy="confirm-annotation-btn"
      >
        {intl.formatMessage({
          id: queueHasWorkflows
            ? 'components.documentValidation.sidebar.submitDocument'
            : 'components.documentValidation.sidebar.confirmDocument',
        })}
      </Button>
      {displayAnnotationNavigation && (
        <div className={styles.NextDocumentBox}>
          <input
            checked={navigateToNext}
            type="checkbox"
            id="nextDocumentInput"
            // TODO this is called setNavigationToNext, but it works as toogle - should be renamed
            onChange={() => setNavigationToNext()}
          />
          <label
            className={styles.NextDocumentLabel}
            htmlFor="nextDocumentInput"
          >
            {navigateToNext ? (
              <CheckBoxOutlineIcon />
            ) : (
              <CheckboxBlankOutlineIcon />
            )}
            {intl.formatMessage({
              id: 'components.documentValidation.sidebar.navigateToNext',
            })}
          </label>
        </div>
      )}
    </div>
  );
};

export default ExportButton;
