import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { queueDeleteQuerySchema } from './delete.schema';

export type QueueDeleteQuery = { deleteAfter?: string };

export const deleteEndpoint = (queueId: ID, query: QueueDeleteQuery = {}) => {
  return {
    endpoint: `/queues/${queueId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
    query,
    querySchema: queueDeleteQuerySchema,
  } as const;
};
