import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { useQueryClient } from '@tanstack/react-query';
import { get } from 'lodash';
import { useCallback } from 'react';
import {
  EmailTemplateDrawerAction,
  EmailTemplateFormModel,
} from '../components/helpers';
import {
  FieldCondition,
  toClientCondition,
  toTriggerApiModel,
  TriggerClientModel,
} from '../mappers/templateMapper';
import { useCreateEmailTemplate } from './useCreateEmailTemplate';
import { useCreateTrigger } from './useCreateTrigger';
import { QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED } from './useEmailTemplatesPerQueueUnpaginated';
import { usePatchEmailTemplate } from './usePatchEmailTemplate';
import { usePatchTrigger } from './usePatchTrigger';
import { QUERY_KEY_TRIGGER } from './useTemplateTrigger';

// TODO: Refactor CRUD hooks to only use 'update' instead of 'create' and 'patch' separately
// to avoid these shenanigans with if-elses
// all this code will then significantly shorten
export const useSaveEmailTemplateAndTrigger = (queueUrl: string) => {
  const queryClient = useQueryClient();

  const {
    mutate: patchEmailTemplate,
    isLoading: isPatchTemplateLoading,
    status: patchEmailTemplateStatus,
  } = usePatchEmailTemplate();

  const {
    mutate: createEmailTemplate,
    isLoading: isCreateTemplateLoading,
    status: createEmailTemplateStatus,
  } = useCreateEmailTemplate();

  const {
    mutate: createTrigger,
    isLoading: isCreateTriggerLoading,
    status: createTriggerStatus,
    error: createTriggerError,
  } = useCreateTrigger();

  const {
    mutate: patchTrigger,
    isLoading: isPatchTriggerLoading,
    status: patchTriggerStatus,
    error: patchTriggerError,
  } = usePatchTrigger();

  const saveTrigger = useCallback(
    (
      emailTemplate: EmailTemplate,
      triggerFormModel: TriggerClientModel,
      onSuccess: () => void
    ) => {
      const triggerApiModel = toTriggerApiModel(triggerFormModel);

      const triggerOptions = {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_TRIGGER],
          });
          onSuccess();
        },
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED],
          });
        },
      };

      if (typeof triggerFormModel.id === 'number') {
        patchTrigger(
          {
            triggerId: triggerFormModel.id,
            triggerPatchModel: {
              queue: emailTemplate.queue,
              condition: triggerApiModel.condition,
              event: triggerApiModel.event,
              emailTemplates: [emailTemplate.url],
            },
          },
          triggerOptions
        );
      } else {
        createTrigger(
          {
            queue: emailTemplate.queue,
            condition: triggerApiModel.condition,
            event: triggerApiModel.event,
            emailTemplates: [emailTemplate.url],
            deleteRecommendations: [],
          },
          triggerOptions
        );
      }
    },
    [createTrigger, patchTrigger, queryClient]
  );

  const onTemplateSaved = useCallback(
    (
      emailTemplate: EmailTemplate,
      triggerFormModel: TriggerClientModel<string> | null,
      onSuccess: () => void
    ) => {
      if (emailTemplate.automate && triggerFormModel) {
        saveTrigger(emailTemplate, triggerFormModel, onSuccess);
      } else {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED],
        });
        onSuccess();
      }
    },
    [queryClient, saveTrigger]
  );

  const save = useCallback(
    (
      action: EmailTemplateDrawerAction,
      formModel: EmailTemplateFormModel,
      onSuccess: () => void
    ) => {
      const { trigger: triggerFormModel, ...emailTemplateFormModel } =
        formModel;

      const payload = {
        ...emailTemplateFormModel,
        to: emailTemplateFormModel.to?.map(email => ({ email })),
        cc: emailTemplateFormModel.cc?.map(email => ({ email })),
        bcc: emailTemplateFormModel.bcc?.map(email => ({ email })),
      };

      if (action.type === 'update') {
        patchEmailTemplate(
          {
            emailTemplateId: action.emailTemplate.id,
            emailTemplatePatchModel: payload,
          },
          {
            onSuccess: emailTemplate =>
              onTemplateSaved(emailTemplate, triggerFormModel, onSuccess),
          }
        );
      } else {
        createEmailTemplate(
          {
            ...payload,
            queue: queueUrl,
            type: action.emailTemplateType,
          },
          {
            onSuccess: emailTemplate =>
              onTemplateSaved(emailTemplate, triggerFormModel, onSuccess),
          }
        );
      }
    },
    [createEmailTemplate, onTemplateSaved, patchEmailTemplate, queueUrl]
  );

  const isLoading =
    isPatchTemplateLoading ||
    isCreateTemplateLoading ||
    isCreateTriggerLoading ||
    isPatchTriggerLoading;
  const isError =
    patchEmailTemplateStatus === 'error' ||
    createEmailTemplateStatus === 'error' ||
    patchTriggerStatus === 'error' ||
    createTriggerStatus === 'error';

  const regexError = (() => {
    // I can't really account for all kind of responses i could get from B/E.
    // So i try to parse a regex error, and silently fail if i can't find it.
    try {
      const triggerError = createTriggerError || patchTriggerError;
      const responseCondition: FieldCondition<string[]> | undefined = get(
        triggerError,
        'data.condition.$and'
      );
      return toClientCondition<string[]>(responseCondition)?.value[0];
    } catch {
      return undefined;
    }
  })();

  return { save, isLoading, isError, regexError };
};
