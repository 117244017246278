import {
  AddRounded,
  DragIndicatorRounded,
  RemoveCircleOutlineRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@rossum/ui/icons';
import {
  buttonClasses,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { DragHandleProps } from '../../../../components/Dnd/SortableWrapper';

type Column = {
  columnType: 'schema' | 'meta';
  visible?: boolean;
  headerName?: string;
  label?: string;
};

type Props = {
  onRemoveColumn?: () => void;
  onToggleVisiblity?: () => void;
  onAddColumn?: () => void;
  column: Column;
  isDraggable?: boolean;
  dragHandleProps?: DragHandleProps;
  isOverlay?: boolean;
};

export const ColumnItem = ({
  onRemoveColumn,
  onAddColumn,
  onToggleVisiblity,
  column,
  isDraggable = false,
  dragHandleProps,
  isOverlay,
}: Props) => {
  const intl = useIntl();
  const { label, visible, headerName } = column;
  const { isDragging, attributes, listeners } = dragHandleProps ?? {};

  return (
    <Stack
      direction="row"
      component={Paper}
      elevation={isOverlay ? 4 : 0}
      sx={{
        px: 1,
        color: 'text.secondary',
        opacity: isDragging ? 0.3 : 1,
        ...(isOverlay ? {} : { boxShadow: 'none' }),
      }}
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      pr={1}
    >
      {isDraggable ? (
        <IconButton
          disableRipple
          {...(listeners ?? {})}
          {...(attributes ?? {})}
          sx={{
            [`&.${buttonClasses.focusVisible}`]: {
              backgroundColor: t => t.palette.background.default,
            },
            color: 'inherit',
            cursor: isOverlay ? 'grabbing' : 'grab',
            outline: 'none',
          }}
        >
          <DragIndicatorRounded />
        </IconButton>
      ) : null}
      {onToggleVisiblity ? (
        <IconButton
          sx={{
            color: visible ? 'success.main' : 'text.secondary',
          }}
          onClick={onToggleVisiblity}
        >
          {visible ? <VisibilityRounded /> : <VisibilityOffRounded />}
        </IconButton>
      ) : null}
      <Stack flexGrow={1}>
        <Typography
          variant="body1"
          sx={{
            color: visible ? 'text.primary' : 'text.secondary',
          }}
        >
          {headerName ??
            intl.formatMessage({
              id: 'containers.allDocuments.columnsPanel.columnNameMissing',
            })}
        </Typography>
        {label ? <Typography variant="body2">{label}</Typography> : null}
      </Stack>
      {onRemoveColumn ? (
        <IconButton
          sx={{
            color: 'text.secondary',
          }}
          onClick={onRemoveColumn}
        >
          <RemoveCircleOutlineRounded />
        </IconButton>
      ) : null}
      {!onRemoveColumn && onAddColumn ? (
        <IconButton
          sx={{
            color: 'text.secondary',
          }}
          onClick={onAddColumn}
        >
          <AddRounded />
        </IconButton>
      ) : null}
    </Stack>
  );
};
