import { Queue } from '@rossum/api-client/queues';
import { Stack } from '@rossum/ui/material';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { camelToSnake } from '../../../lib/keyConvertor';
import { canUserPurgeSelector } from '../../../redux/modules/user/selectors';
import { useStatusTabs } from '../statuses/hooks/useStatusTabs';
import { getMatchingStatusFilter } from '../statuses/utils';
import { DownloadCTA } from './DownloadCTA';
import { ExportCTA } from './ExportCTA';
import { PurgeCTA } from './PurgeCTA';
import { StartReviewingCTA } from './StartReviewingCTA';
import { DocumentListQuery } from './useStartReviewing';
import {
  getExistingStatusFilter,
  normalizeAnnotations,
  NormalizedAnnotations,
  reviewableStatuses,
} from './utils';

type Props = {
  activeQueue: Queue;
  existingFilter: GridFilterModel | null;
  annotations: NormalizedAnnotations;
  documentListQuery: DocumentListQuery;
};

export const CTAButtons = ({
  activeQueue,
  existingFilter,
  annotations,
  documentListQuery,
}: Props) => {
  const { ids: annotationIds, statuses } = normalizeAnnotations(annotations);

  const statusTabs = useStatusTabs(activeQueue);

  const activeStatusTab = getMatchingStatusFilter({
    existingFilter,
    statusTabs,
  });

  const existingStatuses = getExistingStatusFilter(existingFilter) ?? statuses;

  const shouldAllowReview =
    activeStatusTab.key === 'reviews' || activeStatusTab.key === 'postponed';

  const shouldAllowDownload =
    activeStatusTab.key === 'confirmed' || activeStatusTab.key === 'exports';

  const shouldAllowExport =
    activeStatusTab.key === 'confirmed' &&
    activeQueue.settings.hideExportButton !== true;

  const canUserPurge = useSelector(canUserPurgeSelector);

  const shouldAllowPurge = activeStatusTab.key === 'deleted' && canUserPurge;

  // this condition is here because otherwise parent Stack adds spacing to an empty div
  const shouldRender =
    shouldAllowPurge ||
    shouldAllowExport ||
    shouldAllowDownload ||
    shouldAllowReview;

  return shouldRender ? (
    <Stack direction="row" spacing={2}>
      {shouldAllowReview ? (
        <StartReviewingCTA
          queueId={activeQueue.id}
          documentListQuery={documentListQuery}
          existingStatuses={existingStatuses}
          annotationUrls={annotations
            .filter(({ status }) =>
              reviewableStatuses.some(
                reviewableStatus => reviewableStatus === camelToSnake(status)
              )
            )
            .map(({ url }) => url)}
        />
      ) : null}
      {shouldAllowDownload ? (
        <DownloadCTA
          annotationIds={annotationIds}
          isPrimary={!shouldAllowExport}
          queueName={activeQueue.name}
        />
      ) : null}
      {shouldAllowExport ? (
        <ExportCTA queueName={activeQueue.name} annotationIds={annotationIds} />
      ) : null}
      {shouldAllowPurge ? (
        <PurgeCTA queueUrl={activeQueue.url} annotationIds={annotationIds} />
      ) : null}
    </Stack>
  ) : null;
};
