import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Label } from '../models/label';
import { labelSchema } from '../models/label.schema';
import { patchLabelPayloadSchema } from './patch.schema';

export type PatchLabelPayload = { name: string };

export const patch = (labelId: ID, payload: PatchLabelPayload) => {
  return {
    endpoint: `/labels/${labelId}`,
    method: 'PATCH',
    payload,
    responseSchema: labelSchema,
    payloadSchema: patchLabelPayloadSchema,
  } as const;
};
