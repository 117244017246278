import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Survey } from '../models/survey';
import { surveySchema } from '../models/survey.schema';

export const get = (surveyId: ID) => {
  return {
    endpoint: `/surveys/${surveyId}`,
    method: 'GET',
    responseSchema: surveySchema,
  } as const;
};
