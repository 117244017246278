import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Relation, RelationBase } from '../models/relations';
import { relationSchema } from '../models/relations.schema';
import { patchRelationPayloadSchema } from './patch.schema';

export type PatchRelationPayload = Partial<RelationBase>;

export const patch = (relationId: ID, payload: PatchRelationPayload) => {
  return {
    endpoint: `/relations/${relationId}`,
    method: 'PATCH',
    payload,
    responseSchema: relationSchema,
    payloadSchema: patchRelationPayloadSchema,
  } as const;
};
