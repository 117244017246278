import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../types/basic';
import { EmailData } from '../../../types/email';

export const sendEmailFulfilled = createAction(
  'SEND_EMAIL_FULFILLED',
  (payload: EmailData) => payload
)<EmailData>();

export const updateEmail = createAction(
  'UPDATE_EMAIL',
  (email: Partial<EmailData>, _emailId: ID) => email,
  (_, id) => ({ id })
)<Partial<EmailData>, { id: ID }>();

export const updateEmailFulfilled = createAction(
  'UPDATE_EMAIL_FULFILLED',
  (payload: EmailData) => payload
)<EmailData>();

export const rejectEmail = createAction(
  'REJECT_EMAIL',
  (payload: { rootEmailId: number }) => payload
)<{ rootEmailId: number }>();

export type EmailActions = ActionType<
  | typeof sendEmailFulfilled
  | typeof updateEmail
  | typeof updateEmailFulfilled
  | typeof rejectEmail
>;
