import { unknownSchema } from '@rossum/api-client';
import { api } from '../../../../../../lib/apiClient';

// basic atomic types
type BaseRequestParams = {
  endpoint: string;
};

type WithQuery = {
  query: Record<string, unknown>;
};

type WithPayload = {
  payload: Record<string, unknown>;
};

// composed types for HTTP methods
type GetRequestParams = BaseRequestParams & Partial<WithQuery>;
type PostRequestParams = BaseRequestParams &
  Partial<WithQuery> &
  Partial<WithPayload>;
type PatchRequestParams = BaseRequestParams & WithPayload;
type PutRequestParams = BaseRequestParams & WithPayload;
type DeleteRequestParams = BaseRequestParams;

export class ApiRequest {
  get({ query, endpoint }: GetRequestParams) {
    return api.request<unknown, unknown, never>({
      method: 'GET',
      endpoint,
      query,
      querySchema: unknownSchema,
      responseSchema: unknownSchema,
    });
  }

  post({ query, endpoint, payload }: PostRequestParams) {
    return api.request<unknown, unknown, unknown>({
      method: 'POST',
      endpoint,
      payload,
      payloadSchema: unknownSchema,
      query,
      querySchema: unknownSchema,
      responseSchema: unknownSchema,
    });
  }

  patch({ endpoint, payload }: PatchRequestParams) {
    return api.request<unknown, never, unknown>({
      method: 'PATCH',
      endpoint,
      payload,
      payloadSchema: unknownSchema,
      responseSchema: unknownSchema,
    });
  }

  put({ endpoint, payload }: PutRequestParams) {
    return api.request<unknown, never, unknown>({
      method: 'PUT',
      endpoint,
      payload,
      payloadSchema: unknownSchema,
      responseSchema: unknownSchema,
    });
  }

  delete({ endpoint }: DeleteRequestParams) {
    return api.request<unknown, never, never>({
      method: 'DELETE',
      endpoint,
      responseSchema: unknownSchema,
    });
  }
}
