import { AvatarGroup } from '@rossum/ui/material';
import { Key } from 'react';
import UserAvatar, { User } from './UserAvatar';

type MaybeUser = (User & { id: Key }) | undefined;

type UserAvatarGroupProps = {
  users: Array<MaybeUser>;
};

const UserAvatarGroup = ({ users }: UserAvatarGroupProps) => (
  <AvatarGroup sx={{ justifyContent: 'start' }} total={users.length}>
    {users
      .map((user, index) => (
        <UserAvatar key={user ? user.id : `u${index}`} user={user} />
      ))
      .slice(0, 3)}
  </AvatarGroup>
);

export default UserAvatarGroup;
