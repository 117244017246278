// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const documentsDownloadsResponseSchema = z.object({
  url: urlSchema,
});

export const documentsDownloadsQuerySchema = z.object({
  id: z.array(z.number()),
  fileName: z.string().optional().nullable(),
});

export const documentsDownloadsTypeSchema = z.union([
  z.literal('source_document'),
  z.literal('document'),
]);

export const documentsDownloadsPayloadSchema = z.union([
  z.object({
    fileName: z.string(),
    documents: z.array(urlSchema),
    type: documentsDownloadsTypeSchema.optional(),
  }),
  z.object({
    documents: z.tuple([urlSchema]),
    zip: z.literal(false),
    type: documentsDownloadsTypeSchema.optional(),
  }),
]);
