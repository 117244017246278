import { ElisRequestConfig } from '../../utils/requestConfig';
import { FunctionConfig } from '../models/hookConfig';
import { testPayloadSchema, testResponseSchema } from './test.schema';

export type TestPayload = {
  config: FunctionConfig;
  payload?: unknown;
};

// TODO: This will get better once we have assurance about messages type (prob. string)
// and domain models for operations and datapoints
export type TestResponse = {
  log: string;
  response: // TODO: This should be struct once guaranteed by API
  Partial<{
    messages: unknown[];
    operations: unknown[];
    updatedDatapoints: unknown[];
    suggestedOperations: unknown[];
  }>;
  stacktrace?: string;
};

// TODO: Decide on naming of the endpoint folders - singular vs. plural
export const test = (hookId: number, payload: TestPayload) => {
  return {
    endpoint: `/hooks/${hookId}/test`,
    method: 'POST',
    payloadSchema: testPayloadSchema,
    payload,
    responseSchema: testResponseSchema,
  } as const;
};
