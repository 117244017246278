import { FunctionRuntime } from '@rossum/api-client/hooks';
import { ImageNotSupported } from '@rossum/ui/icons';
import {
  Box,
  darken,
  lighten,
  Paper,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import { getIcon } from '../../../containers/Extensions/lib/helpers';
import { ExtensionType } from '../../../types/extensions';

type Props = {
  type: ExtensionType;
  runtime: FunctionRuntime | undefined;
  handleClick: () => void;
  name: string;
  description: string;
  imageUrl: string | null;
  dataCy?: string;
};

const IMAGE_PROPORTIONS = [1185, 409];

const loadImage = (image: HTMLImageElement) => {
  const dataSrc = image.getAttribute('data-src');

  if (dataSrc) {
    image.setAttribute('src', dataSrc);
    // eslint-disable-next-line no-param-reassign
    image.onload = () => {
      image.removeAttribute('data-src');
    };
  }
};

const Tile = ({
  type,
  runtime,
  imageUrl,
  handleClick,
  name,
  description,
  dataCy,
}: Props) => {
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      loadImage(imageRef.current);
    }
  }, []);

  return (
    <Stack
      onClick={() => handleClick()}
      data-cy={dataCy}
      component={Paper}
      spacing={2}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        transition: t => [
          t.transitions.create('background-color', { duration: 150 }),
        ],
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: t =>
            t.palette.mode === 'dark'
              ? lighten(t.palette.background.paper, 0.08)
              : darken(t.palette.background.paper, 0.08),
        },
      }}
    >
      {imageUrl && !isError ? (
        <Box
          component="img"
          ref={imageRef}
          src=""
          data-src={imageUrl}
          onError={() => setIsError(true)}
          alt={name}
          width={IMAGE_PROPORTIONS[0]}
          height={IMAGE_PROPORTIONS[1]}
          sx={{
            maxWidth: '100%',
            height: 'auto',
            mb: 20,
          }}
        />
      ) : (
        <Stack sx={{ backgroundColor: 'background.paper' }}>
          <ImageNotSupported
            sx={{
              width: 80,
              height: 80,
              alignSelf: 'center',
            }}
          />
        </Stack>
      )}
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          gap={1}
          sx={{ svg: { color: t => t.palette.text.secondary } }}
        >
          {getIcon(type, runtime, { size: 20 }, { title: '' })}{' '}
          <Typography variant="body2" fontWeight={700}>
            {name}
          </Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Tile;
