import { endpoints } from '@rossum/api-client';
import { CreateWorkspacePayload } from '@rossum/api-client/workspaces';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const useCreateWorkspace = () => {
  return useMutation({
    mutationFn: (workspace: CreateWorkspacePayload) =>
      api.request(endpoints.workspaces.create(workspace)),
  });
};
