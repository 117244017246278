import { useSelector } from 'react-redux';
import { poweredByAuroraSelector } from '../../redux/modules/organization/selectors';
import AuroraChip, { AuroraChipProps } from '../../ui/aurora/AuroraChip';

const ConditionalAuroraChip = (props: AuroraChipProps) => {
  const auroraPromoVisible = useSelector(poweredByAuroraSelector);

  if (!auroraPromoVisible) {
    return null;
  }

  return <AuroraChip {...props} />;
};

export default ConditionalAuroraChip;
