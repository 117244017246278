export const outOfTheBoxDocumentTypes = [
  { id: 'tax_invoice', tag: 'popular' },
  { id: 'purchase_order', tag: 'none' },
  { id: 'delivery_note', tag: 'none' },
  { id: 'credit_note', tag: 'none' },
  { id: 'debit_note', tag: 'none' },
  { id: 'pro_forma', tag: 'none' },
  { id: 'custom_document_type', tag: 'none' },
] as const;

const outOfTheBoxDocumentTypeIds = outOfTheBoxDocumentTypes.map(({ id }) => id);

export type OutOfTheBoxDocumentType = (typeof outOfTheBoxDocumentTypes)[number];

export const customTrainingDocumentTypesGroups = [
  { id: 'bill_of_lading', tag: 'none' },
  { id: 'boleto_voucher', tag: 'none' },
  { id: 'certificate_of_analysis', tag: 'none' },
  { id: 'delivery_order', tag: 'none' },
  { id: 'eob_form', tag: 'none' },
  { id: 'fapiao', tag: 'none' },
  { id: 'guarantee', tag: 'none' },
  { id: 'material_test_report', tag: 'none' },
  { id: 'packing_list', tag: 'none' },
  { id: 'quotation', tag: 'none' },
  { id: 'receipt', tag: 'none' },
  { id: 'remittance_advice', tag: 'none' },
  { id: 'rent_roll', tag: 'none' },
  { id: 'sales_order', tag: 'none' },
  { id: 'shipping_instruction', tag: 'none' },
  { id: 'shipping_label', tag: 'none' },
  { id: 'shipping_order', tag: 'none' },
  { id: 'truck_hauling_ticket', tag: 'none' },
  { id: 'utility_bill', tag: 'none' },
  { id: 'waybill', tag: 'none' },
  { id: 'weight_ticket', tag: 'none' },
] as const;

type CustomDocumentType = (typeof customTrainingDocumentTypesGroups)[number];

export type DocumentType = CustomDocumentType | OutOfTheBoxDocumentType;

export const isOutOfTheBoxDocType = (
  docTypeId: DocumentType['id']
): docTypeId is OutOfTheBoxDocumentType['id'] =>
  outOfTheBoxDocumentTypeIds.includes(
    docTypeId as OutOfTheBoxDocumentType['id']
  );
