import { useDispatch } from 'react-redux';
import { useDeleteUser } from '../../../../business/users/useDeleteUser';
import { signOut } from '../../../../redux/modules/auth/actions';
import { throwInfo } from '../../../../redux/modules/messages/actions';
import { OrganizationUser, User } from '../../../../types/user';
import { getUserName } from '../../../Extensions/components/UsersDropdown';
import DeleteUserDialogWithCheckboxes from './DeleteUserDialogWithCheckboxes';
import DeleteUserDialogWithoutCheckboxes from './DeleteUserDialogWithoutCheckboxes';
import {
  canRemoveUser,
  isModeWithCheckboxes,
  useDeleteUserMode,
} from './helpers';

type Props = {
  onDelete?: () => void;
  onCancel: () => void;
  user: OrganizationUser | User;
};

const DeleteUserDialog = ({ onDelete, onCancel, user }: Props) => {
  const mode = useDeleteUserMode(user);

  const { mutate: deleteUser } = useDeleteUser();

  const dispatch = useDispatch();

  if (mode === 'loading') {
    return null;
  }

  return isModeWithCheckboxes(mode) ? (
    <DeleteUserDialogWithCheckboxes
      onDelete={() =>
        deleteUser({
          userId: user.id,
          onSuccess: () => {
            onDelete?.();
            dispatch(signOut({ handled: true }));
            dispatch(
              throwInfo(
                mode === 'delete_organization'
                  ? 'organizationDeleted'
                  : 'userDeleted',
                undefined,
                { user: getUserName(user) }
              )
            );
          },
        })
      }
      onCancel={onCancel}
      user={user}
      deletingOrganization={mode === 'delete_organization'}
    />
  ) : (
    <DeleteUserDialogWithoutCheckboxes
      onDelete={() =>
        deleteUser({
          userId: user.id,
          meta: { role: 'role' in user ? user.role : undefined },
          onSuccess: () => {
            onDelete?.();
            dispatch(
              throwInfo('userDeleted', undefined, { user: getUserName(user) })
            );
          },
        })
      }
      onCancel={onCancel}
      user={user}
      {...canRemoveUser(mode)}
    />
  );
};

export default DeleteUserDialog;
