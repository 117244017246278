import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteEndpoint = (id: ID) => {
  return {
    endpoint: `/labels/${id}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
