import { endpoints } from '@rossum/api-client';
import { ScorePasswordPayload } from '@rossum/api-client/authentication';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';

const QUERY_KEY_PASSWORD_VALIDATION = 'password-validation';

export const usePasswordValidation = (payload: ScorePasswordPayload) => {
  const dispatch = useDispatch();
  const { refetch, data } = useQuery({
    // payload is removed from query because then the password is stored in cache
    queryKey: [QUERY_KEY_PASSWORD_VALIDATION] as const,

    queryFn: () =>
      api.request(endpoints.authentication.password.score(payload)),
    onError: () => {
      dispatch(throwError('validatePassword'));
    },
    enabled: false,
    cacheTime: 0,
  });
  return { refetch, data };
};
