import { ArrowRightAlt } from '@rossum/ui/icons';
import { Box, ListItem, Stack, Typography } from '@rossum/ui/material';
import { FieldValues } from 'react-hook-form';
import FormTypeahead from '../../../../../../../../../components/ReactHookForm/FormTypeahead';
import { CompositeControlProps } from '../../../../../../../../../components/ReactHookForm/utils';

export type FieldMappingControlShape = {
  queue: string;
  schemaId: string;
};

type FieldMappingControlProps<TFieldValues extends FieldValues = FieldValues> =
  CompositeControlProps<TFieldValues, FieldMappingControlShape> & {
    queueName: string;
    schemaFieldOptions: { id: string; label: string }[];
  };

const FieldMappingControl = <TFieldValues extends FieldValues = FieldValues>({
  ControllerProps: { control, nameMap },
  disabled,
  queueName,
  schemaFieldOptions,
}: FieldMappingControlProps<TFieldValues>) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      flexGrow={1}
      width="100%"
      divider={
        <Box sx={{ pt: 0.5 }}>
          <ArrowRightAlt color="secondary" />
        </Box>
      }
    >
      <Box
        display="flex"
        // 28px is half the width of Divider including margins
        // I wanted to use Stack with a divider for the icon instead of
        // Grid - even 1 column would be too wide for the icon and minimum would be 2
        // anyway
        flex="0 0 calc(50% - 28px)"
        sx={{ wordWrap: 'break-word' }}
      >
        <Typography
          variant="subtitle2"
          variantMapping={{ subtitle2: 'span' }}
          sx={{ wordBreak: 'break-word' }}
        >
          {queueName}
        </Typography>
      </Box>
      <Box display="flex" flex="0 0 calc(50% - 28px)">
        {/* TODO: Add label to FormTypeahead when refactoring to new API */}
        <FormTypeahead
          disablePortal
          control={control}
          name={nameMap.schemaId}
          options={schemaFieldOptions}
          getOptionValue={option => option.id}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.label
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disableClearable
          fullWidth
          disabled={disabled}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              {option.label}
            </ListItem>
          )}
          size="small"
        />
      </Box>
    </Stack>
  );
};

export default FieldMappingControl;
