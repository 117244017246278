import { Stack } from '@rossum/ui/material';
import createDOMPurify from 'dompurify';
import { sanitizeLinks } from '../../../lib/htmlSanitization';
import { Email } from '../api-client';

const DOMPurify = createDOMPurify();

// TODO: use MUI colors once email drawer has defined grayscale
// TODO: Rearchitect this so the colors don't have to be set as constants (no access to MUI theme here)
// this is color-picked and will need to be updated any time background color of the card changes
const bgColor = '#2c2b32';
const brownGray = '#979797';
const textSecondary = 'rgba(255, 255, 255, 0.7)';

// Light variants of the email template are defined on the BE side:
// https://gitlab.rossum.cloud/elis/elis-backend/-/blob/develop/elis_backend/api/templates/send_email_body.html
const lightVariantWrapperStyle =
  'padding: 25px; background-color: #f6f5f9; color: black; font-family: Roboto, sans-serif; line-height: 1.54;';

// to make panel background match e-mail card background
const updatedLightVariantWrapperStyle =
  'padding: 25px; background-color: #fff; color: black; font-family: Roboto, sans-serif; line-height: 1.54;';

const lightVariantMessageStyle =
  'background-color: white; padding: 40px; border-radius: 10px; border: solid 1px #ebeaeb;';

const darkVariantWrapperStyle = `padding: 25px; background-color: ${bgColor}; color: white; font-family: Roboto, sans-serif; line-height: 1.54;`;

const darkVariantMessageStyle = `background-color: ${bgColor}; color: ${textSecondary}; padding: 40px; border-radius: 10px; border: solid 1px ${brownGray};`;

// If the user uses dark theme, convert BE email template style attributes to dark variant
DOMPurify.addHook('afterSanitizeAttributes', (node, _) => {
  sanitizeLinks(node);
  const {
    user: {
      uiSettings: { theme },
    },
  } = window.store.getState();

  if (theme === 'dark' || !theme) {
    if (
      node.getAttribute('style')?.match(lightVariantWrapperStyle) ||
      node.id === 'email-body'
    ) {
      node.setAttribute('style', darkVariantWrapperStyle);
    }
    if (node.getAttribute('style')?.match(lightVariantMessageStyle)) {
      node.setAttribute('style', darkVariantMessageStyle);
    }
    if (
      node.getAttribute('alt')?.match('Powered by Rossum') &&
      node.getAttribute('src')?.includes('powered_by_rossum.png')
    ) {
      const srcUrl = node.getAttribute('src');
      const srcUrlDarkVariant = srcUrl?.replace(
        'powered_by_rossum',
        'powered_by_rossum_dark_variant'
      );

      if (srcUrlDarkVariant) {
        node.setAttribute('src', srcUrlDarkVariant);
      }
    }
  } else if (
    node.getAttribute('style')?.match(lightVariantWrapperStyle) ||
    node.id === 'email-body'
  ) {
    node.setAttribute('style', updatedLightVariantWrapperStyle);
  }
});

export const EmailBody = ({
  email,
  showHtml,
}: {
  email: Email;
  showHtml: boolean;
}) =>
  showHtml ? (
    <Stack
      sx={theme => ({
        color: theme.palette.text.secondary,
        '& p': {
          margin: 0,
        },
      })}
      {...(email.bodyTextHtml
        ? {
            dangerouslySetInnerHTML: {
              __html: DOMPurify.sanitize(email.bodyTextHtml),
            },
          }
        : {})}
    />
  ) : (
    <Stack component="span" sx={{ color: 'text.secondary' }}>
      {email.bodyTextPlain}
    </Stack>
  );
