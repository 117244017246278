import { Message } from '@rossum/api-client/shared';
import { ErrorRounded, InfoRounded } from '@rossum/ui/icons';

const icons = {
  error: ErrorRounded,
  warning: ErrorRounded,
  info: InfoRounded,
};

type Props = {
  type: Message['type'];
  active?: boolean;
  isSimpleMultivalue?: boolean;
};

const Icon = ({ type, active, isSimpleMultivalue }: Props) => {
  const Icon = icons[type];

  return (
    <Icon
      fontSize="small"
      color={active && !isSimpleMultivalue ? 'inherit' : type}
      data-cy={type === 'error' ? 'error-icon' : ''}
    />
  );
};

export default Icon;
