// Generated by ts-to-zod
import { z } from 'zod';

export const inboxPatchPayloadSchema = z.object({
  filters: z
    .object({
      document_rejection_conditions: z.object({
        mime_types: z.array(z.string()),
      }),
    })
    .optional(),
});
