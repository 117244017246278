import { Stack, ToggleButton, ToggleButtonGroup } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Email } from '../api-client';
import AuthorSummary from './AuthorSummary';
import { EmailBody } from './EmailBody';

type Props = {
  email: Email;
};

export const EmailContent = ({ email }: Props) => {
  const intl = useIntl();
  const hasHtml = !!email.bodyTextHtml?.length;

  const [contentType, setContentType] = useState<'plainText' | 'html'>(
    hasHtml ? 'html' : 'plainText'
  );

  const hasBothTypes = hasHtml && !!email.bodyTextPlain?.length;

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center">
        <AuthorSummary email={email} />
        {hasBothTypes && (
          <ToggleButtonGroup
            color="secondary"
            value={contentType}
            onChange={(_, value) => setContentType(value)}
            size="small"
            sx={{
              ml: 'auto',
              color: 'text.secondary',
            }}
            exclusive
          >
            <ToggleButton value="html" sx={{ color: 'inherit' }}>
              {intl.formatMessage({
                id: 'containers.emailDrawer.emailContent.htmlType',
              })}
            </ToggleButton>
            <ToggleButton value="plainText" sx={{ color: 'inherit' }}>
              {intl.formatMessage({
                id: 'containers.emailDrawer.emailContent.plainTextType',
              })}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Stack>
      <EmailBody email={email} showHtml={contentType === 'html'} />
    </Stack>
  );
};
