import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Redirect, RouteProps } from 'react-router';
import Loader from '../Loader';
import styles from './styles.module.sass';

const OrganizationLoader = (props: RouteProps) => {
  const orgName = get(props.location?.state, 'organizationName');
  return orgName ? (
    <div className={styles.OrganizationLoader}>
      <div className={styles.Loader}>
        <Loader />
      </div>
      <span className={styles.LoadingTitle}>
        <FormattedMessage
          id="containers.organizationLoader.title"
          values={{
            organizationName: orgName,
          }}
        />
      </span>
    </div>
  ) : (
    <Redirect to="/annotations" />
  );
};

export default OrganizationLoader;
