import { Document } from '@rossum/api-client/documents';
import { FolderZip, SubdirectoryArrowRight } from '@rossum/ui/icons';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@rossum/ui/material';
import { useOpenDocument } from './hooks/useOpenDocument';

type Props = {
  document: Document;
  isChild: boolean;
};

export const DocumentInfo = ({ document, isChild }: Props) => {
  const { mutate: loadDocument } = useOpenDocument();
  return (
    <ListItemButton
      onClick={() => document.content && loadDocument(document.content)}
      disabled={!document.content}
      disableGutters
      sx={{ py: 0, px: 2, borderRadius: 1 }}
    >
      {isChild && (
        <SubdirectoryArrowRight
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      {document.attachmentStatus === 'extracted_archive' && (
        <ListItemIcon sx={{ minWidth: 24 }} data-cy="document-zip">
          <FolderZip
            sx={{
              width: 20,
              height: 20,
              color: theme => theme.palette.text.secondary,
            }}
          />
        </ListItemIcon>
      )}
      <ListItemText sx={{ py: 0.5 }}>{document.originalFileName}</ListItemText>
    </ListItemButton>
  );
};
