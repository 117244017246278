import * as z from 'zod';
import { ElisRequestConfig } from '../../../utils/requestConfig';
import { confirmPasswordPayloadSchema } from './confirm.schema';

export type ConfirmPasswordPayload = {
  token: string;
  uid: string;
  username: string;
  new_password1: string;
  new_password2: string;
};

export const confirmPassword = (
  url: string,
  payload: ConfirmPasswordPayload
) => {
  return {
    authorize: false,
    endpoint: url,
    method: 'POST',
    payload,
    payloadSchema: confirmPasswordPayloadSchema,
    responseSchema: z.unknown(),
  } as const;
};
