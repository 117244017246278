import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { OrganizationGroupQueue } from '../models/organizationGroupQueue';
import { organizationGroupQueueSchema } from '../models/organizationGroupQueue.schema';
import { organizationGroupQueueListQuerySchema } from './queues.schema';

export type OrganizationGroupQueueListQuery = {
  organization?: ID;
} & PaginationQuery;

export const queues = (
  // TODO: organizationGroupId needs to be mandatory
  baseQuery: OrganizationGroupQueueListQuery & { organizationGroupId?: ID }
) => {
  const { organizationGroupId, ...query } = baseQuery ?? {};
  // Hack for unpaginated query, where all props have to be optional.
  // eslint-disable-next-line no-console
  if (!organizationGroupId) console.warn('No organization group id');

  return {
    endpoint: `/organization_groups/${organizationGroupId}/queues`,
    method: 'GET',
    responseSchema: listResponse(organizationGroupQueueSchema),
    query,
    querySchema: organizationGroupQueueListQuerySchema,
  } as const;
};
