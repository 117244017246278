// Generated by ts-to-zod
import { z } from 'zod';

export const processingDurationSchema = z.object({
  timeSpentActive: z.number(),
  timeSpentOverall: z.number(),
  timeSpentEdit: z.number(),
  timeSpentEmails: z.number(),
  timeSpentBlockers: z.number(),
  timeSpentOpening: z.number(),
});
