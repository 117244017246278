import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { genericEngineSchema } from '../models/genericEngine.schema';
import { genericEngineGetQuerySchema } from './get.schema';

export type GenericEngineGetQuery = Partial<{
  fields: ('id' | 'name' | 'url' | 'schema' | 'description')[];
}>;

export const get = (engineId: ID, query: GenericEngineGetQuery = {}) => {
  return {
    endpoint: `/generic_engines/${engineId}`,
    method: 'GET',
    responseSchema: genericEngineSchema,
    query,
    querySchema: genericEngineGetQuerySchema,
  } as const;
};
