import { assertNever } from '../../../../lib/typeUtils';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SectionDatapointDataST,
  SimpleDatapointDataST,
  TupleDatapointDataST,
} from '../../../../types/datapoints';
import { findDatapointIndex } from './findDatapointIndex';

const resolveMultivalueDatapointPath = (
  datapoints: Array<AnyDatapointDataST>,
  data: MultivalueDatapointDataST
): [SectionDatapointDataST, MultivalueDatapointDataST] | null => {
  if (!data.meta?.parentId) {
    return null;
  }

  const parentIndex = findDatapointIndex(datapoints, data.meta.parentId);
  const parent = datapoints[parentIndex];

  if (parent?.category === 'section') {
    return [parent, data];
  }

  return null;
};

export const resolveTupleDatapointPath = (
  datapoints: Array<AnyDatapointDataST>,
  data: TupleDatapointDataST
):
  | [SectionDatapointDataST, MultivalueDatapointDataST, TupleDatapointDataST]
  | null => {
  if (!data.meta.parentId) {
    return null;
  }
  const parentIndex = findDatapointIndex(datapoints, data.meta.parentId);

  const parent = datapoints[parentIndex];

  if (parent?.category === 'multivalue') {
    const parentPath = resolveMultivalueDatapointPath(datapoints, parent);

    if (!parentPath) {
      return null;
    }

    return [...parentPath, data];
  }

  return null;
};

export const resolveSimpleDatapointPath = (
  datapoints: Array<AnyDatapointDataST>,
  data: SimpleDatapointDataST
):
  | [SectionDatapointDataST, SimpleDatapointDataST]
  | [SectionDatapointDataST, MultivalueDatapointDataST, SimpleDatapointDataST]
  | [
      SectionDatapointDataST,
      MultivalueDatapointDataST,
      TupleDatapointDataST,
      SimpleDatapointDataST,
    ]
  | null => {
  if (!data.meta.parentId) {
    return null;
  }
  const parentIndex = findDatapointIndex(datapoints, data.meta.parentId);
  const parent = datapoints[parentIndex];

  if (parent?.category === 'section') {
    return [parent, data];
  }

  if (parent?.category === 'multivalue') {
    const p = resolveMultivalueDatapointPath(datapoints, parent);

    if (!p) {
      return null;
    }

    return [...p, data];
  }

  if (parent?.category === 'tuple') {
    const p = resolveTupleDatapointPath(datapoints, parent);

    if (!p) {
      return null;
    }

    return [...p, data];
  }

  return null;
};

export const resolveAnyDatapointPath = (
  datapoints: Array<AnyDatapointDataST>,
  data: AnyDatapointDataST | null | undefined
) => {
  if (!data) return null;

  switch (data.category) {
    case 'section':
      return [data] as [SectionDatapointDataST];
    case 'multivalue':
      return resolveMultivalueDatapointPath(datapoints, data);
    case 'tuple':
      return resolveTupleDatapointPath(datapoints, data);
    case 'datapoint':
      return resolveSimpleDatapointPath(datapoints, data);
    default:
      /* istanbul ignore next */
      return assertNever(data);
  }
};

export type ResolvedDatapointPath = ReturnType<typeof resolveAnyDatapointPath>;
