import { MouseEventHandler, useEffect, useRef } from 'react';
import { Point2D } from '../../features/annotation-view/document-canvas/utils/geometry';
import { useRectangleCreator } from '../../features/annotation-view/document-canvas-svg/rectangle-creator/useRectangleCreator';
import { Vector } from '../../lib/spaceConvertor';
import { usePageSpaceContext } from '../DocumentPage/PageSpaceContext';

// TODO: Tweak API
type UseDraggableInteractionParams = {
  onDragStart: MouseEventHandler;
  onDrag: (diff: Vector) => void;
  onDragEnd: () => void;
};

export const useDraggableInteraction = ({
  onDragStart,
  onDrag,
  onDragEnd,
}: UseDraggableInteractionParams) => {
  const { pointViewportToSvg } = usePageSpaceContext();

  const { handleMouseDown, startPoint, dragPoint } = useRectangleCreator({
    onRectangleCreated: () => {
      startPointRef.current = undefined;
      onDragEnd();
    },
  });
  const startPointRef = useRef<Point2D | undefined>(undefined);

  useEffect(() => {
    const startPointP = startPointRef.current;
    if (startPointP && dragPoint) {
      const dragPointP = pointViewportToSvg(dragPoint);
      onDrag([dragPointP.x - startPointP.x, dragPointP.y - startPointP.y]);
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragPoint, pointViewportToSvg]);

  const handleMouseDownOverride: MouseEventHandler = e => {
    onDragStart(e);
    startPointRef.current = pointViewportToSvg(handleMouseDown(e));
  };

  return {
    handleMouseDown: handleMouseDownOverride,
    isDragging: startPoint !== undefined,
  };
};
