import { Stack, Typography } from '@rossum/ui/material';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledToggle from '../../../../../components/ReactHookForm/Toggle';
import { UnpaidFeatureOverlay } from '../../../../../features/pricing/components/UnpaidFeatureOverlay';
import PaperSection from '../../../../../ui/paper-section/PaperSection';
import styles from '../../../styles.module.sass';
import { QueueSettingsData } from './formType';
import QueueSettingsLabel from './QueueSettingsLabel';

type DocumentArchiveProps = {
  control: Control<QueueSettingsData>;
  disabled: boolean;
};

const DocumentArchive = ({ disabled, control }: DocumentArchiveProps) => {
  const intl = useIntl();

  return (
    <PaperSection
      title={intl.formatMessage({
        id: 'containers.settings.queues.titles.documentArchive',
      })}
      sx={{ position: 'relative' }}
    >
      <div className={styles.Row}>
        <div>
          <QueueSettingsLabel titleId="containers.settings.queues.documentArchive.label" />
        </div>
        <div className={styles.ToggleField}>
          <ControlledToggle<QueueSettingsData>
            control={control}
            name="archiveEnabled"
            disabled={disabled}
          />
        </div>
      </div>

      <Stack pt={1} width={600}>
        <Typography variant="body2" color="text.secondary">
          {intl.formatMessage({
            id: 'containers.settings.queues.documentArchive.description',
          })}
        </Typography>
      </Stack>
      {disabled && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: t => t.zIndex.tooltip,
          }}
        >
          <UnpaidFeatureOverlay
            title={intl.formatMessage({
              id: 'features.pricing.unpaidFeatureOverlay.title.documentArchive',
            })}
            dataCy="unpaid-overlay-queue-settings-document-archive"
          />
        </Stack>
      )}
    </PaperSection>
  );
};

export default DocumentArchive;
