import { forwardRef, MouseEventHandler } from 'react';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox, BoundingBoxProps } from './BoundingBox';

type SuggestedOperationBoxProps = {
  operationId: number;
  position: Rectangle2DCoordinates;
  active: boolean;
  onClick: MouseEventHandler<SVGRectElement>;
  onMoveEnd: (operationId: number, position: Rectangle2DCoordinates) => void;
  onResizeEnd: (operationId: number, position: Rectangle2DCoordinates) => void;
  boundingBoxProps?: Partial<BoundingBoxProps>;
};

export const SuggestedOperationBox = forwardRef<
  SVGRectElement,
  SuggestedOperationBoxProps
>(
  (
    {
      operationId,
      position,
      boundingBoxProps,
      active,
      onClick,
      onMoveEnd,
      onResizeEnd,
    },
    ref
  ) => {
    const setHoveredBbox = useDocumentStore(state => state.setHoveredBbox);

    return (
      <BoundingBox
        ref={ref}
        position={position}
        canResize={active}
        interactive
        onClick={onClick}
        onResizeEnd={position => onResizeEnd(operationId, position)}
        onMoveEnd={position => onMoveEnd(operationId, position)}
        onMouseOver={() => setHoveredBbox(operationId)}
        onMouseOut={() => setHoveredBbox(undefined)}
        {...boundingBoxProps}
      />
    );
  }
);
