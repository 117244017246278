import { downloads } from './downloads';
import { get } from './get';
import { getBlob } from './getBlob';
import { list } from './list';
import { patch } from './patch';
export {
  type DocumentsDownloadsPayload,
  type DocumentsDownloadsType,
} from './downloads';

export const documents = {
  get,
  list,
  patch,
  getBlob,
  downloads,
};
