import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { userSchema } from '../models';
import { User } from '../models/user';
import { usersListQuerySchema } from './list.schema';

export type UsersListQuery = {
  id?: number | number[];
  organization?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isActive?: boolean;
  lastLogin?: string | null;
  groups?: number[];
  queues?: string[];
  deleted?: boolean;
  search?: string;
} & PaginationQuery;

export const list = (query: UsersListQuery = {}) => {
  return {
    endpoint: `/users`,
    method: 'GET',
    responseSchema: listResponse(userSchema),
    query,
    querySchema: usersListQuerySchema,
  } as const;
};
