import React, { useCallback, useEffect, useState } from 'react';
import { usePageSpaceContext } from '../../../../components/DocumentPage/PageSpaceContext';
import { Point2D } from '../utils/geometry';

type UseDraggableInteractionParams = {
  onDragStart?: (e: MouseEvent) => void;
  onDragMove?: (diff: Point2D, e: MouseEvent) => void;
  onDragEnd?: (e: MouseEvent) => void;
  onDragCancel?: () => void;
};

/**
 * Creates a draggable interaction in any component
 * Needs to be used inside `PageSpaceContext` which provides coordinate transforms
 */
/** @deprecated This should be rewritten using some more general solution that doesn't use page space context. */
export const useDraggableInteraction = ({
  onDragStart,
  onDragMove,
  onDragEnd,
  onDragCancel,
}: UseDraggableInteractionParams) => {
  // TODO: Is there a better option that would behave well with scrolling while dragging?
  const [origin, setOrigin] = useState<Point2D | null>(null);

  const { pointViewportToSvg } = usePageSpaceContext();

  // On mouse down, set origin point in web coordinates
  const handleMouseDown: React.MouseEventHandler<HTMLElement | SVGElement> =
    useCallback(
      e => {
        e.stopPropagation();
        onDragStart?.(e.nativeEvent);
        setOrigin(pointViewportToSvg({ x: e.clientX, y: e.clientY }));
      },
      [onDragStart, pointViewportToSvg]
    );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (origin) {
        const eventPointInSVG = pointViewportToSvg({
          x: e.clientX,
          y: e.clientY,
        });

        const diff = {
          x: eventPointInSVG.x - origin.x,
          y: eventPointInSVG.y - origin.y,
        };

        onDragMove?.(diff, e);
      }
    },
    [onDragMove, origin, pointViewportToSvg]
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      if (origin) {
        onDragEnd?.(e);
        setOrigin(null);
      }
    },
    [onDragEnd, origin]
  );

  const handleEscCancel = useCallback(
    (e: KeyboardEvent) => {
      if (origin && e.key === 'Escape') {
        e.stopPropagation();
        onDragCancel?.();
        setOrigin(null);
      }
    },
    [onDragCancel, origin]
  );

  const handleVisibilityCancel = useCallback(() => {
    if (origin && document.visibilityState === 'hidden') {
      onDragCancel?.();
      setOrigin(null);
    }
  }, [onDragCancel, origin]);

  // When origin point is set, mount mouse move and mouse up listeners to document
  useEffect(() => {
    if (origin) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('keydown', handleEscCancel, { capture: true });
      document.addEventListener('visibilitychange', handleVisibilityCancel, {
        capture: true,
      });
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleEscCancel, {
        capture: true,
      });
      document.removeEventListener('visibilitychange', handleVisibilityCancel);
    };
  }, [
    handleEscCancel,
    handleMouseMove,
    handleMouseUp,
    handleVisibilityCancel,
    origin,
  ]);

  return { handleMouseDown, isDragging: !!origin };
};
