import { getMembershipToken } from './getMembershipToken';
import { login } from './login';
import { changePassword } from './password/change';
import { confirmPassword } from './password/confirm';
import { resetPassword } from './password/reset';
import { scorePassword } from './password/score';
import { patch } from './patch';
import { token } from './token';
import { user } from './user';
export { type LoginPayload } from './login';
export { type ChangePasswordPayload } from './password/change';
export {
  type ScorePasswordPayload,
  type ScorePasswordResponse,
} from './password/score';

export const authentication = {
  login,
  token,
  user,
  patch,
  getMembershipToken,
  password: {
    change: changePassword,
    confirm: confirmPassword,
    reset: resetPassword,
    score: scorePassword,
  },
};
