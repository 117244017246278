import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../lib/apiClient';

const GET_SCHEMA_QUERY_KEY = 'get-schema';

type Props = {
  schemaId?: number;
};
export const useGetSchema = ({ schemaId }: Props) => {
  return useQuery({
    queryKey: [GET_SCHEMA_QUERY_KEY, schemaId],
    queryFn: () => {
      if (!schemaId) throw new Error('Schema id is required');
      return api.request(endpoints.schemas.get(schemaId));
    },
    enabled: Boolean(schemaId),
  });
};
