// Generated by ts-to-zod
import { z } from 'zod';
import { triggerUrlSchema } from '../../triggers/models/trigger.schema';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const deleteRecommendationSchema = z.object({
  id: idSchema,
  enabled: z.boolean(),
  url: urlSchema,
  organization: urlSchema,
  queue: urlSchema,
  triggers: z.array(triggerUrlSchema),
});
