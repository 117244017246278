import { isSchemaItem, Schema } from '@rossum/api-client/schemas';
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import * as R from 'remeda';
import { editItemInSchema } from '../../../../queue-settings/fields/form-model/transformations/toSchema';
import { getSchemaObjectPaths } from '../../../../queue-settings/fields/helpers';
import { flattenSchemaAndAddQueues } from '../../../hooks/useMemoFlatSchemasWithQueues';
import { Queue } from '../../../types/queue';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { aggregationRow } from '../../constants';
import { UpdatedSchemasMap } from '../getProcessRowUpdate';
import { transformSchemasToRows } from '../rows/rows';
import { GridRowModel } from '../rows/rowTypes';

type UseCopyPasteFieldSettings = {
  flatSchemasWithQueues: FlatSchemaWithQueues[];
  queues: Queue[];
  schemas: Schema[];
  updatedSchemas: UpdatedSchemasMap;
  setUpdatedSchemas: React.Dispatch<React.SetStateAction<UpdatedSchemasMap>>;
  setRows: React.Dispatch<React.SetStateAction<GridRowModel[]>>;
};
export const useCopyPasteFieldSettings = ({
  flatSchemasWithQueues,
  queues,
  schemas,
  updatedSchemas,
  setUpdatedSchemas,
  setRows,
}: UseCopyPasteFieldSettings) => {
  const [copiedRow, setCopiedRow] = useState<GridRowModel | null>(null);

  const pasteFieldConfigurationToRows = useCallback(
    (rows: GridRowModel[]) => {
      if (copiedRow) {
        if (copiedRow.meta.schema_id === aggregationRow) {
          return;
        }

        const alreadyInUpdated = updatedSchemas[copiedRow.meta.schema_id];

        const originalSchema = flatSchemasWithQueues.find(
          schema => schema.id === copiedRow.meta.schema_id
        );

        const schemaToUpdate = alreadyInUpdated ?? originalSchema;

        if (!schemaToUpdate || !schemaToUpdate.content) {
          return;
        }

        // TODO: Would be nice to make it typesafe but I am ok with returning undefined
        // and checking it with the typeguard afterwards
        // Get schema item which will be duplicated
        const fieldConfigToBePasted = R.pathOr(
          schemaToUpdate.content,
          // @ts-expect-error
          copiedRow.meta.path,
          undefined
        );

        if (!fieldConfigToBePasted || !isSchemaItem(fieldConfigToBePasted)) {
          return;
        }

        rows.forEach(oldRow => {
          if (oldRow.meta.schema_id === aggregationRow) {
            return;
          }

          const targetSchema = schemas.find(
            schema => schema.id === oldRow.meta.schema_id
          );

          if (!targetSchema) {
            return;
          }

          const targetSchemaObjectPaths = getSchemaObjectPaths(targetSchema);

          const itemPath = targetSchemaObjectPaths[oldRow.id];

          if (!itemPath) {
            return;
          }

          const updatedSchema = editItemInSchema(
            targetSchema,
            itemPath,
            fieldConfigToBePasted
          );

          const flattenSchemaWithQueues = flattenSchemaAndAddQueues(
            updatedSchema,
            queues
          );

          const updatedRow = transformSchemasToRows(oldRow.id, [
            flattenSchemaWithQueues,
          ])()?.[0];

          const copiedFieldIsFormula = 'formula' in copiedRow;

          const copiedFieldIsEnum = copiedRow.meta.type === 'enum';

          if (updatedRow) {
            setRows(prevRows => {
              const newRows = prevRows.map(row =>
                row.meta.schema_id === updatedRow.meta.schema_id
                  ? copiedFieldIsFormula
                    ? update(updatedRow, {
                        meta: {
                          metadata: {
                            formulaHashMap: {
                              // We need to keep previous hashmap so that the variants are correct even without updating hashmaps for all rows
                              $set: copiedRow.meta.metadata.formulaHashMap,
                            },
                          },
                        },
                      })
                    : copiedFieldIsEnum
                      ? update(updatedRow, {
                          meta: {
                            metadata: {
                              optionsHashLabelMap: {
                                // We need to keep previous hashmap so that the variants are correct even without updating hashmaps for all rows
                                $set: copiedRow.meta.metadata
                                  .optionsHashLabelMap,
                              },
                            },
                          },
                        })
                      : updatedRow
                  : row
              );
              return newRows;
            });
          }

          setUpdatedSchemas(prevState => {
            return prevState[updatedSchema.id]
              ? update(prevState, {
                  [updatedSchema.id]: { $set: flattenSchemaWithQueues },
                })
              : {
                  ...prevState,
                  [updatedSchema.id]: flattenSchemaWithQueues,
                };
          });
        });
      }
    },
    [
      copiedRow,
      flatSchemasWithQueues,
      queues,
      schemas,
      setRows,
      setUpdatedSchemas,
      updatedSchemas,
    ]
  );

  return {
    copiedRow,
    setCopiedRow,
    pasteFieldConfigurationToRows,
  };
};
