import { ElisRequestConfig } from '../../utils/requestConfig';
import { Label } from '../models/label';
import { labelSchema } from '../models/label.schema';
import { createLabelPayloadSchema } from './create.schema';

export type CreateLabelPayload = {
  name: string;
};

export const create = (payload: CreateLabelPayload) => {
  return {
    endpoint: `/labels`,
    method: 'POST',
    payload,
    responseSchema: labelSchema,
    payloadSchema: createLabelPayloadSchema,
  } as const;
};
