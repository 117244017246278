import { createSelector } from 'reselect';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { AnyDatapointDataST } from '../../../types/datapoints';
import { AnyDatapointSchema } from '../../../types/schema';
import { State } from '../../../types/state';
import { findDatapointById } from '../datapoints/navigation/findDatapointIndex';
import {
  datapointPathSelector,
  datapointsSelector,
  schemaSelector,
} from '../datapoints/selector';
import { schemaMapSelector } from './schemaMapSelector';

const footerExpandedSelector = (state: State) => state.ui.footerExpanded;

export const schemaLoadedSelector = (state: State) =>
  state.schema.id !== undefined;

export const originalSchemaSelector = (state: State) =>
  state.schema.originalContent ?? [];
export const schemaErrorsSelector = (state: State) =>
  state.schema.validationErrors;
export const validSchemaSelector = (state: State) => state.schema.validSchema;

export const getUnhiddenColumnSchemas = createSelector(
  datapointsSelector,
  schemaSelector,
  datapointPathSelector,
  (datapoints, schema, [, tableDatapointId]): AnyDatapointSchema[] => {
    if (!schema) return [];

    const datapoint = findDatapointById(datapoints, tableDatapointId);
    const rowSchemaId = datapoint?.schema?.children?.[0];
    const rowSchema = schema.find(item => item.id === rowSchemaId);

    return schema.filter(
      item => !item.hidden && rowSchema?.children?.includes(item.id)
    );
  }
);

const getColumnSchemasFor = (
  datapointSchema: AnyDatapointSchema,
  schema: AnyDatapointSchema[]
): AnyDatapointSchema[] | null => {
  if (datapointSchema.category !== 'multivalue') return null;

  const maybeRowSchemaId = datapointSchema.children?.[0];

  if (!maybeRowSchemaId) return null;

  const rowSchema = schema.find(s => s.id === maybeRowSchemaId);

  // Filter out simple multivalues
  if (!rowSchema || rowSchema.category !== 'tuple') return null;

  return (rowSchema.children ?? []).flatMap(
    id => schema.find(s => s.id === id) ?? []
  );
};

export const columnsForMultivaluesSelector = createSelector(
  schemaSelector,
  (
    schema: AnyDatapointSchema[] | undefined
  ): Record<string, AnyDatapointSchema[]> => {
    if (!schema) return {};

    return Object.fromEntries(
      schema
        .map(s => {
          const columns = getColumnSchemasFor(s, schema);
          if (!columns) return null;

          return [s.id, columns] as const;
        })
        .filter(isNotNullOrUndefined)
    );
  }
);

export const getFooterSchema = createSelector(
  footerExpandedSelector,
  getUnhiddenColumnSchemas,
  (footerExpanded, unhiddenColumnSchemas) => {
    const columns = unhiddenColumnSchemas.filter(
      ({ canCollapse }) => footerExpanded || !canCollapse
    );

    return {
      columns,
    };
  }
);

export const timeSpentEnabledSelector = createSelector(
  schemaMapSelector,
  schemaMap => (d: AnyDatapointDataST) => {
    const schema = schemaMap.get(d.schemaId);
    return schema
      ? d.category === 'multivalue' ||
          (d.category === 'datapoint' && schema.type !== 'button')
      : false;
  }
);
