import { Workspace } from '@rossum/api-client/workspaces';
import { DeleteForever } from '@rossum/ui/icons';
import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useDeleteWorkspace } from '../../../../business/workspaces/useDeleteWorkspace';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import { boldText } from '../../../../lib/formaterValues';

type Props = {
  workspace: Workspace;
  onDelete: () => void;
  onCancel: () => void;
};

export const WorkspaceDelete = ({ workspace, onDelete, onCancel }: Props) => {
  const { mutate: deleteWorkspace, isLoading } = useDeleteWorkspace(onDelete);
  const intl = useIntl();

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'components.modal.deleteWorkspace.title',
      })}
      onCancel={() => onCancel()}
      dialogColor="error"
      DialogTitleIcon={DeleteForever}
      dialogActions={
        <ActionDialogActions
          confirmButtonProps={{
            onClick: () => deleteWorkspace(workspace.id),
            disabled: isLoading,
          }}
          confirmButtonText={intl.formatMessage({
            id: 'components.modal.deleteWorkspace.button.delete',
          })}
          dataCyConfirm="delete-workspace-confirm-btn-footer"
          dataCyCancel="delete-workspace-cancel-btn-footer"
        />
      }
      dataCy="delete-workspace-cancel-btn"
    >
      <Typography variant="body1">
        {intl.formatMessage(
          {
            id: 'components.modal.deleteWorkspace.text',
          },
          {
            boldText,
            workspace: workspace.name,
          }
        )}
      </Typography>
    </ActionDialog>
  );
};
