import { MagicLine } from './MagicLine';
import { useMagicLine } from './useMagicLine';

type DatapointMagicLineProps = {
  pageNumber: number;
  position: [number, number, number, number];
  sourceElementId: string;
};

export const DatapointMagicLine = ({
  pageNumber,
  position,
  sourceElementId,
}: DatapointMagicLineProps) => {
  const magicLineProps = useMagicLine({
    pageNumber,
    position,
    sourceElementId,
  });
  return magicLineProps ? <MagicLine {...magicLineProps} /> : null;
};
