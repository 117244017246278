// Generated by ts-to-zod
import { z } from 'zod';

export const queueGetQuerySchema = z
  .object({
    format: z.string(),
    columns: z.string(),
    id: z.string(),
  })
  .partial();
