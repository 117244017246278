import { endpoints } from '@rossum/api-client';
import { ChangePasswordPayload } from '@rossum/api-client/authentication';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { setAuthToken } from '../../lib/token';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';

export const useChangePassword = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: ({
      username,
      ...payload
    }: ChangePasswordPayload & { username: string }) =>
      api
        .request(endpoints.authentication.password.change(payload), {
          convertKeys: 'none',
        })
        .then(() =>
          api.request(
            endpoints.authentication.login({
              username,
              password: payload.new_password1,
              origin: 'rossum_ui',
            })
          )
        )
        .then(({ key }) => setAuthToken(key)),

    onSuccess: async () => dispatch(throwInfo('passwordUpdated')),

    // TODO: Correct types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (e: any) => {
      if (
        'code' in e &&
        e.code === 400 &&
        'endpoint' in e &&
        e.endpoint.includes('auth/password/change')
      ) {
        return dispatch(throwError('passwordChangeFailed'));
      }
      if (
        'code' in e &&
        e.code === 400 &&
        'endpoint' in e &&
        e.endpoint.includes('auth/login')
      ) {
        return dispatch(throwError('silentLoginFailed'));
      }
      return dispatch(throwError('validationError'));
    },
  });
};
