import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteEndpoint = (triggerId: ID) => {
  return {
    endpoint: `/triggers/${triggerId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
