import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Schema } from '../models/schema';
import { schemaSchema } from '../models/schema.schema';
import { patchSchemaPayloadSchema } from './patch.schema';

export type PatchSchemaPayload = Partial<Schema>;

export const patch = (schemaId: ID, payload: PatchSchemaPayload) => {
  return {
    endpoint: `/schemas/${schemaId}`,
    method: 'PATCH',
    responseSchema: schemaSchema,
    payloadSchema: patchSchemaPayloadSchema,
    payload,
  } as const;
};
