// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { paginationResponseSchema } from '../../utils/pagination.schema';

export const billingStatsValuesSchema = z.object({
  billablePages: z.number(),
  nonBillablePages: z.number(),
  billableDocuments: z.number(),
  nonBillableDocuments: z.number(),
});

export const billingStatsSchema = z.object({
  organization: urlSchema.nullable(),
  queue: urlSchema.nullable(),
  beginDate: z.string(),
  endDate: z.string(),
  values: billingStatsValuesSchema,
});

export const billingStatsListResponseSchema = z.object({
  pagination: paginationResponseSchema,
  results: z.array(billingStatsSchema),
  updatedAt: z.string(),
  totals: billingStatsValuesSchema,
});
