import { some } from 'lodash';
import React, { ReactNode, useContext, useMemo } from 'react';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, share, throttleTime } from 'rxjs/operators';
import { Point2D } from '../../features/annotation-view/document-canvas/utils/geometry';
import { getEventPath } from '../../lib/DOM';

export type ObservableContextValue = {
  onKeyDownObserver: Observable<KeyboardEvent>;
  onKeyUpObserver: Observable<KeyboardEvent>;
  onDocumentMouseUpObserver: Observable<MouseEvent>;
  mousePositionOnDocumentObserver: Observable<Point2D>;
  onDocumentMouseMoveObserver: Observable<MouseEvent>;
  isDocumentHoveredObserver: Observable<boolean>;
};

export const ObservableContext = React.createContext<
  ObservableContextValue | undefined
>(undefined);

const ObservableProvider = ({ children }: { children: ReactNode }) => {
  const contextValue = useMemo(
    () => ({
      onKeyDownObserver: fromEvent<KeyboardEvent>(window, 'keydown'),

      onKeyUpObserver: fromEvent<KeyboardEvent>(window, 'keyup'),

      onDocumentMouseUpObserver: fromEvent<MouseEvent>(
        document,
        'mouseup'
      ).pipe(share()),

      onDocumentMouseMoveObserver: fromEvent<MouseEvent>(
        document,
        'mousemove'
      ).pipe(throttleTime(16), share()),

      mousePositionOnDocumentObserver: fromEvent<MouseEvent>(
        document,
        'mousemove'
      ).pipe(
        map(e => ({ x: e.clientX, y: e.clientY })),
        distinctUntilChanged(),
        share()
      ),

      isDocumentHoveredObserver: fromEvent<MouseEvent>(
        document,
        'mousemove'
      ).pipe(
        map(event => {
          const eventPath = getEventPath(event);

          return (
            some(
              eventPath,
              element => 'id' in element && element.id === 'document'
            ) &&
            !some(eventPath, element =>
              ['controlBar', 'documentToolBar', 'suggestionBbox'].includes(
                'id' in element && typeof element.id === 'string'
                  ? element.id
                  : ''
              )
            )
          );
        }),
        distinctUntilChanged(),
        share()
      ),
    }),
    []
  );

  return (
    <ObservableContext.Provider value={contextValue}>
      {children}
    </ObservableContext.Provider>
  );
};

export const useObservableContext = () => {
  const ctx = useContext(ObservableContext);

  if (ctx === undefined) {
    throw new Error(
      '`useObservableContext` can only be used within an `ObservableProvider`!'
    );
  }

  return ctx;
};

export default ObservableProvider;
