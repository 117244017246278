import { BillingStatsValues } from '@rossum/api-client/billing';
import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@rossum/ui/icons';
import { MenuItem, Stack, useTheme } from '@rossum/ui/material';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import GraphsContainer from '../../../../components/Graphs/components/GraphContainer';
import StackedBarGraphContent from '../../../../components/Graphs/components/StackedBarContent';
import { grayLight } from '../../../../components/Graphs/constants';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import {
  Aggregate,
  AggregateData,
} from '../../../../containers/Statistics/containers/Aggregates';
import { LocalizationKeys } from '../../../../i18n';
import { formatNumberToLocale } from '../../../../lib/helpers';
import { dateFormatByGranularity } from '../../../../lib/timeUtils';
import { getTemporaryDataForChart, getValuesByUnit } from '../../helpers';
import { BillingEntity } from '../../hooks/useBillingEntity';
import {
  PageStatisticsGranularity,
  usePageStatisticsTimeline,
} from '../../hooks/usePageStatisticsTimeline';
import LoadingOverlay from '../LoadingOverlay';
import { StatisticsFilterFormState } from '../StatisticsFilter';
import EmptyChart from './EmptyChart';

const granularityOptions: Array<{
  value: PageStatisticsGranularity;
  labelId: LocalizationKeys;
}> = [
  {
    value: 'month',
    labelId: 'containers.statistics.granularities.month',
  },
  {
    value: 'week',
    labelId: 'containers.statistics.granularities.week',
  },
];

type GranularityFormState = {
  granularity: PageStatisticsGranularity;
};

type BillingBarChartProps = {
  billingEntity: BillingEntity;
  filters: StatisticsFilterFormState;
};

const BillingBarChart = ({ billingEntity, filters }: BillingBarChartProps) => {
  const intl = useIntl();
  const theme = useTheme();

  const { control } = useForm<GranularityFormState>({
    defaultValues: { granularity: 'week' },
  });

  const granularity = useWatch({ control, name: 'granularity' });
  const { data, isLoading } = usePageStatisticsTimeline(
    billingEntity,
    omit(filters, ['unit']),
    granularity
  );

  const isTmpState = isLoading && !data;
  const temporaryGraphData = useMemo(() => getTemporaryDataForChart(), []);

  const getAggregateData = (
    totals: Partial<BillingStatsValues>
  ): AggregateData => {
    const { billable, nonBillable } = getValuesByUnit(totals, filters.unit);
    return [
      {
        value: formatNumberToLocale(billable, intl.locale),
        icon: (
          <InsertDriveFileOutlinedIcon
            sx={{
              fill: theme => (isTmpState ? undefined : theme.palette.info.main),
            }}
          />
        ),
        description: 'components.graphs.billing.series.billable',
      },
      {
        value: formatNumberToLocale(nonBillable, intl.locale),
        icon: <InsertDriveFileOutlinedIcon />,
        description: 'components.graphs.billing.series.nonBillable',
      },
    ];
  };

  const getBars = (isTemporary: boolean) => [
    {
      name: 'billable' as const,
      color: isTemporary
        ? theme.palette.text.disabled
        : theme.palette.info.main,
    },
    {
      name: 'nonBillable' as const,
      color: grayLight,
    },
  ];

  return (
    <GraphsContainer customWidth="Full">
      {isTmpState && <LoadingOverlay color="info" />}
      {isLoading || data?.results.length ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Aggregate
              withTopMargin={false}
              data={getAggregateData((data ?? temporaryGraphData).totals)}
            />
            <TextFieldControl
              ControllerProps={{ control, name: 'granularity' }}
              select
            >
              {granularityOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {intl.formatMessage({ id: option.labelId })}
                </MenuItem>
              ))}
            </TextFieldControl>
          </Stack>
          <StackedBarGraphContent
            bars={getBars(isTmpState)}
            formatLabel={s =>
              intl.formatMessage({
                id: `components.graphs.billing.series.${s}`,
              })
            }
            data={(data ?? temporaryGraphData).results.map(
              ({ beginDate, endDate, values }) => {
                const { billable, nonBillable } = getValuesByUnit(
                  values,
                  filters.unit
                );
                return {
                  billable,
                  nonBillable,
                  date: dateFormatByGranularity(
                    intl.locale,
                    granularity,
                    beginDate,
                    endDate
                  ),
                };
              }
            )}
          />
        </>
      ) : (
        <EmptyChart
          title="containers.billing.emptyGraph"
          text="containers.billing.emptyDescription"
        />
      )}
    </GraphsContainer>
  );
};

export default BillingBarChart;
