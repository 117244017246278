import UiFieldTypeMessage from '../../components/Datapoint/components/UiFieldTypeMessage';
import { uiFieldTypeIconsMap } from '../../redux/modules/schema/helpers';
import { UiFieldType } from '../../types/schema';

type Props = {
  readOnly: boolean;
  uiFieldType: UiFieldType | undefined;
  isEditable: boolean;
};

const FooterHeaderLabelIcon = ({
  uiFieldType,
  isEditable,
  readOnly,
}: Props) => {
  const Icon = uiFieldType ? uiFieldTypeIconsMap[uiFieldType] : null;

  if (!Icon) {
    return null;
  }

  return (
    <span>
      <UiFieldTypeMessage
        uiFieldType={uiFieldType}
        readOnly={readOnly}
        fieldEditable={isEditable}
      >
        <Icon fontSize="small" />
      </UiFieldTypeMessage>
    </span>
  );
};

export default FooterHeaderLabelIcon;
