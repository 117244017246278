import { ElisRequestConfig } from '../../utils/requestConfig';
import { AnnotationStart, annotationStartSchema } from '../models';
import { cancelAnnotationPayloadSchema } from './cancel.schema';
import { startAnnotationPayloadSchema } from './start.schema';
import { z } from 'zod';

export type CancelAnnotationPayload = {
  proccessingDuration?: unknown;
};

export const cancel = (
  annotationId: number,
  payload: CancelAnnotationPayload = {}
) => {
  return {
    endpoint: `/annotations/${annotationId}/cancel`,
    method: 'POST',
    payloadSchema: cancelAnnotationPayloadSchema,
    payload,
    responseSchema: z.unknown(),
  } as const;
};
