import { Check, ContentCopy } from '@rossum/ui/icons';
import { Grow, Stack, Typography } from '@rossum/ui/material';
import { SwitchTransition } from 'react-transition-group';
import { useCopyToClipboard } from '../../../ui/copy-to-clipboard/useCopyToClipboard';

export const EmailAddress = ({ inboxAddress }: { inboxAddress: string }) => {
  const { copied, copyToClipboard } = useCopyToClipboard(inboxAddress);

  return (
    <Stack direction="row">
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        sx={{
          cursor: 'pointer',
          color: 'text.secondary',
          '&:hover': { color: 'text.primary' },
        }}
        onClick={copyToClipboard}
        data-cy="copy-email-address"
      >
        <Stack direction="row" sx={{ minWidth: 0 }}>
          <Typography
            variant="caption"
            color="inherit"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 1,
            }}
          >
            {inboxAddress}
          </Typography>
        </Stack>
        <SwitchTransition>
          <Grow
            key={copied ? 'copied' : 'copy'}
            appear
            unmountOnExit
            timeout={100}
          >
            {copied ? (
              <Check color="success" fontSize="small" />
            ) : (
              <ContentCopy fontSize="small" color="inherit" />
            )}
          </Grow>
        </SwitchTransition>
      </Stack>
    </Stack>
  );
};
