import clsx from 'clsx';
import LoadingIcon from 'mdi-react/LoadingIcon';
import styles from './styles.module.sass';

type Props = {
  className?: string;
  hidden?: boolean;
};

const LoadingIndicator = ({ className, hidden }: Props) => (
  <div
    className={clsx(
      styles.LoadingIndicator,
      hidden && styles.LoadingIndicatorHidden,
      className
    )}
  >
    <LoadingIcon />
  </div>
);

export default LoadingIndicator;
