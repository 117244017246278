import { Queue } from '@rossum/api-client/queues';
import { ExpandMore } from '@rossum/ui/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { random } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PageLayoutV2 } from '../../components/PageLayoutV2/PageLayoutV2';
import { useUnpaginatedDedicatedEngines } from '../../features/legacy-engines/hooks/useUnpaginatedDedicatedEngines';
import { useUnpaginatedGenericEngines } from '../../features/legacy-engines/hooks/useUnpaginatedGenericEngines';
import { useWorkspacesWithQueues } from '../../features/queues/hooks/useWorkspacesWithQueues';
import { filterWorkspaces } from '../../redux/modules/workspaces/helpers';
import { AutomationHeader } from './AutomationHeader';
import { NoWorkspacesPlaceholder } from './NoWorkspacesPlaceholder';

const Queues = () => {
  const { workspacesWithQueues, isLoading } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const [searchValue, setSearchValue] = useState('');

  const intl = useIntl();

  const workspaces = useMemo(() => {
    if (searchValue)
      return filterWorkspaces(searchValue, workspacesWithQueues ?? []);

    return workspacesWithQueues;
  }, [searchValue, workspacesWithQueues]);

  const [expandedWorkspaces, setExpandedWorkspaces] = useState<number[]>([]);

  const { data: genericEngines } = useUnpaginatedGenericEngines();
  const { data: dedicatedEngines } = useUnpaginatedDedicatedEngines();

  const engineUrlMap = useMemo(() => {
    const allEngines = [...(genericEngines ?? []), ...(dedicatedEngines ?? [])];
    return new Map(allEngines.map(obj => [obj.url, obj]));
  }, [dedicatedEngines, genericEngines]);

  const findEngineByQueue = (queue: Queue) => {
    const engineUrl = queue.dedicatedEngine ?? queue.genericEngine;
    return engineUrl ? engineUrlMap.get(engineUrl) : undefined;
  };

  useEffect(() => {
    if (searchValue !== '') {
      setExpandedWorkspaces(workspaces?.map(ws => ws.id) ?? []);
    } else setExpandedWorkspaces([]);
  }, [searchValue, workspaces]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <AutomationHeader
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          {...params}
        />
      )}
    >
      <Stack spacing={3} data-page-title="automation" p={4} flex={1}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'containers.settings.automation.queues',
          })}
        </Typography>
        {!isLoading && workspaces ? (
          workspaces.length !== 0 ? (
            <Stack spacing={1} data-cy="automation-workspace-row">
              {workspaces?.map(workspace => {
                const isExpanded = expandedWorkspaces.some(
                  id => id === workspace.id
                );
                return (
                  <Accordion
                    key={workspace.id}
                    sx={{
                      '&:before': { display: 'none' },
                    }}
                    expanded={isExpanded}
                    onChange={(_, isOpen) => {
                      if (isOpen) {
                        setExpandedWorkspaces(prev => [...prev, workspace.id]);
                      } else
                        setExpandedWorkspaces(prev =>
                          prev.filter(id => id !== workspace.id)
                        );
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2">
                          {workspace.name}
                        </Typography>
                        <Chip size="small" label={workspace.queues.length} />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction="row" px={2} py={1}>
                        <Stack flex="0 0 40%" minWidth={0}>
                          <Typography variant="body2" color="text.disabled">
                            {intl.formatMessage({
                              id: 'containers.settings.automation.queueColumnName',
                            })}
                          </Typography>
                        </Stack>
                        <Stack flex="0 0 40%" minWidth={0}>
                          <Typography variant="body2" color="text.disabled">
                            {intl.formatMessage({
                              id: 'containers.settings.automation.engineColumnName',
                            })}
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                        </Stack>
                        <Stack flex="0 0 20%" minWidth={0}>
                          <Typography variant="body2" color="text.disabled">
                            {intl.formatMessage({
                              id: 'containers.settings.automation.automationLevelColumnName',
                            })}
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                        </Stack>
                      </Stack>
                      <Divider />
                      {workspace.queues.map(
                        (queue, queueIndex, { length: queuesLength }) => (
                          <Stack
                            key={queue.id}
                            sx={{
                              '&:hover': {
                                backgroundColor: t => t.palette.action.hover,
                              },
                            }}
                          >
                            <Stack
                              component={Link}
                              to={{
                                pathname: `/queues/${queue.id}/settings/automation`,
                                state: { backLink: '/automation' },
                              }}
                              sx={{
                                textDecoration: 'none',
                                color: 'text.primary',
                                '&:hover': {
                                  textDecoration: 'none',
                                  color: 'text.primary',
                                },
                              }}
                              direction="row"
                              data-cy="queue"
                              px={2}
                              py={1}
                              my={1}
                            >
                              <Stack flex="0 0 40%" minWidth={0}>
                                <Typography
                                  variant="body2"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  {queue.name}
                                </Typography>
                              </Stack>
                              <Stack flex="0 0 40%" minWidth={0}>
                                <Typography
                                  variant="body2"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  {findEngineByQueue(queue)?.name ?? '-'}
                                </Typography>
                              </Stack>
                              <Stack flex="0 0 20%" minWidth={0}>
                                <Typography
                                  variant="body2"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  {intl.formatMessage({
                                    id: `containers.settings.automation.automationLevel.${queue.automationLevel}`,
                                  })}
                                </Typography>
                              </Stack>
                            </Stack>
                            {queueIndex !== queuesLength - 1 ? (
                              <Divider />
                            ) : null}
                          </Stack>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          ) : (
            <NoWorkspacesPlaceholder
              description={intl.formatMessage({
                id:
                  searchValue === ''
                    ? 'containers.settings.queues.noWorkspaces.text'
                    : 'containers.users.access.noFilteredQueuesFound.text',
              })}
            />
          )
        ) : (
          <LoadingSkeletons />
        )}
      </Stack>
    </PageLayoutV2>
  );
};

export default Queues;

const skeletons = Array.from({ length: 12 }, () => ({
  key: uuidv4(),
  width: random(100, 150),
}));

const DEFAULT_ACCORDION_HEIGHT = 48;

const LoadingSkeletons = () => (
  <Stack spacing={1}>
    {skeletons.map(({ key, width }) => (
      <Stack
        key={key}
        component={Paper}
        elevation={0}
        height={DEFAULT_ACCORDION_HEIGHT}
        // spacing here matches default accordion values
        py={1.5}
        px={2}
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Skeleton variant="rounded" width={width} />
        <Chip
          // variant="outlined"
          color="default"
          size="small"
          label={<Skeleton width={10} />}
        />
      </Stack>
    ))}
  </Stack>
);
