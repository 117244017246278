import { endpoints } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';

export const useConfirmAnnotation = () => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { annotationId: number }) => {
      return apiClient.request(
        endpoints.annotations.confirm(payload.annotationId)
      );
    },
    onSettled: (_1, _2, { annotationId }) => {
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'annotation',
        annotationId,
      ]);
      queryClient.invalidateQueries([
        'mini-validation-screen',
        'content',
        annotationId,
      ]);
    },
  });
};
