import { SxProps, Theme } from '@rossum/ui/material';
import { GridFilterModel } from '@rossum/ui/x-data-grid-pro';
import { isEqual, sortBy } from 'lodash';
import {
  defaultStatusTabs,
  StatusTabArray,
  StatusTabKeys,
  statusTabsColumnMap,
  statusTabSortOrder,
} from './tabs';

export const getMatchingStatusFilter = ({
  existingFilter,
  statusTabs,
}: {
  existingFilter: GridFilterModel | null;
  statusTabs: StatusTabArray;
}) => {
  const existingStatusSettings = existingFilter?.items.filter(
    item => item.field === 'status'
  );

  const matchingStatusFilter = statusTabs.find(statusTab => {
    // we use sortBy to handle incorrect of order of status values
    const statusTabFilter: typeof statusTab.filter = {
      ...statusTab.filter,
      value: sortBy(statusTab.filter.value),
    };
    const existingFilter = existingStatusSettings?.map(filter => ({
      ...filter,
      value: sortBy(filter.value),
    }));
    return isEqual([statusTabFilter], existingFilter);
  });

  return matchingStatusFilter ?? defaultStatusTabs.all_statuses;
};

export const sortStatusTabs = <T extends { key: StatusTabKeys }>(
  statusTabs: T[]
): T[] =>
  statusTabs
    .map(s => s)
    .sort(
      (a, b) =>
        statusTabSortOrder.indexOf(a.key) - statusTabSortOrder.indexOf(b.key)
    );

export const makeSureActiveTabIsVisible = ({
  visibleTabs,
  hiddenTabs,
  activeTabKey,
}: {
  visibleTabs: StatusTabArray;
  hiddenTabs: StatusTabArray;
  activeTabKey: StatusTabKeys;
}) => {
  const hiddenActiveTab = hiddenTabs.find(tab => tab.key === activeTabKey);
  const lastVisibleTab = visibleTabs[visibleTabs.length - 1];
  if (hiddenActiveTab && lastVisibleTab) {
    return {
      visibleTabs: sortStatusTabs(
        visibleTabs.slice(0, visibleTabs.length - 1).concat(hiddenActiveTab)
      ),
      hiddenTabs: sortStatusTabs(
        [lastVisibleTab, ...hiddenTabs].flatMap(tab =>
          tab.key === activeTabKey ? [] : tab
        )
      ),
    };
  }
  return { visibleTabs, hiddenTabs };
};

type StatusTabsColumn = keyof typeof statusTabsColumnMap;

export const isStatusTabColumn = (value: string): value is StatusTabsColumn =>
  Object.keys(statusTabsColumnMap).includes(value);

export const getTabAnimation = ({
  startAnimation,
  delayIndex,
}: {
  startAnimation: boolean;
  delayIndex: number;
}): SxProps<Theme> => {
  return {
    transform: `translateY(${startAnimation ? 0 : 100}%) scale(${
      startAnimation ? 1 : 0.1 // Do not fully collapse the tab to prevent an issue with MUI
    })`,
    transition: ({ transitions: { create, duration, easing } }) =>
      create(['all'], {
        duration: duration.standard,
        easing: easing.easeInOut,
        delay: delayIndex * 20,
      }),
  };
};
