import { CircularProgress, Stack } from '@rossum/ui/material';
import { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import { useLogin } from '../../business/auth/useLogin';
import { useSignInWithAuthCodeFromUrl } from '../../business/auth/useSignInWithAuthCodeFromUrl';
import Container, { PADDING_TOP } from '../../components/UI/Container';
import { HEADER_HEIGHT } from './components/AnimatedHeader';
import Form from './components/Form';
import Options from './components/Options';
import { LoginAnimationContext } from './LoginAnimationContext';

const Login = () => {
  const intl = useIntl();

  const { isSigningIn } = useSignInWithAuthCodeFromUrl();
  const { setLoginUnmounted, setAnimationState, setTitle } = useContext(
    LoginAnimationContext
  );

  const loginPhase = intl.formatMessage({
    id: 'containers.login.welcome',
  });

  const { mutate: login, isLoading, error } = useLogin();

  useEffect(() => {
    if (error) {
      setAnimationState('ready');
    }
  }, [error, setAnimationState]);

  useEffect(() => {
    if (isLoading) {
      setAnimationState('loading');
    }
  }, [isLoading, setAnimationState]);

  useEffect(() => {
    setTitle(loginPhase);
  }, [loginPhase, setTitle]);

  useEffect(() => {
    setLoginUnmounted(false);
    setAnimationState('ready');

    return () => {
      setLoginUnmounted(true);
    };
  }, [setAnimationState, setLoginUnmounted]);

  return (
    <Container
      dataPageTitle="login"
      customTopOffset={PADDING_TOP + HEADER_HEIGHT}
    >
      <Helmet
        meta={[
          {
            name: 'description',
            content:
              'Log into your Rossum account here. Rossum creates a world free of manual data entry by doing the tedious work for you, so you can focus on other tasks.',
          },
        ]}
      />
      {isSigningIn ? (
        <CircularProgress />
      ) : (
        <Stack spacing={4} alignItems="center" sx={{ width: '100%', pt: 5 }}>
          <Form login={login} />
          <Options />
        </Stack>
      )}
    </Container>
  );
};

export default Login;
