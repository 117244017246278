import { PaginationQuery } from '../../utils/listQuery';
import { paginationQuerySchema } from '../../utils/listQuery.schema';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Label } from '../models/label';
import { labelSchema } from '../models/label.schema';

export const list = (query: PaginationQuery) => {
  return {
    endpoint: `/labels`,
    method: 'GET',
    responseSchema: listResponse(labelSchema),
    query,
    querySchema: paginationQuerySchema,
  } as const;
};
