/* eslint-disable @typescript-eslint/no-explicit-any */
import { UniqueIdentifier } from '@dnd-kit/core';
import {
  isSchemaTableMultiValue,
  Schema,
  SchemaDatapoint,
  SchemaItem,
  SchemaSection,
} from '@rossum/api-client/schemas';
import * as R from 'remeda';
import { assertNever } from '../../../../../lib/typeUtils';
import { FieldsFormModel } from '../formModels';

export type SchemaAction =
  | {
      op: 'add';
      parentId: string | null;
      formModel: FieldsFormModel;
    }
  | {
      op: 'edit';
      id: string;
      formModel: FieldsFormModel;
    }
  | {
      op: 'delete';
      id: string;
    }
  | {
      op: 'move';
      from: readonly [UniqueIdentifier, number];
      to: readonly [UniqueIdentifier, number];
    }
  | {
      op: 'quick-action';
      parentId: UniqueIdentifier;
      items: Record<
        UniqueIdentifier,
        { prop: 'hidden' | 'canExport' | 'required'; value: boolean }
      >;
    };

export type SchemaActionHandler = (action: SchemaAction) => void;

const isObjectPath = (path: unknown): path is Array<unknown> =>
  Array.isArray(path);

export type SchemaSectionsPath = [];

export const isSchemaSectionsPath = (
  path: unknown
): path is SchemaSectionsPath => isObjectPath(path) && path.length === 0;

export type SchemaSectionPath = [number];

export const isSchemaSectionPath = (path: unknown): path is SchemaSectionPath =>
  isObjectPath(path) && path.length === 1 && typeof path[0] === 'number';

export type SchemaItemsPath = [number, 'children'];

export const isSchemaItemsPath = (path: unknown): path is SchemaItemsPath =>
  isObjectPath(path) &&
  path.length === 2 &&
  typeof path[0] === 'number' &&
  path[1] === 'children';

export type SchemaItemPath = [number, 'children', number];

export const isSchemaItemPath = (path: unknown): path is SchemaItemPath =>
  isObjectPath(path) &&
  path.length === 3 &&
  typeof path[0] === 'number' &&
  path[1] === 'children' &&
  typeof path[2] === 'number';

export type SchemaItemChildrenPath = [
  number,
  'children',
  number,
  'children',
  'children',
];

export const isSchemaItemChildrenPath = (
  path: unknown
): path is SchemaItemChildrenPath =>
  isObjectPath(path) &&
  path.length === 5 &&
  typeof path[0] === 'number' &&
  path[1] === 'children' &&
  typeof path[2] === 'number' &&
  path[3] === 'children' &&
  path[4] === 'children';

export type SchemaItemChildPath = [
  number,
  'children',
  number,
  'children',
  'children',
  number,
];

export const isSchemaItemChildPath = (
  path: unknown
): path is SchemaItemChildPath =>
  isObjectPath(path) &&
  path.length === 6 &&
  typeof path[0] === 'number' &&
  path[1] === 'children' &&
  typeof path[2] === 'number' &&
  path[3] === 'children' &&
  path[4] === 'children' &&
  typeof path[5] === 'number';

export type SchemaObjectPath =
  | SchemaSectionPath
  | SchemaItemPath
  | SchemaItemChildPath;

export type SchemaListPath =
  | SchemaSectionsPath
  | SchemaItemsPath
  | SchemaItemChildrenPath;

export type AnySchemaPath = SchemaObjectPath | SchemaListPath;

// TODO: Proper composition of prisms, including array accesses
export type Prism<Whole, Sub> = {
  get: (whole: Whole) => Sub | null;
  set: (whole: Whole, sub: Sub) => Whole;
  modify: (whole: Whole, fn: (sub: Sub) => Sub) => Whole;
};

// TODO: Tests for these two should be enough
export const composePrism =
  <B, C>(bc: Prism<B, C>) =>
  <A>(ab: Prism<A, B>): Prism<A, C> => {
    return {
      get: a => {
        const b = ab.get(a);
        return b === null ? null : bc.get(b);
      },
      set: (a, c) => {
        const b = ab.get(a);
        return b === null ? a : ab.set(a, bc.set(b, c));
      },
      modify: (a, fn) => {
        const b = ab.get(a);
        return b === null ? a : ab.set(a, bc.modify(b, fn));
      },
    };
  };

export const atIndex =
  (i: number) =>
  <S, A>(sa: Prism<S, Array<A>>): Prism<S, A> => {
    return {
      get: s => {
        const collection = sa.get(s);
        return collection === null ? null : collection[i] ?? null;
      },
      set: (s, a) =>
        sa.set(
          s,
          (sa.get(s) ?? []).map((_, index) => (index === i ? a : _))
        ),
      modify: (s, fn) => {
        const b = sa.get(s);
        return b === null
          ? s
          : sa.set(
              s,
              b.map((a, index) => (index === i ? fn(a) : a))
            );
      },
    };
  };

const schemaSectionsPrism: Prism<Schema, Array<SchemaSection>> = {
  get: schema => schema.content ?? null,
  set: (schema, content) => ({ ...schema, content }),
  modify: (schema, fn) => ({
    ...schema,
    content: schema.content ? fn(schema.content) : schema.content,
  }),
};

export const schemaSections = () => schemaSectionsPrism;

export const schemaSection = (sectionIndex: number) =>
  R.pipe(schemaSectionsPrism, atIndex(sectionIndex));

const sectionChildrenPrism: Prism<SchemaSection, SchemaItem[]> = {
  get: section => section.children ?? null,
  set: (section, children) => ({ ...section, children }),
  modify: (section, fn) => ({
    ...section,
    children: section.children ? fn(section.children) : section.children,
  }),
};

export const schemaItems = (sectionIndex: number) =>
  R.pipe(sectionIndex, schemaSection, composePrism(sectionChildrenPrism));

export const schemaItem = (sectionIndex: number, itemIndex: number) =>
  atIndex(itemIndex)(schemaItems(sectionIndex));

const lineItemChildrenPrism: Prism<SchemaItem, Array<SchemaDatapoint>> = {
  get: item => (isSchemaTableMultiValue(item) ? item.children.children : null),
  set: (item, children) =>
    isSchemaTableMultiValue(item)
      ? {
          ...item,
          children: { ...item.children, children },
        }
      : item,
  modify: (item, fn) =>
    isSchemaTableMultiValue(item)
      ? {
          ...item,
          children: { ...item.children, children: fn(item.children.children) },
        }
      : item,
};

export const lineItemChildren = (sectionIndex: number, itemIndex: number) =>
  R.pipe(
    schemaItem(sectionIndex, itemIndex),
    composePrism(lineItemChildrenPrism)
  );

export const lineItemChild = (
  sectionIndex: number,
  itemIndex: number,
  childIndex: number
) => R.pipe(lineItemChildren(sectionIndex, itemIndex), atIndex(childIndex));

type RetrieveFromSchema = {
  (schema: Schema, at: Readonly<SchemaSectionsPath>): SchemaSection[] | null;
  (schema: Schema, at: Readonly<SchemaSectionPath>): SchemaSection | null;
  (schema: Schema, at: Readonly<SchemaItemsPath>): SchemaItem[] | null;
  (schema: Schema, at: Readonly<SchemaItemPath>): SchemaItem | null;
  (
    schema: Schema,
    at: Readonly<SchemaItemChildrenPath>
  ): SchemaDatapoint[] | null;
  (schema: Schema, at: Readonly<SchemaItemChildPath>): SchemaDatapoint | null;
  (
    schema: Schema,
    at: Readonly<SchemaObjectPath>
  ): SchemaSection | SchemaItem | null;
  (
    schema: Schema,
    at: Readonly<SchemaListPath>
  ): SchemaSection[] | SchemaItem[] | null;
  (
    schema: Schema,
    at: Readonly<AnySchemaPath>
  ): SchemaSection[] | SchemaSection | SchemaItem[] | SchemaItem | null;
};

export const retrieveFromSchema: RetrieveFromSchema = (
  schema: any,
  at: Readonly<AnySchemaPath>
): any => {
  switch (at.length) {
    case 0:
      return schemaSectionsPrism.get(schema);
    case 1:
      return schemaSection(at[0]).get(schema);
    case 2:
      return schemaItems(at[0]).get(schema);
    case 3:
      return schemaItem(at[0], at[2]).get(schema);
    case 5:
      return lineItemChildren(at[0], at[2]).get(schema);
    case 6:
      return lineItemChild(at[0], at[2], at[5]).get(schema);
    default:
      return assertNever(at);
  }
};

export const removeItemAtIndex =
  (index: number) =>
  <T>(array: T[]) =>
    array.filter((_, i) => i !== index);

export const insertItemAtIndex =
  <T>(index: number, item: T) =>
  (array: Array<T>) => [...array.slice(0, index), item, ...array.slice(index)];

export const performSafeSwap = <T>(
  schema: Schema,
  item: T,
  fromList: Prism<Schema, T[]>,
  fromIndex: number,
  toList: Prism<Schema, T[]>,
  toIndex: number
) => {
  return toList.modify(
    fromList.modify(schema, removeItemAtIndex(fromIndex)),
    insertItemAtIndex(toIndex, item)
  );
};

const invariantMessage = (
  message: string,
  context: {
    schema: Schema;
    operation: SchemaAction['op'];
    path: AnySchemaPath;
    additionalArgs?: any;
  }
) =>
  `Encountered problematic schema operation ${context.operation} at ${JSON.stringify(context.path)}: ${message}`;

export const logInvalidSchemaOperation = (
  message: string,
  context: {
    schema: Schema;
    operation: SchemaAction['op'];
    path: AnySchemaPath;
    additionalArgs?: any;
  }
) => {
  if (process.env.NODE_ENV !== 'production') {
    throw new Error(invariantMessage(message, context));
  }

  if (process.env.NODE_ENV === 'production' && window.Rollbar) {
    window.Rollbar.error(invariantMessage(message, context), context);
  }
};
