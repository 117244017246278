import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const useHookTemplatesQuery = () =>
  useQuery({
    queryKey: ['hookTemplates'] as const,

    queryFn: () =>
      api.unpaginatedRequest(endpoints.hookTemplates.list)({
        ordering: 'order',
        pageSize: 100,
      }),
    staleTime: 10 * 1000,
  });
