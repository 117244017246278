import {
  Box,
  Button,
  LinearProgress,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateInitialWorkspaceAndAllDocTypesQueues } from '../../business/workspaces/useCreateInitialWorkspaceAndAllDocTypesQueues';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { State } from '../../types/state';
import { ReactComponent as Welcome } from './assets/welcome.svg';

const SVG_WIDTH = 1200;
const SVG_HEIGHT = 588;
const SVG_SIZE_RATIO = Math.floor(100 * (SVG_HEIGHT / SVG_WIDTH));

export const WelcomeScreen = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hasNoWorkspacesOrQueues = useSelector(
    (state: State) =>
      state.queues.list.length === 0 && state.workspaces.list.length === 0
  );

  const {
    createInitialWorkspaceAndAllDocTypesQueues,
    mutationAllDocTypesLoading,
    mutationPrgoress,
  } = useCreateInitialWorkspaceAndAllDocTypesQueues();

  useEffect(() => {
    if (hasNoWorkspacesOrQueues) {
      createInitialWorkspaceAndAllDocTypesQueues();
    }
  }, [
    dispatch,
    createInitialWorkspaceAndAllDocTypesQueues,
    hasNoWorkspacesOrQueues,
  ]);

  return (
    <Stack width={1} maxWidth={{ xs: SVG_WIDTH - 250, xl: SVG_WIDTH }}>
      <Box position="relative" width={1} pt={`${SVG_SIZE_RATIO}%`}>
        <SvgIcon
          inheritViewBox
          component={Welcome}
          sx={{
            width: 1,
            height: 'auto',
            top: 0,
            left: 0,
            position: 'absolute',
          }}
        />
      </Box>

      <Stack direction="row">
        {mutationAllDocTypesLoading && (
          <Stack spacing={0.5} width={320} alignSelf="center" ml="auto">
            <LinearProgress variant="determinate" value={mutationPrgoress} />
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign="center"
            >
              {intl.formatMessage({
                id: 'containers.trialOnboarding.onboardingStep.complete.loading',
              })}
            </Typography>
          </Stack>
        )}
        <Button
          variant="contained"
          onClick={() =>
            dispatch(updateUiSettings({ onboardingAcknowledged: true }))
          }
          disabled={mutationAllDocTypesLoading}
          sx={{ ml: 'auto' }}
        >
          {intl.formatMessage({
            id: 'containers.trialOnboarding.onboardingStep.complete.primaryAction',
          })}
        </Button>
      </Stack>
    </Stack>
  );
};
