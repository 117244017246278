import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';
import { useSaveDeleteRecommendation } from './useSaveDeleteRecommendation';

const QUERY_KEY_DELETE_RECOMMENDATION_ON_QUEUE =
  'delete-recommendation-on-queue';

export const useTriageSetup = (queueUrl: Url) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isRecommendationLoading } = useQuery({
    queryKey: [QUERY_KEY_DELETE_RECOMMENDATION_ON_QUEUE, queueUrl] as const,

    queryFn: ({ queryKey }) =>
      api.request(
        endpoints.deleteRecommendations.list({
          queue: getIDFromUrl(queryKey[1]),
        })
      ),
  });

  const currentDeleteRecommendation = data?.results?.[0];

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_DELETE_RECOMMENDATION_ON_QUEUE, queueUrl],
    });
  };
  const saveMutation = useSaveDeleteRecommendation(queueUrl, onSuccess);

  type Params =
    Parameters<(typeof saveMutation)['mutate']> extends [unknown, ...infer R]
      ? R
      : never;

  return {
    ...saveMutation,
    setTriageEnabled: (enabled: boolean, ...args: Params) =>
      saveMutation.mutate(
        {
          id: currentDeleteRecommendation?.id,
          payload: { enabled },
        },
        ...args
      ),

    connectToTrigger: (trigger: Url, ...args: Params) =>
      saveMutation.mutate(
        {
          id: currentDeleteRecommendation?.id,
          payload: { triggers: [trigger] },
        },
        ...args
      ),

    // Returns undefined if unable to fetch data
    // Returns true if fetched an enabled delete recommendation
    // Returns false if fetched disabled or no recommendation
    isTriageEnabled: data && (currentDeleteRecommendation?.enabled || false),

    isReady: !isRecommendationLoading,
    triggerUrl: currentDeleteRecommendation?.triggers[0],
  };
};
