import { useTheme } from '@rossum/ui/material';
import { forwardRef } from 'react';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox } from './BoundingBox';

type SuggestionBoxProps = {
  position: Rectangle2DCoordinates;
  outlineStyle?: 'solid' | 'dashed' | 'none';
  onClick?: (position: Rectangle2DCoordinates) => void;
};

export const SuggestionBox = forwardRef<SVGRectElement, SuggestionBoxProps>(
  ({ position, outlineStyle, onClick }, ref) => {
    const theme = useTheme();
    return (
      <BoundingBox
        ref={ref}
        position={position}
        outlineStyle={outlineStyle}
        outlineColor={theme.palette.primary.main}
        interactive
        onClick={() => onClick?.(position)}
      />
    );
  }
);
