import { Queue } from '@rossum/api-client/queues';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import DialogTitle from '../../../../ui/dialog-title/DialogTitle';
import { QueueList } from '../../../queues/select-queue/QueueList';
import { WorkspaceWithQueues } from '../../../queues/select-queue/types';

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (selectedQueue: Queue) => void;
  workspaces: WorkspaceWithQueues[];
  isLoading?: boolean;
  submitText: string;
  tertiaryAction?: JSX.Element;
};

/** @deprecated */
export const SelectQueueDialog = ({
  open,
  onClose,
  title,
  workspaces,
  isLoading,
  onSubmit,
  submitText,
  tertiaryAction,
}: Props) => {
  const intl = useIntl();
  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>(
    undefined
  );

  const handleClose = () => {
    setSelectedQueue(undefined);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 2,
        sx: { width: 450, height: '80vh' },
      }}
    >
      <DialogTitle title={title} onClose={handleClose} />
      <QueueList
        workspaces={workspaces}
        value={selectedQueue}
        onChange={setSelectedQueue}
      />

      <DialogActions sx={{ flexGrow: 1, justifySelf: 'right' }}>
        {tertiaryAction}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={isLoading}
          data-cy="select-queue-dialog-cancel-btn"
        >
          {intl.formatMessage({ id: 'components.selectQueueModal.cancel' })}
        </Button>
        <Button
          variant="contained"
          disabled={!selectedQueue || isLoading}
          startIcon={
            isLoading ? <CircularProgress color="inherit" size={16} /> : null
          }
          onClick={() => {
            if (selectedQueue) {
              onSubmit(selectedQueue);
              setSelectedQueue(undefined);
            }
          }}
          data-cy="select-queue-dialog-submit-btn"
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
