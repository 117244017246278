import {
  BasicColumn,
  IconColumn,
  LevelColumn,
  TooltipColumn,
} from './components/Columns';

export const LOG_PADDING_SPACING = 2;
export const LOG_ARROW_WIDTH_SPACING = 3;

export const logsTabs = ['request', 'response', 'detail'] as const;
export type LogTab = (typeof logsTabs)[number];

export const logsColumns = [
  {
    name: 'timestamp',
    width: '23%',
    maxWidth: 270,
    Wrapper: BasicColumn,
  },
  {
    name: 'logLevel',
    width: '8%',
    maxWidth: 85,
    Wrapper: LevelColumn,
  },
  {
    name: 'extension',
    width: '20%',
    maxWidth: 250,
    Wrapper: IconColumn,
  },
  {
    name: 'event',
    width: '19%',
    maxWidth: 320,
    Wrapper: BasicColumn,
  },
  {
    name: 'message',
    width: 'auto',
    maxWidth: '30%',
    Wrapper: TooltipColumn,
  },
] as const;

export type LogContentType = (typeof logsTabs)[number];
