import { DocumentPage } from './DocumentPage';
import { useLastRotation } from './useLastRotation';

type OriginalDocumentPageProps = {
  pageNumber: number;
  isCurrent: boolean;
  onClick: () => void;
  rotationDeg: number;
};

export const OriginalDocumentPage = ({
  isCurrent,
  pageNumber,
  onClick,
  rotationDeg,
}: OriginalDocumentPageProps) => {
  const lastCssRotation = useLastRotation(rotationDeg);
  return (
    <DocumentPage
      pageNumber={pageNumber}
      isCurrent={isCurrent}
      onClick={onClick}
      rotationDeg={lastCssRotation.current}
      blackOverlay={0}
    />
  );
};
