// Generated by ts-to-zod
import { z } from 'zod';
import { functionConfigSchema } from '../models/hookConfig.schema';

export const testPayloadSchema = z.object({
  config: functionConfigSchema,
  payload: z.unknown().optional(),
});

export const testResponseSchema = z.object({
  log: z.string(),
  response: z
    .object({
      messages: z.array(z.unknown()),
      operations: z.array(z.unknown()),
      updatedDatapoints: z.array(z.unknown()),
      suggestedOperations: z.array(z.unknown()),
    })
    .partial(),
  stacktrace: z.string().optional(),
});
