import { Message } from '@rossum/api-client/shared';
import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import { Stack, styled, Typography } from '@rossum/ui/material';
import { truncate } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { MESSAGE_MAX_LENGTH } from '../../constants/values';
import MessageDetailDialog from '../../containers/Sidebar/components/MessageDetailDialog';
import messageStyles from '../Datapoint/style.module.sass';
import { isMessageDetailVisible } from './helpers';
import HTMLMessage from './HTMLMessage';
import Icon from './Icon';

type Props = {
  active: boolean;
  isSimpleMultivalue?: boolean;
  message: Message;
};

const StyledTooltip = styled(Tooltip)({});

const IconWithTooltip = ({
  active,
  isSimpleMultivalue,
  message: { type, content, id, detail },
}: Props) => {
  const intl = useIntl();
  const [showMessageDetail, setShowMessageDetail] = useState(false);

  const messageDetailVisible = isMessageDetailVisible(detail, type);

  return (
    <Stack width={1} data-cy="icon-with-tooltip">
      <StyledTooltip
        keyId={`${id}-${content}`}
        arrowClassName={messageStyles.MessageArrow}
        contentClassName={messageStyles.MessageContent}
        disabled={!id}
        placement="right"
        sx={{ backgroundColor: `${type}.main` }}
        popperClassName={
          messageDetailVisible ? messageStyles.MessageWithLink : undefined
        }
        content={
          <Stack spacing={1}>
            <HTMLMessage
              content={truncate(content, {
                length: MESSAGE_MAX_LENGTH,
              })}
            />
            {messageDetailVisible && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontWeight: 'inherit',
                }}
                onClick={e => {
                  e.stopPropagation();
                  setShowMessageDetail(true);
                }}
              >
                {intl.formatMessage({
                  id: 'components.documentValidation.sidebar.exceptionalError.moreDetails',
                })}
              </Typography>
            )}
          </Stack>
        }
        offset={[0, 5]}
        modifiers={[
          { name: 'preventOverflow', enabled: true },
          { name: 'hide', enabled: true },
        ]}
      >
        <Stack>
          <Icon
            type={type}
            active={active}
            isSimpleMultivalue={isSimpleMultivalue}
          />
        </Stack>
      </StyledTooltip>
      {messageDetailVisible && (
        <MessageDetailDialog
          open={showMessageDetail}
          messageDetail={detail}
          onClose={() => setShowMessageDetail(false)}
          content={content}
          datapointId={id}
        />
      )}
    </Stack>
  );
};

export default IconWithTooltip;
