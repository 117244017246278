import { getIDFromUrl } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { PageContainer } from '@rossum/rossum-ui/PageContainer';
import { PageSectionContainer } from '@rossum/rossum-ui/PageSectionContainer';
import { ErrorOutline } from '@rossum/ui/icons';
import { CircularProgress, Stack, Typography } from '@rossum/ui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { AutomationBreadcrumbs } from '../../../../containers/Automation/AutomationBreadcrumbs';
import {
  automationEnginesPath,
  AutomationParams,
} from '../../../../containers/Automation/helpers';
import { Header } from '../../../../ui/header/Header';
import { useDedicatedEngine } from '../../hooks/useDedicatedEngine';
import { useDedicatedEngineSchema } from '../../hooks/useDedicatedEngineSchema';
import DedicatedEngineSchemaSetup from './components/DedicatedEngineSchemaSetup';

// TODO: This should be part of our DS and design process - how to handle unhappy paths is important
// I didn't go into detail (showing a specific message) for now but it should be done
const ErrorState = () => {
  const intl = useIntl();

  return (
    <Stack alignItems="center" sx={{ p: 4 }}>
      <ErrorOutline fontSize="large" color="error" />
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'containers.settings.engineDetail.fetchingError',
        })}
      </Typography>
    </Stack>
  );
};

const LoadingState = () => (
  <Stack alignItems="center" sx={{ p: 4 }}>
    <CircularProgress />
  </Stack>
);

const DedicatedEngineDetail = () => {
  const { id } = useParams<AutomationParams>();

  const history = useHistory();

  // data fetching logic
  const engineId = Number(id);

  const {
    data: engine,
    status: engineStatus,
    error: engineError,
  } = useDedicatedEngine(engineId);

  const engineSchemaId = engine?.schema ? getIDFromUrl(engine.schema) : null;

  const { data: engineSchema, status: engineSchemaStatus } =
    useDedicatedEngineSchema(engineSchemaId);

  const isError = engineStatus === 'error' || engineSchemaStatus === 'error';

  const isLoading =
    engineStatus === 'loading' || engineSchemaStatus === 'loading';

  // Check if engine was found
  // just an example of handling one unhappy path - there are _many_ more though
  useEffect(() => {
    if (
      engineStatus === 'error' &&
      engineError &&
      engineError instanceof HttpError &&
      engineError.code === 404
    ) {
      history.replace(automationEnginesPath());
    }
  }, [engineError, engineStatus, history]);

  const intl = useIntl();

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          {...params}
          title={intl.formatMessage({
            id: 'features.engines.header.title.main',
          })}
          description={intl.formatMessage({
            id: 'features.engines.header.description.main',
          })}
          breadcrumbs={
            <AutomationBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'components.appBar.menu.automation.aiEngines',
                  }),
                },
              ]}
            />
          }
        />
      )}
    >
      <PageContainer maxWidth="md" data-page-title="dedicated-engine-detail">
        <PageSectionContainer>
          {isLoading ? (
            <LoadingState />
          ) : isError ? (
            <ErrorState />
          ) : engine && engineSchema ? (
            <DedicatedEngineSchemaSetup
              engine={engine}
              engineSchema={engineSchema}
            />
          ) : null}
        </PageSectionContainer>
      </PageContainer>
    </PageLayoutV2>
  );
};

export default DedicatedEngineDetail;
