import { ViewListOutlined as ViewListOutlinedIcon } from '@rossum/ui/icons';
import { TableCell } from '@rossum/ui/material';
import InfoPlaceholder from '../../../../components/UI/InfoPlaceHolder';
import { LocalizationKeys } from '../../../../i18n';
import { TableRow } from './BillingTable';

type EmptyTableProps = {
  columnsCount: number;
  title: LocalizationKeys;
  text?: LocalizationKeys;
};

const EmptyTable = ({ columnsCount, title, text }: EmptyTableProps) => (
  <TableRow className="TableRowOverride TableRowPlaceholder">
    <TableCell colSpan={columnsCount} component="th" scope="row">
      <InfoPlaceholder
        title={title}
        text={text}
        icon={<ViewListOutlinedIcon />}
        inTable
      />
    </TableCell>
  </TableRow>
);

export default EmptyTable;
