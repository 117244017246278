// Generated by ts-to-zod
import { z } from 'zod';

export const updateAnnotationContentPayloadSchema = z.object({
  content: z.object({
    value: z.union([z.string(), z.number()]),
  }),
  validationSources: z.array(
    z.union([z.literal('checks'), z.literal('score'), z.literal('human')])
  ),
});
