import { Document } from '@rossum/api-client/documents';
import { Status } from '../../../../types/annotationStatus';

export type AttachedAnnotation = {
  id: number;
  url: string;
  status: Status;
  pages: string[];
  email: string | null;
  relatedEmails: string[];
  createdAt: string;
  restrictedAccess: boolean;
};

export type AttachmentT = {
  document: Document;
  annotation?: AttachedAnnotation;
  annotationModifier?: { id: number; username: string } | undefined;
};

export const isAnnotationDisabled = (status: Status) =>
  status === 'importing' || status === 'failedImport';
