import { getIDFromUrl, Url } from '@rossum/api-client';
import { isEqual, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { BILLING_USAGE_QUERY } from '../../redux/modules/localStorage/actions';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { formatDateQuery } from '../../redux/modules/statistics/helpers';
import { useStoredState } from '../../utils/hooks/useStoredState';
import { StatisticsFilterFormState } from './components/StatisticsFilter';
import { useOrganizationGroupUrl } from './hooks/useOrganizationGroupUrl';

const KEYS = ['queues', 'organizationId', 'unit', 'from', 'to'] as const;
const UNITS = ['pages', 'documents'] as const;

const getQueueUrl = (
  id: number | string,
  organizationGroupUrl: Url | undefined
) =>
  organizationGroupUrl ? `${organizationGroupUrl}/queues/${id}` : undefined;

const getOrganizationUrl = (
  id: number | string,
  organizationGroupUrl: Url | undefined
) =>
  organizationGroupUrl
    ? `${organizationGroupUrl}/organizations/${id}`
    : undefined;

const getDefaultData = (organizationId: number | undefined) => {
  const dateFrom = new Date();
  const dateTo = new Date();
  dateFrom.setFullYear(dateFrom.getFullYear() - 1);

  return {
    queues: undefined,
    organizationId: organizationId?.toString(),
    unit: 'pages' as const,
    from: formatDateQuery(dateFrom),
    to: formatDateQuery(dateTo),
  };
};

export const useFilterValues = () => {
  const { replace } = useHistory();
  const organizationGroupUrl = useOrganizationGroupUrl();
  const currentOrganization = useSelector(organizationSelector);

  const defaultState = useMemo(
    () => getDefaultData(currentOrganization.id),
    [currentOrganization.id]
  );

  const [state, setState] = useStoredState({
    name: BILLING_USAGE_QUERY,
    defaultValues: defaultState,
    arrayKeys: ['queues'],
    stringKeys: ['organizationId', 'unit', 'from', 'to'],
    historyFn: replace,
  });

  const defaultValues: StatisticsFilterFormState = useMemo(() => {
    return {
      dateRange: {
        from: state.from ?? defaultState.from,
        to: state.to ?? defaultState.to,
      },
      unit: UNITS.find(unit => unit === state.unit) ?? 'pages',
      queues: state.queues
        ? {
            urls: state.queues
              .map(queueId => getQueueUrl(queueId, organizationGroupUrl))
              .filter(isNotNullOrUndefined),
            deleted: true, // store this flag in independent deletedQueues key,
          }
        : undefined,
      organizationUrl: state.organizationId
        ? getOrganizationUrl(state.organizationId, organizationGroupUrl)
        : undefined,
    };
  }, [organizationGroupUrl, state, defaultState]);

  const setStoredValues = useCallback(
    (filters: StatisticsFilterFormState) => {
      const newState = {
        from: filters.dateRange.from,
        to: filters.dateRange.to,
        unit: filters.unit,
        queues: filters.queues?.urls
          .map(queue => {
            const id = getIDFromUrl(queue);
            return id ? id.toString() : undefined;
          })
          .filter(isNotNullOrUndefined),
        organizationId: filters.organizationUrl
          ? getIDFromUrl(filters.organizationUrl)?.toString()
          : undefined,
      };
      const changedKeys = KEYS.filter(
        key => !isEqual(newState[key], defaultState[key])
      );
      const persistOrganizationId =
        changedKeys.includes('queues') &&
        !changedKeys.includes('organizationId');
      setState(
        pick(
          newState,
          persistOrganizationId
            ? [...changedKeys, 'organizationId']
            : changedKeys
        )
      );
    },
    [setState, defaultState]
  );

  return {
    defaultValues,
    setStoredValues,
  };
};
