import { IntlShape } from 'react-intl';
import * as R from 'remeda';
import * as z from 'zod';
import { getErrorLabel, wrongTypeMessage } from '../errors/errorMessages';

export const numericString = (intl: IntlShape) =>
  z
    .string()
    .trim()
    .transform((val, _ctx) => {
      const parsed = Number(val);

      if (Number.isNaN(parsed)) {
        _ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: [],
          expected: 'number',
          received: 'string',
          message: wrongTypeMessage(intl)(
            getErrorLabel(intl, R.last(_ctx.path)),
            'number'
          ),
        });
        return null;
      }

      if (val === '') {
        return null;
      }

      return parsed;
    });
