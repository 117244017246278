import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../../lib/apiClient';

export const useOpenDocument = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: async (documentUrl: string) => {
      const response = await api.request(
        endpoints.documents.getBlob(documentUrl)
      );
      const objectUrl = URL.createObjectURL(response);
      window.open(objectUrl, '_blank');
    },
  });
};
