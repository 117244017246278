import { SvgIcon, SvgIconProps } from '@rossum/ui/material';

export const AuroraIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M11.5416 2.49992C11.5416 1.92462 11.0753 1.45825 10.5 1.45825C9.92749 1.45825 9.46286 1.92009 9.45835 2.49151L9.45824 2.49502C9.45801 2.5024 9.45736 2.51857 9.45574 2.54293C9.45249 2.59169 9.44533 2.67303 9.42972 2.78229C9.39848 3.001 9.3336 3.33001 9.19927 3.73301C8.9318 4.53541 8.38616 5.6406 7.26341 6.76335C6.70472 7.32204 6.15038 7.73783 5.63552 8.04755C5.57822 8.08203 5.58126 8.16682 5.64138 8.19612L7.57037 9.13597C7.62723 9.16367 7.69492 9.1569 7.74467 9.11785C8.07316 8.85995 8.40536 8.56767 8.73655 8.23649C10.1138 6.85924 10.8182 5.46443 11.1757 4.39182C11.3539 3.85733 11.4452 3.40509 11.4921 3.07692C11.5156 2.91275 11.5279 2.77924 11.5345 2.68151C11.5377 2.63263 11.5395 2.59265 11.5405 2.56219C11.541 2.54696 11.5413 2.5341 11.5414 2.5237L11.5416 2.50994L11.5416 2.50446L11.5416 2.50207L11.5416 2.50097C11.5416 2.50097 11.5416 2.49992 10.5 2.49992H11.5416Z" />
    <path d="M9.45831 17.4999C9.45831 18.0752 9.92468 18.5416 10.5 18.5416C11.0725 18.5416 11.5371 18.0798 11.5416 17.5083L11.5417 17.5048C11.542 17.4974 11.5426 17.4813 11.5442 17.4569C11.5475 17.4081 11.5546 17.3268 11.5702 17.2175C11.6015 16.9988 11.6664 16.6698 11.8007 16.2668C12.0682 15.4644 12.6138 14.3592 13.7365 13.2365C14.2952 12.6779 14.8494 12.2621 15.3642 11.9524C15.4216 11.9179 15.4185 11.833 15.3583 11.8038L13.4273 10.8655C13.3704 10.8379 13.3028 10.8447 13.2531 10.8837C12.9253 11.1412 12.5939 11.4329 12.2634 11.7633C10.8862 13.1406 10.1818 14.5354 9.82427 15.608C9.6461 16.1425 9.55473 16.5947 9.50784 16.9229C9.48439 17.0871 9.47202 17.2206 9.4655 17.3183C9.46224 17.3672 9.46045 17.4072 9.45946 17.4376C9.45897 17.4529 9.45868 17.4657 9.45852 17.4761L9.45835 17.4899L9.45832 17.4954L9.45831 17.4978L9.45831 17.4989C9.45831 17.4989 9.45831 17.4999 10.5 17.4999H9.45831Z" />
    <path d="M2.99998 8.95825C2.42468 8.95825 1.95831 9.42462 1.95831 9.99992C1.95831 10.5724 2.42015 11.037 2.99157 11.0416L2.99508 11.0417C3.00246 11.0419 3.01863 11.0425 3.04299 11.0442C3.09176 11.0474 3.17309 11.0546 3.28235 11.0702C3.50106 11.1014 3.83007 11.1663 4.23308 11.3006C5.03547 11.5681 6.14066 12.1137 7.26341 13.2365C7.82157 13.7946 8.2371 14.3485 8.54673 14.8629C8.58139 14.9205 8.66674 14.917 8.69564 14.8564L9.62258 12.9103C9.64961 12.8536 9.64251 12.7864 9.60359 12.737C9.3492 12.4145 9.06162 12.0884 8.73655 11.7633C7.3593 10.3861 5.96449 9.68174 4.89188 9.32421C4.35739 9.14604 3.90515 9.05467 3.57698 9.00778C3.41281 8.98433 3.2793 8.97196 3.18157 8.96544C3.1327 8.96218 3.09271 8.96039 3.06225 8.9594C3.04702 8.95891 3.03416 8.95862 3.02376 8.95846L3.01 8.95829L3.00452 8.95826L3.00213 8.95825L3.00103 8.95825C3.00103 8.95825 2.99998 8.95825 2.99998 9.99992V8.95825Z" />
    <path d="M18 11.0416C18.5753 11.0416 19.0416 10.5752 19.0416 9.99992C19.0416 9.42743 18.5798 8.9628 18.0084 8.95829L18.0049 8.95818C17.9975 8.95795 17.9813 8.9573 17.957 8.95568C17.9082 8.95243 17.8269 8.94527 17.7176 8.92966C17.4989 8.89842 17.1699 8.83354 16.7669 8.69921C15.9645 8.43174 14.8593 7.8861 13.7365 6.76335C13.1773 6.20413 12.7613 5.64926 12.4515 5.13399C12.4172 5.07698 12.333 5.07961 12.3033 5.13915L11.3502 7.05051C11.3217 7.10748 11.3282 7.17571 11.3674 7.22587C11.6288 7.56046 11.9259 7.899 12.2634 8.23649C13.6407 9.61374 15.0355 10.3181 16.1081 10.6756C16.6426 10.8538 17.0948 10.9452 17.423 10.9921C17.5872 11.0155 17.7207 11.0279 17.8184 11.0344C17.8673 11.0377 17.9072 11.0395 17.9377 11.0404C17.9529 11.0409 17.9658 11.0412 17.9762 11.0414L17.99 11.0415L17.9954 11.0416L17.9978 11.0416L17.9989 11.0416C17.9989 11.0416 18 11.0416 18 9.99992V11.0416Z" />
  </SvgIcon>
);

export const AuroraAcceptIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 16">
    <path d="M5.58333 0.833191C5.8359 0.833191 6.04065 1.03794 6.04065 1.29051H5.58333C6.04065 1.29051 6.04065 1.29051 6.04065 1.29051L6.04065 1.29145L6.04065 1.2925L6.04063 1.29491L6.04056 1.30095L6.04014 1.31785C6.03971 1.33122 6.03892 1.34877 6.03749 1.37023C6.03463 1.41314 6.0292 1.47175 6.0189 1.54383C5.99832 1.6879 5.95821 1.88644 5.87999 2.1211C5.72302 2.592 5.41379 3.20435 4.80914 3.809C4.66375 3.9544 4.5179 4.08272 4.37369 4.19594C4.35185 4.21309 4.32213 4.21606 4.29716 4.2039L3.45029 3.79128C3.4239 3.77842 3.42256 3.74119 3.44772 3.72606C3.67375 3.59008 3.91712 3.40754 4.1624 3.16226C4.65531 2.66934 4.89487 2.18414 5.01229 1.83187C5.07127 1.65494 5.09975 1.51049 5.11346 1.41448C5.12032 1.36651 5.12346 1.3308 5.12489 1.30939C5.1256 1.2987 5.12588 1.2916 5.12599 1.28836L5.12603 1.28682C5.12801 1.03595 5.332 0.833191 5.58333 0.833191Z" />
    <path d="M5.58333 8.33319C5.33076 8.33319 5.12602 8.12844 5.12602 7.87587H5.58333C5.12602 7.87587 5.12602 7.87587 5.12602 7.87587L5.12602 7.87493L5.12602 7.87388L5.12603 7.87147L5.12611 7.86543L5.12652 7.84854C5.12695 7.83516 5.12774 7.81761 5.12917 7.79615C5.13203 7.75325 5.13747 7.69463 5.14776 7.62256C5.16834 7.47848 5.20846 7.27994 5.28668 7.04528C5.44365 6.57438 5.75287 5.96203 6.35752 5.35738C6.5026 5.21231 6.64812 5.08424 6.79202 4.9712C6.81384 4.95406 6.84353 4.95107 6.86849 4.9632L7.71625 5.37514C7.74268 5.38798 7.74403 5.42523 7.71886 5.44038C7.49285 5.57635 7.24951 5.75888 7.00427 6.00412C6.51135 6.49704 6.2718 6.98225 6.15438 7.33452C6.0954 7.51144 6.06692 7.65589 6.0532 7.75191C6.04635 7.79987 6.04321 7.83558 6.04178 7.85699C6.04107 7.86769 6.04079 7.87478 6.04068 7.87802L6.04064 7.87956C6.03865 8.13043 5.83467 8.33319 5.58333 8.33319Z" />
    <path d="M2.29065 4.12587C2.03808 4.12587 1.83333 4.33062 1.83333 4.58319C1.83333 4.83453 2.03609 5.03851 2.28696 5.04049L2.2885 5.04054C2.29174 5.04064 2.29884 5.04093 2.30953 5.04164C2.33094 5.04307 2.36665 5.04621 2.41462 5.05306C2.51064 5.06678 2.65508 5.09526 2.83201 5.15423C3.18428 5.27166 3.66949 5.51121 4.1624 6.00412C4.40745 6.24917 4.58987 6.49231 4.72581 6.71816C4.74103 6.74344 4.7785 6.74193 4.79119 6.71529L5.19813 5.86092C5.21 5.83601 5.20688 5.80652 5.1898 5.78485C5.07811 5.64324 4.95186 5.50009 4.80914 5.35738C4.2045 4.75273 3.59214 4.4435 3.12124 4.28654C2.88659 4.20832 2.68804 4.1682 2.54397 4.14762C2.47189 4.13732 2.41328 4.13189 2.37038 4.12903C2.34892 4.1276 2.33136 4.12681 2.31799 4.12638L2.30109 4.12597L2.29505 4.12589L2.29264 4.12588L2.2916 4.12587C2.2916 4.12587 2.29065 4.12587 2.29065 4.58319V4.12587Z" />
    <path d="M9.33333 4.58319C9.33333 4.83576 9.12859 5.04051 8.87602 5.04051V4.58319C8.87602 5.04051 8.87602 5.04051 8.87602 5.04051L8.87507 5.04051L8.87402 5.0405L8.87162 5.04049L8.86558 5.04042L8.84868 5.04C8.83531 5.03957 8.81775 5.03878 8.79629 5.03735C8.75339 5.03449 8.69478 5.02906 8.6227 5.01876C8.47862 4.99818 8.28008 4.95806 8.04542 4.87985C7.57452 4.72288 6.96217 4.41365 6.35752 3.809C6.20936 3.66084 6.07893 3.51221 5.96414 3.36532C5.94693 3.34329 5.94411 3.31334 5.95658 3.28833L6.37502 2.44919C6.38806 2.42305 6.42503 2.4219 6.44008 2.44693C6.5761 2.67315 6.75875 2.91675 7.00427 3.16226C7.49718 3.65517 7.98239 3.89472 8.33466 4.01215C8.51159 4.07112 8.65603 4.0996 8.75205 4.11332C8.80002 4.12017 8.83572 4.12332 8.85713 4.12474C8.86783 4.12546 8.87493 4.12574 8.87817 4.12584L8.87971 4.12589C9.13058 4.12787 9.33333 4.33185 9.33333 4.58319Z" />
    <path d="M9.00853 13.4365L16.394 6.05104C16.6855 5.75951 17.1565 5.75951 17.4405 6.05852H17.448C17.7395 6.35005 17.7395 6.82099 17.448 7.11252L9.53179 15.0213C9.24026 15.3128 8.76933 15.3128 8.47779 15.0213L5.35317 11.8966C5.06164 11.6051 5.06164 11.1342 5.35317 10.8426C5.6447 10.5511 6.11564 10.5511 6.40717 10.8426L9.00853 13.4365Z" />
    <path d="M12.1705 6.05852C12.4621 5.76698 12.933 5.76698 13.2245 6.05852C13.5086 6.34257 13.5086 6.82099 13.2245 7.10504L9.00853 11.3285L7.95453 10.2745L12.1705 6.05852Z" />
    <path d="M1.12969 11.9041L4.25432 15.0287C4.54585 15.3203 5.01679 15.3203 5.30832 15.0287L5.83158 14.5055L2.18369 10.8426C1.89216 10.5511 1.42122 10.5511 1.12969 10.8426C0.838158 11.1342 0.838158 11.6126 1.12969 11.9041Z" />
  </SvgIcon>
);
