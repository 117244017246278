import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCreateUser } from '../../business/users/useCreateUser';
import { onboardingData } from '../../containers/TrialOnboarding/surveyData';
import { getRoleUrlByName } from '../../redux/modules/groups/helpers';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { State } from '../../types/state';
import { getToday } from '../Datepicker/helpers';
import TextFieldControl from '../ReactHookForm/controls/TextFieldControl';
import { ControlledDropdown } from '../UI/ControlledDropdown';
import SuccessMessage from './SuccessMessage';

const InviteColleagueContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const jobPositions = onboardingData.surveySteps[
    'what-is-your-role'
  ].options.map(({ key, translationId }) => ({
    key,
    label: intl.formatMessage({ id: translationId }),
  }));

  const user = useSelector((state: State) => state.user);

  const inviteColleagueSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'components.inviteColleague.error',
        })
      )
      .required(
        intl.formatMessage({
          id: 'components.inviteColleague.required.email',
        })
      ),
    jobPosition: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    reset: resetForm,
    setError,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(inviteColleagueSchema),
    defaultValues: { email: '', jobPosition: '' },
  });

  const { mutate, isLoading, error, apiErrorMessage, isSuccess } =
    useCreateUser(() => resetForm(), 'welcome');

  const adminRole = useSelector((state: State) =>
    getRoleUrlByName('admin', state.groups)
  );

  const inviteUser = ({
    email,
    jobPosition,
  }: {
    email: string;
    jobPosition: string;
  }) => {
    return (
      adminRole &&
      mutate(
        { email, role: [adminRole] },
        {
          onSuccess: () => {
            const sentInvitations = user.uiSettings.sentInvitations ?? [];

            dispatch(
              updateUiSettings({
                sentInvitations: sentInvitations.concat({
                  dateJoined: user.dateJoined,
                  dateOfInvitation: getToday().toISOString(),
                  email,
                  jobPosition,
                }),
              })
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    if (error || apiErrorMessage) {
      const message =
        apiErrorMessage ??
        intl.formatMessage({
          id: 'components.inviteColleague.apiError',
        });

      setError('email', { message });
    }
  }, [error, apiErrorMessage, intl, setError]);

  return (
    <DialogContent sx={{ mt: 3, mx: 3, px: 0 }}>
      <Stack spacing={4}>
        <Stack px={10} textAlign="center">
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.inviteColleague.subtitle',
            })}
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(inviteUser)}>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <TextFieldControl
                sx={{ width: '100%' }}
                FieldLabelProps={{ layout: 'floating' }}
                ControllerProps={{ control, name: 'email' }}
                label={intl.formatMessage({
                  id: 'components.inviteColleague.email.label',
                })}
              />
              <ControlledDropdown
                control={control}
                name="jobPosition"
                options={jobPositions}
                inputLabel={intl.formatMessage({
                  id: 'components.inviteColleague.role.label',
                })}
                getErrorMessage={() =>
                  intl.formatMessage({
                    id: 'components.inviteColleague.required.role',
                  })
                }
              />
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SuccessMessage
                show={isSuccess}
                message={intl.formatMessage({
                  id: 'containers.message.colleagueInvited.text',
                })}
              />
              <Button
                type="submit"
                startIcon={
                  isLoading && <CircularProgress color="inherit" size={20} />
                }
                variant="contained"
                data-cy="invite-colleague-submit-btn"
              >
                {intl.formatMessage({
                  id: 'components.inviteColleague.submit',
                })}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </DialogContent>
  );
};

export default InviteColleagueContent;
