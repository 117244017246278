import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type Props = {
  searchValue: string;
};
export const EmptySidebar = ({ searchValue }: Props) => {
  const intl = useIntl();

  return searchValue ? (
    <Stack
      height={1}
      justifyContent="center"
      alignItems="center"
      spacing={2}
      textAlign="center"
      px={2}
      sx={{ color: 'text.secondary' }}
    >
      <Typography variant="h6">
        {intl.formatMessage(
          {
            id: 'components.documentListSidebar.empty.title',
          },
          { searchValue }
        )}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'components.documentListSidebar.empty.description',
        })}
      </Typography>
    </Stack>
  ) : null;
};
