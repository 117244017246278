// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { automationDatapointSchema } from '../models/automation.schema';
import { automationBlockerSchema } from '../models/automationBlocker.schema';

export const automationPayloadSchema = z.object({
  beginDate: z.string(),
  endDate: z.string(),
});

export const automationResponseSchema = z.object({
  beginDate: z.string(),
  endDate: z.string(),
  queue: urlSchema,
  automationBlockers: z.array(automationBlockerSchema),
  datapoints: z.array(automationDatapointSchema),
  totals: z.object({
    documentCount: z.number(),
    automation: z.number(),
  }),
});
