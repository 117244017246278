import { Document } from '@rossum/api-client/documents';
import { Email } from '@rossum/api-client/email';
import { EmailContent } from '../EmailContent';
import { EmailCard } from './EmailCard';
import { EmailDocumentsPreview } from './EmailDocumentsPreview';

type DocumentEmailCardProps = {
  email: Email;
  documents: Document[] | undefined;
};

export const DocumentEmailCard = ({
  email,
  documents,
}: DocumentEmailCardProps) => (
  <EmailCard email={email} user={undefined}>
    <EmailContent email={email} />
    <EmailDocumentsPreview documents={documents} />
  </EmailCard>
);
