/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { camelCase, mapKeys, snakeCase } from 'lodash';
import {
  z,
  ZodArray,
  ZodEffects,
  ZodIntersection,
  ZodNullable,
  ZodObject,
  ZodOptional,
  ZodRecord,
  ZodTuple,
  ZodType,
  ZodTypeDef,
  ZodUnion,
} from 'zod';

type UnknownKeysConfig = 'passthrough' | 'strict' | 'strip';

// TODO this should be overridable through env variable - for local development / tests we can use strict
const globalUnknownKeysConfig: UnknownKeysConfig = 'passthrough';

const camelCaseExcludeDots = (val: string) =>
  val.split('.').map(camelCase).join('.');

export const fromSnakeCase = (val: unknown) => {
  if (typeof val === 'object' && val !== null) {
    return mapKeys(val, (_, k) => camelCaseExcludeDots(k));
  }
  return val;
};

const snakeCaseExcludeDots = (val: string) =>
  val.split('.').map(snakeCase).join('.');

export const toSnakeCase = (val: unknown) => {
  if (typeof val === 'object' && val !== null) {
    return mapKeys(val, (_, k) => snakeCaseExcludeDots(k));
  }
  return val;
};

export const asResponseDecoder = <Out, Def extends ZodTypeDef, Input>(
  schema: ZodType<Out, Def, Input>,
  keyTransform: (val: unknown) => unknown = fromSnakeCase
): D.Decoder<unknown, Out> => {
  return {
    decode: (backendResponse: unknown) => {
      const parseResult = asResponseSchema(schema, keyTransform).safeParse(
        backendResponse
      );

      if (parseResult.success === true) {
        return D.success(parseResult.data);
      }

      // eslint-disable-next-line no-console
      console.warn('An error has occured:');
      // eslint-disable-next-line no-console
      console.warn(parseResult.error);
      return D.failure(backendResponse, parseResult.error.toString());
    },
  };
};

export const asPayloadEncoder = <Out, Def extends ZodTypeDef, Input>(
  schema: ZodType<Out, Def, Input>,
  keyTransform: (val: unknown) => unknown = toSnakeCase
): any /* E.Encoder<Out, Out> */ => {
  return E.contramap(req => asRequestSchema(schema, keyTransform).parse(req))(
    E.id<Out>()
  );
};

export const asQueryEncoder = <Out, Def extends ZodTypeDef, Input>(
  schema: ZodType<Out, Def, Input>,
  keyTransform: (val: unknown) => unknown = toSnakeCase
): any /* E.Encoder<Out, Out> */ => {
  return E.contramap(req => asRequestSchema(schema, keyTransform).parse(req))(
    E.id<Out>()
  );
};

// See test cases for detailed description why we need this.
const convertIntersectionToObject = (
  schema: ZodIntersection<any, any>
): ZodObject<any> => {
  const left =
    schema._def.left instanceof ZodIntersection
      ? convertIntersectionToObject(schema._def.left)
      : schema._def.left;

  const right =
    schema._def.right instanceof ZodIntersection
      ? convertIntersectionToObject(schema._def.right)
      : schema._def.right;

  if (!(left instanceof ZodObject) || !(right instanceof ZodObject)) {
    throw new Error(
      'Intersection types of non-objects are not supported. If you are creating an intersection type from a union `(A | B) & C`, rewrite it to `(A & C) | (B & C)`.'
    );
  }

  return left.merge(right);
};

export const asResponseSchema = <Out, Def extends ZodTypeDef, Input>(
  schema: ZodType<Out, Def, Input>,
  keyTransform: (val: unknown) => unknown = fromSnakeCase,
  unknownKeys: UnknownKeysConfig = globalUnknownKeysConfig
): ZodType<Out, Def, Input> => {
  if (schema instanceof ZodEffects) {
    const originalEffect = schema._def.effect;

    // for `transform` and `preprocess`, doesn't matter where we put the key conversion
    // this doesn't handle `refinement` so we don't support it yet
    const newEffect =
      originalEffect.type === 'refinement'
        ? originalEffect
        : ({
            ...originalEffect,
            // transform: flow([originalEffect.transform, fromSnakeCase]),
          } as any);

    return ZodEffects.create(
      asResponseSchema(schema._def.schema, keyTransform),
      newEffect
    ) as any;
  }
  if (schema instanceof ZodObject) {
    const newShape: any = {};

    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = asResponseSchema(fieldSchema, keyTransform);
    }
    return z.preprocess(
      fromSnakeCase,
      new ZodObject({
        ...schema._def,
        shape: () => newShape,
        unknownKeys,
      })
    ) as any;
  }
  if (schema instanceof ZodArray) {
    return ZodArray.create(
      asResponseSchema(schema.element, keyTransform)
    ) as any;
  }
  // TODO this is a bug in ts-zod deepPartialify
  if (schema instanceof ZodRecord) {
    return ZodRecord.create(
      asResponseSchema(schema.valueSchema, keyTransform)
    ) as any;
  }
  if (schema instanceof ZodUnion) {
    return ZodUnion.create(
      schema.options.map((item: any) => asResponseSchema(item, keyTransform))
    ) as any;
  }
  if (schema instanceof ZodIntersection) {
    const converted = convertIntersectionToObject(schema);

    return asResponseSchema(converted, keyTransform) as any;
  }

  if (schema instanceof ZodOptional) {
    return ZodOptional.create(
      asResponseSchema(schema.unwrap(), keyTransform)
    ) as any;
  }
  if (schema instanceof ZodNullable) {
    return ZodNullable.create(
      asResponseSchema(schema.unwrap(), keyTransform)
    ) as any;
  }
  if (schema instanceof ZodTuple) {
    return ZodTuple.create(
      schema.items.map((item: any) => asResponseSchema(item, keyTransform))
    ) as any;
  }
  return schema;
};

export const asRequestSchema = <Out, Def extends ZodTypeDef, Input>(
  schema: ZodType<Out, Def, Input>,
  keyTransform: (val: unknown) => unknown = toSnakeCase
): ZodType<Out, Def, Input> => {
  if (schema instanceof ZodObject) {
    const newShape: any = {};

    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = asRequestSchema(fieldSchema, keyTransform);
    }
    return new ZodObject({
      ...schema._def,
      shape: () => newShape,
    }).transform(val => keyTransform(val)) as any;
  }
  if (schema instanceof ZodArray) {
    return ZodArray.create(
      asRequestSchema(schema.element, keyTransform)
    ) as any;
  }
  // TODO this is a bug in ts-zod deepPartialify
  if (schema instanceof ZodRecord) {
    return ZodRecord.create(
      asRequestSchema(schema.valueSchema, keyTransform)
    ) as any;
  }
  if (schema instanceof ZodUnion) {
    return ZodUnion.create(
      schema.options.map((item: any) => asRequestSchema(item, keyTransform))
    ) as any;
  }
  if (schema instanceof ZodIntersection) {
    const converted = convertIntersectionToObject(schema);

    return asRequestSchema(converted, keyTransform) as any;
  }

  if (schema instanceof ZodOptional) {
    return ZodOptional.create(
      asRequestSchema(schema.unwrap(), keyTransform)
    ) as any;
  }
  if (schema instanceof ZodNullable) {
    return ZodNullable.create(
      asRequestSchema(schema.unwrap(), keyTransform)
    ) as any;
  }
  if (schema instanceof ZodTuple) {
    return ZodTuple.create(
      schema.items.map((item: any) => asRequestSchema(item, keyTransform))
    ) as any;
  }
  return schema;
};
