import { useIntl } from 'react-intl';
import Container from '../../components/UI/Container';
import InfoScreen from '../../components/UI/InfoScreenHeader';

export const ChangesSaved = () => {
  const intl = useIntl();

  return (
    <Container dataPageTitle="changes-saved">
      <InfoScreen
        title={intl.formatMessage({ id: 'containers.changesSaved.title' })}
        subTitle={intl.formatMessage({ id: 'containers.changesSaved.text' })}
      />
    </Container>
  );
};
