import { ID, Url } from '@rossum/api-client';
import { CircularProgress, List, Stack, styled } from '@rossum/ui/material';
import { chain, intersection } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useLazyWorkspaces } from '../../../../../../../../business/workspaces';
import ListLoader from './ListLoader';
import { useIntersectionObserver } from './useIntersectionObserver';
import WorkspaceFolder from './WorkspaceFolder';

// TODO: Move this into more appropriate folder when finished (added search, polished UX)
const PREFIX = 'QueueSelection';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledStack = styled(Stack)(() => ({}));

type QueueSelectionProps = {
  value: Url[];
  onChange: (newValue: Url[]) => void;
  disabled?: boolean;
};

const QueueSelection = ({ value, onChange, disabled }: QueueSelectionProps) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const infiniteLoaderRef = useRef<HTMLLIElement | null>(null);

  const { data, status, hasNextPage, isFetching, fetchNextPage } =
    useLazyWorkspaces();

  useIntersectionObserver({
    target: infiniteLoaderRef,
    root: listRef,
    enabled: hasNextPage && !isFetching,
    threshold: 0.5,
    rootMargin: '150px',
    onIntersect: () => fetchNextPage(),
  });

  const valuesForWorkspaces = useMemo(() => {
    if (!data) {
      return {};
    }

    return chain(data)
      .pick('pages')
      .flatMapDeep(page => page.map(p => p.results))
      .map(ws => ({ id: ws.id, value: intersection(ws.queues, value) }))
      .keyBy(o => o.id)
      .value();
  }, [data, value]);

  const handleWorkspaceOnChange = useCallback(
    (workspaceId: ID, newValues: Url[]) => {
      // take current values from other workspaces and join them with newValue from this workspace
      const result = chain(valuesForWorkspaces)
        .filter(o => o.id !== workspaceId)
        .map(o => o.value)
        .flatten()
        .concat(newValues)
        .value();

      onChange(result);
    },
    [onChange, valuesForWorkspaces]
  );

  return (
    <StyledStack className={classes.root} spacing={3} alignItems="center">
      {status === 'loading' && <CircularProgress />}
      {status === 'success' && (
        <List
          sx={{
            width: 550,
            maxHeight: 550,
            overflowY: 'scroll',
            '& ul': { padding: 0 },
          }}
          disablePadding
          ref={listRef}
        >
          {data?.pages.map(page =>
            page.results.map(workspace => (
              <WorkspaceFolder
                key={workspace.id}
                workspace={workspace}
                value={valuesForWorkspaces[workspace.id]?.value ?? []}
                onChange={val => handleWorkspaceOnChange(workspace.id, val)}
                listRef={listRef}
                disabled={disabled}
              />
            ))
          )}
          {hasNextPage && <ListLoader ref={infiniteLoaderRef} />}
        </List>
      )}
    </StyledStack>
  );
};

export default QueueSelection;
