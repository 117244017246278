import {
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
  GridRowId,
} from '@rossum/ui/x-data-grid-pro';
import { Queue } from '../../../types/queue';

export const resolveQueuesDisplayedValue = (v: Array<Queue>, id: GridRowId) => {
  if (v.length > 1) {
    return `Connected queues (${id})`;
  }

  return v[0]?.name ?? '';
};

export const enumFormulaLabelsOperators = getGridStringOperators()
  .filter(
    operator =>
      !['equals', 'isAnyOf', 'endsWith', 'isEmpty', 'isNotEmpty'].includes(
        operator.value
      )
  )
  .map(operator => ({
    ...operator,
    getApplyFilterFnV7: (filterItem: GridFilterItem, column: GridColDef) => {
      const filterFunction =
        operator.getApplyFilterFnV7?.(filterItem, column) ?? null;

      return filterFunction === null
        ? null
        : (v: { label: string; count: number } | null): boolean => {
            // @ts-expect-error default string operators expect only first argument
            return filterFunction(v.label);
          };
    },
  }));
