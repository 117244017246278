import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models/queue';
import { queueSchema } from '../models/queue.schema';
import { queuePatchPayloadSchema } from './patch.schema';

export type QueuePatchPayload = Partial<Queue>;

export const patch = (queueId: ID, payload: QueuePatchPayload = {}) => {
  return {
    endpoint: `/queues/${queueId}`,
    method: 'PATCH',
    responseSchema: queueSchema,
    payload,
    payloadSchema: queuePatchPayloadSchema,
  } as const;
};
