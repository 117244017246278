// Generated by ts-to-zod
import { z } from 'zod';

export const generatePayloadPayloadSchema = z.object({
  action: z.string(),
  event: z.string(),
  annotation: z.string().optional(),
  previousStatus: z.string().optional(),
  status: z.string().optional(),
  email: z.string().optional(),
  upload: z.string().optional(),
});

export const generatePayloadResponseSchema = z.unknown();
