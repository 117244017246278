import { z } from 'zod';
import { Url } from '../../types';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { assignPayloadSchema } from './assign.schema';

export type AssignPayload = {
  annotations: Url[];
  assignees: Url[];
  noteContent?: string;
};

export const assign = (payload: AssignPayload) => {
  return {
    endpoint: `/annotations/assign`,
    method: 'POST',
    payload,
    responseSchema: z.unknown(),
    payloadSchema: assignPayloadSchema,
  } as const;
};
