import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Add } from '@rossum/ui/icons';
import { BackupRounded as BackupRoundedIcon } from '@rossum/ui/icons';
import { UploadFileRounded as UploadFileRoundedIcon } from '@rossum/ui/icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import { AllHTMLAttributes, ChangeEvent, ReactNode } from 'react';
import { isIE } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useGetInbox } from '../hooks/useGetInbox';
import { FileList } from './FileList';
import IEView from './IEView';
import SuggestInbox from './SuggestInbox';
import UploadArea from './UploadArea';

type Props = {
  open: boolean;
  onClose: () => void;
  fileList: File[];
  currentQueue: Queue | undefined;
  children: ReactNode;
  uploadInputProps: Omit<AllHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  inbox: string | null | undefined;
  removeFileFromList: (signature: string) => void;
  dialogActions?: ReactNode;
};

export const UploadDialog = ({
  open,
  onClose,
  fileList,
  currentQueue,
  children,
  uploadInputProps,
  inbox,
  removeFileFromList,
  dialogActions,
}: Props) => {
  const intl = useIntl();

  const inboxId = inbox ? getIDFromUrl(inbox) : 0;

  const { data: queueInbox, isInitialLoading: isInboxLoading } =
    useGetInbox(inboxId);

  const renderInboxSuggestion = queueInbox?.email && fileList.length === 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        sx: {
          minWidth: 800,
        },
        elevation: 8,
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Typography variant="h4">
            {intl.formatMessage({
              id: 'containers.annotationList.upload.title',
            })}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              ml: 'auto',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ p: 0, m: 0 }}>
        <Paper
          sx={{
            p: 3,
            width: '100%',
            borderRadius: '0px',
          }}
          elevation={2}
        >
          {isIE ? (
            <IEView mail={queueInbox?.email} />
          ) : (
            <Stack spacing={3}>
              {/* TODO: Would be nice to also make it possible to DND the files on the dialog when there are some files already */}
              {fileList.length === 0 ? (
                <Stack spacing={2}>
                  <UploadArea
                    fileList={fileList}
                    uploadInputProps={uploadInputProps}
                    DropAreaProps={{
                      dropAreaIcon: <BackupRoundedIcon fontSize="large" />,
                      dropAreaMessage: intl.formatMessage({
                        id: 'containers.annotationList.upload.dragndrop.v2',
                      }),
                      dropAreaOutlineColor: 'secondary.main',
                    }}
                    ButtonProps={{
                      variant: 'contained',
                      color: 'primary',
                      size: 'medium',
                      'data-cy': 'upload-dialog-choose-files-button',
                      startIcon: <UploadFileRoundedIcon />,
                    }}
                    sx={{ py: 4 }}
                  />
                  {isInboxLoading ? (
                    <Stack justifyContent="center" alignItems="center">
                      <Skeleton variant="rounded" width="80%" height={120} />
                    </Stack>
                  ) : (
                    renderInboxSuggestion && (
                      <Stack spacing={2} alignItems="center">
                        <Typography variant="h5" color="text.secondary">
                          {intl.formatMessage({
                            id: 'containers.annotationList.upload.suggestInbox.v2',
                          })}
                        </Typography>
                        <SuggestInbox
                          inboxEmail={queueInbox.email}
                          queueId={currentQueue?.id}
                          isLoading={false}
                          showHelpArticle={false}
                        />
                      </Stack>
                    )
                  )}
                </Stack>
              ) : (
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <FileList
                      fileList={fileList}
                      removeFileFromList={removeFileFromList}
                      selectedQueue={currentQueue}
                    />
                    <Button
                      variant="text"
                      color="secondary"
                      size="medium"
                      data-cy="upload-dialog-add-files-btn"
                      startIcon={<Add />}
                      component="label"
                      sx={{ width: 'fit-content', alignSelf: 'end' }}
                    >
                      <Typography fontSize="small">
                        {intl.formatMessage({
                          id: 'containers.annotationList.upload.addFiles.button.label',
                        })}
                      </Typography>
                      <Input
                        inputProps={uploadInputProps}
                        sx={{
                          display: 'none',
                        }}
                      />
                    </Button>
                  </Stack>
                </Stack>
              )}
              {children}
            </Stack>
          )}
        </Paper>
      </DialogContent>
      {fileList.length > 0 && dialogActions ? dialogActions : null}
    </Dialog>
  );
};
