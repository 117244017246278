import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import {
  handleAuthError,
  handleThrottleError,
  reportMutationError,
  reportQueryError,
} from './apiHelpers';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (e, query) => {
      const disableReportingFunction = query.meta?.disableReporting;
      const disableReporting =
        typeof disableReportingFunction === 'function' &&
        disableReportingFunction(query);

      handleThrottleError(e);
      handleAuthError(e);

      if (!disableReporting) {
        return reportQueryError(e, query);
      }
      return undefined;
    },
  }),
  mutationCache: new MutationCache({
    onError: (e, _variables, _context, mutation) => {
      handleThrottleError(e);
      handleAuthError(e);
      return reportMutationError(e, { mutation });
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
