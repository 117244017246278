import { resolveTheme } from '@rossum/rossum-ui/theme';
import {
  alpha,
  Dialog,
  Stack,
  Theme,
  ThemeProvider,
  Typography,
} from '@rossum/ui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganization } from '../../redux/modules/organization/actions';
import { userSelector } from '../../redux/modules/user/selectors';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { onboardingData } from './surveyData';
import TrialOnboardingSurvey from './TrialOnboardingSurvey';
import { WelcomeScreen } from './WelcomeScreen';

// shared across registration app and Elis as background during onboarding
const BG_1920 =
  'https://rossum-public-resources.app.rossum.ai/images/app_blur_1920.png';
const BG_1440 =
  'https://rossum-public-resources.app.rossum.ai/images/app_blur_1440.png';

const backdropStyle = {
  backgroundColor: (theme: Theme) =>
    alpha(
      theme.palette.mode !== 'dark'
        ? theme.palette.common.black
        : theme.palette.common.white,
      0.85
    ),
  backgroundImage: `url(${BG_1440})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  '@media only screen and (min-width: 1440px)': {
    backgroundImage: `url(${BG_1920})`,
  },
};

const TrialOnboarding = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const surveyEnabled = useFeatureFlag('ac-661-survey');

  const isSurveyComplete =
    user.uiSettings.onboardingSurvey?.activeStep === 'what-is-your-role';

  const shouldDisplaySurvey = surveyEnabled && !isSurveyComplete;

  const stepData = shouldDisplaySurvey
    ? onboardingData.surveySteps['what-is-your-role']
    : onboardingData.onboardingSteps.complete;

  useEffect(() => {
    dispatch(
      updateOrganization({
        metadata: {
          source: user.username.includes('@rossum.ai')
            ? 'internal'
            : 'registration',
        },
      })
    );
  }, [dispatch, user.username]);

  return (
    <ThemeProvider theme={() => resolveTheme('dark')}>
      <Dialog
        BackdropProps={{
          sx: backdropStyle,
        }}
        PaperProps={{
          sx: {
            minHeight: '100%',
            width: '100%',
            background: 'transparent',
            pt: { xs: 8, xl: 15 },
            pb: 8,
            px: 2,
            m: 0,
          },
        }}
        open
        maxWidth={false}
        data-cy="add-first-queue-modal"
      >
        <Stack
          alignItems="center"
          spacing={{ xs: 2, xl: 4 }}
          sx={{ width: '100%' }}
        >
          <Stack sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="h3">
              {intl.formatMessage({ id: stepData.titleId })}
            </Typography>
            <Typography variant="body1">
              {intl.formatMessage({ id: stepData.subtitleId })}
            </Typography>
            {'noteId' in stepData && (
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({ id: stepData.noteId })}
              </Typography>
            )}
          </Stack>

          {shouldDisplaySurvey ? <TrialOnboardingSurvey /> : <WelcomeScreen />}
        </Stack>
      </Dialog>
    </ThemeProvider>
  );
};

export default TrialOnboarding;
