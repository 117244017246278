import { Stack } from '@rossum/ui/material';
import ChartLineIcon from 'mdi-react/ChartLineIcon';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';

const exportedDependentGraphs = [
  'delay',
  'correctionsPerDocumentAvg',
  'turnaroundAvgS',
  'timePerDocumentAvgS',
];

type Props =
  | {
      inGraph: true;
      graphName: string;
    }
  | {
      inGraph: false;
    };

const EmptySeries = (props: Props) => (
  <Stack width="100%">
    <InfoPlaceholder
      icon={<ChartLineIcon />}
      inGraph={props.inGraph}
      title="containers.statistics.noData"
      text={
        props.inGraph && exportedDependentGraphs.includes(props.graphName)
          ? 'containers.statistics.noDataInGraph.text'
          : 'containers.statistics.noData.text'
      }
    />
  </Stack>
);

export default EmptySeries;
