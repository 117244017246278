// Generated by ts-to-zod
import { z } from 'zod';
import { annotationStatusSchema } from '../../shared/models/annotationStatus.schema';
import { urlSchema } from '../../utils/codecUtils.schema';

export const parentPageSchema = z.object({
  page: urlSchema,
  rotationDeg: z.number(),
});

export const editDataSourceSchema = z.union([
  z.literal('automation'),
  z.literal('suggest'),
  z.literal('modified_suggest'),
  z.literal('manual'),
]);

export const accessibleChildAnnotationSchema = z.object({
  url: urlSchema,
  queue: urlSchema,
  status: annotationStatusSchema,
  started: z.boolean(),
  originalFileName: z.string(),
  parentPages: z.array(parentPageSchema),
});

export const inaccessibleChildAnnotationSchema = z.object({
  started: z.literal(false),
  originalFileName: z.string(),
  parentPages: z.array(parentPageSchema),
});

export const childAnnotationSchema = z.union([
  accessibleChildAnnotationSchema,
  inaccessibleChildAnnotationSchema,
]);

export const startEditPagesResponseSchema = z.object({
  parentAnnotation: urlSchema,
  sessionTimeout: z.string(),
  children: z.array(childAnnotationSchema),
});
