import { getIDFromUrl } from '@rossum/api-client';
import { Page } from '@rossum/api-client/pages';
import {
  PageDatapoints,
  PageImage,
  PageSvg,
  PageText,
} from '@rossum/document-canvas';
import { FlatContent } from './hooks/flattenContent';
import { FlatSchema } from './hooks/flattenSchema';
import { usePagePreview } from './hooks/usePagePreview';
import { usePageText } from './hooks/usePageText';
import { useSelectDatapointRectangle } from './hooks/useSelectDatapointRectangle';

type ConnectedPageSvgProps = {
  datapoints: FlatContent[];
  page: Page;
  pageDimensions: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  activeDatapointId: number | undefined;
  setActiveDatapointId: (datapoint: number | undefined) => void;
  schemaMap: Record<string, FlatSchema>;
};

export const ConnectedPageSvg = ({
  datapoints,
  pageDimensions,
  page,
  activeDatapointId,
  setActiveDatapointId,
  schemaMap,
}: ConnectedPageSvgProps) => {
  const { data: pageImage } = usePagePreview({ contentUrl: page.content });
  const { data: pageText } = usePageText({
    annotationUrl: page.annotation,
    pageNumber: page.number,
  });
  const { mutate: selectDatapointRectangle } = useSelectDatapointRectangle();

  return (
    <PageSvg pageDimensions={pageDimensions}>
      <PageImage href={pageImage} />
      {pageText ? (
        <PageText
          pageText={pageText}
          onSelect={rectangle => {
            if (activeDatapointId) {
              selectDatapointRectangle({
                annotationId: getIDFromUrl(page.annotation),
                activeDatapoint: activeDatapointId,
                page: page.url,
                rectangle,
              });
            }
          }}
        />
      ) : null}
      <PageDatapoints
        datapoints={datapoints
          .filter(
            dp =>
              dp.type === 'datapoint' &&
              dp.content?.page === page.number &&
              dp.content?.position &&
              schemaMap[dp.schemaId]?.hidden !== true
          )
          .map(dp => ({
            id: dp.id,
            fill: '#00ff00',
            position: dp.content!.position!,
          }))}
        onMouseDown={setActiveDatapointId}
      />
    </PageSvg>
  );
};
