import {
  DetailDrawer,
  DetailDrawerProps,
} from '@rossum/rossum-ui/DetailDrawer';
import { Add, Edit } from '@rossum/ui/icons';
import { CircularProgress, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isTrialSelector } from '../../../../redux/modules/organization/selectors';
import { useTemplateTrigger } from '../hooks/useTemplateTrigger';
import EmailTemplateForm from './EmailTemplateForm';
import { EmailTemplateDrawerAction } from './helpers';
import { TrialLimited } from './TrialLimited';

type EmailTemplateDrawerProps = {
  DrawerProps: DetailDrawerProps;
  action: EmailTemplateDrawerAction | null;
  queueUrl: string;
};

const EmailTemplateDrawer = ({
  action,
  queueUrl,
  DrawerProps: { open, onClose, sx, ...restDrawerProps },
}: EmailTemplateDrawerProps) => {
  const intl = useIntl();
  const actionTypeUpdate = action && action.type === 'update';

  const { data: trigger, isInitialLoading: isTriggerLoading } =
    useTemplateTrigger(
      actionTypeUpdate ? action.emailTemplate.triggers[0] : undefined
    );
  const isTrial = useSelector(isTrialSelector);

  const emailTemplateId = actionTypeUpdate
    ? action.emailTemplate.id
    : undefined;

  return (
    // TODO: This will get fixed when elevations are refactored and then drawer is adjusted in RUI
    <DetailDrawer
      {...restDrawerProps}
      PaperProps={{
        elevation: 2,
      }}
      title={
        <Stack direction="row" spacing={2} alignItems="center">
          {actionTypeUpdate ? <Edit /> : <Add />}
          <Typography variant="h5">
            {intl.formatMessage({
              id: `components.editTemplateDrawer.${
                actionTypeUpdate ? 'edit' : 'create'
              }.title`,
            })}
          </Typography>
          {emailTemplateId ? (
            <Typography color="text.secondary">
              {intl.formatMessage(
                {
                  id: 'components.editTemplateDrawer.edit.templateId',
                },
                { id: emailTemplateId }
              )}
            </Typography>
          ) : null}
        </Stack>
      }
      open={open}
      keepMounted={false}
      onClose={onClose}
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        {isTrial && <TrialLimited />}
        {action && !isTriggerLoading ? (
          <EmailTemplateForm
            queueUrl={queueUrl}
            action={action}
            trigger={trigger ?? null}
            onClose={() => onClose?.({}, 'backdropClick')}
            isTrial={!!isTrial}
          />
        ) : (
          <Stack alignItems="center" justifyContent="center" sx={{ py: 5 }}>
            <CircularProgress size={48} />
          </Stack>
        )}
      </Stack>
    </DetailDrawer>
  );
};

export default EmailTemplateDrawer;
