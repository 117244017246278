import { findIndex } from 'lodash';
import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Users } from '../../../types/users';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { initialPagination } from '../utils';
import {
  clearUsers,
  deleteUserFulfilled,
  fetchActiveUsersCountFulfilled,
  fetchAdminsCountFulfilled,
  fetchAllUsersFulfilled,
  fetchUserDetailFulfilled,
  fetchUsersFulfilled,
  updateUserDetail,
} from './actions';

const initialState = Immutable<Users>({
  list: [],
  pagination: initialPagination,
  activeUsersCount: undefined,
  adminsCount: undefined,
  loaded: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAllUsersFulfilled): {
      const {
        meta: { isFirstBatch, loaded },
        payload,
      } = action;
      const newState = isFirstBatch
        ? state.set('list', payload)
        : state.update('list', list => [...list, ...payload]);

      return newState.set('loaded', loaded);
    }
    case getType(fetchUsersFulfilled):
      return state
        .set('pagination', action.payload.pagination)
        .set('list', action.payload.results);

    case getType(fetchAdminsCountFulfilled):
      return state.set('adminsCount', action.payload);

    case getType(fetchUserDetailFulfilled):
      return initialState.set('list', [action.payload]);

    case getType(updateUserDetail): {
      const { id } = action.meta;
      const index = findIndex(state.list, { id });

      // TODO what if index is -1?
      return state.updateIn(['list', index], user =>
        user.merge(action.payload, { deep: true })
      );
    }

    case getType(fetchActiveUsersCountFulfilled):
      return state.set('activeUsersCount', action.payload);

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
    case getType(clearUsers):
      return initialState;

    case getType(deleteUserFulfilled):
      return state.update('list', list =>
        list.filter(user => user.id !== action.payload.id)
      );

    default:
      return state;
  }
};

export default reducer;
