// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const relationTypeSchema = z.union([
  z.literal('edit'),
  z.literal('duplicate'),
  z.literal('attachment'),
]);

export const relationBaseSchema = z.object({
  url: urlSchema,
  id: z.number(),
  key: z.string().nullable(),
  annotations: z.array(urlSchema),
});

export const editRelationSchema = relationBaseSchema.and(
  z.object({
    type: z.literal('edit'),
    parent: urlSchema,
  })
);

export const duplicateRelationSchema = relationBaseSchema.and(
  z.object({
    type: z.literal('duplicate'),
    parent: urlSchema.nullable(),
  })
);

export const attachmentRelationSchema = relationBaseSchema.and(
  z.object({
    type: z.literal('attachment'),
    parent: urlSchema.nullable(),
  })
);

export const relationSchema = z.union([
  editRelationSchema,
  duplicateRelationSchema,
  attachmentRelationSchema,
]);
