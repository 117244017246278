import { compact } from 'lodash';
import { Form } from './formTypes';

export const joinElementsByNewline = (
  emailList: Array<string> | null
): string => emailList?.join('\r\n') ?? '';

export const joinElementsWithComma = (
  mimeTypes: Array<string> | null
): string => mimeTypes?.join(', ') ?? '';

export const parseElements = (mimeTypes: string) =>
  compact(mimeTypes.split(/[ ,]+/));

export const formatMimeTypeList = (mimeTypeList: string) =>
  joinElementsWithComma(parseElements(mimeTypeList));

export const formatFileNameList = (fileNameList: string) =>
  joinElementsByNewline(parseElementsSeparatedByNewline(fileNameList));

export const parseElementsSeparatedByNewline = (stringAddresses: string) =>
  compact(stringAddresses.split(/\r\n|\n|\n\r+/g));

export const parseEmailAddresses = (stringAddresses: string) =>
  compact(stringAddresses.split(/,| |\r\n|\n|\n\r+/g));

export const sanitizeValuesToCompare = (data: Partial<Form>): Partial<Form> => {
  const {
    whiteList,
    blackList,
    whiteListEnabled,
    blackListEnabled,
    ...restWatchValues
  } = data;
  return {
    whiteList: whiteListEnabled ? whiteList : '',
    blackList: blackListEnabled ? blackList : '',
    ...restWatchValues,
  };
};

export const parseEmailList = (emailsString: string, condition: boolean) =>
  condition ? parseEmailAddresses(emailsString) : [];

export const formatEmailList = (
  emailList: string,
  shouldReturnList: boolean
) =>
  shouldReturnList ? joinElementsByNewline(parseEmailAddresses(emailList)) : '';
