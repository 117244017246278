import { LogsQuery } from '@rossum/api-client/hooks';
import { ArrowDropDown, ArrowDropUp, Close } from '@rossum/ui/icons';
import { FormControl, IconButton, Stack, TextField } from '@rossum/ui/material';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import Datepicker from '../../../../components/Datepicker';
import {
  convertFromUTC,
  convertToUTC,
  formatDateHoursSecondsToQuery,
  formatDateTimeWithSecondsPickerValue,
} from '../../../../components/Datepicker/helpers';
import {
  activeLabelStyle,
  activeOutlineStyle,
} from '../../../../components/UI/SingleQueueSelect/styles';

type Props = {
  filters: LogsQuery;
  setFilters: (filters: LogsQuery) => void;
  width: number;
};

const DatepickerFilters = ({ filters, setFilters, width }: Props) => {
  const intl = useIntl();
  const beginDate = filters.timestampAfter
    ? convertFromUTC(new Date(filters.timestampAfter))
    : undefined;
  const endDate = filters.timestampBefore
    ? convertFromUTC(new Date(filters.timestampBefore))
    : undefined;

  const dateFilterActive = !!(beginDate && endDate);

  const datepickerProps = {
    showHours: true,
    beginDate,
    endDate,
    onConfirm: ({ from, to }: { from: Date; to: Date }) => {
      return setFilters({
        ...filters,
        timestampAfter: formatDateHoursSecondsToQuery(convertToUTC(from)),
        timestampBefore: formatDateHoursSecondsToQuery(convertToUTC(to)),
      });
    },
    applyButtonTitle: 'applyButton',
    withSeconds: true,
  } as const satisfies Partial<ComponentProps<typeof Datepicker>>;

  const getCurrentValue = (visible: boolean) => {
    return dateFilterActive
      ? `${formatDateTimeWithSecondsPickerValue(
          beginDate,
          endDate,
          intl.locale
        )}`
      : visible
        ? ' '
        : '';
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Datepicker
        key={`${beginDate?.toISOString()}${endDate?.toISOString()}`}
        {...datepickerProps}
        DatePanel={({ visible, setVisible }) => (
          <FormControl variant="outlined" sx={{ width }}>
            <TextField
              type="text"
              size="small"
              autoComplete="off"
              data-cy="new-password-input"
              label={intl.formatMessage({
                id: 'containers.settings.extensions.logs.filters.extension.dateRange',
              })}
              value={getCurrentValue(visible)}
              InputLabelProps={{
                sx: visible ? activeLabelStyle : {},
              }}
              sx={visible ? activeOutlineStyle : {}}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack direction="row" alignItems="center">
                    {dateFilterActive ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const {
                            timestampAfter,
                            timestampBefore,
                            ...filtersWithoutDateRange
                          } = filters;

                          setVisible(false);

                          setFilters(filtersWithoutDateRange);
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    ) : null}
                    {visible ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Stack>
                ),
              }}
            />
          </FormControl>
        )}
      />
    </Stack>
  );
};

export default DatepickerFilters;
