import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Schema } from '../models/schema';
import { schemaSchema } from '../models/schema.schema';
import { schemaListQuerySchema } from './list.schema';

export type SchemaListQuery = {
  id?: number;
  name?: string;
};

export const list = (query: SchemaListQuery = {}) => {
  return {
    endpoint: `/schemas`,
    method: 'GET',
    responseSchema: listResponse(schemaSchema),
    query,
    querySchema: schemaListQuerySchema,
  } as const;
};
