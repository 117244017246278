import { endpoints, getIDFromUrl } from '@rossum/api-client';
import {
  EditPagesPayload,
  EditPagesResponse,
} from '@rossum/api-client/annotations';
import { useMutation } from '@tanstack/react-query';
import { isEmbedded } from '../../constants/config';
import { api } from '../../lib/apiClient';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { ProcessingDuration, timeSpent } from '../../timeSpent/timeSpent';
import {
  areSuggestedSplitsActive,
  EditDocumentConfig,
  EditState,
  hasNoVisiblePages,
  hasSameSequenceOfVisiblePages,
  isEditable,
  Part,
  PartWithAccessibleAnnotation,
  PartWithoutAnnotation,
} from './editState';

type EditOperation = EditPagesPayload['edit'][number];

const toEditOperation = (
  part: PartWithAccessibleAnnotation | PartWithoutAnnotation
): EditOperation => {
  const parentPages = part.pages
    .filter(p => !p.deleted)
    .map(p => ({ rotationDeg: p.rotationDeg, page: p.url }));

  if (part.annotationType === 'accessible')
    return {
      targetQueue: part.targetQueueUrl,
      parentPages,
    };

  return {
    targetQueue: part.targetQueueUrl,
    parentPages,
  };
};

type MoveOperation = EditPagesPayload['move'][number];

const toMoveOperation = (
  part: PartWithAccessibleAnnotation
): MoveOperation => ({
  annotation: part.annotation.url,
  targetQueue: part.targetQueueUrl,
});

const determineOperationForPart = (part: Part, config: EditDocumentConfig) => {
  if (part.annotationType === 'inaccessible') return null;

  // couldn't be changed
  if (!isEditable(part)) return null;

  if (hasNoVisiblePages(part)) return null;

  if (part.annotationType === 'accessible') {
    const { annotation } = part;

    // The annotation can't be changed or added later, so we are sure such a part exists
    const correspondingPartInInitialState = config.initialParts.find(
      (p): p is PartWithAccessibleAnnotation =>
        p.annotationType === 'accessible' && p.annotation === annotation
    )!;

    const pagesAreChanged = !hasSameSequenceOfVisiblePages(
      part,
      correspondingPartInInitialState
    );
    const targetQueueHasChanged =
      correspondingPartInInitialState.targetQueueUrl !== part.targetQueueUrl;

    if (pagesAreChanged) {
      return toEditOperation(part);
    }

    if (targetQueueHasChanged) {
      return toMoveOperation(part);
    }

    // unchanged
    return null;
  }

  // new part
  return toEditOperation(part);
};

const collectAnnotationsToDelete = (editState: EditState) => {
  return editState.config.initialParts
    .filter(
      (p): p is PartWithAccessibleAnnotation =>
        p.annotationType === 'accessible' &&
        p.annotation.started &&
        // The original document should never be sent to delete
        p.annotation.url !== editState.config.annotationUrl
    )
    .flatMap(initialPart => {
      const currentPartWithTheSameAnnotation = editState.parts.find(
        pp =>
          pp.annotationType === 'accessible' &&
          pp.annotation.url === initialPart.annotation.url
      );

      if (currentPartWithTheSameAnnotation) {
        if (
          hasSameSequenceOfVisiblePages(
            initialPart,
            currentPartWithTheSameAnnotation
          )
        ) {
          // Nothing has changed, we can keep the annotation
          return [];
        }

        // The pages have changed, we have to delete the annotation,
        // since a new one will be created.
        return [initialPart.annotation.url];
      }

      // There is no part with the same annotation, so its pages will be used
      // in new annotations. We have to delete it.
      return [initialPart.annotation.url];
    });
};

export const buildConfirmPayload = (
  editState: EditState,
  processingDuration: ProcessingDuration | undefined
): EditPagesPayload => {
  const operations = editState.parts
    .map(part => determineOperationForPart(part, editState.config))
    .filter(isNotNullOrUndefined);

  const annotationsToDelete = collectAnnotationsToDelete(editState);

  const annotationsToStop = editState.config.initialParts.flatMap(p =>
    p.annotationType === 'accessible' &&
    p.annotation.started &&
    // No need to stop annotations which will be deleted
    !annotationsToDelete.includes(p.annotation.url) &&
    // There is a separate stopParent flag for stopping the original annotation
    p.annotation.url !== editState.config.annotationUrl
      ? [p.annotation.url]
      : []
  );

  return {
    delete: annotationsToDelete,
    move: operations.filter(
      (op): op is MoveOperation => !('parentPages' in op)
    ),
    edit: operations.filter((op): op is EditOperation => 'parentPages' in op),
    // TODO
    stopParent: false,
    stopReviewing: annotationsToStop,
    processingDuration,
  };
};

const shouldOnlyMoveAnnotation = (editState: EditState) => {
  // When editing a parent annotation, edit_pages endpoint will handle everything
  if (editState.config.mode.type === 'parent-annotation') return undefined;

  const initialPart = editState.config.initialParts[0];
  const part = editState.parts[0];

  // Check if the state is valid - this should be always true for single/child annotation
  if (
    part &&
    initialPart &&
    editState.config.initialParts.length === 1 &&
    initialPart.annotationType === 'accessible' &&
    // User can get type `none` by splitting and then reverting the operation
    (part.annotationType === 'accessible' || part.annotationType === 'none')
  ) {
    if (
      hasSameSequenceOfVisiblePages(part, initialPart) &&
      initialPart.targetQueueUrl !== part.targetQueueUrl
    ) {
      return endpoints.annotations.patch(
        getIDFromUrl(initialPart.annotation.url),
        { queue: part.targetQueueUrl }
      );
    }
  }

  return undefined;
};

export const useConfirmEditMode = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  return useMutation({
    mutationFn: (editState: EditState) => {
      const annotationId = getIDFromUrl(editState.config.annotationUrl);

      // Split parent won't have timer started, so undefined is a valid result
      const processingDuration = timeSpent.stopAnnotation(annotationId);

      const deletingAnnotation =
        (editState.config.mode.type === 'single-annotation' ||
          editState.config.mode.type === 'child-annotation') &&
        editState.parts.every(p => p.pages.every(page => page.deleted));

      if (deletingAnnotation) {
        return api
          .request(
            endpoints.annotations.deleteEndpoint(
              getIDFromUrl(editState.config.annotationUrl)
            )
          )
          .then<EditPagesResponse>(() => ({ results: [] }));
      }

      const moveRequest = shouldOnlyMoveAnnotation(editState);

      if (moveRequest) {
        return api.request(moveRequest).then<EditPagesResponse>(annotation => ({
          results: [
            { annotation: annotation.url, document: annotation.document },
          ],
        }));
      }

      const editDataSource =
        editState.config.initialPartsWithSuggestedSplits !== null
          ? 'manual'
          : areSuggestedSplitsActive(editState.parts, editState.config)
            ? 'suggest'
            : 'modified_suggest';

      // In order to continue working with a document with only deleted/rotated pages,
      // we have to use in-place endpoint in embedded mode, so that annotation ID is preserved.
      const firstPart = editState.parts[0];
      const inPlace =
        (isEmbedded() || editState.config.settings.preferInPlaceSplitting) &&
        editState.parts.length === 1;

      if (firstPart && inPlace) {
        return api.request(
          endpoints.annotations.editPagesInPlace(annotationId, {
            processingDuration: processingDuration?.processingDuration,
            parentPages: firstPart.pages.map(p => ({
              rotationDeg: p.rotationDeg,
              page: p.url,
            })),
            editDataSource,
          })
        );
      }

      const confirmPayload = buildConfirmPayload(
        editState,
        processingDuration?.processingDuration
      );

      return api.request(
        endpoints.annotations.editPages(annotationId, {
          ...confirmPayload,
          editDataSource,
        })
      );
    },

    onSuccess,
  });
};
