import { format, isAfter, isBefore, isFuture, isValid, parse } from 'date-fns';

const QUERY_FORMAT = 'yyyy-MM-dd';

export const formatDateQuery = (date: Date) => format(date, QUERY_FORMAT);

export const parseDateQuery = (date: string) =>
  parse(date, QUERY_FORMAT, new Date());

type QueryT = string | string[];
type DefaultDates = {
  defaultBeginDate: Date;
  defaultEndDate: Date;
};

export const validateDateQuery = (
  beginDate: QueryT | null,
  endDate: QueryT | null,
  { defaultBeginDate, defaultEndDate }: DefaultDates
) => {
  const parsedBegin = parseDateQuery(beginDate as string);
  const parsedEnd = parseDateQuery(endDate as string);
  const areDatesValid =
    isValid(parsedBegin) &&
    !isAfter(parsedBegin, parsedEnd) &&
    isAfter(parsedBegin, new Date(1999, 11, 31)) &&
    isValid(parsedEnd) &&
    !isBefore(parsedEnd, parsedBegin) &&
    !isFuture(parsedEnd);

  if (areDatesValid) {
    return {
      beginDate,
      endDate,
    };
  }

  return {
    beginDate: formatDateQuery(defaultBeginDate),
    endDate: formatDateQuery(defaultEndDate),
  };
};
