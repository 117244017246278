import { darken, styled } from '@rossum/ui/material';

const direction = [
  'top-right',
  'bottom-right',
  'bottom-left',
  'top-left',
] as const;

type Direction = (typeof direction)[number];

/**
 * This is a presentational component that only renders a circular corner handle
 * It needs to be used within a PageSpaceContext that provides transformational functions
 */
const OuterCircle = styled('circle', {
  shouldForwardProp: propName => propName !== 'color',
})<{ color?: string }>(({ theme, color }) => ({
  fill: 'transparent',
  pointerEvents: 'all',
  [`& ~ circle`]: {
    fill: color ?? theme.palette.primary.main,
    pointerEvents: 'none',
  },
  [`&:hover ~ circle`]: {
    // IBB: Fix colors
    fill: color ? darken(color, 0.5) : theme.palette.primary.dark,
  },
}));

const cornerHandleProps: Record<
  Direction,
  React.SVGAttributes<SVGCircleElement>
> = {
  'top-left': {
    style: {
      cursor: 'nwse-resize',
    },
  },
  'top-right': {
    cx: '100%',
    cy: 0,
    style: {
      cursor: 'nesw-resize',
    },
  },
  'bottom-left': {
    cx: 0,
    cy: '100%',
    style: {
      cursor: 'nesw-resize',
    },
  },
  'bottom-right': {
    cx: '100%',
    cy: '100%',
    style: {
      cursor: 'nwse-resize',
    },
  },
};

type CornerHandleProps = React.SVGProps<SVGCircleElement> & {
  direction: Direction;
  isDragging: boolean;
  color?: string;
  strokeWidth: number;
  radius: number;
};

export const CornerHandle = ({
  direction,
  isDragging,
  color,
  strokeWidth,
  radius,
  ...circleProps
}: CornerHandleProps) => {
  return (
    <g>
      <OuterCircle
        r={2 * radius}
        {...cornerHandleProps[direction]}
        color={color}
        style={{
          ...(cornerHandleProps[direction].style ?? {}),
        }}
        {...circleProps}
      />
      <circle
        vectorEffect="non-scaling-stroke"
        r={radius}
        {...cornerHandleProps[direction]}
        strokeWidth={strokeWidth}
      />
    </g>
  );
};
