// Generated by ts-to-zod
import { z } from 'zod';

export const dedicatedEngineListQuerySchema = z.object({
  pageSize: z.number().optional(),
  page: z.number().optional(),
  fields: z
    .array(
      z.union([
        z.literal('id'),
        z.literal('name'),
        z.literal('url'),
        z.literal('status'),
        z.literal('schema'),
        z.literal('description'),
      ])
    )
    .optional(),
  ordering: z
    .array(
      z.union([
        z.literal('id'),
        z.literal('name'),
        z.literal('url'),
        z.literal('status'),
        z.literal('schema'),
        z.literal('description'),
        z.literal('-id'),
        z.literal('-name'),
        z.literal('-url'),
        z.literal('-status'),
        z.literal('-schema'),
        z.literal('-description'),
      ])
    )
    .optional(),
  id: z.union([z.number(), z.array(z.number())]).optional(),
  name: z.union([z.number(), z.array(z.number())]).optional(),
  url: z.union([z.number(), z.array(z.number())]).optional(),
  status: z.union([z.number(), z.array(z.number())]).optional(),
  schema: z.union([z.number(), z.array(z.number())]).optional(),
  description: z.union([z.number(), z.array(z.number())]).optional(),
});
