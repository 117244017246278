import { connect } from 'react-redux';
import {
  areDatapointsValidSelector,
  datapointPathSelector,
  isDeleteRecommendationInPathSelector,
  validationInProgressSelector,
} from '../../../redux/modules/datapoints/selector';
import {
  openModal as openModalAction,
  OpenModalParameters,
} from '../../../redux/modules/modal/actions';
import {
  SetNavigateToNext,
  setNavigateToNext as setNavigateToNextAction,
} from '../../../redux/modules/ui/actions';
import { Status } from '../../../types/annotationStatus';
import { State } from '../../../types/state';
import ExportButton from './ExportButton';
import ExportingButton from './ExportingButton';
import FailedExportButton from './FailedExportButton';
import ReadOnlyButton from './ReadOnlyButton';
import ValidationInProgressButton from './ValidationInProgressButton';

type Props = {
  areDatapointsValid: boolean;
  confirming: boolean;
  displayAnnotationNavigation: boolean;
  exportButtonFocused: boolean;
  navigateToNext: boolean;
  onDocumentConfirm: () => void;
  openModal: (params: OpenModalParameters) => void;
  readOnly: boolean;
  setNavigateToNext: SetNavigateToNext;
  status: Status | undefined;
  validationInProgress: boolean;
};

const FooterButtons = ({
  areDatapointsValid,
  confirming,
  displayAnnotationNavigation,
  exportButtonFocused,
  navigateToNext,
  onDocumentConfirm,
  openModal,
  readOnly,
  setNavigateToNext,
  status,
  validationInProgress,
}: Props) => {
  if (confirming) {
    return <ExportingButton />;
  }

  if (status === 'failedExport') {
    return <FailedExportButton />;
  }

  if (readOnly) {
    return <ReadOnlyButton />;
  }

  // Status is undefined iff annotation is loading
  if (validationInProgress || !status) {
    return <ValidationInProgressButton />;
  }

  const setNavigationToNext = () => {
    setNavigateToNext(!navigateToNext);
  };
  const onDocumentConfirmByEnter = () => {
    openModal({ textId: 'annotationConfirm', onConfirm: onDocumentConfirm });
  };

  return (
    <ExportButton
      displayAnnotationNavigation={displayAnnotationNavigation}
      documentIsValid={areDatapointsValid}
      focused={exportButtonFocused}
      navigateToNext={navigateToNext}
      onDocumentConfirm={onDocumentConfirm}
      onDocumentConfirmByEnter={onDocumentConfirmByEnter}
      setNavigationToNext={setNavigationToNext}
    />
  );
};

const mapStateToProps = (state: State) => ({
  areDatapointsValid: areDatapointsValidSelector(state),
  exportButtonFocused:
    areDatapointsValidSelector(state) &&
    !datapointPathSelector(state).length &&
    !isDeleteRecommendationInPathSelector(state),
  navigateToNext: state.ui.navigateToNext,
  validationInProgress: validationInProgressSelector(state),
});

const mapDispatchToProps = {
  setNavigateToNext: setNavigateToNextAction,
  openModal: openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterButtons);
