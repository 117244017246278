import { CircularProgress } from '@rossum/ui/material';
import clsx from 'clsx';
import ClearIcon from 'mdi-react/CloseCircleIcon';
import SearchIcon from 'mdi-react/MagnifyIcon';
import { LocalizationKeys } from '../../../i18n';
import Input from '../Input';
import styles from './styles.module.sass';

type Props = {
  value: string | string[];
  onChange: (value: string) => void;
  className?: string;
  placeholder?: LocalizationKeys;
  size?: 'small' | 'large';
  loading?: boolean;
};

/**
 * @deprecated Use src/ui/SearchInput
 */
const SearchInput = ({
  value,
  onChange,
  className,
  placeholder,
  size = 'large',
  loading,
}: Props) => (
  <div
    className={clsx(
      styles.SearchWrapper,
      className,
      size === 'small' && styles[size]
    )}
  >
    <Input
      dataCy="search-input"
      value={Array.isArray(value) ? value.join('') : value}
      className={clsx(styles.SearchInput, value && styles.FilledSearch)}
      onChange={onChange}
      placeholder={placeholder}
      leftIcon={<SearchIcon />}
      leftIconProps={{ className: styles.SearchIcon }}
      rightIcon={
        loading ? (
          <CircularProgress
            size={30}
            color="inherit"
            sx={{
              svg: { width: 'auto !important', height: 'auto !important' },
            }}
          />
        ) : (
          value && <ClearIcon />
        )
      }
      rightIconProps={{
        onClick: () => onChange(''),
        className: styles.ClearIcon,
      }}
    />
  </div>
);

SearchInput.defaultProps = {
  className: '',
  placeholder: 'components.dashboard.queues.search',
  size: 'large',
};

export default SearchInput;
