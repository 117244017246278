import {
  BillingHistory,
  BillingHistoryListResponse,
} from '@rossum/api-client/billing';
import { Stack } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BILLING_OVERVIEW_QUERY } from '../../../redux/modules/localStorage/actions';
import { useStoredState } from '../../../utils/hooks/useStoredState';
import { hasSubscriptionPlanExistSelector } from '../../pricing/selectors';
import { getUsedPercentage, getVolumeFromTotals } from '../helpers';
import { BillingEntity } from '../hooks/useBillingEntity';
import AggregateUsage from './AggregateUsage';
import { AiEnginesUsageProgress } from './AiEnginesUsageProgress';
import BillingPeriodPicker from './BillingPeriodPicker';
import BillingPeriodProgress from './BillingPeriodProgress';
import LabeledValueInRow from './LabeledValueInRow';
import OrganizationOverview from './OrganizationOverview';
import OrganizationsUsageTable from './OrganizationsUsageTable';
import OverusedBanner from './OverusedBanner';

type OverviewContentProps = {
  billingEntity: BillingEntity;
  historyContent: BillingHistoryListResponse;
};

// We have either both of them or neither.
type StoredQueryState = {
  beginDate?: string;
  endDate?: string;
};

const EMPTY_STATE: StoredQueryState = {};

export const OverviewContent = ({
  billingEntity,
  historyContent,
}: OverviewContentProps) => {
  const { replace } = useHistory();
  const [storedState, setStoredState] = useStoredState({
    name: BILLING_OVERVIEW_QUERY,
    stringKeys: ['beginDate', 'endDate'],
    defaultValues: {},
    historyFn: replace,
  });

  const hasSubscriptionPlan = useSelector(hasSubscriptionPlanExistSelector);

  const currentPeriod: BillingHistory | undefined = historyContent.results.find(
    result => result.values.isCurrent
  );

  const lastPeriod: BillingHistory | undefined = historyContent.results[0];

  const selectedPeriod: BillingHistory | undefined =
    historyContent.results.find(
      result =>
        result.endDate === storedState.endDate &&
        result.beginDate === storedState.beginDate
    ) ??
    currentPeriod ??
    lastPeriod;

  const setSelectedPeriod = (period: BillingHistory) => {
    setStoredState(
      period.values.isCurrent
        ? EMPTY_STATE
        : {
            beginDate: period.beginDate,
            endDate: period.endDate,
          }
    );
  };

  // Current period will be undefined for trial.
  const volume = selectedPeriod
    ? getVolumeFromTotals(selectedPeriod.values)
    : null;

  return (
    <>
      {volume && selectedPeriod && (
        <Stack direction="row" justifyContent="space-between">
          <BillingPeriodPicker
            periods={historyContent.results}
            currentPeriod={selectedPeriod}
            setCurrentPeriod={setSelectedPeriod}
          />
          {getUsedPercentage(volume) >= 90 && (
            <OverusedBanner usedPercentage={getUsedPercentage(volume)} />
          )}
        </Stack>
      )}
      <OrganizationOverview billingEntity={billingEntity} volume={volume} />
      {volume && selectedPeriod && (
        <BillingPeriodProgress
          currentPeriod={selectedPeriod}
          usedPercentage={getUsedPercentage(volume)}
        />
      )}
      <AggregateUsage
        // Get volume from totals for trial.
        volume={volume ?? getVolumeFromTotals(historyContent.totals)}
      />

      {hasSubscriptionPlan && selectedPeriod ? (
        <AiEnginesUsageProgress historyContent={selectedPeriod} />
      ) : null}

      {billingEntity.type === 'organization_group' &&
        volume &&
        selectedPeriod && (
          <OrganizationsUsageTable
            billingEntity={billingEntity}
            usedPages={volume.billable}
            period={selectedPeriod}
            unit="pages"
          />
        )}
      <LabeledValueInRow
        label="containers.billing.validity"
        valueColor="text.secondary"
        value={new Date(historyContent.updatedAt).toUTCString()}
      />
    </>
  );
};
