import { endpoints, getIDFromUrl, loadEtag, Url } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';

export const SCHEMA_QUERY_KEY = 'schema';

export const useSchema = (schemaUrl: Url | undefined) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [SCHEMA_QUERY_KEY, schemaUrl] as const,
    queryFn: () =>
      api
        .fullRequest(endpoints.schemas.get(getIDFromUrl(schemaUrl!)))
        .then(loadEtag),
    enabled: schemaUrl !== undefined,
  });
};
