import { identity } from 'lodash';
import {
  ActionsObservable,
  combineEpics,
  StateObservable,
} from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { apiUrl } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import { EpicDependencies } from '../../../lib/apiTypes';
import { State } from '../../../types/state';
import { RootActionType } from '../../rootActions';
import { fetchAnnotationFulfilled } from '../annotation/actions';
import { fetchNoteFulfilled, NoteData } from './actions';

const fetchNote = (
  action$: ActionsObservable<RootActionType>,
  _state$: StateObservable<State>,
  { authGetJSON$ }: EpicDependencies
) =>
  action$.pipe(
    filter(isActionOf([fetchAnnotationFulfilled])),
    map(({ payload: { annotation } }) => annotation),
    filter(annotation => annotation.status === 'rejected'),
    filter(identity),
    mergeMap(annotation =>
      authGetJSON$<NoteData>(`${apiUrl}/notes`, {
        query: {
          annotation: annotation.id,
        },
      }).pipe(
        map(response => fetchNoteFulfilled(response)),
        catchError(errorHandler)
      )
    )
  );

export default combineEpics(fetchNote);
