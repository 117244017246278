import { endpoints } from '@rossum/api-client';
import { HooksListQuery } from '@rossum/api-client/hooks';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_HOOKS = 'hooks';

export const useHooks = (query: HooksListQuery = {}) => {
  return useQuery({
    queryKey: [QUERY_KEY_HOOKS, query] as const,
    queryFn: ({ queryKey }) => api.request(endpoints.hooks.list(queryKey[1])),
    keepPreviousData: true,
  });
};
