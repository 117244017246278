import { useMemo } from 'react';
import {
  applyPadding,
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
  rectangleToCoordinates,
} from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { CanvasBoundingBoxes } from '../useCanvasBoundingBoxes';

type UseSelectionSpanRectangleParams = {
  canvasBoundingBoxes: CanvasBoundingBoxes;
};

export const useSelectionSpanRectangle = ({
  canvasBoundingBoxes,
}: UseSelectionSpanRectangleParams) => {
  const selectedDatapointIds = useDocumentStore(state => state.selectedBboxes);

  const spanRectangle: Rectangle2DCoordinates | null = useMemo(() => {
    const positions = canvasBoundingBoxes
      .filter(box => selectedDatapointIds.includes(box.box.id))
      .map(box => rectangleToCoordinates(box.boxRectangle));

    if (positions.length < 1) {
      return null;
    }

    return [
      Math.min(...positions.map(p => p[0])),
      Math.min(...positions.map(p => p[1])),
      Math.max(...positions.map(p => p[2])),
      Math.max(...positions.map(p => p[3])),
    ];
  }, [canvasBoundingBoxes, selectedDatapointIds]);

  if (!spanRectangle) {
    return null;
  }

  const padding = 1;
  return applyPadding(rectangleFromCoordinates(spanRectangle), padding);
};
