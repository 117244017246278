import { z } from 'zod';
import { ID } from '../../../utils/codecUtils';
import { Schema } from '../../../schemas/models/schema';
import { SectionData } from '../../../annotations/models/annotationData';
import { SchemaSection } from '../../../schemas/models/schemaSection';
import {
  evaluateFormulasPayloadSchema,
  evaluateFormulasResponseSchema,
} from './evaluateFormulas.schema';
import { Message } from '../../../shared/models/message';

export type EvaluateFormulasPayload = {
  schemaContent: SchemaSection[];
  annotationContent: Array<SectionData>;
};

export type AutomationBlocker = { content: string; id: string };

export type EvaluateFormulasResponse = {
  annotationContent: Array<SectionData>;
  automationBlockers: Array<AutomationBlocker>;
  messages: Array<Message>;
};

export const evaluateFormulas = (payload: EvaluateFormulasPayload) => {
  return {
    endpoint: `internal/schemas/evaluate_formulas`,
    method: 'POST',
    responseSchema: evaluateFormulasResponseSchema,
    payloadSchema: evaluateFormulasPayloadSchema,
    payload,
  } as const;
};
