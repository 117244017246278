import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError, throwInfo } from '../../redux/modules/messages/actions';
import { deleteWorkspaceFulfilled } from '../../redux/modules/workspaces/actions';
import { removeWorkspaceMutation } from './removeWorkspaceMutation';
import { QUERY_KEY_LAZY_WORKSPACES } from './useLazyWorkspaces';

export const useDeleteWorkspace = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (workspaceId: ID) =>
      api.request(endpoints.workspaces.delete(workspaceId)),

    onSuccess: (_, workspaceId) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LAZY_WORKSPACES],
      });

      // remove workspace from the list of workspaces
      removeWorkspaceMutation(queryClient, workspaceId);

      dispatch(throwInfo('workspaceDeleted', undefined));
      dispatch(deleteWorkspaceFulfilled(workspaceId));
      onSuccess();
    },

    onError: () => {
      dispatch(throwError('workspaceDeletedError'));
    },
  });
};
