// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const copyAnnotationPayloadSchema = z.object({
  targetQueue: urlSchema,
  targetStatus: z.string(),
});

export const copyAnnotationResponseSchema = z.object({
  annotation: urlSchema,
});
