// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointSchema } from './schemaDatapoint.schema';
import { schemaItemBaseSchema } from './schemaItemBase.schema';

export const schemaTupleSchema = z
  .object({
    category: z.literal('tuple'),
    children: z.array(schemaDatapointSchema),
    rirFieldNames: z.array(z.string()).optional().nullable(),
  })
  .and(schemaItemBaseSchema);
