import { ReactNode } from 'react';
import Scrollable from '../../../components/Scrollable';
import ArrowLeft from '../../../components/Sidebar/ArrowLeft';
import Header from '../../../components/Sidebar/Header';
import SidebarWrapper from '../../../components/Sidebar/Sidebar';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  backLink: { title: LocalizationKeys; to: string } | null;
  children: ReactNode;
};

const Sidebar = ({ children, backLink }: Props) => (
  <SidebarWrapper>
    {backLink && (
      <Header title={backLink.title} onLeft={<ArrowLeft to={backLink.to} />} />
    )}
    <Scrollable>{children}</Scrollable>
  </SidebarWrapper>
);

export default Sidebar;
