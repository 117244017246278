import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  DialogContent,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import SuccessMessage from '../../../../components/InviteColleague/SuccessMessage';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import { boldText } from '../../../../lib/formaterValues';
import { userSelector } from '../../../../redux/modules/user/selectors';
import { useSubmitContactDialog } from './useSubmitContactDialog';

type ContactDialogContentProps = {
  type: 'contactUs' | 'requestAccess';
  extensionName: string;
  onClose: () => void;
};

export const ContactDialogContent = ({
  type,
  onClose,
  extensionName,
}: ContactDialogContentProps) => {
  const intl = useIntl();
  const user = useSelector(userSelector);

  const contactDialogSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'components.contactDialog.email.error',
        })
      )
      .required(
        intl.formatMessage({
          id: 'components.contactDialog.email.error',
        })
      ),
    message: yup.string().required(),
  });

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(contactDialogSchema),
    defaultValues: { email: user.email, message: '' },
  });

  const { isLoading, mutate, isSuccess } = useSubmitContactDialog();

  return (
    <DialogContent sx={{ mt: 3, mx: 3, px: 0 }}>
      <Stack spacing={3}>
        <Typography variant="body1">
          {intl.formatMessage(
            {
              id: `components.contactDialog.subtitle.${type}`,
            },
            { boldText, name: extensionName }
          )}
        </Typography>
        <form
          onSubmit={handleSubmit(payload => {
            mutate({ payload: { ...payload, extension: extensionName } });
          })}
        >
          <Stack spacing={4}>
            <Stack spacing={2}>
              <TextFieldControl
                sx={{ width: '100%' }}
                FieldLabelProps={{ layout: 'floating' }}
                ControllerProps={{ control, name: 'email' }}
                label={intl.formatMessage({
                  id: 'components.contactDialog.email.label',
                })}
              />
              <TextFieldControl
                multiline
                sx={{ width: '100%' }}
                maxRows={8}
                type="text"
                ControllerProps={{ control, name: 'message' }}
                placeholder={intl.formatMessage({
                  id: `components.contactDialog.message.placeholder.${type}`,
                })}
              />
            </Stack>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SuccessMessage
                show={isSuccess}
                message={intl.formatMessage({
                  id: 'components.contactDialog.requestSent',
                })}
              />
              <Stack spacing={1.5} direction="row">
                <Button variant="outlined" onClick={onClose} color="secondary">
                  {intl.formatMessage({
                    id: 'components.contactDialog.buttons.cancel',
                  })}
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading}
                  startIcon={
                    isLoading && <CircularProgress color="inherit" size={20} />
                  }
                  variant="contained"
                  data-cy="contact-dialog-submit-btn"
                >
                  {intl.formatMessage({
                    id: `components.contactDialog.buttons.submit.${type}`,
                  })}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </DialogContent>
  );
};
