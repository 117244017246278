import { Box } from '@rossum/ui/material';

// TODO: Not working for react nodes atm.
export const Paragraphs = ({ children }: { children: string }) => {
  return (
    <>
      {children.split('{linebreak}').map((content, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box sx={{ mb: 1 }} key={`${index}-${content}`}>
          {content}
        </Box>
      ))}
    </>
  );
};
