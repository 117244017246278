import { endpoints } from '@rossum/api-client';
import { CreateEmailTemplatePayload } from '@rossum/api-client/emailTemplates';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const useCreateEmailTemplate = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: (queue: CreateEmailTemplatePayload) =>
      api.request(endpoints.emailTemplates.create(queue)),
  });
};
