import { baseSimpleMultivalueFieldSchema } from './simpleMultivalueField.schema';
import { baseTableMultivalueFieldSchema } from './tableMultivalueField.schema';

export const simpleMultivalueFieldSchema =
  baseSimpleMultivalueFieldSchema?.transform(m => ({
    ...m,
    __kind: 'SimpleMultivalue' as const,
  }));

export const tableMultivalueFieldSchema =
  baseTableMultivalueFieldSchema?.transform(m => ({
    ...m,
    __kind: 'TableMultivalue' as const,
  }));
