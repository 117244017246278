import { CloudDone, Downloading, ErrorOutline } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  CircularProgress,
  Fade,
  ListItem,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import {
  DocumentUpload,
  DocumentUploadStatus,
} from '../../../../types/documents';

const renderIcon = (
  status: DocumentUploadStatus,
  uploadedCount: number,
  reason?: string
) => {
  if (status === 'uploaded') {
    return <CloudDone color="success" />;
  }

  if (status === 'failed') {
    return (
      <Tooltip title={reason ?? ''} placement="top">
        <ErrorOutline color={uploadedCount ? 'warning' : 'error'} />
      </Tooltip>
    );
  }

  return (
    <Box sx={{ position: 'relative', width: 20, height: 20 }}>
      <Fade in style={{ transitionDelay: '750ms' }}>
        <span>
          <CircularProgress size={20} color="inherit" sx={{ opacity: 0.3 }} />
        </span>
      </Fade>
      <Downloading
        color="disabled"
        sx={{
          transform: 'rotate(180deg) translate(50%, 50%)',
          position: 'absolute',
          display: 'block',
          left: '50%',
          top: '50%',
          zIndex: 1,
        }}
      />
    </Box>
  );
};

type OldFileListProps = {
  fileList: DocumentUpload[];
  uploadedCount: number;
};

export const OldFileList = ({ fileList, uploadedCount }: OldFileListProps) => {
  return (
    <Stack flex={0}>
      <Box
        sx={{
          maxHeight: 200,
          mx: 4,
          alignSelf: 'stretch',
          overflow: 'auto',
        }}
      >
        {fileList.map(file => (
          <ListItem
            key={file.signature}
            sx={{
              '&:nth-of-type(odd)': {
                background: theme => {
                  return alpha(
                    theme.palette.mode === 'dark'
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                    0.04
                  );
                },
              },
            }}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              {renderIcon(
                file.status,
                uploadedCount,
                'reason' in file ? file.reason : undefined
              )}
              <div>{file.name}</div>
            </Stack>
          </ListItem>
        ))}
      </Box>
    </Stack>
  );
};
