import { Queue } from '@rossum/api-client/queues';
import { useWorkspacesWithQueues } from '../hooks/useWorkspacesWithQueues';
import { QueueListContent } from './components/QueueList';
import { WorkspaceWithQueues } from './types';

export type QueueListProps = {
  onChange: (queue: Queue) => void;
  value: Queue | undefined;
  /** @deprecated You do not need to pass workspaces. */
  workspaces?: WorkspaceWithQueues[];
};

export const QueueList = ({ onChange, value, workspaces }: QueueListProps) => {
  const { workspacesWithQueues } = useWorkspacesWithQueues({
    enableQueries: workspaces === undefined,
  });

  return (
    <QueueListContent
      onChange={onChange}
      selectedQueue={value}
      workspaces={workspaces ?? workspacesWithQueues}
    />
  );
};
