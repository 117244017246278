import { ElisRequestConfig } from '../../utils/requestConfig';
import { Hook } from '../models/hook';
import { hookSchema } from '../models/hook.schema';

export const get = (hookId: number) => {
  return {
    endpoint: `/hooks/${hookId}`,
    method: 'GET',
    responseSchema: hookSchema,
  } as const;
};
