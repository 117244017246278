import { ElisRequestConfig } from '../../utils/requestConfig';
import { Organization } from '../models/organization';
import {
  createOrganizationPayloadSchema,
  createOrganizationResponseSchema,
} from './create.schema';

export type CreateOrganizationResponse = {
  organization: Organization;
  key: string;
  domain: string;
};

export type CreateOrganizationPayload = {
  templateName: string;
  organizationName: string;
  userFullname: string;
  userEmail: string;
  userPassword: string;
  userUiSettings?: unknown;
  createKey: string;
  domain?: string;
  phoneNumber?: string;
};

export const create = (payload: CreateOrganizationPayload) => {
  return {
    authorize: false,
    endpoint: `/organizations/create`,
    method: 'POST',
    responseSchema: createOrganizationResponseSchema,
    payloadSchema: createOrganizationPayloadSchema,
    payload,
  } as const;
};
