import { Ace, Range } from 'ace-builds';
import * as R from 'remeda';

// based on langTools.textCompleter getCompletions
// changed regexp, naming and syntax to match our coding style

const splitRegex = /^(?!#).*\b([a-zA-Z_][a-zA-Z0-9_]*)\s*(?!=\s*["'])=/gm;

export const getMatches = (text: string) =>
  [...text.matchAll(splitRegex)].map(match => match[1]).filter(R.isDefined);

const getCurrentWordIndex = (session: Ace.EditSession, pos: Ace.Point) => {
  const textBefore = session.getTextRange(
    Range.fromPoints(
      {
        row: 0,
        column: 0,
      },
      pos
    )
  );
  return getMatches(textBefore).length - 1;
};

const getWordsWithScore = (session: Ace.EditSession, pos: Ace.Point) => {
  const currentWordIndex = getCurrentWordIndex(session, pos);
  const words = getMatches(session.getValue());

  const currentLineMatch = getMatches(
    session.getTextRange(Range.fromPoints({ ...pos, column: 0 }, pos))
  )[0];

  const wordListWithScore = words.reduce<Record<string, number>>(
    (acc, word, idx) => {
      // do not suggest current variable
      if (word === currentLineMatch) {
        return acc;
      }

      // calculate score based on distance from current word
      const distance = Math.abs(currentWordIndex - idx);
      const score = words.length - distance;

      return {
        ...acc,
        [word]: Math.max(score, acc[word] || 0) * 100,
      };
    },
    {}
  );

  return wordListWithScore;
};

const getCompletions: Ace.Completer['getCompletions'] = (
  _editor,
  session,
  pos,
  _prefix,
  callback
) => {
  const wordsWithScore = getWordsWithScore(session, pos);
  const wordList = Object.keys(wordsWithScore);

  callback(
    null,
    wordList.map(word => ({
      caption: word,
      value: word,
      score: wordsWithScore[word],
      meta: 'local',
    }))
  );
};

export const getLocalVariables: Ace.Completer = { getCompletions };
