// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { answerSchema } from './answer.schema';

export const surveySchema = z.object({
  id: idSchema,
  url: urlSchema,
  organization: z.string(),
  template: urlSchema,
  createdAt: z.string(),
  modifiedAt: z.string(),
  modifier: urlSchema,
  additionalData: z.unknown().optional(),
  answers: z.array(answerSchema).optional(),
});
