import { alpha, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type Props = {
  label: string;
  children: ReactNode;
};

const Row = ({ label, children }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={0}
      sx={{
        fontSize: theme => theme.typography.pxToRem(16),
        fontWeight: 'normal',
        mb: 1.75,
      }}
    >
      <Stack
        alignItems="flex-end"
        sx={{
          color: theme => theme.palette.text.secondary,
          '&::selection': {
            backgroundColor: theme =>
              theme.palette.mode === 'dark'
                ? alpha(theme.palette.common.white, 0.2)
                : alpha(theme.palette.common.black, 0.15),
          },
        }}
      >
        {label}
      </Stack>
      <Stack
        alignItems="flex-end"
        sx={{
          flex: 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default Row;
