import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from './useApiClient';

export const usePageText = ({
  pageNumber,
  annotationUrl,
}: {
  pageNumber: number;
  annotationUrl: string;
}) => {
  const api = useApiClient();
  return useQuery({
    queryKey: ['mini-validation-screen', 'page-text', pageNumber],
    queryFn: async () => {
      const granularities = ['chars', 'lines', 'words'] as const;
      const response = await Promise.all(
        granularities.map(async granularity => {
          const response = await api.request(
            endpoints.annotations.getPageData(getIDFromUrl(annotationUrl), {
              pageNumbers: [pageNumber],
              granularity,
            })
          );
          return [granularity, response.results[0] ?? []] as const;
        })
      );
      const entries = Object.fromEntries(response);
      return {
        chars: entries.chars?.items ?? [],
        lines: entries.lines?.items ?? [],
        words: entries.words?.items ?? [],
      };
    },
    staleTime: 3600 * 1000,
  });
};
