import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@rossum/ui/material';
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props<FieldValuesT extends FieldValues> = {
  control: Control<FieldValuesT>;
  name: Path<FieldValuesT>;
  options: Array<{
    key: string;
    label: string;
  }>;
  inputLabel?: string;
  getErrorMessage: (name: Path<FieldValuesT>, error: FieldError) => string;
};
export const ControlledDropdown = <FieldValuesT extends FieldValues>({
  control,
  name,
  options,
  inputLabel,
  getErrorMessage,
}: Props<FieldValuesT>) => {
  const intl = useIntl();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });
  const placeholder =
    inputLabel ??
    intl.formatMessage({
      id: 'components.controlledDropdown.inputLabel',
    });
  return (
    <Stack>
      <FormControl>
        <InputLabel>{placeholder}</InputLabel>
        <Select value={value} onChange={onChange} label={placeholder}>
          {options.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              <Typography variant="body2">{label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <FormHelperText sx={{ mx: '14px' }} error>
          {getErrorMessage(name, error)}
        </FormHelperText>
      )}
    </Stack>
  );
};
