import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailThread } from '../models/emailThreads';
import { emailThreadSchema } from '../models/emailThreads.schema';

export const get = (emailId: ID) => {
  return {
    endpoint: `email_threads/${emailId}`,
    method: 'GET',
    responseSchema: emailThreadSchema,
  } as const;
};
