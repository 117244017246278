import { LocalizationKeys } from '../../i18n';
import { CustomerType } from '../../types/organization';
import { Severity } from './helpers';

export const messages: Record<
  CustomerType,
  Record<Severity, LocalizationKeys>
> = {
  endcustomer: {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.common.medium',
  },
  bpo: {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.common.medium',
  },
  softwarevendoroem: {
    low: 'components.announcements.overdue.common.low',
    medium: 'components.announcements.overdue.oem.medium',
  },
};
