import { ArrowDropDown as ArrowDropDownIcon } from '@rossum/ui/icons';
import { ArrowDropUp as ArrowDropUpIcon } from '@rossum/ui/icons';
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Dispatch, memo, ReactNode, SetStateAction, useCallback } from 'react';
import { getPartId } from '../create-field/helpers';

type Option = {
  id: string;
  label: string;
};

// Component is recursive, thus rendering itself in case there are other nested options
// The input data structure determines how deep the list would be nested
type CollapsibleOption =
  | Option
  | {
      id: string;
      label: string;
      options: CollapsibleOption[];
    };

// Two modes for selecting location in schema:
//     1. 'multi-single-select' allows to pick only one item on the schema level but multiple items in different schemas
//     2. 'single-select' allows to pick only one option across schemas
type SelectSchemaLocationMode = 'multi-single-select' | 'single-select';

type SelectSchemaLocationProps = {
  options: CollapsibleOption[];
  expandedOptions: string[];
  setExpandedOptions: Dispatch<SetStateAction<string[]>>;
  mode: SelectSchemaLocationMode;
  onSelect: (selectedPaths: string[]) => void;
  selectedOptions: string[];
};

const getSchemaPart = (optionId: string) => optionId.split('schemaId:')[1];

const SelectSchemaLocation = ({
  options,
  mode,
  onSelect,
  selectedOptions,
  expandedOptions,
  setExpandedOptions,
}: SelectSchemaLocationProps) => {
  const handleOptionSelection = useCallback(
    (option: CollapsibleOption, isSelected: boolean) => {
      const isNestedOption = 'options' in option && option.options.length > 0;

      if (isNestedOption) {
        // No action needed for non-interactive indicators
        return;
      }

      if (mode === 'multi-single-select') {
        // If it's currently selected, clicking it again will deselect it
        // No option at this level is selected or another option is selected
        const updatedOptions = isSelected
          ? selectedOptions.filter(
              selectedOption => selectedOption !== option.id
            )
          : [
              ...selectedOptions.filter(selectedOption => {
                const selectedSchemaId = getPartId(selectedOption, 'schemaId');
                const optionSchemaId = getPartId(option.id, 'schemaId');

                if (!selectedSchemaId || !optionSchemaId) return true;

                return selectedSchemaId !== optionSchemaId; // Maintain selections that are not siblings
              }),
              option.id,
            ];
        onSelect(updatedOptions);
      } else if (mode === 'single-select') {
        // If it's currently selected, clicking it again will deselect it
        const updatedOptions = isSelected
          ? selectedOptions.filter(
              selectedOption => selectedOption !== option.id
            )
          : [option.id];
        onSelect(updatedOptions);
      }
    },
    [mode, onSelect, selectedOptions]
  );

  const isOptionFullySelected = useCallback(
    (option: CollapsibleOption, optionChildIds: string[]) => {
      if (optionChildIds.length > 0) return false;

      if (optionChildIds.length === 0) {
        // There are shared schemas which can be assigned to multiple queues.
        // Comparing only schema parts of the ids
        return selectedOptions.some(
          o => getSchemaPart(o) === getSchemaPart(option.id)
        );
      }

      return false;
    },
    [selectedOptions]
  );

  const isOptionPartiallySelected = useCallback(
    (optionChildIds: string[]) => {
      return optionChildIds.some(childPath =>
        selectedOptions.some(selectedOption => {
          // There are shared schemas which can be assigned to multiple queues.
          // Comparing only schema parts of the ids
          return getSchemaPart(selectedOption)?.includes(
            getSchemaPart(childPath) ?? ''
          );
        })
      );
    },
    [selectedOptions]
  );

  const handleOptionClick = useCallback(
    (option: CollapsibleOption, fullySelected: boolean) =>
      'options' in option && option.options.length > 0
        ? setExpandedOptions(prev =>
            prev.includes(option.id)
              ? prev.filter(id => id !== option.id)
              : [...prev, option.id]
          )
        : handleOptionSelection(option, fullySelected),
    [handleOptionSelection, setExpandedOptions]
  );

  const getQueueId = (id: string) => {
    // Regular expression to match queueId
    const regex = /queueId:(\d+)/;
    const match = id.match(regex);

    // Extracting and setting the queueId
    if (match && match[1]) {
      return match[1];
    }

    return undefined;
  };

  return (
    <Stack spacing={1} width="100%">
      <List disablePadding dense>
        {options.map(option => {
          const collapsibleChildren =
            'options' in option && option.options ? option.options : [];

          const optionFullySelected = isOptionFullySelected(
            option,
            collapsibleChildren.map(child => child.id)
          );

          const optionPartiallySelected =
            !optionFullySelected &&
            isOptionPartiallySelected(
              collapsibleChildren.map(child => child.id)
            );

          const queueId = getQueueId(option.id);

          return (
            <Stack key={`${option.id}-${option.label}`}>
              <ListItemButton
                disableGutters
                disableRipple
                onClick={() => handleOptionClick(option, optionFullySelected)}
                sx={{ my: 0.5, py: 0 }}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  {option &&
                  'options' in option &&
                  option.options &&
                  option.options.length > 0 ? (
                    mode === 'multi-single-select' ? (
                      <Checkbox
                        indeterminate={
                          optionPartiallySelected && !optionFullySelected
                        }
                        checked={optionFullySelected}
                        onClick={e => e.stopPropagation()}
                        sx={{ p: 0 }}
                        disabled
                      />
                    ) : null
                  ) : (
                    <Radio
                      checked={optionFullySelected}
                      onChange={e => {
                        e.stopPropagation();
                        handleOptionSelection(option, optionFullySelected);
                      }}
                      name={`radio-button-${option.id}`}
                      sx={{ p: 0 }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                >
                  {option.label}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {queueId ? ` (${queueId})` : null}
                  </Typography>
                </ListItemText>
                {option &&
                  'options' in option &&
                  option.options &&
                  option.options.length > 0 &&
                  (expandedOptions.includes(option.id) ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  ))}
              </ListItemButton>
              {collapsibleChildren.length > 0 ? (
                <ListItemCollapse
                  isExpanded={expandedOptions.includes(option.id)}
                >
                  <SelectSchemaLocation
                    options={collapsibleChildren}
                    mode={mode}
                    onSelect={onSelect}
                    selectedOptions={selectedOptions}
                    expandedOptions={expandedOptions}
                    setExpandedOptions={setExpandedOptions}
                  />
                </ListItemCollapse>
              ) : null}
            </Stack>
          );
        })}
      </List>
    </Stack>
  );
};

const ListItemCollapse = memo(
  ({ isExpanded, children }: { isExpanded: boolean; children: ReactNode }) => (
    <Collapse in={isExpanded} sx={{ pl: 2 }} mountOnEnter unmountOnExit>
      {children}
    </Collapse>
  )
);

export default memo(SelectSchemaLocation);
