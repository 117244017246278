import { Button, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Container from '../../../components/UI/Container';

type Props = { children: ReactNode; dataPageTitle?: string };

const PasswordContainer = ({ children, dataPageTitle }: Props) => {
  const intl = useIntl();

  return (
    <Container dataPageTitle={dataPageTitle}>
      <Stack spacing={2} alignItems="center" sx={{ width: '100%' }}>
        {children}
        <Button component={Link} to="/" color="secondary" variant="text">
          {intl.formatMessage({ id: 'components.user.recovery.back' })}
        </Button>
      </Stack>
    </Container>
  );
};

export default PasswordContainer;
