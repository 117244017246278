import { Annotation } from '@rossum/api-client/annotations';
import { Button, Stack, Tab, Tabs, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { AnnotationInfo } from './AnnotationInfo';
import { HeaderFields } from './HeaderFields';
import { FlatContent } from './hooks/flattenContent';
import { FlatSchema } from './hooks/flattenSchema';
import { useConfirmAnnotation } from './hooks/useConfirmAnnotation';

type SidebarProps = {
  datapoints: FlatContent[];
  schemaMap: Record<string, FlatSchema>;
  activeDatapointId: number | undefined;
  setActiveDatapointId: (datapoint: number | undefined) => void;
  annotation: Annotation;
};

export const Sidebar = ({
  datapoints,
  schemaMap,
  activeDatapointId,
  setActiveDatapointId,
  annotation,
}: SidebarProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { mutate: confirmAnnotation } = useConfirmAnnotation();

  return (
    <Stack
      sx={{
        minWidth: '250px',
        maxWidth: '250px',
        background: theme => theme.palette.grey[900],
      }}
    >
      <Stack direction="column" height="100%">
        <Tabs
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          value={currentTab}
        >
          <Tab
            label={
              <Typography variant="body2" color="text.primary">
                Header fields
              </Typography>
            }
            value={0}
            onClick={() => setCurrentTab(0)}
          />
          <Tab
            label={
              <Typography variant="body2" color="text.primary">
                Annotation
              </Typography>
            }
            value={1}
            onClick={() => setCurrentTab(1)}
          />
        </Tabs>

        <Stack
          role="tabpanel"
          sx={{
            overflow: 'auto',
            display: currentTab === 0 ? 'flex' : 'none',
            flex: 1,
          }}
        >
          <HeaderFields
            datapoints={datapoints}
            schemaMap={schemaMap}
            activeDatapointId={activeDatapointId}
            setActiveDatapointId={setActiveDatapointId}
          />
        </Stack>

        <Stack
          role="tabpanel"
          sx={{
            overflow: 'auto',
            display: currentTab === 1 ? 'flex' : 'none',
            flex: 1,
          }}
        >
          <AnnotationInfo annotation={annotation} />
        </Stack>

        <Stack p={2}>
          <Button
            size="large"
            variant="contained"
            onClick={() => confirmAnnotation({ annotationId: annotation.id })}
          >
            Confirm document
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
