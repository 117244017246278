import { endpoints } from '@rossum/api-client';
import { EmailSendPayload } from '@rossum/api-client/email';
import { HttpError } from '@rossum/api-client/errors';
import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useApiClient } from '../../../../../lib/apiClient';
import { sendEmailFulfilled } from '../../../../../redux/modules/email/actions';
import { isTrialSelector } from '../../../../../redux/modules/organization/selectors';
import { Email } from '../../../api-client';
import { EmailResponseFormMode } from '../types';

type SendEmailErrorType =
  | 'sendEmailErrorTrialEmailRejection'
  | 'sendEmailErrorTrialDocumentRejection'
  | 'sendEmailErrorTrial'
  | 'sendEmailError';

const getErrorCodeByError = (
  error: unknown,
  isTrial: boolean,
  mode?: EmailResponseFormMode
): SendEmailErrorType => {
  if (
    error instanceof HttpError &&
    get(error.data, 'code') === 'email_sending_disabled' &&
    isTrial
  ) {
    if (mode === 'rejectEmail') return 'sendEmailErrorTrialEmailRejection';

    if (mode === 'rejectDocument')
      return 'sendEmailErrorTrialDocumentRejection';

    return 'sendEmailErrorTrial';
  }
  return 'sendEmailError';
};

type UseSendEmailParams = {
  mode?: EmailResponseFormMode;
  onSend?: (email: Email) => void;
};

export const useSendEmail = ({ mode, onSend }: UseSendEmailParams = {}) => {
  const api = useApiClient();
  const isTrial = useSelector(isTrialSelector);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async ({ payload }: { payload: EmailSendPayload }) => {
      const { email } = await api.request(endpoints.email.send(payload));
      return api.request(endpoints.email.get(email));
    },

    onSuccess: email => {
      onSend?.(email);
      dispatch(sendEmailFulfilled(email));
    },
  });
  return {
    ...mutation,
    errorType: mutation.error
      ? getErrorCodeByError(mutation.error, !!isTrial, mode)
      : undefined,
  };
};
