// Generated with:
// import babel
// import json

// def get_locale(locale):
//     # I have a different babel version where these 6 are missing
//     unknownValues = { "cu": 'Church Slavic', 'cu_RU': 'Church Slavic (Russia)', 'prg': 'Prussian', 'prg_001': 'Prussian (World)', 'vo': 'Volapük', 'vo_001': 'Volapük (World)' }

//     return {
//         "value": locale,
//         "label": unknownValues[locale] if locale in unknownValues else babel.Locale.parse(locale).get_display_name('en_US')
//     }

// def sort_fn(e):
//   return e['label']

// locales = [get_locale(locale) for locale in localeNames]

// locales.sort(key=sort_fn)
// jsonString = json.dumps(locales)

// file1 = open('regions.json', 'w')
// file1.write(jsonString)
// file1.close()

export const regions = [
  { value: 'af', label: 'Afrikaans' },
  { value: 'af_NA', label: 'Afrikaans (Namibia)' },
  { value: 'af_ZA', label: 'Afrikaans (South Africa)' },
  { value: 'agq', label: 'Aghem' },
  { value: 'agq_CM', label: 'Aghem (Cameroon)' },
  { value: 'ak', label: 'Akan' },
  { value: 'ak_GH', label: 'Akan (Ghana)' },
  { value: 'sq', label: 'Albanian' },
  { value: 'sq_AL', label: 'Albanian (Albania)' },
  { value: 'sq_XK', label: 'Albanian (Kosovo)' },
  { value: 'sq_MK', label: 'Albanian (North Macedonia)' },
  { value: 'am', label: 'Amharic' },
  { value: 'am_ET', label: 'Amharic (Ethiopia)' },
  { value: 'ar', label: 'Arabic' },
  { value: 'ar_DZ', label: 'Arabic (Algeria)' },
  { value: 'ar_BH', label: 'Arabic (Bahrain)' },
  { value: 'ar_TD', label: 'Arabic (Chad)' },
  { value: 'ar_KM', label: 'Arabic (Comoros)' },
  { value: 'ar_DJ', label: 'Arabic (Djibouti)' },
  { value: 'ar_EG', label: 'Arabic (Egypt)' },
  { value: 'ar_ER', label: 'Arabic (Eritrea)' },
  { value: 'ar_IQ', label: 'Arabic (Iraq)' },
  { value: 'ar_IL', label: 'Arabic (Israel)' },
  { value: 'ar_JO', label: 'Arabic (Jordan)' },
  { value: 'ar_KW', label: 'Arabic (Kuwait)' },
  { value: 'ar_LB', label: 'Arabic (Lebanon)' },
  { value: 'ar_LY', label: 'Arabic (Libya)' },
  { value: 'ar_MR', label: 'Arabic (Mauritania)' },
  { value: 'ar_MA', label: 'Arabic (Morocco)' },
  { value: 'ar_OM', label: 'Arabic (Oman)' },
  { value: 'ar_PS', label: 'Arabic (Palestinian Territories)' },
  { value: 'ar_QA', label: 'Arabic (Qatar)' },
  { value: 'ar_SA', label: 'Arabic (Saudi Arabia)' },
  { value: 'ar_SO', label: 'Arabic (Somalia)' },
  { value: 'ar_SS', label: 'Arabic (South Sudan)' },
  { value: 'ar_SD', label: 'Arabic (Sudan)' },
  { value: 'ar_SY', label: 'Arabic (Syria)' },
  { value: 'ar_TN', label: 'Arabic (Tunisia)' },
  { value: 'ar_AE', label: 'Arabic (United Arab Emirates)' },
  { value: 'ar_EH', label: 'Arabic (Western Sahara)' },
  { value: 'ar_YE', label: 'Arabic (Yemen)' },
  { value: 'ar_001', label: 'Arabic (world)' },
  { value: 'hy', label: 'Armenian' },
  { value: 'hy_AM', label: 'Armenian (Armenia)' },
  { value: 'as', label: 'Assamese' },
  { value: 'as_IN', label: 'Assamese (India)' },
  { value: 'ast', label: 'Asturian' },
  { value: 'ast_ES', label: 'Asturian (Spain)' },
  { value: 'asa', label: 'Asu' },
  { value: 'asa_TZ', label: 'Asu (Tanzania)' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'az_Cyrl', label: 'Azerbaijani (Cyrillic)' },
  { value: 'az_Cyrl_AZ', label: 'Azerbaijani (Cyrillic, Azerbaijan)' },
  { value: 'az_Latn', label: 'Azerbaijani (Latin)' },
  { value: 'az_Latn_AZ', label: 'Azerbaijani (Latin, Azerbaijan)' },
  { value: 'ksf', label: 'Bafia' },
  { value: 'ksf_CM', label: 'Bafia (Cameroon)' },
  { value: 'bm', label: 'Bambara' },
  { value: 'bm_ML', label: 'Bambara (Mali)' },
  { value: 'bn', label: 'Bangla' },
  { value: 'bn_BD', label: 'Bangla (Bangladesh)' },
  { value: 'bn_IN', label: 'Bangla (India)' },
  { value: 'bas', label: 'Basaa' },
  { value: 'bas_CM', label: 'Basaa (Cameroon)' },
  { value: 'eu', label: 'Basque' },
  { value: 'eu_ES', label: 'Basque (Spain)' },
  { value: 'be', label: 'Belarusian' },
  { value: 'be_BY', label: 'Belarusian (Belarus)' },
  { value: 'bem', label: 'Bemba' },
  { value: 'bem_ZM', label: 'Bemba (Zambia)' },
  { value: 'bez', label: 'Bena' },
  { value: 'bez_TZ', label: 'Bena (Tanzania)' },
  { value: 'brx', label: 'Bodo' },
  { value: 'brx_IN', label: 'Bodo (India)' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'bs_Cyrl', label: 'Bosnian (Cyrillic)' },
  { value: 'bs_Cyrl_BA', label: 'Bosnian (Cyrillic, Bosnia & Herzegovina)' },
  { value: 'bs_Latn', label: 'Bosnian (Latin)' },
  { value: 'bs_Latn_BA', label: 'Bosnian (Latin, Bosnia & Herzegovina)' },
  { value: 'br', label: 'Breton' },
  { value: 'br_FR', label: 'Breton (France)' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'bg_BG', label: 'Bulgarian (Bulgaria)' },
  { value: 'my', label: 'Burmese' },
  { value: 'my_MM', label: 'Burmese (Myanmar (Burma))' },
  { value: 'yue', label: 'Cantonese' },
  { value: 'yue_HK', label: 'Cantonese (Traditional, Hong Kong SAR China)' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ca_AD', label: 'Catalan (Andorra)' },
  { value: 'ca_FR', label: 'Catalan (France)' },
  { value: 'ca_IT', label: 'Catalan (Italy)' },
  { value: 'ca_ES', label: 'Catalan (Spain)' },
  { value: 'ca_ES_VALENCIA', label: 'Catalan (Spain, Valencian)' },
  { value: 'tzm', label: 'Central Atlas Tamazight' },
  { value: 'tzm_MA', label: 'Central Atlas Tamazight (Morocco)' },
  { value: 'ckb', label: 'Central Kurdish' },
  { value: 'ckb_IR', label: 'Central Kurdish (Iran)' },
  { value: 'ckb_IQ', label: 'Central Kurdish (Iraq)' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ce_RU', label: 'Chechen (Russia)' },
  { value: 'chr', label: 'Cherokee' },
  { value: 'chr_US', label: 'Cherokee (United States)' },
  { value: 'cgg', label: 'Chiga' },
  { value: 'cgg_UG', label: 'Chiga (Uganda)' },
  { value: 'zh', label: 'Chinese' },
  { value: 'zh_Hans', label: 'Chinese (Simplified)' },
  { value: 'zh_Hans_CN', label: 'Chinese (Simplified, China)' },
  { value: 'zh_Hans_HK', label: 'Chinese (Simplified, Hong Kong SAR China)' },
  { value: 'zh_Hans_MO', label: 'Chinese (Simplified, Macao SAR China)' },
  { value: 'zh_Hans_SG', label: 'Chinese (Simplified, Singapore)' },
  { value: 'zh_Hant', label: 'Chinese (Traditional)' },
  { value: 'zh_Hant_HK', label: 'Chinese (Traditional, Hong Kong SAR China)' },
  { value: 'zh_Hant_MO', label: 'Chinese (Traditional, Macao SAR China)' },
  { value: 'zh_Hant_TW', label: 'Chinese (Traditional, Taiwan)' },
  { value: 'cu', label: 'Church Slavic' },
  { value: 'cu_RU', label: 'Church Slavic (Russia)' },
  { value: 'ksh', label: 'Colognian' },
  { value: 'ksh_DE', label: 'Colognian (Germany)' },
  { value: 'kw', label: 'Cornish' },
  { value: 'kw_GB', label: 'Cornish (United Kingdom)' },
  { value: 'hr', label: 'Croatian' },
  { value: 'hr_BA', label: 'Croatian (Bosnia & Herzegovina)' },
  { value: 'hr_HR', label: 'Croatian (Croatia)' },
  { value: 'cs', label: 'Czech' },
  { value: 'cs_CZ', label: 'Czech (Czechia)' },
  { value: 'da', label: 'Danish' },
  { value: 'da_DK', label: 'Danish (Denmark)' },
  { value: 'da_GL', label: 'Danish (Greenland)' },
  { value: 'dua', label: 'Duala' },
  { value: 'dua_CM', label: 'Duala (Cameroon)' },
  { value: 'nl', label: 'Dutch' },
  { value: 'nl_AW', label: 'Dutch (Aruba)' },
  { value: 'nl_BE', label: 'Dutch (Belgium)' },
  { value: 'nl_BQ', label: 'Dutch (Caribbean Netherlands)' },
  { value: 'nl_CW', label: 'Dutch (Cura\u00e7ao)' },
  { value: 'nl_NL', label: 'Dutch (Netherlands)' },
  { value: 'nl_SX', label: 'Dutch (Sint Maarten)' },
  { value: 'nl_SR', label: 'Dutch (Suriname)' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'dz_BT', label: 'Dzongkha (Bhutan)' },
  { value: 'ebu', label: 'Embu' },
  { value: 'ebu_KE', label: 'Embu (Kenya)' },
  { value: 'en', label: 'English' },
  { value: 'en_AS', label: 'English (American Samoa)' },
  { value: 'en_AI', label: 'English (Anguilla)' },
  { value: 'en_AG', label: 'English (Antigua & Barbuda)' },
  { value: 'en_AU', label: 'English (Australia)' },
  { value: 'en_AT', label: 'English (Austria)' },
  { value: 'en_BS', label: 'English (Bahamas)' },
  { value: 'en_BB', label: 'English (Barbados)' },
  { value: 'en_BE', label: 'English (Belgium)' },
  { value: 'en_BZ', label: 'English (Belize)' },
  { value: 'en_BM', label: 'English (Bermuda)' },
  { value: 'en_BW', label: 'English (Botswana)' },
  { value: 'en_IO', label: 'English (British Indian Ocean Territory)' },
  { value: 'en_VG', label: 'English (British Virgin Islands)' },
  { value: 'en_BI', label: 'English (Burundi)' },
  { value: 'en_CM', label: 'English (Cameroon)' },
  { value: 'en_CA', label: 'English (Canada)' },
  { value: 'en_KY', label: 'English (Cayman Islands)' },
  { value: 'en_CX', label: 'English (Christmas Island)' },
  { value: 'en_CC', label: 'English (Cocos (Keeling) Islands)' },
  { value: 'en_CK', label: 'English (Cook Islands)' },
  { value: 'en_CY', label: 'English (Cyprus)' },
  { value: 'en_DK', label: 'English (Denmark)' },
  { value: 'en_DG', label: 'English (Diego Garcia)' },
  { value: 'en_DM', label: 'English (Dominica)' },
  { value: 'en_ER', label: 'English (Eritrea)' },
  { value: 'en_SZ', label: 'English (Eswatini)' },
  { value: 'en_150', label: 'English (Europe)' },
  { value: 'en_FK', label: 'English (Falkland Islands)' },
  { value: 'en_FJ', label: 'English (Fiji)' },
  { value: 'en_FI', label: 'English (Finland)' },
  { value: 'en_GM', label: 'English (Gambia)' },
  { value: 'en_DE', label: 'English (Germany)' },
  { value: 'en_GH', label: 'English (Ghana)' },
  { value: 'en_GI', label: 'English (Gibraltar)' },
  { value: 'en_GD', label: 'English (Grenada)' },
  { value: 'en_GU', label: 'English (Guam)' },
  { value: 'en_GG', label: 'English (Guernsey)' },
  { value: 'en_GY', label: 'English (Guyana)' },
  { value: 'en_HK', label: 'English (Hong Kong SAR China)' },
  { value: 'en_IN', label: 'English (India)' },
  { value: 'en_IE', label: 'English (Ireland)' },
  { value: 'en_IM', label: 'English (Isle of Man)' },
  { value: 'en_IL', label: 'English (Israel)' },
  { value: 'en_JM', label: 'English (Jamaica)' },
  { value: 'en_JE', label: 'English (Jersey)' },
  { value: 'en_KE', label: 'English (Kenya)' },
  { value: 'en_KI', label: 'English (Kiribati)' },
  { value: 'en_LS', label: 'English (Lesotho)' },
  { value: 'en_LR', label: 'English (Liberia)' },
  { value: 'en_MO', label: 'English (Macao SAR China)' },
  { value: 'en_MG', label: 'English (Madagascar)' },
  { value: 'en_MW', label: 'English (Malawi)' },
  { value: 'en_MY', label: 'English (Malaysia)' },
  { value: 'en_MT', label: 'English (Malta)' },
  { value: 'en_MH', label: 'English (Marshall Islands)' },
  { value: 'en_MU', label: 'English (Mauritius)' },
  { value: 'en_FM', label: 'English (Micronesia)' },
  { value: 'en_MS', label: 'English (Montserrat)' },
  { value: 'en_NA', label: 'English (Namibia)' },
  { value: 'en_NR', label: 'English (Nauru)' },
  { value: 'en_NL', label: 'English (Netherlands)' },
  { value: 'en_NZ', label: 'English (New Zealand)' },
  { value: 'en_NG', label: 'English (Nigeria)' },
  { value: 'en_NU', label: 'English (Niue)' },
  { value: 'en_NF', label: 'English (Norfolk Island)' },
  { value: 'en_MP', label: 'English (Northern Mariana Islands)' },
  { value: 'en_PK', label: 'English (Pakistan)' },
  { value: 'en_PW', label: 'English (Palau)' },
  { value: 'en_PG', label: 'English (Papua New Guinea)' },
  { value: 'en_PH', label: 'English (Philippines)' },
  { value: 'en_PN', label: 'English (Pitcairn Islands)' },
  { value: 'en_PR', label: 'English (Puerto Rico)' },
  { value: 'en_RW', label: 'English (Rwanda)' },
  { value: 'en_WS', label: 'English (Samoa)' },
  { value: 'en_SC', label: 'English (Seychelles)' },
  { value: 'en_SL', label: 'English (Sierra Leone)' },
  { value: 'en_SG', label: 'English (Singapore)' },
  { value: 'en_SX', label: 'English (Sint Maarten)' },
  { value: 'en_SI', label: 'English (Slovenia)' },
  { value: 'en_SB', label: 'English (Solomon Islands)' },
  { value: 'en_ZA', label: 'English (South Africa)' },
  { value: 'en_SS', label: 'English (South Sudan)' },
  { value: 'en_SH', label: 'English (St. Helena)' },
  { value: 'en_KN', label: 'English (St. Kitts & Nevis)' },
  { value: 'en_LC', label: 'English (St. Lucia)' },
  { value: 'en_VC', label: 'English (St. Vincent & Grenadines)' },
  { value: 'en_SD', label: 'English (Sudan)' },
  { value: 'en_SE', label: 'English (Sweden)' },
  { value: 'en_CH', label: 'English (Switzerland)' },
  { value: 'en_TZ', label: 'English (Tanzania)' },
  { value: 'en_TK', label: 'English (Tokelau)' },
  { value: 'en_TO', label: 'English (Tonga)' },
  { value: 'en_TT', label: 'English (Trinidad & Tobago)' },
  { value: 'en_TC', label: 'English (Turks & Caicos Islands)' },
  { value: 'en_TV', label: 'English (Tuvalu)' },
  { value: 'en_UM', label: 'English (U.S. Outlying Islands)' },
  { value: 'en_VI', label: 'English (U.S. Virgin Islands)' },
  { value: 'en_UG', label: 'English (Uganda)' },
  { value: 'en_GB', label: 'English (United Kingdom)' },
  { value: 'en_US', label: 'English (United States)' },
  { value: 'en_US_POSIX', label: 'English (United States, Computer)' },
  { value: 'en_VU', label: 'English (Vanuatu)' },
  { value: 'en_ZM', label: 'English (Zambia)' },
  { value: 'en_ZW', label: 'English (Zimbabwe)' },
  { value: 'en_001', label: 'English (world)' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'eo_001', label: 'Esperanto (world)' },
  { value: 'et', label: 'Estonian' },
  { value: 'et_EE', label: 'Estonian (Estonia)' },
  { value: 'ee', label: 'Ewe' },
  { value: 'ee_GH', label: 'Ewe (Ghana)' },
  { value: 'ee_TG', label: 'Ewe (Togo)' },
  { value: 'ewo', label: 'Ewondo' },
  { value: 'ewo_CM', label: 'Ewondo (Cameroon)' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fo_DK', label: 'Faroese (Denmark)' },
  { value: 'fo_FO', label: 'Faroese (Faroe Islands)' },
  { value: 'fil', label: 'Filipino' },
  { value: 'fil_PH', label: 'Filipino (Philippines)' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fi_FI', label: 'Finnish (Finland)' },
  { value: 'fr', label: 'French' },
  { value: 'fr_DZ', label: 'French (Algeria)' },
  { value: 'fr_BE', label: 'French (Belgium)' },
  { value: 'fr_BJ', label: 'French (Benin)' },
  { value: 'fr_BF', label: 'French (Burkina Faso)' },
  { value: 'fr_BI', label: 'French (Burundi)' },
  { value: 'fr_CM', label: 'French (Cameroon)' },
  { value: 'fr_CA', label: 'French (Canada)' },
  { value: 'fr_CF', label: 'French (Central African Republic)' },
  { value: 'fr_TD', label: 'French (Chad)' },
  { value: 'fr_KM', label: 'French (Comoros)' },
  { value: 'fr_CG', label: 'French (Congo - Brazzaville)' },
  { value: 'fr_CD', label: 'French (Congo - Kinshasa)' },
  { value: 'fr_CI', label: 'French (C\u00f4te d\u2019Ivoire)' },
  { value: 'fr_DJ', label: 'French (Djibouti)' },
  { value: 'fr_GQ', label: 'French (Equatorial Guinea)' },
  { value: 'fr_FR', label: 'French (France)' },
  { value: 'fr_GF', label: 'French (French Guiana)' },
  { value: 'fr_PF', label: 'French (French Polynesia)' },
  { value: 'fr_GA', label: 'French (Gabon)' },
  { value: 'fr_GP', label: 'French (Guadeloupe)' },
  { value: 'fr_GN', label: 'French (Guinea)' },
  { value: 'fr_HT', label: 'French (Haiti)' },
  { value: 'fr_LU', label: 'French (Luxembourg)' },
  { value: 'fr_MG', label: 'French (Madagascar)' },
  { value: 'fr_ML', label: 'French (Mali)' },
  { value: 'fr_MQ', label: 'French (Martinique)' },
  { value: 'fr_MR', label: 'French (Mauritania)' },
  { value: 'fr_MU', label: 'French (Mauritius)' },
  { value: 'fr_YT', label: 'French (Mayotte)' },
  { value: 'fr_MC', label: 'French (Monaco)' },
  { value: 'fr_MA', label: 'French (Morocco)' },
  { value: 'fr_NC', label: 'French (New Caledonia)' },
  { value: 'fr_NE', label: 'French (Niger)' },
  { value: 'fr_RW', label: 'French (Rwanda)' },
  { value: 'fr_RE', label: 'French (R\u00e9union)' },
  { value: 'fr_SN', label: 'French (Senegal)' },
  { value: 'fr_SC', label: 'French (Seychelles)' },
  { value: 'fr_BL', label: 'French (St. Barth\u00e9lemy)' },
  { value: 'fr_MF', label: 'French (St. Martin)' },
  { value: 'fr_PM', label: 'French (St. Pierre & Miquelon)' },
  { value: 'fr_CH', label: 'French (Switzerland)' },
  { value: 'fr_SY', label: 'French (Syria)' },
  { value: 'fr_TG', label: 'French (Togo)' },
  { value: 'fr_TN', label: 'French (Tunisia)' },
  { value: 'fr_VU', label: 'French (Vanuatu)' },
  { value: 'fr_WF', label: 'French (Wallis & Futuna)' },
  { value: 'fur', label: 'Friulian' },
  { value: 'fur_IT', label: 'Friulian (Italy)' },
  { value: 'ff', label: 'Fulah' },
  { value: 'ff_CM', label: 'Fulah (Latin, Cameroon)' },
  { value: 'ff_GN', label: 'Fulah (Latin, Guinea)' },
  { value: 'ff_MR', label: 'Fulah (Latin, Mauritania)' },
  { value: 'ff_SN', label: 'Fulah (Latin, Senegal)' },
  { value: 'gl', label: 'Galician' },
  { value: 'gl_ES', label: 'Galician (Spain)' },
  { value: 'lg', label: 'Ganda' },
  { value: 'lg_UG', label: 'Ganda (Uganda)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'ka_GE', label: 'Georgian (Georgia)' },
  { value: 'de', label: 'German' },
  { value: 'de_AT', label: 'German (Austria)' },
  { value: 'de_BE', label: 'German (Belgium)' },
  { value: 'de_DE', label: 'German (Germany)' },
  { value: 'de_LI', label: 'German (Liechtenstein)' },
  { value: 'de_LU', label: 'German (Luxembourg)' },
  { value: 'de_CH', label: 'German (Switzerland)' },
  { value: 'el', label: 'Greek' },
  { value: 'el_CY', label: 'Greek (Cyprus)' },
  { value: 'el_GR', label: 'Greek (Greece)' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'gu_IN', label: 'Gujarati (India)' },
  { value: 'guz', label: 'Gusii' },
  { value: 'guz_KE', label: 'Gusii (Kenya)' },
  { value: 'ha', label: 'Hausa' },
  { value: 'ha_GH', label: 'Hausa (Ghana)' },
  { value: 'ha_NE', label: 'Hausa (Niger)' },
  { value: 'ha_NG', label: 'Hausa (Nigeria)' },
  { value: 'haw', label: 'Hawaiian' },
  { value: 'haw_US', label: 'Hawaiian (United States)' },
  { value: 'he', label: 'Hebrew' },
  { value: 'he_IL', label: 'Hebrew (Israel)' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hi_IN', label: 'Hindi (India)' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'hu_HU', label: 'Hungarian (Hungary)' },
  { value: 'is', label: 'Icelandic' },
  { value: 'is_IS', label: 'Icelandic (Iceland)' },
  { value: 'ig', label: 'Igbo' },
  { value: 'ig_NG', label: 'Igbo (Nigeria)' },
  { value: 'smn', label: 'Inari Sami' },
  { value: 'smn_FI', label: 'Inari Sami (Finland)' },
  { value: 'id', label: 'Indonesian' },
  { value: 'id_ID', label: 'Indonesian (Indonesia)' },
  { value: 'ga', label: 'Irish' },
  { value: 'ga_IE', label: 'Irish (Ireland)' },
  { value: 'it', label: 'Italian' },
  { value: 'it_IT', label: 'Italian (Italy)' },
  { value: 'it_SM', label: 'Italian (San Marino)' },
  { value: 'it_CH', label: 'Italian (Switzerland)' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ja_JP', label: 'Japanese (Japan)' },
  { value: 'dyo', label: 'Jola-Fonyi' },
  { value: 'dyo_SN', label: 'Jola-Fonyi (Senegal)' },
  { value: 'kea', label: 'Kabuverdianu' },
  { value: 'kea_CV', label: 'Kabuverdianu (Cape Verde)' },
  { value: 'kab', label: 'Kabyle' },
  { value: 'kab_DZ', label: 'Kabyle (Algeria)' },
  { value: 'kkj', label: 'Kako' },
  { value: 'kkj_CM', label: 'Kako (Cameroon)' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'kl_GL', label: 'Kalaallisut (Greenland)' },
  { value: 'kln', label: 'Kalenjin' },
  { value: 'kln_KE', label: 'Kalenjin (Kenya)' },
  { value: 'kam', label: 'Kamba' },
  { value: 'kam_KE', label: 'Kamba (Kenya)' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kn_IN', label: 'Kannada (India)' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'ks_IN', label: 'Kashmiri (Arabic, India)' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'kk_KZ', label: 'Kazakh (Kazakhstan)' },
  { value: 'km', label: 'Khmer' },
  { value: 'km_KH', label: 'Khmer (Cambodia)' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'ki_KE', label: 'Kikuyu (Kenya)' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'rw_RW', label: 'Kinyarwanda (Rwanda)' },
  { value: 'kok', label: 'Konkani' },
  { value: 'kok_IN', label: 'Konkani (India)' },
  { value: 'ko', label: 'Korean' },
  { value: 'ko_KP', label: 'Korean (North Korea)' },
  { value: 'ko_KR', label: 'Korean (South Korea)' },
  { value: 'khq', label: 'Koyra Chiini' },
  { value: 'khq_ML', label: 'Koyra Chiini (Mali)' },
  { value: 'ses', label: 'Koyraboro Senni' },
  { value: 'ses_ML', label: 'Koyraboro Senni (Mali)' },
  { value: 'nmg', label: 'Kwasio' },
  { value: 'nmg_CM', label: 'Kwasio (Cameroon)' },
  { value: 'ky', label: 'Kyrgyz' },
  { value: 'ky_KG', label: 'Kyrgyz (Kyrgyzstan)' },
  { value: 'lkt', label: 'Lakota' },
  { value: 'lkt_US', label: 'Lakota (United States)' },
  { value: 'lag', label: 'Langi' },
  { value: 'lag_TZ', label: 'Langi (Tanzania)' },
  { value: 'lo', label: 'Lao' },
  { value: 'lo_LA', label: 'Lao (Laos)' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lv_LV', label: 'Latvian (Latvia)' },
  { value: 'ln', label: 'Lingala' },
  { value: 'ln_AO', label: 'Lingala (Angola)' },
  { value: 'ln_CF', label: 'Lingala (Central African Republic)' },
  { value: 'ln_CG', label: 'Lingala (Congo - Brazzaville)' },
  { value: 'ln_CD', label: 'Lingala (Congo - Kinshasa)' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lt_LT', label: 'Lithuanian (Lithuania)' },
  { value: 'dsb', label: 'Lower Sorbian' },
  { value: 'dsb_DE', label: 'Lower Sorbian (Germany)' },
  { value: 'lu', label: 'Luba-Katanga' },
  { value: 'lu_CD', label: 'Luba-Katanga (Congo - Kinshasa)' },
  { value: 'luo', label: 'Luo' },
  { value: 'luo_KE', label: 'Luo (Kenya)' },
  { value: 'lb', label: 'Luxembourgish' },
  { value: 'lb_LU', label: 'Luxembourgish (Luxembourg)' },
  { value: 'luy', label: 'Luyia' },
  { value: 'luy_KE', label: 'Luyia (Kenya)' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mk_MK', label: 'Macedonian (North Macedonia)' },
  { value: 'jmc', label: 'Machame' },
  { value: 'jmc_TZ', label: 'Machame (Tanzania)' },
  { value: 'mgh', label: 'Makhuwa-Meetto' },
  { value: 'mgh_MZ', label: 'Makhuwa-Meetto (Mozambique)' },
  { value: 'kde', label: 'Makonde' },
  { value: 'kde_TZ', label: 'Makonde (Tanzania)' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'mg_MG', label: 'Malagasy (Madagascar)' },
  { value: 'ms', label: 'Malay' },
  { value: 'ms_BN', label: 'Malay (Brunei)' },
  { value: 'ms_MY', label: 'Malay (Malaysia)' },
  { value: 'ms_SG', label: 'Malay (Singapore)' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'ml_IN', label: 'Malayalam (India)' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mt_MT', label: 'Maltese (Malta)' },
  { value: 'gv', label: 'Manx' },
  { value: 'gv_IM', label: 'Manx (Isle of Man)' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mr_IN', label: 'Marathi (India)' },
  { value: 'mas', label: 'Masai' },
  { value: 'mas_KE', label: 'Masai (Kenya)' },
  { value: 'mas_TZ', label: 'Masai (Tanzania)' },
  { value: 'mzn', label: 'Mazanderani' },
  { value: 'mzn_IR', label: 'Mazanderani (Iran)' },
  { value: 'mer', label: 'Meru' },
  { value: 'mer_KE', label: 'Meru (Kenya)' },
  { value: 'mgo', label: 'Meta\u02bc' },
  { value: 'mgo_CM', label: 'Meta\u02bc (Cameroon)' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'mn_MN', label: 'Mongolian (Mongolia)' },
  { value: 'mfe', label: 'Morisyen' },
  { value: 'mfe_MU', label: 'Morisyen (Mauritius)' },
  { value: 'mua', label: 'Mundang' },
  { value: 'mua_CM', label: 'Mundang (Cameroon)' },
  { value: 'naq', label: 'Nama' },
  { value: 'naq_NA', label: 'Nama (Namibia)' },
  { value: 'ne', label: 'Nepali' },
  { value: 'ne_IN', label: 'Nepali (India)' },
  { value: 'ne_NP', label: 'Nepali (Nepal)' },
  { value: 'nnh', label: 'Ngiemboon' },
  { value: 'nnh_CM', label: 'Ngiemboon (Cameroon)' },
  { value: 'jgo', label: 'Ngomba' },
  { value: 'jgo_CM', label: 'Ngomba (Cameroon)' },
  { value: 'nd', label: 'North Ndebele' },
  { value: 'nd_ZW', label: 'North Ndebele (Zimbabwe)' },
  { value: 'lrc', label: 'Northern Luri' },
  { value: 'lrc_IR', label: 'Northern Luri (Iran)' },
  { value: 'lrc_IQ', label: 'Northern Luri (Iraq)' },
  { value: 'se', label: 'Northern Sami' },
  { value: 'se_FI', label: 'Northern Sami (Finland)' },
  { value: 'se_NO', label: 'Northern Sami (Norway)' },
  { value: 'se_SE', label: 'Northern Sami (Sweden)' },
  { value: 'nb', label: 'Norwegian Bokm\u00e5l' },
  { value: 'nb_NO', label: 'Norwegian Bokm\u00e5l (Norway)' },
  { value: 'nb_SJ', label: 'Norwegian Bokm\u00e5l (Svalbard & Jan Mayen)' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'nn_NO', label: 'Norwegian Nynorsk (Norway)' },
  { value: 'nus', label: 'Nuer' },
  { value: 'nus_SS', label: 'Nuer (South Sudan)' },
  { value: 'nyn', label: 'Nyankole' },
  { value: 'nyn_UG', label: 'Nyankole (Uganda)' },
  { value: 'or', label: 'Odia' },
  { value: 'or_IN', label: 'Odia (India)' },
  { value: 'om', label: 'Oromo' },
  { value: 'om_ET', label: 'Oromo (Ethiopia)' },
  { value: 'om_KE', label: 'Oromo (Kenya)' },
  { value: 'os', label: 'Ossetic' },
  { value: 'os_GE', label: 'Ossetic (Georgia)' },
  { value: 'os_RU', label: 'Ossetic (Russia)' },
  { value: 'ps', label: 'Pashto' },
  { value: 'ps_AF', label: 'Pashto (Afghanistan)' },
  { value: 'fa', label: 'Persian' },
  { value: 'fa_AF', label: 'Persian (Afghanistan)' },
  { value: 'fa_IR', label: 'Persian (Iran)' },
  { value: 'pl', label: 'Polish' },
  { value: 'pl_PL', label: 'Polish (Poland)' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'pt_AO', label: 'Portuguese (Angola)' },
  { value: 'pt_BR', label: 'Portuguese (Brazil)' },
  { value: 'pt_CV', label: 'Portuguese (Cape Verde)' },
  { value: 'pt_GQ', label: 'Portuguese (Equatorial Guinea)' },
  { value: 'pt_GW', label: 'Portuguese (Guinea-Bissau)' },
  { value: 'pt_LU', label: 'Portuguese (Luxembourg)' },
  { value: 'pt_MO', label: 'Portuguese (Macao SAR China)' },
  { value: 'pt_MZ', label: 'Portuguese (Mozambique)' },
  { value: 'pt_PT', label: 'Portuguese (Portugal)' },
  { value: 'pt_CH', label: 'Portuguese (Switzerland)' },
  { value: 'pt_ST', label: 'Portuguese (S\u00e3o Tom\u00e9 & Pr\u00edncipe)' },
  { value: 'pt_TL', label: 'Portuguese (Timor-Leste)' },
  { value: 'prg', label: 'Prussian' },
  { value: 'prg_001', label: 'Prussian (World)' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'pa_Arab', label: 'Punjabi (Arabic)' },
  { value: 'pa_Arab_PK', label: 'Punjabi (Arabic, Pakistan)' },
  { value: 'pa_Guru', label: 'Punjabi (Gurmukhi)' },
  { value: 'pa_Guru_IN', label: 'Punjabi (Gurmukhi, India)' },
  { value: 'qu', label: 'Quechua' },
  { value: 'qu_BO', label: 'Quechua (Bolivia)' },
  { value: 'qu_EC', label: 'Quechua (Ecuador)' },
  { value: 'qu_PE', label: 'Quechua (Peru)' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ro_MD', label: 'Romanian (Moldova)' },
  { value: 'ro_RO', label: 'Romanian (Romania)' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rm_CH', label: 'Romansh (Switzerland)' },
  { value: 'rof', label: 'Rombo' },
  { value: 'rof_TZ', label: 'Rombo (Tanzania)' },
  { value: 'rn', label: 'Rundi' },
  { value: 'rn_BI', label: 'Rundi (Burundi)' },
  { value: 'ru', label: 'Russian' },
  { value: 'ru_BY', label: 'Russian (Belarus)' },
  { value: 'ru_KZ', label: 'Russian (Kazakhstan)' },
  { value: 'ru_KG', label: 'Russian (Kyrgyzstan)' },
  { value: 'ru_MD', label: 'Russian (Moldova)' },
  { value: 'ru_RU', label: 'Russian (Russia)' },
  { value: 'ru_UA', label: 'Russian (Ukraine)' },
  { value: 'rwk', label: 'Rwa' },
  { value: 'rwk_TZ', label: 'Rwa (Tanzania)' },
  { value: 'sah', label: 'Sakha' },
  { value: 'sah_RU', label: 'Sakha (Russia)' },
  { value: 'saq', label: 'Samburu' },
  { value: 'saq_KE', label: 'Samburu (Kenya)' },
  { value: 'sg', label: 'Sango' },
  { value: 'sg_CF', label: 'Sango (Central African Republic)' },
  { value: 'sbp', label: 'Sangu' },
  { value: 'sbp_TZ', label: 'Sangu (Tanzania)' },
  { value: 'gd', label: 'Scottish Gaelic' },
  { value: 'gd_GB', label: 'Scottish Gaelic (United Kingdom)' },
  { value: 'seh', label: 'Sena' },
  { value: 'seh_MZ', label: 'Sena (Mozambique)' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sr_Cyrl', label: 'Serbian (Cyrillic)' },
  { value: 'sr_Cyrl_BA', label: 'Serbian (Cyrillic, Bosnia & Herzegovina)' },
  { value: 'sr_Cyrl_XK', label: 'Serbian (Cyrillic, Kosovo)' },
  { value: 'sr_Cyrl_ME', label: 'Serbian (Cyrillic, Montenegro)' },
  { value: 'sr_Cyrl_RS', label: 'Serbian (Cyrillic, Serbia)' },
  { value: 'sr_Latn', label: 'Serbian (Latin)' },
  { value: 'sr_Latn_BA', label: 'Serbian (Latin, Bosnia & Herzegovina)' },
  { value: 'sr_Latn_XK', label: 'Serbian (Latin, Kosovo)' },
  { value: 'sr_Latn_ME', label: 'Serbian (Latin, Montenegro)' },
  { value: 'sr_Latn_RS', label: 'Serbian (Latin, Serbia)' },
  { value: 'ksb', label: 'Shambala' },
  { value: 'ksb_TZ', label: 'Shambala (Tanzania)' },
  { value: 'sn', label: 'Shona' },
  { value: 'sn_ZW', label: 'Shona (Zimbabwe)' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'ii_CN', label: 'Sichuan Yi (China)' },
  { value: 'si', label: 'Sinhala' },
  { value: 'si_LK', label: 'Sinhala (Sri Lanka)' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sk_SK', label: 'Slovak (Slovakia)' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'sl_SI', label: 'Slovenian (Slovenia)' },
  { value: 'xog', label: 'Soga' },
  { value: 'xog_UG', label: 'Soga (Uganda)' },
  { value: 'so', label: 'Somali' },
  { value: 'so_DJ', label: 'Somali (Djibouti)' },
  { value: 'so_ET', label: 'Somali (Ethiopia)' },
  { value: 'so_KE', label: 'Somali (Kenya)' },
  { value: 'so_SO', label: 'Somali (Somalia)' },
  { value: 'es', label: 'Spanish' },
  { value: 'es_AR', label: 'Spanish (Argentina)' },
  { value: 'es_BO', label: 'Spanish (Bolivia)' },
  { value: 'es_BR', label: 'Spanish (Brazil)' },
  { value: 'es_IC', label: 'Spanish (Canary Islands)' },
  { value: 'es_EA', label: 'Spanish (Ceuta & Melilla)' },
  { value: 'es_CL', label: 'Spanish (Chile)' },
  { value: 'es_CO', label: 'Spanish (Colombia)' },
  { value: 'es_CR', label: 'Spanish (Costa Rica)' },
  { value: 'es_CU', label: 'Spanish (Cuba)' },
  { value: 'es_DO', label: 'Spanish (Dominican Republic)' },
  { value: 'es_EC', label: 'Spanish (Ecuador)' },
  { value: 'es_SV', label: 'Spanish (El Salvador)' },
  { value: 'es_GQ', label: 'Spanish (Equatorial Guinea)' },
  { value: 'es_GT', label: 'Spanish (Guatemala)' },
  { value: 'es_HN', label: 'Spanish (Honduras)' },
  { value: 'es_419', label: 'Spanish (Latin America)' },
  { value: 'es_MX', label: 'Spanish (Mexico)' },
  { value: 'es_NI', label: 'Spanish (Nicaragua)' },
  { value: 'es_PA', label: 'Spanish (Panama)' },
  { value: 'es_PY', label: 'Spanish (Paraguay)' },
  { value: 'es_PE', label: 'Spanish (Peru)' },
  { value: 'es_PH', label: 'Spanish (Philippines)' },
  { value: 'es_PR', label: 'Spanish (Puerto Rico)' },
  { value: 'es_ES', label: 'Spanish (Spain)' },
  { value: 'es_US', label: 'Spanish (United States)' },
  { value: 'es_UY', label: 'Spanish (Uruguay)' },
  { value: 'es_VE', label: 'Spanish (Venezuela)' },
  { value: 'zgh', label: 'Standard Moroccan Tamazight' },
  { value: 'zgh_MA', label: 'Standard Moroccan Tamazight (Morocco)' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sw_CD', label: 'Swahili (Congo - Kinshasa)' },
  { value: 'sw_KE', label: 'Swahili (Kenya)' },
  { value: 'sw_TZ', label: 'Swahili (Tanzania)' },
  { value: 'sw_UG', label: 'Swahili (Uganda)' },
  { value: 'sv', label: 'Swedish' },
  { value: 'sv_FI', label: 'Swedish (Finland)' },
  { value: 'sv_SE', label: 'Swedish (Sweden)' },
  { value: 'sv_AX', label: 'Swedish (\u00c5land Islands)' },
  { value: 'gsw', label: 'Swiss German' },
  { value: 'gsw_FR', label: 'Swiss German (France)' },
  { value: 'gsw_LI', label: 'Swiss German (Liechtenstein)' },
  { value: 'gsw_CH', label: 'Swiss German (Switzerland)' },
  { value: 'shi', label: 'Tachelhit' },
  { value: 'shi_Latn', label: 'Tachelhit (Latin)' },
  { value: 'shi_Latn_MA', label: 'Tachelhit (Latin, Morocco)' },
  { value: 'shi_Tfng', label: 'Tachelhit (Tifinagh)' },
  { value: 'shi_Tfng_MA', label: 'Tachelhit (Tifinagh, Morocco)' },
  { value: 'dav', label: 'Taita' },
  { value: 'dav_KE', label: 'Taita (Kenya)' },
  { value: 'ta', label: 'Tamil' },
  { value: 'ta_IN', label: 'Tamil (India)' },
  { value: 'ta_MY', label: 'Tamil (Malaysia)' },
  { value: 'ta_SG', label: 'Tamil (Singapore)' },
  { value: 'ta_LK', label: 'Tamil (Sri Lanka)' },
  { value: 'twq', label: 'Tasawaq' },
  { value: 'twq_NE', label: 'Tasawaq (Niger)' },
  { value: 'te', label: 'Telugu' },
  { value: 'te_IN', label: 'Telugu (India)' },
  { value: 'teo', label: 'Teso' },
  { value: 'teo_KE', label: 'Teso (Kenya)' },
  { value: 'teo_UG', label: 'Teso (Uganda)' },
  { value: 'th', label: 'Thai' },
  { value: 'th_TH', label: 'Thai (Thailand)' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'bo_CN', label: 'Tibetan (China)' },
  { value: 'bo_IN', label: 'Tibetan (India)' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'ti_ER', label: 'Tigrinya (Eritrea)' },
  { value: 'ti_ET', label: 'Tigrinya (Ethiopia)' },
  { value: 'to', label: 'Tongan' },
  { value: 'to_TO', label: 'Tongan (Tonga)' },
  { value: 'tr', label: 'Turkish' },
  { value: 'tr_CY', label: 'Turkish (Cyprus)' },
  { value: 'tr_TR', label: 'Turkish (Turkey)' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tk_TM', label: 'Turkmen (Turkmenistan)' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'uk_UA', label: 'Ukrainian (Ukraine)' },
  { value: 'hsb', label: 'Upper Sorbian' },
  { value: 'hsb_DE', label: 'Upper Sorbian (Germany)' },
  { value: 'ur', label: 'Urdu' },
  { value: 'ur_IN', label: 'Urdu (India)' },
  { value: 'ur_PK', label: 'Urdu (Pakistan)' },
  { value: 'ug', label: 'Uyghur' },
  { value: 'ug_CN', label: 'Uyghur (China)' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'uz_Arab', label: 'Uzbek (Arabic)' },
  { value: 'uz_Arab_AF', label: 'Uzbek (Arabic, Afghanistan)' },
  { value: 'uz_Cyrl', label: 'Uzbek (Cyrillic)' },
  { value: 'uz_Cyrl_UZ', label: 'Uzbek (Cyrillic, Uzbekistan)' },
  { value: 'uz_Latn', label: 'Uzbek (Latin)' },
  { value: 'uz_Latn_UZ', label: 'Uzbek (Latin, Uzbekistan)' },
  { value: 'vai', label: 'Vai' },
  { value: 'vai_Latn', label: 'Vai (Latin)' },
  { value: 'vai_Latn_LR', label: 'Vai (Latin, Liberia)' },
  { value: 'vai_Vaii', label: 'Vai (Vai)' },
  { value: 'vai_Vaii_LR', label: 'Vai (Vai, Liberia)' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vi_VN', label: 'Vietnamese (Vietnam)' },
  { value: 'vo', label: 'Volap\u00fck' },
  { value: 'vo_001', label: 'Volap\u00fck (World)' },
  { value: 'vun', label: 'Vunjo' },
  { value: 'vun_TZ', label: 'Vunjo (Tanzania)' },
  { value: 'wae', label: 'Walser' },
  { value: 'wae_CH', label: 'Walser (Switzerland)' },
  { value: 'cy', label: 'Welsh' },
  { value: 'cy_GB', label: 'Welsh (United Kingdom)' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'fy_NL', label: 'Western Frisian (Netherlands)' },
  { value: 'yav', label: 'Yangben' },
  { value: 'yav_CM', label: 'Yangben (Cameroon)' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yi_001', label: 'Yiddish (world)' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'yo_BJ', label: 'Yoruba (Benin)' },
  { value: 'yo_NG', label: 'Yoruba (Nigeria)' },
  { value: 'dje', label: 'Zarma' },
  { value: 'dje_NE', label: 'Zarma (Niger)' },
  { value: 'zu', label: 'Zulu' },
  { value: 'zu_ZA', label: 'Zulu (South Africa)' },
];
