import { Remove } from '@rossum/ui/icons';
import { Box, BoxProps, styled } from '@rossum/ui/material';
import {
  CONTROL_BAR_DISTANCE,
  GRID_LINE_WIDTH,
  SEPARATOR_HANDLE_SIZE,
} from './constants';

export type SeparatorHandleProps = BoxProps & {
  orientation: 'horizontal' | 'vertical';
  showButton: boolean;
  isInteractive: boolean;
};

const StyledSeparatorHandle = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'orientation' &&
    propName !== 'showButton' &&
    propName !== 'isInteractive',
})<SeparatorHandleProps>(({ theme, orientation }) => {
  const [primaryPos, secondaryPos] =
    orientation === 'horizontal'
      ? (['top', 'left'] as const)
      : (['left', 'top'] as const);

  return {
    width: SEPARATOR_HANDLE_SIZE,
    height: SEPARATOR_HANDLE_SIZE,
    position: 'absolute',
    [primaryPos]: -SEPARATOR_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2,
    [secondaryPos]:
      -GRID_LINE_WIDTH / 2 - SEPARATOR_HANDLE_SIZE / 2 - CONTROL_BAR_DISTANCE,
    cursor: 'pointer',
    borderRadius: 3,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('opacity', { duration: 150 }),
  };
});

export const SeparatorHandle = (props: SeparatorHandleProps) => (
  <StyledSeparatorHandle
    {...props}
    style={{
      pointerEvents: props.isInteractive ? 'all' : 'none',
      opacity: props.showButton ? 1 : 0,
    }}
  >
    <Remove fontSize="inherit" />
  </StyledSeparatorHandle>
);
