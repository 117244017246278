import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MeasuringStrategy,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Paper, Stack } from '@rossum/ui/material';
import { last } from 'lodash';
import { useScrollToElementOnLoad } from '../../utils/hooks/useScrollToElementOnLoad';
import { EditedPage } from './EditedPage';
import { EditedPart } from './EditedPart';
import {
  DispatchEdit,
  EditDocumentConfig,
  EditState,
  findPage,
  isEditable,
  Part,
} from './editState';
import {
  closestCenterOnClosestRow,
  MouseSensorIgnoringNoDndElements,
} from './sensors';
import { UiStateTuple } from './uiState';

// PERF: This has to be defined as a stable reference, otherwise it will cause
// a lot of rerendering (because onMouseDown handler will be recreated)
const mouseSensorOptions = {
  activationConstraint: {
    // This should ensure we can click on a page (to scroll it into view)
    // without starting dragging
    delay: 200, // ms
    tolerance: 100, // px
  },
};

const shouldScrollOnLoad = (config: EditDocumentConfig, part: Part) =>
  config.mode.type === 'parent-annotation' &&
  part.annotationType === 'accessible' &&
  part.annotation.url === config.mode.fromAnnotation;

export const EditedPartList = ({
  editState,
  dispatchEdit,
  uiState: [uiState, setUiState],
  config,
  suggestedSplitsActive,
}: {
  editState: EditState;
  dispatchEdit: DispatchEdit;
  uiState: UiStateTuple;
  config: EditDocumentConfig;
  suggestedSplitsActive: boolean;
}) => {
  const { refCallback } = useScrollToElementOnLoad({ behavior: 'smooth' });

  const sensors = useSensors(
    useSensor(MouseSensorIgnoringNoDndElements, mouseSensorOptions)
  );

  const draggedPageNumber = uiState.draggedPage?.number;

  const draggedPage = draggedPageNumber ? (
    <EditedPage
      page={
        editState.parts
          .flatMap(p => p.pages)
          .find(p => p.pageNumber === draggedPageNumber)!
      }
      dispatchEdit={dispatchEdit}
      isLastPage={false}
      isFollowedByEditablePart={false}
      partIndex={-1}
      uiState={[
        {
          currentPageNumber: null,
          draggedPage: null,
          confirmInProgress: false,
          cancelChangesDialogOpen: false,
        },
        () => {},
      ]}
      mode="dragged-page"
      editable
      config={config}
    />
  ) : null;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    setUiState(s => ({ ...s, draggedPage: null }));

    if (over && active.id !== over.id && uiState.draggedPage) {
      const source = findPage(Number(active.id), editState.parts);
      const target = findPage(Number(over.id), editState.parts);

      if (source && target) {
        dispatchEdit({
          type: 'MOVE_PAGE',
          pageNumber: Number(active.id),
          targetPageNumber: Number(over.id),
          order:
            // When moving a page inside a part, we are reordering it after following pages,
            // but before preceding pages.
            source.pageIndex < target.pageIndex ? 'after' : 'before',

          // All temporary MOVE_PAGE actions are ignored when doing UNDO
          previousState: uiState.draggedPage.stateOnDragStart,
        });
      }
    }
    // TODO revert if dropped outside?
  };

  const isFollowedByEditablePart = (i: number) => {
    const nextPart = editState.parts[i + 1];
    return nextPart ? isEditable(nextPart) : false;
  };

  return (
    <DndContext
      sensors={sensors}
      // Not sure if we need it, but it's in the example
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      collisionDetection={closestCenterOnClosestRow}
      onDragCancel={() => {
        if (uiState.draggedPage) {
          // While dragging a page over a different part, it's temporarily moved there.
          // If we cancel the draggging operation, we have to reset the state back.
          dispatchEdit({
            type: 'RESET',
            newState: uiState.draggedPage.stateOnDragStart,
          });
        }
        setUiState(s => ({ ...s, draggedPage: null }));
      }}
      onDragEnd={handleDragEnd}
      onDragStart={e => {
        setUiState(s => ({
          ...s,
          draggedPage: {
            number: Number(e.active.id),
            stateOnDragStart: editState,
          },
        }));
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (!overId) {
          return;
        }

        const { draggedPage } = uiState;

        if (!draggedPage) {
          return;
        }

        const overPart = editState.parts.find(part =>
          part.pages.some(p => p.pageNumber === Number(overId))
        );
        const partOfTheDraggedPage = editState.parts.find(d =>
          d.pages.some(p => p.pageNumber === Number(draggedPage.number))
        );

        if (!overPart || !partOfTheDraggedPage) {
          return;
        }

        if (partOfTheDraggedPage !== overPart) {
          // Normally, when we drag a page over another page,
          // it will be placed "before" that page.
          // However, when we drag a page over an empty part of the row
          // we want to place it "after" the last page.
          const order =
            Number(over.id) === last(overPart.pages)?.pageNumber &&
            active.rect.current.translated &&
            active.rect.current.translated.left > over.rect.right
              ? 'after'
              : 'before';

          // If we are reordering pages inside one document,
          // we do it in onDragEnd. However, when reordering into
          // a different document, we need to move the page temporarily
          // into that document (and make it undoable)
          dispatchEdit({
            type: 'MOVE_PAGE',
            order,
            pageNumber: Number(active.id),
            targetPageNumber: Number(over.id),
            // All temporary MOVE_PAGE actions are ignored when doing UNDO
            previousState: draggedPage.stateOnDragStart,
          });
        }
      }}
    >
      <Paper
        elevation={2}
        sx={{
          overflowY: 'auto',
          height: '100%',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          background: theme =>
            theme.palette.mode === 'light' ? '#F8F8F8' : undefined,
          boxShadow: theme =>
            theme.palette.mode === 'light'
              ? '0px 0px 10px rgba(81, 81, 81, 0.04), inset 0px 0px 0px 1px #EEEEEE'
              : undefined,
        }}
      >
        <Stack spacing={4} sx={{ p: 4 }}>
          {editState.parts.map((p, i) => (
            <EditedPart
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              index={i}
              part={p}
              dispatchEdit={dispatchEdit}
              isFollowedByEditablePart={isFollowedByEditablePart(i)}
              uiState={[uiState, setUiState]}
              config={config}
              suggestedSplitsActive={suggestedSplitsActive}
              ref={shouldScrollOnLoad(config, p) ? refCallback : undefined}
            />
          ))}
        </Stack>
        <DragOverlay zIndex={10}>{draggedPage}</DragOverlay>
      </Paper>
    </DndContext>
  );
};
