import { ElisRequestConfig } from '../../utils/requestConfig';
import { ResetPassword } from '../models/resetPassword';
import { resetPasswordSchema } from '../models/resetPassword.schema';
import { resetPayloadSchema } from './resetPassword.schema';

export type ResetPayload = {
  email: string;
};

export const resetPassword = (payload: ResetPayload) => {
  return {
    endpoint: `/auth/password/reset`,
    method: 'POST',
    responseSchema: resetPasswordSchema,
    payloadSchema: resetPayloadSchema,
    payload,
  } as const;
};
