import { ReactNode } from 'react';
import { useCanvasGeometryState } from '../../document-store/DocumentStore';
import { PageRectangle } from '../useCanvasDimensions';

type LineItemsTransformWrapperProps = {
  children: ReactNode;
  visiblePage: PageRectangle;
};

export const LineItemsTransformWrapper = ({
  children,
  visiblePage,
}: LineItemsTransformWrapperProps) => {
  const canvasState = useCanvasGeometryState();

  return (
    <foreignObject
      x={
        visiblePage.dimensions.x * canvasState.zoomLevel +
        canvasState.translateX
      }
      y={
        visiblePage.dimensions.y * canvasState.zoomLevel +
        canvasState.translateY
      }
      width={visiblePage.dimensions.width * canvasState.zoomLevel}
      height={visiblePage.dimensions.height * canvasState.zoomLevel}
      overflow="visible"
      pointerEvents="none"
    >
      {children}
    </foreignObject>
  );
};
