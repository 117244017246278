import { ActionType, createAction } from 'typesafe-actions';
import { LocalizationKeys, NeverTranslated } from '../../../i18n';

type ThrowErrorMeta = 'error';
type ThrowInfoMeta = 'info';

type ThrowPayload = {
  id: MessageId;
  options: object;
  values: object | undefined;
};

export type MessageId<
  T extends string = 'title',
  K extends string = LocalizationKeys,
> =
  | (K extends `containers.message.${infer A}.${T}` ? A : never)
  | NeverTranslated<`containers.message.%.title`>;

export const throwError = createAction(
  'TOAST_MESSAGE',
  (id: string, options: object = {}, values?: object) => ({
    id,
    options,
    values,
  }),
  (_id: MessageId, _options: object = {}, _values?: object) => ({
    type: 'error',
  })
)<ThrowPayload, { type: ThrowErrorMeta }>();

export const throwInfo = createAction(
  'TOAST_MESSAGE',
  (id: MessageId, options: object = {}, values?: object) => ({
    id,
    options,
    values,
  }),
  (_id: string, _options: object = {}, _values?: object) => ({ type: 'info' })
)<ThrowPayload, { type: ThrowInfoMeta }>();

export type ThrowError = typeof throwError;
type ThrowInfo = typeof throwInfo;

export type MessagesActions = ActionType<ThrowError | ThrowInfo>;
