import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngine } from '../models/dedicatedEngine';
import { dedicatedEngineSchema } from '../models/dedicatedEngine.schema';
import { dedicatedEngineRequestPayloadSchema } from './request.schema';

export type DedicatedEngineRequestPayload = Partial<{
  documentType: string;
  documentLanguage: string;
  volume: number;
  // Remove for now, maybe put back later
  // sampleUploads: FileList;
}>;

export const requestEndpoint = (payload: DedicatedEngineRequestPayload) => {
  return {
    endpoint: `/dedicated_engines/request`,
    // we are sending files in this one
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    responseSchema: dedicatedEngineSchema,
    payloadSchema: dedicatedEngineRequestPayloadSchema,
    payload,
  } as const;
};
