import { Tooltip, TooltipProps } from '@rossum/rossum-ui/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  translationId: LocalizationKeys;
  translationValues?: React.ComponentProps<typeof FormattedMessage>['values'];
  dark?: boolean;
} & Omit<TooltipProps, 'content'>;

class TranslatedTooltip extends React.PureComponent<Props> {
  static defaultProps = {
    dark: false,
    translationValues: {},
  };

  render() {
    const { translationId, translationValues, ...props } = this.props;
    return (
      <Tooltip
        {...props}
        content={
          <FormattedMessage id={translationId} values={translationValues} />
        }
      />
    );
  }
}

export default TranslatedTooltip;
