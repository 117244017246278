import { HookType } from '@rossum/api-client/hooks';
import { LogLevel } from '@rossum/api-client/hooks';
import { ExpandMore } from '@rossum/ui/icons';
import { AccordionSummary, Divider, Stack } from '@rossum/ui/material';
import {
  LOG_ARROW_WIDTH_SPACING,
  LOG_PADDING_SPACING,
  logsColumns,
} from '../helpers';

type LogOverviewProps = {
  columns: {
    timestamp: string;
    logLevel: LogLevel;
    event: string;
    extension: string | JSX.Element;
    message: string;
  };
  extensionType: HookType;
};

export const LogOverview = ({ columns, extensionType }: LogOverviewProps) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMore />}
      sx={{
        userSelect: 'auto',
        minHeight: t => t.spacing(4),
        px: LOG_PADDING_SPACING,
        '& .MuiAccordionSummary-content': {
          width: 0, // Hack for not expanding width of AccordionSummary over 100% of parent
          my: 0,
          py: 1,
          opacity: 0.8,
          transition: 'opacity 0.2s ease',
          '&:hover': {
            opacity: 1,
          },
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          width: t => t.spacing(LOG_ARROW_WIDTH_SPACING),
        },
      }}
    >
      {logsColumns.map(({ name, width, maxWidth, Wrapper }, index) => (
        <Stack key={name} width={width} maxWidth={maxWidth} direction="row">
          <Wrapper
            width="100%"
            value={columns[name]}
            extensionType={extensionType}
            level={columns.logLevel}
          />
          {index + 1 === logsColumns.length ? null : (
            <Divider orientation="vertical" sx={{ mr: 2 }} />
          )}
        </Stack>
      ))}
    </AccordionSummary>
  );
};
