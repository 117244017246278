import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@rossum/ui/icons';
import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { AggregateStat } from '../../../components/Graphs/components/AggregateStat';
import { formatNumberToLocale } from '../../../lib/helpers';
import { BillingVolume, getColor, getPercentage } from '../helpers';

const aggregateValue = (
  value: number,
  valuePercentage: number,
  locale: string
) =>
  `${formatNumberToLocale(value, locale)} (${getPercentage(valuePercentage)})`;

type AggregateUsageProps = {
  volume: BillingVolume | null;
};

const getValues = (volume: BillingVolume | null) => {
  if (volume && volume.purchased > 0) {
    const used = volume.billable;
    const usedPercentage = (volume.billable / volume.purchased) * 100;
    const remaining = Math.max(volume.purchased - volume.billable, 0);
    const remainingPercentage = (remaining / volume.purchased) * 100;
    const usedColor = getColor(usedPercentage);
    return { used, usedPercentage, remaining, remainingPercentage, usedColor };
  }

  return null;
};

const AggregateUsage = ({ volume }: AggregateUsageProps) => {
  const intl = useIntl();

  const values = getValues(volume);
  const unit = volume?.unit ?? 'pages';
  const used = volume?.billable ?? 0;

  return (
    <Stack flexDirection="row">
      <AggregateStat
        value={
          values
            ? aggregateValue(values.used, values.usedPercentage, intl.locale)
            : used
        }
        icon={
          <InsertDriveFileOutlinedIcon
            sx={{
              fill: theme => theme.palette[values?.usedColor ?? 'info'].main,
            }}
          />
        }
        description={intl.formatMessage({
          id:
            unit === 'pages'
              ? 'containers.billing.overview.usedPages'
              : 'containers.billing.overview.usedDocuments',
        })}
      />
      {values && (
        <AggregateStat
          value={aggregateValue(
            values.remaining,
            values.remainingPercentage,
            intl.locale
          )}
          icon={
            <InsertDriveFileOutlinedIcon
              sx={{
                fill: theme => theme.palette.text.disabled,
              }}
            />
          }
          description={intl.formatMessage({
            id:
              unit === 'pages'
                ? 'containers.billing.overview.remainingPages'
                : 'containers.billing.overview.remainingDocuments',
          })}
        />
      )}
    </Stack>
  );
};

export default AggregateUsage;
