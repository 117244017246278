import { ElisRequestConfig } from '../../utils/requestConfig';
import { PageData } from '../models/pageData';
import { pageDataSchema } from '../models/pageData.schema';
import { pageDataQuerySchema } from './getPageData.schema';
import {
  UnpaginatedListResponse,
  unpaginatedListResponse,
} from '../../utils/listResponse';

export type PageDataQuery = {
  // 'texts' missing in api client. Has a different response model!
  granularity: 'lines' | 'words' | 'chars';
  pageNumbers: number[];
};

export const getPageData = (
  annotationId: number,
  query: PageDataQuery
): ElisRequestConfig<
  UnpaginatedListResponse<PageData>,
  PageDataQuery,
  never
> => {
  return {
    endpoint: `/annotations/${annotationId}/page_data`,
    method: 'GET',
    responseSchema: unpaginatedListResponse(pageDataSchema),
    query,
    querySchema: pageDataQuerySchema,
  };
};
