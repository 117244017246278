import {
  alpha,
  Box,
  Step,
  StepButton,
  StepConnector,
  stepIconClasses,
  StepLabel,
  Stepper,
} from '@rossum/ui/material';
import { StepWorkflowStepState } from '../../../components/UI/StepWorkflow/useStepWorkflow';

type AddQueueStepperProps = {
  activeStep: number;
  steps: StepWorkflowStepState[];
  onChange: (step: number) => void;
};

export const AddQueueStepper = ({
  activeStep,
  steps,
  onChange,
}: AddQueueStepperProps) => {
  return (
    <Box paddingX={1} paddingY={2}>
      <Stepper
        activeStep={activeStep}
        connector={<StepConnector sx={{ width: '32px', flexGrow: 0 }} />}
      >
        {steps.map((step, index) => (
          <Step
            key={step.key}
            disabled={index >= activeStep}
            completed={step.completed}
          >
            <StepButton
              onClick={() => {
                onChange(index);
              }}
              sx={{ px: 2, py: 1 }}
            >
              <StepLabel
                error={step.error}
                StepIconProps={{
                  sx: {
                    fontSize: 24,
                    text: {
                      fill: theme => theme.palette.background.default,
                    },
                    [`&.${stepIconClasses.root}`]: {
                      color: theme => alpha(theme.palette.action.active, 0.16),
                    },
                    [`&.${stepIconClasses.active},
                    &.${stepIconClasses.completed}`]: {
                      color: theme => theme.palette.primary.main,
                    },
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
