import { Close } from '@rossum/ui/icons';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { closeModal } from '../../redux/modules/modal/actions';
import { State as ReduxState } from '../../types/state';
import { ConfirmTypeT, ModalKey } from './types';

type DispatchProps = {
  close: () => void;
  dispatch: Dispatch;
};

type StateProps = {
  confirmAction?: () => AnyAction;
  confirmType?: ConfirmTypeT;
  isOpen?: boolean;
  onConfirm?: () => void;
  textId?: ModalKey;
  values?: Record<string, unknown>;
};

type OwnProps = {
  children?: ReactNode;
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  textId?: ModalKey;
  values?: Record<string, unknown>;
  confirmType?: ConfirmTypeT;
};

type Props = StateProps & OwnProps & DispatchProps;
const ReduxModal = ({
  children,
  close,
  confirmAction,
  confirmType,
  dispatch,
  isOpen,
  onCancel,
  onConfirm,
  textId,
  values,
}: Props) => {
  if (!textId) {
    return null;
  }

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    if (confirmAction) dispatch(confirmAction());
    close();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    if (close) close();
  };

  return (
    <Dialog
      open={isOpen || false}
      onClose={() => {
        handleCancel();
      }}
      PaperProps={{ sx: { minWidth: 400 }, elevation: 2 }}
    >
      <Box
        sx={{
          backgroundColor: theme =>
            alpha(
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black,
              0.05
            ),
          py: 1,
          px: 2,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormattedMessage id={`components.modal.${textId}.title`} />
            <IconButton
              size="small"
              color="inherit"
              onClick={() => handleCancel()}
            >
              <Close />
            </IconButton>
          </Stack>
        </Typography>
      </Box>
      <DialogContent>
        {!children && (
          <Box data-cy="modal-text">
            <Typography variant="body2" component="div">
              <FormattedMessage
                id={`components.modal.${textId}.text`}
                values={{
                  ...values,
                  info: (msg: ReactNode) => (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {msg}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Box>
        )}
        {children}
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 2 }}>
        <Button
          variant="outlined"
          data-cy="modal-cancel-button"
          color="secondary"
          onClick={() => handleCancel()}
        >
          <FormattedMessage id={`components.modal.${textId}.cancel`} />
        </Button>
        <Button
          autoFocus
          variant="contained"
          data-cy="modal-confirm-button"
          color={confirmType === 'Primary' ? 'primary' : 'error'}
          onClick={() => handleConfirm()}
        >
          <FormattedMessage id={`components.modal.${textId}.confirm`} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: () => dispatch(closeModal()),
  dispatch,
});

const mapStateToProps = (
  state: ReduxState,
  { isOpen, textId, onConfirm, values, confirmType }: OwnProps
): StateProps => ({
  confirmAction: state.modal.confirmAction,
  confirmType: confirmType || state.modal.confirmType,
  isOpen: isOpen || state.modal.isOpen,
  onConfirm: onConfirm || state.modal.onConfirm,
  textId: textId || state.modal.textId,
  values: values || state.modal.values,
});

export default connect<StateProps, DispatchProps, OwnProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(ReduxModal);
