import { Box, BoxProps } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { Rectangle2D } from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';

type ContentInBboxCoordinatesProps = {
  children: ReactNode;
  pos: Rectangle2D;
} & BoxProps;

export const ContentInBboxCoordinates = ({
  children,
  pos,
  ...boxProps
}: ContentInBboxCoordinatesProps) => {
  const viewportState = useDocumentStore(state => state.viewportState);
  const canvasState = useDocumentStore(state => state.canvasState);
  const bboxRectangle = {
    x:
      (pos.x * canvasState.zoomLevel + canvasState.translateX) *
      viewportState.zoomLevel,
    y:
      (pos.y * canvasState.zoomLevel + canvasState.translateY) *
      viewportState.zoomLevel,
    width: pos.width * viewportState.zoomLevel * canvasState.zoomLevel,
    height: pos.height * viewportState.zoomLevel * canvasState.zoomLevel,
  };

  return (
    <Box
      {...boxProps}
      style={{
        position: 'absolute',
        width: bboxRectangle.width,
        left: bboxRectangle.x,
        top: bboxRectangle.y + bboxRectangle.height,
        ...boxProps.style,
      }}
    >
      {children}
    </Box>
  );
};
