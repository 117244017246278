import { endpoints } from '@rossum/api-client';
import { SchemaFieldListQuery } from '@rossum/api-client/schemaFields';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../lib/apiClient';

const SCHEMA_FIELDS_SEARCH_QUERY_KEY = 'organization-schema-fields-search';

type Props = {
  search: Required<SchemaFieldListQuery>['search'];
  queueId?: number;
  enabled?: boolean;
};

const useSearchSchemaFields = ({ search, queueId, enabled = true }: Props) => {
  const query: SchemaFieldListQuery = {
    search,
    queue: queueId ? [queueId] : [],
    headerFieldsOnly: true,
  };

  return useQuery({
    queryKey: [SCHEMA_FIELDS_SEARCH_QUERY_KEY, query] as const,
    queryFn: () => api.request(endpoints.schemaFields.list(query)),
    enabled,
  });
};

export default useSearchSchemaFields;
