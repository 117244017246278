import { SchemaDatapointEnumOption } from '@rossum/api-client/schemas';
import { useIntl } from 'react-intl';
import { PreviewDrawer } from '../../ui/PreviewDrawer';
import { EnumAndFormulaCellValue } from '../columns/types';
import { EnumPreview } from './components/EnumPreview';

type OptionsForEnumDrawerProps = {
  onClose: () => void;
  optionsForEnumDialog: {
    options: SchemaDatapointEnumOption[];
    value: EnumAndFormulaCellValue;
  } | null;
};

export const EnumDrawer = ({
  onClose,
  optionsForEnumDialog,
}: OptionsForEnumDrawerProps) => {
  const options = optionsForEnumDialog?.options ?? [];
  const intl = useIntl();

  const optionsLength = options?.length ?? 0;

  const title = optionsForEnumDialog?.value.label ?? '';
  return (
    <PreviewDrawer
      onClose={onClose}
      open={!!optionsForEnumDialog}
      title={title}
      subtitle={intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.enumFormulaEditing.enumDrawer.subtitle',
        },
        { count: optionsLength }
      )}
      elevation={4}
      content={options?.length ? <EnumPreview options={options} /> : null}
    />
  );
};
