// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { workflowStepUrlSchema } from '../../workflowSteps/models/workflowStep.schema';

export const workflowRunUrlSchema = urlSchema;

export const workflowRunStatusSchema = z.union([
  z.literal('pending'),
  z.literal('approved'),
  z.literal('rejected'),
  z.literal('in_review'),
]);

export const workflowRunSchema = z.object({
  id: idSchema,
  url: workflowRunUrlSchema,
  annotation: urlSchema,
  currentStep: workflowStepUrlSchema.nullable(),
  organization: urlSchema,
  workflowStatus: workflowRunStatusSchema,
});
