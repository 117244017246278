// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const connectorListQuerySchema = z
  .object({
    id: z.union([idSchema, z.array(idSchema)]),
    name: z.string(),
    serviceUrl: urlSchema,
  })
  .partial()
  .and(paginationQuerySchema);
