import { InfoOutlined } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';
import { Chunks, linebreak } from '../../../lib/formaterValues/index';

type Props = {
  id: LocalizationKeys;
  values: { [key: string]: (_chunks: Chunks[]) => JSX.Element };
};

const HelpLinkTooltip = ({ id, values }: Props) => (
  <Tooltip
    placement="top"
    title={<FormattedMessage id={id} values={{ linebreak, ...values }} />}
  >
    <InfoOutlined
      fontSize="small"
      sx={{ color: theme => theme.palette.text.secondary }}
    />
  </Tooltip>
);

export default HelpLinkTooltip;
