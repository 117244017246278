import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { canCreateRectangleSelector } from '../../../../redux/modules/datapoints/selector';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
  rectangleFromCoordinates,
  rectangleToCoordinates,
} from '../../document-canvas/utils/geometry';
import { getPageNumberByPoint } from '../../document-store/documentGeometry';
import {
  useDocumentStore,
  useDocumentStoreContext,
} from '../../document-store/DocumentStore';
import { safeInverse } from '../../document-store/helpers';
import { useDocumentContext } from '../../DocumentContext';
import { UseCanvasDimensions } from '../useCanvasDimensions';
import { getRectangleFromPoints } from './getRectangleFromPoints';
import { useRectangleCreator } from './useRectangleCreator';

export const intersectRectangles = (
  rect1: Rectangle2D,
  rect2: Rectangle2D
): Rectangle2D | undefined => {
  const r1 = rectangleToCoordinates(rect1);
  const r2 = rectangleToCoordinates(rect2);

  const x1 = Math.max(r1[0], r2[0]);
  const y1 = Math.max(r1[1], r2[1]);
  const x2 = Math.min(r1[2], r2[2]);
  const y2 = Math.min(r1[3], r2[3]);

  const rectangle = rectangleFromCoordinates([x1, y1, x2, y2]);
  return {
    ...rectangle,
    height: Math.max(5, rectangle.height),
    width: Math.max(5, rectangle.width),
  };
};

const qualifiesAsBbox = (startPoint: Point2D, endPoint: Point2D): boolean => {
  return (
    Math.abs(startPoint.x - endPoint.x) >= 5 &&
    Math.abs(startPoint.y - endPoint.y) >= 5
  );
};

const getRectangleInfoFromPoints = (
  p1: Point2D,
  p2: Point2D,
  startMatrix: DOMMatrix,
  endMatrix: DOMMatrix,
  dimensions: UseCanvasDimensions
) => {
  const startPoint = DOMPoint.fromPoint(p1).matrixTransform(startMatrix);
  const endPoint = DOMPoint.fromPoint(p2).matrixTransform(endMatrix);
  const startPage = getPageNumberByPoint(startPoint, dimensions.pages);
  const rectangle = qualifiesAsBbox(p1, p2)
    ? getRectangleFromPoints(startPoint, endPoint)
    : undefined;

  const pageRectangle =
    rectangle && startPage
      ? intersectRectangles(rectangle, startPage.dimensions)
      : undefined;

  return {
    startPoint,
    endPoint,
    startPage,
    pageRectangle,
  };
};

export const useDrawBoundingBox = ({
  onFinish,
}: {
  onFinish: (pageNumber: number, position: Rectangle2D) => void;
}) => {
  const canCreateBoundingBox = useSelector(canCreateRectangleSelector);
  const { setCanvasActionInProgress } = useDocumentContext();
  const { dimensions } = useDocumentStoreContext();

  const initialInverseMatrix = useRef<DOMMatrix>(IDENTITY_MATRIX_2D);
  const getCanvasCTM = useDocumentStore(state => state.getCanvasCTM);

  const { startPoint, dragPoint, handleMouseDown } = useRectangleCreator({
    onRectangleCreated: (startPoint, endPoint) => {
      const inverseMatrix = safeInverse(getCanvasCTM());
      const { pageRectangle, startPage } = getRectangleInfoFromPoints(
        startPoint,
        endPoint,
        initialInverseMatrix.current,
        inverseMatrix,
        dimensions
      );

      if (pageRectangle && startPage) {
        onFinish(startPage.pageNumber, {
          width: pageRectangle.width,
          height: pageRectangle.height,
          x: pageRectangle.x - startPage.dimensions.x,
          y: pageRectangle.y - startPage.dimensions.y,
        });
      }
    },
  });

  useEffect(() => {
    setCanvasActionInProgress(!!dragPoint);
  }, [dragPoint, setCanvasActionInProgress]);

  const overrideMouseDown = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (!event.shiftKey) {
        initialInverseMatrix.current = getCanvasCTM().inverse();
        if (
          event.target instanceof SVGElement &&
          event.target.classList.contains('bounding-box')
        ) {
          // Do not allow creating bounding boxes over current bounding boxes.
          // Does this really make sense?
        } else if (!canCreateBoundingBox) {
          // Do not register mouse down if user is not allowed to start drawing.
        } else {
          handleMouseDown(event);
        }
      }
    },
    [canCreateBoundingBox, getCanvasCTM, handleMouseDown]
  );

  const rectangleInfo =
    startPoint && dragPoint
      ? getRectangleInfoFromPoints(
          startPoint,
          dragPoint,
          initialInverseMatrix.current,
          safeInverse(getCanvasCTM()),
          dimensions
        )
      : undefined;

  return {
    boundingBox: rectangleInfo?.pageRectangle,
    handleMouseDown: overrideMouseDown,
  };
};
