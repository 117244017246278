import { create } from './create';
import { get } from './get';
import { patch } from './patch';
import { predict } from './predict';
import { validate } from './validate';
export { type DedicatedEngineSchemaPatchPayload } from './patch';
export { type DedicatedEngineSchemaCreatePayload } from './create';
export { type DedicatedEngineSchemaGetQuery } from './get';
export { type DedicatedEngineSchemaValidatePayload } from './validate';

export const engineSchemas = {
  get,
  create,
  patch,
  predict,
  validate,
};
