import { DetailDrawerProps } from '@rossum/rossum-ui/DetailDrawer';
import { Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Extension } from '../../../../types/extensions';
import { useOpenModal } from '../../../../utils/hooks/useOpenModal';
import ConfigAppDrawer from './components/ConfigAppDrawer';
import ConfigAppRoute from './components/ConfigAppRoute';
import { IFrameLoading } from './components/IFrameLoading';
import ConfigAppContainer from './ConfigAppContainer';
import { useExtensionConfigAppContext } from './context/configAppContext';
import { useRefetchHook } from './hooks/useRefetchHookOnDrawerClose';

type Props = {
  configAppUrl: string;
  DrawerProps: Omit<DetailDrawerProps, 'onClose'> & { onClose?: () => void };
  selectedExtension: Extension;
};

const ConfigApp = ({ DrawerProps, configAppUrl, selectedExtension }: Props) => {
  const { id } = selectedExtension;
  const intl = useIntl();

  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const [shouldLeaveSafely, setShouldLeaveSafely] = useState(false);
  const [ModalDialog, openModal] = useOpenModal();

  const { refetch, isFetching } = useRefetchHook(id);
  const { setTimestamp } = useExtensionConfigAppContext();
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const handleClose = () => {
    const onCloseCallback = () => {
      setTimestamp(Date.now());
      DrawerProps.onClose?.();
      setIsIFrameLoaded(false);
      setShouldRefetch(true);
    };

    if (shouldLeaveSafely)
      return openModal({
        onConfirm: onCloseCallback,
        textId: 'confirmCloseDrawer',
        confirmType: 'Danger',
      });

    if (shouldRefetch && isIFrameLoaded) {
      return refetch().then(onCloseCallback);
    }

    return onCloseCallback();
  };

  const isFullscreen =
    selectedExtension.config.app?.displayMode === 'fullscreen';

  const Wrapper = isFullscreen ? ConfigAppRoute : ConfigAppDrawer;

  return (
    <Wrapper
      handleClose={handleClose}
      name={selectedExtension.name}
      id={selectedExtension.id}
      open={!!DrawerProps.open}
    >
      {isFetching && (
        <IFrameLoading
          message={
            <Typography variant="h5">
              {intl.formatMessage({
                id: 'containers.settings.extensions.configApp.isRefetchingHook',
              })}
            </Typography>
          }
        />
      )}
      <ConfigAppContainer
        selectedExtension={selectedExtension}
        configAppUrl={configAppUrl}
        onLoad={setIsIFrameLoaded}
        isLoaded={isIFrameLoaded}
        setShouldRefetch={setShouldRefetch}
        onClose={handleClose}
        setShouldLeaveSafely={setShouldLeaveSafely}
      />
      {ModalDialog}
    </Wrapper>
  );
};

export default ConfigApp;
