import { ElisRequestConfig } from '../../utils/requestConfig';
import { StartEditPagesResponse } from '../models/editPages';
import { startEditPagesResponseSchema } from '../models/editPages.schema';

export const startEditPages = (annotationId: number) => {
  return {
    endpoint: `/annotations/${annotationId}/edit_pages/start`,
    method: 'POST',
    responseSchema: startEditPagesResponseSchema,
  } as const;
};
