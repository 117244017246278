import { createElisClient, endpoints } from '@rossum/api-client';
import { apiUrl } from '../../../constants/config';
import { api } from '../../../lib/apiClient';
import { getAuthToken } from '../../../lib/token';

const WORKFLOWS_TOKEN = 'workflowsToken';

export const getWorkflowsAuthToken = () =>
  localStorage.getItem(WORKFLOWS_TOKEN);

const setWorkflowsAuthToken = (token: string) =>
  localStorage.setItem(WORKFLOWS_TOKEN, token);

const clearWorkflowsAuthToken = () => localStorage.removeItem(WORKFLOWS_TOKEN);

export const workflowsApi = createElisClient({
  baseUrl: apiUrl,
  getAuthToken: () => getWorkflowsAuthToken(),
});

export const loginIntoWorkflows = async (): Promise<void> => {
  const workflowsAuthToken = getWorkflowsAuthToken();
  if (!workflowsAuthToken) {
    const newWorkflowsAuthToken = await getApprovalScopeToken();
    if (newWorkflowsAuthToken) {
      setWorkflowsAuthToken(newWorkflowsAuthToken);
    }
  }
};

export const logoutFromWorkflows = async () => {
  clearWorkflowsAuthToken();
};

const getApprovalScopeToken = async () => {
  const user = await api.request(endpoints.authentication.user());
  const groups = await api.request(endpoints.groups.list());

  const approverGroup = groups.results.find(group => group.name === 'approver');
  const isApprover = approverGroup && user.groups.includes(approverGroup.url);

  if (isApprover) {
    const workflowResponse = await api.request(
      endpoints.authentication.token({
        scope: 'approval',
        origin: 'rossum_ui',
      })
    );
    return workflowResponse.key;
  }

  return getAuthToken();
};
