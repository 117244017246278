import { ArrowBack as ArrowBackIcon } from '@rossum/ui/icons';
import { Divider, Stack, Typography } from '@rossum/ui/material';
import { LocationDescriptor } from 'history';
import { MouseEvent, ReactNode } from 'react';
import BackButton from '../back-button/BackButton';
import { PAPPER_BOX_PADDING } from './PaperBox';

type PaperBoxTitleProps = {
  children: ReactNode;
  to?: LocationDescriptor;
  handleOnBackButtonClick?: (e: MouseEvent) => void;
};

const PaperBoxTitle = ({
  children,
  to,
  handleOnBackButtonClick,
}: PaperBoxTitleProps) => (
  <>
    <Stack direction="row" alignItems="center" gap={1}>
      <BackButton
        handleOnClick={handleOnBackButtonClick}
        to={to}
        dataCy="dialog-back-arrow"
      >
        <ArrowBackIcon
          fontSize="small"
          color="action"
          sx={{ display: 'flex', opacity: 0.5 }}
        />
      </BackButton>
      <Typography
        component="div"
        fontWeight="600"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {children}
      </Typography>
    </Stack>
    <Divider
      sx={{
        my: PAPPER_BOX_PADDING,
        mx: -PAPPER_BOX_PADDING,
      }}
    />
  </>
);

export default PaperBoxTitle;
