import { Check, Close } from '@rossum/ui/icons';
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { boldText } from '../../../../lib/formaterValues';
import { Documents } from '../../../../types/documents';
import Loading from './Loading';

type FooterProps = {
  isUploading: boolean;
  cancelUpload: MouseEventHandler;
  onClose: MouseEventHandler;
  closing: boolean;
  queueName: string | null;
  transformedFiles: Documents['files'];
  children: ReactNode;
};

export const Footer = ({
  isUploading,
  cancelUpload,
  onClose,
  closing,
  queueName,
  transformedFiles,
  children,
}: FooterProps) => {
  const uploaded = transformedFiles.filter(
    ({ status }) => status === 'uploaded'
  );

  const failed = transformedFiles.filter(({ status }) => status === 'failed');

  return (
    <Stack flex={1}>
      {isUploading ? (
        <Stack alignItems="center" sx={{ mt: 3 }} spacing={2.75}>
          <Loading
            totalUploads={transformedFiles.length}
            finishedUploads={uploaded.length}
          />
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Close />}
            onClick={event => cancelUpload(event)}
            data-cy="upload-back-btn"
            size="medium"
          >
            <FormattedMessage id="containers.annotationList.upload.cancel" />
          </Button>
        </Stack>
      ) : (
        <Stack
          alignItems="center"
          spacing={2}
          sx={{ pt: 2, px: 4, pb: 3 }}
          flex={1}
        >
          {!!uploaded.length && (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                display: 'flex',
                alignSelf: 'stretch',
                alignContent: 'flex-start',
                fontWeight: 400,
              }}
            >
              {queueName ? (
                <FormattedMessage
                  id="containers.annotationList.upload.finishedUploadsToQueue"
                  values={{
                    amount: uploaded.length,
                    queueName,
                    boldText,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="containers.annotationList.upload.finishedUploads"
                  values={{
                    amount: uploaded.length,
                  }}
                />
              )}
            </Alert>
          )}

          {!!failed.length && (
            <Typography
              variant="body2"
              color={uploaded.length ? 'warning.main' : 'error.main'}
              alignSelf="flex-start"
            >
              <FormattedMessage
                id="containers.annotationList.upload.failedUploads"
                values={{ amount: failed.length }}
              />
            </Typography>
          )}

          {children ?? (
            <Button
              variant="contained"
              color="primary"
              startIcon={
                closing ? (
                  <CircularProgress size={18} color="inherit" />
                ) : (
                  <Check fontSize="small" />
                )
              }
              onClick={onClose}
              data-cy="upload-back-btn"
              size="medium"
            >
              <Typography variant="button" fontSize="small">
                <FormattedMessage id="containers.annotationList.upload.done" />
              </Typography>
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};
