import { FilePresentRounded } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type AttachmentsIndicatorProps = {
  onClick: () => void;
  dataCy: string;
};

const AttachmentsIndicator = ({
  onClick,
  dataCy,
}: AttachmentsIndicatorProps) => {
  const intl = useIntl();

  return (
    <Stack
      sx={{ zIndex: 1, cursor: 'pointer' }}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      data-cy={dataCy}
    >
      <Tooltip
        title={intl.formatMessage({
          id: 'components.annotationAttachment.indicator.tooltip',
        })}
        placement="top"
      >
        <FilePresentRounded fontSize="small" color="inherit" />
      </Tooltip>
    </Stack>
  );
};

export default AttachmentsIndicator;
