import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Relation } from '../models/relations';
import { relationSchema } from '../models/relations.schema';

export const get = (relationId: ID) => {
  return {
    endpoint: `/relations/${relationId}`,
    method: 'GET',
    responseSchema: relationSchema,
  } as const;
};
