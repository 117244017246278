// Generated by ts-to-zod
import { z } from 'zod';

export const autopilotConfigSchema = z
  .object({
    enabled: z.boolean(),
    searchHistory: z.object({
      rirFieldNames: z.array(z.string()),
      matchingFieldsThreshold: z.number().optional(),
    }),
    automateFields: z.object({
      rirFieldNames: z.array(z.string()),
      fieldRepeatedMin: z.number(),
    }),
  })
  .partial();
