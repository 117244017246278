import { AutomationBlocker } from '@rossum/api-client/internal';
import { Message } from '@rossum/api-client/shared';
import {
  ErrorOutlineOutlined,
  FontDownloadOutlined,
  WarningAmber,
} from '@rossum/ui/icons';
import { Stack, Tooltip, Typography } from '@rossum/ui/material';
import { uniqBy } from 'lodash';
import { useIntl } from 'react-intl';
import HTMLMessage from '../../../../components/DatapointMessage/HTMLMessage';
import {
  SchemaFieldIcon,
  SchemaFieldIconType,
} from '../../../../ui/schema-field-icon/SchemaFieldIcon';

type AggregateTooltipProps<I> = {
  items?: Array<I>;
  color: 'error' | 'warning' | 'info';
  Icon: (typeof messagesIcons)[keyof typeof messagesIcons] | null;
};

const NUMBER_OF_VISIBLE_MESSAGES = 10;

const AggregateTooltip = <
  I extends { content: string; id?: string | number | null },
>({
  items: incomingItems,
  color,
  Icon,
}: AggregateTooltipProps<I>) => {
  const intl = useIntl();
  const items = uniqBy(incomingItems, 'content');
  return items && items.length && Icon ? (
    <Tooltip
      title={
        <>
          {items.slice(0, NUMBER_OF_VISIBLE_MESSAGES).map(({ id, content }) => {
            return (
              <Typography variant="body2" key={id + content} component="div">
                <HTMLMessage content={content} />
              </Typography>
            );
          })}
          {items.slice(NUMBER_OF_VISIBLE_MESSAGES).length ? (
            <Typography variant="body2" color="text.secondary">
              {intl.formatMessage(
                {
                  id: 'features.queueSettings.fields.formulaPreview.table.moreLines',
                },
                { count: items.slice(NUMBER_OF_VISIBLE_MESSAGES).length }
              )}
            </Typography>
          ) : null}
        </>
      }
    >
      <Icon sx={{ color: `${color}.main` }} fontSize="small" />
    </Tooltip>
  ) : null;
};

const messagesIcons = {
  error: ErrorOutlineOutlined,
  warning: WarningAmber,
  info: ErrorOutlineOutlined,
};

type CellIconsProps = {
  type: SchemaFieldIconType | null;
  messages?: Array<Message>;
  automationBlockers?: Array<AutomationBlocker>;
};

export const CellIcons = ({
  type,
  messages,
  automationBlockers,
}: CellIconsProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <AggregateTooltip
        items={messages}
        color={messages?.[0]?.type ?? 'error'}
        Icon={messagesIcons[messages?.[0]?.type ?? 'error'] ?? null}
      />

      <AggregateTooltip
        items={automationBlockers}
        color="error"
        Icon={FontDownloadOutlined}
      />
      {type ? (
        <SchemaFieldIcon
          type={type}
          sx={{ color: 'text.secondary' }}
          fontSize="small"
        />
      ) : null}
    </Stack>
  );
};
