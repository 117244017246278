// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { triggerEventTypeSchema } from '../models/trigger.schema';

export const patchTriggerPayloadSchema = z
  .object({
    queue: urlSchema,
    event: triggerEventTypeSchema,
    condition: z.unknown(),
    emailTemplates: z.array(urlSchema),
    deleteRecommendations: z.array(urlSchema),
  })
  .partial();
