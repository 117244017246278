import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../../types/basic';
import { MultivalueDatapointData } from '../../../../types/datapoints';
import { AcceptSuggestedOperationsMeta, UpdatePositionPayload } from '../types';

type SuggestedOperationId = number;

export const acceptSuggestedOperationsAction = createAction(
  'ACCEPT_SUGGESTED_OPERATIONS',
  undefined,
  ({ datapointPath }: AcceptSuggestedOperationsMeta) => ({ datapointPath })
)<undefined, AcceptSuggestedOperationsMeta>();

export const acceptSuggestedOperationsFulfilledAction = createAction(
  'ACCEPT_SUGGESTED_OPERATIONS_FULFILLED',
  (_, updatedMultivalue) => ({ updatedMultivalue }),
  (ids: number[]) => ({ ids })
)<{ updatedMultivalue: MultivalueDatapointData }, { ids: number[] }>();

export const declineSuggestedOperationsAction = createAction(
  'DECLINE_SUGGESTED_OPERATIONS'
)<void>();

export const updateSuggestedOperationPositionAction = createAction(
  'UPDATE_SUGGESTED_OPERATION_POSITION',
  (_meta: SuggestedOperationId, payload: UpdatePositionPayload) => payload,
  meta => meta
)<UpdatePositionPayload, SuggestedOperationId>();

export const recountSuggestedOperationPositionAction = createAction(
  'RECOUNT_SUGGESTED_OPERATION_POSITION',
  undefined,
  (id: number) => id
)<undefined, number>();

export const recountSuggestedOperationPositionFulfilledAction = createAction(
  'RECOUNT_SUGGESTED_OPERATION_POSITION_FULFILLED',
  (text: string, _id: ID) => text,
  (_, id) => id
)<string, ID>();

export type SuggestedOperationsActions = ActionType<
  | typeof acceptSuggestedOperationsAction
  | typeof declineSuggestedOperationsAction
  | typeof acceptSuggestedOperationsFulfilledAction
  | typeof updateSuggestedOperationPositionAction
  | typeof recountSuggestedOperationPositionAction
  | typeof recountSuggestedOperationPositionFulfilledAction
>;
