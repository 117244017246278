import { getIDFromUrl } from '@rossum/api-client';
import { Stack } from '@rossum/ui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'redux-first-history';
import { getAnnotationBacklink } from '../../components/AnnotationInformation/components/useAnnotationBacklink';
import { isEmbedded } from '../../constants/config';
import Loader from '../../containers/Loader';
import { constructDocumentUrl } from '../../lib/url';
import { annotationSideloadsSelector } from '../../redux/modules/annotation/selectors';
import { confirmEditModeFulfilled } from '../../redux/modules/editMode/actions';
import { throwError } from '../../redux/modules/messages/actions';
import { Document } from '../../types/document';
import { CancelChangesDialog } from './CancelChangesDialog';
import { EditDocumentHeader } from './EditDocumentHeader';
import { EditDocumentSplitPane } from './EditDocumentSplitPane';
import { EditDocumentTopBar } from './EditDocumentTopBar';
import { EditedPartList } from './EditedPartList';
import {
  areSuggestedSplitsActive,
  EditDocumentConfig,
  EditState,
  useEditState,
} from './editState';
import { OriginalDocument } from './OriginalDocument';
import { useUiState } from './uiState';
import { buildConfirmPayload } from './useConfirmEditMode';
import { useEditMode } from './useEditMode';
import { useLeaveEditMode } from './useLeaveEditMode';

export const EditDocument = () => {
  const annotationSideloads = useSelector(annotationSideloadsSelector);

  const { config, confirm, confirmInProgress } = useEditMode();

  const leaveEditMode = useLeaveEditMode();

  return (
    <Stack sx={{ height: '100%' }}>
      <EditDocumentTopBar
        documentName={annotationSideloads.document?.originalFileName}
        onLeaveEditMode={leaveEditMode}
      />
      {config ? (
        <EditDocumentContent
          config={config}
          confirm={confirm}
          confirmInProgress={confirmInProgress}
          document={annotationSideloads.document}
        />
      ) : (
        <Loader />
      )}
    </Stack>
  );
};

const haveChangesToConfirm = (editState: EditState) => {
  const confirmPayload = buildConfirmPayload(editState, undefined);

  return (
    confirmPayload.delete.length > 0 ||
    confirmPayload.move.length > 0 ||
    confirmPayload.edit.length > 0
  );
};

const EditDocumentContent = ({
  config,
  confirm,
  confirmInProgress,
  document,
}: {
  config: EditDocumentConfig;
  confirm: ReturnType<typeof useEditMode>['confirm'];
  confirmInProgress: boolean;
  document: Document | undefined;
}) => {
  const dispatch = useDispatch();

  const defaultListLocation = getAnnotationBacklink();

  const [editState, dispatchEdit] = useEditState({
    parts: config.initialPartsWithSuggestedSplits ?? config.initialParts,
    config,
  });

  const [uiState, setUiState] = useUiState(confirmInProgress);

  const redirectToAnnotation = () => {
    dispatch(
      replace(
        constructDocumentUrl({
          id: getIDFromUrl(editState.config.annotationUrl),
        })
      )
    );
  };

  const suggestedSplitsActive = areSuggestedSplitsActive(
    editState.parts,
    editState.config
  );

  const canUndo =
    !uiState.cancelChangesDialogOpen &&
    !uiState.confirmInProgress &&
    !uiState.draggedPage;

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (canUndo && (e.metaKey || e.ctrlKey) && e.key === 'z') {
        dispatchEdit({ type: 'UNDO' });
        e.preventDefault();
      }

      // WORKAROUND: It seems it's somehow possible to have draggedPage not null
      // when the user is not dragging. It shouldn't happen because both onDragCancel and onDragEnd
      // are resetting the uiState accordingly,
      // Since this situation prevents the user from working,
      // at least we'll provide a way to reset the state.
      if (e.key === 'Escape') {
        setUiState(s => ({ ...s, draggedPage: null }));
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [dispatchEdit, setUiState, canUndo]);

  return (
    <Stack
      sx={{
        flex: '1 0',
        backgroundColor: theme => theme.palette.background.default,
        px: 4,
        pt: 4,
      }}
      spacing={4}
    >
      <EditDocumentHeader
        document={document}
        editState={editState}
        suggestedSplitsActive={suggestedSplitsActive}
        dispatchEdit={dispatchEdit}
        confirmInProgress={uiState.confirmInProgress}
        onConfirm={() =>
          confirm(editState, {
            onSuccess: response => {
              // This can happen if you delete all pages
              if (!isEmbedded() && response.results.length === 0) {
                dispatch(replace(defaultListLocation));
                return;
              }

              const firstAnnotation = response.results[0]?.annotation;

              // If we are doing in-place edit, we need to remove /edit part from the URL,
              // since that's not done by confirmEditModeFulfilled
              if (firstAnnotation === editState.config.annotationUrl) {
                dispatch(
                  replace(
                    constructDocumentUrl({
                      id: getIDFromUrl(editState.config.annotationUrl),
                    })
                  )
                );
              }

              // This action handles redirect to the first annotation or to return_url for embedded mode
              dispatch(confirmEditModeFulfilled(response));
            },
            onError: () => {
              dispatch(throwError('clientError'));
            },
          })
        }
        onCancel={() => {
          if (haveChangesToConfirm(editState)) {
            setUiState(s => ({ ...s, cancelChangesDialogOpen: true }));
          } else {
            redirectToAnnotation();
          }
        }}
      />
      <CancelChangesDialog
        open={uiState.cancelChangesDialogOpen}
        onClose={() =>
          setUiState(s => ({ ...s, cancelChangesDialogOpen: false }))
        }
        onCancel={redirectToAnnotation}
      />
      <EditDocumentSplitPane>
        <OriginalDocument
          editState={editState}
          uiState={[uiState, setUiState]}
          config={config}
        />
        <EditedPartList
          editState={editState}
          dispatchEdit={dispatchEdit}
          uiState={[uiState, setUiState]}
          config={config}
          suggestedSplitsActive={suggestedSplitsActive}
        />
      </EditDocumentSplitPane>
    </Stack>
  );
};
