import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngineSchema } from '../models/dedicatedEngineSchema';
import { dedicatedEngineSchemaSchema } from '../models/dedicatedEngineSchema.schema';
import { dedicatedEngineSchemaCreatePayloadSchema } from './create.schema';

export type DedicatedEngineSchemaCreatePayload = {
  content: {
    trainingQueues: Url[];
    fields: unknown[];
  };
};

export const create = (payload: DedicatedEngineSchemaCreatePayload) => {
  return {
    endpoint: `/dedicated_engine_schemas`,
    method: 'POST',
    responseSchema: dedicatedEngineSchemaSchema,
    payload,
    payloadSchema: dedicatedEngineSchemaCreatePayloadSchema,
  } as const;
};
