import { useCallback, useRef } from 'react';
import { safeInverse } from '../../document-store/helpers';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
} from '../../document-store/helpers/geometry';
import { getRectangleFromPoints } from '../../document-store/helpers/getRectangleFromPoints';
import { useRectangleCreator } from '../common/useRectangleCreator';
import { useDocumentPageContext } from '../page-svg/useDocumentPageContext';

const getRectangleInfoFromPoints = (
  p1: Point2D,
  p2: Point2D,
  startMatrix: DOMMatrix,
  endMatrix: DOMMatrix
) => {
  const startPoint = DOMPoint.fromPoint(p1).matrixTransform(startMatrix);
  const endPoint = DOMPoint.fromPoint(p2).matrixTransform(endMatrix);
  const rectangle = getRectangleFromPoints(startPoint, endPoint);

  return {
    startPoint,
    endPoint,
    rectangle,
  };
};

export const useDrawTextSelection = ({
  onFinish,
}: {
  onFinish?: (position: Rectangle2D) => void;
}) => {
  const pageContext = useDocumentPageContext();
  const initialInverseMatrix = useRef<DOMMatrix>(IDENTITY_MATRIX_2D);

  const getPageCTM = useCallback(
    () => pageContext?.pageRef?.current?.getScreenCTM() ?? IDENTITY_MATRIX_2D,
    [pageContext?.pageRef]
  );

  const { startPoint, dragPoint, handleMouseDown } = useRectangleCreator({
    onRectangleCreated: (startPoint, endPoint) => {
      const inverseMatrix = safeInverse(getPageCTM());
      const { rectangle } = getRectangleInfoFromPoints(
        startPoint,
        endPoint,
        initialInverseMatrix.current,
        inverseMatrix
      );

      if (rectangle) {
        onFinish?.(rectangle);
      }
    },
  });

  const overrideMouseDown = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (!event.shiftKey) {
        initialInverseMatrix.current = getPageCTM().inverse();
        if (
          event.target instanceof SVGElement &&
          event.target.classList.contains('bounding-box')
        ) {
          // Do not allow creating bounding boxes over current bounding boxes.
          // Does this really make sense?
        } else {
          handleMouseDown(event);
        }
      }
    },
    [getPageCTM, handleMouseDown]
  );

  const rectangleInfo =
    startPoint && dragPoint
      ? getRectangleInfoFromPoints(
          startPoint,
          dragPoint,
          initialInverseMatrix.current,
          safeInverse(getPageCTM())
        )
      : undefined;

  return {
    boundingBox: rectangleInfo?.rectangle,
    handleMouseDown: overrideMouseDown,
  };
};
