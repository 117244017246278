import { z } from 'zod';
import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { purgePayloadSchema } from './purge.schema';

export type PurgePayload = Partial<{ annotations: Array<Url>; queue: string }>;

export const purge = (payload: PurgePayload) => {
  return {
    endpoint: `/annotations/purge_deleted`,
    method: 'POST',
    payloadSchema: purgePayloadSchema,
    payload,
    responseSchema: z.unknown(),
  } as const;
};
