import { getType } from 'typesafe-actions';
import { Next } from '../../types/redux';
import {
  ANNOTATIONS_QUERY,
  ANNOTATIONS_QUERY_FOR_BROWSER_TAB,
  removeItem,
  removeItems,
  setItem,
} from '../modules/localStorage/actions';
import { RootActionType } from '../rootActions';

export default () => (next: Next) => (action: RootActionType) => {
  if (action.type === getType(setItem)) {
    window.localStorage.setItem(action.meta.key, action.payload);

    // Not sure if we can make this universal, so for now, it's only for annotations query,
    // where we need to ensure that we are not reading values across tabs
    if (action.meta.key === ANNOTATIONS_QUERY) {
      window.sessionStorage.setItem(
        ANNOTATIONS_QUERY_FOR_BROWSER_TAB,
        action.payload
      );
    }
  }

  if (action.type === getType(removeItem)) {
    window.localStorage.removeItem(action.meta.key);
  }

  if (action.type === getType(removeItems)) {
    action.meta.keys.map(key => window.localStorage.removeItem(key));
  }

  next(action);
};
