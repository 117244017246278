// Generated by ts-to-zod
import { z } from 'zod';

export const rejectionSettingsSchema = z
  .object({
    enabled: z.boolean(),
    email: z.object({
      templateValues: z
        .object({
          subject: z.string(),
          message: z.string(),
        })
        .partial(),
    }),
  })
  .partial();
