import { LocalizationKeys } from '../../../../../i18n';

type TemplateValueOption = {
  value: string;
  descriptionId: LocalizationKeys;
};

export type VariablesConfig = Array<TemplateValueOption>;

const baseEmailTemplateId =
  'containers.settings.queues.email.editTemplate.configValues.description';

export const VARIABLE_SENDER = '{{sender_email}}';
export const VARIABLE_CURRENT_USER_EMAIL = '{{current_user_email}}';

export const cheatSheetConfigBase: VariablesConfig = [
  {
    value: VARIABLE_SENDER,
    descriptionId: `${baseEmailTemplateId}.sender`,
  },
  {
    value: '{{parent_email_subject}}',
    descriptionId: `${baseEmailTemplateId}.parentEmailSubject`,
  },
  {
    value: VARIABLE_CURRENT_USER_EMAIL,
    descriptionId: `${baseEmailTemplateId}.currentUserEmail`,
  },
  {
    value: '{{current_user_fullname}}',
    descriptionId: `${baseEmailTemplateId}.currentUserFullname`,
  },
  {
    value: '{{organization_name}}',
    descriptionId: `${baseEmailTemplateId}.orgName`,
  },
  {
    value: '{{ app_url }}',
    descriptionId: `${baseEmailTemplateId}.appUrl`,
  },
];

export const cheatSheetAnnotationVariables: VariablesConfig = [
  {
    value: '{{annotation.id}}',
    descriptionId: `${baseEmailTemplateId}.annotation.id`,
  },
  {
    value: '{{annotation.url}}',
    descriptionId: `${baseEmailTemplateId}.annotation.url`,
  },
  {
    value: '{{annotation.assignee_email}}',
    descriptionId: `${baseEmailTemplateId}.annotation.assigneeEmail`,
  },
  {
    value: '{{annotation.document.original_file_name}}',
    descriptionId: `${baseEmailTemplateId}.annotation.originalFileName`,
  },
];

export const cheatSheetConfigEmailNotification: VariablesConfig = [
  ...cheatSheetConfigBase,
  {
    value: '{{document_list}}',
    descriptionId: `${baseEmailTemplateId}.documentList`,
  },
];
