import { endpoints } from '@rossum/api-client';
import { QueryStatus, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { extensionsEnabledSelector, logsEnabledSelector } from '../selectors';

// extensions and logs needs different handling than just checking if the feature has been purchased in `usage` object
// users are able to access extensions and logs if they have at least one hook created
// creating of new hooks tho - be it from Rossum store or custom functions/webhooks - should be disabled if not purchased

const HOOKS_ENABlED_QUERY_KEY = ['hooksEnabled'] as const;

type ExtensionsSubscription =
  | {
      purchased: true;
      canAccess: true;
      status: 'success';
    }
  | {
      purchased: false;
      canAccess: boolean;
      status: QueryStatus;
    };

export const useExtensionsFeatureSubscription = (): ExtensionsSubscription => {
  const extensionsPurchased = useSelector(extensionsEnabledSelector);

  const { data: hooksEnabled, status } = useQuery({
    queryKey: HOOKS_ENABlED_QUERY_KEY,
    queryFn: () => api.request(endpoints.hooks.list({ pageSize: 1 })),
    enabled: !extensionsPurchased,
    select: ({ results }) => results.length > 0,
  });

  return extensionsPurchased
    ? {
        purchased: true,
        canAccess: true,
        status: 'success',
      }
    : {
        purchased: false,
        canAccess: !!hooksEnabled,
        status,
      };
};

export const useExtensionLogsFeatureSubscription =
  (): ExtensionsSubscription => {
    const extensionLogsPurchased = useSelector(logsEnabledSelector);

    const { data: hookLogsEnabled, status } = useQuery({
      queryKey: HOOKS_ENABlED_QUERY_KEY,
      queryFn: () => api.request(endpoints.hooks.list({ pageSize: 1 })),
      enabled: !extensionLogsPurchased,
      select: ({ results }) => results.length > 0,
    });

    return extensionLogsPurchased
      ? {
          purchased: true,
          canAccess: true,
          status: 'success',
        }
      : {
          purchased: false,
          canAccess: !!hookLogsEnabled,
          status,
        };
  };
