import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { listResponse } from '../../utils/listResponse';
import { engineFieldSchema } from '../models/engineField.schema';
import { engineFieldListQuerySchema } from './list.schema';

export type EngineFieldListQuery = {
  id?: ID | ID[];
  engine?: ID | ID[];
  name?: string;
  used?: boolean;
} & PaginationQuery;

export const list = (query: EngineFieldListQuery = {}) => {
  return {
    endpoint: `/engine_fields`,
    method: 'GET',
    responseSchema: listResponse(engineFieldSchema),
    query,
    querySchema: engineFieldListQuerySchema,
  } as const;
};
