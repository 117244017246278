import {
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { FocusEvent } from 'react';
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseFormSetFocus,
  useFormState,
} from 'react-hook-form';
import * as R from 'remeda';
import { CustomTextField } from './CustomTextField';

const MultiInputField = <T extends FieldValues>({
  control,
  placeholder,
  name,
  nextFieldName,
  setFocus,
  maxLength,
  sanitizeValueOnBlur,
}: {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  nextFieldName?: Path<T>;
  maxLength: number;
  setFocus: UseFormSetFocus<T>;
  sanitizeValueOnBlur: (e: FocusEvent<HTMLInputElement>) => string;
}) => {
  const fieldProps = useController({
    name,
    control,
  });

  return (
    <CustomTextField
      FieldProps={{
        ...fieldProps,
        fieldState: {
          ...fieldProps.fieldState,
          // do not pass error to the input - we display it in the parent component
          error: undefined,
        },
        field: {
          ...fieldProps.field,
          onChange: e => {
            fieldProps.field.onChange(e);

            if (e.target.value.length === maxLength && !!nextFieldName) {
              setFocus(nextFieldName);
            }
          },
        },
      }}
      FieldLabelProps={{
        layout: 'none',
      }}
      inputProps={{
        sx: {
          textAlign: 'center',
        },
        'data-cy': `multiinput-${name}`,
        placeholder,
        maxLength,
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
          const sanitizedValue = sanitizeValueOnBlur(e);

          if (fieldProps.field.value !== sanitizedValue) {
            return fieldProps.field.onChange(sanitizedValue);
          }
          return undefined;
        },
      }}
    />
  );
};

type MultiInputInputs<T> = ReadonlyArray<{
  name: Path<T>;
  letter?: string;
  placeholder?: string;
}>;

const MultiInput = <T extends FieldValues>({
  control,
  inputs,
  setFocus,
  maxLength,
  sanitizeValueOnBlur,
}: {
  control: Control<T>;
  inputs: MultiInputInputs<T>;
  setFocus: UseFormSetFocus<T>;
  maxLength: number;
  sanitizeValueOnBlur: (e: FocusEvent<HTMLInputElement>) => string;
}) => {
  const { errors } = useFormState({ control });
  const fieldErrors: Array<string> = R.pipe(
    errors,
    R.entries(),
    R.flatMap(([key, value]) => {
      if (inputs.some(input => input.name === key)) {
        return [value?.message];
      }
      return [];
    }),
    R.filter(R.isString),
    R.unique()
  );

  return (
    <FormControl>
      <Stack direction="row" spacing={2.5}>
        {inputs.map(({ name, letter, placeholder }, index) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.75}
            width={85}
            key={`multiinput-${name}-${letter}`}
          >
            <MultiInputField<T>
              control={control}
              placeholder={placeholder}
              maxLength={maxLength}
              name={name}
              setFocus={setFocus}
              nextFieldName={inputs[index + 1]?.name}
              sanitizeValueOnBlur={sanitizeValueOnBlur}
            />
            {letter && (
              <Typography variant="body2" color="text.secondary">
                {letter}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
      {!!fieldErrors.length && (
        <FormHelperText error sx={{ whiteSpace: 'pre' }}>
          {fieldErrors.join('\n')}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiInput;
