import { endpoints } from '@rossum/api-client';
import { CreateTriggerPayload } from '@rossum/api-client/triggers';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const useCreateTrigger = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: (trigger: CreateTriggerPayload) =>
      api.request(endpoints.triggers.create(trigger)),
  });
};
