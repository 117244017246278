import { groupBy } from 'lodash';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { BboxT } from '../../../types/bbox';
import { RootActionType } from '../../rootActions';
import { clearAnnotationData } from '../annotation/actions';
import { leaveValidation } from '../ui/actions';
import { fetchBboxes, fetchBboxesFulfilled } from './actions';

const initialState: { [page: number]: BboxT } = {};

const bboxesReducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchBboxes):
    case getType(leaveValidation):
    case getType(clearAnnotationData):
      return initialState;

    case getType(fetchBboxesFulfilled): {
      return groupBy(action.payload, b => b.page);
    }

    default:
      return state;
  }
};

export default bboxesReducer;
