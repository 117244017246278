import { endpoints } from '@rossum/api-client';
import { EmailTemplateStatsListQuery } from '@rossum/api-client/emailTemplatesStats';
import { EmailTemplateStats } from '@rossum/api-client/emailTemplatesStats';
import { ElisClientError } from '@rossum/api-client/errors';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

const QUERY_KEY_EMAIL_TEMPLATES_STATS_UNPAGINATED =
  'email-templates-stats-unpaginated';

export const useEmailTemplatesStatsPerQueueUnpaginated = <TReturnedData>(
  query: EmailTemplateStatsListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      EmailTemplateStats[],
      ElisClientError,
      TReturnedData,
      readonly [
        typeof QUERY_KEY_EMAIL_TEMPLATES_STATS_UNPAGINATED,
        EmailTemplateStatsListQuery,
      ]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_EMAIL_TEMPLATES_STATS_UNPAGINATED, query] as const,

    queryFn: ({ queryKey }) =>
      api.unpaginatedRequest(endpoints.emailTemplatesStats.list)(queryKey[1]),
    ...queryOptions,
  });
};
