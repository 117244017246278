import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Survey } from '../models';
import { surveySchema } from '../models/survey.schema';
import { surveysListQuerySchema } from './list.schema';

export type SurveysListQuery = {
  pageSize?: number;
  templateUuid?: string;
  ordering?: string[];
};

export const list = (query: SurveysListQuery) => {
  return {
    endpoint: `/surveys`,
    method: 'GET',
    responseSchema: listResponse(surveySchema),
    query,
    querySchema: surveysListQuerySchema,
  } as const;
};
