// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const emailAddressObjectSchema = z.object({
  email: z.string(),
  name: z.string().optional().nullable(),
});

export const emailSchema = z.object({
  id: idSchema,
  url: urlSchema,
  queue: urlSchema,
  inbox: urlSchema.nullable(),
  documents: z.array(urlSchema),
  parent: urlSchema.nullable(),
  children: z.array(urlSchema),
  createdAt: z.string(),
  subject: z.string(),
  to: z.array(emailAddressObjectSchema.partial()),
  cc: z.array(emailAddressObjectSchema.partial()),
  bcc: z.array(emailAddressObjectSchema.partial()),
  bodyTextPlain: z.string().nullable(),
  bodyTextHtml: z.string().nullable(),
  metadata: z.unknown().optional(),
  type: z.union([z.literal('incoming'), z.literal('outgoing')]),
  annotationCounts: z.object({
    annotations: z.number(),
    annotationsProcessed: z.number(),
    annotationsRejected: z.number(),
    annotationsUnprocessed: z.number(),
    annotationsPurged: z.number().optional(),
  }),
  annotations: z.array(urlSchema),
  creator: urlSchema.nullable(),
  lastThreadEmailCreatedAt: z.string().optional(),
  filteredOutDocumentCount: z.number(),
  labels: z.array(z.string()),
  emailThread: urlSchema.nullable(),
  relatedAnnotations: z.array(urlSchema),
  relatedDocuments: z.array(urlSchema),
  from: emailAddressObjectSchema,
});
