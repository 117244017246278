import { Button, CircularProgress, Slide } from '@rossum/ui/material';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationKeys } from '../../../i18n';

type Props = {
  hideCondition: boolean;
  messageId: LocalizationKeys;
  onClick?: MouseEventHandler;
  dataCy?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  isLoading?: boolean;
};

const HiddingButton = ({
  disabled,
  hideCondition,
  messageId,
  onClick,
  dataCy,
  type,
  isLoading,
}: Props) => (
  <Slide in={!hideCondition} direction="up" appear unmountOnExit>
    <Button
      variant="contained"
      onClick={onClick}
      data-cy={dataCy}
      disabled={disabled || isLoading}
      type={type}
      sx={{ position: 'fixed', bottom: 21 }}
      startIcon={isLoading ? <CircularProgress size={18} /> : null}
    >
      <FormattedMessage id={messageId} />
    </Button>
  </Slide>
);

export default HiddingButton;
