import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import {
  ExtensionOutlined,
  FontDownloadOutlined,
  ShortTextOutlined,
  Warning,
} from '@rossum/ui/icons';
import { alpha, Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { DatapointAutomationBlocker } from '../../redux/modules/annotation/types';
import { getUITypeFromSchema } from '../../redux/modules/schema/helpers';
import { schemaMapSelector } from '../../redux/modules/schema/schemaMapSelector';
import {
  AnyDatapointDataST,
  MatchedTriggerRuleDatapoint,
} from '../../types/datapoints';
import { UiFieldType } from '../../types/schema';
import { AutomationBlockersTooltip } from '../Datapoint/components/AutomationBlockersTooltip';
import {
  ValidationSourcesContent,
  ValidationSourcesIcon,
} from '../Datapoint/components/ValidationSourcesTooltip';
import {
  irrelevantTickInfo,
  isEditableFormulaField,
} from '../Datapoint/helpers';
import { FormulaTooltip } from './components/FormulaTooltip';
import styles from './style.module.sass';

type TooltipType = 'validationSources' | 'automationBlockers';

const validationSources: TooltipType = 'validationSources';
const blockers: TooltipType = 'automationBlockers';

type Props = {
  active?: boolean;
  data: AnyDatapointDataST;
  inFooter?: boolean;
  documentAutomated?: boolean;
  tickIconColor?: 'green' | 'white' | null;
  tooltipType: TooltipType | null;
  automationBlockers?: DatapointAutomationBlocker[];
  matchedRules?: MatchedTriggerRuleDatapoint;
  uiFieldType?: UiFieldType;
};

const getContentByType = ({
  data,
  documentAutomated,
  inFooter,
  tickIconColor,
  automationBlockers,
  active,
}: {
  data: AnyDatapointDataST;
  inFooter: boolean;
  documentAutomated?: boolean;
  tickIconColor?: 'green' | 'white' | undefined;
  automationBlockers?: DatapointAutomationBlocker[];
  active?: boolean;
}) => ({
  [validationSources]: {
    content: (
      <ValidationSourcesContent
        data={data}
        documentAutomated={documentAutomated}
      />
    ),
    icon: (
      <ValidationSourcesIcon
        inFooter={inFooter}
        tickIconColor={tickIconColor}
        data={data}
      />
    ),
    disabled:
      get(data, 'category') === 'multivalue' ||
      !get(data, 'validationSources', []).length,
  },
  [blockers]: {
    icon: (
      <FontDownloadOutlined
        sx={{
          fill: theme =>
            active ? theme.palette.action.active : theme.palette.error.main,
          mr: inFooter ? 0 : 0.7,
          display: 'block',
          width: inFooter ? 12 : 20,
          height: inFooter ? 12 : 20,
        }}
      />
    ),
    content: (
      <AutomationBlockersTooltip
        automationBlockers={automationBlockers || []}
        data={data}
      />
    ),
    disabled: false,
  },
});

type PrioritiesArgs = {
  displayAutomationBlockers: boolean;
  tickIconColor?: null | string;
  displaySources?: boolean;
  uiFieldType?: UiFieldType;
};

export const getTooltipTypeWithHighestPriority = ({
  displayAutomationBlockers,
  tickIconColor,
  displaySources,
  uiFieldType,
}: PrioritiesArgs): TooltipType | null => {
  if (displayAutomationBlockers) {
    return blockers;
  }

  if (
    uiFieldType === 'data' ||
    uiFieldType === 'formula' ||
    uiFieldType === 'manual'
  ) {
    return null;
  }

  if ((displaySources === undefined || displaySources) && tickIconColor) {
    return validationSources;
  }

  return null;
};

const DatapointTooltip = ({
  active,
  data,
  documentAutomated,
  inFooter,
  tickIconColor,
  tooltipType,
  automationBlockers,
  matchedRules,
  uiFieldType: datapointUiFieldType,
}: Props) => {
  const schemas = useSelector(schemaMapSelector);
  const simpleMultivalueChildrenSchemaId =
    data.category === 'multivalue' && data.meta.isSimpleMultivalue
      ? data.schema?.children?.[0]
      : undefined;
  // for simpleMultivalues, we need to get the UI type from the children schema
  const uiFieldType = simpleMultivalueChildrenSchemaId
    ? getUITypeFromSchema(schemas.get(simpleMultivalueChildrenSchemaId))
    : datapointUiFieldType;

  // PERF: O(n^2)!
  const hasBlockers =
    data.category === 'multivalue'
      ? data.meta.isSimpleMultivalue
        ? automationBlockers?.[0]?.samples?.length
        : automationBlockers?.length
      : automationBlockers?.some(
          blocker =>
            blocker?.samples?.some(sample => sample.datapointId === data.id) ??
            false
        );

  if (tooltipType === 'automationBlockers' && !hasBlockers) {
    return null;
  }

  if (
    !inFooter &&
    uiFieldType === 'data' &&
    tooltipType !== 'automationBlockers'
  ) {
    return <ExtensionOutlined fontSize="small" sx={{ mr: 0.75 }} />;
  }

  if (
    !inFooter &&
    uiFieldType === 'manual' &&
    tooltipType !== 'automationBlockers'
  ) {
    return <ShortTextOutlined fontSize="small" sx={{ mr: 0.8 }} />;
  }

  if (uiFieldType === 'formula' && tooltipType !== 'automationBlockers') {
    return (
      <FormulaTooltip
        data={data}
        inFooter={inFooter}
        isEditable={!!isEditableFormulaField(schemas.get(data.schemaId))}
      />
    );
  }

  if (matchedRules && tooltipType !== 'automationBlockers')
    return (
      <Warning
        sx={{
          mr: inFooter ? 0 : 0.7,
          display: 'block',
          width: inFooter ? 12 : 20,
          height: inFooter ? 12 : 20,
        }}
        color="warning"
      />
    );

  if (
    !data ||
    !tooltipType ||
    (tooltipType === 'validationSources' &&
      irrelevantTickInfo(
        get(data, 'validationSources', []),
        documentAutomated ?? false
      ))
  ) {
    return null;
  }

  const fieldLabel = get(data, ['schema', 'label'], '');
  const contentType = getContentByType({
    data,
    documentAutomated: documentAutomated ?? false,
    inFooter: inFooter ?? false,
    tickIconColor: tickIconColor ?? undefined,
    automationBlockers,
    active,
  })[tooltipType];

  return (
    <Tooltip
      content={
        <Stack spacing={2} padding={2}>
          <Typography
            variant="body2"
            sx={{
              color: theme => alpha(theme.palette.common.white, 0.6),
            }}
          >
            {fieldLabel}
          </Typography>
          {contentType.content}
        </Stack>
      }
      placement={inFooter ? 'top' : 'right'}
      contentClassName={styles.DatapointTooltip}
      disabled={contentType.disabled}
      popperClassName={clsx(
        styles.TooltipWithLink,
        inFooter && styles.ValidationTooltip
      )}
      delay={tooltipType === 'automationBlockers' ? 0 : inFooter ? 300 : 1000}
      dark={inFooter}
    >
      {contentType.icon}
    </Tooltip>
  );
};

export default DatapointTooltip;
