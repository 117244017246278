import { Message } from '@rossum/api-client/shared';
import { Box, Stack } from '@rossum/ui/material';
import { compact } from 'lodash';
import { LegacyRef } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { getDatapointPathFromSearch } from '../../../lib/url';
import { datapointBlockerSelector } from '../../../redux/modules/annotation/selectors';
import { DatapointAutomationBlocker } from '../../../redux/modules/annotation/types';
import { shouldFocusAddValueSelector } from '../../../redux/modules/datapoints/selector';
import { isVirtualDatapoint } from '../../../redux/modules/datapoints/typedHelpers';
import { complexTablesEnabledOnOrganization } from '../../../redux/modules/ui/selectors';
import {
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
} from '../../../types/datapoints';
import { State } from '../../../types/state';
import LineItemsControls from '../../LineItemsControls';
import SimpleMultiValue from '../../SimpleMultiValue';
import MultivalueBadge from '../../SimpleMultiValue/components/MultivalueBadge';
import OccurenceTooltip from './OccurenceTooltip';

type OwnProps = {
  active: boolean;
  childrenSchemaId?: string;
  documentAutomated: boolean;
  editingDatapointValue: boolean;
  isSimpleMultivalue: boolean;
  multivalueData: MultivalueDatapointDataST;
  myPath: Array<number>;
  readOnly: boolean;
  setRef: LegacyRef<HTMLDivElement>;
  maxOccurrences?: number;
  messages?: { [key: number]: Message };
  minOccurrences?: number;
};

type StateProps = {
  datapointPath: Array<number>;
  values: SimpleDatapointDataST[];
  allBlockers: Record<string, DatapointAutomationBlocker[]>;
  leavingButtonFocused: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps & StateProps;

const MultiValue = (props: Props) => {
  const {
    active,
    allBlockers,
    childrenSchemaId,
    datapointPath,
    documentAutomated,
    editingDatapointValue,
    leavingButtonFocused,
    maxOccurrences,
    messages,
    minOccurrences,
    myPath,
    readOnly,
    setRef,
    isSimpleMultivalue,
    multivalueData,
    values,
  } = props;
  const tooFew = values.length === minOccurrences;
  const tooMany = values.length === maxOccurrences;
  const amount = multivalueData.children.filter(
    child => !isVirtualDatapoint(child.id)
  ).length;
  const tupleIds = multivalueData.children.map(child => child.id);

  const cliFeatureFlag = useSelector(complexTablesEnabledOnOrganization);

  return (
    <Box style={{ flex: active ? '1 1 100%' : '1 1 auto' }}>
      {active ? (
        <OccurenceTooltip
          tooMany={tooMany}
          tooFew={tooFew}
          isSimpleMultivalue={isSimpleMultivalue}
        >
          <Stack
            position="relative"
            paddingBottom={
              (cliFeatureFlag && !readOnly) || isSimpleMultivalue ? '22px' : 0
            }
          >
            <Stack position="absolute" right={0} top="-23px">
              <MultivalueBadge
                amount={amount}
                active
                isSimpleMultivalue={isSimpleMultivalue}
              />
            </Stack>
          </Stack>
          {isSimpleMultivalue && childrenSchemaId ? (
            <SimpleMultiValue
              allBlockers={allBlockers}
              childrenSchemaId={childrenSchemaId}
              datapointPath={datapointPath}
              datapoints={values}
              documentAutomated={documentAutomated}
              editingDatapointValue={editingDatapointValue}
              leavingButtonFocused={leavingButtonFocused}
              messages={messages}
              maxOccurrences={maxOccurrences}
              minOccurrences={minOccurrences}
              myPath={myPath}
              multivalueIndex={multivalueData.meta.index}
              readOnly={readOnly}
              setRef={setRef}
            />
          ) : cliFeatureFlag && !readOnly && multivalueData ? (
            <LineItemsControls
              tupleIds={tupleIds}
              currentDatapoint={multivalueData}
            />
          ) : null}
        </OccurenceTooltip>
      ) : (
        <MultivalueBadge
          amount={amount}
          isSimpleMultivalue={isSimpleMultivalue}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (
  state: State,
  { multivalueData, isSimpleMultivalue, active }: OwnProps
): StateProps => {
  if (isSimpleMultivalue && active) {
    const values = compact(
      multivalueData.children.map(
        ({ index }) => state.datapoints.content[index]
      )
    );
    const datapointPath = getDatapointPathFromSearch(
      state.router.location.search
    );
    const allBlockers = datapointBlockerSelector(state);
    const leavingButtonFocused = shouldFocusAddValueSelector(state);

    return {
      allBlockers,
      datapointPath,
      // @ts-expect-error This seems to be incorrect.
      values,
      leavingButtonFocused: !!leavingButtonFocused,
    };
  }

  // @ts-expect-error This seems to be incorrect.
  return {
    values: [],
  };
};

const connector = connect<StateProps, null, OwnProps, State>(
  mapStateToProps,
  null,
  null,
  {
    areStatesEqual: (next, prev) =>
      !(
        next.datapoints.updatedTimestamp !== prev.datapoints.updatedTimestamp ||
        next.datapoints.content.length !== prev.datapoints.content.length
      ),
    areOwnPropsEqual: (next, prev) => !(next.active || prev.active),
  }
);

export default connector(MultiValue);
