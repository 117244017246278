import { useEffect, useState } from 'react';

export const useKeyPressed = (keys: string[]) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (keys.some(key => e.key === key)) {
        setKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (keys.some(key => e.key === key)) {
        setKeyPressed(false);
      }
    };

    const resetKeyPress = () => {
      setKeyPressed(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    window.addEventListener('focus', resetKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('focus', resetKeyPress);
    };
  }, [keys]);

  return keyPressed;
};
