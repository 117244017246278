import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  WorkflowActivity,
  WorkflowActivityUrl,
} from '../models/workflowActivity';
import { workflowActivitySchema } from '../models/workflowActivity.schema';

export const get = (workflowActivityUrl: WorkflowActivityUrl) => {
  return {
    endpoint: workflowActivityUrl,
    method: 'GET',
    responseSchema: workflowActivitySchema,
  } as const;
};
