import { useCallback, useEffect, useRef, useState } from 'react';
import { StorageKey } from '../redux/modules/localStorage/actions';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useLocalStorageBooleanFlag = (
  key: StorageKey,
  defaultValue: boolean
) => {
  const [value, setValue] = useState(() => {
    const item = localStorage.getItem(key);

    return item && ['true', 'false'].includes(item)
      ? !!JSON.parse(item)
      : defaultValue;
  });

  const setPersistedValue = useCallback(
    (value: boolean) => {
      localStorage.setItem(key, value.toString());
      setValue(value);
    },
    [key]
  );

  return [value, setPersistedValue] as const;
};
