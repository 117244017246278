import {
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
} from '@rossum/ui/x-data-grid-pro';
import { isNonEmptyObject } from '../field-detail/getProcessRowUpdate';

export const arrayObjectOperators = getGridStringOperators().map(operator => ({
  ...operator,
  getApplyFilterFnV7: (filterItem: GridFilterItem, column: GridColDef) => {
    const filterFunction =
      operator.getApplyFilterFnV7?.(filterItem, column) ?? null;

    return filterFunction === null
      ? null
      : (v: { [key: string]: number }): boolean => {
          const value = isNonEmptyObject(v)
            ? Object.keys(v || {})
                .map(label => label)
                .join(' ')
            : null;

          // @ts-expect-error default string operators expect only first argument
          return filterFunction(value);
        };
  },
}));
