import { Button } from '@rossum/ui/material';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Container from '../../components/UI/Container';
import InfoScreen from '../../components/UI/InfoScreenHeader';
import { cancelAnnotationFulfilled as cancelAnnotationFulfilledAction } from '../../redux/modules/annotation/actions';
import { State } from '../../types/state';

type TimeExpiredProps = {
  cancelAnnotationFulfilled: () => void;
  cancelUrlFetched: boolean;
};
const TimeExpired = ({
  cancelAnnotationFulfilled,
  cancelUrlFetched,
}: TimeExpiredProps) => {
  const intl = useIntl();

  return (
    <Container dataPageTitle="time-expired">
      <InfoScreen
        title={intl.formatMessage({ id: 'containers.timeExpired.title' })}
        subTitle={intl.formatMessage({ id: 'containers.timeExpired.text' })}
      />

      {cancelUrlFetched && (
        <Button
          color="secondary"
          size="small"
          onClick={() => cancelAnnotationFulfilled()}
        >
          {intl.formatMessage({ id: 'containers.timeExpired.cancel' })}
        </Button>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  cancelAnnotationFulfilled: cancelAnnotationFulfilledAction,
};

const mapStateToProps = (state: State) => ({
  cancelUrlFetched: !!get(state, 'ui.embeddedConfig.cancelUrl'),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeExpired);
