import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { linebreak } from '../../../../lib/formaterValues';
import { updateUiSettings } from '../../../../redux/modules/user/actions';

type Props = {
  children: (params: {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
  onConfirm: () => void;
};

export const TrialUploadWarning = ({ children, onConfirm }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dontShowChecked, setDontShowChecked] = useState<boolean>(false);

  const dispatch = useDispatch();

  return (
    <Stack spacing={1} mt="auto">
      <Typography variant="h6">
        <FormattedMessage id="containers.annotationList.upload.firstUploadWarning.title" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage
          id="containers.annotationList.upload.firstUploadWarning.description"
          values={{ linebreak }}
        />
      </Typography>
      <FormControlLabel
        control={<Checkbox size="small" />}
        label={
          <FormattedMessage id="containers.annotationList.upload.firstUploadWarning.toggle" />
        }
        value={dontShowChecked}
        onChange={() => setDontShowChecked(!dontShowChecked)}
        componentsProps={{ typography: { fontSize: 14 } }}
        sx={{ width: 'fit-content' }}
      />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => setShowModal(true)}
          color="secondary"
          data-cy="upload-docs-change-queue-btn"
        >
          <FormattedMessage id="containers.annotationList.upload.firstUploadWarning.change" />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(
              updateUiSettings({ hideFirstUploadWarning: dontShowChecked })
            );
            onConfirm();
          }}
          data-cy="upload-docs-confirm-queue-btn"
        >
          <FormattedMessage id="containers.annotationList.upload.firstUploadWarning.confirm" />
        </Button>
      </Stack>
      {children({ showModal, setShowModal })}
    </Stack>
  );
};
