import { ID, Url } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { BillingStatsListResponse } from '../models/billingStats';
import { billingStatsListResponseSchema } from '../models/billingStats.schema';
import {
  billingStatsPayloadSchema,
  billingStatsQuerySchema,
} from './getStats.schema';

export type BillingStatsPayload = Partial<{
  filters: Partial<{
    beginDate: string;
    endDate: string;
    queues: (Url | null)[];
    organizations: Url[]; // Allowed only if endpoint === 'organization_groups'
  }>;
  groupBy: Array<'organization' | 'queue' | 'month' | 'week'>;
  orderBy: Array<'-billable_pages' | '-billable_documents'>;
}>;

export type BillingStatsQuery = PaginationQuery;

export const getStats =
  (endpoint: 'organizations' | 'organization_groups') =>
  (id: ID, payload: BillingStatsPayload, query: BillingStatsQuery) => {
    return {
      endpoint: `/${endpoint}/${id}/billing_stats`,
      method: 'POST',
      responseSchema: billingStatsListResponseSchema,
      payloadSchema: billingStatsPayloadSchema,
      payload,
      querySchema: billingStatsQuerySchema,
      query,
    } as const;
  };
