import { find, findIndex, map, uniq, uniqBy } from 'lodash';
import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Workspaces } from '../../../types/workspaces';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, logoutUser } from '../auth/actions';
import { createQueueFulfilled, deleteQueueFulfilled } from '../queues/actions';
import { initialPagination } from '../utils';
import {
  createWorkspaceFulfilled,
  deleteWorkspaceFulfilled,
  fetchWorkspaceFulfilled,
  fetchWorkspacesFulfilled,
  updateWorkspace,
} from './actions';

const initialState = Immutable<Workspaces>({
  list: [],
  pagination: initialPagination,
  isLoaded: false,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchWorkspacesFulfilled): {
      const { results } = action.payload;
      const updatedWorkspaces = map(state.list, workspace => ({
        ...workspace,
        ...find(results, { id: workspace.id }),
      }));

      const newList = uniqBy([...updatedWorkspaces, ...results], 'id');
      return state
        .set('list', newList)
        .set('pagination', action.payload.pagination)
        .set('isLoaded', true);
    }

    case getType(fetchWorkspaceFulfilled): {
      const updatedWorkspaces = map(state.list, workspace =>
        action.payload.id === workspace.id
          ? { ...workspace, ...action.payload }
          : workspace.asMutable({ deep: true })
      );
      const newList = uniqBy([...updatedWorkspaces, action.payload], 'id');
      return state.set('list', newList);
    }

    case getType(createWorkspaceFulfilled):
      return state.setIn(['list', state.list.length], action.payload);

    case getType(deleteWorkspaceFulfilled):
      return state.set(
        'list',
        state.list.filter(workspace => workspace.id !== action.payload.id)
      );

    case getType(deleteQueueFulfilled):
      return state.set(
        'list',
        state.list.map(workspace => ({
          ...workspace,
          queues: workspace.queues.filter(
            queue => !queue.endsWith(`/${action.payload.id}`)
          ),
        }))
      );

    case getType(createQueueFulfilled): {
      const { workspace, url } = action.payload;

      const workspaceIndex = state.list.findIndex(ws => ws.url === workspace);

      if (workspaceIndex > -1) {
        return state.updateIn(['list', workspaceIndex], ws => ({
          ...ws,
          queues: uniq([...ws.queues, url]),
        }));
      }

      return state;
    }

    case getType(updateWorkspace): {
      const { id } = action.meta;
      const index = findIndex(state.list, { id });

      // TODO what if index is -1 ?
      return state.updateIn(['list', index], workspace =>
        workspace.merge(action.payload, { deep: true })
      );
    }

    case getType(logoutUser):
    case getType(fetchMembershipTokenFulfilled):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
