import TrashIcon from 'mdi-react/TrashIcon';
import { ReactNode } from 'react';
import styles from '../style.module.sass';

export const NextLineWithIcon = (...chunks: ReactNode[]) => (
  <div className={styles.ModalNextLine}>
    <div className={styles.ModalNextLineIcon}>
      <TrashIcon size={17} />
    </div>
    <div className={styles.ModalNextLineText}>{chunks.map(chunk => chunk)}</div>
  </div>
);
