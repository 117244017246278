import { endpoints, getIDFromUrl } from '@rossum/api-client';
import {
  AccessibleChildAnnotation,
  StartEditPagesResponse,
} from '@rossum/api-client/annotations';
import { HttpError } from '@rossum/api-client/errors';
import { Page as ApiPage } from '@rossum/api-client/pages';
import { EditRelation } from '@rossum/api-client/relations';
import { useQuery } from '@tanstack/react-query';
import { Location } from 'history';
import { slice, takeWhile } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'redux-first-history';
import { api } from '../../lib/apiClient';
import { constructDocumentUrl } from '../../lib/url';
import {
  annotationSelector,
  annotationSideloadsSelector,
} from '../../redux/modules/annotation/selectors';
import { SuggestedEdit } from '../../redux/modules/annotation/types';
import { throwError } from '../../redux/modules/messages/actions';
import { safeOrganizationSelector } from '../../redux/modules/organization/selectors';
import { Annotation, AnnotationSideloads } from '../../types/annotation';
import { Organization } from '../../types/organization';
import { State } from '../../types/state';
import { EditDocumentConfig, EditDocumentMode, Page, Part } from './editState';

const determineEditDocumentMode = (
  annotation: Annotation,
  annotationSideloads: AnnotationSideloads,
  startEditResponse: StartEditPagesResponse,
  location: Location
): Promise<EditDocumentMode> => {
  // NOTE: Relation of type "edit" is not attached to parent annotation,
  // so we can't use it here.
  if (annotation.status === 'split') {
    const fromAnnotationId = Number(
      new URLSearchParams(location.search).get('from')
    );

    const validChildAnnotation = startEditResponse.children.find(
      (a): a is AccessibleChildAnnotation =>
        'url' in a && getIDFromUrl(a.url) === fromAnnotationId
    );

    return Promise.resolve({
      type: 'parent-annotation',
      fromAnnotation: validChildAnnotation?.url,
    });
  }

  const edit = annotationSideloads.relations.find(
    (r): r is EditRelation => r.type === 'edit'
  );

  if (!edit) return Promise.resolve({ type: 'single-annotation' });

  return api
    .request(endpoints.annotations.get(getIDFromUrl(edit.parent)))
    .then(() => ({
      type: 'child-annotation' as const,
      parentIsAccessible: true,
      parentUrl: edit.parent,
    }))
    .catch(e => {
      if (e instanceof HttpError && e.code === 404) {
        return {
          type: 'child-annotation',
          parentIsAccessible: false,
          parentUrl: edit.parent,
        };
      }
      // TODO what to do here?
      throw e;
    });
};

export const useStartEditMode = () => {
  const dispatch = useDispatch();
  const annotation = useSelector(annotationSelector);
  const annotationSideloads = useSelector(annotationSideloadsSelector);

  const pages = useSelector((state: State) => state.pages.pages);
  const organization = useSelector(safeOrganizationSelector);

  // string is URL - it will be replaced with SuggestedEdit object
  const waitingForSuggestedEdit =
    typeof annotation?.suggestedEdit === 'string' &&
    annotation?.status !== 'split';

  // From Redux, we need annotation, organization, and suggested edits to be loaded
  const enabled = !!annotation && !waitingForSuggestedEdit && !!organization;

  const location = useLocation();

  return useQuery({
    queryKey: ['start-edit', annotation?.id] as const,

    queryFn: async () => {
      if (!annotation)
        return Promise.reject(
          new Error('Cannot start edit mode without annotation.')
        );

      if (!organization)
        return Promise.reject(
          new Error('Cannot start edit mode without organization.')
        );

      const startEditResponse = await api.request(
        endpoints.annotations.startEditPages(annotation.id)
      );

      const mode = await determineEditDocumentMode(
        annotation,
        annotationSideloads,
        startEditResponse,
        location
      );

      const suggestedEdit =
        annotation.suggestedEdit && typeof annotation.suggestedEdit !== 'string'
          ? annotation.suggestedEdit
          : null;

      return buildEditDocumentConfiguration(
        startEditResponse,
        // get rid of Immutable wrapper
        slice(pages),
        annotation,
        annotationSideloads,
        organization,
        suggestedEdit,
        mode
      );
    },
    enabled,
    cacheTime: 0,
    onError: e => {
      if (e instanceof HttpError && e.code === 409) {
        // The user has no indication that a parent document is locked by somebody else,
        // so we have to explain it in the error message.
        dispatch(throwError('editModeConflictError'));
      } else {
        dispatch(throwError('clientError'));
      }
      // User can access edit mode directly even for annotations where it can't be accessed,
      // so we just redirect them back to validation screen.
      if (annotation) {
        dispatch(replace(constructDocumentUrl({ id: annotation.id })));
      }
    },
    retry: false,
  });
};

const buildEditDocumentConfiguration = (
  response: StartEditPagesResponse,
  parentPages: ApiPage[],
  annotation: Annotation,
  annotationSideloads: AnnotationSideloads,
  organization: Organization,
  suggestedEdit: SuggestedEdit | null,
  mode: EditDocumentMode
): EditDocumentConfig => {
  const pageUrlToNumber = Object.fromEntries(
    parentPages.map(p => [p.url, p.number] as const)
  );

  // We are getting responses with page urls from suggested splits / starting edit mode.
  // We kinda hope that all these pages exist in state so that we can map these to page number.
  // When we are missing some of the pages in state, the behavior can be unpredictable, so we should better crash.
  const getPageNumber = (url: string): number => {
    const pageNumber = pageUrlToNumber[url];
    if (!pageNumber) {
      throw new Error(`Unknown page received: ${url}.`);
    }
    return pageNumber;
  };

  const settings = {
    disableSplitting:
      organization.uiSettings?.features?.disableSplitting ?? false,
    preferInPlaceSplitting:
      organization.uiSettings?.features?.preferInPlaceSplitting ?? false,
  };

  // single annotation
  if (response.children.length === 0) {
    const initialParts: Part[] = [
      {
        annotationType: 'accessible',
        annotation: {
          status: annotation.status,
          filename: annotationSideloads.document?.originalFileName ?? 'N/A',
          queueUrl: annotation.queue,
          started: true,
          url: annotation.url,
        },
        pages: parentPages.map(p => ({
          deleted: false,
          initialDeleted: false,
          pageNumber: p.number,
          rotationDeg: 0,
          initialRotationDeg: 0,
          url: p.url,
        })),
        targetQueueUrl: annotation.queue,
      },
    ];

    const initialPartsWithSuggestedSplits = suggestedEdit
      ? suggestedEdit.documents.map(({ pages }) => ({
          pages: pages.map(p => ({
            deleted: false,
            initialDeleted: false,
            rotationDeg: 0,
            initialRotationDeg: 0,
            pageNumber: getPageNumber(p.page),
            url: p.page,
          })),
          annotationType: 'none' as const,
          targetQueueUrl: annotation.queue,
        }))
      : null;

    return {
      initialParts,
      initialPartsWithSuggestedSplits,
      mode,
      annotationUrl: annotation.url,
      settings,
    };
  }

  // parent annotation
  const assignedPageUrls = new Set(
    response.children.flatMap(c => c.parentPages.map(p => p.page))
  );

  const isDeleted = (page: ApiPage) => !assignedPageUrls.has(page.url);

  const getDeletedPagesFollowingPage = (pageNumber: number): Page[] =>
    takeWhile(parentPages.slice(pageNumber), isDeleted).map(p => ({
      deleted: true,
      initialDeleted: true,
      pageNumber: p.number,
      rotationDeg: p.rotationDeg,
      initialRotationDeg: p.rotationDeg,
      url: p.url,
    }));

  const partsForChildAnnotations: Part[] = response.children.map(
    (childAnnotation, idx) => {
      const pages = [
        // Deleted pages from the beginning of the document are sorted
        // first in the first part
        ...(idx === 0 ? getDeletedPagesFollowingPage(0) : []),

        ...childAnnotation.parentPages.flatMap(p => [
          {
            pageNumber: getPageNumber(p.page),
            rotationDeg: p.rotationDeg,
            initialRotationDeg: p.rotationDeg,
            deleted: false,
            initialDeleted: false,
            url: p.page,
          },
          // After each page we insert deleted pages with consecutive numbers
          ...getDeletedPagesFollowingPage(getPageNumber(p.page)),
        ]),
      ];

      if ('url' in childAnnotation) {
        return {
          annotationType: 'accessible',
          annotation: {
            status: childAnnotation.status,
            filename: childAnnotation.originalFileName,
            queueUrl: childAnnotation.queue,
            started: childAnnotation.started,
            url: childAnnotation.url,
          },
          targetQueueUrl: childAnnotation.queue,
          pages,
        };
      }

      return {
        annotationType: 'inaccessible',
        annotation: {
          started: false,
          filename: childAnnotation.originalFileName,
        },
        pages,
      };
    }
  );

  return {
    initialParts: partsForChildAnnotations,
    // We don't show suggested splits when displaying parent annotation
    initialPartsWithSuggestedSplits: null,
    mode,
    annotationUrl: annotation.url,
    settings,
  };
};
