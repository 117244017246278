import { alpha, chipClasses, Components, Theme } from '../../material';

// Please ignore the verbosity of this file, waiting for
// https://github.com/mui-org/material-ui/pull/29610 to be released
// then it will get _much_ shorter
export const muiChip = (theme: Theme): Components['MuiChip'] => ({
  styleOverrides: {
    root: {
      lineHeight: 1,
    },
    sizeSmall: {
      fontSize: 12,
    },
    iconSmall: {
      fontSize: 18,
    },
    deleteIconSmall: {
      fontSize: 18,
    },
    avatarSmall: {
      width: 18,
      height: 18,
    },
  },
  variants: [
    {
      props: { size: 'tiny' },
      style: {
        height: 20,
        fontSize: 11,
        [`& .${chipClasses.icon}`]: {
          fontSize: 14,
        },
        [`& .${chipClasses.deleteIcon}`]: {
          fontSize: 14,
        },
        [`& .${chipClasses.avatar}`]: {
          width: 14,
          height: 14,
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'primary' },
      style: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.primary.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.primary.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'error' },
      style: {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.error.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.error.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.error.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.error.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'success' },
      style: {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.success.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.success.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.success.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.success.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'info' },
      style: {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.info.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.info.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.info.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.info.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'secondary' },
      style: {
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.secondary.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.secondary.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.secondary.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'warning' },
      style: {
        color: theme.palette.warning.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.15),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.warning.main, 0.18),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.warning.main, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.warning.main, 0.8),
          [`&:hover`]: {
            color: theme.palette.warning.main,
          },
        },
      },
    },
    {
      props: { variant: 'twoTone', color: 'default' },
      style: {
        color: alpha(theme.palette.text.primary, 0.8),
        backgroundColor: alpha(theme.palette.text.primary, 0.08),
        [`&:hover`]: {
          backgroundColor: alpha(theme.palette.text.primary, 0.12),
        },
        [`& .${chipClasses.icon}`]: {
          color: alpha(theme.palette.text.primary, 0.8),
        },
        [`& .${chipClasses.deleteIcon}`]: {
          color: alpha(theme.palette.text.primary, 0.8),
          [`&:hover`]: {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  ],
});
