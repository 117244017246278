// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { triggerEventTypeSchema } from '../models/trigger.schema';

export const createTriggerPayloadSchema = z.object({
  queue: urlSchema,
  event: triggerEventTypeSchema,
  condition: z.unknown().optional(),
  emailTemplates: z.array(urlSchema).optional(),
  deleteRecommendations: z.array(urlSchema).optional(),
});
