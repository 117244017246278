import { endpoints } from '@rossum/api-client';
import { EngineListQuery } from '@rossum/api-client/engines';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineKeys } from '../keys';

export const useEngines = (query: EngineListQuery = {}, enabled = true) => {
  return useQuery({
    queryKey: engineKeys.list({ filters: query }),
    queryFn: () => api.request(endpoints.engines.list(query)),
    enabled,
  });
};
