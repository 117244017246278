import { endpoints } from '@rossum/api-client';
import { LoginPayload } from '@rossum/api-client/authentication';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setRossumAppDomainCookie } from '../../appDomain';
import { api } from '../../lib/apiClient';
import { authSignIn } from '../../redux/modules/auth/actions';
import { throwError } from '../../redux/modules/messages/actions';

export const useLogin = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (credentials: LoginPayload) =>
      api.request(
        endpoints.authentication.login({ ...credentials, origin: 'rossum_ui' })
      ),

    onSuccess: ({ key, domain }) => {
      setRossumAppDomainCookie(domain);
      dispatch(authSignIn(key));
    },

    onError: () => {
      dispatch(throwError('loginError'));
    },
  });
};
