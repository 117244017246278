import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@rossum/ui/icons';
import { Paper, Stack, SxProps } from '@rossum/ui/material';
import { useMemo, useState } from 'react';

type DraggableRowProps = {
  id: UniqueIdentifier;
  isPlaceholder?: boolean;
  renderChildren: (isActive: boolean, isDragging: boolean) => React.ReactNode;
  paperSx?: SxProps;
  indicatorSx?: SxProps;
};

export const DraggableRow = ({
  id,
  isPlaceholder,
  renderChildren,
  paperSx,
  indicatorSx,
}: DraggableRowProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const sort = useSortable({
    id,
  });

  const {
    attributes,
    listeners,
    isDragging,
    transition,
    transform,
    setNodeRef,
  } = sort;

  const { x = 0, y = 0 } = transform || {};
  const tileScale = isPlaceholder ? 1.02 : 1;

  const cursor = useMemo(() => {
    if (isPlaceholder) return 'grabbing';
    return 'grab';
  }, [isPlaceholder]);

  return (
    <Stack
      ref={setNodeRef}
      component={Paper}
      direction="row"
      alignItems="stretch"
      position="relative"
      gap={0.5}
      pr={1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        borderRadius: 1,
        backgroundColor: t =>
          isFocused || isHovered || isPlaceholder
            ? t.palette.action.selected
            : undefined,
        transition: t =>
          [transition, t.transitions.create('background-color')]
            .filter(Boolean)
            .join(', '),
        transform: CSS.Transform.toString({
          x,
          y,
          scaleX: tileScale,
          scaleY: tileScale,
        }),
        ...paperSx,
      }}
    >
      <Stack
        justifyContent="center"
        {...listeners}
        {...attributes}
        px={0.5}
        sx={{
          opacity: isFocused || isHovered || isPlaceholder ? 1 : 0,
          transition: t =>
            t.transitions.create(['opacity', 'background-color'], {
              duration: t.transitions.duration.short,
            }),
          borderTopLeftRadius: 1,
          borderBottomLeftRadius: 1,
          cursor,
          backgroundColor: t =>
            isPlaceholder ? t.palette.action.hover : 'transparent',
          outline: 'none',
          '&:focus': {
            backgroundColor: t => t.palette.action.hover,
          },
          ...indicatorSx,
        }}
      >
        <DragIndicator fontSize="small" color="action" sx={{ opacity: 0.5 }} />
      </Stack>
      {renderChildren(isFocused || isHovered, isDragging)}
    </Stack>
  );
};
