import { Schema, SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { useCallback } from 'react';
import { getSchemaConcept } from '../../hooks/useSchemaContept';
import { useValidateSchema } from '../../hooks/useValidateSchema';
import { FieldsFormModel } from '../formModels';
import { handleFieldFormErrors } from './errorParsers';

type Props = {
  schema: Schema;
  data: SchemaSection | SchemaItem | null;
  parentId: string | null;
  onValidateFail?: () => void;
};
export const useValidateFormErrors = ({
  schema,
  data,
  parentId,
  onValidateFail,
}: Props) => {
  const { mutateAsync: validateSchema } = useValidateSchema();

  const validateForm = useCallback(
    async (formValues: FieldsFormModel) => {
      const schemaConcept = getSchemaConcept({
        schema,
        apiModel: data,
        parentId,
        formValues,
      });

      return validateSchema(schemaConcept)
        .then(response => {
          return handleFieldFormErrors({
            response,
            formModel: formValues,
          });
        })
        .catch(() => {
          onValidateFail?.();
          return null;
        });
    },
    [data, onValidateFail, parentId, schema, validateSchema]
  );

  return { validateForm };
};
