import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import download from 'downloadjs';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { absoluteApiUrl } from '../../../constants/config';
import { api } from '../../../lib/apiClient';
import { getFilenameForExport } from '../../../redux/modules/annotations/epics';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { DownloadFormat } from '../download-buttons/DownloadFormatsSelect';

const organizationUISettingsExportSchema = z
  .object({
    columns: z.array(z.string()).optional(),
    prependColumns: z.array(z.string()).default(['meta_file_name']),
    appendColumns: z
      .array(z.string())
      .default(['meta_arrived_at', 'meta_url', 'meta_file']),
  })
  .default({});

export const useExportAnnotationsCrossQueue = () => {
  const organizationUISettingsExport = useSelector(safeOrganizationSelector)
    ?.uiSettings?.export;

  return useMutation({
    mutationFn: ({
      format,
      selectedAnnotationsIds,
      toExport,
    }: {
      toExport?: boolean;
      queueName: string | null;
      format: DownloadFormat;
      selectedAnnotationsIds?: number[];
    }) => {
      const annotations = selectedAnnotationsIds?.map(
        id => `${absoluteApiUrl}/annotations/${id}`
      );
      const { prependColumns, appendColumns, columns } =
        organizationUISettingsExportSchema.parse(organizationUISettingsExport);
      const pageSize = selectedAnnotationsIds?.length;

      return api.request(
        endpoints.annotations.exportAnnotationCrossQueue(
          {
            format,
            prependColumns: prependColumns.toString(),
            appendColumns: appendColumns.toString(),
            columns: columns?.toString(),
            pageSize,
            ...(toExport ? { toStatus: 'exporting' } : {}),
          },
          {
            annotations,
          }
        )
      );
    },

    onSuccess: (data: Blob, params) => {
      const { type } = data;

      download(
        data,
        getFilenameForExport(null, params.queueName, params.format),
        type
      );
    },
  });
};
