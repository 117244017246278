import { getIDFromUrl } from '@rossum/api-client';
import { AnnotationStatus } from '@rossum/api-client/shared';
import { Visibility } from '@rossum/ui/icons';
import { Button, CircularProgress } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { startAnnotationFulfilled } from '../../../redux/modules/annotation/actions';
import { throwInfo } from '../../../redux/modules/messages/actions';
import { fetchAnnotationStackFulfilled } from '../../../redux/modules/stack/actions';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../../pricing/utils';
import { DocumentListQuery, useStartReviewing } from './useStartReviewing';
import { reviewableStatuses } from './utils';

const NUMBER_OF_PAGES = 10;
const PAGE_SIZE = 100;

type Props = {
  annotationUrls: string[];
  existingStatuses: AnnotationStatus[];
  documentListQuery: DocumentListQuery;
  queueId: number;
};
export const StartReviewingCTA = ({
  annotationUrls,
  existingStatuses,
  documentListQuery,
  queueId,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: startReviewing } = useStartReviewing({
    // Update the dashboard request - set fixed page size and filter only for reviewable statuses.
    query: {
      ...documentListQuery.query,
      pageSize: PAGE_SIZE,
    },
    searchQuery: documentListQuery.searchQuery,
    dataGridFiltering: {
      ...documentListQuery.dataGridFiltering,
      items: [
        ...documentListQuery.dataGridFiltering.items,
        { field: 'status', operator: 'isAnyOf', value: reviewableStatuses },
        ACCESSIBLE_ANNOTATIONS_FILTER_ITEM,
      ],
    },
    numberOfPages: NUMBER_OF_PAGES,
    queueId,
  });

  const existingReviewableStatuses = existingStatuses.filter(status =>
    reviewableStatuses.some(s => status === s)
  );

  // Since we are using this query on-demand, we are using imperative `fetchQuery` API instead
  // Therefore we have to handle the loading state ourselves.
  const handleStartReviewing = () => {
    setLoading(true);
    startReviewing()
      .then(({ annotations, startedAnnotation }) => {
        // If there is anything to review.
        if (startedAnnotation.annotation) {
          dispatch(
            fetchAnnotationStackFulfilled(
              annotations.map(a => getIDFromUrl(a.url))
            )
          );
          dispatch(startAnnotationFulfilled(startedAnnotation.annotation));
        } else {
          dispatch(throwInfo('noAnnotationsToReview'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      variant="contained"
      disabled={
        existingReviewableStatuses.length === 0 ||
        annotationUrls.length === 0 ||
        loading
      }
      onClick={handleStartReviewing}
      data-cy="start-processing-btn"
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <Visibility />
        )
      }
    >
      {intl.formatMessage({
        id: 'containers.annotationList.startReviewing',
      })}
    </Button>
  );
};
