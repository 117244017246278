import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';

export const QUERY_KEY_EMAIL_TEMPLATES = 'emailTemplates';

export const useEmailTemplates = (queue: Url | null) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [QUERY_KEY_EMAIL_TEMPLATES, queue] as const,

    queryFn: () => {
      if (!queue) {
        return Promise.reject(new Error('Queue is null.'));
      }
      return api.unpaginatedRequest(endpoints.emailTemplates.list)({
        queue: getIDFromUrl(queue),
      });
    },
    enabled: !!queue,
  });
};
