import { compact, find, includes } from 'lodash';
import { createSelector } from 'reselect';
import createDeepEqualSelector from '../../../lib/createDeepEqualSelector';
import { Queue } from '../../../types/queue';
import { State } from '../../../types/state';
import { Workspace } from '../../../types/workspace';
import { sortingSelector } from '../user/selectors';
import {
  getQueueSortFn,
  getWorkspaceSortFn,
  WorkspaceWithToReviewCount,
} from './helpers';

const queuesSelector = (state: State) => state.queues.list;
export const workspacesSelector = (state: State) => state.workspaces.list;

const workspacesWithInQueueCounts = createDeepEqualSelector(
  workspacesSelector,
  queuesSelector,
  (workspaces, queues) =>
    workspaces.map<WorkspaceWithToReviewCount>(workspace => {
      const queuesInWorkspace = queues.filter(queue =>
        workspace.queues.includes(queue.url)
      );
      const toReviewCount = queuesInWorkspace.reduce(
        (acc, { counts: { to_review = 0, reviewing = 0 } }) =>
          acc + to_review + reviewing,
        0
      );
      return { ...workspace, toReviewCount };
    })
);

const sortedWorkspacesSelector = createDeepEqualSelector(
  workspacesWithInQueueCounts,
  sortingSelector,
  (workspacesWithInQueueCounts, sorting) => {
    return [...workspacesWithInQueueCounts].sort(getWorkspaceSortFn(sorting));
  }
);

export const findWorkspaceByQueue = (
  workspaces: Array<Workspace>,
  { url }: Queue
): Workspace | null | undefined =>
  find(workspaces, ({ queues }) => includes(queues, url));

export const workspacesWithQueuesSelector = createDeepEqualSelector(
  queuesSelector,
  sortedWorkspacesSelector,
  sortingSelector,
  (queues, workspaces, sorting) =>
    workspaces.map(({ queues: _queues, ...rest }) => ({
      queues: compact(_queues.map(url => find(queues, { url }))).sort(
        getQueueSortFn(sorting)
      ),
      ...rest,
    }))
);

export const workspacesWithNonEmptyQueuesSelector = createSelector(
  workspacesWithQueuesSelector,
  workspaces => workspaces.filter(({ queues }) => !!queues.length)
);

export default workspacesWithInQueueCounts;
