const borderColors = [
  '#0bb3ff',
  '#42b964',
  '#ff4eaa',
  '#ff5a31',
  '#dcdc2f',
  '#1b8fff',
  '#de0073',
  '#ff1c1c',
  '#5d9aa7',
  '#2f72ff',
];

export const getBorderColor = (
  schemaId: string | undefined,
  schemaIds: string[] | undefined
) => {
  if (!schemaId || !schemaIds) return undefined;

  const borderColorIndex =
    schemaIds.findIndex(_schemaId => _schemaId === schemaId) %
    (borderColors.length - 1);

  return borderColors[borderColorIndex];
};
