import clsx from 'clsx';
import { truncate } from 'lodash';
import CircleIcon from 'mdi-react/ArrowRightBoldCircleIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Note } from '../../types/notes';
import styles from './styles.module.sass';

// TODO: probably remove this whole file once mini card on Validation screen would be implemented
const NOTE_LENGTH = 120;

type Props = {
  note: Note;
  onClick?: (_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  withHeader?: boolean;
  style?: React.CSSProperties;
};

const RejectionMessage = ({ withHeader, note, onClick, style }: Props) => (
  <div
    className={clsx(
      styles.RejectionNoteWrapper,
      withHeader && styles.RejectionNoteWrapperHeader
    )}
    style={style}
  >
    {withHeader && (
      <div className={styles.Title}>
        <CancelIcon />
        <FormattedMessage id="components.annotationOverview.note.rejection.header" />
      </div>
    )}
    <span className={styles.RejectionNoteTitle}>
      <FormattedMessage id="components.annotationOverview.note.rejection.title" />
    </span>
    <span className={styles.RejectionNoteContent}>
      {truncate(note.content, { length: NOTE_LENGTH })}
    </span>
    {onClick && (
      <>
        <div className={styles.SeparatorLine} />
        <div
          className={clsx(
            styles.ShowEmailButton,
            !withHeader && styles.ShowEmailButtonWithBorder
          )}
          onClick={onClick}
        >
          <FormattedMessage id="components.annotationOverview.note.rejection.button" />
          <CircleIcon size={18} className={styles.ButtonIcon} />
        </div>
      </>
    )}
  </div>
);

export default RejectionMessage;
