import * as R from 'remeda';

type GetUpdatedPathnameProps = {
  pathname: string;
  segmentsToDrop: number;
  newSegmentToAdd?: string;
};

const addSegmentIfDefined = (path: string[], segment: string | undefined) =>
  segment ? [...path, segment] : path;

export const getUpdatedPathname = ({
  pathname,
  segmentsToDrop = 1,
  newSegmentToAdd,
}: GetUpdatedPathnameProps) => {
  if (segmentsToDrop < 1) {
    throw new Error('At least one segment must be dropped.');
  }

  return R.pipe(
    pathname.split('/'),
    R.dropLast(segmentsToDrop),
    path => addSegmentIfDefined(path, newSegmentToAdd),
    R.join('/')
  );
};
