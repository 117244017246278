import { endpoints, getIDFromUrl, Url, withSideload } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { api } from '../../lib/apiClient';

const useDirectImportingAnnotations = ({
  annotationUrls,
}: {
  annotationUrls: Url[];
}) => {
  return useQuery({
    queryKey: ['uploaded-annotations', annotationUrls],
    queryFn: () =>
      api.request(
        withSideload(
          endpoints.annotations.list({
            id: annotationUrls.map(getIDFromUrl),
          }),
          { queues: true, documents: true }
        )
      ),
    refetchInterval: 1000,
    enabled: annotationUrls.length > 0,
    select: data =>
      data.results.map(annotation => {
        const queue = data.queues.find(queue => queue.url === annotation.queue);
        const document = data.documents.find(
          queue => queue.url === annotation.document
        );

        return {
          annotationUrl: annotation.url,
          annotationStatus: annotation.status,
          queueUrl: queue?.url,
          queueName: queue?.name,
          fileName: document?.originalFileName ?? undefined,
        };
      }),
  });
};

export const useImportingAnnotations = ({
  annotationUrls,
}: {
  annotationUrls: Url[];
}) => {
  const uploadedAnnotationsQuery = useDirectImportingAnnotations({
    annotationUrls,
  });

  const editedAnnotationUrls = useMemo(
    () =>
      uploadedAnnotationsQuery.data
        ?.filter(({ annotationStatus }) => annotationStatus === 'split')
        .map(annotation => annotation.annotationUrl) ?? [],
    [uploadedAnnotationsQuery.data]
  );

  const relationsQuery = useQuery({
    queryKey: ['uploaded-annotations-related', editedAnnotationUrls],
    queryFn: async () =>
      api.request(
        endpoints.relations.list({
          parent: editedAnnotationUrls?.map(getIDFromUrl),
          type: 'edit',
        })
      ),
    enabled: editedAnnotationUrls.length > 0,
    select: data => data.results.flatMap(results => results.annotations),
  });

  const childAnnotationsQuery = useDirectImportingAnnotations({
    annotationUrls: relationsQuery.data ?? [],
  });

  return { uploadedAnnotationsQuery, childAnnotationsQuery };
};
