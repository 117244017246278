import { isVirtualDatapoint } from '../../../../../redux/modules/datapoints/typedHelpers';
import {
  AnyDatapointDataST,
  MultivalueDatapointDataST,
  SimpleDatapointDataST,
  SuggestedOperation,
  SuggestedOperationState,
} from '../../../../../types/datapoints';

export const createMaterializePayload = (
  allDatapoints: AnyDatapointDataST[],
  replaceOperations: SuggestedOperationState<SuggestedOperation>[],
  selectedDatapoints: SimpleDatapointDataST[],
  currentMultivalueDatapoint: MultivalueDatapointDataST
) => {
  const parentIds = new Set(selectedDatapoints.map(dp => dp.meta.parentId));
  const parentVirtualTuples = allDatapoints.filter(
    dp =>
      dp.category === 'tuple' &&
      parentIds.has(dp.id) &&
      isVirtualDatapoint(dp.id)
  );

  const tuplesToCreate = parentVirtualTuples.map(tuple => ({
    tupleId: tuple.id,
    validatedDatapointIds: selectedDatapoints
      .filter(dp => dp.meta.parentId === tuple.id)
      .map(dp => dp.id),
  }));

  const datapointsToReplace = selectedDatapoints
    .filter(dp => replaceOperations.some(op => op.id === dp.id))
    .map(dp => ({
      datapointId: dp.id,
      isValidated: true,
    }));

  return {
    tuplesToCreate,
    datapointsToReplace,
    multivalueId: currentMultivalueDatapoint.id,
  };
};
