// Generated by ts-to-zod
import { z } from 'zod';
import { datapointFieldPatchModelSchema } from './datapointField.schema';
import { simpleMultivalueFieldPatchModelSchema } from './simpleMultivalueField.schema';
import { tableMultivalueFieldPatchModelSchema } from './tableMultivalueField.schema';
import { datapointFieldSchema } from './transformedDatapointField.schema';
import {
  simpleMultivalueFieldSchema,
  tableMultivalueFieldSchema,
} from './transformedFields.schema';

export const dedicatedEngineSchemaObjectSchema = z.union([
  datapointFieldSchema,
  simpleMultivalueFieldSchema,
  tableMultivalueFieldSchema,
]);

export const dedicatedEngineSchemaObjectPatchModelSchema = z.union([
  datapointFieldPatchModelSchema,
  simpleMultivalueFieldPatchModelSchema,
  tableMultivalueFieldPatchModelSchema,
]);
