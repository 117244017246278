import { Error } from '@rossum/ui/icons';
import { Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { TaskProgress } from './components/TaskProgress';
import { UploadTasks } from './TaskContext';

type UploadsTaskProgressProps = {
  task: UploadTasks[number];
};

export const UploadsTaskProgress = ({ task }: UploadsTaskProgressProps) => {
  const intl = useIntl();

  return (
    <TaskProgress
      primaryLabel={
        task.data.type === 'uploads' && task.data.fileName
          ? task.data.fileName
          : intl.formatMessage({
              id: 'components.asyncTasksDialog.item.title.placeholder',
            })
      }
      secondaryLabel={
        task.data.type === 'uploads' && task.data.queueName
          ? intl.formatMessage(
              {
                id: `components.asyncTasksDialog.item.subtitle.upload.${task.data.status}`,
              },
              {
                queueName: task.data.queueName,
              }
            )
          : ''
      }
      progressLabel={intl.formatMessage({
        id: `components.asyncTasksDialog.item.progress.${task.data.status}`,
      })}
      progressIcon={
        <Tooltip title={task.data.detail}>
          <Error color="error" fontSize="small" />
        </Tooltip>
      }
    />
  );
};
