import { Reducer } from 'redux';
import Immutable, { Immutable as ImmutableType } from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { RootActionType } from '../../rootActions';
import { repeateRequest, repeateRequestSucceeded } from './actions';

const initialState: ImmutableType<number[]> = Immutable([]);

const reducer: Reducer<ImmutableType<number[]>, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(repeateRequest):
      return state.concat([action.meta.timestamp]);

    case getType(repeateRequestSucceeded):
      return state.filter(request => request !== action.meta.timestamp);

    default:
      return state;
  }
};

export default reducer;
