import { useCallback, useEffect, useRef } from 'react';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
} from '../../document-canvas/utils/geometry';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { safeInverse } from '../../document-store/helpers';
import { useDocumentContext } from '../../DocumentContext';
import { getRectangleFromPoints } from './getRectangleFromPoints';
import { useRectangleCreator } from './useRectangleCreator';

// Determines whether Shift+Click is a selection (dragging) or just Shift click on bbox.
const isLargeEnough = (startPoint: Point2D, endPoint: Point2D): boolean => {
  return (
    Math.abs(startPoint.x - endPoint.x) >= 5 &&
    Math.abs(startPoint.y - endPoint.y) >= 5
  );
};
const getRectangleInfoFromPoints = (
  p1: Point2D,
  p2: Point2D,
  startMatrix: DOMMatrix,
  endMatrix: DOMMatrix
) => {
  const startPoint = DOMPoint.fromPoint(p1).matrixTransform(startMatrix);
  const endPoint = DOMPoint.fromPoint(p2).matrixTransform(endMatrix);

  const rectangle = isLargeEnough(p1, p2)
    ? getRectangleFromPoints(startPoint, endPoint)
    : undefined;

  return {
    startPoint,
    endPoint,
    rectangle,
  };
};

export const useDrawSelection = ({
  onFinish,
}: {
  onFinish: (rectangle: Rectangle2D) => void;
}) => {
  const { setCanvasActionInProgress } = useDocumentContext();

  const initialInverseMatrix = useRef<DOMMatrix>(IDENTITY_MATRIX_2D);
  const getCanvasCTM = useDocumentStore(state => state.getCanvasCTM);

  const { startPoint, dragPoint, handleMouseDown } = useRectangleCreator({
    onRectangleCreated: (startPoint, endPoint) => {
      const inverseMatrix = safeInverse(getCanvasCTM());
      const rectangleInfo = getRectangleInfoFromPoints(
        startPoint,
        endPoint,
        initialInverseMatrix.current,
        inverseMatrix
      );
      if (rectangleInfo.rectangle) {
        onFinish(rectangleInfo.rectangle);
      }
    },
  });

  useEffect(() => {
    setCanvasActionInProgress(!!dragPoint);
  }, [dragPoint, setCanvasActionInProgress]);

  const overrideMouseDown = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      if (event.shiftKey) {
        initialInverseMatrix.current = getCanvasCTM().inverse();
        handleMouseDown(event);
      }
    },
    [getCanvasCTM, handleMouseDown]
  );

  const rectangleInfo =
    startPoint && dragPoint
      ? getRectangleInfoFromPoints(
          startPoint,
          dragPoint,
          initialInverseMatrix.current,
          safeInverse(getCanvasCTM())
        )
      : undefined;

  return {
    selection: rectangleInfo?.rectangle,
    handleMouseDown: overrideMouseDown,
  };
};
