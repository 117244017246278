import {
  fieldHighlightMatchToken,
  functionHighlightMatchToken,
  schemaFieldRefPrefix,
} from '../constants';

export const customHighlighters = [
  {
    token: fieldHighlightMatchToken,
    regex: `${schemaFieldRefPrefix}\\.[a-zA-Z0-9_-]+`,
  },
  {
    token: functionHighlightMatchToken,
    regex:
      '\\b(is_empty|default_to|show_info|show_warning|show_error|automation_blocker)\\b',
  },
];
