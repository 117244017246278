import { Box, BoxProps } from '@rossum/ui/material';
import { GRID_LINE_WIDTH, MAX_LABEL_HEIGHT } from './constants';
import { useGridContext } from './GridContext';

type SeparatorLabelContainerProps = BoxProps & {
  orientation: 'horizontal' | 'vertical';
  size: number;
  isActive: boolean;
  gridHovered: boolean;
  interactionInProgress: boolean;
};

export const SeparatorLabelContainer = ({
  orientation,
  size,
  isActive,
  gridHovered,
  interactionInProgress,
  ...restBoxProps
}: SeparatorLabelContainerProps) => {
  const { gridFocused } = useGridContext();
  const transformAmount =
    orientation === 'horizontal'
      ? `calc(-100% - ${GRID_LINE_WIDTH}px - ${gridFocused ? 32 : 16}px)`
      : `-${gridFocused ? 32 : 16}px`;

  const height = isActive ? MAX_LABEL_HEIGHT : Math.max(0, size);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: isActive ? 101 : 100,
        transition: theme =>
          theme.transitions.create('all', {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
          }),
      }}
      style={{
        transformOrigin: 'top left',
        transform: `translate(${
          orientation === 'horizontal' ? transformAmount : 0
        }, ${orientation === 'horizontal' ? 0 : transformAmount}) rotate(${
          orientation === 'horizontal' ? '0' : '-90deg'
        })`,
        height,
      }}
      {...restBoxProps}
    />
  );
};
