import { ActionType, createAction } from 'typesafe-actions';

type ConfirmEditModeDocuments = {
  document: string;
  annotation: string;
};

type ConfirmEditModeFulfilledPayload = {
  results: ConfirmEditModeDocuments[];
};

export const confirmEditModeFulfilled = createAction(
  'CONFIRM_EDITMODE_FULFILLED',
  (payload: ConfirmEditModeFulfilledPayload) => payload
)<ConfirmEditModeFulfilledPayload>();

export type EditModeActions = ActionType<typeof confirmEditModeFulfilled>;
