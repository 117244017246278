import { ElisRequestConfig } from '../../../utils/requestConfig';
import {
  changePasswordPayloadSchema,
  changePasswordResponseSchema,
} from './change.schema';

export type ChangePasswordPayload = {
  new_password1: string;
  new_password2: string;
  old_password: string;
};

export type ChangePasswordResponse = {
  detail: string;
};

export const changePassword = (payload: ChangePasswordPayload) => {
  return {
    endpoint: `/auth/password/change`,
    method: 'POST',
    payload,
    payloadSchema: changePasswordPayloadSchema,
    responseSchema: changePasswordResponseSchema,
  } as const;
};
