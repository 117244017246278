// Generated by ts-to-zod
import { z } from 'zod';
import { paginationResponseSchema } from '../../utils/pagination.schema';

export const billingHistoryValuesSchema = z.object({
  purchasedPages: z.number(),
  billablePages: z.number(),
  purchasedDocuments: z.number(),
  billableDocuments: z.number(),
  extractedPagesWithLearning: z.number(),
  extractedPagesWithoutLearning: z.number(),
  splitPagesWithLearning: z.number(),
  splitPagesWithoutLearning: z.number(),
  extractedDocumentsWithLearning: z.number(),
  extractedDocumentsWithoutLearning: z.number(),
  splitDocumentsWithLearning: z.number(),
  splitDocumentsWithoutLearning: z.number(),
  ocrOnlyPages: z.number(),
  ocrOnlyDocuments: z.number(),
  purchasedExtractedPagesWithLearning: z.number(),
  purchasedExtractedPagesWithoutLearning: z.number(),
  purchasedSplitPagesWithLearning: z.number(),
  purchasedSplitPagesWithoutLearning: z.number(),
  purchasedExtractedDocumentsWithLearning: z.number(),
  purchasedExtractedDocumentsWithoutLearning: z.number(),
  purchasedSplitDocumentsWithLearning: z.number(),
  purchasedSplitDocumentsWithoutLearning: z.number(),
  purchasedOcrOnlyPages: z.number(),
  purchasedOcrOnlyDocuments: z.number(),
});

export const billingHistorySchema = z.object({
  beginDate: z.string(),
  endDate: z.string(),
  values: billingHistoryValuesSchema.and(
    z.object({
      isCurrent: z.boolean(),
    })
  ),
});

export const billingHistoryListResponseSchema = z.object({
  pagination: paginationResponseSchema,
  results: z.array(billingHistorySchema),
  updatedAt: z.string(),
  totals: billingHistoryValuesSchema,
});
