import { AnnotationPayload } from '@rossum/api-client/annotations';
import { InfoOutlined } from '@rossum/ui/icons';
import { Checkbox, Stack, Tooltip, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { MoveAnnotationsMeta } from '../../../redux/modules/annotations/types';
import { Status } from '../../../types/annotationStatus';
import { QueueDialog } from '../../queues/select-queue/QueueDialog';
import { usePatchAnnotations } from './hooks/usePatchAnnotations';

export const getAnnotationToMovePayload = (
  defaultData: {
    queue: string;
    schema: string;
  },
  status: string,
  resetData: boolean
): AnnotationPayload => {
  if (resetData) {
    return {
      ...defaultData,
      rirPollId: null,
      status: 'importing',
      messages: [],
    };
  }

  return {
    ...defaultData,
    status: status === 'failedImport' ? 'failed_import' : 'to_review',
  };
};

type MoveAnnotationDialogProps = {
  selectedAnnotations: { id: number; status: Status }[];
  canResetData: boolean;
  onCancel: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

export const MoveAnnotationDialog = ({
  selectedAnnotations,
  canResetData,
  onCancel,
  onSettled,
  onSuccess,
}: MoveAnnotationDialogProps) => {
  const intl = useIntl();

  const open = selectedAnnotations.length > 0;
  const [shouldResetData, setShouldResetData] = useState(false);

  const { mutate: moveAnnotations, isLoading } = usePatchAnnotations();

  const onSubmit = ({
    queueUrl: queue,
    schemaUrl: schema,
  }: Omit<MoveAnnotationsMeta, 'resetData'>) => {
    const defaultData = { queue, schema };

    const annotationToPatch = selectedAnnotations.map(({ id, status }) => ({
      id,
      payload: getAnnotationToMovePayload(defaultData, status, shouldResetData),
    }));

    return moveAnnotations(annotationToPatch, {
      onSettled,
      onSuccess,
    });
  };

  return (
    <QueueDialog
      open={open}
      onClose={onCancel}
      isLoading={isLoading}
      title={intl.formatMessage(
        { id: 'components.modal.moveDocumentTo.title' },
        { count: selectedAnnotations.length }
      )}
      onConfirm={selectedQueue => {
        onSubmit({
          queueUrl: selectedQueue.url,
          schemaUrl: selectedQueue.schema,
        });
      }}
      tertiaryAction={
        canResetData ? (
          <Stack direction="row" spacing={1} mr="auto" alignItems="center">
            <Checkbox
              id="resetData"
              checked={shouldResetData}
              onChange={() => setShouldResetData(!shouldResetData)}
              size="small"
            />
            <Typography sx={{ color: 'text.secondary' }}>
              {intl.formatMessage({
                id: 'components.selectQueueModal.resetData',
              })}
            </Typography>
            <Tooltip
              title={intl.formatMessage({
                id: 'components.selectQueueModal.tooltip',
              })}
            >
              <InfoOutlined
                fontSize="small"
                sx={{ mx: 1, color: 'text.secondary' }}
              />
            </Tooltip>
          </Stack>
        ) : undefined
      }
    />
  );
};
