import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useApiClient } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';
import { deleteUserFulfilled } from '../../redux/modules/users/actions';
import { DeleteUserFulfilledMeta } from '../../redux/modules/users/types';

export const useDeleteUser = () => {
  const dispatch = useDispatch();
  const api = useApiClient();
  return useMutation({
    mutationFn: ({
      userId,
    }: {
      userId: number;
      meta?: DeleteUserFulfilledMeta;
      onSuccess: () => void;
    }) => api.request(endpoints.users.delete(userId)),

    onSuccess: (_, { userId, onSuccess, meta }) => {
      onSuccess();
      dispatch(deleteUserFulfilled(userId, meta));
    },

    onError: () => {
      dispatch(throwError('userDeletedError'));
    },
  });
};
