import { isEmbedded } from '../../constants/config';
import { isUserAdminLike } from '../../redux/modules/groups/helpers';
import { Status } from '../../types/annotationStatus';
import { GroupRole } from '../../types/group';

export const canManageAnnotation = (role: GroupRole, status: Status) => {
  const isAdmin = isUserAdminLike(role);
  const finalStates: Array<Status> = [
    'exported',
    'failedExport',
    'deleted',
    'confirmed',
  ];
  return isAdmin && !finalStates.includes(status) && !isEmbedded();
};

export const provideFileNameLimit = (icons: {
  messagesExist: boolean;
  triageRulesFileName: boolean;
}): number => {
  const iconKeysArray = Object.keys(icons);

  const numberOfExistingIcons = iconKeysArray.filter(
    oneIcon => icons[oneIcon as keyof typeof icons]
  ).length;

  // change max length of filename regarding icons in the same row, max 2
  return numberOfExistingIcons === 0
    ? 26
    : numberOfExistingIcons === 1
      ? 24
      : 21;
};
