import { ActionType, createAction } from 'typesafe-actions';
import { Annotation } from '../../../types/annotation';
import { Url } from '../../../types/basic';
import { Document } from '../../../types/document';
import { Pagination } from '../../../types/pagination';
import { UserList } from '../../../types/user';

export type RelationData = {
  pagination: Pagination;
  results: Annotation[];
  documents: Document[];
  modifiers: UserList;
};

export const fetchRelationsFulfilled = createAction(
  'FETCH_RELATIONS_FULFILLED',
  (payload: RelationData, _urls: Url[]) => payload,
  (_, urls: Url[]) => ({ urls })
)<RelationData, { urls: Url[] }>();

export type RelationActions = ActionType<typeof fetchRelationsFulfilled>;
