import { ReactNode } from 'react';
import Header from '../../../components/Sidebar/Header';
import { LocalizationKeys } from '../../../i18n';
import styles from '../style.module.sass';

type Props = {
  children: ReactNode;
  label: LocalizationKeys;
};

const SidebarSection = ({ label, children }: Props) => (
  <div className={styles.SidebarSection}>
    <Header title={label} />
    {children}
  </div>
);

export default SidebarSection;
