import { Box, Paper, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const ValidationDialogWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Box
    component={Paper}
    elevation={4}
    sx={{ padding: 1, pointerEvents: 'all' }}
    data-tourstep="dataCaptureProductTour-bbox"
    // Prevent RectangleCreator from creating a bbox when user is selecting text
    onMouseDown={e => e.stopPropagation()}
    onKeyDown={e => {
      // Prevent ValidationKeyboard handler from navigating in the footer,
      // when user wants to move inside the value field
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.stopPropagation();
      }
    }}
  >
    <Stack direction="column" spacing={1}>
      {children}
    </Stack>
  </Box>
);
