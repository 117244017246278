import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteEndpoint = (annotationId: ID) => {
  return {
    endpoint: `/annotations/${annotationId}/delete`,
    method: 'POST',
    responseSchema: z.unknown(),
  } as const;
};
