import { Box, Button, Dialog, DialogContent, Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import DialogTitle from '../../ui/dialog-title/DialogTitle';

export const CancelChangesDialog = ({
  onClose,
  onCancel,
  open,
}: {
  onClose: () => void;
  onCancel: () => void;
  open: boolean;
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { width: 448 },
        elevation: 2,
      }}
      onClose={onClose}
    >
      <DialogTitle
        title={intl.formatMessage({
          id: 'containers.editDocument.cancelChangesDialog.cancel',
        })}
        onClose={onClose}
      />
      <DialogContent>
        <Box sx={{ my: 3 }}>
          {intl.formatMessage({
            id: 'containers.editDocument.cancelChangesDialog.description',
          })}
        </Box>
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            data-cy="document-edit-cancel-dialog-back-to-edit-btn"
          >
            {intl.formatMessage({
              id: 'containers.editDocument.cancelChangesDialog.backToEdit',
            })}
          </Button>
          <Button
            variant="contained"
            onClick={onCancel}
            data-cy="document-edit-cancel-dialog-cancel-changes-btn"
          >
            {intl.formatMessage({
              id: 'containers.editDocument.cancelChangesDialog.cancel',
            })}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
