import { create } from './create';
import { deleteEndpoint } from './delete';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { resetPassword } from './resetPassword';
export { type UsersListQuery } from './list';

export const users = {
  delete: deleteEndpoint,
  create,
  patch,
  list,
  resetPassword,
  get,
};
