import { endpoints } from '@rossum/api-client';
import { UsersListQuery } from '@rossum/api-client/users';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../lib/apiClient';

const QUERY_KEY_USERS = 'users';

export const useUsers = (query: UsersListQuery = {}) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [QUERY_KEY_USERS, query] as const,
    queryFn: ({ queryKey }) => api.request(endpoints.users.list(queryKey[1])),
    keepPreviousData: true,
  });
};
