/**
 * Use this in default case of a switch statement to ensure all cases of a discriminated union has been handled.
 */
export const assertNever = (x: never): never => {
  throw new Error(`Unexpected value: ${x}`);
};

export type DistributiveOmit<T, K extends string> = T extends T
  ? Omit<T, K>
  : never;
