import { Warning } from '@rossum/ui/icons';
import { Chat as ChatIcon } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import HTMLMessage from '../../../components/DatapointMessage/HTMLMessage';

const MAX_MESSAGE_LENGTH = 1000;

type NoteProps<T> = {
  messages?: Array<T>;
};

const Note = <
  T extends { type: 'error' | 'warning' | 'info'; content: string },
>({
  messages,
}: NoteProps<T>) => {
  const firstMessage = messages ? messages[0] : undefined;

  if (!firstMessage) return null;

  const message = firstMessage.content;

  const truncatedMessage =
    message.length > MAX_MESSAGE_LENGTH
      ? `${message.substring(0, MAX_MESSAGE_LENGTH)}...`
      : message;

  return (
    <Tooltip
      title={
        <Stack direction="column" spacing={0.5} alignItems="center">
          <Warning
            fontSize="small"
            color={
              firstMessage.type === 'error'
                ? 'error'
                : firstMessage.type === 'warning'
                  ? 'warning'
                  : 'action'
            }
          />
          <HTMLMessage content={truncatedMessage} />
        </Stack>
      }
      placement="top"
    >
      <ChatIcon fontSize="small" />
    </Tooltip>
  );
};

export default Note;
