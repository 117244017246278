import { endpoints, getIDFromUrl, Url } from '@rossum/api-client';
import { useSelector } from 'react-redux';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { organizationGroupSelector } from '../../../redux/modules/organizationGroup/selectors';
import { isUserOfRoleSelector } from '../../../redux/modules/user/selectors';

export type OrganizationBillingEntity = {
  type: 'organization';
  organizationUrl: Url;
  name: string;
};

export type OrganizationGroupBillingEntity = {
  type: 'organization_group';
  organizationGroupUrl: Url;
  name: string;
};

export type BillingEntity =
  | OrganizationBillingEntity
  | OrganizationGroupBillingEntity;

export const getBillingEndpoint = (billingEntity: BillingEntity) =>
  billingEntity.type === 'organization'
    ? {
        endpoint: endpoints.organization,
        id: getIDFromUrl(billingEntity.organizationUrl),
      }
    : {
        endpoint: endpoints.organizationGroups,
        id: getIDFromUrl(billingEntity.organizationGroupUrl),
      };

export const useBillingEntity = (): BillingEntity | undefined => {
  const isGroupAdmin = useSelector(isUserOfRoleSelector)(
    'organization_group_admin'
  );
  const organization = useSelector(safeOrganizationSelector);
  const organizationGroup = useSelector(organizationGroupSelector);
  return organization
    ? isGroupAdmin
      ? {
          type: 'organization_group',
          organizationGroupUrl: organization.organizationGroup,
          name: organizationGroup?.name ?? '',
        }
      : {
          type: 'organization',
          organizationUrl: organization.url,
          name: organization.name,
        }
    : undefined;
};
