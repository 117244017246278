import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Pages } from '../../../types/pages';
import { RootActionType } from '../../rootActions';
import {
  clearAnnotationData,
  fetchAnnotationFulfilled,
  nextAnnotation,
} from '../annotation/actions';
import { logoutUser } from '../auth/actions';
import { leaveValidation } from '../ui/actions';

const initialState = Immutable<Pages>({
  pages: [],
  annotationUrl: undefined,
});

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAnnotationFulfilled): {
      // Don't reset pages when the annotation is periodically refetched
      if (
        state.annotationUrl !== action.payload.annotation.url &&
        action.payload.annotation.status !== 'importing'
      ) {
        return state
          .set('pages', action.payload.sideload.pages)
          .set('annotationUrl', action.payload.annotation.url);
      }
      return state;
    }

    case getType(logoutUser):
    case getType(nextAnnotation):
    case getType(leaveValidation):
    case getType(clearAnnotationData):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
