import { FieldLabel, FieldLabelProps } from '@rossum/rossum-ui/FieldLabel';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@rossum/ui/material';
import React, { ReactNode } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { LabeledFieldProps, SimpleControlProps } from '../utils';

type RadioGroupControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends string | number = string,
  TOption = string,
> = RadioGroupProps &
  SimpleControlProps<TFieldValues, TName> &
  LabeledFieldProps & {
    label?: React.ReactNode;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    disabled?: boolean;
    required?: boolean;
    options: TOption[];
    getOptionLabel?: (option: TOption) => ReactNode;
    getOptionValue?: (option: TOption) => TValue;
  };

const RadioGroupControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends string | number = string,
  TOption = string,
>({
  ControllerProps: { name, control },
  FieldLabelProps,
  label,
  disabled,
  required,
  options,
  labelPlacement = 'end',
  getOptionLabel = option => option as unknown as string,
  getOptionValue = option => option as unknown as TValue,
  ...radioGroupProps
}: RadioGroupControlProps<TFieldValues, TName, TValue, TOption>) => {
  const { id, ...restRadioGroupProps } = radioGroupProps;
  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: id ?? name,
    required,
    label,
    ...FieldLabelProps,
  };

  return (
    <FieldLabel {...resolvedFieldLabelProps}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState, formState }) => (
          <FormControl component="fieldset" hiddenLabel>
            <RadioGroup
              {...restRadioGroupProps}
              name={name}
              value={field.value}
              onChange={(_, val) => field.onChange(val)}
            >
              {options.map(option => (
                <FormControlLabel
                  disabled={disabled || formState.isSubmitting}
                  aria-disabled={disabled || formState.isSubmitting}
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  control={<Radio size="small" />}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                  label={getOptionLabel(option)}
                  labelPlacement={labelPlacement}
                />
              ))}
            </RadioGroup>
            {fieldState.invalid && (
              <FormHelperText error sx={{ mt: 0 }}>
                {fieldState.error?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </FieldLabel>
  );
};

export default RadioGroupControl;
