import { Annotation } from '@rossum/api-client/annotations';
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@rossum/ui/material';

type AnnotationInfoProps = {
  annotation: Annotation;
};

export const AnnotationInfo = ({ annotation }: AnnotationInfoProps) => (
  <List dense sx={{ py: 0 }}>
    <ListSubheader sx={{ textTransform: 'uppercase' }}>
      Basic information
    </ListSubheader>
    <ListItem>
      <ListItemText primary="Annotation status" secondary={annotation.status} />
    </ListItem>
  </List>
);
