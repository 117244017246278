import { alpha, CircularProgress, Stack } from '@rossum/ui/material';

type DisabledDocumentCardProps = {
  loading?: boolean;
};

export const DisabledDocumentCard = ({
  loading,
}: DisabledDocumentCardProps) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: theme =>
        alpha(
          theme.palette.mode === 'dark'
            ? theme.palette.common.black
            : theme.palette.common.white,
          0.5
        ),
    }}
  >
    {loading && <CircularProgress />}
  </Stack>
);
