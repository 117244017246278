import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { User } from '../models/user';
import { userSchema } from '../models/user.schema';
import { patchUserPayloadSchema } from './patch.schema';

export type PatchUserPayload = Partial<User>;

export const patch = (userId: ID, payload: PatchUserPayload) => {
  return {
    endpoint: `/users/${userId}`,
    method: 'PATCH',
    responseSchema: userSchema,
    payloadSchema: patchUserPayloadSchema,
    payload,
  } as const;
};
