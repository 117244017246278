import { styled } from '@rossum/ui/material';
import { clamp } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { usePageSpaceContext } from '../../../../components/DocumentPage/PageSpaceContext';
import { useDocumentStore } from '../../document-store/DocumentStore';
import { scaleFactorSelector } from '../../document-store/documentStoreSelectors';
import { useDraggableInteraction } from '../hooks/useDraggableInteraction';
import {
  applyPadding,
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
} from '../utils/geometry';
import { ResizeHandles } from './ResizeHandles';

/** Purely presentational - a general bounding box that can be moved around/resized/interacted with
 * on a page
 * Used in: DatapointBoundingBox, SuggestionBox, SearchResult, SuggestedOperationBox
 */
export type BoundingBoxProps = React.SVGAttributes<SVGRectElement> & {
  cursor?: string | undefined;
  position: Rectangle2DCoordinates;
  color?: string;
  outlineStyle?: 'solid' | 'dashed' | 'none';
  outlineColor?: string;
  canResize?: boolean;
  interactive?: boolean;
  // onMove and onMoveCancel should be unnecessary with new MagicLine
  // it is good API tho
  onMoveStart?: () => void;
  onMove?: (position: Rectangle2DCoordinates) => void;
  onMoveEnd?: (position: Rectangle2DCoordinates) => void;
  onMoveCancel?: () => void;
  onResizeStart?: () => void;
  onResize?: (position: Rectangle2DCoordinates) => void;
  onResizeEnd?: (position: Rectangle2DCoordinates) => void;
  onResizeCancel?: () => void;
  onPositionChanged?: () => void;
};

const StyledRectangle = styled('rect', {
  shouldForwardProp: propName =>
    propName !== 'outlineStyle' &&
    propName !== 'outlineColor' &&
    propName !== 'color',
})<{
  outlineStyle?: 'solid' | 'dashed' | 'none';
  outlineColor?: string;
  color?: string;
  cursor?: string;
}>(({ theme, cursor, outlineColor, outlineStyle, color }) => ({
  fillOpacity: 0.25,
  fill: color ?? 'transparent',
  stroke: outlineStyle !== 'none' ? outlineColor : 'transparent',
  strokeDasharray: outlineStyle === 'dashed' ? '4 4' : undefined,
  cursor: cursor ?? 'pointer',
  [`&:hover`]: {
    stroke: theme.palette.primary.dark,
    strokeDasharray: 'none',
  },
}));

export const BoundingBox = forwardRef<SVGRectElement, BoundingBoxProps>(
  (
    {
      outlineStyle,
      outlineColor,
      position,
      color,
      canResize,
      interactive,
      onMoveStart,
      onMove,
      onMoveEnd,
      onMoveCancel,
      onResizeStart,
      onResize,
      onResizeEnd,
      onResizeCancel,
      onPositionChanged,
      style,
      ...rectProps
    },
    ref
  ) => {
    const [draftPosition, setDraftPosition] = useState<
      BoundingBoxProps['position'] | null
    >(null);

    const currentPosition = draftPosition ?? position;
    useEffect(() => {
      onPositionChanged?.();
    }, [currentPosition, onPositionChanged]);

    const rectangle = rectangleFromCoordinates(currentPosition);

    const { pageWidth, pageHeight } = usePageSpaceContext();
    const scaleFactor = useDocumentStore(scaleFactorSelector);

    const padding = 1;
    const paddedRectangle = applyPadding(rectangle, padding);

    const { handleMouseDown } = useDraggableInteraction({
      onDragStart: () => {
        onMoveStart?.();
        return setDraftPosition(position);
      },
      onDragMove: diff => {
        const x1 = clamp(
          position[0] + diff.x,
          0,
          pageWidth - (position[2] - position[0])
        );

        const y1 = clamp(
          position[1] + diff.y,
          0,
          pageHeight - (position[3] - position[1])
        );

        const x2 = clamp(
          position[2] + diff.x,
          position[2] - position[0],
          pageWidth
        );

        const y2 = clamp(
          position[3] + diff.y,
          position[3] - position[1],
          pageHeight
        );

        const r = Math.round;
        const newPos: Rectangle2DCoordinates = [r(x1), r(y1), r(x2), r(y2)];

        onMove?.(newPos);
        setDraftPosition(newPos);
      },
      onDragEnd: diff => {
        if (draftPosition && (Math.abs(diff.x) > 0 || Math.abs(diff.y) > 0)) {
          onMoveEnd?.(draftPosition);
        }
        setDraftPosition(null);
      },
      onDragCancel: () => {
        onMoveCancel?.();
        setDraftPosition(null);
      },
    });

    const strokeWidth = 2;
    const cornerHandleRadius = 3 / scaleFactor;

    return (
      <>
        <StyledRectangle
          {...paddedRectangle}
          vectorEffect="non-scaling-stroke"
          pointerEvents={interactive ? 'all' : 'none'}
          ref={ref}
          color={color}
          outlineStyle={outlineStyle}
          outlineColor={outlineColor}
          strokeWidth={strokeWidth}
          rx={cornerHandleRadius}
          ry={cornerHandleRadius}
          style={style}
          onMouseDownCapture={canResize ? handleMouseDown : undefined}
          data-dragged={draftPosition === null ? undefined : true}
          {...rectProps}
        />
        {canResize && (
          <svg
            style={{ overflow: 'visible' }}
            viewBox={`0 0 ${paddedRectangle.width} ${paddedRectangle.height}`}
            {...paddedRectangle}
          >
            <ResizeHandles
              strokeWidth={strokeWidth}
              cornerHandleRadius={cornerHandleRadius}
              onResizeStart={onResizeStart}
              onResize={onResize}
              onResizeCancel={onResizeCancel}
              onResizeEnd={onResizeEnd}
              position={position}
              outlineColor={outlineColor}
              draftPosition={draftPosition}
              setDraftPosition={setDraftPosition}
            />
          </svg>
        )}
      </>
    );
  }
);
