import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createDatapoint } from '../../../redux/modules/datapoints/actions';
import styles from '../styles.module.sass';

type Props = {
  disableAdd: boolean;
  focused: boolean;
  parentIndex: number;
  resetBatchSelection: () => void;
};

const AddValueButton = ({
  disableAdd,
  focused,
  parentIndex,
  resetBatchSelection,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <div
      className={clsx(
        styles.ValueButton,
        focused && styles.AddValueButtonFocused
      )}
      onClick={() => {
        if (disableAdd) return;

        resetBatchSelection();
        dispatch(createDatapoint(parentIndex));
      }}
    >
      <div className={clsx(disableAdd && styles.DisabledText)}>
        {'+ '}
        <FormattedMessage id="components.documentValidation.footer.inputs.add.datapoint" />
      </div>
    </div>
  );
};

export default AddValueButton;
