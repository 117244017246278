import { endpoints } from '@rossum/api-client';
import { PurgePayload } from '@rossum/api-client/annotations';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';

export const usePurgeAnnotations = () => {
  return useMutation({
    mutationFn: (payload: PurgePayload) =>
      api.request(endpoints.annotations.purge(payload)),
  });
};
