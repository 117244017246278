// Generated by ts-to-zod
import { z } from 'zod';

const automationBlockerBaseSchema = z.object({
  occurrences: z.number(),
  documentOccurrences: z.number(),
  documentOccurrencesRel: z.number(),
  id: z.string().nullable(),
});

const annotationAutomationBlockerSchema = automationBlockerBaseSchema.and(
  z.object({
    level: z.literal('annotation'),
    type: z.union([
      z.literal('automation_disabled'),
      z.literal('is_duplicate'),
      z.literal('suggested_edit_present'),
      z.literal('error_message'),
      z.literal('human_confirmation_required'),
      z.literal('delete_recommendation_filename'),
      z.literal('delete_recommendation_page_count'),
      z.literal('delete_recommendation_field'),
      z.literal('extension'),
    ]),
  })
);

const datapointAutomationBlockerSchema = automationBlockerBaseSchema.and(
  z.object({
    level: z.literal('datapoint'),
    id: z.string(),
    type: z.union([
      z.literal('low_score'),
      z.literal('failed_checks'),
      z.literal('no_validation_sources'),
      z.literal('error_message'),
      z.literal('delete_recommendation_filename'),
      z.literal('delete_recommendation_page_count'),
      z.literal('delete_recommendation_field'),
      z.literal('extension'),
    ]),
  })
);

export const automationBlockerSchema = z.union([
  annotationAutomationBlockerSchema,
  datapointAutomationBlockerSchema,
]);
