export const replaceSubjectVariable = (
  subject: string,
  parentEmailSubject: string | undefined
) => {
  const parentEmailSubjectRegex = /{{(\s)*parent_email_subject(\s)*}}/g;

  return subject.replaceAll(
    parentEmailSubjectRegex,
    () => parentEmailSubject || ''
  );
};
