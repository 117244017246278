import { authInfoSchema } from '../models/authInfo.schema';
import { tokenPayloadSchema } from './token.schema';

export type TokenPayload = {
  scope?: string;
  maxTokenLifetimeS?: number;
  origin?: string;
};

export const token = (payload: TokenPayload) => {
  return {
    authorize: true,
    endpoint: `/auth/token`,
    method: 'POST',
    responseSchema: authInfoSchema,
    payloadSchema: tokenPayloadSchema,
    payload,
  } as const;
};
