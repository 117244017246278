import { MutableRefObject, useEffect } from 'react';

// MAYBE: Install react-use or similar to consume this from?
// Adds to Tomas Sorejs' point about having a library like that
type UseIntersectionObserverArgs = {
  root?: MutableRefObject<HTMLElement | null>;
  target: MutableRefObject<HTMLElement | null>;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
};

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: UseIntersectionObserverArgs) => {
  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return undefined;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};
