// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { dedicatedEngineSchemaContentSchema } from '../models/dedicatedEngineSchema.schema';

export const dedicatedEngineSchemaPredictPayloadSchema = z.object({
  trainingQueues: z.array(urlSchema),
});

export const dedicatedEngineSchemaPredictResponseSchema = z.object({
  content: dedicatedEngineSchemaContentSchema,
});
