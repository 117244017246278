import { Box, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import {
  LOG_ARROW_WIDTH_SPACING,
  LOG_PADDING_SPACING,
  logsColumns,
} from '../helpers';

export const ListHead = () => {
  const intl = useIntl();

  return (
    <Stack
      flexDirection="row"
      sx={{
        position: 'sticky',
        top: 0,
        pt: t => t.spacing(1),
        pl: LOG_PADDING_SPACING,
        pr: LOG_PADDING_SPACING + LOG_ARROW_WIDTH_SPACING,
        zIndex: 1,
      }}
    >
      {logsColumns.map(({ name, width, maxWidth }) => (
        <Box key={name} width={width} maxWidth={maxWidth}>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: `containers.settings.extensions.logs.${name}`,
            })}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
