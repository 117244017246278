import { SvgIconComponent } from '@rossum/ui/icons';
import { Button, Stack, Typography } from '@rossum/ui/material';

type ItemWrapperProps = {
  Icon: SvgIconComponent;
  iconProps?: Record<string, string | number>;
  label: string;
  isHovered?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  dataCy?: string;
};

const ItemWrapper = ({
  Icon,
  iconProps,
  label,
  isHovered,
  dataCy,
  onClick,
}: ItemWrapperProps) => (
  <Button
    onClick={onClick}
    data-cy={dataCy}
    sx={{
      px: 1.5,
      py: 0.88,
      height: '100%',
      borderRadius: 0,
      color: theme => theme.palette.primary.contrastText,
      backgroundColor: theme =>
        isHovered ? theme.palette.primary.dark : 'transparent',
      ':hover': {
        backgroundColor: theme => theme.palette.primary.dark,
      },
    }}
  >
    <Stack justifyContent="center" alignItems="center" spacing={0.25}>
      <Icon {...iconProps} />
      <Typography variant="caption" whiteSpace="nowrap">
        {label}
      </Typography>
    </Stack>
  </Button>
);

export default ItemWrapper;
