import { Close } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import { get, some } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ImmutableObject } from 'seamless-immutable';
import datapointStyles from '../../../../../components/Datapoint/style.module.sass';
import FooterCell from '../../../../../components/footerCell';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import footerStyles from '../../../../Footer/style.module.sass';
import styles from '../styles.module.sass';
import { HeaderInJsonSchemaEditor } from './HeaderInJsonSchemaEditor';
import { categorySwitch } from './Preview';

type Props = {
  closeFooter: () => void;
  currentPath: string[];
  schema: ImmutableObject<OriginalAnyDatapointSchema>;
  setCurrent: (path: string[]) => void;
  isCurrentPath: (path: string[]) => boolean;
};

const Footer = ({
  schema,
  closeFooter,
  currentPath,
  setCurrent,
  isCurrentPath,
}: Props) => {
  const columns = get(schema, 'children.children', []);
  const hasStretch = some(columns, { stretch: true });
  const globalStyle = { width: hasStretch ? '100%' : undefined };
  const getMultivaluePath = (index: number) => [
    ...currentPath,
    'children',
    'children',
    index.toString(),
  ];

  return (
    <div className={clsx(footerStyles.Footer, styles.Footer)}>
      <div className={clsx(footerStyles.Table, styles.Table)}>
        <HeaderInJsonSchemaEditor style={globalStyle} columns={columns} />
        <div
          style={{ ...globalStyle }}
          className={clsx(
            footerStyles.InputsContainerBase,
            footerStyles.InputsContainer
          )}
        >
          <div className={footerStyles.ChildWrapper}>
            <div
              className={clsx(
                styles.InputsContainerRowGroupTuple,
                styles.InputsContainerRowGroup
              )}
            >
              {columns.map(
                (
                  {
                    category,
                    children,
                    constraints,
                    format,
                    hidden,
                    id,
                    label,
                    max_occurrences,
                    min_occurrences,
                    options,
                    stretch,
                    type,
                    width,
                  }: ImmutableObject<OriginalAnyDatapointSchema>,
                  index: number
                ) => (
                  <FooterCell
                    width={width}
                    stretch={stretch}
                    label={label}
                    type={type ?? 'string'}
                    key={id}
                    uiFieldType={null}
                  >
                    <div
                      className={clsx(
                        datapointStyles.FooterDatapoint,
                        styles.FooterDatapoint,
                        type === 'number' && styles[`${type}Datapoint`]
                      )}
                    >
                      {categorySwitch({
                        category,
                        children,
                        closeCurrent: () => setCurrent(currentPath),
                        constraints,
                        format,
                        hidden,
                        id,
                        inFooter: true,
                        isCurrentPath,
                        label,
                        max_occurrences,
                        min_occurrences,
                        options,
                        path: getMultivaluePath(index),
                        setCurrent,
                        type,
                      })}
                    </div>
                  </FooterCell>
                )
              )}
            </div>
            <div className={footerStyles.RemoveButtonPlaceholder} />
          </div>
        </div>
      </div>
      <Button
        color="secondary"
        startIcon={<Close />}
        onClick={() => closeFooter()}
        className={styles.FooterCloseButton}
      >
        <FormattedMessage id="containers.queueSchema.components.queueSchema.footer.close" />
      </Button>
    </div>
  );
};

export default Footer;
