import { Layers } from '@rossum/ui/icons';
import { Stack, Tooltip } from '@rossum/ui/material';
import { ReactElement } from 'react';

type DatapointTooltipProps = {
  children: ReactElement;
  active: boolean;
  layerCount: number;
  label: string;
};

export const DatapointTooltip = ({
  children,
  active,
  layerCount,
  label,
}: DatapointTooltipProps) => (
  <Tooltip
    title={
      !active && layerCount > 1 ? (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Layers fontSize="inherit" />
          <span>{layerCount}</span>
        </Stack>
      ) : (
        ''
      )
    }
    arrow={false}
    disableInteractive
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 22],
          },
        },
      ],
    }}
  >
    <g>
      <Tooltip
        title={active ? '' : label}
        arrow={false}
        disableInteractive
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6],
              },
            },
          ],
        }}
      >
        {children}
      </Tooltip>
    </g>
  </Tooltip>
);
