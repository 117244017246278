import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { State } from '../../types/state';
import { CLITooltip } from './CLITooltip';

type FooterTableHeaderProps = {
  part: string;
  showGhostRow: boolean;
};

export const FooterTableHeader = ({
  part,
  showGhostRow,
}: FooterTableHeaderProps) => {
  const intl = useIntl();

  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  const numberOfPages = useSelector(
    (state: State) => state.annotation.pages?.length ?? 0
  );

  return (
    <Stack
      sx={{
        position: 'sticky',
        left: '24px',
        pl: 4,
        pr: 3,
        backgroundColor: theme => theme.palette.background.paper,
        width: 'fit-content',
        zIndex: 2,
        py: 2,
      }}
    >
      {showGhostRow ? (
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: 'components.documentValidation.footer.cliFirstRow',
            })}
          </Typography>
          {complexLineItemsEnabled ? <CLITooltip /> : null}
        </Stack>
      ) : part === 'manually-added' ? (
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'components.documentValidation.footer.manuallyAddedTableHeader',
          })}
        </Typography>
      ) : part !== 'all' && numberOfPages > 1 ? (
        <Typography variant="h6">
          {intl.formatMessage(
            {
              id: 'components.documentValidation.footer.pageTableHeader',
            },
            { pageNumber: part }
          )}
        </Typography>
      ) : null}
    </Stack>
  );
};
