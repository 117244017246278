import { ReactNode } from 'react';
import SidebarWrapper from '../../../components/Sidebar/Sidebar';

type Props = {
  children: ReactNode;
};

const Sidebar = ({ children }: Props) => (
  <SidebarWrapper>{children}</SidebarWrapper>
);

export default Sidebar;
