import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Organization } from '../models/organization';
import { organizationSchema } from '../models/organization.schema';

export type PatchOrganizationPayolad = Partial<Organization>;

export const patch = (
  organizationId: ID,
  payload: PatchOrganizationPayolad
) => {
  return {
    endpoint: `/organizations/${organizationId}`,
    method: 'PATCH',
    responseSchema: organizationSchema,
    payloadSchema: organizationSchema.partial(),
    payload,
  } as const;
};
