import { useCallback, useEffect, useState } from 'react';

type UseScrollToElementOnLoadProps = {
  behavior: 'auto' | 'smooth';
  resetAutomatically?: boolean;
};

export const useScrollToElementOnLoad = ({
  behavior,
  resetAutomatically = false,
}: UseScrollToElementOnLoadProps) => {
  const [scrolled, setScrolled] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const reset = useCallback(() => {
    setRef(null);
    setScrolled(false);
  }, []);

  useEffect(() => {
    if (ref !== null && !scrolled) {
      ref.scrollIntoView({ behavior });
      setScrolled(true);

      // make state ready for next scroll after 2 seconds after every scroll
      if (resetAutomatically) {
        setTimeout(() => {
          reset();
        }, 2000);
      }
    }
  }, [behavior, ref, reset, resetAutomatically, scrolled]);

  return { refCallback: setRef, reset, scrolled };
};
