// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { functionRuntimeSchema } from './hookUtils.schema';

export const scheduleConfigSchema = z.object({
  cron: z.string().optional(),
});

export const appDisplayModeSchema = z.union([
  z.literal('fullscreen'),
  z.literal('drawer'),
]);

export const appConfigSchema = z.object({
  url: urlSchema,
  settings: z.unknown().optional(),
  displayMode: appDisplayModeSchema.optional(),
});

export const webhookConfigSchema = z
  .object({
    private: z.boolean(),
    url: urlSchema,
    insecureSsl: z.boolean(),
    clientSslCertificate: z.string().nullable(),
    clientSslKey: z.string().nullable(),
    secret: z.string().nullable(),
    app: appConfigSchema.nullable(),
    schedule: scheduleConfigSchema,
    payloadLoggingEnabled: z.boolean(),
  })
  .partial();

export const functionConfigSchema = z
  .object({
    private: z.boolean(),
    runtime: functionRuntimeSchema,
    code: z.string(),
    schedule: scheduleConfigSchema,
    payloadLoggingEnabled: z.boolean(),
    app: appConfigSchema.nullable(),
  })
  .partial();
