import { create } from 'zustand';
import {
  documentBboxStoreSlice,
  DocumentBboxStoreType,
} from './documentBboxStoreSlice';
import {
  documentGeometryStoreSlice,
  DocumentGeometryStoreType,
} from './documentGeometryStoreSlice';

export type DocumentStoreType = DocumentGeometryStoreType &
  DocumentBboxStoreType;

export const createDocumentStore = () =>
  create<DocumentStoreType>()((...args) => ({
    ...documentBboxStoreSlice(...args),
    ...documentGeometryStoreSlice(...args),
  }));
