import { Alert, AlertTitle } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

const RuntimeChangeAlert = () => {
  const intl = useIntl();
  return (
    <Alert variant="filled" severity="error" sx={{ fontWeight: 'normal' }}>
      <AlertTitle>
        {intl.formatMessage({
          id: 'containers.settings.extensions.function.runtime.changeWarning.title',
        })}
      </AlertTitle>
      {intl.formatMessage({
        id: 'containers.settings.extensions.function.runtime.changeWarning.message',
      })}
    </Alert>
  );
};

export default RuntimeChangeAlert;
