import { pipe } from 'fp-ts/lib/function';
import { mapValues } from 'lodash/fp';
import { stringify as _stringify, StringifyOptions } from 'query-string';

const stringify =
  (options: StringifyOptions) => (obj: { [key: string]: unknown }) =>
    _stringify(obj, options);

export const encodeObjectToQuery = (obj?: { [key: string]: unknown } | null) =>
  pipe(
    obj,
    mapValues(value =>
      typeof value === 'object' && value !== null && !Array.isArray(value)
        ? JSON.stringify(value)
        : value
    ),
    stringify({
      arrayFormat: 'comma',
      sort: false,
      skipNull: true,
      skipEmptyString: true,
    })
  );
