import { endpoints } from '@rossum/api-client';
import { DedicatedEngineSchemaCreatePayload } from '@rossum/api-client/dedicatedEngineSchema';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const useCreateDedicatedEngineSchema = () => {
  return useMutation({
    mutationFn: (engineSchemaModel: DedicatedEngineSchemaCreatePayload) =>
      api.request(endpoints.engineSchemas.create(engineSchemaModel)),
  });
};
