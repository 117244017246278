import { useUnpaginatedUsers } from '../../../business/users/useUnpaginatedUsers';
import { toDict } from '../helpers';

// this hook is to have a single query key for fetching all the user for filtering purposes
export const useFetchUsers = (params?: { enabled?: boolean }) => {
  return useUnpaginatedUsers(
    { deleted: false, pageSize: 100 },
    {
      select: toDict,
      enabled: params?.enabled,
    }
  );
};
