const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <path
      fill="currentColor"
      d="M26.0740741,3.69482223e-12 C40.4743876,3.69482223e-12 52.1481481,11.6737606 52.1481481,26.0740741 C52.1481481,32.9147128 49.5138787,39.1400849 45.2044348,43.7910954 L63.5219216,62.107708 L62.107708,63.5219216 L43.7910954,45.2044348 C39.1400849,49.5138787 32.9147128,52.1481481 26.0740741,52.1481481 C11.6737606,52.1481481 -8.52651283e-13,40.4743876 -8.52651283e-13,26.0740741 C-8.52651283e-13,11.6737606 11.6737606,3.69482223e-12 26.0740741,3.69482223e-12 Z M26.0740741,2 C12.7783301,2 2,12.7783301 2,26.0740741 C2,39.3698181 12.7783301,50.1481481 26.0740741,50.1481481 C39.3698181,50.1481481 50.1481481,39.3698181 50.1481481,26.0740741 C50.1481481,12.7783301 39.3698181,2 26.0740741,2 Z"
    />
  </svg>
);

export default SearchIcon;
