import { endpoints } from '@rossum/api-client';
import { DedicatedEngineListQuery } from '@rossum/api-client/dedicatedEngines';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { getNextPageFromUrl } from '../helpers/getNextPageFromUrl';
import { dedicatedEngineKeys } from '../keys';

export const useInfiniteDedicatedEngines = (query: DedicatedEngineListQuery) =>
  useInfiniteQuery({
    queryKey: dedicatedEngineKeys.list(query),
    queryFn: ({ pageParam = 1 }) =>
      api.request(
        endpoints.dedicatedEngines.list({
          ...query,
          page: pageParam,
        })
      ),
    getNextPageParam: data => getNextPageFromUrl(data.pagination.next),
  });
