import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { toHumanString } from '../../../lib/helpers';
import { grayLight } from '../constants';
import CategoriesTooltip from '../Tooltips/CategoriesTooltip';

type StackedBarGraphDataValues<K extends string> = Partial<Record<K, number>>;

export type DataType<K extends string> = StackedBarGraphDataValues<K> & {
  date: string;
};

export type BarT<K> = { name: K; color: string };

type StackedBarGraphContentProps<K extends string> = {
  bars: Array<BarT<K>>;
  data: Array<DataType<K>>;
  formatLabel?: (s: K) => string;
};

const StackedBarGraphContent = <K extends string>({
  data,
  bars,
  formatLabel,
}: StackedBarGraphContentProps<K>) => (
  <ResponsiveContainer height="70%">
    <BarChart data={data}>
      <CartesianGrid vertical={false} strokeWidth={0.3} />
      <XAxis tickSize={0} tickMargin={20} dataKey="date" />
      <YAxis
        tickFormatter={toHumanString}
        tickSize={0}
        tickMargin={12}
        axisLine={false}
      />
      {formatLabel && (
        <Tooltip<number, K>
          content={({ payload }) => (
            <CategoriesTooltip
              payload={payload?.map(({ name, value, color }) => ({
                color,
                value,
                name: name ? formatLabel(name) : '-',
              }))}
            />
          )}
          cursor={{ fill: grayLight, opacity: 0.5 }}
        />
      )}
      {bars.map(({ name: _name, color }) => (
        <Bar
          key={_name}
          barSize={32}
          fill={color}
          stackId="1"
          isAnimationActive={false}
          dataKey={_name}
        />
      ))}
    </BarChart>
  </ResponsiveContainer>
);

export default StackedBarGraphContent;
