import { Switch } from '@rossum/ui/material';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

const ControlledToggle = <T extends FieldValues>({
  name,
  control,
  dataCy,
  disabled,
}: {
  control: Control<T>;
  name: Path<T>;
  dataCy?: string;
  disabled?: boolean;
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <Switch
      data-cy={dataCy || name}
      checked={value as boolean}
      disabled={disabled}
      onChange={(_, active) => onChange(active)}
    />
  );
};

export default ControlledToggle;
