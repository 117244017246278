import { endpoints } from '@rossum/api-client';
import { EmailTemplatePatchPayload } from '@rossum/api-client/emailTemplates';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const usePatchEmailTemplate = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: ({
      emailTemplateId,
      emailTemplatePatchModel,
    }: {
      emailTemplateId: number;
      emailTemplatePatchModel: EmailTemplatePatchPayload;
    }) =>
      api.request(
        endpoints.emailTemplates.patch(emailTemplateId, emailTemplatePatchModel)
      ),
  });
};
