import { Paper } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const PAPPER_BOX_PADDING = 1.5;
const PAPPER_BOX_WIDTH = 760;

type PaperBoxProps = {
  children: ReactNode;
};

const PaperBox = ({ children }: PaperBoxProps) => (
  <Paper
    sx={{
      maxWidth: PAPPER_BOX_WIDTH,
      padding: PAPPER_BOX_PADDING,
      margin: '0 auto',
    }}
  >
    {children}
  </Paper>
);

export default PaperBox;
