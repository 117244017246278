import AlertCircle from 'mdi-react/AlertCircleIcon';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';

type Props = {
  schemaConceptExists: boolean;
};

const SchemaAlert = ({ schemaConceptExists }: Props) => (
  <div className={styles.AlertWrapper}>
    <div className={styles.AlertCircle}>
      <AlertCircle />
    </div>
    <div className={styles.AlertTitle}>
      <FormattedMessage id="containers.queueSchema.components.schemaAlert" />
    </div>
    <FormattedMessage
      id={`containers.queueSchema.components.schemaAlert.${
        schemaConceptExists ? 'jsonError' : 'start'
      }`}
    />
  </div>
);

export default SchemaAlert;
