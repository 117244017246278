import { Inbox } from '@rossum/api-client/inboxes';
import { ActionType, createAction } from 'typesafe-actions';
import { Url } from '../../../types/basic';
import { InboxParams } from '../../../types/inbox';

export const createInboxFulfilled = createAction(
  'CREATE_INBOX_FULFILLED',
  (payload: Inbox, _createdWithQueue: boolean, _queueUrl: Url) => payload,
  (_payload: Inbox, createdWithQueue: boolean, queueUrl: Url) => ({
    createdWithQueue,
    queueUrl,
  })
)<Inbox, { createdWithQueue: boolean; queueUrl: Url }>();

export const updateInboxAction = createAction(
  'UPDATE_INBOX',
  (inbox: InboxParams, _inboxUrl: Url) => ({
    inbox,
  }),
  (_inbox, inboxUrl) => ({
    inboxUrl,
  })
)<{ inbox: InboxParams }, { inboxUrl: Url }>();

export const updateInboxFulfilled = createAction(
  'UPDATE_INBOX_FULFILLED',
  (inbox: Inbox) => ({
    inbox,
  })
)<{ inbox: Inbox }>();

export const setCreatingInboxAction = createAction(
  'SET_CREATING_INBOX',
  (creatingInbox: boolean) => creatingInbox
)<boolean>();

export type InboxActions = ActionType<
  | typeof createInboxFulfilled
  | typeof setCreatingInboxAction
  | typeof updateInboxAction
  | typeof updateInboxFulfilled
>;
