import { WarningAmber } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';

const FailedExportButton = () => (
  <Button
    variant="outlined"
    color="secondary"
    size="large"
    disabled
    data-cy="confirm-button-read-only"
    sx={{ width: '100%' }}
  >
    <WarningAmber />
  </Button>
);
export default FailedExportButton;
