// Generated by ts-to-zod
import { z } from 'zod';

export const emailTemplateListQuerySchema = z.object({
  pageSize: z.number().optional(),
  page: z.number().optional(),
  fields: z.array(z.string()).optional(),
  ordering: z.array(z.string()).optional(),
  queue: z.number().optional(),
});
