import { useIntl } from 'react-intl';

const DocumentFileName = ({
  originalFileName,
}: {
  originalFileName: string | null | undefined;
}) => {
  const intl = useIntl();

  const _originalFileName =
    originalFileName ??
    intl.formatMessage({ id: 'containers.documents.missingFileName' });

  return <>{_originalFileName}</>;
};

export default DocumentFileName;
