// Generated by ts-to-zod
import { z } from 'zod';

export const changePasswordPayloadSchema = z.object({
  new_password1: z.string(),
  new_password2: z.string(),
  old_password: z.string(),
});

export const changePasswordResponseSchema = z.object({
  detail: z.string(),
});
