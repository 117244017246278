import { CONTROL_BAR_DISTANCE } from './constants';
import { SeparatorContainer } from './SeparatorContainer';
import { SeparatorLine } from './SeparatorLine';

export type TemporaryRulerProps = {
  orientation: 'horizontal' | 'vertical';
  position: number;
};

export const TemporaryRuler = ({
  orientation,
  position,
}: TemporaryRulerProps) => {
  const primaryPos = orientation === 'horizontal' ? 'top' : 'left';

  const secondaryPos = orientation === 'horizontal' ? 'left' : 'top';

  const primaryDim = orientation === 'horizontal' ? 'width' : 'height';

  return (
    <SeparatorContainer
      orientation={orientation}
      style={{
        [primaryPos]: position,
        pointerEvents: 'none',
      }}
    >
      <SeparatorLine
        orientation={orientation}
        deleteButtonHovered={false}
        willBeDeletedByResize={false}
        isActive={false}
        isDragging={false}
        disabled={false}
        interactive
        sx={{
          backgroundColor: theme => theme.palette.primary.main,
          pointerEvents: 'none',
          [primaryDim]: `calc(100% + ${CONTROL_BAR_DISTANCE}px)`,
          [secondaryPos]: -CONTROL_BAR_DISTANCE,
        }}
      />
    </SeparatorContainer>
  );
};
