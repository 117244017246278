import { Button, Stack, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import TrashIcon from 'mdi-react/TrashIcon';
import { useIntl } from 'react-intl';
import { Url } from '../../../../types/basic';
import { Extension } from '../../../../types/extensions';
import PaperBoxFooter from '../../../../ui/paper-box/PaperBoxFooter';
import styles from '../../style.module.sass';

type OpenDeleteModal = (_values: {
  url: Url;
  name: string;
  queues: Array<Url>;
}) => void;

type ExtensionSettingsFooterProps = {
  selectedExtension: Extension;
  openDeleteModal: OpenDeleteModal;
};

export const ExtensionSettingsFooter = ({
  selectedExtension,
  openDeleteModal,
}: ExtensionSettingsFooterProps) => {
  const intl = useIntl();
  return (
    <PaperBoxFooter>
      <div className={clsx(styles.WidthLimit, styles.DeleteFooter)}>
        <Stack direction="row" spacing={2}>
          <div className={styles.DeleteInfoIcon}>
            <TrashIcon />
          </div>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">
              {intl.formatMessage({
                id: 'containers.settings.extensions.deleteExtension',
              })}
            </Typography>
            <Typography variant="caption" color="text.disabled">
              {intl.formatMessage({
                id: 'containers.settings.extensions.deleteExtensionText',
              })}
            </Typography>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          color="error"
          disabled={get(selectedExtension, 'status') === 'pending'}
          onClick={() =>
            openDeleteModal({
              url: selectedExtension.url,
              queues: selectedExtension.queues,
              name: selectedExtension.name,
            })
          }
          data-cy="extensions-delete-extension"
        >
          {intl.formatMessage({
            id: 'containers.settings.extensions.deleteExtension',
          })}
        </Button>
      </div>
    </PaperBoxFooter>
  );
};
