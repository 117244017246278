import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngineSchemaContent } from '../models/dedicatedEngineSchema';
import {
  dedicatedEngineSchemaPredictPayloadSchema,
  dedicatedEngineSchemaPredictResponseSchema,
} from './predict.schema';

export type DedicatedEngineSchemaPredictPayload = {
  trainingQueues: Url[];
};

export type DedicatedEngineSchemaPredictResponse = {
  content: DedicatedEngineSchemaContent;
};

export const predict = (payload: DedicatedEngineSchemaPredictPayload) => {
  return {
    endpoint: `/dedicated_engine_schemas/predict`,
    method: 'POST',
    responseSchema: dedicatedEngineSchemaPredictResponseSchema,
    payload,
    payloadSchema: dedicatedEngineSchemaPredictPayloadSchema,
  } as const;
};
