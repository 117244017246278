import { endpoints, ID } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import { StatusTabArray, StatusTabKeys } from '../tabs';

export const QUERY_KEY_STATUS_COUNTS = 'status-tab-counts';

type StatusTabCount = Partial<
  Record<StatusTabKeys, { validCount: number; invalidCount: number }>
>;

export const useStatusTabCounts = ({
  queueId,
  statusTabs,
}: {
  queueId: ID;
  statusTabs: StatusTabArray;
}) => {
  return useQuery({
    queryKey: [QUERY_KEY_STATUS_COUNTS, queueId] as const,
    queryFn: ({ queryKey }) => api.request(endpoints.queues.get(queryKey[1])),
    select: ({ counts }) => {
      return statusTabs.reduce<StatusTabCount>(
        (acc, statusTab) =>
          Object.assign(acc, {
            [statusTab.key]: {
              validCount: statusTab.getValidCount(counts),
              invalidCount: statusTab.getInvalidCount(counts),
            },
          }),
        {}
      );
    },
    refetchInterval: 10000,
    // if we want to call this hook conditionally we pass zero as queueId
    enabled: !!queueId,
  });
};
