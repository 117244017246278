import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { AutomationDatapoint } from '../models/automation';
import { AutomationBlocker } from '../models/automationBlocker';
import {
  automationPayloadSchema,
  automationResponseSchema,
} from './automation.schema';

export type AutomationPayload = {
  beginDate: string;
  endDate: string;
};

export type AutomationResponse = {
  beginDate: string;
  endDate: string;
  queue: Url;
  automationBlockers: AutomationBlocker[];
  datapoints: AutomationDatapoint[];
  totals: {
    documentCount: number;
    automation: number;
  };
};

export const automation = (queueId: number, payload: AutomationPayload) => {
  return {
    endpoint: `/queues/${queueId}/automation`,
    method: 'POST',
    payloadSchema: automationPayloadSchema,
    payload,
    responseSchema: automationResponseSchema,
  } as const;
};
