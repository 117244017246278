import { alpha, Components, Theme } from '../../material';

export const muiTooltip = (theme: Theme): Components['MuiTooltip'] => ({
  defaultProps: {
    arrow: true,
    // All additional props passed to Tooltip are assigned to the children element.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    'data-tooltip-trigger': true,
  },
  // TODO: Fix this when we have elevation defined
  styleOverrides: {
    tooltip: {
      fontSize: theme.typography.pxToRem(10),
      textAlign: 'center',
      boxShadow:
        '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
      backgroundColor: '#24252B',
      backgroundImage: `linear-gradient(${alpha('#FFF', 0.16)},${alpha(
        '#FFF',
        0.16
      )})`,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    arrow: {
      color: '#47484d',
    },
  },
});
