// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const defaultEmailTemplateTypeSchema = z.union([
  z.literal('rejection_default'),
  z.literal('email_with_no_processable_attachments'),
]);

export const customEmailTemplateTypeSchema = z.union([
  z.literal('rejection'),
  z.literal('custom'),
]);

export const emailTemplateTypeSchema = z.union([
  customEmailTemplateTypeSchema,
  defaultEmailTemplateTypeSchema,
]);

export const emailSendPayloadAddressSchema = z.object({
  email: z.string(),
  name: z.string().optional(),
});

export const emailTemplateValuesSchema = z.object({
  subject: z.string(),
  message: z.string(),
  bcc: z.array(emailSendPayloadAddressSchema).optional(),
  to: z.array(emailSendPayloadAddressSchema).optional(),
  cc: z.array(emailSendPayloadAddressSchema).optional(),
});

export const emailTemplatePayloadBaseSchema = z.object({
  name: z.string(),
  queue: z.string(),
  subject: z.string(),
  message: z.string(),
  automate: z.boolean(),
  bcc: z.array(emailSendPayloadAddressSchema),
  to: z.array(emailSendPayloadAddressSchema),
  cc: z.array(emailSendPayloadAddressSchema),
});

export const defaultEmailTemplateSchema = z
  .object({
    id: idSchema,
    url: urlSchema,
    name: z.string(),
    queue: urlSchema,
    type: defaultEmailTemplateTypeSchema,
    automate: z.boolean(),
    triggers: z.array(urlSchema),
  })
  .and(emailTemplateValuesSchema);

export const customEmailTemplateSchema = z
  .object({
    id: idSchema,
    url: urlSchema,
    name: z.string(),
    queue: urlSchema,
    type: customEmailTemplateTypeSchema,
    automate: z.boolean(),
    triggers: z.array(urlSchema),
  })
  .and(emailTemplateValuesSchema);

export const emailTemplateSchema = z.union([
  customEmailTemplateSchema,
  defaultEmailTemplateSchema,
]);
