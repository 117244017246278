import { createRef } from 'react';
import * as R from 'remeda';
import { StateCreator } from 'zustand';
import {
  IDENTITY_MATRIX_2D,
  Point2D,
  Rectangle2D,
  Vector2D,
} from '../document-canvas/utils/geometry';
import {
  Dimensions,
  findRectangleCenter,
  findViewportCoordinates,
  fitWithinCanvas,
  isRectangleInside,
  translateBy,
  zoomBy,
} from './documentGeometry';
import { CanvasState, stateToMatrix } from './helpers';

export const ZOOM_SPEED = 0.07;

export type DocumentGeometryState = {
  /** Holds reference to the root `svg` element which simulates user viewport */
  viewportRef: React.MutableRefObject<SVGSVGElement | null>;
  /** Holds reference to the main `g` element to which we are _drawing_ all pages (hence canvas) */
  canvasRef: React.MutableRefObject<SVGGElement | null>;
  /** Describes linear transformations applied to the canvas (translation, scaling) */
  canvasState: CanvasState;
  /** Describes offset of canvas and viewport and implicit zoom */
  viewportState: CanvasState;
  /** Describes the width / height of the canvas */
  viewportDimensions: Dimensions;
};

// TODO: Make these actual _derived state_ using middlewares?
export type DocumentGeometryGetters = {
  /** Returns a matrix representation of `canvasState` */
  getCanvasStateMatrix: () => DOMMatrix;
  /** Returns a matrix representation of `canvasState` */
  getViewportStateMatrix: () => DOMMatrix;
  // TODO: Could they be the same?
  /** Returns a SVG CTM applied to canvas (not necessarily the same as `canvasStateMatrix`)  */
  getCanvasCTM: () => DOMMatrix;
};

export type DocumentGeometryActions = {
  /** Zooms document by `delta` centered around `origin` (center of the viewport by default)  */
  zoomBy: (
    canvasDimensions: Dimensions
  ) => (delta: number, origin: Point2D | undefined) => void;
  setViewportState: (viewportState: CanvasState) => void;
  setViewportDimensions: (viewportState: Dimensions) => void;
  resetZoom: (canvasDimensions: Dimensions) => () => void;
  // TODO: `canvasDimensions` could probably be stored inside the store, this is weird API
  translateBy: (canvasDimensions: Dimensions) => (delta: Vector2D) => void;
  translateTo: (position: Partial<Point2D>) => void;
  translateIntoViewport: (
    canvasDimensions: Dimensions
  ) => (rectangle: Rectangle2D | undefined) => void;
  translateGridIntoViewport: (
    canvasDimensions: Dimensions
  ) => (topLeftCorner: Point2D | undefined) => void;
};

export type DocumentGeometryStoreType = DocumentGeometryState &
  DocumentGeometryGetters &
  DocumentGeometryActions;

export const documentGeometryStoreSlice: StateCreator<
  DocumentGeometryStoreType,
  [],
  [],
  DocumentGeometryStoreType
> = (set, get) => ({
  viewportRef: createRef(),
  canvasRef: createRef(),
  viewportDimensions: {
    width: 0,
    height: 0,
  },
  canvasState: {
    translateX: 0,
    translateY: 0,
    zoomLevel: 1,
  },
  viewportState: {
    translateX: 0,
    translateY: 0,
    zoomLevel: 1,
  },

  getCanvasCTM: () =>
    get().canvasRef.current?.getScreenCTM() ?? IDENTITY_MATRIX_2D,
  getCanvasStateMatrix: () => stateToMatrix(get().canvasState),
  getViewportStateMatrix: () => stateToMatrix(get().viewportState),

  // Actions
  setViewportState: viewportState => {
    set({ viewportState });
  },

  // Actions
  setViewportDimensions: viewportDimensions => {
    set({ viewportDimensions });
  },

  zoomBy: canvasDimensions => (delta, origin) => {
    set(({ canvasState, viewportDimensions }) => {
      const originalScaleFactor = get().getCanvasCTM().inverse().a;

      const produceCenterOrigin = R.piped(
        findViewportCoordinates({ originalScaleFactor, viewportDimensions }),
        findRectangleCenter
      );

      const produceCanvasState = R.piped(
        zoomBy(delta, origin ?? produceCenterOrigin(canvasState)),
        fitWithinCanvas({
          originalState: canvasState,
          originalScaleFactor,
          canvasDimensions,
          viewportDimensions,
        })
      );

      return { canvasState: produceCanvasState(canvasState) };
    });
  },
  resetZoom: canvasDimensions => () => {
    const { viewportDimensions } = get();

    const desiredZoom = Math.min(
      canvasDimensions.width / viewportDimensions.width,
      1
    );

    get().zoomBy(canvasDimensions)(
      // Inverse to ratio calculation in `zoomBy`.
      -1 + desiredZoom / get().canvasState.zoomLevel,
      undefined
    );
  },
  translateBy: canvasDimensions => delta => {
    set(({ canvasState, viewportDimensions }) => {
      const produceCanvasState = R.piped(
        translateBy(delta),
        fitWithinCanvas({
          originalState: canvasState,
          originalScaleFactor: get().getCanvasCTM().inverse().a,
          canvasDimensions,
          viewportDimensions,
        })
      );

      return { canvasState: produceCanvasState(canvasState) };
    });
  },
  translateTo: position => {
    set(({ canvasState }) => {
      const produceCanvasState = (previousState: CanvasState) => {
        return {
          ...previousState,
          ...(position.x !== undefined ? { translateX: position.x } : {}),
          ...(position.y !== undefined ? { translateY: position.y } : {}),
        };
      };

      return { canvasState: produceCanvasState(canvasState) };
    });
  },
  translateGridIntoViewport: canvasDimensions => topLeftCorner => {
    set(({ canvasState, viewportDimensions }) => {
      const originalScaleFactor = get().getCanvasCTM().inverse().a;

      const viewportCoordinates = findViewportCoordinates({
        originalScaleFactor,
        viewportDimensions,
      })(canvasState);

      if (!topLeftCorner) {
        return {};
      }

      const translatedState = {
        translateX:
          -(topLeftCorner.x - viewportCoordinates.width * (1 / 4)) *
          canvasState.zoomLevel,
        translateY:
          -(topLeftCorner.y - viewportCoordinates.height * (1 / 3)) *
          canvasState.zoomLevel,
        zoomLevel: canvasState.zoomLevel,
      };

      return {
        canvasState: fitWithinCanvas({
          originalState: canvasState,
          originalScaleFactor,
          canvasDimensions,
          viewportDimensions,
        })(translatedState),
      };
    });
  },
  translateIntoViewport: canvasDimensions => rectangleCoordinates => {
    // TODO Can be written more concisely & separate into a helper
    set(({ canvasState, viewportDimensions }) => {
      const originalScaleFactor = get().getCanvasCTM().inverse().a;

      const viewportCoordinates = findViewportCoordinates({
        originalScaleFactor,
        viewportDimensions,
      })(canvasState);

      if (!rectangleCoordinates) {
        return {};
      }

      const translatedState = {
        translateX:
          -(
            findRectangleCenter(rectangleCoordinates).x -
            viewportCoordinates.width / 2
          ) * canvasState.zoomLevel,
        translateY:
          -(
            findRectangleCenter(rectangleCoordinates).y -
            viewportCoordinates.height / 2
          ) * canvasState.zoomLevel,
        zoomLevel: canvasState.zoomLevel,
      };

      return isRectangleInside(rectangleCoordinates)(viewportCoordinates)
        ? {}
        : {
            canvasState: fitWithinCanvas({
              originalState: canvasState,
              originalScaleFactor,
              canvasDimensions,
              viewportDimensions,
            })(translatedState),
          };
    });
  },
});
