import {
  ContentCutRounded,
  Delete,
  RotateLeft,
  RotateRight,
} from '@rossum/ui/icons';
import {
  Box,
  ChipProps,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
} from '@rossum/ui/material';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { DragHandleProps } from '../../components/Dnd/SortableWrapper';
import { DocumentPage } from './DocumentPage';
import { EditedPageActions } from './EditedPageActions';
import { DispatchEdit, EditDocumentConfig, Page } from './editState';
import { ReactComponent as SplitCancel } from './icons/split-cancel.svg';
import { UiStateTuple } from './uiState';
import { useLastRotation } from './useLastRotation';

const getEditIndicationForPageChip = (page: Page): ChipProps => {
  const deletedFlagChanged = page.initialDeleted !== page.deleted;
  const rotationDeltaFromInitialState =
    page.rotationDeg - page.initialRotationDeg;

  if (deletedFlagChanged) {
    return {
      color: 'info',
      // TODO change icon to delete cancel when we undeleted a page
      icon: <Delete fontSize="small" />,
    };
  }

  if (rotationDeltaFromInitialState !== 0) {
    return {
      color: 'info',
      icon:
        Math.abs(rotationDeltaFromInitialState) > 180 ? (
          <RotateLeft fontSize="small" />
        ) : (
          <RotateRight fontSize="small" />
        ),
    };
  }

  return {};
};

export const EditedPage = memo(
  ({
    page,
    dispatchEdit,
    dragHandleAttributes,
    dragHandleListeners,
    isLastPage,
    isFollowedByEditablePart,
    partIndex,
    uiState: [uiState, setUiState],
    mode,
    editable,
    config,
    followingPageNumber,
  }: {
    page: Page;
    dispatchEdit: DispatchEdit;
    dragHandleAttributes?: DragHandleProps['attributes'];
    dragHandleListeners?: DragHandleProps['listeners'];
    isLastPage: boolean;
    isFollowedByEditablePart: boolean;
    partIndex: number;
    uiState: UiStateTuple;
    mode: 'dragged-page' | 'placeholder-for-dragged-page' | 'not-dragged-page';
    editable: boolean;
    config: EditDocumentConfig;
    followingPageNumber?: number;
  }) => {
    const lastCssRotation = useLastRotation(page.rotationDeg);

    const showSplitButton =
      !config.settings.disableSplitting &&
      uiState.draggedPage == null &&
      (!isLastPage || isFollowedByEditablePart) &&
      editable;

    const showMergeButton =
      !config.settings.disableSplitting &&
      editable &&
      isLastPage &&
      isFollowedByEditablePart;

    return (
      <Stack
        direction="row"
        key={page.pageNumber}
        // TODO get rid of mb
        sx={{
          width: '150px',
          height: '182px',
          mb: 2,
          position: 'relative',
        }}
        onClick={() =>
          setUiState(s => ({ ...s, currentPageNumber: page.pageNumber }))
        }
      >
        <Box sx={{ flex: 1 }}>
          <DocumentPage
            pageNumber={page.pageNumber}
            key={page.pageNumber}
            isCurrent={page.pageNumber === uiState.currentPageNumber}
            onClick={() =>
              setUiState(s => ({ ...s, currentPageNumber: page.pageNumber }))
            }
            rotationDeg={lastCssRotation.current}
            blackOverlay={
              !editable ||
              mode === 'placeholder-for-dragged-page' ||
              page.deleted
                ? 0.5
                : 0
            }
            overlay={
              mode === 'not-dragged-page' &&
              editable && (
                <EditedPageActions page={page} dispatchEdit={dispatchEdit} />
              )
            }
            imageContainerProps={{
              ...dragHandleAttributes,
              ...dragHandleListeners,
            }}
            pageChipProps={getEditIndicationForPageChip(page)}
          />
        </Box>
        <Stack
          // merging is only possible in a forwards direction
          // the last page of the last part can not be merged with anything
          sx={{
            ...(showMergeButton
              ? {}
              : showSplitButton
                ? {
                    opacity: 0,
                    '&:hover': {
                      opacity: showSplitButton ? 1 : 0,
                      transition: theme =>
                        `opacity ${theme.transitions.duration.shortest}ms ease-in-out`,
                    },
                  }
                : { visibility: 'hidden' }),
          }}
          justifyContent="center"
          alignItems="center"
          // to align the icon centrally
          height={0.8}
        >
          {showMergeButton ? (
            <>
              <Divider orientation="vertical" sx={{ flex: 0.4 }} />
              <IconButton
                onClick={() => {
                  dispatchEdit({
                    type: 'MERGE_PARTS',
                    firstPartIndex: partIndex,
                  });
                }}
                data-cy="document-edit-merge-pages-btn"
                size="small"
                sx={{ m: 1 }}
              >
                <SvgIcon
                  color="secondary"
                  fontSize="inherit"
                  component={SplitCancel}
                />
              </IconButton>
              <Divider orientation="vertical" sx={{ flex: 0.4 }} />
            </>
          ) : (
            <IconButton
              onClick={e => {
                dispatchEdit({
                  type: 'SPLIT_PART',
                  afterPageNumber: page.pageNumber,
                });

                // Select the page after the split, so that it will be scrolled into view,
                // and the user can continue splitting without having to scroll.
                if (followingPageNumber) {
                  setUiState(s => ({
                    ...s,
                    currentPageNumber: followingPageNumber,
                  }));
                }

                e.stopPropagation();
              }}
              data-cy="document-edit-split-pages-btn"
              size="small"
              sx={{
                m: 1,
              }}
            >
              <ContentCutRounded
                color="secondary"
                fontSize="inherit"
                sx={{ transform: 'rotate(270deg)' }}
              />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  },
  isEqual
);

EditedPage.displayName = 'EditedPage';
