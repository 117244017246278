import { endpoints, ID } from '@rossum/api-client';
import { DedicatedEngineSchemaGetQuery } from '@rossum/api-client/dedicatedEngineSchema';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

export const QUERY_KEY_DEDICATED_ENGINE_SCHEMA = 'dedicated-engine-schema';

export const useDedicatedEngineSchema = (
  engineSchemaId: ID | null,
  query: DedicatedEngineSchemaGetQuery = {}
) => {
  return useQuery({
    queryKey: [
      QUERY_KEY_DEDICATED_ENGINE_SCHEMA,
      engineSchemaId,
      query,
    ] as const,

    queryFn: ({ queryKey }) =>
      // without this query isn't enabled - is there a better way?
      api.request(endpoints.engineSchemas.get(queryKey[1]!, queryKey[2])),
    enabled: typeof engineSchemaId === 'number',
  });
};
