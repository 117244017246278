import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { Schema } from '@rossum/api-client/schemas';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { FlatSchemaWithQueues } from '../types/schema';
import { useConcurrentTaskQueue } from './useConcurrentTaskQueue';

// Check this one
interface PromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}

interface PromiseRejectedResult {
  status: 'rejected';
  reason: HttpError;
}

export type ResultsPerStatus = {
  fulfilled: Array<PromiseFulfilledResult<Schema>['value']>;
  rejected: Array<PromiseRejectedResult['reason']>;
};

export const usePatchSchemas = () => {
  const { executeConcurrentTask, taskQueue } =
    useConcurrentTaskQueue<Schema>(25);

  const mutation = useMutation({
    mutationFn: (schemas: Array<FlatSchemaWithQueues | Schema>) => {
      const schemaRequests = schemas.map(({ id, content }) => {
        return executeConcurrentTask(() =>
          api.request(endpoints.schemas.patch(id, { content }))
        );
      });

      return Promise.allSettled(schemaRequests).then(results =>
        results.reduce<ResultsPerStatus>(
          (acc, result) => {
            const isError = result.status === 'rejected';

            return {
              ...acc,
              [result.status]: [
                ...acc[result.status],
                isError ? result.reason : result.value,
              ],
            };
          },
          { fulfilled: [], rejected: [] }
        )
      );
    },
  });

  return { mutation, taskQueue: taskQueue.length };
};
