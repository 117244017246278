import { chain, get, keys } from 'lodash';
import { IntlShape } from 'react-intl';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { Url } from '../../../types/basic';
import { Group, GroupRole, groupRoles, Groups } from '../../../types/group';
import { GroupType } from './types';

const groupValues: GroupType = {
  organization_group_admin: { view: 5, importance: 0 },
  admin: { view: 3, importance: 1 },
  manager: { view: 2, importance: 2 },
  annotator: { view: 0, importance: 3 },
  annotator_limited: { view: 1, importance: 4 },
  viewer: { view: 4, importance: 5 },
  approver: { view: 6, importance: 6 },
};

export const roleNames = keys(groupValues) as Array<GroupRole>;

const compareGroups = (group: Group) => {
  const role = groupValues[group.name] || groupValues.viewer;
  return role.importance;
};

const compareGroupsForSelect = (group: Group) => {
  const role = groupValues[group.name] || groupValues.viewer;
  return role.view;
};

export const isApproverRole = (urls: Array<Url>, groups: Groups) => {
  const approverGroup = groups.find(({ name }) => name === 'approver');
  return approverGroup !== undefined && urls.includes(approverGroup.url);
};

export const getRole = (urls: Array<Url>, groups: Groups) =>
  chain(groups)
    .filter(({ url }) => urls.includes(url))
    .sortBy(compareGroups)
    .first()
    .value();

export const getRoleId = (name: string, groups: Groups) =>
  groups.find(g => g.name === name)?.id;

export const getRoleUrlByName = (name: string, groups: Groups) =>
  groups.find(g => g.name === name)?.url;

export const getRoleName = (urls: Array<Url>, groups: Groups) =>
  get(getRole(urls, groups), 'name');

export const getRoleUrl = (urls: Array<Url>, groups: Groups) =>
  get(getRole(urls, groups), 'url');

export const isUserAdminLike = (role: GroupRole) =>
  ['admin', 'organization_group_admin'].includes(role);

export const getRoleOptions = (groups: Groups, intl: IntlShape) =>
  chain(groupRoles)
    // This makes sure we don't add any role that f/e is not familiar with.
    .map(role => groups.find(group => group.name === role))
    .filter(isNotNullOrUndefined)

    .sortBy(compareGroupsForSelect)
    .map(({ url, name }) => ({
      name,
      label: intl.formatMessage({
        id: `containers.settings.users.roles.${name}`,
      }),
      value: url,
    }))
    .value();
