import { get } from 'lodash';
import { combineEpics } from 'redux-observable';
import * as R from 'remeda';
import { from } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { apiUrl } from '../../../constants/config';
import { errorHandler } from '../../../lib/api';
import { User } from '../../../types/user';
import { logoutUser } from '../auth/actions';
import {
  createGhostRow,
  recountDatapointPositionFulfilled,
  updateDatapointValueFulfilled,
} from '../datapoints/actions';
import { submitFeedbackFormFulfilled } from '../feedback/actions';
import { makeEpic } from '../utils';
import {
  fetchUser,
  fetchUserFulfilled,
  updateUiSettings,
  updateUser,
  updateUserFulfilled,
} from './actions';

// When updating ui settings allDocumentsDashboard width of the columns,
// we need to persist the casing of the column ids
export const excludeUserUISettingsKeysFromConversion = [
  'formula_preview',
  'formulaPreview',
  'documents_dashboard',
  'documentsDashboard',
];

const updateUserEpic = makeEpic((action$, state$, { authPatch$ }) =>
  action$.pipe(
    filter(isActionOf([updateUser, updateUiSettings])),
    mergeMap(action => {
      const { passwordErrors, ...user } = get(state$.value, 'user');

      return authPatch$<User>(`${apiUrl}/auth/user`, user, {
        excludeKeysFromConversion: excludeUserUISettingsKeysFromConversion,
      }).pipe(
        mergeMap(_user => {
          const actions = R.filter(
            [
              updateUserFulfilled(
                _user,
                'meta' in action ? action.meta : undefined
              ),
              'complexLineItems' in action.payload &&
                'auroraPromoCompleted' in action.payload &&
                createGhostRow(),
            ],
            R.isTruthy
          );

          return from(actions);
        }),
        tap(() => {
          // Seamless switching of CLI would be a lot of effort, reloading page is simpler

          if (
            'complexLineItems' in action.payload &&
            !('auroraPromoCompleted' in action.payload)
          ) {
            window.location.reload();
          }
        }),
        catchError(errorHandler)
      );
    })
  )
);

const fetchUserEpic = makeEpic((action$, _, { authGetJSON$ }) =>
  action$.pipe(
    filter(isActionOf(fetchUser)),
    mergeMap(() =>
      authGetJSON$<User>(`${apiUrl}/auth/user`, {
        excludeKeysFromConversion: excludeUserUISettingsKeysFromConversion,
      }).pipe(
        takeUntil(action$.pipe(filter(isActionOf(logoutUser)))),
        tap(({ username, id }) => {
          if (window.Rollbar) {
            window.Rollbar.configure({
              payload: { person: { username, id } },
            });
          }

          if (window.Sentry) {
            window.Sentry.setUser({
              id,
              username,
              email: username,
            });
          }
        }),
        map(fetchUserFulfilled),
        catchError(errorHandler)
      )
    )
  )
);

const anyDatapointCorrectedEpic = makeEpic((action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        updateDatapointValueFulfilled,
        recountDatapointPositionFulfilled,
      ])
    ),
    filter(
      () => !get(state$.value, ['user', 'uiSettings', 'anyDatapointCorrected'])
    ),
    mapTo(updateUiSettings({ anyDatapointCorrected: true }))
  )
);

const saveFeedbackFormSubmissionEpic = makeEpic(action$ =>
  action$.pipe(
    filter(isActionOf(submitFeedbackFormFulfilled)),
    pluck('payload'),
    map(form => updateUiSettings({ feedbackFormsSubmitted: { [form]: true } }))
  )
);

export default combineEpics(
  anyDatapointCorrectedEpic,
  fetchUserEpic,
  saveFeedbackFormSubmissionEpic,
  updateUserEpic
);
