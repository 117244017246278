import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { useQueries } from '@tanstack/react-query';
import { useCallback } from 'react';
import { api } from '../../../lib/apiClient';
import { STALE_TIME } from './config';
import { useConcurrentTaskQueue } from './useConcurrentTaskQueue';

export const useGetSchemas = (schemas: string[]) => {
  const { executeConcurrentTask } = useConcurrentTaskQueue<Schema>(25);

  const fetchSchema = useCallback(
    (schema: string) =>
      executeConcurrentTask(() =>
        api.request(endpoints.schemas.get(getIDFromUrl(schema)))
      ),
    [executeConcurrentTask]
  );

  const queries = schemas.map(schema => ({
    queryKey: ['schema', schema],
    queryFn: () => fetchSchema(schema),
    staleTime: STALE_TIME,
  }));

  return useQueries({ queries });
};
