import { ReactNode } from 'react';
import {
  useCanvasGeometryState,
  useDocumentStore,
} from '../../document-store/DocumentStore';
import { stateToMatrix } from '../../document-store/helpers';

type DocumentScrollableProps = {
  children: ReactNode;
};

export const DocumentScrollable = ({ children }: DocumentScrollableProps) => {
  const matrixState = useCanvasGeometryState();

  const canvasRef = useDocumentStore(state => state.canvasRef);
  return (
    <g ref={canvasRef} transform={`${stateToMatrix(matrixState).toString()}`}>
      {children}
    </g>
  );
};
