// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const membershipSchema = z.object({
  id: idSchema,
  url: urlSchema,
  user: urlSchema,
  organization: urlSchema,
  queues: z.array(urlSchema),
  expiresAt: z.string().nullable(),
});
