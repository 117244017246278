import { endpoints } from '@rossum/api-client';
import { RequestExtensionPayload } from '@rossum/api-client/internal';
import { useMutation } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

export const useSubmitContactDialog = () => {
  const api = useApiClient();
  return useMutation({
    mutationFn: ({ payload }: { payload: RequestExtensionPayload }) =>
      api.request(endpoints.internal.requestExtension(payload)),
  });
};
