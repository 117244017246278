import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

const SearchResults = ({
  querySearch,
}: {
  querySearch: string | undefined;
}) => {
  const intl = useIntl();

  if (!querySearch) return null;

  return (
    <Typography variant="body2">
      {intl.formatMessage(
        { id: 'containers.allDocuments.search.showResultsFor' },
        {
          query: querySearch,
        }
      )}
    </Typography>
  );
};

export default SearchResults;
