import { Message } from '@rossum/api-client/shared';
import { Tooltip } from '@rossum/rossum-ui/Tooltip';
import { Box, styled } from '@rossum/ui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import { ReactNode } from 'react';
import HTMLMessage from '../../DatapointMessage/HTMLMessage';
import IconWithTooltip from '../../DatapointMessage/IconWithTooltip';
import styles from '../style.module.sass';

type Props = {
  active?: boolean;
  children: (_: { messageIcon: ReactNode }) => JSX.Element;
  hideBorder?: boolean;
  inFooter?: boolean;
  isButton?: boolean;
  message?: Message;
  renderEmptyPlaceholder?: boolean;
  resizing?: boolean;
};

const StyledTooltip = styled(Tooltip)({});

const DatapointMessage = ({
  active,
  children,
  hideBorder,
  inFooter,
  isButton,
  message,
  renderEmptyPlaceholder,
  resizing,
}: Props) =>
  inFooter ? (
    <StyledTooltip
      keyId={`${get(message, 'id')}-${get(message, 'content')}`}
      arrowClassName={styles.MessageArrow}
      contentClassName={styles.MessageContent}
      shown={active && !resizing}
      disabled={!get(message, 'id')}
      sx={{ backgroundColor: message ? `${message.type}.main` : 'transparent' }}
      content={<HTMLMessage content={message?.content || ''} />}
      modifiers={[
        { name: 'preventOverflow', enabled: true },
        { name: 'hide', enabled: true },
      ]}
    >
      <Box
        className={clsx(!isButton && styles.Cell)}
        borderColor={
          message && !hideBorder ? `${message.type}.main` : undefined
        }
      >
        {children({ messageIcon: null })}
      </Box>
    </StyledTooltip>
  ) : (
    children({
      messageIcon: message ? (
        <IconWithTooltip message={message} active={!!active} />
      ) : renderEmptyPlaceholder ? (
        <div className={styles.EmptyIconPlaceholder} />
      ) : null,
    })
  );

DatapointMessage.defaultProps = {
  message: null,
  inFooter: false,
  active: false,
  isButton: false,
  hideBorder: false,
  resizing: false,
};

export default DatapointMessage;
