import { endpoints, ID } from '@rossum/api-client';
import { DedicatedEngineSchemaPatchPayload } from '@rossum/api-client/dedicatedEngineSchema';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

// TODO: This is a bit ugly, spend some time on standardising
export const usePatchDedicatedEngineSchema = () => {
  return useMutation({
    mutationFn: ({
      engineSchemaId,
      engineSchemaPatchModel,
    }: {
      engineSchemaId: ID;
      engineSchemaPatchModel: DedicatedEngineSchemaPatchPayload;
    }) =>
      api.request(
        endpoints.engineSchemas.patch(engineSchemaId, engineSchemaPatchModel)
      ),
  });
};
