// List of known group roles.
// Backend can return more roles, but only these are shown.
export const groupRoles = [
  'annotator',
  'annotator_limited',
  'manager',
  'admin',
  'viewer',
  'organization_group_admin',
  'approver',
] as const;

export type GroupRole = (typeof groupRoles)[number];

export type Group = {
  id: number;
  name: GroupRole;
  url: string;
};

export type Groups = Array<Group>;
