// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointSchema } from './schemaDatapoint.schema';
import { schemaItemBaseSchema } from './schemaItemBase.schema';
import { schemaTupleSchema } from './schemaTuple.schema';

export const schemaMultivalueGridSchema = z.object({
  rowTypes: z.array(z.string()),
  defaultRowType: z.string(),
  rowTypesToExtract: z.array(z.string()),
});

export const schemaMultivalueBaseSchema = z
  .object({
    category: z.literal('multivalue'),
    minOccurrences: z.number().optional().nullable(),
    maxOccurrences: z.number().optional().nullable(),
    grid: schemaMultivalueGridSchema.optional().nullable(),
    showGridByDefault: z.boolean().optional().nullable(),
    rirFieldNames: z.array(z.string()).optional().nullable(),
  })
  .and(schemaItemBaseSchema);

export const schemaSimpleMultivalueSchema = z
  .object({
    children: schemaDatapointSchema,
  })
  .and(schemaMultivalueBaseSchema);

export const schemaTableMultivalueSchema = z
  .object({
    children: schemaTupleSchema,
  })
  .and(schemaMultivalueBaseSchema);
