import { yupResolver } from '@hookform/resolvers/yup';
import { PersonRemove } from '@rossum/ui/icons';
import { Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import ActionDialogCheckboxes from '../../../../components/UI/ActionDialog/ActionDialogCheckboxes';
import { boldText } from '../../../../lib/formaterValues';
import { OrganizationUser, User } from '../../../../types/user';
import { getUserName } from '../../../Extensions/components/UsersDropdown';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
  user: OrganizationUser | User;
  deletingOrganization: boolean;
};

const checkboxSchemaMyAccount = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'components.modal.deleteUser.checkbox.account',
    }),
    name: 'accountCheckbox' as const,
  },
];

const checkboxSchemaIsTrial = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'components.modal.deleteUser.checkbox.admin',
    }),
    name: 'adminCheckbox' as const,
  },
  {
    label: intl.formatMessage({
      id: 'components.modal.deleteUser.checkbox.data',
    }),
    name: 'dataCheckbox' as const,
  },
];

const checkboxValidationSchemaMyAccount = yup.object().shape({
  accountCheckbox: yup.boolean().required().oneOf([true], ''),
});

const checkboxValidationSchemaIsTrial = yup.object().shape({
  adminCheckbox: yup.boolean().required().oneOf([true], ''),
  dataCheckbox: yup.boolean().required().oneOf([true], ''),
});

const DeleteUserDialogWithCheckboxes = ({
  onDelete,
  onCancel,
  user,
  deletingOrganization,
}: Props) => {
  const intl = useIntl();

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      deletingOrganization
        ? checkboxValidationSchemaIsTrial
        : checkboxValidationSchemaMyAccount
    ),
    defaultValues: {
      accountCheckbox: false,
      adminCheckbox: false,
      dataCheckbox: false,
    },
  });

  return (
    <form onSubmit={handleSubmit(() => onDelete())}>
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'components.modal.deleteUser.title',
        })}
        onCancel={() => onCancel()}
        dialogColor="error"
        DialogTitleIcon={PersonRemove}
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
            }}
            confirmButtonText={intl.formatMessage({
              id: 'components.modal.deleteUser.button.delete',
            })}
            dataCyConfirm="delete-account-confirm-btn-footer"
            dataCyCancel="delete-account-cancel-btn-footer"
          />
        }
        dataCy="delete-account-cancel-btn"
      >
        <Typography variant="body1">
          {intl.formatMessage(
            {
              id: deletingOrganization
                ? 'components.modal.deleteUser.isTrial.text'
                : 'components.modal.deleteUser.myAccount.text',
            },
            {
              boldText,
              userName: getUserName(user),
            }
          )}
        </Typography>
        <ActionDialogCheckboxes
          control={control}
          checkboxSchema={
            deletingOrganization
              ? checkboxSchemaIsTrial(intl)
              : checkboxSchemaMyAccount(intl)
          }
          errorMessage={intl.formatMessage({
            id: 'components.modal.deleteUser.checkbox.error',
          })}
        />
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {intl.formatMessage({
            id: 'components.modal.deleteUser.willBeLoggedOut',
          })}
        </Typography>
      </ActionDialog>
    </form>
  );
};

export default DeleteUserDialogWithCheckboxes;
