import { sortBy } from 'lodash';
import { Grid } from '../../types/datapoints';
import { AnyDatapointSchema } from '../../types/schema';

/**
 * NOTE: This function is not returning sorted columns directly,
 * since we want to use it also for sorting tuple children.
 */
export const getOrderedIndexesForGrid = (
  grid: Grid,
  schemaColumns: AnyDatapointSchema[]
): number[] => {
  return sortBy(schemaColumns, schemaCol => {
    // The callback in sortBy DOESN'T have index argument, although the types say there is
    const originalIndex = schemaColumns.indexOf(schemaCol);

    // Sort button datapoints first (typical usecase is some kind of context action, so it should be initially visible)
    if (schemaCol.type === 'button') {
      return originalIndex;
    }

    const indexInGrid = grid.columns.findIndex(
      gridCol => gridCol.schemaId === schemaCol.id
    );

    // Use the grid order, but after button datapoints
    if (indexInGrid > -1) return 1000 + indexInGrid;

    // Remaining columns keep their original order from schema, but they are sorted after all grid columns
    return 2000 + originalIndex;
  }).map(schemaCol => schemaColumns.indexOf(schemaCol));
};
