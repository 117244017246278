import { BboxParams } from '../../../../lib/spaceConvertor';
import { MagicLine } from './MagicLine';
import { useMagicLine } from './useMagicLine';

type ConnectedMagicLineProps = {
  pageNumber: number;
  position: BboxParams;
  sourceElementId: string;
};

export const ConnectedMagicLine = ({
  pageNumber,
  position,
  sourceElementId,
}: ConnectedMagicLineProps) => {
  const magicLineProps = useMagicLine({
    pageNumber,
    position,
    sourceElementId,
  });
  return magicLineProps ? <MagicLine {...magicLineProps} /> : null;
};
