// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const documentsListQuerySchema = z
  .object({
    id: z.union([idSchema, z.array(idSchema)]).optional(),
    email: z.union([idSchema, z.array(idSchema)]).optional(),
    creator: z.string().optional(),
    arrivedAt: z.string().optional(),
    createdAt: z.string().optional(),
    originalFileName: z.string().optional(),
    attachmentStatus: z.string().optional(),
    ordering: z
      .union([z.literal('-attachment_status'), z.undefined()])
      .optional(),
  })
  .and(paginationQuerySchema);
