import { History } from 'history';
import { useEffect, useRef } from 'react';

export const LIST_BACKLINK_KEY = 'listBacklink';

const supportedBacklinks = ['/annotations', '/documents'] as const;

const setListBacklinkToStorage = (
  pathname: `${(typeof supportedBacklinks)[number]}${string}`
) => {
  localStorage.setItem(LIST_BACKLINK_KEY, pathname);
  sessionStorage.setItem(LIST_BACKLINK_KEY, pathname);
};

const getValidatedStorageValue = (
  val: string | null
): (typeof supportedBacklinks)[number] | undefined => {
  const match = ((val && val.match(/^.*(\/annotations|\/documents)/)) || [])[1];

  return supportedBacklinks.find(link => link === match);
};

export const useDefaultListBacklink = (history: History) => {
  const currentLocation = useRef(history.location);

  useEffect(() => {
    // try to get referrer and check whether user is on validation screen on initial load
    // this is needed when user opens the validation screen in a new tab
    if (currentLocation?.current.pathname.includes('/document/')) {
      const backlink = getValidatedStorageValue(window.document.referrer);
      if (backlink) {
        const { searchParams } = new URL(window.document.referrer);
        setListBacklinkToStorage(`${backlink}?${searchParams}`);
      }
    }

    // In order to avoid setting the backlink in each Link which opens the validation screen,
    // we set the backlink when user navigates to the validation screen and use the source location
    return history.listen(location => {
      const nextLocation = location;
      const backlink = getValidatedStorageValue(
        currentLocation.current.pathname
      );

      if (nextLocation.pathname.includes('/document/') && backlink) {
        setListBacklinkToStorage(
          `${backlink}${currentLocation.current.search}`
        );
      }

      currentLocation.current = nextLocation;
    });
  }, [currentLocation, history]);
};

export const getAnnotationBacklink = () => {
  const backlinkInSessionStorage = sessionStorage.getItem(LIST_BACKLINK_KEY);
  const backlinkInLocalStorage = localStorage.getItem(LIST_BACKLINK_KEY);

  return backlinkInSessionStorage ?? backlinkInLocalStorage ?? '/annotations';
};
