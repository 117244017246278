import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  membershipTokenPaylodSchema,
  membershipTokenResponseSchema,
} from './getMembershipToken.schema';

export type MembershipTokenPaylod = {
  organization: Url;
  origin?: string;
};

export type MembershipTokenResponse = {
  key: string;
  organization: Url;
};

export const getMembershipToken = (payload: MembershipTokenPaylod) => {
  return {
    endpoint: `/auth/membership_token`,
    method: 'POST',
    responseSchema: membershipTokenResponseSchema,
    payloadSchema: membershipTokenPaylodSchema,
    payload,
  } as const;
};
