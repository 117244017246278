import { AnyAction } from 'redux';
import { ActionType, createAction } from 'typesafe-actions';
import { ConfirmTypeT, ModalKey } from '../../../containers/Modals/types';

export type OpenModalParameters = {
  confirmAction?: () => AnyAction;
  confirmType?: ConfirmTypeT;
  onConfirm?: () => void;
  textId: ModalKey;
  values?: unknown;
};

type OpenModalPayload = {
  confirmAction: undefined | (() => AnyAction);
  confirmType: ConfirmTypeT;
  onConfirm: undefined | (() => void);
  textId: ModalKey;
  values: unknown;
};

export const openModal = createAction(
  'OPEN_MODAL',
  ({
    confirmAction,
    confirmType = 'Primary',
    onConfirm,
    textId,
    values,
  }: OpenModalParameters) => ({
    confirmAction,
    confirmType,
    onConfirm,
    textId,
    values,
  })
)<OpenModalPayload>();

export const closeModal = createAction('CLOSE_MODAL')<void>();

export type OpenModal = typeof openModal;

export type ModalActions = ActionType<OpenModal | typeof closeModal>;
