import {
  alpha,
  Box,
  BoxProps,
  styled,
  SxProps,
  Theme,
} from '@rossum/ui/material';
import {
  CORNER_RESIZE_HANDLE_SIZE,
  GRID_LINE_WIDTH,
  INTERACTIVE_AREA_SIZE,
} from './constants';
import { useGridContext } from './GridContext';
import { useDraggableInteraction } from './useDraggableInteraction';
import { useResizeClamps } from './useResizeClamps';
import { resizeGridByEdge, ResizingEdge } from './utils';

const CornerHandle = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragging',
})<BoxProps & { isDragging: boolean }>(({ theme, isDragging }) => ({
  position: 'absolute',
  width: CORNER_RESIZE_HANDLE_SIZE,
  height: CORNER_RESIZE_HANDLE_SIZE,
  backgroundColor: theme.palette.common.white,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '100%',
  display: 'inline-block',
  [`&:before`]: {
    content: '""',
    width: CORNER_RESIZE_HANDLE_SIZE + INTERACTIVE_AREA_SIZE,
    height: CORNER_RESIZE_HANDLE_SIZE + INTERACTIVE_AREA_SIZE,
    position: 'absolute',
    // -2 is to compensate for parent border
    transform: `translate(calc(-50% + ${
      CORNER_RESIZE_HANDLE_SIZE / 2 - 2
    }px), calc(-50% + ${CORNER_RESIZE_HANDLE_SIZE / 2 - 2}px))`,
    borderRadius: '100%',
    backgroundColor: isDragging
      ? alpha(theme.palette.background.paper, 0.1)
      : 'transparent',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    zIndex: -1,
  },
  [`&:hover`]: {
    [`&:before`]: {
      backgroundColor: alpha(theme.palette.background.paper, 0.1),
    },
  },
}));

const EdgeHandle = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'isDragging' && propName !== 'orientation',
})<BoxProps & { isDragging: boolean; orientation: 'horizontal' | 'vertical' }>(
  ({ theme, isDragging, orientation }) => ({
    borderColor: isDragging
      ? alpha(theme.palette.background.paper, 0.1)
      : 'transparent',
    borderStyle: 'solid',
    borderWidth:
      orientation === 'horizontal'
        ? `${INTERACTIVE_AREA_SIZE}px 0`
        : `0 ${INTERACTIVE_AREA_SIZE}px`,
    backgroundClip: 'content-box',
    position: 'absolute',
    zIndex: -1,
    transition: theme.transitions.create(['border-color'], {
      duration: theme.transitions.duration.short,
    }),
    [`&:hover`]: {
      borderColor: alpha(theme.palette.background.paper, 0.1),
    },
  })
);

const edgeHandleSxProps: Partial<Record<ResizingEdge, SxProps<Theme>>> = {
  right: {
    height: '100%',
    width: GRID_LINE_WIDTH + 2 * INTERACTIVE_AREA_SIZE,
    right: -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE,
    cursor: 'col-resize',
  },
  bottom: {
    height: GRID_LINE_WIDTH + 2 * INTERACTIVE_AREA_SIZE,
    width: '100%',
    bottom: -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE,
    cursor: 'row-resize',
  },
  top: {
    height: GRID_LINE_WIDTH + 2 * INTERACTIVE_AREA_SIZE,
    width: '100%',
    top: -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE,
    cursor: 'row-resize',
  },
  left: {
    height: '100%',
    width: GRID_LINE_WIDTH + 2 * INTERACTIVE_AREA_SIZE,
    left: -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE,
    cursor: 'col-resize',
  },
};

const cornerHandleSxProps: Partial<Record<ResizingEdge, SxProps<Theme>>> = {
  'top-left': {
    left: `-${CORNER_RESIZE_HANDLE_SIZE / 2 + GRID_LINE_WIDTH / 2}px`,
    top: `-${CORNER_RESIZE_HANDLE_SIZE / 2 + GRID_LINE_WIDTH / 2}px`,
    cursor: 'nwse-resize',
  },
  'top-right': {
    left: `calc(100% - ${
      CORNER_RESIZE_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2
    }px)`,
    top: `-${CORNER_RESIZE_HANDLE_SIZE / 2 + GRID_LINE_WIDTH / 2}px`,
    cursor: 'nesw-resize',
  },
  'bottom-right': {
    left: `calc(100% - ${
      CORNER_RESIZE_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2
    }px)`,
    top: `calc(100% - ${
      CORNER_RESIZE_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2
    }px)`,
    cursor: 'nwse-resize',
  },
  'bottom-left': {
    left: `-${CORNER_RESIZE_HANDLE_SIZE / 2 + GRID_LINE_WIDTH / 2}px`,
    top: `calc(100% - ${
      CORNER_RESIZE_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2
    }px)`,
    cursor: 'nesw-resize',
  },
};

export type ResizeHandleProps = BoxProps & {
  onResized: (edge: ResizingEdge) => void;
  edge: ResizingEdge;
};

export const ResizeHandle = ({
  edge,
  onResized,
  ...restBoxProps
}: ResizeHandleProps) => {
  const {
    gridState,
    gridDraftState: [_, setDraftState],
    interactionInProgress,
  } = useGridContext();

  const clamp = useResizeClamps(edge);

  const { handleMouseDown, isDragging } = useDraggableInteraction({
    onDragStart: () => setDraftState(gridState),
    onDrag: diff =>
      setDraftState(resizeGridByEdge(gridState, edge, clamp(diff))),
    onDragEnd: () => {
      onResized(edge);
      setDraftState(null);
    },
  });

  if (edge.split('-').length > 1) {
    return (
      <CornerHandle
        isDragging={isDragging}
        onMouseDownCapture={handleMouseDown}
        sx={cornerHandleSxProps[edge]}
        style={{
          pointerEvents: interactionInProgress && !isDragging ? 'none' : 'all',
          ...(isDragging ? { cursor: 'grabbing' } : {}),
        }}
        {...restBoxProps}
      />
    );
  }

  return (
    <EdgeHandle
      orientation={
        edge === 'top' || edge === 'bottom' ? 'horizontal' : 'vertical'
      }
      isDragging={isDragging}
      onMouseDownCapture={handleMouseDown}
      sx={edgeHandleSxProps[edge]}
      style={{
        pointerEvents: interactionInProgress && !isDragging ? 'none' : 'all',
        ...(isDragging ? { cursor: 'grabbing' } : {}),
      }}
      {...restBoxProps}
    />
  );
};
