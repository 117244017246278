import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Membership } from '../models';
import { membershipSchema } from '../models/membership.schema';
import { createMembershipPayloadSchema } from './create.schema';

export type createMembershipPayload = {
  user: Url;
  organization: Url;
  queues: Array<Url>;
};

export const create = (
  organizationGroupId: ID,
  payload: createMembershipPayload
) => {
  return {
    endpoint: `/organization_groups/${organizationGroupId}/memberships`,
    method: 'POST',
    responseSchema: membershipSchema,
    payloadSchema: createMembershipPayloadSchema,
    payload,
  } as const;
};
