// Generated by ts-to-zod
import { z } from 'zod';
import {
  datapointFieldSourceSchema,
  datapointFieldSourcePatchModelSchema,
} from './datapointFieldSource.schema';

export const datapointFieldTypeSchema = z.union([
  z.literal('number'),
  z.literal('string'),
  z.literal('date'),
  z.literal('enum'),
]);

export const baseDatapointFieldSchema = z.object({
  category: z.literal('datapoint'),
  engineOutputId: z.string(),
  type: datapointFieldTypeSchema,
  label: z.string(),
  trained: z.boolean(),
  description: z.string(),
  sources: z.array(datapointFieldSourceSchema),
});

export const datapointFieldPatchModelSchema = z
  .object({
    category: z.literal('datapoint'),
  })
  .and(
    z
      .object({
        engineOutputId: z.string(),
        type: datapointFieldTypeSchema,
        label: z.string(),
        description: z.string(),
        sources: z.array(datapointFieldSourcePatchModelSchema),
      })
      .partial()
  );
