import { endpoints } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../lib/apiClient';
import { throwError } from '../../redux/modules/messages/actions';

export const useResetPassword = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (payload: { email: string }) =>
      api.request(endpoints.authentication.password.reset(payload)),

    onError: () => {
      dispatch(throwError('passwordChangeFailed'));
    },

    retry: false,
  });
};
