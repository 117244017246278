import { ElisRequestConfig } from '../../utils/requestConfig';
import { Hook, HookBase } from '../models/hook';
import { hookSchema } from '../models/hook.schema';
import { FunctionConfig, WebhookConfig } from '../models/hookConfig';
import { ExtensionStatus } from '../models/hookUtils';
import { hookPatchPayloadSchema } from './patch.schema';

export type HookPatchPayload =
  | ({
      type: 'function';
      config?: FunctionConfig;
      status?: ExtensionStatus;
      test?: {
        savedInput?: string;
      };
    } & Partial<HookBase>)
  | ({ type: 'webhook'; config?: WebhookConfig } & Partial<HookBase>);

export const patch = (hookId: number, payload: HookPatchPayload) => {
  return {
    endpoint: `/hooks/${hookId}`,
    method: 'PATCH',
    responseSchema: hookSchema,
    payload,
    payloadSchema: hookPatchPayloadSchema,
  } as const;
};
