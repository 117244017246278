import { yupResolver } from '@hookform/resolvers/yup';
import { Queue } from '@rossum/api-client/queues';
import { Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useDuplicateQueue } from '../../../../business/queues/useDuplicateQueue';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';
import ActionDialog from '../../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../../components/UI/ActionDialog/ActionDialogActions';
import DuplicateDialogCheckboxes from '../../../../components/UI/ActionDialog/DuplicateDialogCheckboxes';
import { boldText } from '../../../../lib/formaterValues';

type Props = {
  queue: Queue;
  onDuplicate: (queueId: number) => void;
  onCancel: () => void;
};

const checkboxSchema = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'components.modal.duplicateQueue.extensionsSettings',
    }),
    name: 'copyExtensionsSettings' as const,
  },
  {
    label: intl.formatMessage({
      id: 'components.modal.duplicateQueue.emailSettings',
    }),
    name: 'copyEmailSettings' as const,
  },
  {
    label: intl.formatMessage({
      id: 'components.modal.duplicateQueue.automationSettings',
    }),
    name: 'copyAutomationSettings' as const,
  },
  {
    label: intl.formatMessage({
      id: 'components.modal.duplicateQueue.accessPermissions',
    }),
    name: 'copyPermissions' as const,
  },
];

const duplicateQueueFormSchema = yup.object().shape({
  copyExtensionsSettings: yup.boolean().optional(),
  copyEmailSettings: yup.boolean().optional(),
  copyAutomationSettings: yup.boolean().optional(),
  copyPermissions: yup.boolean().optional(),
  copyDeleteRecommendations: yup.boolean().optional(),
  name: yup.string().required(),
});

export const QueueDuplicate = ({ queue, onDuplicate, onCancel }: Props) => {
  const { mutate: duplicateQueue, isLoading } = useDuplicateQueue({
    onSuccess: onDuplicate,
  });

  const intl = useIntl();

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(duplicateQueueFormSchema),
    defaultValues: {
      copyExtensionsSettings: false,
      copyEmailSettings: false,
      copyAutomationSettings: false,
      copyPermissions: false,
      copyDeleteRecommendations: true,
      name: `${queue.name} (Duplicate)`,
    },
  });

  return (
    <form
      onSubmit={handleSubmit(payload =>
        duplicateQueue({ queueId: queue.id, payload })
      )}
    >
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'components.modal.duplicateQueue.title',
        })}
        onCancel={() => onCancel()}
        dialogColor="primary"
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
              disabled: isLoading,
            }}
            confirmButtonText={intl.formatMessage({
              id: 'components.modal.duplicateQueue.button.duplicate',
            })}
            cancelButtonText={intl.formatMessage({
              id: 'components.actionDialog.actionDialogActions.close',
            })}
            dataCyConfirm="duplicate-queue-confirm-btn-footer"
            dataCyCancel="duplicate-queue-cancel-btn-footer"
          />
        }
        dataCy="duplicate-queue-close-btn"
      >
        <Stack spacing={3} direction="column">
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: 'component.modal.duplicate.text',
              },
              {
                boldText,
                queue: queue.name,
              }
            )}
          </Typography>
          <TextFieldControl
            ControllerProps={{ control, name: 'name' }}
            FieldLabelProps={{ layout: 'floating' }}
            label={intl.formatMessage({
              id: 'components.queueDetailForm.queue.label',
            })}
            autoComplete="off"
          />
          <DuplicateDialogCheckboxes
            control={control}
            checkboxSchema={checkboxSchema(intl)}
          />
        </Stack>
      </ActionDialog>
    </form>
  );
};
