import { useCallback, useEffect, useRef } from 'react';
import { combineLatest, fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { useObservableContext } from '../../components/ObservableProvider';
import { ROW_HEIGHT } from '../../decorators/makeResizable/config';
import { BatchUpdateEvent } from './useBatchUpdate';

export const useDetectTupleUnderCursor = ({
  scrolledElement,
  inBatchUpdateState,
  tupleIdsByPart,
  onBatchUpdateEvent,
}: {
  scrolledElement?: HTMLElement;
  inBatchUpdateState: boolean;
  tupleIdsByPart: Record<string, number[]>;
  onBatchUpdateEvent: (event: BatchUpdateEvent) => void;
}) => {
  const { onDocumentMouseMoveObserver } = useObservableContext()!;

  const tableBodyRefs = useRef<Record<string, HTMLElement | null>>({});

  const setTableBodyRef = useCallback(
    (el: HTMLElement | null, part: string) => {
      tableBodyRefs.current[part] = el;
    },
    []
  );

  // To avoid subscribing/unsubscribing when tupleIdsByPart change
  const tupleIdsByPartRef = useRef(tupleIdsByPart);

  tupleIdsByPartRef.current = tupleIdsByPart;

  useEffect(() => {
    if (!scrolledElement) return undefined;

    // AFI: This can be optimized by retrieving the bounds only once
    // (they don't change while we are in batch update state)

    // When scrolling, we take the position from the last mouse move event
    const subscription = combineLatest([
      onDocumentMouseMoveObserver,
      fromEvent(scrolledElement, 'scroll').pipe(startWith({})),
    ]).subscribe(([{ clientY }]) => {
      // We can't do it earlier, so that we remember last mouse position
      if (!inBatchUpdateState) return;

      const tableUnderCursor = Object.entries(tableBodyRefs.current).find(
        ([, tableBody]) => {
          if (!tableBody) return false;

          const rect = tableBody.getBoundingClientRect();

          return clientY >= rect.top && clientY <= rect.bottom;
        }
      );

      if (!tableUnderCursor) return;

      const [part, tableBody] = tableUnderCursor;

      const tupleIds = tupleIdsByPartRef.current[part];

      if (!tupleIds || !tableBody) return;

      const indexOfTupleUnderCursor = Math.floor(
        (clientY - tableBody.getBoundingClientRect().top) / ROW_HEIGHT
      );

      if (
        indexOfTupleUnderCursor < 0 ||
        indexOfTupleUnderCursor >= tupleIds.length
      )
        return;

      onBatchUpdateEvent({
        type: 'over',
        tupleId: tupleIds[indexOfTupleUnderCursor],
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    scrolledElement,
    inBatchUpdateState,
    onBatchUpdateEvent,
    onDocumentMouseMoveObserver,
  ]);

  return { setTableBodyRef };
};
