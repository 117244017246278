import {
  Delete,
  DriveFileMove,
  RotateLeft,
  RotateRight,
} from '@rossum/ui/icons';
import { IconButton, Stack } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmbedded } from '../../constants/config';
import { QueueDialog } from '../queues/select-queue/QueueDialog';
import { DispatchEdit, Part } from './editState';

export const EditedPartActions = ({
  part,
  dispatchEdit,
  index,
}: {
  part: Part;
  index: number;
  dispatchEdit: DispatchEdit;
}) => {
  const intl = useIntl();
  const { pages } = part;

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Stack
      direction="row"
      spacing={1}
      data-cy="part-actions"
      sx={{ alignItems: 'center' }}
    >
      {!isEmbedded() && (
        <IconButton
          onClick={() => setDialogOpen(true)}
          size="small"
          data-cy="document-edit-select-target-queue-btn"
        >
          <DriveFileMove fontSize="small" color="secondary" />
        </IconButton>
      )}
      <QueueDialog
        title={intl.formatMessage({
          id: 'containers.editDocument.selectQueue.title',
        })}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={({ url: queueUrl }) => {
          dispatchEdit({
            type: 'SET_TARGET_QUEUE',
            partIndex: index,
            queueUrl,
          });
          setDialogOpen(false);
        }}
      />
      <IconButton
        onClick={() =>
          dispatchEdit({
            type: 'BATCH_ACTION',
            actions: pages.map(p => ({
              type: 'ROTATE_PAGE',
              pageNumber: p.pageNumber,
              rotationDeg: p.rotationDeg - 90,
            })),
          })
        }
        data-cy="document-edit-batch-rotate-page-counter-clockwise-btn"
        size="small"
      >
        <RotateLeft fontSize="small" color="secondary" />
      </IconButton>
      <IconButton
        onClick={() =>
          dispatchEdit({
            type: 'BATCH_ACTION',
            actions: pages.map(p => ({
              type: 'ROTATE_PAGE',
              pageNumber: p.pageNumber,
              rotationDeg: p.rotationDeg + 90,
            })),
          })
        }
        data-cy="document-edit-batch-rotate-page-clockwise-btn"
        size="small"
      >
        <RotateRight fontSize="small" color="secondary" />
      </IconButton>
      <IconButton
        onClick={() => {
          const allPagesAreDeleted = pages.every(p => p.deleted);

          return dispatchEdit({
            type: 'BATCH_ACTION',
            actions: pages.map(p => ({
              type: 'DELETE_PAGE',
              pageNumber: p.pageNumber,
              deleted: !allPagesAreDeleted,
            })),
          });
        }}
        data-cy="document-edit-batch-delete-page-btn"
        size="small"
      >
        <Delete fontSize="small" color="secondary" />
      </IconButton>
    </Stack>
  );
};
