import { Dispatch, SetStateAction, useState } from 'react';
import { EditState } from './editState';

type UiState = {
  confirmInProgress: boolean;
  currentPageNumber: number | null;
  draggedPage: { number: number; stateOnDragStart: EditState } | null;
  cancelChangesDialogOpen: boolean;
};

export const useUiState = (confirmInProgress: boolean) => {
  const [state, setState] = useState<UiState>({
    currentPageNumber: null,
    draggedPage: null,
    confirmInProgress: false,
    cancelChangesDialogOpen: false,
  });

  if (state.confirmInProgress !== confirmInProgress) {
    setState({
      currentPageNumber: null,
      draggedPage: null,
      confirmInProgress,
      cancelChangesDialogOpen: false,
    });
  }

  return [state, setState] as const;
};

export type UiStateTuple = [UiState, Dispatch<SetStateAction<UiState>>];
