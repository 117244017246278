import { AxiosRequestConfig, Method } from 'axios';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { defaults, identity } from 'lodash';
import { merge } from 'lodash/fp';
import { ZodType, ZodTypeDef } from 'zod';
import { ElisClientError } from '../errors';
import { asPayloadEncoder, asQueryEncoder, toSnakeCase } from '../utils';
import { ElisClientConfig, ElisContentType } from './client';
import { payloadToFormData } from './requestUtils';
import { encodeObjectToQuery } from './urlUtils';

type ElisRequestConfigBase = {
  endpoint: string;
  method?: Method;
  authorize?: boolean;
  withRossumRequestOrigin?: boolean;
  headers?: Record<string, string>;
  contentType?: ElisContentType;
  signal?: AbortSignal;
};

/**
 * @noSchema
 */
type WithQuery<Q> = [Q] extends [never]
  ? {
      query?: never;
      querySchema?: never;
    }
  : {
      query: Q;
      querySchema: ZodType<Q, ZodTypeDef, unknown>;
    };

/**
 * @noSchema
 */
type WithPayload<P> = [P] extends [never]
  ? {
      payload?: never;
      payloadSchema?: never;
    }
  : {
      payload: P;
      payloadSchema: ZodType<P, ZodTypeDef, unknown>;
    };

// To fix strict null checks.
/**
 * @noSchema
 */
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

// add content type config
/**
 * @noSchema
 */
type WithResponse<R> =
  // always require responseDecoder for 'json' responses - worst case scenario identity
  | {
      responseType: 'json';
      responseSchema: ZodType<R, ZodTypeDef, unknown>;
    }
  // responseType defaults to json, so require decoder too
  | {
      responseType?: undefined;
      responseSchema: ZodType<R, ZodTypeDef, unknown>;
    }
  // don't pass decoder for other responseTypes
  | {
      responseType: 'blob' | 'document' | 'text' | 'arraybuffer';
      responseSchema?: never;
    };

/**
 * @noSchema
 */
export type ElisRequestConfig<R, Q, P> = ElisRequestConfigBase &
  WithQuery<Q> &
  WithPayload<P> &
  WithResponse<R>;

export const withRequestConfigDefaults = <R, Q, P>(
  requestConfig: ElisRequestConfig<R, Q, P>
): ElisRequestConfig<R, Q, P> =>
  defaults(requestConfig, {
    method: requestConfig.method ?? 'GET',
    responseType: requestConfig.responseType ?? 'json',
    authorize: requestConfig.authorize ?? true,
    withRossumRequestOrigin: requestConfig.withRossumRequestOrigin ?? true,
  });

export const asAxiosConfig =
  <R, Q, P>(clientConfig: ElisClientConfig) =>
  (
    requestConfig: ElisRequestConfig<R, Q, P>
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { baseUrl } = clientConfig;
    const { endpoint, method, headers, responseType, signal } = requestConfig;

    return E.right({
      baseURL: endpoint.startsWith('/api/') ? new URL(baseUrl).origin : baseUrl,
      url: endpoint,
      method,
      headers,
      responseType,
      signal,
    });
  };

export const withResponseHeaders =
  <R, Q, P>(
    _clientConfig: ElisClientConfig,
    _requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { responseType } = axiosConfig;

    return E.right(
      pipe(
        axiosConfig,
        merge(
          responseType === 'json'
            ? { headers: { Accept: 'application/json' } }
            : undefined
        )
      )
    );
  };

export const withContentTypeHeaders =
  <R, Q, P>(
    _clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { contentType, payload } = requestConfig;

    return E.right(
      pipe(
        axiosConfig,
        merge(
          typeof payload !== 'undefined'
            ? { headers: { 'Content-Type': contentType ?? 'application/json' } }
            : undefined
        )
      )
    );
  };

export const withAuthorizationHeader =
  <R, Q, P>(
    clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { authorize } = requestConfig;
    const { getAuthToken } = clientConfig;
    return E.right(
      merge(axiosConfig, {
        headers: authorize
          ? { Authorization: `Token ${getAuthToken()}` }
          : undefined,
      })
    );
  };

export const withTrackingHeader =
  <R, Q, P>(
    _clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { withRossumRequestOrigin } = requestConfig;
    return E.right(
      merge(axiosConfig, {
        // headers: withRossumRequestOrigin ? rossumTrackingOrigin : undefined,
      })
    );
  };

export const withAdditionalHeaders =
  <R, Q, P>(
    clientConfig: ElisClientConfig,
    _requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    return E.right(
      merge(axiosConfig, {
        headers: clientConfig.additionalHeaders,
      })
    );
  };

export const withParams =
  <R, Q, P>(
    clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { query, querySchema } = requestConfig;

    if (query && querySchema) {
      const keyTransform =
        clientConfig.convertKeys === 'none' ? identity : toSnakeCase;
      return E.right(
        merge(axiosConfig, {
          params: asQueryEncoder(querySchema, keyTransform).encode(query),
          paramsSerializer: encodeObjectToQuery,
        })
      );
    }

    return E.right(axiosConfig);
  };

export const withData =
  <R, Q, P>(
    clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, Q, P>
  ) =>
  (
    axiosConfig: AxiosRequestConfig
  ): E.Either<ElisClientError, AxiosRequestConfig> => {
    const { payload, payloadSchema } = requestConfig;
    if (!payload || !payloadSchema) {
      return E.right(axiosConfig);
    }

    const keyTransform =
      clientConfig.convertKeys === 'none' ? identity : toSnakeCase;

    const payloadEncoder = asPayloadEncoder(payloadSchema, keyTransform);
    const resolvedPayload =
      axiosConfig.headers?.['Content-Type'] === 'multipart/form-data'
        ? // if we are sending a multipart form with files, create FormData
          payloadToFormData(payloadEncoder.encode(payload))
        : // otherwise business as usual
          payloadEncoder.encode(payload);

    return E.right(
      merge(axiosConfig, {
        data: resolvedPayload,
      })
    );
  };

export const buildAxiosConfig = <R, P, Q>(
  clientConfig: ElisClientConfig,
  requestConfig: ElisRequestConfig<R, P, Q>
): E.Either<ElisClientError, AxiosRequestConfig> =>
  pipe(
    requestConfig,
    withRequestConfigDefaults,
    asAxiosConfig(clientConfig),
    E.chain(withResponseHeaders(clientConfig, requestConfig)),
    E.chain(withContentTypeHeaders(clientConfig, requestConfig)),
    E.chain(withAuthorizationHeader(clientConfig, requestConfig)),
    E.chain(withTrackingHeader(clientConfig, requestConfig)),
    E.chain(withAdditionalHeaders(clientConfig, requestConfig)),
    E.chain(withParams(clientConfig, requestConfig)),
    E.chain(withData(clientConfig, requestConfig))
  );
