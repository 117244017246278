import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { queueGetQuerySchema } from './exportAnnotation.schema';

export type QueueGetQuery = Partial<{
  format: string;
  columns: string;
  id: string;
}>;

export const exportAnnotation = (queueID: ID, query: QueueGetQuery = {}) => {
  return {
    endpoint: `queues/${queueID}/export`,
    method: 'GET',
    query,
    querySchema: queueGetQuerySchema,
    responseType: 'arraybuffer',
  } as const;
};
