import { SxProps, Theme } from '@rossum/ui/material';

export const activeOutlineStyle: SxProps<Theme> = {
  '&:hover': {
    fieldset: {
      borderColor: theme => `${theme.palette.primary.dark} !important`,
    },
  },
  fieldset: {
    borderColor: 'primary.main',
  },
};

export const activeOutlineOpenStyle: SxProps<Theme> = {
  '&:hover': {
    fieldset: {
      borderColor: theme => `${theme.palette.primary.main} !important`,
    },
  },
  fieldset: {
    borderColor: 'primary.main',
  },
};

export const activeLabelStyle: SxProps<Theme> = { color: 'primary.main' };
