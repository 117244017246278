import equal from 'fast-deep-equal/es6/react';
import { negate } from 'lodash';
import React from 'react';

const differs = negate(equal);

export const shouldComponentUpdateDeepEqual = <
  Props,
  State,
  C extends React.Component<Props, State>,
>(
  component: C,
  nextProps: Props,
  nextState: State
) => {
  return (
    differs(nextProps, component.props) || differs(nextState, component.state)
  );
};
