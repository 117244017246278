import { ElisRequestConfig } from '../../utils/requestConfig';
import { getSecretsKeysResponseSchema } from './getSecretsKeys.schema';

export type GetSecretsKeysResponse = string[];

export const getSecretsKeys = (hookId: number) => {
  return {
    endpoint: `/hooks/${hookId}/secrets_keys`,
    method: 'GET',
    responseSchema: getSecretsKeysResponseSchema,
  } as const;
};
