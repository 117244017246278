import { ElisRequestConfig } from '../../utils/requestConfig';
import { Inbox } from '../models';
import { inboxSchema } from '../models/inbox.schema';
import { createInboxPayloadSchema } from './create.schema';

export type CreateInboxPayload = {
  name: string;
  emailPrefix: string;
  bounceEmailTo?: string;
  queues: Array<string>;
  filters?: { allowedSenders: Array<string> };
};

export const create = (payload: CreateInboxPayload) => {
  return {
    endpoint: `/inboxes`,
    method: 'POST',
    responseSchema: inboxSchema,
    payloadSchema: createInboxPayloadSchema,
    payload,
  } as const;
};
