import * as React from 'react';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../types/state';
import AnimatedHeader, { AnimationState } from './components/AnimatedHeader';

type LoginAnimationContextT = {
  setTitle: (title: string) => void;
  animationState: AnimationState;
  setAnimationState: Dispatch<SetStateAction<AnimationState>>;
  setLoginUnmounted: Dispatch<SetStateAction<boolean>>;
};

export const LoginAnimationContext = createContext<LoginAnimationContextT>({
  setTitle: () => {},
  animationState: 'hidden',
  setAnimationState: () => {},
  setLoginUnmounted: () => {},
});

export const LoginAnimationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isLoggedIn = useSelector((state: State) => state.user.username !== '');

  // helper state for avoiding rerender when local is changed after login in
  // TODO: move context inside INTL provider and remove this state after fixing [AC-903]
  const [title, setTitle] = useState('');
  const [animationDone, setAnimationDone] = useState(false);
  const [loginUnmounted, setLoginUnmounted] = useState(false);

  const [animationState, setAnimationState] =
    useState<AnimationState>('hidden');

  const resetLocalStates = () => {
    setAnimationDone(false);
    setLoginUnmounted(false);
  };

  useEffect(() => {
    if (isLoggedIn && animationDone) {
      setAnimationState('hidden'); // hide animation
      resetLocalStates(); // reset helpers for next login
    }
  }, [isLoggedIn, setAnimationState, animationDone]);

  useEffect(() => {
    if (loginUnmounted && animationState === 'ready') {
      setAnimationState('hidden');
    }
  }, [animationState, loginUnmounted, setLoginUnmounted]);

  return (
    <LoginAnimationContext.Provider
      value={{
        setTitle,
        animationState,
        setAnimationState,
        setLoginUnmounted,
      }}
    >
      {animationState !== 'hidden' && (
        <AnimatedHeader
          title={title}
          animationState={animationState}
          setAnimationDone={setAnimationDone}
        />
      )}
      {children}
    </LoginAnimationContext.Provider>
  );
};
