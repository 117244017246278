import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Inbox } from '../models';
import { inboxSchema } from '../models/inbox.schema';
import { listInboxesQuerySchema } from './list.schema';

export type ListInboxesQuery = {
  page?: number;
};
export const list = (query: ListInboxesQuery) => {
  return {
    method: 'GET',
    endpoint: '/inboxes',
    responseSchema: listResponse(inboxSchema),
    query,
    querySchema: listInboxesQuerySchema,
  } as const;
};
