import { BackupOutlined } from '@rossum/ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Input,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@rossum/ui/material';
import { HtmlHTMLAttributes, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

type UploadAreaProps = {
  ButtonProps: ButtonProps<'label'> & { 'data-cy': string };
  DropAreaProps?: {
    dropAreaMessage?: string;
    dropAreaIcon?: ReactNode;
    dropAreaOutlineColor?: string;
  };
  fileList: File[];
  uploadInputProps: HtmlHTMLAttributes<HTMLInputElement>;
  sx?: SxProps<Theme>;
};

const UploadArea = ({
  ButtonProps,
  DropAreaProps,
  fileList,
  uploadInputProps,
  sx = { py: 2, mx: 4 },
}: UploadAreaProps) => {
  const intl = useIntl();

  const [dragging, setDragging] = useState(false);

  return (
    <Box
      component="span"
      onDragEnter={() => setDragging(true)}
      sx={{
        ...sx,
        border: '1px dashed',
        borderRadius: 2,
        display: 'flex',
        alignSelf: 'stretch',
        alignContent: 'center',
        justifyContent: 'center',
        borderColor: dragging
          ? 'primary.main'
          : DropAreaProps?.dropAreaOutlineColor ?? 'text.disabled',
        position: 'relative',
      }}
    >
      <Stack spacing={2} alignItems="center" my={1} justifyContent="center">
        <>
          {DropAreaProps?.dropAreaIcon || (
            <BackupOutlined fontSize="large" sx={{ color: 'text.secondary' }} />
          )}
          <Typography variant="body1" sx={{ fontWeight: 400 }}>
            {DropAreaProps?.dropAreaMessage ||
              intl.formatMessage({
                id: 'containers.annotationList.upload.dragndrop',
              })}
          </Typography>
          <Button {...ButtonProps} component="label">
            <Typography fontSize="small">
              {intl.formatMessage({
                id: 'containers.annotationList.upload.clickButton',
              })}
            </Typography>
            <Input
              key={`input-button-${fileList.length}`}
              inputProps={uploadInputProps}
              sx={{
                display: 'none',
              }}
            />
          </Button>
        </>
      </Stack>
      <input
        {...uploadInputProps}
        key={`input-drag-and-drop-${fileList.length}`}
        onDragLeave={() => setDragging(false)}
        onDrop={() => setDragging(false)}
        style={{
          display: 'none',
          top: 0,
          ...(dragging && {
            position: 'absolute',
            display: 'block',
            opacity: 0,
            height: '100%',
            width: '100%',
            background: 'white',
            zIndex: 1,
          }),
        }}
      />
    </Box>
  );
};

export default UploadArea;
