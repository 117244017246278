import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { GenerateRejectionPayload } from '@rossum/api-client/internal';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { api } from '../../../../../lib/apiClient';
import { throwError } from '../../../../../redux/modules/messages/actions';

export const useGenerateRejectionEmail = (
  handleOnSuccess: (body: string) => void
) => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: GenerateRejectionPayload) =>
      api.request(endpoints.internal.generateRejectionEmail(payload)),

    onError: (e: HttpError) => {
      // do not throw on throttled request error
      if (e.code !== 429) {
        dispatch(throwError('generateRejectionEmailError'));
      }
    },

    onSuccess: response => handleOnSuccess(response.emailBody),
  });
};
