import { endpoints } from '@rossum/api-client';
import { ElisClientError } from '@rossum/api-client/errors';
import { HooksListQuery } from '@rossum/api-client/hooks';
import { Hook } from '@rossum/api-client/hooks';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_HOOKS_UNPAGINATED = 'hooks-unpaginated';

export const useUnpaginatedHooks = <TReturnedData = Hook[]>(
  query: HooksListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      Hook[],
      ElisClientError,
      TReturnedData,
      readonly [typeof QUERY_KEY_HOOKS_UNPAGINATED, HooksListQuery]
    >,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery({
    queryKey: [QUERY_KEY_HOOKS_UNPAGINATED, query] as const,
    queryFn: ({ queryKey }) =>
      api.unpaginatedRequest(endpoints.hooks.list)(queryKey[1]),
    ...queryOptions,
  });
};
