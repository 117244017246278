import { ActionType, createAction } from 'typesafe-actions';
import { BeforeLeaveCurrent } from './types';

export const resumeValidation = createAction('RESUME_VALIDATION')<void>();

type PauseValidationPayload = BeforeLeaveCurrent;

export const pauseValidation = createAction(
  'PAUSE_VALIDATION',
  (payload: PauseValidationPayload) => payload
)<PauseValidationPayload>();

export const clearActions = createAction('CLEAR_ACTIONS')<void>();

export type BeforeLeaveActions = ActionType<
  typeof resumeValidation | typeof pauseValidation | typeof clearActions
>;
