import { SVGProps } from 'react';

const IconEditDocument = ({
  viewBoxWidth,
  viewBoxHeight,
  ...props
}: SVGProps<SVGSVGElement> & {
  viewBoxWidth: number;
  viewBoxHeight: number;
}) => (
  <svg {...props} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
    <path
      fill={props.fill}
      d="M1,10 C1,13.2529927 2.72583572,16.1028949 5.31183446,17.6840338 L6.9732666,16 L9.973,19 L10,19 L10,19.027 L10.9732666,20 L10,20 L9.72009516,19.9961582 C4.41966086,19.8504873 0.149512653,15.5803391 0.00384179753,10.2799048 L0,10 L1,10 Z M8.05885716,2.98462185 C9.39252242,2.62726732 10.7633648,3.41872354 11.1207193,4.7523888 C11.4337619,5.92067957 10.8652347,7.11749917 9.82290828,7.63656815 L10.456,10 L17,10 L17,11 L10.724,11 L12.2946962,16.8611368 L11.3287704,17.1199559 L9.688,11 L7.44990271,11.0004345 C7.22868585,12.0892344 6.29984403,12.9209832 5.16437569,12.9946823 L5,13 C3.61928813,13 2.5,11.8807119 2.5,10.5 C2.5,9.11928813 3.61928813,8 5,8 C6.20968176,8 7.2186941,8.85916789 7.4501074,10.000574 L9.421,10 L8.85601581,7.89541873 C7.74727967,7.96326477 6.70377447,7.28139547 6.33877025,6.20388243 L6.29109021,6.04648403 C5.93373568,4.71281877 6.7251919,3.34197638 8.05885716,2.98462185 Z M5,9 C4.17157288,9 3.5,9.67157288 3.5,10.5 C3.5,11.3284271 4.17157288,12 5,12 C5.82842712,12 6.5,11.3284271 6.5,10.5 C6.5,9.67157288 5.82842712,9 5,9 Z M10,-6.25099972e-12 C15.4292399,-6.25099972e-12 19.8479317,4.32667079 19.9961582,9.72009516 L20,10 L19,10 C19,6.74742796 17.2746106,3.89784221 14.6891687,2.31657965 L13.0267334,4 L10.026,1 L10,1 L10,0.974 L9.0267334,-6.25099972e-12 L10,-6.25099972e-12 Z M10.1547935,5.01120785 C9.9403808,4.21100869 9.11787536,3.73613496 8.31767621,3.95054768 C7.51747705,4.16496039 7.04260332,4.98746583 7.25701604,5.78766498 C7.47142875,6.58786414 8.29393419,7.06273787 9.09413334,6.84832516 C9.8943325,6.63391244 10.3692062,5.811407 10.1547935,5.01120785 Z"
    />
  </svg>
);

export default IconEditDocument;
