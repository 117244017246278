// Generated by ts-to-zod
import { z } from 'zod';
import { hookTypeSchema } from './hook.schema';
import {
  annotationContentActionSchema,
  annotationStatusActionSchema,
  uploadActionSchema,
  emailActionSchema,
  invocationActionSchema,
} from './hookUtils.schema';

export const logLevelSchema = z.union([
  z.literal('INFO'),
  z.literal('WARNING'),
  z.literal('ERROR'),
]);

const logRequestSchema = z.string();

const logResponseSchema = z.string();

const logBaseSchema = z.object({
  hookId: z.number(),
  hookType: hookTypeSchema,
  logLevel: logLevelSchema,
  message: z.string(),
  queueId: z.number().optional().nullable(),
  request: logRequestSchema.nullable(),
  requestId: z.string(),
  response: logResponseSchema.nullable(),
  timestamp: z.string(),
});

const annotationContentLogSchema = logBaseSchema.and(
  z.object({
    annotationId: z.number().optional().nullable(),
    event: z.literal('annotation_content'),
    action: annotationContentActionSchema,
  })
);

const annotationStatusLogSchema = logBaseSchema.and(
  z.object({
    annotationId: z.number().optional().nullable(),
    event: z.literal('annotation_status'),
    action: annotationStatusActionSchema,
  })
);

const uploadLogSchema = logBaseSchema.and(
  z.object({
    emailId: z.number().optional().nullable(),
    event: z.literal('upload'),
    action: uploadActionSchema,
  })
);

const emailLogSchema = logBaseSchema.and(
  z.object({
    emailId: z.number().optional().nullable(),
    event: z.literal('email'),
    action: emailActionSchema,
  })
);

const invocationLogSchema = logBaseSchema.and(
  z.object({
    event: z.literal('invocation'),
    action: invocationActionSchema,
  })
);

export const logSchema = z.union([
  annotationContentLogSchema,
  annotationStatusLogSchema,
  uploadLogSchema,
  emailLogSchema,
  invocationLogSchema,
]);
