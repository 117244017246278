import { ID, Url } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Connector } from '../models/connector';
import { connectorSchema } from '../models/connector.schema';
import { connectorListQuerySchema } from './list.schema';

export type ConnectorListQuery = Partial<{
  id: ID | ID[];
  name: string;
  serviceUrl: Url;
}> &
  PaginationQuery;

export const list = (query: ConnectorListQuery = {}) => {
  return {
    endpoint: `/connectors`,
    method: 'GET',
    responseSchema: listResponse(connectorSchema),
    query,
    querySchema: connectorListQuerySchema,
  } as const;
};
