import { alpha, Box, BoxProps, styled } from '@rossum/ui/material';
import {
  CONTROL_BAR_DISTANCE,
  GRID_LINE_WIDTH,
  INTERACTIVE_AREA_SIZE,
} from './constants';

export type SeparatorLineProps = BoxProps & {
  orientation: 'horizontal' | 'vertical';
  deleteButtonHovered: boolean;
  willBeDeletedByResize: boolean;
  isActive: boolean;
  isDragging: boolean;
  interactive: boolean;
  disabled: boolean;
};

export const SeparatorLine = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'orientation' &&
    propName !== 'deleteButtonHovered' &&
    propName !== 'willBeDeletedByResize' &&
    propName !== 'isActive' &&
    propName !== 'isDragging' &&
    propName !== 'interactive' &&
    propName !== 'disabled',
})<SeparatorLineProps>(({
  theme,
  orientation,
  deleteButtonHovered,
  willBeDeletedByResize,
  isActive,
  isDragging,
  interactive,
  disabled,
}) => {
  const [primaryDim, secondaryDim, primaryPos, secondaryPos] =
    orientation === 'horizontal'
      ? (['width', 'height', 'top', 'left'] as const)
      : (['height', 'width', 'left', 'top'] as const);

  return {
    position: 'absolute',
    [primaryDim]: `calc(100% + ${
      deleteButtonHovered ? CONTROL_BAR_DISTANCE : 0
    }px)`,
    [secondaryDim]: 2 * INTERACTIVE_AREA_SIZE + GRID_LINE_WIDTH,
    [primaryPos]: -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE,
    [secondaryPos]: deleteButtonHovered ? -CONTROL_BAR_DISTANCE : 0,
    borderColor: isActive
      ? alpha(theme.palette.background.paper, 0.1)
      : 'transparent',
    borderStyle: 'solid',
    borderWidth:
      orientation === 'horizontal'
        ? `${INTERACTIVE_AREA_SIZE}px 0`
        : `0 ${INTERACTIVE_AREA_SIZE}px`,
    backgroundClip: 'content-box',
    backgroundColor: disabled
      ? alpha(theme.palette.common.black, theme.palette.action.disabledOpacity)
      : deleteButtonHovered || willBeDeletedByResize
        ? alpha(theme.palette.warning.main, 0.5)
        : isActive
          ? theme.palette.primary.main
          : alpha(theme.palette.primary.main, 0.5),
    cursor: isDragging
      ? 'grabbing'
      : disabled || !interactive
        ? 'unset'
        : orientation === 'horizontal'
          ? 'row-resize'
          : 'col-resize',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.short,
    }),
    pointerEvents: disabled ? 'none' : 'auto',
    ...(disabled || !interactive
      ? {}
      : {
          [`&:hover`]: {
            backgroundColor: theme.palette.primary.main,
            borderColor: alpha(theme.palette.background.paper, 0.1),
          },
        }),
  };
});
