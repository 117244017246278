import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteEndpoint = (workspaceId: ID) => {
  return {
    endpoint: `/workspaces/${workspaceId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
