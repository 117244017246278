import { useDispatch, useSelector } from 'react-redux';
import AutomationBlockersTopBar from '../../components/UI/AutomationBlockers';
import { automationBlockersSelector } from '../../redux/modules/annotation/selectors';
import { hideBlockers, showBlockers } from '../../redux/modules/ui/actions';
import { State } from '../../types/state';

const AutomationBlockersContainer = () => {
  const blockers = useSelector(automationBlockersSelector);
  const fieldAutomationBlockersVisible = useSelector(
    (state: State) => state.ui.fieldAutomationBlockersVisible
  );
  const readOnly = useSelector(
    (state: State) => state.annotation.status !== 'reviewing'
  );
  const dispatch = useDispatch();
  return (
    <AutomationBlockersTopBar
      blockers={blockers}
      hideBlockers={() => dispatch(hideBlockers({ readOnly }))}
      isBlockersVisible={fieldAutomationBlockersVisible}
      showBlockers={() => dispatch(showBlockers())}
    />
  );
};

export default AutomationBlockersContainer;
