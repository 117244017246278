import { yupResolver } from '@hookform/resolvers/yup';
import { Save } from '@rossum/ui/icons';
import { Box, Button, Dialog, Stack, Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import TextFieldControl from '../../../../components/ReactHookForm/controls/TextFieldControl';

type FormModel = {
  name: string;
};

type Props = {
  defaultName: string;
  isOpen: boolean;
  onSubmit: (model: FormModel) => void;
  onCancel: () => void;
};

const NewTemplateNameModal = ({
  defaultName,
  isOpen,
  onSubmit,
  onCancel,
}: Props) => {
  const intl = useIntl();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(
        intl.formatMessage({ id: 'components.emailSent.modal.nameIsRequired' })
      ),
  });

  const { handleSubmit, control } = useForm({
    mode: 'onTouched',
    defaultValues: { name: defaultName },
    resolver: yupResolver(validationSchema),
  });

  return (
    <Dialog open={isOpen} onClose={() => onCancel()}>
      <Box margin={2} sx={{ width: '400px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography variant="h6">
              {intl.formatMessage({ id: 'components.emailSent.modal.title' })}
            </Typography>
            <TextFieldControl
              ControllerProps={{ control, name: 'name' }}
              label={intl.formatMessage({
                id: 'components.emailSent.modal.templateName',
              })}
              helperText={intl.formatMessage({
                id: 'components.emailSent.modal.canEditInQueueSettings',
              })}
              FieldLabelProps={{
                layout: 'floating',
              }}
            />
            <Stack spacing={1} direction="row" justifyContent="flex-end">
              <Button onClick={() => onCancel()} type="reset">
                {intl.formatMessage({
                  id: 'components.emailSent.modal.cancel',
                })}
              </Button>
              <Button
                type="submit"
                startIcon={<Save />}
                data-cy="save-template-via-modal"
              >
                {intl.formatMessage({ id: 'components.emailSent.modal.save' })}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Dialog>
  );
};

export default NewTemplateNameModal;
