import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Groups } from '../../../types/group';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { fetchGroupsFulfilled } from './actions';

const initialState = Immutable<Groups>([]);

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchGroupsFulfilled):
      return Immutable(action.payload.results);

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
      return initialState;

    default:
      return state;
  }
};

export default reducer;
