// Generated by ts-to-zod
import { z } from 'zod';

export const annotationsSearchQuerySchema = z
  .object({
    pageSize: z.number(),
    searchAfter: z.string(),
    ordering: z.string(),
    'content.schema_id': z.array(z.string()),
  })
  .partial();

const queryConditionModelSchema = z.record(z.unknown());

export const annotationsSearchPayloadSchema = z.object({
  query: queryConditionModelSchema,
  queryString: z
    .object({
      string: z.string(),
    })
    .optional(),
});
