// Fix bug with pluralization on Safari 12 and iOS12
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import { ReactNode } from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import { connect, ConnectedComponent } from 'react-redux';
import locales, { markValuesWithLineNumbers } from '../../i18n';
import { State } from '../../types/state';

const mapStateToProps = (state: State) => ({
  locale: state.locale.locale,
  messages: markValuesWithLineNumbers(locales)[state.locale.locale],
  key: state.locale.locale,
});

export default connect(mapStateToProps)(IntlProvider) as ConnectedComponent<
  typeof IntlProvider,
  Pick<
    IntlConfig,
    | 'onError'
    | 'formats'
    | 'timeZone'
    | 'textComponent'
    | 'defaultLocale'
    | 'wrapRichTextChunksInFragment'
  > & { children: ReactNode }
>;
