import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { EmailTemplateStats } from '../models';
import { emailTemplateStatsSchema } from '../models/emailTemplateStats.schema';
import { emailTemplateStatsListQuerySchema } from './list.schema';

export type EmailTemplateStatsListQuery = {
  queue?: number;
  pageSize?: number;
  page?: number;
};

export const list = (query: EmailTemplateStatsListQuery = {}) => {
  return {
    endpoint: `/email_templates/stats`,
    method: 'GET',
    responseSchema: listResponse(emailTemplateStatsSchema),
    query,
    querySchema: emailTemplateStatsListQuerySchema,
  } as const;
};
