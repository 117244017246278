export const defaultValues = {
  rirFieldNames: null,
  format: null,
  defaultValue: null,
  hidden: false,
  canExport: true,
  canCollapse: false,
  disablePrediction: false,
  width: null,
  stretch: false,
  scoreThreshold: null,
  constraints: { required: true },
};
