import { Stack, Typography } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import FooterEmptyIcon from '../../../components/icons/FooterEmpty';
import { white } from '../../../lib/formaterValues';
import { findCurrentGrids } from '../../../redux/modules/magicGrid/selector';

const EmptyFooter = () => {
  const someGridExists = useSelector(findCurrentGrids).length > 0;

  return (
    <Stack
      justifyContent="center"
      direction="column"
      spacing={2}
      alignItems="center"
      marginTop={2}
    >
      {someGridExists ? (
        <>
          <Typography textAlign="center">
            <FormattedMessage id="containers.footer.emptyFooter.extractAll.title" />
          </Typography>
          <Typography color="text.secondary" textAlign="center">
            <FormattedMessage
              id="containers.footer.emptyFooter.extractAll.description"
              values={{ white }}
            />
          </Typography>
        </>
      ) : (
        <>
          <FooterEmptyIcon />
          <Typography textAlign="center">
            <FormattedMessage id="containers.footer.emptyFooter.title" />
          </Typography>
          <Typography color="text.secondary" textAlign="center">
            <FormattedMessage id="containers.footer.emptyFooter.description" />
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default EmptyFooter;
