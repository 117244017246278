import { Skeleton, Stack, Typography } from '@rossum/ui/material';
import { useSelector } from 'react-redux';
import { queuesSelector } from '../../redux/modules/queues/selector';
import { findWorkspaceByQueue } from '../../redux/modules/workspaces/selector';
import { Url } from '../../types/basic';
import { State } from '../../types/state';

export const FilenameWorkspaceQueueInfo = ({
  filename,
  queueUrl,
}: {
  filename: string | null;
  queueUrl: Url | null;
}) => {
  const queue = useSelector((state: State) =>
    queuesSelector(state).find(queue => queue.url === queueUrl)
  );

  const workspace = useSelector((state: State) =>
    queue ? findWorkspaceByQueue(state.workspaces.list, queue) : null
  );

  const queueAndWorkspace = !queueUrl ? null : queue && workspace ? (
    <span>
      {workspace.name} - {queue.name}
    </span>
  ) : (
    <Skeleton sx={{ minWidth: '100px' }} />
  );

  return (
    <Typography
      variant="caption"
      component={Stack}
      direction="row"
      spacing={1}
      divider={
        <Typography variant="caption" color="text.disabled">
          |
        </Typography>
      }
    >
      {filename ? <span>{filename}</span> : null}
      {queueAndWorkspace}
    </Typography>
  );
};
