import { DeleteOutlined } from '@rossum/ui/icons';
import { IconButton, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { KeyboardShortcut } from '../../../../../components/UI/KeyboardShortcut';

type DeleteButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

export const DeleteButton = ({ disabled, onClick }: DeleteButtonProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        disabled ? (
          ''
        ) : (
          <KeyboardShortcut
            description={intl.formatMessage({
              id: 'components.documentValidation.validationDialog.button.delete.tooltip',
            })}
          />
        )
      }
      key="delete-button"
    >
      <IconButton
        color="secondary"
        size="medium"
        data-cy="validation-dialog-delete"
        onClick={onClick}
        disabled={disabled}
        sx={{ p: 0.5, minWidth: 0 }}
      >
        <DeleteOutlined />
      </IconButton>
    </Tooltip>
  );
};
