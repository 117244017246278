import * as Sentry from '@sentry/react';
import { ReactNode, useState } from 'react';
import { isEmbedded } from '../../../../constants/config';
import { GlobalErrorFallbackScreen } from '../GlobalErrorFallbackScreen';

type GlobalErrorBoundaryProps = {
  children?: ReactNode;
};

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
  const [lastError, setLastError] = useState<string | null>(null);

  const handleReset = () => {
    if (isEmbedded()) {
      window.location.reload();
    } else {
      window.location.replace(`/`);
    }
  };

  const handleError = (error: unknown) => {
    if (window.Rollbar) {
      window.Rollbar.configure({
        onSendCallback: (
          _1: unknown,
          _2: unknown,
          { uuid }: { uuid: string }
        ) => {
          setLastError(uuid);
        },
      });

      window.Rollbar.error(error, {
        error,
        recent_actions: window.recent_actions || [],
      });
    }

    if (window.playwrightError && error instanceof Error) {
      window.playwrightError(error);
    }
  };

  return (
    <Sentry.ErrorBoundary
      onReset={handleReset}
      onError={handleError}
      fallback={({ resetError }) => (
        <GlobalErrorFallbackScreen
          lastError={lastError}
          resetErrorBoundary={() => resetError()}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
