import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import { useMemo } from 'react';
import { getLocalVariables } from './getLocalVariables';
import { getAceCompleter, getFieldCompletions } from './helpers';
import templateCompletions from './templateCompletions.json';

export const useDefaultCompleters = (
  fields: Array<SchemaSection | SchemaItem>
) => {
  const defaultCompleters = useMemo(() => {
    const fieldsCompletions = getFieldCompletions(fields, true);
    const customCompleters = [fieldsCompletions, templateCompletions].map(c =>
      getAceCompleter(c)
    );

    return [...customCompleters, getLocalVariables];
  }, [fields]);

  return defaultCompleters;
};
