import { createContext, ReactNode, useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { StoreApi, UseBoundStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { State } from '../../../types/state';
import {
  UseCanvasDimensions,
  useCanvasDimensions,
} from '../document-canvas-svg/useCanvasDimensions';
import { createDocumentStore, DocumentStoreType } from './createDocumentStore';

const context = createContext<{
  store: UseBoundStore<StoreApi<DocumentStoreType>> | undefined;
  context: { dimensions: UseCanvasDimensions };
}>({
  store: undefined,
  context: { dimensions: { canvas: { width: 0, height: 0 }, pages: [] } },
});

type DocumentStoreProps = {
  children: ReactNode;
};

export const DocumentStore = ({ children }: DocumentStoreProps) => {
  const useStore = useRef(createDocumentStore()).current;
  const pages = useSelector((state: State) => state.pages.pages ?? []);
  const dimensions = useCanvasDimensions(pages);

  return (
    <context.Provider
      value={{
        store: useStore,
        context: { dimensions },
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useDocumentStore = <T,>(
  selector: (state: DocumentStoreType) => T
) => {
  const storeContext = useContext(context).store;

  if (!storeContext) {
    throw new Error('Document store used outside of the context.');
  }

  return storeContext(selector);
};

export const useDocumentStoreContext = () => {
  return useContext(context).context;
};

export const useCanvasGeometryState = () =>
  useDocumentStore(state => state.canvasState);

export const useCanvasGeometryActions = () => {
  const { dimensions } = useDocumentStoreContext();

  const actions = useDocumentStore(
    useShallow(state => ({
      zoomBy: state.zoomBy,
      resetZoom: state.resetZoom,
      translateBy: state.translateBy,
      translateTo: state.translateTo,
      translateIntoViewport: state.translateIntoViewport,
      translateGridIntoViewport: state.translateGridIntoViewport,
    }))
  );

  return useMemo(
    () => ({
      zoomBy: actions.zoomBy(dimensions.canvas),
      resetZoom: actions.resetZoom(dimensions.canvas),
      translateBy: actions.translateBy(dimensions.canvas),
      translateTo: actions.translateTo,
      translateIntoViewport: actions.translateIntoViewport(dimensions.canvas),
      translateGridIntoViewport: actions.translateGridIntoViewport(
        dimensions.canvas
      ),
    }),
    [actions, dimensions.canvas]
  );
};

export const useCanvasSelectionActions = () => {
  return useDocumentStore(
    useShallow(state => ({
      clearSelectedBboxes: state.clearSelectedBboxes,
      addToSelectedBboxes: state.addToSelectedBboxes,
      removeFromSelectedBboxes: state.removeFromSelectedBboxes,
      setSelectedBboxes: state.setSelectedBboxes,
    }))
  );
};
