import {
  matchPath,
  Route,
  RouteProps,
  RouterChildContext,
} from 'react-router-dom';

interface OwnProps {
  onExit?: () => void;
  onEnter?: () => void;
}
interface Props extends RouteProps, OwnProps {}

class CustomRoute extends Route<Props> {
  computeMatch(
    {
      computedMatch,
      location,
      path,
      strict,
      exact,
      sensitive,
    }: Props & { computedMatch?: unknown },
    router: RouterChildContext['router']
  ) {
    if (computedMatch) return computedMatch;

    const { route } = router;
    const { pathname } = location || route.location;

    return matchPath(pathname, { path, strict, exact, sensitive }, route.match);
  }

  static defaultProps: OwnProps = {
    onExit: undefined,
    onEnter: undefined,
  };

  componentDidMount() {
    this.props.onEnter?.();
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Props,
    nextContext: RouterChildContext
  ) {
    if (nextProps.path !== this.props.path) {
      this.props.onExit?.();
      nextProps.onEnter?.();
    }
    this.setState({
      match: this.computeMatch(nextProps, nextContext.router),
    });
  }

  componentWillUnmount() {
    this.props.onExit?.();
  }
}

export default CustomRoute;
