import { ReactNode, useRef } from 'react';
import { Rectangle2D } from '../../document-store/helpers/geometry';
import { DocumentPageContext } from './useDocumentPageContext';

type PageSvgProps = {
  pageDimensions: Rectangle2D;
  children: ReactNode;
};

export const PageSvg = ({ pageDimensions, children }: PageSvgProps) => {
  const pageRef = useRef<SVGSVGElement>(null);
  return (
    <DocumentPageContext.Provider value={{ pageDimensions, pageRef }}>
      <svg
        ref={pageRef}
        x={pageDimensions.x}
        y={pageDimensions.y}
        width={pageDimensions.width}
        height={pageDimensions.height}
        viewBox={`0 0 ${pageDimensions.width} ${pageDimensions.height}`}
        style={{ overflow: 'visible' }}
      >
        {children}
      </svg>
    </DocumentPageContext.Provider>
  );
};
