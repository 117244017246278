import { endpoints, ID, Url } from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

type SaveDeleteRecommendationArgs = {
  id?: ID | undefined;
  payload: {
    triggers?: Url[];
    enabled?: boolean;
  };
};

export const useSaveDeleteRecommendation = (
  queueUrl: Url,
  onSuccess: () => void
) => {
  return useMutation({
    mutationFn: ({ id, payload }: SaveDeleteRecommendationArgs) => {
      return id
        ? api.request(
            endpoints.deleteRecommendations.patch(id, {
              ...payload,
              // To fix B/E response:
              // Triggers must belong to the same queue as DeleteRecommendation
              queue: queueUrl,
            })
          )
        : api.request(
            endpoints.deleteRecommendations.create({
              queue: queueUrl,
              triggers: payload.triggers || [],
              enabled: payload.enabled || false,
            })
          );
    },

    onSuccess,
  });
};
