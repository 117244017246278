// Generated by ts-to-zod
import { z } from 'zod';

export const confirmPasswordPayloadSchema = z.object({
  token: z.string(),
  uid: z.string(),
  username: z.string(),
  new_password1: z.string(),
  new_password2: z.string(),
});
