import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { State } from '../../../types/state';
import { CardInfo } from '../../../ui/card-info/CardInfo';
import { Email } from '../api-client';
import { truncateEmail } from '../helpers';

type EmailCardHeaderSubtitleProps = {
  email: Email;
  chip?: ReactNode;
};

export const EmailCardHeaderSubtitle = ({
  email,
  chip,
}: EmailCardHeaderSubtitleProps) => {
  const intl = useIntl();
  const currentUserUrl = useSelector((state: State) => state.user.url);

  const emailSentByCurrentUser = email?.creator === currentUserUrl;

  const { truncatedEmail } = truncateEmail(email?.from?.email);

  const fromText = intl.formatMessage(
    {
      id: `components.emailCard.${
        email?.type === 'incoming'
          ? 'receivedFrom'
          : emailSentByCurrentUser
            ? 'sentByYou'
            : 'sentBy'
      }`,
    },
    {
      emailSender: email?.from?.name || truncatedEmail,
    }
  );

  return (
    <CardInfo
      date={email?.createdAt}
      fromTooltip={email?.from?.email}
      fromText={fromText}
      appendix={chip ?? null}
    />
  );
};
