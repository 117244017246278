import { alpha, MenuItem, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

export const AutocompleteGroup = ({ children }: { children: ReactNode }) => (
  <MenuItem
    disabled
    sx={{
      '&.Mui-disabled': { opacity: 1 },
      borderTop: 1,
      marginTop: 1,
      borderTopColor: theme => alpha(theme.palette.text.primary, 0.1),
    }}
  >
    <Typography variant="overline" color="text.secondary">
      {children}
    </Typography>
  </MenuItem>
);
