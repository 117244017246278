import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const getBlob = (documentUrl: Url) => {
  return {
    endpoint: documentUrl,
    method: 'GET',
    responseType: 'blob',
  } as const;
};
