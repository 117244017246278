import { combineEpics } from 'redux-observable';
import { zip } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { isNonEmptyArray } from '../../../lib/typeGuards';
import { fetchGroupsFulfilled } from '../groups/actions';
import { ANNOTATION_STACK, setItem } from '../localStorage/actions';
import { fetchOrgGroupFulfilled } from '../organizationGroup/actions';
import { isActionOf, makeEpic } from '../utils';
import { initializeTrackingAction } from './actions';

export const enableTrackingObserver = makeEpic((action$, state$) =>
  zip(
    state$.pipe(
      map(state => state.organization),
      filter(
        ({ uiSettings, id }) =>
          id !== undefined &&
          (uiSettings?.trackingEnabled === undefined ||
            uiSettings.trackingEnabled)
      ),
      map(({ isTrial }) => isTrial),
      distinctUntilChanged()
    ),
    action$.pipe(filter(isActionOf(fetchOrgGroupFulfilled))),
    action$.pipe(filter(isActionOf(fetchGroupsFulfilled)))
  ).pipe(map(([isTrial]) => initializeTrackingAction(isTrial === true)))
);

const cacheAnnotationStackObserver = makeEpic((_, state$) =>
  state$.pipe(
    map(state => state.stack),
    filter(isNonEmptyArray),
    distinctUntilChanged(),
    map(stack => setItem(ANNOTATION_STACK, stack.toString()))
  )
);

export default combineEpics(
  enableTrackingObserver,
  cacheAnnotationStackObserver
);
