import { Queue } from '@rossum/api-client/queues';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ChoiceDialog,
  SelectDialogProps,
} from '../../../ui/choice-dialog/ChoiceDialog';
import { QueueList } from './QueueList';

type QueueDialogProps = Omit<
  SelectDialogProps,
  'onConfirm' | 'submitText' | 'cancelText' | 'children'
> & {
  onConfirm: (queue: Queue) => void;
  submitText?: SelectDialogProps['submitText'];
  cancelText?: SelectDialogProps['cancelText'];
};

/**
 * Controller variant of Select queue dialog with pre-filled defaults.
 */
export const QueueDialog = ({
  onConfirm,
  submitText,
  cancelText,
  isDisabled,
  ...props
}: QueueDialogProps) => {
  const intl = useIntl();
  const [selectedQueue, setSelectedQueue] = useState<Queue | undefined>();

  return (
    <ChoiceDialog
      submitText={
        submitText ??
        intl.formatMessage({
          id: 'components.selectQueueDialog.submit',
        })
      }
      cancelText={
        cancelText ??
        intl.formatMessage({
          id: 'components.selectQueueModal.cancel',
        })
      }
      isDisabled={isDisabled || selectedQueue === undefined}
      {...props}
      onConfirm={() => {
        if (selectedQueue) {
          onConfirm(selectedQueue);
        }
      }}
    >
      {props.open && (
        <QueueList value={selectedQueue} onChange={setSelectedQueue} />
      )}
    </ChoiceDialog>
  );
};
