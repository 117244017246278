import { ActionType, createAction } from 'typesafe-actions';
import { Note } from '../../../types/notes';
import { Pagination } from '../../../types/pagination';

export type NoteData = {
  pagination: Pagination;
  results: Note[];
};

export const fetchNoteFulfilled = createAction(
  'FETCH_NOTE_FULFILLED',
  (payload: NoteData) => payload
)<NoteData>();

export type NoteActions = ActionType<typeof fetchNoteFulfilled>;
