import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

const magicFunction = (x: number, a = 0.5, b = 0, c = 1) =>
  Math.sin(Math.cos(0.5 * a * x + b) + c ** 2 + 1);

const getTempSeriesDates = ({
  beginDate,
  endDate,
  groupBy,
}: {
  beginDate: string;
  endDate: string;
  groupBy: string;
}) => {
  const start = startOfDay(new Date(beginDate));
  const end = endOfDay(new Date(endDate));

  switch (groupBy) {
    case 'day':
      return eachDayOfInterval({ start, end }).map(day => ({
        beginDate: startOfDay(day).toString(),
        endDate: endOfDay(day).toString(),
      }));
    case 'week':
      return eachWeekOfInterval(
        {
          start: startOfWeek(start, { weekStartsOn: 1 }),
          end: endOfWeek(end, { weekStartsOn: 1 }),
        },
        { weekStartsOn: 1 }
      ).map(week => ({
        beginDate: startOfDay(week).toString(),
        endDate: endOfWeek(week, { weekStartsOn: 1 }).toString(),
      }));
    default:
      return eachMonthOfInterval({
        end: endOfMonth(end),
        start: startOfMonth(start),
      }).map(month => ({
        beginDate: startOfDay(month).toString(),
        endDate: endOfDay(month).toString(),
      }));
  }
};

const mapSerieToDummyData = (
  serie: {
    beginDate: string;
    endDate: string;
  },
  i: number
) => {
  const tmpValue = magicFunction(i);
  const tmpValue2 = magicFunction(i, 0.3, 1, 0);
  const tmpValue3 = magicFunction(i, 0.7, 1, 0);

  return {
    ...serie,
    values: {
      importedCount: tmpValue,
      deletedCount: tmpValue3,
      exportedCount: tmpValue2,
      turnaroundAvgS: tmpValue,
      correctionsPerDocumentAvg: tmpValue2,
      exportedOnTimeCount: tmpValue3,
      exportedLateCount: tmpValue,
      exportedAutomatedCount: tmpValue,
      rejectedAutomaticallyCount: tmpValue,
      rejectedManuallyCount: tmpValue,
      timePerDocumentAvgS: tmpValue,
      timeAndCorrectionsPerField: [],
    },
  };
};

export const getDummyData = (input: {
  beginDate: string;
  endDate: string;
  groupBy: string;
}) => {
  const series = getTempSeriesDates(input);
  return series.map(mapSerieToDummyData);
};
