import { useIntl } from 'react-intl';
import { PreviewDrawer } from '../../ui/PreviewDrawer';
import { EnumAndFormulaCellValue } from '../columns/types';
import { FormulaPreview } from './components/FormulaPreview';

type FormulaDrawerProps = {
  onClose: () => void;
  formulaForDrawer: {
    formula: string;
    value: EnumAndFormulaCellValue;
  } | null;
};

export const FormulaDrawer = ({
  onClose,
  formulaForDrawer,
}: FormulaDrawerProps) => {
  const intl = useIntl();
  return (
    <PreviewDrawer
      onClose={onClose}
      open={!!formulaForDrawer}
      title={formulaForDrawer?.value.label ?? ''}
      subtitle={`${formulaForDrawer?.value.count ?? 0} ${intl.formatMessage(
        {
          id: 'features.fieldManager.fieldDetail.enumFormulaEditing.formulaDrawer.subtitle',
        },
        { count: formulaForDrawer?.value.count }
      )}`}
      elevation={3}
      content={<FormulaPreview value={formulaForDrawer?.formula ?? ''} />}
    />
  );
};
