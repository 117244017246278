export const getUnleashDomainName = (appUrl: string, apiUrl: string) => {
  if (apiUrl.startsWith('/')) {
    return new URL(appUrl).hostname;
  }

  if (apiUrl.includes('.review.')) {
    return 'elis.develop.r8.lol';
  }
  return new URL(apiUrl).hostname.replace(/^api\./, '');
};

export const getUnleashUrl = (appUrl: string, apiUrl: string) =>
  `https://${getUnleashDomainName(appUrl, apiUrl)}/unleash/proxy`;
