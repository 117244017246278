import { compact } from 'lodash';
import { Reducer } from 'redux';
import Immutable from 'seamless-immutable';
import { getType } from 'typesafe-actions';
import { Stack } from '../../../types/stack';
import { RootActionType } from '../../rootActions';
import { ANNOTATION_STACK } from '../localStorage/actions';
import { startValidation } from '../ui/actions';
import { fetchAnnotationStackFulfilled, resetAnnotationStack } from './actions';

export const getCachedStack = () =>
  compact((localStorage.getItem(ANNOTATION_STACK) || '').split(',')).map(
    Number
  ) || [];

const initialState = Immutable<Stack>(getCachedStack());

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAnnotationStackFulfilled):
      return Immutable(action.payload);

    case getType(resetAnnotationStack):
    case getType(startValidation):
      return Immutable([]);
    default:
      return state;
  }
};

export default reducer;
