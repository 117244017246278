import clsx from 'clsx';
import TriangleIcon from 'mdi-react/MenuUpIcon';
import styles from './styles.module.sass';

type Props = {
  disabled?: boolean;
  expanded?: boolean;
};

const Triangle = ({ disabled, expanded }: Props) => (
  <div className={styles.IconWrapper}>
    <TriangleIcon
      size={26}
      className={clsx(
        styles.TriangleIcon,
        !disabled && styles.ToReviewTriangle,
        expanded ? styles.ExpandedIcon : styles.CollapsedIcon
      )}
    />
  </div>
);

Triangle.defaultProps = {
  disabled: false,
  expanded: false,
};

export default Triangle;
