import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DeleteRecommendation } from '../models';
import { deleteRecommendationSchema } from '../models/deleteRecommendation.schema';
import { deleteRecommendationListQueryEncoderSchema } from './list.schema';

export type DeleteRecommendationListQueryEncoder = {
  queue?: number;
};

export const list = (query: DeleteRecommendationListQueryEncoder = {}) => {
  return {
    endpoint: `/delete_recommendations`,
    method: 'GET',
    responseSchema: listResponse(deleteRecommendationSchema),
    query,
    querySchema: deleteRecommendationListQueryEncoderSchema,
  } as const;
};
