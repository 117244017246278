import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';
import { GraphTooltipProps } from './types';

type Props = GraphTooltipProps & {
  name: 'correctionsPerDocumentAvg';
};

const NumberTooltip = ({ label, payload, name }: Props) => (
  <div className={styles.TooltipContent}>
    <div className={styles.TooltipLabel}>{label}</div>
    <div>
      <FormattedMessage
        id={`components.graphs.${name}.unit`}
        values={{ count: get(payload, '0.value') }}
      />
    </div>
  </div>
);

export default NumberTooltip;
