import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { UploadRestrictor } from '../../../components/Restrictors';

type Props = {
  onClick: () => void;
  disabled: boolean;
};
export const UploadButton = ({ onClick, disabled }: Props) => {
  const intl = useIntl();

  return (
    <UploadRestrictor>
      <Button
        color="secondary"
        variant="outlined"
        data-cy="upload-btn"
        disabled={disabled}
        onClick={onClick}
      >
        {intl.formatMessage({
          id: 'components.dashboard.queues.upload',
        })}
      </Button>
    </UploadRestrictor>
  );
};
