import * as E from 'fp-ts/lib/Either';
import * as D from 'io-ts/lib/Decoder';
import { identity } from 'lodash';
import { DecodingError, ElisClientError } from '../errors';
import { asResponseDecoder, fromSnakeCase } from '../utils';
import { ElisClientConfig } from './client';
import { ElisRequestConfig } from './requestConfig';

export const asDecoded =
  <R, P, Q>(
    clientConfig: ElisClientConfig,
    requestConfig: ElisRequestConfig<R, P, Q>
  ) =>
  (data: unknown): E.Either<ElisClientError, R> => {
    if (requestConfig.responseType !== 'json') {
      return E.right(data as R);
    }

    const { endpoint, responseSchema } = requestConfig;
    const keyTransform =
      clientConfig.convertKeys === 'none' ? identity : fromSnakeCase;

    const tried = asResponseDecoder(responseSchema, keyTransform).decode(data);

    if (E.isLeft(tried)) {
      return E.left(
        new DecodingError({
          endpoint,
          decodingOutput: D.draw(tried.left),
        })
      );
    }

    // as R to fix strict null checks - temporary
    return E.right(tried.right as R);
  };
