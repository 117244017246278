import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';

type UseDrawerAnnotationParams = {
  annotationUrl: string;
};

export const useDrawerAnnotation = ({
  annotationUrl,
}: UseDrawerAnnotationParams) => {
  const api = useApiClient();
  return useQuery({
    queryKey: ['workflows', 'drawerAnnotationInfo', annotationUrl] as const,

    queryFn: () => {
      return api.request(
        endpoints.annotations.get(getIDFromUrl(annotationUrl))
      );
    },
  });
};
