import { Skeleton, Stack } from '@rossum/ui/material';
import { TILE_HEIGHT } from './SchemaFieldTileContent';

export const FieldsListSkeleton = () => {
  return (
    <Stack p={4} spacing={16}>
      <Stack justifyContent="center" alignItems="flex-start" spacing={1}>
        <Skeleton width="20%" height="36px" />
        <Skeleton height={TILE_HEIGHT} width="100%" variant="rounded" />
        <Skeleton height={TILE_HEIGHT} width="100%" variant="rounded" />
      </Stack>
      <Stack justifyContent="center" alignItems="flex-start" spacing={1}>
        <Skeleton width="20%" height="36px" />
        <Skeleton height={TILE_HEIGHT} width="100%" variant="rounded" />
        <Skeleton height={TILE_HEIGHT} width="100%" variant="rounded" />
        <Skeleton height={TILE_HEIGHT} width="100%" variant="rounded" />
      </Stack>
    </Stack>
  );
};
