// Generated by ts-to-zod
import { z } from 'zod';
import { sectionDataSchema } from '../../../annotations/models/annotationData.schema';
import { schemaSectionSchema } from '../../../schemas/models/schemaSection.schema';
import { messageSchema } from '../../../shared/models/message.schema';

export const evaluateFormulasPayloadSchema = z.object({
  schemaContent: z.array(schemaSectionSchema),
  annotationContent: z.array(sectionDataSchema),
});

export const automationBlockerSchema = z.object({
  content: z.string(),
  id: z.string(),
});

export const evaluateFormulasResponseSchema = z.object({
  annotationContent: z.array(sectionDataSchema),
  automationBlockers: z.array(automationBlockerSchema),
  messages: z.array(messageSchema),
});
