import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentDatapointHasBbox } from '../datapoints/selector';
import { annotationProductTourSteps } from './useProductTour';

export const useDataCaptureTour = () => {
  const intl = useIntl();
  const datapointHasBbox = useSelector(currentDatapointHasBbox);

  const steps = useMemo(
    () => annotationProductTourSteps(intl, datapointHasBbox),
    [datapointHasBbox, intl]
  );

  return { steps };
};
