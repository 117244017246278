import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { ListResponse, listResponse } from '../../utils/listResponse';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { WorkflowActivity } from '../models';
import { workflowActivitySchema } from '../models/workflowActivity.schema';
import { workflowActivitiesListQuerySchema } from './list.schema';

export type WorkflowActivitiesListQuery = Partial<{
  annotation: ID;
}> &
  PaginationQuery;

export const list = (query: WorkflowActivitiesListQuery = {}) => {
  return {
    endpoint: `/workflow_activities`,
    method: 'GET',
    responseSchema: listResponse(workflowActivitySchema),
    query,
    querySchema: workflowActivitiesListQuerySchema,
  } as const;
};
