import { FieldLabel, FieldLabelProps } from '@rossum/rossum-ui/FieldLabel';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Typography,
} from '@rossum/ui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { LabeledFieldProps, SimpleControlProps } from '../utils';

type SingleCheckboxControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<
  CheckboxProps,
  | 'checkedIcon'
  | 'color'
  | 'disableRipple'
  | 'icon'
  | 'id'
  | 'indeterminateIcon'
  | 'required'
  | 'disabled'
  | 'size'
> &
  Pick<FormControlLabelProps, 'label'> &
  SimpleControlProps<TFieldValues, TName> &
  LabeledFieldProps & {
    labelPlacement?: FormControlLabelProps['labelPlacement'];
  };

const SingleCheckboxControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ControllerProps: { control, name },
  FieldLabelProps,
  label,
  labelPlacement = 'end',
  disabled,
  required,
  ...checkboxProps
}: SingleCheckboxControlProps<TFieldValues, TName>) => {
  const { id } = checkboxProps;
  const resolvedFieldLabelProps: FieldLabelProps = {
    htmlFor: id ?? name,
    required,
    label,
    ...FieldLabelProps,
  };

  return (
    <FieldLabel {...resolvedFieldLabelProps}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState, formState }) => (
          <FormControl>
            <FormControlLabel
              disabled={disabled || formState.isSubmitting}
              aria-disabled={disabled || formState.isSubmitting}
              value={field.value}
              onChange={() => field.onChange(!field.value)}
              control={
                <Checkbox
                  checked={field.value}
                  size="small"
                  {...checkboxProps}
                />
              }
              componentsProps={{
                typography: {
                  variant: 'body2',
                },
              }}
              label={
                required ? (
                  <>
                    {label}
                    <Typography
                      component="sup"
                      variant="caption"
                      color="error"
                      sx={{ pl: 0.25 }}
                    >
                      *
                    </Typography>
                  </>
                ) : (
                  label
                )
              }
              labelPlacement={labelPlacement}
            />
            {fieldState.invalid && (
              <FormHelperText error sx={{ mt: 0 }}>
                {fieldState.error?.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </FieldLabel>
  );
};

export default SingleCheckboxControl;
