import { Typography } from '@rossum/ui/material';
import { GridRenderCellParams, GridRowModel } from '@rossum/ui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { TransformedAnnotation } from '../hooks/useTransformAnnotation';

type SimpleQueueCellValue = TransformedAnnotation['queueName'];

export const SimpleQueue = ({
  value,
}: GridRenderCellParams<GridRowModel, SimpleQueueCellValue>) => {
  const intl = useIntl();

  const queueName =
    value ?? intl.formatMessage({ id: 'components.duplicates.unknownQueue' });

  return <Typography variant="body2">{queueName}</Typography>;
};
