import update from 'immutability-helper';
import { compact, findIndex, findLast, sortBy } from 'lodash';
import { PayloadMetaAction } from 'typesafe-actions';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { ID } from '../../../types/basic';
import {
  AnyDatapointDataST,
  Children,
  DatapointsST,
  Grid,
  MultivalueDatapointData,
  MultivalueDatapointDataST,
  RowPosition,
  TupleDatapointData,
  TupleDatapointDataST,
} from '../../../types/datapoints';
import {
  flattenDatapointTree,
  updateIndexes,
} from '../datapoints/typedHelpers';
import { insertArrayInArray } from '../utils';
import { UpdateColumnGridMeta, UpdateGridActionMeta } from './actions';

type UpdateColumnGridActionType = {
  payload: { grid: Grid };
  meta: UpdateColumnGridMeta;
};

type UpdateGridActionType = {
  type: string;
  meta: UpdateGridActionMeta;
  payload: { grid: Grid };
};

// Todo memo
export const getUpdatedTuples = (
  state: DatapointsST,
  action: UpdateColumnGridActionType,
  options?: { rowIndexes?: number[] }
) => {
  const {
    meta: { datapointIndex },
  } = action;

  const parentDatapoint = state.content[datapointIndex];

  const resolvedRowIndexes: number[] = options?.rowIndexes ?? [];

  const updatedTupleIds = resolvedRowIndexes.length
    ? // To filter by rowIndexes, we can't use getTupleIds, because
      // it filters out null values, so the indexes wouldn't match
      action.payload.grid.rows
        .map(({ tupleId }) => tupleId)
        .filter(
          (tupleId, index) =>
            tupleId !== null && resolvedRowIndexes.includes(index)
        )
    : getTupleIds(action.payload.grid);

  return (parentDatapoint as MultivalueDatapointDataST).children.filter(
    ({ id }) => updatedTupleIds.includes(id)
  );
};

export const getAllDatapointIds = (
  state: DatapointsST,
  action: UpdateColumnGridActionType
): number[] => {
  const updatedTuples = getUpdatedTuples(state, action);

  return updatedTuples.reduce<number[]>((acc, { id, index }) => {
    const dp = state.content[index];

    if (!dp || dp.category !== 'multivalue') {
      return acc;
    }

    const childIds = dp.children.map(({ id }: Children) => id);

    return [...acc, id, ...childIds];
  }, []);
};

export const getUpdatedDatapointIds = (
  state: DatapointsST,
  action: UpdateColumnGridActionType,
  options?: { rowIndexes?: number[] }
): number[] => {
  const updatedTuples = getUpdatedTuples(state, action, options);
  return updatedTuples.flatMap(({ index }) => {
    const dp = state.content[index];

    if (!dp || dp.category !== 'tuple') {
      return [];
    }

    return dp.children.flatMap(({ id }: Children) => id);
  });
};

// TODO memo
export const findGridIndex = (
  state: DatapointsST,
  // action: UpdateColumnGridActionType
  action: PayloadMetaAction<
    string,
    unknown,
    { datapointIndex: number; page: number }
  >
) => {
  const {
    meta: { datapointIndex, page },
  } = action;

  const dp = state.content[datapointIndex];

  if (!dp || dp.category !== 'multivalue' || !dp.grid) {
    return undefined;
  }

  return dp.grid.parts.findIndex(p => p.page === page);
};

// TODO memo
export const getTupleIds = (grid: Grid) =>
  grid.rows.map(({ tupleId }) => tupleId).filter(isNotNullOrUndefined);

export const updateGrid = (
  state: DatapointsST,
  action: UpdateGridActionType
) => {
  const {
    payload: { grid },
    meta: { datapointIndex, gridIndex },
  } = action;

  // we are creating grid at correct index now so we have to be careful about CREATE_GRID_FULFILLED
  return update(state, {
    content: {
      [datapointIndex]: {
        grid: {
          parts: {
            $apply: (grids: Grid[]) => {
              if (action.type === 'CREATE_GRID_FULFILLED') {
                return insertArrayInArray(gridIndex, [grid])(grids);
              }

              return grids.map((originalGrid, index) =>
                index === gridIndex ? grid : originalGrid
              );
            },
          },
        },
      },
    },
  });
};

// TODO memo
export const getUpdatedDatapointIdsForColumns =
  (state: DatapointsST, action: UpdateColumnGridActionType) =>
  (schemaIds: string[]): number[] => {
    const updatedTuples = getUpdatedTuples(state, action);

    if (!updatedTuples.length) {
      return [];
    }

    const firstRow = state.content[updatedTuples[0].index];

    if (firstRow.category !== 'tuple') {
      return [];
    }

    const relativeIndexes = schemaIds.map(columnId => {
      const column = firstRow.children.find(({ index }) => {
        const child = state.content[index];
        return child.schemaId === columnId;
      });

      if (column) {
        return column.index - firstRow.meta.index;
      }

      return 0;
    });

    const updatedDatapointIndexes = updatedTuples.flatMap(({ index }) =>
      relativeIndexes.map(relativeIndex => index + relativeIndex)
    );

    return updatedDatapointIndexes.map(index => state.content[index].id);
  };

// TODO memo
export const getCreatedTuples = (
  state: DatapointsST,
  datapointIndex: number,
  gridIndex: number,
  newDatapoints: MultivalueDatapointData[]
): TupleDatapointData[] => {
  const dp = state.content[datapointIndex];

  if (!dp || dp.category !== 'multivalue') {
    return [];
  }

  const gridPart = dp.grid?.parts[gridIndex];

  if (!gridPart) {
    return [];
  }

  const currentTupleIds = getTupleIds(gridPart);

  return newDatapoints.flatMap(dp =>
    dp.children.flatMap(c =>
      // catgory check just for type inference, we know all will be tuples
      c.category === 'tuple' && !currentTupleIds.includes(c.id) ? [c] : []
    )
  );
};

export const getCreatedDatapointIds = (
  state: DatapointsST,
  datapointIndex: number,
  gridIndex: number,
  newDatapoints: MultivalueDatapointData[]
) =>
  getCreatedTuples(state, datapointIndex, gridIndex, newDatapoints).flatMap(
    tuple => [tuple.id, ...tuple.children.map(({ id }) => id)]
  );

// reducer

const getLastUsedTupleIdBefore = (
  content: AnyDatapointDataST[],
  newGrid: Grid,
  parentDatapointIndex: number,
  firstDatapoint: TupleDatapointData
): number | undefined => {
  const firstDatapointIndex = newGrid.rows.findIndex(
    ({ tupleId }: RowPosition) => firstDatapoint.id === tupleId
  );

  const lastUsedTupleDatapointIdBefore = findLast(
    newGrid.rows.slice(0, firstDatapointIndex),
    ({ tupleId }) => tupleId !== null
  )?.tupleId;

  if (lastUsedTupleDatapointIdBefore) return lastUsedTupleDatapointIdBefore;

  const dp = content[parentDatapointIndex];

  if (!dp || dp.category !== 'multivalue') {
    return undefined;
  }

  const sortedGrids = sortBy(dp.grid?.parts, 'page');

  const newGridIndex = findIndex(sortedGrids, { page: newGrid.page });

  if (newGridIndex === 0) {
    return undefined;
  }

  const tupleIds = sortedGrids
    .slice(0, newGridIndex)
    .flatMap(({ rows }) => rows.map(({ tupleId }: RowPosition) => tupleId));

  return findLast(tupleIds) ?? undefined;
};

const sortCreatedTuples = (createdTuples: TupleDatapointData[], grid: Grid) => {
  const tupleIDs = grid.rows.map(({ tupleId }) => tupleId);

  return createdTuples.sort(
    (a, b) => tupleIDs.indexOf(a.id) - tupleIDs.indexOf(b.id)
  );
};

const removeOldTuples = (
  datapoints: AnyDatapointDataST[],
  gridIndex: number | undefined,
  parentDatapointIndex: number
): AnyDatapointDataST[] => {
  const dp = datapoints[parentDatapointIndex];

  if (!dp || dp.category !== 'multivalue' || !dp.grid?.parts) {
    return datapoints;
  }

  const oldTupleIds: number[] =
    gridIndex !== undefined
      ? compact(dp.grid.parts[gridIndex]?.rows?.map(({ tupleId }) => tupleId))
      : [];

  return removeTuplesFromContent(oldTupleIds)(datapoints).map((dp, index) =>
    index === parentDatapointIndex && dp.category === 'multivalue'
      ? removeTuplesFromParent(oldTupleIds)(dp)
      : dp
  );
};

export const addNewTuples = (
  datapoint: MultivalueDatapointData,
  content: AnyDatapointDataST[],
  createdTuples: TupleDatapointData[],
  createdTupleIds: number[],
  fullResponse: boolean,
  complexLineItemsEnabled: boolean,
  gridIndex?: number
) => {
  if (!datapoint.grid) {
    return [];
  }

  const {
    grid: { parts },
  } = datapoint;

  const datapointState = content.find(({ id }) => id === datapoint.id);

  if (!datapointState) {
    return [];
  }

  const parentDatapointIndex = datapointState.meta.index;

  const cleanContent = fullResponse
    ? removeOldTuples(content, gridIndex, parentDatapointIndex)
    : content;

  const parentDatapoint = cleanContent[
    parentDatapointIndex
  ] as unknown as MultivalueDatapointDataST;

  const withNewGrid = update(cleanContent, {
    [parentDatapoint.meta.index]: {
      grid: {
        parts: {
          $apply: (grids: Grid[]) =>
            insertArrayInArray(
              gridIndex === undefined ? grids.length : gridIndex,
              parts
              // When user manualy creates a grid there is a tmp grid in redux state
            )(grids.filter((_, index) => index !== gridIndex)),
        },
      },
    },
  });

  const sortedCreatedTuples = fullResponse
    ? sortCreatedTuples(createdTuples, parts[0])
    : createdTuples;

  const firstDatapoint = sortedCreatedTuples[0];

  const lastUsedTupleDatapointIdBefore = getLastUsedTupleIdBefore(
    withNewGrid,
    parts[0], // Grid should be sorted by pages when created
    parentDatapoint.meta.index,
    firstDatapoint
  );

  const lastUsedDatapoint = lastUsedTupleDatapointIdBefore
    ? cleanContent.find(dp => lastUsedTupleDatapointIdBefore === dp.id)
    : undefined;

  const lastUsedIndex = lastUsedDatapoint
    ? lastUsedDatapoint.meta.index + firstDatapoint.children.length
    : parentDatapoint.meta.index;

  const createdDatapointPart = {
    ...datapoint,
    children: fullResponse
      ? datapoint.children.map(dp =>
          dp.category === 'tuple'
            ? {
                ...dp,
                children: sortBy(dp.children, child =>
                  dp.schema?.children?.indexOf(child.schemaId)
                ),
              }
            : dp
        )
      : datapoint.children.filter(({ id }) => createdTupleIds.includes(id)),
  };

  const {
    datapoints: [newParentDatapoint, ...newDatapoints],
  } = flattenDatapointTree(
    createdDatapointPart,
    complexLineItemsEnabled,
    createdDatapointPart.id,
    false,
    lastUsedIndex
  );

  if (!newParentDatapoint || newParentDatapoint.category !== 'multivalue') {
    return [];
  }

  const indexInChildren =
    lastUsedIndex === parentDatapoint.meta.index
      ? -1
      : findIndex(
          parentDatapoint.children,
          ({ id }) => id === lastUsedTupleDatapointIdBefore
        );

  const withUpdatedIndexes = updateIndexes({
    startIndex: lastUsedIndex + 1,
    amount: newDatapoints.length,
  })(withNewGrid);

  return insertArrayInArray(
    lastUsedIndex + 1,
    newDatapoints
  )(
    update(withUpdatedIndexes, {
      [parentDatapoint.meta.index]: {
        children: {
          $apply: (children: Children[]) =>
            insertArrayInArray(
              indexInChildren + 1,
              newParentDatapoint.children
            )(children),
        },
      },
    })
  );
};

export const removeTuplesFromParent =
  (deletedTupleIds: ID[]) => (parentDatapoint: MultivalueDatapointDataST) => {
    if (!deletedTupleIds.length) return parentDatapoint;

    return update(parentDatapoint, {
      children: {
        $apply: (children: Children[]) =>
          children.filter(child => !deletedTupleIds.includes(child.id)),
      },
    });
  };

export const removeTuplesFromContent =
  (deletedTupleIds: ID[]) => (datapoints: AnyDatapointDataST[]) => {
    if (!deletedTupleIds.length) return datapoints;

    // Sometimes there are non-extracted rows with tupleId set in backend state
    // this is invalid and the method then crashes
    // BE is trying to ensure that doesn't happen but it probably can't be fixed on existing documents?
    // this ignores grid tupleIds which don't have a corresponding datapoint
    const existingIds = new Set(datapoints.map(d => d.id));

    const validDeletedTupleIds = deletedTupleIds.filter(id =>
      existingIds.has(id)
    );

    if (!validDeletedTupleIds.length) return datapoints;

    const firstDeletedTuple = datapoints.find(
      (dp): dp is TupleDatapointDataST =>
        dp.category === 'tuple' && dp.id === validDeletedTupleIds[0]
    );

    if (!firstDeletedTuple) return datapoints;

    const lastDeletedTupleIndex =
      firstDeletedTuple.meta.index +
      (firstDeletedTuple.children.length + 1) * validDeletedTupleIds.length;

    const contentWithoutDeleted = datapoints
      .slice(0, firstDeletedTuple.meta.index)
      .concat(datapoints.slice(lastDeletedTupleIndex));

    return updateIndexes({
      startIndex: firstDeletedTuple.meta.index,
      amount: firstDeletedTuple.meta.index - lastDeletedTupleIndex,
    })(contentWithoutDeleted);
  };
