import { ReactNode } from 'react';

/**
 * tx/ty are in unscaled coordinates for zoom level 1.
 */
type ScaleToViewportProps = {
  children: ReactNode;
  tx: number;
  ty: number;
  factor: number;
};

export const ScaleToViewport = ({
  children,
  tx,
  ty,
  factor,
}: ScaleToViewportProps) => {
  const inv = 1 / factor;
  const transform = `translate(${tx * inv}px, ${ty * inv}px) scale(${inv})`;
  return <div style={{ transform }}>{children}</div>;
};
