import { Redo, Undo } from '@rossum/ui/icons';
import {
  alpha,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import { ReactNode, useRef } from 'react';
import ReactAce from 'react-ace/lib/ace';
import { IAceEditor } from 'react-ace/lib/types';
import { useIntl } from 'react-intl';
import { KeyboardShortcut } from '../../../../components/UI/KeyboardShortcut';

type Props = {
  editor: ReactAce;
  renderButtons?: ({ editor }: { editor: IAceEditor }) => ReactNode;
};

const FormulaEditorToolbar = (props: Props) => {
  const intl = useIntl();
  const { editor } = props.editor;
  const undoManager = useRef(editor.session.getUndoManager());
  const undoDisabled = !undoManager.current.hasUndo();
  const redoDisabled = !undoManager.current.hasRedo();

  const withFocusHandler = (action: () => void) => () => {
    action();
    editor.focus();
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme => alpha(theme.palette.action.active, 0.05),
        borderBottom: '1px solid',
        borderBottomColor: 'other.muted',
        py: 1,
        px: 2,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip
            title={
              undoDisabled ? (
                ''
              ) : (
                <KeyboardShortcut
                  keyName="ctrlZ"
                  description={intl.formatMessage({
                    id: 'containers.settings.fields.edit.formula.toolbar.undo.tooltip',
                  })}
                />
              )
            }
          >
            <IconButton
              size="small"
              onClick={withFocusHandler(() => editor.undo())}
              disabled={undoDisabled}
            >
              <Undo fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              redoDisabled ? (
                ''
              ) : (
                <KeyboardShortcut
                  keyName="ctrlShiftZ"
                  description={intl.formatMessage({
                    id: 'containers.settings.fields.edit.formula.toolbar.redo.tooltip',
                  })}
                />
              )
            }
          >
            <IconButton
              size="small"
              onClick={withFocusHandler(() => editor.redo())}
              disabled={redoDisabled}
            >
              <Redo fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      {props.renderButtons?.({ editor }) ?? null}
    </Stack>
  );
};

export default FormulaEditorToolbar;
