import { ID } from '../../utils/codecUtils';
import { PaginationQuery } from '../../utils/listQuery';
import { paginationQuerySchema } from '../../utils/listQuery.schema';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { BillingHistoryListResponse } from '../models/billingHistory';
import { billingHistoryListResponseSchema } from '../models/billingHistory.schema';

export const getHistory =
  (endpoint: 'organizations' | 'organization_groups') =>
  (id: ID, query: PaginationQuery) => {
    return {
      endpoint: `/${endpoint}/${id}/billing_history`,
      method: 'GET',
      responseSchema: billingHistoryListResponseSchema,
      query,
      querySchema: paginationQuerySchema,
    } as const;
  };
