import { Button, ButtonProps } from '@rossum/ui/material';

export const ContainedIconButton = ({ sx, ...rest }: ButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      color: theme => theme.palette.common.white,
      p: '2px',
      minWidth: 'unset',
      ...sx,
    }}
    {...rest}
  />
);
