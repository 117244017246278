import {
  ExitToAppOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@rossum/ui/icons';
import { alpha, SvgIconProps } from '@rossum/ui/material';
import { ExitCrossedOut } from '../../../../../components/icons/ExitCrossedOut';
import { Mandatory } from '../../../../../components/icons/Mandatory';
import { Optional } from '../../../../../components/icons/Optional';
import { FieldsFormModel } from '../../form-model/formModels';

export type QuickActionField = keyof Pick<
  FieldsFormModel['field'],
  'exported' | 'hidden' | 'required'
>;

type QuickActionIcons = Record<
  QuickActionField,
  Record<'on' | 'off', React.FunctionComponent<SvgIconProps>>
>;

const icons: QuickActionIcons = {
  hidden: {
    on: VisibilityOutlined,
    off: VisibilityOffOutlined,
  },
  exported: {
    on: ExitToAppOutlined,
    off: ExitCrossedOut,
  },
  required: {
    on: Mandatory,
    off: Optional,
  },
};

type QuickActionIconProps = {
  fieldKey: QuickActionField;
  state: boolean;
  disabled?: boolean;
};

export const QuickActionIcon = ({
  fieldKey,
  state,
  disabled,
}: QuickActionIconProps) => {
  const Icon = icons[fieldKey][state ? 'on' : 'off'];

  return (
    <Icon
      fontSize="small"
      sx={{
        color: theme =>
          disabled
            ? theme.palette.action.disabled
            : state
              ? theme.palette.success.main
              : alpha(theme.palette.action.active, 0.56),
      }}
    />
  );
};
