import { z } from 'zod';
import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const deleteRelation = (relationId: ID) => {
  return {
    endpoint: `/relations/${relationId}`,
    method: 'DELETE',
    responseSchema: z.unknown(),
  } as const;
};
