import { alpha, Box, Stack, useTheme } from '@rossum/ui/material';
import { getScrollbarColors } from '@rossum/ui/theme';
import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SIDEBAR_WIDTH } from '../../constants/values';
import makeResizable from '../../decorators/makeResizable';
import { useCanvasDimensions } from '../../features/annotation-view/document-canvas-svg/useCanvasDimensions';
import { useDocumentStore } from '../../features/annotation-view/document-store/DocumentStore';
import { visiblePageZustandSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import {
  currentMultivalueDatapointSelector,
  datapointPathSelector,
  getTableDatapoints,
} from '../../redux/modules/datapoints/selector';
import { suggestedOperationsOptionsSelector } from '../../redux/modules/datapoints/suggestedOperations/selector';
import { isVirtualDatapoint } from '../../redux/modules/datapoints/typedHelpers';
import { updateUiSettings } from '../../redux/modules/user/actions';
import {
  footerLayoutSelector,
  sortFooterColumnsSelector,
} from '../../redux/modules/user/selectors';
import { State } from '../../types/state';
import { FooterLayout, SortFooterColumns } from '../../types/user';
import { AggregationForCurrentColumn } from './AggregationForCurrentColumn';
import { PasteGridButton } from './components/PasteGridButton';
import SearchAndReplace from './components/SearchAndReplace';
import { SuggestedOperationsActions } from './components/SuggestedOperationsActions';
import { FooterTableList } from './FooterTableList';
import styles from './style.module.sass';

type FooterProps = {
  // AFI: this is optional because makeResizable seems to have wrong types -> revisit later
  height?: number;
};

// Should match $footerZIndex SASS variable
const FOOTER_Z_INDEX = 10003;

export const Footer = ({ height }: FooterProps) => {
  const dispatch = useDispatch();
  const pages = useSelector((state: State) => state.pages.pages ?? []);
  const dimensions = useCanvasDimensions(pages);
  const layout = useSelector(footerLayoutSelector);
  const sortFooterColumns = useSelector(sortFooterColumnsSelector);
  const scrollRef = useRef<HTMLElement>();
  const currentMultivalue = useSelector(currentMultivalueDatapointSelector);
  const theme = useTheme();

  const { thumbColor, trackColor } = getScrollbarColors(theme);

  const { pageNumber: visiblePageNumberSVG } = useDocumentStore(
    visiblePageZustandSelector(dimensions)
  );

  const visiblePageNumber = visiblePageNumberSVG;

  const updateFooterLayout = useCallback(
    (newLayout: FooterLayout) => {
      dispatch(updateUiSettings({ footerLayout: newLayout }));
    },
    [dispatch]
  );

  const updateSortFooterColumns = useCallback(
    (newSortFooterColumns: SortFooterColumns) => {
      dispatch(updateUiSettings({ sortFooterColumns: newSortFooterColumns }));
    },
    [dispatch]
  );

  const updateComplexLineItems = useCallback(
    (cli: boolean) => {
      dispatch(updateUiSettings({ complexLineItems: cli }));
    },
    [dispatch]
  );

  const readOnly = useSelector(
    (state: State) =>
      state.ui.readOnly ||
      suggestedOperationsOptionsSelector(state).rowsCount > 0 ||
      !!state.ui.actionInProgress
  );

  useEffect(() => {
    // Skip initial render - scroll only when user changes the layout.
    // Otherwise it doesn't work properly, when a footer is opened
    // with already selected datapoint.
    if (layout) {
      scrollRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [layout]);

  if (
    currentMultivalue === null ||
    currentMultivalue.meta.isSimpleMultivalue === true
  ) {
    return null;
  }

  return (
    <Stack
      id="footer"
      direction="column"
      alignItems="center"
      style={{ height: `${height}px` }}
      sx={{
        position: 'fixed',
        bottom: 0,
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        // FOOTER_Z_INDEX - 1 is so that the slide animation of FooterSelectionPanel does not overflow on the sidebar.
        zIndex: FOOTER_Z_INDEX - 1,
        borderTop: theme =>
          `1px solid ${
            theme.palette.mode === 'dark'
              ? alpha(theme.palette.common.white, 0.12)
              : alpha(theme.palette.common.black, 0.12)
          }`,
      }}
    >
      {/* In principle, these styles could be on the parent, but it seems it doesn't work properly with overflow */}
      <Box
        ref={scrollRef}
        sx={{
          // This css property prevents f.e. browser navigation with swipe gesture when user reaches the end of the container
          overscrollBehaviorX: 'contain',
          overflow: 'scroll',
          scrollbarColor: 'auto',
          [`&::-webkit-scrollbar`]: {
            width: 8,
            height: 8,
            backgroundColor: `${trackColor}`,
          },
          [`&::-webkit-scrollbar-thumb`]: {
            backgroundColor: `${thumbColor}`,
            borderRadius: 8,
          },
          width: '100%',
          height: '100%',
          // background color is here, so that this element can cover suggested operations panel
          backgroundColor: theme => theme.palette.background.paper,
        }}
      >
        <FooterTableList
          layout={layout}
          onFooterLayoutChanged={updateFooterLayout}
          sortFooterColumns={sortFooterColumns}
          onSortFooterColumnsChanged={updateSortFooterColumns}
          onComplexLineItemsChanged={updateComplexLineItems}
          scrolledElement={scrollRef.current}
          readOnly={readOnly}
          currentMultivalue={currentMultivalue}
        />
      </Box>
      <SuggestedOperationsActionsWrapper />
      {visiblePageNumber && <PasteGridButton pageNumber={visiblePageNumber} />}
      <AggregationForCurrentColumn scrollContainer={scrollRef.current} />
      {!readOnly && <SearchAndReplaceWrapper />}
    </Stack>
  );
};

// AFI: After the new footer is removed, all selectors can be moved directly into SuggestedOperationsActions component
const SuggestedOperationsActionsWrapper = () => {
  const suggestedOperationsOptions = useSelector(
    suggestedOperationsOptionsSelector
  );
  const suggestingOperations = useSelector(
    (state: State) => state.ui.suggestingOperations
  );
  const tuplesCount = useSelector(
    (state: State) =>
      getTableDatapoints(state)?.filter(dp => !isVirtualDatapoint(dp.id))
        .length ?? 0
  );
  const datapointPath = useSelector(datapointPathSelector);

  return (
    <SuggestedOperationsActions
      tuplesCount={tuplesCount}
      suggestedOperationsOptions={suggestedOperationsOptions}
      suggestingOperations={suggestingOperations}
      datapointPath={datapointPath}
    />
  );
};

const SEARCH_AND_REPLACE_DIALOG_WIDTH = 224;

const SearchAndReplaceWrapper = () => {
  const { currentColumn, columnRightClientX } = useSelector(
    (state: State) => state.searchAndReplace
  );

  const leftSearchPanelCoord = Math.max(
    0,
    columnRightClientX - SIDEBAR_WIDTH - SEARCH_AND_REPLACE_DIALOG_WIDTH
  );

  return (
    <>
      {!!currentColumn &&
        ReactDOM.createPortal(
          <div
            className={styles.SearchAndReplace}
            style={{ left: leftSearchPanelCoord }}
          >
            <SearchAndReplace currentFooterColumn={currentColumn} />
          </div>,
          document.getElementById('footer')!
        )}
    </>
  );
};

export default makeResizable(Footer);
