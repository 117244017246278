import { Button, ButtonProps, Menu, Tooltip } from '@rossum/ui/material';
import { ReactNode, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import DownloadFormatsSelect, { DownloadFormat } from './DownloadFormatsSelect';

type DownloadAllButtonProps = {
  downloadAll: (
    payload: {
      format: DownloadFormat;
    },
    toExport: boolean
  ) => void;
  label: 'export' | 'downloadAll';
  tooltip?: ReactNode;
  ButtonProps: ButtonProps;
};

const DownloadAllButton = ({
  downloadAll,
  label,
  tooltip,
  ButtonProps,
}: DownloadAllButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const intl = useIntl();
  const [anchor, setAnchor] = useState<Element | null>(null);

  return (
    <>
      <Tooltip title={tooltip && !anchor ? tooltip : ''}>
        <div>
          <Button
            ref={buttonRef}
            onClick={event => setAnchor(event.currentTarget)}
            data-cy={`${label}-btn`}
            {...ButtonProps}
          >
            {intl.formatMessage({
              id: `containers.documents.buttons.${label}`,
            })}
          </Button>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DownloadFormatsSelect
          downloadAll={({ format }) => {
            setAnchor(null);
            downloadAll({ format }, label === 'export');
          }}
        />
      </Menu>
    </>
  );
};

export default DownloadAllButton;
