import * as apiClient from '@rossum/api-client';
import { useMutation } from '@tanstack/react-query';
import { isString } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { setRossumAppDomainCookie } from '../../appDomain';
import { apiUrl } from '../../constants/config';
import { getAuthCodeFromLocation, ReferrerState } from '../../lib/auth';
import { authSignIn } from '../../redux/modules/auth/actions';
import { throwError } from '../../redux/modules/messages/actions';

export const useSignInWithAuthCodeFromUrl = () => {
  const location = useLocation<ReferrerState>();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (token: string) => {
      // temporary API client with token from URL
      const api = apiClient.createElisClient({
        baseUrl: apiUrl,
        getAuthToken: () => token,
      });

      return api.request(
        apiClient.endpoints.authentication.token({ origin: 'rossum_ui' })
      );
    },
  });

  const token = getAuthCodeFromLocation(location);

  useEffect(() => {
    if (!!token && isString(token)) {
      mutateAsync(token)
        .then(({ key, domain }) => {
          setRossumAppDomainCookie(domain);
          dispatch(authSignIn(key));
        })
        .catch(() => {
          dispatch(throwError('authError'));
        });
    }
  }, [token, dispatch, mutateAsync]);

  return { isSigningIn: isLoading };
};
