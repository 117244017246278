import { Alert, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ResultsPerStatus } from '../../../hooks/usePatchSchemas';
import { FlatSchemaWithQueues } from '../../../types/schema';
import { Dialog } from '../../ui/Dialog';
import { ErrorDialogContent } from './ErrorDialogContent';

type Props = {
  rejectedRequests?: ResultsPerStatus['rejected'];
  schemas: FlatSchemaWithQueues[];
};

export const ErrorMessageWithDialog = ({
  schemas,
  rejectedRequests,
}: Props) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  return (
    <Alert variant="filled" severity="error">
      <Typography variant="body2">
        {intl.formatMessage({
          id: 'features.fieldManager.fieldDetail.errors.errorMessageWithDialog.description',
        })}
        {rejectedRequests && rejectedRequests.length !== 0 && (
          <>
            {' '}
            <Typography
              component="span"
              variant="body2"
              onClick={() => setOpen(true)}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              {intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.errors.errorMessageWithDialog.link',
              })}
            </Typography>
            <Dialog
              title={intl.formatMessage({
                id: 'features.fieldManager.fieldDetail.errors.errorMessageWithDialog.title',
              })}
              open={open}
              onClose={() => setOpen(false)}
            >
              <ErrorDialogContent
                rejectedRequests={rejectedRequests}
                schemas={schemas}
              />
            </Dialog>
          </>
        )}
      </Typography>
    </Alert>
  );
};
