import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

export type DocumentContextValue = {
  canvasActionInProgress: boolean;
  setCanvasActionInProgress: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DocumentContext = createContext<DocumentContextValue | undefined>(
  undefined
);

export const DocumentContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [canvasActionInProgress, setCanvasActionInProgress] = useState(false);

  const contextValue = useMemo(
    () => ({
      canvasActionInProgress,
      setCanvasActionInProgress,
    }),
    [canvasActionInProgress]
  );

  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocumentContext = () => {
  const ctx = useContext(DocumentContext);

  if (ctx === undefined) {
    throw new Error(
      '`useDocumentContext` must be used within a DocumentContext provider'
    );
  }

  return ctx;
};
