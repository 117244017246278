import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Radio,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { State } from '../../types/state';
import { surveySteps } from './surveyData';

const SURVEY_WIDTH = 720;

export const TrialOnboardingSurvey = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const onboardingSurveyStepsData = useSelector(
    (state: State) => state.user.uiSettings?.onboardingSurvey?.stepsData
  );

  const stepData = surveySteps['what-is-your-role'];

  const handleSubmit = () => {
    if (!selectedOption) return;

    dispatch(
      updateUiSettings({
        onboardingSurvey: {
          activeStep: 'what-is-your-role',
          stepsData: {
            ...onboardingSurveyStepsData,
            [stepData.key]: { selectedOptions: [selectedOption] },
          },
        },
      })
    );
  };

  return (
    <Stack
      sx={{
        maxWidth: SURVEY_WIDTH,
        width: 1,
      }}
    >
      <Typography color="text.secondary">
        {intl.formatMessage({
          id: 'containers.trialOnboarding.surveyStep.0.listTitle',
        })}
      </Typography>
      <List sx={{ p: 0 }}>
        {stepData.options.map(({ translationId, key }) => (
          <ListItem key={key} sx={{ px: 0 }}>
            <ListItemButton
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '4px',
              }}
              onClick={() => setSelectedOption(key)}
            >
              <ListItemIcon sx={{ p: 0 }}>
                <Radio checked={selectedOption === key} tabIndex={-1} />
              </ListItemIcon>
              <Typography variant="body2">
                {intl.formatMessage({ id: translationId })}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        sx={{ alignSelf: 'flex-end', mt: 2 }}
        onClick={handleSubmit}
        disabled={!selectedOption}
      >
        {intl.formatMessage({
          id: 'containers.trialOnboardingSurvey.button.submit.label',
        })}
      </Button>
    </Stack>
  );
};
export default TrialOnboardingSurvey;
