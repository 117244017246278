// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const triggerUrlSchema = urlSchema;

export const triggerEventTypeSchema = z.union([
  z.literal('email_with_no_processable_attachments'),
  z.literal('annotation_created'),
  z.literal('annotation_imported'),
  z.literal('annotation_confirmed'),
  z.literal('annotation_exported'),
  z.literal('validation'),
]);

export const triggerSchema = z.object({
  id: idSchema,
  url: urlSchema,
  queue: urlSchema,
  event: triggerEventTypeSchema,
  condition: z.record(z.unknown()).nullable(),
  emailTemplates: z.array(urlSchema),
  deleteRecommendations: z.array(urlSchema),
});
