const AUTOMATION_PARAM_KEY = 'id';
export type AutomationParams = Record<typeof AUTOMATION_PARAM_KEY, string>;

export const dedicatedEnginesDetailRoute =
  `/automation/engines/dedicated/:${AUTOMATION_PARAM_KEY}` as const;

export const automationPath = () => '/automation';
export const automationEnginesPath = () => '/automation/engines';
export const automationDedicatedEngineDetailPath = (id: number) =>
  `/automation/engines/dedicated/${id}`;
