import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Inbox } from '../models';
import { inboxSchema } from '../models/inbox.schema';
import { inboxPatchPayloadSchema } from './patch.schema';

export type InboxPatchPayload = {
  filters?: {
    document_rejection_conditions: {
      mime_types: Array<string>;
    };
  };
};

export const patch = (inboxId: ID, payload: InboxPatchPayload = {}) => {
  return {
    endpoint: `/inboxes/${inboxId}`,
    method: 'PATCH',
    responseSchema: inboxSchema,
    payload,
    payloadSchema: inboxPatchPayloadSchema,
  } as const;
};
