// Generated by ts-to-zod
import { z } from 'zod';
import { schemaItemSchema } from './schemaItem.schema';
import { schemaItemBaseSchema } from './schemaItemBase.schema';

export const schemaSectionSchema = schemaItemBaseSchema.and(
  z.object({
    category: z.literal('section'),
    children: z.array(schemaItemSchema),
    icon: z.string().optional().nullable(),
  })
);
