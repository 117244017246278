import { groupBy } from 'lodash';
import { AnySchemaItem, FlatSchemaWithQueues } from '../../../types/schema';
import { isNonEmptyObject } from '../getProcessRowUpdate';
import { optionsToHash } from './optionsToHash';
import { DataType, GridRowModel } from './rowTypes';

export const getFieldType = (schemaItem: AnySchemaItem): DataType => {
  if (schemaItem.category === 'datapoint') {
    return schemaItem.type;
  }

  if (schemaItem.category === 'section') {
    return 'section';
  }

  if (schemaItem.category === 'tuple') {
    return 'tuple';
  }

  return schemaItem.children?.category === 'tuple'
    ? 'table-multivalue'
    : 'simple-multivalue';
};

const getSchemasWithQueuesAndHashmaps = (
  flatSchemasWithQueues: FlatSchemaWithQueues[]
) => {
  const fieldDetails = flatSchemasWithQueues.flatMap(({ flattenedContent }) =>
    flattenedContent.map(({ id, label, category, ...rest }) => {
      return {
        fieldId: id,
        ...('options' in rest && { optionHash: optionsToHash(rest.options) }),
        ...('formula' in rest && {
          formula: typeof rest.formula === 'string' ? rest.formula : '',
        }),
      };
    })
  );

  const fieldDetailsWithHashmaps = groupBy(fieldDetails, 'fieldId');

  const valueOccurrences = Object.fromEntries(
    Object.entries(fieldDetailsWithHashmaps).map(([fieldId, items]) => {
      const optionsHashLabelMap = items.reduce<Record<string, string>>(
        (acc, currentItem) => {
          if (
            'optionHash' in currentItem &&
            currentItem.optionHash &&
            !acc[currentItem.optionHash]
          ) {
            return {
              ...acc,
              [currentItem.optionHash]: `Enum ${Object.keys(acc).length + 1}`,
            };
          }
          return acc;
        },
        {}
      );

      const formulaHashMap = items.reduce<Record<string, string>>(
        (acc, currentItem) => {
          if (
            'formula' in currentItem &&
            currentItem.formula &&
            !acc[currentItem.formula]
          ) {
            return {
              ...acc,
              [currentItem.formula]: `Formula ${Object.keys(acc).length + 1}`,
            };
          }
          return acc;
        },
        {}
      );

      return [
        fieldId,

        {
          optionsHashLabelMap,
          formulaHashMap,
        },
      ];
    })
  );

  return flatSchemasWithQueues.flatMap(schema => {
    return {
      ...schema,
      flattenedContentWithOccurrences: schema.flattenedContent.map(c => {
        return {
          flatSchemaItem: c,
          metadata: {
            ...(valueOccurrences[c.id]?.optionsHashLabelMap &&
              isNonEmptyObject(valueOccurrences[c.id]?.optionsHashLabelMap) && {
                optionsHashLabelMap:
                  valueOccurrences[c.id]?.optionsHashLabelMap,
              }),
            ...(valueOccurrences[c.id]?.formulaHashMap &&
              isNonEmptyObject(valueOccurrences[c.id]?.formulaHashMap) && {
                formulaHashMap: valueOccurrences[c.id]?.formulaHashMap,
              }),
          },
        };
      }),
    };
  });
};

export const transformSchemasToRows =
  (schemaId: string, schemas: Array<FlatSchemaWithQueues>) =>
  (): Array<GridRowModel> => {
    const flattenedSchemaWithOccurrences =
      getSchemasWithQueuesAndHashmaps(schemas);

    const schemaOccurences = flattenedSchemaWithOccurrences.flatMap(s => {
      const field = s.flattenedContentWithOccurrences.find(
        schemaItem => schemaItem.flatSchemaItem.id === schemaId
      );

      return field
        ? { schema: s, field: field.flatSchemaItem, metadata: field.metadata }
        : [];
    });

    return schemaOccurences.map(
      ({ schema, field: { path, ...field }, metadata }) => ({
        ...field,
        meta: {
          schema_queues: schema.queues,
          path,
          type: getFieldType(field),
          schema_id: schema.id,
          original: field,
          metadata,
        },
      })
    );
  };
