import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { DedicatedEngineSchema } from '../models/dedicatedEngineSchema';
import { dedicatedEngineSchemaSchema } from '../models/dedicatedEngineSchema.schema';
import { dedicatedEngineSchemaGetQuerySchema } from './get.schema';

export type DedicatedEngineSchemaGetQuery = Partial<{
  fields: ('id' | 'url' | 'content')[];
}>;

export const get = (
  engineSchemaId: ID,
  query: DedicatedEngineSchemaGetQuery = {}
) => {
  return {
    endpoint: `/dedicated_engine_schemas/${engineSchemaId}`,
    method: 'GET',
    responseSchema: dedicatedEngineSchemaSchema,
    query,
    querySchema: dedicatedEngineSchemaGetQuerySchema,
  } as const;
};
