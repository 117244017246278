import { User, userSchema } from '../../users/models';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const user = () => {
  return {
    endpoint: `/auth/user`,
    method: 'GET',
    responseSchema: userSchema,
  } as const;
};
