import { Button, Typography } from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export const RetryButton = ({
  disabled,
  onButtonClick,
}: {
  disabled: boolean;
  onButtonClick: () => void;
}) => {
  const intl = useIntl();
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Typography variant="body2" color="error">
        {intl.formatMessage({
          id: 'containers.settings.extensions.savingFailed',
        })}
      </Typography>
      <Button
        onClick={() => {
          setClicked(true);
          onButtonClick();
        }}
        variant="outlined"
        size="small"
        color="error"
        disabled={disabled || clicked}
      >
        {intl.formatMessage({
          id: 'containers.settings.extensions.savingRetry',
        })}
      </Button>
    </>
  );
};
