import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { api } from '../../../lib/apiClient';
import { approvalWorkflowsFeatureSelector } from '../../pricing/selectors';

export const useUnpaginatedWorklowRuns = ({
  annotationIds,
}: {
  annotationIds: number[];
}) => {
  const isWorkflowsEnabled = useSelector(approvalWorkflowsFeatureSelector);
  return useQuery({
    queryKey: ['showWorkflowActivity', annotationIds],

    queryFn: () =>
      api.unpaginatedRequest(endpoints.workflowRuns.list)({
        annotation: annotationIds,
      }),

    enabled: isWorkflowsEnabled && annotationIds.length !== 0,
  });
};
