import { Components, Theme } from '../../material';

export const muiStepper = (_theme: Theme): Components['MuiStepper'] => ({
  styleOverrides: {},
});

export const muiStepLabel = (_theme: Theme): Components['MuiStepLabel'] => ({
  styleOverrides: {
    iconContainer: {},
  },
});

export const muiStepIcon = (theme: Theme): Components['MuiStepIcon'] => ({
  styleOverrides: {
    root: {
      // nice!
      // this let's us specify sizes in px but the result will be in rem and variable
      // based on theme.typography.htmlFontSize
      fontSize: theme.typography.pxToRem(40),
      '&.Mui-completed': {
        color: theme.palette.success.main,
      },
    },
  },
});

export const muiStepConnector = (
  _theme: Theme
): Components['MuiStepConnector'] => ({
  styleOverrides: {
    root: {
      top: '20px',
      left: 'calc(-50% + 30px)',
      right: 'calc(50% + 30px)',
    },
  },
});
