import { endpoints, ID } from '@rossum/api-client';
import { PatchLabelPayload } from '@rossum/api-client/labels';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { UNPAGINATED_LABELS_QUERY_KEY } from './useRequestLabels';

const MUTATION_KEY_EDIT_LABEL = 'editLabel';

type MutationParams = {
  labelId: ID;
} & PatchLabelPayload;

export const useEditLabel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEY_EDIT_LABEL] as const,
    mutationFn: ({ labelId, name }: MutationParams) =>
      api.request(endpoints.labels.patch(labelId, { name })),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UNPAGINATED_LABELS_QUERY_KEY],
      }),
  });
};
