import { engineSchema } from '../models/engine.schema';
import { ID } from '../../utils/codecUtils';
import { engineDuplicatePayloadSchema } from './duplicate.schema';

export type EngineDuplicatePayload = {
  name: string;
};

export const duplicate = (engineId: ID, payload: EngineDuplicatePayload) => {
  return {
    endpoint: `/engines/${engineId}/duplicate`,
    method: 'POST',
    responseSchema: engineSchema,
    payloadSchema: engineDuplicatePayloadSchema,
    payload,
  } as const;
};
