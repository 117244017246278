import { KeyboardArrowDown, KeyboardArrowUp } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type ShowMoreButtonProps = {
  expanded: boolean;
  onClick: (expand: boolean) => void;
};

export const ShowMoreButton = ({ onClick, expanded }: ShowMoreButtonProps) => {
  const intl = useIntl();
  return (
    <Button
      color="secondary"
      onClick={() => onClick(!expanded)}
      startIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      size="small"
      sx={{ alignSelf: 'flex-start', ml: 4 }}
      data-cy={expanded ? 'show-less-attachments' : 'show-more-attachments'}
    >
      {intl.formatMessage({
        id: expanded
          ? 'components.emailDocumentsPreview.button.showLess'
          : 'components.emailDocumentsPreview.button.showAll',
      })}
    </Button>
  );
};
