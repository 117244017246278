import { useHistory } from 'react-router';
import { EXTENSIONS_LOGS_QUERY } from '../../../../redux/modules/localStorage/actions';
import { useStoredState } from '../../../../utils/hooks/useStoredState';

export const useLogsQuery = () => {
  const { push } = useHistory();

  const [filters, setFilters] = useStoredState({
    name: EXTENSIONS_LOGS_QUERY,
    defaultValues: {},
    stringKeys: ['timestampAfter', 'timestampBefore', 'search', 'pageSize'],
    arrayKeys: ['hook', 'logLevel'],
    historyFn: push,
  });

  return {
    filters,
    setFilters,
  };
};
