import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type AutocompleteNoResultsProps = {
  inputValue: string;
};

export const AutocompleteNoResults = ({
  inputValue,
}: AutocompleteNoResultsProps) => {
  const intl = useIntl();
  return (
    <Stack spacing={1} alignItems="center" m={1}>
      <Typography variant="body1" textAlign="center" color="text.primary">
        {intl.formatMessage(
          {
            id: 'features.engines.fieldDetail.basicInformation.field.noResults1',
          },
          { inputValue }
        )}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {intl.formatMessage({
          id: 'features.engines.fieldDetail.basicInformation.field.noResults2',
        })}
      </Typography>
    </Stack>
  );
};
