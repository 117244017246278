import { ElisRequestConfig } from '../../utils/requestConfig';
import { Inbox } from '../models/inbox';
import { inboxSchema } from '../models/inbox.schema';

export const get = (inboxId: number) => {
  return {
    endpoint: `/inboxes/${inboxId}`,
    method: 'GET',
    responseSchema: inboxSchema,
  } as const;
};
