import { ActionType, createAction } from 'typesafe-actions';
import { BboxType } from '../../../types/bbox';
import { FetchBboxesMeta } from './types';

export const fetchBboxes = createAction(
  'FETCH_BBOXES',
  undefined,
  (annotationUrl: string) => ({
    annotationUrl,
  })
)<undefined, FetchBboxesMeta>();

export const fetchBboxesFulfilled = createAction(
  'FETCH_BBOXES_FULFILED',
  (payload: BboxType[]) => payload
)<BboxType[]>();

export type BboxesActions = ActionType<
  typeof fetchBboxes | typeof fetchBboxesFulfilled
>;
