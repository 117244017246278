import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@rossum/ui/icons';
import {
  Paper,
  Popper,
  styled,
  TextField,
  Typography,
} from '@rossum/ui/material';
import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  createFilterOptions,
  inputBaseClasses,
  outlinedInputClasses,
  Stack,
} from '@rossum/ui/material';
import { compact, get } from 'lodash';
import React, {
  ComponentPropsWithRef,
  CSSProperties,
  FocusEventHandler,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Dispatch } from 'redux';
import FakeSelect from '../../components/Datapoint/components/FakeSelect';
import { OnValueChangeOptions } from '../../components/Datapoint/components/Highlighter';
import {
  closeSelectMenu as closeSelectMenuAction,
  openSelectMenu as openSelectMenuAction,
} from '../../redux/modules/ui/actions';
import { ensureMutable } from '../../redux/modules/utils';
import { State as ReduxState } from '../../types/state';

const ITEM_SIZE = 30;
const EMPTY_VALUE = '';
const CHARACTER_WIDTH = 9.1;
const ROW_PADDING_POSITIONS = 10;
const MAX_LIST_BOX_WIDTH = Infinity;
const OVER_SCAN_COUNT = 20;
const SELECT_INPUT_PADDING = 60;

const Wrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  background: 'transparent',
  width: '100%',
  margin: `${theme.spacing(1)} 0`,
}));

const StyledAutocomplete = styled<
  (
    props: AutocompleteProps<ValueType, boolean, boolean, boolean>
  ) => JSX.Element
>(Autocomplete)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
  borderRadius: (theme.shape.borderRadius as number) * 3.5,

  [`& .${outlinedInputClasses.root}`]: {
    [`&.${autocompleteClasses.inputRoot}`]: {
      color: theme.palette.getContrastText(theme.palette.background.default),
      [`& .${autocompleteClasses.endAdornment}`]: {
        right: `${theme.spacing(1 / 2)}`,
      },
      [`& .${autocompleteClasses.popupIndicator}`]: {
        color: theme.palette.getContrastText(theme.palette.background.default),
      },
      [`& .${autocompleteClasses.clearIndicator}`]: {
        color: theme.palette.getContrastText(theme.palette.background.default),
      },
      [`&.${inputBaseClasses.root}`]: {
        fontSize: 16,
        padding: theme.spacing(1 / 2),
        paddingLeft: `0`,
        // take endAdornment into account to avoid overlap
        paddingRight: 60,

        [`& .${inputBaseClasses.input}`]: {
          padding: `0 ${theme.spacing(1 / 4)} 0 ${theme.spacing(1 / 4)}`,
          fontSize: 16,
        },
      },
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'unset',
    },
  },
}));

const ListBoxRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const thisItem = data[index];

  return React.cloneElement(thisItem, { style });
};

const OuterElementContext = React.createContext<HTMLAttributes<HTMLDivElement>>(
  {}
);

const OuterElementType = forwardRef<HTMLDivElement>(
  (props: HTMLAttributes<HTMLDivElement>, ref) => {
    const outerProps = React.useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
  }
);

const ListBoxAdapter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  (componentProps, ref) => {
    const { children, style, ...rest } = componentProps;
    const itemCount = React.Children.count(children);
    const getListBoxHeight = Math.min(7, itemCount) * ITEM_SIZE;

    return (
      <div ref={ref} style={style}>
        <OuterElementContext.Provider value={rest}>
          <FixedSizeList
            itemData={children}
            itemSize={ITEM_SIZE}
            height={getListBoxHeight}
            width="100%"
            itemCount={itemCount}
            overscanCount={OVER_SCAN_COUNT}
            innerElementType="ul"
            outerElementType={OuterElementType}
          >
            {ListBoxRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const ListBoxPaper = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <Paper
    {...props}
    square
    sx={{
      borderRadius: '6px',
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24))`,
      paddingTop: 1,
      paddingBottom: 1,
    }}
  />
);

const StyledListBoxAdapter = styled(ListBoxAdapter)(({ theme }) => {
  return {
    padding: `0 !important`,
    [`& ul`]: {
      margin: 0,
    },
    [`&::-webkit-scrollbar`]: {
      width: 8,
      height: 8,
    },
    [`& .${autocompleteClasses.option}`]: {
      fontSize: 16,
      paddingLeft: `${theme.spacing(1)}`,
      height: ITEM_SIZE,
    },
  };
});

type ValueType = {
  label: string;
  value: string;
};

type OwnProps = {
  onChange: (value: string, options?: OnValueChangeOptions) => void;
  options: Array<ValueType>;
  value: { label: string; value: string } | null | undefined;
  style?: CSSProperties;
  active: boolean;
  readOnly: boolean;
  clearable: boolean;
};

type StateProps = {
  menuIsOpen: boolean;
};

type DispatchProps = {
  openSelectMenu: () => void;
  closeSelectMenu: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const CustomPopper = (props: ComponentPropsWithRef<typeof Popper>) => {
  const { style, ...rest } = props;

  return <Popper {...rest} placement="bottom-start" />;
};

const filterOptions = createFilterOptions<ValueType>({
  stringify: ({ value, label }: ValueType) => `${value} ${label}`,
});

const SelectInputSidebar = ({
  options,
  value = null,
  readOnly,
  active,
  onChange,
  clearable,
  openSelectMenu,
}: Props) => {
  const [activeValue, setActiveValue] = useState(value);
  const [fixedSizeListWidth, setFixedSizeListWidth] = useState<number>(0);
  const [minimalSizeListWidth, setMinimalSizeListWidth] = useState<
    number | null
  >(null);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [highlightedOption, setHighlightedOption] = useState<ValueType | null>(
    null
  );

  const textFieldInputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  const handleOpenState = (isOpen: boolean) => {
    if (isOpen) {
      openSelectMenu();
    }

    setAutocompleteOpen(isOpen);
  };

  const onValueChange = (value: ValueType | null) => {
    onChange(value?.value || '', { setHumanValidationSource: true });
  };

  const onKeyDownHandler = (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (['Enter'].includes(e.key) && autocompleteOpen) {
      e.preventDefault();
      e.stopPropagation();

      onValueChange(highlightedOption);

      handleOpenState(false);
    }

    if (e.key === 'Tab' && autocompleteOpen) {
      if (highlightedOption) onValueChange(highlightedOption);

      handleOpenState(false);
    }

    if (['Delete', 'Backspace'].includes(e.key)) {
      e.stopPropagation();

      if (clearable) {
        onValueChange({ label: EMPTY_VALUE, value: EMPTY_VALUE });
      }
    }
  };

  const handleHighlighted = (value: ValueType | null) => {
    setHighlightedOption(value);
  };

  const resetActiveValue = () => {
    // activeValue.value is empty only in case of new search hint without selected option
    if (activeValue && activeValue.value.length < 1) {
      setActiveValue(value);
    }
  };

  const textFieldInputRefCallback = React.useCallback(
    (node: HTMLInputElement) => {
      textFieldInputRef.current = node;
      // set minimal search of selectBox + padding
      if (textFieldInputRef.current) {
        setMinimalSizeListWidth(
          textFieldInputRef.current.offsetWidth + SELECT_INPUT_PADDING
        );
      }
      node?.focus();
    },
    []
  );

  const textFieldInputOnFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    // timeout workaround is related to this issue
    // https://stackoverflow.com/questions/6003300/how-to-place-cursor-at-end-of-text-in-textarea-when-tabbed-into/6003829#6003829
    setTimeout(() => event.target.setSelectionRange(0, 0), 0);
  };

  const handleActiveValue = (
    newTypedValue: string,
    previousLabel: string | number | readonly string[] | undefined
  ) => {
    const hasInitialValue = String(previousLabel) === value?.label;

    setActiveValue({
      label: hasInitialValue
        ? newTypedValue.replace(String(previousLabel), '')
        : newTypedValue,
      value: EMPTY_VALUE,
    });
  };

  const mutableOptions = compact(ensureMutable(options));

  useEffect(() => {
    if (mutableOptions) {
      const longestLabelWidth = mutableOptions.reduce(
        (maxWidthSoFar, option) => {
          if (!option) {
            return maxWidthSoFar;
          }
          const width =
            option.label.length * CHARACTER_WIDTH + ROW_PADDING_POSITIONS;
          return Math.max(maxWidthSoFar, width);
        },
        0
      );

      const inputWidth =
        autocompleteRef?.current?.getBoundingClientRect()?.width ?? 0;

      // at least input width
      setFixedSizeListWidth(
        Math.max(inputWidth, Math.min(MAX_LIST_BOX_WIDTH, longestLabelWidth))
      );
    }
  }, [mutableOptions]);

  if (readOnly) {
    return (
      <Stack
        sx={{
          flex: 1,
          textAlign: 'right',
        }}
      >
        {get(value, 'label', null)}
      </Stack>
    );
  }

  return (
    <Wrapper alignItems="center">
      <StyledAutocomplete
        PaperComponent={ListBoxPaper}
        PopperComponent={CustomPopper}
        autoHighlight
        disableClearable={!clearable}
        open={autocompleteOpen}
        onOpen={() => handleOpenState(true)}
        onClose={() => handleOpenState(false)}
        options={mutableOptions}
        filterOptions={filterOptions}
        renderInput={params => {
          return (
            <TextField
              {...params}
              autoFocus={active}
              inputRef={textFieldInputRefCallback}
              onBlur={resetActiveValue}
              onFocus={textFieldInputOnFocus}
              onChange={newValue => {
                handleActiveValue(
                  newValue.target.value,
                  params.inputProps.value
                );
              }}
              inputProps={{
                ...params.inputProps,
                'data-id': 'select_box',
                onKeyDown: onKeyDownHandler,
              }}
            />
          );
        }}
        onHighlightChange={(_e, option: ValueType | null) =>
          handleHighlighted(option)
        }
        onChange={(_e, value) => onValueChange(value as ValueType)}
        value={value}
        inputValue={activeValue?.label ?? ''}
        popupIcon={<KeyboardArrowDownIcon />}
        ListboxComponent={StyledListBoxAdapter}
        ListboxProps={{
          style: {
            width: `${fixedSizeListWidth + 26}px`,
            maxWidth: '90vw',
            minWidth: `${minimalSizeListWidth}px`,
          },
        }}
        renderOption={(props, option: ValueType) => {
          return (
            <Typography component="li" {...props} noWrap>
              <Stack
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {option.label}
              </Stack>
            </Typography>
          );
        }}
      />
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeSelectMenu: () => dispatch(closeSelectMenuAction()),
  openSelectMenu: () => dispatch(openSelectMenuAction()),
});

const mapStateToProps = (state: ReduxState) => {
  return {
    menuIsOpen: state.ui.selectMenuIsOpen,
  };
};

const Connected = connect<StateProps, DispatchProps, OwnProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SelectInputSidebar);

export default (props: OwnProps) =>
  props.active ? (
    <Connected {...props} />
  ) : (
    <FakeSelect
      style={props.style}
      value={get(props.value, 'label')}
      inFooter={false}
    />
  );
