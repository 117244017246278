import { Launch } from '@rossum/ui/icons';
import { Divider, Link, MenuItem, Stack, Tooltip } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { docsLinks } from '../../../constants/values';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';

export const FORMATS = ['xlsx', 'csv', 'xml', 'json'] as const;
export type DownloadFormat = (typeof FORMATS)[number];

// BE limit for bulk download
const MAX_PAGE_SIZE_LIMIT = 500;

type DownloadFormatsSelectProps = {
  downloadAll: (format: { format: DownloadFormat }) => void;
  asyncDownload?: () => void;
  selectedItemsCount?: number;
};

const DownloadFormatsSelect = ({
  downloadAll,
  asyncDownload,
  selectedItemsCount,
}: DownloadFormatsSelectProps) => {
  const bulkDownloadOriginalFilesEnabled = useFeatureFlag(
    'ac-1483-bulk-download-original-files'
  );

  return (
    <Stack>
      {FORMATS.map((format: DownloadFormat) => (
        <MenuItem
          key={format}
          onClick={() => downloadAll({ format })}
          data-cy={`download-documents-format-${format}`}
        >
          <FormattedMessage id={`containers.documents.formats.${format}`} />
        </MenuItem>
      ))}
      {bulkDownloadOriginalFilesEnabled && !!asyncDownload ? (
        <>
          <Divider />
          <Tooltip
            title={
              selectedItemsCount && selectedItemsCount > MAX_PAGE_SIZE_LIMIT ? (
                <FormattedMessage
                  id="containers.documents.formats.all.disabled"
                  values={{
                    count: MAX_PAGE_SIZE_LIMIT,
                  }}
                />
              ) : (
                ''
              )
            }
          >
            <MenuItem
              onClick={
                selectedItemsCount && selectedItemsCount <= MAX_PAGE_SIZE_LIMIT
                  ? asyncDownload
                  : undefined
              }
              data-cy="download-documents-format-original"
            >
              <FormattedMessage
                id="containers.documents.formats.all"
                values={{ count: selectedItemsCount ?? 0 }}
              />
            </MenuItem>
          </Tooltip>
          <Divider />
        </>
      ) : null}
      <MenuItem>
        <Link
          href={docsLinks.export}
          rel="noopener noreferrer"
          target="_blank"
          sx={{ width: '100%', textDecoration: 'none' }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormattedMessage id="containers.documents.api.export" />
            <Launch fontSize="small" />
          </Stack>
        </Link>
      </MenuItem>
    </Stack>
  );
};

export default DownloadFormatsSelect;
