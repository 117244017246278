import { ValidationTextarea } from '@rossum/rossum-ui/ValidationTextarea';
import React from 'react';
import {
  Control,
  FieldError,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';

const ControlledTextArea = <T extends FieldValues>({
  control,
  getErrorMessage,
  clearValidationsOnChange,
  name,
  className,
  placeholder,
  dataCy,
}: {
  control: Control<T>;
  getErrorMessage: (
    _id: string,
    _errorMessage: FieldError
  ) => string | undefined;
  name: Path<T>;
  className?: string;
  placeholder?: string;
  dataCy?: string;
  clearValidationsOnChange?: (
    _onChange: (_event: React.ChangeEvent<unknown>) => void
  ) => (_event: React.ChangeEvent<unknown>) => void;
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ValidationTextarea
      dataCy={dataCy}
      className={className}
      id={name}
      onBlur={onBlur}
      pureOnChange={
        clearValidationsOnChange ? clearValidationsOnChange(onChange) : onChange
      }
      placeholder={placeholder}
      value={(value || '') as string}
      errorMessage={error ? getErrorMessage(name, error) ?? '' : ''}
    />
  );
};

export default ControlledTextArea;
