import { ArrowBackIosNew, ArrowForwardIos } from '@rossum/ui/icons';
import { alpha, IconButton, Stack, Typography } from '@rossum/ui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

type Props = {
  next: (nSteps: number) => void;
  current: number;
  total: number;
};

type State = { tmpDifference: number };

class AnnotationNavigationRedesign extends React.Component<Props, State> {
  subject: Subject<number>;

  navigationSubscription: Subscription;

  constructor(props: Props) {
    super(props);
    this.state = { tmpDifference: 0 };
    this.subject = new Subject<number>();

    this.navigationSubscription = this.subject
      .pipe(
        debounceTime(600),
        filter(() => !!this.state.tmpDifference)
      )
      .subscribe(() => this.props.next(this.state.tmpDifference));
  }

  UNSAFE_componentWillReceiveProps({ current }: Props) {
    if (current !== this.props.current) {
      this.setState({ tmpDifference: 0 });
    }
  }

  componentWillUnmount() {
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe();
  }

  onNext = () => {
    const { tmpDifference } = this.state;
    this.setState({ tmpDifference: tmpDifference + 1 });
    if (this.subject) this.subject.next();
  };

  onPrevious = () => {
    const { tmpDifference } = this.state;
    this.setState({ tmpDifference: tmpDifference - 1 });
    if (this.subject) this.subject.next();
  };

  render() {
    const { total, current } = this.props;
    const { tmpDifference } = this.state;
    const tmpCurrent = current + tmpDifference;
    const hidePreviousArrow = tmpCurrent === 1;
    const hideNextArrow = tmpCurrent === total;

    return (
      <Stack direction="row" spacing={0.5} alignItems="center" flexShrink={0}>
        <IconButton
          size="small"
          data-cy="previous-annotation-arrow"
          onClick={() => this.onPrevious()}
          disabled={hidePreviousArrow}
          // TODO: This is an incorrect use from POV of design system - raise to UX
          sx={{
            fontSize: 12,
            color: theme => alpha(theme.palette.action.active, 0.56),
          }}
        >
          <ArrowBackIosNew fontSize="inherit" />
        </IconButton>
        <Typography variant="caption">
          <FormattedMessage
            id="components.annotationNavigation.progress"
            values={{ current: tmpCurrent, total }}
          />
        </Typography>
        <IconButton
          size="small"
          data-cy="next-annotation-arrow"
          onClick={() => this.onNext()}
          disabled={hideNextArrow}
          sx={{
            fontSize: 12,
            color: theme => alpha(theme.palette.action.active, 0.56),
          }}
        >
          <ArrowForwardIos fontSize="inherit" />
        </IconButton>
      </Stack>
    );
  }
}

export default AnnotationNavigationRedesign;
