import { useEffect, useMemo, useState } from 'react';
import { getBoxCoordinates } from '../../document-store/documentGeometry';
import {
  useCanvasGeometryState,
  useDocumentStore,
  useDocumentStoreContext,
} from '../../document-store/DocumentStore';
import {
  stateToInverseMatrix,
  stateToMatrix,
} from '../../document-store/helpers';
import {
  Rectangle2DCoordinates,
  rectangleFromCoordinates,
} from '../../document-store/helpers/geometry';

const LEFT_MARGIN = 24;

export const useMagicLine = ({
  pageNumber,
  position,
  sourceElementId,
}: {
  pageNumber: number;
  position: Rectangle2DCoordinates;
  sourceElementId: string;
}) => {
  const { dimensions } = useDocumentStoreContext();
  const [magicLineStartY, setMagicLineStartY] = useState<number | undefined>(
    undefined
  );

  const canvasState = useCanvasGeometryState();
  const viewportState = useDocumentStore(state => state.viewportState);

  const activeBoundingBoxCoordinates = useMemo(
    () =>
      getBoxCoordinates(
        pageNumber,
        rectangleFromCoordinates(position),
        dimensions.pages
      ),
    [dimensions.pages, pageNumber, position]
  );

  // Watch sidebar datapoint.
  useEffect(() => {
    const setStartYCoordinate = () => {
      // TODO: Use ref instead of getElementById
      const clientRect = document
        .getElementById(sourceElementId)
        // TODO: This is expensive
        ?.getBoundingClientRect();

      // For some reason, this (round + condition) is necessary to avoid rerenders.
      const y = clientRect
        ? Math.round(clientRect.y + clientRect.height / 2)
        : undefined;
      setMagicLineStartY(prev => (prev === y ? prev : y));
      frame = requestAnimationFrame(setStartYCoordinate);
    };
    let frame = requestAnimationFrame(setStartYCoordinate);

    return () => cancelAnimationFrame(frame);
  }, [sourceElementId]);

  return useMemo(() => {
    const stateMatrix = stateToMatrix(canvasState);
    const inverseViewportMatrix = stateToInverseMatrix(viewportState);

    const getOrigin = (y: number) =>
      DOMPoint.fromPoint({
        x: viewportState.translateX + LEFT_MARGIN,
        y,
      }).matrixTransform(inverseViewportMatrix);

    return activeBoundingBoxCoordinates && magicLineStartY
      ? (() => {
          const origin = getOrigin(magicLineStartY);
          const endPoint = DOMPoint.fromPoint({
            x: activeBoundingBoxCoordinates.x,
            y:
              activeBoundingBoxCoordinates.y +
              activeBoundingBoxCoordinates.height / 2,
          }).matrixTransform(stateMatrix);

          return {
            startX: origin.x,
            startY: origin.y,
            endX: endPoint.x,
            endY: endPoint.y,
          };
        })()
      : undefined;
  }, [
    activeBoundingBoxCoordinates,
    canvasState,
    magicLineStartY,
    viewportState,
  ]);
};
