import { create } from './create';
import { get } from './get';
import { list } from './list';
import { patch } from './patch';
import { validate } from './validate';
export { type PatchSchemaPayload } from './patch';

/**
 * @noSchema
 */
type Schemas = {
  get: typeof get;
  create: typeof create;
  list: typeof list;
  patch: typeof patch;
  validate: typeof validate;
};

export const schemas: Schemas = {
  get,
  create,
  list,
  patch,
  validate,
};
