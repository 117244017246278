import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { invokePayloadSchema, invokeResponseSchema } from './invoke.schema';

// this functionality is beta version right now and the API may change
export type InvokeResponse = unknown;
export type InvokePayload = Record<string, unknown> | undefined;

export const invoke = (hookId: ID, payload?: InvokePayload) => {
  return {
    endpoint: `/hooks/${hookId}/invoke`,
    method: 'POST',
    responseSchema: invokeResponseSchema,
    payloadSchema: invokePayloadSchema,
    payload,
  } as const;
};
