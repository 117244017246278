import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { ProductTourState } from '../../../types/productTour';
import { RootActionType } from '../../rootActions';
import {
  finishProductTourAction,
  showProductTourStep,
  skipProductTourAction,
} from './actions';

const reducer: Reducer<ProductTourState, RootActionType> = (
  state = null,
  { type, payload }
) => {
  switch (type) {
    case getType(skipProductTourAction):
    case getType(finishProductTourAction):
      return null;

    case getType(showProductTourStep):
      return {
        tourName: payload.tourName,
        currentStepIndex: payload.stepIndex,
      };

    default:
      return state;
  }
};

export default reducer;
