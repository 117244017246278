import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Queue } from '../models';
import { queueSchema } from '../models/queue.schema';
import { queueDuplicatePayloadSchema } from './duplicate.schema';

export type QueueDuplicatePayload = {
  name: string;
  copyExtensionsSettings: boolean;
  copyEmailSettings: boolean;
  copyAutomationSettings: boolean;
  copyPermissions: boolean;
  copyDeleteRecommendations: boolean;
};

export const duplicate = (queueId: ID, payload: QueueDuplicatePayload) => {
  return {
    endpoint: `/queues/${queueId}/duplicate`,
    method: 'POST',
    responseSchema: queueSchema,
    payload,
    payloadSchema: queueDuplicatePayloadSchema,
  } as const;
};
