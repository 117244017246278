export const getBase64Image = (imgUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      try {
        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas
          .toDataURL('image/png')
          .replace(/^data:image\/png;base64,/, '');
        resolve(`data:image/png;base64,${dataURL}`);
      } catch {
        reject(new Error('Failed to convert image to Base64.'));
      }
    };

    img.onerror = () => {
      reject(new Error('Failed to load image.'));
    };

    img.setAttribute('crossOrigin', 'anonymous');
    img.src = imgUrl;
  });
};
