import { useCallback, useEffect, useRef, useState } from 'react';
import * as R from 'remeda';

/**
    Note: Some events (wheel) have momentum - meaning the actuall event continues even
    after releasing the hand from the mouse/trackpad device. If user presses the
    key and the event is still in progress from previous interaction,
    the handler for another event could strikes in and do some logic which is not
    intentional. To prevent this, we are constantly
    measuring the time difference between the last event and key press.
    If the difference is big enough (empirically measured to 1 frame duration = 16ms) we
    can say that the key press event handler logic intentional
  */

const useDeferredKeypress = ({
  keysToDefer,
  canvasRef,
}: {
  keysToDefer: string[];
  canvasRef: React.MutableRefObject<SVGSVGElement | null>;
}) => {
  const [deferredKeyPress, setDeferredKeyPress] = useState(false);
  const keyPress = useRef(false);
  const wheelInProgress = useRef(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (keysToDefer.includes(e.key)) {
        keyPress.current = true;
        if (!wheelInProgress.current) setDeferredKeyPress(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (keysToDefer.includes(e.key)) {
        keyPress.current = false;
        if (!wheelInProgress.current) setDeferredKeyPress(false);
      }
    };

    const resetKeyPressOnFocus = () => {
      keyPress.current = false;
      setDeferredKeyPress(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    window.addEventListener('focus', resetKeyPressOnFocus);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('focus', resetKeyPressOnFocus);
    };
  }, [keysToDefer]);

  const debounceRef = useRef(
    R.debounce(
      () => {
        wheelInProgress.current = false;
        setDeferredKeyPress(keyPress.current);
      },
      { timing: 'trailing', waitMs: 30 }
    )
  );

  const handleDebounceWheel = useCallback((event: WheelEvent) => {
    wheelInProgress.current = true;
    keyPress.current = event.metaKey;
    debounceRef.current.call();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('wheel', handleDebounceWheel);
      return () => canvas.removeEventListener('wheel', handleDebounceWheel);
    }

    return () => {};
  }, [canvasRef, handleDebounceWheel]);

  return {
    zoomEnabled: deferredKeyPress,
  };
};

export default useDeferredKeypress;
