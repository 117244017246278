import { Delete, DragIndicator } from '@rossum/ui/icons';
import { Box, Card, IconButton, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { DragHandleProps } from '../../../../../../../../../components/Dnd/SortableWrapper';
import { HeaderFieldFormModel } from '../utils';

type LineItemColumnCardProps = {
  columnModel: HeaderFieldFormModel;
  disabled?: boolean;
  isDragging?: boolean;
  dragHandleProps?: DragHandleProps | null;
  onClick?: () => void;
  onDelete?: () => void;
};

const LineItemColumnCard = ({
  columnModel,
  disabled,
  isDragging,
  dragHandleProps,
  onClick,
  onDelete,
}: LineItemColumnCardProps) => {
  const intl = useIntl();

  return (
    <Card
      elevation={24}
      sx={{
        my: 0.5,
        p: 1,
        ':hover': {
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14))',
        },
        cursor:
          disabled && !isDragging
            ? 'not-allowed'
            : isDragging
              ? 'grabbing'
              : 'pointer',
        borderRadius: 1,
      }}
      onClick={() => !disabled && onClick?.()}
    >
      <Box
        display="grid"
        gridTemplateColumns="repeat(24, 1fr)"
        gap={2}
        alignItems="center"
      >
        <Box gridColumn="span 1">
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              size="small"
              color="inherit"
              disabled={disabled}
              aria-disabled={disabled}
              disableRipple
              aria-label={intl.formatMessage({
                id: 'components.lineItemColumnCard.dragIndicator.label',
              })}
              title={intl.formatMessage({
                id: 'components.lineItemColumnCard.dragIndicator.label',
              })}
              sx={{
                cursor: 'grab',
              }}
              {...dragHandleProps?.listeners}
              {...dragHandleProps?.attributes}
            >
              <DragIndicator fontSize="inherit" />
            </IconButton>
          </Typography>
        </Box>
        <Box gridColumn="span 9">
          <Typography variant="body2">{columnModel.label}</Typography>
        </Box>
        <Box gridColumn="span 12">
          <Typography noWrap variant="body2" color="text.disabled">
            {columnModel.engineOutputId}
          </Typography>
        </Box>
        <Box gridColumn="span 2">
          <Typography variant="body2" color="text.disabled">
            <IconButton
              size="small"
              color="inherit"
              onClick={e => {
                e.stopPropagation();
                onDelete?.();
              }}
              disabled={disabled}
              aria-disabled={disabled}
              aria-label={intl.formatMessage({
                id: 'components.lineItemColumnCard.delete.label',
              })}
              title={intl.formatMessage({
                id: 'components.lineItemColumnCard.delete.label',
              })}
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default LineItemColumnCard;
