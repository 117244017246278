import { Menu, MenuItem, Paper, Stack, Typography } from '@rossum/ui/material';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatapoint } from '../../redux/modules/datapoints/actions';
import { datapointPathSelector } from '../../redux/modules/datapoints/selector';
import { useGridContext } from './GridContext';

export type LabelSelectControlProps<TOption, TValue> = {
  highlighted: boolean;
  disabled: boolean;
  value: TValue | null;
  options: TOption[];
  buttonLabel?: ReactNode;
  renderOption: (option: TOption) => ReactNode;
  onChange: (value: TValue | null) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  dataCy: string;
};

export const LabelSelectControl = <
  TOption extends { id: TValue | null; label: string; disabled?: boolean },
  TValue extends string | null,
>({
  highlighted,
  disabled,
  value,
  buttonLabel,
  options,
  renderOption,
  onChange,
  onMenuOpen,
  onMenuClose,
  dataCy,
}: LabelSelectControlProps<TOption, TValue>) => {
  const intl = useIntl();

  const {
    gridUiState: [uiState],
  } = useGridContext();

  // TODO: We probably don't want to control this from here as this _should_ be a presentational component?
  // Quick fix until better solution is found
  const datapointPath = useSelector(datapointPathSelector);

  const dispatch = useDispatch();

  const handleMouseDown = useCallback(() => {
    // if we are in a cell in footer, select parent line item
    if (datapointPath.length === 4) {
      dispatch(selectDatapoint(datapointPath.slice(0, 2), { noTail: true }));
    }
  }, [datapointPath, dispatch]);

  // Hovering
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = useCallback(() => {
    if (!disabled) {
      setIsHovered(true);
    }
  }, [disabled]);

  const handleMouseOut = useCallback(() => {
    if (!disabled) {
      setIsHovered(false);
    }
  }, [disabled]);

  useEffect(() => {
    setIsHovered(false);
  }, [value]);

  // Menu management
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleLabelClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        // quick toggle if there are only two options
        const first = options[0]?.id;

        const second = options[1]?.id;

        if (options.length === 2) {
          onChange(value === first ? second : first);
          // otherwise open menu with all options
        } else {
          setAnchorEl(e.currentTarget);
          onMenuOpen?.();
        }
      }
    },
    [disabled, onChange, onMenuOpen, options, value]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onMenuClose?.();
  }, [onMenuClose]);

  // Value management
  const displayedLabel = useMemo(
    () =>
      !buttonLabel ||
      typeof buttonLabel === 'string' ||
      typeof buttonLabel === 'number' ? (
        <Typography
          variant="caption"
          noWrap
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            mx: 1,
            fontSize: 'inherit',
          }}
        >
          {buttonLabel ??
            options.find(option => option.id === value)?.label ??
            intl.formatMessage({
              id: 'components.magicGrid.labelSelect.defaultLabel',
            })}
        </Typography>
      ) : (
        // if a ReactNode buttonLabel is provided, render it without Typography wrapper
        buttonLabel
      ),
    [buttonLabel, intl, options, value]
  );

  // TODO: Menu is open, column is hovered, DP is selected...
  const isActive = isHovered || isMenuOpen || highlighted;

  return (
    <>
      <Stack
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseDown={handleMouseDown}
        onClick={handleLabelClick}
        component={Paper}
        elevation={isActive ? 4 : 2}
        alignSelf="flex-start"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: 1,
          maxWidth: '100%',
          width: 'min-content',
          maxHeight: '100%',
          height: 22,
          color: t => t.palette.text[disabled ? 'secondary' : 'primary'],
          fontSize: t => t.typography.pxToRem(10),
          transition: theme =>
            theme.transitions.create('all', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
          opacity: disabled && !uiState.documentHovered ? 0.7 : 1,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        style={{ pointerEvents: disabled ? 'none' : 'all' }}
        id="label-select-control"
        data-cy={dataCy}
      >
        {displayedLabel}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        elevation={24}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            onClick={() => {
              if (value !== option.id) {
                onChange(option.id);
                handleClose();
              }
            }}
            selected={value === option.id}
            disabled={option.disabled}
            data-cy={`label-select-option-${option.id}`}
          >
            {renderOption(option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
