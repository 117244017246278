import { ReactNode } from 'react';
import styles from '../styles.module.sass';

type Props = {
  children: ReactNode;
};

const FormLabel = ({ children }: Props) => (
  <div className={styles.FormLabel}>{children}</div>
);

export default FormLabel;
