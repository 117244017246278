import { Check as CheckIcon } from '@rossum/ui/icons';
import { Fade, Stack, Typography } from '@rossum/ui/material';

type SuccessMessageProps = {
  message: string;
  show: boolean;
};

const SuccessMessage = ({ message, show }: SuccessMessageProps) => {
  return (
    <Fade in={show}>
      <Stack flexDirection="row" alignItems="center" gap={0.5}>
        <CheckIcon color="success" fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          {message}
        </Typography>
      </Stack>
    </Fade>
  );
};

export default SuccessMessage;
