import { LocalizationKeys } from '../../../i18n';
import SearchInput from '../SearchInput';

type Props<T> = {
  className?: string;
  placeholderID?: LocalizationKeys;
  searchValue: string;
  setFilteredEntities: (entities: Array<T>) => void;
  setSearchValue: (value: string) => void;
  filterFunction: (value: string, entities: Array<T>) => Array<T>;
  entities: Array<T>;
};

const SearchFilter = <T,>({
  className,
  placeholderID,
  searchValue,
  setFilteredEntities,
  setSearchValue,
  entities,
  filterFunction,
}: Props<T>) => {
  const onValueChange = (value: string) => {
    setFilteredEntities(filterFunction(value, entities));
    return setSearchValue(value);
  };

  return (
    <SearchInput
      className={className}
      onChange={onValueChange}
      placeholder={
        placeholderID || 'containers.users.components.searchfilter.placeholder'
      }
      value={searchValue}
    />
  );
};

SearchFilter.defaultProps = { className: '' };

export default SearchFilter;
