import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { usePasswordValidation } from '../../../business/user/usePasswordValidation';
import PasswordValidationField from '../../../components/PasswordValidationField';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import useDebounce from '../../../utils/hooks/useDebounce';

type NewPasswords = {
  newPassword: string;
  confirmPassword: string;
};

type ConfirmFormProps = {
  username: string;
  confirmPassword: (newPasswords: NewPasswords) => void;
};

const ConfirmForm = ({ username, confirmPassword }: ConfirmFormProps) => {
  const [score, setScore] = useState(0);

  const intl = useIntl();

  const defaultValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const confirmValidationFormSchema = yup.object().shape({
    newPassword: yup
      .string()
      .test(
        '$score',
        '',
        (_, scoreContext) => scoreContext?.options?.context?.score > 2
      ),
    confirmPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'components.user.resetPassword.errors.letter',
        })
      )
      .oneOf(
        [yup.ref('newPassword'), null],
        intl.formatMessage({ id: 'components.user.resetPassword.errors.match' })
      ),
  });

  const { handleSubmit, control, watch } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(confirmValidationFormSchema),
    context: {
      score,
    },
  });

  const newPassword = watch('newPassword');
  const debounceNewPassword = useDebounce(newPassword, 100);

  const { refetch, data } = usePasswordValidation({
    password: debounceNewPassword,
    email: username,
  });

  useEffect(() => {
    if (data?.score) {
      setScore(data.score);
    }
  }, [data?.score, setScore]);

  useEffect(() => {
    refetch();
  }, [debounceNewPassword, refetch]);

  return (
    <Stack sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(values => confirmPassword(values))}>
        <Stack spacing={2} justifyContent="center">
          <input style={{ display: 'none' }} disabled value={username} />
          <Stack spacing={2}>
            <PasswordValidationField
              data={data}
              ControllerProps={{ control, name: 'newPassword' }}
            />
            <TextFieldControl
              type="password"
              autoComplete="new-password"
              FieldLabelProps={{ layout: 'none' }}
              ControllerProps={{ control, name: 'confirmPassword' }}
              placeholder={intl.formatMessage({
                id: 'containers.personalInfo.confirmPassword.placeholder',
              })}
              inputProps={{
                'data-cy': 'confirm-password-input',
              }}
            />
          </Stack>
          <Button variant="contained" type="submit">
            {intl.formatMessage({ id: 'components.user.resetPassword.reset' })}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default ConfirmForm;
