// Generated by ts-to-zod
import { z } from 'zod';

export const schemaFieldSchema = z.object({
  label: z.string().optional(),
  schemaId: z.string().optional(),
  type: z
    .union([z.literal('number'), z.literal('date'), z.literal('string')])
    .optional(),
});
