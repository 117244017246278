import { Box, BoxProps, styled } from '@rossum/ui/material';

export type SeparatorContainerProps = BoxProps & {
  orientation: 'horizontal' | 'vertical';
};

export const SeparatorContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'orientation',
})<SeparatorContainerProps>(({ orientation }) => {
  const primaryDim = orientation === 'horizontal' ? 'width' : 'height';
  return {
    position: 'absolute',
    [primaryDim]: '100%',
  };
});
