import { getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { compact, sum } from 'lodash';
import { useMemo } from 'react';
import { useUnpaginatedQueues } from '../../../business/queues/useUnpaginatedQueues';
import { useUnpaginatedWorkspaces } from '../../../business/workspaces/useUnpaginatedWorkspaces';

type UseWorkspacesWithQueuesProps = {
  enableQueries: boolean;
  onWorkspacesSuccessfulFetched?: (workspaces: Workspace[]) => void;
};

export const useWorkspacesWithQueues = ({
  enableQueries,
  onWorkspacesSuccessfulFetched,
}: UseWorkspacesWithQueuesProps) => {
  const { data: workspaces } = useUnpaginatedWorkspaces(undefined, {
    enabled: enableQueries,
    keepPreviousData: true,
    onSuccess: onWorkspacesSuccessfulFetched,
  });

  const { data: queues, isLoading: isQueuesLoading } = useUnpaginatedQueues({
    enabled: enableQueries,
    keepPreviousData: true,
  });

  const queueObject = useMemo(() => {
    return queues?.reduce<Record<number, Queue>>((acc, queue) => {
      return {
        ...acc,
        [queue.id]: queue,
      };
    }, {});
  }, [queues]);

  const allWorkspacesWithQueues = useMemo(
    () =>
      workspaces
        ?.map(workspace => {
          const { queues, metadata, ...rest } = workspace;
          const queuesOfWorkspace = compact(
            queues?.map(queueUrl => {
              const queueId = getIDFromUrl(queueUrl);
              return queueObject?.[queueId] || undefined;
            })
          );

          const toReviewCount = queuesOfWorkspace.reduce<number>(
            (acc, { counts: { to_review, reviewing, importing } }) =>
              sum([acc, to_review, reviewing, importing]),
            0
          );

          return {
            ...rest,
            queues: queuesOfWorkspace,
            toReviewCount,
          };
        })
        ?.sort((a, b) => a.name.localeCompare(b.name)),

    [queueObject, workspaces]
  );

  const workspacesWithQueues = useMemo(() => {
    return allWorkspacesWithQueues?.filter(
      ({ queues }) => queues?.length && queues?.[0]
    );
  }, [allWorkspacesWithQueues]);

  return {
    workspacesWithQueues,
    allWorkspacesWithQueues,
    queues,
    workspaces,
    isLoading: isQueuesLoading,
  };
};
