import { Box, Stack, Tooltip } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { UiFieldType } from '../../../types/schema';

const TOOLTIP_DELAY = 2000;
const SHORTER_DELAY = 500;

const getTooltipDelay = (type: UiFieldType, fieldEditable: boolean) => {
  if (
    (type === 'data' || type === 'manual' || type === 'formula') &&
    !fieldEditable
  ) {
    return SHORTER_DELAY;
  }

  return TOOLTIP_DELAY;
};

type Props = {
  uiFieldType?: UiFieldType;
  readOnly: boolean;
  fieldEditable: boolean;
  children: ReactNode;
};

const UiFieldTypeMessage = ({
  uiFieldType,
  readOnly,
  fieldEditable,
  children,
}: Props) => {
  const intl = useIntl();

  if (!uiFieldType || readOnly) {
    return <>{children}</>;
  }

  const delay = getTooltipDelay(uiFieldType, fieldEditable);

  return (
    <Tooltip
      title={
        <Stack sx={{ textAlign: 'left' }}>
          <Box component="span">
            {intl.formatMessage({
              id: `components.datapoint.fieldType.description.${uiFieldType}`,
            })}
          </Box>
          {!fieldEditable && uiFieldType !== 'formula' && (
            <Box component="span" sx={{ mt: 0.5 }}>
              {intl.formatMessage({
                id: `components.datapoint.fieldType.description.nonEditable`,
              })}
            </Box>
          )}
        </Stack>
      }
      enterDelay={delay}
      enterNextDelay={delay}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        {children}
      </Stack>
    </Tooltip>
  );
};

export default UiFieldTypeMessage;
