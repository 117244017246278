import { yupResolver } from '@hookform/resolvers/yup';
import { Url } from '@rossum/api-client';
import { DeleteForever } from '@rossum/ui/icons';
import { Typography } from '@rossum/ui/material';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import ActionDialogCheckboxes from '../../../components/UI/ActionDialog/ActionDialogCheckboxes';
import { boldText } from '../../../lib/formaterValues';

type PurgeAnnotationsPayload = { annotations: Url[] } | { queue: Url };

export type AnnotationPurgeParams = Pick<
  AnnotationPurgeProps,
  'annotationCount'
> & { payload: PurgeAnnotationsPayload };

type AnnotationPurgeProps = {
  annotationCount: number;
  onPurge: () => void;
  onCancel: () => void;
};

const checkboxSchema = (intl: IntlShape) => [
  {
    label: intl.formatMessage({
      id: 'components.modal.purgeAnnotations.checkbox.documents',
    }),
    name: 'documentsCheckbox' as const,
  },
];

const checkboxValidationSchema = yup.object().shape({
  documentsCheckbox: yup.boolean().required().oneOf([true], ''),
});

export const AnnotationPurge = ({
  annotationCount,
  onPurge,
  onCancel,
}: AnnotationPurgeProps) => {
  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(checkboxValidationSchema),
    defaultValues: {
      documentsCheckbox: false,
    },
  });

  const intl = useIntl();
  const onSubmit = () => {
    onPurge();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ActionDialog
        dialogTitle={intl.formatMessage({
          id: 'components.modal.purgeAnnotations.title',
        })}
        onCancel={() => onCancel()}
        DialogTitleIcon={DeleteForever}
        dialogColor="error"
        dialogActions={
          <ActionDialogActions
            confirmButtonProps={{
              type: 'submit',
            }}
            confirmButtonText={intl.formatMessage({
              id: 'components.modal.purgeAnnotations.button.delete',
            })}
            dataCyConfirm="purge-documents-confirm-btn-footer"
            dataCyCancel="purge-documents-cancel-btn-footer"
          />
        }
        dataCy="purge-documents-cancel-btn"
      >
        <>
          <Typography variant="body1">
            {intl.formatMessage(
              {
                id: 'components.modal.purgeAnnotations.text',
              },
              {
                boldText,
                count: annotationCount,
              }
            )}
          </Typography>
          <ActionDialogCheckboxes
            control={control}
            checkboxSchema={checkboxSchema(intl)}
            errorMessage={intl.formatMessage({
              id: 'components.modal.purgeAnnotations.checkbox.error',
            })}
          />
        </>
      </ActionDialog>
    </form>
  );
};
