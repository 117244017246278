import { FunctionRuntime } from '@rossum/api-client/hooks';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import {
  runtimesConfig,
  runtimeTypesConfig,
} from '../../../../Extension/config';
import styles from '../style.module.sass';

type Props = {
  onClick: () => void;
  runtime: FunctionRuntime;
  selected: boolean;
  dataCy: string;
};

const RuntimeButton = ({ onClick, runtime, selected, dataCy }: Props) => {
  const { type, translation } = runtimesConfig[runtime];
  const Icon = runtimeTypesConfig[type].icon;

  return (
    <div
      className={clsx(
        styles.RuntimeButton,
        selected && styles.RuntimeButtonSelected
      )}
      onClick={onClick}
      data-cy={dataCy}
    >
      <Icon />
      <FormattedMessage id={translation} />
    </div>
  );
};

export default RuntimeButton;
