import { Accordion, Box, Skeleton, Stack } from '@rossum/ui/material';
import { range } from 'lodash';
import { logsColumns } from '../helpers';

export const Skeletons = () => (
  <>
    {range(5).map(n => (
      <Accordion
        key={n}
        disableGutters
        sx={{
          pl: 2,
          pr: 5,
          '&::before': { display: 'none' },
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{ minHeight: t => t.spacing(4) }}
        >
          {logsColumns.map(({ name, width }) => (
            <Box key={name} width={width}>
              <Skeleton sx={{ mr: 2 }} variant="text" />
            </Box>
          ))}
        </Stack>
      </Accordion>
    ))}
  </>
);
