import { endpoints } from '@rossum/api-client';
import { DedicatedEngineListQuery } from '@rossum/api-client/dedicatedEngines';
import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { ElisClientError } from '@rossum/api-client/errors';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_DEDICATED_ENGINES_UNPAGINATED = 'dedicated-engines-unpaginated';

export const useUnpaginatedDedicatedEngines = <
  TReturnedData = DedicatedEngine[],
>(
  query: DedicatedEngineListQuery = {},
  queryOptions: Omit<
    UseQueryOptions<
      DedicatedEngine[],
      ElisClientError,
      TReturnedData,
      [typeof QUERY_KEY_DEDICATED_ENGINES_UNPAGINATED, DedicatedEngineListQuery]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery({
    queryKey: [QUERY_KEY_DEDICATED_ENGINES_UNPAGINATED, query],

    queryFn: ({ queryKey }) =>
      api.unpaginatedRequest(endpoints.dedicatedEngines.list)(queryKey[1]),

    ...queryOptions,
  });
