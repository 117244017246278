import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  CustomEmailTemplateType,
  EmailTemplate,
  EmailTemplatePayloadBase,
} from '../models/emailTemplate';
import { emailTemplateSchema } from '../models/emailTemplate.schema';
import { createEmailTemplatePayloadSchema } from './create.schema';

export type CreateEmailTemplatePayload = EmailTemplatePayloadBase & {
  type: CustomEmailTemplateType;
  triggers?: Url[];
};

export const create = (payload: CreateEmailTemplatePayload) => {
  return {
    endpoint: `/email_templates`,
    method: 'POST',
    responseSchema: emailTemplateSchema,
    payloadSchema: createEmailTemplatePayloadSchema,
    payload,
  } as const;
};
