import { Email as EmailIcon } from '@rossum/ui/icons';
import { Badge, Stack, Tooltip } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { EmailMultiple } from '../../icons/EmailMultiple';

type Props = {
  onClick: () => void;
  hasReplies: boolean;
  hasNewReplies: boolean;
  isOriginal: boolean;
  dataCy?: string;
};

const Email = ({
  onClick,
  hasReplies,
  hasNewReplies,
  isOriginal,
  dataCy,
}: Props) => {
  const intl = useIntl();

  const Icon = hasReplies ? EmailMultiple : EmailIcon;
  const tooltipId = hasNewReplies
    ? 'components.email.tooltipWithNewReplies'
    : hasReplies
      ? 'components.email.tooltipWithReplies'
      : isOriginal
        ? 'components.email.tooltip'
        : 'components.email.tooltipOther';

  return (
    <Stack
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      sx={{ cursor: 'pointer' }}
      data-cy={dataCy}
    >
      <Tooltip title={intl.formatMessage({ id: tooltipId })} placement="top">
        <Badge
          variant="dot"
          color="info"
          invisible={!hasNewReplies}
          data-cy={hasNewReplies ? 'blue-kulicka' : 'without-blue-kulicka'}
        >
          <Icon fontSize="small" color="inherit" />
        </Badge>
      </Tooltip>
    </Stack>
  );
};

export default Email;
