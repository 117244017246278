import { FindReplace } from '@rossum/ui/icons';
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
} from '@rossum/ui/material';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getUIConfigurationFromSchema } from '../../redux/modules/schema/helpers';
import { setCurrentFooterColumn } from '../../redux/modules/searchAndReplace/actions';
import { AnyDatapointSchema } from '../../types/schema';
import FooterHeaderLabelIcon from './FooterHeaderLabelIcon';
import {
  calculateWidthStyle,
  canSearchAndReplaceIn,
  commonCellStyle,
} from './utils';

export type FooterHeaderTableCellProps = {
  column: AnyDatapointSchema;
  readOnly: boolean;
};

export const FooterHeaderTableCell = ({
  column,
  readOnly,
}: FooterHeaderTableCellProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ref = useRef<HTMLElement>();

  const fieldUiConfiguration = getUIConfigurationFromSchema(column);

  return (
    <TableCell
      component="th"
      sx={{
        pr: 0,
        pl: 0,
        ...commonCellStyle,
        // We always render find and replace element, just make it inactive.
        // This ensures that column labels will always wrap in the same way.
        // Otherwise, the header can have different height in read only mode
        // and the whole layout could shift.
        '&:hover [data-cy=find-and-replace]': readOnly
          ? {
              pointerEvents: 'none',
            }
          : {
              visibility: 'visible',
              opacity: 1,
            },
      }}
      align={column.type === 'number' ? 'right' : 'left'}
      style={calculateWidthStyle(column)}
      ref={ref}
    >
      <Stack
        direction={column.type === 'number' ? 'row-reverse' : 'row'}
        alignItems="flex-end"
        style={calculateWidthStyle(column)}
        sx={{
          pl: column.type === 'number' ? 0 : 2,
          pr: column.type === 'number' ? 2 : 0,
        }}
        spacing={0.5}
      >
        <FooterHeaderLabelIcon
          uiFieldType={fieldUiConfiguration?.type}
          readOnly={readOnly}
          isEditable={fieldUiConfiguration?.edit !== 'disabled'}
        />
        <Tooltip
          title={column.label}
          placement="top-start"
          arrow={false}
          enterDelay={500}
          enterNextDelay={500}
        >
          <Box
            sx={{
              flexGrow: 1,
              wordBreak: 'unset',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              pointerEvents: 'all',
            }}
          >
            {column.label}
          </Box>
        </Tooltip>
        {canSearchAndReplaceIn(column) && (
          <Tooltip
            title={intl.formatMessage({
              id: 'components.documentValidation.footer.searchAndReplace.tooltip',
            })}
            enterDelay={600}
          >
            <IconButton
              color="secondary"
              size="small"
              sx={{
                opacity: 0,
                transition: theme =>
                  theme.transitions.create('opacity', {
                    duration: 150,
                  }),
                p: 0,
              }}
              data-cy="find-and-replace"
              onClick={() => {
                dispatch(
                  setCurrentFooterColumn(
                    column.id,
                    ref.current?.getBoundingClientRect().right
                  )
                );
              }}
            >
              <FindReplace sx={{ fontSize: 'inherit' }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </TableCell>
  );
};
