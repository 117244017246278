// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

export const patchDeleteRecommendationPayloadSchema = z
  .object({
    queue: urlSchema,
    enabled: z.boolean(),
    triggers: z.array(urlSchema),
  })
  .partial();
