import { Add } from '@rossum/ui/icons';
import { alpha, Box, BoxProps, styled } from '@rossum/ui/material';
import {
  CONTROL_BAR_DISTANCE,
  GRID_LINE_WIDTH,
  INTERACTIVE_AREA_SIZE,
  SEPARATOR_HANDLE_SIZE,
} from './constants';

export type ControlBarProps = BoxProps & {
  orientation: 'horizontal' | 'vertical';
  isInteractive: boolean;
  cssPosition: number | null;
};

const StyledControlBar = styled(Box, {
  shouldForwardProp: propName =>
    propName !== 'orientation' &&
    propName !== 'cssPosition' &&
    propName !== 'isInteractive',
})<ControlBarProps>(({ orientation, theme, isInteractive }) => {
  const [primaryDim, secondaryDim, primaryPos, secondaryPos] =
    orientation === 'horizontal'
      ? (['width', 'height', 'top', 'left'] as const)
      : (['height', 'width', 'left', 'top'] as const);

  return {
    [primaryDim]: '100%',
    [secondaryDim]: GRID_LINE_WIDTH + 2 * INTERACTIVE_AREA_SIZE,
    position: 'absolute',
    [primaryPos]:
      -GRID_LINE_WIDTH - INTERACTIVE_AREA_SIZE - CONTROL_BAR_DISTANCE,
    [secondaryPos]: 0,
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth:
      orientation === 'horizontal'
        ? `${INTERACTIVE_AREA_SIZE}px 0`
        : `0 ${INTERACTIVE_AREA_SIZE}px`,
    backgroundClip: 'content-box',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.short,
    }),
    pointerEvents: isInteractive ? 'auto' : 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  };
});

const StyledControlBarHandle = styled(Box)(({ theme }) => ({
  width: SEPARATOR_HANDLE_SIZE,
  height: SEPARATOR_HANDLE_SIZE,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 3,
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  color: theme.palette.common.white,
  zIndex: 101,
}));

export const ControlBar = (props: ControlBarProps) => {
  const primaryDim = props.orientation === 'horizontal' ? 'left' : 'top';

  const secondaryDim = props.orientation === 'horizontal' ? 'top' : 'left';

  return (
    <StyledControlBar
      {...props}
      // ControlBar is always either ready or loading, in other states it is not shown at all
      isInteractive={props.isInteractive}
      style={{ cursor: props.isInteractive ? 'pointer' : 'progress' }}
    >
      {props.cssPosition && props.isInteractive ? (
        <StyledControlBarHandle
          style={{
            [primaryDim]:
              props.cssPosition -
              SEPARATOR_HANDLE_SIZE / 2 -
              GRID_LINE_WIDTH / 2,
            [secondaryDim]:
              -CONTROL_BAR_DISTANCE +
              SEPARATOR_HANDLE_SIZE / 2 -
              GRID_LINE_WIDTH / 2,
          }}
        >
          <Add fontSize="inherit" />
        </StyledControlBarHandle>
      ) : null}
    </StyledControlBar>
  );
};
