import { ElisRequestConfig } from '../../utils/requestConfig';
import { Upload } from '../models/upload';
import { uploadSchema } from '../models/upload.schema';

export const get = (uploadId: number) => {
  return {
    endpoint: `/uploads/${uploadId}`,
    method: 'GET',
    responseSchema: uploadSchema,
  } as const;
};
