import * as z from 'zod';
import { ElisRequestConfig } from '../../../utils/requestConfig';
import { resetPasswordPayloadSchema } from './reset.schema';

export type ResetPasswordPayload = {
  email: string;
  emailType?: string;
  adminEmail?: string;
};

export const resetPassword = (payload: ResetPasswordPayload) => {
  return {
    authorize: false,
    endpoint: `/auth/password/reset`,
    method: 'POST',
    payload,
    payloadSchema: resetPasswordPayloadSchema,
    responseSchema: z.unknown(),
  } as const;
};
