import { ElisRequestConfig } from '../../utils/requestConfig';
import { WorkflowRun, WorkflowRunUrl } from '../models/workflowRun';
import { workflowRunSchema } from '../models/workflowRun.schema';

export const get = (workflowRunUrl: WorkflowRunUrl) => {
  return {
    endpoint: workflowRunUrl,
    method: 'GET',
    responseSchema: workflowRunSchema,
  } as const;
};
