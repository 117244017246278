import { partition } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { asMutable } from 'seamless-immutable';
import { assertNever } from '../../lib/typeUtils';
import { currentDatapointSelector } from '../../redux/modules/datapoints/selector';
import {
  applyColumnsToAllGrids,
  applyGridToNextPages,
  copyGrid,
  deleteAllGrids,
  deleteGrid,
  updateGridAfterColumnsCleared,
  updateGridAfterHorizontalSeparatorCreated,
  updateGridAfterHorizontalSeparatorDeleted,
  updateGridAfterHorizontalSeparatorMove,
  updateGridAfterMoved,
  updateGridAfterResized,
  updateGridAfterRowsCleared,
  updateGridAfterRowTypeChanged,
  updateGridAfterSchemaIdAssigned,
  updateGridAfterVerticalSeparatorDeleted,
  updateGridAfterVerticalSeparatorMove,
  upgradeGridAfterVerticalSeparatorCreated,
} from '../../redux/modules/grid/actions';
import { isValidColumnForAnnotation } from '../../redux/modules/magicGrid/helpers';
import {
  findCurrentGrids,
  findMagicGridByPage,
  getColumnSchemas,
  getGridSchema,
} from '../../redux/modules/magicGrid/selector';
import { ColumnPosition, Grid, RowPosition } from '../../types/datapoints';
import { GridSchema } from '../../types/schema';
import { State } from '../../types/state';
import { NOT_EXTRACTED_COLOR, ROW_TYPE_COLORS } from './constants';
import { GridAction, isColumnSafe, isRowSafe, ResizingEdge } from './utils';

export type UseGridStateParams = {
  pageNumber: number;
  datapointIndex: number;
};

export type RowTypeOption = {
  id: string | null;
  label: string;
  color: string | null;
};

export const useGridState = ({
  pageNumber,
  datapointIndex,
}: UseGridStateParams) => {
  const intl = useIntl();

  // data currently saved in Redux + server
  const magicGridData = useSelector((state: State) =>
    findMagicGridByPage(state)(pageNumber)
  );

  const allGrids = useSelector(findCurrentGrids);

  // TODO: This probably won't work when displaying grid always?
  const columnSchemas = useSelector(getColumnSchemas);

  // TODO: This is fairly ugly
  const schemaIdOptions = useMemo(
    () =>
      columnSchemas && magicGridData?.columns
        ? [
            {
              id: null,
              label: intl.formatMessage({
                id: 'components.magicGrid.labelSelect.notExtracted',
              }),
            },
            ...partition(
              asMutable(columnSchemas)
                // include only Unset and Captured field types
                .filter(isValidColumnForAnnotation)
                .map(columnSchema => ({
                  id: columnSchema.id,
                  label: columnSchema.label,
                })),
              columnSchema =>
                !magicGridData.columns.some(
                  col => col.schemaId === columnSchema.id
                )
            ).flat(),
          ]
        : [],
    // columnSchemas now change every time datapoint is switched becase of datapointsSelector
    // if we could fix that we would save a lot of grid rerenders
    [columnSchemas, intl, magicGridData?.columns]
  );

  const _gridSchema: GridSchema = useSelector((state: State) =>
    getGridSchema(state)
  );

  const gridSchema: Required<GridSchema> = useMemo(
    () => ({
      rowTypes: _gridSchema.rowTypes ?? [],
      defaultRowType: _gridSchema.defaultRowType ?? 'data',
      rowTypesToExtract: _gridSchema.rowTypesToExtract ?? [],
    }),
    [
      _gridSchema.defaultRowType,
      _gridSchema.rowTypes,
      _gridSchema.rowTypesToExtract,
    ]
  );

  const documentReadOnly = useSelector((state: State) => state.ui.readOnly);

  const currentDatapointIndex =
    useSelector((state: State) => currentDatapointSelector(state))?.meta
      ?.index ?? null;

  const lineItemIsFocused = currentDatapointIndex === datapointIndex;

  const actionInProgress = useSelector(
    (state: State) => state.ui.actionInProgress
  );

  // defaultRowType and 'null' (not extracted) have a bit of a special treatment when it comes to label and color
  const rowTypeOptions = useMemo(() => {
    return [
      {
        id: gridSchema.defaultRowType,
        label: intl.formatMessage({
          id: 'components.magicGrid.rowTypes.extract',
        }),
        color: null,
      },
      {
        id: null,
        label: intl.formatMessage({
          id: 'components.magicGrid.labelSelect.notExtracted',
        }),
        color: NOT_EXTRACTED_COLOR,
      },
      ...gridSchema.rowTypes
        .filter(type => type !== gridSchema.defaultRowType)
        .map((type, index) => ({
          id: type,
          label: type,
          color: ROW_TYPE_COLORS[index % ROW_TYPE_COLORS.length],
        })),
    ];
  }, [gridSchema.defaultRowType, gridSchema.rowTypes, intl]);

  const dispatch = useDispatch();

  // send position update to Redux
  const handleGridMoved = useCallback(
    (newState: Grid) => {
      dispatch(
        updateGridAfterMoved({
          datapointIndex,
          page: pageNumber,
          grid: newState,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleGridResized = useCallback(
    (newState: Grid, resizingEdge: ResizingEdge) => {
      const { rows, removedRowsIndexes } = newState.rows.reduce<{
        rows: RowPosition[];
        removedRowsIndexes: number[];
      }>(
        (acc, gridRow, index) => {
          if (index === 0 || isRowSafe(newState, gridRow)) {
            return { ...acc, rows: [...acc.rows, gridRow] };
          }

          return {
            ...acc,
            removedRowsIndexes: [...acc.removedRowsIndexes, index],
          };
        },
        { rows: [], removedRowsIndexes: [] }
      );

      const { columns, removedColumnsIndexes } = newState.columns.reduce<{
        columns: ColumnPosition[];
        removedColumnsIndexes: number[];
      }>(
        (acc, gridColumn, index) => {
          if (index === 0 || isColumnSafe(newState, gridColumn)) {
            return { ...acc, columns: [...acc.columns, gridColumn] };
          }
          return {
            ...acc,
            removedColumnsIndexes: [...acc.removedColumnsIndexes, index],
          };
        },
        { columns: [], removedColumnsIndexes: [] }
      );

      dispatch(
        updateGridAfterResized({
          datapointIndex,
          page: pageNumber,
          grid: {
            ...newState,
            rows,
            columns,
          },
          resizingEdge,
          removedRowsIndexes,
          removedColumnsIndexes,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleVerticalSeparatorMoved = useCallback(
    (newState: Grid, separatorIndex: number) => {
      dispatch(
        updateGridAfterVerticalSeparatorMove({
          datapointIndex,
          page: pageNumber,
          grid: newState,
          separatorIndex,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleVerticalSeparatorDeleted = useCallback(
    (newState: Grid, separatorIndex: number) => {
      dispatch(
        updateGridAfterVerticalSeparatorDeleted({
          datapointIndex,
          page: pageNumber,
          grid: newState,
          separatorIndex,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleVerticalSeparatorCreated = useCallback(
    (grid: Grid, newPosition: number) => {
      dispatch(
        upgradeGridAfterVerticalSeparatorCreated({
          datapointIndex,
          page: pageNumber,
          grid,
          newPosition,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleHorizontalSeparatorMoved = useCallback(
    (newState: Grid, separatorIndex: number) => {
      dispatch(
        updateGridAfterHorizontalSeparatorMove({
          datapointIndex,
          grid: newState,
          page: pageNumber,
          separatorIndex,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleHorizontalSeparatorDeleted = useCallback(
    (grid: Grid, separatorIndex: number) => {
      dispatch(
        updateGridAfterHorizontalSeparatorDeleted({
          datapointIndex,
          page: pageNumber,
          grid,
          separatorIndex,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleHorizontalSeparatorCreated = useCallback(
    (grid: Grid, newPosition: number) => {
      dispatch(
        updateGridAfterHorizontalSeparatorCreated({
          datapointIndex,
          page: pageNumber,
          grid,
          newPosition,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleSchemaIdsAssigned = useCallback(
    (
      newState: Grid,
      changes: {
        separatorIndex: number;
        prev: string | null;
        current: string | null;
      }[]
    ) => {
      dispatch(
        updateGridAfterSchemaIdAssigned({
          datapointIndex,
          page: pageNumber,
          grid: newState,
          changes,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleRowTypeChanged = useCallback(
    (newState: Grid, separatorIndex: number, rowType: string | null) => {
      dispatch(
        updateGridAfterRowTypeChanged({
          datapointIndex,
          page: pageNumber,
          grid: newState,
          separatorIndex,
          rowType,
        })
      );
    },
    [datapointIndex, dispatch, pageNumber]
  );

  const handleGridAction = useCallback(
    (actionId: GridAction, newState: Grid) => {
      switch (actionId) {
        case 'clearColumns': {
          return dispatch(
            updateGridAfterColumnsCleared({
              grid: newState,
              datapointIndex,
              page: pageNumber,
            })
          );
        }
        case 'clearRows': {
          return dispatch(
            updateGridAfterRowsCleared({
              grid: newState,
              datapointIndex,
              page: pageNumber,
            })
          );
        }
        case 'copyGrid': {
          return dispatch(copyGrid(newState));
        }
        case 'applyGrid': {
          return dispatch(
            applyGridToNextPages({
              datapointIndex,
              page: pageNumber,
              grids: allGrids,
            })
          );
        }
        case 'applyColumns': {
          return dispatch(
            applyColumnsToAllGrids({
              datapointIndex,
              columns: newState.columns,
              page: pageNumber,
              width: newState.width,
            })
          );
        }
        case 'deleteGrid': {
          return dispatch(
            deleteGrid({
              datapointIndex,
              page: pageNumber,
            })
          );
        }
        case 'deleteAllGrids': {
          return dispatch(
            deleteAllGrids({ datapointIndex, gridCount: allGrids.length })
          );
        }
        default: {
          return assertNever(actionId);
        }
      }
    },
    [allGrids, datapointIndex, dispatch, pageNumber]
  );

  return {
    serverGridState: magicGridData,
    schemaIdOptions,
    gridSchema,
    rowTypeOptions,
    documentReadOnly,
    lineItemIsFocused,
    actionInProgress,
    handleGridMoved,
    handleGridResized,
    handleVerticalSeparatorMoved,
    handleVerticalSeparatorDeleted,
    handleVerticalSeparatorCreated,
    handleSchemaIdsAssigned,
    handleHorizontalSeparatorCreated,
    handleHorizontalSeparatorMoved,
    handleHorizontalSeparatorDeleted,
    handleRowTypeChanged,
    handleGridAction,
  } as const;
};
