import clsx from 'clsx';
import CheckIcon from 'mdi-react/CheckIcon';
import { useIntl } from 'react-intl';
import { EventAction } from '../../../lib/helpers';
import styles from '../style.module.sass';

type Props = {
  onClick: () => void;
  selected: boolean;
  type: EventAction;
};

const Action = ({ selected, onClick, type }: Props) => {
  const intl = useIntl();
  return (
    <div
      className={clsx(styles.ActionWrapper, styles.Item)}
      onClick={onClick}
      data-cy="extension-event-action"
    >
      <div
        className={clsx(
          styles.IconWrapper,
          selected && styles.SelectedIconWrapper
        )}
      >
        <CheckIcon size={18} />
      </div>
      <div className={styles.ActionDescriptionWrapper}>
        {intl.formatMessage({
          id: `containers.settings.extensions.events.${type}`,
        })}
        <span className={styles.ActionDescription}>
          {intl.formatMessage({
            id: `containers.settings.extensions.events.description.${type}`,
          })}
        </span>
      </div>
    </div>
  );
};

export default Action;
