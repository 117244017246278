import { Message } from '@rossum/api-client/shared';
import { DEV_DEBUG_ENABLED } from '../../constants/config';

type Defined = NonNullable<Message['detail']>;

// sending message attributes separately to have better type predicate for detail type
type DetailPredicate = (
  detail: Message['detail'],
  type: Message['type']
) => detail is Defined;

export const isMessageExceptionalError: DetailPredicate = (
  detail,
  type
): detail is Defined => !!(detail && detail.isException && type === 'error');

export const isMessageDetailVisible: DetailPredicate = (
  detail,
  type
): detail is Defined =>
  isMessageExceptionalError(detail, type) || !!(detail && DEV_DEBUG_ENABLED);
