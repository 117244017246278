import { SvgIcon, SvgIconProps } from '@rossum/ui/material';

type SectionIconProps = SvgIconProps;

const SectionIcon = (props: SectionIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
      <path
        d="M6.66667 5.83321C6.20643 5.83321 5.83333 6.20631 5.83333 6.66654C5.83333 7.12678 6.20643 7.49988 6.66667 7.49988H13.3333C13.7936 7.49988 14.1667 7.12678 14.1667 6.66654C14.1667 6.20631 13.7936 5.83321 13.3333 5.83321H6.66667Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M6.66667 9.16654C6.20643 9.16654 5.83333 9.53964 5.83333 9.99988C5.83333 10.4601 6.20643 10.8332 6.66667 10.8332H13.3333C13.7936 10.8332 14.1667 10.4601 14.1667 9.99988C14.1667 9.53964 13.7936 9.16654 13.3333 9.16654H6.66667Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M5.83333 13.3332C5.83333 12.873 6.20643 12.4999 6.66667 12.4999H13.3333C13.7936 12.4999 14.1667 12.873 14.1667 13.3332C14.1667 13.7934 13.7936 14.1665 13.3333 14.1665H6.66667C6.20643 14.1665 5.83333 13.7934 5.83333 13.3332Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.16654C2.5 3.24607 3.24619 2.49988 4.16667 2.49988H15.8333C16.7538 2.49988 17.5 3.24607 17.5 4.16654V15.8332C17.5 16.7537 16.7538 17.4999 15.8333 17.4999H4.16667C3.24619 17.4999 2.5 16.7537 2.5 15.8332V4.16654ZM4.16667 4.16654H15.8333V15.8332H4.16667L4.16667 4.16654Z"
        fill="white"
        fillOpacity="0.7"
      />
    </SvgIcon>
  );
};

export default SectionIcon;
