import { Skeleton, Stack, Switch, TextField } from '@rossum/ui/material';
import { random } from 'lodash';
import { Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ContentGroup } from '../../../../../ui/content-group/ContentGroup';

export const FieldFormSkeleton = () => {
  const intl = useIntl();

  const descriptionWidths = useRef(
    Array.from({ length: 4 }, () => `${random(80, 100)}%`)
  ).current;

  const optionBoxWidths = useRef(
    Array.from({ length: 3 }, () => ({
      title: `${random(50, 100)}%`,
      description: [`${random(50, 80)}%`, `${random(40, 60)}%`],
    }))
  ).current;

  return (
    <Stack px={4} py={8} spacing={8}>
      <Stack direction="row" spacing={4}>
        {optionBoxWidths.map((option, index) => (
          <Stack
            // eslint-disable-next-line react/no-array-index-key
            key={`${option.title}-${index}`}
            direction="row"
            px={4}
            py={3}
            gap={2}
            width="33%"
            sx={{
              borderRadius: 2,
              backgroundColor: t => t.palette.background.paper,
            }}
          >
            <Stack spacing={1} justifyContent="space-between" flexGrow={1}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Skeleton width={option.title} height={30} />
              </Stack>
              <Stack>
                {option.description.map((width, index) => (
                  <Skeleton
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${width}-${index}`}
                    width={width}
                    height={14}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack pr={1}>
              <Switch size="small" disabled />
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" spacing={4}>
        <ContentGroup
          title={<Skeleton width={200} height={40} />}
          description={
            <Stack>
              {descriptionWidths.map((width, index) => (
                <Fragment
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${width}-${index}`}
                >
                  <Skeleton width={width} />
                  {index === 2 && <br />}
                </Fragment>
              ))}
            </Stack>
          }
        >
          <Stack width={1} spacing={4}>
            <TextField
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.label.label',
              })}
              value=" "
              variant="outlined"
              disabled
            />
            <TextField
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.id.label',
              })}
              value=" "
              variant="outlined"
              disabled
            />
            <TextField
              label={intl.formatMessage({
                id: 'features.queueSettings.fields.form.description.label',
              })}
              value=" "
              variant="outlined"
              disabled
            />
          </Stack>
        </ContentGroup>
      </Stack>
    </Stack>
  );
};
