import { FormatListBulleted as FormatListBulletedIcon } from '@rossum/ui/icons';
import { Timeline as TimelineIcon } from '@rossum/ui/icons';
import { Stack, ToggleButton, ToggleButtonGroup } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { ExtensionsView } from '../../../types/extensions';

type Props = {
  activeView: ExtensionsView;
  onViewChange: (view: ExtensionsView) => void;
};

const icons: Record<ExtensionsView, JSX.Element> = {
  list: <FormatListBulletedIcon fontSize="small" />,
  graph: <TimelineIcon fontSize="small" />,
};

const ViewSwitcher = ({ activeView, onViewChange }: Props) => {
  const intl = useIntl();

  return (
    <Stack sx={{ pl: 3 }}>
      <ToggleButtonGroup
        value={activeView}
        exclusive
        onChange={(_, value: ExtensionsView) => {
          if (value !== null) {
            onViewChange(value);
          }
        }}
        color="primary"
        size="small"
      >
        {(['list', 'graph'] as const).map(viewType => (
          <ToggleButton
            key={viewType}
            value={viewType}
            aria-label={viewType}
            sx={{ px: 2 }}
            data-cy={`extensions-${viewType}-view`}
            color="secondary"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              {icons[viewType]}
              <span>
                {intl.formatMessage({
                  id: `containers.settings.extensions.view.${viewType}`,
                })}
              </span>
            </Stack>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ViewSwitcher;
