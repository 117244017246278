import { endpoints } from '@rossum/api-client';
import { RenderEmailTemplatePayload } from '@rossum/api-client/emailTemplates';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../../lib/apiClient';

export const useRenderTemplate = () =>
  useMutation({
    mutationFn: ({
      templateId,
      payload,
    }: {
      templateId: number;
      payload: RenderEmailTemplatePayload;
    }) => {
      return api.request(endpoints.emailTemplates.render(templateId, payload));
    },
  });
