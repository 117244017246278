import { ID } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';

export const getHistoryExport =
  (endpoint: 'organizations' | 'organization_groups') => (id: ID) => {
    return {
      endpoint: `/${endpoint}/${id}/billing_history/export`,
      method: 'GET',
      responseType: 'blob',
    } as const;
  };
