import { UsageReport } from '@rossum/api-client/annotations';
import { ActionType, createAction } from 'typesafe-actions';

export const fetchStatistics = createAction('FETCH_STATISTICS')<void>();

export const fetchStatisticsFulfilled = createAction(
  'FETCH_STATISTICS_FULFILLED',
  (payload: UsageReport) => payload
)<UsageReport>();

export const showTmpStatistics = createAction(
  'SHOW_TMP_STATISTICS',
  (payload: UsageReport) => payload
)<UsageReport>();

export type StatisticsActions = ActionType<
  | typeof fetchStatistics
  | typeof fetchStatisticsFulfilled
  | typeof showTmpStatistics
>;
