// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../types.schema';

export const suggestedRecipientsPayloadSchema = z.object({
  emailThreads: z.array(urlSchema).optional(),
  annotations: z.array(urlSchema).optional(),
});

export const suggestedRecipientSchema = z.object({
  email: z.string(),
  name: z.string().nullable(),
  source: z.string(),
});

export const suggestedRecipientsResponseSchema = z.object({
  results: z.array(suggestedRecipientSchema),
});
