import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY_WORKSPACES_UNPAGINATED } from './useUnpaginatedWorkspaces';

export const addQueueToWorkspaceMutation = (
  queryClient: QueryClient,
  queue: Queue
) => {
  queryClient.setQueriesData<Workspace[] | undefined>(
    [QUERY_KEY_WORKSPACES_UNPAGINATED],
    prev => {
      const workspaceIndex =
        prev?.findIndex(ws => ws.url === queue.workspace) ?? -1;
      const workspace =
        workspaceIndex > -1 ? prev?.[workspaceIndex] : undefined;

      if (workspaceIndex > -1 && workspace && prev) {
        return [
          ...prev.slice(0, workspaceIndex),
          {
            ...workspace,
            queues: [...workspace.queues, queue.url],
          },
          ...prev.slice(workspaceIndex + 1),
        ];
      }

      return prev;
    }
  );
};
