import { ElisRequestConfig } from '../../utils/requestConfig';
import { User } from '../models/user';
import { userSchema } from '../models/user.schema';
import { createUserPayloadSchema } from './create.schema';

export type CreateUserPayload = {
  authType: string;
  email?: string;
  groups: string[];
  queues: string[];
  username: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  password?: string;
  url?: string;
  uiSettings?: unknown;
};

export const create = (payload: CreateUserPayload) => {
  return {
    endpoint: `/users`,
    method: 'POST',
    responseSchema: userSchema,
    payloadSchema: createUserPayloadSchema,
    payload,
  } as const;
};
