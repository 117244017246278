import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { OrganizationGroupOrganizationListQuery } from '@rossum/api-client/organizationGroups';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { BillingEntity } from './useBillingEntity';

const QUERY_KEY_ORGANIZATIONS_UNPAGINATED = 'organizations-unpaginated';

export const useUnpaginatedOrganizations = (
  billingEntity: BillingEntity,
  query: OrganizationGroupOrganizationListQuery = {}
) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [
      QUERY_KEY_ORGANIZATIONS_UNPAGINATED,
      billingEntity,
      query,
    ] as const,

    queryFn: () =>
      billingEntity.type === 'organization_group'
        ? api.unpaginatedRequest(endpoints.organizationGroups.organizations)({
            organizationGroupId: getIDFromUrl(
              billingEntity.organizationGroupUrl
            ),
            ...query,
          })
        : null,
    enabled: billingEntity.type === 'organization_group',
  });
};
