import clsx from 'clsx';
import { debounce } from 'lodash';
import DragDocumentToolbarIcon from 'mdi-react/DragHorizontalIcon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONTROL_BAR_HEIGHT, SIDEBAR_WIDTH } from '../../../constants/values';
import { useDocumentContext } from '../../../features/annotation-view/DocumentContext';
import { getCurrentAnnotationId } from '../../../lib/url';
import { safeOrganizationSelector } from '../../../redux/modules/organization/selectors';
import { State } from '../../../types/state';
import {
  CONTROL_PANEL_DEFAULT_RIGHT,
  CONTROL_PANEL_HEIGHT,
  CONTROL_PANEL_WIDTH,
  DRAG_HANDLE_HALF_SIZE,
  limitToolbarToDocumentView,
} from '../helpers';
import styles from '../style.module.sass';
import { EditModeButton } from './EditModeButton';
import FindButton from './FindButton';
import Zoom from './Zoom';

type DocumentToolbarState = {
  position: { x: number; y: number };
  dragging: boolean;
  toolbarInSafeZone: boolean;
};

type DocumentToolbarProps = {
  footerHeight: number | undefined;
  increaseZoom: () => void;
  decreaseZoom: () => void;
  resetZoom: () => void;
};

const getDefaultPosition = (footerHeight: number | undefined) => {
  const defaultX =
    window.innerWidth -
    SIDEBAR_WIDTH -
    CONTROL_PANEL_WIDTH / 2 -
    CONTROL_PANEL_DEFAULT_RIGHT;

  const edges =
    (window.innerHeight -
      CONTROL_BAR_HEIGHT -
      CONTROL_PANEL_HEIGHT -
      (footerHeight ?? 0)) /
    2;
  const top = CONTROL_PANEL_HEIGHT / 2 + CONTROL_BAR_HEIGHT;

  return { x: defaultX, y: edges + top };
};

const calculatePanelCoordinates = (
  footerHeight: number | undefined,
  x: number,
  y: number,
  toolbarInSafeZone: boolean
) => {
  const { constrainedX, constrainedY } = limitToolbarToDocumentView(
    x,
    y,
    footerHeight
  );

  const defaultPosition = getDefaultPosition(footerHeight);

  const coordinates = toolbarInSafeZone
    ? defaultPosition
    : { x: constrainedX, y: constrainedY };

  return coordinates;
};

const updateToolbarPosition = (
  x: number,
  y: number,
  footerHeight: number | undefined,
  callback: React.Dispatch<React.SetStateAction<DocumentToolbarState>>
) => {
  const { constrainedX, constrainedY } = limitToolbarToDocumentView(
    x,
    y,
    footerHeight
  );
  callback(prevState => ({
    ...prevState,
    position: {
      x: constrainedX,
      y: constrainedY,
    },
    toolbarInSafeZone: x > getDefaultPosition(footerHeight).x,
  }));
};

const useDocumentToolbarInteraction = ({
  footerHeight,
  documentToolbarRef,
}: {
  footerHeight: number | undefined;
  documentToolbarRef: React.RefObject<HTMLDivElement>;
}) => {
  const [documentToolbarState, setDocumentToolbarState] =
    useState<DocumentToolbarState>({
      position: getDefaultPosition(footerHeight),
      dragging: false,
      toolbarInSafeZone: true,
    });

  const handleDocumentMouseUp = useCallback(() => {
    if (documentToolbarState.dragging) {
      setDocumentToolbarState(prevState => ({ ...prevState, dragging: false }));
    }
  }, [documentToolbarState.dragging]);

  const handleToolbarMouseMove = useCallback(
    (e: MouseEvent) => {
      if (documentToolbarState.dragging) {
        updateToolbarPosition(
          e.clientX - SIDEBAR_WIDTH,
          e.clientY + CONTROL_PANEL_HEIGHT / 2 - DRAG_HANDLE_HALF_SIZE,
          footerHeight,
          setDocumentToolbarState
        );
      }
    },
    [documentToolbarState.dragging, footerHeight]
  );

  const handleToolbarMouseDown = useCallback(() => {
    if (!documentToolbarState.dragging) {
      setDocumentToolbarState(prevState => ({
        ...prevState,
        dragging: true,
      }));
    }
  }, [documentToolbarState.dragging]);

  const handleWindowResize = useMemo(
    () =>
      debounce(
        () =>
          setDocumentToolbarState(prevState => ({
            ...prevState,
            position: calculatePanelCoordinates(
              footerHeight,
              prevState.position.x,
              prevState.position.y,
              prevState.toolbarInSafeZone
            ),
          })),
        100
      ),
    [footerHeight]
  );

  useEffect(() => {
    const toolbarRef = documentToolbarRef.current;
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mousemove', handleToolbarMouseMove);
    toolbarRef?.addEventListener('mousedown', handleToolbarMouseDown);
    window.addEventListener('resize', handleWindowResize);

    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mousemove', handleToolbarMouseMove);
      toolbarRef?.removeEventListener('mousedown', handleToolbarMouseDown);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [
    documentToolbarRef,
    handleDocumentMouseUp,
    handleToolbarMouseDown,
    handleToolbarMouseMove,
    handleWindowResize,
  ]);

  useEffect(() => {
    setDocumentToolbarState(prevState => ({
      ...prevState,
      position: calculatePanelCoordinates(
        footerHeight,
        prevState.position.x,
        prevState.position.y,
        prevState.toolbarInSafeZone
      ),
    }));
  }, [footerHeight]);

  return documentToolbarState;
};

const DocumentToolbar = ({
  footerHeight,
  increaseZoom,
  decreaseZoom,
  resetZoom,
}: DocumentToolbarProps) => {
  const documentToolbarRef = useRef<HTMLDivElement>(null);
  const { canvasActionInProgress } = useDocumentContext();

  const { position, dragging, toolbarInSafeZone } =
    useDocumentToolbarInteraction({
      footerHeight,
      documentToolbarRef,
    });

  const pages = useSelector((state: State) => state.pages.pages);
  const currentAnnotationId = useSelector((state: State) =>
    getCurrentAnnotationId(state.router.location.pathname)
  );
  const annotationStatus = useSelector(
    (state: State) => state.annotation.status
  );
  const organization = useSelector(safeOrganizationSelector);
  const readOnly = useSelector((state: State) => state.ui.readOnly);

  const disableEditMode = !!organization?.uiSettings?.features?.disableEditMode;

  return (
    <div
      id="documentToolBar"
      style={{
        top: position.y,
        left: position.x,
      }}
      className={clsx(
        styles.ControlPanel,
        toolbarInSafeZone && !dragging && styles.ControlPanelInSafeZone,
        canvasActionInProgress &&
          toolbarInSafeZone &&
          styles.ControlPanelHidden,
        pages.length === 0 && styles.ControlPanelHidden
      )}
    >
      <div
        className={clsx(
          styles.ControlPanelDragIcon,
          dragging && styles.ControlPanelDragIconDragging
        )}
        ref={documentToolbarRef}
      >
        <DragDocumentToolbarIcon size={20} />
      </div>
      <Zoom
        onZoomIn={increaseZoom}
        onZoomOut={decreaseZoom}
        onZoomReset={resetZoom}
      />
      {(!readOnly || annotationStatus === 'split') && !disableEditMode && (
        <EditModeButton annotationId={currentAnnotationId} />
      )}
      <FindButton />
    </div>
  );
};

export default DocumentToolbar;
