// Generated by ts-to-zod
import { z } from 'zod';

export const engineFieldTypeSchema = z.union([
  z.literal('string'),
  z.literal('number'),
  z.literal('date'),
  z.literal('enum'),
]);

export const multilineSchema = z.string();
