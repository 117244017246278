import { SvgIcon, SvgIconProps } from '@rossum/ui/material';

type DuplicateIconProps = SvgIconProps;

export const DuplicateIcon = (props: DuplicateIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M1.66675 3.62508C1.66675 2.54352 2.54352 1.66675 3.62508 1.66675H12.6251C13.7066 1.66675 14.5834 2.54352 14.5834 3.62508V5.41675H16.7501C17.6245 5.41675 18.3334 6.12563 18.3334 7.00008V16.7501C18.3334 17.6245 17.6245 18.3334 16.7501 18.3334H7.00008C6.12563 18.3334 5.41675 17.6245 5.41675 16.7501V14.5834H3.62508C2.54352 14.5834 1.66675 13.7066 1.66675 12.6251V3.62508ZM7.08342 14.5834V16.6667H16.6667V7.08342H14.5834V12.6251C14.5834 13.7066 13.7066 14.5834 12.6251 14.5834H7.08342ZM3.62508 3.33341C3.464 3.33341 3.33341 3.464 3.33341 3.62508V12.6251C3.33341 12.7862 3.464 12.9167 3.62508 12.9167H12.6251C12.7862 12.9167 12.9167 12.7862 12.9167 12.6251V3.62508C12.9167 3.464 12.7862 3.33341 12.6251 3.33341H3.62508Z" />
    </SvgIcon>
  );
};
