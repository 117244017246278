import { DedicatedEngine } from '@rossum/api-client/dedicatedEngines';
import { GenericEngine } from '@rossum/api-client/genericEngines';
import { OpenInNew } from '@rossum/ui/icons';
import { Box, Link, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ConditionalAuroraChip from '../../../components/conditional-aurora-chip/ConditionalAuroraChip';
import { auroraEngineEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { shouldDisplayAuroraChipByName } from '../../../ui/aurora/AuroraChip';
import EngineStatusChip from '../../legacy-engines/components/EngineStatusChip';
import { isDedicatedEngine } from '../../legacy-engines/hooks/utils';
import { useQueuesForEngine } from '../hooks/useQueuesForEngine';
import EngineQueues from './EngineQueues';
import TileLink from './TileLink';

type EngineCardProps = {
  engine: DedicatedEngine | GenericEngine;
  configureEngineButton?: React.ReactNode;
};

const EngineTile = ({ engine, configureEngineButton }: EngineCardProps) => {
  const intl = useIntl();

  const auroraEngineEnabled = useSelector(auroraEngineEnabledSelector);

  const shouldDisplayAuroraChip = shouldDisplayAuroraChipByName(
    engine.name,
    auroraEngineEnabled
  );

  const isDedicated = isDedicatedEngine(engine);

  const queuesQuery = isDedicated
    ? { dedicatedEngine: engine.id }
    : { genericEngine: engine.id };

  const { data, isFetching } = useQueuesForEngine(queuesQuery);

  const queuesInfo = {
    info: data?.info ?? [],
    totalCount: data?.pagination?.total ?? 0,
  };

  return (
    <TileLink>
      <Stack direction="row" width={1}>
        <Stack flex={1} spacing={2}>
          <Stack flex={1} spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">{engine.name}</Typography>
              {isDedicated ? (
                <EngineStatusChip
                  status={
                    queuesInfo.totalCount > 0 &&
                    engine.status === 'training_finished'
                      ? 'active'
                      : engine.status
                  }
                />
              ) : (
                <Stack direction="row" spacing={2} alignItems="center">
                  {queuesInfo.totalCount > 0 ? (
                    <EngineStatusChip status="active" />
                  ) : null}
                  {shouldDisplayAuroraChip ? (
                    <Box sx={{ width: 'fit-content', mb: 1.5 }}>
                      <ConditionalAuroraChip size="small" />
                    </Box>
                  ) : null}
                  {engine.documentationUrl ? (
                    <Stack
                      component={Link}
                      data-cy="ai-engines-documentation"
                      href={engine.documentationUrl}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        fontWeight: t => t.typography.fontWeightBold,
                        cursor: 'pointer',
                        textDecoration: 'none',
                        '&:hover': {
                          color: t => t.palette.primary.main,
                        },
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <span>
                          {intl.formatMessage({
                            id: 'components.engineCard.documentationLink',
                          })}
                        </span>
                        <OpenInNew fontSize="small" />
                      </Stack>
                    </Stack>
                  ) : null}
                </Stack>
              )}
            </Stack>
            <Typography variant="body2" color="text.secondary">
              {engine.description}
            </Typography>
          </Stack>
          <EngineQueues isLoading={isFetching} queuesInfo={queuesInfo} />
        </Stack>
        <Stack>{configureEngineButton}</Stack>
      </Stack>
    </TileLink>
  );
};

export default EngineTile;
