import clsx from 'clsx';
import ListIcon from 'mdi-react/FormatListBulletedIcon';
import GridIcon from 'mdi-react/GridIcon';
import { DISPLAYED_VALUE_CLASSNAME } from '../../../constants/values';
import styles from '../styles.module.sass';

type Props = {
  amount: number;
  isSimpleMultivalue: boolean;
  active?: boolean;
};

const MultivalueBadge = ({ amount, isSimpleMultivalue, active }: Props) => (
  <div
    className={clsx(
      styles.MultivalueCollapsed,
      active && styles.MultiValueCollapsedActive
    )}
  >
    <span className={clsx(styles.Number, DISPLAYED_VALUE_CLASSNAME)}>
      {amount}
    </span>
    {isSimpleMultivalue ? (
      <ListIcon className={styles.TypeIcon} />
    ) : (
      <GridIcon className={styles.TypeIcon} />
    )}
  </div>
);

export default MultivalueBadge;
