import { Check, MoreVert } from '@rossum/ui/icons';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  automaticSuggestionsEnabledSelector,
  complexLineItemsEnabledSelector,
  complexTablesEnabledOnOrganization,
} from '../../redux/modules/ui/selectors';
import { updateUiSettings } from '../../redux/modules/user/actions';
import { FooterLayout, SortFooterColumns } from '../../types/user';

// TODO: CLI-UX: remove completely once CLI feature is released

export const FooterMenu = ({
  layout,
  onFooterLayoutChanged,
  sortFooterColumns,
  onSortFooterColumnsChanged,
  onComplexLineItemsChanged,
}: {
  layout: FooterLayout;
  onFooterLayoutChanged: (value: FooterLayout) => void;
  sortFooterColumns: SortFooterColumns;
  onSortFooterColumnsChanged: (value: SortFooterColumns) => void;
  onComplexLineItemsChanged: (cli: boolean) => void;
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const cliFeatureFlag = useSelector(complexTablesEnabledOnOrganization);

  const cliEnabled = useSelector(complexLineItemsEnabledSelector);
  const automaticSuggestionsEnabled = useSelector(
    automaticSuggestionsEnabledSelector
  );
  const dispatch = useDispatch();

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ p: '5px', minWidth: '36px' }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVert />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onFooterLayoutChanged('table-per-page');
          }}
          data-cy="footer-layout-table-per-page-menu-item"
        >
          {layout === 'table-per-page' ? (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          ) : null}
          <ListItemText inset={layout !== 'table-per-page'}>
            {intl.formatMessage({
              id: 'components.documentValidation.footer.layout.footerTablePerPage',
            })}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onFooterLayoutChanged('one-table');
          }}
          data-cy="footer-layout-one-table-menu-item"
        >
          {layout === 'one-table' ? (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          ) : null}
          <ListItemText inset={layout !== 'one-table'}>
            {intl.formatMessage({
              id: 'components.documentValidation.footer.layout.footerOneTable',
            })}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onSortFooterColumnsChanged('automatically');
          }}
          data-cy="sort-columns-automatically-menu-item"
        >
          {sortFooterColumns === 'automatically' ? (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          ) : null}
          <ListItemText inset={sortFooterColumns !== 'automatically'}>
            {intl.formatMessage({
              // keeping original ids even though it should mention column sorting
              id: 'components.documentValidation.footer.layout.tablePerPage',
            })}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onSortFooterColumnsChanged('queue-settings');
          }}
          data-cy="sort-columns-queue-settings-menu-item"
        >
          {sortFooterColumns === 'queue-settings' ? (
            <ListItemIcon>
              <Check />
            </ListItemIcon>
          ) : null}
          <ListItemText inset={sortFooterColumns !== 'queue-settings'}>
            {intl.formatMessage({
              id: 'components.documentValidation.footer.layout.oneTable',
            })}
          </ListItemText>
        </MenuItem>
        {cliFeatureFlag && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onComplexLineItemsChanged(!cliEnabled);
            }}
            data-cy="cli-toggle"
          >
            {cliEnabled ? (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            ) : null}
            <ListItemText inset={!cliEnabled}>
              New line items interface (beta)
            </ListItemText>
          </MenuItem>
        )}
        {cliFeatureFlag && (
          <MenuItem
            disabled={!cliEnabled}
            onClick={() => {
              setAnchorEl(null);
              dispatch(
                updateUiSettings({
                  automaticSuggestions: !automaticSuggestionsEnabled,
                })
              );
            }}
          >
            {automaticSuggestionsEnabled ? (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            ) : null}
            <ListItemText inset={!automaticSuggestionsEnabled}>
              Automatic suggestions
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
