// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const asyncTaskTypeSchema = z.union([
  z.literal('documents_download'),
  z.literal('upload_created'),
]);

export const asyncTaskStatusSchema = z.union([
  z.literal('running'),
  z.literal('succeeded'),
  z.literal('failed'),
]);

export const asyncTaskSchema = z.object({
  id: idSchema,
  url: urlSchema,
  code: z.string().optional(),
  detail: z.string().nullable(),
  expiresAt: z.string(),
  type: asyncTaskTypeSchema,
  status: asyncTaskStatusSchema,
  resultUrl: urlSchema.optional().nullable(),
  content: z
    .object({
      fileName: z.string().optional(),
      upload: z.string().optional(),
    })
    .optional(),
});
