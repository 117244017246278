import { Button } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { currentMultivalueDatapointSelector } from '../../redux/modules/datapoints/selector';
import { updateGridAfterExtractAllRows } from '../../redux/modules/grid/actions';
import { findCurrentGrids } from '../../redux/modules/magicGrid/selector';

const ExtractAllButton = () => {
  const someGridExists = useSelector(findCurrentGrids).length > 0;
  const dispatch = useDispatch();
  const currentDatapoint = useSelector(currentMultivalueDatapointSelector);

  if (
    someGridExists &&
    currentDatapoint &&
    currentDatapoint.children.length <= 0
  ) {
    return (
      <Button
        data-cy="annotation-footer-extractAll"
        onClick={() =>
          dispatch(updateGridAfterExtractAllRows(currentDatapoint))
        }
        sx={{ alignSelf: 'center' }}
        variant="contained"
      >
        <FormattedMessage id="containers.footer.emptyFooter.extractAll" />
      </Button>
    );
  }

  return null;
};

export default ExtractAllButton;
