import { Observable, of } from 'rxjs';
import { delay, filter, mergeMap, scan } from 'rxjs/operators';

export const when = <T>(get: () => T, tries = 10): Observable<T> => {
  const value = get();
  return value
    ? of<T>(value)
    : of<T>(value).pipe<T, T, T>(
        filter(() => tries > 0),
        delay(100),
        mergeMap(() => when(get, tries - 1))
      );
};

type ActionType = { type: string; meta: unknown; payload: unknown };

export const scanSemaphore = (closingAction: string, openingAction: string) =>
  scan<unknown, ['open' | 'closed' | 'waiting', boolean, ActionType]>(
    // @ts-expect-error Not sure about exact type.
    ([gate, _, lastClosingAction], currentAction: ActionType) => {
      if (currentAction.type === closingAction) {
        if (gate === 'open') return ['closed', true, currentAction];
        return ['waiting', false, currentAction];
      }

      if (gate === 'waiting' && currentAction.type === openingAction) {
        return ['closed', true, lastClosingAction];
      }

      return ['open', false, lastClosingAction];
    },
    ['open', false, {} as ActionType]
  );
