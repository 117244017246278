import { Queue as QueueType } from '@rossum/api-client/queues';
import { Switch } from '@rossum/ui/material';
import clsx from 'clsx';
import styles from '../styles.module.sass';

type Props = {
  queue: QueueType;
  active: boolean;
  onToggle: (active: boolean) => void;
};

const Queue = ({ queue, active, onToggle }: Props) => (
  <div className={clsx(styles.Queue, active && styles.ActiveQueue)}>
    <div className={styles.QueueLabel}>
      <div className={styles.Status} />
      {queue.name}
    </div>
    <Switch
      checked={active}
      onChange={(_, value) => onToggle(value)}
      size="small"
    />
  </div>
);

export default Queue;
