import { ContentPaste } from '@rossum/ui/icons';
import { Button } from '@rossum/ui/material';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { currentMultivalueDatapointSelector } from '../../../redux/modules/datapoints/selector';
import { pasteGridToPage } from '../../../redux/modules/grid/actions';
import { State } from '../../../types/state';

export const PasteGridButton = ({ pageNumber }: { pageNumber: number }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const gridPasteAvailable = useSelector(
    (state: State) => !!get(state.datapoints.gridClipboard, 'page')
  );
  const actionInProgress = useSelector(
    (state: State) => state.ui.actionInProgress
  );
  const multivalueIndex = useSelector(
    (state: State) => currentMultivalueDatapointSelector(state)?.meta.index
  );

  if (!gridPasteAvailable || multivalueIndex === undefined) return null;

  return (
    <Button
      variant="contained"
      startIcon={<ContentPaste />}
      onClick={() =>
        dispatch(
          pasteGridToPage({ datapointIndex: multivalueIndex, page: pageNumber })
        )
      }
      sx={{
        position: 'absolute',
        bottom: 'calc(100% + 15px)',
        right: '16px',
      }}
      data-cy="paste-grid"
      disabled={!!actionInProgress}
    >
      {intl.formatMessage({
        id: 'components.documentValidation.footer.copyMagicGrid.paste.text',
      })}
    </Button>
  );
};
