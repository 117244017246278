import { CheckCircle, CheckCircleOutlined } from '@rossum/ui/icons';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@rossum/ui/material';

type Props = {
  onClick: () => void;
  queueName: string;
  selected: boolean;
};

export const Queue = ({ queueName, selected, onClick }: Props) => (
  <ListItemButton
    onClick={onClick}
    sx={{ pl: 4, py: 0.5 }}
    data-cy={`queue-menu-item-${queueName.replaceAll(' ', '-')}`}
    data-selected={selected}
    aria-selected={selected}
  >
    <ListItemIcon sx={{ minWidth: theme => theme.spacing(4.5) }}>
      {selected ? (
        <CheckCircle color="primary" />
      ) : (
        <CheckCircleOutlined color="disabled" />
      )}
    </ListItemIcon>
    <ListItemText
      primary={
        <Typography
          variant="body2"
          title={queueName}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {queueName}
        </Typography>
      }
    />
  </ListItemButton>
);
