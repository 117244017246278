import { AnnotationStatus } from '../../shared/models/annotationStatus';
import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  nextAnnotationPayloadSchema,
  nextAnnotationResponseSchema,
} from './nextAnnotation.schema';

export type NextAnnotationResponse = {
  annotation: Url | null;
  sessionTimeout?: string;
};

export type NextAnnotationPayload = {
  annotationIds: number[];
  statuses: AnnotationStatus[];
};

export const nextAnnotation = (queueId: ID, payload: NextAnnotationPayload) => {
  return {
    endpoint: `/queues/${queueId}/next`,
    method: 'POST',
    responseSchema: nextAnnotationResponseSchema,
    payload,
    payloadSchema: nextAnnotationPayloadSchema,
  } as const;
};
