import { ThemeType } from '@rossum/rossum-ui/theme';
import { ActionType, createAction } from 'typesafe-actions';

export const setThemeAction = createAction(
  'SET_THEME',
  undefined,
  (theme: ThemeType) => ({ theme })
)<undefined, { theme: ThemeType }>();

export type ThemeActions = ActionType<typeof setThemeAction>;
