// Generated by ts-to-zod
import { z } from 'zod';
import { schemaDatapointBaseSchema } from './schemaDatapointBase.schema';

const singleAggregationSchema = z.object({
  label: z.string(),
});

export const schemaDatapointAggregationsSchema = z
  .object({
    sum: singleAggregationSchema.nullable(),
  })
  .partial();

export const schemaDatapointNumberSchema = z
  .object({
    type: z.literal('number'),
    format: z.string().optional().nullable(),
    aggregations: schemaDatapointAggregationsSchema.optional().nullable(),
  })
  .and(schemaDatapointBaseSchema);
