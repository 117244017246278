import FolderOutlineIcon from 'mdi-react/FolderOutlineIcon';
import Picker, { OptionT } from '../../../components/Picker';
import InfoPlaceholder from '../../../components/UI/InfoPlaceHolder';
import { LocalizationKeys } from '../../../i18n';
import Loader from '../../Loader';

type Props<T extends number | string> = {
  isSearched: boolean;
  onPick: (values: Array<T>) => void;
  onSearch: (search: string) => void;
  options: Array<OptionT<T>>;
  picked: Array<T>;
  queuesLoaded: boolean;
  titleId?: LocalizationKeys;
};

const QueuePicker = <T extends number | string>({
  options,
  picked,
  onPick,
  onSearch,
  isSearched,
  queuesLoaded,
  titleId,
}: Props<T>) => (
  <Picker<T>
    titleId={titleId}
    options={options}
    onSearch={onSearch}
    onPick={onPick}
    isSearched={isSearched}
    picked={picked}
    dataCy="stats-select-all-queues"
    noResultsPlaceholder={
      queuesLoaded ? (
        <InfoPlaceholder
          icon={<FolderOutlineIcon />}
          title="containers.statistics.noQueues"
        />
      ) : (
        <Loader />
      )
    }
  />
);

export default QueuePicker;
