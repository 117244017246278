import { Queue } from '@rossum/api-client/queues';
import { CloudUpload } from '@rossum/ui/icons';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import { AllHTMLAttributes, ChangeEvent, ReactNode } from 'react';
import { isIE } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { GETTING_FILES_IN_ROSSUM } from '../../../../constants/values';
import { link } from '../../../../lib/formaterValues';
import { Documents } from '../../../../types/documents';
import IEView from '../IEView';
import { SuggestInbox } from './EmptyList';
import { OldFileList } from './OldFileList';
import OldUploadArea from './OldUploadArea';

type OldUploadDialogProps = {
  open: boolean;
  onClose: () => void;
  fileList: Documents['files'];
  currentQueue: Queue;
  children: ReactNode;
  shouldShowWarningBeforeUpload: boolean;
  uploadInputProps: Omit<AllHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  inboxEmail: string | undefined;
  isInboxLoading: boolean;
};

export const OldUploadDialog = ({
  open,
  onClose,
  fileList,
  currentQueue,
  children,
  shouldShowWarningBeforeUpload,
  uploadInputProps,
  inboxEmail,
  isInboxLoading,
}: OldUploadDialogProps) => {
  const renderInboxSuggestion = inboxEmail && fileList.length === 0;

  const uploaded = fileList.filter(({ status }) => status === 'uploaded');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minWidth: 800, minHeight: 610 }, elevation: 2 }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <CloudUpload />
          <Typography variant="h4" mx={2}>
            {shouldShowWarningBeforeUpload ? (
              <FormattedMessage
                id="containers.annotationList.upload.titleWithQueueName"
                values={{
                  queueName: currentQueue.name,
                }}
              />
            ) : (
              <FormattedMessage id="containers.annotationList.upload.title" />
            )}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              ml: 'auto',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{ p: 0, m: 0, flexDirection: 'column', display: 'flex' }}
      >
        {isIE ? (
          <IEView mail={inboxEmail} />
        ) : (
          <>
            <Paper
              elevation={1}
              sx={{
                borderRadius: '0px',
                py: 0.1,
              }}
            >
              {isInboxLoading ? (
                <Stack justifyContent="center" alignItems="center" my={9}>
                  <Skeleton variant="rounded" width="80%" height={120} />
                </Stack>
              ) : (
                renderInboxSuggestion && (
                  <Stack
                    spacing={0.5}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 9, mx: 0 }}
                  >
                    <SuggestInbox
                      inboxEmail={inboxEmail}
                      queueId={currentQueue?.id}
                    />
                  </Stack>
                )
              )}
            </Paper>
            <Stack sx={{ alignItems: 'center', width: '100%', py: 4 }}>
              {renderInboxSuggestion && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ pt: 2, pb: 1 }}
                >
                  <FormattedMessage id="containers.annotationList.empty.suggestUpload.text" />
                </Typography>
              )}
              <OldUploadArea
                files={fileList}
                uploadInputProps={uploadInputProps}
                ButtonProps={{
                  variant: 'outlined',
                  color: 'secondary',
                  size: !renderInboxSuggestion ? 'medium' : 'small',
                  'data-cy': 'upload-dialog-choose-files-button',
                }}
              />
              {renderInboxSuggestion && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ pt: 1 }}
                >
                  <FormattedMessage
                    id="containers.annotationList.upload.howToUpload"
                    values={{
                      link: link(GETTING_FILES_IN_ROSSUM, {
                        color: 'inherit',
                      }),
                    }}
                  />
                </Typography>
              )}
            </Stack>
            <OldFileList fileList={fileList} uploadedCount={uploaded.length} />

            {children}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
