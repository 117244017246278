import { Page } from '@rossum/api-client/pages';
import React from 'react';
import { useSelector } from 'react-redux';
import { BoundingBoxesLayer } from '../../../../components/DocumentPage/BoundingBoxesLayer';
import { PageSpaceProviderSVG } from '../../../../components/DocumentPage/PageSpaceContext';
import { currentMultivalueDatapointSelector } from '../../../../redux/modules/datapoints/selector';
import { complexLineItemsEnabledSelector } from '../../../../redux/modules/ui/selectors';
import { ComplexLineItemsLabels } from '../../document-canvas/components/ComplexLineItemsLabels/ComplexLineItemsLabels';
import { Rectangle2D } from '../../document-canvas/utils/geometry';
import { useKeyPressed } from '../useKeyPressed';

export type PageContentProps = {
  page: Page;
  pageRef: React.MutableRefObject<SVGSVGElement | null>;
  contentBlob: string;
  canvasCoordinates: Rectangle2D;
};

export const PageContent = ({
  page,
  pageRef,
  contentBlob,
  canvasCoordinates,
}: PageContentProps) => {
  const suggestionsEnabled = useKeyPressed(['Alt']);
  const currentMultivalueDatapoint = useSelector(
    currentMultivalueDatapointSelector
  );
  const complexLineItemsEnabled = useSelector(complexLineItemsEnabledSelector);

  return (
    <PageSpaceProviderSVG
      canvasDimensions={canvasCoordinates}
      pageRef={pageRef}
    >
      <image
        href={contentBlob}
        width={canvasCoordinates.width}
        height={canvasCoordinates.height}
        data-page-number={page.number}
      />
      <BoundingBoxesLayer
        page={page}
        activeBboxRef={undefined}
        suggestionsEnabled={suggestionsEnabled}
      />

      {complexLineItemsEnabled &&
        currentMultivalueDatapoint &&
        !currentMultivalueDatapoint.meta.isSimpleMultivalue && (
          <ComplexLineItemsLabels
            multivalue={currentMultivalueDatapoint}
            page={page.number}
          />
        )}
    </PageSpaceProviderSVG>
  );
};
