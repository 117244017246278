import { setTheme } from '@rossum/rossum-ui/theme';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { Next } from '../../types/redux';
import { setThemeAction } from '../modules/theme/actions';

export default () => (next: Next) => (action: AnyAction) => {
  if (action.type === getType(setThemeAction)) {
    setTheme(action.meta.theme);
  }

  return next(action);
};
