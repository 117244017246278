import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  documentsDownloadsPayloadSchema,
  documentsDownloadsResponseSchema,
} from './downloads.schema';

export type DocumentsDownloadsResponse = {
  url: Url;
};

export type DocumentsDownloadsQuery = {
  id: number[];
  fileName?: string | null;
};

export type DocumentsDownloadsType = 'source_document' | 'document';

export type DocumentsDownloadsPayload =
  | {
      fileName: string;
      documents: Url[];
      type?: DocumentsDownloadsType;
    }
  | {
      documents: [Url];
      zip: false;
      type?: DocumentsDownloadsType;
    };

export const downloads = (payload: DocumentsDownloadsPayload) => {
  return {
    endpoint: `/documents/downloads`,
    method: 'POST',
    responseSchema: documentsDownloadsResponseSchema,
    payload,
    payloadSchema: documentsDownloadsPayloadSchema,
  } as const;
};
