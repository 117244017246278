import { PersonOutline as PersonOutlineIcon } from '@rossum/ui/icons';
import { Share as ShareIcon } from '@rossum/ui/icons';

const items = [
  { name: 'access', Icon: PersonOutlineIcon },
  { name: 'info', Icon: ShareIcon },
] as const;

export const userSidebarItems = {
  items,
  type: 'user' as const,
};
