import clsx from 'clsx';
import ArrowDownDropCircleOutlineIcon from 'mdi-react/ArrowDownDropCircleOutlineIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import datapointStyles from '../../../../../components/Datapoint/style.module.sass';
import TranslatedTooltip from '../../../../../components/UI/Tooltip/TranslatedTooltip';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import { isFieldRequired } from '../helpers';
import scrollingSubject from '../lib/scrollingSubject';
import styles from '../styles.module.sass';

type Props = {
  inFooter?: boolean;
  isOpen: boolean;
  optionsCount: number;
  toggleCurrent: () => void;
} & Pick<OriginalAnyDatapointSchema, 'label' | 'constraints' | 'hidden' | 'id'>;

const Enum = ({
  constraints,
  hidden,
  id,
  inFooter,
  isOpen,
  label,
  optionsCount,
  toggleCurrent,
}: Props) => {
  const handleClick = () => {
    toggleCurrent();
    scrollingSubject.next(id);
  };
  return (
    <div
      onClick={handleClick}
      className={clsx(
        !inFooter && styles.Datapoint,
        !inFooter && datapointStyles.Datapoint,
        !inFooter && isOpen && datapointStyles.Active
      )}
    >
      {!inFooter && (
        <div className={clsx(datapointStyles.Title, styles.TitleEncapsulor)}>
          {label}
          {isFieldRequired(constraints) && (
            <TranslatedTooltip translationId="containers.settings.queues.schema.sidebar.required">
              <span className={styles.Requirer}>&nbsp;*</span>
            </TranslatedTooltip>
          )}
          {hidden && <EyeOffIcon className={styles.HiddenIcon} />}
        </div>
      )}
      <div
        className={clsx(
          styles.Multivalue,
          isOpen &&
            (inFooter ? styles.ActiveFooterMultivalue : styles.ActiveMultivalue)
        )}
      >
        <span>{optionsCount}</span>
        <ArrowDownDropCircleOutlineIcon />
      </div>
    </div>
  );
};

export default Enum;
