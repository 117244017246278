import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '../../../../lib/apiClient';
import {
  toTriggerClientModel,
  TriggerConditionApiModel,
} from '../mappers/templateMapper';

export const QUERY_KEY_TRIGGER = 'trigger';

export const useTemplateTrigger = (triggerUrl: string | undefined) => {
  const api = useApiClient();
  return useQuery({
    queryKey: [QUERY_KEY_TRIGGER, triggerUrl] as const,

    // We know ID is not null because of the enabled condition.
    queryFn: ({ queryKey }) =>
      api.request(endpoints.triggers.get(queryKey[1]!)),
    enabled: !!triggerUrl,
    select: data =>
      toTriggerClientModel(
        data.id,
        data.event,
        data.condition as TriggerConditionApiModel
      ),
  });
};
