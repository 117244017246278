import { endpoints, ID } from '@rossum/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { engineFieldKeys, mutationKey } from '../keys';
import { useToastMessage } from './useToastMessage';

export const useDeleteEngineField = () => {
  const queryClient = useQueryClient();
  const { showToastMessage } = useToastMessage();

  return useMutation({
    mutationKey: mutationKey(['delete_engine_field'] as const),
    mutationFn: (id: ID) => api.request(endpoints.engineFields.delete(id)),
    onSuccess: (_, id) => {
      showToastMessage('engineFieldDeleted');
      queryClient.removeQueries(engineFieldKeys.detail(id));
    },
  });
};
