// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';
import { editDataSourceSchema } from '../models/editPages.schema';
import { processingDurationSchema } from '../models/processingDuration.schema';

export const editPagesPayloadSchema = z.object({
  delete: z.array(urlSchema),
  edit: z.array(
    z.object({
      annotation: urlSchema.optional(),
      parentPages: z.array(
        z.object({
          rotationDeg: z.number(),
          page: urlSchema,
        })
      ),
      targetQueue: urlSchema,
      documentName: z.string().optional(),
    })
  ),
  move: z.array(
    z.object({
      annotation: urlSchema,
      targetQueue: urlSchema,
    })
  ),
  stopReviewing: z.array(urlSchema),
  stopParent: z.boolean(),
  processingDuration: processingDurationSchema.optional(),
  editDataSource: editDataSourceSchema.optional(),
});

export const editPagesResponseSchema = z.object({
  results: z.array(
    z.object({
      document: urlSchema,
      annotation: urlSchema,
    })
  ),
});
