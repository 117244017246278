import {
  AnyDatapointDataST,
  Grid,
  MultivalueDatapointDataST,
} from '../../../../types/datapoints';
import { AnyDatapointSchema } from '../../../../types/schema';
import { NavigationStop, NavigationStopNotNone } from './navigationStop';

export type NavigationContext = {
  isAnnotationValid: boolean;
  datapoints: AnyDatapointDataST[];
  schema: AnyDatapointSchema[];
  getGrids: (multivalue: MultivalueDatapointDataST) => Grid[];
};

export type NavigationStepFunc = (
  current: NavigationStop,
  context: NavigationContext
) => NavigationStop;

export type NavigationConfiguration = {
  stepInOrder: NavigationStepFunc;
  shouldSkip: (
    current: NavigationStopNotNone,
    context: NavigationContext
  ) => boolean;
  shouldWrap: (start: NavigationStop, context: NavigationContext) => boolean;
};

export const findNextNavigationStop = (
  start: NavigationStop,
  ctx: NavigationContext,
  config: NavigationConfiguration
): NavigationStop => {
  let cur = config.stepInOrder(start, ctx);

  // Don't want to blow up the stack, so it's not written recursively
  while (cur.kind !== 'none' && config.shouldSkip(cur, ctx)) {
    cur = config.stepInOrder(cur, ctx);
  }
  if (cur.kind === 'none') {
    if (config.shouldWrap(start, ctx)) {
      // Run again from the start, but without wrapping to avoid inifinite loop
      return findNextNavigationStop({ kind: 'none' }, ctx, {
        ...config,
        shouldWrap: () => false,
      });
    }
  }

  return cur;
};
