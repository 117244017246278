type TokenProps = {
  getToken: () => void;
};

export class Token {
  #getToken: () => void;

  constructor({ getToken }: TokenProps) {
    this.#getToken = getToken;
  }

  getToken = () => {
    return this.#getToken();
  };
}
