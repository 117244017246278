import { endpoints } from '@rossum/api-client';
import { HttpError } from '@rossum/api-client/errors';
import { TestPayload } from '@rossum/api-client/hooks';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';

export const QUERY_KEY_HOOK_TEST = 'hook-test';

// even though it's a POST call I don't believe this is a mutation but a query
export const useTestFunction = (hookId: number, payload: TestPayload) => {
  return useQuery({
    queryKey: [QUERY_KEY_HOOK_TEST, hookId],
    queryFn: () => api.request(endpoints.hooks.test(hookId, payload)),
    enabled: false,

    // retry with a 2 second delay as long as 409 is returned
    retry: (_failureCount, error) =>
      error instanceof HttpError && error.code === 409,

    retryDelay: 2 * 1000,
    cacheTime: 0,
  });
};
