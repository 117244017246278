import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Relation, RelationType } from '../models/relations';
import { relationSchema } from '../models/relations.schema';
import { createRelationPayloadSchema } from './create.schema';

export type CreateRelationPayload = {
  type: RelationType;
  parent?: Url;
  annotations: Url[];
};

export const create = (payload: CreateRelationPayload) => {
  return {
    endpoint: '/relations',
    method: 'POST',
    payloadSchema: createRelationPayloadSchema,
    responseSchema: relationSchema,
    payload,
  } as const;
};
