import { StackProps } from '@rossum/ui/material';

export const contentWrapperStyles = {
  height: 1,
  sx: { backgroundColor: 'inherit' },
} satisfies StackProps;

export const navigationPanelOuterStyles = {
  flexDirection: 'column',
  mb: 2,
} satisfies StackProps;

export const navigationPanelInnerStyles = {
  flexDirection: 'row',
  gap: 2,
  alignItems: 'center',
  flex: 1,
  mb: 2,
} satisfies StackProps;

export const toolbarStyles = {
  direction: 'row',
  alignItems: 'center',
  sx: {
    maxWidth: '100%',
  },
  gap: 2,
  height: 1,
  ml: 'auto',
} satisfies StackProps;
