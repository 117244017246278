import { Queue } from '@rossum/api-client/queues';
import { Box, List, Stack } from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { WorkspaceWithQueues } from '../types';
import { EmptyQueueList } from './EmptyQueueList';
import { QueueListSkeletons } from './QueueListSkeletons';
import { SearchFilter } from './SearchFilter';
import { Workspace } from './Workspace';

type QueueListContentProps = {
  workspaces: WorkspaceWithQueues[] | undefined;
  selectedQueue: Queue | undefined;
  onChange: (_queue: Queue) => void;
};

// This component is a duplivate of SingleQueueSelectList only with new types coming form api client
// TODO @unified-dashboard: replace SingleQueueSelectList and its usages across the app with this component
export const QueueListContent = ({
  onChange,
  selectedQueue,
  workspaces,
}: QueueListContentProps) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspaces);

  useEffect(() => {
    setFilteredWorkspaces(workspaces);
  }, [workspaces]);

  const getContent = () => {
    if (!filteredWorkspaces) {
      return <QueueListSkeletons />;
    }

    if (!filteredWorkspaces.length) {
      return <EmptyQueueList />;
    }

    return (
      <List component="nav">
        {filteredWorkspaces.map((workspace, index) => (
          <Workspace
            key={workspace.id}
            forceExpand={!!searchValue || index === 0}
            workspace={workspace}
            selectedQueue={selectedQueue}
            setSelectedQueue={onChange}
          />
        ))}
      </List>
    );
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <SearchFilter
          placeholder={intl.formatMessage({
            id: 'components.selectQueueModal.placeholder',
          })}
          setFilteredWorkspaces={setFilteredWorkspaces}
          workspaces={workspaces}
          onChange={value => setSearchValue(value)}
        />
      </Box>
      <Stack sx={{ overflowY: 'auto', height: '100%' }}>{getContent()}</Stack>
    </>
  );
};
