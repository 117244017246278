import { endpoints, getIDFromUrl } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { api } from '../../../lib/apiClient';
import { useTaskContext } from '../../tasks/TaskContext';
import { UploadDocumentsPayload } from '../components/helpers';

type OnSuccess = (signature: string) => void;
type OnError = (signature: string, error: unknown) => void;

type UseUploadDocumentProps = {
  queue: Queue;
  payload: UploadDocumentsPayload;
  onEverySuccess?: OnSuccess;
  onEveryError?: OnError;
};

type RequestParams = {
  onSuccess?: OnSuccess;
  onError?: OnError;
  queue: Queue | null;
};

// Logical flow implemented in this hook is as follows:
// 1. When the uploadQueue and queueId are defined the useEffect runs mutations
// 2. The useEffect runs in a synchronous loop until pendingCount reaches its limit. In most cases max 20 (MAX_CONCURRENT_UPLOADS) requests are made almost simultaneously.
// 3. onSuccess (or onError) of each request pendingCount is decreased
// 4. cancelUpload will only work if requests are more than MAX_CONCURRENT_UPLOADS

const MAX_CONCURRENT_UPLOADS = 20;

const normalizeAdditionalValues = (object: Record<string, string>) =>
  Object.entries(object).reduce<Record<string, string>>((acc, [key, value]) => {
    const newKey = `upload:${key}`;
    acc[newKey] = value;
    return acc;
  }, {});

export const useUploadDocuments = () => {
  const [uploadQueue, setUploadQueue] = useState<UploadDocumentsPayload>([]);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [requestParams, setRequestParams] = useState<RequestParams>({
    queue: null,
  });

  const { registerAsyncTask, registerUploadTask } = useTaskContext();

  const { mutate } = useMutation({
    mutationFn: ({
      queue,
      payload,
    }: {
      payload: UploadDocumentsPayload[number];
      queue: Queue;
    }) => {
      const { file, name, signature } = payload;
      const data = new FormData();
      data.append('content', file);

      const values = payload.values
        ? normalizeAdditionalValues(payload.values)
        : undefined;

      if (values) {
        data.append('values', JSON.stringify(values));
      }

      const metadata = payload.metadata
        ? normalizeAdditionalValues(payload.metadata)
        : undefined;

      if (metadata) {
        data.append('metadata', JSON.stringify(metadata));
      }

      return api
        .request(endpoints.uploads.post(queue.id, data, name))
        .then(response => {
          requestParams.onSuccess?.(signature);
          return registerAsyncTask({
            taskId: getIDFromUrl(response.url),
            metadata: {
              fileName: name,
              queueName: queue.name,
              queueUrl: queue.url,
            },
          });
        })
        .catch(error => {
          requestParams.onError?.(signature, error?.response?.content?.[0]);

          return registerUploadTask({
            taskId: uuidv4(),
            metadata: {
              fileName: name,
              queueName: queue.name,
              queueUrl: queue.url,
              detail: error?.response?.content?.[0],
            },
          });
        });
    },
  });

  const decreasePendingCount = useCallback(() => {
    setPendingCount(prev => Math.max(0, prev - 1));
  }, []);

  // this useEffect should trigger MAX_CONCURRENT_UPLOADS * mutations simultaneously.
  // It should stop triggering requests if we have more than MAX_CONCURRENT_UPLOADS, and enable the user to cancel
  useEffect(() => {
    const nextUpload = uploadQueue[0];
    if (
      pendingCount < MAX_CONCURRENT_UPLOADS &&
      nextUpload &&
      requestParams.queue
    ) {
      setPendingCount(prev => Math.min(uploadQueue.length, prev + 1));

      setUploadQueue(uploadQueue.slice(1));

      mutate(
        { queue: requestParams.queue, payload: nextUpload },
        {
          onSuccess: decreasePendingCount,
          onError: decreasePendingCount,
        }
      );
    }
  }, [
    uploadQueue,
    pendingCount,
    requestParams.queue,
    mutate,
    decreasePendingCount,
  ]);

  const uploadDocuments = useCallback(
    ({
      queue,
      payload,
      onEverySuccess,
      onEveryError,
    }: UseUploadDocumentProps) => {
      setRequestParams({
        queue,
        onError: onEveryError,
        onSuccess: onEverySuccess,
      });
      setUploadQueue(payload);
    },
    []
  );

  const cancelUpload = useCallback(() => {
    setUploadQueue([]);
    setRequestParams({ queue: null });
    setPendingCount(0);
  }, []);

  return {
    uploadDocuments,
    isLoading: !!pendingCount,
    cancelUpload,
  };
};
