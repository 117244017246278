import { SchemaItem, SchemaSection } from '@rossum/api-client/schemas';
import * as R from 'remeda';

export type FlatSchema = {
  id: string;
  type: 'datapoint' | 'tuple' | 'multivalue' | 'section';
  label: string;
  hidden?: boolean;
};

export const flattenSchema = (
  content: Array<SchemaSection | SchemaItem> | null,
  parentId: string | null
): FlatSchema[] =>
  content?.flatMap<FlatSchema>(item => {
    if (item.category === 'datapoint') {
      return [
        {
          id: item.id,
          type: 'datapoint' as const,
          parentId,
          label: item.label,
          hidden: item.hidden ?? undefined,
        },
      ];
    }

    if (item.category === 'section') {
      return [
        {
          id: item.id,
          type: 'section' as const,
          parentId,
          label: item.label,
          hidden: item.hidden ?? undefined,
        },
        ...flattenSchema(item.children, item.id),
      ];
    }

    if (item.category === 'tuple') {
      return [
        {
          id: item.id,
          type: 'tuple' as const,
          parentId,
          label: item.label,
          hidden: item.hidden ?? undefined,
        },
        ...flattenSchema(item.children.filter(R.isDefined), item.id),
      ];
    }

    if (item.category === 'multivalue') {
      return [
        {
          id: item.id,
          type: 'multivalue' as const,
          parentId,
          label: item.label,
          hidden: item.hidden ?? undefined,
        },
        ...flattenSchema([item.children], item.id),
      ];
    }

    return [];
  }) ?? [];
