import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Stack } from '@rossum/ui/material';
import { TILE_HEIGHT } from './SchemaFieldTileContent';

type SortableContainerProps = {
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  children: React.ReactNode;
};

export const SortableContainer = ({
  id,
  children,
  items,
}: SortableContainerProps) => {
  const { setNodeRef } = useDroppable({
    id,
    data: {
      type: 'container',
      children: items,
    },
  });

  return (
    <SortableContext
      strategy={verticalListSortingStrategy}
      items={items}
      id={`${id}`}
    >
      <Stack
        spacing={0.5}
        ref={setNodeRef}
        sx={{
          minHeight: TILE_HEIGHT,
        }}
      >
        {children}
      </Stack>
    </SortableContext>
  );
};
