import { HelpOutline } from '@rossum/ui/icons';
import clsx from 'clsx';
import { get } from 'lodash';
import CodeTagsIcon from 'mdi-react/CodeTagsIcon';
import TextSubjectIcon from 'mdi-react/TextSubjectIcon';
import { FormattedMessage } from 'react-intl';
import Dropdown, { ItemPropsT } from '../../../Dropdown';
import styles from '../../styles.module.sass';
import { EditorType } from '../InfoMessage';
import { documentationLinksConfig } from './documentationLinksConfig';

const icons = {
  textSubject: <TextSubjectIcon />,
  codeTags: <CodeTagsIcon />,
};

type Props = { editorType: EditorType };

const DocumentationLinks = ({ editorType }: Props) => {
  const links =
    editorType === 'function'
      ? documentationLinksConfig.codeEditor.map(d => ({
          ...d,
          localizationKey:
            `components.editor.documentationLinks.${editorType}.${d.translation}` as const,
        }))
      : documentationLinksConfig.schemaEditor.map(d => ({
          ...d,
          localizationKey:
            `components.editor.documentationLinks.${editorType}.${d.translation}` as const,
        }));

  return (
    <Dropdown
      listClassName={styles.DocLinksWrapper}
      align="left"
      items={links.map(option => (props: ItemPropsT) => (
        <a
          target="_blank"
          key={`editor-docs-links-${option.translation}`}
          rel="noreferrer noopener"
          {...props}
          href={option.link}
        >
          {get(icons, `${option.icon}`)}
          <FormattedMessage id={option.localizationKey} />
        </a>
      ))}
    >
      {({ open }: Record<'open', boolean>) => (
        <div className={clsx(styles.EditorIcon, !open && styles.ActiveIcon)}>
          <HelpOutline />
        </div>
      )}
    </Dropdown>
  );
};

export default DocumentationLinks;
