// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';
import { workflowUrlSchema } from '../../workflows/models/workflow.schema';

export const workflowStepUrlSchema = urlSchema;

export const workflowStepModeSchema = z.union([
  z.literal('any'),
  z.literal('all'),
  z.literal('auto'),
]);

export const workflowStepSchema = z.object({
  id: idSchema,
  url: workflowStepUrlSchema,
  workflow: workflowUrlSchema,
  name: z.string(),
  type: z.unknown().optional(),
  condition: z.unknown().optional(),
  mode: workflowStepModeSchema,
  ordering: z.number(),
});
