import { ID, Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Workspace } from '../models';
import { workspaceSchema } from '../models/workspace.schema';
import { patchWorkspacePayloadSchema } from './patch.schema';

export type PatchWorkspacePayload = {
  name: string;
};

export const patch = (workspaceId: ID, payload: PatchWorkspacePayload) => {
  return {
    endpoint: `/workspaces/${workspaceId}`,
    method: 'PATCH',
    responseSchema: workspaceSchema,
    payloadSchema: patchWorkspacePayloadSchema,
    payload,
  } as const;
};
