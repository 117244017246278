import update from 'immutability-helper';
import { compact, findIndex, get, some } from 'lodash';
import { Vector } from '../../lib/spaceConvertor';
import { ColumnPosition, Grid, RowPosition } from '../../types/datapoints';
import {
  CONTROL_BAR_DISTANCE,
  GRID_LINE_WIDTH,
  INTERACTIVE_AREA_SIZE,
  LABEL_HORIZONTAL_PADDING,
  LABEL_VERTICAL_PADDING,
  MAX_LABEL_HEIGHT,
  MAX_LABEL_WIDTH,
  MIN_SEPARATOR_DISTANCE,
  SEPARATOR_HANDLE_SIZE,
} from './constants';

// all 8 possible resizing directions
const resizingEdges = [
  'top',
  'top-right',
  'right',
  'bottom-right',
  'bottom',
  'bottom-left',
  'left',
  'top-left',
] as const;

export type ResizingEdge = (typeof resizingEdges)[number];

// TODO: Don't we have this anywhere yet?
export type SelectOption = {
  id: string | null;
  label: string;
};

export type GridUiState = {
  activeColumnIndex: number | null;
  activeRowIndex: number | null;
  documentHovered: boolean;
};

export const gridActions = compact([
  'clearColumns',
  'clearRows',
  'copyGrid',
  'applyGrid',
  'applyColumns',
  'deleteGrid',
  'deleteAllGrids',
]);
// ]) as const;

export type GridAction = (typeof gridActions)[number];

// Hovering interactions
// TODO: Clean this up
// x, y, width, height
export type Rectangle = [number, number, number, number];

/** Checks if `point` lies within `rectangle` (inclusive borders) */
const pointInRectangle = (point: Vector, rectangle: Rectangle): boolean => {
  return (
    point[0] >= rectangle[0] &&
    point[0] <= rectangle[0] + rectangle[2] &&
    point[1] >= rectangle[1] &&
    point[1] <= rectangle[1] + rectangle[3]
  );
};

// Describe areas on the document as union/difference of rectangles
export type DocumentArea = {
  include: Rectangle[];
  exclude: Rectangle[];
};

export const getColumnsAreas = (
  gridState: Grid,
  scaleFactor: number
): DocumentArea[] => {
  const gridTop = gridState.rows[0].topPosition * scaleFactor;

  const gridLeft = gridState.columns[0].leftPosition * scaleFactor;

  const gridHeight = gridState.height * scaleFactor;

  const gridWidth = gridState.width * scaleFactor;

  // PERF: ImmutableJs `map` is probably slower than regular map
  // If you do the same thing with a for loop it runs a lot faster
  return gridState.columns.map((col, index) => {
    const columnLeft = col.leftPosition * scaleFactor;

    const nextColumnLeft =
      index < gridState.columns.length - 1
        ? gridState.columns[index + 1]?.leftPosition * scaleFactor
        : gridLeft + gridWidth;

    return {
      include: [
        [
          columnLeft - GRID_LINE_WIDTH / 2,
          gridTop - GRID_LINE_WIDTH - LABEL_VERTICAL_PADDING - MAX_LABEL_HEIGHT,
          nextColumnLeft - columnLeft,
          LABEL_VERTICAL_PADDING +
            MAX_LABEL_HEIGHT -
            SEPARATOR_HANDLE_SIZE / 2 +
            GRID_LINE_WIDTH / 2 -
            CONTROL_BAR_DISTANCE,
        ],
        [
          columnLeft -
            GRID_LINE_WIDTH / 2 -
            INTERACTIVE_AREA_SIZE * scaleFactor,
          gridTop -
            GRID_LINE_WIDTH / 2 -
            SEPARATOR_HANDLE_SIZE / 2 -
            CONTROL_BAR_DISTANCE,
          nextColumnLeft - columnLeft,
          gridHeight + CONTROL_BAR_DISTANCE + SEPARATOR_HANDLE_SIZE / 2,
        ],
      ],
      exclude:
        index === 0
          ? [
              [
                gridLeft - SEPARATOR_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2,
                gridTop - SEPARATOR_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2,
                SEPARATOR_HANDLE_SIZE,
                SEPARATOR_HANDLE_SIZE,
              ],
            ]
          : [],
    };
  });
};

export const getRowAreas = (
  gridState: Grid,
  scaleFactor: number
): DocumentArea[] => {
  const gridTop = gridState.rows[0].topPosition * scaleFactor;

  const gridLeft = gridState.columns[0].leftPosition * scaleFactor;

  const gridHeight = gridState.height * scaleFactor;

  const gridWidth = gridState.width * scaleFactor;

  // PERF: ImmutableJs `map` is probably slower than regular map
  // If you do the same thing with a for loop it runs a lot faster
  return gridState.rows.map((row, index) => {
    const rowTop = row.topPosition * scaleFactor;

    const nextRowTop =
      index < gridState.rows.length - 1
        ? gridState.rows[index + 1]?.topPosition * scaleFactor
        : gridTop + gridHeight;

    return {
      // TODO: The half of MAX_LABEL_WIDTH shouldn't be there but without it it's unnecessarily large
      include: [
        [
          gridLeft - MAX_LABEL_WIDTH / 2 - LABEL_HORIZONTAL_PADDING,
          rowTop - GRID_LINE_WIDTH / 2,
          MAX_LABEL_WIDTH / 2 +
            LABEL_HORIZONTAL_PADDING -
            SEPARATOR_HANDLE_SIZE / 2 -
            CONTROL_BAR_DISTANCE,
          nextRowTop - rowTop,
        ],
        [
          gridLeft -
            GRID_LINE_WIDTH -
            INTERACTIVE_AREA_SIZE * scaleFactor -
            CONTROL_BAR_DISTANCE,
          rowTop - GRID_LINE_WIDTH / 2 - INTERACTIVE_AREA_SIZE * scaleFactor,
          gridWidth + CONTROL_BAR_DISTANCE,
          nextRowTop - rowTop,
        ],
      ],
      exclude:
        index === 0
          ? [
              [
                gridLeft - SEPARATOR_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2,
                gridTop - SEPARATOR_HANDLE_SIZE / 2 - GRID_LINE_WIDTH / 2,
                SEPARATOR_HANDLE_SIZE,
                SEPARATOR_HANDLE_SIZE,
              ],
            ]
          : [],
    };
  });
};

export const isAreaHovered = (area: DocumentArea, mousePosition: Vector) => {
  return (
    area.include.some(rect => pointInRectangle(mousePosition, rect)) &&
    area.exclude.every(rect => !pointInRectangle(mousePosition, rect))
  );
};

export const isColumnVisible = (gridState: Grid, column: ColumnPosition) => {
  return (
    column.leftPosition >= gridState.columns[0].leftPosition &&
    column.leftPosition < gridState.columns[0].leftPosition + gridState.width
  );
};

// column lies in grid and outside of reach of resizing handles (so it won't be deleted)
export const isColumnSafe = (gridState: Grid, column: ColumnPosition) => {
  return (
    column.leftPosition >=
      gridState.columns[0].leftPosition + MIN_SEPARATOR_DISTANCE * 2 &&
    column.leftPosition <=
      gridState.columns[0].leftPosition +
        gridState.width -
        MIN_SEPARATOR_DISTANCE * 2
  );
};

export const isRowVisible = (gridState: Grid, row: RowPosition) => {
  return (
    row.topPosition >= gridState.rows[0].topPosition &&
    row.topPosition < gridState.rows[0].topPosition + gridState.height
  );
};

export const isRowSafe = (gridState: Grid, row: RowPosition) => {
  return (
    row.topPosition >=
      gridState.rows[0].topPosition + MIN_SEPARATOR_DISTANCE * 2 &&
    row.topPosition <=
      gridState.rows[0].topPosition +
        gridState.height -
        MIN_SEPARATOR_DISTANCE * 2
  );
};

// UI operations helpers

/** Produces a new Grid draft state after resizing using `resizeHandleId` handle by `diff` PDF pixels
 * @param originalState Original state of the Grid
 * @param resizeHandleId An identifier of handle that did the resizing
 * @param diff A vector by which the handle moved, already clamped and in PDF coordinates
 */
export const resizeGridByEdge = (
  originalState: Grid,
  resizingEdge: ResizingEdge,
  diff: Vector
): Grid => {
  switch (resizingEdge) {
    case 'top-left': {
      return {
        ...originalState,
        columns: [
          {
            ...originalState.columns[0],
            leftPosition: originalState.columns[0].leftPosition + diff[0],
          },
          ...originalState.columns.slice(1),
        ],
        rows: [
          {
            ...originalState.rows[0],
            topPosition: originalState.rows[0].topPosition + diff[1],
          },
          ...originalState.rows.slice(1),
        ],
        height: originalState.height - diff[1],
        width: originalState.width - diff[0],
      };
    }
    case 'top-right': {
      return {
        ...originalState,
        rows: [
          {
            ...originalState.rows[0],
            topPosition: originalState.rows[0].topPosition + diff[1],
          },
          ...originalState.rows.slice(1),
        ],
        height: originalState.height - diff[1],
        width: originalState.width + diff[0],
      };
    }
    case 'bottom-right': {
      return {
        ...originalState,
        width: originalState.width + diff[0],
        height: originalState.height + diff[1],
      };
    }
    case 'bottom-left': {
      return {
        ...originalState,
        columns: [
          {
            ...originalState.columns[0],
            leftPosition: originalState.columns[0].leftPosition + diff[0],
          },
          ...originalState.columns.slice(1),
        ],
        width: originalState.width - diff[0],
        height: originalState.height + diff[1],
      };
    }
    case 'right': {
      return {
        ...originalState,
        width: originalState.width + diff[0],
      };
    }
    case 'bottom': {
      return {
        ...originalState,
        height: originalState.height + diff[1],
      };
    }
    case 'top': {
      return {
        ...originalState,
        rows: [
          {
            ...originalState.rows[0],
            topPosition: originalState.rows[0].topPosition + diff[1],
          },
          ...originalState.rows.slice(1),
        ],
        height: originalState.height - diff[1],
      };
    }
    case 'left': {
      return {
        ...originalState,
        columns: [
          {
            ...originalState.columns[0],
            leftPosition: originalState.columns[0].leftPosition + diff[0],
          },
          ...originalState.columns.slice(1),
        ],
        width: originalState.width - diff[0],
      };
    }
    default: {
      return originalState;
    }
  }
};

/** Produces a new Grid draft state after moving it by DragHandle by `diff` PDF pixels
 * @param originalState Original state of the Grid
 * @param diff A vector by which the corner moved, already clamped and in PDF coordinates
 */
export const moveGridByGridHandle = (
  originalState: Grid,
  diff: Vector
): Grid => {
  return {
    ...originalState,
    columns: originalState.columns.map(col => ({
      ...col,
      leftPosition: col.leftPosition + diff[0],
    })),
    rows: originalState.rows.map(row => ({
      ...row,
      topPosition: row.topPosition + diff[1],
    })),
  };
};

const emptyColumn = (leftPosition: number): ColumnPosition => ({
  schemaId: null,
  headerTexts: [],
  leftPosition,
});

const emptyRow =
  (rowType: string | null) =>
  (topPosition: number): RowPosition => ({
    type: rowType,
    topPosition,
    tupleId: null,
  });

export const canSeparatorBeCreated = (
  orientation: 'horizontal' | 'vertical',
  gridState: Grid,
  position: number
) => {
  const [orientationKey, positionKey] =
    orientation === 'horizontal'
      ? (['rows', 'topPosition'] as const)
      : (['columns', 'leftPosition'] as const);

  const dimensionKey = orientation === 'horizontal' ? 'height' : 'width';

  return (
    position < get(gridState, [dimensionKey], 0) - 2 * MIN_SEPARATOR_DISTANCE &&
    !some(
      get(gridState, [orientationKey], [] as RowPosition[] | ColumnPosition[]),
      (colOrRow: RowPosition | ColumnPosition) =>
        Math.abs(
          position -
            // @ts-expect-error
            (colOrRow[positionKey] -
              get(gridState, [orientationKey, 0, positionKey], 0))
        ) <
        2 * MIN_SEPARATOR_DISTANCE
    )
  );
};

/** Creates a separator at position `position`
 * @param orientation Whether to create horizontal or vertical separator
 * @param originalState Original state of the Grid
 * @param position PDF position to create a separator at
 */
export function createSeparator(
  orientation: 'vertical',
  originalState: Grid,
  position: number
): Grid;
export function createSeparator(
  orientation: 'horizontal',
  originalState: Grid,
  position: number,
  defaultRowType: string | null
): Grid;
export function createSeparator(
  orientation: 'horizontal' | 'vertical',
  originalState: Grid,
  position: number,
  defaultRowType?: string | null
) {
  const [orientationKey, positionKey, creator] =
    orientation === 'horizontal'
      ? (['rows', 'topPosition', emptyRow(defaultRowType ?? null)] as const)
      : (['columns', 'leftPosition', emptyColumn] as const);

  const index = findIndex(
    // @ts-expect-error
    get(
      originalState,
      [orientationKey],
      [] as RowPosition[] | ColumnPosition[]
    ),
    colOrRow => get(colOrRow, [positionKey], 0) > position
  );

  if (index < 0) {
    return update(originalState, {
      [orientationKey]: {
        $push: [creator(position)],
      },
    });
  }

  return update(originalState, {
    [orientationKey]: {
      $splice: [[index, 0, creator(position)]],
    },
  });
}

/** Deletes a separator at `index`
 * @param orientation Whether to delete row or column
 * @param originalState Original state of the Grid
 * @param index Index at which to delete the separator
 */
export const deleteSeparator = (
  orientation: 'horizontal' | 'vertical',
  originalState: Grid,
  index: number
) => {
  const orientationKey = orientation === 'horizontal' ? 'rows' : 'columns';

  return update(originalState, {
    [orientationKey]: {
      $splice: [[index, 1]],
    },
  });
};

/** Moves a separator at `index`
 * @param orientation Whether to move row or column
 * @param originalState Original state of the Grid
 * @param index Which separator to update
 */
export const moveSeparator = (
  orientation: 'horizontal' | 'vertical',
  originalState: Grid,
  index: number,
  diff: number
) => {
  const [orientationKey, positionKey] =
    orientation === 'horizontal'
      ? (['rows', 'topPosition'] as const)
      : ['columns', 'leftPosition'];

  return update(originalState, {
    [orientationKey]: {
      [index]: {
        [positionKey]: (originalPosition: number) => originalPosition + diff,
      },
    },
  });
};

/** Produces new state after reassigning schemaIds
 * @param originalState Original state of the Grid
 * @param changes An array with `schemaId` changes
 */
export const assignSchemaIds = (
  originalState: Grid,
  changes: {
    separatorIndex: number;
    prev: string | null;
    current: string | null;
  }[]
) => {
  const newColumns = changes.reduce<Record<string, string | null>>(
    (acc, change) => ({ ...acc, [change.separatorIndex]: change.current }),
    {}
  );

  return {
    ...originalState,
    columns: originalState.columns.map((col, index) =>
      Object.keys(newColumns).includes(String(index))
        ? { ...col, schemaId: newColumns[index] }
        : col
    ),
  };
};

/** Produces new state after changing a rowType for a horizontal separator
 * @param originalState Original state of the Grid
 * @param separatorIndex Index of the horizontal separator
 * @param rowType New value for `type` of the separator
 */
export const changeRowType = (
  originalState: Grid,
  separatorIndex: number,
  rowType: string | null,
  rowTypesToExtract: string[]
) =>
  update(originalState, {
    rows: {
      [separatorIndex]: {
        type: {
          $set: rowType,
        },
        tupleId: tupleId =>
          rowType !== null && rowTypesToExtract.includes(rowType)
            ? tupleId
            : null,
      },
    },
  });
