import { Url } from '../../utils/codecUtils';
import { ElisRequestConfig } from '../../utils/requestConfig';
import { Connector } from '../models/connector';
import { connectorSchema } from '../models/connector.schema';
import { createConnectorPayloadSchema } from './create.schema';

export type CreateConnectorPayload = {
  name: string;
  queues: Array<string>;
  serviceUrl: Url;
  authorizationToken: string;
};

export const create = (payload: CreateConnectorPayload) => {
  return {
    endpoint: `/connectors`,
    method: 'POST',
    responseSchema: connectorSchema,
    payloadSchema: createConnectorPayloadSchema,
    payload,
  } as const;
};
