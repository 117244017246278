import { Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useInfiniteGenericEngines } from '../hooks/useInfiniteGenericEngines';
import EngineTile from './EngineTile';
import ShowMoreButton from './ShowMoreButton';
import TilesList from './TilesList';
import TilesListEmptyState from './TilesListEmptyState';

const GenericEnginesList = () => {
  const intl = useIntl();

  const {
    data: engines,
    fetchNextPage,
    hasNextPage,
    isFetching,
    status,
  } = useInfiniteGenericEngines({
    pageSize: 10,
    ordering: ['-id'],
  });

  const allEngines = engines?.pages.flatMap(page => page.results);

  return (
    <TilesList
      title={intl.formatMessage({
        id: 'components.genericEnginesList.title',
      })}
      items={allEngines}
      renderTile={engine => <EngineTile key={engine.id} engine={engine} />}
      status={status}
      emptyState={
        <TilesListEmptyState
          title={intl.formatMessage({
            id: 'components.genericEnginesList.noEngines',
          })}
        />
      }
      errorState={
        <Stack justifyContent="center" direction="row">
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'components.genericEnginesList.errorFetchingEngines',
            })}
          </Typography>
        </Stack>
      }
      buttons={
        hasNextPage
          ? [
              <ShowMoreButton
                key="show-more-results"
                onClick={fetchNextPage}
                isFetching={isFetching}
              />,
            ]
          : []
      }
    />
  );
};

export default GenericEnginesList;
