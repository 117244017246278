// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema, urlSchema } from '../../utils/codecUtils.schema';

export const genericEngineSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: urlSchema,
  schema: urlSchema.nullable(),
  description: z.string(),
  documentationUrl: z.string().nullable(),
});
