import { snakeToCamel } from '../lib/keyConvertor';

export const snakeCaseStatuses = [
  'created',
  'exporting',
  'deleted',
  'confirmed',
  'exported',
  'failed_export',
  'failed_import',
  'reviewing',
  'to_review',
  'importing',
  'split',
  'purged',
  'rejected',
  'postponed',
  'in_workflow',
] as const;

export const annotationStatuses = snakeCaseStatuses.map(snakeToCamel);

export type SnakeCaseStatus = (typeof snakeCaseStatuses)[number];
export type Status = (typeof annotationStatuses)[number];
