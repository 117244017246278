import { Stack, Typography } from '@rossum/ui/material';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useIntl } from 'react-intl';
import SingleCheckboxControl from '../../ReactHookForm/controls/SingleCheckboxControl';

type Props<T extends FieldValues> = {
  control?: Control<T>;
  checkboxSchema: {
    label: string;
    name: Path<T>;
  }[];
};

const DuplicateDialogCheckboxes = <T extends FieldValues>({
  control,
  checkboxSchema,
}: Props<T>) => {
  const intl = useIntl();

  return (
    <Stack spacing={1} sx={{ mb: 0 }}>
      <Typography variant="body2" color="text.secondary">
        {intl.formatMessage({
          id: 'components.queueDuplicate.actionDialogCheckboxes.text',
        })}
      </Typography>
      <Stack spacing={0} sx={{ pl: 2.5 }}>
        {checkboxSchema.map(({ label, name }) => (
          <SingleCheckboxControl
            ControllerProps={{ control, name }}
            FieldLabelProps={{ layout: 'none' }}
            label={label}
            key={name}
          />
        ))}
      </Stack>
    </Stack>
  );
};
export default DuplicateDialogCheckboxes;
