import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@rossum/ui/icons';
import clsx from 'clsx';
import { CSSProperties } from 'react';
import { DISPLAYED_VALUE_CLASSNAME } from '../../../constants/values';
import styles from '../style.module.sass';

type Props = {
  value?: string;
  inFooter: boolean;
  style: CSSProperties | undefined;
};

const FakeSelect = ({ value, inFooter, style }: Props) => (
  <div
    className={clsx(
      styles.FakeSelect,
      inFooter && styles.FakeSelectInFooter,
      DISPLAYED_VALUE_CLASSNAME
    )}
    style={style}
  >
    <span className={styles.Value}>{value}</span>
    <span className={styles.Icon}>
      <KeyboardArrowDownIcon />
    </span>
  </div>
);

export default FakeSelect;
