import { endpoints, ID } from '@rossum/api-client';
import { DedicatedEnginePatchPayload } from '@rossum/api-client/dedicatedEngines';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

// TODO: This is a bit ugly, spend some time on standardising
export const usePatchDedicatedEngine = () => {
  return useMutation({
    mutationFn: ({
      engineId,
      enginePatchModel,
    }: {
      engineId: ID;
      enginePatchModel: DedicatedEnginePatchPayload;
    }) =>
      api.request(endpoints.dedicatedEngines.patch(engineId, enginePatchModel)),
  });
};
