import { useTheme } from '@rossum/ui/material';
import { Rectangle2DCoordinates } from '../utils/geometry';
import { BoundingBox } from './BoundingBox';

type SearchResultBoxProps = {
  position: Rectangle2DCoordinates;
  current: boolean;
};

export const SearchResultBox = ({
  position,
  current,
}: SearchResultBoxProps) => {
  const theme = useTheme();

  return (
    <BoundingBox
      data-cy="search-result"
      position={position}
      outlineStyle="none"
      color={current ? theme.palette.error.light : theme.palette.warning.light}
      style={{ scrollMargin: '200px' }}
    />
  );
};
