import { includes } from 'lodash';

export const ARROW_DOWN_CODE = 40;
export const ARROW_LEFT_CODE = 37;
export const ARROW_RIGHT_CODE = 39;
export const ARROW_UP_CODE = 38;
export const A_KEY_CODE = 65;
export const BACKSPACE_CODE = 8;
export const C_KEY_CODE = 67;
export const DELETE_CODE = 46;
export const ENTER_CODE = 13;
export const ESC_CODE = 27;
export const F_KEY_CODE = 70;
export const TAB_CODE = 9;
export const V_KEY_CODE = 86;

export const isEnter = ({ which, keyCode }: KeyboardEvent) =>
  which === ENTER_CODE || keyCode === ENTER_CODE;

export const isEsc = ({ which, keyCode }: KeyboardEvent) =>
  which === ESC_CODE || keyCode === ESC_CODE;

export const filterKeys =
  (keys: number[]) =>
  ({ which, keyCode }: KeyboardEvent) =>
    includes(keys, which) || includes(keys, keyCode);
