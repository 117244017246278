import { Logo } from '@rossum/rossum-ui/Logo';
import { useIntl } from 'react-intl';
import styles from '../style.module.sass';

// TODO: remove sass styles
const Header = () => {
  const intl = useIntl();
  return (
    <div className={styles.Header}>
      <div className={styles.HeaderLogoWrapper}>
        <Logo />
      </div>
      <div className={styles.HeaderText}>
        {intl.formatMessage({ id: 'containers.introScreen.welcome' })}
      </div>
    </div>
  );
};

export default Header;
