import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { Chip, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import TileLink from './TileLink';

type ConnectedQueueTileProps = {
  queue: Queue;
  workspace?: Workspace;
  connectedFieldsCount?: number;
  totalFieldsCount?: number;
};

export const ConnectedQueueTile = ({
  queue,
  workspace,
  connectedFieldsCount,
  totalFieldsCount,
}: ConnectedQueueTileProps) => {
  const intl = useIntl();

  return (
    <TileLink
      to={`/queues/${queue.id}/settings/basic`}
      data-cy="connected-queue"
      data-id={queue.id}
    >
      <Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {workspace && (
            <Typography variant="h6" color="text.secondary" fontWeight={400}>
              {workspace.name}
            </Typography>
          )}
          <Typography variant="h6">{queue.name}</Typography>
          <Chip
            size="small"
            variant="outlined"
            label={intl.formatMessage({
              id: `features.engines.engineDetail.connectedQueues.${
                queue.trainingEnabled ? 'trainingEnabled' : 'trainingDisabled'
              }`,
            })}
          />
          {totalFieldsCount && connectedFieldsCount !== totalFieldsCount && (
            <Chip
              size="small"
              variant="outlined"
              color={queue.trainingEnabled ? 'warning' : 'default'}
              label={intl.formatMessage(
                {
                  id: `features.engines.engineDetail.connectedQueues.connectedFields`,
                },
                {
                  connectedFieldsCount,
                  totalFieldsCount,
                }
              )}
            />
          )}
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: 'features.engines.engineDetail.basicInformation.field.id',
            })}
            {': '}
          </Typography>
          <Typography variant="body2">{queue.id}</Typography>
        </Stack>
      </Stack>
    </TileLink>
  );
};
