// Generated by ts-to-zod
import { z } from 'zod';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const workflowActivitiesListQuerySchema = z
  .object({
    annotation: idSchema,
  })
  .partial()
  .and(paginationQuerySchema);
