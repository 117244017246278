import { createSelector } from 'reselect';
import { LocalizationKeys } from '../../../i18n';
import {
  customerTypeSelector,
  overduePaymentDateSelector,
} from '../../../redux/modules/organization/selectors';
import { isHandledCustomerType, resolveSeverity, Severity } from '../helpers';
import { messages } from '../messages';

type UseOverdueHook =
  | {
      isOverdue: false;
      severity: null;
      messageKey: null;
    }
  | {
      isOverdue: true;
      severity: Severity;
      messageKey: LocalizationKeys;
    };

const nonAlphabeticalsRegexp = /[^a-zA-Z]/g;

export const overduePaymentsSelector = createSelector(
  overduePaymentDateSelector,
  customerTypeSelector,
  (overduePaymentDate, customerType): UseOverdueHook => {
    const severity = resolveSeverity(Date.now(), overduePaymentDate);

    const lowercaseCustomerType = customerType
      ?.replace(nonAlphabeticalsRegexp, '')
      .trim()
      .toLowerCase();

    const messageKey =
      isHandledCustomerType(lowercaseCustomerType) && !!severity
        ? messages[lowercaseCustomerType][severity]
        : null;

    if (severity !== null && messageKey !== null) {
      return {
        isOverdue: true,
        severity,
        messageKey,
      };
    }

    return {
      isOverdue: false,
      severity: null,
      messageKey: null,
    };
  }
);
