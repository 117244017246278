import { InfoOutlined } from '@rossum/ui/icons';
import { Tooltip, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CLI_HELP_LINK } from '../../constants/values';
import { boldText, link } from '../../lib/formaterValues';
import { hideArticleLinksSelector } from '../../redux/modules/organization/selectors';

export const CLITooltip = () => {
  const intl = useIntl();
  const hideArticleLinks = useSelector(hideArticleLinksSelector);

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          {intl.formatMessage(
            {
              id: 'containers.footerTableList.complexLineItemsHelp',
            },
            {
              boldText,
            }
          )}
          {hideArticleLinks ? null : (
            <>
              {intl.formatMessage(
                {
                  id: 'containers.footerTableList.complexLineItemsHelpLink',
                },
                {
                  helpLink: link(CLI_HELP_LINK),
                }
              )}
            </>
          )}
        </Typography>
      }
    >
      <InfoOutlined color="info" />
    </Tooltip>
  );
};
