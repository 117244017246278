import { DataGridPro } from '@rossum/ui/x-data-grid-pro';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { parse } from '../../../lib/url';
import { DOCUMENTS_SEARCH_AFTER_KEY } from '../../../redux/modules/localStorage/actions';
import { useJsonParse } from '../../../utils/hooks/useJsonParse';

// Addapted example of DataGrid cursor pagination https://mui.com/x/react-data-grid/pagination/#cursor-implementation
export const useRowCount = (total: number | undefined) => {
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(total || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      total !== undefined ? total : prevRowCountState
    );
  }, [total, setRowCountState]);

  return rowCountState;
};

type MapPageToNextCursor = {
  [page: number]: string;
};

export type NewPaginationModel = Required<
  ComponentProps<typeof DataGridPro>
>['paginationModel'];

export const allowedPageSizes = [20, 50, 100, 500];

export const DEFAULT_PAGE_SIZE = 100;

type UsePaginationProps = {
  page?: number | null;
  pageSize?: number | null;
  onPaginationReset?: () => void;
  onPaginationChange?: (newPaginationModel: NewPaginationModel) => void;
  disableResetOnMissingPage?: boolean;
};

export type UsePaginationResponse = ReturnType<typeof usePagination>;

export const usePagination = (props?: UsePaginationProps) => {
  const {
    page,
    pageSize,
    onPaginationReset,
    onPaginationChange,
    disableResetOnMissingPage = false,
  } = props ?? {};

  const [existingSearchAfter] = useJsonParse<MapPageToNextCursor>(
    sessionStorage.getItem(DOCUMENTS_SEARCH_AFTER_KEY) ?? ''
  );

  const mapPageToNextCursor = useRef<MapPageToNextCursor>(
    existingSearchAfter ?? {}
  );

  // paginationModel's page is initialised with zero because DataGrid's definition of first page is index === 0
  const [paginationModel, setPaginationModel] = useState({
    page: page ? page - 1 : 0,
    pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
  });

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: NewPaginationModel) => {
      // We have the cursor, we can allow the page transition.
      if (
        newPaginationModel.page === 0 ||
        mapPageToNextCursor.current[newPaginationModel.page - 1]
      ) {
        setPaginationModel(newPaginationModel);
        onPaginationChange?.(newPaginationModel);
      }
    },

    [onPaginationChange]
  );

  const resetPagination = useCallback(() => {
    mapPageToNextCursor.current = {};
    setPaginationModel(prev => ({ ...prev, page: 0 }));
    onPaginationReset?.();
    sessionStorage.removeItem(DOCUMENTS_SEARCH_AFTER_KEY);
  }, [onPaginationReset]);

  const nextCursor = mapPageToNextCursor.current[paginationModel.page - 1];
  const nextCursorQueryString = nextCursor?.split('?')[1];
  const searchAfter = nextCursorQueryString
    ? parse(nextCursorQueryString).search_after ?? undefined
    : undefined;

  // if a user land to a page 5 and we don't have it stored in sessionStorage the page will display wrong information
  // pagination will display page 5, but searchAfter will be undefined and BE will return page 1
  // This useEffect makes sure that the page is actually valid, but at the cost of 1 extra resetPagination at landing.
  useEffect(() => {
    if (!searchAfter && !disableResetOnMissingPage) resetPagination();
  }, [
    searchAfter,
    resetPagination,
    disableResetOnMissingPage,
    paginationModel.page,
  ]);

  const setNextToMap = useCallback(
    (next: string) => {
      // TODO: we could overwrite also the previous cursor at index [page - 1] to update stored backward pagination
      mapPageToNextCursor.current[paginationModel.page] = next;
      sessionStorage.setItem(
        DOCUMENTS_SEARCH_AFTER_KEY,
        JSON.stringify(mapPageToNextCursor.current)
      );
    },
    [paginationModel.page]
  );

  return {
    searchAfter,
    resetPagination,
    paginationModel,
    handlePaginationModelChange,
    setNextToMap,
    // we should not allow mapPageToNextCursor.current to be an empty object, otherwise pagination breaks
    shouldForceSetNextCursor:
      Object.keys(mapPageToNextCursor.current).length === 0,
  };
};

type UseNextCursorStore = {
  setNextToMap: (next: string) => void;
  gridIsLoading: boolean;
  next: string | undefined | null;
  shouldForceSetNextCursor: boolean;
};

export const useNextCursorStore = ({
  setNextToMap,
  gridIsLoading,
  next,
  shouldForceSetNextCursor,
}: UseNextCursorStore) => {
  // updating of the cursor should happen in two cases:
  // 1. When we have a new pagination.next value coming from BE
  // 2. When 1 is not true, but somehow the pagination is reset clearing the saved cursor values. In this case we default back to the existing pagination.next
  // e.g. when the user triggers a 1 letter search we do not make a new request, but we reset pagination.
  useEffect(() => {
    if (next && (!gridIsLoading || shouldForceSetNextCursor)) {
      // We add nextCursor when available
      setNextToMap(next);
    }
  }, [setNextToMap, gridIsLoading, next, shouldForceSetNextCursor]);
};
