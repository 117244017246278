import { Workspace } from '@rossum/api-client/workspaces';
import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEY_WORKSPACES_UNPAGINATED } from './useUnpaginatedWorkspaces';

export const addWorkspaceMutation = (
  queryClient: QueryClient,
  workspace: Workspace
) => {
  queryClient.setQueriesData<Workspace[] | undefined>(
    [QUERY_KEY_WORKSPACES_UNPAGINATED],
    prev => {
      if (prev) {
        return prev.concat(workspace);
      }

      return prev;
    }
  );
};
