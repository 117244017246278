import { useCallback, useReducer } from 'react';

// This API can probably get a little polished
export type StepWorkflowStepState = {
  key: string;
  label: string;
  // these are not mutually exclusive, hence booleans
  completed: boolean;
  error: boolean;
};

type StepWorkflowState = {
  /** Holds state for individual steps */
  steps: StepWorkflowStepState[];
  /** Holds index of currently active step */
  activeStep: number;
};

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type StepWorkflowInitialState = Omit<
  PartialBy<StepWorkflowState, 'activeStep'>,
  'steps'
> & {
  steps: PartialBy<StepWorkflowStepState, 'label' | 'completed' | 'error'>[];
};

const resolveInitialState = (
  initialState: StepWorkflowInitialState
): StepWorkflowState => {
  return {
    activeStep: initialState.activeStep ?? 0,
    steps: initialState.steps.map(step => ({
      label: step.key,
      completed: false,
      error: false,
      ...step,
    })),
  };
};

type StepWorkflowAction =
  | {
      type: 'complete-step';
      payload: { index: number };
    }
  | {
      type: 'go-to-step';
      payload: { index: number };
    }
  | {
      type: 'set-error';
      payload: { index: number; error: boolean };
    }
  | {
      type: 'uncomplete-step';
      payload: { index: number };
    }
  | {
      type: 'set-state';
      payload: { state: StepWorkflowState };
    };

const stepperStateReducer = (
  state: StepWorkflowState,
  action: StepWorkflowAction
): StepWorkflowState => {
  switch (action.type) {
    case 'complete-step': {
      const { index } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step, i) =>
          index === i ? { ...step, completed: true } : step
        ),
      };
    }
    case 'uncomplete-step': {
      const { index } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step, i) =>
          index === i ? { ...step, completed: false } : step
        ),
      };
    }
    case 'go-to-step': {
      const { index } = action.payload;
      return { ...state, activeStep: index };
    }
    case 'set-error': {
      const { index, error } = action.payload;
      return {
        ...state,
        steps: state.steps.map((step, i) =>
          index === i
            ? { ...step, error, completed: error ? false : step.completed }
            : step
        ),
      };
    }
    case 'set-state': {
      const { state } = action.payload;
      return state;
    }
    default: {
      return state;
    }
  }
};

export const useStepWorkflow = (initialState: StepWorkflowInitialState) => {
  const [state, dispatch] = useReducer(
    stepperStateReducer,
    resolveInitialState(initialState)
  );

  const getStepIndex = useCallback(
    (key: string) => state.steps.findIndex(step => step.key === key),
    [state.steps]
  );

  const goToStep = useCallback((index: number) => {
    dispatch({ type: 'go-to-step', payload: { index } });
  }, []);

  const hasNext = state.activeStep < state.steps.length - 1;

  const next = useCallback(() => {
    if (hasNext) {
      dispatch({
        type: 'go-to-step',
        payload: { index: state.activeStep + 1 },
      });
    }
  }, [hasNext, state.activeStep]);

  const hasPrevious = state.activeStep > 0;

  const previous = useCallback(() => {
    if (hasPrevious) {
      dispatch({
        type: 'go-to-step',
        payload: { index: state.activeStep - 1 },
      });
    }
  }, [hasPrevious, state.activeStep]);

  const complete = useCallback(
    (index: number) => {
      dispatch({ type: 'complete-step', payload: { index } });
      next();
    },
    [next]
  );

  const unComplete = useCallback((index: number) => {
    dispatch({ type: 'uncomplete-step', payload: { index } });
  }, []);

  const setError = useCallback((index: number, error: boolean) => {
    dispatch({ type: 'set-error', payload: { index, error } });
  }, []);

  const reset = useCallback(() => {
    dispatch({
      type: 'set-state',
      payload: { state: resolveInitialState(initialState) },
    });
  }, [initialState]);

  // we can expand this as needed
  return {
    state,
    getStepIndex,
    goToStep,
    hasNext,
    hasPrevious,
    next,
    previous,
    complete,
    unComplete,
    setError,
    reset,
  };
};
