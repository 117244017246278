import { useSelector } from 'react-redux';
import { useCanvasDimensions } from '../../../../features/annotation-view/document-canvas-svg/useCanvasDimensions';
import { useDocumentStore } from '../../../../features/annotation-view/document-store/DocumentStore';
import { visiblePageZustandSelector } from '../../../../features/annotation-view/document-store/documentStoreSelectors';
import { State } from '../../../../types/state';

export const useCurrentPage = () => {
  const pages = useSelector((state: State) => state.pages.pages ?? []);
  const dimensions = useCanvasDimensions(pages);

  const visiblePageSVG = useDocumentStore(
    visiblePageZustandSelector(dimensions)
  );

  return visiblePageSVG;
};
