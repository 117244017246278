import { EmailTemplate } from '@rossum/api-client/emailTemplates';
import { Edit } from '@rossum/ui/icons';
import { Button, Stack, Typography } from '@rossum/ui/material';
import { Control, Path } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ControlledToggle from '../../../../components/ReactHookForm/Toggle';
import { Form } from '../../../../containers/Queue/containers/QueueEmails/formTypes';
import EmailTemplateChips from './EmailTemplateChips';
import { defaultEmailTemplateNames } from './helpers';

type Props = {
  emailTemplate: EmailTemplate | Pick<EmailTemplate, 'type'>;
  onEdit: (emailTemplate: EmailTemplate) => void;
  control?: Control<Form>;
  formPath?: Path<Form>;
  numberOfUsages?: number;
};

const DefaultEmailTemplateRow = ({
  emailTemplate,
  onEdit,
  control,
  formPath,
  numberOfUsages,
}: Props) => {
  const intl = useIntl();
  const translationId = defaultEmailTemplateNames[emailTemplate.type];
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
    >
      <Stack spacing={2} direction="row" alignItems="center">
        {control && formPath && (
          <ControlledToggle
            control={control}
            name={formPath}
            dataCy="queue-settings-manual-rejection-switch"
          />
        )}
        <Typography variant="body2">
          {translationId
            ? intl.formatMessage({
                id: translationId,
              })
            : intl.formatMessage({
                id: 'components.emailTemplateForm.unnamedTemplate',
              })}
        </Typography>

        <EmailTemplateChips
          emailTemplate={emailTemplate}
          numberOfUsages={numberOfUsages}
        />
      </Stack>

      {'id' in emailTemplate && (
        <Button
          color="secondary"
          sx={{ flexShrink: 0 }}
          startIcon={<Edit />}
          onClick={() => onEdit(emailTemplate)}
          data-cy="queue-settings-emails-edit-template-btn"
        >
          {intl.formatMessage({
            id: 'containers.settings.queues.email.editTemplate.button',
          })}
        </Button>
      )}
    </Stack>
  );
};

export default DefaultEmailTemplateRow;
