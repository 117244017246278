import { z } from 'zod';

const flattenObj = (
  obj: object,
  savedKey: string | null = null,
  result: Record<string, string> = {}
): Record<string, string> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const path = savedKey ? `${savedKey}.${key}` : key;

    if (value && typeof value === 'object') {
      return flattenObj(value, path, acc);
    }

    if (typeof value === 'string') return { ...acc, [path]: value };

    return acc;
  }, result);
};

const objectSchema = z
  .object({ data: z.record(z.unknown()).default({}) })
  .default({ data: {} })
  .catch({ data: {} });

export const getDocumentListErrorState = (error: unknown) => {
  if (!error) return null;

  const parsedError = objectSchema.parse(error);

  const flattenedError = flattenObj(parsedError.data);

  const errorState = Object.entries(flattenedError).reduce<
    'unknown-error' | 'no-access'
  >((acc, [key, value]) => {
    if (key.includes('queue') && value.includes('Object does not exist'))
      return 'no-access';

    return acc;
  }, 'unknown-error');

  return errorState;
};
