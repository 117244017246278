import { minBy } from 'lodash';
import { clock } from './clock';

type Timer = { startTimestamp: number; metadata: unknown };

export type TimeInterval = [start: number, end: number];

const timerMap: Record<string, Timer> = {};

export const timers = {
  snapshot: () => {
    return { ...timerMap };
  },

  clear: () => {
    Object.keys(timerMap).forEach(k => delete timerMap[k]);
  },

  start: (name: string, metadata?: unknown) => {
    if (name in timerMap) {
      // Ignore starting a timer again
      return;
    }

    timerMap[name] = { startTimestamp: clock.now(), metadata };
  },
  setMetadata: (name: string, metadata?: unknown) => {
    if (!(name in timerMap)) {
      return;
    }

    timerMap[name].metadata = metadata;
  },
  updateMetadata: <TMetadata>(
    name: string,
    updateFn: (metadata: TMetadata) => TMetadata
  ) => {
    if (!timerMap[name]?.metadata) {
      return;
    }

    timerMap[name].metadata = updateFn(timerMap[name].metadata as TMetadata);
  },
  elapsedInterval: <TMetadata>(name: string) => {
    if (!(name in timerMap)) {
      return undefined;
    }

    return {
      interval: [timerMap[name].startTimestamp, clock.now()] as TimeInterval,
      metadata: timerMap[name].metadata as TMetadata,
    };
  },
  stop: <TMetadata>(name: string) => {
    if (!(name in timerMap)) {
      return undefined;
    }

    const result = timers.elapsedInterval<TMetadata>(name);
    delete timerMap[name];
    return result;
  },

  minStartTimestamp: (): number | undefined => {
    const values = Object.values(timerMap);

    if (values.length > 0) {
      return minBy(values, 'startTimestamp')!.startTimestamp;
    }

    return undefined;
  },
};
