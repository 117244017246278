import update from 'immutability-helper';
import { isEqual, zip } from 'lodash';
import { useReducer } from 'react';
import { assertNever } from '../../lib/typeUtils';
import { SnakeCaseStatus } from '../../types/annotationStatus';
import { Url } from '../../types/basic';

export type Page = {
  // Page number in the original document is the unique identifier
  pageNumber: number;
  url: Url;
  // Rotation degree in the interval [0, 360)
  rotationDeg: number;
  initialRotationDeg: number;

  deleted: boolean;
  initialDeleted: boolean;
};

// Normalizes the input degree to [0-360) interval
// Just using 'd % 360' doesn't work as needed for negative numbers.
const normalizeDegree = (d: number) => ((d % 360) + 360) % 360;

export type Part =
  | PartWithoutAnnotation
  | PartWithAccessibleAnnotation
  | PartWithInaccessibleAnnotation;

export type PartWithoutAnnotation = {
  pages: Page[];
  targetQueueUrl: Url;
  annotationType: 'none';
};

export type PartWithAccessibleAnnotation = {
  pages: Page[];
  targetQueueUrl: Url;
  annotation: {
    url: Url;
    filename: string;
    queueUrl: Url;
    status: SnakeCaseStatus;
    started: boolean;
  };
  annotationType: 'accessible';
};

export type PartWithInaccessibleAnnotation = {
  pages: Page[];
  annotation: {
    filename: string;
    started: false;
  };
  annotationType: 'inaccessible';
};

export type EditState = {
  parts: Part[];
  previousState?: EditState;
  config: EditDocumentConfig;
};

export type SimpleEditAction = {
  previousState?: EditState;
} & (
  | {
      type: 'ROTATE_PAGE';
      pageNumber: number;
      rotationDeg: number;
    }
  | {
      type: 'MOVE_PAGE';
      pageNumber: number;
      targetPageNumber: number;
      order: 'before' | 'after';
    }
  | {
      type: 'DELETE_PAGE';
      pageNumber: number;
      deleted: boolean;
    }
  | { type: 'SPLIT_PART'; afterPageNumber: number }
  | {
      type: 'MERGE_PARTS';
      firstPartIndex: number;
    }
  | {
      type: 'SET_TARGET_QUEUE';
      partIndex: number;
      queueUrl: Url;
    }
);

type EditAction =
  | SimpleEditAction
  | {
      type: 'UNDO';
    }
  // We need a batch action, so that rotating/deleting all pages in a part
  // is treated as one action for undo
  | { type: 'BATCH_ACTION'; actions: SimpleEditAction[] }
  | { type: 'RESET'; newState: EditState };

export const findPage = (pageNumber: number, parts: Part[]) => {
  const partIndex = parts.findIndex(p =>
    p.pages.some(page => page.pageNumber === pageNumber)
  );

  if (partIndex === -1) return null;

  const part = parts[partIndex];

  if (part === undefined) return null;

  const pageIndex = part.pages.findIndex(
    page => page.pageNumber === pageNumber
  );

  const page = part.pages[pageIndex];

  if (page === undefined) return null;

  return { partIndex, pageIndex, part, page };
};

export const applyAction = (
  parts: Part[],
  action: SimpleEditAction
): Part[] => {
  switch (action.type) {
    case 'DELETE_PAGE':
    case 'ROTATE_PAGE': {
      const index = findPage(action.pageNumber, parts);
      if (!index) return parts;

      return update(parts, {
        [index.partIndex]: {
          pages: {
            [index.pageIndex]:
              action.type === 'DELETE_PAGE'
                ? {
                    deleted: { $set: action.deleted },
                  }
                : {
                    rotationDeg: { $set: normalizeDegree(action.rotationDeg) },
                  },
          },
        },
      });
    }
    case 'MERGE_PARTS': {
      const firstPart = parts[action.firstPartIndex];
      const secondPart = parts[action.firstPartIndex + 1];
      if (!firstPart || !secondPart) return parts;

      if (
        firstPart.annotationType === 'inaccessible' ||
        secondPart.annotationType === 'inaccessible'
      )
        return parts;

      return update(parts, {
        $splice: [
          [
            action.firstPartIndex,
            2,
            {
              annotationType: 'none',
              targetQueueUrl: firstPart.targetQueueUrl,
              pages: [...firstPart.pages, ...secondPart.pages],
            },
          ],
        ],
      });
    }
    case 'MOVE_PAGE': {
      const source = findPage(action.pageNumber, parts);

      if (action.pageNumber === action.targetPageNumber || !source)
        return parts;

      const movedPage = source.page;

      const partsWithoutMovedPage = update(parts, {
        [source.partIndex]: {
          pages: {
            $splice: [[source.pageIndex, 1]],
          },
        },
      });

      // targetIndex might change after removing the moved page, so we can't search in 'parts'
      const target = findPage(action.targetPageNumber, partsWithoutMovedPage);

      if (!target) return parts;

      const partsAfterMove = update(partsWithoutMovedPage, {
        [target.partIndex]: {
          pages: {
            $splice: [
              [
                target.pageIndex + (action.order === 'before' ? 0 : 1),
                0,
                movedPage,
              ],
            ],
          },
        },
      });

      // TODO should we keep empty parts if they have annotation attached?
      return partsAfterMove.filter(part => part.pages.length > 0);
    }
    case 'SET_TARGET_QUEUE': {
      return update(parts, {
        [action.partIndex]: {
          targetQueueUrl: { $set: action.queueUrl },
        },
      });
    }
    case 'SPLIT_PART': {
      const index = findPage(action.afterPageNumber, parts);
      if (!index) return parts;

      const partToSplit = parts[index.partIndex];

      if (!partToSplit || partToSplit.annotationType === 'inaccessible')
        return parts;

      const newParts: Part[] = [
        {
          targetQueueUrl: partToSplit.targetQueueUrl,
          pages: partToSplit.pages.slice(0, index.pageIndex + 1),
          annotationType: 'none',
        },
        {
          targetQueueUrl: partToSplit.targetQueueUrl,
          pages: partToSplit.pages.slice(index.pageIndex + 1),
          annotationType: 'none',
        },
      ];

      return update(parts, {
        $splice: [[index.partIndex, 1, ...newParts]],
      });
    }
    default:
      return assertNever(action);
  }
};

const editDocumentReducer = (
  state: EditState,
  action: EditAction
): EditState => {
  switch (action.type) {
    case 'UNDO':
      return state.previousState ?? state;
    case 'BATCH_ACTION':
      return {
        parts: action.actions.reduce(applyAction, state.parts),
        previousState: state,
        config: state.config,
      };
    case 'RESET':
      return action.newState;
    default:
      return {
        parts: applyAction(state.parts, action),
        previousState: action.previousState ?? state,
        config: state.config,
      };
  }
};

export const useEditState = (initialState: EditState) => {
  return useReducer(editDocumentReducer, initialState);
};

export type DispatchEdit = ReturnType<typeof useEditState>[1];

export type EditDocumentMode =
  | {
      type: 'single-annotation';
    }
  | {
      type: 'child-annotation';
      parentUrl: Url;
      parentIsAccessible: boolean;
    }
  | {
      type: 'parent-annotation';
      // We can access parent annotation from a specific child annotation, or directly
      fromAnnotation?: Url;
    };

export type EditDocumentConfig = {
  initialParts: Part[];
  initialPartsWithSuggestedSplits: Part[] | null;
  mode: EditDocumentMode;
  annotationUrl: Url;
  settings: {
    disableSplitting: boolean;
    preferInPlaceSplitting: boolean;
  };
};

export const hasSameSequenceOfVisiblePages = (a: Part, b: Part) =>
  isEqual(
    a.pages.filter(p => !p.deleted),
    b.pages.filter(p => !p.deleted)
  );

export const hasBeenEdited = (part: Part, config: EditDocumentConfig) =>
  !config.initialParts.some(p => hasSameSequenceOfVisiblePages(p, part));

export const isEditable = (part: Part) =>
  part.annotationType === 'none' || part.annotation.started;

export const hasNoVisiblePages = (part: Part) =>
  part.pages.every(p => p.deleted);

export const areSuggestedSplitsActive = (
  currentParts: Part[],
  config: EditDocumentConfig
) => {
  if (!config.initialPartsWithSuggestedSplits) return false;

  // Changing target queue is not considered as editing suggested splits
  return zip(currentParts, config.initialPartsWithSuggestedSplits).every(
    ([a, b]) => a && b && hasSameSequenceOfVisiblePages(a, b)
  );
};
