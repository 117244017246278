import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultWorkspaceSorting } from '../../../../constants/values';
import { updateUiSettings } from '../../../../redux/modules/user/actions';
import { userSelector } from '../../../../redux/modules/user/selectors';
import { SortingValue } from '../../../../types/user';

export const useWorkspaceSorting = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const setSorting = useCallback(
    (newSorting: SortingValue) => {
      dispatch(
        updateUiSettings({ dashboard: { workspacesSorting: newSorting } })
      );
    },
    [dispatch]
  );

  return {
    sorting:
      user.uiSettings?.dashboard?.workspacesSorting ?? defaultWorkspaceSorting,
    setSorting,
  };
};
