// Generated by ts-to-zod
import { z } from 'zod';
import { urlSchema } from '../../utils/codecUtils.schema';

const suggestedDocumentPageSchema = z.object({
  page: urlSchema,
  rotationDeg: z.number(),
});

export const suggestedDocumentSchema = z.object({
  pages: z.array(suggestedDocumentPageSchema),
});
