import { z, ZodTypeAny } from 'zod';
import { PaginationResponse } from './pagination';
import { paginationResponseSchema } from './pagination.schema';

export const listResponse = <T extends ZodTypeAny>(itemSchema: T) => {
  return z.object({
    pagination: paginationResponseSchema,
    results: z.array(itemSchema),
  });
};

/**
 * @noSchema
 */
export type ListResponse<T> = {
  pagination: PaginationResponse;
  results: T[];
};

export const unpaginatedListResponse = <T extends ZodTypeAny>(
  itemSchema: T
) => {
  return z.object({
    results: z.array(itemSchema),
  });
};

/**
 * @noSchema
 */
export type UnpaginatedListResponse<T> = {
  results: T[];
};
