import { Button } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';

const ReadOnlyButton = () => (
  <Button
    variant="outlined"
    color="secondary"
    size="large"
    disabled
    data-cy="confirm-button-read-only"
    sx={{ width: '100%' }}
  >
    <FormattedMessage id="components.documentValidation.sidebar.readOnlyDocument" />
  </Button>
);
export default ReadOnlyButton;
