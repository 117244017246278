import { PersonAdd } from '@rossum/ui/icons';
import { Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import ActionDialog from '../../../components/UI/ActionDialog';
import ActionDialogActions from '../../../components/UI/ActionDialog/ActionDialogActions';
import { boldText } from '../../../lib/formaterValues';

type ResetUserPasswordDialogProps = {
  onCancel: () => void;
  onReset: () => void;
  userName: string;
};

const ResetUserPasswordDialog = ({
  onReset,
  onCancel,
  userName,
}: ResetUserPasswordDialogProps) => {
  const intl = useIntl();

  return (
    <ActionDialog
      dialogTitle={intl.formatMessage({
        id: 'components.modal.resetPassword.title',
      })}
      onCancel={() => onCancel()}
      dialogColor="primary"
      DialogTitleIcon={PersonAdd}
      dialogActions={
        <ActionDialogActions
          confirmButtonProps={{
            onClick: () => {
              onReset();
              onCancel();
            },
            color: 'primary',
          }}
          confirmButtonText={intl.formatMessage({
            id: 'components.modal.resetPassword.title',
          })}
          dataCyConfirm="reset-user-password-confirm-footer"
          dataCyCancel="reset-user-password-cancel-footer"
        />
      }
      dataCy="reset-user-password-cancel-button"
    >
      <Typography variant="body1">
        {intl.formatMessage(
          {
            id: 'components.modal.resetPassword.text',
          },
          {
            boldText,
            user: userName,
          }
        )}
      </Typography>
    </ActionDialog>
  );
};

export default ResetUserPasswordDialog;
