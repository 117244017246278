import { format, formatDistanceToNow } from 'date-fns';
import { get } from 'lodash';
import { Email } from '../../api-client';

export const composeFwdMessage = (emailData: Email) => {
  const createdAt: string | null = get(emailData, ['createdAt']);
  const formattedDate = createdAt
    ? format(new Date(createdAt), 'ccc, hh:MM aa, dd/MM/yyyy')
    : undefined;
  const distanceToNow = createdAt
    ? formatDistanceToNow(new Date(createdAt), { addSuffix: true })
    : undefined;

  const ccEmails = emailData?.cc.map(({ email }) => email);
  const bccEmails = emailData?.bcc.map(({ email }) => email);

  const { bodyTextHtml, bodyTextPlain } = emailData;

  const bodyText = bodyTextPlain || bodyTextHtml || '';

  return `</br></br></br></br></br>---------- Forwarded message ---------
FROM: ${get(emailData, ['from', 'email'], '')}
DATE: ${formattedDate} ${distanceToNow}
${ccEmails && ccEmails.length ? `CC: ${ccEmails.join(', ')}` : ''}
${bccEmails && bccEmails.length ? `BCC: ${bccEmails.join(', ')}` : ''}
${bodyText}`;
};

export const createEmailAddressObject = (addressList: Array<string>) =>
  addressList.map(emailAddress => ({
    email: emailAddress,
  }));
