import { DedicatedEngineSchemaValidationResult } from '@rossum/api-client/dedicatedEngineSchema';
import { isEmpty } from 'lodash';

export const isErrorAtIndex = (
  errors: DedicatedEngineSchemaValidationResult,
  index: number
) => {
  return !!errors?.content?.fields?.[index];
};

export const anyErrors = (errors: DedicatedEngineSchemaValidationResult) =>
  !isEmpty(errors);
