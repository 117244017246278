import { BillingHistoryListResponse } from '@rossum/api-client/billing';
import { PaginationQuery } from '@rossum/api-client/utils';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApiClient } from '../../../lib/apiClient';
import { BillingEntity, getBillingEndpoint } from './useBillingEntity';

const QUERY_KEY_BILLING_HISTORY = 'billing-history';

export const useBillingHistory = (
  billingEntity: BillingEntity,
  pagination: Required<PaginationQuery>
): UseQueryResult<BillingHistoryListResponse> => {
  const api = useApiClient();
  return useQuery({
    queryKey: [QUERY_KEY_BILLING_HISTORY, billingEntity, pagination] as const,

    queryFn: ({ queryKey: [, billingEntity, pagination] }) => {
      const { id, endpoint } = getBillingEndpoint(billingEntity);

      return api.request(
        endpoint.billingHistory.get(id, {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        })
      );
    },
  });
};
