import { useRef } from 'react';

export const useLastRotation = (initialRotation: number) => {
  const lastCssRotation = useRef<number>(initialRotation);

  const rotationDelta = (initialRotation - lastCssRotation.current) % 360;

  // Animation between two rotations should go always in the shortest direction.
  // For example a rotation by +270 should be animated as a rotation by -90 degrees.
  const shift =
    Math.abs(rotationDelta) <= 180 ? 0 : Math.sign(rotationDelta) * 360;

  lastCssRotation.current += rotationDelta - shift;

  return lastCssRotation;
};
