import { SVGProps } from 'react';

const FooterEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="57"
    height="49"
    viewBox="0 0 57 49"
    {...props}
  >
    <defs>
      <path
        id="footer-empty-state-a"
        d="M8,0 L37,0 L37,0 L37,23 C37,27.418278 33.418278,31 29,31 L0,31 L0,31 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"
      />
      <mask
        id="footer-empty-state-c"
        width="37"
        height="31"
        x="0"
        y="0"
        fill="#fff"
      >
        <use xlinkHref="#footer-empty-state-a" />
      </mask>
      <path
        id="footer-empty-state-b"
        d="M8,0 L22,0 L22,0 L22,10 C22,14.418278 18.418278,18 14,18 L0,18 L0,18 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"
      />
      <mask
        id="footer-empty-state-d"
        width="22"
        height="18"
        x="0"
        y="0"
        fill="#fff"
      >
        <use xlinkHref="#footer-empty-state-b" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#403E46"
        fillRule="nonzero"
        d="M52,37 L51.999,42 L57,42 L57,44 L51.999,44 L52,49 L50,49 L49.999,44 L45,44 L45,42 L49.999,42 L50,37 L52,37 Z M44,0 C48.418278,0 52,3.581722 52,8 L52,32 L50,32 L50,8 C50,4.76160306 47.4344251,2.12242824 44.2249383,2.00413847 L44,2 L8,2 C4.76160306,2 2.12242824,4.56557489 2.00413847,7.77506174 L2,8 L2,36 C2,39.2383969 4.56557489,41.8775718 7.77506174,41.9958615 L8,42 L39,42 L39,44 L8,44 C3.581722,44 0,40.418278 0,36 L0,8 C0,3.581722 3.581722,0 8,0 L44,0 Z"
      />
      <use
        stroke="#403E46"
        strokeDasharray="2 2"
        strokeWidth="4"
        mask="url(#footer-empty-state-c)"
        xlinkHref="#footer-empty-state-a"
      />
      <use
        stroke="#403E46"
        strokeDasharray="2 2"
        strokeWidth="4"
        mask="url(#footer-empty-state-d)"
        xlinkHref="#footer-empty-state-b"
      />
    </g>
  </svg>
);

export default FooterEmpty;
