import { differenceInDays, startOfDay } from 'date-fns';
import { findLast } from 'lodash';
import { CustomerType } from '../../types/organization';

export type Severity = 'low' | 'medium';

const customerTypes: CustomerType[] = [
  'bpo',
  'endcustomer',
  'softwarevendoroem',
];

export const isHandledCustomerType = (
  customerType: string | null | undefined
): customerType is CustomerType =>
  customerTypes.some(value => value === customerType);

const severityLimits: Array<[number, Severity]> = [
  [0, 'low'],
  [10, 'medium'],
];

const getSeverityByDifference = (difference: number) =>
  findLast(severityLimits, ([limit]) => difference >= limit)?.[1];

export const resolveSeverity = (
  today: Date | number,
  overduePaymentDate: Date | null | undefined
) => {
  if (!overduePaymentDate) return null;

  const difference = differenceInDays(
    startOfDay(new Date(today)),
    startOfDay(new Date(overduePaymentDate))
  );

  return getSeverityByDifference(difference) || null;
};
