// Generated by ts-to-zod
import { z } from 'zod';

export const createInboxPayloadSchema = z.object({
  name: z.string(),
  emailPrefix: z.string(),
  bounceEmailTo: z.string().optional(),
  queues: z.array(z.string()),
  filters: z
    .object({
      allowedSenders: z.array(z.string()),
    })
    .optional(),
});
