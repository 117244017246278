// Generated by ts-to-zod
import { z } from 'zod';
import { annotationStatusSchema } from '../../shared/models/annotationStatus.schema';
import { idSchema } from '../../utils/codecUtils.schema';
import { paginationQuerySchema } from '../../utils/listQuery.schema';

export const annotationListQuerySchema = z
  .object({
    id: z.array(z.number()),
    queue: idSchema,
    search: z.string(),
    status: z.array(annotationStatusSchema),
    labels: z.array(z.string()),
    ordering: z.string(),
    assignees: z.array(z.number()),
    'content.schema_id': z.array(z.string()),
  })
  .partial()
  .and(paginationQuerySchema);
