import { Rectangle2DCoordinates } from './geometry';

export const getSpanningRectangleCoordinates = (
  coordinates: Rectangle2DCoordinates[]
): Rectangle2DCoordinates | undefined => {
  return coordinates.length
    ? [
        Math.min(...coordinates.map(p => p[0])),
        Math.min(...coordinates.map(p => p[1])),
        Math.max(...coordinates.map(p => p[2])),
        Math.max(...coordinates.map(p => p[3])),
      ]
    : undefined;
};
