import { SVGProps } from 'react';

const Find = (props: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={18} {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M17.114 13.32l3.011 3.018a.559.559 0 0 1-.793.782l-3.006-3.006a4.47 4.47 0 1 1 .788-.788v-.005zm-3.531.615a3.352 3.352 0 1 0 0-6.704 3.352 3.352 0 0 0 0 6.704zM.447 1.5H17a.5.5 0 1 0 0-1H.447a.5.5 0 0 0 0 1zM.447 4.5H17a.5.5 0 1 0 0-1H.447a.5.5 0 0 0 0 1zM.5 7.5H7a.5.5 0 0 0 0-1H.5a.5.5 0 0 0 0 1zM.5 10.5H7a.5.5 0 1 0 0-1H.5a.5.5 0 0 0 0 1z" />
    </g>
  </svg>
);

export default Find;
