import { ElisRequestConfig } from '../../utils/requestConfig';
import {
  domainSettingsQuerySchema,
  domainSettingsResponseSchema,
} from './domainSettings.schema';

export type DomainSettingsQuery = {
  domain: string;
};

export type DomainSettingsSsoProvider = {
  id: number;
  name: string;
  sendRedirectUrl: boolean;
  apiUrl: string;
};

export type DomainSettingsResponse = {
  ssoProviders: DomainSettingsSsoProvider[];
};

export const domainSettings = (query: DomainSettingsQuery) => {
  return {
    endpoint: `/internal/domain_settings`,
    method: 'GET',
    responseSchema: domainSettingsResponseSchema,
    query,
    querySchema: domainSettingsQuerySchema,
  } as const;
};
