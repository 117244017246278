import { endpoints } from '@rossum/api-client';
import { DedicatedEngineSchemaValidatePayload } from '@rossum/api-client/dedicatedEngineSchema';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';

const QUERY_KEY_DEDICATED_ENGINE_SCHEMA_VALIDATE =
  'dedicated-engine-schema-validate-result';

export const useDedicatedEngineSchemaValidateResult = (
  engineSchema: DedicatedEngineSchemaValidatePayload
) => {
  return useQuery({
    // TODO: using big objects as query keys
    queryKey: [
      QUERY_KEY_DEDICATED_ENGINE_SCHEMA_VALIDATE,
      engineSchema,
    ] as const,

    queryFn: ({ queryKey }) =>
      api.request(endpoints.engineSchemas.validate(queryKey[1])),
  });
};
