import { Message } from '@rossum/api-client/shared';
import { ErrorOutline, Info, WarningAmber } from '@rossum/ui/icons';
import { Box, Divider, Paper, Stack, useTheme } from '@rossum/ui/material';
import HTMLMessage from '../../DatapointMessage/HTMLMessage';

const icons = {
  info: Info,
  warning: WarningAmber,
  error: ErrorOutline,
};

const ContentMessage = ({
  content,
  type,
}: {
  content: string;
  type: Message['type'];
}) => {
  const Icon = icons[type];
  const theme = useTheme();
  return (
    <Stack direction="row" gap={0.5}>
      <Box color={theme.palette[type].main}>
        <Icon />
      </Box>
      <Stack sx={{ wordBreak: 'break-word' }}>
        <HTMLMessage content={content} />
      </Stack>
    </Stack>
  );
};

type MessagesProps = { messages?: Array<Message> };

const Messages = ({ messages }: MessagesProps) => {
  if (!messages || messages.length < 1) return null;

  return (
    <Stack
      component={Paper}
      elevation={4}
      sx={{
        width: '250px',
        maxHeight: '600px',
        overflowY: 'auto',
        position: 'absolute',
        left: '406px',
        top: '51px',
        padding: '10px',
        borderRadius: '5px',
      }}
    >
      <Stack divider={<Divider />} spacing={1}>
        {messages.map(({ id, type, content }) => (
          <ContentMessage key={id} type={type} content={content} />
        ))}
      </Stack>
    </Stack>
  );
};

export default Messages;
