import clsx from 'clsx';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import styles from './style.module.sass';

type Props = {
  children: ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

const Scrollable = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }: Props, ref) => (
    <div className={clsx(styles.Scrollable, className)} ref={ref} {...props}>
      {children}
    </div>
  )
);

Scrollable.defaultProps = {
  className: '',
};

export default Scrollable;
