// Generated by ts-to-zod
import { z } from 'zod';

const iDBrandSchema = z.object({
  ID: z.any(),
});

export const idSchema = z.number();

const urlBrandSchema = z.object({
  Url: z.any(),
});

export const urlSchema = z.string();
