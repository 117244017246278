import { reducer as toastr } from 'react-redux-toastr';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { RouterState } from 'redux-first-history';
import { Immutable, ImmutableObject } from 'seamless-immutable';
import { NonNullableRouterState, State } from '../../types/state';
import { RootActionType } from '../rootActions';
import annotation from './annotation/reducer';
import bbox from './bboxes/reducer';
import beforeLeave from './beforeLeave/reducer';
import datapoints from './datapoints/reducer';
import extensions from './extensions/reducer';
import groups from './groups/reducer';
import locale from './locale/reducer';
import modal from './modal/reducer';
import organization from './organization/reducer';
import organizationGroup from './organizationGroup/reducer';
import pages from './pages/reducer';
import productTour from './productTour/reducer';
import queues from './queues/reducer';
import relations from './relations/reducer';
import repeateRequests from './repeatedRequests/reducer';
import schema from './schema/reducer';
import search from './search/reducer';
import searchAndReplace from './searchAndReplace/reducer';
import stack from './stack/reducer';
import statistics from './statistics/reducer';
import ui from './ui/reducer';
import user from './user/reducer';
import users from './users/reducer';
import validationMessages from './validationMessages/reducer';
import workspaces from './workspaces/reducer';

export default (router: Reducer<RouterState, AnyAction>) => {
  const reducers: {
    [A in keyof State]:
      | Reducer<State[A], RootActionType>
      | Reducer<Immutable<State[A]>, RootActionType>
      | Reducer<ImmutableObject<State[A]>, RootActionType>;
  } = {
    annotation,
    bbox,
    datapoints,
    extensions,
    groups,
    locale,
    modal,
    organization,
    organizationGroup,
    pages,
    queues,
    relations,
    repeateRequests,
    // Is it ok to consider non-nullable? It gets filled in the first action.
    router: router as Reducer<NonNullableRouterState, AnyAction>,
    schema,
    search,
    searchAndReplace,
    stack,
    statistics,
    toastr,
    productTour,
    ui,
    user,
    users,
    validationMessages,
    workspaces,
    beforeLeave,
  };

  return combineReducers(reducers);
};
